import { Injectable } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { Router, RouterStateSnapshot } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class NotLoggedGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route, state: RouterStateSnapshot) {
    if (!this.authService.isLoggedIn()) return true;
    this.router.navigate(["/"]);
    return false;
  }
}
