<div class="card mb-5">
  <h5 class="card-header text-white" [innerHtml]="checkHeaderByService()"></h5>

  <div class="card-body" style="padding-left: 0.5rem!important; padding-right: 0.5rem!important;">
    <div class="p-5 container-xl text-center" [hidden]="!loadingImpegni">
      <app-loading message="Caricamento in corso..."></app-loading>
    </div>

    <div class="table-responsive scrollbar-y" [hidden]="loadingImpegni">
      <table class="table small">
        <thead>
          <tr>
            <th class="px-1" scope="col"></th>
            <th class="px-1" scope="col">Codice Capitolo</th>
            <th class="px-1" scope="col">Anno {{ getLabelFieldsByType() }}</th>
            <th class="px-1" scope="col">Numero {{ getLabelFieldsByType() }}</th>
            <th class="px-1" scope="col">Descrizione {{ getLabelFieldsByType() }}</th>
            <th class="px-1" scope="col">Soggetto {{ getLabelSoggettoByType() }}</th>
            <th class="px-1" scope="col">Importo Iniziale</th>
            <th class="px-1" scope="col">Importo Modifiche</th>
            <th class="px-1" scope="col">Valore Attuale</th>
            <th class="px-1" scope="col">Numero Provv.</th>
            <th class="px-1" scope="col">Data Provv.</th>
            <th class="px-1" scope="col">Anno {{ getLabelFieldsByType() }} Ribaltato</th>
            <th class="px-1" scope="col">Numero {{ getLabelFieldsByType() }} Ribaltato</th>
            <th class="px-0" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let accertamento of impAccsSelezionati">
            <tr>
              <td class="px-1">
                <a class="text-danger pointer" *ngIf="!accertamento.isChangeSoggetto" [title]="getTitleByType()" aria-label="rimuovi accertamento"
                  (click)="disassociaSicerImpegnoAccertamento(accertamento)" >
                  <i class="fas fa-times-circle "
                    aria-hidden="true">
                  </i>
                </a>
              </td>
              <td class="px-1">{{ !accertamento.isChangeSoggetto ? accertamento.codCpt : '' }}</td>
              <td class="px-1">{{ !accertamento.isChangeSoggetto ? accertamento.annoImpacc: '' }}</td>
              <td class="px-1">{{ !accertamento.isChangeSoggetto ? accertamento.numeroImpacc : '' }}</td>
              <td class="px-1">
                <span class="line-clamp" title="{{ accertamento.descrImpacc }}">{{ !accertamento.isChangeSoggetto ? accertamento.descrImpacc : '' }}</span>
              </td>
              <td class="px-1" [ngClass]="{ 'border-top-0 border-left': accertamento.isChangeSoggetto }">{{ accertamento.ragsocSoggetto }}</td>
              <td class="px-1" [ngClass]="{ 'border-0': accertamento.isChangeSoggetto }" style="text-align: right;">{{ insertDotForThousands(accertamento.importoIniImpacc) }}</td>
              <td class="px-1" [ngClass]="{ 'border-0': accertamento.isChangeSoggetto }" style="text-align: right;">{{ insertDotForThousands(accertamento.importoModifica) }}</td>
              <td class="px-1" [ngClass]="{ 'border-0': accertamento.isChangeSoggetto }" style="text-align: right;">{{ insertDotForThousands(accertamento.valoreAttualeImpacc) }}</td>
              <td class="px-1" [ngClass]="{ 'border-0': accertamento.isChangeSoggetto }">{{ accertamento.numApprovAtto }}</td>
              <td class="px-1" [ngClass]="{ 'border-0': accertamento.isChangeSoggetto }">{{ getDataFormat(accertamento.dataAtto) }}</td>
              <td class="px-1" [ngClass]="{ 'border-0': accertamento.isChangeSoggetto }">{{ accertamento.annoImpacc > accertamento.annoImpaccRib ? '' : accertamento.annoImpaccRib }}</td>
              <td class="px-1" [ngClass]="{ 'border-0': accertamento.isChangeSoggetto }">{{ accertamento.annoImpacc > accertamento.annoImpaccRib ? '' : accertamento.numeroImpaccRib }}</td>
              <td class="px-0" [ngClass]="{ 'border-0': accertamento.isChangeSoggetto }">
                <button class="btn btn-primary btn-sm px-1"
                  [id]="accertamento.id"
                  type="button"
                  [disabled]="checkDisabledShowLiquidazioniOrdinativi(accertamento)"
                  [title]="checkTitleButton(accertamento)"
                  data-toggle="collapse"
                  aria-expanded="false"
                  [attr.data-target]="setTargetAndControl(accertamento)"
                  [attr.aria-controls]="setTargetAndControl(accertamento)"
                  (click)="removeFocus(accertamento.id)">
                  <span>{{ getLiquidazioniOrdinativiByService() }}&nbsp;</span>
                  <i *ngIf="!checkDisabledShowLiquidazioniOrdinativi(accertamento)" class="d-inline fas " [ngClass]="{
                    'fa-chevron-up': checkButtonIsExpanded(accertamento),
                    'fa-chevron-down': !checkButtonIsExpanded(accertamento)
                  }"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td class="py-0 px-0 border-0" colspan="16">
                <app-dettaglio-ordinativo *ngIf="checkServiceTypeFinanziamento()"
                  [accertamento]="accertamento"
                  [ordinativi]="accertamento.ordinativi"
                  [type]="type"
                ></app-dettaglio-ordinativo>
                <app-dettaglio-liquidazione *ngIf="checkServiceTypeInterventoOrProgetto()"
                  [interventoId]="interventoId"
                  [impegno]="accertamento"
                  [liquidazioni]="accertamento.liquidazioni"
                  [type]="type"
                ></app-dettaglio-liquidazione>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
