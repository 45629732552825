import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as moment from "moment";
import { CurrencyPipe } from '@angular/common';
import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";

@Component({
  selector: "app-chart-gantt-interventi",
  templateUrl: "./chart-gantt-interventi.component.html",
  styleUrls: ["./chart-gantt-interventi.component.css"],
})
export class ChartGanttInterventiComponent implements OnInit, AfterViewInit, OnDestroy {
  gantt;
  _datChart;
  chartDivName = "";
  element: any;
  importoTotale = 0;
  importiPerTipologia = [];
  htmlClasses = "bg-white chartfase border shadow-sm my-3 rounded";
  hasPhases = false;
  chartHeigth = "500px";
  fakeStartDate = '2020-12-01' //Serve se vogliamo farli partire dallo stesso istante
  @Input() set dataChart(element) {
    this.element = element;
  }

  constructor(private cdRef: ChangeDetectorRef) {
    this.chartDivName = this.makeRandomString().toLowerCase();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    console.log("set data chart");

    const elem = this.element.item;

    const projectTitle = this.element.dataType === 'Finanziamenti'
      ? `Finanziamenti`
      : `${elem.codiceProgetto} - ${elem.titoloProgetto}`;
    const elementTitle = '';
    am4core.options.autoSetClassName = true;

    const vectorData = this.element.dataType === 'Finanziamenti'
      ? elem
      : elem.interventi;

    const data = [];
    vectorData.forEach(element => {
      const phases = element.fasi;
      phases.forEach(phase => {
        const fase = {};
        fase["name"] = `${element.codice} - ${element.titolo}`;
        fase["fromDate"] = moment(phase.dataPrevistaInizio, "YYYY-MM-DD")
          .startOf("month")
          .format("YYYY-MM-DD");
        fase["toDate"] = moment(phase.dataPrevistaFine, "YYYY-MM-DD")
          .add(1, "M")
          .startOf("month")
          .format("YYYY-MM-DD");
        fase["color"] = phase.coloreTipologia;
        fase["tipologia"] = phase.tipologiaFase.fase;
        fase["descrizione"] = phase.tipologiaFase.descrizione;
        fase["colorFasi"] = am4core.color(phase.coloreFasi, 0.8);
        fase[
          "labelTooltip"
        ] = `${phase.tipologiaFase.fase}`;

        data.push(fase);
      });
    });
    this._datChart = data;

    if (this._datChart.length > 0) {
      this.hasPhases = true;

      const startDate = this._datChart
        .map((e) => e.fromDate)
        .reduce((prev, curr) => (prev < curr ? prev : curr));

      const endDate = this._datChart
        .map((e) => e.toDate)
        .reduce((prev, curr) => (prev > curr ? prev : curr));

      this.renderGantt(
        this._datChart,
        projectTitle,
        elementTitle,
        startDate,
        endDate,
        this.element.color
      );
    } else {
      this.hasPhases = false;
    }
    this.cdRef.detectChanges();
  }

  renderGantt(data, projTitle, elementTitle, startDate, endDate, parentColor) {
    const that = this;

    /// Themes
    am4core.useTheme(am4themes_animated);

    const rowHeight = data.length * 23;
    const minChartHeight = 550;
    this.chartHeigth = Math.max(rowHeight, minChartHeight) +'px';

    this.gantt = am4core.create(`div_${this.chartDivName}`, am4charts.XYChart);
    this.gantt.hiddenState.properties.opacity = 0; // this creates initial fade-in

    this.gantt.paddingRight = 30;
    this.gantt.dateFormatter.inputDateFormat = "yyyy-MM"; //"yyyy-MM-dd HH:mm";

    this.gantt.language.locale = am4lang_it_IT;

    const colorSet = new am4core.ColorSet();
    colorSet.saturation = 0.4;
    const title = this.gantt.titles.create();
    const title1 = projTitle; //"P20.0013-008 Ferrovia Roma - Viterbo";
    const title2 = elementTitle; //"Ammodernamento e potenziamento della ferrovia ex concessa Roma - Viterbo nella tratta extraurbana Riano - Morlupo da progr. km 0+000 a progr. km 5+989,31 di progetto. Opere Civili lotto 1";
    title.text = `${title1.replace(/(\r\n|\n|\r)/gm, "")} \n ${title2.replace(/(\r\n|\n|\r)/gm, "")} `;
    title.fontSize = 16;
    title.marginBottom = 30;
    title.align = "left";

    //sono tutte fasi
    this.gantt.data = data.sort((a, b) =>
      a.name < b.name ? -1 : a.name < b.name ? 0 : 1
    );

    const categoryAxis = this.gantt.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.cellStartLocation = 0.0;
    categoryAxis.renderer.cellEndLocation = 1;

    const label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.maxWidth = 303;
    label.fontSize = 12;
    label.tooltipText = '{name}';
    categoryAxis.tooltip.label.pointerOrientation ="top";
    categoryAxis.tooltip.label.fontSize = 12;
    categoryAxis.tooltip.label.maxWidth = 160;
    categoryAxis.tooltip.label.wrap = true;

    const dateAxis = this.gantt.xAxes.push(new am4charts.DateAxis());
    dateAxis.dateFormatter.dateFormat = "yyyy-MM";
    dateAxis.renderer.minGridDistance = 70;
    dateAxis.baseInterval = { count: 1, timeUnit: "month" };
    dateAxis.dateFormats.setKey("month", "MMM yyyy");
    dateAxis.renderer.tooltipLocation = 0;
    dateAxis.renderer.opposite = true;
    dateAxis.renderer.grid.template.location = 0;

    const series1 = this.gantt.series.push(new am4charts.ColumnSeries());
    series1.columns.template.tooltipText = "{labelTooltip}";
    series1.dataFields.openDateX = "fromDate";
    series1.dataFields.dateX = "toDate";
    series1.dataFields.categoryY = "name";

    series1.columns.template.propertyFields.fill = "colorFasi"; // get color from data
    series1.columns.template.propertyFields.stroke = "colorFasi";
    series1.columns.template.strokeOpacity = 0;
    series1.columns.template.column.fillOpacity = 1;
    series1.columns.template.height = am4core.percent(60);
    series1.zIndex = 10;
    series1.name = "Tipologie";

    this.gantt.scrollbarX = new am4core.Scrollbar();
    this.gantt.scrollbarX.exportable = false;
    this.gantt.scrollbarY = new am4core.Scrollbar();
    this.gantt.scrollbarY.exportable = false;

    this.aggiungiLogo(this.gantt);

    function createFillParent(code, elementName, date, openDate, color, span) {
      const fillAxis = that.gantt.yAxes.push(new am4charts.CategoryAxis());
      fillAxis.dataFields.category = "name";
      fillAxis.renderer.grid.template.disabled = true;
      fillAxis.renderer.labels.template.disabled = true;
      fillAxis.renderer.inversed = true;
      fillAxis.renderer.cellStartLocation =
        categoryAxis.renderer.cellStartLocation;
      fillAxis.renderer.cellEndLocation =
        span - fillAxis.renderer.cellStartLocation;

      const fillSeries = that.gantt.series.push(new am4charts.ColumnSeries());
      fillSeries.clustered = false;
      fillSeries.yAxis = fillAxis;
      fillSeries.columns.template.height = am4core.percent(100);
      fillSeries.dataFields.openDateX = "fromDate";
      fillSeries.dataFields.dateX = "toDate";
      fillSeries.dataFields.categoryY = "name";
      fillSeries.fill = color;
      fillSeries.stroke = color;
      fillSeries.strokeWidth = 0;
      fillSeries.columns.template.column.fillOpacity = 1;
      fillSeries.data = [
        {
          fromDate: openDate,
          toDate: date,
          name: elementName,
        },
      ];
      fillSeries.name = code;
      fillSeries.columns.template.height = am4core.percent(100);
      fillSeries.hiddenInLegend = true;
    }

    if (data.length > 0) {
      createFillParent(
        "",
        data[0].name,
        startDate,
        endDate,
        am4core.color(parentColor, 0.1),
        data.length
      );

      const tipologie = [];

      data.forEach((element) => {
        let tipologia = tipologie.find((x) => x.tipologia == element.tipologia);
        if (tipologia == null) {
          tipologia = {
            name: element.name,
            tipologia: element.tipologia,
            color: element.color,
            fromDate: element.fromDate,
            toDate: element.toDate,
            rowCount: 1,
          };
          tipologie.push(tipologia);
        } else {
          tipologia.rowCount++;
          tipologia.fromDate = moment
            .min(
              moment(tipologia.fromDate, "YYYY-MM-DD"),
              moment(element.fromDate, "YYYY-MM-DD")
            )
            .format("YYYY-MM-DD");
          tipologia.toDate = moment
            .max(
              moment(tipologia.toDate, "YYYY-MM-DD"),
              moment(element.toDate, "YYYY-MM-DD")
            )
            .format("YYYY-MM-DD");
        }
      });

      tipologie.forEach((element) => {
        const inizio = moment(element.fromDate, "YYYY-MM-DD").set("date", 1);
        const fine = moment(element.toDate, "YYYY-MM-DD").set("date", 1);

        for (; inizio < fine; ) {
          const finetmp = moment(inizio);
          finetmp.add(1, "M");
          data.filter(
            (d) =>
              d.tipologia === element.tipologia &&
              ((moment(d.fromDate, "YYYY-MM-DD").format("YYYY-MM") ==
                moment(d.toDate, "YYYY-MM-DD").format("YYYY-MM") &&
                moment(d.fromDate, "YYYY-MM-DD").format("YYYY-MM") ==
                  inizio.format("YYYY-MM") &&
                finetmp.format("YYYY-MM") ==
                  moment(d.toDate, "YYYY-MM-DD").format("YYYY-MM")) ||
                (moment(d.fromDate, "YYYY-MM-DD").format("YYYY-MM") !=
                  moment(d.toDate, "YYYY-MM-DD").format("YYYY-MM") &&
                  moment(d.fromDate, "YYYY-MM-DD") <= inizio &&
                  finetmp <= moment(d.toDate, "YYYY-MM-DD")))
          );
          inizio.add(1, "M");
        }
      });

      this.gantt.legend = new am4charts.Legend();

      const that = this;
      const legendData = [];
      const fullLegendData = [];
      series1.events.on("ready", function(ev) {
        series1.columns.each(function(column) {
          const find = legendData.find(data => data.name === column.dataItem._dataContext.tipologia);
          if (!find) {
            legendData.push({
              name: column.dataItem._dataContext.tipologia,
              fill: column.fill,
              columnDataItem: column.dataItem
            });
          }

          fullLegendData.push({
            name: column.dataItem._dataContext.tipologia,
            fill: column.fill,
            columnDataItem: column.dataItem
          });
        });
        that.gantt.legend.data = legendData;
      });

      this.gantt.legend.itemContainers.template.events.on("hit", function(ev) {
        if (!ev.target.isActive) {
          fullLegendData.forEach(data => {
            if (data.columnDataItem._dataContext.tipologia == ev.target.dataItem.dataContext.columnDataItem._dataContext.tipologia) {
              data.columnDataItem.hide();
            }
          });
        } else {
          fullLegendData.forEach(data => {
            if (data.columnDataItem._dataContext.tipologia == ev.target.dataItem.dataContext.columnDataItem._dataContext.tipologia) {
              data.columnDataItem.show();
            }
          });
        }
      });

      //Esportazione nascondi asse y
      // this.gantt.exporting.events.on("exportstarted", function (ev) {
      //   categoryAxis.renderer.labels.template.disabled = true;
      // });

      // this.gantt.exporting.events.on("exportfinished", function (ev) {
      //   categoryAxis.renderer.labels.template.disabled = false;
      // });
    }
    this.exportChart(this.gantt, title.text);
  }

  aggiungiLogo(logoChart) {
    // Add watermark
    const watermark = new am4core.Image();
    watermark.href = "assets/img/logo_blu.svg";
    logoChart.tooltipContainer.children.push(watermark);
    watermark.align = "right";
    watermark.valign = "bottom";
    watermark.opacity = 0.3;
    watermark.marginRight = 10;
    watermark.marginBottom = 5;
    watermark.disabled = true;

    // Enable watermark on export
    logoChart.exporting.events.on("exportstarted", function (ev) {
      watermark.disabled = false;
    });

    // Disable watermark when export finishes
    logoChart.exporting.events.on("exportfinished", function (ev) {
      watermark.disabled = true;
    });

    // Add watermark to validated sprites
    logoChart.exporting.validateSprites.push(watermark);
  }

  exportChart(chart, title) {
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = "right";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.background = "#ff0000";
    chart.exporting.menu.defaultStyles = true;
    chart.exporting.filePrefix = title;
    chart.exporting.menu.items = [
      {
        menu: [
          { type: "jpg", label: "JPG" },
          { type: "png", label: "PNG" },
          { type: "csv", label: "CSV" },
        ],
      },
    ];
    chart.exporting.menu.items[0].icon = "assets/img/export.png";
  }

  printValueAsCurrency(value) {
    value = typeof value === "number" ? value : value.replace(",", ".").trim();
    const format = "1.2-2";
    const currency = "€";
    const currentLocale: string = "it";
    return new CurrencyPipe(currentLocale).transform(
      value,
      "EUR",
      currency,
      format,
      "it-IT"
    );
  }

  makeRandomString() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  ngOnDestroy() {
    this.gantt && this.gantt.dispose();
  }
}
