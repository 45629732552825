import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
    providedIn: "root"
})
export class ImageHelper {

    constructor(private domSanitizer: DomSanitizer) { }

    public hrefBase64Image(base64Image: string): string {
        return `data:image/png;base64, ${base64Image}`
    }

    public renderSanitizedBase64Image(base64Content: any): any {
        return this.sanitizeUri(this.hrefBase64Image(base64Content))
    }

    public sanitizeUri(uri: any): any {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(uri)
    }
}