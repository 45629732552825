import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { Classificazione } from '../models';


class DataTablesResponse {
    data: Classificazione[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}

@Injectable({
    providedIn: "root"
})
export class ClassificazioneService {
    private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}Classificazioni/`;
    constructor(private http: HttpClient) { }

    getAllDt(dataTablesParameters: any) {
        return this.http.post<DataTablesResponse>(
            `${this.apiUrl}alldt`,
            dataTablesParameters
        );
    }
    getAll() {
        return this.http.get<Classificazione[]>(
            `${this.apiUrl}`
        );
    }
    insert(s: Classificazione) {
        return this.http.post<any>(`${this.apiUrl}`, s);
    }
    update(s: Classificazione) {
        return this.http.put<any>(`${this.apiUrl}${s.id}`, s);
    }
    delete(id: number) {
        return this.http.delete<any>(`${this.apiUrl}${id}`);
    }
    save(s:Classificazione){
        if (s.id)return this.update(s);
        return this.insert(s);
    }

}
