<form [formGroup]="form">
  <div class="form-row">
    <div class="col-md-6 mb-3">
      <label for="validationDefault01">Codice Intervento</label>
      <input formControlName="codice" type="text" class="form-control" max="12" id="validationDefault03" placeholder="Inserisci il codice intervento">
    </div>
    <div class="col-md-6 mb-3">
      <label for="validationDefault03">Descrizione Intervento / Note</label>
      <input formControlName="descrizione" type="text" class="form-control" id="validationDefault05" placeholder="Inserisci la descrizione">
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-6 mb-3">
      <label for="validationDefault02">Soggetti Giuridici</label>
      <ng-select formControlName="soggetti" [items]="soggettiGiuridici" bindValue="id" bindLabel="descrizione"
        [multiple]="true" [closeOnSelect]="false" id="validationDefault04" placeholder="Seleziona il soggetto giuridico"
        [clearSearchOnAdd]="true">
      </ng-select>
    </div>
    <div class="col-md-6 mb-3">
      <label for="areeTematicheCombo">Aree gestionali direzione e/o esterne</label>
      <ng-select [items]="areeTematicheCombo" bindValue="descrizione" bindLabel="descrizione"
        formControlName="areaGestionale" placeholder="Seleziona area gestionale direz. e/o esterna ">
        <ng-template ng-option-tmp let-item="item">
          <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-6 mb-3">
      <label>Tipologia Intervento</label>
      <ng-select [items]="tipologieIntervento" bindValue="codice" bindLabel="descrizione" placeholder="Seleziona tipologia "
        formControlName="tipologiaIntervento">
      </ng-select>
    </div>
    <div class="col-md-6 mb-3">
      <label for="validationDefault06">RUP/Referente</label>
      <ng-select [items]="soggettiRespArray" bindValue="id" bindLabel="label" placeholder="Seleziona rup "
        formControlName="rup">
      </ng-select>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-6 mb-3">
      <label for="validationDefault06">CUP</label>
      <input formControlName="cup" type="text" maxlength="15" class="form-control" id="validationDefault06" placeholder="Inserisci il cup">
    </div>
    <div class="col-md-6 mb-3">
      <label for="validationDefault06">Importo</label>
      <input formControlName="importo" type="text" [imask]="maskCodice"
            [unmask]="true" class="form-control" placeholder="Inserisci l'importo" [ngClass]="{
              'form-control': true
      }" />
    </div>
  </div>

  <button class="btn btn-info btn-block bold" type="button" [disabled]="checkDisableSearch()" (click)="onSubmit()">Cerca
    <i class="fas fa-search pointer" style="color: #fff; font-size: 18px;"></i>
  </button>
</form>

<br *ngIf="loadComponent"/>

<interventi-component *ngIf="loadComponent"
  [filters]="filters"
  (disableSearchEmitter)="setDisableSearchButton($event)"></interventi-component>

<hr *ngIf="loadComponent">
