import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { WebSocketApiService } from './services/web-socket-api.service';
import { NotificationService } from './services/notification.service';
import { InitService } from './modules/inframob/services/init.service';
import { AuthService } from './services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
    title = 'Gestione Lavori Pubblici';

    private notificationsSubscription = null;
    constructor(
        private router: Router
        , private webSocketApiService: WebSocketApiService
        , private notificationService:NotificationService
        , private appLoadService: InitService
        , private authservice: AuthService
    ) {
        if (false){
        this.webSocketApiService.connect();
        //this.webSocketApiService.send('some message');
        this.notificationsSubscription = this.webSocketApiService.dataUpdates$().subscribe(
            x=>{console.log(x);this.notificationService.manageChanges(x);}
            ,err=>console.log(err)
            ,()=>console.log('complete')
            );
        }
        this.authservice.currentUser.subscribe(user => {
            if (user) {
                this.appLoadService.initializeApp();
            }
        });
    }
    ngOnDestroy(): void {
        console.log('app.component.destroy');
        if (this.notificationsSubscription)this.notificationsSubscription.unsubscribe();
        this.webSocketApiService.closeConnection();
    }

    onActivate(event) {
        this.router.url === '/' ? window.scroll(0, 0) : null;
    }
}
