<div class="p-5 container-xl text-center" [hidden]="loadDataCounter<=0">
  <app-loading message="Caricamento in corso..."></app-loading>
</div>


<div class="position-sticky sticky-top  pt-4 pb-4 elemento-aggiorna shadow-custom"
  [ngClass]="{'sticky-top-custom-modal-detail':isInModal && isReadOnly,   'sticky-top-custom': !isInModal,'sticky-top-custom-modal': isInModal && !isReadOnly }" [hidden]="loadDataCounter>0">
  <div class=" row  justify-content-center ">
    <div class="text-center col-lg-2 col-md-2" [ngClass]="
        {
        ' offset-lg-3': !gotoBtnOptions || !gotoBtnOptions.show
        }
        " [hidden]="readOnlyForm || isReadOnly">
      <button [disabled]="loading" (click)="onSubmit()" class="btn btn-primary btn-block" type="button">
        Salva&nbsp;<i class="fas fa-save"></i>
        <img *ngIf="loading" class="pl-3 float-right"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      </button>
    </div>


    <div class=" text-center"
      [ngClass]="{ ' col-lg-4 col-md-6': !(gotoBtnOptions && gotoBtnOptions.showIcon) , 'col-lg-2' : gotoBtnOptions && gotoBtnOptions.showIcon}">
      <button (click)="gotoBackBtnClick()" *ngIf="gotoBtnOptions && gotoBtnOptions.show" [disabled]="loading"
        class="btn btn-secondary btn-block font-weight-bold"
        type="button">{{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}&nbsp;<i class="fas fa-times"
          *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i></button>
    </div>
  </div>

</div>
<!--***-->


<form [formGroup]="myGroup" novalidate [hidden]="loadDataCounter>0">
  <div class="col-lg-12 mt-5">
    <div class=" row" *ngIf="currentProject && currentProject.id">

      <div class="form-group col-sm-6 " [hidden]="true">
        <label for="id">
          <h5>Id:</h5>
        </label>
      </div>

      <div class="form-group col-sm-12 mt-3 " *ngIf="userIsManager() && showAbilitaMonitoraggioBtn">
        <label for="" class="">
          <h5 class="">
            Monitoraggio {{monitoraggioLabel}}
          </h5>

        </label>
        <div>
            <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="abilitato_check"
                [formGroup]="myGroup" formControlName="abilitato" (change)="changeMonitoraggioStatus($event)"
                  >
                <label class="custom-control-label" for="abilitato_check"></label>

              </div>




          <!--<div class="form-check  form-check-inline">

            <input [formGroup]="myGroup" id="abilitato1" formControlName="abilitato" type="radio" value="1"
              class="form-check-input" (click)="changeMonitoraggioStatus($event)" />

            <label for="abilitato1" class="form-check-label">Si</label>
          </div>
          <div class="form-check form-check-inline">
            <input [formGroup]="myGroup" id="abilitato2" formControlName="abilitato" type="radio" value="0"
              class="form-check-input" (click)="changeMonitoraggioStatus($event)" />
            <label for="abilitato2" class="form-check-label">No</label>
          </div>-->
        </div>
      </div>

      <div class="form-group  col-sm-12 mt-3 ">
        <label for="codice">
          <h5>Codice:&nbsp;</h5>
        </label>
        <input readonly [formGroup]="myGroup" maxlength="255" formControlName="codice" type="text"
          class="form-control" />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 mt-3 ">
        <label for="descrizione">
          <h5 class="mandatory">Descrizione:</h5>
        </label>
        <textarea readonly [formGroup]="myGroup" formControlName="descrizione" type="text" class="form-control"
          placeholder="" rows="3"></textarea>
      </div>
    </div>
  </div>

  <div class="col-lg-12 mt-5">
    <hr>
  </div>

 <!--classificazioni-->
  <div class="col-lg-12 mt-4">
    <div class="row">
      <div class="col-lg-12">
        <div class="row justify-content-between ">
          <div class="col-lg-6 col-sm-6 col-6 mt-3 ">
            <h5 for="" class="">Classificazioni</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 mt-3 table-responsive-lg ">

        <table class="table table-hover table-colore ">
          <thead>
            <tr>
              <th scope="col ">codice</th>
              <th scope="col ">tipo</th>
              <th scope="col " class="casella-descrizione-tx ">descrizione</th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngIf="currentProject && currentProject[ 'classificazioni'] && currentProject[ 'classificazioni'].length && currentProject[ 'classificazioni'].length>0">
              <tr *ngFor="let item of currentProject.classificazioni">
                <td>{{item.codice}}</td>
                <td>{{item.tipoClassificazione.tipo?item.tipoClassificazione.tipo:item.tipoClassificazione}}</td>
                <td>{{item.descrizione}}</td>
              </tr>
            </ng-container>
            <tr
              *ngIf="!(currentProject && currentProject.classificazioni && currentProject.classificazioni.length && currentProject.classificazioni.length>0)">
              <td colspan="3">Nessuna classificazione selezionata</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!--fine classificazioni-->

  <div class="col-lg-12 mt-5">
    <hr>
  </div>

  <!--sezione Interazione con siti rete Natura 2000-->
  <div class="col-lg-12 mt-4">
      <div class="row justify-content-between">
        <div class="col-lg-6 col-sm-6 col-6 mt-3">
          <h5 for="" class="">Siti Rete Natura 2000</h5>
        </div>
        <div [hidden]="readOnlyForm || isReadOnly" class="col-lg-1 col-sm-2 col-3 text-center mt-3">
          <a href="javascript:;;" class="bottone-inserisci" (click)="openModaleSitoReteNatura();"><i
              class="fas fa-plus"></i></a>
        </div>
      </div>
    <div class="row">
      <div class="col-lg-12 mt-3 table-responsive-lg">
        <table class="table table-hover table-colore">
          <thead class="">
            <th>tipologia </th>
            <th>codice </th>
            <th class="casella-descrizione-tx">descrizione</th>
            <th>distanza</th>
            <th class="casella-icona-tx">elimina</th>
          </thead>
          <tbody>
            <ng-container *ngIf="sitiReteNaturaSelezionati && sitiReteNaturaSelezionati.length>0">
              <tr *ngFor="let item of sitiReteNaturaSelezionati">
                <td>{{item.tipologia}}</td>
                <td>{{item.codice}}</td>
                <td>{{item.nome}}</td>
                <td>{{item.distanza.nome}}</td>
                <td><a (click)="removeSitoReteNatura(item)" href="javascript:;;" data-toggle="tooltip"
                    data-placement="top" title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i>
                  </a>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="!(sitiReteNaturaSelezionati && sitiReteNaturaSelezionati.length >0)">
              <td colspan="4">Nessun sito selezionato</td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="submitted && sitiReteNaturaIsError" class="invalidFieldMessage">
          <div *ngIf="sitiReteNaturaIsError" class="invalid-feedback" style="display: block;">
            Selezionare almeno un sito
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--fine sezione Interazione con siti rete Natura 2000-->

  <div class="col-lg-12 mt-5">
    <hr>
  </div>

  <!--sezione Unità di misura dei prodotti previsti-->
  <div class="col-lg-12 mt-4">
    <div class="row">
      <div class="form-group col-sm-12 mt-3 ">
        <label for="">
          <h5 class="">Unità di misura dei prodotti previsti:</h5>
        </label>
        <span class="input-group-text"
          id="inputGroupPrepend">{{getParametriTecniciPrevisti()}} {{getUnitaDiMisura()}}</span>
        <!--<input readonly type="text" [imask]="maskValue" [unmask]="true" formControlName="parametriTecnici" class="form-control" />-->
      </div>
    </div>
  </div>
  <!--fine sezione Unità di misura dei prodotti previsti-->

  <div class="col-lg-12 mt-5">
    <hr>
  </div>

  <!--sezione livello di progettazione-->
    <!--fasi-->
    <div class="col-lg-12 mt-4 ">
      <div class="row justify-content-between ">
        <div class="col-lg-6 col-sm-6 col-6 mt-3 ">
          <h5 for="" class="">Livello di Progettazione - Fasi</h5>
        </div>
      </div>
      <div class="row">
      <div class="col-lg-12 mt-3 table-responsive-lg ">

        <table class="table table-hover table-colore ">
          <thead>
            <tr>
              <th scope="col ">tipologia</th>
              <th scope="col ">data prevista inizio</th>
              <th scope="col ">data inizio</th>
              <th scope="col ">data prevista fine</th>
              <th scope="col ">data fine</th>
              <th scope="col " class="casella-descrizione-tx ">soggetti responsabili</th>
              <th scope="col ">note</th>
              <th scope="col ">flusso di cassa</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="fasi && fasi.length>0">
              <tr *ngFor="let item of fasi">
                <td>{{item.tipologia}}</td>
                <td>{{printValueAsDate(item.dataPrevistaInizio)}}</td>
                <td>{{printValueAsDate(item.dataInizio)}}</td>
                <td>{{printValueAsDate(item.dataPrevistaFine)}}</td>
                <td>{{printValueAsDate(item.dataFine)}}</td>
                <td>
                  <div *ngFor="let soggetto of item.soggettiResponsabili">
                    <span>{{ soggetto.cognome}} {{soggetto.nome }}</span>
                  </div>
                </td>
                <td>
                  <i class="fas font-size-18 text-secondary" [ngClass]="{
                      ' fa-comment-slash': !item.note,
                      ' fa-comment text-info help px-1': item.note
                    }" popover="{{item.note}}" placement="left" triggers="mouseenter:mouseleave"></i>
                </td>
                <td>
                  <i class="fas fa-euro-sign font-size-18 " [ngClass]="{
                      ' text-secondary': !item.flussoDiCassa,
                      ' text-success text-info help px-1': item.flussoDiCassa
                    }"
                    popover="{{item.flussoDiCassa && item.previsioneDiImporto ? printValueAsCurrency(item.previsioneDiImporto):''}}"
                    placement="left" triggers="mouseenter:mouseleave"></i>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="!(fasi && fasi.length>0)">
              <td colspan="8">Nessuna fase selezionata</td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
    </div>
    <!--fine fasi-->
  <!--fine sezione livello di progettazione-->

  <div class="col-lg-12 mt-5">
    <hr>
  </div>

  <!--sezione stato di attuazione-->
    <div class="col-lg-12 mt-4 ">
      <div class="row">
        <div class="form-group col-sm-12 mt-3 ">
          <label for="statoAttuazione">
            <h5 class="">Stato di attuazione:</h5>
          </label>
          <div [ngClass]="{ 'is-invalid': submitted && f.statoAttuazione.errors }">
            <ng-select [items]="statiAttuazione" bindValue="id" bindLabel="descrizione" placeholder="Seleziona"
              formControlName="statoAttuazione">
            </ng-select>
          </div>
          <div *ngIf="submitted && f.statoAttuazione.errors" class="invalid-feedback" style="display: block;">
            <div *ngIf="f.statoAttuazione.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>
      </div>
      <div class=" row">
        <div class="form-group col-sm-12 mt-3 ">
          <label for="statoAttuazioneNote">
            <h5 class="">Note:</h5>
          </label>
          <textarea [formGroup]="myGroup" formControlName="statoAttuazioneNote" type="text" class="form-control"
            placeholder="" rows="3"></textarea>
        </div>
      </div>
    </div>
  <!--fine sezione stato di attuazione-->

  <div class="col-lg-12 mt-5">
    <hr>
  </div>

  <!--sezione durata prevista-->
  <div class="col-lg-12 mt-4 ">
    <div class="row">
      <div class="form-group col-sm-12 mt-3 ">
        <label for="">
          <h5 class="">Durata prevista:&nbsp;</h5>
        </label>
        <span>{{getDurataPrevista()}} giorni</span>
      </div>
    </div>
  </div>
  <!--fine sezione durata prevista-->


  <!--sezione durata effettiva-->
  <div class="col-lg-12 mt-4 ">
    <div class="row">
      <div class="form-group col-sm-12 mt-3 ">
        <label for="">
          <h5 class="">Durata effettiva:&nbsp;</h5>
        </label>
        <span>{{getDurataEffettiva()}} giorni</span>
      </div>
    </div>
  </div>
  <!--fine sezione durata effettiva-->

  <div class="col-lg-12 mt-5">
    <hr>
  </div>

  <!--sezione Finanziamenti -->
  <div class="col-lg-12 mt-4 ">
    <div class="row justify-content-between ">
      <div class="col-lg-6 col-sm-6 col-6 mt-3 ">
        <h5 for="" class="">Finanziamenti</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 mt-3 table-responsive-lg ">

        <table class="table table-hover table-colore ">
          <thead>
            <tr>
              <th scope="col ">codice</th>
              <th scope="col ">finanziamento</th>
              <th scope="col ">importo</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="finanziamenti && finanziamenti.length>0">
              <tr *ngFor="let item of finanziamenti">
                <td>{{item.finanziamento.codice}}</td>
                <td>{{item.finanziamento.titolo}}</td>
                <td>
                  <table class="w-100 table table-borderless" id="detail_importi_{{item.id}}">
                    <tr class="collapse trdetail" *ngFor="let val of sortImporti(item.annualita)">

                      <td class="pt-0 pb-1 pl-0 pr-1">{{val.anno}}:</td>
                      <td class="pt-0 pb-1 text-0083e0 pl-0">{{printValueAsCurrency(val.importo)}}</td>
                    <tr>
                      <td class="pt-0 pb-1 pl-0 pr-1">
                        <a class="show-detail-anchor collapse show" data-toggle="collapse"
                          attr.data-target="#detail_importi_{{item.id}} .trdetail" data-placement="top"
                          title="mostra dettaglio"><i class="fas fa-list-ul pointer"></i></a>
                      </td>
                      <td class="pt-0 pb-1 pl-0">{{ printValueAsCurrency(item.importo)}}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="!(finanziamenti && finanziamenti.length>0)">
              <td colspan="3">Nessun finanziamento selezionato</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!--fine sezione Finanziamenti -->

  <div class="col-lg-12 mt-5">
    <hr>
  </div>

  <!--sezione costi previsti-->
  <!--importi-->
  <div class="col-lg-12 mt-4 ">
    <div class="row justify-content-between ">
      <div class="col-lg-6 col-sm-6 col-6 mt-3 ">
        <h5 for="" class="">Costi previsti - Annualità</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 mt-3 table-responsive-lg ">
        <table class="table table-hover table-colore ">
          <thead>
            <tr>
              <th scope="col ">anno</th>
              <th scope="col ">importo</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="importi && importi.length>0">
              <tr *ngFor="let item of importi">
                <td>{{item.annoTesto}}</td>
                <td>{{printValueAsCurrency(item.importo)}}</td>
              </tr>
            </ng-container>
            <tr *ngIf="!(importi && importi.length>0)">
              <td colspan="2">Nessun importo selezionato</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</div>
  <!--fine importi-->
  <!--fine sezione costi previsti-->

  <div class="col-lg-12 mt-5">
    <hr>
  </div>

  <!--sezione costi effettivi-->
  <div class="col-lg-12 mt-4 ">
    <div class="row">
      <div class="form-group col-sm-12 mt-3 ">
        <label for="">
          <h5 class="">Costi effettivi:&nbsp;</h5>
        </label>
        <span>{{getCostiEffettivi()}}</span>
      </div>
    </div>
  </div>
  <!--fine sezione costi effettivi-->

  <div class="col-lg-12 mt-5">
    <hr>
  </div>

  <!--sezione Prodotti della misura realizzati-->
  <div class="col-lg-12 mt-4 mb-5">
    <div class="row">
      <div class="form-group col-sm-12 mb-4 mt-3">
        <label for="">
          <h5 class="">Prodotti della misura realizzati:</h5>
        </label>
        <span class="input-group-text"
          id="inputGroupPrepend">{{getParametriTecniciEffettivi()}} {{getUnitaDiMisura()}}</span>
      </div>
    </div>
  </div>
  <!--fine sezione Prodotti della misura realizzati-->


</form>
<!-- -------fine form--------- -->

<ng-template #modalAggiungiRete>
  <div class="modal-header text-center">
    <h4 class="modal-title pull-left">Seleziona Sito Rete Natura 2000</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalReteNatura()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <section class="fascia-grey-login" style="margin:0px;padding-top:20px">
      <div class="container">
        <div class="row clearfix">
          <div class="col-lg-12 ">
            <form [formGroup]="frmReteNatura" novalidate>
              <div class="form-registrazione">
                <div class="user-type">
                  <h5><label for="tipologia" class="mandatory">Tipologia:</label></h5>

                  <div class="form-group"
                    [ngClass]="{ 'is-invalid': submittedFrmReteNatura && frmReteNatura.controls['tipologia'].errors }">
                    <ng-select [formGroup]="myGroup" formControlName="tipologia" [items]="tipologie" bindValue="key"
                      bindLabel="value" placeholder="Seleziona tipologia" (change)="changeTipologiaRete($event)">
                    </ng-select>
                  </div>
                  <div *ngIf="submittedFrmReteNatura && frmReteNatura.controls['tipologia'].errors" class="invalidFieldMessage"
                    style="display: block;">
                    <div *ngIf="frmReteNatura.controls['tipologia'].errors" class="invalid-feedback"
                      style="display: block;">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
                <div class="user-type">
                  <h5> <label for="sitoReteNatura" class="mandatory">Sito rete natura 2000:</label></h5>

                  <div class="form-group"
                    [ngClass]="{ 'is-invalid': submittedFrmReteNatura && frmReteNatura.controls['sitoReteNatura'].errors }">
                    <ng-select formControlName="sitoReteNatura" [items]="sitiReteNatura"
                      bindValue="id" bindLabel="label" placeholder="Seleziona ">
                    </ng-select>
                  </div>
                  <div *ngIf="submittedFrmReteNatura && frmReteNatura.controls['sitoReteNatura'].errors" class="invalidFieldMessage"
                    style="display: block;">
                    <div *ngIf="frmReteNatura.controls['sitoReteNatura'].errors" class="invalid-feedback"
                      style="display: block;">
                      campo obbligatorio
                    </div>
                  </div>
                </div>

                <div class="user-type">
                    <h5> <label for="distanza" class="mandatory">Distanza dell'intervento dal sito:</label></h5>

                    <div class="form-group"
                      [ngClass]="{ 'is-invalid': submittedFrmReteNatura && frmReteNatura.controls['distanza'].errors }">
                      <ng-select [items]="distanze" bindValue="id" bindLabel="nome" placeholder="Seleziona"
                            formControlName="distanza">
                        </ng-select>
                    </div>
                    <div *ngIf="submittedFrmReteNatura && frmReteNatura.controls['distanza'].errors" class="invalidFieldMessage"
                      style="display: block;">
                      <div *ngIf="frmReteNatura.controls['distanza'].errors" class="invalid-feedback"
                        style="display: block;">
                        campo obbligatorio
                      </div>
                    </div>
                  </div>


                <button class="btn btn-lg btn-primary btn-block" type="button" (click)="addSitoReteNatura()"
                  style="margin:15px 0">
                  Aggiungi
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</ng-template>
