import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from "src/app/services/auth.service";
import { Router } from '@angular/router';
@Injectable()
export class AuthenticationIAMService {


     makeRandom = ()=> {
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]\=-)(*&^%$#@!~`";
        const lengthOfCode = 40;
        let text = "";
        for (let i = 0; i < lengthOfCode; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
          return text;
      }

      makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }
      
      

      async sha256(plain,callBack) { // returns promise ArrayBuffer
        const encoder = new TextEncoder();
        const data = encoder.encode(plain);
        return await  window.crypto.subtle.digest('SHA-256', data).then(
            x=>callBack(x)
        );
      }

      base64urlencode(a) {
        // Convert the ArrayBuffer to string using Uint8 array.
        // btoa takes chars from 0-255 and base64 encodes.
        // Then convert the base64 encoded to base64url encoded.
        // (replace + with -, replace / with _, trim trailing =)
        return btoa(String.fromCharCode.apply(null, new Uint8Array(a)))
          .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
      }


    constructor(
        /*private oauthService: OAuthService,    */
        private http: HttpClient   
        ,private authService: AuthService
        ,private router: Router
      ) {
        this.configureWithNewConfigApi();        
      }


      private configureWithNewConfigApi() {        
      }

      public login() {

        const apiUrl = `${AppConfigService.env.api_endpoint}api/public/iam/loginUrl`;
        this.http.get
            (
                `${apiUrl}?&state=&codeVerifier=`).subscribe(
                (x:any)=>{
                    const url = x.url;
                    console.log(url);
                    
                    const callBack =(x)=>{
                        const state = this.makeid(50);
                        localStorage.setItem("state",state);
                        x = this.base64urlencode(x);
            
                        const params = new HttpParams()
                        .set('state', state)
                        .set('code_challenge', x)
                        ;
                        const url1 = `${url}&${params.toString()}`;
                        console.log(url1);
                        location.href=url1;                                   
                    }
                    const code_verifier = this.makeid(50);
        
                    localStorage.setItem("code_verifier",code_verifier);
                    
                    this.sha256(code_verifier,callBack);      




                }
            );   


        

          
      }

      public async  getToken(code:string,state:string){
        const myState = localStorage.getItem("state");
        const code_verifier = localStorage.getItem("code_verifier");
        localStorage.removeItem("state");
        localStorage.removeItem("code_verifier");
        if (state !== myState)return;
        console.log(`got code from iam: ${code}`);
        console.log(`got codeVerifier from iam: ${code_verifier}`);
        if (code){
            const apiUrl = `${AppConfigService.env.api_endpoint}api/public/iam/`;

            return this.http.get
            (`${apiUrl}?code=${code}&state=${state}&codeVerifier=${code_verifier}`)           
            .subscribe((data:any)=>{
                console.log(data);
                if (data && data.access_token) {
                    localStorage.setItem("isIamToken","1");
                    this.authService.saveToken(data.access_token);
                    this.authService.fetchPermissionsByToken(data.access_token);
                    this.authService.getUserData();
                    this.router.navigate([""]);
                    return true;

                }
            }
            ,error=>console.log(error));           
        }
      }
}