import { Section } from "./section";

export class UserSection {
  idUser: number;
  idSection: number;
  name: string;
  insert: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
  section: Section;
}
