<div class="modal-header text-center">
  <h4 class="modal-title pull-left">Seleziona geolocalizzazione</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <section class="fascia-grey-login" style="margin: 0px; padding-top: 20px">
    <div class="container">
      <div class="row clearfix">
        <div class="col-lg-12">
          <form [formGroup]="myGroup" novalidate>
            <div class="form-registrazione">
              <div class="user-type">
                <h5>
                  <label for="provincia" class="mandatory">Provincia:</label>
                </h5>

                <div
                  class="form-group"
                  [ngClass]="{ 'is-invalid': submitted && f.province.errors }"
                >
                  <ng-select
                    [formGroup]="myGroup"
                    formControlName="province"
                    [items]="province"
                    bindValue="key"
                    bindLabel="value"
                    (change)="getMunicipality()"
                    placeholder="Seleziona la provincia"
                  >
                  </ng-select>
                </div>
                <div
                  *ngIf="submitted && f.province.errors"
                  class="invalidFieldMessage"
                  style="display: block"
                >
                  <div
                    *ngIf="f.province.errors"
                    class="invalid-feedback"
                    style="display: block"
                  >
                    Provincia &egrave; obbligatorio
                  </div>
                </div>
              </div>
              <div class="user-type">
                <h5><label for="comune" class="mandatory">Comune:</label></h5>

                <div
                  class="form-group"
                  [ngClass]="{
                    'is-invalid': submitted && f.municipality.errors
                  }"
                >
                  <ng-select
                    [formGroup]="myGroup"
                    formControlName="municipality"
                    [items]="municipality"
                    bindValue="key"
                    bindLabel="value"
                    placeholder="Seleziona il comune"
                  >
                  </ng-select>
                </div>
                <div
                  *ngIf="submitted && f.municipality.errors"
                  class="invalidFieldMessage"
                  style="display: block"
                >
                  <div
                    *ngIf="f.municipality.errors"
                    class="invalid-feedback"
                    style="display: block"
                  >
                    Comune &egrave; obbligatorio
                  </div>
                </div>
              </div>
              <div class="user-type">
                <div class="row justify-content-between">
                  <h5 class="ml-3">
                    <label for="specificaAggregata" class="mandatory"
                      >Dettaglio tipologia:</label
                    >
                  </h5>
                  <div class="col-lg-1 col-sm-2 col-3 text-center">
                    <a
                      class="bottone-inserisci"
                      (click)="openModalDettaglioTipologia($event)"
                      ><i class="fas fa-plus"></i
                    ></a>
                  </div>
                </div>

                <div
                  class="form-group"
                  [ngClass]="{
                    'is-invalid': submitted && f.specificaAggregata.errors
                  }"
                >
                  <!-- <input class="form-control" [formGroup]="myGroup" formControlName="specificaAggregata" maxlength="255" type="text" name="specificaAggregata" placeholder="Seleziona il dettaglio tipologia" /> -->
                  <ng-select
                    [formGroup]="myGroup"
                    formControlName="specificaAggregata"
                    [items]="aggregato"
                    bindValue="id"
                    bindLabel="name"
                    placeholder="Seleziona il dettaglio tipologia"
                  >
                  </ng-select>
                </div>
                <div
                  *ngIf="submitted && f.specificaAggregata.errors"
                  class="invalidFieldMessage"
                  style="display: block"
                >
                  <div
                    *ngIf="f.specificaAggregata.errors"
                    class="invalid-feedback"
                    style="display: block"
                  >
                    Dettaglio tipologia &egrave; obbligatorio
                  </div>
                </div>
              </div>
              <div *ngIf="keySelected != '03'" class="user-type">
                <h5>
                  <label
                    for="quantita"
                    [ngClass]="keySelected == '01' ? 'mandatory' : ''"
                    >Quantità:</label
                  >
                </h5>

                <div
                  class="form-group"
                  [ngClass]="{ 'is-invalid': submitted && f.quantity.errors }"
                >
                  <input
                    class="form-control"
                    [formGroup]="myGroup"
                    formControlName="quantity"
                    maxlength="255"
                    type="text"
                    [imask]="quantity"
                    placeholder="Seleziona la quantita"
                  />
                </div>
                <div
                  *ngIf="submitted && f.quantity.errors"
                  class="invalidFieldMessage"
                  style="display: block"
                >
                  <div
                    *ngIf="f.quantity.errors"
                    class="invalid-feedback"
                    style="display: block"
                  >
                    Quantità &egrave; obbligatorio
                  </div>
                </div>
              </div>
              <div class="user-type">
                <h5><label for="indirizzo">Indirizzo:</label></h5>

                <div
                  class="form-group"
                  [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                >
                  <input
                    class="form-control"
                    [formGroup]="myGroup"
                    formControlName="address"
                    maxlength="255"
                    type="text"
                    placeholder="Seleziona l'indirizzo"
                  />
                </div>
                <div
                  *ngIf="submitted && f.address.errors"
                  class="invalidFieldMessage"
                  style="display: block"
                >
                  <div
                    *ngIf="f.address.errors"
                    class="invalid-feedback"
                    style="display: block"
                  >
                    Indirizzo
                  </div>
                </div>
              </div>
              <button
                class="btn btn-lg btn-primary btn-block"
                type="button"
                (click)="add()"
                style="margin: 15px 0"
              >
                Aggiungi
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #templateModalDettaglioTipologia>
  <div class="modal-header text-center">
    <h4 class="modal-title pull-left">
      Inserisci un nuovo dettaglio tipologia
    </h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModalDettaglioTipologia()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-lg-12">
      <form
        class="row"
        [formGroup]="dettaglioTipologiaForm"
        (ngSubmit)="onSubmit()"
      >
        <input
          type="hidden"
          [formGroup]="dettaglioTipologiaForm"
          formControlName="id"
        />
        <div class="form-group col-lg-6" [hidden]="true">
          <h5 class="mandatory">
            <label for="natura"> Natura intervento: </label>
          </h5>

          <div
            [ngClass]="{
              'is-invalid': submittedModalDetailsInt && d.natura.errors
            }"
          >
            <ng-select
              [items]="naturaList"
              bindValue="key"
              bindLabel="value"
              placeholder="Seleziona natura"
              (change)="setNaturaIntervento($event)"
              formControlName="natura"
            >
            </ng-select>
          </div>
          <div
            *ngIf="submittedModalDetailsInt && d.natura.errors"
            class="invalid-feedback"
            style="display: block"
          >
            <div *ngIf="d.natura.errors.required">Campo obbligatorio</div>
          </div>
        </div>
        <div class="form-group col-lg-6" [hidden]="true">
          <h5 class="mandatory">
            <label for="tipologiaIntervento">Tipologia intervento:</label>
          </h5>

          <div
            [ngClass]="{
              'is-invalid':
                submittedModalDetailsInt && d.tipologiaIntervento.errors
            }"
          >
            <ng-select
              [items]="types"
              bindValue="key"
              bindLabel="value"
              placeholder="Seleziona tipologia "
              formControlName="tipologiaIntervento"
            >
            </ng-select>
          </div>
          <div
            *ngIf="submittedModalDetailsInt && d.tipologiaIntervento.errors"
            class="invalid-feedback"
            style="display: block"
          >
            <div *ngIf="d.tipologiaIntervento.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>

        <div class="form-group col-lg-6">
          <h5><label for="codice" class="">Codice:</label></h5>
          <input
            [formGroup]="dettaglioTipologiaForm"
            formControlName="codice"
            maxlength="255"
            type="text"
            value=""
            class="form-control"
            placeholder="Inserisci il codice"
            [ngClass]="{
              'form-control': true,
              'is-invalid': submittedModalDetailsInt && d.codice.errors
            }"
          />
          <div
            *ngIf="submittedModalDetailsInt && d.codice.errors"
            class="invalid-feedback"
            style="display: block"
          >
            <div *ngIf="d.codice.errors.required">Campo obbligatorio</div>
          </div>
        </div>

        <div class="form-group col-lg-6">
          <h5>
            <label for="name" class="mandatory">Dettaglio tipologia:</label>
          </h5>
          <input
            [formGroup]="dettaglioTipologiaForm"
            formControlName="name"
            maxlength="255"
            type="text"
            value=""
            class="form-control"
            placeholder="Inserisci il dettaglio tipologia"
            [ngClass]="{
              'form-control': true,
              'is-invalid': submittedModalDetailsInt && d.name.errors
            }"
          />
          <div
            *ngIf="submittedModalDetailsInt && d.name.errors"
            class="invalid-feedback"
            style="display: block"
          >
            <div *ngIf="d.name.errors.required">Campo obbligatorio</div>
          </div>
        </div>
        <button
          class="btn btn-lg btn-primary btn-block ml-3 mr-3"
          type="submit"
          style="margin: 15px 0"
        >
          Aggiungi
        </button>
      </form>
    </div>
  </div>
</ng-template>
