import {
    Component,
    Input,
    OnInit,
    ChangeDetectionStrategy,
    Output,
    EventEmitter
} from "@angular/core";

import { CurrencyPipe } from "@angular/common";
import { Observable } from "rxjs";
import * as moment from "moment";
import * as FileSaver from "file-saver";
import { ToastService } from "src/app/services/toast.service";
import {
    DashboardService,
} from "../../../services";
import { AuthService } from "src/app/services";

@Component({
    selector: "dashboard-finanziaria-details-table-component",
    templateUrl: "./dashboard-finanziaria-details-table.component.html",
    styleUrls: ["./dashboard-finanziaria-details-table.component.css"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardFinanziariaDetailsTableComponent implements OnInit {
    getValore(item, anno) {
        if (!item) return 0;
        //console.log(`getvalore di ${item.codice} con anno = ${anno}`);
        let importo = item.importi.find((x) => x.anno == anno);
        return importo ? importo.importo : 0;
    }
    getFinanziato(item, anno) {
        //console.log(`getfinanziato di ${item.codice} con anno = ${anno}`);
        if (!item) return 0;
        /*if(item.codice =='PRJ0000013'){
                console.log(`getfinanziato di ${item.codice} con anno = ${anno}`);
                console.log(`getfinanziato di ${item.finanziamenti.length} con anno = ${anno}`);
            }*/
        let somma = 0;
        item.finanziamenti.forEach((fin) => {
            fin.annualita
                .filter((x) => x.anno == anno)
                .forEach((importo) => {
                    somma += importo ? importo.importo : 0;
                });
        });
        return somma;
    }
    getTotaleValore(item) {
        if (!item || !this.tabellaDettaglioObjArrayAnni) return 0;
        let somma = 0;
        this.tabellaDettaglioObjArrayAnni.forEach((anno) => {
            somma += this.getValore(item, anno);
        });
        return somma;
    }

    getTotaleFinanziato(item) {
        if (!item || !this.tabellaDettaglioObjArrayAnni) return 0;
        let somma = 0;
        this.tabellaDettaglioObjArrayAnni.forEach((anno) => {
            somma += this.getFinanziato(item, anno);
        });
        return somma;
    }

    @Input()
    tabellaDettaglioObj: any; // Observable<any>;

    @Input()
    observable$: Observable<any>;

    @Input()
    filterObjectForDetailTable: any;

    constructor(
        private dashboardService: DashboardService,
        private toastr: ToastService,
        private authService: AuthService
    ) { }


    ngOnInit(): void {

    }

    get tabellaDettaglioObjArrayAnni(): any {
        return this.currentObject.anni;
    }
    get currentObject(): any {
        if (this.observable$) return this.observable$;
        if (this.tabellaDettaglioObj) return this.tabellaDettaglioObj;
    }

    printValueAsCurrency(value) {
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = typeof value === "number" ? value : value.replace(",", ".").trim();
        const format = "1.2-2";
        const currency = "€";
        const currentLocale: string = "it";
        return new CurrencyPipe(currentLocale).transform(
            value,
            "EUR",
            currency,
            format,
            "it-IT"
        );
    }
    printCodice(cod) {
        console.log(cod);
        return cod;
    }

    trackByFn(index, item) {
        return index;
    }


    @Output()
    onExportExcelClick: EventEmitter<boolean> = new EventEmitter();

    @Input()
    //doingExportExcel = false;
    doingExportExcel$: Observable<any>;

    get doingExportExcel(): any {
        return this.doingExportExcel$;
    }



    isAdmin() {
        return this.authService.isAdmin();
    }
    isGr34() {
        return this.authService.isGr34();
    }
    isGr34OrAdmin(){
        return this.isAdmin() || this.isGr34();
    }

    exportDetailTable() {
        this.onExportExcelClick.emit(true);
        return;
        this.dashboardService
            .getDashboardFinanziariaDetailTableExport({
                filterProjectId: this.filterObjectForDetailTable.progettoId,
                filterSimpleInterventionId: this.filterObjectForDetailTable.interventoId,
                filterStatus: this.filterObjectForDetailTable.validationStatusCode,
                filterAreeGestionaliDirezioneEsterne: this.filterObjectForDetailTable.idAreaTematica,
                filterTipoClassificazione: this.filterObjectForDetailTable.idTipoClassificazione,
                filterSoggettoGiuridico: this.filterObjectForDetailTable.idSoggetto,
                filterProvinciaSoggettoGiuridico: this.filterObjectForDetailTable.provinciaSoggetto,
                filterTemaProprietario: this.filterObjectForDetailTable.idTema,
                filterFrom: this.filterObjectForDetailTable.annoFrom,
                filterTo: this.filterObjectForDetailTable.annoTo,
                filterTipologiaIntervento: this.filterObjectForDetailTable.tipologiaIntervento
            })
            .subscribe(
                (res) => {
                    // this.doingExportExcel = false;
                    let filename = `esportazione_sintesi_dettaglio_${moment(
                        new Date()
                    ).format("YYYY_MM_DDTHH_mm_ss")}.xls`;
                    FileSaver.saveAs(res.body, `${filename}`);
                    //this.doingExportExcel = false;
                },
                (error) => {
                    //   this.doingExportExcel = false;
                    this.toastr.error(
                        `Errore: ${error.error.message
                            ? error.error.message
                            : error.error.error_description
                                ? error.error.error_description
                                : error.error
                        }`,
                        null,
                        {
                            timeOut: 2000,
                            disableTimeOut: false,
                        }
                    );
                    //console.log("error");
                    // this.doingExportExcel = false;
                }
            );
    }

}
