<div class="container-fluid px-0 bg-celeste" [formGroup]="myForm">
    <div class="text-center p-5 position-absolute top-50 start-50 translate-middle bg-white border border-dark rounded shadow"
    style="z-index: 999999999" *ngIf="showLoading">
        <app-loading message="Caricamento in corso..."></app-loading>
    </div>

      <app-header-dashboard></app-header-dashboard>
    <!-- menu -->
    <div class="row pb-3 pt-1 bg-0083e0" style="z-index: 13000">
      <div class="col-xl-3 col-lg-6 col-md-6 pt-1" style="z-index: 13000">
        <label for="importoFinanziamento">
          <h6 class="mb-0 text-white bold">
            {{isGr34() || isAdmin()?'Progettualità/fabbisogni - Interventi - Finanziamenti':'Progettualità - Interventi - Finanziamenti'}}
          </h6>
        </label>
        <ng-select
          [items]="mainTypesCombo"
          bindValue="k"
          bindLabel="v"
          placeholder="{{isGr34() || isAdmin()?'Seleziona Progettualità/fabbisogni - Interventi - Finanziamenti':'Seleziona Progettualità - Interventi - Finanziamenti'}}"
          formControlName="main"
          (change)="setMain($event)"

        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.v }}">{{ item.v }}</div>
          </ng-template>
        </ng-select>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6 pt-1">
        <label for="importoFinanziamento">
          <h6 class="mb-0 text-white bold">
            {{isAdmin() || isGr34()?'Progettualità/Fabbisogno - Interventi - Finanziamento':'Progettualità - Interventi - Finanziamento'}}
            <small>(min 3 caratteri)</small>
          </h6>
        </label>
        <app-completer
          [cpOptions]="completerParams"
          (onSelectEmitter)="setInterventionCompleter($event)"
          (keyup)="onChangeInterventionCompleter($event)"
          [disabled]="!myForm.disabled"
        >
        </app-completer>
      </div>

      <div class="col-xl-3 col-lg-5 col-md-5 pt-1">
        <label for="direction">
          <h6 class="mb-0 text-white bold">
            Aree gestionali direzione e/o esterne
          </h6>
        </label>
        <ng-select
          [items]="areeTematicheCombo"
          bindValue="id"
          bindLabel="descrizione"
          formControlName="direction"
          placeholder="Seleziona area gestionale direzione e/o esterna "
          (change)="setAreaTematica($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
          </ng-template>
        </ng-select>
      </div>

      <div class="col-xl-2 col-lg-4 col-md-4 pt-1">
        <label for="importoFinanziamento ">
          <h6 class="mb-0 text-white bold">Stato Avanzamento Fasi</h6>
        </label>
        <ng-select
          [items]="avanzamentoCombo"
          bindValue="k"
          bindLabel="v"
          formControlName="statoFasi"
          placeholder="Seleziona stato di avanzamento fasi "
          (change)="setStatoFasi($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.v }}">{{ item.v }}</div>
          </ng-template>
        </ng-select>
      </div>


      <div class="col-xl-3 col-lg-3 col-md-3 pt-1">
          <label for="tipologiaFaseFase">
            <h6  class="mb-0 text-white bold">Tipologia fase</h6>
          </label>
            <ng-select [formGroup]="myForm" formControlName="tipologiaFaseFase" [items]="tipologieFasiFasi"
               placeholder="Seleziona la tipologia della fase"
               (change)="tipologiaFaseFaseChangeValue($event)"
               >
              <ng-template ng-option-tmp let-item="item">
                <div title="{{ item }}">
                  {{ item }}
                </div>
              </ng-template>
            </ng-select>

      </div>

      <div class="col-xl-3 col-lg-3 col-md-3 pt-1">
        <label for="tipologiaFaseTitolo">
            <h6  class="mb-0 text-white bold">Tipologia fase - titolo</h6>
          </label>
            <ng-select [formGroup]="myForm" formControlName="tipologiaFaseTitolo" [items]="TipologieFasiTitoli"
               placeholder="Seleziona il titolo della fase"
               (change)="tipologiaFaseTitoloChangeValue($event)"
               >
              <ng-template ng-option-tmp let-item="item">
                <div title="{{ item }}">
                  {{ item }}
                </div>
              </ng-template>
            </ng-select>

      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 pt-1">
        <label for="tipologia">
            <h6  class="mb-0 text-white bold">Tipologia fase - descr.</h6>
          </label>
          <ng-select [formGroup]="myForm" formControlName="tipologia" [items]="tipologieFase" bindValue="id"
            bindLabel="descrizioneEstesa" placeholder="Seleziona la descrizione della fase"  (change)="setTipologiaFase($event)">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.descrizioneEstesa }}">
                {{ item.descrizioneEstesa }}
              </div>
            </ng-template>
          </ng-select>

    </div>




      <div class="col-xl-1 col-lg-2 col-md-3 pt-1" [hidden]="!isAdmin() && !isGr34()">
        <label for="importoFinanziamento">
          <h6 class="mb-0 text-white bold">Flusso Di Cassa</h6>
        </label>
        <ng-select
          formControlName="flussoDiCassa"
          [items]="flussoDiCassaItems"
          bindValue="k"
          bindLabel="v"
          placeholder="Seleziona"
          (change)="setFlussoDiCassa($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.v }}">{{ item.v }}</div>
          </ng-template>
        </ng-select>
      </div>

      <div class="col-xl-3 col-lg-6 col-md-5 pt-1">
        <label for="importoFinanziamento">
          <h6 class="mb-0 text-white bold">Tipologia Soggetti Responsabili</h6>
        </label>
        <ng-select
          formControlName="tipologiaResponsabile"
          [items]="tipologieResponsabiliCombo"
          bindValue="id"
          bindLabel="descrizione"
          placeholder="Seleziona la tipologia di responsabile"
          (change)="setTipologiaResponsabile($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
          </ng-template>
        </ng-select>
      </div>

      <div class="col-xl-3 col-lg-6 col-md-5 pt-1">
        <label for="importoFinanziamento">
          <h6 class="mb-0 text-white bold">
            Soggetto Responsabile <small>(min 3 caratteri)</small>
          </h6>
        </label>
        <app-completer
          [cpOptions]="completerParamsSoggetti"
          (onSelectEmitter)="setSoggettiCompleter($event)"
          (keyup)="onChangeSoggettiCompleter($event)"
          [disabled]="!myForm.disabled"
        >
        </app-completer>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1"  [hidden]="!isAdmin() && !isGr34()">
        <label for="importoFinanziamento">
          <h6 class="mb-0 text-white bold">Tipologia Classificazioni</h6>
        </label>
        <ng-select
          [items]="classificazioniCombo"
          bindValue="id"
          bindLabel="tipo"
          formControlName="classification"
          placeholder="Seleziona tipologia classificazioni"
          (change)="setClassificazione($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.tipo }}">{{ item.tipo }}</div>
          </ng-template>
        </ng-select>
      </div>
      <!--filtro per pnrr-->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label title="PNRR / PNC">
          <h6 class="mb-0 text-white bold" >PNRR / PNC</h6>
        </label>
        <ng-select
          [items]="pnrrComboItems"
          formControlName="pnrrPnc"
          bindValue="key"
          bindLabel="value"
          placeholder="Seleziona"
          (change)="setPnrrFilter($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.value }}">{{ item.value }}</div>
          </ng-template>
        </ng-select>
      </div>

      <!--filtro per linee di finanziamento pnrr-->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label>
          <h6 class="mb-0 text-white bold">Linee di Finanziamento PNRR / PNC - Missioni</h6>
        </label>
        <ng-select
          [items]="finanziamentoMissionsPnrrComboItems"
          formControlName="finanziamentoPnrrPnc"
          [multiple]="true" [selectableGroup]="true" [selectableGroupAsModel]="false" groupBy="gruppo"
          [closeOnSelect]="false"
          bindValue="codice"
          bindLabel="valore"
          placeholder="Seleziona una o più linee di finanziamento"
          (change)="setLineeFinanziamento($event, 'mission')"
        >
          <!-- <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.codice }}">{{ item.codice }}</div>
          </ng-template> -->

          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> <span title="{{ item.valore }}">&nbsp;{{ item.valore }}</span>
          </ng-template>

        </ng-select>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label>
          <h6 class="mb-0 text-white bold">Linee di Finanziamento PNRR / PNC - Componenti</h6>
        </label>
        <ng-select
          [items]="finanziamentoComponentsPnrrComboItems"
          formControlName="finanziamentoPnrrPnc"
          [multiple]="true" [selectableGroup]="true" [selectableGroupAsModel]="false" groupBy="gruppo"
          [closeOnSelect]="false"
          bindValue="codice"
          bindLabel="valore"
          placeholder="Seleziona una o più linee di finanziamento"
          (change)="setLineeFinanziamento($event, 'component')"
        >
          <!-- <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.codice }}">{{ item.codice }}</div>
          </ng-template> -->

          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> <span title="{{ item.valore }}">&nbsp;{{ item.valore }}</span>
          </ng-template>

        </ng-select>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label>
          <h6 class="mb-0 text-white bold">Linee di Finanziamento PNRR / PNC - Linee</h6>
        </label>
        <ng-select
          [items]="finanziamentoLinesPnrrComboItems"
          formControlName="finanziamentoPnrrPnc"
          [multiple]="true" [selectableGroup]="true" [selectableGroupAsModel]="false" groupBy="gruppo"
          [closeOnSelect]="false"
          bindValue="codice"
          bindLabel="valore"
          placeholder="Seleziona una o più linee di finanziamento"
          (change)="setLineeFinanziamento($event, 'line')"
        >
          <!-- <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.codice }}">{{ item.codice }}</div>
          </ng-template> -->

          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> <span title="{{ item.valore }}">&nbsp;{{ item.valore }}</span>
          </ng-template>

        </ng-select>
      </div>

<!--filtri per date-->

<div class="col-xl-3 col-lg-4 col-md-6 pt-1">
    <label><h6  class="mb-0 text-white bold">Data prevista inzio</h6></label>

    <div class="d-flex">
        <input type="text" formControlName="dataPrevistaInizio" class="form-control" bsDaterangepicker [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY', containerClass:'theme-dark-blue' }" #dataPrevistaInizio="bsDaterangepicker"
            style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
        <button class="btn btn-secondary" (click)="dataPrevistaInizio.toggle()" style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
           <i class="fas fa-calendar-alt"></i>
        </button>
    </div>

</div>

<div class="col-xl-3 col-lg-4 col-md-6 pt-1">
    <label><h6  class="mb-0 text-white bold">Data prevista fine</h6></label>

    <div class="d-flex">
        <input type="text" formControlName="dataPrevistaFine" class="form-control" bsDaterangepicker [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY', containerClass:'theme-dark-blue' }" #dataPrevistaFine="bsDaterangepicker"
            style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
        <button class="btn btn-secondary" (click)="dataPrevistaFine.toggle()" style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
           <i class="fas fa-calendar-alt"></i>
        </button>
    </div>


</div>

<div class="col-xl-3 col-lg-4 col-md-6 pt-1">
    <label><h6  class="mb-0 text-white bold">Data inizio</h6></label>

    <div class="d-flex">
        <input type="text" formControlName="dataInizio" class="form-control" bsDaterangepicker [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY', containerClass:'theme-dark-blue' }" #dataInizio="bsDaterangepicker"
            style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
        <button class="btn btn-secondary" (click)="dataInizio.toggle()" style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
           <i class="fas fa-calendar-alt"></i>
        </button>
    </div>


</div>

<div class="col-xl-3 col-lg-4 col-md-6 pt-1">
    <label><h6  class="mb-0 text-white bold">Data fine</h6></label>

    <div class="d-flex">
        <input type="text" formControlName="dataFine" class="form-control" bsDaterangepicker [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY', containerClass:'theme-dark-blue' }" #dataFine="bsDaterangepicker"
            style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
        <button class="btn btn-secondary" (click)="dataFine.toggle()" style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
           <i class="fas fa-calendar-alt"></i>
        </button>
    </div>


</div>




      <!-- Filtro Soggetti attuatori -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label>
          <h6 class="mb-0 text-white bold">Soggetti Attuatori</h6>
        </label>
        <ng-select
          [items]="soggettiAttuatoriItems"
          formControlName="attuatore"
          bindValue="id"
          bindLabel="denominazione"
          placeholder="Seleziona soggetto"
          (change)="setSoggettoAttuatore($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.denominazione }}">{{ item.denominazione }}</div>
          </ng-template>
        </ng-select>
      </div>
      <!--filtro per rup-->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1" >
        <label>
          <h6 class="mb-0 text-white bold">RUP</h6>
        </label>
        <ng-select [items]="soggettiRPCombo" formControlName="rup" bindValue="id" bindLabel="label"
          placeholder="Seleziona RUP" (change)="setRUP($event)">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.label }}">{{ item.label }}</div>
          </ng-template>
        </ng-select>
      </div>

<!--filtro per comune-->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1" >
        <label>
          <h6 class="mb-0 text-white bold">Comune</h6>
        </label>
        <ng-select [items]="municipalities" formControlName="comune" bindValue="id" bindLabel="label"
          placeholder="Seleziona Comune" (change)="setComune($event)">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.label }}">{{ item.label }}</div>
          </ng-template>
        </ng-select>
      </div>


      <!--filtro per RP solo sisma-->
<div class="col-xl-3 col-lg-4 col-md-6 pt-1" [hidden]="!isSisma">
    <label>
      <h6 class="mb-0 text-white bold">RP</h6>
    </label>
    <ng-select [items]="soggettiRPCombo" formControlName="rp" bindValue="id" bindLabel="label"
      placeholder="Seleziona RP" (change)="setRP($event)">
      <ng-template ng-option-tmp let-item="item">
        <div title="{{ item.label }}">{{ item.label }}</div>
      </ng-template>
    </ng-select>
  </div>

      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 pt-1 align-self-end align-items-end">
        <div class="d-flex">
          <div class="col-lg-6 pl-0">
            <button
              [disabled]="showLoading"
              type="button"
              class="mt-1 btn btn-info btn-block"
              (click)="filter(false, true)"
            >
              Cerca
            </button>

          </div>

        <div class="col-lg-6 pr-0">
          <button
          [disabled]="loadingExportExcel"
          type="button"
          class="mt-1 btn btn-info btn-block"
          (click)="esportaExcel()"
        >
          Esporta
          <img
            *ngIf="loadingExportExcel"
            class="pl-3 float-right"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
          />
        </button>
        </div>
      </div>
    </div>
  </div>

    <ng-container *ngIf="!showPage">
      <div [hidden]="loading" *ngIf="!noresult" class="jumbotron container my-5 border shadow-sm my-3 rounded bg-white">
        <div class="container text-center">
            <h4 class="bold">Dashboard Fasi {{isSisma?"Sisma":""}}</h4>
            <p class="h5"><small>{{ (isFiltered() ? 'Premere' : 'Selezionare almeno un filtro e premere') + ' sul pulsante Cerca per attivare la visualizzazione dei grafici delle Fasi.' }}</small></p>
        </div>
      </div>

      <ng-container *ngIf="noresult">
        <div [hidden]="loading" class="jumbotron container my-5 border shadow-sm my-3 rounded bg-white">
            <div class="container text-center">
                <p class="h5"><small>La ricerca non ha prodotto risultati</small></p>
            </div>
        </div>
      </ng-container>
    </ng-container>

    <!--grafici classificazione-->
    <div class="row" *ngIf="showPage">
      <div class="col-lg-6 col-md-12">
        <div
          id="chart1"
          class="bg-white chart1 border shadow-sm my-3 rounded"
        ></div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div id="chart2" class="bg-white chart2 border shadow-sm my-3 rounded">
          <div id="piechart1" class="asset"></div>
          <div id="piechart2" class="asset"></div>
          <div id="piechart3" class="asset"></div>
          <div id="piechart4" class="asset" [hidden]="!isAdmin() && !isGr34()"></div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="showPage" [hidden]="isHiddenChart6">

      <div class="col-lg-12 col-md-12">
        <div
          id="chart6"
          class="bg-white chart3 border shadow-sm my-3 rounded"
        ></div>
      </div>
    </div>
    <div class="row" *ngIf="showPage">
      <div class="col-lg-12 col-md-12">
        <div [hidden]="!showChart3"
          id="chart3"
          class="bg-white chart3 border shadow-sm my-3 rounded"
        ></div>
      </div>
    </div>
    <div class="row" *ngIf="showPage">
      <div class="col-lg-12" style="z-index: 9000;">
        <div id="chart4" class="bg-white chart4 border shadow-sm my-3 rounded" [hidden]="!showChart4"></div>
      </div>
    </div>

    <div class="row" *ngIf="showPage">
      <div class="col-lg-12 my-3 text-center">
        <div class="bg-white border shadow-sm rounded" >
            <button *ngIf="!tabellaDettaglioObjLoaded && datiDettaglioCaricati" (click)="tabellaDettaglioObjLoaded = true" class="btn btn-primary my-2">Mostra tabella di dettaglio</button>
            <div class="text-left"><dashboard-fasi-details-table-component [hidden]="!tabellaDettaglioObjLoaded"
            [observable$]="observable$ | async"
            [tabellaDettaglioObj]="tabellaDettaglioObj"
          ></dashboard-fasi-details-table-component></div>
        </div>
      </div>
    </div>

</div>
