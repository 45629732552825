import { Injectable } from "@angular/core";
import { AuthService } from "../services/auth.service";
import {
  Router,
  RouterStateSnapshot,
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  ActivatedRoute,
} from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class LoggedGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService
    , private router: Router    
    ) {}

  canActivate(route, state: RouterStateSnapshot) {
    console.log(`inside LoggedGuard.canactivate`);
    if (this.authService.isLoggedIn()) return true;
    console.log(`user not logged in`);
    if (route.queryParams && route.queryParams['code']){
        this.router.navigate(["/iam"],{queryParams:route.queryParams});
    }
    else{
        this.router.navigate(["/login"]);
    }
    
    return false;
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    console.log(`inside LoggedGuard.canActivateChild`);
    return this.canActivate(route, state);
  }
}
