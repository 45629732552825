import { CurrencyPipe } from '@angular/common';
import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    TemplateRef,
    AfterViewInit,
    ElementRef
} from "@angular/core";
import { registerLocaleData } from '@angular/common';
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProgettoService, InterventoService, StorageService} from '../../services';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from '../../../../components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component'
import localeIt from '@angular/common/locales/it';
import { OrderPipe } from 'ngx-order-pipe';
import { AuthService } from 'src/app/services';
registerLocaleData(localeIt, 'it');
defineLocale('it', itLocale);
import { IsDirtyHelper } from "../../../../helpers/is-dirty.helper"


@Component({
    selector: 'monitoraggi-component',
    templateUrl: './monitoraggi.component.html',
    styleUrls: ['./monitoraggi.component.css']
})
export class MonitoraggiComponent implements OnInit, OnDestroy, AfterViewInit {

    ngOnDestroy(): void {
        if(this.elencoAreeSubscription)this.elencoAreeSubscription.unsubscribe();
    }

    submitted: boolean = false;
    loading: boolean = false;
    loadDataCounter: number = 0;
    interventoId: number;


    modalSubscriptions = [];

    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnGeoTemplate', { static: true })
    public btnGeoTemplate: TemplateRef<any>;

    @ViewChild('direzioneFieldValueTemplate', { static: true })
    public direzioneFieldValueTemplate: TemplateRef<any>;

    @ViewChild('annualitaFieldValueTemplate', { static: true })
    public annualitaFieldValueTemplate: TemplateRef<any>;

    @ViewChild('btnCheckTemplate', { static: true })
    public btnCheckTemplate: TemplateRef<any>;



    @ViewChild('fieldNoteTemplate', { static: true })
    public fieldNoteTemplate: TemplateRef<any>;



    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    @ViewChild('extraFilterTemplate', { static: true })
    public extraFilterTemplate: TemplateRef<any>;


    templateList = [];

    dtOptions: any;
    dtOptionsSoggResp: any;
    rows: any[];
    columns = [
        {
            name: '', prop: 'id', sortable: true, filtrable: false, visible: false
            // 'buttonsTemplate'
        }

        , {
            name: 'codice'
            , prop: 'codice'
            , sortable: true
            , filtrable: true
            , minWidth: 85
            , flexGrow: 3
        }
        , {
            name: 'cup'
            , prop: 'cup'
            , sortable: true
            , filtrable: true
            , minWidth: 120
            , flexGrow: 4
            , visible: false

        }
        , {
            name: 'descrizione'
            , prop: 'descrizione'
            , sortable: true
            , filtrable: true
            , minWidth: 120
            , flexGrow: 4
        }
        , {
            name: 'aree gestionali'
            , prop: 'areeTematiche'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'direzioneFieldValueTemplate'
            , minWidth: 120
            , flexGrow: 4
        }
        , {
            name: 'valore'
            , prop: 'importi'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'annualitaFieldValueTemplate'
            , minWidth: 200
            , flexGrow: 6
        }
        , {
            name: 'validazione'
            , prop: 'validationStatus'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'btnCheckTemplate'
            , flexGrow: 2
            , minWidth: 70


        }
        , {
            name: 'note'
            , prop: 'noteResponsabile'
            , sortable: true
            , filtrable: false
            , cellTemplate: 'fieldNoteTemplate'
            , flexGrow: 1
            , minWidth: 50


        }
        , {
            name: 'loc.'
            , prop: 'disegnato'
            , sortable: true
            , filtrable: false
            , cellTemplate: 'btnGeoTemplate'// 'buttonsTemplate'
            //, width: 10
            , resizeable: false
            , draggable: false
            , flexGrow: 1
            , minWidth: 40




        }
        , {
            name: 'dettaglio'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , minWidth: 60
            , flexGrow: 2

        }
    ];



    modalTitle: string = '';
    modalButtonLabel: string = '';

    modalRef: BsModalRef;

    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-xl"
    };
    ngAfterViewInit() {

    }

    selectedInterventoData: string;


    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;



    constructor(
        private el: ElementRef,
        private progettoService: ProgettoService
        , private toastr: ToastService
        , private modalService: BsModalService
        , private localeService: BsLocaleService
        , private interventoService: InterventoService
        , private orderPipe: OrderPipe
        , private authService: AuthService
        , private isDirtyHelper: IsDirtyHelper
        ,private storageService:StorageService
    ) {
       // this.deleteForm = this.initializeDeleteFormGroup();
       // this.getMotivazioniCancellazione();
        this.getAreeTematiche();
        this.localeService.use("it");
        this.dtOptions = {
            rows: this.rows
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , scrollbarH: "true"
            , ajax: this.getInterventiMonitoratiDt
            , extraFilterTemplate: this.extraFilterTemplate
        };

    }


    ngOnInit() {
        this.templateList = [

             { name: 'btnEditTemplate', template: this.btnEditTemplate }
            , { name: 'direzioneFieldValueTemplate', template: this.direzioneFieldValueTemplate }
            , { name: 'annualitaFieldValueTemplate', template: this.annualitaFieldValueTemplate }
            , { name: 'btnCheckTemplate', template: this.btnCheckTemplate },
            { name: 'btnGeoTemplate', template: this.btnGeoTemplate }
            , { name: 'fieldNoteTemplate', template: this.fieldNoteTemplate }

        ];
        window.scroll(0, 0);
        this.dtOptions.extraFilterTemplate = this.extraFilterTemplate;
        this.ngxDatatableWrapper ? this.ngxDatatableWrapper.dtOptions = this.dtOptions : null;
    }


    getInterventiMonitoratiDt = (dataTablesParameters: any, callback: any) => {
        let that = this;
        this.interventoService.getMonitoraggiDt( dataTablesParameters)
            .subscribe(resp => {
                callback(resp)
            });
    }





    openAddModal() {
        /*this.selectedInterventoData = JSON.stringify({ interventoId: null, projectId: this.projectId, type: "pippo" });
        this.modalTitle = 'Nuovo intervento';
        this.modalRef = this.modalService.show(this.templateEditAdd, this.config);*/
    }



    onEdit(row, notOpenModal?: boolean) {
        this.interventoId = row.id;
        this.modalRef = this.modalService.show(this.templateEditAdd, this.config);
    }



    clearSubscriptions() {
        this.modalSubscriptions.forEach(x => x.unsubscribe());
        this.modalSubscriptions = [];
    }

    closeModal(data: any) {
        if (!this.isDirtyHelper.checkIsDirty()) return false;
        this.modalRef.hide();
       // this.getInterventiByProjectId(this._projectId);
    }



    printDirezioneFieldValue(data) {
        let str = '';
        let arr = [];
        if (data && data != '') {
            //let obj = JSON.parse(data);

            for (let o in data) {
                let s = "";
                s = (data[o]["disabled"]?"(disattiva) ":"") + data[o]['descrizione'];
                arr.push(s);
            }
            str = arr.join('<br />');
        }
        return arr;
    }

    printValueAsCurrency(value) {
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }

    calcolaValoreIntervento(row: any) {

        if (!row || !row['importi']) return this.printValueAsCurrency(0);
        let valore = 0;
        row['importi'].forEach(element => {
            valore += element['importo'];
        });
        return this.printValueAsCurrency(valore);
    }

    sortImporti(arr) {
        return this.orderPipe.transform(arr, 'anno');
    }

    reloadData() {
        //if (this._projectId) this.getInterventiByProjectId(this._projectId);
    }

    isAdmin() {
        return this.authService.isAdmin();
    }
    isDirectionalGroup() {
        return this.authService.isDirectionalGroup();
    }
    isAreaStaff() {
        return this.authService.isAreaStaff();
    }
    isExternal() {
        return this.authService.isExternal();
    }
    isViewer() {
        return this.authService.isViewer();
    }

    printBtnValidazioneTitle(value) {
                return '';
    }

    onRichiediValidazione(row: any) {

                return;

    }
    isReadOnly(info: any): boolean {
        //controllo se l'utente può modificare il progetto
        //let user = this.authService.getCurrentUser();
        return true;
    }

    closeModalEmitterSubscribe = null;
    onGeolocalizza(row) {
         return;

    }
    areeTematicheCombo = [];
    private elencoAreeSubscription = null;
    getAreeTematiche() {
       
        this.elencoAreeSubscription = this.storageService.elencoAreeTematiche.subscribe(
            x=>{
                console.log(x);
                this.areeTematicheCombo = x?x:[];
            }
            );
    }

    filterAreaTematica($event) {
        let descr = $event ? $event.descrizione : '';
        if (descr.startsWith('(disattiva)')){
            descr = descr.replace('(disattiva)','');
        }
        this.ngxDatatableWrapper.originalColumns[4].search = { value: descr, regex: false };
        this.ngxDatatableWrapper.dtInputParams.columns[4].search = { value: descr, regex: false };
        this.ngxDatatableWrapper.refresh();
        //this.filterArea = $event ? $event.id : -1;
        //this.filterInterventions(false);
    }


}
