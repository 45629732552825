import { Injectable } from '@angular/core';
//import 'rxjs/add/operator/toPromise';
import { ProgettoService } from './progetto.service';
import{StorageService} from './storage.service';

@Injectable()
export class InitService {
    constructor(
        private progettoService:ProgettoService
        ,private storageService:StorageService
        ){}

    initializeApp():Promise<any>{
        return new Promise<void>((resolve, reject) => {
            console.log(`initializeApp:: inside promise`);                
            this.getTipiIntervento(); 
            this.getPrioritaMotivazioni();
            this.getRup();
            this.getAreeTematiche();
            this.getTipiFinanziamento();
            resolve();
          });
    }
    getTipiIntervento(){
        console.log(`init - invoke progettoService.getTipiInterventi`);
        this.progettoService.getTipiInterventi().subscribe((tipi)=>{
            console.log(`init - done progettoService.getTipiInterventi`,tipi);
            localStorage.setItem("tipologieItervento",JSON.stringify(tipi));            
        }); 
    }

    getPrioritaMotivazioni() {
        this.storageService.reloadPriorita();
    }

    getRup(){
        this.storageService.reloadRup();
    }
    getAreeTematiche(){
        this.storageService.reloadAreeTematiche();
    }

    getTipiFinanziamento(){
        this.storageService.reloadTipiFinanziamento();
    }

    
}