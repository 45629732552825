import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { Progetto, Fase, Intervento } from "../models";

class DataTablesResponse {
  data: Progetto[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: "root",
})
export class ProgettoService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}Progetti/`;
  private publicApiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob_public}Progetti/`;
  private apiUrlInterventi = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}Interventi/`;
  constructor(private http: HttpClient) {}

  get(id: number) {
    return this.http.get<any>(`${this.apiUrl}${id}`);
  }

  getAllDt(dataTablesParameters: any, filters: any) {
    return this.http.post<DataTablesResponse>(`${this.apiUrl}alldt`, {
      dtParams: dataTablesParameters,
      filters: filters,
    });
  }
  getAll(onlyPnrr: string = "") {
    return this.http.get<any>(`${this.publicApiUrl}?onlyPnrr=${onlyPnrr}`);
  }
  insert(s: Progetto) {
    return this.http.post<any>(`${this.apiUrl}`, s);
  }
  update(s: Progetto) {
    return this.http.put<any>(`${this.apiUrl}${s.id}`, s);
  }
  delete(id: number, motivazione: string = "") {
    return this.http.delete<any>(
      `${this.apiUrl}${id}?motivazione=${motivazione}`
    );
  }
  save(progetto: any) {
    if (progetto.id && progetto.id > 0) return this.update(progetto);
    return this.insert(progetto);
  }

  getFasi(progettoId: number) {
    return this.http.get<Fase[]>(`${this.apiUrl}${progettoId}/Fasi`);
  }
  deleteFase(progettoId: number, faseId: number) {
    return this.http.delete<boolean>(
      `${this.apiUrl}${progettoId}/Fasi/${faseId}`
    );
  }
  saveFase(progettoId: number, fase: any) {
    if (fase.id && fase.id > 0) return this.updateFase(progettoId, fase);
    return this.insertFase(progettoId, fase);
  }
  insertFase(progettoId: number, fase: any) {
    return this.http.post<any>(`${this.apiUrl}${progettoId}/Fasi`, fase);
  }
  updateFase(progettoId: number, fase: any) {
    return this.http.put<any>(
      `${this.apiUrl}${progettoId}/Fasi/${fase.id}`,
      fase
    );
  }
  getInterventi(progettoId: number) {
    return this.http.get<Intervento[]>(
      `${this.apiUrl}${progettoId}/Interventi`
    );
  }

  getInterventiSimpleData(filterObject: any = null) {
    if (!filterObject.filterStatus) filterObject.filterStatus = [-1];
    if (!Array.isArray(filterObject.filterStatus))
      filterObject.filterStatus = [filterObject.filterStatus];
    else if (filterObject.filterStatus.length <= 0)
      filterObject.filterStatus.push(-1);
    if (!filterObject.filterTipologiaIntervento)
      filterObject.filterTipologiaIntervento = [-1];
    if (!Array.isArray(filterObject.filterTipologiaIntervento))
      filterObject.filterTipologiaIntervento = [
        filterObject.filterTipologiaIntervento,
      ];
    else if (filterObject.filterTipologiaIntervento.length <= 0)
      filterObject.filterTipologiaIntervento.push(-1);
    if (!filterObject.filterFonteFinanziamentoIds)
      filterObject.filterFonteFinanziamentoIds = [-1];
    if (!Array.isArray(filterObject.filterFonteFinanziamentoIds))
      filterObject.filterFonteFinanziamentoIds = [
        filterObject.filterFonteFinanziamentoIds,
      ];
    else if (filterObject.filterFonteFinanziamentoIds.length <= 0)
      filterObject.filterFonteFinanziamentoIds.push(-1);
    if (!filterObject.filterAggregato) filterObject.filterAggregato = [-1];
    if (!Array.isArray(filterObject.filterAggregato))
      filterObject.filterAggregato = [filterObject.filterAggregato];
    else if (filterObject.filterAggregato.length <= 0)
      filterObject.filterAggregato.push(-1);

    return this.http.get<any[]>(`${this.apiUrl}direzione/sintesi`, {
      params: { filterObject: JSON.stringify(filterObject) },
    });
  }

  getInterventiListSimpleData(filterObject: any = null) {
    if (!filterObject.filterStatus) filterObject.filterStatus = [-1];
    if (!Array.isArray(filterObject.filterStatus))
      filterObject.filterStatus = [filterObject.filterStatus];
    else if (filterObject.filterStatus.length <= 0)
      filterObject.filterStatus.push(-1);
    if (!filterObject.filterTipologiaIntervento)
      filterObject.filterTipologiaIntervento = [-1];
    if (!Array.isArray(filterObject.filterTipologiaIntervento))
      filterObject.filterTipologiaIntervento = [
        filterObject.filterTipologiaIntervento,
      ];
    else if (filterObject.filterTipologiaIntervento.length <= 0)
      filterObject.filterTipologiaIntervento.push(-1);
    if (!filterObject.filterFonteFinanziamentoIds)
      filterObject.filterFonteFinanziamentoIds = [-1];
    if (!Array.isArray(filterObject.filterFonteFinanziamentoIds))
      filterObject.filterFonteFinanziamentoIds = [
        filterObject.filterFonteFinanziamentoIds,
      ];
    else if (filterObject.filterFonteFinanziamentoIds.length <= 0)
      filterObject.filterFonteFinanziamentoIds.push(-1);
    if (!filterObject.filterAggregato) filterObject.filterAggregato = [-1];
    if (!Array.isArray(filterObject.filterAggregato))
      filterObject.filterAggregato = [filterObject.filterAggregato];
    else if (filterObject.filterAggregato.length <= 0)
      filterObject.filterAggregato.push(-1);

    return this.http.get<Intervento[]>(
      `${this.publicApiUrl}direzione/sintesi`,
      { params: { filterObject: JSON.stringify(filterObject) } }
    );
  }

  getInterventiSimpleDataWithProjects(
    // progettoId: number,
    // interventoId: number,
    // validationStatusCode: any,
    // idAreaTematica: number,
    // idTipoClassificazione: number,
    // idSoggetto: number,
    // anno: string,
    // provinciaSoggetto: string,
    // idTema: number,
    // annoFrom:number,
    // annoTo:number,
    // description:string='',
    // tipologiaIntervento:any,
    // filterFinanziamento:string='',
    // filterFonteFinanziamento:any=null,
    // filterPnrr:any=-1,
    // filterLineeFinanziamenti = null,
    // filterProvince:any=-1,
    // filterComune:any=-1,
    // filterAggregato:any=null,
    filterObject
  ) {
    if (!filterObject.filterStatus) filterObject.filterStatus = [-1];
    if (!Array.isArray(filterObject.filterStatus))
      filterObject.filterStatus = [filterObject.filterStatus];
    else if (filterObject.filterStatus.length <= 0)
      filterObject.filterStatus.push(-1);
    if (!filterObject.filterTipologiaIntervento)
      filterObject.filterTipologiaIntervento = [-1];
    if (!Array.isArray(filterObject.filterTipologiaIntervento))
      filterObject.filterTipologiaIntervento = [
        filterObject.filterTipologiaIntervento,
      ];
    else if (filterObject.filterTipologiaIntervento.length <= 0)
      filterObject.filterTipologiaIntervento.push(-1);
    if (!filterObject.filterFonteFinanziamentoIds)
      filterObject.filterFonteFinanziamentoIds = [-1];
    if (!Array.isArray(filterObject.filterFonteFinanziamentoIds))
      filterObject.filterFonteFinanziamentoIds = [
        filterObject.filterFonteFinanziamentoIds,
      ];
    else if (filterObject.filterFonteFinanziamentoIds.length <= 0)
      filterObject.filterFonteFinanziamentoIds.push(-1);
    if (!filterObject.filterAggregato) filterObject.filterAggregato = [-1];
    if (!Array.isArray(filterObject.filterAggregato))
      filterObject.filterAggregato = [filterObject.filterAggregato];
    else if (filterObject.filterAggregato.length <= 0)
      filterObject.filterAggregato.push(-1);

    filterObject.alwaysProject = 1;
    filterObject.descriptionSearchString = "";

    return this.http.get<Intervento[]>(`${this.apiUrl}direzione/sintesi`, {
      params: { filterObject: JSON.stringify(filterObject) },
    });
  }

  getInterventiDt(progettoId: number, dataTablesParameters: any, filters: any) {
    return this.http.post<DataTablesResponse>(
      `${this.apiUrl}${progettoId ? progettoId : -1}/Interventi/dt`,
      {
        dtParams: dataTablesParameters,
        filters: filters,
      }
    );
  }
  getInterventiCount(progettoId: number) {
    return this.http.get<number>(
      `${this.apiUrl}${progettoId}/Interventi/count`
    );
  }

  getAnniInterventi() {
    return this.http.get<string[]>(`${this.apiUrl}statoInterventi/anni`);
  }

  getTipiInterventi() {
    return this.http.get<string[]>(`${this.apiUrl}tipologie`);
  }

  getTipiInterventoFromLocalStorage() {
    return localStorage.getItem("tipologieItervento") &&
      localStorage.getItem("tipologieItervento") != ""
      ? JSON.parse(localStorage.getItem("tipologieItervento"))
      : null;
  }

  getDatiDettaglio(id: number) {
    return this.http.get<any>(
      `${this.apiUrlInterventi}${id}/datiDettaglio?isProject=1`
    );
  }
}
