import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "./app-config.service";
import { AccessLog } from "../models/accessLog";

class DataTablesResponse {
    data: AccessLog[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
  }

@Injectable({
  providedIn: "root"
})
export class AccessLogService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${
    AppConfigService.env.endpoints.accesslog
  }`;
  constructor(private http: HttpClient) {}

  getAllDt(dataTablesParameters:any){
        return this.http.post<DataTablesResponse>(
                `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.accesslog}alldt`,
                dataTablesParameters
        );
  }
  
}
