import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { StoricoService } from '../../services';
import { StoricoDettaglioComponent } from "../../components/storicoDettaglio/storico-dettaglio.component";
import * as FileSaver from "file-saver";

@Component({
    selector: 'storico-generale-component',
    templateUrl: './storico-generale.component.html',
    styleUrls: ['./storico-generale.component.css']
})
export class StoricoGeneraleComponent
    implements OnInit {

    modalRef: BsModalRef;
    _isInModal: boolean = false;
    @Input()
    set isInModal(value: boolean) {
        this._isInModal = value;
    }
    get isInModal() { return this._isInModal; }


    loading: boolean = true;

    @ViewChild('btnDetailTemplate', { static: true })
    public btnDetailTemplate: TemplateRef<any>;

    @ViewChild('tipologiaValueTemplate', { static: true })
    public tipologiaValueTemplate: TemplateRef<any>;

    @ViewChild('codiceValueTemplate', { static: true })
    public codiceValueTemplate: TemplateRef<any>;


    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    dtOptions: any;

    columns = [
        {
            name: '', prop: 'id', sortable: true, filtrable: false, maxWidth: 0, width: 0, visible: false
            // 'buttonsTemplate'
        }

        , {
            name: 'data'
            , prop: 'date'
            , sortable: true
            , filtrable: true
            , minWidth: 160

            , cellTemplate: 'dateTimeValueTemplate'
            , flexGrow: 2
        },
        {
            name: 'codice'
            , prop: 'mainCode'
            , sortable: true
            , filtrable: true
            , minWidth: 140
            , flexGrow: 2
            ,cellTemplate:'codiceValueTemplate'

        }
        , {
            name: 'tipologia'
            , prop: 'action'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'tipologiaValueTemplate'
            , minWidth: 140
            , flexGrow: 2

        }
        , {
            name: 'descrizione'
            , prop: 'actionDescription'
            , sortable: true
            , filtrable: true
            , minWidth: 200
            , flexGrow: 5
        }

        , {
            name: 'utente'
            , prop: 'username'
            , sortable: true
            , filtrable: true
            , minWidth: 140
            , flexGrow: 3
        }

        , {
            name: 'dettaglio'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDetailTemplate'// 'buttonsTemplate'
            , minWidth: 70
            , flexGrow: 1

        }
    ];
    templateList = [];

    constructor(private storicoService: StoricoService
        , private modalService: BsModalService
        , private toastr: ToastService) {
        this.dtOptions = {
            rows: []
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , scrollbarH: "true"
            , ajax: this.getStorico
            , sorts: [{ dir: 'desc', prop: 'date' }]
        };
    }
    ngOnInit(): void {
        this.templateList = [
            { name: 'btnDetailTemplate', template: this.btnDetailTemplate }
            , { name: 'tipologiaValueTemplate', template: this.tipologiaValueTemplate }
            , { name: 'codiceValueTemplate', template: this.codiceValueTemplate }
        ];
        window.scroll(0, 0);
    }

    reloadAllData() {
        this.loading = true;
        this.ngxDatatableWrapper.gridModel.data = [];
        this.ngxDatatableWrapper.refresh();
    }

    getStorico = (dataTablesParameters: any, callback: any) => {
        let that = this;
        this.storicoService.getAllDt( dataTablesParameters)
            .subscribe(resp => {
                callback(resp)
                that.loading = false;
            });
    }

    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
    gotoBackBtnClick() {
        this.gotoBtnFunction.emit(true);
    }

    onDetail(row) {
        let modalDefinition = {
            pageTitle: `Dettaglio storico ${row.mainObjectClass.toLowerCase()=='finanziamento'
            ?'Finanziamento':(
                row.mainObjectClass.toLowerCase()=='provvedimento'
                ?'Provvedimento':
                (row.mainObjectClass.toLowerCase()=='pnrrlineafinanziamento'
            ?'Linea Finanziamento PNRR/PNC':'Progettualità / Intervento')
            )
        } `
        };
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: 'modal-xl'
        };
        const initialState = { modalDefinition: modalDefinition, id: row.id
            , what: row.mainObjectClass.toLowerCase()
            , interventoId: row.mainObjectClass.toLowerCase()=='intervento'
            || row.mainObjectClass.toLowerCase()=='progetto'?row.mainObjectId:null
            , finanziamentoId:row.mainObjectClass.toLowerCase()=='finanziamento'?row.mainObjectId :null
            , provvedimentoId:row.mainObjectClass.toLowerCase()=='provvedimento'?row.mainObjectId :null
            ,pnrrLineaFinanziamentoId:row.mainObjectClass.toLowerCase()=='pnrrlineafinanziamento'?row.mainObjectId:null
        };

        this.modalRef = this.modalService.show(StoricoDettaglioComponent, Object.assign({}, config, { initialState }));
    }

    getCodice(row,value){
        if (row && row.oldObject && JSON.parse(row.oldObject).codice && JSON.parse(row.oldObject).codice!='') return JSON.parse(row.oldObject).codice;
        return value;
    }

    esportaExcel(){
      this.loading = true;
        this.storicoService.exportExcel().subscribe(
            res => {
                this.loading = false;
                let filename = `esportazione_cancellazione.xlsx`;
                FileSaver.saveAs(
                    res.body,
                    `${filename}`
                );
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );
    }
}
