<section class="fascia-grey-login" style="margin:0px;padding-top:20px">
  <div class="container ">
    <div class="row justify-content-center">
      <div class="col-lg-8 ">
        <h3 id="registrati" class="text-center font-weight-bold mb-4 mt-3">Completa la registrazione</h3>

        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" novalidate>
          <div class="account-wall">

            <div class="tab-content clearfix">
              <!--main step-->
              <app-register-main-step  (OnSubmitDone)="mainStepDone($event)" [myUser]="registerUser" *ngIf="isMainStepEnabled()"></app-register-main-step>
              <!--TAB4-->
              <app-register-step4 *ngIf="isStep4Enabled()"></app-register-step4>
            </div>
          </div>
        </form>
      </div>


    </div>
  </div>
</section>
