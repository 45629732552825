<div class="p-5 container-xl text-center" [hidden]="!loading">
    <app-loading message="Caricamento in corso..."></app-loading>
</div>
<div class="container-xl" [hidden]="loading">
    <div class="position-sticky sticky-top  pt-4 pb-4 elemento-aggiorna mb-5 shadow-custom" [ngClass]="{'sticky-top-custom-modal': isInModal,'sticky-top-custom':!isInModal  }">
        <div class=" row  justify-content-center">
            <div class="col-lg-4 col-md-6 text-center" *ngIf="gotoBtnOptions && gotoBtnOptions.show">
                <button (click)="gotoBackBtnClick()" [disabled]="loading" class="btn btn-secondary btn-block font-weight-bold" type="button">
                  {{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}&nbsp;<i class="fas fa-times" *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i>
                </button>
            </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-2 pr-0">
            <button
            [disabled]="loading"
            type="button"
            class="mt-1 btn btn-info btn-block"
            (click)="esportaExcel()"
          >
            Esporta Cancellazioni
            <img
              *ngIf=""
              class="pl-3 float-right"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
            />
          </button>
        </div>

    </div>

    <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptions">
        <ng-template #btnDetailTemplate let-row="row" let-value="value">
            <a [ngClass]="{
                'disabled': loading
                }" class=" border-0 text-center" (click)="onDetail(row)" data-toggle="tooltip" data-placement="top" title="dettaglio">&nbsp;&nbsp;&ensp;<i class="far pointer fa-eye detail-iocn"  ></i></a>
        </ng-template>

        <ng-template #tipologiaValueTemplate let-row="row" let-value="value">
            <span>{{value=='DELETE'?'Cancellazione':(value=='INSERT'?'Inserimento':'Aggiornamento')}}</span>
        </ng-template>

        <ng-template #codiceValueTemplate let-row="row" let-value="value">
            <span>{{getCodice(row,value)}}</span>
        </ng-template>


    </ngx-datatable-wrapper>
</div>
