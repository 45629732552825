import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { RegisterUser } from "../../models/registerUser";
import { UserService,AuthService,AlertService } from "src/app/services/index";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { LoadingComponent } from "../_components/loading/loading.component";
import { ToastService } from 'src/app/services/toast.service';


@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"]
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;

  
  step4_disabled: boolean = true;
  mainStep_disabled:boolean=false;
  registerUser: RegisterUser;
  dprOk: false;
  privacyOk: boolean = false;

  modalRef: BsModalRef;
  modalOpts: ModalOptions = {
    backdrop: "static",
    ignoreBackdropClick: true,
    keyboard: false
  };

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private modalService: BsModalService,
    private alertService: AlertService,
    private authService:AuthService,
    private toastr: ToastService    
  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group({});
    this.registerUser = new RegisterUser();
    //this.registerUser = {ownedPlace:null,institutionCodes:null, "institutions":null,"firstName":"valerio","lastName":"fornaciari","fiscalCode":"FRNVLR80S25E625Q","birthDay":"25","birthMonth":"11","birthYear":"1980","birthNation":"0001","birthCountry":"09","birthProvince":"049","birthMunicipality":"E625","gender":"M","email":"a@a.it","emailconf":"a@a.it","n":"Italia","p":"Livorno","m":"Livorno","type":"ROLE_AREA_STAFF"};
    //this.enableStep3();
  }

  onSubmit() {
    this.submitted = true;
    console.log("main");
    return;
  }


  isStep4Enabled() {
    return !this.step4_disabled;
  }
  isMainStepEnabled(){
      return !this.mainStep_disabled;
  }


  enableStep4() {    
    this.mainStep_disabled=true;
    this.step4_disabled = false;
  }
  

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  saveUser() {
    console.log("saving user...");
    this.loading = true;
    this.modalRef = this.modalService.show(LoadingComponent, this.modalOpts);

    this.userService.checkUserExistsByFiscalCodeOrEmail(this.registerUser)
    .subscribe(
        x=>{
            if (x){
                (async () => {
                    await this.delay(1000);
                    this.modalRef.hide();
                this.toastr.error("Email già registrata");
                return;
                }
                )();//async
            }
            else{
                this.userService.registerUser(this.registerUser).subscribe(res => {
                    if (res) {
                      //delay for close modal
                      (async () => {
                        await this.delay(1000);
                        this.modalRef.hide();
                        this.enableStep4();
                        this.authService.clearUserData();
                      })();
                    } else {
                      this.modalRef.hide();
                      this.alertService.error(
                        "Ops! Si è verificato un errore, contattare l'assistenza! "
                      );
                    }
                  });
            }

        }
    );



    
  }

  mainStepDone(myUser:any){
    console.log('inside mainStepDone');
    this.registerUser = Object.assign(this.registerUser,myUser);
    this.saveUser();
  }
}
