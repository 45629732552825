<div class="" [hidden]="mode !='list'">
    <hr />
    <div *ngIf="canInsert() && !filters" class="col-lg-12 mb-3"><button class="btn btn-lg btn-primary btn-block" type="button" (click)="openNewProjectPage();">
      Inserisci Nuova Progettualità {{isAdmin()||isGr34()?'/ Fabbisogno':''}}
    </button>
    </div>
    <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsNgx">

        <ng-template #btnEditTemplate let-row="row" let-value="value">
            <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top" title="{{isReadOnly(row)?'dettaglio':'modifica dati'}}">&nbsp;&nbsp;&nbsp;&nbsp;<i class="far pointer"
          [ngClass]="{'fa-edit': !isReadOnly(row),'fa-eye': isReadOnly(row),'detail-icon': isReadOnly(row)}"></i></a>
        </ng-template>

        <ng-template #btnGeoTemplate let-row="row" let-value="value">
            <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onGeolocalizza(row)" data-toggle="tooltip" data-placement="top" title="{{row.nonLocalizzabile?'non localizzabile':   (value)?'localizzazione presente':'Nessuna localizzazione presente'}}">
                <i class="pointer fas  text-center font-size-18" [ngClass]="{'text-info icon-no-map': row.nonLocalizzabile
          ,'text-success fa-map-marker-alt':value && !row.nonLocalizzabile
          ,'text-secondary fa-map-marker-alt':!value && !row.nonLocalizzabile
          ,'pointer fa-map-marker-alt': !row.nonLocalizzabile
        }"></i></a>
        </ng-template>

        <ng-template #haInterventiTemplate let-row="row" let-value="value">
            <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="openInterventi(row)" data-toggle="tooltip" data-placement="top" title="{{value>0?'interventi presenti':'interventi non presenti'}}">
        <i class="  fas fa-list-ol font-size-18 text-center"
          [ngClass]="{'text-success':value>0,'text-secondary':value<=0,'pointer':value>0}"></i>

      </a>
        </ng-template>


        <ng-template #extraFilterTemplate>
            <form class="form-inline justify-content-center px-0 px-sm-3">
                <div>
                    <label class="mb-sm-2 mb-md-0">Rilevante PNRR:&nbsp;</label>

                    <ng-select style="letter-spacing: -0.5px;" class="" [items]="itemsComboFilterPnrr" bindValue="id"
                      bindLabel="descrizione" placeholder="" (change)="filterRilevantePnrr($event)">
                      <ng-template ng-option-tmp let-item="item">
                        <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
                      </ng-template>
                    </ng-select>


                <label class="mb-sm-2 mb-md-0">Aree gestionali direzione e/o esterne:&nbsp;</label>

                <ng-select style="letter-spacing: -0.5px; min-width: 330px;" class="" [items]="areeTematicheCombo" bindValue="id" bindLabel="descrizione" placeholder="Seleziona area gestionale direzione e/o esterna " (change)="filterAreaTematica($event)">
                    <ng-template ng-option-tmp let-item="item">
                        <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
                    </ng-template>
                </ng-select>
                </div>
            </form>

        </ng-template>


        <ng-template #fieldNoteTemplate let-row="row" let-value="value">

            <ng-template #popTemplate>


                <div [hidden]="!value && !row.haNoteIntervento" [innerHtml]="'<div>'+value+'</div>'+( row.haNoteIntervento ? '<hr class=\'' + (!value|| value=='' ?'d-none':'') +'\' ><span><i class=\'fas fa-exclamation-circle text-warning\'></i>&nbsp;sono&nbsp;presenti&nbsp;note&nbsp;all`interno&nbsp;degli&nbsp;interventi</span>' : '')">
                </div>
            </ng-template>

            &nbsp; <i class="fas font-size-18 text-secondary" [ngClass]="{
                      ' fa-comment-slash': !value && !row.haNoteIntervento,

                      ' fa-comment text-info help px-1': value || row.haNoteIntervento
                    }" [popover]="value || row.haNoteIntervento?  popTemplate:null" placement="left" triggers="mouseenter:mouseleave"></i>



        </ng-template>




        <ng-template #btnCheckTemplate let-row="row" let-value="value">
            <a class=" border-0 text-center" (click)="onRichiediValidazione(row)" data-toggle="tooltip" data-placement="top" title="{{printBtnValidazioneTitle(value) }}">&nbsp;&nbsp;
        <i class=" font-size-18  pointer" [ngClass]="{
                    'text-3AB795 fa-certificate': value=='TERRITORIALE',
                    'text-danger': value=='FABBISOGNO',
                    'text-success': value=='APPROVED',
                    'fa-check-circle': value=='APPROVED',
                    'fa-ban': value=='REJECTED',
                    'text-warning': value =='WAITING_FOR_APPROVAL_FIRST_LEVEL' || value =='WAITING_FOR_APPROVAL_SECOND_LEVEL',
                    'fa-pause-circle': value =='WAITING_FOR_APPROVAL_FIRST_LEVEL' || value =='WAITING_FOR_APPROVAL_SECOND_LEVEL',
                    'fa-adjust': value=='IBRIDO',
                    'text-dark': value=='IBRIDO',
                    'text-dark': (value=='IBRIDO' || value=='IDLE'),
                    'fa-circle': ( (value !='IBRIDO'
                    && value!='REJECTED'
                    && value!='APPROVED'
                    && value!='WAITING_FOR_APPROVAL_FIRST_LEVEL'
                    && value!='WAITING_FOR_APPROVAL_SECOND_LEVEL'
                    && value!='TERMINATO'   && value!='TERRITORIALE'
                    ))
                    ,'far': !value || value=='' || value=='IDLE'
                    ,'fas': value && value!='' && value!='IDLE'
                    ,'fa-check-square': value=='TERMINATO'
                    ,'text-0083e0': value=='TERMINATO'
                    }">
        </i></a>
        </ng-template>


        <ng-template #btnDeleteTemplate let-row="row" let-value="value">
            <a [hidden]="isReadOnly(row) || !canDelete()" [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
        </ng-template>
        <ng-template #direzioneFieldValueTemplate let-row="row" let-value="value">
            <ul class="pl-2" *ngIf="value && value !=''">
                <li class="text-lowercase" *ngFor="let val of printDirezioneFieldValue(value)">{{val}}</li>
            </ul>
        </ng-template>
        <ng-template #annualitaFieldValueTemplate let-row="row" let-value="value" let-index="index">
            <table class="w-100 table table-borderless" id="detail_importi_{{row.id}}">
                <tr class="collapse trdetail" *ngFor="let val of sortImporti(row.annualita)">

                    <td class="pt-0 pb-1 pl-0 pr-1">{{val.anno}}:</td>
                    <td class="pt-0 pb-1 text-0083e0 pl-0">{{printValueAsCurrency(val.importo)}}</td>
                    <tr>
                        <td class="pt-0 pb-1 pl-0 pr-1">
                            <a class="show-detail-anchor collapse show" data-toggle="collapse" attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i
                class="fas fa-list-ul pointer"></i></a>
                            <!--<a class="trdetail show-detail-anchor collapse show" data-toggle="collapse" attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i class="fas fa-edit pointer"></i></a>-->
                        </td>
                        <td class="pt-0 pb-1 pl-0">{{ printValueAsCurrency(value)}}</td>
                    </tr>
            </table>
        </ng-template>
        <ng-template #disponibilitaFieldValueTemplate let-row="row" let-value="value" let-index="index">
            <table class="w-100 table table-borderless" id="detail_importi_{{row.id}}">
                <tr class="collapse trdetail" *ngFor="let val of sortImporti(row.annualita)">

                    <td class="pt-0 pb-1 pl-0 pr-1">{{val.anno}}:</td>
                    <td class="pt-0 pb-1 text-0083e0 pl-0">{{printValueAsCurrency(val.disponibilita)}}</td>
                    <tr>
                        <td class="pt-0 pb-1 pl-0 pr-1">
                            <a class="show-detail-anchor collapse show" data-toggle="collapse" attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i
                class="fas fa-list-ul pointer"></i></a>
                            <!--<a class="trdetail show-detail-anchor collapse show" data-toggle="collapse" attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i class="fas fa-edit pointer"></i></a>-->
                        </td>
                        <td class="pt-0 pb-1 pl-0">{{ printValueAsCurrency(value)}}</td>
                    </tr>
            </table>
        </ng-template>
    </ngx-datatable-wrapper>

    <div class="bold text-center mb-3">Legenda:</div>
    <div class="d-flex justify-content-around mb-5 flex-wrap">


        <div><i class="fas fa-certificate font-size-18 text-3AB795 no-hover"></i><span>&nbsp;&nbsp;rilevanza territoriale</span></div>
        <div *ngIf="isAdmin() || isGr34()"><i class="fas fa-circle font-size-18 text-danger no-hover"></i><span>&nbsp;&nbsp;fabbisogno</span></div>
        <div><i class="fas fa-adjust font-size-18 no-hover"></i><span>&nbsp;&nbsp;parzialmente finanziato</span></div>
        <div><i class="far fa-circle font-size-18"></i><span>&nbsp;&nbsp;da validare</span></div>
        <div><i class="fas fa-pause-circle font-size-18 text-warning no-hover"></i><span>&nbsp;&nbsp;in attesa di
        validazione</span></div>
        <div><i class="fas fa-check-circle font-size-18 text-success no-hover"></i><span>&nbsp;&nbsp;validato</span></div>
        <div><i class="fas fa-ban font-size-18 no-hover"></i><span>&nbsp;&nbsp;rifiutato</span></div>
        <div><i class="fas fa-check-square font-size-18 text-0083e0 no-hover"></i><span>&nbsp;&nbsp;completato</span></div>

    </div>

</div>




<div class="container" [hidden]="mode !='insertedit'">
    <progetto-component [projectId]="projectId" (datiGeneraliSavedEmitter)="datiGeneraliSaved($event)" [gotoBtnOptions]="getGotoOptions()" (gotoBtnFunction)="gotoBackList($event)" #progettoContent></progetto-component>
</div>






<ng-template #templateModalInterventi>
    <div class="modal-header  text-center">
        <h4 class="modal-title pull-left">Elenco interventi</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalInterventi()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <interventi-component [gotoBtnOptions]="{show:true,label: 'Chiudi'} " (gotoBtnFunction)="closeModalInterventi($event) " #tabInterventiContent [projectId]="projectIdXInterventi" [interventoId]="projectIdXInterventi" [readOnlyForm]="true" [readOnlyFormNoDetail]="true"></interventi-component>
    </div>
</ng-template>

<ng-template #modalConfirmDelete>
    <div class="modal-header  text-center">
        <h4 class="modal-title pull-left">Conferma cancellazione progettualità {{isAdmin()||isGr34()?'/ fabbisogno':''}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeConfirmDeleteModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="deleteForm">
            <div class="col-lg-12 mt-3">
                <p>Inserire la motivazione per l'eliminazione della progettualità {{isAdmin()||isGr34()?' / fabbisogno':''}} {{deleteRowTitle}}.</p>

                <div class="form-group">

                    <label for="tipologia">
                    <h5>Motivazione:</h5>
                  </label>
                    <div [ngClass]="{ 'is-invalid': submitted && deleteForm.controls.motivazione.errors }">
                        <ng-select [items]="motivazioniCancellazione" bindValue="descrizione" bindLabel="descrizione" placeholder="Seleziona motivazione " formControlName="motivazione" (change)="setMotivazioneCancellazione($event)">
                        </ng-select>
                    </div>
                    <div *ngIf="submitted && deleteForm.controls.motivazione.errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="deleteForm.controls.motivazione.errors.required">
                            Campo obbligatorio
                        </div>
                    </div>




                </div>
                <div *ngIf="motivazioneCancellazioneSelected=='Altro'" class="form-group">
                    <label><h5>Altro:</h5></label>
                    <div>
                        <textarea maxlength="255" class="form-control" [ngClass]="{
                      'is-invalid': submitted && deleteForm.controls.txtMotivazioneCancellazione.errors
                      }" [formGroup]="deleteForm" rows="5" formControlName="txtMotivazioneCancellazione"></textarea>
                        <div *ngIf="submitted && deleteForm.controls.txtMotivazioneCancellazione.errors" class="invalidFieldMessage" style="display: block;">
                            <div *ngIf="deleteForm.controls.txtMotivazioneCancellazione.errors" class="invalid-feedback" style="display: block;">
                                Campo obbligatorio
                            </div>
                        </div>
                    </div>

                </div>



            </div>
            <div class="row justify-content-center">
                <button [disabled]="loading" (click)="doDelete()" class=" btn btn-lg btn-danger mx-1">
                    <img *ngIf="loading " class="pl-3 float-right "
                      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    Elimina
                  </button>
                <button [disabled]="loading" (click)="closeConfirmDeleteModal();" class="  btn btn-lg btn-secondary mx-1">
                    <img *ngIf="loading " class="pl-3 float-right "
                      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    Annulla
                  </button>
            </div>
        </form>
    </div>

</ng-template>
