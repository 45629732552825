<ng-template #templateEditAdd>
  <form class="row" [formGroup]="addAggregato" (ngSubmit)="onSubmit()">
    <input type="hidden" [formGroup]="addAggregato" formControlName="id" />
    <div class="form-group col-lg-6">
      <h5 class="mandatory">
        <label for="natura"> Natura intervento: </label>
      </h5>

      <div [ngClass]="{ 'is-invalid': submitted && f.natura.errors }">
        <ng-select
          [items]="naturaList"
          bindValue="key"
          bindLabel="value"
          placeholder="Seleziona natura"
          (change)="setNaturaIntervento($event)"
          formControlName="natura"
        >
        </ng-select>
      </div>
      <div
        *ngIf="submitted && f.natura.errors"
        class="invalid-feedback"
        style="display: block"
      >
        <div *ngIf="f.natura.errors.required">Campo obbligatorio</div>
      </div>
    </div>
    <div class="form-group col-lg-6">
      <h5 class="mandatory">
        <label for="tipologiaIntervento">Tipologia intervento:</label>
      </h5>

      <div
        [ngClass]="{ 'is-invalid': submitted && f.tipologiaIntervento.errors }"
      >
        <ng-select
          [items]="types"
          bindValue="key"
          bindLabel="value"
          placeholder="Seleziona tipologia "
          formControlName="tipologiaIntervento"
        >
        </ng-select>
      </div>
      <div
        *ngIf="submitted && f.tipologiaIntervento.errors"
        class="invalid-feedback"
        style="display: block"
      >
        <div *ngIf="f.tipologiaIntervento.errors.required">
          Campo obbligatorio
        </div>
      </div>
    </div>

    <div class="form-group col-lg-6">
        <h5><label for="codice" class="">Codice:</label></h5>
        <input
          [formGroup]="addAggregato"
          formControlName="codice"
          maxlength="255"
          type="text"
          value=""
          class="form-control"
          placeholder="Inserisci il codice"
          [ngClass]="{
            'form-control': true,
            'is-invalid': submitted && f.codice.errors
          }"
        />
        <div
          *ngIf="submitted && f.codice.errors"
          class="invalid-feedback"
          style="display: block"
        >
          <div *ngIf="f.codice.errors.required">Campo obbligatorio</div>
        </div>
      </div>


    <div class="form-group col-lg-6">
      <h5><label for="name" class="mandatory">Dettaglio tipologia:</label></h5>
      <input
        [formGroup]="addAggregato"
        formControlName="name"
        maxlength="255"
        type="text"
        value=""
        class="form-control"
        placeholder="Inserisci il dettaglio tipologia"
        [ngClass]="{
          'form-control': true,
          'is-invalid': submitted && f.name.errors
        }"
      />
      <div
        *ngIf="submitted && f.name.errors"
        class="invalid-feedback"
        style="display: block"
      >
        <div *ngIf="f.name.errors.required">Campo obbligatorio</div>
      </div>
    </div>
    <button
      class="btn btn-lg btn-primary btn-block ml-3 mr-3"
      type="submit"
      style="margin: 15px 0"
    >
      {{ buttonLabel }}
    </button>
  </form>
</ng-template>

<div class="container sfondo_bianco">
  <hr />
  <accordion [isAnimated]="true">
    <accordion-group heading="Inserisci un nuovo dettaglio tipologia">
      <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
    </accordion-group>
  </accordion>
  <div class="mt-5">
    <ngx-datatable-wrapper
      #ngxDatatableWrapper
      [templates]="templateList"
      style="width: 100%; height: auto"
      [dtOptions]="dtOptions"
    >
      <ng-template #btnEditTemplate let-row="row" let-value="value">
        <a
          [ngClass]="{
            disabled: loading
          }"
          class="border-0 text-center"
          (click)="onEdit(row)"
          data-toggle="tooltip"
          data-placement="top"
          title="modifica dati"
          >&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i
        ></a>
      </ng-template>
      <ng-template #btnDeleteTemplate let-row="row" let-value="value">
        <a
          [ngClass]="{
            disabled: loading
          }"
          class="border-0 text-center"
          (click)="onDelete(row)"
          data-toggle="tooltip"
          data-placement="top"
          title="elimina"
          >&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i
        ></a>
      </ng-template>
    </ngx-datatable-wrapper>
  </div>
</div>
<ng-template #templateModal>
  <div class="modal-header text-center">
    <h4 class="modal-title pull-left">Modifica dettaglio tipologia</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
  </div>
</ng-template>
