import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { CapitoloBilancioService } from "../../../services";
import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
  ValidatorFn,
} from "@angular/forms";
import { ProvvedimentoCapitoloBilancio } from "../../../models";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastService } from "src/app/services";

@Component({
  templateUrl:
    "./provvedimento-capitolo-bilancio-selection-modal.component.html",
  styleUrls: [
    "./provvedimento-capitolo-bilancio-selection-modal.component.css",
  ],
})
export class ProvvedimentoCapitoloBilancioSelectionModalComponent
  implements OnInit {
  capitoli = [];
  myGroup: FormGroup;
  submitted: boolean = false;

  anniList = [];
  minYear: number = 1950;
  maxYear: number = new Date().getFullYear();

  completerParams = {
    labelField: "descrizioneEstesa",
    placeholder: "Cerca capitolo bilancio",
    ajax: null,
  };
  @Output() onSubmitOk = new EventEmitter();

  constructor(
    private capitoloBilancioService: CapitoloBilancioService,
    private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private toastr: ToastService
  ) {}

  ngOnInit(): void {
    this.myGroup = this.initializeFormGroup();
    this.completerParams = {
      labelField: "descrizioneEstesa",
      ajax: this.getCapitoliBilancio,
      placeholder: "Cerca capitolo bilancio",
    };
    //this.getCapitoliBilancio();
  }

  initializeFormGroup() {
    return this.formBuilder.group({
      //capitolo: [null, [Validators.required]],
      importo: [null, [Validators.required]],
      anno: [
        null,
        [
          Validators.required /*, Validators.minLength(4), Validators.maxLength(4), this.checkAnnoRiferimentoRangeValidator(this.minYear, this.maxYear)*/,
        ],
      ],
    });
  }

  checkAnnoRiferimentoRangeValidator(
    minYear: number,
    maxYear: number
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      console.log("inside checkAnnoRiferimentoRangeValidator ");
      if (!control || !control.parent) return null;
      let value = parseInt(control.value);
      if (isNaN(value) || value < minYear || value > maxYear)
        return { range: true };
      return null;
    };
  }

  getCapitoliBilancio = (filter: string, callback: any) => {
    this.capitoloBilancioService.getAll(filter).subscribe((res) => {
      res.forEach(
        (x) => (x["descrizioneEstesa"] = `${x.codice} - ${x.descrizione}`)
      );
      this.capitoli = res;
      callback(res);
    });
  };

  get f() {
    return this.myGroup.controls;
  }

  add() {
    console.log("onSubmit");
    this.submitted = true;

    var errors = false;
    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }

    console.log("errors:" + errors);
    if (this.myGroup.invalid || errors || !this.capitoloSelected) {
      /*if (!this.capitoloSelected) {
                    
                }*/
      console.log("invalid");
      return false;
    }

    let data = new ProvvedimentoCapitoloBilancio();
    //data.capitoloBilancioId = this.f.capitolo.value;
    data.capitoloBilancioId = this.capitoloSelected.id;
    let capitoloSelezionato = this.capitoli.find(
      (x) => x.id == data.capitoloBilancioId
    );
    data.capitoloBilancioDescrizione = capitoloSelezionato.descrizione;
    data.capitoloBilancioCodice = capitoloSelezionato.codice;
    data.anno = this.f.anno.value;
    data.importo = this.f.importo.value;
    let importoCapitoloDiBilancio = this.capitoloSelected.annualita.find(
      (x) => x.anno == data.anno
    ).stanziamentoCompetenza;
    if (importoCapitoloDiBilancio < data.importo) {
      this.toastr.error(
        "L'importo immesso è maggiore dello stanziamento del capitolo di bilancio",
        null,
        {
          timeOut: 4000,
          /*closeButton:true,*/ disableTimeOut: false,
        }
      );
      return;
    }

    this.onSubmitOk.emit(data);
  }

  capitoloSelected: any;
  capitoloOnChange($event) {
    this.capitoloSelected = $event;
    console.log("here");
    this.anniList = [];
    if ($event.annualita) {
      $event.annualita.forEach((element) => {
        this.anniList.push({ k: element.anno, v: element.anno });
      });
    }
    this.anniList.sort((a, b) => a.k - b.k);
  }

  modalSubscriptions = [];
  modalRef: BsModalRef;

  @ViewChild("templateEditCapitoloBilancio", { static: true })
  public templateEditCapitoloBilancio: TemplateRef<any>;

  closeModalEditCapitoliBilancio() {
    this.modalRef.hide();
  }

  refreshCapitoloBilancio($event) {
    this.closeModalEditCapitoliBilancio();
    this.capitoloOnChange($event);
  }
  openEditCapitoliBilancio() {
    if (!this.capitoloSelected) return;

    let config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: "modal-xl",
    };
    this.modalRef = this.modalService.show(
      this.templateEditCapitoloBilancio,
      config
    );
  }
}
