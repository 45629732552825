<ng-template #btnDeleteProsaTemplate let-row="row" let-value="value">
    <a class=" border-0 text-center" (click)="deleteProsaDocument(row)" data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
</ng-template>

<ng-template #numeroProtocolloTemplate let-row="row">
    <a class="pointer font-weight-bold text-0083e0" title="mostra allegati" data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.dtw_tr_collapse_' + row.id" [attr.data-target]="'.dtw_tr_collapse_' + row.id">
        <i class="fas fa-list"></i>&nbsp;{{row.numeroProtocollo}}</a>
    <br />
    <div id="documenti-{{row.annoProtocollo}}-{{row.numeroProtocollo}}" class="collapse {{'dtw_tr_collapse_' + row.id}}">

        <p style="color: red;" *ngIf="row.messaggio && row.messaggio !=''">{{row.messaggio}}</p>
        <ul class="pl-2" *ngIf="!(row.messaggio && row.messaggio !='')">
            <li class="text-lowercase" *ngFor="let allegato of row.allegati">
                <a href="javascript:;" (click)="downloadDocumento(allegato.codice, allegato.nome)">{{allegato.nome}}</a>
            </li>
        </ul>
    </div>
</ng-template>
<ng-template #noteProtocolloTemplate let-row="row" let-value="value">
    <a class=" border-0 text-center" (click)="editProsaDocument(row)" data-toggle="tooltip" data-placement="top" title="aggiorna note"><i class="far fa-edit pointer"></i></a><span class="help" title="{{value}}">{{trimText(value,100)}}</span>
</ng-template>

<ng-template #oggettoProtocolloTemplate let-row="row" let-value="value">
    <span class="help" title="{{value}}">{{trimText(value,100)}}</span>
</ng-template>

<div class="p-5 container-xl text-center" [hidden]="loadDataCounter<=0">
    <app-loading message="Caricamento in corso..."></app-loading>
  </div>
  <div class="container-xl" [hidden]="loadDataCounter>0">
  
    <div class="position-sticky sticky-top  pt-4 pb-4 elemento-aggiorna mb-5 shadow-custom"
      [ngClass]="{'sticky-top-custom-modal': currentIntervento && !currentIntervento.isMain,'sticky-top-custom':!currentIntervento ||( currentIntervento && currentIntervento.isMain) }">
      <div class=" row  justify-content-center">
        <div class="col-lg-4 col-md-6 text-center" [hidden]="readOnlyForm">
          <button [disabled]="loading" (click)="openAddModal(1)" class="btn btn-primary btn-block" type="button">
            Aggiungi nuovo SAL/Altro&nbsp;<i class="fas fa-plus"></i>
          </button>
        </div>
        <div class="col-lg-4 col-md-6 text-center" [hidden]="readOnlyForm">
          <button [disabled]="loading" (click)="openAddModal(0)" class="btn btn-primary btn-block" type="button">
            Aggiungi nuovo giustificativo&nbsp;<i class="fas fa-plus"></i>
          </button>
        </div>
        <div class="col-lg-4 col-md-6 text-center" *ngIf="gotoBtnOptions && gotoBtnOptions.show">
          <button (click)="gotoBackBtnClick()" [disabled]="loading" class="btn btn-secondary btn-block font-weight-bold"
            type="button">
            {{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}&nbsp;<i class="fas fa-times"
              *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i>
          </button>
        </div>
      </div>
    </div>
    <!--***-->
  
  
  
    <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;"
      [dtOptions]="dtOptions">
      <ng-template #btnEditTemplate let-row="row" let-value="value">
        <a [ngClass]="{
                    'disabled': loading
                    }" class="  border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top"
          title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
      </ng-template>
      <ng-template #btnDeleteTemplate let-row="row" let-value="value">
        <a [ngClass]="{
                    'disabled': loading
                    }" class="  border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top"
          title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
      </ng-template>
      <ng-template #btnDetailTemplate let-row="row" let-value="value">
        <a [ngClass]="{
              'disabled': loading
              }" class="  border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top"
          title="dettaglio">&nbsp;&nbsp;&ensp;<i class="far fa-eye pointer detail-icon"></i></a>
      </ng-template>
  
      <ng-template #salGiustificativoTemplate let-row="row" let-value="value">
        {{value?'SAL/Altro':'Giustificativo'}}
      </ng-template>
      
    </ngx-datatable-wrapper>
  </div>
  
  
  <ng-template #templateEditAdd>
    <div class="modal-header  text-center">
      <h4 class="modal-title pull-left">{{modalTitle}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
        <input type="hidden" [formGroup]="editForm" formControlName="id" />
  
        <div class="col-lg-12 mt-3">
  
          <div class="form-group row">
            <div class="col-lg-12">
              <label for="categoriaId">
                <h5 class="mandatory">Categoria:</h5>
              </label>
              <div [ngClass]="{ 'is-invalid': submitted && editForm.controls['categoriaId'].errors }">
                <ng-select [formGroup]="editForm" formControlName="categoriaId" [items]="categorieSal" bindValue="id"
                  bindLabel="titolo" placeholder="Seleziona la tipologia">
                </ng-select>
              </div>
              <div *ngIf="submitted && editForm.controls['categoriaId'].errors" class="invalidFieldMessage"
                style="display: block;">
                <div *ngIf="editForm.controls['categoriaId'].errors" class="invalid-feedback" style="display: block;">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>
  
          <div class="form-group row">
            <div class="col-lg-12">
              <label for="importo">
                <h5 class="mandatory">Importo:</h5>
              </label>
              <input [formGroup]="editForm" formControlName="importo" maxlength="255" type="text" class="form-control"
                placeholder="Inserisci l'importo" [ngClass]="{
                                        'is-invalid': submitted && editForm.controls.importo.errors
                                        }" [imask]="mask" [unmask]="true" />
              <div *ngIf="submitted && editForm.controls.importo.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="editForm.controls.importo.errors.required">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>
  
       
          <div class="form-group row">
            <div class="col-sm-12">
              <label for="descrizione">
                <h5 class="">Descrizione:</h5>
              </label>
              <textarea rows="3" [formGroup]="editForm" formControlName="descrizione" maxlength="255" type="text"
                class="form-control" placeholder="Inserisci la descrizione" [ngClass]="{
                                        'is-invalid': submitted && editForm.controls.descrizione.errors
                                        }"></textarea>
              <div *ngIf="submitted && editForm.controls.descrizione.errors" class="invalid-feedback"
                style="display: block;">
                <div *ngIf="editForm.controls.descrizione.errors.required">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>
  
          
  
          <div class="row" [hidden]="true">
            <div class="form-group col-sm-6" [ngClass]="{
        'is-invalid': submitted && editForm.controls.anticipazioneASoggettoAttuatore.errors
        }">
              <label for="" class="">
                <h5 class="mandatory">Somme a soggetto attuatore:</h5>
              </label>
              <div>
                <div class="form-check  form-check-inline">
  
                  <input [formGroup]="editForm" id="anticipazioneASoggettoAttuatore1"
                    formControlName="anticipazioneASoggettoAttuatore" type="radio" value="true" class="form-check-input"
                    [ngClass]="{
                          'is-invalid': submitted && editForm.controls.anticipazioneASoggettoAttuatore.errors
                          }" />
  
                  <label for="anticipazioneASoggettoAttuatore1" class="form-check-label">Si</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [formGroup]="editForm" id="anticipazioneASoggettoAttuatore2"
                    formControlName="anticipazioneASoggettoAttuatore" type="radio" value="false" class="form-check-input"
                    [ngClass]="{
                                        'is-invalid': submitted && editForm.controls.anticipazioneASoggettoAttuatore.errors
                                        }" />
                  <label for="anticipazioneASoggettoAttuatore2" class="form-check-label">No</label>
                </div>
              </div>
              <div *ngIf="submitted && editForm.controls.anticipazioneASoggettoAttuatore.errors" class="invalid-feedback"
                style="display: block;">
                <div *ngIf="editForm.controls.anticipazioneASoggettoAttuatore.errors.required">
                  Campo obbligatorio
                </div>
  
              </div>
            </div>
  
  
  
  
  
          </div>
  
          
  
          <div class="form-group row">
            <div class="col-sm-12">
              <label for="dataLavori">
                <h5 class="mandatory">
                  {{editForm.controls.anticipazioneASoggettoAttuatore.value && editForm.controls.anticipazioneASoggettoAttuatore.value.toString()=="true"?'Data competenza del SAL/Altro':'Data giustificativo'}}:
                </h5>
              </label>
              <div [ngClass]="{
                        'is-invalid': submitted && editForm.controls.dataLavori.errors
                        }" class="col-lg-12 col-md-6 col-sm-8 col-xs-7"
                style="display:flex; padding-right:0px;padding-left:0px">
                <input type="text" formControlName="dataLavori" class="form-control" bsDatepicker [formGroup]="editForm"
                  [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY',containerClass:'theme-dark-blue' }"
                  #dataLavori="bsDatepicker" style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
                <button class="btn btn-primary" (click)="dataLavori.toggle()" type="button"
                  style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
  
              <div *ngIf="submitted && editForm.controls.dataLavori.errors" class="invalid-feedback"
                style="display: block;">
                <div *ngIf="editForm.controls.dataLavori.errors.required">
                  Campo obbligatorio
                </div>
  
              </div>
            </div>
          </div>
  
          <div class="form-group row"
            *ngIf="editForm.controls.anticipazioneASoggettoAttuatore.value && editForm.controls.anticipazioneASoggettoAttuatore.value.toString()=='true'">
            <div class="col-sm-12">
              <label for="dataEmissioneCertificatoPagamento">
                <h5 class="">Data emissione certificato pagamento:</h5>
              </label>
              <div [ngClass]="{
                        'is-invalid': submitted && editForm.controls.dataEmissioneCertificatoPagamento.errors
                        }" class="col-lg-12 col-md-6 col-sm-8 col-xs-7"
                style="display:flex; padding-right:0px;padding-left:0px">
                <input type="text" formControlName="dataEmissioneCertificatoPagamento" class="form-control" bsDatepicker
                  [formGroup]="editForm"
                  [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY',containerClass:'theme-dark-blue' }"
                  #dataEmissioneCertificatoPagamento="bsDatepicker"
                  style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
                <button class="btn btn-primary" (click)="dataEmissioneCertificatoPagamento.toggle()" type="button"
                  style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
  
              <div *ngIf="submitted && editForm.controls.dataEmissioneCertificatoPagamento.errors"
                class="invalid-feedback" style="display: block;">
                <div *ngIf="editForm.controls.dataEmissioneCertificatoPagamento.errors.required">
                  Campo obbligatorio
                </div>
  
              </div>
            </div>
          </div>
          <div class="form-group row"
            *ngIf="editForm.controls.anticipazioneASoggettoAttuatore.value && editForm.controls.anticipazioneASoggettoAttuatore.value.toString()=='true'">
            <div class="col-sm-12">
              <label for="dataQuietanza">
                <h5 class="">Data quietanza:</h5>
              </label>
              <div [ngClass]="{
                        'is-invalid': submitted && editForm.controls.dataQuietanza.errors
                        }" class="col-lg-12 col-md-6 col-sm-8 col-xs-7"
                style="display:flex; padding-right:0px;padding-left:0px">
                <input type="text" formControlName="dataQuietanza" class="form-control" bsDatepicker
                  [formGroup]="editForm"
                  [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY',containerClass:'theme-dark-blue' }"
                  #dataQuietanza="bsDatepicker" style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
                <button class="btn btn-primary" (click)="dataQuietanza.toggle()" type="button"
                  style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>
  
              <div *ngIf="submitted && editForm.controls.dataQuietanza.errors" class="invalid-feedback"
                style="display: block;">
                <div *ngIf="editForm.controls.dataQuietanza.errors.required">
                  Campo obbligatorio
                </div>
  
              </div>
            </div>
          </div>
        </div>
  
        <!--a fronte del sal-->
        <div class="col-lg-12 mt-5" *ngIf="!(editForm.controls.anticipazioneASoggettoAttuatore.value && editForm.controls.anticipazioneASoggettoAttuatore.value.toString()=='true')">
          <hr>
        </div>
        <div class="col-lg-12 mt-4"  *ngIf="!(editForm.controls.anticipazioneASoggettoAttuatore.value && editForm.controls.anticipazioneASoggettoAttuatore.value.toString()=='true')">
          <div class="row justify-content-between">
  
            <div class="col-lg-6 col-sm-6 col-6 mt-3">
              <h5 for="" class="">A fronte del SAL/Altro</h5>
            </div>
            <div class="col-lg-1 col-sm-2 col-3 text-center mt-3" [hidden]="readOnlyForm">
              <a href="javascript:;;" class="bottone-inserisci" (click)="openModalSAL();"><i class="fas fa-plus"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-12 mt-3 table-responsive-lg"  *ngIf="!(editForm.controls.anticipazioneASoggettoAttuatore.value && editForm.controls.anticipazioneASoggettoAttuatore.value.toString()=='true')">
          <table class="table table-hover table-colore" [ngClass]="{
              'is-invalid': submitted && salListIsError
              }">
              <thead class="">
                  <th>categoria</th>
                  <th>importo</th>
                  <th>data<br />competenza SAL/Altro</th>
                  <th>descrizione</th>
                  <!--<th>somme<br />a soggetto attuatore</th>-->
                  <th class="casella-icona-tx" [hidden]="(readOnlyForm)">elimina</th>
              </thead>
              <tbody>
                  <ng-container *ngIf="salList && salList.length && salList.length>0">
                      <tr *ngFor="let item of salList">
                          <td>{{item.categoria.titolo}}</td>
                          <td>{{printValueAsCurrency(item.importo)}}</td>
                          <td>{{printValueAsDate(item.dataLavori)}}</td>
                          <td>{{item.descrizione}}</td>
                          <!--<td>{{printValueAsBoolean(item.anticipazioneASoggettoAttuatore)}}</td>-->
                          <td [hidden]="(readOnlyForm )"><a (click)="removeSal(item)" href="javascript:;;" data-toggle="tooltip" data-placement="top" title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i> </a>
                          </td>
                      </tr>
                  </ng-container>
                  <tr *ngIf="!(salList && salList.length && salList.length>0)">
                      <td colspan="5">Nessun sal/Altro selezionato</td>
                  </tr>
              </tbody>
          </table>
          <div *ngIf="submitted && salListIsError" class="invalidFieldMessage">
              <div *ngIf="salListIsError" class="invalid-feedback" style="display: block;">
                  Selezionare almeno un Sal/Altro
              </div>
          </div>
      </div>
        <!--fine a fronte del sal-->
  
          <!--protocolli-->
  
          <div class="col-lg-12 mt-5" *ngIf="editForm.controls.id.value && editForm.controls.id.value!=''">
              <hr>
            </div>
  
  
            <div class="col-lg-12 mt-4"  *ngIf="editForm.controls.id.value && editForm.controls.id.value!=''">
              <div class="row justify-content-between">
      
                <div class="col-lg-6 col-sm-6 col-6 mt-3">
                  <h5 for="" class="">Protocolli</h5>
                </div>              
              </div>
            </div>
  
  
  <!--div protocolli-->
  <div class="col-lg-12 "  >
      <div class="" [hidden]="readOnlyForm">
          <form [formGroup]="frm_protocolli">
              <!-- *****  -->
              <!--<div class="row justify-content-between">
              <div class="col-lg-6 col-sm-6 col-6 mt-3">
                  <h5 class="" for="">Cerca Protocollo</h5>
              </div>
          </div>-->
  
              <div class="form-row">
                  <div class="form-group col-md-4">
                      <label for="inputAnno4"><h5>Anno:</h5></label>
                      <input type="text" [formGroup]="frm_protocolli" formControlName="protocollo_anno" class="form-control" />
                  </div>
                  <div class="form-group col-md-5">
                      <label for="inputProtocollo4"><h5>N° Protocollo:</h5></label>
                      <input type="text" [formGroup]="frm_protocolli" formControlName="protocollo_numero" class="form-control" />
                  </div>
                  <div class="form-group col-md-3 ">
                      <label for="buttonCerca1">
                        <h5 class="d-none d-sm-block">&nbsp;</h5>
                      </label>
  
                      <button [disabled]="loading || !frm_protocolli.controls['protocollo_anno'] || !frm_protocolli.controls['protocollo_anno'].value || frm_protocolli.controls['protocollo_anno'].value.length!=4 || !frm_protocolli.controls['protocollo_numero'] || !frm_protocolli.controls['protocollo_numero'].value || frm_protocolli.controls['protocollo_numero'].value.length<=0 "
                          type="button" class="btn btn-info btn-block bold" (click)="acquisisciProtocolli()">
                      Cerca&nbsp;<i class="fas fa-search pointer"
                      style="color:#fff; font-size: 18px;"></i>
                      <img
                        *ngIf="loading"
                        class="pl-3 float-right"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                      />
                    </button>
  
  
                  </div>
              </div>
  
  
  
  
  
  
  
          </form>
  
  
          <div class="col-lg-12 mt-3 table-responsive-lg">
              <table class="table table-hover table-colore">
                  <thead class="">
                      <th scope="col">tipologia</th>
                      <th scope="col">numero&nbsp;protocollo</th>
                      <th scope="col">data</th>
                      <th class="casella-descrizione-tx" scope="col" class="casella-descrizione-tx">oggetto</th>
                      <th class="casella-icona-tx" scope="col" class="casella-icona-tx">aggiungi</th>
  
                  </thead>
                  <tbody>
                      <ng-container *ngIf="protocolli && protocolli.length>0">
                          <tr *ngFor="let item of protocolli;let i = index;">
                              <td class="casella-icona-tx">{{item.tipoProtocollo}}</td>
                              <td>
  
                                  <a class="pointer font-weight-bold text-0083e0 " title="mostra allegati" data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.tr_collapse_' + i" [attr.data-target]="'.tr_collapse_' + i">
                                      <i class="fas fa-list"></i>&nbsp;{{item.numeroProtocollo}}
                                  </a>
                                  <br />
                                  <div class="ml-0" id="documenti-{{item.annoProtocollo}}-{{item.numeroProtocollo}}" class="collapse {{'tr_collapse_' + i}}">
  
                                      <p style="color: red;" *ngIf="item.messaggio && item.messaggio !=''">{{item.messaggio}}</p>
                                      <ul class="pl-2" *ngIf="!(item.messaggio && item.messaggio !='')">
                                          <li class="text-lowercase" *ngFor="let allegato of item.allegati">
                                              <small><a href="javascript:;" (click)="downloadDocumento(allegato.codice, allegato.nome)">{{allegato.nome}}</a></small>
                                          </li>
                                      </ul>
  
  
                                  </div>
  
                              </td>
                              <td><small>{{printDateValue(item.dataProtocollo)}}</small></td>
                              <td><small>{{item.oggetto}}</small></td>
                              <td>
                                  &nbsp;&nbsp;&nbsp;&nbsp;<a class="bottone-inserisci" (click)="aggiungiProtocollo(item.numeroProtocollo);" href="javascript:;;"><i
                      class="fas fa-plus "></i></a>
                              </td>
                          </tr>
                      </ng-container>
                      <tr *ngIf="(!protocolli || protocolli.length <=0)">
                          <td colspan="4">Nessun protocollo trovato</td>
                      </tr>
                  </tbody>
              </table>
  
          </div>
  
  
  
  
  
      </div>
  
      <!--accordion-->
      <accordion [isAnimated]="true">
          <accordion-group heading="Protocolli in Entrata ({{protocolliEntrata && protocolliEntrata.length? protocolliEntrata.length:0}})">

            <div class="col-lg-12 mt-3 table-responsive-lg ">

                <table class="table table-hover table-colore ">
                  <thead>
                    <tr>
                      <th scope="col ">numero protocollo</th>
                      <th scope="col ">data<br />protocollo</th>
                      <th scope="col " >data<br />acquisizione</th>
                      <th scope="col ">oggetto</th>
                      <th scope="col ">note</th>
                      <th scope="col " class="casella-icona-tx " [hidden]="readOnlyForm ">elimina</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngIf=" protocolliEntrata &&  protocolliEntrata.length &&  protocolliEntrata.length>0">
                      <tr *ngFor="let item of  protocolliEntrata">
                        <td><small>
                            <a class="pointer font-weight-bold text-0083e0" title="mostra allegati" data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.dtw_tr_collapse_' + item.id" [attr.data-target]="'.dtw_tr_collapse_' + item.id">
                                <i class="fas fa-list"></i>&nbsp;{{item.numeroProtocollo}}</a>
                            <br />
                            <div id="documenti-{{item.annoProtocollo}}-{{item.numeroProtocollo}}" class="collapse {{'dtw_tr_collapse_' + item.id}}">
                        
                                <p style="color: red;" *ngIf="item.messaggio && item.messaggio !=''">{{row.messaggio}}</p>
                                <ul class="pl-2" *ngIf="!(item.messaggio && item.messaggio !='')">
                                    <li class="text-lowercase" *ngFor="let allegato of item.allegati">
                                        <a href="javascript:;" (click)="downloadDocumento(allegato.codice, allegato.nome)">{{allegato.nome}}</a>
                                    </li>
                                </ul>
                            </div> 
                            </small>                       
                        </td>
                        <td><small>{{printDateValue(item.dataProtocollo)}}</small></td>
                        <td><small>{{printDateValue(item.dataAcquisizione)}}</small></td>
                        <td><small><span class="help" title="{{item.oggetto}}">{{trimText(item.oggetto,100)}}</span></small></td>
                        <td><small><a class=" border-0 text-center" (click)="editProsaDocument(item)" data-toggle="tooltip" data-placement="top" title="aggiorna note"><i class="far fa-edit pointer"></i></a><span class="help" title="{{item.note}}">{{trimText(item.note,100)}}</span></small></td>                                                            
                        <td [hidden]="readOnlyForm"><a (click)="deleteProsaDocument(item)"
                            href="javascript:;;" data-toggle="tooltip" data-placement="top" title="elimina"><i
                              class="far fa-trash-alt casella-icona-tx"></i>
                          </a>
                        </td>
                      </tr>
                    </ng-container>
                    <tr
                      *ngIf="!(protocolliEntrata && protocolliEntrata.length &&  protocolliEntrata.length>0)">
                      <td colspan="6">Nessun protocollo presente</td>
                    </tr>
                  </tbody>
                </table>                
              </div>



             
          </accordion-group>
      </accordion>
      <accordion [isAnimated]="true">
          <accordion-group heading="Protocolli in Uscita ({{protocolliUscita &&  protocolliUscita.length?protocolliUscita.length:0}})">
              
            <div class="col-lg-12 mt-3 table-responsive-lg ">

                <table class="table table-hover table-colore ">
                  <thead>
                    <tr>
                      <th scope="col ">numero protocollo</th>
                      <th scope="col ">data<br />protocollo</th>
                      <th scope="col " >data<br />acquisizione</th>
                      <th scope="col ">oggetto</th>
                      <th scope="col ">note</th>
                      <th scope="col " class="casella-icona-tx " [hidden]="readOnlyForm ">elimina</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngIf="protocolliUscita &&  protocolliUscita.length &&  protocolliUscita.length>0">
                      <tr *ngFor="let item of  protocolliUscita">
                        <td><small>
                            <a class="pointer font-weight-bold text-0083e0" title="mostra allegati" data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.dtw_tr_collapse_' + item.id" [attr.data-target]="'.dtw_tr_collapse_' + item.id">
                                <i class="fas fa-list"></i>&nbsp;{{item.numeroProtocollo}}</a>
                            <br />
                            <div id="documenti-{{item.annoProtocollo}}-{{item.numeroProtocollo}}" class="collapse {{'dtw_tr_collapse_' + item.id}}">
                        
                                <p style="color: red;" *ngIf="item.messaggio && item.messaggio !=''">{{row.messaggio}}</p>
                                <ul class="pl-2" *ngIf="!(item.messaggio && item.messaggio !='')">
                                    <li class="text-lowercase" *ngFor="let allegato of item.allegati">
                                        <a href="javascript:;" (click)="downloadDocumento(allegato.codice, allegato.nome)">{{allegato.nome}}</a>
                                    </li>
                                </ul>
                            </div> 
                            </small>                       
                        </td>
                        <td><small>{{printDateValue(item.dataProtocollo)}}</small></td>
                        <td><small>{{printDateValue(item.dataAcquisizione)}}</small></td>
                        <td><small><span class="help" title="{{item.oggetto}}">{{trimText(item.oggetto,100)}}</span></small></td>
                        <td><small><a class=" border-0 text-center" (click)="editProsaDocument(item)" data-toggle="tooltip" data-placement="top" title="aggiorna note"><i class="far fa-edit pointer"></i></a><span class="help" title="{{item.note}}">{{trimText(item.note,100)}}</span></small></td>                                                            
                        <td [hidden]="readOnlyForm"><a (click)="deleteProsaDocument(item)"
                            href="javascript:;;" data-toggle="tooltip" data-placement="top" title="elimina"><i
                              class="far fa-trash-alt casella-icona-tx"></i>
                          </a>
                        </td>
                      </tr>
                    </ng-container>
                    <tr
                      *ngIf="!(protocolliUscita &&  protocolliUscita.length &&  protocolliUscita.length>0)">
                      <td colspan="6">Nessun protocollo presente</td>
                    </tr>
                  </tbody>
                </table>                
              </div>


          </accordion-group>
      </accordion>
      <accordion [isAnimated]="true">
          <accordion-group heading="Protocolli Interni ({{protocolliInterni &&  protocolliInterni.length? protocolliInterni.length:0}})">
            <div class="col-lg-12 mt-3 table-responsive-lg ">

                <table class="table table-hover table-colore ">
                  <thead>
                    <tr>
                      <th scope="col ">numero protocollo</th>
                      <th scope="col ">data<br />protocollo</th>
                      <th scope="col " >data<br />acquisizione</th>
                      <th scope="col ">oggetto</th>
                      <th scope="col ">note</th>
                      <th scope="col " class="casella-icona-tx " [hidden]="readOnlyForm ">elimina</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngIf="protocolliInterni &&  protocolliInterni.length &&  protocolliInterni.length>0">
                      <tr *ngFor="let item of  protocolliInterni">
                        <td><small>
                            <a class="pointer font-weight-bold text-0083e0" title="mostra allegati" data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.dtw_tr_collapse_' + item.id" [attr.data-target]="'.dtw_tr_collapse_' + item.id">
                                <i class="fas fa-list"></i>&nbsp;{{item.numeroProtocollo}}</a>
                            <br />
                            <div id="documenti-{{item.annoProtocollo}}-{{item.numeroProtocollo}}" class="collapse {{'dtw_tr_collapse_' + item.id}}">
                        
                                <p style="color: red;" *ngIf="item.messaggio && item.messaggio !=''">{{row.messaggio}}</p>
                                <ul class="pl-2" *ngIf="!(item.messaggio && item.messaggio !='')">
                                    <li class="text-lowercase" *ngFor="let allegato of item.allegati">
                                        <a href="javascript:;" (click)="downloadDocumento(allegato.codice, allegato.nome)">{{allegato.nome}}</a>
                                    </li>
                                </ul>
                            </div> 
                            </small>                       
                        </td>
                        <td><small>{{printDateValue(item.dataProtocollo)}}</small></td>
                        <td><small>{{printDateValue(item.dataAcquisizione)}}</small></td>
                        <td><small><span class="help" title="{{item.oggetto}}">{{trimText(item.oggetto,100)}}</span></small></td>
                        <td><small><a class=" border-0 text-center" (click)="editProsaDocument(item)" data-toggle="tooltip" data-placement="top" title="aggiorna note"><i class="far fa-edit pointer"></i></a><span class="help" title="{{item.note}}">{{trimText(item.note,100)}}</span></small></td>                                                            
                        <td [hidden]="readOnlyForm"><a (click)="deleteProsaDocument(item)"
                            href="javascript:;;" data-toggle="tooltip" data-placement="top" title="elimina"><i
                              class="far fa-trash-alt casella-icona-tx"></i>
                          </a>
                        </td>
                      </tr>
                    </ng-container>
                    <tr
                      *ngIf="!(protocolliInterni &&  protocolliInterni.length &&  protocolliInterni.length>0)">
                      <td colspan="6">Nessun protocollo presente</td>
                    </tr>
                  </tbody>
                </table>                
              </div>
          </accordion-group>
      </accordion>
  
  </div>
  
  <!--fine div protocolli-->
  
  
  
  
  
  
  
  
  
  
  
  
  
        <div class="col-lg-12 mt-5">
          <hr>
        </div>
  
        <!--documenti-->
        <div class="col-lg-12 mt-4">
          <div class="row justify-content-between">
  
            <div class="col-lg-6 col-sm-6 col-6 mt-3">
              <h5 for="" class="">Documenti</h5>
            </div>
            <div class="col-lg-1 col-sm-2 col-3 text-center mt-3" [hidden]="readOnlyForm">
              <a href="javascript:;;" class="bottone-inserisci" (click)="addDocumento();"><i class="fas fa-plus"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-12 mt-3 table-responsive-lg">
          <table class="table table-hover table-colore">
            <thead class="">
              <th>documento</th>
              <th class="casella-icona-tx">scarica</th>
              <th class="casella-icona-tx" [hidden]="readOnlyForm">elimina</th>
            </thead>
            <tbody>
              <ng-container *ngIf="documenti && documenti.length && documenti.length>0 && hasDocuments()">
                <ng-container *ngFor="let item of documenti">
  
  
                  <tr *ngIf="item.status!='delete'">
                    <td>
                      <uploadfile [externalConfig]="{elementName:item.id
                            ,hideUploadButton:true,
                            doUploadOnFileChange:true
                            ,mandatory:false
                            ,apiUrl:uploadFileUrl  ,
                            maxSize:50,
                            accept:[],
                            fileExists:item.filename && item.filename!='',
                            hideDownloadButton:true,
                            fixedFilename:item.originalFileName
                            }" (onAfterUpload)="onAfterFileUpload($event)" (onDelete)="onDeleteFile($event)"
                        (onDownload)="onDownloadFile($event)" (onFileChanged)="onFileChanged($event)"></uploadfile>
                    </td>
                    <td><a *ngIf="item.filename!='' && item.status!='update'" (click)="onDownloadFile(item)"
                        href="javascript:;;" data-toggle="tooltip" data-placement="top" title="Scarica"><i
                          class="fas fa-download casella-icona-tx"></i> </a>
                    </td>
                    <td [hidden]="readOnlyForm"><a (click)="removeDocumento(item)" href="javascript:;;"
                        data-toggle="tooltip" data-placement="top" title="elimina"><i
                          class="far fa-trash-alt casella-icona-tx"></i> </a>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              <tr *ngIf="!(documenti && documenti.length && documenti.length>0 && hasDocuments()  )">
                <td colspan="5">Nessun documento inserito</td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <div class="col-lg-12 mt-3" [hidden]="readOnlyForm"> <button [disabled]="loading"
            class="btn btn-lg btn-primary btn-block" type="submit">
            <img *ngIf="loading" class="pl-3 float-right"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            {{modalButtonLabel}}
          </button></div>
      </form>
    </div>
  </ng-template>
  
  <ng-template #templateEditNoteModal>
    <div class="modal-header  text-center">
        <h4 class="modal-title pull-left">Modifica note</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalEditProsaNote()">
    <span aria-hidden="true">&times;</span>
  </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="editNoteProtocollo">
            <input type="hidden" [formGroup]="editNoteProtocollo" formControlName="id" />
            <div class="form-group">
                <div class="col-12">
                    <h5><label for="note" class="">Note:</label></h5>
                    <textarea [formGroup]="editNoteProtocollo" rows="3" formControlName="note_protocollo" maxlength="255" type="text" value="" class="form-controlh" placeholder="Inserisci le note" [ngClass]="{
                'form-control': true                
                }"></textarea>
                </div>
            </div>


            <div class="col-12 mb-3">
                <button class="btn btn-lg btn-primary btn-block" type="button" style="margin:15px 0" (click)="salvaNoteProtocollo()">
                Salva
            </button></div>
        </form>
    </div>
</ng-template>