import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { InterventoService,ProgettoService } from '../../services';
import * as FileSaver from "file-saver";

@Component({
  selector: 'dnsh-component',
  templateUrl: './dnsh.component.html',
  styleUrls: ['./dnsh.component.css']
})
export class DnshComponent implements OnInit {

  @Input()
  interventoId: number;
  @Input()
  gotoBtnOptions: any;

  @Output()
  gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();

  attach: any;
  addDocumentForm: FormGroup;
  submitted = false;
  readOnlyForm = false;
  showAllData = false;

  templateList = [];
  dtOptionsNgx: any;

  _isInModal: boolean = false;
  @Input()
  set isInModal(value: boolean) {
      this._isInModal = value;
  }
  get isInModal() { return this._isInModal; }

  @ViewChild("btnDeleteTemplate", { static: true })
  public btnDeleteTemplate: TemplateRef<any>;
  @ViewChild("btnDownloadTemplate", { static: true })
  public btnDownloadTemplate: TemplateRef<any>;

  @ViewChild('ngxDatatableWrapper')
  ngxDatatableWrapper: NgxDatatableWrapperComponent;

  constructor(
    private el: ElementRef,
    private formBuilder: FormBuilder,
    private interventoService: InterventoService,
    private progettoService:ProgettoService
  ) { }

  ngOnInit() {
    this.dtOptionsNgx = {
      rows: [],
      totalElements: 0,
      columns: this.columns,
      columnMode: ColumnMode.flex,
      headerHeight: "50",
      footerHeight: "50",
      rowHeight: "auto",
      externalSorting: true, // true
      loadingIndicator: "loading",
      class: "bootstrap",
      summaryRow: true,
      summaryPosition: "'bottom'",
      externalPaging: true, // true
      count: 0,
      offset: 0,
      limit: 10,
      serverSide: true,
      ajax: this.loadData,
      scrollbarH: "true"
    };

    this.templateList = [
      { name: "btnDeleteTemplate", template: this.btnDeleteTemplate },
      { name: "btnDownloadTemplate", template: this.btnDownloadTemplate }
    ];

    this.addDocumentForm = this.formBuilder.group({
      title: [null, Validators.required],
      description: [null, Validators.required],
      attach: [null, Validators.required],
    });
  }

  columns = [
    {
      name: "id",
      prop: "id",
      visible: false,
      sortable: true,
      filtrable: true,
      width: 10,
      resizeable: false,
    },
    {
      name: "descrizione",
      prop: "description",
      sortable: true,
      filtrable: true,
      resizeable: false,
      draggable: false,
      flexGrow: 4,
      minWidth: 120,
    },
    {
      name: "titolo documentazione",
      prop: "title",
      sortable: true,
      filtrable: true,
      resizeable: false,
      draggable: false,
      flexGrow: 4,
      minWidth: 200,
    },
    {
      name: "scarica",
      prop: null,
      sortable: false,
      filtrable: false,
      resizeable: false,
      draggable: false,
      cellTemplate: "btnDownloadTemplate",
      flexGrow: 1,
      minWidth: 55,
    },
    {
      name: "elimina",
      prop: null,
      sortable: false,
      filtrable: false,
      cellTemplate: "btnDeleteTemplate",
      resizeable: false,
      draggable: false,
      flexGrow: 1,
      minWidth: 65,
    }
  ];

  gotoBackBtnClick() {
    this.gotoBtnFunction.emit(true);
  }

  reloadAllData() {
    this.ngxDatatableWrapper.gridModel.data = [];
    this.ngxDatatableWrapper.refresh();
  }

  loadData = (dataTablesParameters: any, callback: any) => {
    if (!this.interventoId) return;
    this.interventoService.getAllDt(dataTablesParameters, this.interventoId)
      .subscribe(resp => {
        callback(resp);
    });
    this.getInterventoData(this.interventoId);
  };

  esistonoInterventi = false;
  getInterventoData(id) {
    this.interventoService.get(id).subscribe(
      result => {
        this.readOnlyForm = result.isReadOnly;
        if (result.isMain) {
          // per i progetti vado a prendere il numero degli interventi per i bottoni sticky
          this.progettoService.getInterventiCount(result.progettoId).subscribe(
            res => {
              this.esistonoInterventi = res - 1 > 0;
            }
          );
        }
      });
  }

  get f() {
    return this.addDocumentForm.controls;
  }

  fileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      this.attach = file;
    }
  }

  downloadDocument(data: any) {
    this.interventoService.downloadDocument(data.id).subscribe(resp => {
      let filename = data.filePath.replace(/^.*[\\\/]/, "");
      FileSaver.saveAs(resp.body, filename);
    });
  }

  deleteDocument(data: any) {
    const result: boolean = confirm(
      `Sei sicuro di voler eliminare il documento selezionato?`
    );
    if (result) {
      this.interventoService.deleteDocument(data.id).subscribe(resp => {
        this.reloadAllData();
      });
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.addDocumentForm.invalid) {
      const invalidElements = this.el.nativeElement.querySelectorAll(
        ".is-invalid"
      );
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }
      return;
    }

    const formData: FormData = new FormData();
    formData.append('attach', this.attach, this.attach.name);
    formData.append('title', this.f.title.value);
    formData.append('description', this.f.description.value);

    this.interventoService.saveDnsh(formData, this.interventoId).subscribe(res => {
      this.reloadAllData();
    }, error => {
      console.log(error);
    });
  }
}
