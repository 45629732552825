import { AreaTematica } from '../modules/inframob/models';

export class RegisterUser {
  type: string;
  fiscalCode: string;
  firstName: string;
  lastName: string;
  birthDay: string;
  birthMonth: string;
  birthYear: string;
  birthNation: string;
  birthCountry: string;
  birthProvince: string;
  birthMunicipality: string;
  gender: string;
  email: string;
  emailconf: string;
  institutions: string[];
  institutionCodes: string[];
  ownedPlace: string;
  soggettoGiuridicoId:string;
  //nation's name
  n: string;
  //province's name
  p: string;
  //municipality's name
  m: string;
  areeTematiche:AreaTematica[];

  constructor(){
      this.areeTematiche =  [];
  }
}
