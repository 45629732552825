<div class="modal-header">
    <h4 id="dialog-events-name" class="modal-title pull-left">INVIA MAIL ALL'UTENTE</h4>
    <button type="button" class="close pull-right" aria-label="Chiudi" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <form [formGroup]="registerForm" novalidate>
        <label>Destinatario:</label>
        <input type="text" value="{{currentObj.to}}" readonly="readonly" class="form-control">
        <br>
        <div class="form-group">
          <label for="name">Oggetto <span class="required" aria-required="true"> </span></label>
          <input [ngClass]="{ 'is-invalid': submitted && f.subject.errors }"  formControlName="subject" type="text" maxlength="255" class="form-control" placeholder="Oggetto della mail">
          <div *ngIf="submitted && f.subject.errors" class="invalid-feedback" >
            <div *ngIf="f.subject.errors.required">Oggetto &egrave; obbligatorio</div>
            <div *ngIf="f.subject.errors.maxlength">Oggetto non può superare i 255 caratteri</div>
        </div>
        </div>
        <div class="form-group" style="margin-bottom:20px">
          <label>Testo della mail <span class="required" aria-required="true"> </span> &nbsp;</label>
          <textarea [ngClass]="{ 'is-invalid': submitted && f.body.errors }" formControlName="body" rows="9" cols="20" class="form-control" placeholder="Testo della mail"></textarea>
          <div *ngIf="submitted && f.body.errors" class="invalid-feedback" >
            <div *ngIf="f.body.errors.required">Testo dell'email &egrave; obbligatorio</div>                
        </div>
        </div>
        <div id="div_invio" class=" col-md-12 form-group"
          style="background-color:#fff;width:100%; padding:10px; margin-bottom:10px;text-align:center">          
            <button
          class="btn btn-lg btn-primary btn-block"
          type="submit"
          style="margin:15px 0"
          (click)="onSubmit()"
        >INVIA</button>
        </div>
      </form>
    </div>
  </div>