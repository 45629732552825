import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-title-page",
  templateUrl: "./title-page.component.html",
  styleUrls: ["./title-page.component.css"]
})
export class TitlePageComponent implements OnInit {
  pageTitle: string;
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.pageTitle = this.activatedRoute.routeConfig.data["breadcrumb"];
  }
}
