<div [hidden]="
    router.url.includes('dashboard-finanziaria') ||
    router.url.includes('dashboard-fasi') ||
    router.url.includes('dashboard-stato-finanziamento') ||
    router.url.includes('dashboard-stato-intervento') ||
    router.url.includes('dashboard-interventi') ||
    router.url.includes('dashboard-pnrr') ||
    router.url.includes('dashboard-non-disegnabile') ||
    router.url.includes('dashboard-pkmg') ||
    router.url.includes('dashboard-entrata-uscita')||
    router.url.includes('dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr') ||
    router.url.includes('dashboard-sisma')||
    router.url.includes('cruscotto-note-atti')
  " class="bg-0083e0">
  <div class="container container-fluid-md">
    <nav class="navbar navbar-expand-md navbar-dark bg-0083e0">
      <!--hamburger menu-->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- logo mobile-->
      <div class="d-block d-md-none text-white pointer" (click)="goToHome()">
        <h1 class="mb-0 h5 text-uppercase">{{title}}</h1>

      </div>
      <!-- fine hamburger menu-->

      <div class="collapse navbar-collapse" id="navbarMenu">
        <ul class="navbar-nav w-100 d-flex justify-content-around">
          <ng-container *ngFor="let item of menuItems">
            <li *ngIf="(item.subMenu && item.subMenu.length > 0)" class="nav-item " [ngClass]="item.cssClass">
              <a type="button" class="nav-link btn btn-primary text-white px-md-1 px-lg-2 " (click)="gotoRowMenu(item)">{{item.title}}</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>
