<div class="form-group">
    <div *ngIf="uploadResponse.status === 'error'">
            {{ uploadResponse.message }}
    </div>
    <div *ngIf=" !myconfig.fileExists">
        <div  style="display: inline-block">
            <i class="fas fa-download mr-2" *ngIf="myconfig.fileUploaded && !hideDownloadButton" (click)="downloadFile()"
            alt="Scarica file" title="Scarica file" style="cursor:pointer"></i>
            <i class="fas fa-check mr-2" *ngIf="uploadResponse.progressBarType=='success'"
            title="File caricato con successo" alt="File caricato con successo"></i>
            <input type="file" accept="{{myconfig.accept}}" 
                class=""  (change)="onFileChange($event)" id="{{myconfig.elementName}}"  name="{{myconfig.elementName}}" />                 
            <div *ngIf="uploadResponse.status === 'progress' ">            
                <progressbar [type]="uploadResponse.progressBarType" striped="uploadResponse.progressBarType!='success'" [value]="uploadResponse.message"  [animate]="uploadResponse.progressBarType!='success'" max="100">{{uploadResponse.message}}%</progressbar>       
            </div>        
        </div>
        <div *ngIf="errors.length>0" style="margin-top:0.25rem;width:100%;color:rgb(220,53,69);" >
            <div *ngIf="errors.length>0">{{errors}}</div>
        </div>    
        <div class="form-group">
            <button *ngIf="!myconfig.hideUploadButton" [disabled]="errors.length>0 || currentFile ===null" class="btn btn-primary" (click)="uploadFile()" >{{myconfig.uploadButtonLabel}}</button>
            <img *ngIf="loading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />        
        </div>  
    </div>   
    <div *ngIf="myconfig.fileExists">
        {{myconfig.fixedFilename}}
    </div>
    
    <div *ngIf="false" class="col-lg-12 col-md-9 col-sm-4 com-xs-12">
        <div class="col-lg-6 col-md-9 col-sm-4 col-xs-12 float-left">
            <span style="font-size:10px;display:inline-block;margin-top:10px;vertical-align: middle">SCARICA IL FILE <br><br></span>             
            <span class="fa-stack fa-1x downloadbtn" style="color:#24557F;text-align: center;cursor:pointer" (click)="downloadFile();">
                <i class="fas fa-circle fa-stack-2x"></i><i class="fas fa-download fa-stack-1x fa-inverse"></i>
            </span>
        </div>
        <div class="col-lg-6 col-md-4 col-sm-4 col-xs-12 float-left">
            <span style="font-size:10px;display:inline-block;margin-top:10px;vertical-align: middle">ELIMINA IL FILE <br><br></span> 
            <span class="fa-stack fa-1x deletebtn" style="color:#B01A19;text-align: center;cursor:pointer" (click)="deleteFile();">
                <i class="fas fa-circle fa-stack-2x"></i><i class="fas fa-trash-alt fa-stack-1x fa-inverse"></i>
            </span>            
        </div>  
    </div>
</div>