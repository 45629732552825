import { Classificazione } from "./classificazione";
import { SoggettoGiuridico } from "./soggetto-giuridico";
import { Geolocalizzazione } from "./geolocalizzazione";
import { AreaTematica } from "./area-tematica";
import { TemaPrioritario } from "./tema-prioritario";
import { Provvedimento } from "./provvedimento";
import { ImportoProgetto } from "./importo-progetto";

export class Progetto {
  id: number;
  codice: string;
  descrizione: string;
  annoRiferimento: number;
  codiceDirezione: string;
  isDeleted: boolean;
  noteResponsabile: string;
  importo: number;
  importoDaFinanziare: number;
  importoFinanziato: number;
  numeroInterventi: number;
  cup: string;
  cig: string;

  idClassificazione: number;
  idAreaTematica: number;
  idSoggettoGiuridico: number;
  pnrrRilevante:boolean;
  classificazioni: Classificazione[];
  areeTematiche: AreaTematica[];
  soggettiGiuridici: SoggettoGiuridico[];
  localizzazioni: Geolocalizzazione[];
  temiPrioritari: TemaPrioritario[];
  provvedimenti: Provvedimento[];
  importi: ImportoProgetto[];

  presenteInBdu: boolean; //serve solo se intervento
  progettoId: number; //serve solo se intervento
  isReadOnly: boolean;
  haNoteIntervento: boolean = true;
  constructor() {
    this.isDeleted = false;
    //this.classificazione = new Classificazione();
    this.areeTematiche = [];
    this.classificazioni = [];
    this.soggettiGiuridici = [];
    this.localizzazioni = [];
    this.temiPrioritari = [];
    this.provvedimenti = [];
    this.importi = [];
  }
}
