import { Injectable } from "@angular/core";
import { ActivatedRoute, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router
    , private authService: AuthService
    ,private route: ActivatedRoute,
    ) {}

  canActivate(route, state: RouterStateSnapshot) {
      console.log(`into canActivate`);
      //not logged
    if (!this.authService.isLoggedIn() ){
        if(this.route.snapshot.queryParams['code']){
            this.router.navigate(["iam"]);
            return false;    
        }
        this.router.navigate(["login"]);
        return false;
      }
      if ( !this.authService.hasRoles()){
        if (state.url.indexOf('register')<0){
            this.router.navigate(["register"]);
            return false;
        }
        return true;
    }
    if ( !this.authService.isEnabled()){
        if (state.url.indexOf('me')<0){
            this.router.navigate(["account/me"]);
            return false;
        }
        return true;
    }
    
    console.log(`into canActivate, return true`);
    return true;    
  }
}
