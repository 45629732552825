<form>
  <div class="form-group ">
    <h5><label for="sezione" class="">Sezione:</label></h5>
    <ng-select style="letter-spacing: -0.5px;" class="" [items]="sections" bindValue="id" bindLabel="value"
               placeholder="Seleziona sezione" (change)="onChange($event)">
      <ng-template ng-option-tmp let-item="item">
        <div title="{{ item.value }}">{{ item.value }}</div>
      </ng-template>
    </ng-select>
  </div>

  <fieldset *ngIf="sectionSelected" class="form-group">
    <div class="row">
      <legend class="col-form-label col-sm-3 pt-0">Tipologia di permesso</legend>
      <div class="col-sm-9">
        <div class="form-check ">
          <input class='selected form-check-input' type='checkbox' [checked]="value"
                 (click)="enableHandler('read', $event)" name="Lettura" id="gridCheck1" />
          <label class="form-check-label" for="gridCheck1">Lettura</label>
        </div>
        <div class="form-check">
          <input class='selected form-check-input' type='checkbox' [checked]="value"
                 (click)="enableHandler('insert', $event)" name="Inserimento" id="gridCheck2" />
          <label class="form-check-label" for="gridCheck2">Inserimento</label>
        </div>
        <div class="form-check">
          <input class='selected form-check-input' type='checkbox' [checked]="value"
                 (click)="enableHandler('update', $event)" name="Aggiornamento" id="gridCheck3" />
          <label class="form-check-label" for="gridCheck3">Modifica</label>
        </div>
        <div class="form-check ">
          <input class='selected form-check-input' type='checkbox' [checked]="value"
                 (click)="enableHandler('delete', $event)" name="Cancellazione" id="gridCheck4"/>
          <label class="form-check-label" for="gridCheck4">Cancellazione</label>
        </div>
      </div>
    </div>
  </fieldset>

  <button *ngIf="sectionSelected" class="btn btn-lg btn-primary btn-block" type="button" style="margin:15px 0"
    (click)="onSubmit()">
    Inserisci
  </button>
</form>
