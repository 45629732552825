<div class="p-5 container-xl text-center" [hidden]="loadDataCounter<=0">
  <app-loading message="Caricamento in corso..."></app-loading>
</div>

<div class="container sfondo_bianco" [hidden]="loadDataCounter>0">

  <hr />

  <!--inizio filtri-->

  <div class="row pb-3 pt-1  mx-0 mb-3 rounded" [ngClass]="{'bg-info':!flagSoggResp,'bg-0083e0':flagSoggResp}"
    [formGroup]="filterForm">
    <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
      <label for="mainObjectTypeFilter">
        <h6 class="mb-0 text-white bold">
          Progettualità - Fabbisogno
        </h6>
      </label>
      <ng-select
          title="{{ description }}"
          [items]="allProjects"
          bindValue="codice"
          bindLabel="label"
          placeholder="Seleziona progettualità/fabbisogno"
          formControlName="project"
          (change)="setProject($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizione }}">{{ item.label }}</div>
          </ng-template>
        </ng-select>
    </div>

    <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
      <label for="importoFinanziamento">
        <h6 class="mb-0 text-white bold">
          Intervento
          <small>(min 3 caratteri)</small>
        </h6>
      </label>
      <app-completer [cpOptions]="completerParams" (onSelectEmitter)="setInterventionCompleter($event)"
        (keyup)="onChangeInterventionCompleter($event)">
      </app-completer>
    </div>

    <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
        <label for="importoFinanziamento">
          <h6 class="mb-0 text-white bold">
            Descrizione intervento
            <small>(min 3 caratteri)</small>
          </h6>
        </label>
        <input type="text" maxlength="200" formControlName="objectSearchText" class="form-control" placeholder="Inserisci la descrizione" />        
      </div>

    <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
      <label for="areeTematicheCombo">
        <h6 class="mb-0 text-white bold">
          Aree gestionali direzione e/o esterne
        </h6>
      </label>
      <ng-select [items]="areeTematicheCombo" bindValue="id" bindLabel="descrizione"
        formControlName="areeTematicheCombo" placeholder="Seleziona area gestionale direz. e/o esterna "
        (change)="setAreaTematicaFilter($event)">
        <ng-template ng-option-tmp let-item="item">
          <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
        </ng-template>
      </ng-select>
    </div>


    <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
      <label for="tipologiaClassificazioni">
        <h6 class="mb-0 text-white bold">Tipologia Classificazioni</h6>
      </label>
      <ng-select formControlName="tipologiaClassificazioni" [items]="tipologieClassificazioni" bindValue="id" bindLabel="tipo"
        placeholder="Seleziona tipologia classificazioni" (change)="setTipologiaClassificazioniFilter($event)">
        <ng-template ng-option-tmp let-item="item">
          <div title="{{ item.tipo }}">{{ item.tipo }}</div>
        </ng-template>
      </ng-select>
    </div>

    <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
      <label for="temiPrioritari">
        <h6 class="mb-0 text-white bold">Temi prioritari</h6>
      </label>
      <ng-select formControlName="temiPrioritari" [items]="temiPrioritariCombo" bindValue="id"
        bindLabel="descrizione" placeholder="Seleziona tema prioritario"
        (change)="setTemiPrioritario($event)">
        <ng-template ng-option-tmp let-item="item">
          <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
        </ng-template>
      </ng-select>
    </div>    
  </div>
  <!--fine filtri-->
  <!--accordion-->
  <accordion [isAnimated]="true">
    <accordion-group heading="Gestione classificazioni" [panelClass]="panelClass">
      <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
    </accordion-group>
  </accordion>
  <!--inizio tabella-->

  <div class="col-lg-6 col-sm-6 col-6 mt-5">
    <h5 for="" class="">Tabella Progettualità / Fabbisogni / Interventi</h5>
  </div>
  <div class="container">


    <gestione-massiva-classificazioni-details-table-component [observable$]="observable$ | async"
      [tabellaDettaglioObj]="tabellaDettaglioObj" [showSelectColumn]="true"
      (codesSelectedChanged)="codesSelectedChanged($event)" (classificazioniSelectedChanged)="classificazioniSelectedChanged($event)">
    </gestione-massiva-classificazioni-details-table-component>


  </div>


</div>
<!--fine tabella-->

<!--inizio contenuto accordion-->
<ng-template #templateEditAdd>

  <div class="">
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
      <div class="col-lg-12 mt-3">



      </div>
      <div class="col-lg-12 mt-5">
        <hr [ngClass]="{'border-info':!flagSoggResp}">
      </div>
      <!--tabella-->

      <div class="col-lg-12 mt-4">
        <div class="row justify-content-between">

          <div class="col-lg-7 col-md-6 col-sm-12 mt-3">
            <h5 for="" class="">Classificazioni</h5>
          </div>




          <div class="col-lg-4 col-md-5 col-sm-7 col-9 mt-3">

            <div class="custom-control custom-switch  pl-0 ml-0 text-md-right">

              <input type="checkbox" checked="" class="box custom-control-input" id="customSwitch2"
                (click)="changeFlagSoggResp()">
              <label class="mr-5 bold">Aggiungi</label>
              <label class="custom-control-label" for="customSwitch2">Rimuovi</label>
            </div>
          </div>


          <div class="col-lg-1 col-md-1 col-sm-2 col-3 text-center mt-3 " id="colorOfSwitch2">
            <a href="javascript:;;" class="bottone-inserisci" [ngClass]="{'text-info':!flagSoggResp}"
              (click)="openModalClassificazioni();">
              <i class="fas fa-plus"></i></a>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mt-3 table-responsive-lg">
        <table class="table table-hover table-colore">
          <thead>
            <tr>
              <th scope="col">codice</th>
              <th scope="col">tipo</th>
              <th scope="col" class="casella-descrizione-tx">descrizione</th>
              <th scope="col" class="casella-icona-tx">elimina</th>
            </tr>
          </thead>

          <tbody>
            <ng-container *ngIf="classificazioni && classificazioni.length>0">
              <tr *ngFor="let item of classificazioni">
                <td>{{item.codice}}</td>
                <td>{{item.tipoClassificazione}}</td>
                <td>{{item.descrizione}}</td>
                <td><a (click)="removeClassificazione(item)" href="javascript:;;" data-toggle="tooltip"
                    data-placement="top" title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i>
                  </a>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="!(classificazioni && classificazioni.length>0)">
              <td colspan="4">Nessuna classificazione selezionata</td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="submitted && soggettoResponsabileIsError" class="invalidFieldMessage">
          <div *ngIf="soggettoResponsabileIsError" class="invalid-feedback" style="display: block;">
            Selezionare almeno una classificazione
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-3">
        <button [disabled]="loading" class="btn btn-lg btn-block f-weight-600"
          [ngClass]="{'btn-info':!flagSoggResp,'btn-primary':flagSoggResp}" type="submit">
          <img *ngIf="loading" class="pl-3 float-right"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          Applica a elementi selezionati
        </button>
      </div>
    </form>
  </div>
</ng-template>
<!--fine contenuto accordion-->
