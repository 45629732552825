import { Component, Input, OnInit } from "@angular/core";
import { TargetMilestone } from "../../../models/targetMilestone";
import { FaseService, PnrrService } from "../../../services";
import * as moment from "moment";
import { FormGroup, FormControl } from "@angular/forms";
import { PnrrComponente } from "../../../models/pnrrComponente";
import * as FileSaver from "file-saver";

@Component({
  selector: "app-pnrr-tab-target-milestone",
  templateUrl: "./pnrr-tab-target-milestone.component.html",
  styleUrls: ["./pnrr-tab-target-milestone.component.css"],
})
export class PnrrTabTargetMilestoneComponent implements OnInit {
  constructor(private pnrrService: PnrrService, private faseService: FaseService) {}

  allPnrrTargetMilestone: TargetMilestone[] = [];
  progettiMtSelezionato = {};

  percentualeMtPrimoLivello: number = 0;
  percentualeProgSecondoLivello: number = 0;
  percentualeIntervTerzoLivello: number = 0;

  pnrrMissioniItems = [];
  pnrrLineeItems = [];
  pnrrComponentiItems = [];

  pnrrLineeAll = [];
  pnrrComponentiAll = [];

  showLoading: boolean = false;

  myForm: FormGroup;

  @Input() tipologia : string;

  ngOnInit(): void {
    this.myForm = new FormGroup({
      pnrrMissioni: new FormControl(null),
      pnrrComponenti: new FormControl(null),
      pnrrLinee: new FormControl(null),
    });
    this.getAllTargetMilestone(this.myForm.value);
    this.attachComboValueChange();
    this.getAllComponenti();
    this.getAllLinee();
    this.fillComboFinanziamentoPnrrMissioniComponentiLinee();

  }

  getAllTargetMilestone(filters) {
    this.faseService.getPnrrTargetMilestone(this.tipologia, filters).subscribe((tm) => {
      this.allPnrrTargetMilestone = tm;

      //Percentuale M&T
      this.allPnrrTargetMilestone.forEach((targetMilestone) => {
        if (
          targetMilestone.valoreEffettivo !== 0 &&
          targetMilestone.valorePrevisto !== 0
        ) {
          targetMilestone.percentuale = Number(
            (
              (targetMilestone.valoreEffettivo /
                targetMilestone.valorePrevisto) *
              100
            ).toFixed(2)
          );
        } else {
          targetMilestone.percentuale = 0;
        }

        //Percentuale progetti

        targetMilestone.children.forEach((progetto) => {
          if (progetto.valoreEffettivo !== 0 && progetto.valorePrevisto !== 0) {
            progetto.percentuale = Number(
              (
                (progetto.valoreEffettivo / progetto.valorePrevisto) *
                100
              ).toFixed(2)
            );
          } else {
            progetto.percentuale = 0;
          }

          progetto.children.forEach((intervento) => {
            if (
              intervento.valoreEffettivo !== 0 &&
              intervento.valorePrevisto !== 0
            ) {
              intervento.percentuale = Number(
                (
                  (intervento.valoreEffettivo / intervento.valorePrevisto) *
                  100
                ).toFixed(2)
              );
            } else {
              intervento.percentuale = 0;
            }
          });
        });
      });
    });
  }

  doSearch() {
    this.arrExpandStatus = {};

    this.percentualeMtPrimoLivello = 0;
    this.percentualeProgSecondoLivello = 0;
    this.percentualeIntervTerzoLivello = 0;

    this.getAllTargetMilestone(this.myForm.value);
  }

  getAllLinee() {
    this.pnrrService.getAllLineaFinanziamento().subscribe((linee) => {
      this.pnrrLineeAll = linee
        .map((filteredLinee) => ({
          valore: `${filteredLinee.codice} - ${filteredLinee.descrizione}`,
          ...filteredLinee,
        }))
        .sort((a, b) => a.valore.localeCompare(b.valore));
    });
  }

  getAllComponenti() {
    this.pnrrService.getAllComponente().subscribe((componenti) => {
      this.pnrrComponentiAll = componenti
        .map((filteredComponente: PnrrComponente) => ({
          valore: `${filteredComponente.missione.codice} - ${filteredComponente.descrizione} `,
          ...filteredComponente,
        }))
        .sort((a, b) => a.valore.localeCompare(b.valore));
    });
  }

  attachComboValueChange() {
    this.myForm
      .get("pnrrMissioni")
      .valueChanges.subscribe((selectedMissioni) => {
        if (selectedMissioni && selectedMissioni.length > 0) {
          this.pnrrComponentiItems = this.pnrrComponentiAll.filter(
            (filteredComponent: PnrrComponente) =>
              selectedMissioni.includes(filteredComponent.missione.id)
          );

          if (this.myForm.get("pnrrComponenti").value) {
            const comp = this.myForm.get("pnrrComponenti").value;
            this.myForm
              .get("pnrrComponenti")
              .setValue(
                this.pnrrComponentiItems
                  .filter((x) => comp.includes(x.id))
                  .map((x) => x.id)
              );
          }
          if (selectedMissioni.includes(this.pnrrComponentiItems)) {
            this.myForm.get("pnrrComponent").updateValueAndValidity();
          }
        } else {
          this.myForm.get("pnrrComponenti").setValue([]);
          this.pnrrComponentiItems = [];

          this.myForm.get("pnrrLinee").setValue([]);
          this.pnrrLineeItems = [];
        }
      });

    this.myForm
      .get("pnrrComponenti")
      .valueChanges.subscribe((selectedComponenti) => {
        if (selectedComponenti && selectedComponenti.length > 0) {
          this.pnrrLineeItems = this.pnrrLineeAll.filter((filteredLine) =>
            selectedComponenti.includes(filteredLine.componente.id)
          );

          if (this.myForm.get("pnrrLinee").value) {
            const linee = this.myForm.get("pnrrLinee").value;
            this.myForm
              .get("pnrrLinee")
              .setValue(
                this.pnrrLineeItems
                  .filter((x) => linee.includes(x.id))
                  .map((x) => x.id)
              );
          }
          if (selectedComponenti.includes(this.pnrrLineeItems)) {
            this.myForm.get("pnrrLinee").updateValueAndValidity();
          }
        } else {
          this.myForm.get("pnrrLinee").setValue([]);
          this.pnrrLineeItems = [];
        }
      });
  }

  fillComboFinanziamentoPnrrMissioniComponentiLinee() {
    this.pnrrService.getAllMissione().subscribe((missioni) => {
      this.pnrrMissioniItems = missioni;
    });
  }

 

  printValueAsDate(value) {
    if (!value || value == "") return "";
    let v = moment(value).toDate();
    return moment(v).format("DD/MM/YYYY");
  }

  arrExpandStatus = {};
  doExpand(id) {
    console.log("into do expand", id);
    const code = "prog_" + id;
    this.arrExpandStatus[code] = !this.arrExpandStatus[code];
    if (
      !this.arrExpandStatus[code] &&
      this.progettiMtSelezionato[`prog_${id}`]
    ) {
      for (const mt of this.progettiMtSelezionato[`prog_${id}`]) {
        this.arrExpandStatus["prog_" + mt.id] = false;
      }
    }
  }

  caricaChildren(id: number) {
    this.doExpand(id);
    if (this.progettiMtSelezionato[`prog_${id}`]) return;
    const progetto = this.allPnrrTargetMilestone.find(
      (progettoId) => progettoId.id === id
    );

    if (progetto !== null && progetto.children.length > 0) {
      this.progettiMtSelezionato[`prog_${id}`] = progetto.children;
    }
  }

  interventiProgettoMtSelezionato = {};
  caricaInterventoProgettoMt(intervento: any) {
    const id = intervento.id;
    this.doExpand(id);
    if (this.interventiProgettoMtSelezionato[`int_${id}`]) return;

    if (intervento.children.length > 0) {
      this.interventiProgettoMtSelezionato[`int_${id}`] = intervento.children;
    }
    console.log(this.interventiProgettoMtSelezionato[`int_${id}`]);
  }

  loadingExportExcel: boolean = false;

  doExcel() {
    const pnrrMissioni = this.myForm.controls.pnrrMissioni.value;
    const pnrrComponenti = this.myForm.controls.pnrrComponenti.value;
    const pnrrLinee = this.myForm.controls.pnrrLinee.value;
    const tipologia = this.tipologia;

    const filters = {
      pnrrMissioni,
      pnrrComponenti,
      pnrrLinee,
      tipologia
    };

    
    this.loadingExportExcel = true;
    this.faseService.esportaExcelMT(filters, tipologia).subscribe(
      (response) => {
        const filename = `esportazione_tabella_${moment(new Date()).format(
          "YYYY_MM_DDTHH_mm_ss"
          )}.xlsx`;
          FileSaver.saveAs(response.body, `${filename}`);
          this.loadingExportExcel = false;
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
        );
        console.log(filters, tipologia);
  }

}
