import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild,OnDestroy } from '@angular/core';
import { InterventoService, AreeTematicheService,FinanziamentoService ,StorageService} from '../../services';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from '../../../../components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component'
import { OrderPipe } from 'ngx-order-pipe';
import * as FileSaver from "file-saver";
import { ToastService } from 'src/app/services/toast.service';


@Component({
    selector: 'finanziamento-usato-da-interventi-component',
    templateUrl: './finanziamento-usato-da-interventi.component.html',
    styleUrls: ['./finanziamento-usato-da-interventi.component.css']
})
export class FinanziamentoUsatoDaInterventiComponent
    implements OnInit,OnDestroy {


    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnGeoTemplate', { static: true })
    public btnGeoTemplate: TemplateRef<any>;

    @ViewChild('direzioneFieldValueTemplate', { static: true })
    public direzioneFieldValueTemplate: TemplateRef<any>;

    @ViewChild('annualitaFieldValueTemplate', { static: true })
    public annualitaFieldValueTemplate: TemplateRef<any>;

    @ViewChild('diCuiUtilizzatoFieldValueTemplate', { static: true })
    public diCuiUtilizzatoFieldValueTemplate: TemplateRef<any>;




    @ViewChild('btnCheckTemplate', { static: true })
    public btnCheckTemplate: TemplateRef<any>;



    @ViewChild('fieldNoteTemplate', { static: true })
    public fieldNoteTemplate: TemplateRef<any>;



    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    @ViewChild('extraFilterTemplate', { static: true })
    public extraFilterTemplate: TemplateRef<any>;



    _isInModal: boolean = false;
    @Input()
    set isInModal(value: boolean) {
        this._isInModal = value;
    }
    get isInModal() { return this._isInModal; }


    _finanziamentoId: number;
    @Input()
    set finanziamentoId(value: number) {
        this._finanziamentoId = value;
    }

    get finanziamentoId() {
        return this._finanziamentoId;
    }


    loading: boolean = true;


    dtOptions: any;
    rows: any[];

    columns = [
        {
            name: '', prop: 'id', sortable: true, filtrable: false, visible: false
            // 'buttonsTemplate'
        }

        , {
            name: 'codice'
            , prop: 'codice'
            , sortable: true
            , filtrable: true
            , minWidth: 85
            , flexGrow: 3
        }
        , {
            name: 'cup'
            , prop: 'cup'
            , sortable: true
            , filtrable: true
            , minWidth: 120
            , flexGrow: 4
            , visible: false

        }
        , {
            name: 'descrizione'
            , prop: 'descrizione'
            , sortable: true
            , filtrable: true
            , minWidth: 120
            , flexGrow: 4
        }
        , {
            name: 'aree gestionali'
            , prop: 'areeTematiche'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'direzioneFieldValueTemplate'
            , minWidth: 120
            , flexGrow: 4
        }
        , {
            name: 'valore prog/int'
            , prop: 'importi'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'annualitaFieldValueTemplate'
            , minWidth: 200
            , flexGrow: 5
        }
        , {
            name: 'di cui coperto <br />da questo finanziamento'
            , prop: 'finanziamenti'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'diCuiUtilizzatoFieldValueTemplate'
            , minWidth: 200
            , flexGrow: 5
        }
        , {
            name: 'validazione'
            , prop: 'validationStatus'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'btnCheckTemplate'
            , flexGrow: 2
            , minWidth: 70


        }
        , {
            name: 'note'
            , prop: 'noteResponsabile'
            , sortable: true
            , filtrable: false
            , cellTemplate: 'fieldNoteTemplate'
            , flexGrow: 1
            , minWidth: 50


        }
        , {
            name: 'loc.'
            , prop: 'disegnato'
            , sortable: true
            , filtrable: false
            , cellTemplate: 'btnGeoTemplate'// 'buttonsTemplate'
            //, width: 10
            , resizeable: false
            , draggable: false
            , flexGrow: 1
            , minWidth: 40
        }
    ];
    templateList = [];

    constructor(
        private interventoService: InterventoService
        , private orderPipe: OrderPipe
        , private areeTematicheService: AreeTematicheService
        , private finanziamentoService:FinanziamentoService
        ,private toastr: ToastService,
        private storageService:StorageService
    ) {
        this.getAreeTematiche();
        this.dtOptions = {
            rows: this.rows
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , scrollbarH: "true"
            , ajax: this.getInterventiDelFinanziamentoDt
            , extraFilterTemplate: this.extraFilterTemplate
            ,sorts : [{ dir: 'asc', prop: 'codice' }]
        };
    }
    ngOnDestroy(): void {
        if(this.elencoAreeSubscription)this.elencoAreeSubscription.unsubscribe();
    }
    ngOnInit(): void {

        window.scroll(0, 0);
        this.templateList = [

            { name: 'btnEditTemplate', template: this.btnEditTemplate }
            , { name: 'direzioneFieldValueTemplate', template: this.direzioneFieldValueTemplate }
            , { name: 'annualitaFieldValueTemplate', template: this.annualitaFieldValueTemplate }
            , { name: 'btnCheckTemplate', template: this.btnCheckTemplate },
            { name: 'btnGeoTemplate', template: this.btnGeoTemplate }
            , { name: 'fieldNoteTemplate', template: this.fieldNoteTemplate },
            {name:'diCuiUtilizzatoFieldValueTemplate', template:this.diCuiUtilizzatoFieldValueTemplate}

        ];
        window.scroll(0, 0);
        this.dtOptions.extraFilterTemplate = this.extraFilterTemplate;
        this.ngxDatatableWrapper ? this.ngxDatatableWrapper.dtOptions = this.dtOptions : null;
    }

    getInterventiDelFinanziamentoDt = (dataTablesParameters: any, callback: any) => {
        let that = this;
        if (this.finanziamentoId)
            this.interventoService.getInterventiDelFinanziamentoDt(this.finanziamentoId, dataTablesParameters)
                .subscribe(
                    resp => {
                    callback(resp);
                    that.loading = false;
                }
                ,error=>{
                    console.error(error);
                }
                );
    }


    reloadAllData() {
        this.loading = true;
        this.ngxDatatableWrapper.gridModel.data = [];
        this.ngxDatatableWrapper.refresh();
    }



    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
    gotoBackBtnClick() {
        this.gotoBtnFunction.emit(true);
    }

    printDirezioneFieldValue(data) {
        let str = '';
        let arr = [];
        if (data && data != '') {
            //let obj = JSON.parse(data);

            for (let o in data) {
                let s = "";
                s = (data[o]["disabled"] ? "(disattiva) " : "") + data[o]['descrizione'];
                arr.push(s);
            }
            str = arr.join('<br />');
        }
        return arr;
    }

    printValueAsCurrency(value) {
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }

    calcolaValoreIntervento(row: any) {

        if (!row || !row['importi']) return this.printValueAsCurrency(0);
        let valore = 0;
        row['importi'].forEach(element => {
            valore += element['importo'];
        });
        return this.printValueAsCurrency(valore);
    }

    sortImporti(arr) {
        return this.orderPipe.transform(arr, 'anno');
    }
    printBtnValidazioneTitle(value) {
        return '';
    }

    onRichiediValidazione(row: any) {

        return;

    }
    isReadOnly(info: any): boolean {
        //controllo se l'utente può modificare il progetto
        //let user = this.authService.getCurrentUser();
        return true;
    }

    closeModalEmitterSubscribe = null;
    onGeolocalizza(row) {
        return;

    }
    areeTematicheCombo = [];
private elencoAreeSubscription = null;
    getAreeTematiche() {
        this.elencoAreeSubscription = this.storageService.elencoAreeTematiche.subscribe(
            x=>{
                console.log(x);
                this.areeTematicheCombo = x?x:[];
            }
            );
    }

    filterAreaTematica($event) {
        let descr = $event ? $event.descrizione : '';
        if (descr.startsWith('(disattiva)')) {
            descr = descr.replace('(disattiva)', '');
        }
        this.ngxDatatableWrapper.originalColumns[4].search = { value: descr, regex: false };
        this.ngxDatatableWrapper.dtInputParams.columns[4].search = { value: descr, regex: false };
        this.ngxDatatableWrapper.refresh();
        //this.filterArea = $event ? $event.id : -1;
        //this.filterInterventions(false);
    }

    esportaExcel(){
        this.loading = true;

        this.finanziamentoService.getById(this.finanziamentoId).subscribe(
            result => {
                let codice = result.codice;
                this.finanziamentoService.esportaExcel(this.finanziamentoId).subscribe(
                    res => {
                        this.loading = false;
                        let filename = `esportazione_${codice}.xlsx`;
                        FileSaver.saveAs(
                            res.body,
                            `${filename}`
                        );
                    }
                    , error => {
                        this.loading = false;
                        this.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                            timeOut: 2000, disableTimeOut: false
                        });
                        console.log('error');
                    }
                );
            }
        );


    }


    getFinanziamentoData(finArray){
        let r = finArray.filter(x=>{
            if (x.finanziamento_id)return x.finanziamento_id == this.finanziamentoId;
            return x.id == this.finanziamentoId;

        }
            );
        return r && r.length>0? this.sortImporti(r[0].annualita):[];
    }
    calcolaUtilizzato(obj){
        let r = obj.finanziamenti.filter(
            x=>{
                if (x.finanziamento_id)return x.finanziamento_id == this.finanziamentoId;
                return x.id == this.finanziamentoId;

            }
        );
        if (!r || r.length<=0)return 0;
        let valore = 0;
        for(const f of r[0].annualita)valore +=f.importo;
        return this.printValueAsCurrency(valore);
    }
}
