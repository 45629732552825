import { Component, OnInit } from "@angular/core";
import { AuthService, UserService } from "src/app/services/index";
import { JwtUser } from "src/app/models/jwt-user";
import { Router } from "@angular/router";

@Component({
  selector: "app-header-menu",
  templateUrl: "./header-menu.component.html",
  styleUrls: ["./header-menu.component.css"],
})
export class HeaderMenuComponent implements OnInit {
  menuItems =[] /*menu*/;  
  currentUser: JwtUser;
  headerCode:string;
  constructor(private authService: AuthService, public router: Router,private userService:UserService) {
    this.currentUser = this.authService.getCurrentUser();
    this.menuItems = this.authService.getMenu();
    this.headerCode = this.authService.getHeaderCode();
    if (!this.menuItems || this.menuItems.length <= 0) {
      this.userService.getInfo('').subscribe(
        res => {
          this.authService.setMenu(res['menu']);
          this.menuItems = this.authService.getMenu();
          this.menuItems = this.menuItems.filter(x=>this.canAccess(x));
          this.authService.setHeaderCode(res['headerCode']);
          this.headerCode = this.authService.getHeaderCode();
        }
      );
      console.log(`this.menuItems =${this.menuItems}`);
    }
  }

  ngOnInit() { }

  canAccess(item) {
    if (item.permissions && item.permissions.length > 0) {
      if (this.currentUser.authorities == null) return false;
      let ris = false;

      item.permissions.forEach((element) => {
        ris = ris || this.currentUser.authorities.includes(element);
        if (ris) return;
      });
      return ris;
    }
    return true;
  }

  gotoPage(item) {
    console.log(`item:${JSON.stringify(item)}`);
    if (item.link) this.router.navigate([item.link]);
  }

  isEnabled() {
    return this.authService.isEnabled();
  }
}
