<ng-template #templateEditAdd>
    <div class="col-lg-12">
        <form [formGroup]="addSchoolYearForm" (ngSubmit)="onSubmit()">
            <input type="hidden" [formGroup]="addSchoolYearForm" formControlName="id" />
            <div class="row">
                
                <div class="form-group col-lg-12">
                    <h5> <label for="titolo" class="mandatory">Titolo:</label></h5>
                    <input [formGroup]="addSchoolYearForm" formControlName="titolo" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci la fase" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submitted && f.titolo.errors
                      }" />
                    <div *ngIf="submitted && f.titolo.errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="f.titolo.errors.required">
                            Campo obbligatorio
                        </div>
                    </div>
                </div>
                

            </div>            
            <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
            {{buttonLabel}} 
        </button>
        </form>
    </div>
</ng-template>

<div class="container sfondo_bianco">
    <hr />
    <accordion [isAnimated]="true">
        <accordion-group heading='Inserisci nuova banca dati esterna'>
            <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
        </accordion-group>
    </accordion>
    <div class="mt-5">

        <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptions">
            <ng-template #btnEditTemplate let-row="row" let-value="value">
                <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top" title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
            </ng-template>
            <ng-template #btnDeleteTemplate let-row="row" let-value="value">
                <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
            </ng-template>            
        </ngx-datatable-wrapper>
    </div>
</div>
<ng-template #templateModal>
    <div class="modal-header  text-center">
        <h4 class="modal-title pull-left">Modifica banca dati esterna</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
    </div>
    <div class="modal-body">
        <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
    </div>
</ng-template>