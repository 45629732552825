import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class IsDirtyHelper {
    currentValue: Observable<boolean>;
    storageKeyName = 'currentIsDirtyValue';
    private currentValueSubject: BehaviorSubject<boolean>;

    message = 'Proseguendo le modifiche correnti andranno perse, continuare?';
  constructor() {
    this.currentValueSubject = new BehaviorSubject<boolean>(
        localStorage.getItem(this.storageKeyName)=="true"
    );

    this.currentValue = this.currentValueSubject.asObservable();
  }

  

    public get isDirty(): boolean {
        return this.currentValueSubject.value;
    }

    public set isDirty(value:boolean){
        //console.log(`set isDirty with ${value}`)
        localStorage.setItem(this.storageKeyName,value?'true':'false');
        this.currentValueSubject.next(value);
    }

    public checkIsDirty():boolean{
        //console.log(`checkIsDirty with ${this.isDirty}`)
        if (this.isDirty){
            let ris = confirm(this.message);
            if (!ris)return false;
            this.isDirty = false;
        }
        return true;
    }
}
