<ng-template #templateEditAdd>
  <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
    <div class="col-lg-12 mt-3">
      <input type="hidden" [formGroup]="editForm" formControlName="id" />

      <div class="form-group row">

        <div class="col-lg-6 col-md-6 col-sm-12 mb-xs-3">
          <label for="descrizione">
            <h5 class="">Descrizione:</h5>
          </label>
          <input [formGroup]="editForm" formControlName="descrizione" maxlength="255" type="text" class="form-control"
            placeholder="Inserisci la descrizione" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submitted && editForm.controls.descrizione.errors
                      }" />
          <div *ngIf="submitted && editForm.controls.descrizione.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="editForm.controls.descrizione.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>


        <ng-template #IndiceDellePubblicheAmministrazioni>
          <span>Indice delle Pubbliche Amministrazioni</span>
        </ng-template>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="codiceIpa">
            <h5 class="mandatory">
              <a type="button"
              class="help"

              [popover]="IndiceDellePubblicheAmministrazioni"
              placement="bottom"
              triggers="mouseenter:mouseleave">

                <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                </i>
               </a>
               &nbsp;Codice Ipa:</h5>
          </label>
          <input [formGroup]="editForm" formControlName="codiceIpa" maxlength="50" type="text" value=""
            class="form-control" placeholder="Inserisci il codice ipa" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submitted && editForm.controls.codiceIpa.errors
                      }" />
          <div *ngIf="submitted && editForm.controls.codiceIpa.errors" class="invalid-feedback" style="display: block;">
            <div *ngIf="editForm.controls.codiceIpa.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>

      </div>

      <div class="form-group row">

        <div class="col-lg-6 col-md-6 col-sm-12 mb-xs-3">
          <label for="struttura">
            <h5 class="mandatory">Struttura:</h5>
          </label>
          <input [formGroup]="editForm" formControlName="struttura" maxlength="50" type="text" value=""
            class="form-control" placeholder="Inserisci la struttura" [ngClass]="{
                          'form-control': true,
                          'is-invalid': submitted && editForm.controls.struttura.errors
                          }" />
          <div *ngIf="submitted && editForm.controls.struttura.errors" class="invalid-feedback" style="display: block;">
            <div *ngIf="editForm.controls.struttura.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>



        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="nominativo">
            <h5 class="mandatory">Nominativo:</h5>
          </label>
          <input [formGroup]="editForm" formControlName="nominativo" maxlength="150" type="text" value=""
            class="form-control" placeholder="Inserisci il nominativo" [ngClass]="{
                              'form-control': true,
                              'is-invalid': submitted && editForm.controls.nominativo.errors
                              }" />
          <div *ngIf="submitted && editForm.controls.nominativo.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="editForm.controls.nominativo.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <label for="menu">
            <h5>Menu:</h5>
          </label>
          <ng-select bindValue="id" [formGroup]="editForm" placeholder="Seleziona" bindLabel="code" [items]="menus" formControlName="menu">
          </ng-select>  
        </div>
      </div>

      <div class="form-group row">

        <div class="col-sm-12">
          <label for="descrizioneEstesa">
            <h5 class="mandatory">Descrizione estesa:</h5>
          </label>

          <textarea [formGroup]="editForm" rows="3" formControlName="descrizioneEstesa" value="" class="form-control"
            placeholder="Inserisci la descrizione estesa" [ngClass]="{
                                  'form-control': true,
                                  'is-invalid': submitted && editForm.controls.descrizioneEstesa.errors
                                  }"></textarea>
          <div *ngIf="submitted && editForm.controls.descrizioneEstesa.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="editForm.controls.descrizioneEstesa.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>

      </div>


      <div class="form-group col-sm-12 mt-3 " >
        <label for="" class="">
          <h5 class="">
            Area abilitata:
          </h5>

        </label>
        <div>
          <div class="form-check  form-check-inline">

            <input [formGroup]="editForm" id="abilitato1" formControlName="areaTematicaDisabledSwitch" type="radio" value="1"
              class="form-check-input"  />

            <label for="abilitato1" class="form-check-label">Si</label>
          </div>
          <div class="form-check form-check-inline">
            <input [formGroup]="editForm" id="abilitato2" formControlName="areaTematicaDisabledSwitch" type="radio" value="0"
              class="form-check-input" />
            <label for="abilitato2" class="form-check-label">No</label>
          </div>
        </div>

      </div>

      <div class="form-group col-sm-12 mt-3 " >
        <label for="" class="">
          <h5 class="mandatory">
            Gestisci dati PNRR:
          </h5>

        </label>
        <div>
          <div class="form-check  form-check-inline">

            <input [formGroup]="editForm" id="gestisciPnrr1" formControlName="gestisciPnrrSwitch" type="radio" value="1"
              class="form-check-input"  />

            <label for="gestisciPnrr1" class="form-check-label">Si</label>
          </div>
          <div class="form-check form-check-inline">
            <input [formGroup]="editForm" id="gestisciPnrr2" formControlName="gestisciPnrrSwitch" type="radio" value="0"
              class="form-check-input" />
            <label for="gestisciPnrr2" class="form-check-label">No</label>
          </div>
        </div>
        <div *ngIf="submitted && editForm.controls.gestisciPnrrSwitch.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="editForm.controls.gestisciPnrrSwitch.errors.required">
              Campo obbligatorio
            </div>
          </div>
      </div>
      <div class="form-group col-sm-12 mt-3 " >
        <label for="" class="">
          <h5 class="mandatory">
            Gestisci dati non PNRR:
          </h5>

        </label>
        <div>
          <div class="form-check  form-check-inline">

            <input [formGroup]="editForm" id="gestisciNonPnrr1" formControlName="gestisciNonPnrrSwitch" type="radio" value="1"
              class="form-check-input"  />

            <label for="gestisciNonPnrr1" class="form-check-label">Si</label>
          </div>
          <div class="form-check form-check-inline">
            <input [formGroup]="editForm" id="gestisciNonPnrr2" formControlName="gestisciNonPnrrSwitch" type="radio" value="0"
              class="form-check-input" />
            <label for="gestisciNonPnrr2" class="form-check-label">No</label>
          </div>
        </div>
        <div *ngIf="submitted && editForm.controls.gestisciNonPnrrSwitch.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="editForm.controls.gestisciNonPnrrSwitch.errors.required">
              Campo obbligatorio
            </div>
          </div>
      </div>

      <div class="form-group col-sm-12 mt-3 " >
        <label for="" class="">
          <h5 class="mandatory">
            Visualizza solo dati della direzione:
          </h5>

        </label>
        <div>
          <div class="form-check  form-check-inline">

            <input [formGroup]="editForm" id="visualizzaSoloEntitaDirezione1" formControlName="visualizzaSoloEntitaDirezioneSwitch" type="radio" value="1"
              class="form-check-input"  />

            <label for="visualizzaSoloEntitaDirezione1" class="form-check-label">Si</label>
          </div>
          <div class="form-check form-check-inline">
            <input [formGroup]="editForm" id="visualizzaSoloEntitaDirezione2" formControlName="visualizzaSoloEntitaDirezioneSwitch" type="radio" value="0"
              class="form-check-input" />
            <label for="visualizzaSoloEntitaDirezione2" class="form-check-label">No</label>
          </div>
        </div>
        <div *ngIf="submitted && editForm.controls.visualizzaSoloEntitaDirezioneSwitch.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="editForm.controls.visualizzaSoloEntitaDirezioneSwitch.errors.required">
              Campo obbligatorio
            </div>
          </div>
      </div>


    </div>
    <div class="col-lg-12 mt-5">
      <hr>
    </div>

    <div class="col-lg-12 mt-4">
      <div class="row justify-content-between">

        <div class="col-lg-6 col-sm-6 col-6 mt-3">
          <h5 for="" class="">Obbiettivi</h5>
        </div>
        <div class="col-lg-1 col-sm-2 col-3 text-center mt-3">
          <a href="javascript:;;" class="bottone-inserisci" (click)="addObbiettivo();"><i class="fas fa-plus"></i></a>
        </div>
      </div>
    </div>

    <div class="col-lg-12 mt-3 table-responsive-lg">
      <table class="table table-hover table-colore">
        <thead class=" ">
          <th>anno</th>
          <th>obbiettivo</th>
          <th class="casella-icona-tx ">elimina</th>
        </thead>
        <tbody>
          <ng-container *ngIf="obbiettivi && obbiettivi.length && obbiettivi.length>0">
            <tr *ngFor="let item of obbiettivi">
              <td>{{item.anno}}</td>
              <td>{{item.descrizione}}</td>
              <td><a (click)="removeObbiettivo(item)" href="javascript:;;" data-toggle="tooltip" data-placement="top"
                  title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i> </a>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="!(obbiettivi && obbiettivi.length && obbiettivi.length>0)">
            <td colspan="3">Nessun obbiettivo selezionato</td>
          </tr>
        </tbody>
      </table>
    </div>








    <div class="col-lg-12 mb-3"><button [disabled]="loading" class="btn btn-lg btn-primary btn-block " type="submit">
        {{buttonLabel}}
        <img *ngIf="loading" class="pl-3 float-right"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      </button></div>
  </form>
</ng-template>


<ng-template #templateModalObbiettivo>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">Aggiungi obbiettivo</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalObbiettivo()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">


    <form [formGroup]="obbiettivoForm" (ngSubmit)="onSubmitObbiettivo()">
      <input type="hidden" [formGroup]="obbiettivoForm" formControlName="id" />
      <div class="col-lg-12 mb-3 mt-3">
        <div class="form-group">
          <label for="anno" class="mandatory">Anno:</label>
          <input [formGroup]="obbiettivoForm" formControlName="anno" maxlength="4" type="text" [imask]="maskYear"
            [unmask]="true" class="form-control" placeholder="Inserisci l'anno" [ngClass]="{
                              'form-control': true,
                              'is-invalid': submittedObbiettivo && obbiettivoForm.controls.anno.errors
                              }" />
          <div *ngIf="submittedObbiettivo && obbiettivoForm.controls.anno.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="obbiettivoForm.controls.anno.errors.required">
              Campo obbligatorio
            </div>
            <div *ngIf="obbiettivoForm.controls.anno.errors.minlength">
              Anno deve essere di 4 cifre
            </div>
            <div *ngIf="obbiettivoForm.controls.anno.errors.range">
              Anno deve essere maggiore di {{minYear}}
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="descrizione" class="mandatory">Descrizione:</label>
          <input [formGroup]="obbiettivoForm" formControlName="descrizione" maxlength="255" type="text"
            class="form-control" placeholder="Inserisci la descrizione" [ngClass]="{
                            'form-control': true,
                            'is-invalid': submittedObbiettivo && obbiettivoForm.controls.descrizione.errors
                            }" />
          <div *ngIf="submittedObbiettivo && obbiettivoForm.controls.descrizione.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="obbiettivoForm.controls.descrizione.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>




        <button [disabled]="loading" class="btn btn-lg btn-primary btn-block" type="submit">
          Aggiungi
          <img *ngIf="loading" class="pl-3 float-right"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </button>
      </div>
    </form>







  </div>
</ng-template>


<hr>
<div class="container sfondo_bianco">

  <accordion [isAnimated]="true">
    <accordion-group heading="Inserisci nuova area direzione">
      <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
    </accordion-group>
  </accordion>
  <div class="mt-5">

    <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="max-width: 100%;height: auto;"
      [dtOptions]="dtOptions">
      <ng-template #btnEditTemplate let-row="row" let-value="value">
        <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top"
          title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
      </ng-template>
      <ng-template #btnDeleteTemplate let-row="row" let-value="value">
        <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top"
          title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
      </ng-template>

      <ng-template #btnEnableTemplate let-row="row" let-value="value">
        <a [ngClass]="{'fa-check-circle-disabilitato':value,'fa-check-circle-abilitato':!value}"
          class=" border-0 text-center" data-toggle="tooltip" data-placement="top"
          title="{{!value?'abilitata':'disabilitata'}}">&nbsp;&nbsp;&ensp;
          <i class="far fa-check-circle"></i></a>
      </ng-template>


      <ng-template #menuFieldTemplate let-row="row" let-value="value">

        <span> {{row.menu && row.menu.length > 0 ? row.menu[0].code : '' }} </span>

      </ng-template>
    </ngx-datatable-wrapper>
  </div>
  <ng-template #templateModal>
    <div class="modal-header  text-center">
      <h4 class="modal-title pull-left">Modifica area direzione</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
    </div>
  </ng-template>


</div>
