<div class="p-5 container-xl text-center" [hidden]="loadingCounter<=0">
  <app-loading message="Caricamento in corso..."></app-loading>
</div>
<hr>
<div [hidden]="loadingCounter>0">
  <div [formGroup]="myGroup" novalidate>
    <!--start form ricerca-->

    <div class="form-row">

      <div class="form-group col-lg-4 col-sm-12" [hidden]="true">
        <label for="ruolo" class="">
          <h6 class="bold">Ruolo</h6>
        </label>
        <div [ngClass]="{ 'is-invalid': submitted && f.ruolo.errors }">
          <ng-select [formGroup]="myGroup" formControlName="ruolo" [items]="rolesList" bindValue="id" bindLabel="ruolo"
            placeholder="Seleziona un ruolo">
          </ng-select>
        </div>

        <div *ngIf="submitted && f.ruolo.errors" class="invalid-feedback d-block">
          <div *ngIf="f.ruolo.errors.required">
            Campo obbligatorio
          </div>
        </div>
      </div>

      <div class="form-group col-lg-4 col-sm-12">
        <label for="ruolo" class="">
          <h6 class="bold">Progettualità/Fabbisogno/Intervento <span class="small">(min 3 caratteri)</span></h6>
        </label>
        <div>
          <app-completer [cpOptions]="completerParams" (onSelectEmitter)="setIntervention($event)"
            (keyup)="onChangeIntervention($event)">
          </app-completer>
        </div>
      </div>


      <div class="form-group col-lg-4 col-sm-12 d-block">
        <label for="soggetto">
          <h6 class="bold">Soggetto giuridico</h6>
        </label>
        <div [ngClass]="{ 'is-invalid': submitted && f.soggetto.errors }">
          <ng-select
          [items]="soggettiGiuridiciList"
          bindValue="id"
          bindLabel="descrizione"
          placeholder="Seleziona un soggetto giuridico"
          [formGroup]="myGroup"
          formControlName="soggetto"
          >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
        </ng-template>
          </ng-select>
        </div>

        <div *ngIf="submitted && f.soggetto.errors" class="invalid-feedback d-block">
          <div *ngIf="f.soggetto.errors.required">
            Campo obbligatorio
          </div>
        </div>
      </div>
      <div class="form-group col-lg-2 col-sm-12 d-block align-self-end">

        <button [disabled]="!f.soggetto.value && !filterIntervento" class="btn btn-primary btn-block" (click)="search()">Cerca&nbsp;<i
            class="fas fa-search pointer"></i></button>

      </div>
      <div class="form-group col-lg-2 col-sm-12 d-block align-self-end">

        <button [disabled]="!isFiltered" class="btn btn-secondary btn-block font-weight-bold"
          (click)="undoSearch()">Resetta<i class="fas fa-search-alt pointer"></i></button>

      </div>
    </div>
  </div>
  <!--end form ricerca-->

  <!--start tabella generica interventi ed erogazioni-->
  <div class="row" [hidden]="isFiltered">


    <div class="col-lg-3 table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class=" text-center">
              <h5>Interventi validati</h5>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class=" text-center">
              <h3>{{data?data.interventi:''}}</h3>
            </td>

          </tr>

        </tbody>
      </table>
    </div>

    <div class="col-lg-9 table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <ng-container *ngIf="data">
              <th  scope="col" *ngFor="let item of data.annualita">{{item.anno}}</th>
            </ng-container>
            <th  scope="col">Totale</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Erogazioni </th>
            <td>{{data?data.erogazioniSa:''}}</td>
            <ng-container *ngIf="data">
              <td *ngFor="let item of data.annualita">{{printValueAsCurrency(item.erogazioniSaImporto)}}</td>
            </ng-container>
            <td>{{printValueAsCurrency(getTotaleErogazionexAnnualita('erogazioniSaImporto'))}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!--end tabella generica interventi ed erogazioni-->
  <!--start grafici generici interventi ed erogazioni-->
  <hr>
  <div class="row mb-5" [hidden]="isFiltered">
    <div class="col-lg-12 border shadow-sm rounded">
      <div class="chart" id="chart2"></div>
    </div>

  </div>
  <div class="row mb-5 border shadow-sm rounded" [hidden]="true">
    <div class="col-lg-6">
      <div class="chart" id="chart1"></div>
    </div>


    <div class="col-lg-6 " [hidden]="true">
      <div class="chart" id="chart3"></div>
    </div>
  </div>
  <!--end grafici generici interventi ed erogazioni-->
  <!--inizio tabella e grafici di dettaglio dopo filtro-->
  
  <div [hidden]="!isFiltered">
    <app-erogazioni-charts-components [ruoloId]="ruoloId" [soggettoId]="soggettoId" [filterIntervento]="filterIntervento"></app-erogazioni-charts-components>
  </div>
</div>
