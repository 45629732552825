<div class="container bg-white">

        <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <br/>
                    <h3 class="bold">Privacy Policy</h3>
                    <br/>
                    <h4 class="bold">
                        Informativa sul trattamento dei dati personali<br>
                        Regolamento UE 679/2016
                    </h4>
                    <p>La Regione Toscana in qualità di Titolare del trattamento, desidera, con la presente informativa, resa ai sensi dell'art. 13 del Regolamento UE 679/2016,“Regolamento (Ue) 2016/679 del Parlamento Europeo e del Consiglio del 27 aprile 2016 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera circolazione di tali dati e che abroga la direttiva 95/46/CE - regolamento generale sulla protezione dei dati, fornire informazioni circa il trattamento dei dati personali che La riguardano.</p>
        
                    <hr>
                    <h4 class="bold">Titolare del trattamento dei dati</h4>
                    <p>Il Titolare del trattamento Regione e' Toscana e nello specifico la Direzione Istruzione e Formazione – Settore Educazione e Istruzione con sede in Piazza dell’Unità Italiana, 1 – FIRENZE - PEC: regionetoscana@postacert.toscana.it.</p>
        
                    <hr>
                    <h4 class="bold">Responsabile per la protezione dei dati</h4>
                    <p>
                        Il Responsabile per la protezione dei dati personali della Regione Toscana è individuato con DGR n.325 del 31/04/2018, per maggiori informazioni è possibile consultare il seguente link: <br>
                        <a href="http://www.regione.toscana.it/-/il-responsabile-della-protezione-dei-dati">http://www.regione.toscana.it/-/il-responsabile-della-protezione-dei-dati</a> .
                    </p>
        
        
                    <hr>
                    <h4 class="bold">I destinatari del trattamento e l’eventuale trasferimento</h4>
                    <p>
                        I dati potranno essere comunicati, nel rispetto della normativa vigente, a società esterne incaricate dalla Regione Toscana per svolgere servizi di varia natura, quali a titolo esemplificativo, la manutenzione e l'assistenza del Sito e dei relativi Servizi.
                        <br> I dati personali non sono soggetti a diffusione.
                    </p>
        
                    <hr>
                    <h4 class="bold">Periodo di conservazione dei dati personali</h4>
                    <p>Al fine di garantire un trattamento corretto e trasparente i dati sono conservati per un periodo di tempo non superiore a quello necessario agli scopi per i quali essi sono stati raccolti o successivamente trattati conformemente a quanto previsto dagli obblighi di legge.</p>
        
                    <hr>
                    <h4 class="bold">Tipi di dati trattati</h4>
                    <h4 class="bold"><small><i>Dati di navigazione</i></small></h4>
                    <p>I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet. I dati potrebbero essere utilizzati per l'accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito; salva questa eventualità, allo stato i dati sui contatti web non persistono per più di sette giorni.</p>
                    <h4 class="bold"><small><i>Dati forniti volontariamente dall'utente</i></small></h4>
                    <p>L'invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati su questo sito comporta la successiva acquisizione dell'indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati personali indicati. </p>
                    <h4 class="bold"><small><i>Cookies</i></small></h4>
                    <p>Nessun dato personale degli utenti viene in proposito acquisito dal sito. Non viene fatto uso di cookies per la trasmissione di informazioni di carattere personale, né vengono utilizzati c.d. cookies persistenti di alcun tipo, ovvero sistemi per il tracciamento degli utenti. L'uso di c.d. cookies di sessione (che non vengono memorizzati in modo persistente sul computer dell'utente e svaniscono con la chiusura del browser) è strettamente limitato alla trasmissione di identificativi di sessione (costituiti da numeri casuali generati dal server) necessari per consentire l'esplorazione sicura ed efficiente del sito. I c.d. cookies di sessione utilizzati in questo sito evitano il ricorso ad altre tecniche informatiche potenzialmente pregiudizievoli per la riservatezza della navigazione degli utenti e non consentono l'acquisizione di dati personali identificativi dell'utente.</p>
        
        
                    <hr>
                    <h4 class="bold">Modalità del trattamento</h4>
                    <p>Il trattamento dei dati personali è compiuto tramite l’utilizzo di strumenti elettronici o comunque automatizzati, nel rispetto delle regole di riservatezza e di sicurezza previste dalla normativa vigente. Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti e accessi non autorizzati.</p>
        
                    <hr>
                    <h4 class="bold">Diritti dell'interessato</h4>
                    <p>L'interessato ha diritto di ottenere la conferma dell'esistenza o meno di dati personali che lo riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile.</p>
        
                    <hr>
                    <h4 class="bold">Natura del conferimento dei dati personali e conseguenze di un eventuale rifiuto di fornire i dati</h4>
                    <p>Il conferimento dei dati personali è facoltativo. L'eventuale rifiuto di conferirli può comportare l'impossibilità di utilizzare il Sito e fruire dei relativi Servizi.</p>
        
                </div>
               
        </div>
        
        </div>
