import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  ElementRef,
} from "@angular/core";
import { GeoService, ToastService } from "../../../../../services";
import { AggregatoService } from "../../../services";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NaturaTipologieService } from "../../../services/natura-tipologie.service";
import { IsDirtyHelper } from "src/app/helpers/is-dirty.helper";
import { TipologiaInterventiService } from "../../../services/tipologia-interventi.service";

@Component({
  templateUrl: "./geolocalizzazione-selection-modal.component.html",
  styleUrls: ["./geolocalizzazione-selection-modal.component.css"],
})
export class GeolocalizzazioneSelectionModalComponent implements OnInit {
  // Inizializzazione campi
  province = [];
  municipality = [];
  aggregato = [];
  id: number;
  public quantity: any = {
    mask: "KK",
    lazy: false, // make placeholder always visible

    blocks: {
      KK: {
        mask: Number,
        /*thousandsSeparator: '.',*/
        scale: 0,
        padFractionalZeros: false,
        radix: ",",
        min: 0,
      },
    },
  };

  myGroup: FormGroup;
  submitted: boolean = false;
  submittedModalDetailsInt: boolean = false;
  // Queste variabili sono passate tramite l'initialState della modale
  @Input() keySelected!: any;
  @Input() tipologyCode!: any;
  @Input() naturaIntervento: any;
  @Input() tipologiaIntervento: any;
  

  @Output() onSubmitOk = new EventEmitter();

  constructor(
    private geoService: GeoService,
    private aggregatoService: AggregatoService,
    private formBuilder: FormBuilder,
    private naturaTipologieService: NaturaTipologieService,
    public bsModalRef: BsModalRef,
    private modaleRef: BsModalRef,
    private modalService: BsModalService,
    private el: ElementRef,
    private isDirtyHelper: IsDirtyHelper,
    private toastr: ToastService,
    private tipologiaInterventiService: TipologiaInterventiService 
  ) {}



  ngOnInit(): void {
    this.myGroup = this.initializeFormGroup();
    this.dettaglioTipologiaForm = this.initializeDettaglioTipologiaForm();
    this.initializeTipologieIntervento();
    this.dettaglioTipologiaForm.patchValue({
      natura: this.naturaIntervento,
      tipologiaIntervento: this.tipologiaIntervento
    });
    this.getNaturaInterventoList(null);
    this.getProvince();
    this.getAggregato();
  }

  allComboTipologie = [];
  naturaList = [];
  types = [];

  initializeTipologieIntervento() {
    const tipologie = [];

    const tipi = this.tipologiaInterventiService.getTipologiaInterventi().subscribe(
        (tipi) => {
            tipi.forEach((t: any) => {
                const tipo = {
                    key: t.codice, label: t.descrizione,
                    value: t.descrizione
                    , disegnabile: t.disegnabile, naturaTipologia: t.naturaTipologia
                };
                tipologie.push(tipo);
            }
            )
            this.allComboTipologie = [...tipologie];
            this.types = [...tipologie];
        }
    );

}

getTipologiaType(key) {
    return this.allComboTipologie.find((type) => type.key == key);
}

  getNaturaInterventoList(callBack) {
    console.log("getNaturaInterventoList, callBack!=null", callBack != null);
    this.d.tipologiaIntervento.disable();
    this.naturaTipologieService.getNaturaTipologie().subscribe((result) => {
      this.naturaList = [];
      result.sort((a, b) => (a.codice <= b.codice ? -1 : 1));
      result.forEach((x) =>
        this.naturaList.push({
          key: x.codice,
          value: x.codice + " - " + x.etichetta,
        })
      );
      if (callBack) callBack();
    });
  }

  setNaturaIntervento($event) {
    this.dettaglioTipologiaForm.get('natura').setValue($event.key);
    this.dettaglioTipologiaForm.get('natura').markAsTouched();
  }

  

  dettaglioTipologiaForm: FormGroup;

  initializeDettaglioTipologiaForm() {
    let frm = this.formBuilder.group({
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(255)]],
      tipologiaIntervento: [{value: null}, [Validators.required]],
      natura: [null, [Validators.required]],
      codice: [null],
    });
    return frm;
  }

  configModal = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-lg",
  };

  closeModalDettaglioTipologia() {
    this.dettaglioTipologiaForm.reset();
    this.modaleRef.hide();
  }

  @ViewChild("templateModalDettaglioTipologia")
  templateModalDettaglioTipologia: TemplateRef<any>;

  openModalDettaglioTipologia($event) {

    this.modaleRef = this.modalService.show(
      this.templateModalDettaglioTipologia,
      Object.assign({}, this.configModal)
    );
    
  }

  initializeFormGroup() {
    if (this.keySelected == "01")
      return this.formBuilder.group({
        province: [null, [Validators.required]],
        municipality: [null, [Validators.required]],
        specificaAggregata: [null, [Validators.required]],
        quantity: [null, [Validators.required]],
        address: [null],
      });
    else
      return this.formBuilder.group({
        province: [null, [Validators.required]],
        municipality: [null, [Validators.required]],
        specificaAggregata: [null, [Validators.required]],
        quantity: [null],
        address: [null],
      });
  }

  getProvince() {
    this.geoService.getProvinces("12").subscribe((res) => {
      // console.log("valore result: ", res);
      let ress: any[];
      ress = res;
      ress.map((i) => {
        return i.provincia;
      });
      this.province = ress;
    });
  }

  getMunicipality() {
    this.f.municipality.setValue(null);
    this.geoService
      .getMunicipalities(this.myGroup.value.province)
      .subscribe((res) => {
        // console.log("valore result: ", res);
        let ress: any[];
        ress = res;
        ress.map((i) => {
          return i.municipality;
        });
        this.municipality = ress;
      });
  }

  getAggregato() {
    this.aggregatoService.getAll().subscribe((res) => {
      let kp: any[];
      kp = res.filter((x) => x.tipologiaIntervento.codice == this.tipologyCode);
      this.aggregato = kp;
    });
  }

  get f() {
    return this.myGroup.controls;
  }

  get d() {
    return this.dettaglioTipologiaForm.controls;
  }

  hasError(form, control: string) {
    const invalid =
      form.get(control)?.invalid || this.getControlErrors(form, control);
    return invalid;
  }

  getControlErrors(form, control: string) {
    const errors = this.getControls(form)[control].errors && !form.pristine;
    return errors;
  }

  getControls(form) {
    return form.controls;
  }

  validateForm(form) {
    form.updateValueAndValidity();
    let errors = false;
    for (let c in this.getControls(form)) {
      errors = errors || this.getControls(form)[c].errors !== null;
    }
    if (form.invalid || errors) {
      let invalidElements = [];
      for (let currElem in form.controls) {
        if (form.controls[currElem].invalid) {
          if (form.controls[currElem] instanceof FormGroup) {
            for (let control in form.get(currElem)["controls"]) {
              if (form.get(currElem)["controls"][control].invalid) {
                invalidElements = this.el.nativeElement.querySelectorAll(
                  `[formcontrolname="${control}"]`
                );
                break;
              }
            }
          } else {
            invalidElements = this.el.nativeElement.querySelectorAll(
              `[formcontrolname="${currElem}"]`
            );
          }
          break;
        }
      }
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }
      return false;
    }
    return true;
  }

  onSubmit() {
    this.submittedModalDetailsInt = true;

    if(!this.validateForm(this.dettaglioTipologiaForm)) {
      this.dettaglioTipologiaForm.updateValueAndValidity();
      this.setIsDirty(true);
      return;
    }

    this.setIsDirty(true);

    let tipologyToBeSent = this.types.find(x => x.key == this.d.tipologiaIntervento.value);

    if(!tipologyToBeSent){ 
      this.toastr.error("Errore di sistema");
      return; 
    }

    tipologyToBeSent.codice = tipologyToBeSent.key;

    let newDettaglioTipologia = {
      id: this.dettaglioTipologiaForm.value.id,
      name: this.dettaglioTipologiaForm.value.name,
      natura: this.dettaglioTipologiaForm.value.natura,
      tipologiaIntervento: tipologyToBeSent,
      codice: this.dettaglioTipologiaForm.value.codice
    }

    this.aggregatoService.insert(newDettaglioTipologia).subscribe((response) => {
      const newId = response.id;
      newDettaglioTipologia.id = newId;
      this.aggregato.push(newDettaglioTipologia);
      this.closeModalDettaglioTipologia();
      this.submittedModalDetailsInt = false;

      this.toastr.success('Dettaglio tipologia aggiunto con successo!')
      this.myGroup.get('specificaAggregata').setValue(newId);
      this.getAggregato();

    }, () => {
      this.toastr.error("Si è verificato un' errore aggiungendo nuovo dettaglio tipologia")
    });
  }

  setIsDirty(value: boolean) {
    this.isDirtyHelper.isDirty = value;
}

  add() {
    console.log("onSubmit");
    this.submitted = true;
    this.id = Date.now();
    var errors = false;
    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }

    console.log("errors:" + errors);
    if (this.myGroup.invalid || errors) {
      console.log("invalid");
      return false;
    }
    let provincia = this.province.find(
      (x) => x.key == this.myGroup.value.province
    );
    let comune = this.municipality.find(
      (x) => x.key == this.myGroup.value.municipality
    );
    let agg = this.aggregato.find(
      (x) => x.id == this.myGroup.value.specificaAggregata
    );
    let obj = {
      provincia: {
        id: provincia.id,
        code: provincia.key,
        name: provincia.value,
        acronym: "",
        isCittametropolitana: "",
        fromDate: Date,
        toDate: Date,
      },
      comune: {
        id: comune.id,
        istatCode: comune.key,
        cadastreCode: "",
        name: comune.value,
        fromDate: Date,
        toDate: Date,
        provinceId: provincia.id,
        provinceName: provincia.value,
      },
      aggregato: {
        id: agg.id,
        name: agg.name,
        tipologiaIntervento: agg.tipologiaIntervento,
      },
    };

    let data = Object.assign(
      {},
      { id: this.id },
      { provincia: obj.provincia },
      { comune: obj.comune },
      {
        quantita: this.myGroup.value.quantity
          ? this.myGroup.value.quantity
          : null,
      },
      { aggregato: obj.aggregato },
      { indirizzo: this.myGroup.value.address }
    );
    console.log("data passato al submit", data);

    this.onSubmitOk.emit(data);
  }
}
