import { SicerOrdinativo } from './sicer-ordinativo';
export class SicerLiquidazione {
  id: number;
  utIns: string;
  dtIns: Date;
  utVar: string;
  dtVar: Date;
  flgValidita: string;
  lastOperation: string;
  azienda: string;
  pkCpt: string;
  codCpt: string;
  versoCpt: string;
  pkImpacc: string;
  versoImpacc: string;
  statoImpacc: string;
  annoImpacc: number;
  numeroImpacc: number;
  pkComponente: string;
  codComponente: string;
  descComponente: string;
  pkScad: string;
  importoScadenza: number;
  cupScadenza: string;
  cigScadenza: string;
  pkSoggetto: string;
  ragsocSoggetto: string;
  tipoSoggetto: string;
  codFiscSoggetto: string;
  pivaSoggetto: string;
  pkDoc: string;
  annoDoc: number;
  numSicerDoc: string;
  codCausaleDoc: string;
  descCausaleDoc: string;
  tipoDoc: string;
  descTipoDoc: string;
  dtRegDoc: string;
  dtDoc: Date;
  numDoc: string;
  numRuf: number;
  annoRuf: number;
  flagFattura: string;
  pkLiq: string;
  tipoLiq: string;
  descTipoLiq: string;
  siglaLiq: string;
  dataLiq: Date;
  annoLiq: number;
  numLiq: string;
  descLiq: string;
  statoLiq: string;
  descStatoLiq: string;
  annoPropAtto: number;
  numPropAtto: string;
  numApprovAtto: string;
  dataAtto: Date;
  descAtto: string;
  flagEsecutiva: string;
  dtEsecutivita: Date;
  tipoAtto: string;
  descTipoAtto: string;
  pkOrd: string;
  versoOrd: string;
  tipoOrd: string;
  annoOrd: number;
  numeroOrd: number;
  dtEmisOrd: Date;
  dtRiscontroOrd: Date;
  descOrd: string;
  statoOrd: string;
  descStatoOrd: string;
  dtVariazione: Date;
  finanziamentoId: number;
  interventoId: number;

  ordinativi: SicerOrdinativo[];
}
