import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    TemplateRef
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BancaDatiEsternaService } from '../../services';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Router } from '@angular/router';


@Component({
    selector: 'gestione-banche-dati-esterne-component',
    templateUrl: './gestione-banche-dati-esterne.component.html',
    styleUrls: ['./gestione-banche-dati-esterne.component.css']
})
export class GestioneBancheDatiEsterneComponent
    implements OnInit {
    dtOptions: any = {};
    visible = false;
    submitted: boolean = false;
    addSchoolYearForm: FormGroup;
    modalRef: BsModalRef;
    open: boolean = false;

    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;
    @ViewChild('templateModal', { static: true })
    public templateModal: TemplateRef<any>;

    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    columns = [
        {
            name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
        }
       
        , {
            name: 'titolo&ensp;'
            , prop: 'titolo'
            , sortable: true
            , filtrable: true
            , flexGrow: 14
            , minWidth: 200


        }        
        , {
            name: 'modifica'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60

        }
        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60
        }
    ];


    templateList = [];
    constructor(
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private el: ElementRef,
        private localeService: BsLocaleService,
        private toastr: ToastService,
        private modalService: BsModalService,
        private currentService: BancaDatiEsternaService,
        private router:Router
    ) {
        this.localeService.use("it");
        this.dtOptions = {
            rows: []
            ,totalElements:0
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: false// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: false// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: false
            , scrollbarH:"true"
        };


        let url = this.router.url;
        console.log(url);
        
    }

    

    ngOnInit() {
        this.templateList = [ { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }, { name: 'btnEditTemplate', template: this.btnEditTemplate }];
        window.scroll(0, 0);
        this.addSchoolYearForm = this.initializeFormGroup();        
        this.getBancheDatiEsterne();
    }


    initializeFormGroup() {
        let frm =  this.formBuilder.group({
            id:[null],
            titolo: [null, [Validators.required, Validators.maxLength(255)]],            
        });
        
        return frm;
        
    }

    get f() {
        return this.addSchoolYearForm.controls;
    }


    onSubmit() {
        this.submitted = true;
        this.addSchoolYearForm.updateValueAndValidity();
        let errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );
        }
        if (this.addSchoolYearForm.invalid || errors) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.addSchoolYearForm.controls) {
                if (this.addSchoolYearForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.addSchoolYearForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.addSchoolYearForm.get(el)["controls"]) {
                            if (this.addSchoolYearForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }

        let obj = this.addSchoolYearForm.value;
        let subFunctionOk = (res)=>{
            this.toastr.success("Banca dati esterna salvata con successo!");
            this.submitted = false;
            this.open = false;
            this.addSchoolYearForm.reset();
            
            this.buttonLabel="Inserisci";
            if (!obj.id){
                //new row
                this.ngxDatatableWrapper.addRow(res);                        
                //this.getMotivazioni();
            }
            else{
                this.modalRef.hide();
                this.ngxDatatableWrapper.updateRow(obj);                                                
                //this.getMotivazioni();
            }
        };
        let subFunctionKo = (error)=>{this.toastr.error("Errore di sistema");};

        this.currentService.save(obj).subscribe(subFunctionOk,subFunctionKo);
        
    }
    buttonLabel:string="Inserisci";
    loading:boolean=false;
    config = {
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };
    onEdit(row) {
        this.submitted = false;
        console.log(row);
        this.buttonLabel = "Aggiorna";
        this.modalRef = this.modalService.show(this.templateModal, this.config);
        this.addSchoolYearForm.setValue({
            id: row.id,
            titolo:row.titolo            
        });
    }
    onDelete(row) {
        console.log(row);
        if (!confirm(`Stai per eliminare la banca dati esterna "${row.titolo}", proseguire?`)) return;
        this.loading = true;
        
        let subFunctionOk = 
            (result) =>
         {
            this.loading = false;
            this.ngxDatatableWrapper.deleteRow(row);
            this.toastr.success('Banca dati esterna eliminata con successo', null, {
                timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
            });
        };
        let subFunctionKo =
        ( error) => {
            this.loading = false;
            this.toastr.error(`Impossibile eliminare la banca dati esterna: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
            });
            console.log('error');
        };

        this.currentService.delete(row.id).subscribe(subFunctionOk,subFunctionKo);
        
    }
    closeModal(){
        this.modalRef.hide();
        this.addSchoolYearForm.reset();
        this.buttonLabel="Inserisci";
    }  

    getBancheDatiEsterne() {
        

        let subscribeFunction = (result)=> {            
            this.ngxDatatableWrapper.dtOptions = this.dtOptions;
            this.ngxDatatableWrapper.setInitialData(result);

        };

        
        this.currentService.getAll().subscribe(subscribeFunction);        
        


        
    }
}
