import { CurrencyPipe } from '@angular/common';
import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    TemplateRef,
    OnDestroy, EventEmitter, Output, Input
} from "@angular/core";
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl, ValidatorFn } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
    FinanziamentoService, 
    TipoStrumentoAttuativoService, AreeTematicheService
    , PnrrService,StorageService,
    TipoFinanziamentoService
} from '../../services';
import { TipoStrumentoAttuativo, TipoFinanziamento } from '../../models';
import { FasiComponent } from '../fasi/fasi.component';
import { registerLocaleData } from '@angular/common';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import localeIt from '@angular/common/locales/it';
import { OrderPipe } from 'ngx-order-pipe';
//import { DocumentazioneComponent } from '../documentazione/documentazione.component';
import { AssociaProvvedimentiComponent } from '../associaProvvedimenti/associa-provvedimenti.component';
import { AssociaAnnualitaComponent } from '../associaAnnualita/associa-annualita.component';
import { RiepilogoFinanziamentoComponent } from '../riepilogoFinanziamento/riepilogo-finanziamento.component';
import { AuthService } from 'src/app/services';
import { GenericSelectionModalComponent } from "../genericSelectionModal/generic-selection-modal.component";
import { ColumnMode } from '@swimlane/ngx-datatable';
import * as FileSaver from "file-saver";
import { StoricoFinanziamentoComponent } from '../storicoFinanziamento/storico-finanziamento.component';
import { FinanziamentoUsatoDaInterventiComponent } from '../finanziamentoUsatoDaInterventi/finanziamento-usato-da-interventi.component';
registerLocaleData(localeIt, 'it');
defineLocale('it', itLocale);

import { IsDirtyHelper } from "../../../../helpers/is-dirty.helper"

import { FinanziamentoDivDettaglioService } from "../finanziamentoDivDettaglio/finanziamento-div-dettaglio.service";
import { AssociaProvvedimentiDocumentiComponent } from '../associa-provvedimenti-documenti/associa-provvedimenti-documenti.component';
import { SicerBilancioComponent } from '../sicerBilancio/sicer-bilancio.component';
import { ServiceType } from '../../models/service-type';

@Component({
    selector: 'finanziamento-component',
    templateUrl: './finanziamento.component.html',
    styleUrls: ['./finanziamento.component.css']
})
export class FinanziamentoComponent
    implements OnInit, OnDestroy {

    type: ServiceType = ServiceType.FINANZIAMENTO;
    section: string = 'Finanziamenti';
    public mask: any = {
        mask: 'KK €',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            }
        }
    };
    public maskCofin: any = {
        mask: 'KK %',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ',',
                max: 100
            }
        }
    };

    isDirty = false;
    setIsDirty(value: boolean) {
        this.isDirtyHelper.isDirty = value;
        this.isDirty = value;
    }

    tipiStrumentiAttuativi: TipoStrumentoAttuativo[];
    tipiFinanziamento: TipoFinanziamento[];

    submitted: boolean = false;
    loading: boolean = false;
    editForm: FormGroup;
    modalRef: BsModalRef;
    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };
    open: boolean = false;
    buttonLabel: string = "Inserisci";
    modalTitle: string = "Inserisci finanziamento";

    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;
    @ViewChild('templateModal', { static: true })
    public templateModal: TemplateRef<any>;



    //@ViewChild("tabDocumentazioneContent", { static: true })
    //tabDocumentazioneContent: DocumentazioneComponent;
    @ViewChild("tabFasiContent", { static: true })
    tabFasiContent: FasiComponent;
    @ViewChild("tabProvvedimentiContent", { static: true })
    tabProvvedimentiContent: AssociaProvvedimentiComponent;
    @ViewChild("tabAnnualitaContent", { static: true })
    tabAnnualitaContent: AssociaAnnualitaComponent;

    @ViewChild("divTemplateRiepilogo", { static: true })
    divTemplateRiepilogo: RiepilogoFinanziamentoComponent;

    @ViewChild("divTemplateStorico", { static: true })
    divTemplateStorico: StoricoFinanziamentoComponent;

    @ViewChild("divTemplateUsatoInterventi", { static: true })
    divTemplateUsatoInterventi: FinanziamentoUsatoDaInterventiComponent;


    @ViewChild("divProvvedimentiDocumentiContent", { static: true })
    divProvvedimentiDocumentiContent: AssociaProvvedimentiDocumentiComponent;

    @ViewChild("divSicerBilancioContent", { static: true })
    divSicerBilancioContent: SicerBilancioComponent;



    mode: string = 'insertedit';

    constructor(
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private el: ElementRef,
        private localeService: BsLocaleService,
        private toastr: ToastService,
        private modalService: BsModalService,
        private currentService: FinanziamentoService,
        private tipoStrumentoAttuativoService: TipoStrumentoAttuativoService
        , private orderPipe: OrderPipe
        , private authService: AuthService
        , private areeTematicheService: AreeTematicheService
        , private isDirtyHelper: IsDirtyHelper
        , private finanziamentoDivDettaglioService: FinanziamentoDivDettaglioService
        , private pnrrService: PnrrService,
        private storageService:StorageService,
        private tipoFinanziamentoService: TipoFinanziamentoService
    ) {
        this.localeService.use("it");
        this.getTipiFinanziamento();
        this.getTipiStrumentiAttuativi();
        //this.getPnrrLineeFinanziamento();
    }


    ngOnInit() {
      window.scroll(0, 0);
      this.editForm = this.initializeFormGroup();
      this.strumentoAttuativoForm = this.initializeStrumentoAttuativoForm();
      this.fonteFinanziamentoForm = this.initializeFonteForm();
    }

    fonteFinanziamentoForm: FormGroup;

    @ViewChild('templateModalFonteFinanziamento') templateModalFonteFinanziamento: TemplateRef<any>;
    submitFonte: boolean = false;

    initializeFonteForm() {
      let frm = this.formBuilder.group({
        id:[null],
        tipoFinanziamento: [null, [Validators.required, Validators.maxLength(255)]],  
      });
      return frm;
    }

    openModalFonteFinanziamento($event) {
      this.modalRef = this.modalService.show(
        this.templateModalFonteFinanziamento, 
        Object.assign({}, this.configModal)
      );
    };
    

    closeModalFonteFinanziamento() {
      this.modalService.hide()
      this.fonteFinanziamentoForm.reset();
    }

    onSubmitFonteFinanziamento() {
      this.submitFonte = true;

      if(!this.validateForm(this.fonteFinanziamentoForm)) {
        this.fonteFinanziamentoForm.updateValueAndValidity();
        this.setIsDirty(true);
        return;
      }

      this.setIsDirty(true);

      const newFonte = {
        id: this.fonteFinanziamentoForm.get('id').value,
        tipoFinanziamento: this.fonteFinanziamentoForm.get('tipoFinanziamento').value
      }

      this.tipoFinanziamentoService.insert(newFonte).subscribe((response) => {
        const newId = response.id;
        newFonte.id = newId;
        this.tipiFinanziamento.push(newFonte);
        this.closeModalFonteFinanziamento();
        this.submitFonte = false;

        this.toastr.success('Fonte finanziamento aggiunta con successo!')
        this.editForm.get('tipologia').setValue(newId);
        this.storageService.reloadTipiFinanziamento();
        this.tipologiaChange(newFonte);
      }, () => {
        this.toastr.error("Si è verificato un' errore inserendo fonte finanziamento")
      })

    }

    get ff() {
      return this.fonteFinanziamentoForm.controls;
    };

    @ViewChild('templateStrumentoAttutativoModal') templateStrumentoAttutativoModal: TemplateRef<any>;
    submitSt: boolean = false;

    configModal = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-lg'
    }

    openModalStrumentoAttuativo($event) {
      this.modalRef = this.modalService.show(
        this.templateStrumentoAttutativoModal,
        Object.assign({}, this.configModal)
      );
    };

    closeModalStrumentoAttuativo() {
      this.strumentoAttuativoForm.reset();
      this.modalService.hide();
    }

    strumentoAttuativoForm: FormGroup;
    initializeStrumentoAttuativoForm() {
      let frm = this.formBuilder.group({
          id: [null],
          descrizione: [null, [Validators.required, Validators.maxLength(255)]],
          cofinanziamento:[null,[Validators.required]]
      })
      return frm;
    }

    onSubmitStrumentoAttuativo() {
      this.submitSt = true;
      if(!this.validateForm(this.strumentoAttuativoForm)) {
        this.strumentoAttuativoForm.updateValueAndValidity();
        this.setIsDirty(true);
        return;
      };

      this.setIsDirty(true)

      const newSA = {
        id: this.strumentoAttuativoForm.get('id').value,
        descrizione: this.strumentoAttuativoForm.get('descrizione').value,
        cofinanziamento: (this.strumentoAttuativoForm.get('cofinanziamento').value === '1' ? true : false)
      }

      this.tipoStrumentoAttuativoService.insert(newSA).subscribe((response) => {
        const newId = response.id;
        newSA.id = newId;
        this.tipiStrumentiAttuativi.push(newSA);
        this.closeModalStrumentoAttuativo();
        this.submitSt = false;

        this.toastr.success('Tipologia di strumento attuativo inserito con successo!')
        this.editForm.get('strumentoAttuativo').setValue(newId);
        this.getTipiStrumentiAttuativi();

        this.strumentoAttuativoChange(newSA)
      },() => {
        this.toastr.error("Si è verificato un' errore durante l'inserimento della tipologia strumento attuativo")
      })
    
    }

    
    get s() {
    return this.strumentoAttuativoForm.controls;
    };

    getControls(form) {
        return form.controls;
    };

    validateForm(form) {
        form.updateValueAndValidity();
        let errors = false;
        for (let c in this.getControls(form)) {
          errors = errors || this.getControls(form)[c].errors !== null;
        }
        if (form.invalid || errors) {
          let invalidElements = [];
          for (let currElem in form.controls) {
            if (form.controls[currElem].invalid) {
              if (form.controls[currElem] instanceof FormGroup) {
                for (let control in form.get(currElem)["controls"]) {
                  if (form.get(currElem)["controls"][control].invalid) {
                    invalidElements = this.el.nativeElement.querySelectorAll(
                      `[formcontrolname="${control}"]`
                    );
                    break;
                  }
                }
              } else {
                invalidElements = this.el.nativeElement.querySelectorAll(
                  `[formcontrolname="${currElem}"]`
                );
              }
              break;
            }
          }
          if (invalidElements.length > 0) {
            invalidElements[0].focus();
          }
          return false;
        }
        return true;
    }


    private elencoTipiFinSubscription= null;
    getTipiFinanziamento() {
        this.elencoTipiFinSubscription = this.storageService.elencoTipiFinanziamento.subscribe(
            x=>{
                console.log(x);
                this.tipiFinanziamento = x?x:[];
            }
            );
    }

    getTipiStrumentiAttuativi() {
        this.tipoStrumentoAttuativoService.getAll().subscribe(
            result => this.tipiStrumentiAttuativi = result
        );
    }

    //pnrrLineeFinanziamento =[];
    showPnrrLineeFinanziamento = false;
    /*getPnrrLineeFinanziamento(){
        this.pnrrService.getAllLineaFinanziamento().subscribe(
            x=>{
                this.pnrrLineeFinanziamento = x;
                this.pnrrLineeFinanziamento = this.pnrrLineeFinanziamento.map(y=>
                    {
                        y['descrizioneEstesa']=`${y.codice} - ${y.descrizione}`;
                        return y;
                    }
                );

            }
        );
    }*/

    initializeFormGroup() {
        let form = this.formBuilder.group({
            id: [null],
            titolo: [null, [Validators.required]],
            note: [null],
            importoComplessivo: [null],
            disponibilitaResidua: [null],
            tipologia: [null, [Validators.required]],
            strumentoAttuativo: [null],
            percentualeCofinanziamento: [null, [this.requiredIfIsCofinanziamento(this)]]
            , scadenza: ["", [Validators.required]]
            , codice: { value: null, disabled: true }
            /*, pnrrLineaFinanziamento:[null]*/
            , pnrrRilevante: [null]
        });
        form.controls.disponibilitaResidua.disable();
        form.controls.importoComplessivo.disable();
        form.valueChanges.subscribe(val =>
            this.setIsDirty(form.dirty)
        );

        return form;
    }

    get f() {
        return this.editForm?.controls;
    }




    requiredIfIsCofinanziamento(that: any): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            console.log('inside requiredIfIsCofinanziamento');
            console.log(`isCofinanziamento=${that.isCofinanziamento}`);
            if (!control || !control.parent) return null;
            if (control.value && control.value != '') return null;

            let mainCtrl = control.parent.get('strumentoAttuativo') as FormControl;
            console.log(`mainctrl = ${mainCtrl}`);
            console.log(`mainctrl.value = ${mainCtrl.value}`);
            if (that.isCofinanziamento) return { required: true };
            return null;

        }
    }


    isCofinanziamento = false;
    strumentoAttuativoChange($event) {
        this.isCofinanziamento = false;
        if (!$event) return;
        console.log(`event`);
        this.isCofinanziamento = $event.cofinanziamento;
    }

    tipologiaChange($event) {
        //this.hidePnrrLineaFinanziamentoAndUnsetValidators();
        //if (!$event) return;
        /*if ($event.tipoFinanziamento.toLowerCase().includes('pnrr')){
            this.showPnrrLineaFinanziamentoAndSetValidators();
        }*/
    }

    pnrrRilevanteChange() {
        const isRilevante = this.f.pnrrRilevante.value == '1';
        if (isRilevante) this.showPnrrLineaFinanziamentoAndSetValidators();
        else this.hidePnrrLineaFinanziamentoAndUnsetValidators();
    }


    hidePnrrLineaFinanziamentoAndUnsetValidators() {
        this.showPnrrLineeFinanziamento = false;
        this.f?.tipologia?.enable();
        this.f?.scadenza?.enable();
        //this.editForm.controls['pnrrLineaFinanziamento'].clearValidators();
        // this.editForm.controls['pnrrLineaFinanziamento'].updateValueAndValidity();
        //this.setIsDirty(false);
    }

    showPnrrLineaFinanziamentoAndSetValidators() {
        this.showPnrrLineeFinanziamento = true;
        //tipologia
        const fonte = this.tipiFinanziamento.find(x => x.tipoFinanziamento.toLowerCase().indexOf('pnrr') >= 0);
        if (fonte) this.f.tipologia.setValue(fonte.id);
        this.f.tipologia.disable();
        this.f.scadenza.disable();
        //this.editForm.controls['pnrrLineaFinanziamento'].setValidators([Validators.required]);
        //this.editForm.controls['pnrrLineaFinanziamento'].updateValueAndValidity();
        //this.setIsDirty(false);
    }

    removePnrrLineaFinanziamento() {
        this.pnrrLineaFinanziamentoSelezionata = null;
        this.setIsDirty(true);
    }



    openModalPnrrLineeFinanziamento() {
        let modalDefinition = {
            columns: [
                {
                    name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
                    , resizeable: false
                }

                , {
                    name: 'codice'
                    , prop: 'codice'

                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 1
                    , minWidth: 65

                },
                {
                    name: 'missione'
                    , prop: 'missione.descrizione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 100
                },
                {
                    name: 'componente'
                    , prop: 'componente.descrizione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 100
                },
                {
                    name: 'descrizione'
                    , prop: 'descrizione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 100
                }
                ,
                {
                    name: 'misura PNRR'
                    , prop: 'misuraPnrr'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 100
                }
                , {
                    name: 'tipologia'
                    , prop: 'tipologia'

                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 70

                }
                , {
                    name: 'pnrr'
                    , prop: 'pnrr'
                    , sortable: true
                    , filtrable: true
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 2
                    , minWidth: 70
                }
                , {
                    name: 'pnc'
                    , prop: 'fc'
                    , sortable: true
                    , filtrable: true
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 2
                    , minWidth: 70
                }
                , {
                    name: 'quota assegnata pnrr'
                    , prop: 'quotaAssegnataPnrr'
                    , sortable: true
                    , filtrable: true
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 2
                    , minWidth: 70
                }
                , {
                    name: 'quota assegnata pnc'
                    , prop: 'quotaAssegnataFc'
                    , sortable: true
                    , filtrable: true
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 2
                    , minWidth: 70
                }
            ],
            ajax: this.getLineeFinanziamentoPnrrDt,
            pageTitle: "Seleziona linea di finanziamento PNRR/PNC",
            rows: [],
            totalElements: 0,
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            scrollbarH: "true",
            sorts: [{ dir: 'asc', prop: 'codice' }]
        };

        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl",
        };
        const initialState = {
            modalDefinition: modalDefinition,
            dtOptionsNgx: modalDefinition,
        };

        this.modalRefSelectLineaFinPnrrFc = this.modalService.show(
            GenericSelectionModalComponent,
            Object.assign({}, config, { initialState })
        );
        console.log("apertura modale GenericSelectionModalComponent");
        this.clearSubscriptions();
        let subscription = this.modalRefSelectLineaFinPnrrFc.content.onSubmitOk.subscribe((data) => {
            console.log("inside ok of lineepnrr");
            if (this.pnrrLineaFinanziamentoSelezionata) {
                this.toastr.error("Esiste già una linea di finanziamento PNRR/PNC associata", null, {
                    timeOut: 2000,
                    disableTimeOut: false,
                });
                return;
            }
            this.pnrrLineaFinanziamentoSelezionata = data;
            this.getFasiDellaPnrrLineaFinanziamento(data.id);

            this.toastr.success("Linea di finanziamento PNRR/PNC aggiunta con successo", null, {
                timeOut: 2000,
                disableTimeOut: false,
            });
            this.setIsDirty(true);
            this.closeModalRefSelectLineaFinPnrrFc();

        });
        this.modalSubscriptions.push(subscription);
    }
    modalRefSelectLineaFinPnrrFc: BsModalRef;

    closeModalRefSelectLineaFinPnrrFc() {
        if (this.modalRefSelectLineaFinPnrrFc) this.modalRefSelectLineaFinPnrrFc.hide();
    }

    getLineeFinanziamentoPnrrDt = (dataTablesParameters: any, callback: any) => {
        this.pnrrService.getAllLineaFinanziamentoDt(dataTablesParameters, true)
            .subscribe(resp => {
                callback(resp);
            });
    };

    getFasiDellaPnrrLineaFinanziamento(idLinea) {
        this.pnrrService.getFasi(idLinea).subscribe(
            result => {
                let maxDate = null;
                for (const fase of result) {
                    if (!maxDate) maxDate = fase.dataPrevistaFine;
                    if (moment(maxDate).isBefore(moment(fase.dataPrevistaFine))) maxDate = fase.dataPrevistaFine;
                }
                if (maxDate) {
                    this.f.scadenza.setValue(moment(maxDate).format('DD-MM-YYYY'));
                    this.f.scadenza.disable();
                }
                else {
                    this.f.scadenza.enable();
                }
            }
        );
    }



    pnrrLineaFinanziamentoSelezionata = null;
    pnrrLineaFinanziamentoIsError = false;
    onSubmit() {
        this.submitted = true;
        this.editForm.updateValueAndValidity();

        this.pnrrLineaFinanziamentoIsError = !(this.pnrrLineaFinanziamentoSelezionata || !this.showPnrrLineeFinanziamento);

        for (let c in this.editForm.controls) {
            this.editForm.controls[c].updateValueAndValidity();
        }
        let errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );
        }
        if (this.editForm.invalid || errors || this.pnrrLineaFinanziamentoIsError || !this.areeTematicheSelezionate || this.areeTematicheSelezionate.length <= 0) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.editForm.controls) {
                if (this.editForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.editForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.editForm.get(el)["controls"]) {
                            if (this.editForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }

        const obj = this.editForm.getRawValue();
        obj.tipoFinanziamento = this.tipiFinanziamento.find(x => x.id == obj.tipologia);
        obj.tipoStrumentoAttuativo = this.tipiStrumentiAttuativi.find(x => x.id == obj.strumentoAttuativo);
        obj.pnrrLineaFinanziamento = this.pnrrLineaFinanziamentoSelezionata;
        obj.scadenza = moment(this.editForm.controls.scadenza.value, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss');//.utc(false).toDate();
        obj.pnrrRilevante = this.f.pnrrRilevante.value == '1';
        if (!obj.pnrrRilevante) obj.pnrrLineaFinanziamento = null;
        obj.areeTematiche = this.areeTematicheSelezionate;
        this.currentService.save(obj).subscribe(res => {
            this.toastr.success("Finanziamento salvato con successo!");
            this.submitted = false;
            this.open = false;
            this.setIsDirty(false);

            let oldId = this.editForm.controls.id.value;
            if (!oldId || oldId == '-1') {
                this.saveElementEmitter.emit(res.id);
                //ero in insert, ed in modale, quindi devo mostrare warning di ricordarsi di associare il finanziamento
            }
            //this.editForm.reset();
            //this.buttonLabel = "Inserisci";
            if (!obj.id) {
                //new row
                this.onEdit(res.id);
            }

        }, error => {
            this.loading = false;
            this.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                timeOut: 2000, disableTimeOut: false
            });
            console.log('error');
        }
        );
    }


    modalIsShowed: boolean = false;
    isInInsertState: boolean = false;
    statoFinanziamentoLabel: string = '';
    readOnlyForm: boolean = false;
    validato: boolean = false;
    hasProvvedimenti: boolean = false;
    areeTematicheSelezionate = [];


    hideAnnualitaTab = false;
    pnrrRilevante = false;

    onEdit(id) {
        if (this.loading) return;
        //this.hidePnrrLineaFinanziamentoAndUnsetValidators();
        let that = this;
        if (this.finanziamentoDivDettaglioService)
            window.setTimeout(
                () =>
                    this.finanziamentoDivDettaglioService.setFinanziamentoId(id),
                10
            );
        if (this.finanziamentoDivDettaglioService)
            window.setTimeout(() => this.finanziamentoDivDettaglioService.reload(), 10);

        this.currentService.getById(id).subscribe(result => {
            that.editForm.enable();
            that.areeTematicheSelezionate = result['areeTematiche'];
            that.editForm.controls.codice.disable();
            that.editForm.controls.importoComplessivo.disable();
            that.editForm.controls.disponibilitaResidua.disable();
            that.readOnlyForm = false;
            that.validato = false;
            that.mode = 'insertedit';
            that.isInInsertState = false;
            that.submitted = false;
            that.buttonLabel = "Aggiorna";
            that.modalTitle = "Modifica finanziamento";
            that.activeTab = "tabDatiGenerali";
            //open the modal only if it's not already showed
            //if (!this.modalIsShowed) this.modalRef = this.modalService.show(this.templateModal, this.config);
            //this.modalIsShowed = true;
            let scadenza = moment(result['scadenza']).toDate();
            scadenza.setHours(scadenza.getHours() - scadenza.getTimezoneOffset() / 60);
            that.editForm.setValue({
                id: result.id,
                titolo: result.titolo,
                importoComplessivo: result.importoComplessivo ? result.importoComplessivo.toString() : null,
                disponibilitaResidua: result.disponibilitaResidua ? result.disponibilitaResidua.toString() : null,
                tipologia: result.tipoFinanziamento ? result.tipoFinanziamento.id : null,
                strumentoAttuativo: result.tipoStrumentoAttuativo ? result.tipoStrumentoAttuativo.id : null
                , percentualeCofinanziamento: result.percentualeCofinanziamento ? result.percentualeCofinanziamento.toString() : null
                , scadenza: scadenza
                , codice: result.codice ? result.codice : null
                , note: result.note ? result.note : null
                /*,pnrrLineaFinanziamento:result.pnrrLineaFinanziamento?result.pnrrLineaFinanziamento.id:null*/
                , pnrrRilevante: result.pnrrRilevante ? '1' : '0'
            });
            that.pnrrLineaFinanziamentoSelezionata = result.pnrrLineaFinanziamento;
            that.strumentoAttuativoChange(result.tipoStrumentoAttuativo);
            that._finanziamentoId = result.id;

            let status = result.validazione ? (result.validazione.validationStatus ? result.validazione.validationStatus : 0) : 0;
            let msg = '';
            let action = false;
            //status =0 posso richiede approvazione
            switch (status.toString().toUpperCase()) {
                case '0':
                case 'IDLE':
                    that.statoFinanziamentoLabel = 'non impostato';
                    break;
                case 'REJECTED':
                case '3':
                    that.statoFinanziamentoLabel = 'rifiutato';
                    break;
                case '1':
                case 'APPROVED':
                    that.statoFinanziamentoLabel = 'validato';
                    that.validato = true;
                    break;
                case '4':
                case 'WAITING_FOR_APPROVAL_FIRST_LEVEL':
                    that.statoFinanziamentoLabel = 'in attesa di validazione';
                    that.validato = true;
                    break;
                case 5:
                case 'WAITING_FOR_APPROVAL_SECOND_LEVEL':
                    that.statoFinanziamentoLabel = 'in attesa di validazione';
                    that.validato = true;
                    break;
                default:
                    that.statoFinanziamentoLabel = '';
                    return;
            }
            if (result.isReadOnly) that.readOnlyForm = true;
            that.readOnlyForm = that.readOnlyForm || !that.canEdit();
            if (that.readOnlyForm || that.validato) that.editForm.disable();
            //le note sono sempre editabili se ho i permessi
            if (!that.readOnlyForm) that.editForm.controls.note.enable();
            this.tipologiaChange(result.tipoFinanziamento);
            this.editForm.markAsPristine();

            this.hasProvvedimenti = result.provvedimenti && result.provvedimenti.length > 0;
            this.hideAnnualitaTab = (result.pnrrRilevante && !this.hasProvvedimenti);
            this.pnrrRilevante = result.pnrrRilevante;
            this.f.pnrrRilevante.enable();
            if (
                this.pnrrRilevante && (this.hasProvvedimenti )
                ) this.f.pnrrRilevante.disable();
            //this.pnrrRilevanteChange();
            if (result.pnrrRilevante) this.showPnrrLineaFinanziamentoAndSetValidators();
            else this.hidePnrrLineaFinanziamentoAndUnsetValidators();

            if (!this.isAdmin() && !this.isGr34()){
                if (!this.gestisciNonPnrr()){
                    this.f.pnrrRilevante.disable();
                    this.f.pnrrRilevante.setValue('1');
                    this.showPnrrLineaFinanziamentoAndSetValidators();
                }
                if (!this.gestisciPnrr()){
                    this.f.pnrrRilevante.disable();
                    this.f.pnrrRilevante.setValue('0');
                    this.hidePnrrLineaFinanziamentoAndUnsetValidators();
                }
    
            }
            this.setIsDirty(false);
        });

    }

    associaProvvedimentiChanged($event) {
        console.log($event);
        //$event true è stato associato un provvedimento
        //$event false è stato rimosso un provvedimento
        this.f.pnrrRilevante.enable();
        if (!$event) {
            this.getProvvedimentiByFinanziamentoId(this._finanziamentoId);
        }
        else {
            this.hideAnnualitaTab = false;
            if (this.pnrrRilevante) this.f.pnrrRilevante.disable();
        }
    }

    getProvvedimentiByFinanziamentoId(id: number) {
        this.currentService.getProvvedimenti(id).subscribe(
            result => {
                this.hasProvvedimenti = result.length > 0;
                this.hideAnnualitaTab = (this.pnrrRilevante && !this.hasProvvedimenti);
                if (this.pnrrRilevante && this.hasProvvedimenti) this.f.pnrrRilevante.disable();
            }
        );
    }


    onInsert() {
        if (this.finanziamentoDivDettaglioService)
            window.setTimeout(
                () =>
                    this.finanziamentoDivDettaglioService.setFinanziamentoId(null),
                10
            );
        if (this.finanziamentoDivDettaglioService)
            window.setTimeout(() => this.finanziamentoDivDettaglioService.reload(), 10);
        this.readOnlyForm = false;
        this.validato = false;
        this.areeTematicheSelezionate = [];
        this.pnrrLineaFinanziamentoSelezionata = null;
        if (this.editForm) {
            this.editForm.enable();
            this.editForm.controls.disponibilitaResidua.disable();
            this.editForm.controls.importoComplessivo.disable();
        }
        this.statoFinanziamentoLabel = '';
        this.activeTab = "tabDatiGenerali";
        this.isInInsertState = true;
        this.submitted = false;
        this.buttonLabel = "Inserisci";
        this.modalTitle = "Inserisci finanziamento";
        //this.modalRef = this.modalService.show(this.templateModal, this.config);
        if (this.editForm) {
            this.editForm.reset();
        }
        this.mode = 'insertedit';
        this._finanziamentoId = null;
        this.setIsDirty(false);
        this.hidePnrrLineaFinanziamentoAndUnsetValidators();
        this.hasProvvedimenti = false;

        if (!this.isAdmin() && !this.isGr34()){
            if (!this.gestisciNonPnrr()){
                this.f.pnrrRilevante.disable();
                this.f.pnrrRilevante.setValue('1');
                this.showPnrrLineaFinanziamentoAndSetValidators();
            }
            if (!this.gestisciPnrr()){
                this.f.pnrrRilevante.disable();
                this.f.pnrrRilevante.setValue('0');
                this.hidePnrrLineaFinanziamentoAndUnsetValidators();
            }

        }

        //this.modalIsShowed = true;
    }

    closeModal() {
        this.submitted = false;
        this.open = false;
        this.editForm.reset();

        this.buttonLabel = "Inserisci";
        this.modalRef.hide();
        this.modalIsShowed = false;
    }


    //finanziamentoId: number;
    //tabs functions
    confirmChangeTab($event, index) {
        if (!this.isDirtyHelper.checkIsDirty()) {
            $event.stopPropagation();
            return false;
        }

        if (index !== 13 && this.activeTab === "tabSicerBilancio") {
            this.divSicerBilancioContent.resetSubData();
        }
        console.log(`finanziamentoId = ${this.finanziamentoId}`);
        switch (index) {
            case 0:
                this.activeTab = "tabDatiGenerali";
                break;
            case 1:
                this.activeTab = "tabFasi";
                if (this.tabFasiContent) this.tabFasiContent.reloadData();
                break;
            case 2:
                this.activeTab = "tabAnnualita";
                if (this.tabAnnualitaContent) this.tabAnnualitaContent.reloadData();
                break;
            case 3:
                this.activeTab = "tabProvvedimenti";
                if (this.tabProvvedimentiContent) this.tabProvvedimentiContent.reloadData();
                break;
            case 4:
                this.activeTab = "tabDocumentazione";
                //if (this.tabDocumentazioneContent) this.tabDocumentazioneContent.ngxDatatableWrapper.refresh();
                break;
            case 9:
                this.activeTab = "tabRiepilogo";
                if (this.divTemplateRiepilogo) this.divTemplateRiepilogo.reloadData();
                break;
            case 10:
                this.activeTab = "tabStorico";
                if (this.divTemplateStorico) this.divTemplateStorico.reloadAllData();
                break;
            case 11:
                this.activeTab = "tabUsatoInterventi";
                if (this.divTemplateUsatoInterventi) this.divTemplateUsatoInterventi.reloadAllData();
                break;
            case 12:
                this.activeTab = "tabProvvedimentiDocumenti";
                if (this.divProvvedimentiDocumentiContent) this.divProvvedimentiDocumentiContent.reloadAllData();
                break;
            case 13:
                this.activeTab = "tabSicerBilancio";
                if (this.divSicerBilancioContent) this.divSicerBilancioContent.reloadAllData();
                break;
        }
    }
    activeTab: string = "tabDatiGenerali";

    //changedAnnulaita will be call if an annualita is inserted, deleted or updated. I need to update finanziamento data like importo complessivo
    changedAnnualita($event) {
        this.reloadAll();
    }
    reloadAll() {
        //this.getFinanziamenti();
        if (this.finanziamentoId) {
            this.currentService.getById(this.finanziamentoId).subscribe(
                result => {
                    this.editForm.controls.importoComplessivo.setValue(result['importoComplessivo'].toString());
                }
            );
        }
    }
    printValueAsCurrency(value) {
        if (!value) value = 0;
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }
    sortImporti(arr) {
        return this.orderPipe.transform(arr, 'anno');
    }

    isAdmin() {
        return this.authService.isAdmin();
    }
    isDirectionalGroup() {
        return this.authService.isDirectionalGroup();
    }
    isAreaStaff() {
        return this.authService.isAreaStaff();
    }
    isExternal() {
        return this.authService.isExternal();
    }
    isViewer() {
        return this.authService.isViewer();
    }


    ngOnDestroy(): void {
        this.clearSubscriptions();
        if(this.elencoTipiFinSubscription)this.elencoTipiFinSubscription.unsubscribe();
        if (this.finanziamentoDivDettaglioService)
            window.setTimeout(
                () => this.finanziamentoDivDettaglioService.setFinanziamentoId(null),
                10
            );
    }


    modalSubscriptions = [];

    getAreeTematicheDTNgx = (dataTablesParameters: any, callback: any) => {
        this.areeTematicheService
            .getAllDtOnlyActive(dataTablesParameters)
            .subscribe((resp) => {
                callback(resp);
            });
    };

    clearSubscriptions() {
        this.modalSubscriptions.forEach((x) => x.unsubscribe());
        this.modalSubscriptions = [];
    }

    openModalAT() {
        let modalDefinition = {
            pageTitle: "Seleziona le aree gestionali e/o esterne",
        };

        let modalDefinitionNgx = {
            rows: [],
            totalElements: 0,
            columns: [
                {
                    name: "id",
                    prop: "id",
                    visible: false,
                    sortable: true,
                    filtrable: true,
                    width: 10,
                    resizeable: false,
                },

                {
                    name: "descrizione",
                    prop: "descrizione",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 4,
                    minWidth: 200,
                },
                {
                    name: "codice ipa",
                    prop: "codiceIpa",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 1,
                    minWidth: 60,
                },
                {
                    name: "struttura",
                    prop: "struttura",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 2,
                    minWidth: 100,
                },
                {
                    name: "nominativo",
                    prop: "nominativo",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 3,
                    minWidth: 200,
                },
            ],
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            ajax: this.getAreeTematicheDTNgx,
            scrollbarH: "true",
        };

        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl",
        };
        const initialState = {
            modalDefinition: modalDefinition,
            dtOptionsNgx: modalDefinitionNgx,
        };

        this.modalRef = this.modalService.show(
            GenericSelectionModalComponent,
            Object.assign({}, config, { initialState })
        );
        this.clearSubscriptions();
        let subscription = this.modalRef.content.onSubmitOk.subscribe((data) => {
            console.log("inside ok of area tematica");
            if (!this.areeTematicheSelezionate) this.areeTematicheSelezionate = [];
            let existsObj = this.areeTematicheSelezionate.find(
                (x) => x.id == data.id
            );
            if (existsObj) return; //already added
            this.areeTematicheSelezionate.push(data);
            this.areeTematicheSelezionate = Object.assign(
                [],
                this.areeTematicheSelezionate
            );

            this.toastr.success("Area direzione inserita con successo", null, {
                timeOut: 2000,
            /*closeButton:true,*/ disableTimeOut: false,
            });
            this.setIsDirty(true);
        });
        this.modalSubscriptions.push(subscription);
    }

    removeAreaTematica(item) {
        console.log(`inside removeAreaTematica id=${item.id}`);
        this.areeTematicheSelezionate = this.areeTematicheSelezionate.filter((x) => x.id != item.id);
        this.setIsDirty(true);
    }


    isReadOnly(info: any): boolean {
        //controllo se l'utente può modificare il progetto
        //let user = this.authService.getCurrentUser();
        return info.isReadOnly;
    }

    printDirezioneFieldValue(data) {
        let str = '';
        let arr = [];
        if (data && data != '') {
            //let obj = JSON.parse(data);

            for (let o in data) {
                arr.push(data[o]['descrizione']);
            }
            str = arr.join('<br />');
        }
        return arr;
    }


    //gestione apertura da finaziamneti component
    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
    gotoBackBtnClick($event) {
        if (!this.isDirtyHelper.checkIsDirty()) return false;
        if (this.activeTab === "tabSicerBilancio") {
            this.divSicerBilancioContent.resetSubData();
        }
        this.gotoBtnFunction.emit(true);
    }

    @Output()
    saveElementEmitter: EventEmitter<any> = new EventEmitter();


    //gestione apertura in modale
    @Output() closeModalEmitter: EventEmitter<any> = new EventEmitter();
    _finanziamentoId: number;
    @Input()
    set finanziamentoId(value: number) {
        this._finanziamentoId = value;
        if (this._finanziamentoId <= 0) this._finanziamentoId = null;
        if (!this._finanziamentoId) this.onInsert();
        else this.onEdit(this._finanziamentoId);
    }
    get finanziamentoId() {
        return this._finanziamentoId;
    }
    _finanziamento: any;
    @Input()
    set finanziamento(value: any) {
        this._finanziamento = value;
        // this.onEdit(this._finanziamento);
    }
    get finanziamento() {
        return this._finanziamento;
    }

    closeMainModal(data) {
        this.closeModalEmitter.emit(data);
    }
    _isInModal: boolean;

    @Input()
    set isInModal(value: boolean) {
        this._isInModal = value;
    }
    get isInModal() { return this._isInModal; }

    esportaPdf() {
        this.loading = true;
        this.currentService.esportaPdf(this._finanziamentoId).subscribe(
            res => {
                this.loading = false;
                let filename = `esportazione_finanziamento_${this.editForm.controls.codice.value}.pdf`;
                FileSaver.saveAs(
                    res.body,
                    `${filename}`
                );
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );
    }

    esportaExcel() {
        this.loading = true;
        this.currentService.esportaExcel(this._finanziamentoId).subscribe(
            res => {
                this.loading = false;
                let filename = `esportazione_finanziamento_${this.editForm.controls.codice.value}.xlsx`;
                FileSaver.saveAs(
                    res.body,
                    `${filename}`
                );
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );
    }

    canDeactivate() {
        this.isDirty = this.isDirtyHelper.isDirty;
        let temp = this.isDirty;
        /*if (this.tabFasiContent)temp = temp || this.tabFasiContent.canDeactivate();
        if (this.tabAnnualitaContent)temp = temp || this.tabAnnualitaContent.canDeactivate();

        if (this.tabProvvedimentiContent)temp = temp || this.tabProvvedimentiContent.canDeactivate();
        if (this.tabDocumentazioneContent)temp = temp || this.tabDocumentazioneContent.canDeactivate();
        */
        console.log(`isDirty=${temp}`);



        return this.isDirty;
    }

    canEdit() {
        return (this.enableSection(this.section, ['update'], null, this.isAdmin() || this.isGruppoPnrr()));
    }
    /**
* Controlla se l'utente può accedere o meno alla sezione.
* @param section il nome della sezione
* @param operations le operazioni da poter effettuare sulla sezione
* @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
* @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
* @returns true se il controllo è andato a buon fine, false altrimenti
*/
    enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
        return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
    }
    isGruppoPnrr() {
        return this.authService.isGruppoPnrr();
    }
    isGr34(){
        return this.authService.isGr34();
    }
    isManager(){
        return this.authService.isManager();
    }
    gestisciNonPnrr() {
        return this.authService.gestisciNonPnrr();
      }
      gestisciPnrr() {
        return this.authService.gestisciPnrr();
      }

    esportaExcelCompleto() {
        this.loading = true;
        this.currentService.esportaExcelCompleto(this._finanziamentoId).subscribe(
            res => {
                this.loading = false;
                let filename = `esportazione_finanziamento_${this.editForm.controls.codice.value}.xlsx`;
                FileSaver.saveAs(
                    res.body,
                    `${filename}`
                );
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );
    }

}



