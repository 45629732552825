export class AreaTematica {
  id: number;
  descrizione: string;
  codiceIpa: string;
  struttura: string;
  nominativo: string;
  descrizioneEstesa: string;
  disabled:boolean;
  obbiettivi: AreaTematicaObbiettivi[];
  gestisciEntitaPnrr:boolean;
  gestisciEntitaNonPnrr:boolean;
  visualizzaSoloEntitaDirezione:boolean;
  menu: AreaTematicaMenu[];
}

export class AreaTematicaObbiettivi {
  id: number;
  anno: number;
  descrizione: string;
}

export class AreaTematicaMenu {
  id: number;
  descrizione?: string;
}