import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-register-step4",
  templateUrl: "./register-step4.component.html",
  styleUrls: ["./register-step4.component.css"]
})
export class RegisterStep4Component implements OnInit {
  constructor() {}

  ngOnInit() {
    this.scrollTop();
  }

  scrollTop() {
    window.scroll(0, 0);
  }
}
