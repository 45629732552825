import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: "root",
})
export class ProtocolloService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}protocolli/`;
  constructor(private http: HttpClient) {}

  /*getAllDt(dataTablesParameters: any) {
    return this.http.post<DataTablesResponse>(
      `${this.apiUrl}alldt`,
      dataTablesParameters
    );
  }*/
  getAll(anno:string,numero:string) {
    return this.http.get<any[]>(`${this.apiUrl}?anno=${anno}&numero=${numero}`);
  }
  
    isPresente(codice:string,nome:string){
        return this.http.get<any[]>(`${this.apiUrl}documentoPresente?codice=${codice}&nome=${nome}`);
    }

    download(codice:string,nome:string) {
        return this.http.get(`${this.apiUrl}download?codice=${codice}&nome=${nome}`, {
          observe: "response",
          responseType: "blob"
        });
      }

    insert(id,what,protocollo){
        return this.http.post<any>(`${this.apiUrl}?objectId=${id}&type=${what}`, protocollo);
    }

    getAllDt(id:number,what,dataTablesParameters: any,protocolliType:string) {
        return this.http.post<any>(
            `${this.apiUrl}alldt?objectId=${id}&type=${what}&protocolliType=${protocolliType}`,
            dataTablesParameters
        );
    }

    getAllData(id:number,what,protocolliType:string){
        return this.http.get<any>(
            `${this.apiUrl}all?objectId=${id}&type=${what}&protocolliType=${protocolliType}`            
        );
    }
    delete (idParent:number,what:string,id:number){
        return this.http.delete<any>(`${this.apiUrl}${id}?objectId=${idParent}&type=${what}`);
    }

    saveNote(id:number,note:string){
        return this.http.put<any>(`${this.apiUrl}${id}/note`,{'note':note});
    }

    updateLavorato(id:number){
        return this.http.put(
        `${this.apiUrl}${id}/lavorato`,null
          );
    }
    updateNoteLavorazione(id:number,note:string){
        return this.http.put(
        `${this.apiUrl}${id}/noteLavorazione`,{noteLavorazione:note}
          );
    }

    sendEmailToRup(id:number,body:string,dest:string){
        return this.http.post(
            `${this.apiUrl}${id}/emailToRup`,{body,dest}
              );
    }

}
