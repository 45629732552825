<div *ngIf="news.enabled">
    <div class="card account-wall mb-3 shadow">
        <div class="card-body ">
            <h5 class="card-title bold">{{ news.title }}</h5>
            <time class="small">{{ news.datePub | date: "dd/MM/yyyy" }}</time>
            <hr/>
            <p class="card-text">
                <span class="firstPart">{{news.message.trim().substr(0,110)}}</span>
                <a *ngIf="showExpandAnchor" style="cursor:pointer" class="expandText" (click)="showExpandAnchorClick()">[...]</a>
                <span *ngIf="showExpandedText" class="secondPart">{{news.message.trim().substr(110,news.message.trim().length-110)}}</span>
            </p>
            <button *ngIf="news.filePath" class="btn btn-sm btn-primary btn-block" (click)="downloadFile(news)">
                    Scarica Allegato
                  </button>
        </div>
    </div>
</div>