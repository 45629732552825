import {
  Component,
  Input,
  OnInit,
} from "@angular/core";
import {  Router } from "@angular/router";
import { AuthService,UserService } from "../../../services/";
import { AppConfigService } from "src/app/services/app-config.service";
import { TitleLogoService } from "src/app/services/title-logo.service";

@Component({
  selector: "megaMenu",
  styleUrls: ["./megamenu.component.css"],
  templateUrl: "./megamenu.component.html",
})
export class MegaMenuComponent implements OnInit{

  title: string;

  logo: string;

  ente: any;

  @Input()
  items: any[];
  currentUser: any;
  titleExtraText: string = "";
  headerCode = null;

  constructor(
    public router: Router,
    private authService: AuthService,
    private userService:UserService,
    private titleLogoService: TitleLogoService
    ) {
    this.currentUser = this.authService.getCurrentUser();
    this.titleExtraText = AppConfigService.env.titleExtraText;
    this.headerCode = this.authService.getHeaderCode();
    if (!this.headerCode || this.headerCode==''){
        this.userService.getInfo('').subscribe(
            res=>{
                this.authService.setHeaderCode(res['headerCode']);
                this.headerCode = this.authService.getHeaderCode();
            }
        );
    }

  }

  ngOnInit(){
    this.title = this.titleLogoService.title;
    this.logo = this.titleLogoService.logo;
    this.ente = this.titleLogoService.ente;
  }


  isHeaderCustom(){
    return (this.headerCode && this.headerCode!='generale');
}

  canAccess(item) {
    if (item.permissions && item.permissions.length > 0) {
      if (this.currentUser.authorities == null) return false;
      let ris = false;

      item.permissions.forEach((element) => {
        ris = ris || this.currentUser.authorities.includes(element);
        if (ris) return;
      });
      return ris;
    }
    return true;
  }
  goToHome(): void {
    this.router.navigate(["/"]);
  }
  gotoPage(item, $event) {
    console.log(`item:${JSON.stringify(item)}`);
    if (item.link)
    {
        if(item.externalLink){
            window.open(item.link,'_blank');
        }
        else{
            this.router.navigate([item.link]);
        }
    }

    $event.preventDefault();
  }
  getElementId(index) {
    return "dropdown" + index;
  }
}
