<div class="col-12 mb-5">
    <div class="col-md-12">
        <p class="text-sm-left mt-3">
            In questa pagina è possibile <b>inserire una nuova Regione, visualizzare la lista delle Regioni presenti e modificarne i relativi dati</b>.
        </p>
    </div>
    <hr />
    <accordion [isAnimated]="true">
        <accordion-group heading="Inserisci Nuova Regione">
            <form [formGroup]="addRegionForm" (ngSubmit)="onSubmit()" class="col-lg-12">

                <div class="row">
                    <!-- *** -->
                    <div class="form-group col-xl-6 col-lg-6">
                        <h5><label for="name" class="mandatory">Nome Regione:
    </label></h5>
                        <div class="">
                            <input [formGroup]="addRegionForm" formControlName="name" type="text" value="" class="form-control" placeholder="Inserisci il nome della regione" [ngClass]="{
              'form-control': true,
              'is-invalid': submitted && f.name.errors
            }" />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.name.errors.required">
                                    Nome &egrave; obbligatorio
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-xl-6 col-lg-6">
                        <h5><label for="code" class="mandatory">Codice:
          </label></h5>
                        <div class="">
                            <input [formGroup]="addRegionForm" formControlName="code" type="text" value="" maxlength="2" class="form-control" placeholder="Inserisci il codice della regione" [ngClass]="{
              'form-control': true,
              'is-invalid': submitted && f.code.errors
            }" />
                            <div *ngIf="submitted && f.code.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.code.errors.required">
                                    Codice &egrave; obbligatorio
                                </div>
                                <div *ngIf="f.code.errors.minlength">
                                    Il codice deve essere di 2 caratteri
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="false">
                    <!-- *** -->
                    <div class="form-group col-xl-6 col-lg-6">
                        <h5> <label class="mandatory">Nazione:
          </label></h5>
                        <div class="">
                            <ng-select formControlName="nation" [items]="nationsAdd" bindValue="id" placeholder="Seleziona" bindLabel="value" selectOnTab="true" [searchable]="true" [clearable]="false" (change)="onChangeNewNation($event)" notFoundText="Nessun risultato trovato..."
                                [ngClass]="{
              'is-invalid': submitted && f.nation.errors
            }">
                            </ng-select>
                            <div *ngIf="submitted && f.nation.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.nation.errors.required">
                                    Nazione &egrave; obbligatoria
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- *** -->
                    <div class="form-group col-xl-6 col-lg-6">
                        <h5> <label class="">Data Inizio Validità:</label></h5>

                        <div class="input-group">
                            <input formControlName="fromDate" type="text" class="form-control" bsDatepicker #dp="bsDatepicker" [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true,
                containerClass: 'theme-dark-blue',
                isAnimated: true
              }" [ngClass]="{
                'is-invalid': submitted && f.fromDate.errors
              }" />
                            <button type="button" class="btn btn-primary mt-0" (click)="dp.show()" [attr.aria-expanded]="dp.isOpen">
              <i class="fas fa-calendar-alt"></i>
            </button>
                        </div>
                    </div>

                    <div class="form-group col-xl-6 col-lg-6">
                        <h5><label class="" style="letter-spacing: -0.2px;">Data Fine Validità:</label></h5>

                        <div class="input-group">
                            <input formControlName="toDate" type="text" class="form-control" bsDatepicker #dp2="bsDatepicker" [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true,
                containerClass: 'theme-dark-blue',
                isAnimated: true
              }" [ngClass]="{
                'is-invalid': submitted && f.toDate.errors
              }" />
                            <button type="button" class="btn btn-primary mt-0" (click)="dp2.show()" [attr.aria-expanded]="dp.isOpen">
              <i class="fas fa-calendar-alt"></i>
            </button>
                            <div *ngIf="submitted && f.toDate.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.toDate.errors.required">
                                    Data Fine Validità &egrave; obbligatoria
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text-center my-3">
                    <button class="btn btn-primary " type="submit">
          INSERISCI NUOVA REGIONE
          &nbsp;<i aria-hidden="true" class="fa fa-plus"></i>
        </button>
                </div>

            </form>
        </accordion-group>
    </accordion>

    <div class="mt-4">
        <h4 class="titoletti">Lista Regioni presenti in GLP</h4>

        <div class="form-group clearfix" *ngIf="false">
            <label for="provinces">Nazione <span aria-hidden="true">*</span>: </label>
            <ng-select [items]="nations" bindValue="id" placeholder="Seleziona" bindLabel="value" selectOnTab="true" [searchable]="true" [clearable]="false" (change)="onChangeNation($event)" notFoundText="Nessun risultato trovato...">
            </ng-select>
        </div>
        <div [hidden]="!visible">

            <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsNgx">
                <ng-template #btnEditTemplate let-row="row" let-value="value">
                    <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="editHandler(row)" data-toggle="tooltip" data-placement="top" title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer" ></i></a>
                </ng-template>
            </ngx-datatable-wrapper>
        </div>
    </div>
</div>
