import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  ElementRef,
} from "@angular/core";
import {
  SoggettoGiuridicoService,
  SoggettoRuoloService,
} from "../../../services";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { SoggettoGiuridico } from "../../../models";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { GeoService, ToastService } from "src/app/services";
import { Province } from "src/app/models/province";
import { IsDirtyHelper } from "src/app/helpers/is-dirty.helper";

@Component({
  templateUrl: "./soggetto-giuridico-selection-modal.component.html",
  styleUrls: ["./soggetto-giuridico-selection-modal.component.css"],
})
export class SoggettoGiuridicoSelectionModalComponent implements OnInit {
  roles = [];
  soggettiGiuridici = [];
  myGroup: FormGroup;
  submitted: boolean = false;
  @Output() onSubmitOk = new EventEmitter();

  constructor(
    private soggettoGiuridicoService: SoggettoGiuridicoService,
    private soggettoRuoloService: SoggettoRuoloService,
    private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private geoService: GeoService,
    private modalService: BsModalService,
    private el: ElementRef,
    private isDirtyHelper: IsDirtyHelper,
    private toastr: ToastService
  ) {}

  ngOnInit(): void {
    this.myGroup = this.initializeFormGroup();
    this.getSoggettiGiuridici();
    this.getRoles();
    this.soggGiuridiciForm = this.initializeSoggGiuridiciForm();
    this.getProvince();
    this.soggettoRuoloForm = this.initializeSoggettoRuoloForm();
  }

  province: Province[];
  provinceSelected: Province;

  getProvince() {
    this.geoService
      .getAllProvinceDt(0, {
        draw: 1,
        columns: [],
        order: [],
        start: 0,
        length: 2000,
        search: {},
      })
      .subscribe((resp) => {
        this.province = resp.data;
      });
  }

  setIsDirty(value: boolean) {
    this.isDirtyHelper.isDirty = value;
  }

  onSubmitSoggettoGiuridico() {
    this.submittedSoggGiuridici = true;

    if (!this.validateForm(this.soggGiuridiciForm)) {
      this.soggGiuridiciForm.updateValueAndValidity();
      this.setIsDirty(true);
      return;
    }

    this.setIsDirty(true);

    const soggGiuridiciForm = this.soggGiuridiciForm.value;

    const newSoggGiuridico = new SoggettoGiuridico();
    newSoggGiuridico.id = soggGiuridiciForm.id;
    newSoggGiuridico.codiceIdentificativo =
      soggGiuridiciForm.codiceIdentificativo;
    newSoggGiuridico.codiceIpa = soggGiuridiciForm.codiceIpa;
    newSoggGiuridico.denominazione = soggGiuridiciForm.denominazione;
    newSoggGiuridico.siglaProvincia = this.provinceSelected
      ? this.provinceSelected.acronym
      : null;

    this.soggettoGiuridicoService.insert(newSoggGiuridico).subscribe(
      (response) => {
        const newId = response.id;
        newSoggGiuridico.id = newId;
        this.soggettiGiuridici.push(newSoggGiuridico);
        this.closeModalSoggettiGiuridici();
        this.submittedSoggGiuridici = false;
        this.toastr.success("Soggetto responsabile salvato con successo!");
        this.myGroup.get("soggettoGiuridico").setValue(newId);

        this.getSoggettiGiuridici();
      },
      () => {
        this.toastr.error(
          "Si è verificato un' errore inserendo soggetto giuridico"
        );
      }
    );
  }

  soggGiuridiciForm: FormGroup;
  soggGiuridiciModalRef: BsModalRef;
  submittedSoggGiuridici: boolean = false;
  @ViewChild("templateModalSoggGiuridici")
  templateModalSoggGiuridici: TemplateRef<any>;

  initializeSoggGiuridiciForm() {
    let frm = this.formBuilder.group({
      id: [null],
      codiceIdentificativo: [
        "",
        [
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ],
      ],
      codiceIpa: ["", [Validators.required, Validators.maxLength(50)]],
      denominazione: ["", [Validators.required, Validators.maxLength(255)]],
      provincia: ["", [Validators.required]],
    });
    return frm;
  }

  onProvincesChange($event: Province) {
    this.provinceSelected = $event;
  }

  configModal = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-lg",
  };

  get sg() {
    return this.soggGiuridiciForm.controls;
  }

  openModalSoggGiuridici($event) {
    this.soggGiuridiciModalRef = this.modalService.show(
      this.templateModalSoggGiuridici,
      Object.assign({}, this.configModal)
    );
  }

  closeModalSoggettiGiuridici() {
    this.soggGiuridiciForm.reset();
    this.soggGiuridiciModalRef.hide();
  }

  soggettoRuoloForm: FormGroup;
  soggettoRuoloModalRef: BsModalRef;
  submittedSoggettoRuolo: boolean = false;
  @ViewChild("templateModalSoggettoRuolo")
  templateModalSoggettoRuolo: TemplateRef<any>;

  initializeSoggettoRuoloForm() {
    let frm = this.formBuilder.group({
      id: [null],
      ruolo: [null, [Validators.required, Validators.maxLength(255)]],
      codice: [null, [Validators.required, Validators.maxLength(255)]],
    });
    return frm;
  }

  get sr() {
    return this.soggettoRuoloForm.controls;
  }

  openModalSoggettoRuolo($event) {
    this.soggettoRuoloModalRef = this.modalService.show(
      this.templateModalSoggettoRuolo,
      Object.assign({}, this.configModal)
    );
  }

  closeModalSoggettoRuolo() {
    this.soggettoRuoloForm.reset();
    this.soggettoRuoloModalRef.hide();
  }

  onSubmitSoggettoRuolo() {
    this.submittedSoggettoRuolo = true;

    if (!this.validateForm(this.soggettoRuoloForm)) {
      this.soggettoRuoloForm.updateValueAndValidity();
      this.setIsDirty(true);
      return;
    }

    this.setIsDirty(true);

    const newSoggettoRuolo = {
      id: this.soggettoRuoloForm.get("id").value,
      ruolo: this.soggettoRuoloForm.get("ruolo").value,
      codice: this.soggettoRuoloForm.get("codice").value,
    };

    this.soggettoRuoloService.insert(newSoggettoRuolo).subscribe(
      (response) => {
        const newId = response.id;
        newSoggettoRuolo.id = newId;
        this.roles.push(newSoggettoRuolo);
        this.closeModalSoggettoRuolo();
        this.submittedSoggettoRuolo = false;

        this.toastr.success("Nuovo soggetto ruolo aggiunto con successo!");
        this.myGroup.get("role").setValue(newId);
        this.getRoles();
      },
      () => {
        this.toastr.error(
          "Si è verificato un' errore aggiungendo nuovo soggetto ruolo"
        );
      }
    );
  }

  initializeFormGroup() {
    return this.formBuilder.group({
      role: [null, [Validators.required]],
      soggettoGiuridico: [null, [Validators.required]],
    });
  }

  getSoggettiGiuridici() {
    this.soggettoGiuridicoService.getAll().subscribe((res) => {
      let ress: any[];
      ress = res;
      ress.map((i) => {
        i.descrizione = `${i.siglaProvincia} - ${i.codiceIdentificativo} - ${i.denominazione}`;
        return i;
      });
      this.soggettiGiuridici = ress;
    });
  }

  getControls(form) {
    return form.controls;
  }

  validateForm(form) {
    form.updateValueAndValidity();
    let errors = false;
    for (let c in this.getControls(form)) {
      errors = errors || this.getControls(form)[c].errors !== null;
    }
    if (form.invalid || errors) {
      let invalidElements = [];
      for (let currElem in form.controls) {
        if (form.controls[currElem].invalid) {
          if (form.controls[currElem] instanceof FormGroup) {
            for (let control in form.get(currElem)["controls"]) {
              if (form.get(currElem)["controls"][control].invalid) {
                invalidElements = this.el.nativeElement.querySelectorAll(
                  `[formcontrolname="${control}"]`
                );
                break;
              }
            }
          } else {
            invalidElements = this.el.nativeElement.querySelectorAll(
              `[formcontrolname="${currElem}"]`
            );
          }
          break;
        }
      }
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }
      return false;
    }
    return true;
  }

  getRoles() {
    this.soggettoRuoloService.getAll().subscribe((res) => {
      this.roles = res;
    });
  }

  get f() {
    return this.myGroup.controls;
  }

  add() {
    console.log("onSubmit");
    this.submitted = true;

    var errors = false;
    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }

    console.log("errors:" + errors);
    if (this.myGroup.invalid || errors) {
      console.log("invalid");
      return false;
    }

    let idSoggetto = this.f.soggettoGiuridico.value;
    let idRuolo = this.f.role.value;
    let data = Object.assign(
      {},
      this.soggettiGiuridici.find((x) => x.id == idSoggetto)
    );
    data.ruoloId = idRuolo;
    data.ruolo = this.roles.find((x) => x.id == idRuolo).ruolo;
    this.onSubmitOk.emit(data);
  }
}
