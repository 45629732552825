import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { InterventoService, ProgettoService } from '../../services/index';
import * as moment from 'moment';
import { AuthService } from 'src/app/services';

@Component(
    {
        selector: 'riepilogo-progetto-component',
        templateUrl: 'riepilogo-progetto.component.html',
        styleUrls: ['riepilogo-progetto.component.css']
    }
)
export class RiepilogoProgettoComponent implements OnInit {

    private _interventoId: number

    intervento: any;
    listaInterventi=[];
    loadDataCounter = 0;

    @Input()
    set interventoId(value) {
       // console.log(`inside set interventoId= ${value}`);
        this._interventoId = value;

        if (!value) return;
    }
    get interventoId() {
       // console.log(`inside get interventoId= ${this._interventoId}`);
        return this._interventoId;
    }


    constructor(
        private el: ElementRef,
        private toastr: ToastService,
        private interventoService: InterventoService,
        private progettoService: ProgettoService,
        private authService:AuthService
    ) {
        //console.log('into RiepilogoProgettoComponent.constructor');
    }


esistonoInterventi = false;
    ngOnInit() {
       // console.log('into RiepilogoProgettoComponent.ngoninit');
    }

    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
    gotoBackBtnClick() {
        this.gotoBtnFunction.emit(true);
    }

    reloadData() {
        //dati generali
        let that = this;
        this.loadDataCounter++;
        this.interventoService.get(this._interventoId).subscribe(
            x => {
                that.intervento = x;
                that.intervento['importi'].sort((a, b) => a.anno - b.anno);
                this.loadDataCounter--;

                //interventi
                that.loadDataCounter++;
                that.progettoService.getInterventi(this.intervento.progettoId).subscribe(
                    result => {
                        that.loadDataCounter--;
                        
                        result = result.filter(x => !x.isMain);//remove the main intervento                
                        that.listaInterventi = result;
                        that.esistonoInterventi = that.listaInterventi.length>0;
                    }

                );

            }
        );

    }

    getFaseCorrente(subIntervento:any){
        if (subIntervento.fasi && subIntervento.fasi.length>0){
            for(let i = 0;i<subIntervento.fasi.length;i++)
            {
                let fase = subIntervento.fasi[i];
                let startdate=moment( fase.dataInizio?fase.dataInizio:fase.dataPrevistaInizio);
                let enddate= moment(fase.dataFine?fase.dataFine:fase.dataPrevistaFine);
                let currentDate =moment( new Date());
                if (startdate<=currentDate && enddate>=currentDate)return `${fase.tipologiaFase.fase} - ${fase.tipologiaFase.titolo} - ${fase.tipologiaFase.descrizione}`;

            }
        }
        return "";
    }

    getImportoPerAnno(subIntervento:any,anno:number){
        if(!subIntervento) return 0;
        if( (!subIntervento.annualita || subIntervento.annualita.length<=0)&&
         (!subIntervento.importi || subIntervento.importi.length<=0))return (0);
        let importoAnno = 0;
        if (subIntervento.importi)
            importoAnno = subIntervento.importi.find(x=>x.anno == anno);
        else
            importoAnno = subIntervento.annualita.find(x=>x.anno == anno);
        if (!importoAnno)return (0);
        return importoAnno['importo'];
    }


    getTotale(subIntervento:any){
        if (!subIntervento)return 0;
        if( (!subIntervento.annualita || subIntervento.annualita.length<=0)&&
         (!subIntervento.importi || subIntervento.importi.length<=0))return (0);
        let somma = 0;
        if (subIntervento.importi)
            subIntervento.importi.forEach(element => {
                somma += element.importo;
            });
        else
        subIntervento.annualita.forEach(element => {
            somma += element.importo;
        });
        return somma;
    }
    getDaCoprire(subIntervento:any){
        if(!subIntervento) return 0;
        let finanziato = 0;
        let somma = 0;
        subIntervento.importi.forEach(element => {
            somma += element.importo;
        });
        subIntervento.finanziamenti.forEach(element => {
            element.annualita.forEach(annualita => {
                finanziato +=annualita.importo;
            });
        });
        let coperto = somma -finanziato;
        return coperto;
        
    }

    getCoperto(subIntervento:any){
        if(!subIntervento) return 0;
        let finanziato = 0;
        subIntervento.finanziamenti.forEach(element => {
            element.annualita.forEach(annualita => {
                finanziato +=annualita.importo;
            });
        });
        return finanziato;
    }

    totalePerAnnoSommaInterventiList = [];
    

    getTotaleSommaAnno(anno:number){
        let valore = 0;
        if (this.listaInterventi && this.listaInterventi.length>0){
        this.listaInterventi.forEach(subIntervento=>
            {
                let importoAnno = subIntervento.importi.find(x=>x.anno == anno);
                valore += (importoAnno?importoAnno.importo:0);
            });
        }
        this.totalePerAnnoSommaInterventiList['a' + anno] = valore;
        return valore;
    }

    getTotaleSommaInterventi(){
        let valore = 0;
        if (this.listaInterventi && this.listaInterventi.length>0){
            this.listaInterventi.forEach(
                subIntervento=>{
                    valore += this.getTotale(subIntervento);
                }
            );
        } 
        return valore;
    }

    getTotaleCapacitaResiduaProgetto(){
        let totaleInterventi = this.getTotaleSommaInterventi();
        let totaleProgetto = this.getTotale(this.intervento);
        return totaleProgetto - totaleInterventi;
    }

    getSommaCopertoTotale(){
        let valore = 0;
        if (this.listaInterventi && this.listaInterventi.length>0){
            this.listaInterventi.forEach(
                subIntervento=>{
                    valore += this.getCoperto(subIntervento);
                }
            );
        } 
        return valore;
    }

    getDisponibilitaCoperto(){
        let copertoProgetto = this.getCoperto(this.intervento);
        let copertoSommaInterventi = this.getSommaCopertoTotale();
        return copertoProgetto - copertoSommaInterventi;
    }
    getDisponibilitaDaCoprire(){
        let copertoProgetto = this.getDaCoprire(this.intervento);
        let copertoSommaInterventi = this.getSommaDaCoprireTotale();
        return copertoProgetto - copertoSommaInterventi;
    }

    getSommaDaCoprireTotale(){
        let valore = 0;
        if (this.listaInterventi && this.listaInterventi.length>0){
            this.listaInterventi.forEach(
                subIntervento=>{
                    valore += this.getDaCoprire(subIntervento);
                }
            );
        } 
        return valore;
    }


/**********    INIZIO FUNZIONI TABELLA FINANZIAMENTI           ******************** */

    getAnniFinanziamento(){
        if (!this.intervento || !this.intervento.finanziamenti || this.intervento.finanziamenti.length<=0) return [];
        let result = [];
        this.intervento.finanziamenti.forEach(element => {
            element.annualita.forEach(annualita=>{
                if (!result.includes(annualita.anno))result.push(annualita.anno);
            });
        });
        result.sort((a, b) => a - b);
        return result;
    }


    getTotaleSommaAnnoFinanziamenti(anno:number){
        let valore = 0;
        if (!this.intervento)return 0;
        if (this.intervento.finanziamenti && this.intervento.finanziamenti.length>0){
            this.intervento.finanziamenti.forEach(finanziamento=>
            {
                let importoAnno = this.getImportoPerAnno(finanziamento.finanziamento,anno);
                valore +=importoAnno;
            });
        }
        //this.totalePerAnnoSommaInterventiList['a' + anno] = valore;
        return valore;
    }
    getTotaleSommaAnnoFinanziamentiAssegnato(anno:number){
        let valore = 0;
        if (!this.intervento)return 0;
        if (this.intervento.finanziamenti && this.intervento.finanziamenti.length>0){
            this.intervento.finanziamenti.forEach(finanziamento=>
            {
                let importoAnno = this.getImportoPerAnno(finanziamento,anno);
                valore +=importoAnno;
            });
        }
        //this.totalePerAnnoSommaInterventiList['a' + anno] = valore;
        return valore;
    }

    getTotaleFinanziamentiAssegnato(){
        let valore = 0;
        this.getAnniFinanziamento().forEach(
            anno=>valore += this.getTotaleSommaAnnoFinanziamentiAssegnato(anno)
        );
        return valore;
    }
    getTotaleFinanziamenti(){
        let valore = 0;
        this.getAnniFinanziamento().forEach(
            anno=>valore += this.getTotaleSommaAnnoFinanziamenti(anno)
        );
        return valore;
    }


    getInterventiByFinanziamento(finId){
        let result = [];
        if (!this.intervento)    return result;
        this.listaInterventi.forEach(
            intervento =>{
                let finExists = intervento.finanziamenti.find(finanziamento=>finanziamento.finanziamento.id == finId);
                if (finExists)result.push(intervento);
            }
        );
        return result;
    }
    getFinanziamentoIntervento(finId,intervento){
        if (!this.intervento) return null;
        return intervento.finanziamenti.find(finanziamento=>finanziamento.finanziamento.id == finId);
    }

    getUtilizzatoPerAnno(finId,anno){
        let interventi = this.getInterventiByFinanziamento(finId);
        let valore = 0;
        interventi.forEach(
            intervento=>{
                let fin = intervento.finanziamenti.find(finanziamento=>finanziamento.finanziamento.id == finId);
                valore += this.getImportoPerAnno(fin,anno);
            }
        );
        return valore;
    }
    getTotaleUtilizzato(finId){
        let interventi = this.getInterventiByFinanziamento(finId);
        let valore = 0;
        interventi.forEach(
            intervento=>{
                let fin = intervento.finanziamenti.find(finanziamento=>finanziamento.finanziamento.id == finId);
                valore += this.getTotale(fin);
            }
        );
        return valore;
    }


    //getUtilizzatoPerAnno(finanziamento.finanziamento.id,anno)
    getTotaleUtilizzatoSommaPerAnno(anno){
        if (!this.intervento)return 0;
      let valore = 0;
      this.intervento.finanziamenti.forEach(finanziamento => {
          valore += this.getUtilizzatoPerAnno(finanziamento.finanziamento.id,anno);
      });
      return valore;  
    }

    getTotaleUtilizzatoSomma(){
        if (!this.intervento)return 0;
        let valore = 0;
        this.intervento.finanziamenti.forEach(finanziamento => {
            valore += this.getTotaleUtilizzato(finanziamento.finanziamento.id);
        });
        return valore;  
      }

/**********    FINE FUNZIONI TABELLA FINANZIAMENTI           ******************** */
    

    

    printValueAsCurrency(value,withCurrency:boolean = true) {
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        let currency = '€';
        if (!withCurrency) currency = "";
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }
    arrowUp = true;

    isAdmin() {
        return this.authService.isAdmin();
    }
    isGr34() {
        return this.authService.isGr34();
    }
    isGr34OrAdmin(){
        return this.isAdmin() || this.isGr34();
    }
}
