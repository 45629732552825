import { Component, OnInit } from "@angular/core";
import { News } from "src/app/models/news";
import { NewsService } from "src/app/services/news.service";
import { PageChangedEvent } from "ngx-bootstrap/pagination";

@Component({
  selector: "app-comunications",
  templateUrl: "./comunications.component.html",
  styleUrls: ["./comunications.component.css"]
})
export class ComunicationsComponent implements OnInit {
  news: News[];
  returnedArray: News[];

  constructor(private newsService: NewsService) {
    this.news = [];
  }

  ngOnInit() {
    this.newsService.getAllNewsEnabled().subscribe(res => {
      this.news = res;
      this.returnedArray = res.slice(0, 3);
      //const n = new News();
      // n.title = "asd";
      // n.message = "asdasdasdasdasdasdasdas";
      // n.enabled = true;
      // n.datePub = new Date();
      // this.news.push(n);
      // this.news.push(n);
      // this.news.push(n);
      // this.news.push(n);
    });
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnedArray = this.news.slice(startItem, endItem);
  }
}
