import { CurrencyPipe } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as turf from "@turf/turf";
import * as moment from "moment";
import { BsModalRef } from "ngx-bootstrap/modal";
import LayerSwitcher from "ol-layerswitcher";
import * as olControls from "ol/control";
import * as olConditions from "ol/events/condition";
import * as olFormat from "ol/format";
import * as olGeom from "ol/geom";
import * as olInteractions from "ol/interaction";
import { Vector as VectorLayer } from "ol/layer";
import Map from "ol/Map";
import Overlay from "ol/Overlay";
import * as olProj from "ol/proj";
import * as olSource from "ol/source";
import * as olStyle from "ol/style";
import Style from "ol/style/Style";
import View from "ol/View";
import { KML } from "ol/format";
import { Feature } from "ol/index";
import { Subscription } from "rxjs";
import {
  InterventoService,
  ProgettoService,
} from "src/app/modules/inframob/services/index.js";
import { ToastService } from "src/app/services/index.js";
import Sidebar from "src/assets/js/sidebar.js";
import { AppConfigService } from "src/app/services/app-config.service";
import { MapConf } from "../../models/map-conf";
import { MyStreetFeatures } from "../../models/my-street-feature";
import { GeoService } from "../../services/geo-service.service";
import * as JSZip from "jszip";
declare var $: any;

interface KeyObject {
  key: string;
}

class GeocodedPlace {
  name: string;
  coordinates: number[];
  confidence: string;
  region: string;
}

interface LayerAttribution {
  url: string;
  text1: string;
  text2: string;
}

class InterventionProperty {
  id: number;
  validated: any;
  isMain: boolean;
  year: number;
  cup: string;
  descrizione: string;
  direzioni: any;
  importi: any;
  codice: any;
  type: string;
  drawed: boolean;
  visible: true;
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  divMsgOpen: boolean = false;
  @Input() isProjectMode: boolean;
  @ViewChild("popup") popup: ElementRef;
  @Input() interventoId: any;
  @Input() descrizione: string;
  @Input() campoCerca: string;
  @Input() passedConf: MapConf;
  conf: MapConf;

  @Output() closeModalEmitter = new EventEmitter();

  defaultConf: MapConf = {
    geo_server_url: "http://geoservertest.soluxioni.it:8080/geoserver/",
    geo_store: "inframob",
    store_local: "inframoblocale",
    backend_url: "http://localhost:8080/",
    //layers
    wmsLayerNames: ["province_lazio_2020"],
    wmsLayerTitles: ["Province"],

    railStyle: new Style({
      stroke: new olStyle.Stroke({
        color: "#4d4d4d", //ferrovie presistenti
        width: 2,
      }),
      fill: new olStyle.Fill({
        color: "rgba(0, 0, 255, 1)",
      }),
    }),
    roadStyle: new Style({
      stroke: new olStyle.Stroke({
        color: "#808080", //strade presistenti
        width: 2,
      }),
      fill: new olStyle.Fill({
        color: "rgba(0, 0, 255, 1)",
      }),
    }),
    buildingStyle: new olStyle.Style({
      image: new olStyle.Circle({
        radius: 6,
        stroke: new olStyle.Stroke({
          color: "white",
          width: 2,
        }),
        fill: new olStyle.Fill({
          color: "grey", //edificio censito
        }),
      }),
    }),
    savedStyle: [
      new Style({
        stroke: new olStyle.Stroke({
          color: "#0083E0",
          width: 3, //spessore strade salvate
        }),
        fill: new olStyle.Fill({
          color: "rgba(0, 0, 255, 0.1)",
        }),
      }),
      new olStyle.Style({
        image: new olStyle.Circle({
          radius: 6,
          stroke: new olStyle.Stroke({
            color: "white", //edificio salvato
            width: 2,
          }),
          fill: new olStyle.Fill({
            color: "#0083E0",
          }),
        }),
      }),
    ],
    vectorLayers: [
      //rail
      {
        vectorLayerName: "rete_ferroviaria_complementare",
        vectorLayerTitle: "Rete ferroviaria",
        vectorLayerType: "rail",
        vectorStyle: 0,
      },
      {
        vectorLayerName: "rete_ferroviaria",
        vectorLayerTitle: "Rete ferroviaria complementare",
        vectorLayerType: "rail",
        vectorStyle: 0,
      },
      {
        vectorLayerName: "rete_ferroviaria_urbana",
        vectorLayerTitle: "Rete ferroviaria urbana",
        vectorLayerType: "rail",
        vectorStyle: 0,
      },
      {
        vectorLayerName: "rete_metro",
        vectorLayerTitle: "Rete metro",
        vectorLayerType: "metro",
        vectorStyle: 0,
      },
      //roads
      {
        vectorLayerName: "rete_viaria_principale_lazio_v2",
        vectorLayerTitle: "Rete viaria principale",
        vectorLayerType: "road",
        vectorStyle: 1,
      },
      {
        vectorLayerName: "strade_statali_e_regionali",
        vectorLayerTitle: "Rete viaria statale e regionale",
        vectorLayerType: "road",
        vectorStyle: 1,
      },
      {
        vectorLayerName: "strade_prov_frosinone",
        vectorLayerTitle: "Rete viaria locale prov. Frosinone",
        vectorLayerType: "road",
        vectorStyle: 1,
      },
      {
        vectorLayerName: "strade_prov_latina",
        vectorLayerTitle: "Rete viaria locale prov. Latina",
        vectorLayerType: "road",
        vectorStyle: 1,
      },
      {
        vectorLayerName: "strade_prov_rieti",
        vectorLayerTitle: "Rete viaria locale prov. Rieti",
        vectorLayerType: "road",
        vectorStyle: 1,
      },
      {
        vectorLayerName: "strade_prov_roma",
        vectorLayerTitle: "Rete viaria locale C.M. Roma",
        vectorLayerType: "road",
        vectorStyle: 1,
      },
      {
        vectorLayerName: "strade_roma",
        vectorLayerTitle: "Rete viaria Comune di Roma",
        vectorLayerType: "road",
        vectorStyle: 1,
      },
      {
        vectorLayerName: "strade_prov_viterbo",
        vectorLayerTitle: "Rete viaria locale prov. Viterbo",
        vectorLayerType: "road",
        vectorStyle: 1,
      },
      //bike
      {
        vectorLayerName: "ciclabili",
        vectorLayerTitle: "Piste ciclabili",
        vectorLayerType: "bike",
        vectorStyle: 5,
      },
      //risalita
      {
        vectorLayerName: "risalita",
        vectorLayerTitle: "Impianti di risalita",
        vectorLayerType: "skilift",
        vectorStyle: 5,
      },
      {
        vectorLayerName: "bonifiche_lines",
        vectorLayerTitle: "Bonifiche territorio (linee)",
        vectorLayerType: "bonifiche_lines",
        vectorStyle: 5,
      },
      {
        vectorLayerName: "linee_generiche",
        vectorLayerTitle: "Altre vie di comunicazione (linee)",
        vectorLayerType: "other_lines",
        vectorStyle: 1,
      },
      //points buildings
      {
        vectorLayerName: "stazioni_ferroviarie",
        vectorLayerTitle: "Stazioni ferroviarie",
        vectorLayerType: "station",
        vectorStyle: 3,
      },
      {
        vectorLayerName: "stazioni_ferroviarie_urbane",
        vectorLayerTitle: "Stazioni ferroviarie urbane",
        vectorLayerType: "station",
        vectorStyle: 3,
      },
      {
        vectorLayerName: "stazioni_metro",
        vectorLayerTitle: "Stazioni metro",
        vectorLayerType: "metrostop",
        vectorStyle: 3,
      },
      {
        vectorLayerName: "coordinate_edifici_ares",
        vectorLayerTitle: "Edifici scolastici ARES",
        vectorLayerType: "points",
        vectorStyle: 3,
      },
      {
        vectorLayerName: "porti",
        vectorLayerTitle: "Porti",
        vectorLayerType: "port",
        vectorStyle: 3,
      },
      {
        vectorLayerName: "punti_generici",
        vectorLayerTitle: "Edifici generici (punti)",
        vectorLayerType: "other_points",
        vectorStyle: 3,
      },
      {
        vectorLayerName: "nodes",
        vectorLayerTitle: "Nodi di scambio",
        vectorLayerType: "nodes",
        vectorStyle: 3,
      },
      {
        vectorLayerName: "parcheggi",
        vectorLayerTitle: "Parcheggi",
        vectorLayerType: "park",
        vectorStyle: 3,
      },

      {
        vectorLayerName: "parchi",
        vectorLayerTitle: "Parchi e aree verdi",
        vectorLayerType: "parkland", //11112021
        vectorStyle: 3,
      },
      {
        vectorLayerName: "emobility",
        vectorLayerTitle: "E-Mobility",
        vectorLayerType: "emobility", //02122021
        vectorStyle: 3,
      },
      {
        vectorLayerName: "asl",
        vectorLayerTitle: "ASL",
        vectorLayerType: "asl", //02122021
        vectorStyle: 3,
      },
      {
        vectorLayerName: "plants",
        vectorLayerTitle: "Impianti Acuqa-Luce-Gas",
        vectorLayerType: "plants", //02122021
        vectorStyle: 3,
      },
      {
        vectorLayerName: "networks_satellites",
        vectorLayerTitle: "Rete satellitare",
        vectorLayerType: "networks_satellites", //02122021
        vectorStyle: 3,
      },
      {
        vectorLayerName: "bonifiche_points",
        vectorLayerTitle: "Bonifiche territorio (punti)",
        vectorLayerType: "bonifiche_points", //02122021
        vectorStyle: 3,
      },
      {
        vectorLayerName: "cimiteri",
        vectorLayerTitle: "Edilizia Cimiteriale",
        vectorLayerType: "cimiteri", //02122021
        vectorStyle: 3,
      },
      {
        vectorLayerName: "aeroporti",
        vectorLayerTitle: "Aeroporti",
        vectorLayerType: "aeroporti", //02122021
        vectorStyle: 3,
      },
      {
        vectorLayerName: "luoghi_di_culto",
        vectorLayerTitle: "Luoghi di Culto",
        vectorLayerType: "luoghi_di_culto", //02122021
        vectorStyle: 3,
      },
      {
        vectorLayerName: "hospitals",
        vectorLayerTitle: "Ospedali - Presidi",
        vectorLayerType: "hospitals", //02122021
        vectorStyle: 3,
      },
      {
        vectorLayerName: "musei_cultura",
        vectorLayerTitle: "Musei Biblioteche Cultura",
        vectorLayerType: "musei_cultura", //02122021
        vectorStyle: 3,
      },

      {
        vectorLayerName: "impianti_sportivi",
        vectorLayerTitle: "Impianti sportivi",
        vectorLayerType: "impianti_sportivi", //02122021
        vectorStyle: 3,
      },
      {
        vectorLayerName: "interventi_sul_sociale",
        vectorLayerTitle: "Interventi sul sociale",
        vectorLayerType: "interventi_sul_sociale", //02122021
        vectorStyle: 3,
      },
      {
        vectorLayerName: "stazioni_autolinee",
        vectorLayerTitle: "Stazioni - Autolinee",
        vectorLayerType: "stazioni_autolinee", //02122021
        vectorStyle: 3,
      },
      {
        vectorLayerName: "impianti_energie_rinnovabili",
        vectorLayerTitle: "Impianti (Energie rinnovabili)",
        vectorLayerType: "impianti_energie_rinnovabili", //02122021
        vectorStyle: 3,
      },
      {
        vectorLayerName: "opere_urbanizzazione",
        vectorLayerTitle: "Opere di urbanizzazione",
        vectorLayerType: "opere_urbanizzazione", //02122021
        vectorStyle: 3,
      }
      ,

      //specials
      {
        vectorLayerName: "coordinate_intervento",
        vectorLayerTitle: "Interventi",
        vectorLayerType: "saved",
        vectorStyle: 4,
      },
      {
        vectorLayerName: "view",
        vectorLayerTitle: "Visualizzazione",
        vectorLayerType: null,
        vectorStyle: null,
      },
      {
        vectorLayerName: "project",
        vectorLayerTitle: "Progetto/Fabbisogno",
        vectorLayerType: "proj",
        vectorStyle: null,
      },
    ],

    vectorLayerNames: [
      //linee
      //ferrovie
      "rete_ferroviaria_complementare", //0
      "rete_ferroviaria", //1
      "rete_ferroviaria_urbana", //2
      "rete_metro", //3
      //strade
      "rete_viaria_principale_lazio_v2", //4
      "strade_statali_e_regionali", //5
      "strade_prov_frosinone", //6
      "strade_prov_latina", //7
      "strade_prov_rieti", //8
      "strade_prov_roma", //9
      "strade_roma", //10
      "strade_prov_viterbo", //11
      "ciclabili", //12
      "risalita", //13
      "bonifiche_lines", //14
      "linee_generiche", //15
      //"rete_viaria_locale_semplificata",
      //punti
      "stazioni_ferroviarie", //16
      "stazioni_ferroviarie_urbane", //17
      "stazioni_metro", //18
      "coordinate_edifici_ares", //19
      "porti", //20
      "punti_generici", //21
      "parcheggi", //22
      "nodi", //23
      "parchi", //24
      "emobility", //25
      "asl", //26
      "plants", //27
      "bonifiche_points", //28
      "networks_satellites", //29
      "cimiteri", //30
      "aeroporti", //31
      "luoghi_di_culto", //32
      "musei_cultura", //33
      "hospitals", //34
        "impianti_sportivi", //35
        "interventi_sul_sociale", //36
        "stazioni_autolinee", //37
        "impianti_energie_rinnovabili", //38
        "opere_urbanizzazione",//39


      //geometrie
      "coordinate_intervento", //40,
    ],
    vectorLayerTitles: [
      //linee
      //ferrovie
      "Rete ferroviaria", //0
      "Rete ferroviaria complementare", //1
      "Rete ferroviaria urbana", //2
      "Rete metro", //3
      //strade
      "Rete viaria principale", //4
      "Rete viaria statale e regionale", //5
      "Rete viaria locale prov. Frosinone", //6
      "Rete viaria locale prov. Latina", //7
      "Rete viaria locale prov. Rieti", //8
      "Rete viaria locale C.M. Roma", //9
      "Rete viaria Comune di Roma", //10
      "Rete viaria locale prov. Viterbo", //11
      "Piste ciclabili", //12
      "Impianti di risalita", //13
      "Bonifiche territorio (linee)", //14
      "Altre vie di comunicazione (linee)", //15
      //"Strade locali",
      //punti
      "Stazioni ferroviarie", //16
      "Stazioni ferroviarie urbane", //17
      "Stazioni metro", //18
      "Edifici scolastici ARES", //19
      "Porti", //20
      "Edifici generici (punti)", //21
      "Parcheggi", //22
      "Nodi di scambio", //23
      "Parchi e aree verdi", //24
      "E-Mobility", //25
      "ASL", //26
      "Impianti Acqua-Luce-Gas", //27
      "Bonifiche territorio (punti)", //28
      "Rete satellitare", //29
      "Edilizia Cimiteriale", //30
      "Aeroporti", //31
      "Luoghi di Culto", //32
      "Musei Biblioteche Cultura", //33
      "Ospedali - Presidi", //34
      "Impianti sportivi", //35
        "Interventi sul sociale", //36
        "Stazioni - Autolinee", //37
        "Impianti (Energie rinnovabili)", //38
        "Opere di urbanizzazione", //39
      //
      "Interventi",
      //
      "Visualizzazione",
      "Progetto/Fabbisogno",
    ],
    vectorLayerSRS: "EPSG:4326",

    //styles
    vectorStyles: [
      new Style({
        stroke: new olStyle.Stroke({
          color: "#4d4d4d", //ferrovie presistenti
          width: 2,
        }),
        fill: new olStyle.Fill({
          color: "rgba(0, 0, 255, 1)",
        }),
      }),
      new Style({
        stroke: new olStyle.Stroke({
          color: "#808080", //strade presistenti
          width: 2,
        }),
        fill: new olStyle.Fill({
          color: "rgba(0, 0, 255, 1)",
        }),
      }),
      //strade ed edifici salvati
      new olStyle.Style({
        image: new olStyle.Circle({
          radius: 6,
          stroke: new olStyle.Stroke({
            color: "white",
            width: 2,
          }),
          fill: new olStyle.Fill({
            color: "grey", //edificio censito
          }),
        }),
      }),
      [
        new Style({
          stroke: new olStyle.Stroke({
            color: "#0083E0",
            width: 3, //spessore strade salvate
          }),
          fill: new olStyle.Fill({
            color: "rgba(0, 0, 255, 0.1)",
          }),
        }),
        new olStyle.Style({
          image: new olStyle.Circle({
            radius: 6,
            stroke: new olStyle.Stroke({
              color: "white", //edificio salvato
              width: 2,
            }),
            fill: new olStyle.Fill({
              color: "#0083E0",
            }),
          }),
        }),
      ],
      new Style({
        stroke: new olStyle.Stroke({
          color: "#708090", //piste ciclabili
          width: 2,
        }),
        fill: new olStyle.Fill({
          color: "rgba(0, 0, 255, 1)",
        }),
      }),
    ],

    lineFeaturesLayer: null,
    pointFeaturesLayer: null,
    layerToSnapLines: null,
    layerToSnapPoints: null,
    snapTollerance: 200,

    baseMapsLayers: [
      { title: "CartoDb", code: "carto" },
      { title: "Google Strade", code: "gstreet" },
      { title: "Google Satellite", code: "gsat" },
      { title: "Google Terreno", code: "gterrain" },
      { title: "Google Ibrido", code: "ghybrid" },
      { title: "Stamen Toner", code: "toner" },
      { title: "Stamen Watercolor", code: "watercolor" },
    ],
  };

  geometryTypes = [
    { title: "Punto", code: "points" },
    { title: "Linea", code: "line" },
    //{ title: "Poligono", code: "polygon" },
  ];

  layersForDrawing: any[] = [];
  allDrawingLayers: any[] = [];

  private map: Map;
  private snapInteraction: olInteractions.Snap = new olInteractions.Snap();
  private drawStreetInteraction;
  private drawBuildingInteraction;
  private selectInteraction;
  isDrawing: boolean = false;
  private vectorLayers;
  selectedFilters: string[] = [];
  myForm: FormGroup;
  selectedLayer: any;
  selectedProperty: any;
  currentFilter: string;
  allFilterLayers: any[] = [];
  layerProperties: KeyObject[] = [];
  propertiesValues = [];
  //popup
  popupOverlay: Overlay;
  popupHidden = true;
  popupSelectedLayer: string;
  popUpInfo = {};
  popUpKeys = [];
  popUpValues = [];
  cloning = false;
  selectedFeature;

  sourceSRS = "EPSG:3857";
  destSRS = "EPSG:4326";

  //drawingLayer: olLayers.Layer;

  canSave: boolean = false;

  interventionId: number;

  disableDraw = false;
  projectStyle = [
    new Style({
      stroke: new olStyle.Stroke({
        color: "#aa66cc",
        width: 3, //spessore strade salvate
      }),
      fill: new olStyle.Fill({
        color: "rgba(0, 0, 255, 0.1)",
      }),
    }),
    new olStyle.Style({
      image: new olStyle.Circle({
        radius: 6,
        stroke: new olStyle.Stroke({
          color: "white", //edificio salvato
          width: 2,
        }),
        fill: new olStyle.Fill({
          color: "#aa66cc",
        }),
      }),
    }),
  ];

  /* colore hover strade */
  highlightStyle: olStyle.Style = [
    new olStyle.Style({
      zIndex: 300,
      image: new olStyle.Circle({
        radius: 7,
        stroke: new olStyle.Stroke({
          color: "white",
          width: 3,
        }),
        fill: new olStyle.Fill({
          color: "#33cccc", //verde hover edificio
        }),
      }),
    }),
    new olStyle.Style({
      zIndex: 300,
      fill: new olStyle.Fill({
        color: "rgba(255,255,255,0.7)",
      }),
      stroke: new olStyle.Stroke({
        color: "#33cccc", //verde hover strada
        width: 3,
      }),
    }),
  ];

  filterStyle = [
    new olStyle.Style({
      image: new olStyle.Circle({
        radius: 10,
        stroke: new olStyle.Stroke({
          color: "white",
          width: 2,
        }),
        fill: new olStyle.Fill({
          color: "red",
        }),
      }),
    }),
    new olStyle.Style({
      fill: new olStyle.Fill({
        color: "rgba(255,255,255,0.7)",
      }),
      stroke: new olStyle.Stroke({
        color: "blue",
        width: 7,
      }),
    }),
  ];

  isPopupActive = true;

  allLayersName: Array<{ k: string; v: string }> = [];

  streetFeatures: MyStreetFeatures[] = [];

  deletedFeatureIds: number[] = [];

  ICON_LAYER_TITLE = "Icone";
  ICON_TEMP_LAYER_TITLE = "IconeTemp";
  ICON_VIEW_LAYER_TITLE = "Icone View";
  PROJECT_LAYER_TITLE = "Progetto/Fabbisogno";
  VIEWER_LAYER_TITLE = "Visualizzazione";
  INTERVENTIONS_LAYER_TITLE = "Interventi";
  DRAW_LAYER_TITLE = "Disegni";

  editor = "Editor";

  allInterventionsWithStatus: any[] = [];

  currentIntervention;
  isOnlyProject: boolean;
  readOnly: boolean;

  mainInterventionId: number;

  loadedLayers: any[] = [];
  interventionIds = [];
  layerReadySubscription: Subscription;
  listElementSubscription: Subscription;
  layerLoadedSubscription: Subscription;

  freehand: boolean = true;
  drawingType: string = "";
  showButton = false;
  showPage = false;
  highlightedFeatures = [];
  highlightedIcons = [];

  highlitedFeaturesDraws = [];
  highlightedDraws = [];
  highlightedIconsDraws = [];

  featuresToMerge: MyStreetFeatures[] = [];
  minDistanceToMerge = 28;
  isInit: boolean = true;
  showFreehandChooser: boolean = false;
  showMergeButton: boolean = false;

  oldInterventions = [];

  merged;
  newFeat: MyStreetFeatures;
  arrowHidden = false;

  list1Height = true;
  currentLayerTipeSelected = "";
  currentLayerTitle = "";
  layersForDrawInitialized: boolean = false;
  eyeStatus = "opened";
  waiting = false;
  legendIcon = "close";

  isAllHidden = false;

  attribution: LayerAttribution;
  //attribution: LayerAttribution;
  highLightedIntSource: olSource.Source;

  currentInterventionType = "";
  currentKMZInterventionType = "";

  isComboLayerFiltered = false;

  geocodedPlaces: GeocodedPlace[] = [];
  isGeocodeHidden = true;

  cloneTextButton = "";

  kmzFile = null;
  kmzType = null;
  layersForKmz: any[] = [];

  lastGeomteryType;

  constructor(
    private geoService: GeoService,
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private toastr: ToastService,
    private projectService: ProgettoService,
    private interventionService: InterventoService
  ) {
    //TODO sistemare gli ordini e la configurazione
    this.conf = { ...this.defaultConf, ...this.passedConf };
    //se non viene specificato il layer da snappare linee viene scelto il primo
    if (!this.conf.layerToSnapLines)
      this.conf.layerToSnapLines = this.conf.vectorLayerTitles[0];
    this.conf.layerToSnapLines = this.conf.vectorLayerTitles[0];
    //se non viene specificato il layer da snappare punti viene scelto il secondo
    if (!this.conf.layerToSnapPoints)
      this.conf.layerToSnapPoints = this.conf.vectorLayerTitles[1];
    //se non viene specificato il layer su cui salvare linee disegnate viene scelto l'ultimo
    if (!this.conf.lineFeaturesLayer)
      this.conf.lineFeaturesLayer =
        this.conf.vectorLayerTitles[this.conf.vectorLayerTitles.length - 1];
    //se non viene specificato il layer su cui salvare i punti disegnati viene scelto il penultimo
    if (!this.conf.pointFeaturesLayer)
      this.conf.pointFeaturesLayer =
        this.conf.vectorLayerTitles[this.conf.vectorLayerTitles.length - 2];
  }

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.searchPlaces();
    }
  }

  ngOnInit() {
    console.log(
      `activeMode: ${this.isProjectMode ? "progetto" : "intervento"}`
    );

    //config service
    this.geoService.setVectorStyles(this.conf.vectorStyles);
    this.geoService.setProjectStyle(this.projectStyle);
    this.geoService.setvectorLayerNames(this.conf.vectorLayerNames);
    this.geoService.setVectorLayerSRS(this.conf.vectorLayerSRS);

    this.myForm = this.initializeFormGroup();
    this.map = this.getMap(null, null, null);
    if (typeof this.interventoId == "string")
      this.interventoId = parseInt(this.interventoId);
    console.log(`intervento: ${this.interventoId}`);
    this.setUpWmsLayers();
    /* show file value after file select */
    let that = this;
    document
      .querySelector(".custom-file-input")
      .addEventListener("change", function (e: any) {
        const uploadfile: any = document.getElementById("myInput");
        const fileName = uploadfile.files[0].name;
        const regex = /(?:\.([^.]+))?$/;

        const ext = regex.exec(fileName)[1];
        if (ext.localeCompare("KMZ")) {
          that.kmzFile = { file: uploadfile.files[0], fileName };
          const nextSibling = e.target.nextElementSibling;
          nextSibling.innerText = fileName;
        } else {
          this.toastr.error(`Estensione non KMZ.`);
        }
      });
  }

  ngAfterViewInit(): void {
    console.log("after init");

    this.getInterventionsById(this.interventoId);
    let that = this;
    this.layerReadySubscription = this.geoService
      .getReadyTracker()
      .subscribe((e) => {
        const layerTitle = e.get("title");
        console.log("pronto per disegno: " + layerTitle);
        this.waiting = false;
        if (layerTitle !== this.INTERVENTIONS_LAYER_TITLE) {
          const geometry =
            e.getSource().getFeatures().length > 0
              ? e.getSource().getFeatures()[0].getGeometry().getType()
              : null;
          if (layerTitle === this.PROJECT_LAYER_TITLE || geometry !== "Point") {
            //that.map.removeInteraction(this.snapInteraction);
            this.removeSnapInteractions();
            that.setSnapOnLayer(layerTitle, this.conf.snapTollerance);
            that.map.addInteraction(this.snapInteraction);
          } else {
            //snap per punti
            if (
              layerTitle !== this.ICON_LAYER_TITLE &&
              layerTitle !== this.ICON_TEMP_LAYER_TITLE
            ) {
              //that.map.removeInteraction(this.snapInteraction);
              that.removeSnapInteractions();
              that.setSnapOnLayer(layerTitle, 30);
              that.map.addInteraction(this.snapInteraction);
            }
          }
        }
      });

    //update of list into the sidebar
    this.listElementSubscription = this.geoService
      .getlistFeature()
      .subscribe((res) => {
        that.streetFeatures = res; //[...res];
        //that.highlightedIconsDraws = []
        that.isDrawing = false;
        this.geoService.deleteLayer(this.map, this.VIEWER_LAYER_TITLE);
        this.geoService.deleteLayer(this.map, this.ICON_VIEW_LAYER_TITLE);
        this.geoService.deleteLayer(this.map, this.PROJECT_LAYER_TITLE);
        this.geoService.deleteLayer(this.map, this.INTERVENTIONS_LAYER_TITLE);
        this.getInterventionsById(this.interventoId);
        console.log("rimosso visualizzazione");
      });

    this.layerLoadedSubscription = this.geoService
      .getInterventiLoaded()
      .subscribe((isLoaded) => {
        this.waiting = false;

        if (isLoaded === this.PROJECT_LAYER_TITLE) {
          this.geoService.setZindexToLayer(
            this.map,
            this.PROJECT_LAYER_TITLE,
            77
          );
          this.setGeometryComboValue();
        }
        if (!this.cloning) {
          if (isLoaded === this.INTERVENTIONS_LAYER_TITLE) {
            that.geoService.createIconLayerBylayer(
              this.map,
              this.INTERVENTIONS_LAYER_TITLE,
              that.ICON_LAYER_TITLE,
              that.iconSavedLayerStyleFunction,
              false
            );
            this.geoService.setZindexToLayer(
              that.map,
              that.ICON_LAYER_TITLE,
              101
            );
            const intLayer = this.geoService.getLayerByTitle(
              that.map,
              this.INTERVENTIONS_LAYER_TITLE
            );

            that.streetFeatures = [...[]];
            intLayer
              .getSource()
              .getFeatures()
              .forEach((f) => {
                const myFeature: MyStreetFeatures = {
                  feature: f,
                  saved: true,
                  date: f.values_.date,
                };
                if (!that.streetFeatures.includes(myFeature))
                  that.streetFeatures.push(myFeature);
              });
            this.streetFeatures.sort((a, b) => {
              return new Date(b.date).valueOf() - new Date(a.date).valueOf();
            });
            if (
              this.currentIntervention.isMain &&
              this.currentIntervention.numSubInterventi > 0
            ) {
              this.layersForDrawing = [...this.allDrawingLayers].filter(
                (l) => l.vectorLayerType !== "proj"
              );
              this.layersForKmz = [...this.layersForDrawing].filter(
                (l) => l.vectorLayerType !== "proj"
              );
            } else if (that.streetFeatures.length > 0) {
              //25/01/2022
              if (
                !this.currentIntervention.isMain &&
                this.currentInterventionType
              ) {
                if (!this.drawingType) {
                  this.drawingType = this.currentInterventionType;
                }
                if (!this.isComboLayerFiltered) {
                  this.f.geometryChooser.disable();
                  if (!this.isComboLayerFiltered) {
                    this.filterDrawingAndKmzLayers();
                    this.isComboLayerFiltered = true;
                  }
                }
              }
            }

            this.setPopup();
            if (this.isInit) {
              this.cloneTextButton = this.currentIntervention.isMain
                ? "Copia da Interventi "
                : "Copia da Progetto ";
            }

            if (this.currentIntervention.isMain && this.isInit) {
              //this.cloneTextButton = "Copia da Interventi ";
              $("#editor").trigger("click");

              this.setVisualMode();
            } else {
              // this.cloneTextButton = "Copia da Progetto ";
              this.removeDrawingInteractions();

              this.setEditorMode();
            }
            this.isInit = false;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.geoService.emptyLoaded();
    this.layerReadySubscription.unsubscribe();
    this.layerLoadedSubscription.unsubscribe();
    this.listElementSubscription.unsubscribe();
  }

  getMap(center: number[], zoom: number, controls: any) {
    return new Map({
      target: "map",
      controls: controls
        ? controls
        : olControls.defaults({
            attribution: false,
            zoom: true,
            rotate: false,
          }),
      view: new View({
        center: olProj.fromLonLat(center ? center : [12.5, 41.9]),
        zoom: zoom ? zoom : 8.7,
      }),
    });
  }

  initializeFormGroup() {
    return this.formBuilder.group({
      drawingTypeChooser: [null, null],
      layerChooser: [
        { value: null, disabled: !this.currentInterventionType },
        null,
      ],
      propertyChooser: [{ value: null, disabled: true }, null],
      valueChooser: [{ value: null, disabled: true }, null],
      basemapChooser: [{ value: "carto", disabled: false }, null],
      geometryChooser: [{ value: null, disabled: true }, null],
      geocodeSearchCity: [{ value: null, disabled: false }, null],
      geocodeSearchAddress: [{ value: this.campoCerca, disabled: false }, null],
      kmzTypeChooser: [null, null],
    });
  }

  getInterventionsById(interventionId) {
    let that = this;
    this.interventionService.get(interventionId).subscribe((i) => {
      this.currentIntervention = i;
      //this.layersForDrawing = [...[]];
      this.allInterventionsWithStatus = [...[]];
      if (!this.layersForDrawInitialized) {
        if (
          this.currentIntervention.isMain &&
          this.currentIntervention.numSubInterventi > 0
        ) {
          //DENTRO progetto
          this.currentInterventionType = "";
          this.conf.vectorLayers.forEach((l) => {
            if (
              l.vectorLayerTitle !== this.PROJECT_LAYER_TITLE &&
              l.vectorLayerTitle !== this.INTERVENTIONS_LAYER_TITLE &&
              l.vectorLayerTitle !== this.VIEWER_LAYER_TITLE
            )
              this.layersForDrawing.push(l);
          });
          this.allDrawingLayers = [...this.layersForDrawing].filter(
            (l) => l.vectorLayerType !== "proj"
          );
        } else {
          //dentro intervento
          // this.currentInterventionType = i.drawType
          //   ? i.drawType
          //   : i.tipo
          //   ? i.tipo
          //   : "";
          this.currentInterventionType = i.tipo ? i.tipo : "";
          console.log("TIPO INTERVENTO: " + this.currentInterventionType);
          //this.myForm.controls.drawingTypeChooser.setValue(this.currentInterventionType);
          //this.myForm.controls.drawingTypeChooser.disable();
        }
        this.fillLayerDrawCombo();
      }
      let status = (
        this.currentIntervention.validazione
          ? this.currentIntervention.validazione.validationStatus
            ? this.currentIntervention.validazione.validationStatus
            : 0
          : 0
      )
        .toString()
        .toUpperCase();
      this.readOnly =
        /*status == "1" ||
        status == "APPROVED" ||
        status == "WAITING_FOR_APPROVAL_FIRST_LEVEL" ||
        status == "4" ||
        status == "WAITING_FOR_APPROVAL_SECOND_LEVEL" ||
        status == "5" ||
        status == "TERMINATO" ||*/
        this.currentIntervention.isReadOnly;
      if (i && i.progettoId)
        this.projectService
          .getInterventiSimpleData({
              filterProjectId: i.progettoId,
              filterSimpleInterventionId: -1,
              filterStatus: -1,
              filterAreeGestionaliDirezioneEsterne: -1,
              filterTipoClassificazione: -1,
              filterSoggettoGiuridico: -1,
              filterAnno: "all",
              filterProvinciaSoggettoGiuridico: "all",
              filterTemaProprietario: -1,
              filterFrom: -1,
              filterTo: -1,
              filterTipologiaIntervento: -1
          })
          .subscribe((interventions) => {
            console.log(
              `lista tutti interventi del progetto = ${interventions.length}`
            );
            if (
              that.currentIntervention.isMain &&
              that.currentIntervention.numSubInterventi === 0
            ) {
              that.isOnlyProject = true;
            } else {
              that.isOnlyProject = false;
            }

            interventions.forEach((element) => {
              const simpleIntervention = new InterventionProperty();
              simpleIntervention.id = element.id;
              simpleIntervention.validated =
                element.validazione && element.validazione.validationStatus
                  ? element.validazione.validationStatus
                  : null;
              simpleIntervention.isMain = element.isMain;
              simpleIntervention.year = element.annoRiferimento;
              simpleIntervention.cup = element.cup;
              simpleIntervention.codice = element.codice;
              simpleIntervention.descrizione = element.descrizione;
              simpleIntervention.direzioni = [];
              simpleIntervention.importi = [];
              simpleIntervention.drawed = element.disegnato;
              simpleIntervention.visible = true;
              if (element.areeTematiche) {
                element.areeTematiche.forEach((at) => {
                  simpleIntervention.direzioni.push(at.descrizione);
                });
              }
              if (element.importi) {
                let importi = "";

                element.importi.forEach((i) => {
                  importi += `<br>&nbsp;&nbsp;&nbsp;&nbsp;<strong>${
                    i.anno
                  }:</strong> ${this.printValueAsCurrency(i.importo)}`;
                });

                // Object.keys(element.importi).forEach(function (x) {
                //   importi += `<br>    ${i.anno}:${this.printValueAsCurrency(
                //     i.importo
                //   )}`;
                // });
                //importi = importi.slice(0, -1);
                simpleIntervention.importi = importi;
                // element.importi.forEach((i) => {
                //   simpleIntervention.importi.push(
                //     this.printValueAsCurrency(i.importo)
                //   );
                // });
              }
              if (
                this.currentIntervention.isMain ||
                (!this.currentIntervention.isMain &&
                  this.currentIntervention.id === simpleIntervention.id)
              )
                this.allInterventionsWithStatus.push(simpleIntervention);
              if (element.isMain) {
                this.mainInterventionId = element.id;
              }
            });

            this.getAllGeoInterventions();
          });
    });
  }

  fillLayerDrawCombo() {
    this.conf.vectorLayers.forEach((l) => {
      if (
        l.vectorLayerTitle !== this.INTERVENTIONS_LAYER_TITLE &&
        l.vectorLayerTitle !== this.VIEWER_LAYER_TITLE
      ) {
        if (l.vectorLayerTitle === this.PROJECT_LAYER_TITLE) {
          let projLayer = this.layersForDrawing.find((l) => {
            l.vectorLayerTitle === this.PROJECT_LAYER_TITLE;
          });
          if (!this.layersForDrawing.includes(l))
            this.layersForDrawing.splice(0, 0, l);
        } else {
          if (!this.layersForDrawing.includes(l)) this.layersForDrawing.push(l);
        }
      }

      this.allDrawingLayers = [...this.layersForDrawing];
    });
    this.layersForKmz = [...this.layersForDrawing].filter(
      (l) => l.vectorLayerType !== "proj"
    );
    this.isComboLayerFiltered = false;

    if (
      (this.currentInterventionType && !this.currentIntervention.isMain) ||
      this.currentIntervention.numSubInterventi <= 0
    ) {
    } else {
      this.currentInterventionType = "";
    }
    this.layersForDrawing = [...this.layersForDrawing];
    this.layersForDrawInitialized = true;
  }

  projectIconStyleFunction(feature, resolution) {
    const isMain = feature.get("progetto");
    const status = feature.get("stato");
    const type = feature.get("type");
    let imageUrl;

    switch (status) {
      case "Lavorazione":
        if (type === "road") {
          imageUrl = "assets/img/road_lavorazione.png";
        }
        if (type === "rail") {
          imageUrl = "assets/img/rail_lavorazione.png";
        }
        if (type === "metro") {
          imageUrl = "assets/img/metro_lavorazione.png";
        }
        if (type === "bike") {
          imageUrl = "assets/img/bike_lavorazione.png";
        }
        if (type === "parkland") {
          imageUrl = "assets/img/parchi_lavorazione.png";
        }
        if (type === "emobility") {
          imageUrl = "assets/img/emobility_lavorazione.png";
        }
        if (type === "skilift") {
          imageUrl = "assets/img/funivia_lavorazione.png";
        }
        if (type === "bonifiche_lines") {
          imageUrl = "assets/img/bonifiche_territorio_linee_lavorazione.png";
        }
        if (type === "points") {
          imageUrl = "assets/img/scuola_inlavorazione.png";
        }
        if (type === "port") {
          imageUrl = "assets/img/porto_lavorazione.png";
        }
        if (type === "station") {
          imageUrl = "assets/img/station_lavorazione.png";
        }
        if (type === "metrostop") {
          imageUrl = "assets/img/metrostation_lavorazione.png";
        }
        if (type === "other_lines") {
          imageUrl = "assets/img/altre-vie-comunicazione_lavorazione.png";
        }
        if (type === "other_points") {
          imageUrl = "assets/img/building_lavorazione.png";
        }
        if (type === "park") {
          imageUrl = "assets/img/parcheggi_lavorazione.png";
        }
        if (type === "nodes") {
          imageUrl = "assets/img/nodi_lavorazione.png";
        }
        if (type === "asl") {
          imageUrl = "assets/img/asl_lavorazione.png";
        }
        if (type === "plants") {
          imageUrl = "assets/img/plants_lavorazione.png";
        }
        if (type === "networks_satellites") {
          imageUrl = "assets/img/networks_satellites_lavorazione.png";
        }
        if (type === "bonifiche_points") {
          imageUrl = "assets/img/bonifiche_territorio_punti_lavorazione.png";
        }

        if (type === "cimiteri") {
          imageUrl = "assets/img/cimiteri_lavorazione.png";
        }
        if (type === "musei_cultura") {
          imageUrl = "assets/img/musei_cultura_lavorazione.png";
        }
        if (type === "luoghi_di_culto") {
          imageUrl = "assets/img/luoghi_di_culto_lavorazione.png";
        }
        if (type === "aeroporti") {
          imageUrl = "assets/img/aeroporti_lavorazione.png";
        }
        if (type === "hospitals") {
          imageUrl = "assets/img/hospital_lavorazione.png";
        }

        if (type === "impianti_sportivi") {
          imageUrl = "assets/img/impianti_sportivi_lavorazione.png";
        }
        if (type === "interventi_sul_sociale") {
          imageUrl = "assets/img/interventi_sul_sociale_lavorazione.png";
        }
        if (type === "stazioni_autolinee") {
          imageUrl = "assets/img/stazioni_autolinee_lavorazione.png";
        }
        if (type === "impianti_energie_rinnovabili") {
          imageUrl = "assets/img/impianti_energie_rinnovabili_lavorazione.png";
        }
        if (type === "opere_urbanizzazione") {
            imageUrl = "assets/img/opere_urbanizzazione_lavorazione.png";
          }

        break;
      case "Fabbisogno":
        if (type === "road") {
          imageUrl = "assets/img/road_fabbisogno.png";
        }
        if (type === "rail") {
          imageUrl = "assets/img/rail_fabbisogno.png";
        }
        if (type === "metro") {
          imageUrl = "assets/img/metro_fabbisogno.png";
        }
        if (type === "bike") {
          imageUrl = "assets/img/bike_fabbisogno.png";
        }
        if (type === "parkland") {
          imageUrl = "assets/img/parchi_fabbisogno.png";
        }
        if (type === "emobility") {
          imageUrl = "assets/img/emobility_fabbisogno.png";
        }
        if (type === "skilift") {
          imageUrl = "assets/img/funivia_fabbisogno.png";
        }
        if (type === "bonifiche_lines") {
          imageUrl = "assets/img/bonifiche_territorio_linee_fabbisogno.png";
        }
        if (type === "points") {
          imageUrl = "assets/img/scuola_fabbisogno.png";
        }
        if (type === "port") {
          imageUrl = "assets/img/porto_fabbisogno.png";
        }
        if (type === "station") {
          imageUrl = "assets/img/station_fabbisogno.png";
        }
        if (type === "metrostop") {
          imageUrl = "assets/img/metrostation_fabbisogno.png";
        }
        if (type === "other_lines") {
          imageUrl = "assets/img/altre-vie-comunicazione_fabbisogno.png";
        }
        if (type === "other_points") {
          imageUrl = "assets/img/building_fabbisogno.png";
        }
        if (type === "park") {
          imageUrl = "assets/img/parcheggi_fabbisogno.png";
        }
        if (type === "nodes") {
          imageUrl = "assets/img/nodi_fabbisogno.png";
        }
        if (type === "asl") {
          imageUrl = "assets/img/asl_fabbisogno.png";
        }
        if (type === "plants") {
          imageUrl = "assets/img/plants_fabbisogno.png";
        }
        if (type === "networks_satellites") {
          imageUrl = "assets/img/networks_satellites_fabbisogno.png";
        }
        if (type === "bonifiche_points") {
          imageUrl = "assets/img/bonifiche_territorio_punti_fabbisogno.png";
        }

        if (type === "cimiteri") {
          imageUrl = "assets/img/cimiteri_fabbisogno.png";
        }
        if (type === "musei_cultura") {
          imageUrl = "assets/img/musei_cultura_fabbisogno.png";
        }
        if (type === "luoghi_di_culto") {
          imageUrl = "assets/img/luoghi_di_culto_fabbisogno.png";
        }
        if (type === "aeroporti") {
          imageUrl = "assets/img/aeroporti_fabbisogno.png";
        }
        if (type === "hospitals") {
          imageUrl = "assets/img/hospital_fabbisogno.png";
        }

        if (type === "impianti_sportivi") {
          imageUrl = "assets/img/impianti_sportivi_fabbisogno.png";
        }
        if (type === "interventi_sul_sociale") {
          imageUrl = "assets/img/interventi_sul_sociale_fabbisogno.png";
        }
        if (type === "stazioni_autolinee") {
          imageUrl = "assets/img/stazioni_autolinee_fabbisogno.png";
        }
        if (type === "impianti_energie_rinnovabili") {
          imageUrl = "assets/img/impianti_energie_rinnovabili_fabbisogno.png";
        }
        if (type === "opere_urbanizzazione") {
            imageUrl = "assets/img/opere_urbanizzazione_fabbisogno.png";
          }


        break;
      default:
        if (type === "road") {
          imageUrl = "assets/img/road_completato.png";
        }
        if (type === "rail") {
          imageUrl = "assets/img/rail_completato.png";
        }
        if (type === "metro") {
          imageUrl = "assets/img/metro_completato.png";
        }
        if (type === "bike") {
          imageUrl = "assets/img/bike_completato.png";
        }
        if (type === "parkland") {
          imageUrl = "assets/img/parchi_completato.png";
        }
        if (type === "emobility") {
          imageUrl = "assets/img/emobility_completato.png";
        }
        if (type === "skilift") {
          imageUrl = "assets/img/funivia_completato.png";
        }
        if (type === "bonifiche_lines") {
          imageUrl = "assets/img/bonifiche_territorio_linee_completato.png";
        }
        if (type === "points") {
          imageUrl = "assets/img/scuole_completato.png";
        }
        if (type === "port") {
          imageUrl = "assets/img/porto_completato.png";
        }
        if (type === "station") {
          imageUrl = "assets/img/station_completato.png";
        }
        if (type === "metrostop") {
          imageUrl = "assets/img/metrostation_completato.png";
        }
        if (type === "other_lines") {
          imageUrl = "assets/img/altre-vie-comunicazione_completato.png";
        }
        if (type === "other_points") {
          imageUrl = "assets/img/building_completato.png";
        }
        if (type === "park") {
          imageUrl = "assets/img/parcheggi_completato.png";
        }
        if (type === "nodes") {
          imageUrl = "assets/img/nodi_completato.png";
        }
        if (type === "asl") {
          imageUrl = "assets/img/asl_completato.png";
        }
        if (type === "plants") {
          imageUrl = "assets/img/plants_completato.png";
        }
        if (type === "networks_satellites") {
          imageUrl = "assets/img/networks_satellites_completato.png";
        }
        if (type === "bonifiche_points") {
          imageUrl = "assets/img/bonifiche_territorio_punti_completato.png";
        }

        if (type === "cimiteri") {
          imageUrl = "assets/img/cimiteri_completato.png";
        }
        if (type === "musei_cultura") {
          imageUrl = "assets/img/musei_cultura_completato.png";
        }
        if (type === "luoghi_di_culto") {
          imageUrl = "assets/img/luoghi_di_culto_completato.png";
        }
        if (type === "aeroporti") {
          imageUrl = "assets/img/aeroporti_completato.png";
        }
        if (type === "hospitals") {
          imageUrl = "assets/img/hospital_completato.png";
        }

        if (type === "impianti_sportivi") {
          imageUrl = "assets/img/impianti_sportivi_completato.png";
        }
        if (type === "interventi_sul_sociale") {
          imageUrl = "assets/img/interventi_sul_sociale_completato.png";
        }
        if (type === "stazioni_autolinee") {
          imageUrl = "assets/img/stazioni_autolinee_completato.png";
        }
        if (type === "impianti_energie_rinnovabili") {
          imageUrl = "assets/img/impianti_energie_rinnovabili_completato.png";
        }
        if (type === "opere_urbanizzazione") {
            imageUrl = "assets/img/opere_urbanizzazione_completato.png";
          }


        break;
    }

    // if (isMain === "Si") {
    //   imageUrl = "assets/img/progetto_base.png";
    // }
    let style = new Style({
      image: new olStyle.Icon({
        src: imageUrl,
      }),
    });
    return style;
  }

  projectStyleFunction(feature, resolution) {
    const isMain = feature.get("progetto");
    const status = feature.get("stato");

    let color;

    switch (status) {
      case "Lavorazione":
        color = "#ff8800";
        break;
      case "Fabbisogno":
        color = "#cc0000";
        break;
      default:
        color = "#007e33";
    }

    // if (isMain === "Si") {
    //   color = "#aa66cc";
    // }
    let style = [
      new Style({
        stroke: new olStyle.Stroke({
          color: color,
          width: 3, //spessore strade salvate
        }),
        fill: new olStyle.Fill({
          color: "rgba(0, 0, 255, 0.1)",
        }),
      }),
      new olStyle.Style({
        image: new olStyle.Circle({
          radius: 6,
          stroke: new olStyle.Stroke({
            color: "white", //edificio salvato
            width: 2,
          }),
          fill: new olStyle.Fill({
            color: color,
          }),
        }),
      }),
    ];
    return style;
  }

  getAllGeoInterventions() {
    console.log("dentro get geoJson for viewer");
    this.interventionIds = [];
    let ids = "";
    //CREAZIONE LAYER VISUALIZZAZIONE
    if (this.currentIntervention.isMain) {
      this.allInterventionsWithStatus.forEach((element) => {
        if (!this.isOnlyProject && element.isMain) {
        } else this.interventionIds.push(element.id);
      });

      ids = JSON.stringify(this.interventionIds);
      ids = "(" + ids.slice(1, -1) + ")";
    } else {
      ids = "(" + this.currentIntervention.id + ")";
    }
    this.showPage = true;
    ////
    this.geoService
      .getGeoJson(
        `${this.conf.geo_store}:${
          this.conf.vectorLayerNames[this.conf.vectorLayerNames.length - 1]
        }`,
        `${this.conf.vectorLayerSRS}`,
        undefined,
        `cql_filter=intervento_id in ${ids}`
      )
      .subscribe((geoJSon) => {
        console.log(geoJSon);
        //aggiungere
        geoJSon.features.forEach((f) => {
          let interv = this.allInterventionsWithStatus.find(
            (e) => e.id === f.properties.intervento_id
          );
          if (interv) {
            interv.type = f.properties["type"];
            let status = "Fabbisogno";
            if (interv.validated) {
              switch (interv.validated) {
                case "APPROVED":
                  status = "Lavorazione";
                  break;
                case "TERMINATO":
                  status = "Completato";
                  break;
              }
            }

            f.properties["codice"] = interv.codice;
            f.properties["stato"] = status;
            f.properties["progetto"] = interv.isMain ? "Si" : "No";
            f.properties["anno"] = interv.year;
            f.properties["cup"] = interv.cup;
            f.properties["descrizione"] = interv.descrizione;
            f.properties["direzioni"] = interv.direzioni;
            f.properties["importi"] = interv.importi;
            f.properties["type"] = interv.type;
          }
        });

        this.geoService.createLayerFromGeoJson(
          this.map,
          geoJSon,
          this.VIEWER_LAYER_TITLE,
          this.projectStyleFunction,
          null,
          false,
          false
        );
        this.createVisualIconLayer();

        this.setUpVectorLayers();
      });
  }

  get f() {
    return this.myForm.controls;
  }
  legendIconOnOff() {
    if (this.legendIcon == "close") this.legendIcon = "open";
    else this.legendIcon = "close";
  }

  enableDraw(): void {
    //attiva disegno
    if (!this.isDrawing) {
      this.divMsgOpen = true;
      //Start drawing
      this.isDrawing = true;
      const selectedGeom = this.f.geometryChooser.value;

      if (this.drawingType === "proj" && !selectedGeom) {
        alert("Attenzione seleziona la geometria del progetto");
        return;
      }

      if (this.drawingType === "proj" && selectedGeom === "points") {
        this.drawingType = this.currentLayerTipeSelected; // "points";
      }
      //punti senza snap
      if (
        this.drawingType === "points" ||
        this.drawingType === "port" ||
        this.drawingType === "metrostop" ||
        this.drawingType === "station" ||
        this.drawingType === "park" ||
        this.drawingType === "parkland" ||
        this.drawingType === "emobility" ||
        this.drawingType === "nodes" ||
        this.drawingType === "asl" ||
        this.drawingType === "plants" ||
        this.drawingType === "networks_satellites" ||
        this.drawingType === "cimiteri" ||
        this.drawingType === "luoghi_di_culto" ||
        this.drawingType === "aeroporti" ||
        this.drawingType === "bonifiche_points" ||
        this.drawingType === "musei_cultura" ||
        this.drawingType === "other_points" ||
        this.drawingType === "hospitals" ||
        this.drawingType === "impianti_sportivi" ||
        this.drawingType === "interventi_sul_sociale" ||
        this.drawingType === "stazioni_autolinee" ||
        this.drawingType === "impianti_energie_rinnovabili" ||
        this.drawingType === "opere_urbanizzazione"
      ) {
        //this.map.removeInteraction(this.snapInteraction);
        this.removeSnapInteractions();
        this.map.addInteraction(this.drawBuildingInteraction);
      } else {
        if (selectedGeom === "points") {
          this.map.addInteraction(this.drawBuildingInteraction);
        } else {
          let count = 0;
          this.removeDrawingInteractions();
          console.log(count);
          this.removeSnapInteractions();
          this.setSnapOnLayer(this.currentLayerTitle, this.conf.snapTollerance);
          this.map.addInteraction(this.snapInteraction);
          this.map.addInteraction(this.drawStreetInteraction);
        }
      }
      //linee a mano libera
      if (
        (this.freehand &&
          this.drawingType !== "points" &&
          this.drawingType !== "port" &&
          this.drawingType !== "metrostop" &&
          this.drawingType !== "parkland" &&
          this.drawingType !== "emobility" &&
          this.drawingType !== "station" &&
          this.drawingType !== "park" &&
          this.drawingType !== "nodes" &&
          this.drawingType !== "asl" &&
          this.drawingType !== "plants" &&
          this.drawingType !== "networks_satellites" &&
          this.drawingType !== "bonifiche_points" &&
          this.drawingType !== "cimiteri" &&
          this.drawingType !== "luoghi_di_culto" &&
          this.drawingType !== "aeroporti" &&
          this.drawingType !== "musei_cultura" &&
          this.drawingType !== "hospitals" &&
          this.drawingType !== "other_points" &&
          this.drawingType !== "impianti_sportivi" &&
          this.drawingType !== "interventi_sul_sociale" &&
          this.drawingType !== "stazioni_autolinee" &&
          this.drawingType !== "impianti_energie_rinnovabili" &&
          this.drawingType !== "opere_urbanizzazione"
          ) ||
        !this.showFreehandChooser
      ) {
        this.map.addInteraction(this.snapInteraction);
      } else {
        //this.map.removeInteraction(this.snapInteraction);
        this.removeSnapInteractions();
      }
    } else {
      //disattiva disegno
      //Stop drawing
      this.isDrawing = false;
      this.divMsgOpen = false;

      if (
        this.currentIntervention.isMain &&
        this.currentIntervention.numSubInterventi > 0
      ) {
        this.layersForDrawing = [...this.allDrawingLayers];
        this.layersForKmz = [...this.allDrawingLayers].filter(
          (l) => l.vectorLayerType !== "proj"
        );
      } else {
        if (!this.currentIntervention.isMain && this.currentInterventionType) {
          if (!this.drawingType) {
            this.drawingType = this.currentInterventionType;
          }
          if (!this.isComboLayerFiltered) {
            this.f.geometryChooser.disable();
            if (!this.isComboLayerFiltered) {
              this.filterDrawingAndKmzLayers();
              this.isComboLayerFiltered = true;
            }
          }
        }
        //this.myForm.controls.drawingTypeChooser.disable(); asd
      }

      if (
        this.drawingType === "points" ||
        this.drawingType === "port" ||
        this.drawingType === "metrostop" ||
        this.drawingType === "parkland" ||
        this.drawingType === "emobility" ||
        this.drawingType === "station" ||
        this.drawingType === "park" ||
        this.drawingType === "nodes" ||
        this.drawingType === "asl" ||
        this.drawingType === "plants" ||
        this.drawingType === "networks_satellites" ||
        this.drawingType === "cimiteri" ||
        this.drawingType === "aeroporti" ||
        this.drawingType === "musei_cultura" ||
        this.drawingType === "luoghi_di_culto" ||
        this.drawingType === "bonifiche_points" ||
        this.drawingType === "hospitals" ||
        this.drawingType === "other_points" ||
        this.drawingType === "impianti_sportivi" ||
        this.drawingType === "interventi_sul_sociale" ||
        this.drawingType === "stazioni_autolinee" ||
        this.drawingType === "impianti_energie_rinnovabili" ||
        this.drawingType === "opere_urbanizzazione"
      ) {
        //this.map.removeInteraction(this.drawBuildingInteraction);
        this.removeDrawingInteractions();
      } else {
        this.removeDrawingInteractions();
        //this.map.removeInteraction(this.drawStreetInteraction);
      }
    }
  }

  chooseDrawingType($event) {
    if (
      this.drawingType !== "points" &&
      this.drawingType !== "port" &&
      this.drawingType !== "metrostop" &&
      this.drawingType !== "parkland" &&
      this.drawingType !== "emobility" &&
      this.drawingType !== "station" &&
      this.drawingType !== "park" &&
      this.drawingType !== "nodes" &&
      this.drawingType !== "asl" &&
      this.drawingType !== "plants" &&
      this.drawingType !== "networks_satellites" &&
      this.drawingType !== "bonifiche_points" &&
      this.drawingType !== "cimiteri" &&
      this.drawingType !== "luoghi_di_culto" &&
      this.drawingType !== "musei_cultura" &&
      this.drawingType !== "aeroporti" &&
      this.drawingType !== "hospitals" &&
      this.drawingType !== "other_points" &&
      this.drawingType !== "impianti_sportivi" &&
        this.drawingType !== "interventi_sul_sociale" &&
        this.drawingType !== "stazioni_autolinee" &&
        this.drawingType !== "impianti_energie_rinnovabili" &&
        this.drawingType !== "opere_urbanizzazione"
    ) {
      console.log($event);
      this.isDrawing = false;
      this.removeDrawingInteractions();

      this.freehand = $event === "freehand" ? true : false;
      let vectorSource = new olSource.Vector({
        format: new olFormat.GeoJSON(),
      });
      this.drawStreetInteraction = new olInteractions.Draw({
        source: vectorSource, //sembra che drawStreet e drawing layer abbiano la stessa sorgente
        type: "LineString",
        freehand: this.freehand,
        style: new Style({
          stroke: new olStyle.Stroke({
            color: "#28a745", //colore tratto disegno (verde)
            width: 3,
          }),
          fill: new olStyle.Fill({
            color: "rgba(0, 0, 255, 0.1)",
          }),
        }),
      });
      //events on street's layer
      this.drawStreetInteraction.on("drawstart", (event) => {
        this.isDrawing = true;
      });
      this.drawStreetInteraction.on("drawend", (event) => {
        this.addDrawedFeatureToMap(event.feature);

        if (
          (!this.currentIntervention.isMain ||
            this.currentIntervention.numSubInterventi <= 0) &&
          this.streetFeatures.length > 0
        ) {
          const geometry = this.streetFeatures[0].feature
            .getGeometry()
            .getType();
          if (geometry == "LineString") {
            this.geometryTypes = [{ title: "Linea", code: "line" }];
          } else {
            this.geometryTypes = [{ title: "Punto", code: "points" }];
          }
          this.layersForKmz = this.layersForDrawing.filter((l) => {
            return l.vectorLayerType === this.drawingType;
          });
        }
      });
    }
  }

  setUpIntersectLayer() {
    this.geoService
      .getGeoJson(
        `${this.conf.geo_store}:${this.conf.vectorLayerNames[2]}`,
        `${this.conf.vectorLayerSRS}`,
        undefined,
        `intersects(geom, querySingle('gavinoTest:rete_viarie_ferroviarie', 'geom', 'INCLUDE'))`
      )
      .subscribe((res) => {
        this.geoService.createLayerFromGeoJson(
          this.map,
          res,
          "Intersezione",
          undefined,
          3,
          false,
          false
        );
      });
  }

  setUpWmsLayers() {
    //default basemap
    this.map.addLayer(this.geoService.getCartoDbLayer());
    this.attribution = {
      url: "https://www.openstreetmap.org/copyright",
      text1: "© OpenStreetMap",
      text2: "contributors.",
    };
    //set up wms layers
    for (let index = 0; index < this.conf.wmsLayerNames.length; index++) {
      this.geoService
        .getTileWMS(
          `${this.conf.geo_store}:${this.conf.wmsLayerNames[index]}`,
          `${this.conf.wmsLayerTitles}`
        )
        .subscribe(
          (res) => {
            this.map.addLayer(res);
          },
          (error) => console.log(`${error}`)
        );
    }
  }

  setSnapOnLayer(titleLayer: string, tollerance: number) {
    let l = this.geoService.getLayerByTitle(
      this.map,
      titleLayer ? titleLayer : this.conf.layerToSnapLines
    );

    this.snapInteraction = new olInteractions.Snap({
      source: l.getSource(),
      pixelTolerance: tollerance ? tollerance : 100,
      active: true,
    });
  }

  checkHidden() {
    return this.editor !== "Editor" || this.currentLayerTipeSelected !== "proj";
  }

  setDrawing() {
    if (!this.isDrawing) {
      this.chooseDrawingType("freehand");
      if (!this.drawBuildingInteraction) {
        this.drawBuildingInteraction = new olInteractions.Draw({
          //source: vector.getSource(),
          type: "Point",
        });
        //events on street's layer
        this.drawBuildingInteraction.on("drawstart", (event) => {
          this.isDrawing = true;
          this.map.on("pointermove", (event) => {
            // if (this.isDrawing) {
            //   this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
            //     if (
            //       layer &&
            //       layer.get("title") &&
            //       layer.get("title") === this.PROJECT_LAYER_TITLE
            //     ) {
            //       this.drawingType = feature.values_["type"];
            //       return;
            //     }
            //   });
            // }
          });
        });
        this.drawBuildingInteraction.on("drawend", (event) => {
          this.addDrawedFeatureToMap(event.feature);
        });
      }
    }
  }

  addDrawedFeatureToMap(feature) {
    if (this.drawingType && this.drawingType !== "") {
      this.canSave = true;
      //feature.values_["type"] = this.drawingType;
      feature.values_["type"] =
        this.drawingType === "proj"
          ? this.currentInterventionType
          : this.drawingType;
      feature.values_["myid"] = feature.ol_uid;
      const drawLayer = this.geoService.getLayerByTitle(
        this.map,
        this.DRAW_LAYER_TITLE
      );
      drawLayer.getSource().addFeature(feature);
      drawLayer.getSource().changed();
      const newFeature: MyStreetFeatures = {
        feature: feature,
        saved: false,
        date: moment(new Date()),
      };

      this.streetFeatures.unshift(newFeature);
      this.geoService.setZindexToLayer(this.map, this.DRAW_LAYER_TITLE, 93);

      this.geoService.createIconLayerBylayer(
        this.map,
        this.DRAW_LAYER_TITLE,
        this.ICON_TEMP_LAYER_TITLE,
        this.iconTempLayerStyleFunction,
        true
      );
      this.geoService.setZindexToLayer(
        this.map,
        this.ICON_TEMP_LAYER_TITLE,
        94
      );
      this.filterDrawingAndKmzLayers();
    }
  }

  iconTempLayerStyleFunction(feature, resolution) {
    if (feature) {
      const type = feature.get("type");
      let imageUrl;
      const merged = feature.get("merged") ? feature.get("merged") : false;
      if (!merged) {
        switch (type) {
          case "road":
            imageUrl = "assets/img/roadGreen.png";
            break;
          case "rail":
            imageUrl = "assets/img/railGreen.png";
            break;
          case "metro":
            imageUrl = "assets/img/metroGreen.png";
            break;
          case "bike":
            imageUrl = "assets/img/bikeGreen.png";
            break;
          case "parkland":
            imageUrl = "assets/img/parchiGreen.png";
            break;
          case "emobility":
            imageUrl = "assets/img/emobilityGreen.png";
            break;
          case "skilift":
            imageUrl = "assets/img/funiviaGreen.png";
            break;
          case "bonifiche_lines":
            imageUrl = "assets/img/bonifiche_territorio_lineeGreen.png";
            break;
          case "points":
            imageUrl = "assets/img/scuoleGreen.png";
            break;
          case "port":
            imageUrl = "assets/img/portoGreen.png";
            break;
          case "metrostop":
            imageUrl = "assets/img/metrostationGreen.png";
            break;
          case "station":
            imageUrl = "assets/img/stationGreen.png";
            break;
          case "other_lines":
            imageUrl = "assets/img/altre-vie-comunicazioneGreen.png";
            break;
          case "other_points":
            imageUrl = "assets/img/buildingGreen.png";
            break;
          case "park":
            imageUrl = "assets/img/parcheggiGreen.png";
            break;
          case "nodes":
            imageUrl = "assets/img/nodiGreen.png";
            break;
          case "asl":
            imageUrl = "assets/img/aslGreen.png";
            break;
          case "plants":
            imageUrl = "assets/img/plantsGreen.png";
            break;
          case "networks_satellites":
            imageUrl = "assets/img/networks_satellitesGreen.png";
            break;
          case "bonifiche_points":
            imageUrl = "assets/img/bonifiche_territorio_puntiGreen.png";
            break;

          case "cimiteri":
            imageUrl = "assets/img/cimiteriGreen.png";
            break;
          case "musei_cultura":
            imageUrl = "assets/img/musei_culturaGreen.png";
            break;

          case "luoghi_di_culto":
            imageUrl = "assets/img/luoghi_di_cultoGreen.png";
            break;

          case "hospitals":
            imageUrl = "assets/img/hospitalGreen.png";
            break;

          case "aeroporti":
            imageUrl = "assets/img/aeroportiGreen.png";
            break;

          case "impianti_sportivi":
            imageUrl = "assets/img/impianti_sportiviGreen.png";
            break;
          case "interventi_sul_sociale":
            imageUrl = "assets/img/interventi_sul_socialeGreen.png";
            break;
          case "stazioni_autolinee":
            imageUrl = "assets/img/stazioni_autolineeGreen.png";
            break;
          case "impianti_energie_rinnovabili":
            imageUrl = "assets/img/impianti_energie_rinnovabiliGreen.png";
            break;
            case "opere_urbanizzazione":
                imageUrl = "assets/img/opere_urbanizzazioneGreen.png";
                break;


          default:
            imageUrl = "assets/img/Green.png";
        }
      } else {
        switch (type) {
          case "road":
            imageUrl = "assets/img/road_merge.png";
            break;
          case "rail":
            imageUrl = "assets/img/rail_merge.png";
            break;
          case "metro":
            imageUrl = "assets/img/metro_merge.png";
            break;
          case "bike":
            imageUrl = "assets/img/bike_merge.png";
            break;
          case "skilift":
            imageUrl = "assets/img/funivia_merge.png";
            break;
          case "bonifiche_lines":
            imageUrl = "assets/img/bonifiche_territorio_linee_merge.png";
            break;
          case "other_lines":
            imageUrl = "assets/img/altre-vie-comunicazione_merge.png";
            break;
        }
      }
      console.log("imageUrl", imageUrl);
      let style = new Style({
        image: new olStyle.Icon({
          src: imageUrl,
        }),
      });
      return style;
    }
  }

  iconSavedLayerStyleFunction(feature, resolution) {
    if (feature) {
      const type = feature.get("type");
      let imageUrl;

      switch (type) {
        case "road":
          imageUrl = "assets/img/road.png";
          break;
        case "rail":
          imageUrl = "assets/img/rail.png";
          break;
        case "metro":
          imageUrl = "assets/img/metro.png";
          break;
        case "bike":
          imageUrl = "assets/img/bike.png";
          break;
        case "parkland":
          imageUrl = "assets/img/parchi-default.png";
          break;
        case "emobility":
          imageUrl = "assets/img/emobility-default.png";
          break;
        case "skilift":
          imageUrl = "assets/img/funivia.png";
          break;
        case "bonifiche_lines":
          imageUrl = "assets/img/bonifiche_territorio_linee.png";
          break;
        case "points":
          imageUrl = "assets/img/scuola_save.png";
          break;
        case "port":
          imageUrl = "assets/img/porto.png";
          break;
        case "metrostop":
          imageUrl = "assets/img/metrostation.png";
          break;
        case "station":
          imageUrl = "assets/img/station.png";
          break;
        case "other_lines":
          imageUrl = "assets/img/altre-vie-comunicazione_save.png";
          break;
        case "other_points":
          imageUrl = "assets/img/building.png";
          break;
        case "park":
          imageUrl = "assets/img/parcheggi-default.png";
          break;
        case "nodes":
          imageUrl = "assets/img/nodi-default.png";
          break;
        case "asl":
          imageUrl = "assets/img/asl-default.png";
          break;
        case "plants":
          imageUrl = "assets/img/plants-default.png";
          break;
        case "networks_satellites":
          imageUrl = "assets/img/networks_satellites-default.png";
          break;
        case "bonifiche_points":
          imageUrl = "assets/img/bonifiche_territorio_punti-default.png";
          break;

        case "cimiteri":
          imageUrl = "assets/img/cimiteri-default.png";
          break;
        case "musei_cultura":
          imageUrl = "assets/img/musei_cultura-default.png";
          break;

        case "luoghi_di_culto":
          imageUrl = "assets/img/luoghi_di_culto-default.png";
          break;
        case "hospitals":
          imageUrl = "assets/img/hospital-default.png";
          break;

        case "aeroporti":
          imageUrl = "assets/img/aeroporti-default.png";
          break;
        case "impianti_sportivi":
          imageUrl = "assets/img/impianti_sportivi-default.png";
          break;
        case "interventi_sul_sociale":
          imageUrl = "assets/img/interventi_sul_sociale-default.png";
          break;
        case "stazioni_autolinee":
          imageUrl = "assets/img/stazioni_autolinee-default.png";
          break;
        case "impianti_energie_rinnovabili":
          imageUrl = "assets/img/impianti_energie_rinnovabili-default.png";
          break;
          case "opere_urbanizzazione":
            imageUrl = "assets/img/opere_urbanizzazione-default.png";
            break;


        default:
          imageUrl = "assets/img/road.png";
      }

      let style = new Style({
        image: new olStyle.Icon({
          src: imageUrl,
        }),
      });
      return style;
    }
  }

  saveDrawedInterventions() {
    const drawLayer = this.geoService.getLayerByTitle(
      this.map,
      this.DRAW_LAYER_TITLE
    );
    let features = drawLayer.getSource().getFeatures();
    drawLayer.getSource().clear();
    if (this.deletedFeatureIds.length > 0) {
      console.log("dentro alla cancellazione");
      this.geoService
        .deleteGeometries(this.deletedFeatureIds, this.interventoId)
        .subscribe((res) => {
          this.deletedFeatureIds = [];
          this.toastr.success("Salvataggio avvenuto con successo");
          if (features.length > 0) {
            this.geoService.saveAndReprojectInterventions(
              this.map,
              features,
              this.sourceSRS,
              this.destSRS,
              this.interventoId,
              this.ICON_LAYER_TITLE,
              this.ICON_TEMP_LAYER_TITLE,
              this.iconSavedLayerStyleFunction,
              `cql_filter=intervento_id=${this.interventoId}`,
              this.streetFeatures
            );
            this.highlightedDraws = [];
            console.log("salvoooooo:" + JSON.stringify(features[0]));
          }
          this.featuresToMerge = [];
          this.oldInterventions = [];
          this.merged = null;
          this.newFeat = null;

          this.canSave = false;
        });
    } else {
      this.toastr.success("Salvataggio avvenuto con successo");
      console.log("nessuna cancellazione");
      this.geoService.saveAndReprojectInterventions(
        this.map,
        features,
        this.sourceSRS,
        this.destSRS,
        this.interventoId,
        this.ICON_LAYER_TITLE,
        this.ICON_TEMP_LAYER_TITLE,
        this.iconSavedLayerStyleFunction,
        `cql_filter=intervento_id=${this.interventoId}`,
        this.streetFeatures
      );
      this.highlightedDraws = [];
      console.log("salvoooooo:" + JSON.stringify(features[0]));

      this.featuresToMerge = [];
      this.oldInterventions = [];
      this.merged = null;
      this.newFeat = null;

      this.canSave = false;
    }
  }

  setPopup() {
    this.popupOverlay = new Overlay({
      element: this.popup.nativeElement,
      offset: [9, 9],
      autoPan: true,
      autoPanAnimation: {
        duration: 1000,
      },
    });
    this.map.addOverlay(this.popupOverlay);
    let that = this;
    this.map.on("pointermove", (event) => {
      this.popup.nativeElement.hidden = true;
      this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
        if (that.isDrawing) {
          if (
            layer &&
            layer.get("title") &&
            //1 == 1
            layer.get("title") === this.PROJECT_LAYER_TITLE &&
            this.currentLayerTitle === this.PROJECT_LAYER_TITLE
          ) {
            this.drawingType = this.currentInterventionType;
            if (this.drawingType) return;
          }
        }

        if (that.isPopupActive && this.map.getView().getZoom() > 1) {
          this.showPopupInfo(feature, layer, event.coordinate);
          return feature;
        }
      });
    });
    this.map.on("click", (event) => {
      this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
        if (
          feature &&
          layer &&
          layer.get("title") &&
          (layer.get("title") === this.INTERVENTIONS_LAYER_TITLE ||
            layer.get("title") === this.VIEWER_LAYER_TITLE ||
            layer.get("title") === this.DRAW_LAYER_TITLE ||
            layer.get("title") === this.ICON_VIEW_LAYER_TITLE ||
            layer.get("title") === this.ICON_LAYER_TITLE ||
            layer.get("title") === this.ICON_TEMP_LAYER_TITLE)
        ) {
          let oluid;
          switch (layer.get("title")) {
            case this.INTERVENTIONS_LAYER_TITLE:
              oluid = feature.ol_uid;
              const layerInt = this.geoService.getLayerByTitle(
                this.map,
                this.INTERVENTIONS_LAYER_TITLE
              );
              layerInt.getSource().changed();
              this.streetFeatures.forEach((sf) => {
                let index = this.streetFeatures.indexOf(sf);
                if (sf.feature.ol_uid === oluid) {
                  $(`#element${index}`).trigger("click");
                }
              });
              break;
            case this.ICON_LAYER_TITLE:
              const layerIcon = this.geoService.getLayerByTitle(
                this.map,
                this.ICON_LAYER_TITLE
              );
              layerIcon.getSource().changed();
              oluid = feature.values_.gid;
              this.streetFeatures.forEach((sf) => {
                let index = this.streetFeatures.indexOf(sf);
                if (sf.feature.values_.gid === oluid) {
                  $(`#element${index}`).trigger("click");
                }
              });
              break;
            case this.VIEWER_LAYER_TITLE:
              oluid = feature.values_.intervento_id;
              this.allInterventionsWithStatus.forEach((i) => {
                let index = this.allInterventionsWithStatus.indexOf(i);
                if (i.id === oluid) {
                  console.log("trovato visualizzazione");
                  $(`#intervention${index}`).trigger("click");
                  //$(`#intervention${index}`).addClass("text-33cccc");
                } //else {
                //   $(`#intervention${index}`).removeClass("text-33cccc");
                // }
              });
              break;
            case this.ICON_VIEW_LAYER_TITLE:
              oluid = feature.values_.intervento_id;
              this.allInterventionsWithStatus.forEach((i) => {
                let index = this.allInterventionsWithStatus.indexOf(i);
                if (i.id === oluid) {
                  console.log("trovato visualizzazione");
                  $(`#intervention${index}`).trigger("click");
                  //$(`#intervention${index}`).addClass("text-33cccc");
                } // else {
                //   $(`#intervention${index}`).removeClass("text-33cccc");
                //   $(`#intervention${index}`).trigger("click");
                // }
              });
              break;
            case this.DRAW_LAYER_TITLE:
              oluid = feature.ol_uid;
              this.streetFeatures.forEach((sf) => {
                let index = this.streetFeatures.indexOf(sf);
                console.log("dentro disegni");
                if (sf.feature.ol_uid === oluid) {
                  $(`#element${index}`).trigger("click");
                }
              });
              break;
            case this.ICON_TEMP_LAYER_TITLE:
              oluid = feature.values_.gid;
              this.streetFeatures.forEach((sf) => {
                let index = this.streetFeatures.indexOf(sf);
                if (sf.feature.ol_uid === oluid) {
                  console.log("trovata icona verde");
                  $(`#element${index}`).trigger("click");
                }
              });
              break;
          }
        }
        event.preventDefault();
        event.stopPropagation();
        return feature;
      });
    });
  }

  showPopupInfo(feature, layer, coordinate) {
    if (layer instanceof VectorLayer) {
      this.popupSelectedLayer = layer.get("title");
      if (
        (this.popupSelectedLayer === this.VIEWER_LAYER_TITLE ||
          //this.popupSelectedLayer === "Progetto" ||
          this.popupSelectedLayer === this.ICON_VIEW_LAYER_TITLE) &&
        !this.isDrawing
      ) {
        this.popUpKeys = Object.keys(feature.values_);

        this.popUpValues = Object.values(feature.values_);
        const indexGeom = this.popUpKeys.indexOf("geometry");
        if (indexGeom >= 0) {
          this.popUpKeys.splice(indexGeom, 1);
          this.popUpValues.splice(indexGeom, 1);
        }
        const indexGid = this.popUpKeys.indexOf("gid");
        if (indexGid >= 0) {
          this.popUpKeys.splice(indexGid, 1);
          this.popUpValues.splice(indexGid, 1);
        }
        const indexProgetto = this.popUpKeys.indexOf("progetto");
        if (indexProgetto >= 0) {
          this.popUpKeys.splice(indexProgetto, 1);
          this.popUpValues.splice(indexProgetto, 1);
        }
        const indexInterventoId = this.popUpKeys.indexOf("intervento_id");
        if (indexInterventoId >= 0) {
          this.popUpKeys.splice(indexInterventoId, 1);
          this.popUpValues.splice(indexInterventoId, 1);
        }
        const indexStato = this.popUpKeys.indexOf("stato");
        if (indexStato >= 0) {
          this.popUpKeys.splice(indexStato, 1);
          this.popUpValues.splice(indexStato, 1);
        }
        const indexType = this.popUpKeys.indexOf("type");
        if (indexType >= 0) {
          this.popUpKeys.splice(indexType, 1);
          this.popUpValues.splice(indexType, 1);
        }

        for (let index = 0; index < this.popUpKeys.length; index++) {
          if (this.popUpKeys[index] !== "geometry")
            this.popUpInfo[`${this.popUpKeys[index]}`] =
              this.popUpValues[index];
        }
        this.popup.nativeElement.hidden = false;

        this.popupOverlay.setPosition(coordinate);
      }
    }
    return true;
  }

  addLayerSwitcher() {
    const layerSwitcher = new LayerSwitcher({
      tipLabel: "Layers", // Optional label for button
      groupSelectStyle: "children",
      target: "layers",
    });

    this.map.addControl(layerSwitcher);
  }

  addSidebar() {
    const sidebar = new Sidebar({
      element: "sidebar",
      position: "right",
    });
    this.map.addControl(sidebar);
    sidebar.open("interactions");
  }

  setSelectionOnLayers(layers: any[]) {
    this.selectInteraction = new olInteractions.Select({
      condition: olConditions.click,
      layers: layers,
    });

    this.map.addInteraction(this.selectInteraction);
  }

  setUpVectorLayers() {
    const filters = [];
    const projectStyle = [
      new Style({
        stroke: new olStyle.Stroke({
          color: "#aa66cc",
          width: 3, //spessore strade salvate
        }),
        fill: new olStyle.Fill({
          color: "rgba(0, 0, 255, 0.1)",
        }),
      }),
      new olStyle.Style({
        image: new olStyle.Circle({
          radius: 6,
          stroke: new olStyle.Stroke({
            color: "white", //edificio salvato
            width: 2,
          }),
          fill: new olStyle.Fill({
            color: "#aa66cc",
          }),
        }),
      }),
    ];

    //creo il layer progetto
    this.geoService.createLayer(
      this.map,
      this.PROJECT_LAYER_TITLE,
      null,
      true,
      "coordinate_intervento",
      this.conf.vectorLayerSRS,
      projectStyle,
      `cql_filter=intervento_id in (${this.mainInterventionId})`,
      false
    );
    //creo il layer Interventi
    this.createInterventionLayer();
    const drawingLayer = this.geoService.getDrawingLayer(
      "#1e7e34", //strada disegnata stato 2
      "white", //colore bordo edificio
      "#1e7e34", //colore nuovo edificio
      6,
      3,
      2,
      this.DRAW_LAYER_TITLE
    );

    this.setDrawing();
    this.map.addLayer(drawingLayer);
    //this.addLayerSwitcher();
    this.addSidebar();

    this.map.updateSize();

    console.log(this.streetFeatures);
    //combo's filters
    this.allFilterLayers = [...filters];
  }
  createInterventionLayer() {
    this.oldInterventions = [];
    this.featuresToMerge = [];
    let interventionIds = [];

    interventionIds.push(this.interventoId);
    let ids = JSON.stringify(interventionIds);
    ids = "(" + ids.slice(1, -1) + ")";
    try {
      this.geoService.createLayer(
        this.map,
        this.INTERVENTIONS_LAYER_TITLE,
        null,
        false,
        "coordinate_intervento",
        this.conf.vectorLayerSRS,
        this.conf.vectorStyles[3],
        `cql_filter=intervento_id in ${ids}`,
        false
      );
    } catch (e) {
      console.log(e);
    }
  }

  createVisualIconLayer() {
    const l = this.geoService.getLayerByTitle(
      this.map,
      this.VIEWER_LAYER_TITLE
    );
    l.getSource().changed();
    const length = l.getSource().getFeatures().length;
    const features = l.getSource().getFeatures();
    const onlyPoint = [];

    if (features.length) {
      for (let index = 0; index < length; index++) {
        const f = features[index].clone();
        if (features[index].getGeometry().getType() === "LineString") {
          const ls = turf.lineString(
            f
              .getGeometry()
              .transform(this.sourceSRS, this.destSRS)
              .getCoordinates()
          );
          let point = turf.pointOnFeature(ls);
          point.properties["codice"] = f.get("codice");
          point.properties["stato"] = f.get("stato");
          point.properties["progetto"] = f.get("progetto");
          point.properties["type"] = f.get("type");
          point.properties["anno"] = f.get("anno");
          point.properties["descrizione"] = f.get("descrizione");
          point.properties["cup"] = f.get("cup");
          point.properties["intervento_id"] = f.get("intervento_id");
          point.properties["direzioni"] = f.get("direzioni");
          point.properties["importi"] = f.get("importi");
          onlyPoint.push(point);
        } else {
          if (features[index].getGeometry().getType() === "MultiLineString") {
            const ls = turf.multiLineString(
              f
                .getGeometry()
                .transform(this.sourceSRS, this.destSRS)
                .getCoordinates()
            );
            let point = turf.pointOnFeature(ls);
            point.properties["codice"] = f.get("codice");
            point.properties["stato"] = f.get("stato");
            point.properties["progetto"] = f.get("progetto");
            point.properties["type"] = f.get("type");
            point.properties["anno"] = f.get("anno");
            point.properties["descrizione"] = f.get("descrizione");
            point.properties["cup"] = f.get("cup");
            point.properties["intervento_id"] = f.get("intervento_id");
            point.properties["direzioni"] = f.get("direzioni");
            point.properties["importi"] = f.get("importi");
            onlyPoint.push(point);
          } else {
            //point
            let point = turf.point(
              f
                .getGeometry()
                .transform(this.sourceSRS, this.destSRS)
                .getCoordinates()
            );
            point.properties["codice"] = f.get("codice");
            point.properties["stato"] = f.get("stato");
            point.properties["progetto"] = f.get("progetto");
            point.properties["type"] = f.get("type");
            point.properties["anno"] = f.get("anno");
            point.properties["descrizione"] = f.get("descrizione");
            point.properties["cup"] = f.get("cup");
            point.properties["intervento_id"] = f.get("intervento_id");
            point.properties["direzioni"] = f.get("direzioni");
            point.properties["importi"] = f.get("importi");
            onlyPoint.push(point);
          }
        }
      }
      console.log(onlyPoint);
      const icons = {
        type: "FeatureCollection",
        features: onlyPoint,
      };
      this.geoService.createLayerFromGeoJson(
        this.map,
        icons,
        this.ICON_VIEW_LAYER_TITLE,
        this.projectIconStyleFunction,
        null,
        false,
        false
      );
    }
  }

  setDrawingLayer($event) {
    if (!$event) {
      this.f.geometryChooser.disable();
      this.f.geometryChooser.setValue(null);
      this.showFreehandChooser = false;
      this.currentLayerTipeSelected = "";

      this.filterDrawingAndKmzLayers();
      return;
    }
    this.layersForDrawing = this.allDrawingLayers;
    this.layersForKmz = this.layersForDrawing.filter(
      (l) => l.vectorLayerType !== "proj"
    );
    this.currentLayerTitle = $event.vectorLayerTitle;
    if (
      this.currentIntervention.tipo &&
      this.currentIntervention.tipo !== $event.vectorLayerType &&
      $event.vectorLayerTitle !== "Progetto/Fabbisogno"
    ) {
      this.toastr.warning(
        "Attenzione hai selezionato una tipologia diversa da quella selezionata nei dati generali. Ti preghiamo di aggiornare uno dei due valori per renderli congrui.",
        null,
        { disableTimeOut: true }
      );
    }
    this.disableDraw = false;
    this.f.geometryChooser.setValue(null);
    if (this.currentLayerTitle !== this.PROJECT_LAYER_TITLE) {
      this.waiting = true;
    } else {
      this.waiting = false;
      this.isComboLayerFiltered = false; //se scelgo progetto la combo non è filtrata
      this.f.geometryChooser.enable();
      this.setGeometryComboValue();
    }
    this.list1Height = false;
    this.drawingType = $event.vectorLayerType;
    this.currentLayerTipeSelected = $event.vectorLayerType;
    this.geoService.hideLayersExcept(
      this.map,
      this.conf.vectorLayerTitles,
      this.currentLayerTitle,
      this.currentIntervention.isMain
    );
    //14 è l'ultimo layer linea
    const indexTitle = this.conf.vectorLayerTitles.indexOf(
      this.currentLayerTitle
    );
    if (indexTitle <= 14) {
      this.showFreehandChooser = true;
    } else {
      this.showFreehandChooser = false;
    }

    this.removeDrawingInteractions();

    if (
      this.currentIntervention.isMain &&
      this.currentIntervention.numSubInterventi > 0
    ) {
      this.layersForDrawing = [...this.allDrawingLayers].filter(
        (l) => l.vectorLayerType !== "proj"
      );
      this.layersForKmz = [...this.allDrawingLayers].filter(
        (l) => l.vectorLayerType !== "proj"
      );
    } else {
      if (!this.isComboLayerFiltered) {
        this.filterDrawingAndKmzLayers();

        this.isComboLayerFiltered = true;
        //}
      }
    }
    this.setGeometryComboValue();
    this.showButton = true;
    this.isDrawing = false;
  }

  setGeometryComboValue() {
    const isProject =
      this.currentIntervention.isMain &&
      this.currentIntervention.numSubInterventi > 0
        ? true
        : false;

    const pLayer = this.geoService.getLayerByTitle(
      this.map,
      this.PROJECT_LAYER_TITLE
    );

    if (isProject || this.streetFeatures.length == 0) {
      const features = pLayer.getSource().getFeatures();
      const geometries = features.map((f) => f.getGeometry().getType());
      if (features.length > 0) {
        // const points = geometries.filter((g) => g === "Point").length;
        // const lines = geometries.filter((g) => g === "LineString").length;
        // if (points > 0 && lines > 0) {
        //   this.lastGeomteryType = null;
        // } else {
        //   if (points > 0) {
        //     this.lastGeomteryType = "Point";
        //   }
        //   if (lines > 0) {
        //     this.lastGeomteryType = "LineString";
        //   }
        // }
        this.lastGeomteryType = this.getGeomteryByType(
          this.currentInterventionType
        );
      } else {
        this.lastGeomteryType = null;
      }

      if (!this.lastGeomteryType) {
        this.geometryTypes = [
          { title: "Linea", code: "line" },
          { title: "Punto", code: "points" },
          //{ title: "Poligono", code: "polygon" },
        ];

        this.lastGeomteryType = null;
      } else {
        if (this.lastGeomteryType === "LineString") {
          this.geometryTypes = [
            { title: "Linea", code: "line" },
            //{ title: "Poligono", code: "polygon" },
          ];

          this.lastGeomteryType = null;
        } else {
          this.geometryTypes = [
            { title: "Punto", code: "points" },
            //{ title: "Poligono", code: "polygon" },
          ];
          this.lastGeomteryType = null;
        }
      }
    } else {
      if (this.streetFeatures.length > 0) {
        const geom = this.streetFeatures[0].feature.getGeometry().getType();
        if (geom == "LineString") {
          this.geometryTypes = [{ title: "Linea", code: "line" }];
          this.lastGeomteryType = null;
        } else {
          this.geometryTypes = [
            { title: "Punto", code: "points" },
            //{ title: "Poligono", code: "polygon" },
          ];
          this.lastGeomteryType = null;
        }
      }
    }
  }

  closeModal() {
    this.geoService.emptyLoaded();
    this.layerReadySubscription.unsubscribe();
    this.layerLoadedSubscription.unsubscribe();
    this.listElementSubscription.unsubscribe();
    this.bsModalRef.hide();
    this.closeModalEmitter.emit(true);
  }

  delete($event: number) {
    console.log($event);
    this.canSave = true;
    const f = this.streetFeatures[$event];
    const index = this.featuresToMerge.indexOf(f);
    if (index >= 0) this.featuresToMerge.splice(index, 1);
    const intLayer = this.geoService.getLayerByTitle(
      this.map,
      this.INTERVENTIONS_LAYER_TITLE
    );
    if (f.saved) {
      this.deletedFeatureIds.push(f.feature.values_.gid);
      intLayer.getSource().removeFeature(f.feature);
      intLayer.getSource().changed();
      if (!this.currentIntervention.isMain) {
        if (intLayer.getSource().getFeatures().length === 0) {
          this.fillLayerDrawCombo();
          this.f.geometryChooser.enable();
          this.setGeometryComboValue();
        }
      }
      const iconLayer = this.geoService.getLayerByTitle(
        this.map,
        this.ICON_LAYER_TITLE
      );
      iconLayer.getSource().clear();
      this.geoService.createIconLayerBylayer(
        this.map,
        this.INTERVENTIONS_LAYER_TITLE,
        this.ICON_LAYER_TITLE,
        this.iconSavedLayerStyleFunction,
        false
      );
    } else {
      const drawingLayer = this.geoService.getLayerByTitle(
        this.map,
        this.DRAW_LAYER_TITLE
      );
      drawingLayer.getSource().removeFeature(f.feature);
      drawingLayer.getSource().changed();
      console.log("rimossa");
      if (!this.currentIntervention.isMain) {
        const features = drawingLayer.getSource().getFeatures();
        if (features.length === 0) {
          this.fillLayerDrawCombo();
          this.f.geometryChooser.enable();
          this.setGeometryComboValue();
        }
      }
      const iconLayer = this.geoService.getLayerByTitle(
        this.map,
        this.ICON_TEMP_LAYER_TITLE
      );
      iconLayer.getSource().clear();
      this.geoService.createIconLayerBylayer(
        this.map,
        this.DRAW_LAYER_TITLE,
        this.ICON_TEMP_LAYER_TITLE,
        this.iconTempLayerStyleFunction,
        true
      );
    }
    this.geoService.setZindexToLayer(this.map, this.ICON_LAYER_TITLE, 101);
    this.geoService.setZindexToLayer(this.map, this.ICON_TEMP_LAYER_TITLE, 93);

    if ($event >= 0) this.streetFeatures.splice($event, 1);
  }

  changeEditorMode() {
    console.log(this.geometryTypes);
    if (this.editor == "Editor") {
      this.editor = this.VIEWER_LAYER_TITLE;
      this.setVisualMode();
    } else {
      this.editor = "Editor";
      this.setEditorMode();
    }
  }

  setEditorMode() {
    this.arrowHidden = false;
    this.geoService.showLayerByTitle(this.map, this.INTERVENTIONS_LAYER_TITLE);
    this.geoService.setZindexToLayer(
      this.map,
      this.INTERVENTIONS_LAYER_TITLE,
      100
    );
    this.geoService.showLayerByTitle(this.map, this.ICON_LAYER_TITLE);
    this.geoService.setZindexToLayer(this.map, this.ICON_LAYER_TITLE, 101);
    this.geoService.hideLayerByTitle(this.map, this.VIEWER_LAYER_TITLE);
    this.geoService.hideLayerByTitle(this.map, this.ICON_VIEW_LAYER_TITLE);
    if (this.currentIntervention.isMain) {
      this.geoService.hideLayerByTitle(this.map, this.PROJECT_LAYER_TITLE);
    } else {
      this.geoService.showLayerByTitle(this.map, this.PROJECT_LAYER_TITLE);
    }
    $("#coordinate_interventocoordinate_intervento").prop("hidden", false);
    $("#view").prop("checked", false);
    $("#project").prop("checked", false);
    $("#coordinate_intervento").prop("checked", true);
    $("#viewview").prop("hidden", true);
    $("#projectproject").prop("hidden", true);
    const a = $("#layerslist").attr("class");
    if (a === "active") $("#geodraw").trigger("click");
    if (this.isInit) this.zoomToFeatures(this.INTERVENTIONS_LAYER_TITLE);
  }

  setVisualMode() {
    this.isDrawing = false;

    this.removeDrawingInteractions();
    this.divMsgOpen = false;
    this.arrowHidden = true;
    this.removeHighLight();
    this.isDrawing = false;
    $("#viewview").prop("hidden", false);
    $("#projectproject").prop("hidden", false);
    $("#view").prop("checked", true);
    $("#project").prop("checked", true);
    $("#coordinate_intervento").prop("checked", false);
    $("#coordinate_interventocoordinate_intervento").prop("hidden", true);

    this.geoService.showLayerByTitle(this.map, this.PROJECT_LAYER_TITLE);

    this.geoService.showLayerByTitle(this.map, this.VIEWER_LAYER_TITLE);

    this.geoService.setZindexToLayer(this.map, this.VIEWER_LAYER_TITLE, 98);
    this.geoService.showLayerByTitle(this.map, this.ICON_VIEW_LAYER_TITLE);
    this.geoService.setZindexToLayer(this.map, this.ICON_VIEW_LAYER_TITLE, 99);

    this.geoService.hideLayerByTitle(this.map, this.INTERVENTIONS_LAYER_TITLE);
    this.geoService.hideLayerByTitle(this.map, this.ICON_LAYER_TITLE);
    const projectFeatures = this.geoService
      .getLayerByTitle(this.map, "Progetto/Fabbisogno")
      .getSource()
      .getFeatures();
    const viewFeatures = this.geoService
      .getLayerByTitle(this.map, this.VIEWER_LAYER_TITLE)
      .getSource()
      .getFeatures();
    if (projectFeatures.length >= viewFeatures.length)
      this.zoomToFeatures("Progetto/Fabbisogno");
    else this.zoomToFeatures(this.VIEWER_LAYER_TITLE);
  }

  zoomToFeatures(titleLayer: string) {
    const layer = this.geoService.getLayerByTitle(this.map, titleLayer);
    const extent = layer.getSource().getExtent();
    if (layer.getSource().getFeatures().length > 0) {
      this.map.getView().fit(extent, this.map.getSize());
      if (
        layer.getSource().getFeatures().length === 1 &&
        layer.getSource().getFeatures()[0].getGeometry().getType() === "Point"
      ) {
        this.map.getView().setZoom(14);
      } else {
        this.map.getView().setZoom(this.map.getView().getZoom() - 1);
      }
    }
  }

  changeEyeStatus() {
    if (this.eyeStatus == "opened") this.eyeStatus = "closed";
    else this.eyeStatus = "opened";
  }

  mergeFeatures(
    firstFeature: MyStreetFeatures,
    secondFeature: MyStreetFeatures
  ) {
    if (this.featuresToMerge.length === 2) {
      const type1: string = firstFeature.feature.values_["type"];
      const type2: string = secondFeature.feature.values_["type"];

      if (type1 === type2) {
        if (!this.oldInterventions.includes(firstFeature))
          this.oldInterventions.push(firstFeature);
        if (!this.oldInterventions.includes(secondFeature))
          this.oldInterventions.push(secondFeature);
        const cloned1 = firstFeature.feature.clone();
        const cloned2 = secondFeature.feature.clone();

        const lineAB = cloned1
          .getGeometry()
          .transform(this.sourceSRS, this.destSRS)
          .getCoordinates();

        const lineCD = cloned2
          .getGeometry()
          .transform(this.sourceSRS, this.destSRS)
          .getCoordinates();

        const pointA = lineAB[0];
        const pointB = lineAB[lineAB.length - 1];

        const pointC = lineCD[0];
        const pointD = lineCD[lineCD.length - 1];

        const distanceAC: number = turf.distance(pointA, pointC);
        const distanceAD: number = turf.distance(pointA, pointD);
        const distanceBC: number = turf.distance(pointB, pointC);
        const distanceBD: number = turf.distance(pointB, pointD);

        const minDistance = Math.min(
          distanceAC,
          distanceAD,
          distanceBC,
          distanceBD
        );
        let shortestPath;
        switch (minDistance) {
          case distanceAC:
            shortestPath = turf.shortestPath(pointA, pointC);
            break;
          case distanceAD:
            shortestPath = turf.shortestPath(pointA, pointD);
            break;
          case distanceBC:
            shortestPath = turf.shortestPath(pointB, pointC);
            break;
          case distanceBD:
            shortestPath = turf.shortestPath(pointB, pointD);
            break;
        }

        const line1 = turf.lineString(lineAB);
        const line2 = turf.lineString(lineCD);
        const collection = turf.featureCollection([line1, shortestPath, line2]);
        const combined = turf.combine(collection);

        const features = new olFormat.GeoJSON({
          featureProjection: "EPSG:3857",
        }).readFeatures(combined);
        const mergeStyle = new Style({
          stroke: new olStyle.Stroke({
            color: "#942911", //"#f8e16c",
            width: 3, //spessore strade salvate
          }),
          fill: new olStyle.Fill({
            color: "rgba(0, 0, 255, 0.1)",
          }),
        });
        features[0].values_["merged"] = true;
        features[0].setStyle(mergeStyle);
        const intLayer = this.geoService.getLayerByTitle(
          this.map,
          this.INTERVENTIONS_LAYER_TITLE
        );
        const drawLayer = this.geoService.getLayerByTitle(
          this.map,
          this.DRAW_LAYER_TITLE
        );
        const drawSource = drawLayer.getSource();
        const sourceInterv = intLayer.getSource();
        if (firstFeature.saved) {
          sourceInterv.removeFeature(this.oldInterventions[0].feature);
          sourceInterv.changed();
        } else {
          if (
            drawSource
              .getFeatures()
              .indexOf(this.oldInterventions[0].feature) >= 0
          ) {
            drawSource.removeFeature(this.oldInterventions[0].feature);
            drawSource.changed();
          }
        }

        if (secondFeature.saved) {
          sourceInterv.removeFeature(this.oldInterventions[1].feature);
          sourceInterv.changed();
        } else {
          if (
            drawSource
              .getFeatures()
              .indexOf(this.oldInterventions[1].feature) >= 0
          ) {
            drawSource.removeFeature(this.oldInterventions[1].feature);
            drawSource.changed();
          }
        }

        if (firstFeature.feature.values_.gid) {
          this.deletedFeatureIds.push(firstFeature.feature.values_.gid);
        }
        if (secondFeature.feature.values_.gid) {
          this.deletedFeatureIds.push(secondFeature.feature.values_.gid);
        }
        sourceInterv.changed();

        features[0].values_["type"] = type1;

        drawSource.addFeature(features[0]);
        drawSource.changed();

        this.newFeat = {
          feature: features[0],
          saved: false,
          date: moment(new Date()),
        };
        this.merged = features[0];

        this.geoService.createIconLayerBylayer(
          this.map,
          this.INTERVENTIONS_LAYER_TITLE,
          this.ICON_LAYER_TITLE,
          this.iconSavedLayerStyleFunction,
          false
        );
        this.geoService.setZindexToLayer(this.map, this.ICON_LAYER_TITLE, 101);
        this.geoService.createIconLayerBylayer(
          this.map,
          this.DRAW_LAYER_TITLE,
          this.ICON_TEMP_LAYER_TITLE,
          this.iconTempLayerStyleFunction,
          true
        );
        this.geoService.setZindexToLayer(this.map, this.DRAW_LAYER_TITLE, 93);
        this.geoService.setZindexToLayer(
          this.map,
          this.ICON_TEMP_LAYER_TITLE,
          94
        );
        const source = new olSource.Vector({
          features: [features[0]],
        });
        const extent = source.getExtent();
        this.map.getView().fit(extent, this.map.getSize());
        this.map.getView().setZoom(this.map.getView().getZoom() - 1);
        this.canSave = true;
      } else {
        this.toastr.warning(`Non è possibile unire 2 elementi di diverso tipo`);
      }
    } else {
      this.toastr.warning(`Non è possibile unire più di 2 elementi alla volta`);
    }
  }

  toggleVisibility($event, title) {
    console.log($event.target.checked);
    console.log(title);

    if ($event.target.checked) {
      this.waiting = true;
      this.geoService.showLayer(
        this.map,
        this.conf.vectorLayerTitles,
        title,
        false
      );
      if (title === this.INTERVENTIONS_LAYER_TITLE)
        this.geoService.showLayerByTitle(this.map, this.ICON_LAYER_TITLE);
      if (title === this.VIEWER_LAYER_TITLE)
        this.geoService.showLayerByTitle(this.map, this.ICON_VIEW_LAYER_TITLE);
    } else {
      if (title === this.INTERVENTIONS_LAYER_TITLE)
        this.geoService.hideLayerByTitle(this.map, this.ICON_LAYER_TITLE);
      if (title === this.VIEWER_LAYER_TITLE)
        this.geoService.hideLayerByTitle(this.map, this.ICON_VIEW_LAYER_TITLE);
      this.geoService.hideLayerByTitle(this.map, title);
    }
  }

  highlightIconStyleFunction(feature, resolution) {
    if (feature) {
      const type = feature.get("type");
      let imageUrl;

      switch (type) {
        case "road":
          imageUrl = "assets/img/roadSelected.png";
          break;
        case "rail":
          imageUrl = "assets/img/railSelected.png";
          break;
        case "metro":
          imageUrl = "assets/img/metroSelected.png";
          break;
        case "bike":
          imageUrl = "assets/img/bikeSelected.png";
          break;
        case "parkland":
          imageUrl = "assets/img/parchiSelected.png";
          break;
        case "emobility":
          imageUrl = "assets/img/emobilitySelected.png";
          break;
        case "skilift":
          imageUrl = "assets/img/funiviaSelected.png";
          break;
        case "bonifiche_lines":
          imageUrl = "assets/img/bonifiche_territorio_lineeSelected.png";
          break;
        case "points":
          imageUrl = "assets/img/scuolaSelected.png";
          break;
        case "port":
          imageUrl = "assets/img/portoSelected.png";
          break;
        case "metrostop":
          imageUrl = "assets/img/metrostationSelected.png";
          break;
        case "station":
          imageUrl = "assets/img/stationSelected.png";
          break;

        case "other_lines":
          imageUrl = "assets/img/altre-vie-comunicazioneSelected.png";
          break;
        case "other_points":
          imageUrl = "assets/img/buildingSelected.png";
          break;
        case "park":
          imageUrl = "assets/img/parcheggiSelected.png";
          break;
        case "nodes":
          imageUrl = "assets/img/nodiSelected.png";
          break;
        case "asl":
          imageUrl = "assets/img/aslSelected.png";
          break;
        case "plants":
          imageUrl = "assets/img/plantsSelected.png";
          break;
        case "networks_satellites":
          imageUrl = "assets/img/networks_satellitesSelected.png";
          break;
        case "bonifiche_points":
          imageUrl = "assets/img/bonifiche_territorio_puntiSelected.png";
          break;

        case "cimiteri":
          imageUrl = "assets/img/cimiteriSelected.png";
          break;
        case "musei_cultura":
          imageUrl = "assets/img/musei_culturaSelected.png";
          break;

        case "luoghi_di_culto":
          imageUrl = "assets/img/luoghi_di_cultoSelected.png";
          break;
        case "hospitals":
          imageUrl = "assets/img/hospitalSelected.png";
          break;

        case "aeroporti":
          imageUrl = "assets/img/aeroportiSelected.png";
          break;
        case "impianti_sportivi":
          imageUrl = "assets/img/impianti_sportiviSelected.png";
          break;
        case "interventi_sul_sociale":
          imageUrl = "assets/img/interventi_sul_socialeSelected.png";
          break;
        case "stazioni_autolinee":
          imageUrl = "assets/img/stazioni_autolineeSelected.png";
          break;
        case "impianti_energie_rinnovabili":
          imageUrl = "assets/img/impianti_energie_rinnovabiliSelected.png";
          break;
          case "opere_urbanizzazione":
            imageUrl = "assets/img/opere_urbanizzazioneSelected.png";
            break;

        default:
          imageUrl = "assets/img/Blue.png";
      }

      let style = new Style({
        zIndex: Infinity,
        image: new olStyle.Icon({
          src: imageUrl,
        }),
      });
      return style;
    }
  }

  undoMerge(feature) {
    let deleteIndex = -1;
    if (this.featuresToMerge[0]) {
      this.featuresToMerge[0].feature.setStyle(undefined);
      deleteIndex = this.deletedFeatureIds.indexOf(
        this.featuresToMerge[0].feature.values_.gid
      );
      if (deleteIndex >= 0) {
        this.deletedFeatureIds.splice(deleteIndex, 1);
      }
    }
    if (this.featuresToMerge[1]) {
      this.featuresToMerge[1].feature.setStyle(undefined);
      deleteIndex = this.deletedFeatureIds.indexOf(
        this.featuresToMerge[1].feature.values_.gid
      );
      if (deleteIndex >= 0) {
        this.deletedFeatureIds.splice(deleteIndex, 1);
      }
    }
    this.showMergeButton = false;

    const indexDraws = this.highlightedDraws.indexOf(feature);
    if (indexDraws >= 0) {
      this.highlightedDraws.splice(indexDraws, 1);
    }

    const index = this.featuresToMerge.indexOf(feature);
    let selected = this.featuresToMerge[index];
    if (index > -1) {
      this.featuresToMerge.splice(index, 1);
    }
    const drawLayer = this.geoService.getLayerByTitle(
      this.map,
      this.DRAW_LAYER_TITLE
    );
    const drawSource = drawLayer.getSource();
    if (this.merged) {
      const intLayer = this.geoService.getLayerByTitle(
        this.map,
        this.INTERVENTIONS_LAYER_TITLE
      );
      const sourceInterv = intLayer.getSource();
      drawSource.removeFeature(this.merged);
      if (this.oldInterventions[0].saved) {
        sourceInterv.addFeature(this.oldInterventions[0].feature);
        sourceInterv.changed();
      } else {
        drawSource.addFeature(this.oldInterventions[0].feature);
        drawSource.changed();
      }
      if (this.oldInterventions[1].saved) {
        sourceInterv.addFeature(this.oldInterventions[1].feature);
        sourceInterv.changed();
      } else {
        drawSource.addFeature(this.oldInterventions[1].feature);
        drawSource.changed();
      }
      const indexDeleteOld = this.oldInterventions.indexOf(selected);
      if (indexDeleteOld >= 0) this.oldInterventions.splice(indexDeleteOld, 1);

      this.merged = null;
      this.newFeat = null;
      this.geoService.createIconLayerBylayer(
        this.map,
        this.DRAW_LAYER_TITLE,
        this.ICON_TEMP_LAYER_TITLE,
        this.iconTempLayerStyleFunction,
        true
      );
      this.geoService.setZindexToLayer(this.map, this.DRAW_LAYER_TITLE, 93);
      this.geoService.setZindexToLayer(
        this.map,
        this.ICON_TEMP_LAYER_TITLE,
        94
      );
      this.geoService.createIconLayerBylayer(
        this.map,
        this.INTERVENTIONS_LAYER_TITLE,
        this.ICON_LAYER_TITLE,
        this.iconSavedLayerStyleFunction,
        false
      );
      this.geoService.setZindexToLayer(this.map, this.ICON_LAYER_TITLE, 101);
    }

    if (drawSource.getFeatures().length > 0) {
      this.canSave = true;
    } else {
      this.canSave = false;
    }
  }

  removeHighLightDraws() {
    if (this.highlightedDraws.length > 0) {
      this.highlightedDraws.forEach((f) => {
        f.setStyle(undefined);
      });
      this.highlightedIconsDraws.forEach((i) => {
        i.setStyle(undefined);
      });
    }
    this.highlightedDraws = [];
    this.highlightedIconsDraws = [];
  }

  zoomToHighLightedFeatures() {
    const features = [];
    this.highlightedDraws.forEach((element) => {
      if (!features.includes(element.feature)) features.push(element.feature);
    });

    if (features.length > 0) {
      const source = new olSource.Vector({
        features: [...features],
      });

      const geometry = features[0].getGeometry().getType();

      if (features.length === 1 && geometry === "Point") {
        const extent = source.getExtent();
        this.map.getView().fit(extent, this.map.getSize());
        this.map.getView().setZoom(14);
      } else {
        const extent = source.getExtent();
        this.map.getView().fit(extent, this.map.getSize());
        this.map.getView().setZoom(this.map.getView().getZoom() - 1);
      }
    }
  }

  nuovaFunzioneDiSelezioneEUnione($event, element) {
    const selected = element;
    const index = this.streetFeatures.indexOf(selected);
    const checked = $(`#checkMerge${index}`).is(":checked");
    const isSelected = $(`#element${index}`).hasClass("text-33cccc");
    const isMerging = $(`#element${index}`).hasClass("color-merge");

    if (this.featuresToMerge.length >= 2 && checked && !isMerging) {
      $(`#checkMerge${index}`).prop("checked", false);

      this.toastr.warning("Hai già selezionato 2 elementi.");
    }

    if (this.featuresToMerge.length < 2 && checked) {
      //metto classe seleziona e aggiungo feature per merge e metto lo zoom sull elemento
      $(`#element${index}`).addClass("text-33cccc");
      if (!this.featuresToMerge.includes(selected))
        this.featuresToMerge.push(selected);
      if (!this.highlightedDraws.includes(selected))
        this.highlightedDraws.push(selected);
      //this.zoomToHighLightedFeatures();
      if (this.featuresToMerge.length == 2) {
        //faccio l'unione
        let indexMerge = this.streetFeatures.indexOf(this.featuresToMerge[0]);
        $(`#element${indexMerge}`).addClass("color-merge");
        indexMerge = this.streetFeatures.indexOf(this.featuresToMerge[1]);
        $(`#element${indexMerge}`).addClass("color-merge");
        const type1: string = this.featuresToMerge[0].feature.values_["type"];
        const type2: string = this.featuresToMerge[1].feature.values_["type"];
        if (type1 === type2) {
          this.mergeFeatures(this.featuresToMerge[0], this.featuresToMerge[1]);
        } else {
          //stoppo il merge
          let indexUnMerge = this.streetFeatures.indexOf(
            this.featuresToMerge[1]
          );
          $(`#element${indexUnMerge}`).removeClass("color-merge");
          $(`#element${indexUnMerge}`).removeClass("text-33cccc");
          $(`#checkMerge${indexUnMerge}`).prop("checked", false);
          indexUnMerge = this.highlightedDraws.indexOf(selected);
          if (indexUnMerge >= 0) {
            this.highlightedDraws.splice(indexUnMerge, 1);
          }
          indexUnMerge = this.streetFeatures.indexOf(this.featuresToMerge[0]);
          $(`#element${indexUnMerge}`).removeClass("color-merge");
          if (indexUnMerge >= 0) this.featuresToMerge.splice(1, 1);
          this.toastr.warning(
            `Non è possibile unire 2 elementi di diverso tipo`
          );
        }
      }
    }

    if (!checked && isMerging) {
      //annullo il merge
      let indexUnMerge = this.streetFeatures.indexOf(this.featuresToMerge[0]);
      $(`#element${indexUnMerge}`).removeClass("color-merge");
      indexUnMerge = this.streetFeatures.indexOf(this.featuresToMerge[1]);
      $(`#element${indexUnMerge}`).removeClass("color-merge");
      $(`#element${index}`).removeClass("text-33cccc");
      this.undoMerge(selected);
    }

    if (!checked && !isSelected) {
      //seleziono l elemento ma non lo aggiungo alle feature da mergiare
      $(`#element${index}`).addClass("text-33cccc");
      if (!this.highlightedDraws.includes(selected))
        this.highlightedDraws.push(selected);
    }

    if (!checked && isSelected && !isMerging) {
      //tolgo la selezione dall'elemento e rimuovo la feature da mergiare e resetto stile di liea e icona
      $(`#element${index}`).removeClass("text-33cccc");
      let indexDelete = this.highlightedDraws.indexOf(selected);
      this.highlightedDraws[indexDelete].feature.setStyle(undefined);
      if (indexDelete >= 0) {
        this.highlightedDraws.splice(indexDelete, 1);
      }
      indexDelete = this.featuresToMerge.indexOf(selected);
      if (indexDelete >= 0) {
        this.featuresToMerge.splice(indexDelete, 1);
      }
      if (this.highlightedIconsDraws.length > 0) {
        if (selected.saved) {
          this.highlightedIconsDraws.forEach((icon) => {
            if (icon.values_.gid === selected.feature.values_.gid) {
              icon.setStyle(undefined);
              const index = this.highlightedIconsDraws.indexOf(icon);
              if (index >= 0) {
                this.highlightedIconsDraws.splice(index, 1);
              }
            }
          });
        } else {
          //icone verdi
          this.highlightedIconsDraws.forEach((icon) => {
            if (icon.values_.gid === selected.feature.ol_uid) {
              icon.setStyle(undefined);
              const index = this.highlightedIconsDraws.indexOf(icon);
              if (index >= 0) {
                this.highlightedIconsDraws.splice(index, 1);
              }
            }
          });
        }
      }
    }
    let that = this;
    //illumino le feature
    if (
      this.highlightedFeatures.length === 0 &&
      this.highlightedIconsDraws.length > 0
    ) {
      this.highlightedIconsDraws.forEach((i) => {
        i.setStyle(undefined);
      });
    }
    this.highlightedDraws.forEach((element) => {
      element.feature.setStyle(this.highlightStyle);

      let iconLayer;
      if (element.saved) {
        iconLayer = this.geoService.getLayerByTitle(
          this.map,
          this.ICON_LAYER_TITLE
        );
        if (iconLayer) {
          const iconSource = iconLayer.getSource();
          iconSource.changed();
          const icons = iconSource.getFeatures();

          icons.forEach((i) => {
            if (i.values_.gid === element.feature.values_["gid"]) {
              that.highlightedIconsDraws.push(i);
              i.setStyle(that.highlightIconStyleFunction);
            }
          });
        }
      } else {
        iconLayer = this.geoService.getLayerByTitle(
          this.map,
          this.ICON_TEMP_LAYER_TITLE
        );
        if (iconLayer) {
          const iconSource = iconLayer.getSource();

          const icons = iconSource.getFeatures();

          icons.forEach((i) => {
            if (i.values_.gid === element.feature.ol_uid) {
              that.highlightedIconsDraws.push(i);
              i.setStyle(that.highlightIconStyleFunction);
            }
          });
        }
      }
    });
  }

  highlightIntervent($event, intervention, selectedInterventionIndex) {
    console.log($event);
    if (
      !$(`#intervention${selectedInterventionIndex}`).hasClass("text-33cccc")
    ) {
      this.removeHighLight();
      const id = intervention.id;
      const viewLayer = this.geoService.getLayerByTitle(
        this.map,
        this.VIEWER_LAYER_TITLE
      );
      for (let i = 0; i < this.allInterventionsWithStatus.length; i++) {
        if (this.allInterventionsWithStatus[i].id == id) {
          $(`#intervention${i}`).addClass("text-33cccc");
        } else {
          $(`#intervention${i}`).removeClass("text-33cccc");
        }
      }
      const iconLayer = this.geoService.getLayerByTitle(
        this.map,
        this.ICON_VIEW_LAYER_TITLE
      );
      let features = viewLayer.getSource().getFeatures();
      const icons = iconLayer.getSource().getFeatures();

      icons.forEach((i) => {
        if (i.values_.intervento_id === id) this.highlightedIcons.push(i);
      });

      features.forEach((f) => {
        if (f.values_.intervento_id === id) this.highlightedFeatures.push(f);
      });
      this.highlightedFeatures.forEach((sf) => {
        sf.setStyle(this.highlightStyle);
      });
      this.highlightedIcons.forEach((i) => {
        i.setStyle(this.highlightIconStyleFunction);
      });

      const source = new olSource.Vector({
        features: this.highlightedFeatures,
      });
      const extent = source.getExtent();
      this.map.getView().fit(extent, this.map.getSize());
      if (
        this.highlightedFeatures.length === 1 &&
        this.highlightedFeatures[0].getGeometry().getType() === "Point"
      ) {
        this.map.getView().setZoom(14);
      } else {
        this.map.getView().setZoom(this.map.getView().getZoom() - 1);
      }
    } else {
      this.removeHighLight();
    }
    $event.preventDefault();
    $event.stopPropagation();
  }

  removeHighLight() {
    if (this.highlightedFeatures.length > 0) {
      // this.map.getView().setCenter(olProj.fromLonLat([12.5, 41.9]));
      // this.map.getView().setZoom(8.7);
      this.highlightedFeatures.forEach((f) => {
        f.setStyle(undefined);
      });
      this.highlightedIcons.forEach((i) => {
        i.setStyle(undefined);
      });
      for (let i = 0; i < this.allInterventionsWithStatus.length; i++) {
        $(`#intervention${i}`).removeClass("text-33cccc");
      }
    }
    this.highlightedFeatures = [];
    this.highlightedIcons = [];
  }

  getDrawedLength() {
    return this.allInterventionsWithStatus.filter((i) => i.drawed).length;
  }

  printValueAsCurrency(value) {
    //return this.italianCurrencyPipe.parse(value) + " €";
    value = typeof value === "number" ? value : value.replace(",", ".").trim();
    const format = "1.2-2";
    const currency = "€";
    const currentLocale: string = "it";
    return new CurrencyPipe(currentLocale).transform(
      value,
      "EUR",
      currency,
      format,
      "it-IT"
    );
  }

  chooseBasemap($event) {
    const oldMap = this.geoService.getLayerByTitle(this.map, "basemap");
    let newMap;

    this.geoService.setZindexToLayer(this.map, "Province", 33);
    switch ($event.code) {
      case "carto":
        this.map.removeLayer(oldMap);
        this.map.addLayer(this.geoService.getCartoDbLayer());
        this.attribution = {
          url: "https://www.openstreetmap.org/copyright",
          text1: "© OpenStreetMap",
          text2: "contributors.",
        };
        break;
      case "gstreet":
        this.map.removeLayer(oldMap);
        this.map.addLayer(this.geoService.getGoogleLayer("street"));
        this.attribution = {
          url: "https://maps.google.it",
          text1: "© Google",
          text2: "",
        };
        break;
      case "gsat":
        this.map.removeLayer(oldMap);
        this.map.addLayer(this.geoService.getGoogleLayer("sat"));
        this.attribution = {
          url: "https://maps.google.it",
          text1: "© Google",
          text2: "",
        };
        break;
      case "gterrain":
        this.map.removeLayer(oldMap);
        this.map.addLayer(this.geoService.getGoogleLayer("terrain"));
        this.attribution = {
          url: "https://maps.google.it",
          text1: "© Google",
          text2: "",
        };
        break;
      case "ghybrid":
        this.map.removeLayer(oldMap);
        this.map.addLayer(this.geoService.getGoogleLayer("hybrid"));
        this.attribution = {
          url: "https://maps.google.it",
          text1: "© Google",
          text2: "",
        };
        break;
      case "toner":
        this.map.removeLayer(oldMap);
        this.map.addLayer(this.geoService.getStamenLayer("toner"));
        this.attribution = {
          url: "http://maps.stamen.com",
          text1: "© Stamen",
          text2: "",
        };
        break;
      case "watercolor":
        this.map.removeLayer(oldMap);
        this.map.addLayer(this.geoService.getStamenLayer("watercolor"));
        this.attribution = {
          url: "http://maps.stamen.com",
          text1: "© Stamen",
          text2: "",
        };
        break;
    }
  }
  setGeometry($event) {
    console.log($event.code);
    if ($event.code !== "line") {
      this.showFreehandChooser = false;

      this.removeDrawingInteractions();

      this.map.addInteraction(this.snapInteraction);
      this.filterDrawingAndKmzLayersbyGeometry("Point");
    } else {
      this.removeDrawingInteractions();
      this.map.addInteraction(this.drawStreetInteraction);
      this.map.addInteraction(this.snapInteraction);
      this.showFreehandChooser = true;
      this.filterDrawingAndKmzLayersbyGeometry("LineString");
    }

    this.isDrawing = false;
    this.drawingType = "proj";
  }

  removeDrawingInteractions() {
    this.map.getInteractions().forEach((interaction) => {
      if (interaction instanceof olInteractions.Draw) {
        this.map.removeInteraction(interaction);
      }
    });
  }

  removeSnapInteractions() {
    this.map.getInteractions().forEach((interaction) => {
      if (interaction instanceof olInteractions.Snap) {
        this.map.removeInteraction(interaction);
      }
    });
  }

  countSnapInteractions() {
    let count = 0;
    this.map.getInteractions().forEach((interaction) => {
      if (interaction instanceof olInteractions.Snap) {
        count++;
      }
    });
    console.log(`snaps: ${count}`);
  }

  showHideIntervention(intervention: any) {
    const viewLayer = this.geoService.getLayerByTitle(
      this.map,
      this.VIEWER_LAYER_TITLE
    );
    const features = viewLayer.getSource().getFeatures();
    const iconLayer = this.geoService.getLayerByTitle(
      this.map,
      this.ICON_VIEW_LAYER_TITLE
    );
    const iconFeatures = iconLayer.getSource().getFeatures();

    const selectedFeatures = features.filter(
      (f) => f.values_.intervento_id === intervention.id
    );
    const selectedIcons = iconFeatures.filter(
      (f) => f.values_.intervento_id === intervention.id
    );

    selectedFeatures.forEach((f) => {
      if (intervention.visible) {
        f.setStyle(new olStyle.Style({}));
        f.setStyle(new olStyle.Style({}));
      } else {
        f.setStyle(undefined);
      }
    });
    viewLayer.getSource().changed();
    selectedIcons.forEach((i) => {
      if (intervention.visible) {
        i.setStyle(new olStyle.Style({}));
      } else {
        i.setStyle(undefined);
      }
    });
    iconLayer.getSource().changed();
    intervention.visible = !intervention.visible;
    this.isAllHidden = this.checkIfAllHidden();
    console.log(`is all hidden: ${this.isAllHidden}`);
  }

  checkIfAllHidden() {
    const visibleInterventions = this.allInterventionsWithStatus.filter(
      (i) => i.drawed && i.visible && !i.isMain
    );

    if (visibleInterventions.length === 0) return true;
    else return false;
  }

  showHideAllIntervention() {
    const allDrawed = this.allInterventionsWithStatus.filter(
      (i) => i.drawed && !i.isMain
    );
    if (this.isAllHidden) {
      allDrawed.forEach((i) => {
        this.showHideIntervention(i);
      });
    } else {
      allDrawed.forEach((i) => {
        i.visible = true;
        this.showHideIntervention(i);
      });
    }
  }

  /**
   * FILTRI
   */
  getFeatures($event: any) {
    console.log($event);
    this.selectedFilters = [];
    const fileterdProps = [];
    this.selectedLayer = $event;
    if ($event.getSource().getFeatures().length > 0) {
      const props: string[] = $event.getSource().getFeatures()[0].getKeys();
      let i;
      for (i = 0; i <= props.length; i++) {
        console.log(props[i]);
        fileterdProps.push({ key: props[i] });
      }
    }
    this.layerProperties = [...fileterdProps];
    this.f.propertyChooser.setValue(null);
    this.f.valueChooser.setValue(null);
    this.f.propertyChooser.enable();
  }

  getFeatureValues($event: any) {
    this.selectedProperty = $event;
    let features;

    features = this.selectedLayer.getSource().getFeatures();

    this.f.valueChooser.setValue(null);
    const values = [];
    let i;
    for (i = 0; i <= features.length; i++) {
      if (features[i] && features[i].values_) {
        const v = { key: features[i].values_[`${$event.key}`] };

        values.push(v);
      }
    }
    //unique values
    this.propertiesValues = [
      ...new Set(
        values.map((element) => (element.key ? element.key : "Non disponibile"))
      ),
    ];
    this.f.valueChooser.enable();
    console.log($event);
  }

  setFilter($event: any) {
    if ($event) {
      // if (!this.currentFilter) {
      if ($event.length == 1) {
        this.currentFilter = $event
          ? `${this.selectedProperty.key}='${$event}'`
          : "";
      } else {
        this.currentFilter = `${this.selectedProperty.key}='${$event[0]}'`;
        for (let index = 1; index < $event.length; index++) {
          this.currentFilter += `AND ${this.selectedProperty.key}='${$event[index]}'`;
        }
      }
    }
    console.log($event);
  }

  addFilter() {
    if (this.currentFilter) {
      this.selectedFilters.push(this.currentFilter);
      this.selectedFilters = [...new Set(this.selectedFilters)];
      this.currentFilter = null;
      this.f.valueChooser.setValue(null);
    }
  }

  applyFilters() {
    const layerName = this.selectedLayer
      .getSource()
      .getFeatures()[0]
      .id_.split(".")[0];

    const chainedFilter = this.geoService.chainFiltersInOR(
      this.selectedFilters
    );

    const filter = this.geoService.serializeCqlFilter(chainedFilter);
    this.geoService
      .getGeoJson(
        `${AppConfigService.env.geo_store}:${layerName}`,
        "EPSG:4326",
        undefined,
        filter
      )
      .subscribe((res) => {
        this.geoService.hideAllLayerExcept(this.map, "Filtrato");
        const layer = this.geoService.createLayerFromGeoJson(
          this.map,
          res,
          "Filtrato",
          this.filterStyle,
          null,
          false,
          false
        );
        const extent = layer.getSource().getExtent();
        this.map.getView().fit(extent, this.map.getSize());
      });
  }

  removeFilters() {
    this.selectedFilters = [];
    this.geoService.showAllLayerExcept(this.map, "Filtrato");
  }

  //geocoding
  searchPlaces() {
    const address = this.f.geocodeSearchAddress.value
      ? this.f.geocodeSearchAddress.value
      : "-";
    const geocodeRequest =
      `https://nominatim.openstreetmap.org/search.php?q=` +
      address +
      "&addressdetails=1&countrycodes=it&format=jsonv2";

    this.callRestService(geocodeRequest, this.geocodeCallback);
  }

  callRestService(request, callback) {
    const that = this;
    $.ajax({
      url: request,
      //dataType: "jsonp",
      //jsonp: "jsonp",
      success: function (r) {
        callback(r, that);
      },
      error: function (e) {
        alert(e.statusText);
      },
    });
  }

  geocodeCallback(result, that) {
    if (result.length > 0) {
      that.getPlaces(result, that);
    }
  }

  getPlaces(places, that) {
    that.geocodedPlaces = [];
    places.forEach((p) => {
      let place = new GeocodedPlace();
      if (p.importance > 0.1) {
        place.name = `${p.display_name}`;
        place.coordinates = [p.lat, p.lon];
        place.confidence = p.importance;
        that.geocodedPlaces.push(place);
      }
    });
    if (places.length > 0) {
      that.isGeocodeHidden = false;
    }
    console.log(places);
  }

  centerMap(place) {
    console.log(
      "Long: " + place.coordinates[1] + " Lat: " + place.coordinates[0]
    );
    this.isGeocodeHidden = true;
    this.map
      .getView()
      .setCenter(
        olProj.transform(
          [place.coordinates[1], place.coordinates[0]],
          "EPSG:4326",
          "EPSG:3857"
        )
      );
    this.map.getView().setZoom(21);
  }

  redrawInterventionLayer(draws) {
    const intLayer = this.geoService.getLayerByTitle(
      this.map,
      this.INTERVENTIONS_LAYER_TITLE
    );
    const projLayer = this.geoService.getLayerByTitle(
      this.map,
      this.PROJECT_LAYER_TITLE
    );
    this.deletedFeatureIds = [];
    intLayer
      .getSource()
      .getFeatures()
      .forEach((f) => {
        this.deletedFeatureIds.push(f.values_.gid);
      });

    intLayer.getSource().clear();
    intLayer.getSource().changed();
    const features = [];

    if (draws.length > 0) {
      draws.forEach((d) => {
        let feature;
        if (d.geometria.type == "LineString") {
          let line = new olGeom.LineString(d.geometria.coordinates);
          feature = new Feature(line);
        } else {
          let point = new olGeom.Point(d.geometria.coordinates);
          feature = new Feature(point);
        }
        feature.values_["type"] = d.type;
        feature.getGeometry().transform("EPSG:4326", "EPSG:3857");
        features.push(feature);
      });
    }
    intLayer.getSource().addFeatures(features);
    intLayer.getSource().changed();

    projLayer.getSource().clear();
    projLayer.getSource().changed();
    projLayer.getSource().addFeatures(features);
    projLayer.getSource().changed();

    this.geoService.createIconLayerBylayer(
      this.map,
      this.INTERVENTIONS_LAYER_TITLE,
      this.ICON_LAYER_TITLE,
      this.iconSavedLayerStyleFunction,
      false
    );
    this.geoService.setZindexToLayer(this.map, this.ICON_LAYER_TITLE, 101);

    this.streetFeatures = [...[]];
    intLayer
      .getSource()
      .getFeatures()
      .forEach((f) => {
        const myFeature: MyStreetFeatures = {
          feature: f,
          saved: true,
          date: moment(new Date()),
        };
        if (!this.streetFeatures.includes(myFeature))
          this.streetFeatures.push(myFeature);
      });
    this.streetFeatures.sort((a, b) => {
      return new Date(b.date).valueOf() - new Date(a.date).valueOf();
    });
    this.toastr.success("Geometrie copiate con successo");
  }

  cloneElement() {
    if (this.currentIntervention.isMain)
      this.geoService
        .cloneAllDrawsIntoProject(this.currentIntervention.progettoId)
        .subscribe((draws) => {
          if (draws.length) this.redrawInterventionLayer(draws);
          else {
            this.toastr.warning(
              "Nessun intervento localizzato, impossibile copiare da interventi"
            );
          }
        });
    else {
      this.geoService
        .cloneProjectIntoIntervention(this.currentIntervention.id)
        .subscribe((res) => {
          if (res.length) this.redrawInterventionLayer(res);
          else {
            this.toastr.warning(
              "Tipologia intervento non coerente con il progetto, impossibile copiare da progetto"
            );
          }
        });
    }
  }

  getGeomteryByType(type) {
    switch (type) {
      case "rail":
      case "road":
      case "bike":
      case "skilift":
      case "bonifiche_lines":
      case "metro":
      case "other_lines":
        return "LineString";
      default:
        return "Point";
    }
  }

  getKMLData(buffer) {
    const zip: JSZip = new JSZip();
    let that = this;
    zip.loadAsync(buffer).then((zip) => {
      // <----- HERE
      Object.keys(zip.files).forEach((filename) => {
        // <----- HERE
        zip.files[filename].async("string").then((fileData) => {
          // <----- HERE
          console.log(fileData);
          const features = new olFormat.KML({
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857",
          }).readFeatures(fileData);

          const geometryFeature = features[0].getGeometry().getType();

          const type = this.kmzType;
          this.currentKMZInterventionType = type;
          const selectedLayerGeometry = this.getGeomteryByType(type);
          if (geometryFeature != selectedLayerGeometry) {
            this.canSave = true;
            //alert("errore");
            this.toastr.error(`Geometria KMZ non coerente.`);
            return;
          }
          features.forEach((f) => {
            f.values_["type"] = type;
            const myFeature: MyStreetFeatures = {
              feature: f,
              saved: false,
              date: f.values_.date,
            };
            that.streetFeatures.push(myFeature);
            const drawLayer = that.geoService.getLayerByTitle(
              that.map,
              that.DRAW_LAYER_TITLE
            );
            f.setStyle(undefined);
            f.getGeometry().transform("EPSG:4326", "EPSG:3857");
            drawLayer.getSource().addFeature(f);
            drawLayer.getSource().changed();

            ////

            if (
              this.currentIntervention.isMain &&
              this.currentIntervention.numSubInterventi > 0
            ) {
              this.layersForDrawing = [...this.allDrawingLayers];
              this.layersForKmz = [...this.allDrawingLayers].filter(
                (l) => l.vectorLayerType !== "proj"
              );
            } else {
              if (
                !this.currentIntervention.isMain &&
                this.currentInterventionType
              ) {
                this.drawingType = this.kmzType;

                if (!this.isComboLayerFiltered) {
                  this.f.geometryChooser.disable();
                  if (!this.isComboLayerFiltered) {
                    this.filterDrawingAndKmzLayers();
                    this.isComboLayerFiltered = true;
                  }
                }
              }
            }

            ///
            this.canSave = true;
          });

          this.geoService.createIconLayerBylayer(
            this.map,
            this.DRAW_LAYER_TITLE,
            this.ICON_TEMP_LAYER_TITLE,
            this.iconTempLayerStyleFunction,
            true
          );
          this.myForm.controls.kmzTypeChooser.setValue(null);
          return features;
        });
      });
    });
  }

  uploadKmz() {
    this.kmzFile.file && this.getKMLData(this.kmzFile.file);
  }

  setKMZtype(type) {
    this.kmzType = type.vectorLayerType;
  }

  checkKmzType() {
    if (
      this.currentIntervention.isMain &&
      this.currentIntervention.numSubInterventi > 0
    ) {
      //progetto
      this.layersForKmz = [...this.allDrawingLayers].filter(
        (l) => l.vectorLayerType !== "proj"
      );
    } else {
      //intervento

      this.layersForKmz = this.layersForKmz.filter((l) => {
        return l.vectorLayerType === this.drawingType;
      });
    }
  }

  filterDrawingAndKmzLayersbyGeometry(geom) {
    this.layersForDrawing = this.allDrawingLayers.filter((l) => {
      if (geom == "LineString") {
        return (
          l.vectorLayerType === "rail" ||
          l.vectorLayerType === "road" ||
          l.vectorLayerType === "bike" ||
          l.vectorLayerType === "skilift" ||
          l.vectorLayerType === "bonifiche_lines" ||
          l.vectorLayerType === "metro" ||
          l.vectorLayerType === "other_lines" ||
          l.vectorLayerType === "proj"
        );
      } else {
        return (
          l.vectorLayerType === "points" ||
          l.vectorLayerType === "parkland" ||
          l.vectorLayerType === "emobility" ||
          l.vectorLayerType === "port" ||
          l.vectorLayerType === "metrostop" ||
          l.vectorLayerType === "park" ||
          l.vectorLayerType === "nodes" ||
          l.vectorLayerType === "asl" ||
          l.vectorLayerType === "asl" ||
          l.vectorLayerType === "plants" ||
          l.vectorLayerType === "networks_satellites" ||
          l.vectorLayerType === "bonifiche_points" ||
          l.vectorLayerType === "cimiteri" ||
          l.vectorLayerType === "aeroporti" ||
          l.vectorLayerType === "musei_cultura" ||
          l.vectorLayerType === "luoghi_di_culto" ||
          l.vectorLayerType === "hospitals" ||
          l.vectorLayerType === "other_points" ||
          l.vectorLayerType === "proj" ||
          l.vectorLayerType === "impianti_sportivi" ||
          l.vectorLayerType === "interventi_sul_sociale" ||
          l.vectorLayerType === "stazioni_autolinee" ||
          l.vectorLayerType === "impianti_energie_rinnovabili" ||
          l.vectorLayerType === "opere_urbanizzazione"
        );
      }
    });

    this.layersForKmz = this.layersForDrawing.filter((l) => {
      if (geom == "LineString") {
        return (
          l.vectorLayerType === "rail" ||
          l.vectorLayerType === "road" ||
          l.vectorLayerType === "bike" ||
          l.vectorLayerType === "skilift" ||
          l.vectorLayerType === "bonifiche_lines" ||
          l.vectorLayerType === "metro" ||
          l.vectorLayerType === "other_lines"
        );
      } else {
        return (
          l.vectorLayerType === "points" ||
          l.vectorLayerType === "parkland" ||
          l.vectorLayerType === "emobility" ||
          l.vectorLayerType === "port" ||
          l.vectorLayerType === "metrostop" ||
          l.vectorLayerType === "park" ||
          l.vectorLayerType === "nodes" ||
          l.vectorLayerType === "asl" ||
          l.vectorLayerType === "plants" ||
          l.vectorLayerType === "networks_satellites" ||
          l.vectorLayerType === "bonifiche_points" ||
          l.vectorLayerType === "cimiteri" ||
          l.vectorLayerType === "luoghi_di_culto" ||
          l.vectorLayerType === "hospitals" ||
          l.vectorLayerType === "musei_cultura" ||
          l.vectorLayerType === "aeroporti" ||
          l.vectorLayerType === "station" ||
          l.vectorLayerType === "other_points" ||
          l.vectorLayerType === "impianti_sportivi" ||
          l.vectorLayerType === "interventi_sul_sociale" ||
          l.vectorLayerType === "stazioni_autolinee" ||
          l.vectorLayerType === "impianti_energie_rinnovabili" ||
          l.vectorLayerType === "opere_urbanizzazione"
        );
      }
    });
    console.log("qui");
  }

  filterDrawingAndKmzLayers() {
    if (this.drawingType !== "proj") {
      this.layersForDrawing = this.layersForDrawing.filter((l) => {
        return (
          l.vectorLayerType === this.drawingType || l.vectorLayerType === "proj"
        );
      });

      if (this.currentLayerTitle !== this.PROJECT_LAYER_TITLE) {
        this.layersForKmz = this.layersForDrawing.filter((l) => {
          return l.vectorLayerType === this.drawingType;
        });
      } else if (this.streetFeatures.length == 0) {
        this.layersForDrawing = this.allDrawingLayers;
        this.layersForKmz = this.layersForDrawing.filter(
          (l) => l.vectorLayerType !== "proj"
        );
      }
    } else if (this.streetFeatures.length > 0) {
      const type = this.streetFeatures[0].feature.get("type");
      this.layersForDrawing = this.allDrawingLayers.filter((l) => {
        return l.vectorLayerType === type || l.vectorLayerType === "proj";
      });

      //if (this.currentLayerTitle !== this.PROJECT_LAYER_TITLE) {
      this.layersForKmz = this.layersForDrawing.filter((l) => {
        return l.vectorLayerType === type;
      });
      // } else if (this.streetFeatures.length == 0) {
      //   this.layersForDrawing = this.allDrawingLayers;
      //   this.layersForKmz = this.layersForDrawing.filter(
      //     (l) => l.vectorLayerType !== "proj"
      //   );
      // }
    }
  }
}
