import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { map, catchError } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { AppConfigService } from "./app-config.service";
import { JwtUser } from "../models/jwt-user";
import { BehaviorSubject, of } from "rxjs";
import { Router } from "@angular/router";
import { UserService } from "../services/user.service";
import { UserSection } from "../models/userSection";
import { Roles } from "../helpers/constants";
@Injectable({
    providedIn: "root"
})
export class AuthService {
    public currentUser: BehaviorSubject<JwtUser> = new BehaviorSubject<JwtUser>(JSON.parse(localStorage.getItem('currentUser')));
    private tokenName: string = AppConfigService.env.token_name;
    private permissions: UserSection[] = [];

    constructor(
        private http: HttpClient,
        private jwtHelper: JwtHelperService,
        private cookieHelper: CookieService,
        private router: Router,
        private userService: UserService
    ) {
        let token = cookieHelper.get(AppConfigService.env.token_name);
        if (token) {
            this.loadUser();
        }

        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.setPermissions(userInfo && userInfo.sections ? userInfo.sections : []);
    }

    


    public isIamToken():boolean{
        return (localStorage.getItem("isIamToken") && localStorage.getItem("isIamToken")=="1");
    }

    login(credentials) {
        const clientPassword = AppConfigService.env.clientSecretKey;
        const clientKey = AppConfigService.env.clientKey;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: "Basic " + btoa(`${clientKey}:${clientPassword}`),
                "Content-type": "application/x-www-form-urlencoded",
            }),
        };

        const psw = encodeURIComponent(credentials.password);
        const body = `scope=read&password=${psw}&username=${credentials.username}&grant_type=password`;

        return this.http
            .post<{ access_token: string }>(
                `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.login}`,
                body,
                httpOptions
            )
            .pipe(
                map((data) => {
                    if (data && data.access_token) {
                        this.saveToken(data.access_token);
                        this.fetchPermissionsByToken(data.access_token);
                        this.getUserData();
                        return true;
                    }
                    return false;
                }),
                catchError((_) => of(false))
            );
    }

    saveToken(token): void {
        const expirationDate: Date = this.jwtHelper.getTokenExpirationDate(token);
        this.cookieHelper.set(this.tokenName, token, expirationDate);
        let user = this.jwtHelper.decodeToken(token);        
        if (!user.user_name)user.user_name = user.sub;
        this.currentUserValue = user;        
    }

    loadUser() {
        if (!this.getCurrentUser()) this.reloadCurrentUserData(null);
    }

    reloadCurrentUserData(callback) {
        this.userService.getInfo("").subscribe((data) => {
            console.log("here");
            const u = this.getCurrentUser();
            u.authorities = data.authorities;
            this.currentUserValue = u;
            if (callback) callback();
        });
    }

    getCurrentUser(): JwtUser {
        return this.currentUser.value;
    }

    getCurrentToken(): string {
        return this.cookieHelper.get(this.tokenName);
    }

    public get currentUserValue(): JwtUser {
        return this.getCurrentUser();
    }

    isLoggedIn() {
        const token: string = this.cookieHelper.get(this.tokenName);
        const isExpired: boolean = this.jwtHelper.isTokenExpired(token);
        if (token && token != "" && !isExpired) return true;
        return false;
    }

    logout(): void {
        
        const apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.user_protected}`;
        this.http.get(apiUrl + "logout").subscribe(
            (x:any) => {
                console.log(x);
                
                this.clearUserData();
                if (x && x.url){location.href = x.url;}
            this.router.navigate(["login"]);
        }
        ,error=>{this.clearUserData();}
        );
    }

    clearUserData(): void {
        localStorage.clear();
        this.cookieHelper.delete(this.tokenName);
        this.cookieHelper.deleteAll();
        this.cookieHelper.deleteAll("/");
        this.currentUserValue = null;
        this.currentUser.next(null);
    }

    isRegistered(): boolean {
        return this.hasRole(Roles.role_reg);
    }

    setMenu(value: string) {
        localStorage.setItem("currentMenu", (value));
    }

    getMenu(): any {
        const v = localStorage.getItem("currentMenu");
        if (v && v != '') return JSON.parse(v);
        return [];
    }

    setHeaderCode(value: string) {
        localStorage.setItem("currentHeaderCode", (value));
    }

    getHeaderCode(): any {
        const v = localStorage.getItem("currentHeaderCode");
        if (v == 'null') return null;
        return v;
    }

    hasRole(role: string): boolean {
        if (this.currentUser == null) return false;
        const user: JwtUser = { ...this.currentUser.value };
        if (user.authorities == null) return false;
        return user.authorities.includes(role);
    }

    isEnabled(): boolean {
        return this.hasRole(Roles.role_enabled);
    }

    isAdmin(): boolean {
        return this.hasRole(Roles.role_admin);
    }
    isManager(): boolean {
        return this.hasRole(Roles.role_manager);
    }

    isAreaStaff(): boolean {
        return this.hasRole(Roles.role_area_staff);
    }
    isExternal(): boolean {
        return this.hasRole(Roles.role_external);
    }
    isDirectionalGroup(): boolean {
        return this.hasRole(Roles.role_directional_group);
    }
    isViewer() {
        return this.hasRole(Roles.role_viewer);
    }
    getUserInfo() {
        const o = localStorage.getItem("userInfo");
        return o ? JSON.parse(o) : null;
    }

    isGruppoPnrr() {
        const o = this.getUserInfo();
        return o && o.areeTematiche && o.areeTematiche.find(x => x.codiceIpa == 'TRHXPV') != null;
    }

    isGr34() {
        const o = this.getUserInfo();
        return o && o.areeTematiche && o.areeTematiche.find(x => x.struttura.includes( 'GR34')) != null;
    }

    gestisciPnrr() {
        const o = this.getUserInfo();
        return o && o.areeTematiche && o.areeTematiche.find(x => x.gestisciEntitaPnrr) != null;
    }
    gestisciNonPnrr() {
        const o = this.getUserInfo();
        return o && o.areeTematiche && o.areeTematiche.find(x => x.gestisciEntitaNonPnrr) != null;
    }

    visualizzaEntitaAltreDirezione(){
        const o = this.getUserInfo();
        return o && o.areeTematiche && o.areeTematiche.find(x => !x.visualizzaSoloEntitaDirezione) != null;        
    }

    hasRoles(): boolean {
        if (this.currentUser == null) return false;
        const user: JwtUser = { ...this.currentUser.value };
        if (user.authorities == null) return false;
        return user.authorities.length > 0;
    }

    setIsRegistered(): void {
        const user: JwtUser = this.currentUser.value;
        if (!user.authorities.includes(Roles.role_reg)) {
            user.authorities.push(Roles.role_reg);
            this.currentUser.next(user);
        }
    }

    setIsEnabled(): void {
        const user: JwtUser = this.currentUser.value;
        if (!user.authorities.includes(Roles.role_enabled)) {
            user.authorities.push(Roles.role_enabled);
            this.currentUser.next(user);
        }
    }

    //local storage set method
    public set currentUserValue(user: JwtUser) {
        if (user) {
            localStorage.setItem("currentUser", JSON.stringify(user));            
        } else {
            localStorage.removeItem("currentUser");
            localStorage.removeItem("currentMenu");
            localStorage.removeItem("currentHeaderCode");
        }
        this.currentUser.next(user);
    }

    public fetchPermissionsByToken(token: string) {
        const user = this.jwtHelper.decodeToken(token);
        this.userService.getAllPermissionsByUsername(user.user_name).subscribe(resp => {
            this.setPermissions(resp);
        });
    }

    public getUserData() {       
            this.userService.getInfo('').subscribe(resp => {
                localStorage.setItem("userInfo", JSON.stringify(resp));
              });
    }

    public setPermissions(permissions: UserSection[]) {
        this.permissions = permissions;
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo){
            userInfo.sections = this.permissions;
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
        }
    }


    

    /**
     * Controlla se l'utente può accedere o meno alla sezione.
     * @param section il nome della sezione
     * @param operations le operazioni da poter effettuare sulla sezione
     * @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
     * @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
     * @returns true se il controllo è andato a buon fine, false altrimenti
    */
    public checkPermissionBySectionAndOperations(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = false): boolean {
        const find = this.permissions.find(permission => permission.section.name === section);
        if (find) {
            let opFind = atLeastOne ? false : true;
            operations.forEach(op => {
                if (!find[op]) {
                    opFind = atLeastOne ? opFind : false;
                } else if (atLeastOne) {
                    opFind = true;
                }
            });

            return opFind;
        }

        return enableOnNotFound;
    }
}
