<div style="position: relative">
  <div
    class="d-flex bg-white"
    style="border: 1px solid #ced4da; border-radius: 0.25rem; height: 36px"
  >
    <input
      placeholder="{{ cpOptions.placeholder }}"
      #search      
      type="text"
      maxlength="255"
      class="form-control py-0"
      
      style="border: 0px solid #ced4da; border-radius: 0.25rem; height: 34px"
      [formControl]="searchField"
    />

    <!-- <div class="w-60 h-40 completer-container">-->
    <ul [hidden]="!visible" class="completer-container">
      <li *ngFor="let item of matches">
        <a
          [ngClass]="{ 'text-black-50': item.disabled }"
          (click)="setValue(item)"
          style="display: block"
        >
          <span title="{{ item[cpOptions.labelField] }}">{{
            truncate(item[cpOptions.labelField], cpOptions.labelMaxLength)
          }}</span>
        </a>
      </li>
    </ul>

    <span
      *ngIf="text && text != ''"
      class="ng-clear-wrapper pointer pt-1"
      title="Clear all "
      (click)="resetValue()"
      ><span
        aria-hidden="true"
        class="ng-clear pr-2"
        style="
          font-size: 18px;
          line-height: 1;
          pointer-events: none;
          color: #999;
        "
        >×</span
      ></span
    >
  </div>
  <!--    <div *ngFor="let item of matches">
            <a [ngClass]="{'text-secondary': item.responsible}" (click)="setValue(item)"><span>{{item.title}}</span></a>
        </div>-->
  <!--</div>-->
</div>
