import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    TemplateRef
  } from "@angular/core";
  import { FormGroup, FormBuilder, Validators } from "@angular/forms";
  import { HttpClient } from "@angular/common/http";  
  import { ToastService } from 'src/app/services/toast.service';
  import { BsLocaleService } from 'ngx-bootstrap/datepicker';
  import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';  
import { StatoAttuazioneService,AreeTematicheService } from '../../services';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { GenericSelectionModalComponent } from "../genericSelectionModal/generic-selection-modal.component";
  
  
  @Component({
    selector: 'stato-attuazione-component',
    templateUrl: './stato-attuazione.component.html',
    styleUrls: ['./stato-attuazione.component.css']
  })
  export class StatoAttuazioneComponent
    implements OnInit {
    dtOptions: any = {};
    visible = false;
    submitted: boolean = false;
    addSchoolYearForm: FormGroup;
    modalRef: BsModalRef;
    open: boolean = false; 
    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;
    @ViewChild('templateModal', { static: true })
    public templateModal: TemplateRef<any>;

    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    @ViewChild("direzioneFieldValueTemplate", { static: true })
    public direzioneFieldValueTemplate: TemplateRef<any>;


    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    columns = [
        {
            name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
        }        
        , {
            name: 'stato attuazione'
            , prop: 'descrizione'
            , sortable: true
            , filtrable: true
            //,cellClass: 'mobile-hidden desktop-hidden'
            //,headerClass: 'mobile-hidden desktop-hidden'
            , flexGrow: 14
            , minWidth: 200
        }
        ,
        {
            name: "aree gestionali",
            prop: "areeTematiche.descrizione",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            cellTemplate: "direzioneFieldValueTemplate",
            flexGrow: 8,
            minWidth: 120,
        }
        , {
            name: 'modifica'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60

        }
        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60

        }
    ];


    templateList = [];    
    constructor(
      private http: HttpClient,
      private formBuilder: FormBuilder,
      private el: ElementRef,
      private localeService: BsLocaleService,
      private toastr: ToastService,
      private modalService: BsModalService,
      private currentService:StatoAttuazioneService,   
      private areeTematicheService: AreeTematicheService,   
    ) {
      this.localeService.use("it");  
      this.dtOptions = {
        rows: []
        ,totalElements:0
        , columns: this.columns
        , columnMode: ColumnMode.flex
        , headerHeight: "50"
        , footerHeight: "50"
        , rowHeight: "auto"
        , externalSorting: false// true
        , loadingIndicator: 'loanding'
        , class: "bootstrap"
        , summaryRow: true
        , summaryPosition: "'bottom'"
        , externalPaging: false// true
        , count: 0
        , offset: 0
        , limit: 10
        , serverSide: false
        , scrollbarH:"true"
    };  
    }
  
    ngOnInit() {
        this.templateList = [{name:'direzioneFieldValueTemplate',template:this.direzioneFieldValueTemplate}, { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }, { name: 'btnEditTemplate', template: this.btnEditTemplate }];
        window.scroll(0, 0);
        this.addSchoolYearForm = this.initializeFormGroup();
        this.getStatiAttuazione();
    }
  
    
  
    initializeFormGroup() {
      return this.formBuilder.group({
          id:[null],
        descrizione: [null, [Validators.required, Validators.maxLength(255)]],                      
      });
    }
  
    get f() {
      return this.addSchoolYearForm.controls;
    }
    

    onSubmit() {
      this.submitted = true;
      this.addSchoolYearForm.updateValueAndValidity();
      let errors = false;
      for (let c in this.f) {
        errors = errors || this.f[c].errors !== null;
        console.log(
          "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
        );
      }
      if (this.addSchoolYearForm.invalid || errors) {
        console.log("invalid");
        let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
        for (let el in this.addSchoolYearForm.controls) {
          if (this.addSchoolYearForm.controls[el].invalid) {
            console.log(`set focus on ${el}`);
            if (this.addSchoolYearForm.controls[el] instanceof FormGroup) {
              for (let el1 in this.addSchoolYearForm.get(el)["controls"]) {
                if (this.addSchoolYearForm.get(el)["controls"][el1].invalid) {
                  invalidElements = this.el.nativeElement.querySelectorAll(
                    `[formcontrolname="${el1}"]`
                  );
                  break;
                }
              }
            } else {
              invalidElements = this.el.nativeElement.querySelectorAll(
                `[formcontrolname="${el}"]`
              );
            }
            break;
          }
        }
        if (invalidElements.length > 0) {
          invalidElements[0].focus();
        }
  
        return false;
      }
  
      
  
      let obj = this.addSchoolYearForm.value;
        obj.areeTematiche = this.areeTematiche;
        this.currentService.save(obj).subscribe(res => {
            this.toastr.success("Stato attuazione salvato con successo!");
            this.submitted = false;
            this.open = false;
            this.addSchoolYearForm.reset();
            this.areeTematiche = [];
            this.buttonLabel="Inserisci";
            if (!obj.id){
                //new row
                this.ngxDatatableWrapper.addRow(res);                        
            }
            else{
                this.modalRef.hide();
                this.ngxDatatableWrapper.updateRow(obj);                                                
            }

        }, error => {
            this.toastr.error("Errore di sistema");
        });
    }
  
    buttonLabel:string="Inserisci";
    loading:boolean=false;
    config = {
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };
    onEdit(row) {
        this.submitted = false;
        this.open = false;
        this.areeTematiche = [];
        console.log(row);
        this.buttonLabel = "Aggiorna";
        this.modalRef = this.modalService.show(this.templateModal, this.config);
        this.addSchoolYearForm.setValue({
            id: row.id,
            descrizione:row.descrizione
        });
        this.areeTematiche = row.areeTematiche;
    }
    onDelete(row) {
        console.log(row);
        if (!confirm(`Stai per eliminare lo stato di attuazione "${row.descrizione}", proseguire?`)) return;
        this.loading = true;
        this.currentService.delete(row.id).subscribe(
            result => {
                this.loading = false;
                this.ngxDatatableWrapper.deleteRow(row);
                this.toastr.success('Stato di attuazione eliminato con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile eliminare lo stato di attuazione: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                console.log('error');
            });
    }
    closeModal(){
        this.modalRef.hide();
        this.addSchoolYearForm.reset();
        this.buttonLabel="Inserisci";
    }  

    getStatiAttuazione() {
        this.currentService.getAll().subscribe(
            result => {
                
                this.ngxDatatableWrapper.setInitialData(result);

            }
        );

    }

    printDirezioneFieldValue(row){
        console.log(row);
        return '';
    }

    modalRefAreeTematiche: BsModalRef;

    areeTematiche=[];

    removeAreaTematica(item) {
        console.log(`inside removeAreaTematica id=${item.id}`);
        this.areeTematiche = this.areeTematiche.filter((x) => x.id != item.id);
    }

    getAreeTematicheDTNgx = (dataTablesParameters: any, callback: any) => {
        this.areeTematicheService
            .getAllDtOnlyActive(dataTablesParameters)
            .subscribe((resp) => {
                callback(resp);
            });
    };
    openModalAT() {
        let modalDefinition = {
            pageTitle: "Seleziona le aree gestionali e/o esterne"
        };

        let modalDefinitionNgx = {
            rows: [],
            totalElements: 0,
            columns: [
                {
                    name: "id",
                    prop: "id",
                    visible: false,
                    sortable: true,
                    filtrable: true,
                    width: 10,
                    resizeable: false,
                },

                {
                    name: "descrizione",
                    prop: "descrizione",

                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 4,
                    minWidth: 200,
                },
                {
                    name: "codice ipa",
                    prop: "codiceIpa",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 1,
                    minWidth: 60,
                },
                {
                    name: "struttura",
                    prop: "struttura",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 2,
                    minWidth: 100,
                },
                {
                    name: "nominativo",
                    prop: "nominativo",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 3,
                    minWidth: 200,
                }
            ],
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            ajax: this.getAreeTematicheDTNgx,
            scrollbarH: "true",
        };

        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl",
        };
        const initialState = {
            modalDefinition: modalDefinition,
            dtOptionsNgx: modalDefinitionNgx,
        };

        this.modalRefAreeTematiche = this.modalService.show(
            GenericSelectionModalComponent,
            Object.assign({}, config, { initialState })
        );
        console.log("apertura modale GenericSelectionModalComponent");
        this.clearSubscriptions();
        let subscription = this.modalRefAreeTematiche.content.onSubmitOk.subscribe((data) => {
            console.log("inside ok of area tematica");
            if (!this.areeTematiche) this.areeTematiche = [];
            let existsObj = this.areeTematiche.find(
                (x) => x.id == data.id
            );
            if (existsObj) return; //already added
            this.areeTematiche.push(data);
            this.areeTematiche = Object.assign(
                [],
                this.areeTematiche
            );

            this.toastr.success("Area direzione inserita con successo", null, {
                timeOut: 2000,
        /*closeButton:true,*/ disableTimeOut: false,
            });
        });
        this.modalSubscriptions.push(subscription);
    }
    clearSubscriptions() {
        this.modalSubscriptions.forEach((x) => x.unsubscribe());
        this.modalSubscriptions = [];
    }
    modalSubscriptions = [];

  }
  