import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SicerCapitolo } from 'src/app/modules/inframob/models/sicer-capitolo';

@Component({
  selector: 'app-capitolo-info',
  templateUrl: './capitolo-info.component.html',
  styleUrls: ['./capitolo-info.component.css']
})
export class CapitoloInfoComponent implements OnInit, OnChanges {

  @Input()
  capitolo: SicerCapitolo = null;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    switch (true) {
      case changes.capitolo !== undefined:
        this.form = this.initializeFormGroup();
        break;
    }
  }

  private initializeFormGroup() {
    return this.formBuilder.group({
      cod_cpt: [this.capitolo.codCpt],
      descr_cpt: [this.capitolo.descrCpt]
    });
  }

  get f() {
    return this.form.controls;
  }

  insertDotForThousands(baseValue: number): string {
    const value = baseValue.toFixed(2).toString().replace('.', ',');
    return value
              ? value.replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
              : '';
  }
}
