import { CurrencyPipe } from '@angular/common';
import {
    Component,
    OnInit,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    ElementRef,
    TemplateRef,
    Input,
    EventEmitter,
    Output,
    SimpleChanges
} from "@angular/core";
import { registerLocaleData } from '@angular/common';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
//import { Subject } from "rxjs";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from 'src/app/services/app-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProvvedimentoCapitoloBilancioSelectionModalComponent } from './provvedimentoCapitoloBilancioSelectionModal/provvedimento-capitolo-bilancio-selection-modal.component';
import { Provvedimento, TipoProvvedimento, ScopoProvvedimento, Progetto } from '../../models';
import {
    ProtocolloService, ProvvedimentoService, SoggettoRuoloService, TipoProvvedimentoService, ScopoProvvedimentoService, ProgettoService, SoggettoGiuridicoService
    , SicerService, PnrrService
} from '../../services';
import { GenericSelectionModalComponent } from '../genericSelectionModal/generic-selection-modal.component';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import localeIt from '@angular/common/locales/it';
import { ColumnMode } from '@swimlane/ngx-datatable';
import * as FileSaver from "file-saver";

//import {DirtyComponent} from "../../../../models/dirty-component"
import { IsDirtyHelper } from '../../../../helpers/is-dirty.helper';
import { StoricoProvvedimentoComponent } from "./storicoProvvedimento/storico-provvedimento.component";
import { DettaglioProvvedimentiComponent } from "./dettaglio-provvedimenti/dettaglio-provvedimenti.component";
import { lineArc } from '@turf/turf';
import { AuthService } from 'src/app/services';
registerLocaleData(localeIt, 'it');
defineLocale('it', itLocale);

@Component({
    selector: 'provvedimenti-component',
    templateUrl: './provvedimenti.component.html',
    styleUrls: ['./provvedimenti.component.css']
})
export class ProvvedimentiComponent
    implements OnInit, AfterViewInit, OnDestroy {
    dtOptions: any = {};
    visible = false;
    submitted: boolean = false;
    addSchoolYearForm: FormGroup;
    modalRef: BsModalRef;
    open: boolean = false;
    maxDate = new Date();
    projectId: number;
    loading: boolean = false;
    isDirty = false;
    modalSubscriptions: any[];
    tipiProvvedimento: TipoProvvedimento[];
    scopiProvvedimento: ScopoProvvedimento[];
    soggettiGiuridici = [];


    capitoliBilancioIsError: boolean = false;
    capitoliBilancio: any[];
    currentProject: Progetto;
    soggettiGiuridiciIsError = false;

    section: string = 'Gestione Provvedimenti';

    activeTab = "tabDatiGenerali";
    isInInsertState = false;

    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;


    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    @ViewChild("divTemplateStorico", { static: true })
    divTemplateStorico: StoricoProvvedimentoComponent;

    @ViewChild("divTemplateDettaglio", { static: true })
    divTemplateDettaglio: DettaglioProvvedimentiComponent;

    templateList = [];

    @Input()
    filters: any = null;

    @Output()
    disableButtonEmit = new EventEmitter<boolean>();
    @Output()
    disableSearchEmitter = new EventEmitter<boolean>();

    columns = [
        {
            name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
            , resizeable: false
        }

        , {
            name: 'data'
            , prop: 'data'

            , sortable: true
            , filtrable: true
            , cellTemplate: 'dateValueTemplate'
            , resizeable: false, draggable: false
            , flexGrow: 2
            , minWidth: 80
        }
        , {
            name: 'numero'
            , prop: 'numero'
            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 2
            , minWidth: 75
        }
        , {
            name: 'oggetto'
            , prop: 'oggetto'
            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 4
            , minWidth: 170
        }
        , {
            name: 'tipo provvedimento'
            , prop: 'tipoProvvedimento.provvedimento'
            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 3
            , minWidth: 130
        }
        /*, {
            name: 'scopo provvedimento'
            , prop: 'scopoProvvedimento.descrizione'
            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 3
            , minWidth: 140
        }*/

        , {
            name: 'rilevante PNRR'
            , prop: 'pnrrRilevante'
            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 2
            , minWidth: 75
            , cellTemplate: 'pnrrFieldValueTemplate'
        }
        , {
            name: 'Linee Finanziamento<br /> PNRR/FC'
            , prop: 'triplette'
            , sortable: false
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 2
            , minWidth: 75
            , cellTemplate: 'tripletteFieldValueTemplate'
        }

        , {
            name: this.enableSection(this.section, ['update']) ? 'modifica' : 'dettaglio'
            , prop: null
            , visible: this.enableSection(this.section, ['read', 'update'], true)
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , resizeable: false
            , draggable: false
            , flexGrow: 1
            , minWidth: 60
        }
        , {
            name: 'elimina'
            , prop: null
            , visible: this.enableSection(this.section, ['delete'])
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , resizeable: false
            , draggable: false
            , flexGrow: 1
            , minWidth: 60
        }
    ];

    mode: string = 'list';


    public uploadFileUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.uploadFile}`;



    constructor(
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private el: ElementRef,
        private localeService: BsLocaleService,
        private toastr: ToastService,
        private modalService: BsModalService,
        private currentService: ProvvedimentoService,
        private tipoProvvedimentoService: TipoProvvedimentoService,
        private scopoProvvedimentoService: ScopoProvvedimentoService,
        private soggettoGiuridicoService: SoggettoGiuridicoService
        , private isDirtyHelper: IsDirtyHelper
        , private protocolloService: ProtocolloService
        , private sicerService: SicerService
        , private soggettoRuoloService: SoggettoRuoloService
        , private pnrrService: PnrrService
        , private authService: AuthService
    ) {
        this.localeService.use("it");
        this.modalSubscriptions = [];
        this.capitoliBilancio = [];
        this.dtOptions = {
            rows: []
            , totalElements: 0
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , scrollbarH: "true"
            , sorts: [{ dir: 'asc', prop: 'data' }]
            , ajax: this.loadTableData
            , extraFilterTemplate: this.extraFilterTemplate
        };
        this.addSchoolYearForm = this.initializeFormGroup();
        this.pnrrRipartizioneRisorseForm = this.initializeFormGroupPnrrRipartizione();
        this.pnrrLineaFinanziamentoForm = this.initializeFormGroupPnrrLineaFinanziamentoForm();
    }

    ngOnInit() {
        window.scroll(0, 0);
        this.dtOptions.extraFilterTemplate = (this.isAdmin() || this.isGr34()) ? this.extraFilterTemplate : null;
        this.templateList = [{
            name: 'btnDeleteTemplate'
            , template: this.btnDeleteTemplate
        }
            , { name: 'btnEditTemplate', template: this.btnEditTemplate }
            , {
            name: "tripletteFieldValueTemplate",
            template: this.tripletteFieldValueTemplate,
        },{name:'pnrrFieldValueTemplate',template:this.pnrrFieldValueTemplate}
        ];
        this.getTipiProvvedimento();
        this.getScopiProvvedimento();
        this.getPnrrLineeFinanziamento();

        //this.getTableData();
        this.frm_protocolli = this.formBuilder.group(
            {
                protocollo_anno: [null]
                , protocollo_numero: [null]
            }
        );
        this.frm_sicer = this.formBuilder.group(
            {
                sicer_anno: [null]
                , sicer_numero: [null]
            }
        );
        this.editNoteProtocollo = this.formBuilder.group(
            {
                note_protocollo: [null]
                , id: [null]
                , tipo: [null]
            }
        );
        this.getRuoliSoggettiGiuridici();
        this.getSoggettiGiuridici();

    }


    ngAfterViewInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
      switch (true) {
        case changes.filters !== undefined:
            setTimeout(() => {
                this.ngxDatatableWrapper.refresh();
            }, 0);
            break;
      }
    }

    canDeactivate() {
        this.isDirty = this.isDirtyHelper.isDirty;
        console.log(`isDirty=${this.isDirty}`);
        return this.isDirty;
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event

        //this.modalRef.content.onClose.unsubscribe();
        console.log('inside destroy');
        // console.log(`isdirty = ${this.addSchoolYearForm.dirty}`);
    }

    initializeFormGroup() {
        const grp = this.formBuilder.group({
            id: [null],
            data: ['', [Validators.required]],
            tipo: [null, [Validators.required]],
            scopo: [null],
            numero: ['', [Validators.required]],
            oggetto: [null, [Validators.required]]
            /*,pnrrLineaFinanziamento:[null]*/
            , pnrrRilevante: [null, [Validators.required]],
            note: [null],
            pnrrSoggettoProponente: [null],
            pnrrPubblicatoInGu: [null, [Validators.required]],
            pnrrDataGu: [null],
            pnrrNumeroGu: [null],
            pnrrRegione: [null]
            , fcRegione: [null]
        });
        grp.valueChanges.subscribe(val =>
            this.setIsDirty(grp.dirty)
        );
        return grp;
    }

    get f() {
        return this.addSchoolYearForm.controls;
    }

    buttonLabel: string = "Inserisci";
    modalTitle: string = "";
    onInsert() {
        this.addSchoolYearForm.enable();
        this.isDetailView = false;
        this.pnrrLineaFinanziamentoForm.controls.rilevanteNazionale.enable();
        this.f.pnrrSoggettoProponente.enable();
        this.pnrrLineaFinanziamentoForm.controls.assorbimentoDaDecreto.enable();
        this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.enable();
        this.submitted = false;
        this.isInInsertState = true;
        this.provvedimentoId = -1;
        this.buttonLabel = "Inserisci";
        this.modalTitle = "Inserisci provvedimento";
        this.capitoliBilancio = [];
        this.soggettiGiuridici = [];
        this.pnrrRipartizioneRisorse = [];
        this.documenti = [];
        //this.modalRef = this.modalService.show(this.templateModal, this.config);
        this.addSchoolYearForm.reset();
        this.addSchoolYearForm.markAsPristine();

        this.mode = 'insertedit';
        this.activeTab = "tabDatiGenerali";
        this.frm_protocolli.reset();
        this.frm_sicer.reset();
        this.loadProtocolliTable();
        this.currentNewProtocolloId = -1;
        this.protocolli = [];
        this.sicerAtti = [];
        this.pnrrLineeFinanziamento = [];
        this.pnrrRipartizioneRisorse = [];
        this.calcolaValoriRipartizioneRegione();

        if (!this.isAdmin() && !this.isGr34()){
            this.f.pnrrRilevante.disable();
            this.f.pnrrRilevante.setValue('1');
            this.pnrrRilevanteChange();

        }
        this.setIsDirty(false);


    }

    onSubmit() {
        this.submitted = true;
        this.addSchoolYearForm.updateValueAndValidity();

        for (let c in this.f) {
            this.f[c].updateValueAndValidity();
        }

        let errors = false;
        this.pnrrRipartizioneRisorseIsInError = false;

        //this.capitoliBilancioIsError = !(this.capitoliBilancio && this.capitoliBilancio.length && this.capitoliBilancio.length > 0);
        this.capitoliBilancioIsError = false;
        //this.pnrrRipartizioneRisorseIsInError = this.isPnrrRipartizioneRisorseVisible() && (!this.pnrrRipartizioneRisorse || this.pnrrRipartizioneRisorse.length<=0);
        this.pnrrLineeFinanziamentoIsError = this.isPnrrRipartizioneRisorseVisible() && (!this.pnrrLineeFinanziamento || this.pnrrLineeFinanziamento.length <= 0);
        if (this.isPnrrRipartizioneRisorseVisible() && this.pnrrLineeFinanziamento && this.pnrrRipartizioneRisorse) {
            for (const linea of this.pnrrLineeFinanziamento.filter(x => x.status != 'deleted' && !x.rilevanteNazionale)) {
                this.pnrrRipartizioneRisorseIsInError = this.pnrrRipartizioneRisorseIsInError || !this.pnrrRipartizioneRisorse.find(x => x.pnrrLineaFinanziamento.id == linea.pnrrLineaFinanziamento.id);
            }
        }




        this.soggettiGiuridiciIsError = !(this.soggettiGiuridici && this.soggettiGiuridici.length && this.soggettiGiuridici.length > 0);
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );
        }
        if (this.addSchoolYearForm.invalid || errors || this.capitoliBilancioIsError
            || this.soggettiGiuridiciIsError || this.pnrrRipartizioneRisorseIsInError || this.pnrrLineeFinanziamentoIsError) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.addSchoolYearForm.controls) {
                if (this.addSchoolYearForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.addSchoolYearForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.addSchoolYearForm.get(el)["controls"]) {
                            if (this.addSchoolYearForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }
            else {
                this.toastr.error(`Impossibile salvare il provvedimento, alcuni campi non sono stati validati`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
            }
            return false;
        }

        const newSchoolYear: Provvedimento = new Provvedimento();
        //newSchoolYear.descrizione = this.f.descrizione.value;
        newSchoolYear.numero = this.f.numero.value;
        newSchoolYear.oggetto = this.f.oggetto.value;
        newSchoolYear['note'] = this.f.note.value;
        newSchoolYear.tipoProvvedimentoId = this.f.tipo.value;
        newSchoolYear.tipoProvvedimento = this.tipiProvvedimento.find(x => x.id == newSchoolYear.tipoProvvedimentoId);
        newSchoolYear.tipoProvvedimentoDescrizione = newSchoolYear.tipoProvvedimento.provvedimento;

        newSchoolYear.scopoProvvedimentoId = this.f.scopo.value;
        newSchoolYear.scopoProvvedimento = this.f.scopo.value ? this.scopiProvvedimento.find(x => x.id == newSchoolYear.scopoProvvedimentoId) : null;
        newSchoolYear.scopoProvvedimentoDescrizione = newSchoolYear.scopoProvvedimento ? newSchoolYear.scopoProvvedimento.descrizione : '';

        newSchoolYear.provvedimentoCapitoliBilancio = this.capitoliBilancio;
        newSchoolYear.data = moment(this.f.data.value, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss');//.utc(false).toDate();

        newSchoolYear.soggettiGiuridici = this.soggettiGiuridici;
        newSchoolYear.id = this.f.id.value;

        newSchoolYear.documenti = this.documenti.filter(x => !(x.id < 0 && x['status'] == 'deleted'));

        //newSchoolYear['pnrrLineaFinanziamento'] =this.f.pnrrLineaFinanziamento.value? this.pnrrLineeFinanziamento.find(x=>x.id == this.f.pnrrLineaFinanziamento.value):null;

        newSchoolYear['pnrrRipartizioneRisorse'] = this.pnrrRipartizioneRisorse;
        newSchoolYear['pnrrRilevante'] = this.f.pnrrRilevante.value == '1';
        newSchoolYear['pnrrSoggettoProponente'] = this.soggettiProponenti.find(x => x.id == this.f.pnrrSoggettoProponente.value);
        newSchoolYear['pnrrLineeFinanziamento'] = this.pnrrLineeFinanziamento.filter(x => x.status != 'deleted');
        if (!newSchoolYear['pnrrRilevante']) {
            //newSchoolYear['pnrrLineaFinanziamento'] = null;
            newSchoolYear['pnrrRipartizioneRisorse'] = null;
            newSchoolYear['pnrrSoggettoProponente'] = null;
            newSchoolYear['pnrrLineeFinanziamento'] = null;
        }
        newSchoolYear['pnrrPubblicatoInGu'] = this.f.pnrrPubblicatoInGu.value == '1';
        if (!newSchoolYear['pnrrPubblicatoInGu']) {
            newSchoolYear['pnrrNumeroGu'] = null;
            newSchoolYear['pnrrDataGu'] = null;
        }
        else {
            newSchoolYear['pnrrDataGu'] = moment(this.f.pnrrDataGu.value, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss');//.utc(false).toDate();
            newSchoolYear['pnrrNumeroGu'] = this.f.pnrrNumeroGu.value;
        }

        if (newSchoolYear['pnrrLineeFinanziamento'] && newSchoolYear['pnrrRipartizioneRisorse'])
            for (const linea of newSchoolYear['pnrrLineeFinanziamento'].filter(x => x.rilevanteNazionale)) {
                const id = linea.id;
                newSchoolYear['pnrrRipartizioneRisorse'] = newSchoolYear['pnrrRipartizioneRisorse'].filter(x => x.pnrrLineaFinanziamento.id != id);
            }

        if (newSchoolYear['pnrrRilevante']) {
            //se l'amministratore titolare è diverso da almeno uno di quello delle triplette mostro il warning
            const ammTit = newSchoolYear['pnrrSoggettoProponente'];
            for (const linea of newSchoolYear['pnrrLineeFinanziamento']) {
                if (ammTit.id != linea.pnrrLineaFinanziamento.amministrazioneTitolare.id) {
                    this.toastr.warning("L'amministrazione titolare del provvedimento è diversa dall'amministrazione titolare di almeno una delle linee finanziamento PNRR/PNC selezionate");
                    break;
                }
            }
        }


        let that = this;
        that.loading = true;
        that.currentService.save(newSchoolYear).subscribe(res => {
            that.toastr.success("Provvedimento salvato con successo!");


            let id = res.id;
            this.protocolliDeleted.forEach(
                x => {
                    this.protocolloService.delete(id, 'PROVVEDIMENTI', x.id).subscribe(r => this.loadProtocolliTable());
                }
            );

            this.allProtocolli
                .filter(x => x['status'] == 'NEW')
                .forEach(
                    x => this.protocolloService.insert(id, 'PROVVEDIMENTI', x).subscribe(r => this.loadProtocolliTable())
                );

            this.allProtocolli
                .filter(x => x['status'] == 'EDIT')
                .forEach(x =>
                    this.protocolloService.saveNote(x.id, x.note).subscribe(r => this.loadProtocolliTable())
                );

            this.sicerService.saveAll(id, 'PROVVEDIMENTI', this.sicerAttiSelezionati).subscribe(r => this.loadProtocolliTable());
            that.submitted = false;
            that.loading = false;

            //dopo aver inserito il provvedimento, controllo se sono in inserimento da intervento/finanziamento chiedo se deve essere associato
            if (this.isInFastInsert && (!this.addSchoolYearForm.controls.id.value || this.addSchoolYearForm.controls.id.value != '-1')) this.saveElementEmitter.emit(id);


            that.onEdit(res);


            that.ngxDatatableWrapper.refresh();


        }, error => {
            that.loading = false;
            that.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                timeOut: 2000, disableTimeOut: false
            });
            console.log('error');
        });
    }

    /*getProvvedimentoCapitoliBilancio(id){
        this.currentService.getCapitoliBilancio(this.currentSchoolYear.id).subscribe(
            x=>{
                this.currentSchoolYear.provvedimentoCapitoliBilancio = x;
                this.capitoliBilancio = this.currentSchoolYear.provvedimentoCapitoliBilancio;
            }
        );
    }*/

    provvedimentoId = -1;
    onEdit(row) {
        if (this.loading) return;
        this.disableButtonEmit.emit(true);
        this.pnrrLineaFinanziamentoForm.controls.rilevanteNazionale.enable();
        this.f.pnrrSoggettoProponente.enable();
        this.pnrrLineaFinanziamentoForm.controls.assorbimentoDaDecreto.enable();
        this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.enable();
        this.submitted = false;
        this.isInInsertState = false;
        this.buttonLabel = "Aggiorna";
        this.modalTitle = "Modifica provvedimento";
        this.capitoliBilancio = row.provvedimentoCapitoliBilancio;
        this.soggettiGiuridici = row.soggettiGiuridici;
        this.pnrrRipartizioneRisorse = row['pnrrRipartizioneRisorse'];
        this.pnrrLineeFinanziamento = row['pnrrLineeFinanziamento'];
        this.pnrrLineeFinanziamento.sort((a, b) => a.pnrrLineaFinanziamento.codice < b.pnrrLineaFinanziamento.codice ? -1 : 1);
        this.pnrrRipartizioneRisorse.sort((a, b) =>
            a.pnrrLineaFinanziamento.codice == b.pnrrLineaFinanziamento.codice ? a.anno - b.anno :
                a.pnrrLineaFinanziamento.codice < b.pnrrLineaFinanziamento.codice ? -1 : 1
        );
        this.documenti = row.documenti;
        this.provvedimentoId = row.id;
        this.mode = 'insertedit';
        this.activeTab = "tabDatiGenerali";
        const data = moment(row.data).toDate();
        data.setHours(data.getHours() - data.getTimezoneOffset() / 60);

        let pnrrDataGu = null;
        if (row.pnrrDataGu) {
            pnrrDataGu = moment(row.pnrrDataGu).toDate();
            pnrrDataGu.setHours(pnrrDataGu.getHours() - pnrrDataGu.getTimezoneOffset() / 60);
        }
        this.addSchoolYearForm.setValue({
            id: row.id,
            data: data,
            tipo: row.tipoProvvedimento.id,
            scopo: row.scopoProvvedimento ? row.scopoProvvedimento.id : null,
            numero: row.numero
            , oggetto: row.oggetto ? row.oggetto : ''
            /*, pnrrLineaFinanziamento:row.pnrrLineaFinanziamento?row.pnrrLineaFinanziamento.id:null*/
            , pnrrRilevante: row.pnrrRilevante ? '1' : '0'
            , note: row.note ? row.note : ''
            , pnrrSoggettoProponente: row.pnrrSoggettoProponente ? row.pnrrSoggettoProponente.id : null
            , pnrrPubblicatoInGu: row.pnrrPubblicatoInGu ? '1' : '0'
            , pnrrNumeroGu: row.pnrrNumeroGu ? row.pnrrNumeroGu : ''
            , pnrrDataGu: pnrrDataGu
            , pnrrRegione: '0'
            , fcRegione: '0'
        });
        this.addSchoolYearForm.markAsPristine();

        this.clearSubscriptions();
        this.frm_protocolli.reset();
        this.frm_sicer.reset();
        this.loadProtocolliTable();
        this.currentNewProtocolloId = -1;
        this.protocolli = [];
        this.sicerAtti = [];
        this.soggettiGiuridici = this.soggettiGiuridici.map(i => { i.descrizione = `${i.siglaProvincia} - ${i.codiceIdentificativo} - ${i.denominazione}`; return i; });
        if (row.pnrrRilevante) this.setPnrrValidators();
        this.pnrrPubblicatoInGuChange();
        this.calcolaValoriRipartizioneRegione();
        const isOnEdit = !this.enableSection(this.section, ['update']);
        if (isOnEdit) {
            this.addSchoolYearForm.disable();
        }
        this.isDetailView = isOnEdit;
        if (!this.isAdmin() && !this.isGr34()){
            this.f.pnrrRilevante.disable();
            this.f.pnrrRilevante.setValue('1');
            this.pnrrRilevanteChange();

        }
        this.setIsDirty(false);
    }
    onDelete(row) {
        if (this.loading) return;
        if (!confirm(`Stai per eliminare il provvedimento "${row.numero}", proseguire?`)) return;
        this.loading = true;
        let that = this;
        this.currentService.delete(row.id).subscribe(
            x => {
                that.loading = false;
                that.ngxDatatableWrapper.deleteRow(row);
                that.toastr.success('Provvedimento eliminato con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
            }
            , error => {
                that.loading = false;
                that.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );
    }


    loadTableData = (dataTablesParameters: any, callback: any) => {
        this.currentService.getAllDtDto(dataTablesParameters, this.filters)
            .subscribe(resp => {
                callback(resp);
                this.disableSearchEmitter.emit(false);
            });
    }


    getTableData = () => {
        this.currentService.getAll()
            .subscribe(result => {
                this.ngxDatatableWrapper.setInitialData(result);
            });
    }

    getTipiProvvedimento() {
        this.tipoProvvedimentoService.getAll().subscribe(
            x => this.tipiProvvedimento = x
        );
    }
    getScopiProvvedimento() {
        this.scopoProvvedimentoService.getAll().subscribe(
            x => this.scopiProvvedimento = x
        );
    }

    removeCapitoloBilancio(item) {
        console.log(`inside removeCapitoloBilancio id=${item.id}`);
        if (confirm(`Rimuovere il capitolo di bilancio '${item.capitoloBilancioDescrizione}' del ${item.anno}?`))
            this.capitoliBilancio = this.capitoliBilancio.filter(x => x.id != item.id);
        this.setIsDirty(true);
    }

    clearSubscriptions() {
        this.modalSubscriptions.forEach(x => x.unsubscribe());
        this.modalSubscriptions = [];
    }

    openModalCapitoliBilancio($event) {
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: 'modal-lg'
        };
        this.modalRef = this.modalService.show(ProvvedimentoCapitoloBilancioSelectionModalComponent, config);
        this.clearSubscriptions();
        let subscription = this.modalRef.content.onSubmitOk.subscribe(
            data => {
                console.log('inside ok of capitolo bilancio');
                let existsObj = this.capitoliBilancio.find(x => x.anno == data.anno && x.capitoloBilancioCodice == data.capitoloBilancioCodice);
                if (existsObj) {
                    this.toastr.warning('Esiste già un capitolo di bilancio per l\'anno selezionato', null, {
                        timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                    });
                    return;//already added
                }
                this.capitoliBilancio.push(data);
                this.capitoliBilancio = Object.assign([]
                    , this.capitoliBilancio
                );

                this.toastr.success('Capitolo di bilancio inserito con successo');
                this.setIsDirty(true);
                this.closeModal();
            }
        );
        this.modalSubscriptions.push(subscription);
        $event.preventDefault();
    }

    removeSoggettoGiuridico(item) {
        console.log(`inside removeSoggettoGiuridico id=${item.id}`);
        if (confirm(`Rimuovere il soggetto giuridico '${item.siglaProvincia} - ${item.codiceIdentificativo} - ${item.denominazione}'`)) {
            this.soggettiGiuridici = this.soggettiGiuridici.filter(x => x.id != item.id);
            if (this.pnrrRipartizioneRisorse) this.pnrrRipartizioneRisorse = this.pnrrRipartizioneRisorse.filter(x => x.soggettoGiuridico.id != item.id);
            this.setIsDirty(true);
        }
    }

    openModalSoggettiGiuridici() {

        let modalDefinition = {
            columns: [
                { title: "ID", data: "id", searchable: true, orderable: true, width: '40px' },
                { title: "codice identificativo", data: "codiceIdentificativo", searchable: true, orderable: true },
                { title: "codice ipa", data: "codiceIpa", searchable: true, orderable: true },
                { title: "denominazione", data: "denominazione", searchable: true, orderable: true },
                { title: "sigla provincia", data: "siglaProvincia", searchable: true, orderable: true }
            ],
            ajax: this.getSogggettiGiuridiciDT
            , pageTitle: this.isPnrrRipartizioneRisorseVisible() ? 'Seleziona i soggetti attuatori' : 'Seleziona i soggetti giuridici'
        };

        let modalDefinitionNgx = {
            rows: []
            , totalElements: 0
            , columns: [
                {
                    name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
                    , resizeable: false
                }

                , {
                    name: 'codice identificativo'
                    , prop: 'codiceIdentificativo'

                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 1
                    , minWidth: 60

                }
                , {
                    name: 'codice ipa'
                    , prop: 'codiceIpa'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 1
                    , minWidth: 60
                }
                , {
                    name: 'denominazione'
                    , prop: 'denominazione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 6
                    , minWidth: 200
                }
                , {
                    name: 'sigla provincia'
                    , prop: 'siglaProvincia'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 1
                    , minWidth: 60
                }
            ]
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , ajax: this.getSogggettiGiuridiciDTNgx
            , scrollbarH: "true"
        };


        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: 'modal-xl'
        };
        const initialState = { modalDefinition: modalDefinition, dtOptionsNgx: modalDefinitionNgx };

        this.modalRef = this.modalService.show(GenericSelectionModalComponent, Object.assign({}, config, { initialState }));
        this.clearSubscriptions();
        let subscription = this.modalRef.content.onSubmitOk.subscribe(
            data => {
                console.log('inside ok of openModalSoggettiGiuridici');
                let existsObj = this.soggettiGiuridici.find(x => x.id == data.id);
                if (existsObj) return;//already added
                data.descrizione = `${data.siglaProvincia} - ${data.codiceIdentificativo} - ${data.denominazione}`;
                this.soggettiGiuridici.push(data);
                this.soggettiGiuridici = Object.assign([]
                    , this.soggettiGiuridici
                );

                this.toastr.success('Soggetto inserito con successo');
                this.setIsDirty(true);
            }
        );
        this.modalSubscriptions.push(subscription);
    }

    getSogggettiGiuridiciDT = (dataTablesParameters: any, callback: any) => {
        this.soggettoGiuridicoService.getAllDt(dataTablesParameters)
            .subscribe(resp => {
                callback({
                    recordsTotal: resp.recordsTotal,
                    recordsFiltered: resp.recordsFiltered,
                    data: resp.data
                });
            });
    }
    getSogggettiGiuridiciDTNgx = (dataTablesParameters: any, callback: any) => {
        //regione lazio non è piu solo pnrr, tolgo il filtro da sotto altrimenti non mi esce tra i valori
        //if (this.f.pnrrRilevante.value == '1') dataTablesParameters.columns[2].search.value = 'pnrr';
        this.soggettoGiuridicoService.getAllDt(dataTablesParameters)
            .subscribe(resp => {
                callback(resp);
            });
    }

    gotoBackList() {
        if (!this.isDirtyHelper.checkIsDirty()) return false;
        /*if(this.canDeactivate()){
            if (!confirm('Proseguendo le modifiche correnti andranno perse, continuare?'))return false;
        }
        this.setIsDirty(false);*/
        if (this.isDetailView || !this.showBackButton) {
            this.closeModalEmitter.emit(true);
        }
        this.mode = 'list';
        this.ngxDatatableWrapper.refresh();

        this.disableButtonEmit.emit(false);
    }

    @Output()
    closeModalEmitter: EventEmitter<any> = new EventEmitter();

    @Output()
    saveElementEmitter: EventEmitter<any> = new EventEmitter();

    fakeObj = {};
    @ViewChild('templateAddScopoProvvedimento', { static: true })
    public templateAddScopoProvvedimento: TemplateRef<any>;

    @ViewChild('templateAddTipoProvvedimento', { static: true })
    public templateAddTipoProvvedimento: TemplateRef<any>;

    openAddScopoProvvedimento() {
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: 'modal-lg'
        };
        this.modalRef = this.modalService.show(this.templateAddScopoProvvedimento, config);
    }

    openAddTipoProvvedimento() {
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: 'modal-lg'
        };
        this.modalRef = this.modalService.show(this.templateAddTipoProvvedimento, config);
    }
    refreshTipoProvvedimentoList($event) {
        this.tipiProvvedimento = [...this.tipiProvvedimento, $event];
        this.closeModal();
    }

    refreshScopoProvvedimentoList($event) {
        this.scopiProvvedimento = [...this.scopiProvvedimento, $event];
        this.closeModal();
    }

    closeModal() {
        if (this.modalRef) this.modalRef.hide();
    }

    showBackButton: boolean = true;
    isInFastInsert = false;
    @Input()
    set provvedimentoDaMostrare(obj: any) {
        if (!obj) return;
        this.isInModal = true;
        this.showBackButton = false;
        if (obj.id === -1) {
            this.isInFastInsert = true;
            this.onInsert();
        }
        else {
            this.onDetail(obj);
        }
    }
    isDetailView: boolean = this.enableSection(this.section, ['read'], true, false);
    onDetail(row) {
        if (this.loading) return;
        this.provvedimentoId = row.id;
        this.isDetailView = true;
        this.submitted = false;
        this.documenti = row.documenti;
        this.capitoliBilancio = row.provvedimentoCapitoliBilancio;
        this.soggettiGiuridici = row.soggettiGiuridici;
        this.pnrrRipartizioneRisorse = row['pnrrRipartizioneRisorse'];
        this.pnrrLineeFinanziamento = row['pnrrLineeFinanziamento'];
        if (this.pnrrLineeFinanziamento) this.pnrrLineeFinanziamento.sort((a, b) => a.pnrrLineaFinanziamento.codice < b.pnrrLineaFinanziamento.codice ? -1 : 1);
        if (this.pnrrRipartizioneRisorse) this.pnrrRipartizioneRisorse.sort((a, b) =>
            a.pnrrLineaFinanziamento.codice == b.pnrrLineaFinanziamento.codice ? a.anno - b.anno :
                a.pnrrLineaFinanziamento.codice < b.pnrrLineaFinanziamento.codice ? -1 : 1
        );
        this.mode = 'insertedit';
        let data = moment(row.data).toDate();
        data.setHours(data.getHours() - data.getTimezoneOffset() / 60);


        let pnrrDataGu = null;
        if (row.pnrrDataGu) {
            pnrrDataGu = moment(row.pnrrDataGu).toDate();
            pnrrDataGu.setHours(pnrrDataGu.getHours() - pnrrDataGu.getTimezoneOffset() / 60);
        }

        this.addSchoolYearForm.setValue({
            id: row.id,
            data: data,
            tipo: row.tipoProvvedimento.id,
            scopo: row.scopoProvvedimento ? row.scopoProvvedimento.id : null,
            numero: row.numero
            , oggetto: row.oggetto ? row.oggetto : ''
            , pnrrRilevante: row.pnrrRilevante ? "1" : "0"
            , note: row.note ? row.note : ''
            , pnrrSoggettoProponente: row.pnrrSoggettoProponente ? row.pnrrSoggettoProponente.id : null
            , pnrrPubblicatoInGu: row.pnrrPubblicatoInGu ? '1' : '0'
            , pnrrNumeroGu: row.pnrrNumeroGu ? row.pnrrNumeroGu : ''
            , pnrrDataGu: pnrrDataGu
            , pnrrRegione: '0'
            , fcRegione: '0'
        });
        this.addSchoolYearForm.disable();
        this.clearSubscriptions();

        this.loadProtocolliTable();
        this.currentNewProtocolloId = -1;
        this.protocolli = [];
        this.sicerAtti = [];
        this.soggettiGiuridici = this.soggettiGiuridici.map(i => { i.descrizione = `${i.siglaProvincia} - ${i.codiceIdentificativo} - ${i.denominazione}`; return i; });
        this.calcolaValoriRipartizioneRegione();

        //this.pnrrPubblicatoInGuChange();
    }

    //gestione files

    documenti = [];
    currentDocumentNewId = 0;

    setIsDirty(value: boolean) {
        console.log(`setIsDirty with ${value}`);
        this.isDirty = value;
        this.isDirtyHelper.isDirty = value;
    }

    addDocumento() {
        if (!this.documenti) this.documenti = [];
        this.documenti = [{ id: --this.currentDocumentNewId, filename: '', status: '', originalFileName: '' }, ...this.documenti];
        this.setIsDirty(true);
    }
    removeDocumento(item) {
        if (!this.documenti) return;
        //this.documenti = this.documenti.filter(x=>x.id !=item.id);
        this.documenti.forEach(x => {
            if (x.id == item.id) { x['status'] = 'delete'; }
        });
        this.documenti = [...this.documenti];
        this.setIsDirty(true);
    }
    onFileChanged($event) {
        console.log('onfileChanged');
    }
    onAfterFileUpload($event) {
        console.log('onAfterFileUpload');
        let filePath = $event.filePath;
        let id = $event.name;
        let originalFileName = $event.originalFileName;
        let document = this.documenti.forEach(x => {
            if (x.id == id) {
                x['filename'] = filePath;
                x['status'] = 'update';
                x['originalFileName'] = originalFileName;
            }
        });
        this.setIsDirty(true);

    }
    onDeleteFile($event) { }

    onDownloadFile($event) {
        this.currentService.downloadDocument(this.addSchoolYearForm.controls.id.value, $event).subscribe(
            res => {
                let filename = $event.originalFileName;
                FileSaver.saveAs(
                    res.body,
                    `${filename}`
                );
            }
        );
    }
    hasDocuments() {
        return this.documenti && this.documenti.filter(x => x['status'] != 'delete').length > 0;
    }

    confirmChangeTab($event, index) {
        if (!this.isDirtyHelper.checkIsDirty()) {
            $event.stopPropagation();
            return false;
        }
        let oldval = this.provvedimentoId;
        console.log(`finanziamentoId = ${this.provvedimentoId}`);
        this.provvedimentoId = null;
        window.setTimeout(
            x => { console.log(`timeout, oldval = ${oldval}`); this.provvedimentoId = oldval; }, 10
        );
        switch (index) {
            case 0:
                this.activeTab = "tabDatiGenerali";
                break;
            case 1:
                this.activeTab = "tabStorico";
                if (this.divTemplateStorico) this.divTemplateStorico.reloadAllData();
                break;
            case 2:
                this.activeTab = "tabDettaglio";
                if (this.divTemplateDettaglio) this.divTemplateDettaglio.reloadAllData();
                break;
        }
    }
    isInModal = false;

    /**** PROTOCOLLI */
    /*****PROSA *******/
    frm_protocolli: FormGroup;
    editNoteProtocollo: FormGroup;



    loadProtocolliTable() {
        /*this.loadDataProtocolliEntrata();
        this.loadDataProtocolliUscita();
        this.loadDataProtocolliInterni(); */
        this.loadAllProtocolliData();
        this.protocolliDeleted = [];
        this.loadSicerAtti();
    }


    allProtocolli = [];
    get protocolliEntrata() {
        return this.allProtocolli ? this.allProtocolli.filter(x => x.tipoProtocollo == 'I') : [];
    }
    get protocolliUscita() {
        return this.allProtocolli ? this.allProtocolli.filter(x => x.tipoProtocollo == 'U') : [];
    }
    get protocolliInterni() {
        return this.allProtocolli ? this.allProtocolli.filter(x => x.tipoProtocollo == 'D') : [];
    }

    loadAllProtocolliData() {
        let currentId = this.addSchoolYearForm.controls.id.value;
        this.allProtocolli = [];
        if (!currentId) return;
        this.protocolloService.getAllData(currentId, 'PROVVEDIMENTI', '').subscribe(result => this.allProtocolli = result);
    }


    loadSicerAtti() {
        let currentId = this.addSchoolYearForm.controls.id.value;
        this.allProtocolli = [];
        if (!currentId) return;
        this.sicerService.getAllData(currentId, 'PROVVEDIMENTI').subscribe(result => this.sicerAttiSelezionati = result);
    }


    protocolli = [];

    currentNewProtocolloId = -1;
    protocolliDeleted = [];

    acquisisciProtocolli() {
        let anno = this.frm_protocolli.controls.protocollo_anno.value;
        let numero = this.frm_protocolli.controls.protocollo_numero.value;

        /*this.protocolli =
        [{
          "contenuto": "",
          "dataDocumento": "2020-10-25T23:00:00.000Z",
          "dataProtocollo": "2020-10-26T00:00:00.000Z",
          "esito": {
              "codiceEsito": "000",
              "descrizioneEsito": "Operazione eseguita senza errori"
          },
          "id": 4098022,
          "isContenuto": true,
          "mittentiDestinatari": [],
          "nomeFileContenuto": "REGLAZIO.REGISTRO UFFICIALE.2020.0914708.pdf",
          "note": "",
          "numeroProtocollo": "914708",
          "numeroProtocolloEsterno": "161892",
          "oggetto": "POSTA CERTIFICATA: Ferrovia Regionale Roma- Civitacastellana- Viterbo. Necessità finanziamenti manutenzione straordinaria ed interventi di rinnovo dell'armamento ferroviario.  - ATAC S.p.A. - Protocollo numero 161892 del 26/10/2020",
          "registro": "REGISTRO UFFICIALE",
          "timbro": false,
          "tipoProtocollo": "I",
          "ufficioCompetente": " GR3411 - PIANIFICAZIONE DEL TRASPORTO PUBBLICO REGIONALE E PROGRAMMAZIONE E GESTIONE DELLE FERROVIE GR3400 - INFRASTRUTTURE E MOBILITA'",
          "vociTitolario": [],
          "allegati": [{
                  "nome": "documentoprotocollo.pdf",
                  "codice": "4098022"
              }, {
                  "nome": "__body.html",
                  "codice": "8363775"
              }
          ]
      }
      ];
        return;*/

        this.loading = true;
        this.protocolloService.getAll(anno, numero).subscribe(
            result => { this.protocolli = result; this.loading = false; }
        );
    }


    @ViewChild('templateEditNoteModal', { static: true })
    public templateEditNoteModal: TemplateRef<any>;
    editProsaDocument(data) {
        this.editNoteProtocollo.controls.note_protocollo.setValue(data.note);
        this.editNoteProtocollo.controls.id.setValue(data.id);
        this.editNoteProtocollo.controls.tipo.setValue('protocollo');
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-lg"
        };
        this.modalRef = this.modalService.show(this.templateEditNoteModal, config);
    }

    closeModalEditProsaNote() {
        if (this.modalRef) this.modalRef.hide();
    }

    salvaNoteProtocollo() {
        let note = this.editNoteProtocollo.controls.note_protocollo.value;
        let id = this.editNoteProtocollo.controls.id.value;
        const tipo = this.editNoteProtocollo.controls.tipo.value;
        this.closeModalEditProsaNote();

        if (tipo == 'protocollo') {
            let protocollo = this.allProtocolli.find(x => x.id == id);
            if (!protocollo) {
                this.toastr.error(`Protocollo non trovato`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                return;
            }
            if (protocollo['status'] != 'NEW') protocollo.status = 'EDIT';
            protocollo.note = note;
            this.toastr.success("Note del protocollo salvate con successo", null, {
                timeOut: 2000,
                disableTimeOut: false,
            });
            this.setIsDirty(true);
        }
        if (tipo == 'atto') {
            let atto = this.sicerAttiSelezionati.find(x => x.numero == id);
            if (atto) atto.note = note;
            this.toastr.success("Note dell'atto salvate con successo", null, {
                timeOut: 2000,
                disableTimeOut: false,
            });
            this.setIsDirty(true);
        }



    }

    trimText(text, size) {
        if (!size) return text;
        if (text.length <= size) return text;
        return text.substring(0, size) + '...';
    }
    printDateValue(value) {
        let v = moment(value).toDate();
        //v.setHours(v.getHours() - v.getTimezoneOffset() / 60);
        return moment(v).format('DD/MM/YYYY');
    }
    downloadDocumento(codice, nome) {
        console.log(`${codice} - ${nome}`);
        this.protocolloService.isPresente(codice, nome).subscribe(
            result => {
                if (result) {
                    this.protocolloService.download(codice, nome).subscribe(
                        res => {
                            let filename = nome.replace(/^.*[\\\/]/, "");
                            FileSaver.saveAs(res.body, filename);
                        }
                    );
                }
                else {
                    this.toastr.error(`Documento ${nome} non presente su Prosa`, null, {
                        timeOut: 3000,/*closeButton:true,*/ disableTimeOut: false
                    });
                }
            }
        );
    }


    aggiungiProtocollo(numero) {
        let protocollo = this.protocolli.find(x => x.numeroProtocollo == numero);
        if (!protocollo) {
            this.toastr.error(`Documento ${numero} non trovato`, null, {
                timeOut: 3000,/*closeButton:true,*/ disableTimeOut: false
            });
            return;
        }

        if (this.allProtocolli.find(x => x.numeroProtocollo == protocollo.numeroProtocollo)
        ) {
            this.toastr.warning(`Protocollo già presente`, null, {
                timeOut: 3000, disableTimeOut: false
            });
            return;
        }


        let id = this.currentNewProtocolloId--;
        protocollo.id = id;
        protocollo.status = 'NEW';
        this.allProtocolli.push(protocollo);

        this.setIsDirty(true);
        this.toastr.success("Documento aggiunto con successo", null, {
            timeOut: 2000,
            disableTimeOut: false,
        });

    }

    deleteProsaDocument(data: any) {
        const result: boolean = confirm(
            `Sei sicuro di voler eliminare il protocollo selezionato?`
        );
        if (result) {
            this.allProtocolli = this.allProtocolli.filter(x => x.numeroProtocollo != data.numeroProtocollo);
            //protocolliDeleted
            if (data['status'] != 'NEW') {
                this.protocolliDeleted.push(data);
            }
            this.toastr.success("Protocollo eliminato con successo", null, {
                timeOut: 2000,
                disableTimeOut: false,
            });
            this.setIsDirty(true);
        }
    }

    //sicer atti
    frm_sicer: FormGroup;

    sicerAtti = [];
    sicerAttiSelezionati = [];
    downloadSicer(item) {
        this.sicerService.download(item.anno, item.numero,item.direzione).subscribe(
            x => {
                let filename = item.numero + '.pdf';
                FileSaver.saveAs(
                    x.body,
                    `${filename}`
                );
            }
        );
    }
    acquisisciSicerAtti() {
        let anno = this.frm_sicer.controls.sicer_anno.value;
        let numero = this.frm_sicer.controls.sicer_numero.value;
        this.loading = true;
        this.sicerService.getAll(anno, numero).subscribe(
            result => { this.sicerAtti = result; this.loading = false; }
        );
    }
    aggiungiSicerAtto(item) {
        if (!this.sicerAttiSelezionati) this.sicerAttiSelezionati = [];
        if (this.sicerAttiSelezionati.length > 0) {
            this.toastr.warning("E' possibile aggiungere soltanto un atto al provvedimento, operazione annullata", null, {
                timeOut: 2000,
                disableTimeOut: false,
            });
            return;
        }
        item.note = '';
        this.sicerAttiSelezionati.push(item);
        this.f.oggetto.setValue(item.oggetto);
        this.f.numero.setValue(item.numero);
        this.f.data.setValue(new Date((item.data)));
        const tipo = this.tipiProvvedimento.find(x => x.provvedimento.toLowerCase() == item.tipo.toLowerCase());
        if (tipo) this.f.tipo.setValue(tipo.id);
        this.setIsDirty(true);

    }
    deleteSicerAtto(item) {
        if (!this.sicerAttiSelezionati) this.sicerAttiSelezionati = [];
        this.sicerAttiSelezionati = this.sicerAttiSelezionati.filter(x => x.numero != item.numero);
        this.setIsDirty(true);
    }
    editSicerAtto(item) {
        this.editNoteProtocollo.controls.note_protocollo.setValue(item.note);
        this.editNoteProtocollo.controls.id.setValue(item.numero);
        this.editNoteProtocollo.controls.tipo.setValue('atto');
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-lg"
        };
        this.modalRef = this.modalService.show(this.templateEditNoteModal, config);
    }

    printValueAsCurrency(value) {
        if (!value) value = 0;
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }



    @ViewChild('templateAddPnrrRipartizioneRisorse', { static: true })
    public templateAddPnrrRipartizioneRisorse: TemplateRef<any>;

    pnrrRipartizioneRisorse = [];
    pnrrRipartizioneRisorseIsInError = false;
    pnrrRipartizioneRisorseForm: FormGroup;
    submittedPnrrRisorsa = false;

    soggettiGiuridiciList = [];
    ruoliSoggettiGiuridiciList = [];


    getSoggettiGiuridici() {
        this.soggettoGiuridicoService.getAll().subscribe(
            res => {
                let ress: any[]; ress = res; ress.map((i) => {
                    i.descrizione = `${i.siglaProvincia} - ${i.codiceIdentificativo} - ${i.denominazione}`;
                    return i;
                });
                this.soggettiGiuridiciList = ress;

                this.soggettiProponenti = ress.filter(x => x.codiceIdentificativo && (x.codiceIdentificativo.toLowerCase() == 'propon_pnrr' || x.codiceIdentificativo.toLowerCase() == 'ammtit_pnrr'));
            }
        );
    }

    getRuoliSoggettiGiuridici() {
        this.soggettoRuoloService.getAll().subscribe(
            res => {
                this.ruoliSoggettiGiuridiciList = res.filter(x => x['codice'] == 'SOGGETTO_ATTUATORE_RIL_BIL' || x['codice'] == 'SOGGETTO_ATTUATORE');
            }
        );
    }

    public mask: any = {
        mask: 'KK €',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            }
        }
    };
    public maskYear: any = {
        mask: 'KK',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                /*thousandsSeparator: '.',*/
                scale: 0,
                padFractionalZeros: false,
                radix: ','
                , min: 1950
            }
        }
    };


    openModalPnrrRipartizioneRisorse() {
        this.pnrrLineaFinanziamentoSelectedIsError = false;
        this.pnrrLineaFinanziamentoSelected = null;
        this.pnrrRipartizioneRisorseForm.reset();
        this.submittedPnrrRisorsa = false;
        this.soggettiGiuridici = this.soggettiGiuridici.map(i => { i.descrizione = `${i.siglaProvincia} - ${i.codiceIdentificativo} - ${i.denominazione}`; return i; });
        this.soggettiGiuridiciTmp = Object.assign([]
            , this.soggettiGiuridici
        );
        const config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl"
        };
        this.modalRef = this.modalService.show(this.templateAddPnrrRipartizioneRisorse, config);
    }



    removePnrrRipartizioneRisorse(item) {
        this.pnrrRipartizioneRisorse = this.pnrrRipartizioneRisorse.filter(
            x => !(x.anno == item.anno
                && x.soggettoGiuridico.id == item.soggettoGiuridico.id
                && item.ruoloSoggettoGiuridico.id == x.ruoloSoggettoGiuridico.id
                && x.pnrrLineaFinanziamento.id == item.pnrrLineaFinanziamento.id
            )
        );
        this.setIsDirty(true);
        this.calcolaValoriRipartizioneRegione();
        this.toastr.success("Ripartizione risorsa PNRR rimossa con successo", null, {
            timeOut: 2000,
            disableTimeOut: false,
        });
    }

    calcolaValoriRipartizioneRegione() {
        if (this.pnrrRipartizioneRisorse) {
            const sarArray = this.pnrrRipartizioneRisorse
                .filter(b => b.ruoloSoggettoGiuridico && b.ruoloSoggettoGiuridico.codice && b.ruoloSoggettoGiuridico.codice.toUpperCase() == 'SOGGETTO_ATTUATORE_RIL_BIL');
            const pnrrRegione = sarArray.reduce((a, b) => a + parseFloat(b.valorePnrr) || 0, 0);
            const fcRegione = sarArray.reduce((a, b) => a + parseFloat(b.valoreFc) || 0, 0);
            this.f.pnrrRegione.setValue(pnrrRegione.toString());
            this.f.fcRegione.setValue(fcRegione.toString());
        }
        else {
            this.f.pnrrRegione.setValue('0');
            this.f.fcRegione.setValue('0');
        }
    }

    initializeFormGroupPnrrRipartizione() {
        let grp = this.formBuilder.group({
            anno: ["", [Validators.required]],
            valorePnrr: [null, [Validators.required]],
            valoreFc: [null, [Validators.required]],
            soggettoGiuridico: [null, [Validators.required]],
            ruoloSoggettoGiuridico: [null, [Validators.required]]
        });
        return grp;

    }
    get formPnrrRipartizioneRisorse() {
        return this.pnrrRipartizioneRisorseForm.controls;
    }

    addPnrrRisorsa() {
        this.submittedPnrrRisorsa = true;
        this.pnrrRipartizioneRisorseForm.updateValueAndValidity();

        let errors = false;
        this.pnrrLineaFinanziamentoSelectedIsError = !this.pnrrLineaFinanziamentoSelected;
        //this.capitoliBilancioIsError = !(this.capitoliBilancio && this.capitoliBilancio.length && this.capitoliBilancio.length > 0);
        for (let c in this.formPnrrRipartizioneRisorse) {
            this.formPnrrRipartizioneRisorse[c].updateValueAndValidity();
            errors = errors || this.formPnrrRipartizioneRisorse[c].errors !== null;
        }
        if (this.pnrrRipartizioneRisorseForm.invalid || errors || this.pnrrLineaFinanziamentoSelectedIsError) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.pnrrRipartizioneRisorseForm.controls) {
                if (this.pnrrRipartizioneRisorseForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.pnrrRipartizioneRisorseForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.pnrrRipartizioneRisorseForm.get(el)["controls"]) {
                            if (this.pnrrRipartizioneRisorseForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }

        this.ripartizioneSoggettoGiuridicoChange();

        const newObj = {
            valorePnrr: this.formPnrrRipartizioneRisorse.valorePnrr.value,
            valoreFc: this.formPnrrRipartizioneRisorse.valoreFc.value,
            anno: this.formPnrrRipartizioneRisorse.anno.value,
            soggettoGiuridico: this.soggettiGiuridiciList.find(x => x.id == this.formPnrrRipartizioneRisorse.soggettoGiuridico.value),
            ruoloSoggettoGiuridico: this.ruoliSoggettiGiuridiciList.find(x => x.id == this.formPnrrRipartizioneRisorse.ruoloSoggettoGiuridico.value)
            , pnrrLineaFinanziamento: this.pnrrLineaFinanziamentoSelected
        };
        if (!this.pnrrRipartizioneRisorse) this.pnrrRipartizioneRisorse = [];
        if (this.pnrrRipartizioneRisorse.find(
            x => x.anno == newObj.anno
                && x.soggettoGiuridico.id == newObj.soggettoGiuridico.id
                && newObj.ruoloSoggettoGiuridico.id == x.ruoloSoggettoGiuridico.id
                && x.pnrrLineaFinanziamento.id == newObj.pnrrLineaFinanziamento.id
        )) {
            this.toastr.error("Esiste già il soggetto giuridico per la linea di finanziamento PNRR/PNC, anno ed il ruolo selezionati", null, {
                timeOut: 2000,
                disableTimeOut: false,
            });
            return;
        }
        this.pnrrRipartizioneRisorse.push(newObj);
        this.pnrrRipartizioneRisorse.sort((a, b) =>
            a.pnrrLineaFinanziamento.codice == b.pnrrLineaFinanziamento.codice ? a.anno - b.anno :
                a.pnrrLineaFinanziamento.codice < b.pnrrLineaFinanziamento.codice ? -1 : 1
        );

        this.calcolaValoriRipartizioneRegione();
        this.setIsDirty(true);
        this.toastr.success("Ripartizione risorsa PNRR/PNC aggiunta con successo", null, {
            timeOut: 2000,
            disableTimeOut: false,
        });
        this.closeModal();
    }

    getPnrrLineeFinanziamento() {
        this.pnrrService.getAllLineaFinanziamento().subscribe(
            x => {
                x.sort((a, b) => a['codice'] < b['codice'] ? -1 : 1);
                this.pnrrLineeFinanziamentoList = x;
                this.pnrrLineeFinanziamentoList = this.pnrrLineeFinanziamentoList.map(y => {
                    y['descrizioneEstesa'] = `${y.codice} - ${y.descrizione}`;
                    return y;
                }
                );
            }
        );
    }

    isPnrrRipartizioneRisorseVisible() {
        return this.f.pnrrRilevante.value && this.f.pnrrRilevante.value == '1';
    }


    pnrrRilevanteChange() {
        const isRilevante = this.f.pnrrRilevante.value == '1';
        if (isRilevante) this.setPnrrValidators();
        else this.unsetPnrrValidators();
        this.pnrrPubblicatoInGuChange();
    }

    setPnrrValidators() {
        this.addSchoolYearForm.controls['pnrrSoggettoProponente'].setValidators([Validators.required]);
        this.addSchoolYearForm.controls['pnrrSoggettoProponente'].updateValueAndValidity();
        this.addSchoolYearForm.controls['pnrrPubblicatoInGu'].setValidators([Validators.required]);
        this.addSchoolYearForm.controls['pnrrPubblicatoInGu'].updateValueAndValidity();
        this.pnrrPubblicatoInGuChange();
    }
    unsetPnrrValidators() {
        this.addSchoolYearForm.controls['pnrrSoggettoProponente'].clearValidators();
        this.addSchoolYearForm.controls['pnrrSoggettoProponente'].updateValueAndValidity();
        this.addSchoolYearForm.controls['pnrrPubblicatoInGu'].clearValidators();
        this.addSchoolYearForm.controls['pnrrPubblicatoInGu'].updateValueAndValidity();
        this.pnrrPubblicatoInGuChange();
    }

    setPnrrAvvisoValidators() {
        //this.pnrrLineaFinanziamentoForm.controls['assorbimentoDaDecreto'].setValidators([Validators.required]);
        //this.pnrrLineaFinanziamentoForm.controls['assorbimentoDaDecreto'].updateValueAndValidity();
        this.pnrrLineaFinanziamentoForm.controls['scadenza'].setValidators([Validators.required]);
        this.pnrrLineaFinanziamentoForm.controls['scadenza'].updateValueAndValidity();
    }
    unsetPnrrAvvisoValidators() {
        //this.pnrrLineaFinanziamentoForm.controls['assorbimentoDaDecreto'].clearValidators();
        //this.pnrrLineaFinanziamentoForm.controls['assorbimentoDaDecreto'].updateValueAndValidity();
        this.pnrrLineaFinanziamentoForm.controls['scadenza'].clearValidators();
        this.pnrrLineaFinanziamentoForm.controls['scadenza'].updateValueAndValidity();
    }

    pnrrAvvisoChange() {
        console.log(this.pnrrLineaFinanziamentoForm.controls.avviso.value);
        const isAvviso = this.pnrrLineaFinanziamentoForm.controls.avviso.value == '1';
        if (isAvviso) this.setPnrrAvvisoValidators();
        else this.unsetPnrrAvvisoValidators();
        //this.assorbimentoDaDecretoChange();
    }


    pnrrPubblicatoInGuChange() {
        const isPubblicato = this.f.pnrrPubblicatoInGu.value == '1';
        const isRilevante = this.f.pnrrRilevante.value == '1';
        if (isPubblicato && isRilevante) this.setPnrrPubblicatoInGuValidators();
        else this.unsetPnrrPubblicatoInGuValidators();

    }
    setPnrrPubblicatoInGuValidators() {
        this.addSchoolYearForm.controls['pnrrNumeroGu'].setValidators([Validators.required]);
        this.addSchoolYearForm.controls['pnrrNumeroGu'].updateValueAndValidity();
        this.addSchoolYearForm.controls['pnrrDataGu'].setValidators([Validators.required]);
        this.addSchoolYearForm.controls['pnrrDataGu'].updateValueAndValidity();
    }
    unsetPnrrPubblicatoInGuValidators() {
        this.addSchoolYearForm.controls['pnrrNumeroGu'].clearValidators();
        this.addSchoolYearForm.controls['pnrrNumeroGu'].updateValueAndValidity();
        this.addSchoolYearForm.controls['pnrrDataGu'].clearValidators();
        this.addSchoolYearForm.controls['pnrrDataGu'].updateValueAndValidity();
    }

    setPnrDecretoAssorbimentoValidators() {
        this.pnrrLineaFinanziamentoForm.controls['decretoAssorbimento'].setValidators([Validators.required]);
        this.pnrrLineaFinanziamentoForm.controls['decretoAssorbimento'].updateValueAndValidity();
    }
    unsetPnrDecretoAssorbimentoValidators() {
        this.pnrrLineaFinanziamentoForm.controls['decretoAssorbimento'].clearValidators();
        this.pnrrLineaFinanziamentoForm.controls['decretoAssorbimento'].updateValueAndValidity();
    }

    assorbimentoDaDecretoChange() {
        const assorbimentoDaDecreto = this.pnrrLineaFinanziamentoForm.controls.assorbimentoDaDecreto.value == '1';
        //const avviso = this.pnrrLineaFinanziamentoForm.controls.avviso.value=='1';
        if (assorbimentoDaDecreto) this.setPnrDecretoAssorbimentoValidators();
        else this.unsetPnrDecretoAssorbimentoValidators();

    }

    decretoAssorbimentoChange($event) {
        const val = this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.value;
        if (!val) {
            this.pnrrLineaFinanziamentoForm.controls.rilevanteNazionale.enable();
        }
        else {
            const provv = this.provvedimentiPnrrList.find(x => x.id == val);
            if (provv && this.pnrrLineaFinanziamentoSelected) {
                const linea = provv.pnrrLineeFinanziamento.find(x => x.pnrrLineaFinanziamento.id == this.pnrrLineaFinanziamentoSelected.id);
                if (linea) this.pnrrLineaFinanziamentoForm.controls.rilevanteNazionale.setValue(linea.rilevanteNazionale ? '1' : '0');
                this.pnrrLineaFinanziamentoForm.controls.quotaAssegnataPnrr.setValue(linea.quotaAssegnataDecretoAssorbimentoPnrr.toString());
                this.pnrrLineaFinanziamentoForm.controls.quotaDisponibilePnrr.setValue((linea.importoPnrr - linea.quotaAssegnataDecretoAssorbimentoPnrr).toString());
                this.pnrrLineaFinanziamentoForm.controls.quotaAssegnataFc.setValue(linea.quotaAssegnataDecretoAssorbimentoFc.toString());
                this.pnrrLineaFinanziamentoForm.controls.quotaDisponibileFc.setValue((linea.importoFc - linea.quotaAssegnataDecretoAssorbimentoFc).toString());
            }

            this.pnrrLineaFinanziamentoForm.controls.rilevanteNazionale.disable();
        }
    }


    @ViewChild('templateAddPnrrLineaFinanziamento', { static: true })
    public templateAddPnrrLineaFinanziamento: TemplateRef<any>;


    pnrrLineeFinanziamento = [];
    pnrrLineeFinanziamentoList = [];
    pnrrLineeFinanziamentoIsError = false;
    pnrrLineaFinanziamentoForm: FormGroup;
    pnrrLineaFinanziamentoNewId = -1;
    soggettiProponenti = [];
    submittedPnrrLineaFinanziamento = false;
    decretoSelezionato = null;
    decretoSelezionatoIsError = false;
    pnrrStatiAvanzamento = [
        { value: '01 Bozza/Schema' },
        { value: '02 Firmato' },
        { value: '03 In Fase di conversione/Acquisizione pareri Intesa' },
        { value: '04 Approvato' },
        { value: '05 Pubblicato GU' }
    ];


    isEditPnrrLineaFinanziamento = false;
    editPnrrLineaFinanziamento(item) {

        this.openModalPnrrLineeFinanziamento(item);
    }

    openModalPnrrLineeFinanziamento(item = null) {
        this.pnrrLineaFinanziamentoForm.reset();
        this.submittedPnrrLineaFinanziamento = false;
        this.decretoSelezionatoIsError = false;
        this.provvedimentiPnrrListPerLineaFinPnrr = [];

        const config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl"
        };
        this.modalRef = this.modalService.show(this.templateAddPnrrLineaFinanziamento, config);
        this.pnrrLineaFinanziamentoSelected = null;
        this.isEditPnrrLineaFinanziamento = false;
        if (item) {
            this.isEditPnrrLineaFinanziamento = true;
            let scadenza = null;
            if (item.scadenza) {
                scadenza = moment(item.scadenza).toDate();
                scadenza.setHours(scadenza.getHours() - scadenza.getTimezoneOffset() / 60);
            }
            const disponibilePnrr = (item.pnrrLineaFinanziamento.pnrr ?
                parseFloat(item.pnrrLineaFinanziamento.pnrr) : 0)
                - (item.pnrrLineaFinanziamento.quotaAssegnataPnrr
                    ? parseFloat(item.pnrrLineaFinanziamento.quotaAssegnataPnrr) : 0
                );
            const disponibileFc = (item.pnrrLineaFinanziamento.fc ?
                parseFloat(item.pnrrLineaFinanziamento.fc) : 0)
                - (item.pnrrLineaFinanziamento.quotaAssegnataFc
                    ? parseFloat(item.pnrrLineaFinanziamento.quotaAssegnataFc) : 0
                );
            this.pnrrLineaFinanziamentoForm.setValue(
                {
                    id: item.id,
                    importoPnrr: item.importoPnrr,
                    importoFc: item.importoFc,
                    vecchioImportoPnrr: item.vecchioImportoPnrr,
                    vecchioImportoFc: item.vecchioImportoFc,
                    rilevanteNazionale: item.rilevanteNazionale ? '1' : '0',
                    scadenza: scadenza,
                    avviso: item.avviso ? '1' : '0'
                    , assorbimentoDaDecreto: item.assorbimentoDaDecreto ? '1' : '0'
                    /*,pnrrLineaFinanziamento:item.pnrrLineaFinanziamento?item.pnrrLineaFinanziamento.id:null*/
                    , decretoAssorbimento: item.decretoAssorbimento ? item.decretoAssorbimento.id : null
                    , pnrr: item.pnrrLineaFinanziamento.pnrr ? item.pnrrLineaFinanziamento.pnrr.toString() : '0'
                    , pnc: item.pnrrLineaFinanziamento.fc ? item.pnrrLineaFinanziamento.fc.toString() : '0'
                    , quotaDisponibilePnrr: disponibilePnrr
                    , quotaAssegnataPnrr: item.pnrrLineaFinanziamento.quotaAssegnataPnrr ? item.pnrrLineaFinanziamento.quotaAssegnataPnrr.toString() : '0'
                    , quotaDisponibileFc: disponibileFc
                    , quotaAssegnataFc: item.pnrrLineaFinanziamento.quotaAssegnataFc ? item.pnrrLineaFinanziamento.quotaAssegnataFc.toString() : '0'
                }
            );
            this.pnrrLineaFinanziamentoSelected = item.pnrrLineaFinanziamento;
            this.getAllPnrrProvvedimenti(this.pnrrLineaFinanziamentoSelected.id);

            if (this.pnrrLineaFinanziamentoSelected.isPnrrLineaFinanziamentoPrincipale) {
                if (!this.pnrrLineaFinanziamentoSelected.abilitaPnrr) {
                    this.pnrrLineaFinanziamentoForm.controls.importoPnrr.setValue('0');
                    this.pnrrLineaFinanziamentoForm.controls.importoPnrr.disable();
                }
                if (!this.pnrrLineaFinanziamentoSelected.abilitaFc) {
                    this.pnrrLineaFinanziamentoForm.controls.importoFc.setValue('0');
                    this.pnrrLineaFinanziamentoForm.controls.importoFc.disable();
                }

            }


        }
        this.checkAssorbimento();

        /*this.pnrrLineaFinanziamentoChange(
            this.pnrrLineeFinanziamentoList.find(x=>x.id ==
            this.pnrrLineaFinanziamentoForm.controls.pnrrLineaFinanziamento.value),false
            );*/
    }
    removePnrrLineaFinanziamento(item) {
        this.pnrrLineeFinanziamento.find(x => x.id == item.id).status = 'deleted';
        this.pnrrRipartizioneRisorse = this.pnrrRipartizioneRisorse.filter(
            x => x.pnrrLineaFinanziamento.id != item.pnrrLineaFinanziamento.id
        );


        this.setIsDirty(true);
        this.checkAssorbimento();
        //this.reloadSoggettiGiuridiciPossibili();
    }

    /*openModalSelectDecreto(){}
    rimuoviDecreto(item){
        this.decretoSelezionato = null;
    }*/

    initializeFormGroupPnrrLineaFinanziamentoForm() {
        let grp = this.formBuilder.group({
            id: [null],
            importoPnrr: [null, [Validators.required]],
            importoFc: [null, [Validators.required]],
            /*pnrrLineaFinanziamento: [null, [Validators.required]],*/
            rilevanteNazionale: [null, [Validators.required]]
            , assorbimentoDaDecreto: [null, [Validators.required]]
            , avviso: [null, [Validators.required]]
            , scadenza: [null]
            , decretoAssorbimento: [null]
            , pnrr: [null]
            , pnc: [null]
            , quotaAssegnataPnrr: [null]
            , quotaDisponibilePnrr: [null]
            , vecchioImportoPnrr: [null]
            , quotaAssegnataFc: [null]
            , quotaDisponibileFc: [null]
            , vecchioImportoFc: [null]
        });
        return grp;
    }

    pnrrLineaFinanziamentoChange($event, resetDecreto = true) {
        this.decretoSelezionato = null;
        if (resetDecreto) this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.setValue(null);
        this.provvedimentiPnrrListPerLineaFinPnrr = [];
        if ($event) {
            this.getAllPnrrProvvedimenti($event.id);
            //this.provvedimentiPnrrListPerLineaFinPnrr = this.provvedimentiPnrrList.filter(x=>x.pnrrLineeFinanziamento.find(y=>y.pnrrLineaFinanziamento.id == $event.id));
        }
    }

    savePnrrLineaFinanziamento() {
        this.submittedPnrrLineaFinanziamento = true;
        this.decretoSelezionatoIsError = false;
        let assorbimentoWasEnabled = this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.enabled;
        this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.enable();
        const provvedimentoAssorbimentoId = this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.value;
        if (!this.provvedimentiPnrrList || !this.provvedimentiPnrrList.find(x => x.id == provvedimentoAssorbimentoId)) {
            this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.setValue(null);
            this.decretoSelezionatoIsError = this.pnrrLineaFinanziamentoForm.controls.assorbimentoDaDecreto.value == '1';
        }
        this.pnrrLineaFinanziamentoForm.updateValueAndValidity();
        this.pnrrLineaFinanziamentoSelectedIsError = !this.pnrrLineaFinanziamentoSelected;

        let errors = false;

        //this.decretoSelezionatoIsError = !this.decretoSelezionato && this.pnrrLineaFinanziamentoForm.controls.assorbimentoDaDecreto.value=='1';
        //this.capitoliBilancioIsError = !(this.capitoliBilancio && this.capitoliBilancio.length && this.capitoliBilancio.length > 0);
        for (let c in this.pnrrLineaFinanziamentoForm.controls) {
            this.pnrrLineaFinanziamentoForm.controls[c].updateValueAndValidity();
            errors = errors || this.pnrrLineaFinanziamentoForm.controls[c].errors !== null;
        }
        if (this.pnrrLineaFinanziamentoForm.invalid || errors || this.pnrrLineaFinanziamentoSelectedIsError) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.pnrrLineaFinanziamentoForm.controls) {
                if (this.pnrrLineaFinanziamentoForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.pnrrLineaFinanziamentoForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.pnrrLineaFinanziamentoForm.get(el)["controls"]) {
                            if (this.pnrrLineaFinanziamentoForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }
            if (!assorbimentoWasEnabled) this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.disable();
            return false;
        }

        this.pnrrLineaFinanziamentoForm.enable();
        const obj = this.pnrrLineaFinanziamentoForm.value;
        obj.avviso = obj.avviso == '1';
        obj.assorbimentoDaDecreto = obj.assorbimentoDaDecreto == '1';
        obj.rilevanteNazionale = obj.rilevanteNazionale == '1';

        obj.pnrrLineaFinanziamento = this.pnrrLineaFinanziamentoSelected;


        if (!this.pnrrLineeFinanziamento) this.pnrrLineeFinanziamento = [];
        const exists = this.pnrrLineeFinanziamento.find(x => x.pnrrLineaFinanziamento.id == obj.pnrrLineaFinanziamento.id && x.id != obj.id);

        if (exists) {
            this.toastr.error("Esiste già la linea finanziamento PNRR/PNC", null, {
                timeOut: 2000,
                disableTimeOut: false,
            });
            return;
        }


        //controllo la disponibilità
        //da riflettere perche ci sono tante casistiche da gestire ,tipo la cancellazione di una riga salvata, nel disponibilita ancora non viene registrata.

        /*const disponibilita = obj.quotaDisponibile;
        const quota = obj.importo;
        const id = obj.id;
        if (!id || id<=0){
            //nuovo record
            if (parseFloat(quota)>parseFloat(disponibilita)){
                this.toastr.error("Il valore della quota supera il valore della disponibilità", null, {
                    timeOut: 2000,
                    disableTimeOut: false,
                });
                return;
            }
        }*/


        obj.decretoAssorbimento = this.provvedimentiPnrrList.find(x => x.id == obj.decretoAssorbimento);
        if (!(obj.assorbimentoDaDecreto)) obj.decretoAssorbimento = null;
        if (!obj.avviso) { obj.scadenza = null; }
        obj.id = obj.id ? obj.id : this.pnrrLineaFinanziamentoNewId--;
        obj.status = 'updated';
        if (obj.scadenza) obj.scadenza = moment(obj.scadenza, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss');
        //obj.pnrrLineaFinanziamento = this.pnrrLineeFinanziamentoList.find(x=>x.id == obj.pnrrLineaFinanziamento);
        this.pnrrLineeFinanziamento = this.pnrrLineeFinanziamento.filter(x => x.id != obj.id);



        this.pnrrLineeFinanziamento.push(obj);
        this.pnrrLineeFinanziamento.sort((a, b) => a.pnrrLineaFinanziamento.codice < b.pnrrLineaFinanziamento.codice ? -1 : 1);
        if (obj.rilevanteNazionale) {
            //devo togliere, se esitono le ripartizioni
            this.pnrrRipartizioneRisorse = this.pnrrRipartizioneRisorse.filter(x => x.pnrrLineaFinanziamento.id != obj.pnrrLineaFinanziamento.id);
        }

        this.closeModal();
        this.toastr.success("Linea finanziamento PNRR/PNC aggiunta con successo", null, {
            timeOut: 2000,
            disableTimeOut: false,
        });
        this.setIsDirty(true);
        this.checkAssorbimento();
        //this.reloadSoggettiGiuridiciPossibili();
    }

    soggettiGiuridiciTmp = [];
    assorbimentoIsReadonly = false;
    checkAssorbimento() {
        if (this.isPnrrRipartizioneRisorseVisible()) {
            const linea = (this.pnrrLineeFinanziamento.find(x => x.status != 'deleted' && x.assorbimentoDaDecreto));
            if (linea) {
                this.f.pnrrSoggettoProponente.setValue(linea.decretoAssorbimento.pnrrSoggettoProponente.id);
                this.f.pnrrSoggettoProponente.disable();
                for (const s of linea.decretoAssorbimento.soggettiGiuridici) {
                    if (!this.soggettiGiuridici.find(x => x.id == s.id)) this.soggettiGiuridici.push(s);
                }
                this.soggettiGiuridici = Object.assign([]
                    , this.soggettiGiuridici
                );
                this.pnrrLineaFinanziamentoForm.controls.assorbimentoDaDecreto.setValue('1');
                this.pnrrLineaFinanziamentoForm.controls.rilevanteNazionale.setValue(linea.rilevanteNazionale ? '1' : '0');
                this.assorbimentoIsReadonly = true;
                this.pnrrLineaFinanziamentoForm.controls.assorbimentoDaDecreto.disable();
                this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.disable();
                this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.setValue(linea.decretoAssorbimento.id);
                this.assorbimentoDaDecretoChange();

                this.getAllPnrrProvvedimenti(linea.pnrrLineaFinanziamento.id);
            }
            else {
                this.f.pnrrSoggettoProponente.enable();
                this.assorbimentoIsReadonly = false;
                this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.enable();
                this.pnrrLineaFinanziamentoForm.controls.assorbimentoDaDecreto.enable();
                this.assorbimentoDaDecretoChange();
            }
            this.decretoAssorbimentoChange(null);
        }
    }





    getPnrrLineeFinanziamentoWithoutDeleted() {
        if (!this.pnrrLineeFinanziamento) this.pnrrLineeFinanziamento = [];
        return this.pnrrLineeFinanziamento.filter(x => x.status != 'deleted');
    }

    provvedimentiPnrrList = [];
    provvedimentiPnrrListPerLineaFinPnrr = [];
    getAllPnrrProvvedimenti(lineaId) {
        this.currentService.getAllPnrrProvvedimenti(lineaId).subscribe(
            x => {
                this.provvedimentiPnrrList = x;
                this.provvedimentiPnrrList = this.provvedimentiPnrrList.map(x => {
                    x['descrizioneEstesa'] = `${x.numero} - ${x.tipoProvvedimento.provvedimento} - ${x.oggetto}`;
                    return x;
                });
                if (this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.value) {
                    if (!this.provvedimentiPnrrList.find(x => x.id == this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.value)) this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.setValue(null);
                }
            }
        );
    }



    pnrrLineaFinanziamentoSelected = null;
    pnrrLineaFinanziamentoSelectedIsError = false;

    modalRefSelectLineaFinPnrrFc: BsModalRef;

    closeModalRefSelectLineaFinPnrrFc() {
        if (this.modalRefSelectLineaFinPnrrFc) this.modalRefSelectLineaFinPnrrFc.hide();
    }


    removeSelectedPnrrLineaFinanziamento() {
        this.pnrrLineaFinanziamentoSelected = null;
        this.decretoSelezionato = null;
        //this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.setValue(null);
        this.provvedimentiPnrrListPerLineaFinPnrr = [];
        this.pnrrLineaFinanziamentoForm.controls.pnrr.setValue(null);
        this.pnrrLineaFinanziamentoForm.controls.pnc.setValue(null);
        this.pnrrLineaFinanziamentoForm.controls.quotaAssegnataPnrr.setValue(null);
        this.pnrrLineaFinanziamentoForm.controls.quotaDisponibilePnrr.setValue(null);
        this.pnrrLineaFinanziamentoForm.controls.quotaAssegnataFc.setValue(null);
        this.pnrrLineaFinanziamentoForm.controls.quotaDisponibileFc.setValue(null);
        this.setIsDirty(true);
        this.checkAssorbimento();
    }


    openModalRefSelectLineaFinPnrrFc() {
        let modalDefinition = {
            columns: [
                {
                    name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
                    , resizeable: false
                }

                , {
                    name: 'codice'
                    , prop: 'codice'

                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 1
                    , minWidth: 30

                },
                {
                    name: 'missione'
                    , prop: 'missione.descrizione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 35
                },
                {
                    name: 'componente'
                    , prop: 'componente.descrizione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 85
                },
                {
                    name: 'descrizione'
                    , prop: 'descrizione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 85
                }
                ,
                {
                    name: 'misura PNRR'
                    , prop: 'misuraPnrr'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 85
                }
                , {
                    name: 'tipologia'
                    , prop: 'tipologia'

                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 85

                }
                , {
                    name: 'pnrr'
                    , prop: 'pnrr'
                    , sortable: true
                    , filtrable: true
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 3
                    , minWidth: 70
                }
                , {
                    name: 'pnc'
                    , prop: 'fc'
                    , sortable: true
                    , filtrable: true
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 3
                    , minWidth: 70
                }
                , {
                    name: 'quota <br/> assegnata pnrr'
                    , prop: 'quotaAssegnataPnrr'
                    , sortable: true
                    , filtrable: true
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 3
                    , minWidth: 75

                }
                , {
                    name: 'quota <br/> assegnata pnc'
                    , prop: 'quotaAssegnataFc'
                    , sortable: true
                    , filtrable: true
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 3
                    , minWidth: 75
                }
            ],
            ajax: this.getLineeFinanziamentoPnrrDt,
            pageTitle: "Seleziona linea di finanziamento PNRR/PNC",
            rows: [],
            totalElements: 0,
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            scrollbarH: "true",
            sorts: [{ dir: 'asc', prop: 'codice' }]
        };

        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl",
        };
        const initialState = {
            modalDefinition: modalDefinition,
            dtOptionsNgx: modalDefinition,
        };

        this.modalRefSelectLineaFinPnrrFc = this.modalService.show(
            GenericSelectionModalComponent,
            Object.assign({}, config, { initialState })
        );
        console.log("apertura modale GenericSelectionModalComponent");
        this.clearSubscriptions();
        let subscription = this.modalRefSelectLineaFinPnrrFc.content.onSubmitOk.subscribe((data) => {
            console.log("inside ok of lineepnrr");
            if (this.pnrrLineaFinanziamentoSelected) {
                this.toastr.error("Esiste già una linea di finanziamento PNRR/PNC associata", null, {
                    timeOut: 2000,
                    disableTimeOut: false,
                });
                return;
            }
            this.pnrrLineaFinanziamentoSelected = data;



            this.toastr.success("Linea di finanziamento PNRR/PNC aggiunta con successo", null, {
                timeOut: 2000,
                disableTimeOut: false,
            });
            this.setIsDirty(true);
            this.closeModalRefSelectLineaFinPnrrFc();
            this.decretoSelezionato = null;
            //this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.setValue(null);
            this.pnrrLineaFinanziamentoForm.controls.pnrr.setValue(data.pnrr ? data.pnrr.toString() : '0');
            this.pnrrLineaFinanziamentoForm.controls.pnc.setValue(data.fc ? data.fc.toString() : '0');
            this.pnrrLineaFinanziamentoForm.controls.quotaAssegnataPnrr.setValue(data.quotaAssegnataPnrr ? data.quotaAssegnataPnrr.toString() : '0');
            this.pnrrLineaFinanziamentoForm.controls.quotaAssegnataFc.setValue(data.quotaAssegnataFc ? data.quotaAssegnataFc.toString() : '0');
            const disponibilePnrr = (data.pnrr ? parseFloat(data.pnrr) : 0) - (data.quotaAssegnataPnrr ? parseFloat(data.quotaAssegnataPnrr) : 0);
            const disponibileFc = (data.fc ? parseFloat(data.fc) : 0) - (data.quotaAssegnataFc ? parseFloat(data.quotaAssegnataFc) : 0);
            this.pnrrLineaFinanziamentoForm.controls.quotaDisponibilePnrr.setValue(disponibilePnrr.toString());
            this.pnrrLineaFinanziamentoForm.controls.quotaDisponibileFc.setValue(disponibileFc.toString());
            this.provvedimentiPnrrListPerLineaFinPnrr = [];
            this.getAllPnrrProvvedimenti(data.id);
            this.pnrrLineaFinanziamentoForm.controls.pnrr.enable();
            this.pnrrLineaFinanziamentoForm.controls.pnc.enable();
            if (data.isPnrrLineaFinanziamentoPrincipale) {
                if (!data.abilitaPnrr) {
                    this.pnrrLineaFinanziamentoForm.controls.importoPnrr.setValue('0');
                    this.pnrrLineaFinanziamentoForm.controls.importoPnrr.disable();
                }
                if (!data.abilitaFc) {
                    this.pnrrLineaFinanziamentoForm.controls.importoFc.setValue('0');
                    this.pnrrLineaFinanziamentoForm.controls.importoFc.disable();
                }

            }
        });
        this.modalSubscriptions.push(subscription);
    }


    getLineeFinanziamentoPnrrDt = (dataTablesParameters: any, callback: any) => {
        this.pnrrService.getAllLineaFinanziamentoDt(dataTablesParameters, false, true)
            .subscribe(resp => {
                callback(resp);
            });
    };

    openModalRefSelectLineaFinPnrrFcPerRipartizione() {
        let modalDefinition = {
            rows: this.pnrrLineeFinanziamento.filter(x => !x.rilevanteNazionale).map(x => x.pnrrLineaFinanziamento),
            columns: [
                {
                    name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
                    , resizeable: false
                }

                , {
                    name: 'codice'
                    , prop: 'codice'

                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 1
                    , minWidth: 60

                },
                {
                    name: 'missione'
                    , prop: 'missione.descrizione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 1
                    , minWidth: 40
                },
                {
                    name: 'componente'
                    , prop: 'componente.descrizione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 80
                },
                {
                    name: 'descrizione'
                    , prop: 'descrizione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 80
                }
                ,
                {
                    name: 'misura PNRR'
                    , prop: 'misuraPnrr'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 80
                }
                , {
                    name: 'tipologia'
                    , prop: 'tipologia'

                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 70

                }
                , {
                    name: 'pnrr'
                    , prop: 'pnrr'
                    , sortable: true
                    , filtrable: true
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 2
                    , minWidth: 70
                }
                , {
                    name: 'pnc'
                    , prop: 'fc'
                    , sortable: true
                    , filtrable: true
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 2
                    , minWidth: 70
                }
            ],
            ajax: null,
            pageTitle: "Seleziona linea di finanziamento PNRR/PNC",
            totalElements: 0,
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: false, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: false, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: false,
            scrollbarH: "true",
        };

        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl",
        };
        const initialState = {
            modalDefinition: modalDefinition,
            dtOptionsNgx: modalDefinition,
        };

        this.modalRefSelectLineaFinPnrrFc = this.modalService.show(
            GenericSelectionModalComponent,
            Object.assign({}, config, { initialState })
        );
        console.log("apertura modale GenericSelectionModalComponent");
        this.clearSubscriptions();
        let subscription = this.modalRefSelectLineaFinPnrrFc.content.onSubmitOk.subscribe((data) => {
            console.log("inside ok of lineepnrr");
            if (this.pnrrLineaFinanziamentoSelected) {
                this.toastr.error("Esiste già una linea di finanziamento PNRR/PNC associata", null, {
                    timeOut: 2000,
                    disableTimeOut: false,
                });
                return;
            }
            this.pnrrLineaFinanziamentoSelected = data;

            this.toastr.success("Linea di finanziamento PNRR/PNC aggiunta con successo", null, {
                timeOut: 2000,
                disableTimeOut: false,
            });
            this.setIsDirty(true);
            this.closeModalRefSelectLineaFinPnrrFc();
            this.decretoSelezionato = null;
            this.pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.setValue(null);
            this.provvedimentiPnrrListPerLineaFinPnrr = [];
            this.getAllPnrrProvvedimenti(data.id);
        });
        this.modalSubscriptions.push(subscription);
    }

    @ViewChild("extraFilterTemplate", { static: true })
    public extraFilterTemplate: TemplateRef<any>;
    itemsComboFilterPnrr = [{ id: 'Si', descrizione: 'Si' }, { id: 'No', descrizione: 'No' }];
    filterRilevantePnrr($event) {
        let descr = $event ? $event.descrizione : "";
        let id = $event ? $event.id : null;
        let value = id ? (id.toLowerCase() == 'si' ? true : false) : null;

        this.ngxDatatableWrapper.originalColumns[5].search = {
            value: value,
            regex: false,
        };

        this.ngxDatatableWrapper.refresh();
    }
    necessitaRipartizione() {
        return this.pnrrLineeFinanziamento && this.pnrrLineeFinanziamento.filter(x => !x.rilevanteNazionale).length > 0;
    }


    ripartizioneSoggettoGiuridicoChange() {
        const val = this.formPnrrRipartizioneRisorse.soggettoGiuridico.value;
        const sogg = this.soggettiGiuridici.find(x => x.id == val);
        //regione lazio puo' essere solo soggetto attuatore
        if (sogg.codiceIdentificativo == '80143490581') this.formPnrrRipartizioneRisorse.ruoloSoggettoGiuridico.setValue(this.ruoliSoggettiGiuridiciList.find(x => x.codice == 'SOGGETTO_ATTUATORE').id);

    }

    @ViewChild("tripletteFieldValueTemplate", { static: true })
    public tripletteFieldValueTemplate: TemplateRef<any>;

    
    @ViewChild("pnrrFieldValueTemplate", { static: true })
    public pnrrFieldValueTemplate: TemplateRef<any>;

    getTriplette(tripletteJsonString) {
        if (!tripletteJsonString || tripletteJsonString == '') return [];
        let r = JSON.parse(tripletteJsonString);
        r.sort((a, b) => a < b ? -1 : 1);
        return r;
    }

    /**
     * Controlla se l'utente può accedere o meno alla sezione.
     * @param section il nome della sezione
     * @param operations le operazioni da poter effettuare sulla sezione
     * @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
     * @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
     * @returns true se il controllo è andato a buon fine, false altrimenti
    */
    enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
        return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
    }

    isAdmin() {
        return this.authService.isAdmin();
    }
    isDirectionalGroup() {
        return this.authService.isDirectionalGroup();
    }
    isAreaStaff() {
        return this.authService.isAreaStaff();
    }
    isExternal() {
        return this.authService.isExternal();
    }

    isViewer() {
        return this.authService.isViewer();
    }
    isGruppoPnrr() {
        return this.authService.isGruppoPnrr();
    }
    isGr34() {
        return this.authService.isGr34();
    }

    printPnrrFieldValue(item){
        return (item.pnrrRilevante?'Si':'No') + `- ID ${item.id}`;
    }
}
