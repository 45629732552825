<div class="container bg-white">

    <div class="col-12 mt-3 sfondo_bianco">
        <app-title-page></app-title-page>

        <div class="row justify-content-md-center">
            <button type="button" class="btn btn-primary mb-3 mx-auto" data-target="#template1" data-toggle="modal" data-backdrop="static" data-keyboard="false" (click)="openModal(template1,true)">
            <b>&nbsp;&nbsp;CREA NUOVA NEWS </b>&nbsp;
            <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
        </div>


        <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsNgx">
            <ng-template #btnEditTemplate let-row="row" let-value="value">
                <a [ngClass]="{
        'disabled': loading
        }" class=" border-0 text-center" (click)="editHandler(row)" data-toggle="tooltip" data-placement="top" title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
            </ng-template>

            <ng-template #btnEnableTemplate let-row="row" let-value="value">


                <a [ngClass]="{'fa-check-circle-abilitato':value,'fa-check-circle-disabilitato':!value}" class=" border-0 text-center" (click)="enableHandler(row)" data-toggle="tooltip" data-placement="top" title="{{value?'pubblicato':'non pubblicato'}}">&nbsp;&nbsp;&ensp;
                &nbsp;&nbsp;&nbsp;&ensp;<i class="far fa-check-circle pointer"></i></a>
            </ng-template>


            <ng-template #btnDeleteTemplate let-row="row" let-value="value">
                <a [ngClass]="{
        'disabled': loading
        }" class=" border-0 text-center" (click)="deleteHandler(row)" data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
            </ng-template>

        </ngx-datatable-wrapper>


    </div>

    <button style="display: none" id="fake" type="button" class="btn btn-primary" data-target="#template" data-toggle="modal" data-backdrop="static" data-keyboard="false" (click)="openModal(template1,false)">
    Create template modal
</button>
</div>

<!--/////////////////MODALE///////////////////////-->
<ng-template #template1>
    <div class="modal-header text-center">
        <h4 class="modal-title pull-left">Crea/Modifica News</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="newsGroup" class="form-registrazione">
            <div class="col-lg-12 mt-3">
                <div class="form-group">
                    <h5><label class="mandatory">Titolo</label></h5>
                    <input maxlength="255" formControlName="title" type="text" class="form-control" [ngClass]="{'is-invalid': submitted && f.title.errors }" />
                    <div *ngIf="submitted && f.title.errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="f.title.errors.required">Titolo &egrave; obbligatorio</div>
                    </div>
                </div>

                <!-- ////datapicker/////////// -->
                <div class="form-group">
                    <h5><label class="mandatory">Data</label></h5>
                    <div class="row">
                        <div class="col-md-12 form-group">
                            <div class="input-group">
                                <input formControlName="date" readonly type="text" class="form-control" bsDatepicker #dp="bsDatepicker" [bsConfig]="{
                  dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true,
                  containerClass: 'theme-dark-blue',
                  isAnimated: true
                }" [ngClass]="{'is-invalid': submitted && f.title.errors }" />
                                <button class="btn btn-primary mt-0" (click)="dp.show()" [attr.aria-expanded]="dp.isOpen">
                                    <i class="fas fa-calendar-alt"></i>
                                </button>
                                <div *ngIf="submitted && f.date.errors" class="invalid-feedback" style="display: block;">
                                    <div *ngIf="f.date.errors.required">Data &egrave; obbligatoria</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- /////////testo/////////// -->
                <div class="form-group">
                    <h5><label class="mandatory">Testo</label></h5>
                    <textarea formControlName="message" class="form-control" rows="10" id="message" [ngClass]="{'is-invalid': submitted && f.title.errors }"></textarea>
                    <div *ngIf="submitted && f.message.errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="f.message.errors.required">Testo &egrave; obbligatorio</div>
                    </div>
                </div>

                <!-- /////////testo/////////// -->
                <div class="form-group">
                    <h5><label>Allegato</label></h5>
                    <div [hidden]="modNews != null && modNews.filePath != null" id="download">
                        <input type="file" formControlName="attach" (change)="fileChange($event)" />
                    </div>

                    <div [hidden]="modNews == null || modNews.filePath == null" id="download">
                        <div class="row" style="padding-left:15px!important;padding-right:15px!important;">
                            <button type="button" class="btn btn-primary btn-sm col-sm m-1" (click)="downloadFile()">
                                SCARICA FILE &nbsp;<i class="fas fa-download text-white"></i>
                            </button>

                            <button type="button" class="btn btn-primary btn-sm col-sm m-1" (click)="deleteFile()">
                                ELIMINA FILE &nbsp;<i class="far fa-trash-alt text-white"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <button class="btn btn-lg btn-primary btn-block" style="margin:15px 0" (click)="onSubmit()">
                    Salva
                </button>
            </div>
        </form>
    </div>
</ng-template>