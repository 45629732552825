import { CurrencyPipe } from '@angular/common';
import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    ElementRef,
    TemplateRef,
    Input,
    Output, EventEmitter
} from "@angular/core";
import { registerLocaleData } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProgettoService, InterventoService, FinanziamentoService, ProvvedimentoService } from '../../services';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from '../../../../components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component'
import localeIt from '@angular/common/locales/it';
import { OrderPipe } from 'ngx-order-pipe';
import { ProgettoDivDettaglioService } from '../progettoDivDettaglio/progetto-div-dettaglio.service';
import { FinanziamentoDivDettaglioService } from '../finanziamentoDivDettaglio/finanziamento-div-dettaglio.service';
import { AuthService } from 'src/app/services';
registerLocaleData(localeIt, 'it');
defineLocale('it', itLocale);
import { IsDirtyHelper } from "../../../../helpers/is-dirty.helper"
import { SpostamentoAnnualitaComponent } from "../spostamentoAnnualita/spostamento-annualita.component"

@Component({
    selector: 'associa-annualita-component',
    templateUrl: './associa-annualita.component.html',
    styleUrls: ['./associa-annualita.component.css']
})
export class AssociaAnnualitaComponent implements OnInit, OnDestroy {
    section: string = '';
    public maskCodice: any = {
        mask: 'KKKKKK/YYYY',
        lazy: false,  // make placeholder always visible
        placeholderChar: '#',
        blocks: {
            KKKKKK: {
                mask: '000000',
                scale: 0,
                padFractionalZeros: false,
                radix: ','
            },
            YYYY: {
                mask: '0000',
                /*thousandsSeparator: '.',*/
                scale: 0,
                padFractionalZeros: false,
                radix: ','
                , min: 1950
            }
        }
    };

    public mask: any = {
        mask: 'KK €',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            }
        }
    };
    public maskYear: any = {
        mask: 'KK',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                /*thousandsSeparator: '.',*/
                scale: 0,
                padFractionalZeros: false,
                radix: ','
                , min: 1950
            }
        }
    };

    ngOnDestroy(): void {
    }

    submitted: boolean = false;
    loading: boolean = false;
    anniList = [];
    readOnlyForm: boolean = false;
    isValidated: boolean = false;
    private _finanziamentoId: number;
    tabsOnOneRow = true;

    modalSubscriptions = [];
    //@ViewChild('buttonsTemplate',{static:false}) buttonsTemplate: TemplateRef<any>;
    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('accertatoFieldTemplate', { static: true })
    public accertatoFieldTemplate: TemplateRef<any>;



    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;



    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    templateList = [];

    dtOptions: any;
    rows: any[];
    columns = [
        {
            name: '', prop: 'id', sortable: true, filtrable: false, maxWidth: 0, width: 0, visible: false
            // 'buttonsTemplate'
        }
        , {
            name: 'anno'
            , prop: 'anno'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            , minWidth: 60
        }
        , {
            name: 'importo'
            , prop: 'importo'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'currencyValueTemplate'
            , resizeable: false
            , draggable: false
            , flexGrow: 4
            , minWidth: 130
        }
        , {
            name: 'disponibilità'
            , prop: 'disponibilita'
            , visible: true,
            sortable: true
            , filtrable: true
            , cellTemplate: 'currencyValueTemplate'
            , resizeable: false
            , draggable: false
            , flexGrow: 4
            , minWidth: 130
        }
        , {
            name: this.interventoId ? 'impegnato' : 'accertato'
            , prop: 'accertatoImpegnato'
            , visible: true,
            sortable: true
            , filtrable: true
            , cellTemplate: 'accertatoFieldTemplate'
            , resizeable: false
            , draggable: false
            , flexGrow: 2
            , minWidth: 60
        }
        , {
            name: 'modifica'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , flexGrow: 2
            , minWidth: 60

        }
        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , flexGrow: 2
            , minWidth: 60

        }
    ];

    modalTitle: string = '';
    modalButtonLabel: string = '';

    modalRef: BsModalRef;
    modalRefSogResp: BsModalRef;

    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-md"
    };


    editForm: FormGroup;


    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;

    @Output()
    onSubmitEmitter: EventEmitter<any> = new EventEmitter();

    constructor(
        private toastr: ToastService
        , private modalService: BsModalService
        , private localeService: BsLocaleService
        , private formBuilder: FormBuilder
        , private el: ElementRef
        , private finanziamentoService: FinanziamentoService
        , private provvedimentoService: ProvvedimentoService
        , private interventoService: InterventoService
        , private progettoDivDettaglioService: ProgettoDivDettaglioService
        , private progettoService: ProgettoService
        , private orderPipe: OrderPipe
        , private isDirtyHelper: IsDirtyHelper
        , private finanziamentoDivDettaglioService: FinanziamentoDivDettaglioService
        , private authService: AuthService
    ) {
        this.localeService.use("it");
        this.dtOptions = {
            rows: this.rows
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: false// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: false// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: false
            , scrollbarH: "true"

        };
        this.editForm = this.initializeFormGroup();
    }


    setIsDirty(value: boolean) {
        this.isDirtyHelper.isDirty = value;
    }

    initializeFormGroup(): FormGroup {
        let frm = this.formBuilder.group({
            id: [null],
            anno: [null, [Validators.required]],
            importo: [null, [Validators.required]],
            disponibilita: [{ value: null, disabled: true }],
            accertatoImpegnato: [null]
        });
        frm.valueChanges.subscribe(val =>
            this.setIsDirty(frm.dirty)
        );
        return frm;
    }
    get f() {
        return this.editForm.controls;
    }

    ngOnInit() {
        this.templateList = [
            { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }
            , { name: 'btnEditTemplate', template: this.btnEditTemplate }
            , { name: 'accertatoFieldTemplate', template: this.accertatoFieldTemplate }
        ];
        window.scroll(0, 0);
        this.accertatoImpegnatoFg = this.formBuilder.group({});
        this.accertatoImpegnatoFg.valueChanges.subscribe(val =>
            this.setIsDirty(this.accertatoImpegnatoFg.dirty)
        );
    }

    refreshGrid() {

        /*if (this.ngxDatatableWrapper) {
            if (this.ngxDatatableWrapper.dtOptions.columns[2].prop != 'disponibilita') this.ngxDatatableWrapper.dtOptions.columns[2].name = 'impegnato';
            if (this._finanziamentoId) this.ngxDatatableWrapper.dtOptions.columns[2].name = 'accertato';
            this.ngxDatatableWrapper.refresh();
            this.ngxDatatableWrapper.dtOptions = this.ngxDatatableWrapper.dtOptions;
        }*/
        this.reloadData();

    }

    loadDataCounter: number = 0;
    reloadData() {
        if (this._finanziamentoId) this.getFinanziamentoData(this._finanziamentoId);
        if (this._interventoId) this.getInterventoData(this._interventoId);
    }


    @Input()
    set finanziamentoId(value) {
        console.log(`inside set finanziamentoId= ${value}`);
        this._finanziamentoId = value;
        this.section = 'Finanziamenti';
        this._interventoId = null;
        if (!value) return;
        //this.getFinanziamentoData(value);

    }
    get finanziamentoId() {
       // console.log(`inside get finanziamentoId= ${this._finanziamentoId}`);
        return this._finanziamentoId;
    }

    private _interventoId: number;
    @Input()
    set interventoId(value) {
       // console.log(`inside set interventoId= ${value}`);
        this._interventoId = value;
        this.section = 'Interventi';
        this._finanziamentoId = null;
        if (!value) return;
        //this.getAnnualitaByInterventoId(value);
        //this.getInterventoData(value);
        //if (this.ngxDatatableWrapper && (this.ngxDatatableWrapper.dtOptions.columns[2].prop != 'disponibilita')) this.ngxDatatableWrapper.dtOptions.columns[3].name = 'impegnato';
    }
    get interventoId() {
        //console.log(`inside get interventoId= ${this._interventoId}`);
        return this._interventoId;
    }

    getFinanziamentoData(id: number) {
        this.esistonoInterventi = false;
        this.tabsOnOneRow = true;
        this.loadDataCounter++;
       // console.log('inside fasi-getfinanziamentodata');
        let that = this;
        this.finanziamentoService.getById(id).subscribe(result => {
            that.columns[5].visible = true;
            that.columns[6].visible = true;
            let status = (result.validazione ? (result.validazione.validationStatus ? result.validazione.validationStatus : 0) : 0).toString().toUpperCase();
            that.readOnlyForm = result.isReadOnly;
            that.readOnlyForm = that.readOnlyForm || !that.canEdit();
            that.isValidated = (status == '1'
                || status == 'APPROVED'
                || status == 'WAITING_FOR_APPROVAL_FIRST_LEVEL' || status == '4'
                || status == 'WAITING_FOR_APPROVAL_SECOND_LEVEL' || status == '5'
                || status == 'TERMINATO'
            );
            //se è validato devo poter comunque modificare le annualita
            if (that.readOnlyForm) {
                //nascondo colonne edit e delete
                that.columns[5].visible = false;
                that.columns[6].visible = false;
            }
            if (that.isValidated) {
                that.columns[6].visible = false;
            }
            that.columns[4].name = 'accertato';
            that.ngxDatatableWrapper.dtOptions.columns = that.columns;

            that.ngxDatatableWrapper.dtOptions = that.ngxDatatableWrapper.dtOptions;
            that.ngxDatatableWrapper.refresh();
            this.getAnnualitaByFinId(id);
            this.loadDataCounter--;
        });


        //this.getProvvedimenti(id);
    }


    getAnnualitaByFinId(id: number) {
        this.loadDataCounter++;
        this.ngxDatatableWrapper.gridModel.data = [];
        this.finanziamentoService.getAnnualita(id).subscribe(
            result => {
                result = this.orderPipe.transform(result, 'anno');
                this.ngxDatatableWrapper.setInitialData(result);
                this.loadDataCounter--;

            }
        );
    }
    getAnnualitaByInterventoId(id: number) {
        this.loadDataCounter++;
        this.ngxDatatableWrapper.gridModel.data = [];
        this.interventoService.getAnnualita(id).subscribe(
            result => {
                result = this.orderPipe.transform(result, 'anno');
                this.ngxDatatableWrapper.setInitialData(result);
                this.loadDataCounter--;
            }
        );
    }


    interventoData: any;
    esistonoInterventi:boolean = false;
    getInterventoData(id: number) {
        this.loadDataCounter++;
        this.esistonoInterventi = false;
        let that = this;
        this.anniList = [];
        this.loading = true;
        this.interventoService.get(id).subscribe(
            result => {
                this.loading = false;
                that.interventoData = result;
                that.readOnlyForm = result.isReadOnly;
                that.readOnlyForm = that.readOnlyForm || !that.canEdit();
                let status = (result.validazione ? (result.validazione.validationStatus ? result.validazione.validationStatus : 0) : 0).toString().toUpperCase();
                that.isValidated = (status == '1'
                    || status == 'APPROVED'
                    || status == 'WAITING_FOR_APPROVAL_FIRST_LEVEL' || status == '4'
                    || status == 'WAITING_FOR_APPROVAL_SECOND_LEVEL' || status == '5'
                    || status == 'TERMINATO'

                );



                that.columns[3].visible = true;
                that.columns[4].visible = true;
                that.columns[5].visible = true;
                that.columns[6].visible = true;
                if (this.readOnlyForm) {
                    //nascondo colonne edit e delete
                    that.columns[5].visible = false;
                    that.columns[6].visible = false;
                }
                if (this.isValidated) {
                    that.columns[6].visible = false;
                }
                if (!result.isMain) {
                    //per sotto iterventi non mostro la colonna disponibilita
                    this.tabsOnOneRow = false;
                    that.columns[3].visible = false;
                    this.loading = true;
                    that.progettoService.get(result.progettoId).subscribe(
                        res => {

                            res.importi.forEach(element => {
                                that.anniList.push(element);
                            });
                            that.anniList.sort((a, b) => {
                                if (a.anno > b.anno) {
                                    return 1;
                                }
                                if (b.anno > a.anno) {
                                    return -1;
                                }
                                return 0;
                            });
                            this.loading = false;
                        }
                    );
                }
                else {
                    //per progetto non mostro la colonna impegnato
                    that.columns[4].visible = false;


                    //per i progetti vado a prendere il numero degli interventi per i bottoni sticky
                    this.progettoService.getInterventiCount(result.progettoId).subscribe(
                        res => {
                            this.esistonoInterventi = res - 1 > 0;      
                            this.tabsOnOneRow =this.esistonoInterventi;
                        }
                    );
                }
                /*if (that.columns[3].prop != 'disponibilita')*/ that.columns[4].name = 'impegnato';
                that.ngxDatatableWrapper.dtOptions.columns = that.columns;

                that.ngxDatatableWrapper.dtOptions = that.ngxDatatableWrapper.dtOptions;
                that.ngxDatatableWrapper.refresh();
                this.getAnnualitaByInterventoId(this._interventoId);
                this.loadDataCounter--;

            });
    }


    onSubmit() {
        this.submitted = true;
        this.editForm.updateValueAndValidity();
        for (let c in this.f) {
            this.editForm.controls[c].updateValueAndValidity();
        }
        let errors = false;
        for (let c in this.editForm.controls) {
            errors = errors || this.editForm.controls[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );
        }



        if (errors || this.editForm.invalid || (
            this.editForm.controls.accertatoImpegnato.value == true && (
                this.accertatoImpegnatoList.length <= 0
                || this.accertatoImpegnatoFg.invalid))) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.editForm.controls) {
                if (this.editForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.editForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.editForm.get(el)["controls"]) {
                            if (this.editForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }
        //no errors
        let obj = this.editForm.value;
        obj.anno = this.editForm.getRawValue().anno;
        obj.importo = this.editForm.getRawValue().importo;
        if (obj.accertatoImpegnato == null) obj.accertatoImpegnato = false;
        if (!obj.id) {
            let anni: [] = this.ngxDatatableWrapper.gridModel.data.map(x => x['anno']);
            if (anni.find(x => x == obj.anno)) {
                this.toastr.error('Annualità già presente,operazione annullata', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                return;
            }
        }


        //Verifico gli accertamenti/impegni
        let valoreSommaAccertamenti = 0;
        let accertatoImpegnatoListTemp = [];


        for (let el in this.accertatoImpegnatoFg.controls) {
            console.log(`el= ${el}, value = ${this.accertatoImpegnatoFg.controls[el].value}`)
        }

        if (obj.accertatoImpegnato) {
            let seed = 0;
            this.accertatoImpegnatoList.forEach(
                item => {
                    let controlNameId = `accertato_impegnato_id_${item.idHtml}`;
                    let controlNameValue = `accertato_value_${item.idHtml}`;
                    let controlNameNumber = `accertato_number_${item.idHtml}`;
                    item.numero = this.accertatoImpegnatoFg.controls[controlNameNumber].value;
                    item.valore = parseFloat(this.accertatoImpegnatoFg.controls[controlNameValue].value);
                    //if (item.idHtml.toString().includes('new')) item.id = --seed;

                    valoreSommaAccertamenti += (item.valore);
                }
            );




            if (this.convertToDigitDecimalNumber(obj.importo) < this.convertToDigitDecimalNumber(valoreSommaAccertamenti)) {
                this.toastr.error(`La somma dei valori degli ${this._interventoId ? 'impegni' : 'accertamenti'} è maggiore del valore dell'annualità,operazione annullata`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                return;
            }
        }
        obj.accertamenti = this.accertatoImpegnatoList;


        this.loading = true;
        this.submitted = false;
        let currentId = this._finanziamentoId ? this._finanziamentoId : this._interventoId;

        let service = this._finanziamentoId ? this.finanziamentoService : this.interventoService;
        service.saveAnnualita(currentId, obj).subscribe(
            result => {
                let warnMsg = result.warningMessage;
                result = result;
                this.setIsDirty(false);
                this.closeModal();
                this.loading = false;
                this.toastr.success('Annualità salvata con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                if (this._interventoId && obj.accertatoImpegnato && this.oldAccertatoImpegnatoValue) {
                    this.toastr.warning('Attenzione, è stata modificata un annualità già impegnata', null, {
                        timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                    });
                }
                if (obj.accertatoImpegnato && this.convertToDigitDecimalNumber(obj.importo) != this.convertToDigitDecimalNumber(valoreSommaAccertamenti)) {
                    this.toastr.warning(`La somma dei valori degli ${this._interventoId ? 'impegni' : 'accertamenti'} è diversa del valore dell'annualità`, null, {
                        timeOut: 3000,/*closeButton:true,*/ disableTimeOut: false
                    });
                }
                if (warnMsg && warnMsg != '') {
                    this.toastr.warning(warnMsg, null, {
                        timeOut: 10000,/*closeButton:true,*/ disableTimeOut: false
                    });
                }

                this.modalRef.hide();
                if (!obj.id) {
                    //new row
                    this.ngxDatatableWrapper.addRow(result);
                }
                else {
                    this.ngxDatatableWrapper.updateRow(result);
                }
                this.onSubmitEmitter.emit(result);
                this.progettoDivDettaglioService.reload();
                if (this._finanziamentoId) this.finanziamentoDivDettaglioService.reload();
                if (this._interventoId) this.getInterventoData(this._interventoId);
            }, error => {
                this.loading = false;
                this.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );

    }

    convertToDigitDecimalNumber(value) {
        let num = value;
        let with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        return parseFloat(with2Decimals);
    }

    openAddModal() {
        this.accertatoImpegnatoList = [];
        this.accertatoImpegnatoFg = new FormGroup({});
        this.accertatoImpegnatoFg.valueChanges.subscribe(val =>
            this.setIsDirty(this.accertatoImpegnatoFg.dirty)
        );
        this.oldAccertatoImpegnatoValue = false;
        this.submitted = false;
        this.editForm.reset();

        //if (!this._finanziamentoId)this.editForm.controls.disponibilita.clearValidators();
        this.editForm.controls.disponibilita.clearValidators();
        if (this.interventoData && this.interventoData.isMain) {
            //se sono un progetto tolgo i controlli sul campo impegnato e numero impegno
            this.editForm.controls.accertatoImpegnato.clearValidators();
        }
        this.modalTitle = 'Nuova annualità';
        this.modalButtonLabel = 'Inserisci';
        this.editForm.controls.anno.enable();
        this.editForm.controls.importo.enable();
        this.modalRef = this.modalService.show(this.templateEditAdd, this.config);
        this.setIsDirty(false);
    }

    oldAccertatoImpegnatoValue = false;

    onEdit(row) {
        if (this.loading) return;
        this.accertatoImpegnatoList = [];
        /*if (!this._finanziamentoId)*/this.editForm.controls.disponibilita.clearValidators();
        if (this.interventoData && this.interventoData.isMain && this.interventoData.numSubInterventi > 0) {
            //se sono un progetto tolgo i controlli sul campo impegnato e numero impegno
            this.editForm.controls.accertatoImpegnato.clearValidators();
        }
        this.submitted = false;
        //console.log(row);
        this.modalTitle = 'Modifica annualità';
        this.modalButtonLabel = "Aggiorna";
        this.modalRef = this.modalService.show(this.templateEditAdd, this.config);
        //let disponibilita = row.disponibilita?row.disponibilita          :null          ;
        this.oldAccertatoImpegnatoValue = row.accertatoImpegnato;
        this.editForm.setValue({
            id: row.id,
            anno: row.anno,
            importo: row.importo,
            disponibilita: null,
            accertatoImpegnato: row.accertatoImpegnato ? row.accertatoImpegnato : false,
        });
        this.accertatoImpegnatoOnChange(null);
        this.editForm.controls.anno.disable();
        if (this.anniList && this.anniList.length > 0) {
            let annualita = this.anniList.find(x => x.anno == row.anno);
            if (annualita) this.annoListChanged(annualita);
        }
        this.accertatoImpegnatoList = row.accertamenti;
        this.accertatoImpegnatoFg = new FormGroup({});
        this.accertatoImpegnatoFg.valueChanges.subscribe(val =>
            this.setIsDirty(this.accertatoImpegnatoFg.dirty)
        );
        this.accertatoImpegnatoList.forEach(
            x => {
                let controlNameId = `accertato_id_${x.id}`;
                let controlNameValue = `accertato_value_${x.id}`;
                let controlNameNumber = `accertato_number_${x.id}`;
                x['idHtml'] = '' + x.id;
                this.accertatoImpegnatoFg.addControl(controlNameId, new FormControl(''));
                this.accertatoImpegnatoFg.addControl(controlNameNumber, new FormControl(x.numero));
                this.accertatoImpegnatoFg.addControl(controlNameValue, new FormControl(x.valore));
                this.accertatoImpegnatoFg.controls[controlNameNumber].setValidators(Validators.required);
            }
        );
        this.editForm.controls.importo.enable();
        if (this.isValidated) {
            this.editForm.controls.importo.disable();
        }
        this.setIsDirty(false);
    }

    onDelete(row) {
        if (this.loading) return;
        console.log(row);
        if (!confirm(`Stai per eliminare l'annualità "${row['anno']}", proseguire?`)) return;
        this.loading = true;
        let currentId = this._finanziamentoId ? this._finanziamentoId : this._interventoId;
        let service = this._finanziamentoId ? this.finanziamentoService : this.interventoService;
        service.deleteAnnualita(currentId, row).subscribe(
            result => {
                this.loading = false;
                this.ngxDatatableWrapper.deleteRow(row);
                this.toastr.success('Annualità eliminata con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                this.onSubmitEmitter.emit(row);
                this.progettoDivDettaglioService.reload();
                if (this._finanziamentoId) this.finanziamentoDivDettaglioService.reload();
                if (this._interventoId) this.getInterventoData(this._interventoId);
            }, error => {
                this.loading = false;
                this.toastr.error(`Impossibile eliminare l'annualità selezionata: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );
    }
    closeModal() {
        if (!this.isDirtyHelper.checkIsDirty()) return false;
        this.modalRef.hide();
    }
    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
    gotoBackBtnClick() {
        if (!this.isDirtyHelper.checkIsDirty()) return false;
        this.gotoBtnFunction.emit(true);
    }

    annoListChanged($event) {
        this.editForm.controls.disponibilita.setValue($event.disponibilita.toString());
    }


    /**************INIZIO GESTIONE ACCCERTATO-IMPEGNATO********** */


    accertatoImpegnatoOnChange($event) {
    }
    printAccertatoValue(row, value) {
        if (!value) return "No";
        return 'Si';
        /*let val = IMask.pipe(row.codice, this.maskCodice
        );
        return `Si [ ${val} ]`;*/
    }



    accertatoImpegnatoList = [];
    accertatoImpegnatoNewIdSeed = 0;
    accertatoImpegnatoFg: FormGroup;

    openModalAggiungiAccertatoImpegnato() {
        if (!this.accertatoImpegnatoList) this.accertatoImpegnatoList = [];
        ++this.accertatoImpegnatoNewIdSeed;
        let controlNameId = `accertato_id_new_${this.accertatoImpegnatoNewIdSeed}`;
        let controlNameValue = `accertato_value_new_${this.accertatoImpegnatoNewIdSeed}`;
        let controlNameNumber = `accertato_number_new_${this.accertatoImpegnatoNewIdSeed}`;
        this.accertatoImpegnatoList.push({
            id: null, idHtml: `new_${this.accertatoImpegnatoNewIdSeed}`, valore: 0, numero: ''
        });
        this.accertatoImpegnatoFg.addControl(controlNameId, new FormControl(''));
        this.accertatoImpegnatoFg.addControl(controlNameNumber, new FormControl(''));
        this.accertatoImpegnatoFg.addControl(controlNameValue, new FormControl(''));
        this.accertatoImpegnatoFg.controls[controlNameNumber].setValidators(Validators.required);

    }

    removeAccertatoImpegnato(item) {
        let id = item.idHtml;
        let controlNameId = `accertato_id_${id}`;
        let controlNameValue = `accertato_value_${id}`;
        let controlNameNumber = `accertato_number_${id}`;
        this.accertatoImpegnatoFg.removeControl(controlNameId);
        this.accertatoImpegnatoFg.removeControl(controlNameValue);
        this.accertatoImpegnatoFg.removeControl(controlNameNumber);
        this.accertatoImpegnatoList = this.accertatoImpegnatoList.filter(x => x.idHtml != item.idHtml);
        this.setIsDirty(true);
    }

    /**************FINE GESTIONE ACCCERTATO-IMPEGNATO********** */
    printValueAsCurrency(value) {
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }

    _isInModal: boolean = false;
    @Input()
    set isInModal(value: boolean) {
        this._isInModal = value;
    }
    get isInModal() { return this._isInModal; }

    /*sposta annualita */
    openSpostaAnnualitaModal() {



        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl",
        };
        const initialState = {
            interventoId: this._interventoId
        };

        this.modalRef = this.modalService.show(
            SpostamentoAnnualitaComponent,
            Object.assign({}, config, { initialState })
        );
        console.log("apertura modale SpostamentoAnnualitaComponent");
        let that = this;
        let subscription = this.modalRef.content.onSubmitOk.subscribe((data) => {
            console.log('chiusa modale');
            that.progettoDivDettaglioService.reload();
            if (this._finanziamentoId) this.finanziamentoDivDettaglioService.reload();
            if (that._interventoId) that.getInterventoData(that._interventoId);
            subscription.unsubscribe();
        });
    }

    canEdit() {
        return (this.enableSection(this.section, ['update'], null, true));
    }
    /**
* Controlla se l'utente può accedere o meno alla sezione.
* @param section il nome della sezione
* @param operations le operazioni da poter effettuare sulla sezione
* @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
* @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
* @returns true se il controllo è andato a buon fine, false altrimenti
*/
    enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
        return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
    }
    isGruppoPnrr() {
        return this.authService.isGruppoPnrr();
    }

    isAdmin() {
        return this.authService.isAdmin();
    }
    isGr34(){
        return this.authService.isGr34();
    }

}