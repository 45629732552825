import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { Fase } from "../models";
import { JsonPipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class FaseService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}Fasi/`;
  constructor(private http: HttpClient) {}

  /*insert(s: Fase) {
        return this.http.post<any>(`${this.apiUrl}`, s);
    }*/
  update(s: Fase) {
    return this.http.put<any>(`${this.apiUrl}${s.id}`, s);
  }
  delete(id: number) {
    return this.http.delete<any>(`${this.apiUrl}${id}`);
  }

  getAllPhasesUserCanEdit(filters: any) {
    return this.http.post<any>(`${this.apiUrl}userCanEdit`, filters);
  }

  getAllPhases(filters: any) {
    return this.http.post<any>(`${this.apiUrl}allPhases`, filters);
  }

  updateMassive(fase: any, codesSelected: string[]) {
    return this.http.put<any>(`${this.apiUrl}massive`, {
      fase: fase,
      ids: codesSelected,
    });
  }

  getPnrrTargetMilestone(tipologia: string, filters: any) {
    let url = `${this.apiUrl}pnrr-target-milestone`;
    return this.http.get<any[]>(url, {
      params: { filterObject: JSON.stringify(filters), tipologia: tipologia },
    });
  }

  esportaExcelMT(filters, tipologia) {
    if (!filters.pnrrMissioni) filters.pnrrMissioni = [];
    if (!filters.pnrrComponenti) filters.pnrrComponenti = [];
    if (!filters.pnrrLinee) filters.pnrrLinee = [];

    let url = `${this.apiUrl}esporta`;
    return this.http.get(url, {
      observe: "response",
      responseType: "blob",
      params: {
        filterObject: JSON.stringify(filters),
        tipologia: tipologia,
      },
    });
  }
}
