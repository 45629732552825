import { CurrencyPipe } from '@angular/common';
import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    TemplateRef,
    AfterViewInit
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FinanziamentoService,InterventoService,ValidazioneService } from '../../services';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { registerLocaleData } from '@angular/common';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import localeIt from '@angular/common/locales/it';
import { OrderPipe } from 'ngx-order-pipe';
import { AuthService } from 'src/app/services';
import{ProgettoDivDettaglioService}from '../progettoDivDettaglio/progetto-div-dettaglio.service';


registerLocaleData(localeIt, 'it');
defineLocale('it', itLocale);



@Component({
    selector: 'app-validazione',
    templateUrl: './validazione.component.html',
    styleUrls: ['./validazione.component.css']
})
export class ValidazioneComponent implements OnInit, AfterViewInit {

    public mask: any = {
        mask: 'KK €',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            }
        }
    };
    public maskCofin: any = {
        mask: 'KK %',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ',',
                max: 100
            }
        }
    };


    dtOptionsFinanziamenti: any = {};
    dtOptionsInterventi: any = {};
    dtOptionsValidati:any={};
    loading: boolean = false;
    modalRef: BsModalRef;
    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-xl"
    };

    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    @ViewChild('annualitaFieldValueTemplate', { static: true })
    public annualitaFieldValueTemplate: TemplateRef<any>;

    @ViewChild('annualitaInterventiFieldValueTemplate', { static: true })
    public annualitaInterventiFieldValueTemplate: TemplateRef<any>;



    @ViewChild('disponibilitaFieldValueTemplate', { static: true })
    public disponibilitaFieldValueTemplate: TemplateRef<any>;

    @ViewChild('btnCheckTemplate', { static: true })
    public btnCheckTemplate: TemplateRef<any>;

    @ViewChild('btnBanTemplate', { static: true })
    public btnBanTemplate: TemplateRef<any>;

    @ViewChild('btnDettaglioInterventoTemplate', { static: true })
    public btnDettaglioInterventoTemplate: TemplateRef<any>;

    @ViewChild('btnDettaglioFinanziamentoTemplate', { static: true })
    public btnDettaglioFinanziamentoTemplate: TemplateRef<any>;

    @ViewChild('btnDettaglioValidatiTemplate', { static: true })
    public btnDettaglioValidatiTemplate: TemplateRef<any>;



    @ViewChild('btnCheckInterventiTemplate', { static: true })
    public btnCheckInterventiTemplate: TemplateRef<any>;

    @ViewChild('btnBanInterventiTemplate', { static: true })
    public btnBanInterventiTemplate: TemplateRef<any>;

    @ViewChild('direzioneFieldValueTemplate', { static: true })
    public direzioneFieldValueTemplate: TemplateRef<any>;

    @ViewChild('datiRichiestaValueTemplate', { static: true })
    public datiRichiestaValueTemplate: TemplateRef<any>;

    @ViewChild('fonteStrumentoAttuativoValueTemplate', { static: true })
    public fonteStrumentoAttuativoValueTemplate: TemplateRef<any>;

    @ViewChild('datiRichiestaApprovazioneValueTemplate', { static: true })
    public datiRichiestaApprovazioneValueTemplate: TemplateRef<any>;
    @ViewChild('datiRichiestaValidateValueTemplate', { static: true })
    public datiRichiestaValidateValueTemplate: TemplateRef<any>;


    @ViewChild('ngxDatatableWrapperFinanziamenti')
    ngxDatatableWrapperFinanziamenti: NgxDatatableWrapperComponent;

    @ViewChild('ngxDatatableWrapperInterventi')
    ngxDatatableWrapperInterventi: NgxDatatableWrapperComponent;

    @ViewChild('ngxDatatableWrapperValidati')
    ngxDatatableWrapperValidati: NgxDatatableWrapperComponent;




    templateList = [];

    columnsFinanziamenti = [
        {
            name: 'id', prop: 'id', visible: false
            , sortable: true, filtrable: true, width: 10

        }
        , {
            name: 'codice'
            , prop: 'codice'
            , sortable: true
            , filtrable: true
            , flexGrow: 3
            , minWidth: 90
            // , maxWidth: 100
        }

        , {
            name: 'richiesta'
            , prop: 'codice'
            , sortable: true
            , filtrable: true
            , flexGrow: 3
            // , minWidth: 85
            , minWidth: 90
            , cellTemplate: 'datiRichiestaValueTemplate'
        }
        , {
            name: 'titolo'
            , prop: 'titolo'
            , sortable: true
            , filtrable: true
            , flexGrow: 4
            , minWidth: 120
        }
        , {
            name: 'fonte<br />strumento<br />attuativo'
            , prop: 'tipoFinanziamento.tipoFinanziamento'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'fonteStrumentoAttuativoValueTemplate'
            , flexGrow: 3
            , minWidth: 90
        }
        , {
            name: 'direzione'
            , prop: 'areeTematiche'
            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , cellTemplate: 'direzioneFieldValueTemplate'
            , flexGrow: 4
            , minWidth: 120
        }
        , {
            name: 'importo'
            , prop: 'importoComplessivo'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'annualitaFieldValueTemplate'
            , flexGrow: 4
            , minWidth: 120
            // , maxWidth: 150

        }
        , {
            name: 'disponibilità<br/>residua'
            , prop: 'disponibilitaResidua'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'disponibilitaFieldValueTemplate'
            , flexGrow: 4
            , minWidth: 120
            // , maxWidth: 150
        }
        , {
            name: 'scadenza'
            , prop: 'scadenza'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'dateValueTemplate'
            , flexGrow: 3
            , minWidth: 90

        }
        , {
            name: 'dettaglio'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDettaglioFinanziamentoTemplate'
            , flexGrow: 2
            , minWidth: 60

        }

        , {
            name: 'valida'
            , prop: 'validazione.validationStatus'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnCheckTemplate'
            , flexGrow: 2
            , minWidth: 55

        }
        , {
            name: 'rifiuta'
            , prop: 'validazione.validationStatus'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnBanTemplate'// 'buttonsTemplate'
            , flexGrow: 2
            , minWidth: 55

        }
        ,
        {
            name: ''
            , prop: 'tipoStrumentoAttuativo.descrizione'
            , sortable: true
            , filtrable: true
            , flexGrow: 1
            , minWidth: 90
            , visible: false
        }

    ];


    columnsInterventi = [
        {
            name: '', prop: 'id', sortable: true, filtrable: false, maxWidth: 0, width: 0, visible: false
            // 'buttonsTemplate'
        }
        , {
            name: 'codice'
            , prop: 'codice'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            , minWidth: 90

        }

        , {
            name: 'richiesta'
            , prop: 'codice'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            , minWidth: 85
            , maxWidth: 90
            , cellTemplate: 'datiRichiestaValueTemplate'
        }

        , { name: 'anno'
        , prop: 'annoRiferimento'
        , sortable: true
        , filtrable: true
        ,minWidth:60
        ,flexGrow:0
     }
        , { name: 'cup'
        , prop: 'cup'
        , sortable: true
        , filtrable: true
        ,minWidth:60
        ,flexGrow:1

    }
        , { name: 'descrizione'
        , prop: 'descrizione'
        , sortable: true
        , filtrable: true
        ,minWidth:100
        ,flexGrow:3
    }
        ,{ name:'direzione'
        ,prop:'areeTematiche'
        , sortable: true
        , filtrable: true
        ,cellTemplate:'direzioneFieldValueTemplate'
        ,minWidth:100
        ,flexGrow:3
    }
    ,{ name:'valore'
        ,prop:'importi'
        , sortable: false
        , filtrable: false
        ,cellTemplate:'annualitaInterventiFieldValueTemplate'
        ,minWidth:90
        ,flexGrow:3
    }
    , {
        name: 'dettaglio'
        , prop: 'id'
        , sortable: false
        , filtrable: false
        , cellTemplate: 'btnDettaglioInterventoTemplate'
        , flexGrow: 0
        , minWidth: 60


    }
    , {
        name: 'valida'
        , prop: 'validazione.validationStatus'
        , sortable: false
        , filtrable: false
        , cellTemplate: 'btnCheckInterventiTemplate'
        , flexGrow: 0
        , minWidth: 60


    }
    , {
        name: 'rifiuta'
        , prop: 'validazione.validationStatus'
        , sortable: false
        , filtrable: false
        , cellTemplate: 'btnBanInterventiTemplate'// 'buttonsTemplate'
        , flexGrow: 0
        , minWidth: 60

    }
    ];

    columnsValidati = [
        {
            name: '', prop: 'id', sortable: true, filtrable: false, maxWidth: 0, width: 0, visible: false
            // 'buttonsTemplate'
        }
        , {
            name: 'tipologia'
            , prop: 'tipologia'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            , minWidth: 110

        }
        , {
            name: 'codice'
            , prop: 'codice'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            , minWidth: 95

        }

        , {
            name: 'richiesta'
            , prop: 'validationRequestDate'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            , minWidth: 95

            , cellTemplate: 'datiRichiestaValidateValueTemplate'
        }
        , {
            name: 'validazione'
            , prop: 'validationChangeStatusDate'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            , minWidth: 85

            , cellTemplate: 'datiRichiestaApprovazioneValueTemplate'
        }

        , { name: 'titolo'
        , prop: 'titolo'
        , sortable: true
        , filtrable: true
        ,minWidth:190
        ,flexGrow:3
    }
        ,{ name:'direzione'
        ,prop:'areeTematiche'
        , sortable: false
        , filtrable: false
        ,cellTemplate:'direzioneFieldValueTemplate'
        ,minWidth:190
        ,flexGrow:3
    }
    , {
        name: 'dettaglio'
        , prop: 'id'
        , sortable: false
        , filtrable: false
        , cellTemplate: 'btnDettaglioValidatiTemplate'
        , flexGrow: 1
        , minWidth: 60


    }
    ];


    mode: string = 'list';

    constructor(
        private http: HttpClient,
        private el: ElementRef,
        private localeService: BsLocaleService,
        private toastr: ToastService,
        private modalService: BsModalService,
        private finanziamentoService: FinanziamentoService,
        private interventoService: InterventoService,
        private orderPipe: OrderPipe
        , private authService: AuthService
        ,private progettoDivDettaglioService:ProgettoDivDettaglioService
        ,private validazioneService:ValidazioneService
    ) {
        this.localeService.use("it");
        this.dtOptionsFinanziamenti = {
            rows: []
            , totalElements: 0
            , columns: this.columnsFinanziamenti
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , scrollbarH: "true"
            , ajax: this.getFinanziamentiDaValidare
        };
        this.dtOptionsInterventi = {
            rows: []
            , totalElements: 0
            , columns: this.columnsInterventi
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , scrollbarH: "true"
            , ajax: this.getInterventiDaValidare
        };

        this.dtOptionsValidati = {
            rows: []
            , totalElements: 0
            , columns: this.columnsValidati
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , scrollbarH: "true"
            , ajax: this.getValidati
        };
    }

    ngOnInit() {
        window.scroll(0, 0);
        this.templateList = [
            {name:'btnDettaglioFinanziamentoTemplate',template:this.btnDettaglioFinanziamentoTemplate},
            {name:'btnDettaglioInterventoTemplate',template:this.btnDettaglioInterventoTemplate},
            {name:'annualitaInterventiFieldValueTemplate',template:this.annualitaInterventiFieldValueTemplate},
             { name: 'btnBanInterventiTemplate', template: this.btnBanInterventiTemplate }
             , { name: 'btnCheckInterventiTemplate', template: this.btnCheckInterventiTemplate }
             ,{ name: 'fonteStrumentoAttuativoValueTemplate', template: this.fonteStrumentoAttuativoValueTemplate }
             , { name: 'datiRichiestaValueTemplate', template: this.datiRichiestaValueTemplate }
             , { name: 'direzioneFieldValueTemplate', template: this.direzioneFieldValueTemplate }
             , { name: 'btnBanTemplate', template: this.btnBanTemplate }
             , { name: 'btnCheckTemplate', template: this.btnCheckTemplate }
             , { name: 'disponibilitaFieldValueTemplate', template: this.disponibilitaFieldValueTemplate }
             , { name: 'annualitaFieldValueTemplate', template: this.annualitaFieldValueTemplate }
             , { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }
             , { name: 'btnEditTemplate', template: this.btnEditTemplate }
             , { name: 'datiRichiestaValidateValueTemplate', template: this.datiRichiestaValidateValueTemplate }
             , { name: 'datiRichiestaApprovazioneValueTemplate', template: this.datiRichiestaApprovazioneValueTemplate }
             , { name: 'btnDettaglioValidatiTemplate', template: this.btnDettaglioValidatiTemplate }

            ];
    }
    ngAfterViewInit() {
        this.ngxDatatableWrapperFinanziamenti.clearHeaderColumnsTitles();
        this.ngxDatatableWrapperInterventi.clearHeaderColumnsTitles();
        //this.getInterventiDaValidareNoDt();
    }


    getFinanziamentiDaValidare = (dataTablesParameters: any, callback: any) => {
        let that = this;
        this.finanziamentoService.getAllDaValidareDt(dataTablesParameters)
            .subscribe(resp => {
                callback(resp)
            });
    }

    getInterventiDaValidareNoDt(){
        this.interventoService.getAllDaValidareDt(null).subscribe(
            resp=>{
                this.ngxDatatableWrapperInterventi.setInitialData(resp.data);
            }
        );
    }

    getInterventiDaValidare = (dataTablesParameters: any, callback: any) => {
        let that = this;
        this.interventoService.getAllDaValidareDt(dataTablesParameters)
            .subscribe(resp => {
                callback(resp)
            });
    }

    getValidati = (dataTablesParameters: any, callback: any) => {
        let that = this;
        this.validazioneService.getAllDt(dataTablesParameters)
            .subscribe(resp => {
                callback(resp)
            });
    }


    closeModal() {
        this.modalRef.hide();
    }


    //tabs functions
    confirmChangeTab($event, index) {
        switch (index) {
            case 0:
                this.activeTab = "tabFinanziamenti";
                break;
            case 1:
                this.activeTab = "tabInterventi";
                break;
            case 2:
                this.activeTab = "tabValidati";
                this.ngxDatatableWrapperValidati.refresh();
                break;
        }
    }
    activeTab: string = "tabFinanziamenti";



    printValueAsCurrency(value) {
        if (!value) value = 0;
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }
    sortImporti(arr) {
        return this.orderPipe.transform(arr, 'anno');
    }

    printDirezioneFieldValue(data) {
        let str = '';
        let arr = [];
        if (data && data != '') {
            //let obj = JSON.parse(data);

            for (let o in data) {
                let str1 = data[o]["descrizione"];
                if (data[o]["disabled"])str1 = `(disattiva) ${str1}`;
                arr.push(str1);
            }
            str = arr.join('<br />');
        }
        return arr;
    }

    printDatiRichiestaFieldValue(row: any) {
        let validazione = row.validazione;
        if (validazione) {
            let data = moment(validazione.validationChangeStatusDate).toDate();
            data.setHours(data.getHours() - data.getTimezoneOffset() / 60);
            let result = moment(data).format('DD/MM/YYYY');
            let user = validazione.validationRequestUserLabel;
            result += '\n' + user;
            return result;
        }
        return '';
    }


    printDatiRichiestaValidateFieldValue(row: any) {
        let validazione = row.validationRequestDate;
        if (validazione) {
            let data = moment(validazione).toDate();
            data.setHours(data.getHours() - data.getTimezoneOffset() / 60);
            let result = moment(data).format('DD/MM/YYYY');
            let user = row.validationRequestUserLabel;
            result += '\n' + user;
            return result;
        }
        return '';
    }

    printDatiApprovazioneValidateFieldValue(row: any) {
        let validazione = row.validationChangeStatusDate;
        if (validazione) {
            let data = moment(validazione).toDate();
            data.setHours(data.getHours() - data.getTimezoneOffset() / 60);
            let result = moment(data).format('DD/MM/YYYY');
            let user = row.validationApprovedUserLabel;
            result += '\n' + user;
            return result;
        }
        return '';
    }

    rifiutaFinanziamento(row) {
        let msg = `Stai per rifiutare la validazione del finanziamento ${row.titolo}, proseguire?`;
        if (!confirm(msg))return;
        this.eseguiValidazioneFinanziamento(row,false);
    }

    validaFinanziamento(row) {
        let msg = `Stai per validare il finanziamento ${row.titolo}, proseguire?`;
        if (!confirm(msg))return;
        this.eseguiValidazioneFinanziamento(row,true);

    }

    reloadFinanziamenti(){
        this.ngxDatatableWrapperFinanziamenti.refresh();
    }

    eseguiValidazioneFinanziamento(row,action){
        this.finanziamentoService.manageValidation(row.id,action).subscribe(
            result=>{
                this.reloadFinanziamenti();
                let msg = '';
                if(action)msg = 'Validazione avvenuta con successo';
                if (!action)msg = 'Validazione annullata con successo';
                this.toastr.success(msg, null, {
                    timeOut: 4000, disableTimeOut: false
                });
                if (result.warningMessage && result.warningMessage!=''){
                    this.toastr.warning(result.warningMessage,null,{timeOut:10000,disableTimeOut: false});
                }

            }
            , error => {
                this.toastr.error(`Errore: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );
    }


    rifiutaIntervento(row) {
        let msg =  `Stai per rifiutare la validazione dell'intervento ${row.codice} - ${row.descrizione}, proseguire?`;
        if (!confirm(msg))return;
        this.eseguiValidazioneIntervento(row,false);
    }

    validaIntervento(row) {
        let msg = `Stai per validare l'intervento ${row.codice} - ${row.descrizione}, proseguire?`;
        if (!confirm(msg))return;
        this.eseguiValidazioneIntervento(row,true);

    }

    reloadInterventi(){
        this.ngxDatatableWrapperInterventi.refresh();
    }

    eseguiValidazioneIntervento(row,action){
        this.interventoService.manageValidation(row.id,action).subscribe(
            result=>{
                this.reloadInterventi();
                let msg = '';
                if(action)msg = 'Validazione avvenuta con successo';
                if (!action)msg = 'Validazione annullata con successo';
                this.toastr.success(msg, null, {
                    timeOut: 4000, disableTimeOut: false
                });
                if (result.warningMessage && result.warningMessage!=''){
                    this.toastr.warning(result.warningMessage,null,{timeOut:10000,disableTimeOut: false});
                }

            }
            , error => {
                this.toastr.error(`Errore: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );
    }
    calcolaValoreIntervento(row:any){

        if (!row || !row['importi'])return this.printValueAsCurrency(0);
        let valore = 0;
        row['importi'].forEach(element => {
            valore +=element['importo'];
        });
        return this.printValueAsCurrency( valore);
    }

    @ViewChild('modaleIntervento', { static: true })
    public modaleIntervento: TemplateRef<any>;
    selectedInterventoData=null;
    onDetailIntervento(row){
        if (this.progettoDivDettaglioService)this.progettoDivDettaglioService.setId(row.progettoId);
        this.selectedInterventoData = JSON.stringify({interventoId:row.id,projectId:row.progettoId});
        this.modalRef = this.modalService.show(this.modaleIntervento, this.config);

    }

    finanziamentoId:number;
    finanziamento:any;
    @ViewChild('modaleFinanziamento', { static: true })
    public modaleFinanziamento: TemplateRef<any>;
    onDetailFinanziamento(row){
        this.finanziamentoId = row.id;
        this.finanziamento = row;
        this.modalRef = this.modalService.show(this.modaleFinanziamento, this.config);
    }

    isAdmin() {
        return this.authService.isAdmin();
      }

      onDetailValidati(row){
          var myrow = JSON.parse(JSON.stringify(row));
          myrow.id = myrow.objectId;
          if (row.tipologia == 'FINANZIAMENTO'){
            this.onDetailFinanziamento(myrow);
          }
          else{
            this.onDetailIntervento(myrow);
          }
      }

}



