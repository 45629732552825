import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import {  CommonModule } from "@angular/common";
import{UploadFileComponent} from './index';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';



@NgModule({
  declarations: [
    UploadFileComponent
],
  exports: [
    UploadFileComponent
]

  ,imports:[
      CommonModule,
      ModalModule.forRoot(),
      ProgressbarModule.forRoot(),
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule    
  ],
  providers: [    
  ],
  entryComponents: [    
  ]
})
export class UploadFileModule {}