import { SoggettiRuoliComponent } from "./components/soggettiRuoli/soggetti-ruoli.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppConfigService } from "src/app/services/app-config.service";
import { LoggedGuard } from "src/app/guards/logged-guard";
import { RoleGuard } from "src/app/guards/role-guard";
import { InframobComponent } from "./components/inframob/inframob.component";
import { AreeTematicheComponent } from "./components/areeTematiche/aree-tematiche.component";
import { TipoClassificazioneComponent } from "./components/tipiClassificazione/tipi-classificazione.component";
import { ClassificazioneComponent } from "./components/Classificazione/classificazione.component";
import { StatiFinanziamentoComponent } from "./components/statiFinanziamento/stati-finanziamento.component";
import { SoggettiGiuridiciComponent } from "./components/soggettiGiuridici/soggetti-giuridici.component";
import { TipiFinanziamentoComponent } from "./components/tipiFinanziamento/tipi-finanziamento.component";
import { TipiProvvedimentoComponent } from "./components/tipiProvvedimento/tipi-provvedimento.component";
import { ProgettiComponent } from "./components/progetti/progetti.component";
import { TemiPrioritariComponent } from "./components/temiPrioritari/temi-prioritari.component";
import { TipologieFasiComponent } from "./components/tipologieFasi/tipologie-fasi.component";
import { ProgettoComponent } from "./components/progetto/progetto.component";
import { ScopiProvvedimentoComponent } from "./components/scopiProvvedimento/scopi-provvedimento.component";
import { CapitoliBilancioComponent } from "./components/capitoliBilancio/capitoli-bilancio.component";
import { ProvvedimentiComponent } from "./components/provvedimenti/provvedimenti.component";
import { TipologieSoggettoResponsabileComponent } from "./components/tipologieSoggettoResponsabile/tipologie-soggetto-responsabile.component";
import { SoggettoResponsabileComponent } from "./components/soggettoResponsabile/soggetto-responsabile.component";
import { TipiStrumentoAttuativoComponent } from "./components/tipiStrumentoAttuativo/tipi-strumento-attuativo.component";
import { FinanziamentiComponent } from "./components/finanziamenti/finanziamenti.component";
import { TipologieErogazioniComponent } from "./components/tipologieErogazioni/tipologie-erogazioni.component";
import { CategorieSalComponent } from "./components/categorieSal/categorie-sal.component";
import { MacroCategorieSalComponent } from "./components/macroCategorieSal/macro-categorie-sal.component";
import { ValidazioneComponent } from "./components/validazione/validazione.component";
import { DashboardFinanziariaComponent } from "../inframob/components/dashboardFinanziaria/dashboard-finanziaria.component";
import { DashboardErogazioniComponent } from "../inframob/components/dashboardErogazioni/dashboard-erogazioni.component";
import { DashboardFasiComponent } from "./components/dashboard-fasi/dashboard-fasi.component";
import { DashboardStatoFinanziamentiComponent } from "./components/dashboard-stato-finanziamenti/dashboard-stato-finanziamenti.component";
import { DirtyCheckGuard } from "../../guards/dirty-check.guard";
import { StoricoGeneraleComponent } from "./components/storicoGenerale/storico-generale.component";
import { DashboardStatoInterventiComponent } from "./components/dashboard-stato-interventi/dashboard-stato-interventi.component";
import { DettaglioProvvedimentiComponent } from "./components/provvedimenti/dettaglio-provvedimenti/dettaglio-provvedimenti.component";

import { GestioneMassivaFasiComponent } from './components/gestioneMassivaFasi/gestione-massiva-fasi.component';
import { GestioneMassivaClassificazioniComponent } from './components/gestioneMassivaClassificazioni/gestione-massiva-classificazioni.component';
import { GestioneMassivaAreeTematicheComponent } from './components/gestioneMassivaAreeTematiche/gestione-massiva-aree-tematiche.component';
import { GestioneMotivazioniComponent } from './components/gestioneMotivazioni/gestione-motivazioni.component';
import { DashboardFasiGanttComponent } from './components/dashboard-fasi-gantt/dashboard-fasi-gantt.component';
import { DashboardInterventiGanttComponent } from "./components/dashboard-interventi-gantt/dashboard-interventi-gantt.component";
import { SpostamentoMassivoInterventiComponent } from "./components/spostamentoMassivoInterventi/spostamento-massivo-interventi.component";

import { MotivazioniCancellazioneComponent } from "./components/motivazioniCancellazione/motivazioni-cancellazione.component";
import { MonitoraggioDistanzaInterventoComponent } from "./components/monitoraggioDistanzeIntervento/monitoraggio-distanze-intervento.component";
import { MonitoraggioSitiReteNaturaComponent } from "./components/monitoraggioSitiReteNatura/monitoraggio-siti-rete-natura.component";
import { MonitoraggiComponent } from "./components/monitoraggi/monitoraggi.component";
import { DocumentazioneCompletaComponent } from "./components/documentazioneCompleta/documentazione-completa.component";
import { StatoAttuazioneComponent } from './components/statoAttuazione/stato-attuazione.component'

import { PnrrManagementComponent } from "./components/pnrrManagement/index";
import { SearchAreaComponent } from "src/app/modules/inframob/components/search-area/search-area.component";
import { SicerGestioneMicroservicesComponent } from "./components/sicer-gestione-microservices/sicer-gestione-microservices.component";
import { GestioneNaturaTipologieComponent } from "./components/gestione-natura-tipologie/gestione-natura-tipologie.component";
import { GestioneTipologiaInterventiComponent } from "./components/gestione-tipologia-interventi/gestione-tipologia-interventi.component";
import{DashboardPnrrComponent} from "./components/dashboard-pnrr/dashboard-pnrr.component";
import{DashboardPkmgComponent} from "./components/dashboard-pkmg/dashboard-pkmg.component";
import { DashboardEntrataUscitaComponent } from "./components/dashboard-entrata-uscita/dashboard-entrata-uscita.component";
import { GestioneBancheDatiEsterneComponent } from "./components/gestioneBancheDatiEsterne/gestione-banche-dati-esterne.component";
import { GestioneAggregatiComponent } from "./components/gestione-aggregati/gestione-aggregati.component";
import { RichiestaCapitoloSicerComponent } from "./components/richiesta-capitolo-sicer/richiesta-capitolo-sicer.component";
import { TipologieSismaComponent } from "./components/tipologieSisma/tipologie-sisma.component";
import { CategorieSismaComponent } from "./components/categorie-sisma/categorie-sisma.component";
import { DashboardPnrrNonDisegnabileComponent } from "./components/dashboard-pnrr-non-disegnabile/dashboard-pnrr-non-disegnabile.component";

import {DashboardPnrrPubblicaComponent} from './components/dashboard-pnrr-pubblica/dashboard-pnrr-pubblica.component';
import {DashboardPnrrPubblicaBisComponent} from './components/dashboard-pnrr-pubblica-bis/dashboard-pnrr-pubblica-bis.component';
import { DashboardAnalisiReportingRisorseProgettiFinanziatiPnrrComponent } from './components/dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr/dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr.component';
import { CruscottoNoteAttiComponent } from "./components/cruscottoNoteAtti/cruscotto-note-atti.component";
import { GestioneMenuComponent } from "./components/gestione-menu/gestione-menu.component";
import { Roles } from "src/app/helpers/constants";

const routes: Routes = [
  {
    path: "",
    component: InframobComponent,
    data: { breadcrumb: null },
    canDeactivate: [DirtyCheckGuard],
    children: [
      {
        path: "dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr",
        data: {
            breadcrumb: ""
        },
        component: DashboardAnalisiReportingRisorseProgettiFinanziatiPnrrComponent,
        canActivate: []
    },
        {
            path: "dashboard-pnrr-pubblica",
            data: {
                breadcrumb: ""
            },
            component: DashboardPnrrPubblicaComponent,
            canActivate: []
        },
        {
            path: "dashboard-pnrr-pubblica-bis",
            data: {
                breadcrumb: ""
            },
            component: DashboardPnrrPubblicaBisComponent,
            canActivate: []
        },


      {
        path: "",
        //component: InframobComponent,
        data: { breadcrumb: "Admin", clickable: false },
        children: [
          {
            path: "manageSoggettiRuoli",
            data: {
              breadcrumb: "Gestione Soggetti ruoli",
              expectedRole: [Roles.role_admin],
            },
            component: SoggettiRuoliComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "gestione-menu",
            data: {
              breadcrumb: "Gestione Menu",
              expectedRole: [Roles.role_admin],
            },
            component: GestioneMenuComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "manageAreeTematiche",
            data: {
              breadcrumb: "Gestione Aree direzione",
              expectedRole: [Roles.role_admin],
            },
            component: AreeTematicheComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "manageTipiClassificazione",
            data: {
              breadcrumb: "Gestione Tipi classificazione",
              expectedRole: [Roles.role_admin],
            },
            component: TipoClassificazioneComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "manageClassificazioni",
            data: {
              breadcrumb: "Gestione Classificazioni",
              expectedRole: [Roles.role_admin],
            },
            component: ClassificazioneComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "manageStatiFinanziamento",
            data: {
              breadcrumb: "Gestione Stati finanziamento",
              expectedRole: [Roles.role_admin],
            },
            component: StatiFinanziamentoComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "manageSoggettiGiuridici",
            data: {
              breadcrumb: "Gestione Soggetti giuridici",
              expectedRole: [Roles.role_admin],
            },
            component: SoggettiGiuridiciComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "manageTipiFinanziamento",
            data: {
              breadcrumb: "Gestione Fonti finanziamento",
              expectedRole: [Roles.role_admin],
            },
            component: TipiFinanziamentoComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "manageTipiProvvedimento",
            data: {
              breadcrumb: "Gestione Tipi provvedimento",
              expectedRole: [Roles.role_admin],
            },
            component: TipiProvvedimentoComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },

          {
            path: "manageTemiPrioritari",
            data: {
              breadcrumb: "Gestione Temi prioritari",
              expectedRole: [Roles.role_admin],
            },
            component: TemiPrioritariComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },

          {
            path: "manageTipologieFasi",
            data: {
              breadcrumb: "Gestione Tipologie fasi",
              expectedRole: [Roles.role_admin],
            },
            component: TipologieFasiComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "manageScopiProvvedimento",
            data: {
              breadcrumb: "Gestione Scopi provvedimento",
              expectedRole: [Roles.role_admin],
            },
            component: ScopiProvvedimentoComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "manageCapitoliBilancio",
            data: {
              breadcrumb: "Gestione Capitoli di bilancio",
              expectedRole: [Roles.role_admin],
            },
            component: CapitoliBilancioComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "manageTipologieSoggettiResponsabili",
            data: {
              breadcrumb: "Gestione Tipologie soggetti responsabili",
              expectedRole: [Roles.role_admin],
            },
            component: TipologieSoggettoResponsabileComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "manageSoggettiResponsabili",
            data: {
              breadcrumb: "Gestione Soggetti responsabili",
              expectedRole: [Roles.role_admin],
            },
            component: SoggettoResponsabileComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "manageTipiStrumentiAttuativi",
            data: {
              breadcrumb: "Gestione Tipologie strumenti attuativi",
              expectedRole: [Roles.role_admin],
            },
            component: TipiStrumentoAttuativoComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "manageTipologieErogazioni",
            data: {
              breadcrumb: "Gestione Tipologie erogazioni",
              expectedRole: [Roles.role_admin],
            },
            component: TipologieErogazioniComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "manageMacroCategorieSal",
            data: {
              breadcrumb: "Gestione Macro categorie SAL",
              expectedRole: [Roles.role_admin],
            },
            component: MacroCategorieSalComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "manageCategorieSal",
            data: {
              breadcrumb: "Gestione Categorie SAL",
              expectedRole: [Roles.role_admin],
            },
            component: CategorieSalComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "storico",
            data: {
              breadcrumb: "Storico",
              expectedRole: [Roles.role_manager],
            },
            component: StoricoGeneraleComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "gestione-motivazioni",
            data: {
              breadcrumb: "Gestione motivazioni",
              expectedRole: [Roles.role_manager],
            },
            component: GestioneMotivazioniComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "gestione-dettagli-tipologie",
            data: {
              breadcrumb: "Gestione dettagli tipologie",
              expectedRole: [Roles.role_manager],
            },
            component: GestioneAggregatiComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "richiesta-capitolo-sicer",
            data: {
              breadcrumb: "Richiesta Capitolo Sicer",
              expectedRole: [Roles.role_manager],
            },
            component: RichiestaCapitoloSicerComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "gestione-priorita-motivazioni",
            data: {
              breadcrumb: "Gestione priorità motivazioni",
              expectedRole: [Roles.role_manager],
            },
            component: GestioneMotivazioniComponent,
            canActivate: [LoggedGuard, RoleGuard],
          }

          ,
          {
            path: "gestione-motivazioni-cancellazione",
            data: {
              breadcrumb: "Gestione motivazioni della cancellazione",
              expectedRole: [Roles.role_admin],
            },
            component: MotivazioniCancellazioneComponent,
            canActivate: [LoggedGuard, RoleGuard],
          }
          ,
          {
            path: "monitoraggio-distanze-intervento",
            data: {
              breadcrumb: "Gestione distanze intervento",
              expectedRole: [Roles.role_admin],
            },
            component: MonitoraggioDistanzaInterventoComponent,
            canActivate: [LoggedGuard, RoleGuard],
          }
          ,
          {
            path: "monitoraggio-siti-rete-natura",
            data: {
              breadcrumb: "Gestione Siti Rete Natura",
              expectedRole: [Roles.role_admin],
            },
            component: MonitoraggioSitiReteNaturaComponent,
            canActivate: [LoggedGuard, RoleGuard],
          }
          ,
          {
            path: "stati-attuazione",
            data: {
              breadcrumb: "Gestione Stati di Attuazione",
              expectedRole: [Roles.role_admin],
            },
            component: StatoAttuazioneComponent,
            canActivate: [LoggedGuard, RoleGuard],
          }
          ,
          {
            path: "documentazione-completa",
            data: {
              breadcrumb: "Documentazione",
              expectedRole: [Roles.role_manager],
            },
            component: DocumentazioneCompletaComponent,
            canActivate: [LoggedGuard, RoleGuard],
          }
          ,
          {
            path: "gestione-pnrr",
            data: {
              breadcrumb: "Gestione PNRR",
              expectedRole: [Roles.role_manager],
            },
            component: PnrrManagementComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "gestione-natura-tipologie",
            data: {
              breadcrumb: "Gestione Natura Tipologie",
              expectedRole: [Roles.role_admin],
            },
            component: GestioneNaturaTipologieComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "gestione-tipologia-interventi",
            data: {
              breadcrumb: "Gestione Tipologia Interventi",
              expectedRole: [Roles.role_admin],
            },
            component: GestioneTipologiaInterventiComponent,
            canActivate: [LoggedGuard, RoleGuard],
          }
          ,
          {
            path: "gestione-banche-dati-esterne",
            data: {
              breadcrumb: "Gestione Banche Dati Esterne",
              expectedRole: [Roles.role_admin],
            },
            component: GestioneBancheDatiEsterneComponent,
            canActivate: [LoggedGuard, RoleGuard],
          }
          ,
          {
            path: "tipologie-sisma",
            data: {
              breadcrumb: "Gestione tipologie Sisma",
              expectedRole: [Roles.role_admin],
            },
            component: TipologieSismaComponent,
            canActivate: [LoggedGuard, RoleGuard],
          }
          ,
          {
            path: "categorie-sisma",
            data: {
              breadcrumb: "Gestione categorie Sisma",
              expectedRole: [Roles.role_admin],
            },
            component: CategorieSismaComponent,
            canActivate: [LoggedGuard, RoleGuard],
          }
        ],
      },

      {
        path: "",
        data: {
          breadcrumb: "Strumenti",
          clickable: false,
          expectedRole: [],
        }
        , canActivate: [LoggedGuard],
        children: [
          {
            path: "gestioneMassivaFasi",
            data: { breadcrumb: "Gestione massiva fasi" },
            component: GestioneMassivaFasiComponent,
            canActivate: [LoggedGuard],
          },
          {
            path: "gestioneMassivaClassificazioni",
            data: {
              breadcrumb: "Gestione massiva classificazioni"
              , expectedRole: [Roles.role_manager],
            },
            component: GestioneMassivaClassificazioniComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "manageTipologieFasiReadOnly",
            data: {
              breadcrumb: "Elenco tipologie fasi",
              expectedRole: [],
            },
            component: TipologieFasiComponent,
            canActivate: [LoggedGuard],
          },
          {
            path: "spostamentoMassivoInterventi",
            data: {
              breadcrumb: "Spostamento interventi"
              , expectedRole: [Roles.role_admin],
            },
            component: SpostamentoMassivoInterventiComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "gestioneMassivaAreeDirezionali",
            data: {
              breadcrumb: "Gestione massiva aree direzionali"
              , expectedRole: [Roles.role_manager],
            },
            component: GestioneMassivaAreeTematicheComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "areaDiRicerca",
            data: {
              breadcrumb: "Area di ricerca"
              , expectedRole: [Roles.role_manager],
            },
            component: SearchAreaComponent,
            canActivate: [LoggedGuard, RoleGuard],
          },
          {
            path: "sicerGestioneMicroservices",
            data: {
              breadcrumb: "Gestione Microservices Sicer",
              expectedRole: [
                Roles.role_manager
              ]
            },
            component: SicerGestioneMicroservicesComponent,
            canActivate: [LoggedGuard, RoleGuard]
          }

        ]
      },


      {
        path: "",
        data: {
          breadcrumb: "Servizi e Report",
          clickable: false,
          expectedRole: [],
        },
        canActivate: [LoggedGuard],
        children: [
          {
            path: "dashboard-finanziaria",
            data: { breadcrumb: "Dashboard di sintesi" },
            component: DashboardFinanziariaComponent,
            canActivate: [LoggedGuard],
          },
          {
            path: "dashboard-erogazioni",
            data: { breadcrumb: "Dashboard pagamenti" },
            component: DashboardErogazioniComponent,
            canActivate: [LoggedGuard],
          },
          {
            path: "dashboard-fasi",
            data: { breadcrumb: "Dashboard fasi" },
            component: DashboardFasiComponent,
            canActivate: [LoggedGuard],
          },
          {
            path: "dashboard-stato-finanziamento",
            data: { breadcrumb: "Dashboard stato finanziamenti" },
            component: DashboardStatoFinanziamentiComponent,
            canActivate: [LoggedGuard],
          },
          {
            path: "dashboard-stato-intervento",
            data: { breadcrumb: "Dashboard stato interventi" },
            component: DashboardStatoInterventiComponent,
            canActivate: [LoggedGuard],
          },
          {
            path: "dashboard-fasi-gantt",
            data: { breadcrumb: "Dashboard Gantt Fasi" },
            component: DashboardFasiGanttComponent,
            canActivate: [LoggedGuard],
          },
          {
            path: "dashboard-interventi-gantt",
            data: { breadcrumb: "Dashboard Gantt Interventi" },
            component: DashboardInterventiGanttComponent,
            canActivate: [LoggedGuard],
          },
          {
            path: "monitoraggi",
            data: { breadcrumb: "Monitoraggio PRMTL" },
            component: MonitoraggiComponent,
            canActivate: [LoggedGuard],
          },
          {
            path: "dashboard-pnrr",
            data: { breadcrumb: "Dashboard PNRR" },
            component: DashboardPnrrComponent,
            canActivate: [LoggedGuard],
          }
          ,
          {
            path: "dashboard-pkmg",
            data: { breadcrumb: "Dashboard PKMG"
            ,expectedRole: [
                Roles.role_manager
              ]
        },
            component: DashboardPkmgComponent,
            canActivate: [LoggedGuard,RoleGuard]

          }
          ,
          {
            path: "dashboard-entrata-uscita",
            data: { breadcrumb: "Dashboard Entrata/Uscita" },
            component: DashboardEntrataUscitaComponent,
            canActivate: [LoggedGuard],
          },
          {
            path: "dashboard-non-disegnabile",
            data: { breadcrumb: "Dashboard PNRR Non Disegnabile" },
            component: DashboardPnrrNonDisegnabileComponent,
            canActivate: [LoggedGuard],
          },
          {
            path: "dashboard-sisma",
            data: { breadcrumb: "Dashboard sisma" },
            component: DashboardFinanziariaComponent,
            canActivate: [LoggedGuard],
          }
          ,
          {
            path: "dashboard-fasi-sisma",
            data: { breadcrumb: "Dashboard fasi sisma" },
            component: DashboardFasiComponent,
            canActivate: [LoggedGuard],
          }
          ,
          {
            path: "cruscotto-note-atti",
            data: { breadcrumb: "Cruscotto Note-Atti" },
            component: CruscottoNoteAttiComponent,
            canActivate: [LoggedGuard],
          }
        ],
      },

      {
        path: "",
        data: {
          breadcrumb: "Gestione investimenti",
          clickable: false,
          expectedRole: [],
        },
        canActivate: [LoggedGuard],
        children: [
          {
            //list
            path: "progetti",
            data: {
              breadcrumb: "Elenco progettualità / fabbisogni",
              expectedRole: [],
            },
            component: ProgettiComponent,
            canActivate: [LoggedGuard],
            canDeactivate: [DirtyCheckGuard],
          },
          {
            //list
            path: "progetti/:id",
            data: {
              breadcrumb: "Elenco progettualità / fabbisogni",
              expectedRole: [],
            },
            component: ProgettiComponent,
            canActivate: [LoggedGuard],
            canDeactivate: [DirtyCheckGuard],
          },
          {
            //list
            path: "progetti/:id/:idIntervento",
            data: {
              breadcrumb: "Elenco progettualità / fabbisogni",
              expectedRole: [],
            },
            component: ProgettiComponent,
            canActivate: [LoggedGuard],
            canDeactivate: [DirtyCheckGuard],
          },
          {
            // new
            path: "progetto",
            data: { breadcrumb: "Nuovo progetto / fabbisogno" },
            canActivate: [LoggedGuard],
            component: /*DatiGeneraliProgettoComponent*/ ProgettoComponent,
            canDeactivate: [DirtyCheckGuard],
          },
          {
            // edit
            path: "progetto/:id",
            data: { breadcrumb: "Modifica progetto / fabbisogno" },
            canActivate: [LoggedGuard],
            component: /*DatiGeneraliProgettoComponent*/ ProgettoComponent,
            canDeactivate: [DirtyCheckGuard],
          },
          {
            path: "manageFinanziamenti",
            data: {
              breadcrumb: "Gestione Finanziamenti",
              expectedRole: [],
            },
            component: FinanziamentiComponent,
            canActivate: [LoggedGuard],
            canDeactivate: [DirtyCheckGuard],
          },
          {
            path: "manageFinanziamenti/:id",
            data: {
              breadcrumb: "Gestione Finanziamenti",
              expectedRole: [],
            },
            component: FinanziamentiComponent,
            canActivate: [LoggedGuard],
            canDeactivate: [DirtyCheckGuard],
          },
          {
            path: "manageProvvedimenti",
            data: {
              breadcrumb: "Gestione Provvedimenti",
              expectedRole: [],
            },
            component: ProvvedimentiComponent,
            canDeactivate: [DirtyCheckGuard],
            canActivate: [LoggedGuard],
          },
          {
            path: "validazioni",
            data: {
              breadcrumb: "Gestione Validazioni",
              expectedRole: [],
            },
            component: ValidazioneComponent,
            canActivate: [LoggedGuard],
          },
          {
            path: "pippo",
            data: {
              breadcrumb: "pippo",
              expectedRole: [],
            },
            component: DettaglioProvvedimentiComponent,
            canActivate: [LoggedGuard],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InfraMobRoutingModule { }
