import {
    Component,
    Input,
    OnInit,
    ChangeDetectionStrategy,
} from "@angular/core";
import * as moment from 'moment';
import { Observable } from "rxjs";
import { CurrencyPipe } from "@angular/common";

@Component({
    selector: "dashboard-stato-finanziamenti-details-table-component",
    templateUrl: "./dashboard-stato-finanziamenti-details-table.component.html",
    styleUrls: ["./dashboard-stato-finanziamenti-details-table.component.css"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardStatoFinanziamentiDetailsTableComponent implements OnInit {


    _tabellaDettaglioObj:any;
    
    @Input()
    set tabellaDettaglioObj(val){
        this._tabellaDettaglioObj = val;
        console.log(`inside set tabellaDettaglioObj with = ${val}`);
    }

    get tabellaDettaglioObj(){
        return this._tabellaDettaglioObj;
    }

    _observable$ :Observable<any>;

    @Input()
    public set observable$(val){
        this._observable$ = val;
        this.tabellaDettaglioObj = val;
        console.log(`inside set observable with = ${val}`);
        this.getArrayAnni();
    }
    
    public get observable$(){
        return this._observable$;
    }    

    constructor() { }
    ngOnInit(): void { }


    get currentObject(): any {
        if (this.observable$) return this.observable$;
        if (this.tabellaDettaglioObj) return this.tabellaDettaglioObj;
    }

    trackByFn(index, item) {
        return index;
    }

    printDate(date) {
        if (!date) return '';
        let v = moment(date).toDate();
        //v.setHours(v.getHours() - v.getTimezoneOffset() / 60);
        return moment(v).format('DD/MM/YYYY');
    }

    printValueAsDateWithCurrentDateAsDefault(value) {
        if (!value) value = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())).toDate();
        return this.printValueAsDate(value);
    }

    printValueAsDate(value) {
        if (!value || value == '') return '';
        let v = moment(value).toDate();
        return moment(v).format('DD/MM/YYYY');
    }
    printValueAsCurrency(value) {
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = typeof value === "number" ? value : value.replace(",", ".").trim();
        const format = "1.2-2";
        const currency = "€";
        const currentLocale: string = "it";
        return new CurrencyPipe(currentLocale).transform(
          value,
          "EUR",
          currency,
          format,
          "it-IT"
        );
      }

    arrayAnni=[];
    getArrayAnni(){
        this.arrayAnni = [];
        this.observable$ && this.observable$.forEach(finanziamento=>{
            finanziamento.annualita.forEach(annualita => {
                if(!this.arrayAnni.includes('' + annualita.anno)) this.arrayAnni.push('' + annualita.anno);
            });
        });
        console.log(`arrayAnni=${this.arrayAnni}`);
        this.arrayAnni.sort((a,b)=>a-b);
    }

    getValoreFinanziamento(finanziamento,anno){
        let a = finanziamento.annualita.find(a=>a.anno == anno);
        if (!a) return 0;
        return a.valore;
    }
    getImpegnatoFinanziamento(finanziamento,anno){
        let a = finanziamento.annualita.find(a=>a.anno == anno);
        if (!a) return 0;
        return a.impegnato;
    }
    getDisponibilitaFinanziamento(finanziamento,anno){
        let a = finanziamento.annualita.find(a=>a.anno == anno);
        if (!a) return 0;
        return a.disponibilita;
    }

    getValoreFinanziamentoTotale(finanziamento){
        let somma = 0;
        finanziamento.annualita.forEach(element => {
            somma += element.valore;
        });
        return somma;
    }

    getImpegnatoFinanziamentoTotale(finanziamento){
        let somma = 0;
        finanziamento.annualita.forEach(element => {
            somma += element.impegnato;
        });
        return somma;
    }

    getDisponibilitaFinanziamentoTotale(finanziamento){
        let somma = 0;
        finanziamento.annualita.forEach(element => {
            somma += element.disponibilita;
        });
        return somma;
    }

    getValoreTotale(anno){
        let somma = 0;
        this.tabellaDettaglioObj.forEach(
            finanziamento=>{
                if (anno)somma += this.getValoreFinanziamento(finanziamento,anno);
                else somma += this.getValoreFinanziamentoTotale(finanziamento);
            }
        );
        return somma;
    }
    getImpegnatoTotale(anno){
        let somma = 0;
        this.tabellaDettaglioObj.forEach(
            finanziamento=>{
                if (anno)somma += this.getImpegnatoFinanziamento(finanziamento,anno);
                else somma += this.getImpegnatoFinanziamentoTotale(finanziamento);
            }
        );
        return somma;
    }
    getDisponibilitaTotale(anno){
        let somma = 0;
        this.tabellaDettaglioObj.forEach(
            finanziamento=>{
                if (anno)somma += this.getDisponibilitaFinanziamento(finanziamento,anno);
                else somma += this.getDisponibilitaFinanziamentoTotale(finanziamento);
            }
        );
        return somma;
    }



}
