import { CurrencyPipe } from '@angular/common';
import {
    Component,
    OnInit,
    ElementRef,
    OnDestroy,
    Input,
    EventEmitter,
    Output,
    ViewChild,
    TemplateRef,
} from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Progetto } from "../../models";
import {
    ProgettoService,
    InterventoService,
    MonitoraggioService,
    StatoAttuazioneService
} from "../../services";
import { AuthService } from "../../../../services/auth.service";
import { Subscription } from "rxjs";
import { ToastService } from "src/app/services/toast.service";
import * as moment from "moment";
import { IsDirtyHelper } from "../../../../helpers/is-dirty.helper"
declare var $: any;
import { OrderPipe } from 'ngx-order-pipe';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: "monitoraggio-component",
    templateUrl: "./monitoraggio.component.html",
    styleUrls: ["./monitoraggio.component.css"],
})
export class MonitoraggioComponent implements OnInit, OnDestroy {
    section:string='Interventi';
    monitoraggioLabel = 'non attivo'
    public mask: any = {
        mask: 'KK €',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            }
        }
    };

    public maskValue: any = {
        mask: 'KK',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            }
        }
    };
    public maskValueNumber: any = {
        mask: 'KK',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 0,
                padFractionalZeros: false,
                radix: ','
            }
        }
    };


    tipologie = [{ key: 'ZPS', value: 'ZPS' },/* { key: 'ZPS/ZSC', value: 'ZPS/ZSC' }, */{ key: 'ZSC', value: 'ZSC' }];


    myGroup: FormGroup;
    submitted = false;
    loading = false;
    subscribed: Subscription;
    loadDataCounter: number = 0;

    currentProject = new Progetto();




    isIntervento = false;
    esistonoInterventi = false;

    _interventoId= null;
    @Input()
    set interventoId(value) {
      //  console.log(`inside set interventoData= ${value}`);
        if (!value) return;
        this._interventoId = value;
        if (this.reloadAllDataOnSetInterventoId)this.reloadAllData();
    }
    get interventoId() {
        return this._interventoId;
    }

    _isInModal=null;
    @Input()
    set isInModal(value){
        this._isInModal = value;
    }
    get isInModal(){
        return this._isInModal;
    }


    _isReadOnly = false;
    @Input()
    set isReadOnly(value){
        this._isReadOnly = value;
    }
    get isReadOnly(){
        return this._isReadOnly || !this.canEdit();
    }

    _reloadAllDataOnSetInterventoId = false;
    @Input()
    set reloadAllDataOnSetInterventoId(value){
        this._reloadAllDataOnSetInterventoId = value;
        if (value && this.interventoId) this.reloadAllData();
    }

    get reloadAllDataOnSetInterventoId(){
        return this._reloadAllDataOnSetInterventoId;
    }


    @Output() onFillFormCompleteOk: EventEmitter<any> = new EventEmitter();
    @Output() onSubmitOk: EventEmitter<any> = new EventEmitter();

    readOnlyForm: boolean = false;
    validato: boolean = false;



    constructor(
        private formBuilder: FormBuilder,
        private el: ElementRef,
        private progettoService: ProgettoService,
        private toastr: ToastService,
        private interventoService: InterventoService // private maskPipe:MaskPipe
        , private isDirtyHelper: IsDirtyHelper
        , private authService: AuthService
        , private monitoraggioService: MonitoraggioService
        , private orderPipe: OrderPipe
        ,private modalService: BsModalService,
        private statoAttuazioneService:StatoAttuazioneService
    ) {
        this.myGroup = this.initializeFormGroup();
        this.createFormReteNatura();
    }

    ngOnInit() {
        this.getSitiReteNatura();
        this.getDistanze();
        this.getStatiAttuazione();

    }




    setIsDirty(value: boolean) {
        this.isDirtyHelper.isDirty = value;
    }

    



    getInterventoData() {
        let that = this;
        this.esistonoInterventi = false;
        console.log("datigeneraliprogetto-getInterventoData");

        
            this.loadDataCounter++;
            this.interventoService.get(this._interventoId).subscribe((x) => {
                this.currentProject = x;

                let status = x.validazione
                    ? x.validazione.validationStatus
                        ? x.validazione.validationStatus
                        : 0
                    : 0;
                switch (status.toString().toUpperCase()) {
                    case "1":
                    case "APPROVED":
                    case "4":
                    case "WAITING_FOR_APPROVAL_FIRST_LEVEL":
                    case "5":
                    case "WAITING_FOR_APPROVAL_SECOND_LEVEL":
                    case "TERMINATO":
                        // that.statoFinanziamentoLabel ='in attesa di validazione';
                        //this.readOnlyForm = true;
                        this.validato = true;
                        break;
                }
                this.readOnlyForm = x.isReadOnly || this.readOnlyForm || this.isReadOnly;
                this.currentProject["importi"].sort((a, b) => a.anno - b.anno);
                this.fillForm();
                this.getImporti();
                this.getFasiByInterventoId();
                this.getFinanziamenti();
                this.getMonitoraggioData();
                this.loadDataCounter--;
                this.setIsDirty(false);
                
            });        
    }

    fillForm() {

        console.log("inside fillForm");
        let that = this;
        this.myGroup.setValue({
            codice: this.currentProject["codice"],
            descrizione: this.currentProject["descrizione"]
            , statoAttuazione:null
            , statoAttuazioneNote:null
            ,abilitato:null
        });
        this.onFillFormCompleteOk.emit(true);
        if (this.readOnlyForm)this.myGroup.disable();
        this.setIsDirty(false);
    }



    onSubmit() {
        console.log("progetto_onSubmit");
        this.submitted = true;
        //this.sitiReteNaturaIsError = this.sitiReteNaturaSelezionati.length<=0;



        var errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );
        }

        console.log("errors:" + errors);
        if (
            this.myGroup.invalid ||
            errors || this.sitiReteNaturaIsError
        ) {
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (var el in this.myGroup.controls) {
                if (this.myGroup.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.myGroup.controls[el] instanceof FormGroup) {
                        for (var el1 in this.myGroup.get(el)["controls"]) {
                            if (this.myGroup.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                if (invalidElements[0].className.includes('ng-select')) {
                    $(`[formcontrolname='${el}'] input`).focus();
                }
                else {
                    invalidElements[0].focus();
                }

            }



            console.log("invalid");
            return false;
        }


        let monitoraggio = JSON.parse(JSON.stringify(this.myGroup.value));
        monitoraggio.abilitato = (monitoraggio.abilitato=="1");   
        monitoraggio.statoAttuazione = {
            id:monitoraggio.statoAttuazione
            ,descrizione: monitoraggio.statoAttuazione?this.statiAttuazione.find(x=>x.id == monitoraggio.statoAttuazione).descrizione:null
        };
        let siti =     [...this.sitiReteNaturaSelezionati];
        monitoraggio.sitiReteNatura = siti.map(
            x=>{
                let k = {...x};
                    k['sitoReteNatura'] = {codice:x.codice,id:x.id,nome:x.nome}
                    k['id'] =null;
                    return k;
        });
        
        this.loading = true;
        
            this.interventoService.salvaMonitoraggio(this.interventoId
                ,monitoraggio                
            )
            .subscribe(
                (res) => {
                    this.toastr.success(
                        `Salvataggio avvenuto con successo`
                    );
                    this.loading = false;
                    this.showAbilitaMonitoraggioBtn = true;
                    this.setIsDirty(false);  
                    /*if(this.myGroup.controls.abilitato.value!="1"){
                        this.toastr.warning("Attenzione: Monitoraggio non abilitato",
                        null, { disableTimeOut: true });
                    }*/
                },
                (err) => {
                    this.toastr.error(
                        `Errore durante il salvataggio: non si dispone dei permessi per salvare la risorsa selezionata`
                    );
                    this.loading = false;
                }
            );
        

        return true;
    }
    ngOnDestroy(): void {
        

    }

    initializeFormGroup() {
        let frm = this.formBuilder.group({            
            codice: [null],
            descrizione: [null]
            , statoAttuazione: [null/*, [Validators.required]*/]
            , statoAttuazioneNote: [null]
            ,abilitato:[null]
        });
        frm.valueChanges.subscribe(val =>
            this.setIsDirty(frm.dirty)
        );
        return frm;
    }






    get f() {
        return this.myGroup.controls;
    }


    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();

    gotoBackBtnClick() {
        if (!this.isDirtyHelper.checkIsDirty()) return false;
        this.gotoBtnFunction.emit(true);
    }


    userIsManager() {
        return this.authService.isManager();
    }

    //motivazioni


    trimText(text, size) {
        if (!size) return text;
        if (text.length <= size) return text;
        return text.substring(0, size) + '...';
    }

    printValueAsDate(value) {
        if (!value || value == '') return '';
        let v = moment(value).toDate();
        return moment(v).format('DD/MM/YYYY');
    }

    printValueAsDecimal(value){
        if (value == undefined)return;
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }


    printValueAsCurrency(value) {
        if (value == undefined)return;
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }

    sitiReteNatura = [];
    sitiReteNaturaAll = [];
    getSitiReteNatura() {
        this.monitoraggioService.getAllSitiReteNatura().subscribe(
            result => this.sitiReteNaturaAll = result.map(x => { x.label = `${x.codice} - ${x.nome}`; return x; })
        );
    }

    distanze = [];
    getDistanze() {
        this.monitoraggioService.getAllDistanzeIntervento().subscribe(
            result => this.distanze = result
        );
    }

    getParametriTecniciPrevisti(){
        return this.printValueAsDecimal(this.currentProject['parametriTecnici']);
    }

    getParametriTecniciEffettivi(){
        return this.printValueAsDecimal(this.currentProject['parametriTecniciEffettivi']);
    }

    getUnitaDiMisura() {
        let tipologia = this.currentProject["tipo"];
        switch (tipologia) {
            case "road":
                return "km";

            case "rail":
                return "km";

            case "metro":
                return "km";

            case "bike":
                return "km";
                
            case "parkland":
                    return "ha";
            case "emobility":
                return "mc";

            case "skilift":
                return "m";

            case "points":
                return "mc";

            case "port":
                return "mc";

            case "metrostop":
                return "mc";

            case "station":
                return "mc";

            case "other_lines":
                return "km";

            case "other_points":
                return "mc";
            case "park":
                return "mc";
            case "nodes":
                    return "mc";
            default:
                return '';
        }

    }

    fasi = null;
    
    getFasiByInterventoId() {
        if (!this._interventoId)return;
        let id = this._interventoId;
        this.loadDataCounter++;
        this.interventoService.getFasi(id).subscribe(
            result => {
                result.forEach(
                    x => {
                        x['tipologia'] = `${x.tipologiaFase.fase} - ${x.tipologiaFase.titolo} - ${x.tipologiaFase.descrizione}`
                        x['soggettiResponsabiliDescrizione'] = x.soggettiResponsabili.map(
                            (x) => `${x.cognome} ${x.nome}`
                        ).join('|');
                    }
                );
                this.fasi = result;
                this.loadDataCounter--;
            }
        );
    }

    printSoggResp(fase) {
        return 'to implement';
    }

    statiAttuazione = [
        /*{ id: "intervento_sospeso_temporaneamente", nome: "Intervento sospeso temporaneamente" },
        { id: "intervento_sospeso_totalmente", nome: "Intervento sospeso totalmente" },
        { id: "intervento_in_corso", nome: "Intervento in corso" },
        { id: "intervento_realizzato", nome: "Intervento realizzato" }*/
    ];

    getStatiAttuazione(){
        this.statoAttuazioneService.getAll().subscribe(
            result=>{
                result.sort((a,b)=>a['descrizione']<b['descrizione']?-1:1);
                this.statiAttuazione = result;}
        );
    }

    finanziamenti = null;
    

    getFinanziamenti() {
        let that = this;
        if (!this._interventoId)return;
        this.loadDataCounter++;   

        this.interventoService.getFinanziamenti(this._interventoId).subscribe(
            result => {               
                that.finanziamenti = result;
                this.loadDataCounter--;   
            }
        );
    }

    sortImporti(arr) {
        return this.orderPipe.transform(arr, 'anno');
    }


    getDurataPrevista() {
        if (!this.fasi)return 0;        
            let fase = this.fasi.find(
                fase=>
                fase.tipologiaFase.monitoraggioIsDataPrevistaInizio
                );       
            let faseEnd  = this.fasi.find(
                fase=>
                fase.tipologiaFase.monitoraggioIsDataPrevistaFine
                );
        if (!fase || !faseEnd) return '0';
        
        let dataPrevistaFineStart = moment(fase.dataPrevistaFine).toDate();
        let dataPrevistaFineEnd = moment(faseEnd.dataPrevistaFine).toDate();
        return ((Math.abs(dataPrevistaFineEnd.getTime() - dataPrevistaFineStart.getTime())) / (24 * 3600 * 1000)).toFixed(0);

    }

    getDurataEffettiva() {
        if (!this.fasi)return 0;        
       
            let fase = this.fasi.find(
                fase=>
                fase.tipologiaFase.monitoraggioIsDataEffettivaInizio
                );
       
            let faseEnd  = this.fasi.find(
                fase=>
                fase.tipologiaFase.monitoraggioIsDataEffettivaFine
                );
        if (!fase || !faseEnd || !fase.dataFine || !faseEnd.dataFine) return '0';
        
        let dataPrevistaFineStart = moment(fase.dataFine).toDate();
        let dataPrevistaFineEnd = moment(faseEnd.dataFine).toDate();
        return ((Math.abs(dataPrevistaFineEnd.getTime() - dataPrevistaFineStart.getTime())) / (24 * 3600 * 1000)).toFixed(0);
    }

    getCostiEffettivi() {
        return this.printValueAsCurrency(this.currentProject["importoAggiudicato"]);
    }
    importi = null;

    getImporti() {
        let importi = this.currentProject["importi"].map(
            x => { x['annoTesto'] = x.anno + ''; return x; }
        );
        let somma = 0;
        importi.forEach(x => somma += x.importo);
        let k = { importo: somma, anno: 0, id: -1 };
        k['annoTesto'] = 'Totale';
        importi.push(k);
        this.importi = importi;
    }

    reloadAllData() {
        console.log('into reloadalldata monitoraggio');
        this.myGroup.reset();
        this.importi = null;
        this.finanziamenti = null;
        this.fasi = null;
        this.getInterventoData();

    }

    showAbilitaMonitoraggioBtn = true;
    getMonitoraggioData(){
        this.monitoraggioLabel = 'non attivo';
        if (!this._interventoId)return;
        this.loadDataCounter++;
        this.interventoService.getMonitoraggio(this._interventoId).subscribe(
            result=>{
                
                if (!result/* || !result.sitiReteNatura || result.sitiReteNatura.length<=0*/)this.showAbilitaMonitoraggioBtn = false;
                else this.showAbilitaMonitoraggioBtn = true;
                this.myGroup.controls.abilitato.setValue(result.abilitato);
                this.monitoraggioLabel = result.abilitato?'attivo':'non attivo';
                this.myGroup.controls.statoAttuazioneNote.setValue( result.statoAttuazioneNote);
                if(result.statoAttuazione)this.myGroup.controls.statoAttuazione.setValue( result.statoAttuazione.id);
                let temp = [];
                if(result && result.sitiReteNatura)result.sitiReteNatura.forEach(x=>{
                    let obj = x.sitoReteNatura;
                    obj.distanza = x.distanza;
                    temp.push(obj);
                });
                this.sitiReteNaturaSelezionati = temp;
                this.setIsDirty(false);                
                this.loadDataCounter--;
            }
        );
    }

    changeMonitoraggioStatus(){
        if (!this._interventoId || ! this.userIsManager())return;
        this.loading = true;
        
            this.interventoService.cambiaMonitoraggioAbilitatoFlag(this._interventoId                                
            )
            .subscribe(
                (res) => {
                    this.toastr.success(
                        `Salvataggio avvenuto con successo`
                    );
                    this.loading = false;
                    this.setIsDirty(false);   
                    this.monitoraggioLabel = this.monitoraggioLabel=='attivo'?'non attivo':'attivo';                 
                },
                (error) => {
                    this.toastr.error(
                        `Errore: ${error.error.message
                            ? error.error.message
                            : error.error.error_description
                                ? error.error.error_description
                                : error.error
                        }`
                    );
                    this.loading = false;
                }
            );
    }

    tipologiaChange($event){
        if (!$event){this.sitiReteNatura = [];return;}
        if ($event.key == 'ZPS/ZSC'){this.sitiReteNatura = [...this.sitiReteNaturaAll];return;}
        this.sitiReteNatura = this.sitiReteNaturaAll.filter(x=>x.tipologia == $event.key);

    }

    modalRef: BsModalRef;
    frmReteNatura :FormGroup;
    sitiReteNaturaSelezionati=[];
    sitiReteNaturaIsError:boolean = false;
    submittedFrmReteNatura= false;
    @ViewChild('modalAggiungiRete', { static: true })
    public modalAggiungiRete: TemplateRef<any>;

    createFormReteNatura(){
        this.frmReteNatura = this.formBuilder.group({            
            tipologia: [null, [Validators.required]]  ,
            sitoReteNatura: [null, [Validators.required]]            
            ,distanza: [null, [Validators.required]]            
        });
    }

    removeSitoReteNatura(item){
        this.sitiReteNaturaSelezionati = this.sitiReteNaturaSelezionati.filter(x=>x.id != item.id);
    }

    addSitoReteNatura(){
        this.submittedFrmReteNatura = true;
        var errors = false;

        

        for (let c in this.frmReteNatura.controls) {
            errors = errors || this.frmReteNatura.controls[c].errors !== null;
            /*console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );*/
        }

        console.log("errors:" + errors);
        if (
            this.frmReteNatura.invalid ||
            errors 
        ) {
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (var el in this.frmReteNatura.controls) {
                if (this.frmReteNatura.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.frmReteNatura.controls[el] instanceof FormGroup) {
                        for (var el1 in this.frmReteNatura.get(el)["controls"]) {
                            if (this.frmReteNatura.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                if (invalidElements[0].className.includes('ng-select')) {
                    $(`[formcontrolname='${el}'] input`).focus();
                }
                else {
                    invalidElements[0].focus();
                }

            }



            console.log("invalid");
            return false;
        }


        let sito = this.frmReteNatura.controls.sitoReteNatura.value;
        let distanza = this.frmReteNatura.controls.distanza.value;        
        let sitoObj = {...this.sitiReteNaturaAll.find(x=>x.id == sito)};
        sitoObj.distanza = {...this.distanze.find(x=>x.id == distanza)};
        if (!this.sitiReteNaturaSelezionati)this.sitiReteNaturaSelezionati = [];
        if(this.sitiReteNaturaSelezionati.find(x=>x.id == sitoObj.id)){
            this.toastr.error(
                `Sito natura già presente`
            );
            return;
        }
        this.sitiReteNaturaSelezionati.push(sitoObj);
        this.toastr.success(
            `Sito natura aggiunto con successo`
        );
        this.closeModalReteNatura();
    }

    openModaleSitoReteNatura(){
        let config = {
            animated: true,
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-lg"
        };
        this.modalRef = this.modalService.show(this.modalAggiungiRete, config);
        this.frmReteNatura.reset();
        this.submittedFrmReteNatura = false;
    }

    closeModalReteNatura(){
        this.modalRef.hide();
        this.frmReteNatura.reset();
        this.submittedFrmReteNatura = false;
    }

    changeTipologiaRete($event){
        if (!$event){this.sitiReteNatura = [];return;}
        if ($event.key == 'ZPS/ZSC'){this.sitiReteNatura = [...this.sitiReteNaturaAll];return;}
        this.sitiReteNatura = this.sitiReteNaturaAll.filter(x=>x.tipologia == $event.key);
    }

    canEdit() {
        return (this.enableSection(this.section, ['update'],false,true));
    }    


    /**
* Controlla se l'utente può accedere o meno alla sezione.
* @param section il nome della sezione
* @param operations le operazioni da poter effettuare sulla sezione
* @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
* @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
* @returns true se il controllo è andato a buon fine, false altrimenti
*/
    enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
        return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
    }

}
