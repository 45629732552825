import { TipologiaFase } from './tipologia-fase';
import { SoggettoResponsabile } from './soggetto-responsabile';

export class Fase{
    id:number;
    tipologiaFase:TipologiaFase;
    dataInizio:Date;
    dataFine:Date;
    dataPrevistaInizio:Date;
    dataPrevistaFine:Date;
    titolo:string;
    descrizione:string;

    soggettiResponsabili:SoggettoResponsabile[];
}