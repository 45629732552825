import { NgModule } from '@angular/core';
import {  CommonModule } from "@angular/common";
import{MegaMenuComponent} from './megamenu.component';



@NgModule({
    imports: [CommonModule],
    exports: [MegaMenuComponent],
    declarations: [MegaMenuComponent]
})
export class MegaMenuModule {}