import { TipologiaErogazione } from './tipologia-erogazione';
import { StatoAvanzamentoLavori } from './stato-avanzamento-lavori';

export class Erogazione{
    id:number;
    tipologia:TipologiaErogazione;
    importo:number;
    dataPrevista:Date;
    dataEffettiva:Date;
    interventoId:number;
    statoAvanzamentoLavori:StatoAvanzamentoLavori[];
}