<div class="container-xl mb-5">
  <div class="position-sticky sticky-top pt-4 pb-4 elemento-aggiorna shadow-custom test "
  [ngClass]="{'sticky-top-custom-modal': isInModal,'sticky-top-custom':!isInModal  }">
    <div class=" row  justify-content-center ">
      <div class=" text-center test2"
        [ngClass]="{ ' col-lg-4 col-md-6': !(gotoBtnOptions && gotoBtnOptions.showIcon) , 'col-lg-2' : gotoBtnOptions && gotoBtnOptions.showIcon}">
        <button (click)="gotoBackBtnClick()" *ngIf="gotoBtnOptions && gotoBtnOptions.show" [disabled]="loading"
          class="btn btn-secondary btn-block font-weight-bold"
          type="button">{{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}&nbsp;<i class="fas fa-times"
            *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i></button>
      </div>
    </div>
  </div>

  <div class="row justify-content-md-center">
    <div class="col-lg-12" *ngIf="!cup">
       <div class=" alert alert-warning mt-4" role="alert" >
         Inserire il <strong>cup</strong> per poter consultare i dati!
       </div>
    </div>
    <div class="col-lg-12 mt-4" [hidden]="!cup">
      <accordion [isAnimated]="true" style="width:100%">
        <accordion-group [isOpen]="true" heading="Procedura di attivazione">
          <accordion [isAnimated]="true" style="width:100%">
            <tabset>
              <tab heading="Fondo" class="mt-4 ">
                <ngx-datatable-wrapper #ngxDtWrpFondo [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsFondo" [ngStyle]="{ 'visibility': dtDataSize.get('fondo') ? '' : 'hidden' }">
                  <ng-template #rigthAlignDataTemplate let-row="row" let-value="value">
                    <div class="text-right text-nowrap">
                      {{ value }} €
                    </div>
                  </ng-template>
                </ngx-datatable-wrapper>

                <span style="color: red" [hidden]="dtDataSize.get('fondo')">{{ noAccessDataLabel }}</span>
              </tab>
              <tab heading="Quadro Economico" class="mt-4">
                <ngx-datatable-wrapper #ngxDtWrpQuadro [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsQuadro" [ngStyle]="{ 'visibility': dtDataSize.get('quadro') ? '' : 'hidden' }">
                  <ng-template #rigthAlignDataTemplate let-row="row" let-value="value">
                    <div style="text-align: right; margin-right: 10px;" >
                      {{ value }}
                    </div>
                  </ng-template>
                </ngx-datatable-wrapper>

                <span style="color: red" [hidden]="dtDataSize.get('quadro')">{{ noAccessDataLabel }}</span>
              </tab>
              <tab heading="Procedure di aggiudicazione" class="mt-4">
                <ngx-datatable-wrapper #ngxDtWrpAggiudicazione [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsAggiudicazione" [ngStyle]="{ 'visibility': dtDataSize.get('aggiudicazione') ? '' : 'hidden' }">
                  <ng-template #rigthAlignDataTemplate let-row="row" let-value="value">
                    <div style="text-align: right; margin-right: 10px;">
                      {{ value }}
                    </div>
                  </ng-template>
                </ngx-datatable-wrapper>

                <span style="color: red" [hidden]="dtDataSize.get('aggiudicazione')">{{ noAccessDataLabel }}</span>
              </tab>
              <tab heading="Soggetti Correlati" class="mt-4">
                <ngx-datatable-wrapper #ngxDtWrpSoggetti [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsSoggetti" [ngStyle]="{ 'visibility': dtDataSize.get('soggetti') ? '' : 'hidden' }">
                </ngx-datatable-wrapper>

                <span style="color: red" [hidden]="dtDataSize.get('soggetti')">{{ noAccessDataLabel }}</span>
              </tab>
              <tab heading="Indicatori" class="mt-4">
                <ngx-datatable-wrapper #ngxDtWrpIndicatori [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsIndicatori" [ngStyle]="{ 'visibility': dtDataSize.get('indicatori') ? '' : 'hidden' }">
                </ngx-datatable-wrapper>

                <span style="color: red" [hidden]="dtDataSize.get('indicatori')">{{ noAccessDataLabel }}</span>
              </tab>
            </tabset>
          </accordion>
        </accordion-group>

        <accordion-group [isOpen]="true" heading="Gestione finanziaria">
          <accordion [isAnimated]="true" style="width:100%">
            <tabset>
              <tab heading="Percettori" class="mt-4">
                <ngx-datatable-wrapper #ngxDtWrpPercettori [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsPercettori" [ngStyle]="{ 'visibility': dtDataSize.get('percettori') ? '' : 'hidden' }">
                  <ng-template #rigthAlignDataTemplate let-row="row" let-value="value">
                    <div style="text-align: right;">
                      {{ value }}
                    </div>
                  </ng-template>
                </ngx-datatable-wrapper>

                <span style="color: red" [hidden]="dtDataSize.get('percettori')">{{ noAccessDataLabel }}</span>
              </tab>
              <tab heading="Giustificativo" class="mt-4">
                <ngx-datatable-wrapper #ngxDtWrpGiustificativo [templates]="templateList" style="width: 100%; height: auto; " [dtOptions]="dtOptionsGiustificativo" [ngStyle]="{ 'visibility': dtDataSize.get('giustificativo') ? '' : 'hidden' }">
                  <ng-template #rigthAlignDataTemplate let-row="row" let-value="value">
                    <div style="text-align: right;" class="text-nowrap">
                     {{ value }}
                    </div>
                  </ng-template>
                </ngx-datatable-wrapper>

                <span style="color: red" [hidden]="dtDataSize.get('giustificativo')">{{ noAccessDataLabel }}</span>
              </tab>
              <tab heading="Pagamenti BDAP" class="mt-4 ">
                <ngx-datatable-wrapper #ngxDtWrpPagamentiBDAP [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsPagamentiBDAP" [ngStyle]="{ 'visibility': dtDataSize.get('pagamentiBDAP') ? '' : 'hidden' }">
                  <ng-template #rigthAlignDataTemplate let-row="row" let-value="value">
                    <div style="text-align: right;">
                      {{ value }}
                    </div>
                  </ng-template>
                </ngx-datatable-wrapper>

                <span style="color: red" [hidden]="dtDataSize.get('pagamentiBDAP')">{{ noAccessDataLabel }}</span>
              </tab>
              <tab heading="Pagamenti SIOPE+" class="mt-4">
                <ngx-datatable-wrapper #ngxDtWrpPagamentiSIOPE [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsPagamentiSIOPE" [ngStyle]="{ 'visibility': dtDataSize.get('pagamentiSIOPE') ? '' : 'hidden' }">
                  <ng-template #rigthAlignDataTemplate let-row="row" let-value="value">
                    <div style="text-align: right;">
                      {{ value }}
                    </div>
                  </ng-template>
                </ngx-datatable-wrapper>

                <span style="color: red" [hidden]="dtDataSize.get('pagamentiSIOPE')">{{ noAccessDataLabel }}</span>
              </tab>
              <tab heading="Pagamenti Fattura" class="mt-4 ">
                <ngx-datatable-wrapper #ngxDtWrpPagamentiFattura [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsPagamentiFattura" [ngStyle]="{ 'visibility': dtDataSize.get('pagamentiFattura') ? '' : 'hidden' }">
                  <ng-template #rigthAlignDataTemplate let-row="row" let-value="value">
                    <div style="text-align: right; margin-right: 10px;" >
                      {{ value }}
                    </div>
                  </ng-template>
                </ngx-datatable-wrapper>

                <span style="color: red" [hidden]="dtDataSize.get('pagamentiFattura')">{{ noAccessDataLabel }}</span>
              </tab>
              <tab heading="Piano dei Costi" class="mt-4">
                <ngx-datatable-wrapper #ngxDtWrpCosti [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsCosti" [ngStyle]="{ 'visibility': dtDataSize.get('costi') ? '' : 'hidden' }">
                  <ng-template #rigthAlignDataTemplate let-row="row" let-value="value">
                    <div style="text-align: right; margin-right: 10px;" >
                      {{ value }}
                    </div>
                  </ng-template>
                </ngx-datatable-wrapper>

                <span style="color: red" [hidden]="dtDataSize.get('costi')">{{ noAccessDataLabel }}</span>
              </tab>
            </tabset>
          </accordion>
        </accordion-group>
      </accordion>
    </div>
  </div>
</div>
