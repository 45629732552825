import { NgModule } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";
import {  CommonModule } from "@angular/common";
import{LoadingComponent } from './loading.component';



@NgModule({
  declarations: [
    LoadingComponent
],
  exports: [
    LoadingComponent
]

  ,imports:[
      CommonModule,
      HttpClientModule      
    
  ],
  providers: [    
  ],
  entryComponents: [    
  ]
})
export class AppLoadingModule {}