<ng-container *ngIf="fastEditObject">
    <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
</ng-container>
<div class="container sfondo_bianco" [hidden]="fastEditObject">
    <hr />
    <accordion [isAnimated]="true">
        <accordion-group heading="Inserisci Nuovo Capitolo di bilancio">
            <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
        </accordion-group>
    </accordion>
    <div class="mt-5">
        <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptions">
            <ng-template #btnEditTemplate let-row="row" let-value="value">
                <a [ngClass]="{
        'disabled': loading
        }" class="  border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top" title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
            </ng-template>
            <ng-template #btnDeleteTemplate let-row="row" let-value="value">
                <a [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
            </ng-template>
            <ng-template #booleanTemplate let-row="row" let-value="value">
                {{(value?"Si":"No")}}
            </ng-template>
            <ng-template #dateTemplate let-row="row" let-value="value">
                {{printValueAsDate(value)}}
            </ng-template>
        </ngx-datatable-wrapper>
    </div>

    <ng-template #templateModal>
        <div class="modal-header  text-center">
            <h4 class="modal-title pull-left">Modifica capitolo di bilancio</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">

            <ng-container *ngTemplateOutlet="formTemplate"></ng-container>

        </div>
    </ng-template>
    <!--<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table-bordered row-border hover small"
    style="width:100%"></table>-->
</div>


<ng-template #formTemplate>
    <form [formGroup]="addSchoolYearForm" (ngSubmit)="onSubmit()">
        <div class="col-lg-12 mt-3">
            <input type="hidden" formControlName="id" [formGroup]="addSchoolYearForm" />

            <div class="row">

                <div class="form-group col-lg-8">

                    <div class="">
                        <label for="codice">
            <h5 class="mandatory">Codice:</h5>
          </label>
                        <input [formGroup]="addSchoolYearForm" formControlName="codice" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci il codice" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submitted && f.codice.errors
                      }" />
                        <div *ngIf="submitted && f.codice.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.codice.errors.required">
                                Campo obbligatorio
                            </div>
                        </div>
                    </div>

                </div>                

                <div class="form-group col-lg-4">

                    <div class="">
                        <label for="tipologia">
            <h5 class="mandatory">Tipologia:</h5>
          </label>
                        <div class="inputbg-frame inputbg-frame-mm">
                            <div id="div-sex" style="display: inline-block" [ngClass]="{ 'is-invalid': submitted && f.isEntrata.errors }">
                                <label id="label-gender-m" class="radio-inline">
                <input [formGroup]="addSchoolYearForm" type="radio" ng-control="options" formControlName="isEntrata"
                  [ngClass]="{ 'is-invalid': submitted && addSchoolYearForm.controls.isEntrata.errors }" value="1" /><span>&emsp;Entrata&emsp;</span>
              </label>

                                <label id="label-gender-f" class="radio-inline">
                <input [formGroup]="addSchoolYearForm" type="radio" ng-control="options" formControlName="isEntrata"
                  [ngClass]="{ 'is-invalid': submitted && addSchoolYearForm.controls.isEntrata.errors }" value="0" /><span>&emsp;Uscita&emsp;</span>
              </label>
                            </div>
                            <div *ngIf="submitted && addSchoolYearForm.controls.isEntrata.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="addSchoolYearForm.controls.isEntrata.errors.required">Specificare se è entrata</div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div class="form-group row">

                <div class="col-sm-12">
                    <label for="descrizione">
            <h5 class="mandatory">Descrizione:</h5>
          </label>
                    <input [formGroup]="addSchoolYearForm" formControlName="descrizione" type="text" value="" class="form-control" placeholder="Inserisci la descrizione" [ngClass]="{
                    'form-control': true,
                    'is-invalid': submitted && f.descrizione.errors
                    }" />
                    <div *ngIf="submitted && f.descrizione.errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="f.descrizione.errors.required">
                            Campo obbligatorio
                        </div>
                    </div>
                </div>

            </div>



        </div>

        <div class="col-lg-12 mt-5">
            <hr>
        </div>

        <div class="col-lg-12 mt-4">
            <div class="row justify-content-between">

                <div class="col-lg-6 col-sm-6 col-6 mt-3">
                    <h5 for="" class="">Annualità</h5>
                </div>
                <div class="col-lg-1 col-sm-2 col-3 text-center mt-3">
                    <a href="javascript:;;" class="bottone-inserisci" (click)="openModalAnnualita();"><i class="fas fa-plus"></i></a>
                </div>
            </div>
        </div>

        <div class="col-lg-12 mt-3 table-responsive-lg">
            <table class="table table-hover table-colore">
                <thead class="">
                    <th>anno</th>
                    <th>stanziamento<br />competenza</th>
                    <th>stanziamento<br />cassa</th>
                    <th>variazione<br />competenza</th>
                    <th>variazione<br />cassa</th>
                    <th>impegnato</th>
                    <th class="casella-icona-tx">modifica</th>
                    <th class="casella-icona-tx">elimina</th>
                </thead>
                <tbody>
                    <ng-container *ngIf="annualita && annualita.length && annualita.length>0">
                        <tr *ngFor="let item of annualita">
                            <td>{{item.anno}}</td>
                            <td>
                                <span *ngIf="item.stanziamentoCompetenza">{{item.stanziamentoCompetenza| italianCurrencyPipe  }} €</span>
                            </td>
                            <td><span *ngIf="item.stanziamentoCassa">{{item.stanziamentoCassa| italianCurrencyPipe  }} €</span></td>
                            <td>
                                <span *ngIf="item.variazioniCompetenza">{{item.variazioniCompetenza| italianCurrencyPipe  }} €</span>
                            </td>
                            <td><span *ngIf="item.variazioniCassa">{{item.variazioniCassa| italianCurrencyPipe  }} €</span></td>
                            <td><span *ngIf="item.impegnato">{{item.impegnato| italianCurrencyPipe  }} €</span></td>
                            <td>
                                <a (click)="editAnnualita(item)" href="javascript:;;" data-toggle="tooltip" data-placement="top" title="Modifica"><i class="far fa-edit casella-icona-tx"></i> </a>
                            </td>
                            <td>
                                <a (click)="removeAnnualita(item)" href="javascript:;;" data-toggle="tooltip" data-placement="top" title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i> </a>
                            </td>
                        </tr>
                    </ng-container>
                    <tr *ngIf="!(annualita &&  annualita.length && annualita.length>0)">
                        <td colspan="8">Nessuna annualità selezionata</td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="submitted && annualitaIsError" class="invalidFieldMessage">
                <div *ngIf="annualitaIsError" class="invalid-feedback" style="display: block;">
                    Selezionare almeno una annualità
                </div>
            </div>
        </div>

        <div class="col-lg-12 mb-3">
            <button [disabled]="loading" class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
      {{buttonLabel}}
      <img *ngIf="loading" class="pl-3 float-right"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
    </button>
        </div>
    </form>
</ng-template>
<!-- modale Inserisci nuova titolo-->
<ng-template #templateAnnualita>
    <div class="modal-header  text-center">
        <h4 class="modal-title pull-left">{{templateAnnualitaTitle}}</h4>

        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefAnn.hide()">
      <span aria-hidden="true">&times;</span>
    </button>


    </div>


    <!-- modale Inserisci nuova annualità body-->
    <div class="modal-body">
        <div class="col-lg-12">
            <form [formGroup]="annualitaForm" (ngSubmit)="onSubmitAnnualita()">
                <input type="hidden" [formGroup]="annualitaForm" formControlName="id" />
                <div class="form-group row">
                    <div class="col-lg-6 mb-3 mb-lg-0">
                        <h5> <label for="anno" class="mandatory">Anno :</label></h5>
                        <input [formGroup]="annualitaForm" formControlName="anno" type="text" [imask]="maskYear" maxlength="4" minlength="4" [unmask]="true" [ngClass]="{
              'form-control': true,
              'is-invalid': submittedAnnualita && annualitaForm.controls.anno.errors
            }" placeholder="Inserisci l'anno" />
                        <div *ngIf="submittedAnnualita && annualitaForm.controls.anno.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="annualitaForm.controls.anno.errors.required">
                                Campo obbligatorio
                            </div>
                            <div *ngIf="annualitaForm.controls.anno.errors.minlength">
                                Anno deve essere di 4 cifre
                            </div>
                            <div *ngIf="annualitaForm.controls.anno.errors.range">
                                Anno deve essere maggiore o uguale a {{minYear}}
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-6 ">
                        <h5><label for="impegnato" class="">Impegnato:</label></h5>
                        <input [formGroup]="annualitaForm" formControlName="impegnato" type="text"  [imask]="mask" [unmask]="true"  class="form-control" placeholder="Inserisci il valore dell'impegnato" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submittedAnnualita && annualitaForm.controls.impegnato.errors
                      }" />
                        <div *ngIf="submittedAnnualita && annualitaForm.controls.impegnato.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="annualitaForm.controls.impegnato.errors.required">
                                Campo obbligatorio
                            </div>
                        </div>
                    </div>

                </div>

                <div class="form-group row">
                    <div class="col-lg-6 mb-3 mb-lg-0">
                        <h5><label for="stanziamentoCompetenza" class="mandatory">Stanziamento competenza:</label></h5>
                        <input [formGroup]="annualitaForm" formControlName="stanziamentoCompetenza" type="text" [imask]="mask " [unmask]="true" class="form-control" placeholder="Inserisci lo stanziamento competenza" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submittedAnnualita && annualitaForm.controls.stanziamentoCompetenza.errors
                      }" />
                        <div *ngIf="submittedAnnualita && annualitaForm.controls.stanziamentoCompetenza.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="annualitaForm.controls.stanziamentoCompetenza.errors.required">
                                Campo obbligatorio
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 ">
                        <h5><label for="stanziamentoCassa" class="">Stanziamento cassa:</label></h5>
                        <input [formGroup]="annualitaForm" formControlName="stanziamentoCassa" type="text" [imask]="mask" [unmask]="true"  class="form-control" placeholder="Inserisci lo stanziamento cassa" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submittedAnnualita && annualitaForm.controls.stanziamentoCassa.errors
                      }" />
                        <div *ngIf="submittedAnnualita && annualitaForm.controls.stanziamentoCassa.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="annualitaForm.controls.stanziamentoCassa.errors.required">
                                Campo obbligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6 mb-3 mb-lg-0">
                        <h5><label for="variazioniCompetenza" class="">Variazioni competenza:</label></h5>
                        <input [formGroup]="annualitaForm" formControlName="variazioniCompetenza" type="text" [imask]="mask" [unmask]="true"  class="form-control" placeholder="Inserisci variazioni competenza" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submittedAnnualita && annualitaForm.controls.variazioniCompetenza.errors
                      }" />
                        <div *ngIf="submittedAnnualita && annualitaForm.controls.variazioniCompetenza.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="annualitaForm.controls.variazioniCompetenza.errors.required">
                                Campo obbligatorio
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <h5><label for="variazioniCassa" class="">Variazioni cassa:</label></h5>
                        <input [formGroup]="annualitaForm" formControlName="variazioniCassa" type="text" [imask]="mask" [unmask]="true"  class="form-control" placeholder="Inserisci variazioni cassa" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submittedAnnualita && annualitaForm.controls.variazioniCassa.errors
                      }" />
                        <div *ngIf="submittedAnnualita && annualitaForm.controls.variazioniCassa.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="annualitaForm.controls.variazioniCassa.errors.required">
                                Campo obbligatorio
                            </div>
                        </div>
                    </div>
                </div>
                

                <button [disabled]="loading" class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
        {{buttonLabelAnnualita}}
        <img *ngIf="loading" class="pl-3 float-right"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      </button>




            </form>
        </div>
    </div>
</ng-template>