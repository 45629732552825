export * from './soggetto-ruolo.service';
export * from './aggregato.service';
export * from './sub-interventi.service';
export * from './aree-tematiche.service';
export * from './tipo-classificazione.service';
export * from './classificazione.service';
export * from './stato-finanziamento.service';
export * from './soggetto-giuridico.service';
export * from './tipi-finanziamento.service';
export * from './tipo-provvedimento.service';
export * from './progetto.service';
export * from './tema-prioritario.service';
export * from './tipologia-fase.service';
export * from './stato-avanzamento-progetto.service';
export * from './scopo-provvedimento.service';
export * from './capitolo-bilancio.service';
export * from './provvedimento.service';
export * from './fase.service';
export * from './tipologia-soggetto-responsabile.service';
export * from './soggetto-responsabile.service';
export * from './tipo-strumento-attuativo.service';
export * from './finanziamento.service';
export * from './intervento.service';
export * from './tipologia-erogazione.service';
export * from './categoria-sal.service';
export * from './macro-categoria-sal.service';
export * from './documentazione.service';
export * from './dashboard-service';
export * from './storico.service';
export * from './protocollo.service';
export * from './validazione.service';
export * from './motivazioni-service';
export * from './motivazione-cancellazione.service';
export * from './monitoraggio.service';
export * from './stato-attuazione.service';
export * from './pnrr.service';
export * from './sicer.service';
export * from './sicer-bilancio.service';
export * from './storage.service';
export * from './banca-dati-esterna.service';