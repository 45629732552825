<div class="row text-white justify-content-center align-items-center" style="background-color: #002742" >
  <div class="text-white col-xl-4 col-lg-5 col-md-7 col-sm-12  text-center">
    <div class="d-inline-block mr-4">
    <img
      class="logo-app"
      src="{{ente.logo}}"
      alt="logo {{ente.nome}}"
    /> {{ente.nome}}
  </div>
    <img
      class="logo-app ml-4"
      src="{{logo}}"
      alt="logo {{title}}"
    />

  </div>
  <div class="text-center col-xl-4 col-lg-5 col-md-5 col-sm-12 ">
    <button
      type="button"
      class="btn btn-primary btn-sm my-1"
      routerLink=""
      title="Torna alla Home Page"
    >
      Torna alla Home Page
    </button>
  </div>
</div>
