<div class="container-fluid px-0 bg-celeste " [formGroup]="myForm">
  <div
    class="text-center p-5 position-absolute top-50 start-50 translate-middle bg-white border border-dark rounded shadow"
    style="z-index: 999999999" [hidden]="!loading">
    <app-loading message="Caricamento in corso..."></app-loading>
  </div>


    <!-- menu -->
    <app-header-dashboard></app-header-dashboard>
    <div class="row pb-3 pt-1 bg-0083e0" style="z-index: 13000">


      <div class="col-xl-4 col-lg-6 col-md-6 pt-1">
        <label for="importoFinanziamento">
          <h6 class="mb-0 text-white bold">
            Progettualità/Fabbisogno - Interventi - Finanziamento
            <small><span class="font-small">(min 3 caratteri)</span></small>
          </h6>
        </label>
        <app-completer [cpOptions]="completerParams" (onSelectEmitter)="setInterventionCompleter($event)"
          (keyup)="onChangeInterventionCompleter($event)">
        </app-completer>
      </div>

      <div class="col-xl-3 col-lg-5 col-md-5 pt-1">
        <label for="direction">
          <h6 class="mb-0 text-white bold">
            Aree gestionali direzione e/o esterne
          </h6>
        </label>
        <ng-select [items]="areeTematicheCombo" bindValue="id" bindLabel="descrizione" formControlName="direction"
          placeholder="Seleziona area gestionale direzione e/o esterna " (change)="setAreaTematica($event)">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
          </ng-template>
        </ng-select>
      </div>

      <div class="col-xl-2 col-lg-4 col-md-4 pt-1">
        <label for="importoFinanziamento ">
          <h6 class="mb-0 text-white bold">Stato Avanzamento Fasi</h6>
        </label>
        <ng-select [items]="avanzamentoCombo" bindValue="k" bindLabel="v" formControlName="statoFasi"
          placeholder="Seleziona stato di avanzamento fasi " (change)="setStatoFasi($event)">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.v }}">{{ item.v }}</div>
          </ng-template>
        </ng-select>
      </div>

      <!-- <div class="col-xl-3 col-lg-3 col-md-3 pt-1">
                    <label for="importoFinanziamento">
        <h6 class="mb-0 text-white bold">Tipologia Fase</h6>
      </label>
                    <ng-select formControlName="tipologiaResponsabile" [items]="tipologieFasiCombo" bindValue="k" bindLabel="v" placeholder="Seleziona tipologia fase" (change)="setTipologiaFase($event)" [multiple]="true" [selectableGroup]="true" [selectableGroupAsModel]="false"
                        groupBy="fase">
                        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item.fase | uppercase}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> <span title="{{ item.v }}">&nbsp;{{ item.v }}</span>
                        </ng-template>



                    </ng-select>
                </div> -->



      <!-- <div class="col-xl-3 col-lg-3 col-md-6 pt-1">
                    <label for="importoFinanziamento">
        <h6 class="mb-0 text-white bold">Tipologia Soggetti Responsabili</h6>
      </label>
                    <ng-select formControlName="tipologiaResponsabile" [items]="tipologieResponsabiliCombo" bindValue="id" bindLabel="descrizione" placeholder="Seleziona la tipologia di responsabile" (change)="setTipologiaResponsabile($event)">
                        <ng-template ng-option-tmp let-item="item">
                            <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
                        </ng-template>
                    </ng-select>
                </div> -->

      <!-- <div class="col-xl-2 col-lg-3 col-md-6 pt-1">
                    <label for="importoFinanziamento">
        <h6 class="mb-0 text-white bold">
          Soggetto Responsabile <small><span class="font-small">(min 3 caratteri)</span></small>
        </h6>
      </label>
                    <app-completer [cpOptions]="completerParamsSoggetti" (onSelectEmitter)="setSoggettiCompleter($event)" (keyup)="onChangeSoggettiCompleter($event)">
                    </app-completer>
                </div> -->

      <!-- <div class="col-xl-2 col-lg-3 col-md-5 pt-1">
                    <label for="importoFinanziamento">
        <h6 class="mb-0 text-white bold">Tipologia Classificazioni</h6>
      </label>
                    <ng-select [items]="classificazioniCombo" bindValue="id" bindLabel="tipo" formControlName="classification" placeholder="Seleziona tipologia classificazioni" (change)="setClassificazione($event)">
                        <ng-template ng-option-tmp let-item="item">
                            <div title="{{ item.tipo }}">{{ item.tipo }}</div>
                        </ng-template>
                    </ng-select>
                </div> -->

      <!-- <div class="col-xl-2 col-lg-2 col-md-4 pt-1">
                    <label for="importoFinanziamento">
      <h6 class="mb-0 text-white bold">Flusso Di Cassa</h6>
    </label>
                    <ng-select [items]="flussoDiCassaItems" bindValue="k" bindLabel="v" placeholder="Seleziona" (change)="setFlussoDiCassa($event)">
                        <ng-template ng-option-tmp let-item="item">
                            <div title="{{ item.v }}">{{ item.v }}</div>
                        </ng-template>
                    </ng-select>
                </div> -->

      <div class="col-xl-1 col-lg-1 col-md-3 col-sm-12 pt-1">
        <label>
          <h6 class="mb-0 text-white bold"></h6>
        </label>
        <div>
          <button [disabled]="loadingExportExcel" type="button" class="mt-1 btn btn-info btn-block"
            (click)="esportaExcel()">
            Esporta
            <img *ngIf="loadingExportExcel" class="pl-3 float-right"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!showPage">
      <ng-container *ngIf="!noresult">
        <div [hidden]="loading" class="jumbotron container my-5 border shadow-sm my-3 rounded bg-white">
          <div class="container text-center">
            <h4 class="bold">Dashboard Gantt Interventi</h4>
            <p class="h5"><small>Selezionare almeno un filtro per attivare la visualizzazione dei grafici Gantt.</small>
            </p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="noresult">
        <div [hidden]="loading" class="jumbotron container my-5 border shadow-sm my-3 rounded bg-white">
          <div class="container text-center">
            <p class="h5"><small>La ricerca non ha prodotto risultati</small></p>
          </div>
        </div>
      </ng-container>

    </ng-container>
    <ng-container *ngIf="showPage">
      <div id="fin_gantt" *ngIf="filteredElements.finanziamenti.length > 0">
        <app-chart-gantt-interventi
          [dataChart]="{item: filteredElements.finanziamenti, dataType: 'Finanziamenti', index: null, color: 'green'} "></app-chart-gantt-interventi>
      </div>

      <div id="proj_gantt" *ngIf="filteredElements.progetti && filteredElements.progetti.length > 0">
        <hr>
        <ng-container *ngFor="let proj of filteredElements.progetti; let i = index">
          <app-chart-gantt-interventi
            [dataChart]="{item: proj, dataType: 'Progetti', index: i, color:'cyan'}"></app-chart-gantt-interventi>
          <hr>
        </ng-container>
      </div>
    </ng-container>

</div>

<!--inizio modale esportazione-->
<ng-template #modalExportData>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">Opzioni esportazione</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalEsportazione()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" [formGroup]="exportForm">
    <!--<div class="custom-control custom-switch">
                <input [formGroup]="exportForm" formControlName="tipologieRaggruppate" type="checkbox" class="custom-control-input" id="tipologieRaggruppate">
                <label class="custom-control-label" for="tipologieRaggruppate">Tipologie raggruppate</label>
            </div>-->

    <div class="mt-2">
      <label for="raggruppamentoMesi">Raggruppamento mesi</label>
      <ng-select [items]="[{v:'Mensile'},{v:'Trimestrale'},{v:'Semestrale'},{v:'Annuale'}]" bindValue="v" bindLabel="v"
        placeholder="Seleziona" formControlName="raggruppamentoMesi" [formGroup]="exportForm"></ng-select>
    </div>

    <button [disabled]="loading || !exportForm.controls.intervallo" class="btn btn-lg btn-primary btn-block"
      type="button" style="margin:15px 0" (click)="doEsportaExcel()">
      Esporta
    </button>
  </div>
</ng-template>
<!--fine modale esportazione-->
