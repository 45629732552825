import { ServiceType } from "src/app/modules/inframob/models/service-type";
import { CurrencyPipe } from "@angular/common";
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import { TabsetComponent } from "ngx-bootstrap/tabs";
import { DatiGeneraliComponent } from "../datiGenerali/dati-generali.component";
import { ActivatedRoute } from "@angular/router";
import { Progetto } from "../../models";
import { FasiComponent } from "../fasi/fasi.component";
import { InterventiComponent } from "../interventi/interventi.component";
import {  ProgettoService } from "../../services";
import { ProgettoDivDettaglioService } from "../progettoDivDettaglio/progetto-div-dettaglio.service";
import { InterventoErogazioniComponent } from "../interventoErogazioni/intervento-erogazioni.component";
import { InterventoSalComponent } from "../interventoSal/intervento-sal.component";
import { AssociaProvvedimentiComponent } from "../associaProvvedimenti/associa-provvedimenti.component";
import { AssociaAnnualitaComponent } from "../associaAnnualita/associa-annualita.component";
import { InterventoFinanziamentoComponent } from "../interventoFinanziamento/intervento-finanziamento.component";
import { DocumentazioneComponent } from "../documentazione/documentazione.component";
import { RiepilogoProgettoComponent } from "../riepilogoProgetto/riepilogo-progetto.component";
import { RiepilogoInterventoComponent } from "../riepilogoIntervento/riepilogo-intervento.component";
import { StoricoInterventoComponent } from "../storicoIntervento/storico-intervento.component";
import { IsDirtyHelper } from "../../../../helpers/is-dirty.helper";
import { MonitoraggioComponent } from "../monitoraggio/monitoraggio.component";
import { AuthService } from "src/app/services";
import { DnshComponent } from "../dnsh/dnsh.component";
import { AssociaProvvedimentiDocumentiComponent } from "../associa-provvedimenti-documenti/associa-provvedimenti-documenti.component";
import { BdapComponent } from "../bdap/bdap.component";
import { SicerBilancioComponent } from "../sicerBilancio/sicer-bilancio.component";
import { InterventiSismaComponent } from "../interventi-sisma/interventi-sisma.component";
import { SubInterventiComponent } from "../sub-interventi/sub-interventi.component";

@Component({
  selector: "progetto-component",
  templateUrl: "./progetto.component.html",
  styleUrls: ["./progetto.component.css"],
})
export class ProgettoComponent implements OnInit, OnDestroy, AfterViewInit {
  public mask: any = {
    mask: "KK €",
    lazy: false, // make placeholder always visible

    blocks: {
      KK: {
        mask: Number,
        thousandsSeparator: ".",
        scale: 2,
        padFractionalZeros: true,
        radix: ",",
      },
    },
  };

  @ViewChild("tabs") tabs: TabsetComponent;

  @ViewChild("tabDatiGeneraliProgettoContent", { static: true })
  tabDatiGeneraliProgettoContent: DatiGeneraliComponent;

  @ViewChild("tabFasiContent", { static: true })
  tabFasiContent: FasiComponent;

  @ViewChild("tabInterventiContent", { static: true })
  tabInterventiContent: InterventiComponent;

  @ViewChild("tabFinanziamentiContent", { static: true })
  tabFinanziamentiContent: InterventoFinanziamentoComponent;
  
  @ViewChild("tabErogazioniContent", { static: true })
  tabErogazioniContent: InterventoErogazioniComponent;
  @ViewChild("tabSalContent", { static: true })
  tabSalContent: InterventoSalComponent;
  @ViewChild("tabProvvedimentiContent", { static: true })
  tabProvvedimentiContent: AssociaProvvedimentiComponent;
  @ViewChild("tabAnnualitaContent", { static: true })
  tabAnnualitaContent: AssociaAnnualitaComponent;
  @ViewChild("tabDocumentazioneContent", { static: true })
  tabDocumentazioneContent: DocumentazioneComponent;

  @ViewChild("tabRiepilogoContent", { static: true })
  tabRiepilogoContent: RiepilogoProgettoComponent;

  @ViewChild("tabRiepilogoInterventoContent", { static: true })
  tabRiepilogoInterventoContent: RiepilogoInterventoComponent;

  @ViewChild("divTemplateStorico", { static: true })
  divTemplateStorico: StoricoInterventoComponent;

  @ViewChild("divTemplateMonitoraggio", { static: true })
  divTemplateMonitoraggio: MonitoraggioComponent;

  @ViewChild("divTemplateDnsh", { static: true })
  divTemplateDnsh: DnshComponent;

  @ViewChild("divTemplateBdap", { static: true })
  divTemplateBdap: BdapComponent;

  @ViewChild("divSicerBilancioContent", { static: true })
  divSicerBilancioContent: SicerBilancioComponent;

  @ViewChild("divProvvedimentiDocumentiContent", { static: true })
  divProvvedimentiDocumentiContent: AssociaProvvedimentiDocumentiComponent;

  @ViewChild("divSismaContent", { static: true })
  divSismaContent: InterventiSismaComponent;

  @ViewChild("divSubInterventi", { static: true })
  divSubInterventi: SubInterventiComponent;

  type: ServiceType = ServiceType.PROGETTO;

  codiceIntervento:any;
  haFinanziamenti: boolean=false;
  haSubInterventi = false;

  openAsNewProject() {
    this.projectId = null;
  }

  datiProgetto: any;
  @Input()
  set projectId(value: number) {
    this.showPage = false;
    this.activeTab = "tabDatiGenerali";
    this._projectId = value && value >= 0 ? value : null;
    if (this.progettoDivDettaglioService)
      window.setTimeout(
        () => this.progettoDivDettaglioService.setId(value),
        10
      );
    if (!this._projectId) this.interventoId = null;
    this.isInInsertState = !this._projectId || this._projectId <= 0;
    let callback = null;
    if (
      this._forceOpenEditIntervento &&
      value != null 
    ) {
      callback = () => {
        this.isDirtyHelper.isDirty = false;
        if (!this._stayOnDetailPage) this.confirmChangeTab(null, 5);       
      };
    }
    this.getMainInterventoByProjectId(callback);

    if (this._projectId) {
      this.progettoService.get(this._projectId).subscribe((x) => {
        this.datiProgetto = x;
        this.sismaIsHidden(x);
      });
    }   
  }

  sismaHidden: boolean = true;

  sismaIsHidden(data) {
    if (data.areeTematiche.find((el) => el.struttura == "DU0100")) {
      this.sismaHidden = false;
    } else {
      this.sismaHidden = true;
    }
  }

  get projectId(): number {
    return this._projectId;
  }

  _forceOpenEditIntervento = false;
  set forceOpenEditIntervento(value: boolean) {
    this._forceOpenEditIntervento = value;
  }
  _stayOnDetailPage = false;
  set stayOnDetailPage(value: boolean) {
    this._stayOnDetailPage = value;
  }

  set interventoId(value) {
    this._interventoId = value;
  }
  get interventoId(): number {
    return this._interventoId;
  }

  currentProject: Progetto;
  _projectId: number;

  _interventoId: number;
  isInInsertState = true;
  esistonoInterventi = false;
  esistonoSal = false;

  queryStringProjectId;
  queryStringInterventoId;

  constructor(
    private route: ActivatedRoute,
    private progettoService: ProgettoService,
    private progettoDivDettaglioService: ProgettoDivDettaglioService,
    private isDirtyHelper: IsDirtyHelper,
    private cdref: ChangeDetectorRef,
    private authService: AuthService
  ) {
    let projectIdParam: string = this.route.snapshot.paramMap.get("id");
    if (!isNaN(parseInt(projectIdParam))) {
      this._projectId = parseInt(projectIdParam);
      this.isInInsertState = false;
    }
  }
  ngAfterViewInit(): void {
    this.getMainInterventoByProjectId();
  }

  ngOnDestroy(): void {
    
  }
  ngOnInit(): void {}

  

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  activeTab: string = "tabDatiGenerali";
  confirmChangeTab($event, index) {
    if (!this.isDirtyHelper.checkIsDirty()) {
      $event.stopPropagation();
      return false;
    }

    if (index !== 16 && this.activeTab === "tabSicerBilancio") {
      this.divSicerBilancioContent.resetSubData();
    }
    switch (index) {
      case 0:
        this.activeTab = "tabDatiGenerali";
        this.tabDatiGeneraliProgettoContent.getProjectData(this.datiProgetto);
        break;
      case 1:
        this.activeTab = "tabFasi";
        if (this.tabFasiContent) this.tabFasiContent.reloadData();
        break;
      case 2:
        this.activeTab = "tabFinanziamenti";
        if (this.tabFinanziamentiContent) {
          this.tabFinanziamentiContent.reloadData();
        }
        break;
      case 3:
        this.activeTab = "tabErogazioni";
        if (this.tabErogazioniContent) {
          this.tabErogazioniContent.reloadData();          
        }
        break;
      case 4:
        this.activeTab = "tabSal";
        if (this.tabSalContent) this.tabSalContent.reloadData();
        break;
      case 5:
        this.activeTab = "tabInterventi";
        if (this.tabInterventiContent) {
          
          if (this.route.snapshot.paramMap.get("idIntervento")) {
            this.tabInterventiContent.interventoId = parseInt(
              this.route.snapshot.paramMap.get("idIntervento")
            );
          }
          this.tabInterventiContent.reloadAllData();
        }
        break;
      case 6:
        this.activeTab = "tabProvvedimenti";
        if (this.tabProvvedimentiContent)
          this.tabProvvedimentiContent.reloadData();
        break;
      case 7:
        this.activeTab = "tabAnnualita";
        if (this.tabAnnualitaContent) this.tabAnnualitaContent.reloadData();
        break;
      case 8:
        this.activeTab = "tabRiepilogo";
        if (this.tabRiepilogoContent) this.tabRiepilogoContent.reloadData();
        break;
      case 9:
        this.activeTab = "tabStorico";
        if (this.divTemplateStorico) this.divTemplateStorico.reloadAllData();
        break;
      case 10:
        this.activeTab = "tabDocumentazione";
        if (this.tabDocumentazioneContent)
          this.tabDocumentazioneContent.refreshGrid();
        break;
      case 11:
        this.activeTab = "tabRiepilogoIntervento";
        if (this.tabRiepilogoInterventoContent)
          this.tabRiepilogoInterventoContent.reloadData();
        break;
      case 12:
        this.activeTab = "tabMonitoraggio";
        if (this.divTemplateMonitoraggio)
          this.divTemplateMonitoraggio.reloadAllData();
        break;
      case 13:
        this.activeTab = "tabDnsh";
        if (this.divTemplateDnsh) this.divTemplateDnsh.reloadAllData();
        break;
      case 14:
        this.activeTab = "tabProvvedimentiDocumenti";
        if (this.divProvvedimentiDocumentiContent)
          this.divProvvedimentiDocumentiContent.reloadAllData();
        break;
      case 15:
        this.activeTab = "tabBdap";
        if (this.divTemplateBdap) this.divTemplateBdap.reloadAllData();
        break;
      case 16:
        this.activeTab = "tabSicerBilancio";
        if (this.divSicerBilancioContent)
          this.divSicerBilancioContent.reloadAllData();
        break;
      case 17:
        this.activeTab = "tabSisma";
        if (this.divSismaContent) this.divSismaContent.reloadAllData();
        break;
        case 18:
            this.activeTab = "tabSubInterventi";
            if (this.divSubInterventi) this.divSubInterventi.reloadAllData();
            break;
    }
  }

  mainIntervento: any;

  getMainInterventoByProjectId(callback = null) {
    if (!this.projectId || this.projectId <= 0) {
      this.datiGeneraliFormFillComplete(null);
      if (callback) callback();
      return;
    }
    this.esistonoInterventi = false;
    this.haSubInterventi = false;
    this.haFinanziamenti = false;
    this.type = ServiceType.PROGETTOINTERVENTO;

    this.progettoService
      .getDatiDettaglio(this.projectId)
      .subscribe((result) => {
        this.mainIntervento = result;
        this.codiceIntervento = result.codice;
        this.haFinanziamenti = (result["finanziato"] ?? 0) >0;
        this.haSubInterventi = result["numSottoInterventi"];//sub_interventi se >0 li ha
        console.log("haSubInterventi",this.haSubInterventi);
        this.interventoId = result["id"];
        this.esistonoSal = result.esistonoSal;

        this.esistonoInterventi = result.numSubInterventi > 0;
        this.type = !this.esistonoInterventi
          ? ServiceType.PROGETTO
          : ServiceType.PROGETTOINTERVENTO;

        if (callback) callback();
      });    
  }

  onSalSaved($event: any) {
    this.getMainInterventoByProjectId();
  }

  reloadAfterSubInterventi($event:any){
    this.getMainInterventoByProjectId();
  }

  onInterventoDatiGeneraliSaved($event: any) {
    this.getMainInterventoByProjectId();
    if (this._projectId) {
      this.progettoService.get(this._projectId).subscribe((x) => {
        this.datiProgetto = x;
        this.tabDatiGeneraliProgettoContent.projectData = this.datiProgetto;
      });
    }

    //ricarico il dati generali del progetto
  }

  @Output()
  datiGeneraliSavedEmitter: EventEmitter<any> = new EventEmitter();

  datiGeneraliSaved($event: any) {
    console.log("progetto - datiGeneraliSaved");

    this.projectId = $event.progettoId ? $event.progettoId : $event.id;
    this.datiGeneraliSavedEmitter.emit($event);
  }
  //emit invocato da cambio annualita
  annualitaChangedEmit($event: any) {
    this.getMainInterventoByProjectId();
  }

  gotoBackList($event: any) {
    console.log("inside gotobacklist");
    if (!this.isDirtyHelper.checkIsDirty()) return false;
    if (this.activeTab === "tabSicerBilancio") {
      this.divSicerBilancioContent.resetSubData();
    }
    this.mainIntervento = null;
    this.tabDatiGeneraliProgettoContent.cleanUpForm();
    this.gotoBtnFunction.emit($event);
  }

  @Input()
  gotoBtnOptions: any;

  @Output()
  gotoBtnFunction: EventEmitter<any> = new EventEmitter();

  printValueAsCurrency(value) {
    value = typeof value === "number" ? value : value.replace(",", ".").trim();
    const format = "1.2-2";
    const currency = "€";
    const currentLocale: string = "it";
    return new CurrencyPipe(currentLocale).transform(
      value,
      "EUR",
      currency,
      format,
      "it-IT"
    );
  }

  showPage: boolean = false;
  datiGeneraliFormFillComplete($event) {
    this.showPage = true;
  }

  isAdmin() {
    return this.authService.isAdmin();
  }
  isGr34() {
    return this.authService.isGr34();
  }
  getGotoOptions() {
    return {
      show: true,
      label:
        "Torna a elenco progetti " +
        (this.isAdmin() || this.isGr34() ? "/ fabbisogni" : ""),
    };
  }
}
