<div class="px-sm-0 px-0 ">
    <div class="d-flex justify-content-end">

        <button class="btn btn-info btn-sm font-weight-bold mb-2" [hidden]="!dtOptions.buttons || !dtOptions.buttons.excel" (click)="exportExcelFunction()">Esporta XLS <i class="fas fa-file-excel"></i></button>

    </div>



    <div class="row justify-content-between" [hidden]="dtOptions?.hideRowsPerPage">
        <div class=" page-count col-lg-4 col-md-12 col-sm-12 col-xs-12 text-left testo-centrato-mobile">
            <label>Visualizza
            <select (change)="onLimitChange($event.target.value)" class="page-limit">
                <option
                    *ngFor="let option of pageLimitOptions"
                    [value]="option.value" [selected]="option.value ==currentPageLimit"
                    >
                    {{option.text}}
                </option>
            </select>
            elementi
        </label>
        </div>
         <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
    <div class="row justify-content-end">
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <ng-template #extraRichiestaSicerTemplate [ngTemplateOutlet]="dtOptions.extraRichiestaSicerTemplate"></ng-template>
        </div>

        <div class="page-filter col-lg-7 col-md-6 col-sm-12 col-xs-12 text-right testo-centrato-mobile " [hidden]="dtOptions?.hideSearch">
            <form [formGroup]="searchForm">
                <label>Cerca:
                    <input formControlName="filterText" type="text">
                </label>
            </form>
        </div>
    </div>
        </div>
    </div>
    <div class="row justify-content-end">
        <ng-template #extraFilter [ngTemplateOutlet]="dtOptions.extraFilterTemplate"></ng-template>
    </div>


    <ngx-datatable #datatable class="table-bordered small mb-5 {{dtOptions.class}}" style="width: 100%; height: auto;" [rows]="gridModel.data" [columns]="columns" [columnMode]="dtOptions.columnMode" [headerHeight]="dtOptions.headerHeight" [footerHeight]="dtOptions.footerHeight"
        rowHeight="dtOptions.rowHeight" [externalSorting]="dtOptions.externalSorting" (sort)="onSort($event)" [ngClass]="dtOptions.class" [summaryRow]="dtOptions.summaryRow" [summaryPosition]="dtOptions.summaryPosition" [externalPaging]="dtOptions.externalPaging"
        [count]="gridModel.totalElements" [offset]="gridModel.currentPageNumber" [limit]="gridModel.pageSize" (page)="loadPage($event)" [messages]="messages" [draggable]="dtOptions.draggable" [scrollbarV]="dtOptions.scrollbarV" [scrollbarH]="dtOptions.scrollbarH"
        [loadingIndicator]="_isLoading" [attr.id]="dtOptions.id">

        <!--<ng-container *ngFor="let col of columns">
        <ngx-datatable-column *ngIf="col.visible!=false" [name]="col.name" [prop]="col.prop">
                <ng-container *ngTemplateOutlet="normalValueTemplate"></ng-container>

        </ngx-datatable-column>
    </ng-container>   -->



    </ngx-datatable>
</div>



<ng-template #hideTemplate let-row="row" let-value="value" ngx-datatable-cell-template>
</ng-template>
<ng-template #normalValueTemplate ngx-datatable-cell-template>
    {{value}}
</ng-template>
<ng-template #dateValueTemplate let-row="row" let-value="value" ngx-datatable-cell-template>
    {{printValueAsDate(value)}}
</ng-template>
<ng-template #dateTimeValueTemplate let-row="row" let-value="value" ngx-datatable-cell-template>
    {{printValueAsDateTime(value)}}
</ng-template>
<ng-template #currencyValueTemplate let-row="row" let-value="value" ngx-datatable-cell-template>
    {{printValueAsCurrency(value)}}
</ng-template>
<ng-template #booleanValueTemplate let-row="row" let-value="value" ngx-datatable-cell-template>
    {{(value?"Si":"No")}}
</ng-template>

<!--<div style="display:none;" id="div_export_excel" #div_export_excel></div>-->
