<div class="modal-header text-center">
  <h4 class="modal-title pull-left">{{'Gestione Permessi di ' + username}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onCloseModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 ">
        <accordion [isAnimated]="true">
          <accordion-group heading="Inserisci nuovo permesso">
            <app-add-permissions [userId]="userId" [sections]="sectionsToSet" (permissionEmit)="addPermissionHandler($event)" (permissionSelectedEmit)="toConfirmHandler($event)"></app-add-permissions>
          </accordion-group>
        </accordion>

        <div class="mt-5">
          <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto; padding: 0px;" [dtOptions]="dtOptionsNgx">
            <ng-template #btnInsertPermissionTemplate let-row="row" let-value="value">
              <input class='selected' type='checkbox' [checked]="value" (change)="enableHandler($event, row, 'insert')" />
            </ng-template>

            <ng-template #btnUpdatePermissionTemplate let-row="row" let-value="value">
              <input class='selected' type='checkbox' [checked]="value" (change)="enableHandler($event, row, 'update')" />
            </ng-template>

            <ng-template #btnReadPermissionTemplate let-row="row" let-value="value">
              <input class='selected' type='checkbox' [checked]="value" (change)="enableHandler($event, row, 'read')" />
            </ng-template>

            <ng-template #btnDeletePermissionTemplate let-row="row" let-value="value">
              <input class='selected' type='checkbox' [checked]="value" (change)="enableHandler($event, row, 'delete')" />
            </ng-template>

            <ng-template #btnDeleteTemplate let-row="row" let-value="value">
              <a [ngClass]="{
                'disabled': loading
                }" class=" border-0 text-center" (click)="deleteHandler(row)" data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer" ></i>
              </a>
            </ng-template>
          </ngx-datatable-wrapper>
        </div>
        <ng-template #templateAddPermissions>
          <div class="modal-header  text-center">
            <h4 class="modal-title pull-left">Aggiungi permessi</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
