import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { AuthService } from "src/app/services";
import {
  DashboardService,
  PnrrService,
  ProgettoService,
  SoggettoGiuridicoService,
} from "../../services";
import { PnrrComponente } from "../../models/pnrrComponente";
import { InterventiSicerContabilita } from "../../models/interventi-sicer-contabilita";
import { CurrencyPipe } from "@angular/common";
import * as moment from "moment";
import * as FileSaver from "file-saver";




@Component({
  selector: "app-dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr",
  templateUrl:
    "./dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr.component.html",
  styleUrls: [
    "./dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr.component.css",
  ],
})
export class DashboardAnalisiReportingRisorseProgettiFinanziatiPnrrComponent
  implements OnInit
{
  loadingExportExcel: boolean = false;
  myForm: FormGroup;

  pnrrMissioniItems = [];
  pnrrLineeItems = [];
  pnrrComponentiItems = [];

  pnrrLineeAll = [];
  pnrrComponentiAll = [];

  progettiItems = [];
  anniItems = [];
  soggettiAttuatoriItems = [];
  interventiSicerContabilita: InterventiSicerContabilita[] = [];
  interventiSicerContabilitaArrayOriginale: InterventiSicerContabilita[] = [];

  //!Progetti
  sommaImportoPrevisto: any = [];
  sommaImportoImpegnato: any = [];
  sommaImportoEffettivo: any = [];

  sommaTotaleImportoPrevistoProgetti: number = 0;
  sommaTotaleImportoImpegnatoProgetti: number = 0;
  sommaTotaleImportoEffettivoProgetti: number = 0;


  annualitaProgettoSelezionato = {};
  interventiProgettoSelezionato = {};

  annualitaInterventoSelezionato = {};

  percentualeTotaleProgettiImpegnatoPrevisto: number = 0;
  percentualeTotaleProgettiEffettivoImpegnato: number = 0;

  //! interventi
  sommaImportoPrevistoIntervento: any = [];
  sommaImportoEffettivoIntervento: any = [];
  sommaImportoImpegnatoIntervento: any = [];

  showLoading: boolean = false;

  completerParams = {
    labelField: "descrizioneEstesa",
    placeholder: "Cerca intervento",
    ajax: this.filterComboInterventi,
    items: [],
    context: this,
  };

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private progettoService: ProgettoService,
    private pnrrService: PnrrService,
    private soggettoGiuridicoService: SoggettoGiuridicoService
  ) {}

  ngOnInit(): void {
    this.myForm = new FormGroup({
      pnrrMissioni: new FormControl(null),
      pnrrComponenti: new FormControl(null),
      pnrrLinee: new FormControl(null),
      progetto: new FormControl(null),
      intervento: new FormControl(null),
      anno: new FormControl(null),
      soggAttuatore: new FormControl(null),
    });
    this.attachComboValueChange();
    this.getAllComponenti();
    this.getAllLinee();
    this.fillComboFinanziamentoPnrrMissioniComponentiLinee();
    this.getAllAnni();
    this.getSoggettiAttuatori();
    this.getAllProgettiOnlyPnrr();
    this.getAllInterventiSicerContabilita(this.myForm.value);
  }

  getAllProgettiOnlyPnrr() {
    return this.progettoService.getAll("1").subscribe((progetti) => {
      this.progettiItems = progetti
        .filter((progetto) => !progetto.isDeleted && progetto.pnrrRilevante)
        .sort((a, b) => a.descrizione.localeCompare(b.descrizione));

      this.progettiItems.forEach(
        (x) => (x["descrizioneEstesa"] = `${x.codice} - ${x.descrizione}`)
      );
    });
  }

  getAllInterventiSicerContabilita(filters) {
    return this.pnrrService
      .getAllInterventiSicerContabilita(filters)
      .subscribe((interventi: any) => {
        this.interventiSicerContabilitaArrayOriginale = interventi;

//per i progetti che hanno degli interventi al loro interno, il calcolo dell'importo effettivo (sommaImportoEffettivo) va preso dalla somma degli interventi al loro interno

        this.interventiSicerContabilita = interventi
          ? interventi.map((intervento: InterventiSicerContabilita) => {
              const annoEsistente = new Set<number>();
            intervento.annualita = this.raggruppaAnnualita(intervento.annualita);
            //per i progetti che hanno degli interventi al loro interno, il calcolo dell'importo effettivo (sommaImportoEffettivo) va preso dalla somma degli interventi al loro interno
            //lo faccio anche per le singole annualita
            /*if (intervento.interventi.length>0){
                for(let annualita of intervento.annualita){
                    annualita.importoLiquidazioni =intervento.interventi.reduce((val,interventoTmp)=>
                            val + interventoTmp.annualita.filter(x=>x.annoImporto==annualita.annoImporto)
                            .reduce((v,ann)=>v+ ann.importoLiquidazioni,0)  ,0)

                }
            }*/

              const result = {
                ...intervento,

                sommaImportoPrevisto: intervento.annualita.reduce(
                  (acc, ann) => {
                    if (!annoEsistente.has(ann.annoImporto)) {
                      annoEsistente.add(ann.annoImporto);
                      return acc + ann.importo;
                    } else {
                      return acc;
                    }
                  },
                  0
                ),
                sommaImportoImpegnato: intervento.annualita.reduce(
                  (acc, ann) => acc + ann.importoImpegno,
                  0
                ),
                sommaImportoEffettivo:  intervento.annualita.reduce(
                  (acc, ann) => acc + ann.importoLiquidazioni,
                  0
                )
              };

              //console.log(intervento.annualita);

              return result;
            })
          : [];

        this.sommaTotaleImportoPrevistoProgetti = 0;
        this.sommaTotaleImportoImpegnatoProgetti = 0;
        this.sommaTotaleImportoEffettivoProgetti = 0;

        this.interventiSicerContabilita.forEach((intervento: any) => {
          this.sommaImportoEffettivo.push(intervento.sommaImportoEffettivo);
          this.sommaImportoImpegnato.push(intervento.sommaImportoImpegnato);
          this.sommaImportoPrevisto.push(intervento.sommaImportoPrevisto);
          intervento.percentualeImpegnatoPrevisto =
            intervento.sommaImportoPrevisto <= 0
              ? 0
              : (
                  (intervento.sommaImportoImpegnato /
                    intervento.sommaImportoPrevisto) *
                  100
                ).toFixed(2);

                intervento.percentualeEffettivoImpegnato =
                intervento.sommaImportoImpegnato <= 0
                  ? 0
                  : (
                      (intervento.sommaImportoEffettivo /
                        intervento.sommaImportoImpegnato) *
                      100
                    ).toFixed(2);

          this.sommaTotaleImportoPrevistoProgetti +=
            intervento.sommaImportoPrevisto;
          this.sommaTotaleImportoImpegnatoProgetti +=
            intervento.sommaImportoImpegnato;
          this.sommaTotaleImportoEffettivoProgetti +=
            intervento.sommaImportoEffettivo;
        });




        this.percentualeTotaleProgettiImpegnatoPrevisto = this.sommaTotaleImportoPrevistoProgetti
          ? Number(
              (
                (this.sommaTotaleImportoImpegnatoProgetti /
                  this.sommaTotaleImportoPrevistoProgetti) *
                100
              ).toFixed(2)
            )
          : 0;

          this.percentualeTotaleProgettiEffettivoImpegnato = this.sommaTotaleImportoImpegnatoProgetti
          ? Number(
              (
                (this.sommaTotaleImportoEffettivoProgetti /
                  this.sommaTotaleImportoImpegnatoProgetti) *
                100
              ).toFixed(2)
            )
          : 0;

      });
  }

  caricaAnnualitaInterventoSelezionato(intervento:any) {
    const id = intervento.id;
    this.doExpand(id);
    if (this.annualitaInterventoSelezionato[`int_${id}`]) return;

    if (intervento) {
      const annualitaInterventoUnica = this.raggruppaAnnualita(intervento.annualita);
      this.annualitaInterventoSelezionato[`int_${id}`] = annualitaInterventoUnica;
    } else {
      this.annualitaInterventoSelezionato[`int_${id}`] = [];
    }

    console.log(this.annualitaInterventoSelezionato[`int_${id}`]);
  }


  raggruppaAnnualita = (annualitaList) => {
    let result = [];
    for (let annualita of annualitaList) {
      let current = result.find(
        (x) => x.annoImporto == annualita.annoImporto
      );
      if (!current) {
        result.push(annualita);
        current = annualita;
      } else {
        current.importoImpegno += annualita.importoImpegno || 0;
        current.importoLiquidazioni += annualita.importoLiquidazioni || 0;
      }

            current.percentualeImpegnatoPrevisto =
            current.importo <= 0
              ? 0
              : (
                  (current.importoImpegno /
                  current.importo) *
                  100
                ).toFixed(2);

                current.percentualeEffettivoImpegnato =
                current.importoImpegno <= 0
                  ? 0
                  : (
                      (current.importoLiquidazioni /
                      current.importoImpegno) *
                      100
                    ).toFixed(2);





    }
    result.sort((a, b) => a.annoImporto - b.annoImporto);
    return result;
  };

  caricaAnnualitaEInterventi(id: number) {
    this.doExpand(id);
    if (this.interventiProgettoSelezionato[`prog_${id}`]) return;
    const progetto = this.interventiSicerContabilitaArrayOriginale.find(
      (progettoId) => progettoId.id === id
    );

    if (progetto) {


      if (!this.annualitaProgettoSelezionato[`prog_${progetto.id}`])
        this.annualitaProgettoSelezionato[`prog_${progetto.id}`] =
          this.raggruppaAnnualita(progetto.annualita);

      for (let intervento of progetto.interventi) {
        intervento.sommaImportoEffettivo= 0;
        intervento.sommaImportoImpegnato= 0;
        intervento.sommaImportoPrevisto= 0;
        intervento.annualita = this.raggruppaAnnualita(intervento.annualita);
        for(const annualita of intervento.annualita){
            intervento.sommaImportoPrevisto += annualita.importo ||0;
            intervento.sommaImportoImpegnato += annualita.importoImpegno || 0;
            intervento.sommaImportoEffettivo += annualita.importoLiquidazioni || 0;
        }

        intervento['percentualeImpegnatoPrevisto'] =
        intervento.sommaImportoPrevisto <= 0
          ? 0
          : (
              (intervento.sommaImportoImpegnato /
                intervento.sommaImportoPrevisto) *
              100
            ).toFixed(2);

            intervento['percentualeEffettivoImpegnato'] =
            intervento.sommaImportoImpegnato <= 0
              ? 0
              : (
                  (intervento.sommaImportoEffettivo /
                    intervento.sommaImportoImpegnato) *
                  100
                ).toFixed(2);


      }
      this.interventiProgettoSelezionato[`prog_${id}`] =progetto.interventi;
    } else {
      console.log("Progetto non trovato:", id);
      return;
    }
  }


  printValueAsCurrency(value) {
    value = typeof value === "number" ? value : value.replace(",", ".").trim();
    const format = "1.2-2";
    const currency = "€";
    const currentLocale: string = "it";
    return new CurrencyPipe(currentLocale).transform(
      value,
      "EUR",
      currency,
      format,
      "it-IT"
    );
  }

  fillComboFinanziamentoPnrrMissioniComponentiLinee() {
    this.pnrrService.getAllMissione().subscribe((missioni) => {
      this.pnrrMissioniItems = missioni;
    });
  }

  getAllComponenti() {
    this.pnrrService.getAllComponente().subscribe((componenti) => {
      this.pnrrComponentiAll = componenti
        .map((filteredComponente: PnrrComponente) => ({
          valore: `${filteredComponente.missione.codice} - ${filteredComponente.descrizione} `,
          ...filteredComponente,
        }))
        .sort((a, b) => a.valore.localeCompare(b.valore));
    });
  }

  getAllLinee() {
    this.pnrrService.getAllLineaFinanziamento().subscribe((linee) => {
      this.pnrrLineeAll = linee
        .map((filteredLinee) => ({
          valore: `${filteredLinee.codice} - ${filteredLinee.descrizione}`,
          ...filteredLinee,
        }))
        .sort((a, b) => a.valore.localeCompare(b.valore));
    });
  }

  attachComboValueChange() {
    this.myForm
      .get("pnrrMissioni")
      .valueChanges.subscribe((selectedMissioni) => {
        if (selectedMissioni && selectedMissioni.length > 0) {
          this.pnrrComponentiItems = this.pnrrComponentiAll.filter(
            (filteredComponent: PnrrComponente) =>
              selectedMissioni.includes(filteredComponent.missione.id)
          );

          if (this.myForm.get("pnrrComponenti").value) {
            const comp = this.myForm.get("pnrrComponenti").value;
            this.myForm
              .get("pnrrComponenti")
              .setValue(
                this.pnrrComponentiItems
                  .filter((x) => comp.includes(x.id))
                  .map((x) => x.id)
              );
          }
          if (selectedMissioni.includes(this.pnrrComponentiItems)) {
            this.myForm.get("pnrrComponent").updateValueAndValidity();
          }
        } else {
          this.myForm.get("pnrrComponenti").setValue([]);
          this.pnrrComponentiItems = [];

          this.myForm.get("pnrrLinee").setValue([]);
          this.pnrrLineeItems = [];
        }
      });

    this.myForm
      .get("pnrrComponenti")
      .valueChanges.subscribe((selectedComponenti) => {
        if (selectedComponenti && selectedComponenti.length > 0) {
          this.pnrrLineeItems = this.pnrrLineeAll.filter((filteredLine) =>
            selectedComponenti.includes(filteredLine.componente.id)
          );

          if (this.myForm.get("pnrrLinee").value) {
            const linee = this.myForm.get("pnrrLinee").value;
            this.myForm
              .get("pnrrLinee")
              .setValue(
                this.pnrrLineeItems
                  .filter((x) => linee.includes(x.id))
                  .map((x) => x.id)
              );
          }
          if (selectedComponenti.includes(this.pnrrLineeItems)) {
            this.myForm.get("pnrrLinee").updateValueAndValidity();
          }
        } else {
          this.myForm.get("pnrrLinee").setValue([]);
          this.pnrrLineeItems = [];
        }
      });
  }

  getSoggettiAttuatori() {
    this.soggettoGiuridicoService
      .getSoggettiAttuatori(1)
      .subscribe((soggetti) => {
        this.soggettiAttuatoriItems = soggetti;
        this.soggettiAttuatoriItems.sort((a, b) =>
          a.denominazione.localeCompare(b.denominazione)
        );
      });
  }

  getAllAnni() {
    this.dashboardService.getAnni().subscribe((anni) => {
      this.anniItems = anni;
      this.anniItems.sort((a, b) => parseInt(a) - parseInt(b));
    });
  }

  isCustomHeader() {
    return this.authService.getHeaderCode() != "generale";
  }
  getHeaderLabel() {
    return this.isCustomHeader()
      ? "Piano Nazionale Ripresa e Resilienza"
      : "Direzione Regionale Infrastrutture e Mobilità";
  }

  filterComboInterventi(filterString, callBack) {
    let f = {
      filterPnrr: 1,
      descriptionSearchString: filterString,
    };
    this["context"].dashboardService
      .getDashboardPnrrInterventiList(f)
      .subscribe((x) => {
        x = x.map((y) => {
          y["descrizioneEstesa"] = `${y.codice} - ${y.descrizione}`;
          return y;
        });
        if (callBack) callBack(x);
      });
  }

  setIntervention($event) {
    this.myForm.get("intervento").setValue($event ? $event.id : -1);
  }

  doSearch() {
    this.sommaImportoEffettivo = [];
    this.sommaImportoImpegnato = [];
    this.sommaImportoPrevisto = [];

    this.annualitaProgettoSelezionato = {};
    this.interventiProgettoSelezionato = {};
    this.annualitaInterventoSelezionato = {};
    this.arrExpandStatus = {};

    this.sommaTotaleImportoPrevistoProgetti = 0;
    this.sommaTotaleImportoImpegnatoProgetti = 0;
    this.sommaTotaleImportoEffettivoProgetti = 0;

    this.getAllInterventiSicerContabilita(this.myForm.value);
  }

  doExcel() {
    const pnrrMissioni = this.myForm.controls.pnrrMissioni.value;
    const pnrrComponenti = this.myForm.controls.pnrrComponenti.value;
    const pnrrLinee = this.myForm.controls.pnrrLinee.value;
    const progetto = this.myForm.controls.progetto.value;
    const intervento = this.myForm.controls.intervento.value;
    const anno = this.myForm.controls.anno.value;
    const soggAttuatore = this.myForm.controls.soggAttuatore.value;

    const filters = {
      pnrrMissioni,
      pnrrComponenti,
      pnrrLinee,
      progetto,
      intervento,
      anno,
      soggAttuatore,
    };

    console.log(filters);

    this.loadingExportExcel = true;
    this.pnrrService.esportaInExcel(filters).subscribe(
      (response) => {
        const filename = `esportazione_tabella_${moment(new Date()).format(
          "YYYY_MM_DDTHH_mm_ss"
        )}.xlsx`;
        FileSaver.saveAs(response.body, `${filename}`);
        this.loadingExportExcel = false;
        console.log(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  arrExpandStatus = {};
  doExpand(id) {
    console.log("into do expand", id);
    const code = "prog_" + id;
    this.arrExpandStatus[code] = !this.arrExpandStatus[code];
    if (
      !this.arrExpandStatus[code] &&
      this.interventiProgettoSelezionato[`prog_${id}`]
    ) {
      for (const intervento of this.interventiProgettoSelezionato[
        `prog_${id}`
      ]) {
        this.arrExpandStatus["prog_" + intervento.id] = false;
      }
    }
  }










}
