import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { filter, distinctUntilChanged, map } from "rxjs/operators";
import { AuthService } from "src/app/services";
import { BreadCrumb } from "./breadcrumb";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.css"],
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    distinctUntilChanged(),
    map((event) => this.buildBreadCrumb(this.activatedRoute.root))
  );

  hiddenOnPages = ["/login","login", "/uploadFile"];
  hidden = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() { }

  buildBreadCrumb(route: ActivatedRoute): Array<BreadCrumb> {
    const completeUrl = route.snapshot['_routerState'].url;
    this.hidden = this.hiddenOnPages.includes(completeUrl);

    return this.hidden ? [] : [{ clickable: true, label: 'Home', url: '' }].concat(this.checkItemMenuFromUrl(completeUrl, this.authService.getMenu()));
  }

  private checkItemMenuFromUrl(uri: string, menuItems: any[]) {
    const subItem = menuItems.find(item => item.subMenu && (item.subMenu.find((sub: { link: string; }) => sub.link === uri) !== undefined));
    const item = subItem ? subItem.subMenu.find((sub: { link: string; }) => sub.link === uri) : null;
    return subItem && item ? [{ clickable: false, label: subItem.breadcrumb, url: '' }, { clickable: false, label: item.breadcrumb, url: item.link }] : [];
  }

  getRouterLink(bc: any) {
    return bc.clickable ? bc.url : null;
  }
}
