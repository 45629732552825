export class Nation {
    id: string;
    istatCode: string;
    atCode: string;
    isoCode: string;
    name: string;
    isState: boolean;
    citizenship: string;
    fromDate: string;
    toDate: string;
    idContinent: number;
  }
  