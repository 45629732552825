<ng-template #templateEditAdd>
    <form [formGroup]="addTipologiaInterventoForm" (ngSubmit)="onSubmit()" class="col-lg-12">
        <input type="hidden" [formGroup]="addTipologiaInterventoForm" formControlName="id" />
        <input type="hidden" [formGroup]="addTipologiaInterventoForm" formControlName="disegnabile" />
        <div  class="form-group col-lg-6">
            <h5><label for="codice" class="mandatory">Codice:</label></h5>
            <input  [readonly]="!!disegnabile" [formGroup]="addTipologiaInterventoForm" formControlName="codice" maxlength="255" type="text"
                value="" class="form-control" placeholder="Inserisci il codice" [ngClass]="{
                    'form-control': true,
                    'is-invalid': submitted && f.codice.errors
                    }" />
            <div *ngIf="submitted && f.codice.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.codice.errors.required">
                    Campo obbligatorio
                </div>
            </div>
        </div>
        <div class="form-group col-lg-12">
            <h5><label for="descrizione" class="mandatory">Descrizione:</label></h5>
            <input [formGroup]="addTipologiaInterventoForm" formControlName="descrizione" type="text" value=""
                class="form-control" placeholder="Inserisci descrizione" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submitted && f.descrizione.errors
                      }" />
            <div *ngIf="submitted && f.descrizione.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.descrizione.errors.required">
                    Campo obbligatorio
                </div>
            </div>
        </div>

        <div class="form-group col-lg-12">
            <h5><label for="note">Note:</label></h5>
            <input [formGroup]="addTipologiaInterventoForm" formControlName="note" type="text" value=""
                class="form-control" placeholder="Inserisci Note" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submitted && f.note.errors
                      }" />
        </div>
        <div class="form-group col-lg-12">
            <h5><label for="idNaturaTipologia" class="mandatory">Natura Tipologia:</label></h5>
            <div  [ngClass]="{ 'is-invalid': submitted && f.idNaturaTipologia.errors }">
                <ng-select [readonly]="!!disegnabile" [formGroup]="addTipologiaInterventoForm" formControlName="idNaturaTipologia"
                    [items]="naturaTipologie" bindValue="id" bindLabel="etichetta"
                    (change)="onChangeIdNaturaTipologia($event)" placeholder="Seleziona natura tipologia">
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        {{ item.codice }} - {{ item.etichetta }}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-clear="clear">
                        {{ item.codice }} - {{ item.etichetta }}
                    </ng-template>
                </ng-select>
            </div>
            <div *ngIf="submitted && f.idNaturaTipologia.errors" class="invalidFieldMessage" style="display: block;">
                <div *ngIf="f.idNaturaTipologia.errors" class="invalid-feedback" style="display: block;">
                    Campo obbligatorio
                </div>
            </div>
        </div>
        <div >
        
            <div id="edit-icona" class="col-lg-12 mb-2">
                <div class="row justify-content-between">
                    <div class="col-lg-10 col-sm-10 col-10 ">
                        <h5 for="icona-text-value" class="">Icona: </h5>
                        <img class="tipologia-intervento-icon" data-toggle="tooltip" data-placement="top"
                        [title]="disegnabile ? '' : f.icona.value ? f.icona.value : 'Generica'" [src]="getSrcForImage()"> 
                        <input [hidden]="true" [formGroup]="addTipologiaInterventoForm" formControlName="icona"
                            type="text" value="" class="form-control" placeholder="Nessuna" [ngClass]="{
              'form-control': true,
              'is-invalid': submitted && f.icona.errors
              }" />
                    </div>
                    <div [hidden]="!!disegnabile" class="col-lg-1 col-sm-2 col-3 text-center icona-edit-button">
                        <a href="javascript:;;" [ngClass]="{
                                'disabled': loading
                                }" class=" border-0 text-center" data-toggle="tooltip" data-placement="top"
                            title="cambia icona" (click)="onEditingIcona()">
                            <i class="far fa-image fa-2x pointer"></i>                             
                            </a>
                    </div>
                </div>
            </div>

            <div id="ripristina-icona" *ngIf="isEditingIcona" class="col-lg-12 mb-2">
                <table class="icona-edit-table">
                    <tr>
                        <td>Carica File</td>
                        <td class="t-a-r">{{ f.icona.value ? "Ripristina Default": "" }}</td>
                    </tr>
                    <tr>
                        <td>
                            <input  type="file" value="" (change)="onIconUpload($event)" class="pt-1 pointer " />
                            <div *ngIf="!iconFileExtensionIsValid" class="invalidFieldMessage" style="display: block; font-size: x-small; color: red">
                                <span>Supportati solo file di tipo 'png'</span>
                                
                            </div>
                        </td>
                        <td class="t-a-r">
                            <a id="ripristina-icona" *ngIf="f.icona.value" href="javascript:;;" (click)="onIconReset()" [ngClass]="{
                    'disabled': loading
                    }" class=" border-0 text-center" data-toggle="tooltip" data-placement="top"
                                title="ripristina icona di default">&nbsp;&nbsp;&ensp;<i
                                    class="fas fa-sync pointer"></i></a>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="form-group col-lg-12">
            <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
                {{buttonLabel}}
            </button>
        </div>


    </form>
</ng-template>




<div class="container sfondo_bianco">
    <hr />
    <accordion [isAnimated]="true" >
        <accordion-group [(isOpen)]="accordionIsOpen" heading="Inserisci Nuova Tipologia Intervento">
            <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
        </accordion-group>
    </accordion>
    <div class="mt-5">

        <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;"
            [dtOptions]="dtOptions">
            <ng-template #columnDisegnabileTemplate let-row="row" let-value="value">
                {{ value === undefined ? "": value ? "Si": "No" }}
            </ng-template>
            <ng-template #columnNaturaTipologiaTemplate let-row="row" let-value="value">
                {{ row.naturaTipologia.codice }} - {{ row.naturaTipologia.etichetta }}
            </ng-template>
            <ng-template #columnIconaTemplate let-row="row" let-value="value">
                <img data-toggle="tooltip" data-placement="top"  [title]="row.disegnabile ? row.descrizione : row.icona ? row.icona : 'Generica'"
                 class="tipologia-intervento-icon"
                 [src]="getSrcForImageRow(row)"> 
            </ng-template>

            <ng-template #btnEditTemplate let-row="row" let-value="value">
                <a [ngClass]="{
        'disabled': loading
        }" class=" border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top"
                    title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
            </ng-template>
            <ng-template #btnDeleteTemplate let-row="row" let-value="value">
                <a *ngIf="!row.disegnabile" [ngClass]="{
        'disabled': loading
        }" class=" border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top"
                    title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
            </ng-template>
        </ngx-datatable-wrapper>
    </div>
</div>
<ng-template #templateModal>
    <div class="modal-header  text-center">
        <h4 class="modal-title pull-left">Modifica Tipologia Intervento</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
    </div>
</ng-template>