import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  ElementRef,
} from "@angular/core";
import { NgxDatatableWrapperComponent } from "src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component";
import { ToastService } from "src/app/services/toast.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { TipologiaSismaService } from "../../services/tipologia-sisma.service";

@Component({
  selector: "tipologie-sisma-component",
  templateUrl: "./tipologie-sisma.component.html",
  styleUrls: ["./tipologie-sisma.component.css"],
})
export class TipologieSismaComponent implements OnInit, OnDestroy {
  editForm: FormGroup;
  dtOptions: any = {};
  submitted: boolean = false;
  loading: boolean = false;
  open: boolean = false;
  dtTable = (dataTablesParameters: any, callback: any) => {
    this.currentService.getAllDt(dataTablesParameters).subscribe(resp => callback(resp))

  };
  config = {
    animated: true,
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-lg",
  };
  buttonLabel: string = "Inserisci";

  columns = [
    {
      name: "id",
      prop: "id",
      sortable: true,
      filtrable: true,
      flexGrow: 1,
      minWidth: 60,
      visible: false,
    },

    {
      name: "descrizione",
      prop: "descrizione",
      sortable: true,
      filtrable: true,

      flexGrow: 12,
      minWidth: 200,
      //,cellClass: 'mobile-hidden desktop-hidden'
      //,headerClass: 'mobile-hidden desktop-hidden'
    },

    {
      name: "modifica",
      prop: "id",
      sortable: false,
      filtrable: false,
      cellTemplate: "btnEditTemplate", // 'buttonsTemplate'
      flexGrow: 1,
      minWidth: 60,
    },
    {
      name: "elimina",
      prop: "id",
      sortable: false,
      filtrable: false,
      cellTemplate: "btnDeleteTemplate", // 'buttonsTemplate'
      flexGrow: 1,
      minWidth: 60,
    },
  ];

  modalRef: BsModalRef;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastService,
    private modalService: BsModalService,
    private currentService: TipologiaSismaService,
    private el: ElementRef
  ) {
    this.dtOptions = {
      rows: [],
      totalElements: 0,
      columns: this.columns,
      columnMode: ColumnMode.flex,
      headerHeight: "50",
      footerHeight: "50",
      rowHeight: "auto",
      externalSorting: false, // true
      loadingIndicator: "loanding",
      class: "bootstrap",
      summaryRow: true,
      summaryPosition: "'bottom'",
      externalPaging: false, // true
      count: 0,
      offset: 0,
      limit: 10,
      serverSide: true,
      scrollbarH: "true",
      ajax: this.dtTable
    };
  }

  @ViewChild("editModalTemplate", { static: true })
  public editModalTemplate: TemplateRef<any>;

  @ViewChild("btnEditTemplate", { static: true })
  public btnEditTemplate: TemplateRef<any>;

  @ViewChild("btnDeleteTemplate", { static: true })
  public btnDeleteTemplate: TemplateRef<any>;

  @ViewChild("dateTemplate", { static: true })
  public dateTemplate: TemplateRef<any>;

  @ViewChild("ngxDatatableWrapper")
  ngxDatatableWrapper: NgxDatatableWrapperComponent;

  templateList = [];

  ngOnInit() {
    window.scroll(0, 0);
    this.templateList = [
      { name: "btnDeleteTemplate", template: this.btnDeleteTemplate },
      { name: "btnEditTemplate", template: this.btnEditTemplate },
    ];
    this.editForm = this.initializeFormGroup();
  }

  initializeFormGroup() {
    return this.formBuilder.group({
      id: [null],
      descrizione: [null, [Validators.required, Validators.maxLength(255)]],
    });
  }

  ngOnDestroy() {}

  get f() {
    return this.editForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.editForm.updateValueAndValidity();
    let errors = false;
    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }
    if (this.editForm.invalid || errors) {
      console.log("invalid");
      let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
      for (let el in this.editForm.controls) {
        if (this.editForm.controls[el].invalid) {
          console.log(`set focus on ${el}`);
          if (this.editForm.controls[el] instanceof FormGroup) {
            for (let el1 in this.editForm.get(el)["controls"]) {
              if (this.editForm.get(el)["controls"][el1].invalid) {
                invalidElements = this.el.nativeElement.querySelectorAll(
                  `[formcontrolname="${el1}"]`
                );
                break;
              }
            }
          } else {
            invalidElements = this.el.nativeElement.querySelectorAll(
              `[formcontrolname="${el}"]`
            );
          }
          break;
        }
      }
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }

      return false;
    }

    let data = {
      id: this.editForm.value.id,
      descrizione: this.editForm.value.descrizione,
    };

    this.currentService.save(data).subscribe(
      (res) => {
        this.toastr.success("Tipologia sisma salvata con successo!");
        this.submitted = false;
        this.open = false;
        this.editForm.reset();

        this.buttonLabel = "Inserisci";
        if (!data.id) {
          //new row
          this.ngxDatatableWrapper.refresh();
        } else {
          this.modalRef.hide();
          this.ngxDatatableWrapper.refresh();
        }
      },
      (error) => {
        this.toastr.error("Errore di sistema");
      }
    );
  }

  onEdit(row) {
    this.submitted = false;
    console.log(row);
    this.buttonLabel = "Aggiorna";
    this.modalRef = this.modalService.show(this.editModalTemplate, this.config);
    this.editForm.setValue({
      id: row.id,
      descrizione: row.descrizione,
    });
  }

  onDelete(row) {
    console.log(row);
    if (
      !confirm(
        `Stai per eliminare la tipologia "${row.descrizione}", proseguire?`
      )
    )
      return;
    this.loading = true;
    this.currentService.delete(row.id).subscribe(
      (result) => {
        this.loading = false;
        this.ngxDatatableWrapper.refresh();
        this.toastr.success("Tipologia eliminata con successo", null, {
          timeOut: 2000,
          /*closeButton:true,*/ disableTimeOut: false,
        });
      },
      (error) => {
        this.loading = false;
        this.toastr.error(
          `Impossibile eliminare la tipologia: ${
            error.error.message
              ? error.error.message
              : error.error.error_description
              ? error.error.error_description
              : error.error
          }`,
          null,
          {
            timeOut: 2000,
            /*closeButton:true,*/ disableTimeOut: false,
          }
        );
        console.log("error");
      }
    );
  }

  closeModal() {
    this.submitted = false;
    this.open = false;
    this.editForm.reset();
    this.buttonLabel = "Inserisci";
    this.modalRef.hide();
  }
}
