<div class="col-lg-12 px-0">
    <div class=" fixedHeaderTable col-lg-12  table-responsive table-wrapper-scroll-y scrollbar-y  px-0" style="max-height: 600px;">
        <table class="table table-hover table-colore tableFixHead" #fasiTable>
            <thead class="">
                <th *ngIf="showSelectColumn" class="bg-white" style="max-width:40px;">&nbsp;</th>
                <th class="fixedmeY bg-white"><small class="bold">-&nbsp;progetto&nbsp;/&nbsp;fabbisogno <br>-&nbsp;interventi <br></small>
                </th>
                <th class="bg-white"  style="max-width:290px"><small class="bold">Descrizione</small></th>
                <th class="bg-white" style="max-width:120px"><small class="bold">aree&nbsp;gestionali</small></th>
                <th class="bg-white" style="max-width:90px"><small class="bold">classificazioni</small></th>                                
            </thead>
            <tbody>

                <!--progetti / interventi-->
                <ng-container *ngIf="currentObject && currentObject.progetti as progetti">
                    <ng-container *ngFor="let progetto of progetti;let i=index;">


                       

                            <ng-container >
                                <!--scrivo la riga del progetto-->
                                <tr class="">
                                    <td *ngIf="showSelectColumn" style="max-width:40px;"><input type="checkbox" (click)="addRemoveFromParent('tr-fase-intervento-' + progetto.codice,$event,progetto)"></td>
                                    <td  class="fixedme" style=" max-width: 290px;">
                                        <a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')" class="pointer font-weight-bold text-0083e0"
                                            data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.tr_collapse_' + i" [attr.data-target]="'.tr_collapse_' + i">
                                            <i class="fas fa-arrow-circle-down">
                          </i><small class="bold">&nbsp;{{ 'progetto/fabbisogno ' + progetto.codice }}</small>
                                        </a>
                                        <!--<div class="" style="font-size: 13px;"><small>{{progetto.titolo}}</small></div>-->
                                    </td>
                                    <td>
                                        <span popover="{{progetto.descrizione}}" placement="auto" triggers="mouseenter:mouseleave"><small>{{trimText(progetto.descrizione,100)}}</small></span>                                        
                                    </td>
                                    <td>
                                        <ul style="min-width:180px; " class="pl-2">
                                            <li *ngFor="let val of getAreeTematiche(progetto)"><small style="line-height: 1.1!important;">{{val}}</small></li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul style="min-width:180px; " class="pl-2">
                                            <li *ngFor="let val of getClassificazioni(progetto)"><small style="line-height: 1.1!important;" popover="{{val}}" placement="auto" triggers="mouseenter:mouseleave">{{trimText(val,100)}}</small></li>
                                        </ul>
                                    </td>
                           
                                </tr>

                                <!--fine scrivo riga del progetto-->
                            </ng-container>                            
                        
                        <ng-container *ngIf="progetto && progetto.interventi">
                            <ng-container *ngFor="let intervento of progetto.interventi;let j = index;">

                                    

                                        <!--scrivo la riga dell'intervento-->
                                        <tr class="background-table collapse {{'tr_collapse_' + i}} tr-fase-intervento-{{progetto.codice}}">
                                            <td *ngIf="showSelectColumn" style="max-width:40px;"><input type="checkbox" (click)="addRemoveSelection(intervento)"></td>
                                            <td  class="fixedme" style="max-width: 60px;">
                                                <span class="font-weight-bold "><small class="bold">intervento {{ intervento.codice}}</small></span>

                                                <!--<div class="" style="font-size: 13px; font-weight: normal;">
                                                    <small>{{ intervento.descrizione}}</small></div>-->
                                            </td>
                                            <td>
                                                <span popover="{{intervento.descrizione}}" placement="auto" triggers="mouseenter:mouseleave"><small>{{trimText(intervento.descrizione,100)}}</small></span>                                        
                                            </td>
                                            <td>
                                                <ul style="min-width:180px; " class="pl-2">
                                                    <li *ngFor="let val of getAreeTematiche(intervento)"><small style="line-height: 1.1!important;">{{val}}</small></li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul style="min-width:180px; " class="pl-2">
                                                    <li *ngFor="let val of getClassificazioni(intervento)"><small style="line-height: 1.1!important;" popover="{{val}}" placement="auto" triggers="mouseenter:mouseleave">{{trimText(val,100)}}</small></li>
                                                </ul>
                                            </td>
                                           
                                        </tr>

                                        <!--fine scrivo riga dell'intervento-->                                        
                                   
                                
                            </ng-container>
                        </ng-container>

                    </ng-container>


                </ng-container>

                <!--nessun risultato-->
                <ng-container *ngIf="!(currentObject && ((currentObject.progetti && currentObject.progetti.length>0) || (currentObject.finanziamenti && currentObject.finanziamenti.length>0)))">
                    <tr>
                        <td colspan="20">Nessun progetto / fabbisogno </td>
                    </tr>
                </ng-container>
            </tbody>




        </table>

    </div>
</div>