import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { AreaTematica } from "../models";

class DataTablesResponse {
  data: AreaTematica[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: "root",
})
export class AreeTematicheService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}AreeTematiche/`;
  private publicApiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob_public}AreeTematiche/`;
  constructor(private http: HttpClient) {}

  getAllDt(dataTablesParameters: any) {
    return this.http.post<DataTablesResponse>(
      `${this.apiUrl}alldt`,
      dataTablesParameters
    );
  }

  getAllDtOnlyActive(dataTablesParameters: any) {
    return this.http.post<DataTablesResponse>(
      `${this.apiUrl}allDtOnlyActive`,
      dataTablesParameters
    );
  }

  getAll() {
    return this.http.get<AreaTematica[]>(`${this.publicApiUrl}`);
  }

  save(s:AreaTematica){
    if (s.id)return this.update(s);
    return this.insert(s);
  }
  insert(s: AreaTematica) {
    return this.http.post<any>(`${this.apiUrl}`, s);
  }

  update(s: AreaTematica) {
    return this.http.put<any>(`${this.apiUrl}${s.id}`, s);
  }
  delete(id: number) {
    return this.http.delete<any>(`${this.apiUrl}${id}`);
  }
}
