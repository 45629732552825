import { OrderPipe } from "ngx-order-pipe";
import { CurrencyPipe } from "@angular/common";
import {
    Component,
    OnInit,
    ViewChild,
    AfterViewInit,
    ElementRef,
    TemplateRef,
    Input,
    SimpleChanges,
    EventEmitter,
    Output,
    OnDestroy
} from "@angular/core";
//import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ToastService } from "src/app/services/toast.service";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Progetto } from "../../models";
import {
    ProgettoService,
    InterventoService,
    AreeTematicheService,
    MotivazioneCancellazioneService
    ,StorageService
} from "../../services";
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { NgxDatatableWrapperComponent } from "src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component";
import { AuthService } from "src/app/services";
import { ProgettoComponent } from "../progetto/progetto.component";
import { DashboardComponent } from "src/app/modules/web-gis/components/dashboard/dashboard.component";
import { InterventiComponent } from "../interventi/interventi.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: "progetti-component",
    templateUrl: "./progetti.component.html",
    styleUrls: ["./progetti.component.css"],
})
export class ProgettiComponent implements OnInit, AfterViewInit,OnDestroy {
    @Input() id;
    dtOptions: any = {};
    visible = false;
    submitted: boolean = false;
    modalRef: BsModalRef;
    open: boolean = false;
    dtOptionsNgx: any;
    section: string = 'Interventi';
    @ViewChild("progettoContent", { static: true })
    progettoContent: ProgettoComponent;

    itemsComboFilterPnrr = [{ id: 'Si', descrizione: 'Si' }, { id: 'No', descrizione: 'No' }];

    @Input()
    filters: any = null;

    @Output()
    disableButtonEmit = new EventEmitter<boolean>();
    @Output()
    disableSearchEmitter = new EventEmitter<boolean>();

    constructor(
        private http: HttpClient,
        private el: ElementRef,
        private localeService: BsLocaleService,
        private toastr: ToastService,
        private modalService: BsModalService,
        private currentService: ProgettoService,
        private router: Router,
        private orderPipe: OrderPipe,
        private authService: AuthService,
        private interventoService: InterventoService,
        private route: ActivatedRoute
        , private formBuilder: FormBuilder
        , private motivazioneCancellazioneService: MotivazioneCancellazioneService
        ,private storageService: StorageService
    ) {
        this.deleteForm = this.initializeDeleteFormGroup();
        this.getMotivazioniCancellazione();
        this.localeService.use("it");
        this.getAreeTematiche();
        this.dtOptionsNgx = {
            rows: [],
            totalElements: 0,
            columns: this.columns,
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            ajax: this.loadData,
            scrollbarH: "true",
            sorts: [{ dir: "asc", prop: "codice" }],
            extraFilterTemplate:(this.isAdmin()|| this.isGr34()) ?this.extraFilterTemplate:null,
            id: 'ngx-datatable-progetti',

        };
    }
    ngOnDestroy(): void {
        if(this.elencoAreeSubscription)this.elencoAreeSubscription.unsubscribe();
    }
    loading = false;
    ngOnInit() {
        // const id: string = this.id;
        // const project = { id: id };
        // if (id) {
        //   this.mode = "insertedit";
        //   this.projectId = +id;
        //   this.onEdit(project);
        // }
        this.templateList = [
            { name: "btnGeoTemplate", template: this.btnGeoTemplate },
            { name: "btnCheckTemplate", template: this.btnCheckTemplate },
            {
                name: "disponibilitaFieldValueTemplate",
                template: this.disponibilitaFieldValueTemplate,
            },
            {
                name: "annualitaFieldValueTemplate",
                template: this.annualitaFieldValueTemplate,
            },
            {
                name: "direzioneFieldValueTemplate",
                template: this.direzioneFieldValueTemplate,
            },
            { name: "btnDeleteTemplate", template: this.btnDeleteTemplate },
            { name: "btnEditTemplate", template: this.btnEditTemplate },
            { name: "fieldNoteTemplate", template: this.fieldNoteTemplate },
            { name: "haInterventiTemplate", template: this.haInterventiTemplate },

        ];
        window.scroll(0, 0);
        this.dtOptionsNgx.extraFilterTemplate=(this.isAdmin()|| this.isGr34()) ?this.extraFilterTemplate:null;
        if (this.ngxDatatableWrapper) this.ngxDatatableWrapper.dtOptions = this.dtOptionsNgx;
        this.route.params.subscribe((params) => {
          const progettoId = params["id"];
            if (progettoId) {
              this.doEdit({ id: progettoId });
            }
        });


    }

    ngAfterViewInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
      switch (true) {
        case changes.filters !== undefined:
            setTimeout(() => {
                this.ngxDatatableWrapper.refresh();
            }, 0);
            break;
      }
    }

    isReadOnly(info: any): boolean {
        //controllo se l'utente può modificare il progetto
        //let user = this.authService.getCurrentUser();
        return info.isReadOnly || !this.canEdit();
    }


    doEdit(info: any): void {
        if (this.loading) return;
        //this.router.navigate([`inframob/progetti/progetto/${info.id}`]);
        this.mode = "insertedit";
        this.projectId = null;
        let that = this;
        //if (info.id)info.id =('' + info.id).replace('\?detail=1','');
        window.setTimeout(() => {
            if (that.route.snapshot.paramMap.get("id")) {
                that.progettoContent.stayOnDetailPage = that.route.snapshot.paramMap.get("id") && ('' + that.route.snapshot.paramMap.get("id")).includes('detail=1');
                this.router.navigate([], {
                    queryParams: {
                      'id': null
                    },
                    queryParamsHandling: 'merge'
                  })
                  that.route.snapshot.paramMap['params'] ={};
                that.progettoContent.forceOpenEditIntervento = true;



            }
            that.projectId = info.progettoId ? info.progettoId : ('' + info.id).replace('\?detail=1','');
        }, 10);
    }

    onEdit(info: any): void {
        if (this.loading) return;
        this.disableButtonEmit.emit(true);
        this.progettoContent.forceOpenEditIntervento = false;
        this.doEdit(info);
    }


    onDelete(info: Progetto): void {
        if (this.loading) return;
        this.openConfirmDeleteModal(info);
        return;
        if (
            !confirm(
                `Stai per eliminare il progetto / fabbisogno "${info.codice}", proseguire?`
            )
        )
            return;
        let id = info.progettoId ? info.progettoId : info.id;
        this.currentService.delete(id).subscribe(
            (x) => {
                this.ngxDatatableWrapper.deleteRow(info);
                this.toastr.success(
                    "Progetto / fabbisogno eliminato con successo",
                    null,
                    {
                        timeOut: 2000,
            /*closeButton:true,*/ disableTimeOut: false,
                    }
                );
            },

            (err) => {
                this.toastr.error(
                    `Errore durante l'eliminazione: non si dispone dei permessi per eliminare il progetto / fabbisogno selezionato`
                );
                this.loading = false;
            }
        );
    }

    loadData = (dataTablesParameters: any, callback: any) => {
        this.currentService.getAllDt(dataTablesParameters, this.filters).subscribe((resp) => {
            callback(resp);
            this.disableSearchEmitter.emit(false);
        });
    };

    openNewProjectPage() {
        console.log("openNewProjectPage");
        this.mode = "insertedit";
        this.projectId = -1;
        if (this.progettoContent) this.progettoContent.openAsNewProject();
        //this.router.navigate([`inframob/progetti/progetto`]);
    }

    projectId: number = null;

    datiGeneraliSaved($event) {
        console.log("ricarico la griglia");
        this.ngxDatatableWrapper.refresh();
    }

    gotoBackList($event) {
        this.disableButtonEmit.emit(false);
        this.mode = "list";
        this.ngxDatatableWrapper.refresh();
    }

    @ViewChild("btnEditTemplate", { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild("btnGeoTemplate", { static: true })
    public btnGeoTemplate: TemplateRef<any>;

    @ViewChild("btnCheckTemplate", { static: true })
    public btnCheckTemplate: TemplateRef<any>;

    @ViewChild("btnDeleteTemplate", { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    @ViewChild("direzioneFieldValueTemplate", { static: true })
    public direzioneFieldValueTemplate: TemplateRef<any>;

    @ViewChild("annualitaFieldValueTemplate", { static: true })
    public annualitaFieldValueTemplate: TemplateRef<any>;

    @ViewChild("disponibilitaFieldValueTemplate", { static: true })
    public disponibilitaFieldValueTemplate: TemplateRef<any>;

    @ViewChild("fieldNoteTemplate", { static: true })
    public fieldNoteTemplate: TemplateRef<any>;

    @ViewChild("extraFilterTemplate", { static: true })
    public extraFilterTemplate: TemplateRef<any>;

    @ViewChild("haInterventiTemplate", { static: true })
    public haInterventiTemplate: TemplateRef<any>;



    mode: string = "list";

    @ViewChild("ngxDatatableWrapper")
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    templateList = [];

    columns = [
        {
            name: "id",
            prop: "id",
            visible: false,
            sortable: true,
            filtrable: true,
            width: 10,
            resizeable: false,
            exportable: false,
        },

        {
            name: "anno",
            prop: "annoRiferimento",

            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 3,
            minWidth: 50,
            visible: false,
            exportable: false,
        },
        {
            name: "codice",
            prop: "codice",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 4,
            minWidth: 70,
        },
        {
            name: "descrizione",
            prop: "descrizione",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 7,
            minWidth: 130,
        },
        {
            name: "aree gestionali",
            prop: "direzione",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            cellTemplate: "direzioneFieldValueTemplate",
            flexGrow: 8,
            minWidth: 120,
        },
        {
            name: "valore<br />progetto " + ((this.isAdmin()|| this.isGr34())?"/ fabbisogno":""),
            prop: "importo",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            cellTemplate: "annualitaFieldValueTemplate",
            flexGrow: 9,
            minWidth: 155,
        },
        {
            name: "capacità residua<br />progetto " + ((this.isAdmin()|| this.isGr34())?"/ fabbisogno":""),
            prop: "disponibilita",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            cellTemplate: "disponibilitaFieldValueTemplate",
            flexGrow: 9,
            minWidth: 155,
        },
        {
            name: "stato",
            prop: "validationStatus",
            sortable: true,
            filtrable: false,
            cellTemplate: "btnCheckTemplate", // 'buttonsTemplate'
            //, width: 10
            resizeable: false,
            draggable: false,
            flexGrow: 3,
            minWidth: 50,
            exportFunction: (row) => {
                switch (row.validationStatus) {
                    case 'APPROVED':
                        return 'VALIDATO';
                    case 'REJECTED':
                        return 'RIFIUTATO';
                    case 'WAITING_FOR_APPROVAL_FIRST_LEVEL':
                    case 'WAITING_FOR_APPROVAL_SECOND_LEVEL':
                        return 'IN ATTESA DI VALIDAZIONE';
                    case 'IBRIDO':
                        return 'PARZIALMENTE FINANZIATO';
                    case 'TERMINATO':
                        return 'COMPLETATO';
                    case 'IDLE':
                        return 'DA VALIDARE';
                    default:
                        return row.validationStatus;
                }
            }
        },
        {
            name: "note",
            prop: "noteResponsabile",
            sortable: true,
            filtrable: false,
            cellTemplate: "fieldNoteTemplate",
            flexGrow: 3,
            minWidth: 45,
        },
        {
            name: "loc.",
            prop: "disegnato",
            sortable: true,
            filtrable: false,
            cellTemplate: "btnGeoTemplate", // 'buttonsTemplate'
            //, width: 10
            resizeable: false,
            draggable: false,
            flexGrow: 2,
            minWidth: 40,
            exportFunction: (row) => {
                return row.disegnato ? "PRESENTE" : "NON PRESENTE"
            }
        },


        {
            name: "interv.",
            prop: "numeroInterventi",
            sortable: false,
            filtrable: false,
            cellTemplate: "haInterventiTemplate", // 'buttonsTemplate'
            //, width: 10
            resizeable: false,
            draggable: false,
            flexGrow: 2,
            minWidth: 45,
            exportFunction: (row) => {
                return row.numeroInterventi && row.numeroInterventi > 0 ? "Si" : "No"
            }
        },

        {
            name: "modifica",
            prop: null,
            sortable: false,
            filtrable: false,
            cellTemplate: "btnEditTemplate", // 'buttonsTemplate'
            //, width: 10
            resizeable: false,
            draggable: false,
            flexGrow: 3,
            minWidth: 60,
            exportable: false,
        },
        {
            name: "elimina",
            prop: null,
            sortable: false,
            filtrable: false,
            cellTemplate: "btnDeleteTemplate", // 'buttonsTemplate'
            //, width: 10
            resizeable: false,
            draggable: false,
            flexGrow: 3,
            minWidth: 58,
            exportable: false,
        }
        , {
            name: 'rilevante PNRR'
            , prop: 'pnrrRilevante'
            ,visible:false
            , sortable: false
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 2
            , minWidth: 75
            , cellTemplate: 'booleanValueTemplate'
        }
    ];

    printDirezioneFieldValue(data) {
        //console.log('printDirezioneFieldValue');
        let str = "";
        let arr = [];
        if (data && data != "") {
            let areeDirezione = JSON.parse(data);
            for (let o in areeDirezione) {
                let str = areeDirezione[o]["descrizioneDirezione"];
                if (areeDirezione[o]["disabled"]) str = `(disattiva) ${str}`;
                arr.push(str);
            }
            str = arr.join("<br />");
        }
        return arr;
    }
    printValueAsCurrency(value) {
        if (!value) value = 0;
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = typeof value === "number" ? value : value.replace(",", ".").trim();
        const format = "1.2-2";
        const currency = "€";
        const currentLocale: string = "it";
        return new CurrencyPipe(currentLocale).transform(
            value,
            "EUR",
            currency,
            format,
            "it-IT"
        );
    }
    sortImporti(arr) {
        return this.orderPipe.transform(arr, "anno");
    }

    isAdmin() {
        return this.authService.isAdmin();
    }
    isDirectionalGroup() {
        return this.authService.isDirectionalGroup();
    }
    isAreaStaff() {
        return this.authService.isAreaStaff();
    }
    isExternal() {
        return this.authService.isExternal();
    }
    isViewer() {
        return this.authService.isViewer();
    }
    isGr34(){
        return this.authService.isGr34();
    }


    printBtnValidazioneTitle(value) {
        let result = '';
        switch (value) {
            case '':
            case 'IDLE':
            case 'REJECT':
                if (this.isAdmin())
                    result = 'clicca per validare';
                else
                    result = 'clicca per richiedere la validazione';
                break;
            case 'APPROVED':
                if (this.isAdmin()) result = 'clicca per svalidare';
                break;
            case "WAITING_FOR_APPROVAL_FIRST_LEVEL":
                if (!(this.isAreaStaff() || this.isExternal())) result = 'clicca per validare';
                break;
            case "WAITING_FOR_APPROVAL_SECOND_LEVEL":
                if (this.isAdmin())
                    result = 'clicca per validare';
                break;

        }
        return result;
    }

    onRichiediValidazione(row: any) {
        if (row.isReadOnly || row.numeroInterventi > 1) return;
        let status = row.validazione
            ? row.validazione.validationStatus
                ? row.validazione.validationStatus
                : 0
            : 0;
        if (!row.validazione && row.validationStatus) status = row.validationStatus;
        let msg = "";
        let action = false;
        //status =0 posso richiede approvazione
        switch (status.toString().toUpperCase()) {
            case "0":
            case "IDLE":
            case "REJECTED":
            case "3":
                if (this.isAdmin())
                    msg = `Stai per validare l'intervento ${row.codice} - ${row.descrizione}, proseguire?`;
                else
                    msg = `Stai per richiedere la validazione dell'intervento ${row.codice} - ${row.descrizione}, proseguire?`;
                action = true;
                break;
            case "1":
            case "APPROVED":
                if (this.isAdmin())
                    msg = `Stai per annullare la validazione dell'intervento ${row.codice} - ${row.descrizione}, proseguire?`;
                else return; //msg = `Stai per richiedere l'annullamento della validazione del finanziamento ${row.titolo}, proseguire?`;
                action = false;
                break;
            case "4":
            case "WAITING_FOR_APPROVAL_FIRST_LEVEL":
                if (this.isAreaStaff() || this.isExternal()) return;
                msg = `Stai per validare l'intervento ${row.codice} - ${row.descrizione}, proseguire?`;
                action = true;
                break;
            case 5:
            case "WAITING_FOR_APPROVAL_SECOND_LEVEL":
                if (!this.isAdmin()) return;
                msg = `Stai per validare l'intervento ${row.codice} - ${row.descrizione}, proseguire?`;
                action = true;
                break;
            /*case 6:
                      if (!this.isAdmin())return;
                      msg = `Stai per annullare la validazione del finanziamento ${row.titolo}, proseguire?`;
                      action=false;
                      break;*/
            default:
                return;
        }
        if (!confirm(msg)) return;
        this.interventoService
            .manageValidation(row.mainInterventoId, action)
            .subscribe(
                (result) => {
                    this.reloadData();
                    let msg = "";
                    if ((this.isExternal() || this.isAreaStaff()) && action)
                        msg = "Richiesta di validazione avvenuta con successo";
                    if ((this.isAdmin() || this.isDirectionalGroup()) && action)
                        msg = "Validazione avvenuta con successo";
                    if (this.isAdmin() && !action)
                        msg = "Validazione annullata con successo";
                    this.toastr.success(msg, null, {
                        timeOut: 2000,
                        disableTimeOut: false,
                    });
                    if (result.warningMessage && result.warningMessage != "") {
                        this.toastr.warning(result.warningMessage, null, {
                            timeOut: 10000,
                            disableTimeOut: false,
                        });
                    }
                },
                (error) => {
                    //this.toastr.error(`${JSON.stringify(error)}`);
                    this.toastr.error(
                        `Errore: ${error.error.message
                            ? error.error.message
                            : error.error.error_description
                                ? error.error.error_description
                                : error.error
                        }`,
                        null,
                        {
                            timeOut: 2000,
                            disableTimeOut: false,
                        }
                    );
                    console.log("error");
                }
            );
    }

    reloadData() {
        this.ngxDatatableWrapper.refresh();
    }

    closeModalEmitterSubscribe = null;
    onGeolocalizza(row) {
        if (row.nonLocalizzabile) return;
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xxl",
        };
        const initialState = {
            interventoId: row.mainInterventoId,

            isProjectMode: true,
            descrizione: row.descrizione,
        };
        this.modalRef = this.modalService.show(
            DashboardComponent,
            Object.assign({}, config, { initialState })
        );
        if (this.closeModalEmitterSubscribe)
            this.closeModalEmitterSubscribe.unsubscribe();
        this.closeModalEmitterSubscribe = this.modalRef.content.closeModalEmitter.subscribe(
            (data) => {
                this.reloadData();
            }
        );
    }

    areeTematicheCombo = [];
    private elencoAreeSubscription = null;
    getAreeTematiche() {

        this.elencoAreeSubscription = this.storageService.elencoAreeTematiche.subscribe(
            x=>{
                console.log(x);
                this.areeTematicheCombo = x?x:[];
            }
            );
    }

    filterAreaTematica($event) {
        let descr = $event ? $event.descrizione : "";
        if (descr.startsWith('(disattiva)')) {
            descr = descr.replace('(disattiva)', '');
        }
        this.ngxDatatableWrapper.originalColumns[4].search = {
            value: descr,
            regex: false,
        };
        this.ngxDatatableWrapper.dtInputParams.columns[4].search = {
            value: descr,
            regex: false,
        };
        this.ngxDatatableWrapper.refresh();
        //this.filterArea = $event ? $event.id : -1;
        //this.filterInterventions(false);
    }
    openInterventi(row) {

        /*   const config = {
             animated: true,
             backdrop: true,
             ignoreBackdropClick: true,
             keyboard: false,
             class: "modal-xl",
         };*/
        if (row.numeroInterventi <= 0) return;
        this.progettoContent.forceOpenEditIntervento = true;
        this.progettoContent.stayOnDetailPage = false;
        this.progettoContent.projectId = row.id;
        this.doEdit(row);
        /* this.projectIdXInterventi = row.mainInterventoId;
           this.modalRefTemplateInterventi = this.modalService.show(
             this.templateModalInterventi,
             config
         );*/

    }

    @ViewChild("tabInterventiContent", { static: true })
    tabInterventiContent: InterventiComponent;
    projectIdXInterventi = null;

    @ViewChild("templateModalInterventi", { static: true })
    templateModalInterventi: TemplateRef<any>;

    modalRefTemplateInterventi: BsModalRef;

    closeModalInterventi() {
        this.modalRefTemplateInterventi.hide();
    }


    //modale conferma cancellazione
    @ViewChild('modalConfirmDelete', { static: true })
    public modalConfirmDelete: TemplateRef<any>;

    modalRefCancellazione: BsModalRef;

    deleteRowTitle = '';
    deleteForm: FormGroup;
    deletedRow = null;

    initializeDeleteFormGroup(): FormGroup {
        let frm = this.formBuilder.group({
            motivazione: [null, [Validators.required]],
            txtMotivazioneCancellazione: [null],
        });
        return frm;
    }

    openConfirmDeleteModal(row) {
        this.submitted = false;
        this.deletedRow = row;
        this.deleteRowTitle = row.codice;
        this.deleteForm.reset();
        let config = {
            animated: true,
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-lg"
        };
        this.modalRefCancellazione = this.modalService.show(this.modalConfirmDelete, config);
    }

    closeConfirmDeleteModal() {
        this.modalRefCancellazione.hide();
    }

    doDelete() {
        this.submitted = true;
        this.deleteForm.updateValueAndValidity();
        for (let c in this.deleteForm.controls) {
            this.deleteForm.controls[c].updateValueAndValidity();
        }
        let errors = false;
        for (let c in this.deleteForm.controls) {
            errors = errors || this.deleteForm.controls[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.deleteForm.controls[c].errors)
            );
        }



        if (errors || this.deleteForm.invalid) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.deleteForm.controls) {
                if (this.deleteForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.deleteForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.deleteForm.get(el)["controls"]) {
                            if (this.deleteForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }
        //no errors
        let obj = this.deleteForm.value;
        if (obj.motivazione == 'Altro') obj.motivazione = obj.txtMotivazioneCancellazione;
        this.closeConfirmDeleteModal();
        let id = this.deletedRow.progettoId ? this.deletedRow.progettoId : this.deletedRow.id;
        this.currentService.delete(id, obj.motivazione).subscribe(
            (x) => {
                this.ngxDatatableWrapper.deleteRow(this.deletedRow);
                this.toastr.success(
                    "Progetto " + (this.isAdmin()|| this.isGr34())?"/ fabbisogno":"" + " eliminato con successo",
                    null,
                    {
                        timeOut: 2000,
                 /*closeButton:true,*/ disableTimeOut: false,
                    }
                );
            },

            (err) => {
                this.toastr.error(
                    `Errore durante l'eliminazione: non si dispone dei permessi per eliminare il progetto ` + (this.isAdmin()|| this.isGr34())?"/ fabbisogno":"" + ` selezionato`
                );
                this.loading = false;
            }
        );



    }

    motivazioniCancellazione = [];
    getMotivazioniCancellazione() {
        this.motivazioneCancellazioneService.getAll().subscribe(
            result => {
                result.push({ descrizione: 'Altro' });
                this.motivazioniCancellazione = result;
            }
        );
    }
    motivazioneCancellazioneSelected = null;
    setMotivazioneCancellazione($event) {
        this.motivazioneCancellazioneSelected = $event ? $event.descrizione : null;
        if (this.motivazioneCancellazioneSelected && this.motivazioneCancellazioneSelected == 'Altro') {
            this.deleteForm.controls.txtMotivazioneCancellazione.setValidators(Validators.required);
        }
        else {
            this.deleteForm.controls.txtMotivazioneCancellazione.clearValidators();
        }
    }


    canEdit() {
        return (this.enableSection(this.section, ['update']));
    }
    canInsert() {
        return (this.enableSection(this.section, ['insert']));
    }
    canDelete() {
        return (this.enableSection(this.section, ['delete']));
    }


    /**
* Controlla se l'utente può accedere o meno alla sezione.
* @param section il nome della sezione
* @param operations le operazioni da poter effettuare sulla sezione
* @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
* @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
* @returns true se il controllo è andato a buon fine, false altrimenti
*/
    enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
        return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
    }

    filterRilevantePnrr($event) {
        let descr = $event ? $event.descrizione : "";
        let id = $event ? $event.id : null;
        let value = id ? (id.toLowerCase() == 'si' ? true : false) : null;
        let col = this.ngxDatatableWrapper.originalColumns.find(x=>x.prop=='pnrrRilevante');

        if (col)
            col.search = {
                value: value,
                regex: false,
            };

        this.ngxDatatableWrapper.refresh();
    }

    getGotoOptions(){
        return {show:true,label:'Torna a elenco progetti ' + (this.isAdmin() || this.isGr34()?'/ fabbisogni':'') };
    }

}
