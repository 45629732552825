import { Injectable } from "@angular/core";
import { AppConfigService } from "./app-config.service";
import { HttpClient } from "@angular/common/http";
import { AccountInfo } from "../models/accountInfo";
import{RegisterUser} from "../models/registerUser";
import { UserSection } from "../models/userSection";

@Injectable({
  providedIn: "root"
})
export class UserService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.user}`;
  private protectedUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.user_protected}`;

  constructor(private http: HttpClient) {}

  checkUserExistsByFiscalCodeOrEmail(user: RegisterUser) {
    console.log("call: /users/exists");
    return this.http.post(`${this.protectedUrl}exists`, user);
  }

  registerUser(user: RegisterUser) {
    return this.http.post<RegisterUser>(`${this.protectedUrl}`, user);
  }

  changePassword(
    oldPassword: string,
    newPassword: string,
    newPswConfirm: string,
    username: string
  ) {
    const data: any = { oldPassword, newPassword, newPswConfirm, username };
    return this.http.put<any>(`${this.protectedUrl}changePassword`, data);
  }

  getInfo(username: string) {
    console.log("getInfo");
    if (username)
      return this.http.get<AccountInfo>(
        `${this.protectedUrl}userInfo/${username}`
      );
    return this.http.get<AccountInfo>(`${this.protectedUrl}userInfo`);
  }

  getInfoByIamToken(token) {
    console.log("getInfoByIamToken");
    return this.http.get<AccountInfo>(`${this.protectedUrl}userInfoByToken?token=${token}`);
  }

  deleteUser(username: string) {
    return this.http.delete<boolean>(`${this.protectedUrl}${username}`);
  }

  editAccount(user: AccountInfo) {
    return this.http.put<boolean>(`${this.protectedUrl}${user.id}`, user);
  }

  toggleUserStatus(username: string) {
    return this.http.put<boolean>(`${this.protectedUrl}${username}/toggleUserEnabledStatus`,null);
  }

  toggleNotificationsDisabled(username: string) {
    return this.http.put<boolean>(`${this.protectedUrl}${username}/toggleNotificationsDisabled`,null);
  }

  getDocument(username: string, filename: string) {
    return this.http.get(`${this.protectedUrl}docs/${username}/${filename}`, {
      observe: "response",
      responseType: "blob"
    });
  }

  sendDeleteLink() {
    return this.http.get<boolean>(`${this.protectedUrl}deleteMe`);
  }

  deleteAccount(token: string) {
    return this.http.get<boolean>(`${this.apiUrl}deleteAccount/${token}`);
  }

  getAvaibleRoles() {
    return this.http.get<string[]>(`${this.apiUrl}availableRoles`);
  }

  sendMessageToTheUserByEmail(userId:number,obj:any) {
    return this.http.post(`${this.protectedUrl}${userId}/sendMessageByEmail`,obj);
  }

  getAllSections() {
    return this.http.get<any[]>(`${this.protectedUrl}sections`);
  }

  saveNewUserPermission(us: UserSection) {
    return this.http.post<UserSection>(`${this.protectedUrl}userPermissions/save`, us);
  }

  getAllPermissionsById(dataTablesParameters: any, userId: number) {
    return this.http
      .post<any>(
        `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.user_protected}userPermissions/dt?id=${userId}`,
        dataTablesParameters
      )
  }

  getAllPermissionsByUsername(username: string) {
    return this.http
      .get<any>(
        `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.user_protected}userPermissions?username=${username}`
      )
  }

  deleteUserPermissionsByUserIdAndSectionId(userId: number, sectionId: number) {
    return this.http
      .delete<any>(
        `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.user_protected}userPermissions/delete?user_id=${userId}&section_id=${sectionId}`
      )
  }

  updateUserPermissionsByUserId(userId: number, value: UserSection) {
    value.idUser = value['userId']; value.idSection = value['sectionId'];
    delete value['id'];
    return this.http
      .put<any>(
        `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.user_protected}userPermissions/update?user_id=${userId}`,
        value
      )
  }

  resetPassword(username:string){
    const url = `${this.apiUrl}${username}/resetPassword`;
    console.log(url);
    return this.http.get(url);
  }

}
