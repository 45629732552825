import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NaturaTipologieService } from '../../services/natura-tipologie.service';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastService } from 'src/app/services/toast.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { IsDirtyHelper } from 'src/app/helpers/is-dirty.helper';

@Component({
  selector: 'app-gestione-natura-tipologie',
  templateUrl: './gestione-natura-tipologie.component.html',
  styleUrls: ['./gestione-natura-tipologie.component.css']
})
export class GestioneNaturaTipologieComponent implements OnInit {

  dtOptions: any = {};
  visible = false;

  addNaturaTipologiaForm: FormGroup;

  submitted: boolean = false;
  modalRef: BsModalRef;
  open: boolean = false;

  @ViewChild('templateEditAdd', { static: true })
  public templateEditAdd: TemplateRef<any>;
  @ViewChild('templateModal', { static: true })
  public templateModal: TemplateRef<any>;

  @ViewChild('btnEditTemplate', { static: true })
  public btnEditTemplate: TemplateRef<any>;

  @ViewChild('btnDeleteTemplate', { static: true })
  public btnDeleteTemplate: TemplateRef<any>;


  @ViewChild('ngxDatatableWrapper')
  ngxDatatableWrapper: NgxDatatableWrapperComponent;

  columns = [
    {
      name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
    }
    , {
      name: 'codice'
      , prop: 'codice'
      , sortable: true
      , filtrable: true
      , flexGrow: 7
      , minWidth: 100
    },
    {
      name: 'etichetta'
      , prop: 'etichetta'
      , sortable: true
      , filtrable: true
      , flexGrow: 7
      , minWidth: 100
    },
    {
      name: 'note'
      , prop: 'note'
      , sortable: true
      , filtrable: true
      , flexGrow: 7
      , minWidth: 100
    }
    , {
      name: 'modifica'
      , prop: 'id'
      , sortable: false
      , filtrable: false
      , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
      , flexGrow: 1
      , minWidth: 60

    }
    , {
      name: 'elimina'
      , prop: 'id'
      , sortable: false
      , filtrable: false
      , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
      , flexGrow: 1
      , minWidth: 60
    }
  ];

  templateList = [];
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private el: ElementRef,
    private localeService: BsLocaleService,
    private toastr: ToastService,
    private modalService: BsModalService,
    private currentService: NaturaTipologieService,
    private isDirtyHelper: IsDirtyHelper) {
    this.localeService.use("it");
    this.dtOptions = {
      rows: []
      , totalElements: 0
      , columns: this.columns
      , columnMode: ColumnMode.flex
      , headerHeight: "50"
      , footerHeight: "50"
      , rowHeight: "auto"
      , externalSorting: false// true
      , loadingIndicator: 'loanding'
      , class: "bootstrap"
      , summaryRow: true
      , summaryPosition: "'bottom'"
      , externalPaging: false// true
      , count: 0
      , offset: 0
      , limit: 10
      , serverSide: false
      , scrollbarH: "true"
    };
  }

  ngOnInit(): void {
    this.templateList = [{ name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }, { name: 'btnEditTemplate', template: this.btnEditTemplate }];
    window.scroll(0, 0);

    this.addNaturaTipologiaForm = this.initializeFormGroup();        

    this.currentService.getNaturaTipologie().subscribe(
      (result) => {
        console.log(result)
        this.ngxDatatableWrapper.dtOptions = this.dtOptions;
        this.ngxDatatableWrapper.setInitialData(result);
      },
      (error) => console.log(error)
    );
  }

  setIsDirty(value: boolean) {
    this.isDirtyHelper.isDirty = value;
  }

  initializeFormGroup() {
        let frm =  this.formBuilder.group({
            id:[null],
            codice: [null, [Validators.required, Validators.maxLength(255)]],
            etichetta: [null, [Validators.required, Validators.maxLength(255)]],
            note: [null]          
        });
        frm.valueChanges.subscribe(val =>
          this.setIsDirty(frm.dirty)
        );
        return frm;
    }

    get f() {
        return this.addNaturaTipologiaForm.controls;
    }

    onSubmit() {
      this.submitted = true;
      this.addNaturaTipologiaForm.updateValueAndValidity();
      let errors = false;
      for (let c in this.f) {
          errors = errors || this.f[c].errors !== null;
          console.log(
              "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
          );
      }
      if (this.addNaturaTipologiaForm.invalid || errors) {
          console.log("invalid");
          let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
          for (let el in this.addNaturaTipologiaForm.controls) {
              if (this.addNaturaTipologiaForm.controls[el].invalid) {
                  console.log(`set focus on ${el}`);
                  if (this.addNaturaTipologiaForm.controls[el] instanceof FormGroup) {
                      for (let el1 in this.addNaturaTipologiaForm.get(el)["controls"]) {
                          if (this.addNaturaTipologiaForm.get(el)["controls"][el1].invalid) {
                              invalidElements = this.el.nativeElement.querySelectorAll(
                                  `[formcontrolname="${el1}"]`
                              );
                              break;
                          }
                      }
                  } else {
                      invalidElements = this.el.nativeElement.querySelectorAll(
                          `[formcontrolname="${el}"]`
                      );
                  }
                  break;
              }
          }
          if (invalidElements.length > 0) {
              invalidElements[0].focus();
          }

          return false;
      }

      let obj = this.addNaturaTipologiaForm.value;
      let subFunctionOk = (res)=>{
          this.toastr.success("Natura di tipologia salvata con successo!");
          this.submitted = false;
          this.open = false;
          this.addNaturaTipologiaForm.reset()
          this.setIsDirty(false)
          this.buttonLabel="Inserisci";
          if (!obj.id){
              this.ngxDatatableWrapper.addRow(res);                        
          }
          else{
              this.modalRef.hide();
              this.ngxDatatableWrapper.updateRow(obj);                                                
          }
      };
      let subFunctionKo = (error)=>{this.toastr.error(`Impossibile salvare/aggiornare la natura tipologia: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`);};
      this.currentService.save(obj).subscribe(subFunctionOk,subFunctionKo);
      
  }


  buttonLabel:string="Inserisci";
  loading:boolean=false;
  config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: "modal-lg"
  };
  onEdit(row) {
      this.submitted = false;
      this.buttonLabel = "Aggiorna";
      this.modalRef = this.modalService.show(this.templateModal, this.config);
      this.addNaturaTipologiaForm.setValue({
          id: row.id,
          codice:row.codice,   
          etichetta:row.etichetta,
          note:row.note
      });
  }
  onDelete(row) {
      console.log(row);
      if (!confirm(`Stai per eliminare la natura di tipologia, proseguire?`)) return;
      this.loading = true;
      
      let subFunctionOk = 
          (result) =>
       {
          this.loading = false;
          this.ngxDatatableWrapper.deleteRow(row);
          this.setIsDirty(false)
          this.toastr.success('Natura tipologia eliminata con successo', null, {
              timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
          });
      };
      let subFunctionKo =
      ( error) => {
          this.loading = false;
          this.toastr.error(`Impossibile eliminare la natura tipologia: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
              timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
          });
          console.log('error');
      };

      this.currentService.delete(row.id).subscribe(subFunctionOk,subFunctionKo);
      
  }
  closeModal(){
      this.modalRef.hide();
      //this.addNaturaTipologiaForm.reset();
      this.buttonLabel="Inserisci";
  }  



}
