export class Province {
    id: string;
    code: string;
    name: string;
    acronym: string;
    isCittametropolitana: boolean;
    fromDate: string;
    toDate: string;
    idRegion: number;
  }
  