import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router, UrlSerializer } from "@angular/router";
import * as olFormat from "ol/format";
import * as olLayers from "ol/layer";
import Map from "ol/Map";
import * as olSource from "ol/source";
import OSM from "ol/source/OSM";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import * as olStyle from "ol/style";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { AppConfigService } from "src/app/services/app-config.service";
import { Intervention } from "../models/interventions";
import * as turf from "@turf/turf";
import { MyStreetFeatures } from "../models/my-street-feature";
import * as moment from "moment";

import { Vector as VectorSource } from "ol/source";
import GeoJSON from "ol/format/GeoJSON.js";
import Cluster from 'ol/source/Cluster';
@Injectable({
  providedIn: "root",
})
export class GeoService {
  wmsUrl: string = `${AppConfigService.env.geo_server_url}${AppConfigService.env.geo_store}wms`;
  wfsUrl: string = `${AppConfigService.env.geo_server_url}${AppConfigService.env.geo_store}ows`;
  backendUrl: string = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.interventions}`;
  listFeature: MyStreetFeatures[] = [];
  layers: any[];
  loadedLayersName: any[] = [];
  //layerLoaded: string;
  layerLoadedTracker = new Subject<string>();
  vectorStyles = [];
  projectStyle;
  vectorLayerNames: string[] = [];
  vectorLayerSRS: string;
  /*listFeatureTracker = new BehaviorSubject<MyStreetFeatures[]>(
      this.listFeature
    );
  */
  listFeatureTracker = new Subject<MyStreetFeatures[]>();
  layerReadyTracker = new Subject<any>();
  savedTracker = new Subject<any>();

  sourceSRS = "EPSG:3857";
  destSRS = "EPSG:4326";

  ICON_LAYER_TITLE = "Icone";
  ICON_TEMP_LAYER_TITLE = "IconeTemp";
  ICON_VIEW_LAYER_TITLE = "Icone View";
  PROJECT_LAYER_TITLE = "Progetto/Fabbisogno";
  VIEWER_LAYER_TITLE = "Visualizzazione";
  INTERVENTIONS_LAYER_TITLE = "Interventi";
  DRAW_LAYER_TITLE = "Disegni";

  constructor(
    private http: HttpClient,
    private router: Router,
    private serializer: UrlSerializer
  ) {}

  getlistFeature() {
    return this.listFeatureTracker.asObservable();
  }

  getReadyTracker() {
    return this.layerReadyTracker.asObservable();
  }
  getSavedTracker() {
    return this.savedTracker.asObservable();
  }

  setVectorStyles(styles: any[]) {
    this.vectorStyles = styles;
  }

  setProjectStyle(style: any) {
    this.projectStyle = style;
  }

  setvectorLayerNames(names: string[]) {
    this.vectorLayerNames = names;
  }

  setVectorLayerSRS(srs: string) {
    this.vectorLayerSRS = srs;
  }

  getInterventiLoaded() {
    return this.layerLoadedTracker.asObservable();
  }

  getOSMLayer() {
    return of(
      new TileLayer({
        zIndex: -100,
        source: new OSM(),
      })
    );
  }

  getCartoDbLayer() {
    return new TileLayer({
      title: "basemap",
      zIndex: -100,
      source: new olSource.XYZ({
        url: "http://{a-c}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
        crossOrigin: "*",
      }),
    });
  }

  getStamenLayer(type: string) {
    //type: toner, terrain, watercolor
    return new TileLayer({
      title: "basemap",
      zIndex: -100,
      source: new olSource.XYZ({
        url: `http://tile.stamen.com/${type}/{z}/{x}/{y}.png`,
        crossOrigin: "*",
      }),
    });
  }

  getGoogleLayer(type: string) {
    let param = "m";
    if (type) {
      switch (type) {
        case "street":
          param = "m";
          break;
        case "sat":
          param = "s";
          break;
        case "terrain":
          param = "p";
          break;
        case "black":
          param = "t";
          break;
        case "hybrid":
          param = "y";
          break;
      }
    }
    return new TileLayer({
      title: "basemap",
      zIndex: -100,
      source: new olSource.XYZ({
        url: `http://mt0.google.com/vt/lyrs=${param}&hl=en&x={x}&y={y}&z={z}`,
        crossOrigin: "*",
      }),
    });
  }

  createLayer(
    map: any,
    title: string,
    minZoom: number,
    visible: boolean,
    layerName: string,
    srs: string,
    style: any,
    cqlFilter: string,
    isForDrawing: boolean
  ) {
    this.getGeoJson(layerName, srs, undefined, cqlFilter).subscribe(
      (geoJson) => {
        const newLayer = this.createLayerFromGeoJson(
          map,
          geoJson,
          title,
          style,
          minZoom,
          visible,
          isForDrawing
        );
        if (!this.loadedLayersName.includes(title))
          this.loadedLayersName.push(title);
        this.layerLoadedTracker.next(title);
        // if (isForDrawing) {
        //   this.layerReadyTracker.next(title);
        // }
      }
    );
  }

  isLoaded(title: string) {
    if (this.loadedLayersName.includes(title)) return true;
    return false;
  }

  getGeoJson(layerName: string, srs: string, style: any, cqlFilter: string) {
    return this.http.get<any>(
      `${this.wfsUrl}/?service=WFS&version=1.0.0&request=GetFeature&typeName=${layerName}&srsName=${srs}&outputFormat=application%2Fjson&${cqlFilter}`
    );
  }

  createLayerFromGeoJson(
    map: any,
    geoJSON: any,
    title: string,
    style: any,
    minZoom: number,
    visible: boolean,
    isForDrawing: boolean
  ) {
    const geoJson = geoJSON;
    const features = new olFormat.GeoJSON({
      featureProjection: "EPSG:3857",
    }).readFeatures(geoJson);
    const source = new olSource.Vector({
      features: features,
      crossOrigin: "anonymous",
    });
    const layer = new olLayers.Vector({
      minZoom: minZoom ? minZoom : 4, // visible at zoom levels above 14
      title: title,
      source: source,
      visible: visible,
      style: style,
      //zIndex: 50,
    });
    map.addLayer(layer);
    if (isForDrawing) this.layerReadyTracker.next(layer);
    if (!visible) this.hideLayerByTitle(map, title);
    if (!this.loadedLayersName.includes(title))
      this.loadedLayersName.push(title);
    return layer;
  }

  getVectorLayer(
    layerName: string,
    layerTitle: string,
    visible: boolean,
    cqlFilter: string,
    projection: any,
    layerStyle: olStyle
  ) {
    const encodedPareams = this.router.createUrlTree([], {
      queryParams: {
        service: "WFS",
        version: "1.0.0",
        request: "GetFeature",
        typeName: layerName,
        srsName: "EPSG:4326",
        cql_filter: cqlFilter,
        outputFormat: "application/json",
      },
    });
    //console.log(this.wfsUrl + this.serializer.serialize(encodedPareams));
    const source = new olSource.Vector({
      url: this.wfsUrl + this.serializer.serialize(encodedPareams),
      crossOrigin: "*",
      format: new olFormat.GeoJSON(),
    });
    return of(
      new olLayers.Vector({
        //extent: [-13884991, 2870341, -7455066, 6338219],
        title: layerTitle,
        source: source,
        visible: visible != null ? visible : true,
        projection: projection,
        style: layerStyle,
      })
    );
  }

  getDrawingLayer(
    colorLine: string,
    colorCircleBorder: string,
    colorCircle: string,
    radiusCircleBorder: number,
    widthLine: number,
    widthCircleBorder: number,
    title: string
  ) {
    let vectorSource = new olSource.Vector({
      crossOrigin: "*",
      format: new olFormat.GeoJSON(),
    });
    return new olLayers.Vector({
      title: title,
      source: vectorSource,
      //features: features ? features : null,
      style: [
        new olStyle.Style({
          stroke: new olStyle.Stroke({
            color: colorLine,
            width: widthLine,
          }),
          fill: new olStyle.Fill({
            color: colorLine,
          }),
        }),
        new olStyle.Style({
          image: new olStyle.Circle({
            radius: radiusCircleBorder,
            stroke: new olStyle.Stroke({
              color: colorCircleBorder,
              width: widthCircleBorder,
            }),
            fill: new olStyle.Fill({
              color: colorCircle,
            }),
          }),
        }),
      ],
    });
  }

  getLayerByTitle(map: any, title: string) {
    let mapLayers = map.getLayers().array_;

    for (let index = 0; index < mapLayers.length; index++) {
      if (mapLayers[index].get("title") === title) return mapLayers[index];
    }
  }

  getTileWMS(layerName: string, title: string): Observable<olLayers.Tile> {
    return of(
      new olLayers.Tile({
        //extent: [-13884991, 2870341, -7455066, 6338219],
        title: title,
        source: new olSource.TileWMS({
          title: title,
          url: this.wmsUrl,
          params: { LAYERS: layerName, TILED: true },
          serverType: "geoserver",
          crossOrigin: "*",
          transition: 0,
        }),
      })
    );
  }

  showAllLayers(map: any) {
    map.getLayers().forEach((l) => {
      l.setVisible(true);
    });
  }

  hideLayerByTitle(map: any, title: string) {
    if (this.isLoaded(title)) {
      map.getLayers().forEach((l) => {
        if (l.get("title") === title) l.setVisible(false);
      });
    }
  }

  showLayerByTitle(map: any, title: string) {
    console.log(`into showing...`);
    map.getLayers().forEach((l) => {
      if (l.get("title") === title) {
        console.log(`showing: ${title}`);
        this.layerReadyTracker.next(l);
        l.setVisible(true);
      }
    });
  }

  setZindexToLayer(map: any, title: string, zindex: number) {
    map.getLayers().forEach((l) => {
      if (l.get("title") === title) l.setZIndex(zindex);
    });
  }

  hideAllLayerExcept(map: any, titleLayerToShow: string) {
    map.getLayers().forEach((layer) => {
      console.log(layer.get("title"));
      //OSM layer has no title
      if (
        layer instanceof VectorLayer &&
        layer.get("title") !== titleLayerToShow
      )
        layer.setVisible(false);
    });
  }

  showAllLayerExcept(map: any, titleLayerToHide: string) {
    map.getLayers().forEach((layer) => {
      console.log(layer.get("title"));
      //OSM layer has no title
      if (
        layer instanceof VectorLayer &&
        layer.get("title") === titleLayerToHide
      )
        layer.setVisible(false);
      else layer.setVisible(true);
    });
  }

  removeLayerByTitle(map: Map, title: string) {
    let layers = map.getLayers();
    layers.forEach((l) => {
      if (l && l.get("title") === title) {
        map.removeLayer(l);
      }
    });
  }

  serializeCqlFilter(filter: string) {
    const encodedFilter = this.router.createUrlTree([], {
      queryParams: {
        cql_filter: filter,
      },
    });
    return this.serializer.serialize(encodedFilter).substring(2);
  }

  addCqlFilter(filter: string, layer: any) {
    let encodedFilter = filter ? this.serializeCqlFilter(filter) : "";

    let prevUrl: string = layer.getSource().url_;
    if (prevUrl.includes("cql_filter")) {
      let url = new URL(`${layer.getSource().url_}`);
      let params = new URLSearchParams(url.search.slice(1));
      params.delete("cql_filter");
      url.search = params.toString();
      prevUrl = url.href;
    }

    let newUrl = `${prevUrl}&${encodedFilter}`;
    let filteredSource = new olSource.Vector({
      url: newUrl,
      crossOrigin: "*",
      format: new olFormat.GeoJSON(),
    });
    console.log(newUrl);
    layer.getSource().url_ = newUrl;
    layer.getSource().refresh();
  }

  chainFiltersInOR(filters: string[]): string {
    let filter: string = filters[0];
    for (let index = 1; index < filters.length; index++) {
      filter += ` OR ${filters[index]}`;
    }
    return filter;
  }

  saveAndReprojectInterventions(
    map: Map,
    drawedFeatures: any,
    srsSrc: string,
    srsDest: string,
    coordinate_id: string,
    iconLayerTitle,
    tempIconLayerTitle,
    iconStyle,
    cql_filter,
    listElement: MyStreetFeatures[]
  ) {
    const clonedFeature = [];
    drawedFeatures.forEach((f) => {
      clonedFeature.push(f.clone());
    });
    //OSM srs
    const src = srsSrc;
    //DEAFULT srs
    const dest = srsDest;
    const interventions: Intervention[] = [];
    clonedFeature.forEach((f) => {
      const polygon = f.getGeometry().transform(src, dest);
      const ol3Geom = polygon;
      const format = new olFormat.WKT();
      const wktRepresenation = format.writeGeometry(ol3Geom);
      console.log("WKT: " + wktRepresenation);
      const intervention: Intervention = {
        geometria: wktRepresenation,
        intervento_id: coordinate_id,
        type: f.values_["type"],
        date: null,
      };
      interventions.push(intervention);
    });

    console.log("interventi da salvare=" + interventions.length);
    this.saveGeometries(interventions).subscribe((res) => {
      if (res) {
        setTimeout(() => {
          this.getGeoJson(
            "inframob:coordinate_intervento",
            "EPSG:4326",
            null,
            cql_filter
          ).subscribe((res) => {
            console.log("json caricato");
            console.log(res);

            const geoJson = res;
            const features = new olFormat.GeoJSON({
              featureProjection: "EPSG:3857",
            }).readFeatures(geoJson);
            const source = new olSource.Vector({
              features: features,
              crossOrigin: "*",
            });
            console.log(
              "nuovo layer interventi ha features =" + features.length
            );
            const intervationLayer = this.getLayerByTitle(map, "Interventi");
            intervationLayer.setSource(source);
            const array = [];
            intervationLayer
              .getSource()
              .getFeatures()
              .forEach((f) => {
                const myFeature: MyStreetFeatures = {
                  feature: f,
                  saved: true,
                  date: moment(new Date()),
                };

                array.push(myFeature);
              });
            this.deleteLayer(map, this.ICON_LAYER_TITLE);
            this.deleteLayer(map, this.ICON_TEMP_LAYER_TITLE);
            this.deleteLayer(map, this.VIEWER_LAYER_TITLE);
            this.deleteLayer(map, this.ICON_VIEW_LAYER_TITLE);
            this.createIconLayerBylayer(
              map,
              this.INTERVENTIONS_LAYER_TITLE,
              this.ICON_LAYER_TITLE,
              this.iconSavedLayerStyleFunction,
              false
            );
            //TODO commentato per clona
            //if ( true /*interventions.length < 1*/) {
            listElement = array; //[...array];

            this.listFeatureTracker.next(listElement);
            //}
            this.showLayerByTitle(map, this.ICON_LAYER_TITLE);
            this.setZindexToLayer(map, this.ICON_LAYER_TITLE, 101);
          });
        }, 500); //timeout prima di interrogare geoserver
      }
    });
  }

  iconSavedLayerStyleFunction(feature, resolution) {
    if (feature) {
      const type = feature.get("type");
      let imageUrl;

      switch (type) {
        case "road":
          imageUrl = "assets/img/road.png";
          break;
        case "rail":
          imageUrl = "assets/img/rail.png";
          break;
        case "metro":
          imageUrl = "assets/img/metro.png";
          break;
        case "bike":
          imageUrl = "assets/img/bike.png";
          break;
        case "parkland":
          imageUrl = "assets/img/parchi.png";
          break;
        case "emobility":
          imageUrl = "assets/img/emobility.png";
          break;
        case "skilift":
          imageUrl = "assets/img/funivia.png";
          break;
        case "points":
          imageUrl = "assets/img/building.png";
          break;
        case "port":
          imageUrl = "assets/img/porto.png";
          break;
        case "metrostop":
          imageUrl = "assets/img/metrostation.png";
          break;
        case "station":
          imageUrl = "assets/img/station.png";
          break;
        case "park":
          imageUrl = "assets/img/parcheggi.png";
          break;
        case "nodes":
          imageUrl = "assets/img/nodi.png";
          break;
        case "asl":
          imageUrl = "assets/img/asl.png";
          break;
        case "plants":
          imageUrl = "assets/img/plants.png";
          break;
        case "networks_satellites":
          imageUrl = "assets/img/networks_satellites.png";
          break;
        case "bonifiche_territorio_punti":
          imageUrl = "assets/img/bonifiche_territorio_punti.png";
          break;
        case "bonifiche_territorio_linee":
          imageUrl = "assets/img/bonifiche_territorio_linee.png";
          break;

        case "aeroporti":
          imageUrl = "assets/img/aeroporti.png";
          break;
        case "musei_cultura":
          imageUrl = "assets/img/musei_cultura.png";
          break;
        case "luoghi_di_culto":
          imageUrl = "assets/img/luoghi_di_culto.png";
          break;
        case "hospitals":
          imageUrl = "assets/img/hospital.png";
          break;
        case "cimiteri":
          imageUrl = "assets/img/cimiteri.png";
          break;
        case "impianti_sportivi":
          imageUrl = "assets/img/impianti_sportivi.png";
          break;
        case "interventi_sul_sociale":
          imageUrl = "assets/img/interventi_sul_sociale.png";
          break;
        case "stazioni_autolinee":
          imageUrl = "assets/img/stazioni_autolinee.png";
          break;
        case "impianti_energie_rinnovabili":
          imageUrl = "assets/img/impianti_energie_rinnovabili.png";
          break;
        case "opere_urbanizzazione":
            imageUrl = "assets/img/opere_urbanizzazione.png";
            break;

        default:
          imageUrl = "assets/img/road.png";
      }

      let style = new olStyle.Style({
        image: new olStyle.Icon({
          src: imageUrl,
        }),
      });
      return style;
    }
  }

  deleteLayer(map, title) {
    //console
    this.removeLayerByTitle(map, title);
    const index = this.loadedLayersName.indexOf(title);
    if (index >= 0) {
      this.loadedLayersName.splice(index, 1);
    }
  }

  saveGeometry(intervention: Intervention): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.backendUrl}interventionscoordinate`,
      intervention
    );
  }

  saveGeometries(interventions: Intervention[]): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.backendUrl}interventionscoordinate`,
      interventions
    );
  }

  deleteGeometries(gids: number[], interventionId: number) {
    return this.http.post<boolean>(
      `${this.backendUrl}${interventionId}/geolocalizzazione`,
      gids
    );
  }

  setLayerStyle(vectorLayerTitles, title: string) {
    const index = vectorLayerTitles.indexOf(title);
    let styleIndex: number;
    switch (true) {
      case index <= 3: //ferrovie style
        styleIndex = 0;
        break;
      case index >= 4 && index <= 15: //starde style
        styleIndex = 1;
        break;
      case index > 15 && index < 40: //edifici style
        styleIndex = 2;
        break;
      case index == 40: //interventi style
        styleIndex = 3;
        break;
      case index > 40: //progetto style
        styleIndex = -1;
        break;
    }
    let currentStyle =
      styleIndex >= 0 ? this.vectorStyles[styleIndex] : this.projectStyle;
    return currentStyle;
  }

  showLayer(
    map: any,
    vectorLayerTitles: string[],
    title: string,
    isForDrawing
  ) {
    if (!this.isLoaded(title)) {
      const index = vectorLayerTitles.indexOf(title);
      let currentStyle = this.setLayerStyle(vectorLayerTitles, title);
      this.createLayer(
        map,
        title,
        null,
        true,
        this.vectorLayerNames[index],
        this.vectorLayerSRS,
        currentStyle,
        null,
        isForDrawing
      );
    } else {
      this.showLayerByTitle(map, title);
    }
  }

  hideLayersExcept(
    map: any,
    vectorLayerTitles: string[],
    titleLayerToShow: string,
    isProject: boolean
  ) {
    vectorLayerTitles.forEach((layerTitle) => {
      const condition =
        layerTitle !== "Interventi" && layerTitle !== this.PROJECT_LAYER_TITLE;
      // const condition = isProject
      //   ? layerTitle !== "Interventi" && layerTitle !== "Progetto"
      //   : layerTitle !== "Interventi";
      if (layerTitle !== titleLayerToShow && condition) {
        this.hideLayerByTitle(map, layerTitle);
      } else {
        if (titleLayerToShow === this.PROJECT_LAYER_TITLE) {
          const projLayer = this.getLayerByTitle(map, this.PROJECT_LAYER_TITLE);
          this.layerReadyTracker.next(projLayer);
        } else {
          if (condition)
            this.showLayer(map, vectorLayerTitles, titleLayerToShow, true);
        }
      }
    });
  }

  emptyLoaded() {
    this.loadedLayersName = [...[]];
  }

  createIconLayerBylayer(
    map: any,
    titleLayer: string,
    titleIconLayer: string,
    iconStyle: any,
    drawTemp: boolean
  ) {
    if (!drawTemp) {
      const iconLayer = this.getLayerByTitle(map, this.ICON_LAYER_TITLE);
      if (iconLayer) {
        map.removeLayer(iconLayer);
      }
    } else {
      const iconLayerTemp = this.getLayerByTitle(
        map,
        this.ICON_TEMP_LAYER_TITLE
      );
      if (iconLayerTemp) {
        map.removeLayer(iconLayerTemp);
      }
    }

    const l = this.getLayerByTitle(map, titleLayer);
    const length = l.getSource().getFeatures().length;
    const features = l.getSource().getFeatures();
    const onlyPoint = [];
    if (features.length) {
      for (let index = 0; index < length; index++) {
        const f = features[index].clone();
        if (features[index].getGeometry().getType() === "LineString") {
          const ls = turf.lineString(
            f
              .getGeometry()
              .transform(this.sourceSRS, this.destSRS)
              .getCoordinates()
          );

          let point = turf.pointOnFeature(ls);
          point.properties["stato"] = f.get("stato");
          point.properties["progetto"] = f.get("progetto");
          if (f.get("type")) {
            point.properties["type"] = f.get("type");
          }
          point.properties["anno"] = f.get("anno");
          point.properties["descrizione"] = f.get("descrizione");
          point.properties["cup"] = f.get("cup");
          point.properties["direzioni"] = f.get("direzioni");
          point.properties["importi"] = f.get("importi");
          if (f.get("gid")) {
            point.properties["gid"] = f.get("gid");
          } else {
            point.properties["gid"] = f.get("myid");
          }
          point.properties["oluid"] = f.ol_uid;
          if (f.get("merged")) {
            point.properties["merged"] = f.get("merged");
          }
          point.properties["fid"] = f.get("myid");

          onlyPoint.push(point);
        } else {
          if (features[index].getGeometry().getType() === "MultiLineString") {
            const ls = turf.multiLineString(
              f
                .getGeometry()
                .transform(this.sourceSRS, this.destSRS)
                .getCoordinates()
            );
            let point = turf.pointOnFeature(ls);
            point.properties["stato"] = f.get("stato");
            point.properties["progetto"] = f.get("progetto");
            if (f.get("type")) {
              point.properties["type"] = f.get("type");
            }
            point.properties["anno"] = f.get("anno");
            point.properties["descrizione"] = f.get("descrizione");
            point.properties["cup"] = f.get("cup");
            point.properties["direzioni"] = f.get("direzioni");
            point.properties["importi"] = f.get("importi");
            if (f.get("gid")) {
              point.properties["gid"] = f.get("gid");
            } else {
              point.properties["gid"] = f.get("myid");
            }
            if (f.get("merged")) {
              point.properties["merged"] = f.get("merged");
            }
            point.properties["fid"] = f.get("myid");
            point.properties["oluid"] = f.ol_uid;

            onlyPoint.push(point);
          } else {
            //point
            const point = turf.point(
              f
                .getGeometry()
                .transform(this.sourceSRS, this.destSRS)
                .getCoordinates()
            );
            point.properties["type"] = f.get("type");
            if (f.get("gid")) {
              point.properties["gid"] = f.get("gid");
            } else {
              point.properties["gid"] = f.get("myid");
            }
            if (f.get("merged")) {
              point.properties["merged"] = f.get("merged");
            }
            point.properties["fid"] = f.get("myid");
            point.properties["oluid"] = f.ol_uid;
            onlyPoint.push(point);
          }
        }
      }
      console.log(onlyPoint);
      const icons = {
        type: "FeatureCollection",
        features: onlyPoint,
      };
      this.createLayerFromGeoJson(
        map,
        icons,
        titleIconLayer,
        iconStyle,
        null,
        true,
        false
      );
    }
  }

  createIconLayerForDashboard(
    map: any,
    titleLayer: string,
    titleIconLayer: string,
    iconStyle: any,
    drawTemp: boolean
  ) {
    if (!drawTemp) {
      const iconLayer = this.getLayerByTitle(map, this.ICON_LAYER_TITLE);
      if (iconLayer) {
        map.removeLayer(iconLayer);
      }
    } else {
      const iconLayerTemp = this.getLayerByTitle(
        map,
        this.ICON_TEMP_LAYER_TITLE
      );
      if (iconLayerTemp) {
        map.removeLayer(iconLayerTemp);
      }
    }

    const l = this.getLayerByTitle(map, titleLayer);
    const length = l.getSource().getFeatures().length;
    const features = l.getSource().getFeatures();
    const onlyPoint = [];
    if (features.length) {
      for (let index = 0; index < length; index++) {
        const f = features[index].clone();
        const visibile = f.get("visibile");
        const hideIcon = f.values_["icon_hidden"];
        if (!hideIcon && visibile) {
          if (features[index].getGeometry().getType() === "LineString") {
            const ls = turf.lineString(
              f
                .getGeometry()
                .transform(this.sourceSRS, this.destSRS)
                .getCoordinates()
            );

            let point = turf.pointOnFeature(ls);
            point.properties["stato"] = f.get("stato");
            point.properties["progetto"] = f.get("progetto");
            point.properties["codice"] = f.get("codice");
            point.properties["anno"] = f.get("anno");
            point.properties["descrizione"] = f.get("descrizione");
            point.properties["cup"] = f.get("cup");
            point.properties["direzioni"] = f.get("direzioni");
            point.properties["importi"] = f.get("importi");
            point.properties["visibile"] = f.get("visibile");
            if (f.get("type")) {
              point.properties["type"] = f.get("type");
            }

            onlyPoint.push(point);
          } else {
            if (features[index].getGeometry().getType() === "MultiLineString") {
              const ls = turf.multiLineString(
                f
                  .getGeometry()
                  .transform(this.sourceSRS, this.destSRS)
                  .getCoordinates()
              );
              let point = turf.pointOnFeature(ls);
              point.properties["stato"] = f.get("stato");
              point.properties["progetto"] = f.get("progetto");
              point.properties["codice"] = f.get("codice");
              point.properties["anno"] = f.get("anno");
              point.properties["descrizione"] = f.get("descrizione");
              point.properties["cup"] = f.get("cup");
              point.properties["direzioni"] = f.get("direzioni");
              point.properties["importi"] = f.get("importi");
              point.properties["visibile"] = f.get("visibile");
              if (f.get("type")) {
                point.properties["type"] = f.get("type");
              }
              onlyPoint.push(point);
            } else {
              //point
              const point = turf.point(
                f
                  .getGeometry()
                  .transform(this.sourceSRS, this.destSRS)
                  .getCoordinates()
              );
              point.properties["stato"] = f.get("stato");
              point.properties["progetto"] = f.get("progetto");
              point.properties["codice"] = f.get("codice");
              point.properties["anno"] = f.get("anno");
              point.properties["descrizione"] = f.get("descrizione");
              point.properties["cup"] = f.get("cup");
              point.properties["direzioni"] = f.get("direzioni");
              point.properties["importi"] = f.get("importi");
              point.properties["visibile"] = f.get("visibile");
              if (f.get("type")) {
                point.properties["type"] = f.get("type");
              }
              onlyPoint.push(point);
            }
          }
        }
        //////
      }
      console.log(onlyPoint);
      const icons = {
        type: "FeatureCollection",
        features: onlyPoint,
      };
      this.createLayerFromGeoJson(
        map,
        icons,
        titleIconLayer,
        iconStyle,
        null,
        true,
        false
      );
    }
  }

  callBingGeocoding(request) {
    return this.http.get(`${request}`);
  }

  cloneAllDrawsIntoProject(projectId) {
    return this.http.get<any[]>(
      `${this.backendUrl}clonainprogetto/${projectId}`
    );
  }

  cloneProjectIntoIntervention(interventoId) {
    return this.http.get<any[]>(
      `${this.backendUrl}clonainintervento/${interventoId}`
    );
  }

  createClusteredLayerFromGeoJson(
    map: any,
    geoJSON: any,
    title: string,
    style: any,
    minZoom: number,
    visible: boolean,
    isForDrawing: boolean
  ) {
    const geoJson = geoJSON;

    const features = new GeoJSON({
      featureProjection: 'EPSG:3857',
    }).readFeatures(geoJson);
    const source = new VectorSource({
      features: features,
    });


    const clusterSource = new Cluster({
      distance: 69,
      minDistance: 5,
      source: source,
    });

    const layer = new VectorLayer({
      title: title,
      source: clusterSource,
      visible: visible,
      style: style,
    });

    map.addLayer(layer);
    if (isForDrawing) this.layerReadyTracker.next(layer);
    if (!visible) this.hideLayerByTitle(map, title);
    if (!this.loadedLayersName.includes(title))
      this.loadedLayersName.push(title);
    return layer;
  }

  setZoomToLayer(map: any, layer: any, level: number) {
    if (
      layer &&
      layer.getSource().getFeatures() &&
      layer.getSource().getFeatures().length > 0
    ) {
      layer &&
        layer.getSource() &&
        map.getView().fit(layer.getSource().getExtent());
      level && map.getView().setZoom(map.getView().getZoom() - level);
    }
  }

  zoomToBoundaries = (map, vectorLayer) => {
    
    const extent = vectorLayer.getSource().getExtent();
    map.getView().fit(extent, map.getSize());
    map.getView().setZoom(map.getView().getZoom() - 0.25);
  }

}
