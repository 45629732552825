import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { JwtUser } from 'src/app/models/jwt-user';
import { AuthService, UserService } from 'src/app/services';
import { MenuSelectedService } from 'src/app/services/menu-selected.service';
import { RowMenuComponent } from './row-menu/row-menu.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit, OnDestroy {

  @ViewChildren(RowMenuComponent)
  rowsMenu: QueryList<RowMenuComponent>;
  menuItems: any[] = [];
  currentUser: JwtUser;
  subscription: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private menuSelectedService: MenuSelectedService
  ) {
    this.subscription = this.menuSelectedService.notifier.subscribe({
      next: (titleMenu) => {
        const rowMenu = this.rowsMenu.find(row => row.menuItem.title === titleMenu);
        rowMenu ? rowMenu.goToView() : null;
      }
    });
  }

  ngOnInit() {
    window.scroll(0, 0);

    this.currentUser = this.authService.getCurrentUser();
    this.menuItems = this.authService.getMenu();
    if (!this.menuItems || this.menuItems.length <= 0) {
      this.userService.getInfo('').subscribe(
        res => {
          this.authService.setMenu(res['menu']);
          this.menuItems = this.authService.getMenu();
          this.menuItems = this.menuItems.filter(item => item.title !== 'Home');
          this.menuItems = this.menuItems.filter(x=>this.canAccess(x));
          this.checkCanAccessSubMenu();
        }
      );
    } else {
      this.menuItems = this.menuItems.filter(item => item.title !== 'Home');
      this.menuItems = this.menuItems.filter(x=>this.canAccess(x));
      this.checkCanAccessSubMenu();
    }
  }

  private checkCanAccessSubMenu() {
    this.menuItems.forEach(item => {
      item && item.subMenu
                ? item.subMenu = item.subMenu.filter((sub: any) => this.canAccess(sub))
                : null;
    });
  }

  private canAccess(item: any) {
    if (this.isIannini && item?.link==='/inframob/dashboard-pnrr')return false;
    if (item && item.permissions && item.permissions.length > 0) {
      return this.currentUser.authorities === null
                ? false
                : item.permissions.find((element: string) => this.currentUser.authorities.includes(element));
    }
    return true;
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

  get isIannini(){
    return this.currentUser.user_name ==='NNNPLA71E13H501X';
  }

}
