<div class="row justify-content-end">
  <div class="col-lg-3 col-sm-6 col-2 mt-3 mb-4 pt-1 pr-0">
    <h5 class="float-right">Seleziona Capitolo {{ getCapitoloLabelByTipe() }}:</h5>
  </div>
  <div class="col-lg-1 col-sm2 col-3 text-center mt-3 pl-0" [hidden]="!canEdit() || readOnlyForm">
    <a class="bottone-inserisci" (click)="openModalSelezionaCapitolo(); " href="javascript:;; " title="Aggiungi capitoli" aria-label="Aggiungi capitoli">
      <i class="fas fa-plus" aria-hidden="true"></i>
    </a>
  </div>
</div>

<div>
  <ng-container *ngFor="let capitolo of capitoliSelezionati">
    <app-sicer-bilancio-capitolo-dettaglio
      [impAccsSelezionati]="impAccsSelezionati"
      [capitolo]="capitolo"
      [interventi]="interventi"
      [progettoId]="progettoId"
      [impegniAccertamenti]="capitolo.impegniAccertamenti"
      [finanziamentoId]="finanziamentoId"
      [interventoId]="interventoId"
      [type]="type"
      (disassociaCapitoloEmitter)="manageDisassociaCapitolo($event)"

      (associaAccertamentiEmitter)="bindImpegnoAccertamento($event)"
      (disassociaAccertamentiEmitter)="unbindImpegnoAccertamento($event)"

      (associaMassivoAccertamentiEmitter)="massiveBindImpegniAccertamenti($event)"
      (disassociaMassivoAccertamentiEmitter)="massiveUnbindImpegniAccertamenti($event)"
      [readOnlyForm]="!canEdit() || readOnlyForm"
      >
    </app-sicer-bilancio-capitolo-dettaglio>
  </ng-container>

  <div class="text-white mt-3 p-2 rounded bg-info" *ngIf="checkRecapTotali()">
    <span class="px-3" [innerHtml]="getRecapData()"></span>
  </div>

  <hr>

  <app-sicer-bilancio-accertamenti
    [impAccsSelezionati]="impAccsSelezionati"
    [finanziamentoId]="finanziamentoId"
    [interventoId]="interventoId"
    [interventi]="interventi"
    [type]="type"
    (disassociaAccertamentiEmitter)="unbindImpegnoAccertamento($event)"
    *ngIf="checkVisibileImpAccSelezionati()">
  </app-sicer-bilancio-accertamenti>
</div>

<ng-template #extraRichiestaSicerTemplate>
  <button class=" btn-block btn btn-info btn-sm mb-2" (click)="requestCodiceCapitolo()">
      Richiedi capitolo mancante
  </button>
</ng-template>
