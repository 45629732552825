import { Directive } from '@angular/core';
import { itLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ColumnMode } from '@swimlane/ngx-datatable';

defineLocale('it', itLocale);

@Directive({
  selector: '[base]'
})
export class BdapDirective {

  columnsFondo = [
    this.createColumnObject('Fonte', 'codFonte', 6),
    this.createColumnObject('Codice Nazione', 'regione', 6),
    this.createColumnObject('Codice Fiscale Cofinanziatore', 'cfCofinanziatore', 6),
    this.createColumnObject('Importo', 'importo', 4, 'rigthAlignDataTemplate')
  ];

  columnsQuadro = [
    this.createColumnObject('Voce di Spesa', 'codVoceDiSpesa', 16),
    this.createColumnObject('Importo', 'importo', 12, 'rigthAlignDataTemplate')
  ];

  columnsAggiudicazione = [
    this.createColumnObject('Cig', 'cig', 6),
    this.createColumnObject('Step Procedura', 'codStep', 6),
    this.createColumnObject('Tipo Procedura', 'codTipo'),
    this.createColumnObject('Importo Base d\'Asta', 'importo', 5, 'rigthAlignDataTemplate'),
    this.createColumnObject('Data Step', 'dataEffettiva', 5),
    this.createColumnObject('Importo Step', 'importoStep', 6, 'rigthAlignDataTemplate')
  ];

  columnsSoggetti = [
    this.createColumnObject('Codice Fiscale', 'codFiscale'),
    this.createColumnObject('Denominazione', 'denominazione') ,
    this.createColumnObject('Forma Giuridica', 'descIstatFormaGiuridica') ,
    this.createColumnObject('Ruolo Soggetto', 'codRuolo'),
    this.createColumnObject('Codice Nazione', 'regione')
  ];

  columnsIndicatori = [
    this.createColumnObject('Tipo Indicatore', 'codTipoIndicatore'),
    this.createColumnObject('Valore Inizio Programmazione', 'valProgramIniz'),
    this.createColumnObject('Valore Conclusione', 'valConclusione')
  ];

  columnsPercettori = [
    this.createColumnObject('CIG', 'cig'),
    this.createColumnObject('Partiva Iva<br>Percettore', 'numeroIdFiscaleFornitore'),
    this.createColumnObject('Codice Fiscale<br>Percettore', 'codiceFiscaleFornitore'),
    this.createColumnObject('Denominazione<br>Percettore', 'denomFornitore'),
    this.createColumnObject('Codice Fiscale<br>Committente', 'codiceFiscaleCommittente'),
    this.createColumnObject('Denominazione<br>Committente', 'denomCommittente'),
    this.createColumnObject('Importo Totale<br>Pagato', 'importoTotalePagato', 3, 'rigthAlignDataTemplate'),
    this.createColumnObject('Data Pagamento', 'dataPagamento')
  ];

  columnsGiustificativo = [
    this.createColumnObject('ID Univoco', 'idUnivoco', 5, null, 0, true, false, false, false, false, 80),
    this.createColumnObject('ID Univoco<br>PCC', 'idUnivocoFatturaPcc', 5, null, 0, true, false, false, false, false, 80),
    this.createColumnObject('CIG', 'cig', 5, null, 0, true, false, false, false, false, 85),
    this.createColumnObject('Tipologia<br>Documento', 'tipologiaDocumento', 6, null, 0, true, false, false, false, false, 120),
    this.createColumnObject('Data<br>Emissione', 'dataEmissione', 5, null, 0, true, false, false, false, false, 85),
    this.createColumnObject('Data<br>Scadenza', 'dataScadenza', 5, null, 0, true, false, false, false, false, 85),
    this.createColumnObject('Importo<br>Lordo', 'importoTotaleDocumento', 5, 'rigthAlignDataTemplate', 0, true, false, false, false, false, 90),
    this.createColumnObject('Importo<br>IVA', 'importoTotaleIvaDocumento', 5, 'rigthAlignDataTemplate', 0, true, false, false, false, false, 90),
    this.createColumnObject('Importo<br>Netto', 'importoNetto', 5, 'rigthAlignDataTemplate', 0, true, false, false, false, false, 90),
    this.createColumnObject('Codice<br>Fiscale<br>Committente', 'codiceFiscaleCommittente', 5, null, 0, true, false, false, false, false, 90),
    this.createColumnObject('Denominazione<br>Committente', 'denomCommittente', 7, null, 0, true, false, false, false, false, 150),
    this.createColumnObject('Partita<br>Iva<br>Percettore', 'numeroIdFiscaleFornitore', 5, null, 0, true, false, false, false, false, 100),
    this.createColumnObject('Codice<br>Fiscale<br>Fornitore', 'codiceFiscaleFornitore', 5, null, 0, true, false, false, false, false, 90),
    this.createColumnObject('Denominazione<br>Percettore', 'denomFornitore', 7, null, 0, true, false, false, false, false, 150),
    this.createColumnObject('Stato<br>Fattura', 'statoFattura', 5, null, 0, true, false, false, false, false, 100)
  ];

  columnsPagamentiBDAP = [
    this.createColumnObject('Codice Pagamento', 'codicePagamento'),
    this.createColumnObject('Data Pagamento', 'dataPagamento'),
    this.createColumnObject('Tipologia Pagamento', 'tipologiaPagamento'),
    this.createColumnObject('Importo', 'importo', 3, 'rigthAlignDataTemplate'),
    this.createColumnObject('Causale', 'causale')
  ];

  columnsPagamentiSIOPE = [
    this.createColumnObject('CIG', 'cig'),
    this.createColumnObject('Investimento', 'investimento'),
    this.createColumnObject('Data Pagamento', 'dataPagamento'),
    this.createColumnObject('Importo Pagamento', 'importoPagamento', 3, 'rigthAlignDataTemplate'),
    this.createColumnObject('Numero Mandato', 'numeroMandato'),
    this.createColumnObject('Numero Fattura', 'numeroFattura')
  ];

  columnsPagamentiFattura = [
    this.createColumnObject('CIG', 'cig'),
    this.createColumnObject('ID Univoco', 'idUnivoco'),
    this.createColumnObject('ID Univoco Fattura PCC', 'idUnivocoFatturaPcc'),
    this.createColumnObject('Numero Fattura', 'numeroFattura'),
    this.createColumnObject('Data Pagamento', 'dataPagamento'),
    this.createColumnObject('Numero Mandato', 'numeroMandatoPagamento'),
    this.createColumnObject('Importo Pagamento', 'importoTotalePagato', 4, 'rigthAlignDataTemplate')
  ];

  columnsCosti = [
    this.createColumnObject('Anno', 'anno'),
    this.createColumnObject('Importo da Realizzare', 'importoDaRealizzare', 3, 'rigthAlignDataTemplate'),
    this.createColumnObject('Importo Realizzato', 'importoRealizzato', 4, 'rigthAlignDataTemplate')
  ];

  private createColumnObject(name: string, prop: string, flexGrow: number = 3, cellTemplate: string = null, width: number = 0, visible: boolean = true, sortable: boolean = false, filtrable: boolean = false, resizeable: boolean = false, exportable: boolean = false, minWidth: number = 10) {
    return {
      name: name,
      prop: prop,
      visible: visible,
      sortable: sortable,
      filtrable: filtrable,
      flexGrow: flexGrow,
      width: width,
      minWidth: minWidth,
      resizeable: resizeable,
      exportable: exportable,
      cellTemplate: cellTemplate,
    }
  }

  getDtOptions(type: string, callback: any) {
    return {
      rows: [],
      totalElements: 0,
      columns: this.getColumns(type),
      columnMode: ColumnMode.flex,
      headerHeight: "50",
      footerHeight: "50",
      rowHeight: "auto",
      externalSorting: true, // true
      loadingIndicator: "loading",
      class: "bootstrap",
      summaryRow: true,
      summaryPosition: "'bottom'",
      externalPaging: true, // true
      count: 0,
      offset: 0,
      limit: 10,
      serverSide: true,
      ajax: callback,
      scrollbarH: "true",
      hideRowsPerPage: true,
      hideSearch: true
    };
  }

  private getColumns(type: string) {
    switch (type) {
      case 'fondo':
        return this.columnsFondo;
      case 'quadro':
        return this.columnsQuadro;
      case 'aggiudicazione':
        return this.columnsAggiudicazione;
      case 'soggetti':
        return this.columnsSoggetti;
      case 'indicatori':
        return this.columnsIndicatori;
      case 'percettori':
        return this.columnsPercettori;
      case 'giustificativo':
        return this.columnsGiustificativo;
      case 'pagamentiBDAP':
        return this.columnsPagamentiBDAP;
      case 'pagamentiSIOPE':
        return this.columnsPagamentiSIOPE;
      case 'pagamentiFattura':
        return this.columnsPagamentiFattura;
      case 'costi':
        return this.columnsCosti;
    }
  }
}
