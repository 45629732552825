import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


@Component({
  selector: "app-chart-tot-spesa-effettiva-spesa-prevista",
  templateUrl: "./chart-tot-spesa-effettiva-spesa-prevista.component.html",
  styleUrls: ["./chart-tot-spesa-effettiva-spesa-prevista.component.css"],
})
export class ChartTotSpesaEffettivaSpesaPrevistaComponent
  implements OnInit
{
  chart: any;
  _percentualeTotProgetti:number = 0;
  @Input()
  get  percentualeTotProgetti(){
    return this._percentualeTotProgetti;
  }
  set percentualeTotProgetti(value:any){
    this._percentualeTotProgetti = value;
    if(this._percentualeTotProgetti)this.grafico();
  }
  @Input() label:string='';

  chartId:string = '';
  constructor() {this.chartId = this.makeRandomString();}

  ngOnInit(): void {

   // this.grafico();
  }

  /*ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.percentualeTotProgetti &&
      !changes.percentualeTotProgetti.firstChange
    ) {
      this.grafico();
    }
  }*/

  grafico() {
    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    let chartMin = 0;
    let chartMax = this.percentualeTotProgetti > 100 ? this.percentualeTotProgetti : 100;



    let data = {
      score: this.percentualeTotProgetti,
      gradingData: [
        {
          title: "Bassa",
          color: "#f04922",
          lowScore: 0,
          highScore: 20,
        },
        {
          title: "Medio Bassa",
          color: "#fdae19",
          lowScore: 20,
          highScore: 40,
        },
        {
          title: "Media",
          color: "#f3eb0c",
          lowScore: 40,
          highScore: 60,
        },
        {
          title: "Medio Alta",
          color: "#b0d136",
          lowScore: 60,
          highScore: 80,
        },
        {
          title: "Alta",
          color: "#54b947",
          lowScore: 80,
          highScore: 100,
        },
      ],
    };



    if(this.percentualeTotProgetti > 100) {
      data.gradingData.push({
        title: "Fuori soglia",
        color: "#8c8c8c",
        lowScore: 100,
        highScore: this.percentualeTotProgetti,
      })
    }

    /**
  Grading Lookup
  */
    function lookUpGrade(lookupScore, grades) {
      // Only change code below this line
      for (var i = 0; i < grades.length; i++) {
        if (
          grades[i].lowScore < lookupScore &&
          grades[i].highScore >= lookupScore
        ) {
          return grades[i];
        }
      }
      return 0;
    }

    // create chart

    let chart = am4core.create(`cd_${this.chartId}`, am4charts.GaugeChart);

    am4core.options.autoDispose = true;
    chart.hiddenState.properties.opacity = 0;
    chart.fontSize = 11;
    chart.innerRadius = am4core.percent(80);
    chart.resizable = true;

    chart.numberFormatter.numberFormat = "#";

       //Export chart
       chart.exporting.menu = new am4core.ExportMenu();

       chart.exporting.menu.align = "left";
       chart.exporting.menu.verticalAlign = "top";

       chart.exporting.menu.defaultStyles = true;
       chart.exporting.menu.items = [
         {
           menu: [
             { type: "jpg", label: "JPG" },
             { type: "png", label: "PNG" },
             { type: "csv", label: "CSV" },
           ],
         },
       ];

       chart.exporting.menu.items[0].icon = "assets/img/export.png";

    // Add chart title
    var title = chart.titles.create();
    title.text = this.label;
    title.fontSize = 22;
    //title.marginBottom = 30;

    /**
     * Normal axis
     */

    let axis = chart.xAxes.push(
      new am4charts.ValueAxis<am4charts.AxisRendererCircular>()
    );
    axis.min = chartMin;
    axis.max = chartMax;
    axis.strictMinMax = true;
    axis.renderer.radius = am4core.percent(80);
    axis.renderer.inside = true;
    axis.renderer.line.strokeOpacity = 0.1;
    axis.renderer.ticks.template.disabled = false;
    axis.renderer.ticks.template.strokeOpacity = 1;
    axis.renderer.ticks.template.strokeWidth = 0.5;
    axis.renderer.ticks.template.length = 5;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.labels.template.radius = am4core.percent(15);
    axis.renderer.labels.template.fontSize = "0.9em";

    /**
     * Axis for ranges
     */

    let axis2 = chart.xAxes.push(
      new am4charts.ValueAxis<am4charts.AxisRendererCircular>()
    );
    axis2.min = chartMin;
    axis2.max = chartMax;
    axis2.strictMinMax = true;
    axis2.renderer.labels.template.disabled = true;
    axis2.renderer.ticks.template.disabled = true;
    axis2.renderer.grid.template.disabled = false;
    axis2.renderer.grid.template.opacity = 0.5;
    axis2.renderer.labels.template.bent = true;
    axis2.renderer.labels.template.fill = am4core.color("#000");
    axis2.renderer.labels.template.fontWeight = "bold";
    axis2.renderer.labels.template.fillOpacity = 0.3;

    /**
  Ranges
  */

    for (let grading of data.gradingData) {
      let range = axis2.axisRanges.create();
      range.axisFill.fill = am4core.color(grading.color);
      range.axisFill.fillOpacity = 0.8;
      range.axisFill.zIndex = -1;
      range.value = grading.lowScore > chartMin ? grading.lowScore : chartMin;
      range.endValue =
        grading.highScore < chartMax ? grading.highScore : chartMax;
      range.grid.strokeOpacity = 0;
      range["stroke"] = am4core.color(grading.color).lighten(-0.1);
      range.label.inside = true;
      range.label.text = grading.title.toUpperCase();
      range.label.inside = true;
      range.label.location = 0.5;
      range.label.inside = true;
      range.label["radius"] = am4core.percent(10);
      range.label.paddingBottom = -5; // ~half font size
      range.label.fontSize = "0.9em";
    }

    let matchingGrade = lookUpGrade(data.score, data.gradingData);

    /**
     * Label 1
     */

    let label = chart.radarContainer.createChild(am4core.Label);
    label.isMeasured = false;
    label.fontSize = "3em";
    label.x = am4core.percent(50);
    label.paddingBottom = 15;
    label.horizontalCenter = "middle";
    label.verticalCenter = "bottom";
    //label.text = chart.numberFormatter.format(data.score, "#.'%'");
    //label.dataItem = data;
    label.fill = am4core.color(matchingGrade.color);

    /**
     * Label 2
     */

    //let label2 = chart.radarContainer.createChild(am4core.Label);
    //label2.isMeasured = false;
    //label2.fontSize = "2em";
    //label2.horizontalCenter = "middle";
    //label2.verticalCenter = "bottom";
    //label2.text = matchingGrade.title.toUpperCase();
    //label2.fill = am4core.color(matchingGrade.color);

    /**
     * Hand
     */

    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.axis = axis2;
    hand.innerRadius = am4core.percent(55);
    hand.startWidth = 8;
    hand.pin.disabled = true;
    hand.value = data.score;
    hand.fill = am4core.color("#444");
    hand.stroke = am4core.color("#000");

    hand.events.on("positionchanged", function () {
      label.text = chart.numberFormatter.format(data.score, "#'%'");
      let value2 = axis.positionToValue(hand.currentPosition);
      let matchingGrade = lookUpGrade(
        value2,
        // axis.positionToValue(hand.currentPosition),
        data.gradingData
      );
      label.fill = matchingGrade.color;
      //label2.text = matchingGrade.title.toUpperCase();
      //label2.fill = am4core.color(matchingGrade.color);
      //label2.stroke = am4core.color(matchingGrade.color);
      // label.fill = am4core.color(matchingGrade.color);
    });

    if (this.chart) {
      chart.dispose();
    }
  }

  makeRandomString() {
    return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  //setInterval(function() {
  //   let value = chartMin + Math.random() * (chartMax - chartMin);
  //   hand.showValue(value, 1000, am4core.ease.cubicOut);
  //}, 2000);
}
