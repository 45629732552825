import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    TemplateRef,
    OnDestroy,
    Input,
    Output,
    EventEmitter
} from "@angular/core";
import { ToastService } from 'src/app/services/toast.service';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { FinanziamentoService, InterventoService, ProgettoService, DocumentazioneService, ProtocolloService,SicerService } from "../../services";
import { ProgettoDivDettaglioService } from "../progettoDivDettaglio/progetto-div-dettaglio.service";
import { FinanziamentoDivDettaglioService } from "../finanziamentoDivDettaglio/finanziamento-div-dettaglio.service";
import { AuthService } from "src/app/services";
import { DocumentazioneComponent } from "../documentazione/documentazione.component";
import * as FileSaver from "file-saver";



@Component({
    selector: 'associa-provvedimenti-documenti-component',
    templateUrl: './associa-provvedimenti-documenti.component.html',
    styleUrls: ['./associa-provvedimenti-documenti.component.css']
})
export class AssociaProvvedimentiDocumentiComponent implements OnInit, OnDestroy {
    section: string = '';

    tabsOnOneRow = true;

    ngOnDestroy(): void {
    }

    submitted: boolean = false;
    loading: boolean = false;
    anniList = [];
    readOnlyForm: boolean = false;
    isValidated: boolean = false;
    private _finanziamentoId: number;
    _finanziamentoIdForDocumentComponent : number;
    _interventoIdForDocumentComponent : number;

    modalSubscriptions = [];



    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

@ViewChild('documentazioneComponent')
documentazioneComponent:DocumentazioneComponent;

@ViewChild("noteProtocolloTemplate", { static: true })
    public noteProtocolloTemplate: TemplateRef<any>;

    templateList = [];

    dtOptions: any;
    rows: any[];
    columns = [
        {
            name: '', prop: 'id', sortable: true, filtrable: false, maxWidth: 0, width: 0, visible: false
            // 'buttonsTemplate'
        }
        
        , {
            name: "del",
            prop: "dataDocumento",
            cellTemplate: "dateValueTemplate",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 2,
            minWidth: 60
        }
        , {
            name: 'numero'
            , prop: 'numero'
            , sortable: true
            , filtrable: true
            , flexGrow: 4,
            minWidth: 120,
            cellTemplate:'numeroDocumentoTemplate'
        },
        {
            name: "oggetto/titolo",
            prop: "titolo",
            cellTemplate: 'oggettoProtocolloTemplate',
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 4,
            minWidth: 120
        },
        {
            name: "tipologia",
            prop: "tipo",
            cellTemplate: "tipoTemplate",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 3,
            minWidth: 80,
        },
        {
            name: "movimento<br/>PROSA",
            prop: "tipo",
            cellTemplate: "movimentoTemplate",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 2,
            minWidth: 60,
        }
        ,
        {
            name: "note PROSA",
            prop: "note",
            cellTemplate: 'noteProtocolloTemplate',
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 3,
            minWidth: 120,
        }
        , {
            name: "inserito in",
            prop: "inseritoIn",
            /*cellTemplate: "tipologiaEntitaTemplate",*/
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 2,
            minWidth: 60,
        }
    ];






    constructor(
        private toastr: ToastService
        , private el: ElementRef
        , private finanziamentoService: FinanziamentoService
        , private interventoService: InterventoService
        , private progettoDivDettaglioService: ProgettoDivDettaglioService
        , private progettoService: ProgettoService
        , private finanziamentoDivDettaglioService: FinanziamentoDivDettaglioService
        , private authService: AuthService,
        private documentazioneService: DocumentazioneService,
        private protocolloService: ProtocolloService,
        private sicerService:SicerService
    ) {
        this.dtOptions = {
            rows: this.rows
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , scrollbarH: "true"
            , ajax: this.loadDocumentazione,
        };
    }

    loadDocumentazione = (dataTablesParameters: any, callback: any) => {
        const what = (this._finanziamentoId ? 'F' : 'I');
        const mainId = (this._finanziamentoId ? this._finanziamentoId : this._interventoId);
        if (!mainId) return;
        this.documentazioneService.getAllDtWithProvvedimenti(what, mainId, dataTablesParameters)
            .subscribe(resp => {
                if (resp && resp.data){
                    resp.data.forEach(x=>{
                        if (x.tipologiaEntita=='PROSA')x.allegatiArray = this.getAllegati(x.allegati);
                    });
                }
                callback(resp);
            });
    };


    ngOnInit() {
        this.templateList = [

            { name: "oggettoProtocolloTemplate", template: this.oggettoProtocolloTemplate },
            { name: "tipoTemplate", template: this.tipoTemplate },
            { name: "movimentoTemplate", template: this.movimentoTemplate },
            { name: "tipologiaEntitaTemplate", template: this.tipologiaEntitaTemplate },
            { name:'numeroDocumentoTemplate',template:this.numeroDocumentoTemplate},
            { name: "noteProtocolloTemplate", template: this.noteProtocolloTemplate },
        ];
        window.scroll(0, 0);
    }

    refreshGrid() {
        this.ngxDatatableWrapper.refresh();     
    }

    documentazioneChanged($event) {
        console.log('into documentazioneChanged');
        this.refreshGrid();
    }

    loadDataCounter: number = 0;
    reloadAllData() {
        console.log(`associa-provvedimenti-documenti: reloadAllData with: finId= ${this._finanziamentoId}, intId = ${this._interventoId}`);
        this.esistonoInterventi = false;
        this.tabsOnOneRow = true;
        this._finanziamentoIdForDocumentComponent = this._finanziamentoId;
        this._interventoIdForDocumentComponent = this._interventoId;
        if (this._finanziamentoId) this.getFinanziamentoData(this._finanziamentoId);
        if (this._interventoId) this.getInterventoData(this._interventoId);
        if(this.documentazioneComponent){
            if(this._finanziamentoId)this.documentazioneComponent.finanziamentoId = this._finanziamentoId;
            else this.documentazioneComponent.interventoId = this._interventoId;
            this.documentazioneComponent.loadProtocolliTable();
        }
    }


    @Input()
    set finanziamentoId(value) {
        this._finanziamentoId = value;
        this.section = 'Finanziamenti';
        this._interventoId = null;
        if (!value) return;
       
    }
    get finanziamentoId() {
        return this._finanziamentoId;
    }

    private _interventoId: number;
    @Input()
    set interventoId(value) {
        this._interventoId = value;
        this.section = 'Interventi';
        this._finanziamentoId = null;
        if (!value) return;        
    }
    get interventoId() {
        return this._interventoId;
    }

    getFinanziamentoData(id: number) {
        this.loadDataCounter++;
        console.log('inside fasi-getfinanziamentodata');
        let that = this;
        this.finanziamentoService.getById(id).subscribe(result => {
            this.refreshGrid();
            this.loadDataCounter--;
        });
    }




    interventoData: any;
    getInterventoData(id: number) {
        this.loadDataCounter++;
        let that = this;
        this.anniList = [];
        this.loading = true;
        this.interventoService.get(id).subscribe(
            result => {
                this.loading = false;
                this.refreshGrid();
                this.loadDataCounter--;
                if (result.isMain){
                    this.progettoService.getInterventiCount(result.progettoId).subscribe(
                        res => {
                            that.esistonoInterventi = res - 1 > 0;   
                            that.tabsOnOneRow = that.esistonoInterventi;                         
                        }
                    );
                }
                else{
                    that.tabsOnOneRow = false;
                }

            });
    }



    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
    gotoBackBtnClick() {
        this.gotoBtnFunction.emit(true);
    }

esistonoInterventi = false;

    _isInModal: boolean = false;
    @Input()
    set isInModal(value: boolean) {
        this._isInModal = value;
    }
    get isInModal() { return this._isInModal; }


    /**
* Controlla se l'utente può accedere o meno alla sezione.
* @param section il nome della sezione
* @param operations le operazioni da poter effettuare sulla sezione
* @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
* @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
* @returns true se il controllo è andato a buon fine, false altrimenti
*/
    enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
        return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
    }
    isGruppoPnrr() {
        return this.authService.isGruppoPnrr();
    }

    isAdmin() {
        return this.authService.isAdmin();
    }
    isGr34() {
        return this.authService.isGr34();
    }
    @Output()
    associaProvvedimentiChangedEmitter: EventEmitter<any> = new EventEmitter();
    associaProvvedimentiChanged($event) {
        this.refreshGrid();
        this.associaProvvedimentiChangedEmitter.emit($event);
    }

    trimText(text, size) {
        if (!size) return text;
        if (text.length <= size) return text;
        return text.substring(0, size) + '...';
    }

    printTipologiaCell(row, value) {
        if (row.tipologiaEntita == 'GENERICO' && value !=''){
            const v = value.split('/');
            return v[v.length-1];
        }
        if (row.tipologiaEntita != 'PROSA') return value;
        return '';
    }
    printMovimentoCell(row, value) {
        if (row.tipologiaEntita == 'PROSA') return value;
        return '';
    }
    printTipologiaEntitaTemplateCell(row, value) {
        const val = row.tipologiaEntita;
        switch (val) {
            case 'PROSA':
                return 'Protocolli';

            case 'SICER':
                return 'SICER - Atti';
            case 'PROVVEDIMENTO':
                return 'Provvedimenti (Non SICER)';
            case 'GENERICO':
                return 'Caricamento da file';
        }
    }
    @ViewChild("oggettoProtocolloTemplate", { static: true })
    public oggettoProtocolloTemplate: TemplateRef<any>;

    @ViewChild("tipoTemplate", { static: true })
    public tipoTemplate: TemplateRef<any>;

    @ViewChild("movimentoTemplate", { static: true })
    public movimentoTemplate: TemplateRef<any>;

    @ViewChild("tipologiaEntitaTemplate", { static: true })
    public tipologiaEntitaTemplate: TemplateRef<any>;

    @ViewChild("numeroDocumentoTemplate", { static: true })
    public numeroDocumentoTemplate: TemplateRef<any>;


    
    getAllegati(obj) {
        if (!obj) return [];
        return JSON.parse(obj);
    }

    log(msg){
        console.log(msg);
    }


    downloadDocumentoProsa(codice, nome) {
        console.log(`${codice} - ${nome}`);
        this.protocolloService.isPresente(codice, nome).subscribe(
            result => {
                if (result) {
                    this.protocolloService.download(codice, nome).subscribe(
                        res => {
                            let filename = nome.replace(/^.*[\\\/]/, "");
                            FileSaver.saveAs(res.body, filename);
                        }
                    );
                }
                else {
                    this.toastr.error(`Documento ${nome} non presente su Prosa`, null, {
                        timeOut: 3000,/*closeButton:true,*/ disableTimeOut: false
                    });
                }
            }
        );
    }
    downloadDocument(data: any) {


        this.documentazioneService
            .downloadAttachById(data.id)
            .subscribe((res) => {
                let filename = data.filePath.replace(/^.*[\\\/]/, "");
                FileSaver.saveAs(res.body, filename);                
            });
    }
    downloadSicer(item) {
        this.sicerService.download(item.anno, item.numero,item.direzione).subscribe(
            x => {
                let filename = item.numero + '.pdf';
                FileSaver.saveAs(
                    x.body,
                    `${filename}`
                );
            }
        );
    }

}