import { UserSection } from "./userSection";

export class AccountInfo {
  id: string;
  username: string;
  userType: string;
  entity: string[];
  entityName: string;
  firstName: string;
  lastName: string;
  birthDay: string;
  birthDate: Date;
  nationName: string;
  provinceName: string;
  municipalityName: string;
  male: boolean;
  email: string;
  isDocsOk: boolean;
  enabled: boolean;
  fullName: string;
  fiscalCode: string;
  isAdmin: boolean;
  idFilePath: string;
  fcFilePath: string;
  authorities: any[];
  areeTematiche: any[];
  ldapUser: boolean;
  sections: UserSection[];
  menuId: number;
  constructor() {
    this.userType = "";
  }
}
