import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { AreaTematica } from "../models";

class DataTablesResponse {
  data: AreaTematica[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: "root",
})
export class StoricoService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}Storico/`;
  constructor(private http: HttpClient) {}

  getAllDt(dataTablesParameters: any) {
    return this.http.post<DataTablesResponse>(
      `${this.apiUrl}alldt`,
      dataTablesParameters
    );
  }

  exportExcel() {
    /*return this.http.get<any>(
        `${this.apiUrl}/pdf`
    );*/
    return this.http.get(`${this.apiUrl}Excel`, {
      observe: "response",
      responseType: "blob",
    });
  }
}
