<div class="col-lg-12 px-0">
    <div class=" fixedHeaderTable col-lg-12  table-responsive table-wrapper-scroll-y scrollbar-y  px-0" style="max-height: 600px;">
        <table class="table table-hover table-colore tableFixHead" #fasiTable>
            <thead class="">
                <th *ngIf="showSelectColumn" class="bg-white" style="max-width:40px;">&nbsp;</th>
                <th class="fixedmeY bg-white"><small class="bold">-&nbsp;progetto&nbsp;/&nbsp;fabbisogno <br>-&nbsp;interventi <br>-&nbsp;finanziamenti</small>
                </th>
                <!--<th>Descrizione</th>            -->
                <th class="bg-white" style="max-width:120px"><small class="bold">tipologia&nbsp;fase</small></th>
                <th class="bg-white" style="max-width:90px"><small class="bold">stato&nbsp;fase</small></th>
                <th class="bg-white"><small class="bold">data prevista&nbsp;inizio</small></th>
                <th class="bg-white"><small class="bold">data inizio</small></th>
                <!--<th class="bg-white">Scostamento (gg)
          <a type="button" class="help"
            popover="Lo scostamento viene calcolato dalla data inizio, se presente, o dal giorno corrente"
            placement="bottom" triggers="mouseenter:mouseleave">
            <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
            </i>
          </a>
        </th>-->
                <th class="bg-white"><small class="bold">data prevista&nbsp;fine</small></th>
                <th class="bg-white"><small class="bold">data fine</small></th>
                <!--<th class="bg-white">
          Scostamento (gg)
          <a type="button" class="help"
            popover="Lo scostamento viene calcolato dalla data fine, se presente, o dal giorno corrente"
            placement="bottom" triggers="mouseenter:mouseleave">
            <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
            </i>
          </a>
        </th>-->
                <th class="bg-white" style="min-width: 60px"><small class="bold">soggetti&nbsp;responsabili</small></th>
            </thead>
            <tbody>

                <!--finanziamenti-->
                <ng-container *ngIf="currentObject && currentObject.finanziamenti as finanziamenti">
                    <ng-container *ngFor="let finanziamento of finanziamenti;let i=index;">
                        <ng-container [ngSwitch]="finanziamento.fasi && finanziamento.fasi.length>0 && 'hasFasi'">

                            <ng-container *ngSwitchCase="'hasFasi'">
                                <!--scrivo il finanziamento senza la fase come "intestazione"-->
                                <tr class="background-table-green">
                                    <td *ngIf="showSelectColumn" style="max-width:40px;">

                                        <input type="checkbox" (click)="addRemoveFromParent('tr-fase-finanziamento-' + finanziamento.codice,$event,finanziamento)">
                                    </td>
                                    <td colspan="8" class="fixedme" style="max-width: 60px;">
                                        <span class="font-weight-bold "><small class="bold">{{ 'finanziamento ' + finanziamento.codice  }}</small></span>
                                        <!-- </a>-->
                                        <div class="" style="font-size: 13px; font-weight: normal;">
                                            <small>{{ finanziamento.titolo}}</small></div>
                                    </td>
                                    <!-- <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                     <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                    <td>&nbsp;</td>-->
                                </tr>
                                <!--test-->


                                <!--fine scrivo il finanziamento senza la fase come "intestazione"-->


                                <ng-container *ngFor="let fase of finanziamento.fasi;let j=index;">
                                    <tr class="background-table-green-40 tr-fase-finanziamento-{{finanziamento.codice}}">
                                        <td *ngIf="showSelectColumn" style="max-width:40px;"><input type="checkbox" (click)="addRemoveSelection(fase)"></td>
                                        <td class="fixedme" style="max-width: 290px;">
                                            <!--<a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')" class="pointer font-weight-bold text-0083e0"
                                            data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.tr_collapse_' + i" [attr.data-target]="'.tr_collapse_' + i">-->
                                            <span class="font-weight-bold ">&nbsp;</span>
                                            <!-- </a>-->
                                            <div class="" style="font-size: 13px; font-weight: normal;">
                                            </div>
                                        </td>
                                        <td>
                                            <span popover="{{fase.tipologiaFase.fase + ' ' + fase.tipologiaFase.titolo + ' ' + fase.tipologiaFase.descrizione}}" placement="auto" triggers="mouseenter:mouseleave"><small>{{trimText(fase.tipologiaFase.fase + ' ' + fase.tipologiaFase.titolo + ' ' + fase.tipologiaFase.descrizione,100)}}</small></span>
                                        </td>
                                        <td class="text-center">
                                            <small>&nbsp;{{printStatoFase(fase)}}&nbsp;</small>
                                            <div class="help p-2 rounded shadow-sm justify-content-center {{getColorStatoFase(fase)}} " title="gravità: {{getColorStatoFase1(fase)}}"></div>
                                        </td>
                                        <td>
                                            <small>{{printDate(fase.dataPrevistaInizio)}}</small>

                                        </td>
                                        <td>
                                            <small>{{printDate(fase.dataInizio)}}</small>

                                        </td>
                                        <!--<td>
                      {{printScostamentoRitardo(fase)}}
                      <a type="button" class="help"
                        popover="Scostamento calcolato dal {{printValueAsDateWithCurrentDateAsDefault(fase.dataInizio)}}"
                        placement="bottom" triggers="mouseenter:mouseleave">
                        <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                        </i>
                      </a>

                    </td>-->
                                        <td>
                                            <small>{{printDate(fase.dataPrevistaFine)}}</small>

                                        </td>
                                        <td>
                                            <small>{{printDate(fase.dataFine)}}</small>

                                        </td>
                                        <!--<td>
                      {{printScostamentoScaduto(fase)}}
                      <a type="button" class="help"
                        popover="Scostamento calcolato dal {{printValueAsDateWithCurrentDateAsDefault(fase.dataFine)}}"
                        placement="bottom" triggers="mouseenter:mouseleave">
                        <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                        </i>
                      </a>
                    </td>-->
                                        <td>
                                            <ul style="min-width:180px; " class="pl-1">
                                                <li *ngFor="let val of getSoggettoResponsabileText(fase)"><small style="line-height: 1.1!important;">{{val}}</small></li>
                                            </ul>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <tr class="background-table-green">
                                    <td class="fixedme" style="max-width: 290px;">
                                        <!--<a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')" class="pointer font-weight-bold text-0083e0"
                                        data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.tr_collapse_' + i" [attr.data-target]="'.tr_collapse_' + i">-->
                                        <small class="bold">finanziamento {{finanziamento.codice}}</small>
                                        <!-- </a>-->
                                        <div class="" style="font-size: 13px;"><small>{{finanziamento.titolo}}</small></div>
                                    </td>
                                    <td>
                                        nessuna fase presente
                                    </td>
                                    <td>
                                        &nbsp;
                                    </td>
                                    <td>
                                        &nbsp;
                                    </td>
                                    <td>
                                        &nbsp;
                                    </td>
                                    <!--<td>
                    &nbsp;
                  </td>-->
                                    <!--<td>
                    &nbsp;
                  </td>-->
                                    <td>
                                        &nbsp;
                                    </td>
                                    <td>
                                        &nbsp;
                                    </td>
                                    <td>
                                        &nbsp;
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>

                    </ng-container>


                </ng-container>

                <!--progetti / interventi-->
                <ng-container *ngIf="currentObject && currentObject.progetti as progetti">
                    <ng-container *ngFor="let progetto of progetti;let i=index;">


                        <ng-container [ngSwitch]="((progetto.fasi && progetto.fasi.length>0)|| (progetto.fasiProgetto && progetto.fasiProgetto.length>0 ) ) && 'hasFasi'">

                            <ng-container *ngSwitchCase="'hasFasi'">
                                <!--scrivo la riga del progetto-->
                                <tr class="">
                                    <td *ngIf="showSelectColumn" style="max-width:40px;"><input type="checkbox" (click)="addRemoveFromParent('tr-fase-intervento-' + progetto.codice,$event,progetto)"></td>
                                    <td colspan="8" class="fixedme" style=" max-width: 290px;">
                                        <a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')" class="pointer font-weight-bold text-0083e0"
                                            data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.tr_collapse_' + i" [attr.data-target]="'.tr_collapse_' + i">
                                            <i class="fas fa-arrow-circle-down">
                          </i><small class="bold">&nbsp;{{ 'progetto/fabbisogno ' + progetto.codice }}</small>
                                        </a>
                                        <div class="" style="font-size: 13px;"><small>{{progetto.titolo}}</small></div>
                                    </td>

                                    <!--<td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>-->
                                </tr>

                                <!--fine scrivo riga del progetto-->

                                <ng-container *ngFor="let fase of getFasi(progetto) ;let j=index;">
                                    <tr class="background-table-grey-10 tr-fase-intervento-{{progetto.codice}}">
                                        <td *ngIf="showSelectColumn" style="max-width:40px;"><input type="checkbox" (click)="addRemoveSelection(fase)"></td>
                                        <td class="fixedme" style="max-width: 290px;">
                                            &nbsp;
                                        </td>
                                        <td>
                                            <span popover="{{fase.tipologiaFase.fase + ' ' + fase.tipologiaFase.titolo + ' ' + fase.tipologiaFase.descrizione}}" placement="auto" triggers="mouseenter:mouseleave"><small>{{trimText(fase.tipologiaFase.fase + ' ' + fase.tipologiaFase.titolo + ' ' + fase.tipologiaFase.descrizione,100)}}</small></span>
                                        </td>
                                        <td class="text-center">
                                            <small>{{printStatoFase(fase)}}</small>
                                            <div class="help text-center p-2 rounded shadow-sm justify-content-center {{getColorStatoFase(fase)}}" title="gravità: {{getColorStatoFase1(fase)}}"></div>
                                        </td>
                                        <td>

                                            <small>{{printDate(fase.dataPrevistaInizio)}}</small>

                                        </td>
                                        <td>
                                            <small>{{printDate(fase.dataInizio)}}</small>

                                        </td>
                                        <!--<td>
                      {{printScostamentoRitardo(fase)}}
                      <a type="button" class="help"
                        popover="Scostamento calcolato dal {{printValueAsDateWithCurrentDateAsDefault(fase.dataInizio)}}"
                        placement="bottom" triggers="mouseenter:mouseleave">
                        <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                        </i>
                      </a>

                    </td>-->
                                        <td>
                                            <small>{{printDate(fase.dataPrevistaFine)}}</small>

                                        </td>
                                        <td>
                                            <small>{{printDate(fase.dataFine)}}</small>

                                        </td>
                                        <!--<td>
                      {{printScostamentoScaduto(fase)}}
                      <a type="button" class="help"
                        popover="Scostamento calcolato dal {{printValueAsDateWithCurrentDateAsDefault(fase.dataFine)}}"
                        placement="bottom" triggers="mouseenter:mouseleave">
                        <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                        </i>
                      </a>
                    </td>-->
                                        <td>
                                            <ul style="min-width:180px; " class="pl-2">
                                                <li *ngFor="let val of getSoggettoResponsabileText(fase)"><small style="line-height: 1.1!important;">{{val}}</small></li>
                                            </ul>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <tr>
                                    <td *ngIf="showSelectColumn" style="max-width:40px;"><input type="checkbox" (click)="addRemoveFromParent('tr-fase-intervento-' + progetto.codice,$event,progetto)"></td>
                                    <td class="fixedme" style="max-width: 290px;">
                                        <a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')" class="pointer font-weight-bold text-0083e0"
                                            data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.tr_collapse_' + i" [attr.data-target]="'.tr_collapse_' + i">
                                            <i class="fas fa-arrow-circle-down"></i><small class="bold">&nbsp;progetto/fabbisogno {{progetto.codice}}</small>
                                        </a>
                                        <div class="" style="font-size: 13px;"><small>{{progetto.titolo}}</small></div>
                                    </td>
                                    <td>
                                        nessuna fase presente
                                    </td>
                                    <td>
                                        &nbsp;
                                    </td>
                                    <td>
                                        &nbsp;
                                    </td>
                                    <td>
                                        &nbsp;
                                    </td>
                                    <!--<td>
                    &nbsp;
                  </td>-->
                                    <!--<td>
                    &nbsp;
                  </td>-->
                                    <td>
                                        &nbsp;
                                    </td>
                                    <td>
                                        &nbsp;
                                    </td>
                                    <td>
                                        &nbsp;
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="progetto && progetto.interventi">
                            <ng-container *ngFor="let intervento of progetto.interventi;let j = index;">

                                <ng-container [ngSwitch]="intervento.fasi && intervento.fasi.length>0 && 'hasFasiIntervento'">

                                    <ng-container *ngSwitchCase="'hasFasiIntervento'">

                                        <!--scrivo la riga dell'intervento-->
                                        <tr class="background-table collapse {{'tr_collapse_' + i}} tr-fase-intervento-{{progetto.codice}}">
                                            <td *ngIf="showSelectColumn" style="max-width:40px;"><input type="checkbox" (click)="addRemoveFromParent('tr-fase-intervento-' + intervento.codice,$event,intervento)"></td>
                                            <td colspan="8" class="fixedme" style="max-width: 60px;">
                                                <span class="font-weight-bold "><small class="bold">intervento {{ intervento.codice}}</small></span>

                                                <div class="" style="font-size: 13px; font-weight: normal;">
                                                    <small>{{ intervento.titolo}}</small></div>
                                            </td>
                                            <!--<td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>-->
                                        </tr>

                                        <!--fine scrivo riga dell'intervento-->

                                        <ng-container *ngFor="let faseIntervento of intervento.fasi;let k=index;">
                                            <tr class="background-table-03 collapse {{'tr_collapse_' + i}}  tr-fase-intervento-{{intervento.codice}}">
                                                <td *ngIf="showSelectColumn" style="max-width:40px;"><input type="checkbox" (click)="addRemoveSelection(faseIntervento)"></td>
                                                <th class="fixedme" style="max-width: 290px;" scope="row">

                                                    &nbsp;

                                                    <div class="" style="font-size: 13px; font-weight: normal;">
                                                    </div>
                                                </th>
                                                <td>
                                                    <small>{{faseIntervento.tipologiaFase.fase + ' ' + faseIntervento.tipologiaFase.titolo + ' ' + faseIntervento.tipologiaFase.descrizione}}</small>
                                                </td>
                                                <td class="text-center">
                                                    <small>{{printStatoFase(faseIntervento)}}</small>
                                                    <div class="help p-2  rounded shadow-sm justify-content-center {{getColorStatoFase(faseIntervento)}} " title="gravità: {{getColorStatoFase1(faseIntervento)}}"></div>
                                                </td>
                                                <td>
                                                    <small>{{printDate(faseIntervento.dataPrevistaInizio)}}</small>

                                                </td>
                                                <td>
                                                    <small>{{printDate(faseIntervento.dataInizio)}}</small>

                                                </td>
                                                <!--<td>
                          {{printScostamentoRitardo(faseIntervento)}}
                          <a type="button" class="help"
                            popover="Scostamento calcolato dal {{printValueAsDateWithCurrentDateAsDefault(faseIntervento.dataInizio)}}"
                            placement="bottom" triggers="mouseenter:mouseleave">
                            <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                            </i>
                          </a>

                        </td>-->
                                                <td>
                                                    <small>{{printDate(faseIntervento.dataPrevistaFine)}}</small>

                                                </td>
                                                <td>
                                                    <small>{{printDate(faseIntervento.dataFine)}}</small>

                                                </td>
                                                <!--<td>
                          {{printScostamentoScaduto(faseIntervento)}}
                          <a type="button" class="help"
                            popover="Scostamento calcolato dal {{printValueAsDateWithCurrentDateAsDefault(faseIntervento.dataFine)}}"
                            placement="bottom" triggers="mouseenter:mouseleave">
                            <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                            </i>
                          </a>
                        </td>-->
                                                <td>
                                                    <ul style="min-width:180px; " class="pl-2">
                                                        <li *ngFor="let val of getSoggettoResponsabileText(faseIntervento)"><small style="line-height: 1.1!important;">{{val}}</small></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <tr class="background-table collapse {{'tr_collapse_' + i}}">
                                            <td *ngIf="showSelectColumn" style="max-width:40px;">&nbsp;</td>
                                            <th class="fixedme" style="max-width: 290px;" scope="row">
                                                <small class="bold">intervento {{intervento.codice}}</small>
                                                <div class="" style="font-size: 13px;"><small>{{intervento.titolo}}</small></div>
                                            </th>
                                            <td>
                                                nessuna fase presente
                                            </td>
                                            <td>
                                                &nbsp;
                                            </td>
                                            <td>
                                                &nbsp;
                                            </td>
                                            <td>
                                                &nbsp;
                                            </td>
                                            <td>
                                                &nbsp;
                                            </td>
                                            <!--<td>
                        &nbsp;
                      </td>-->
                                            <!--<td>
                        &nbsp;
                      </td>-->
                                            <td>
                                                &nbsp;
                                            </td>
                                            <td>
                                                &nbsp;
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                    </ng-container>


                </ng-container>

                <!--nessun risultato-->
                <ng-container *ngIf="!(currentObject && ((currentObject.progetti && currentObject.progetti.length>0) || (currentObject.finanziamenti && currentObject.finanziamenti.length>0)))">
                    <tr>
                        <td colspan="20">Nessun progetto / fabbisogno / finanziamento presente</td>
                    </tr>
                </ng-container>
            </tbody>




        </table>

    </div>
</div>