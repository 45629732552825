import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    ElementRef,
    TemplateRef
} from "@angular/core";
import { registerLocaleData } from '@angular/common';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, FormControl } from "@angular/forms";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {   TipologiaFaseService, SoggettoResponsabileService
    ,FaseService,InterventoService,TipologiaSoggettoResponsabileService,StorageService
} from '../../services';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { Fase, Finanziamento, TipologiaFase } from '../../models';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from '../../../../components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component'
import localeIt from '@angular/common/locales/it';
import { GenericSelectionModalComponent } from '../genericSelectionModal/generic-selection-modal.component';
import { Subject } from "rxjs";
registerLocaleData(localeIt, 'it');
defineLocale('it', itLocale);
import IMask from 'imask';

@Component({
    selector: 'gestione-massiva-fasi-component',
    templateUrl: './gestione-massiva-fasi.component.html',
    styleUrls: ['./gestione-massiva-fasi.component.css']
})
export class GestioneMassivaFasiComponent implements OnInit, OnDestroy {

    public mask: any = {
        mask: 'Z KK',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '',
                scale: 0,
                padFractionalZeros: false,
                radix: ','
            },
            Z:{
                mask:IMask.MaskedEnum,
                enum:['+','-']
            }
        }
    };

    ngOnDestroy(): void {
        this.clearSubscriptions();
        if(this.elencoAreeSubscription)this.elencoAreeSubscription.unsubscribe();
    }

    submitted: boolean = false;
    loading: boolean = false;
    fasi: Fase[];
    readOnlyForm: boolean = false;

    projectId: number;
    private _finanziamentoId: number;
    private _interventoId: number;

    modalSubscriptions = [];
    //@ViewChild('buttonsTemplate',{static:false}) buttonsTemplate: TemplateRef<any>;
    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;



    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    @ViewChild('hideTemplate', { static: true })
    public hideTemplate: TemplateRef<any>;
    @ViewChild('soggettirespoTemplate', { static: true })
    public soggettirespoTemplate: TemplateRef<any>;


    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    @ViewChild('ngxDatatableWrapperSogResp', { static: true })
    ngxDatatableWrapperSogResp: NgxDatatableWrapperComponent;


    templateList = [];

    dtOptions: any;
    dtOptionsSoggResp: any;
    rows: any[];
    columns = [
        {
            name: '', prop: 'id', sortable: true, filtrable: false, maxWidth: 0, width: 0, visible: false
            // 'buttonsTemplate'
        }

        , {
            name: 'tipologia'
            , prop: 'tipologia'
            , sortable: true
            , filtrable: true
            , minWidth: 200
            , flexGrow: 4
        }
        , {
            name: 'data<br />prevista inizio'
            , prop: 'dataPrevistaInizio'
            , sortable: true, filtrable: true
            , cellTemplate: 'dateValueTemplate'
            , minWidth: 100
            , flexGrow: 2
        }
        , {
            name: 'data inizio'
            , prop: 'dataInizio'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'dateValueTemplate'
            , minWidth: 100
            , flexGrow: 2
        }
        , {
            name: 'data<br />prevista fine'
            , prop: 'dataPrevistaFine'
            , sortable: true, filtrable: true
            , cellTemplate: 'dateValueTemplate'
            , minWidth: 100
            , flexGrow: 2
        }
        , {
            name: 'data fine'
            , prop: 'dataFine'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'dateValueTemplate'
            , minWidth: 100
            , flexGrow: 2
        }
        , {
            name: 'soggetti<br />responsabili'
            , prop: 'soggettiResponsabiliDescrizione'
            , sortable: false
            , filtrable: true
            , cellTemplate: 'soggettirespoTemplate'
            , minWidth: 100
            , flexGrow: 2
        }
        , {
            name: 'modifica'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , minWidth: 60
            , flexGrow: 1

        }
        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , minWidth: 60
            , flexGrow: 1

        }
    ];
    columnsSogResp = [
        , {
            name: ''
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnSelectSoggResp'// 'buttonsTemplate'
            , width: 10

        },
        {
            name: 'id', prop: 'id', sortable: true, filtrable: true, width: 10
        }
        , { name: 'cognome', prop: 'cognome', sortable: true, filtrable: true }
        , { name: 'nome', prop: 'nome', sortable: true, filtrable: true }
        , { name: 'email', prop: 'email', sortable: true, filtrable: true }
        , { name: 'tipologia', prop: 'tipologia.descrizione', sortable: true, filtrable: true }
    ];



    modalTitle: string = '';
    modalButtonLabel: string = '';

    modalRef: BsModalRef;
    modalRefSogResp: BsModalRef;

    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };

    tipologieFase = Array<TipologiaFase>();

    editForm: FormGroup;
    filterForm:FormGroup;

    soggettiResponsabili = [];
    allSoggettiResponsabili = [];
    templateListSogResp = [];

    soggettoResponsabileIsError: boolean = false;

    @ViewChild('btnSelectSoggResp', { static: true })
    public btnSelectSoggResp: TemplateRef<any>;



    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;

    @ViewChild('templateModalSogResp', { static: true })
    public templateModalSogResp: TemplateRef<any>;

    constructor(
        private toastr: ToastService
        , private modalService: BsModalService
        , private tipologiaFaseService: TipologiaFaseService
        , private localeService: BsLocaleService
        , private formBuilder: FormBuilder
        , private el: ElementRef,
        private soggettoResponsabileService: SoggettoResponsabileService
        , private faseService: FaseService        
        ,private tipologiaSoggettoResponsabileService:TipologiaSoggettoResponsabileService
        ,private storageService:StorageService
    ) {
        this.localeService.use("it");
        this.dtOptions = {
            rows: this.rows
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: false// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: false// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: false
            , scrollbarH: "true"
        };
        this.dtOptionsSoggResp = {
            rows: this.allSoggettiResponsabili
            , columns: this.columnsSogResp
            , columnMode: ColumnMode.force
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: false// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: false// true
            , count: this.allSoggettiResponsabili.length
            , offset: 0
            , limit: 10
            , serverSide: false
        };
        this.editForm = this.initializeFormGroup();
        this.filterForm = this.initializeFilterFormGroup();
    }

    setIsDirty(value: boolean) {
        return;
    }


    initializeFilterFormGroup():FormGroup{
        let frm = this.formBuilder.group({
            mainObjectTypeFilter: [null],
            /*mainObjectIdFilter: [null],*/
            areeTematicheCombo: [null],
            tipologiaFase: [null],
            tipologiaResponsabile: [null]
            /*, soggettoResponsabile: [null]*/
        });
        return frm;
    }


    initializeFormGroup(): FormGroup {
        let frm = this.formBuilder.group({
            tipologia: [null],
            dataInizio: [null],
            dataFine: [null],
            dataPrevistaInizio: [null],
            dataPrevistaFine: [null]
            , note: [null]
            ,dataPrevistaInizioGiorni:[null]
            ,dataInizioGiorni:[null]
            ,dataPrevistaFineGiorni:[null]
            ,dataFineGiorni:[null]
        });
        frm.controls.dataPrevistaFine.valueChanges.subscribe(
            valore=>{
                frm.controls.dataPrevistaFineGiorni.setValue(null);
                if(valore && valore!='')frm.controls.dataPrevistaFineGiorni.disable();
                else frm.controls.dataPrevistaFineGiorni.enable();
            }
        );
        frm.controls.dataPrevistaInizio.valueChanges.subscribe(
            valore=>{
                frm.controls.dataPrevistaInizioGiorni.setValue(null);
                if(valore && valore!='')frm.controls.dataPrevistaInizioGiorni.disable();
                else frm.controls.dataPrevistaInizioGiorni.enable();
            }
        );
        frm.controls.dataInizio.valueChanges.subscribe(
            valore=>{
                frm.controls.dataInizioGiorni.setValue(null);
                if(valore && valore!='')frm.controls.dataInizioGiorni.disable();
                else frm.controls.dataInizioGiorni.enable();
            }
        );
        frm.controls.dataFine.valueChanges.subscribe(
            valore=>{
                frm.controls.dataFineGiorni.setValue(null);
                if(valore && valore!='')frm.controls.dataFineGiorni.disable();
                else frm.controls.dataFineGiorni.enable();
            }
        );
        /* frm.valueChanges.subscribe(val=>
             this.setIsDirty(frm.dirty)
             );*/
        return frm;
    }


    checkDate(startDateCtrlName: any): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            //console.log('inside checkDate');
            //  console.log(`startDateCtrlName=${startDateCtrlName}`);
            if (!control || !control.parent) return null;
            if (!control.value || control.value == '') return null;

            let mainCtrl = control.parent.get(startDateCtrlName) as FormControl;
            //console.log(`mainctrl = ${mainCtrl}`);
            // console.log(`mainctrl.value = ${mainCtrl.value}`);
            if (!mainCtrl.value ||
                mainCtrl.value == ''
                || control.value < mainCtrl.value)
                return { checkDate: true };

            return null;

        }
    }


    get f() {
        return this.editForm.controls;
    }

    ngOnInit() {
        this.templateList = [
            { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }
            , { name: 'btnEditTemplate', template: this.btnEditTemplate }
            , { name: 'hideTemplate', template: this.hideTemplate }
            , { name: 'soggettirespoTemplate', template: this.soggettirespoTemplate }
        ];
        this.templateListSogResp = [{ name: 'btnSelectSoggResp', template: this.btnSelectSoggResp }];
        window.scroll(0, 0);
        //this.getTipologieFase();
        this.getSoggettiResponsabili();
        // this.gridOptions=this.createGridOptions();

        //this.loadFasi();

        this.getTipologieFase();

        this.getAreeTematiche();
        this.getTipologieResponsabili();
    }




    loadDataCounter: number = 0;
    reloadData() {
        this.loadDataCounter = 0;

    }

    getTipologieResponsabili() {
        this.tipologiaSoggettoResponsabileService.getAll().subscribe((result) => {
          this.tipologieResponsabiliCombo = result;
        });
      }




    getTipologieFase() {
        this.tipologiaFaseService.getAll().subscribe(
            result => {
                result.forEach(x => x['descrizioneEstesa'] = `${x.fase} - ${x.titolo} - ${x.descrizione}`);
                this.tipologieFase = result;

            }
        );
    }

    getSoggettiResponsabili() {
        this.soggettoResponsabileService.getAll().subscribe(
            result => {
                this.allSoggettiResponsabili = result;
                this.allSoggettiResponsabili.forEach((element) => {
                    element["label"] = `${element.cognome} ${element.nome}`;
                  });
                this.completerParamsSoggetti = {
                    items: JSON.parse(JSON.stringify(this.allSoggettiResponsabili)),
                    labelField: "label",
                    ajax: null,
                    placeholder: "Cerca soggetto resposabile ",
                  };
            }
        );
    }


    codesSelected=null;
    flagSoggResp =true;//true = sostituisci
    panelClass="";


    codesSelectedChanged(codes){
       // console.log(codes);
        this.codesSelected = codes;
    }

    changeFlagSoggResp(){
        this.flagSoggResp = !this.flagSoggResp;
        this.panelClass = this.flagSoggResp?"":"card-header-info";
    }

    fasiSelectedChanged(fasi){
        //controllo se tutte le fasi selezionate hanno gli stessi valori e riempio la form dei valori di conseguenza
        let tipologiaIsTheSame = true;
        let dataPrevistaInizioIsTheSame = true;
        let dataPrevistaFineIsTheSame = true;
        let dataInizioIsTheSame = true;
        let dataFineIsTheSame = true;
        let noteIsTheSame = true;
        let soggettiResponsabiliIsTheSame = true;
        if (!fasi || fasi.length<=0)return;
        if (fasi.length>1){
            for(let i = 0;i<fasi.length;i++){
                let primaFase = fasi[0];
                let faseCorrente = fasi[i];
                if (primaFase.tipologiaFase.id != faseCorrente.tipologiaFase.id)tipologiaIsTheSame = false;
                if (primaFase.note != faseCorrente.note)noteIsTheSame = false;
                if (
                    !primaFase.dataPrevistaInizio
                    || !faseCorrente.dataPrevistaInizio
                    || !moment(primaFase.dataPrevistaInizio).isSame(faseCorrente.dataPrevistaInizio)
                    ) dataPrevistaInizioIsTheSame = false;
                if (
                    !primaFase.dataPrevistaFine
                    || !faseCorrente.dataPrevistaFine
                    || !moment(primaFase.dataPrevistaFine).isSame(faseCorrente.dataPrevistaFine)
                    ) dataPrevistaFineIsTheSame = false;
                if (
                    !primaFase.dataInizio
                    || !faseCorrente.dataInizio
                    || !moment(primaFase.dataInizio).isSame(faseCorrente.dataInizio)
                    ) dataInizioIsTheSame = false;
                if (
                    !primaFase.dataFine
                    || !faseCorrente.dataFine
                    || !moment(primaFase.dataFine).isSame(faseCorrente.dataFine)
                    ) dataFineIsTheSame = false;

                if (primaFase.soggettiResponsabili.length ==0 || primaFase.soggettiResponsabili.length!= faseCorrente.soggettiResponsabili.length)soggettiResponsabiliIsTheSame = false;
                else{
                    primaFase.soggettiResponsabili.forEach(
                        sg=> {
                            if (!faseCorrente.soggettiResponsabili.find(x=>x.id == sg.id))soggettiResponsabiliIsTheSame = false;
                        }
                    );
                }

            }
        }
        //per ogni true imposto il valore del form
        if (tipologiaIsTheSame)
            this.editForm.controls.tipologia.setValue(fasi[0].tipologiaFase.id);
        else
             this.editForm.controls.tipologia.setValue(null);
        if (noteIsTheSame)
            this.editForm.controls.note.setValue(fasi[0].note);
        else
            this.editForm.controls.note.setValue(null);

        if (dataPrevistaInizioIsTheSame && fasi[0].dataPrevistaInizio){
            let dataI =null;
            dataI = moment(fasi[0].dataPrevistaInizio).toDate();
            dataI.setHours(dataI.getHours() - dataI.getTimezoneOffset() / 60);
            this.editForm.controls.dataPrevistaInizio.setValue(dataI);
        }
        else
            this.editForm.controls.dataPrevistaInizio.setValue(null);

        if (dataInizioIsTheSame && fasi[0].dataInizio){
            let dataI =null;
            dataI = moment(fasi[0].dataInizio).toDate();
            dataI.setHours(dataI.getHours() - dataI.getTimezoneOffset() / 60);
            this.editForm.controls.dataInizio.setValue(dataI);
        }
        else
            this.editForm.controls.dataInizio.setValue(null);

        if (dataPrevistaFineIsTheSame && fasi[0].dataPrevistaFine){
            let dataI =null;
            dataI = moment(fasi[0].dataPrevistaFine).toDate();
            dataI.setHours(dataI.getHours() - dataI.getTimezoneOffset() / 60);
            this.editForm.controls.dataPrevistaFine.setValue(dataI);
        }
        else
            this.editForm.controls.dataPrevistaFine.setValue(null);

        if (dataFineIsTheSame && fasi[0].dataFine){
            let dataI =null;
            dataI = moment(fasi[0].dataFine).toDate();
            dataI.setHours(dataI.getHours() - dataI.getTimezoneOffset() / 60);
            this.editForm.controls.dataFine.setValue(dataI);
        }
        else
            this.editForm.controls.dataFine.setValue(null);

        if (soggettiResponsabiliIsTheSame && fasi[0].soggettiResponsabili.length>0){
            this.soggettiResponsabili = [];
            fasi[0].soggettiResponsabili.forEach(
                sg=>this.soggettiResponsabili.push(this.allSoggettiResponsabili.find(x=>x.id == sg.id))
            );
        }
        else{
            this.soggettiResponsabili = [];
        }

    }


    onSubmit() {
        this.submitted = true;
        //this.editForm.updateValueAndValidity();
        if ((!this.codesSelected) || this.codesSelected.length<=0){
            this.toastr.error(`Nessuna fase selezionata`, null, {
                timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
            });
            return;
        }
       // console.log(this.codesSelected);


        //this.soggettoResponsabileIsError = !(this.soggettiResponsabili && this.soggettiResponsabili.length > 0);

       /* for (let c in this.editForm.controls) {
            this.editForm.controls[c].updateValueAndValidity();
        }*/
        let errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;
            /*console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );*/
        }
        if (errors || this.editForm.invalid || this.soggettoResponsabileIsError) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.editForm.controls) {
                if (this.editForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.editForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.editForm.get(el)["controls"]) {
                            if (this.editForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }
        //no errors
        let fase = this.editForm.value;
        fase.dataPrevistaInizio = fase.dataPrevistaInizio ? moment(fase.dataPrevistaInizio).format('YYYY-MM-DD') : null;
        fase.dataInizio = fase.dataInizio ? moment(fase.dataInizio).format('YYYY-MM-DD') : null;
        fase.dataPrevistaFine = fase.dataPrevistaFine ? moment(fase.dataPrevistaFine).format('YYYY-MM-DD') : null;
        fase.dataFine = fase.dataFine ? moment(fase.dataFine).format('YYYY-MM-DD') : null;
        //console.log(`fase.dataPrevistaInizio=${fase.dataPrevistaInizio}, moment=${moment(fase.dataPrevistaInizio).format('YYYY-MM-DD')}`);
        fase.tipologiaFase = new TipologiaFase();
        fase.tipologiaFase.id = fase.tipologia;
        fase.soggettiResponsabili = this.soggettiResponsabili;
        fase.sostituireSoggettiResponsabili = this.flagSoggResp;

        let myService =this.faseService;

        if(!confirm(`Procedere all'aggiornamento massivo delle ${this.codesSelected.length} fasi selezionate?`))return false;
        this.loading = true;
        this.submitted = false;
        myService.updateMassive(fase,this.codesSelected).subscribe(
            result => {
                this.loading = false;
                this.toastr.success('Fasi aggiornate con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                this.editForm.reset();
                this.soggettiResponsabili = [];
                //this.modalRef.hide();
                this.loadFasi();
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile salvare le fasi:${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                console.log('error');
            }
        );

    }

    openAddModal() {
        this.soggettoResponsabileIsError = false;
        this.soggettiResponsabili = [];
        this.submitted = false;
        this.editForm.reset();
        this.modalTitle = 'Nuova fase';
        this.modalButtonLabel = 'Inserisci'
        this.modalRef = this.modalService.show(this.templateEditAdd, this.config);
        this.setIsDirty(false);
    }

    closeModal() {
        this.modalRef.hide();
    }




    printValueAsDate(value) {
        if (!value || value == '') return "";
        return moment(value).format('DD/MM/YYYY');
    }
    printSoggettiResponsabiliDescription(value: string | undefined) {
        if (!value || value == '') return '';
        return value.split('|').join('<br />');
    }

    removeSoggettoResponsabile(item: any) {
        this.soggettiResponsabili = this.soggettiResponsabili.filter(x => x.id != item.id);
        this.setIsDirty(true);
    }

    clearSubscriptions() {
        this.modalSubscriptions.forEach(x => x.unsubscribe());
        this.modalSubscriptions = [];
    }

    addSoggettoResponsabile() {
        //this.modalRefSogResp = this.modalService.show(this.templateModalSogResp, this.config);
        //this.ngxDatatableWrapperSogResp.setInitialData(this.allSoggettiResponsabili);
        let modalDefinition = {
            columns: [
                { title: "Cognome", data: "cognome", searchable: true, orderable: true },
                { title: "Nome", data: "nome", searchable: true, orderable: true },
                { title: "Email", data: "email", searchable: true, orderable: true },
                { title: "Tipologia", data: "tipologia.descrizione", searchable: true, orderable: true }

            ],
            ajax: this.getSoggettiResponsabiliDt
            , pageTitle: 'Seleziona i soggetti responsabili'
        };

        let modalDefinitionNgx = {
            rows: []
            , totalElements: 0
            , columns: [
                {
                    name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
                    , resizeable: false
                }

                , {
                    name: 'cognome'
                    , prop: 'cognome'

                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 100

                }
                , {
                    name: 'nome'
                    , prop: 'nome'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 100
                }
                , {
                    name: 'email'
                    , prop: 'email'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 200
                }
                , {
                    name: 'tipologia'
                    , prop: 'tipologia.descrizione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 3
                    , minWidth: 200
                }
            ]
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , ajax: this.getSoggettiResponsabiliDtNgx
            , scrollbarH: "true"
        };

        const initialState = { modalDefinition: modalDefinition, dtOptionsNgx: modalDefinitionNgx };
        let config = {
            animated: true,
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl"
        };
        this.modalRefSogResp = this.modalService.show(GenericSelectionModalComponent, Object.assign({}, config, { initialState }));
        this.clearSubscriptions();
        let subscription = this.modalRefSogResp.content.onSubmitOk.subscribe(
            data => {
                console.log('inside ok of add resp');
                this.onSelectSoggResp(data);


            }
        );
        this.modalSubscriptions.push(subscription);









    }
    onSelectSoggResp(item) {
        if (this.soggettiResponsabili.find(x => x.id == item.id)) return;
        this.soggettiResponsabili.push(item);
        this.toastr.success('Soggetto responsabile inserito con successo', null, {
            timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
        });
        this.setIsDirty(true);
    }

    getSoggettiResponsabiliDt = (dataTablesParameters: any, callback: any) => {
        this.soggettoResponsabileService.getAllDt(dataTablesParameters)
            .subscribe(resp => {
                callback({
                    recordsTotal: resp.recordsTotal,
                    recordsFiltered: resp.recordsFiltered,
                    data: resp.data
                });
            });
    }
    getSoggettiResponsabiliDtNgx = (dataTablesParameters: any, callback: any) => {
        this.soggettoResponsabileService.getAllDt(dataTablesParameters)
            .subscribe(resp => {
                callback(resp);
            });
    }
    getSoggResp(value) {
        return value.split('|');
    }

    loadFasi(){
        this.codesSelected = [];
        if (
            this.filterObject.mainObjectType=='t'   &&
            this.filterObject.filterAreaTematica ==-1 &&
            this.filterObject.filterTipologiaFase == -1 &&
            this.filterObject.soggettoResponsabileId == -1 &&
            this.filterObject.tipologiaSoggettoResponsabileId == -1 &&
            this.filterObject.mainObjectId==''
        ){
            //svuoto i filtri!!
            this.editForm.reset();
            this.soggettiResponsabili = [];
            this.tabellaDettaglioObj = [];
            this.subjectTabDettaglio.next(this.tabellaDettaglioObj);
            return;
        }
        this.faseService.getAllPhasesUserCanEdit(this.filterObject).subscribe(
            result=>{
                //ricreo l'array degli interventi per ogni progetto perchè adesso ho un intervento per ogni fase e quindi lo vedrei duplicato
                result.progetti.forEach(progetto=>{
                    let interventi = [];
                    progetto.interventi.forEach(intervento=>{
                        let interventoTrovato = interventi.find(x=>x.codice == intervento.codice);
                        if (!interventoTrovato)interventi.push(intervento);
                        else interventoTrovato.fasi = [...interventoTrovato.fasi, ...intervento.fasi];

                    });
                    progetto.interventi = interventi;
                });
                this.tabellaDettaglioObj = result;
                this.subjectTabDettaglio.next(this.tabellaDettaglioObj);
            }
        );
    }


    tabellaDettaglioObj: any;
    tabellaDettaglioObjLoaded = false;
    subjectTabDettaglio = new Subject<any>();
    observable$ = this.subjectTabDettaglio.asObservable();

/*gestione filtri*/
    completerParamsSoggetti = {};

    filterObject = {
        mainObjectType:'t'
        ,filterAreaTematica:-1
        ,filterTipologiaFase:-1
        ,soggettoResponsabileId:-1
        ,tipologiaSoggettoResponsabileId:-1
        ,mainObjectId:''
        ,mainObjectStringFilter:''
    };

    mainTypesCombo: Array<{ k: string; v: string }> = [
        { k: "INTERVENTI", v: "interventi" },
        { k: "PROGETTI", v: "progettualità/fabbisogni" },
        { k: "FINANZIAMENTI", v: "finanziamenti" },
        /*{ k: "t", v: "tutti" },*/
    ];

    areeTematicheCombo:any[];
    tipologieResponsabiliCombo:any[];





    private elencoAreeSubscription = null;
    getAreeTematiche() {
       
        this.elencoAreeSubscription = this.storageService.elencoAreeTematiche.subscribe(
            x=>{
                console.log(x);
                this.areeTematicheCombo = x?x:[];
            }
            );
    }

    setMainObjectTypeFilter($event){
        this.filterObject.mainObjectType = $event ? $event.k : "t";
        this.loadFasi();
    }

    setAreaTematicaFilter($event) {
        this.filterObject.filterAreaTematica = $event ? $event.id : -1;
        this.loadFasi();
      }

      setTipologiaFaseFilter($event){
            this.filterObject.filterTipologiaFase = $event ? $event.id : -1;
            this.loadFasi();
      }

      onChangeSoggettiCompleter($event) {
        !$event.target.value && this.setSoggettiCompleter(null);
      }

      setSoggettiCompleter($event) {
        this.filterObject.soggettoResponsabileId = $event ? $event.id : -1;
        this.loadFasi();
      }

      setTipologiaResponsabile($event) {
        this.filterObject.tipologiaSoggettoResponsabileId = $event ? $event.id : -1;
        //console.log(`tipologia responsabile selezionato: ${$event.descrizione}`);
        this.loadFasi();
      }

      completerParams = {
        labelField: "parentTitle",
        placeholder: "Cerca intervento; prog./fabbisogno; finanz.",
        ajax: this.filterComboMainObjectId,
        context:this,

        items: [],
      };

      setInterventionCompleter($event) {
        //this.f.status.enable();
        if ($event) {
          let type = $event.parentType;
          this.filterObject.mainObjectType = type;
          /*switch(type){
              case "FINANZIAMENTO":
                this.filterObject.mainObjectType = 'FINANZIAMENTI';
                  break;
            default:
                this.filterObject.mainObjectType = 'INTERVENTI';
                break;
          }*/
          this.filterObject.mainObjectId = $event.id;
          //this.completerObject = $event;
          /*this.filterObject.mainObjectId = $event
            ? type == "FINANZIAMENTO"
              ? $event.finanziamentoId
              : $event.interventoId
            : -1;*/
        } else {
          this.filterObject.mainObjectId = '';
          this.completerParams.items = [];
          this.filterObject.mainObjectType = 't';
          //this.completerObject = null;
        }
        //this.description = $event && $event.parentTitle;

        this.loadFasi();
      }

      onChangeInterventionCompleter($event) {
        !$event.target.value && this.setInterventionCompleter(null);
      }

      filterComboMainObjectId(filterString,callBack){
            this['context'].faseService.getAllPhasesUserCanEdit(
                {
                    mainObjectType:'t'
                    ,filterAreaTematica:-1
                    ,filterTipologiaFase:-1
                    ,soggettoResponsabileId:-1
                    ,tipologiaSoggettoResponsabileId:-1
                    ,mainObjectId:''
                    ,mainObjectStringFilter:filterString
                }

            ).subscribe(
                result=>{
                    let res = [];
                    if (result.finanziamenti){
                        result.finanziamenti.forEach(

                            finanziamento=>{
                                if(!res.find(x=>x.id == finanziamento.codice))
                                    res.push({
                                    id:finanziamento.codice
                                    ,parentType:'FINANZIAMENTI'
                                    ,parentTitle: finanziamento.codice + ' - ' + finanziamento.titolo});
                            }
                        );
                    }
                    if (result.progetti){
                        result.progetti.forEach(
                            progetto =>{
                                if(!res.find(x=>x.id == progetto.codice)){
                                    //siccome il progetto mi viene reso sempre, devo verificare se il filtro è presente nell adescrizione o nel codice, altrimenti non lo inserisco nell'array
                                    if (progetto.codice.toLowerCase().includes(filterString.toLowerCase()) || progetto.titolo.toLowerCase().includes(filterString.toLowerCase()))
                                        res.push({
                                            id:progetto.codice
                                            ,parentType:'PROGETTI'
                                            ,parentTitle: progetto.codice + ' - ' + progetto.titolo});
                                }
                                if (progetto.interventi){
                                    progetto.interventi.forEach(
                                        intervento=>{
                                            if(!res.find(x=>x.id == intervento.codice))
                                                res.push({
                                                id:intervento.codice
                                                ,parentType:'INTERVENTI'
                                                ,parentTitle: intervento.codice + ' - ' + intervento.titolo});
                                        }
                                    );
                                }
                            }
                        );
                    }
                    callBack(res);
                }
            );
      }



}
