import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import{AuthenticationIAMService} from "src/app/services/authentication-iam.service"

@Component({ 
    selector: "app-iam",
    templateUrl: "./iam.component.html",
    styleUrls: ["./iam.component.css"]

})
export class IamComponent implements OnInit {
    invalidLogin: boolean;
    loading: boolean = false;
    submitted: boolean = false;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private authenticationIAMService:AuthenticationIAMService        
    ) { }

    ngOnInit() {
    
        if (!this.authService.isLoggedIn()&&
            this.route.snapshot.queryParams['code'] ){
            this.getIamToken();               
        }
        else this.router.navigate([""]);


    }

    getIamToken(){
        const code = this.route.snapshot.queryParams['code'];
        const state = this.route.snapshot.queryParams['state'];
        this.authenticationIAMService.getToken(code,state);        
    }


   
    iamLogin(){
        return this.authenticationIAMService.login();
    }
}
