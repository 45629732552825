<div class="p-5 container-xl text-center" [hidden]="loadDataCounter<=0">
    <app-loading message="Caricamento in corso..."></app-loading>
</div>

<div class="container-xl" [hidden]="loadDataCounter>0">
    <!--<div class=" pt-4 elemento-aggiorna">
        <div class=" row  justify-content-center">
            <div class="col-lg-3 col-md-6 text-center" *ngIf="gotoBtnOptions && gotoBtnOptions.show">
                <button (click)="gotoBackBtnClick()" [disabled]="loading" class="btn btn-light btn-block" type="button">
          {{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}
        </button>
            </div>
        </div>
    </div>
    <div class="mb-5">
        <hr />
    </div>-->

    <div class="position-sticky sticky-top  sticky-top-custom pt-4 pb-4 elemento-aggiorna mb-5 shadow-custom"
    [ngClass]="{'sticky-top-custom-sal': esistonoInterventi }"
    >
        <div class=" row  justify-content-center">
            <div class="col-lg-4 col-md-6 text-center" *ngIf="gotoBtnOptions && gotoBtnOptions.show">
                <button (click)="gotoBackBtnClick()" [disabled]="loading" class="btn btn-secondary btn-block font-weight-bold" type="button">
          {{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}&nbsp;<i class="fas fa-times" *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i>
        </button>
            </div>
        </div>

    </div>
    <!--***-->


    <accordion [isAnimated]="true">
        <accordion-group heading="Dati generali">


            <div class="row justify-content-center">
                <div class=" col-lg-6">
                    <table class="table">
                        <tr>
                            <td class="text-right">id:</td>
                            <td>{{intervento?intervento.id:''}}</td>
                        </tr>

                        <tr>
                            <td class="text-right">codice:</td>
                            <td>{{intervento?intervento.codice:''}}</td>
                        </tr>

                        <tr style="vertical-align: top;">
                            <td class="text-right">descrizione:</td>
                            <td style="vertical-align: top;">{{intervento?intervento.descrizione:''}}</td>
                        </tr>
                        <tr>
                            <td class="text-right">anno&nbsp;di&nbsp;riferimento:</td>
                            <td>{{intervento?intervento.annoRiferimento:''}}</td>
                        </tr>
                        <tr>
                            <td class="text-right">codice&nbsp;direzione:</td>
                            <td>{{intervento?intervento.codiceDirezione:''}}</td>
                        </tr>

                    </table>
                </div>

            </div>

            <div class="mb-5 ">
                <hr>
            </div>

            <div class="row justify-content-between">
                <div class=" col-lg-6">
                    <table class="table table-bordered border border-light rounded shadow">
                        <tr>
                            <td colspan="3">
                                <h5><label class="p-1">Aree&nbsp;gestionali&nbsp;direzione&nbsp;e/o&nbsp;esterne</label></h5>
                            </td>
                        </tr>
                        <tr>
                            <th class="">descrizione</th>
                            <th class="">codice ipa</th>
                            <th class="">struttura</th>
                        </tr>
                        <ng-container *ngIf="intervento && intervento.areeTematiche && intervento.areeTematiche.length>0">
                            <tr *ngFor="let item of intervento.areeTematiche">
                                <td>{{item.descrizione}}</td>
                                <td>{{item.codiceIpa}}</td>
                                <td>{{item.struttura}}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!(intervento && intervento.areeTematiche && intervento.areeTematiche.length>0)">
                            <tr>
                                <td colspan="3">Nessuna area selezionata</td>
                            </tr>
                        </ng-container>
                    </table>
                </div>
                <div class=" col-lg-6">
                    <table class="table table-bordered  border border-light rounded shadow">
                        <tr>
                            <td colspan="3">
                                <h5><label class="p-1">Classificazioni</label></h5>
                            </td>
                        </tr>
                        <tr>
                            <th class="">codice</th>
                            <th class="">tipo</th>
                            <th class="">descrizione</th>
                        </tr>

                        <ng-container *ngIf="intervento && intervento.classificazioni && intervento.classificazioni.length>0">
                            <tr *ngFor="let item of intervento.classificazioni" style="vertical-align: top;">
                                <td>{{item.codice}}</td>
                                <td>{{item.tipoClassificazione}}</td>
                                <td>{{item.descrizione}}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!(intervento && intervento.classificazioni && intervento.classificazioni.length>0)">
                            <tr>
                                <td colspan="3">Nessuna classificazione selezionata</td>
                            </tr>
                        </ng-container>

                    </table>
                </div>


            </div>

            <div class="row justify-content-between">


                <div class=" col-lg-6">
                    <table class="table table-bordered border border-light rounded shadow">
                        <tr>
                            <td colspan="4">
                                <h5><label class="p-1">Soggetti&nbsp;giuridici</label></h5>
                            </td>
                        </tr>
                        <tr>
                            <th class="">sigla prov.</th>
                            <th class="">codice</th>
                            <th class="">denominazione</th>
                            <th class="">ruolo</th>
                        </tr>
                        <ng-container *ngIf="intervento && intervento.soggettiGiuridici && intervento.soggettiGiuridici.length>0">
                            <tr *ngFor="let item of intervento.soggettiGiuridici" style="vertical-align: top;">
                                <td>{{item.siglaProvincia}}</td>
                                <td>{{item.codiceIdentificativo}}</td>
                                <td>{{item.denominazione}}</td>
                                <td>{{item.ruolo}}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!(intervento && intervento.soggettiGiuridici && intervento.soggettiGiuridici.length>0)">
                            <tr>
                                <td colspan="4">Nessuna soggetto giuridico selezionato</td>
                            </tr>
                        </ng-container>
                    </table>
                </div>
                <div class=" col-lg-6">
                    <table class="table table-bordered  border border-light rounded shadow">
                        <tr>
                            <td colspan="3">
                                <h5><label class="p-1">Temi prioritari</label></h5>
                            </td>
                        </tr>
                        <tr>

                            <th class="">descrizione</th>
                        </tr>


                        <ng-container *ngIf="intervento && intervento.temiPrioritari && intervento.temiPrioritari.length>0">
                            <tr *ngFor="let item of intervento.temiPrioritari" style="vertical-align: top;">
                                <td>{{item.descrizione}}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!(intervento && intervento.temiPrioritari && intervento.temiPrioritari.length>0)">
                            <tr>
                                <td colspan="">Nessuna tema prioritario selezionato</td>
                            </tr>
                        </ng-container>

                    </table>
                </div>


            </div>

        </accordion-group>
    </accordion>



    <!--tabella interventi-->
    <div class="col-lg-6 col-sm-6 col-6 mt-5">
        <h5 for="" class="">Tabella Interventi</h5>
    </div>
    <div class="container">

        <div class="fixedHeaderTable col-lg-12 mt-3 table-responsive table-wrapper-scroll-y scrollbar-y px-0">
            <table class="table table-hover table-colore">
                <thead class="">
                    <th class="fixedmeY" style="min-width: 230px;"></th>
                    <th style="width: 240px;">fase</th>
                    <ng-container *ngIf="intervento">
                        <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">{{annualita.anno}}</th>
                    </ng-container>
                    <th class="text-right" style="min-width: 150px;">coperto</th>
                    <th class="text-right" style="min-width: 150px;">da coprire</th>
                    <th class="text-right" style="min-width: 180px;">totale</th>
                </thead>
                <tbody>

                    <!--1###-->
                    <ng-container *ngIf="listaInterventi && listaInterventi.length>0">
                        <ng-container *ngFor="let subIntervento of listaInterventi;let i = index">
                            <tr>

                                <td class="fixedme" style="min-width: 230px;">

                                    <a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')" class="pointer font-weight-bold text-0083e0"
                                        data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.tr_collapse_' + i" [attr.data-target]="'.tr_collapse_' + i">
                                        <i class="fas  fa-arrow-circle-down"></i>&nbsp;Intervento {{subIntervento.codice}}
                                    </a>
                                    <div class="" style="font-size: 13px;">{{subIntervento.descrizione}}</div>
                                </td>

                                <td style="min-width: 240px; font-size: 13px;">{{getFaseCorrente(subIntervento)}}</td>
                                <td class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                    {{printValueAsCurrency(getImportoPerAnno(subIntervento,annualita.anno),false)}}&nbsp;€</td>
                                <!--coperto-->
                                <td class="text-right" style="min-width: 150px;">{{printValueAsCurrency(getCoperto(subIntervento),false)}}&nbsp;€</td>
                                <!--da coprire-->
                                <td class="text-right" style="min-width: 150px;">{{printValueAsCurrency(getDaCoprire(subIntervento),false)}}&nbsp;€</td>
                                <!--totale-->
                                <td class="text-right" style="min-width: 180px;">
                                    <strong>{{printValueAsCurrency(getTotale(subIntervento),false)}}&nbsp;€</strong></td>

                            </tr>
                            <ng-container *ngIf="subIntervento && subIntervento.finanziamenti.length>0">

                                <ng-container *ngFor="let finanziamento of subIntervento.finanziamenti;let j = index">

                                    <tr class="background-table collapse {{'tr_collapse_' + i}}">
                                        <th class="fixedme" scope="row" style="min-width: 230px;">
                                            finanziamento {{finanziamento.finanziamento.codice}}
                                            <div class="" style="font-size: 13px; font-weight: normal;">
                                                {{finanziamento.finanziamento.titolo}}</div>
                                        </th>

                                        <td style="min-width: 240px; font-size: 13px;">{{getFaseCorrente(finanziamento)}}</td>

                                        <td class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                            {{printValueAsCurrency(getImportoPerAnno(finanziamento,annualita.anno),false)}}&nbsp;€
                                        </td>
                                        <td style="min-width: 150px;"></td>
                                        <td style="min-width: 150px;"></td>
                                        <td class="text-right" style="min-width: 180px;">
                                            <strong>{{printValueAsCurrency(getTotale(finanziamento),false)}}&nbsp;€</strong></td>
                                    </tr>
                                </ng-container>




                            </ng-container>

                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!(listaInterventi && listaInterventi.length>0)">
                        <tr>
                            <td colspan="20">Nessun intervento presente</td>
                        </tr>
                    </ng-container>


                </tbody>


                <tfoot>
                    <tr>
                        <th class="fixedmefooter">totale</th>
                        <th></th>
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                {{printValueAsCurrency(getTotaleSommaAnno(annualita.anno),false)}}&nbsp;€</th>
                        </ng-container>
                        <th class="text-right">{{printValueAsCurrency(getSommaCopertoTotale(),false)}}&nbsp;€</th>
                        <th class="text-right">{{printValueAsCurrency(getSommaDaCoprireTotale(),false)}}&nbsp;€</th>
                        <th class="text-right">{{printValueAsCurrency(getTotaleSommaInterventi(),false)}}&nbsp;€</th>
                    </tr>
                    <tr>
                        <th class="fixedmefooter">valore progetto {{isGr34OrAdmin()?'/ fabbisogno':''}}</th>
                        <th></th>
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                {{printValueAsCurrency(getImportoPerAnno(intervento,annualita.anno),false)}}&nbsp;€</th>
                        </ng-container>
                        <th class="text-right">{{printValueAsCurrency(getCoperto(intervento),false)}}&nbsp;€</th>
                        <th class="text-right">{{printValueAsCurrency(getDaCoprire(intervento),false)}}&nbsp;€</th>

                        <th class="text-right">{{printValueAsCurrency(getTotale(intervento),false)}}&nbsp;€</th>
                    </tr>
                    <tr>
                        <th class="fixedmefooter">capacità residua progetto {{isGr34OrAdmin()?'/ fabbisogno':''}}</th>
                        <th></th>
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                {{printValueAsCurrency(getImportoPerAnno(intervento,annualita.anno) - totalePerAnnoSommaInterventiList['a'+ annualita.anno] ,false)}}&nbsp;€
                            </th>
                        </ng-container>
                        <th class="text-right" style="min-width: 150px;">{{printValueAsCurrency(getDisponibilitaCoperto(),false)}}&nbsp;€</th>
                        <th class="text-right" style="min-width: 150px;">{{printValueAsCurrency(getDisponibilitaDaCoprire(),false)}}&nbsp;€</th>

                        <th class="text-right">{{printValueAsCurrency(getTotaleCapacitaResiduaProgetto(),false)}}&nbsp;€</th>
                    </tr>
                </tfoot>
            </table>
        </div>


        <div class="mt-5">
            <hr>
        </div>
    </div>

    <!--TABELLA FINAZIAMENTI-->

    <div class="col-lg-6 col-sm-6 col-6 mt-5">
        <h5 for="" class="">Tabella Finanziamenti</h5>
    </div>
    <div class="container">

        <div class="fixedHeaderTable col-lg-12 mt-3 table-responsive scrollbar-y px-0">
            <table class="table table-hover table-colore">
                <thead class="">
                    <th class="fixedmeY" style="width: 230px;"></th>
                    <th style="width: 240px;">fase</th>
                    <ng-container *ngIf="intervento && intervento.finanziamenti">
                        <th class="text-right" style="min-width: 150px;" *ngFor="let anno of getAnniFinanziamento()">{{anno}}</th>
                    </ng-container>
                    <th class="text-right" style="min-width: 180px;">totale</th>
                </thead>
                <tbody>

                    <!--1###-->
                    <ng-container *ngIf="intervento && intervento.finanziamenti">
                        <ng-container *ngFor="let finanziamento of intervento.finanziamenti;let i=index">
                            <!--[ngClass]="{'bg-danger': i%2==0,'bg-success':i%2!=0}"-->
                            <tr>

                                <td class="fixedme" style="width: 230px;">
                                    <a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')" class="pointer font-weight-bold text-0083e0"
                                        data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.tr_collapse_fin_' + i" [attr.data-target]="'.tr_collapse_fin_' + i">
                                        <i class="fas  fa-arrow-circle-down"></i>&nbsp;finaziamento {{finanziamento.finanziamento.codice}}
                                    </a>
                                    <div class="" style="font-size: 13px;">{{finanziamento.finanziamento.titolo}}</div>
                                </td>

                                <td style="width: 240px; font-size: 13px;">{{getFaseCorrente(finanziamento.finanziamento)}}</td>

                                <td class="text-right" style="min-width: 150px;" *ngFor="let anno of getAnniFinanziamento()">
                                    <!--commento perche questo è il valore complessivo del finanziamento, non quello associato al progetto {{printValueAsCurrency(getImportoPerAnno(finanziamento.finanziamento,anno),false)}}&nbsp;€</td>-->
                                    {{printValueAsCurrency(getImportoPerAnno(finanziamento,anno),false)}}&nbsp;€
                                </td>

                                <td class="text-right" style="min-width: 180px;">
                                    <!--commento perche questo è il valore complessivo del finanziamento, non quello associato al progetto  <strong>{{printValueAsCurrency(getTotale(finanziamento.finanziamento),false)}}&nbsp;€</strong>-->
                                    <strong>{{printValueAsCurrency(getTotale(finanziamento),false)}}&nbsp;€</strong>
                                </td>

                            </tr>

                            <!--righe interventi-->
                            <ng-container *ngFor="let intervento of getInterventiByFinanziamento(finanziamento.finanziamento.id);let j = index">
                                <tr class="background-table collapse {{'tr_collapse_fin_' + i}}">
                                    <th class="fixedme" scope="row">
                                        intervento {{intervento.codice}}
                                        <div class="" style="font-size: 13px; font-weight: normal;">{{intervento.descrizione}}</div>
                                    </th>

                                    <td style=" font-size: 13px;">{{getFaseCorrente(intervento)}}</td>

                                    <td class="text-right" *ngFor="let anno of getAnniFinanziamento()">
                                        {{printValueAsCurrency(getImportoPerAnno(getFinanziamentoIntervento(finanziamento.finanziamento.id,intervento),anno),false)}}&nbsp;€
                                    </td>

                                    <td class="text-right">
                                        <strong>{{printValueAsCurrency(getTotale(getFinanziamentoIntervento(finanziamento.finanziamento.id,intervento)),false)}}&nbsp;€</strong>
                                    </td>
                                </tr>
                            </ng-container>



                            <tr>
                                <td colspan="1" class="fixedHeaderTable fixedme bold" style="min-width: 230px;">
                                    destinato
                                </td>
                                <td></td>
                                <td class="text-right" style="min-width: 150px;" *ngFor="let anno of getAnniFinanziamento()">
                                    {{printValueAsCurrency(getUtilizzatoPerAnno(finanziamento.finanziamento.id,anno),false)}}&nbsp;€</td>
                                <td class="text-right" style="min-width: 180px;">
                                    <strong>{{printValueAsCurrency(getTotaleUtilizzato(finanziamento.finanziamento.id),false)}}&nbsp;€</strong>
                                </td>
                            </tr>
                            <!-- [ngClass]="{'bg-danger': i%2==0,'bg-success':i%2!=0}"-->
                            <tr class="border-custom border-primary">
                                <td colspan="1" class="fixedHeaderTable fixedme bold" style="width: 230px;">
                                    disponibile
                                </td>
                                <td></td>
                                <td class="text-right" style="min-width: 150px;" *ngFor="let anno of getAnniFinanziamento()">
                                    {{printValueAsCurrency(getImportoPerAnno(finanziamento,anno) - getUtilizzatoPerAnno(finanziamento.finanziamento.id,anno),false)}}&nbsp;€
                                </td>
                                <td class="text-right" style="min-width: 180px;">
                                    <strong>{{printValueAsCurrency(getTotale(finanziamento) - getTotaleUtilizzato(finanziamento.finanziamento.id),false)}}&nbsp;€</strong>
                                </td>
                            </tr>



                        </ng-container>
                    </ng-container>


                </tbody>


                <tfoot>
                    <tr>
                        <th class="fixedmefooter">totale destinato</th>
                        <th></th>
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let anno of getAnniFinanziamento()">
                                <!--{{printValueAsCurrency(getTotaleSommaAnnoFinanziamentiAssegnato(anno),false)}}&nbsp;€</th>-->
                                {{printValueAsCurrency(getTotaleUtilizzatoSommaPerAnno(anno),false)}}&nbsp;€</th>

                        </ng-container>
                        <!--<th>{{printValueAsCurrency(getTotaleFinanziamentiUtilizzato(),false)}}&nbsp;€</th>-->
                        <th class="text-right">{{printValueAsCurrency(getTotaleUtilizzatoSomma(),false)}}&nbsp;€</th>
                    </tr>
                    <tr>
                        <th class="fixedmefooter">valore finanziamento</th>
                        <th></th>
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let anno of getAnniFinanziamento()">
                                {{printValueAsCurrency(getTotaleSommaAnnoFinanziamentiAssegnato(anno),false)}}&nbsp;€</th>
                            <!--{{printValueAsCurrency(getTotaleSommaAnnoFinanziamenti(anno),false)}}&nbsp;€</th>-->
                        </ng-container>
                        <!--<th>{{printValueAsCurrency(getTotaleFinanziamenti(),false)}}&nbsp;€</th>-->
                        <th class="text-right">{{printValueAsCurrency(getTotaleFinanziamentiAssegnato(),false)}}&nbsp;€</th>

                    </tr>
                    <tr>
                        <th class="fixedmefooter">disponibilità finanziamento</th>
                        <th></th>
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let anno of getAnniFinanziamento()">
                                {{printValueAsCurrency(getTotaleSommaAnnoFinanziamentiAssegnato(anno)-getTotaleUtilizzatoSommaPerAnno(anno),false)}}&nbsp;€
                            </th>
                        </ng-container>

                        <th class="text-right">{{printValueAsCurrency(getTotaleFinanziamentiAssegnato()-getTotaleUtilizzatoSomma(),false)}}&nbsp;€</th>
                    </tr>
                </tfoot>
            </table>
        </div>


        <div class="mt-5">
            <hr>
        </div>
    </div>


</div>