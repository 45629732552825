<div class="div_contenuti">
    <app-header></app-header>
    <app-alert></app-alert>
    <app-breadcrumb></app-breadcrumb>

    <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>
<app-footer></app-footer>
<app-popover-page></app-popover-page>
<app-scroll-to-top></app-scroll-to-top>
