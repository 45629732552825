import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    TemplateRef,
    OnDestroy
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AreeTematicheService, TipologiaFaseService, StatoAttuazioneService } from '../../services';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Router } from "@angular/router";
import { GenericSelectionModalComponent } from "../genericSelectionModal/generic-selection-modal.component";
declare var $: any;

@Component({
    selector: 'tipologie-fasi-component',
    templateUrl: './tipologie-fasi.component.html',
    styleUrls: ['./tipologie-fasi.component.css']
})
export class TipologieFasiComponent
    implements OnInit, OnDestroy {
    dtOptions: any = {};
    visible = false;
    submitted: boolean = false;
    addSchoolYearForm: FormGroup;
    modalRef: BsModalRef;
    modalRefAreeTematiche: BsModalRef;
    open: boolean = false;

    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;
    @ViewChild('templateModal', { static: true })
    public templateModal: TemplateRef<any>;

    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    @ViewChild('spanTipologiaTemplate', { static: true })
    public spanTipologiaTemplate: TemplateRef<any>;
    //spanTipologiaTemplate

    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    @ViewChild("direzioneFieldValueTemplate", { static: true })
    public direzioneFieldValueTemplate: TemplateRef<any>;

    columns = [
        {
            name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
            , exportable: false
        }
        , {
            name: 'tipologia&ensp;'
            , prop: 'fase'
            , sortable: true
            , filtrable: true
            , flexGrow: 3
            , minWidth: 120


        }
        , {
            name: 'titolo&ensp;'
            , prop: 'titolo'
            , sortable: true
            , filtrable: true
            , flexGrow: 3
            , minWidth: 120


        }
        , {
            name: 'entrata/uscita/PNRR'
            , prop: 'tipologia'
            , sortable: true
            , filtrable: true
            , flexGrow: 3
            , minWidth: 120
        }
        , {
            name: 'descrizione'
            , prop: 'descrizione'
            , sortable: true
            , filtrable: true
            //,cellClass: 'mobile-hidden desktop-hidden'
            //,headerClass: 'mobile-hidden desktop-hidden'
            , flexGrow: 6
            , minWidth: 200
        }
        ,
        {
            name: "aree gestionali",
            prop: "areeTematicheDescrizione",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            cellTemplate: "direzioneFieldValueTemplate",
            flexGrow: 8,
            minWidth: 120,
        }
        , {
            name: 'modifica'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60
            , exportable: false

        }
        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60
            , exportable: false
        }
    ];

    readonly = false;
    templateList = [];

    tipologie = [{ value: 'entrata' }, { value: 'uscita' }, { value: 'PNRR' }];
    constructor(
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private el: ElementRef,
        private localeService: BsLocaleService,
        private toastr: ToastService,
        private modalService: BsModalService,
        private currentService: TipologiaFaseService,
        private router: Router,
        private areeTematicheService: AreeTematicheService,
        private statoAttuazioneService: StatoAttuazioneService
    ) {

        let url = this.router.url;
        this.readonly = url.toLowerCase().includes("managetipologiefasireadonly");
        if (this.readonly) {
            //nascondo le colonne di modifica ed elimina
            this.columns[this.columns.length - 1].visible = false;
            this.columns[this.columns.length - 2].visible = false;
        }
        this.localeService.use("it");
        this.dtOptions = {
            rows: []
            , totalElements: 0
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: false// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: false// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: false
            , scrollbarH: "true"
            , buttons: { excel: this.readonly }
        };
    }
    ngOnDestroy(): void {
        this.clearSubscriptions();
    }

    ngOnInit() {
        this.templateList = [{ name: 'spanTipologiaTemplate', template: this.spanTipologiaTemplate }
            , { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }
            , { name: 'btnEditTemplate', template: this.btnEditTemplate }
            , {
            name: "direzioneFieldValueTemplate",
            template: this.direzioneFieldValueTemplate,
        }];
        window.scroll(0, 0);
        this.addSchoolYearForm = this.initializeFormGroup();
        this.getTipologie();
        this.getStatiAttuazione();
    }





    initializeFormGroup() {
        return this.formBuilder.group({
            id: [null],
            fase: [null, [Validators.required, Validators.maxLength(255)]],
            titolo: [null, [Validators.required, Validators.maxLength(255)]],
            descrizione: [null, [Validators.required, Validators.maxLength(255)]],
            /*isEntrata: [null, [Validators.required]],*/
            is_data_prevista_inizio: [null],
            is_data_prevista_fine: [null],
            is_data_effettiva_inizio: [null],
            is_data_effettiva_fine: [null],
            tipologia: [null, [Validators.required]],
            statoAttuazione:[null]
        });
    }

    get f() {
        return this.addSchoolYearForm.controls;
    }


    onSubmit() {
        this.submitted = true;
        this.addSchoolYearForm.updateValueAndValidity();
        this.areeTematicheIsError = !this.areeTematiche || this.areeTematiche.length <= 0;
        let errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );
        }
        if (this.addSchoolYearForm.invalid || errors || this.areeTematicheIsError) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.addSchoolYearForm.controls) {
                if (this.addSchoolYearForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.addSchoolYearForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.addSchoolYearForm.get(el)["controls"]) {
                            if (this.addSchoolYearForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }

        let obj = this.addSchoolYearForm.value;
        //obj.isEntrata = obj.isEntrata == '1';

        obj.monitoraggioIsDataPrevistaInizio = obj.is_data_prevista_inizio == '1';
        obj.monitoraggioIsDataPrevistaFine = obj.is_data_prevista_fine == '1';
        obj.monitoraggioIsDataEffettivaInizio = obj.is_data_effettiva_inizio == '1';
        obj.monitoraggioIsDataEffettivaFine = obj.is_data_effettiva_fine == '1';
        obj.areeTematiche = this.areeTematiche;

        if(obj.statoAttuazione)obj.statoAttuazione = this.elencoStatiAttuazione.find(x=>x.id == obj.statoAttuazione);

        this.currentService.save(obj).subscribe(res => {
            this.toastr.success("Tipologia salvata con successo!");
            this.submitted = false;
            this.open = false;
            this.addSchoolYearForm.reset();
            this.areeTematiche = [];

            this.buttonLabel = "Inserisci";
            if (!obj.id) {
                //new row
                this.ngxDatatableWrapper.addRow(res);
            }
            else {
                this.modalRef.hide();
                this.ngxDatatableWrapper.updateRow(res);
            }

        }, error => {
            this.toastr.error("Errore di sistema");
        });




    }
    buttonLabel: string = "Inserisci";
    loading: boolean = false;
    config = {
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };
    onEdit(row) {
        this.submitted = false;
        console.log(row);
        this.buttonLabel = "Aggiorna";
        this.modalRef = this.modalService.show(this.templateModal, this.config);
        this.addSchoolYearForm.setValue({
            id: row.id,
            descrizione: row.descrizione,
            titolo: row.titolo,
            fase: row.fase,
            /*isEntrata: row.isEntrata ? '1' : '0'*/
            is_data_prevista_inizio: row.monitoraggioIsDataPrevistaInizio,
            is_data_prevista_fine: row.monitoraggioIsDataPrevistaFine,
            is_data_effettiva_inizio: row.monitoraggioIsDataEffettivaInizio,
            is_data_effettiva_fine: row.monitoraggioIsDataEffettivaFine,
            tipologia: row.tipologia,
            statoAttuazione:row.statoAttuazione?row.statoAttuazione.id:null
        });
        this.areeTematiche = row.areeTematiche;
    }
    onDelete(row) {
        console.log(row);
        if (!confirm(`Stai per eliminare la tipologia "${row.titolo}", proseguire?`)) return;
        this.loading = true;
        this.currentService.delete(row.id).subscribe(
            result => {
                this.loading = false;
                this.ngxDatatableWrapper.deleteRow(row);
                this.toastr.success('Tipologia eliminata con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile eliminare la tipologia: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                console.log('error');
            });
    }
    closeModal() {
        this.modalRef.hide();
        this.addSchoolYearForm.reset();
        this.buttonLabel = "Inserisci";
        this.areeTematiche = [];
    }
    tipologieUnivoche = [];
    labelTipologieXColori = [];
    getTipologie() {
        this.currentService.getAll().subscribe(
            result => {
                this.labelTipologieXColori = [...new Set(result.map(item => item.fase))];
                this.getTipologieFasiColori();
                this.ngxDatatableWrapper.setInitialData(result);

            }
        );

    }
    color = "";
    coloreFasiGenerico = "";
    getTipologieFasiColori() {
        this.currentService.getTipologieFasiColori().subscribe(
            result => {
                this.labelTipologieXColori.forEach(x => {
                    if (!result.find(y => y.fase == x)) result.push({ fase: x, colore: '', changed: false });
                });
                this.tipologieUnivoche = result;
            }
        );
    }

    elencoStatiAttuazione = [];
    getStatiAttuazione() {
        this.statoAttuazioneService.getAll().subscribe(
            result => this.elencoStatiAttuazione = result
        );
    }

    onColorChange($event, item) {
        console.log($event);
        item.colore = $event;
        item.changed = true;
        //this.currentService.saveTipologiaFaseColore(item);
    }

    onColorChangeFasi($event, item) {
        item.coloreFasi = $event;
        item.changed = true;
    }
    saveTipologiaFaseColore() {
        let items = this.tipologieUnivoche.filter(x => x['changed']);
        if (items.length <= 0) {
            this.toastr.warning('Nessuna tipologia da salvare', null, {
                timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
            });
            return;
        }
        this.loading = true;
        this.currentService.saveTipologieFaseColori(items).subscribe(
            result => {
                this.loading = false;
                this.toastr.success('Colore tipologie salvati con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                this.tipologieUnivoche.filter(x => x['changed']).forEach(x => x.changed = false);
            }
        );
    }

    selectedItems = [];
    selectColorItem(item) {
        if (this.selectedItems.includes(item.fase)) this.selectedItems = this.selectedItems.filter(x => x != item.fase);
        else this.selectedItems.push(item.fase);
    }

    applyColorToSelected() {
        this.selectedItems.forEach(
            x => {
                let obj = this.tipologieUnivoche.find(y => y.fase == x);
                obj.colore = this.color;
                obj.coloreFasi = this.coloreFasiGenerico;
                obj.changed = true;
            }
        );
    }

    selectUnSelectColors($event) {
        //console.log($event);
        this.selectedItems = [];
        let selected = $event.target.checked;
        //if (selected) 
        //    this.selectedItems = this.tipologieUnivoche.map(x=>x.fase);
        let checkboxes = $('.custom-control-input');
        checkboxes.each(function () {
            let checkbox = this;
            if (
                (selected && !$(checkbox).is(':checked'))
                ||
                (!selected && $(checkbox).is(':checked'))
            ) $(checkbox).trigger('click');
        });
    }


    presetColors = [
        '#00FFFF', '#000000', '#0000FF', '#6699CC'
        , '#66FF00', '#964B00', '#FF7F50', '#6495ED', '#021092'
        , '#006400', '#8B0000', '#014D4E', '#CCCC00'
        , '#FFD700', '#00FF00', '#3F3F3F', '#666666', '#7F7F7F'
        , '#CCCCCC', '#4B0082', '#B57EDC', '#FFFACD'
        , '#BEFFFF', '#93CCEA', '#90EE90', '#FED8B1'
        , '#80A7AF', '#FFFFE0', '#BFFF00', '#800000'
        , '#808000', '#FFA500', '#DA70D6', '#AFEEEE', '#FFC0CB'
        , '#DDA0DD', '#FF0000', '#FF007F', '#4169E1', '#2E8B57', '#87CEEB'
        , '#D2B48C', '#008080', '#40E0D0', '#EE82EE', '#FFFFFF', '#FFFF00'
    ];

    printDirezioneFieldValue(data) {
        //console.log('printDirezioneFieldValue');
        let str = "";
        let arr = [];
        if (data && data != "") {
            let areeDirezione = JSON.parse(data);
            for (let o in areeDirezione) {
                let str = areeDirezione[o]["descrizioneDirezione"];
                if (areeDirezione[o]["disabled"]) str = `(disattiva) ${str}`;
                arr.push(str);
            }
            str = arr.join("<br />");
        }
        return arr;
    }
    areeTematiche = null;
    areeTematicheIsError = false;

    removeAreaTematica(item) {
        console.log(`inside removeAreaTematica id=${item.id}`);
        this.areeTematiche = this.areeTematiche.filter((x) => x.id != item.id);
    }

    getAreeTematicheDTNgx = (dataTablesParameters: any, callback: any) => {
        this.areeTematicheService
            .getAllDtOnlyActive(dataTablesParameters)
            .subscribe((resp) => {
                callback(resp);
            });
    };
    openModalAT() {
        let modalDefinition = {
            pageTitle: "Seleziona le aree gestionali e/o esterne"
        };

        let modalDefinitionNgx = {
            rows: [],
            totalElements: 0,
            columns: [
                {
                    name: "id",
                    prop: "id",
                    visible: false,
                    sortable: true,
                    filtrable: true,
                    width: 10,
                    resizeable: false,
                },

                {
                    name: "descrizione",
                    prop: "descrizione",

                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 4,
                    minWidth: 200,
                },
                {
                    name: "codice ipa",
                    prop: "codiceIpa",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 1,
                    minWidth: 60,
                },
                {
                    name: "struttura",
                    prop: "struttura",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 2,
                    minWidth: 100,
                },
                {
                    name: "nominativo",
                    prop: "nominativo",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 3,
                    minWidth: 200,
                }
            ],
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            ajax: this.getAreeTematicheDTNgx,
            scrollbarH: "true",
        };

        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl",
        };
        const initialState = {
            modalDefinition: modalDefinition,
            dtOptionsNgx: modalDefinitionNgx,
        };

        this.modalRefAreeTematiche = this.modalService.show(
            GenericSelectionModalComponent,
            Object.assign({}, config, { initialState })
        );
        console.log("apertura modale GenericSelectionModalComponent");
        this.clearSubscriptions();
        let subscription = this.modalRefAreeTematiche.content.onSubmitOk.subscribe((data) => {
            console.log("inside ok of area tematica");
            if (!this.areeTematiche) this.areeTematiche = [];
            let existsObj = this.areeTematiche.find(
                (x) => x.id == data.id
            );
            if (existsObj) return; //already added
            this.areeTematiche.push(data);
            this.areeTematiche = Object.assign(
                [],
                this.areeTematiche
            );

            this.toastr.success("Area direzione inserita con successo", null, {
                timeOut: 2000,
        /*closeButton:true,*/ disableTimeOut: false,
            });
        });
        this.modalSubscriptions.push(subscription);
    }
    clearSubscriptions() {
        this.modalSubscriptions.forEach((x) => x.unsubscribe());
        this.modalSubscriptions = [];
    }
    modalSubscriptions = [];
}
