<div class="container">
  <div class="container-xl" [hidden]="mode!='insertedit'">



    <div class="pb-3 " *ngIf="!isDetailView && !isInFastInsert">
      <div class=" row ">
        <div class="titolo col-md-12 ">
          <h3 class="mt-3 ">{{modalTitle}}</h3>
        </div>
      </div>
    </div>


    <div class="row" [hidden]="mode!='insertedit'">
      <div id="tabs" #tabs class="col-lg-12 ">
        <ul class="nav nav-tabs navscheda position-sticky sticky-top bg-white pt-4 pb-1"
          [ngClass]="{'top-tab-modal': isDetailView || isInFastInsert,'top-tab-custom':!isDetailView && !isInFastInsert  }">
          <li class="nav-item mt-1 px-1">
            <a (click)="confirmChangeTab($event,0);" class="nav-link"
              [ngClass]="{ 'active':activeTab==='tabDatiGenerali'}" href="#divTabDatiGeneraliContent"
              data-toggle="tab">Dati generali</a>
          </li>
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,1);" [ngClass]="{ 'active':activeTab==='tabStorico'}" class="nav-link"
              href="#divTemplateStorico" data-toggle="tab">Storico</a>
          </li>
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,2);" [ngClass]="{ 'active':activeTab==='tabDettaglio'}" class="nav-link"
              href="#divTemplateDettaglio" data-toggle="tab">Dettaglio</a>
          </li>

        </ul>
        <div class="tab-content">
          <div class="tab-pane " id="divTemplateStorico" [ngClass]="{ 'active':activeTab==='tabStorico'}">
            <storico-provvedimento-component [isInModal]="isInModal" #divTemplateStorico
              [provvedimentoId]="provvedimentoId"
              [gotoBtnOptions]="{show:true,label:isInModal?'Chiudi':'Torna a elenco provvedimenti'}"
              (gotoBtnFunction)="gotoBackList()">
            </storico-provvedimento-component>
          </div>
          <div class="tab-pane " id="divTemplateDettaglio" [ngClass]="{ 'active':activeTab==='tabDettaglio'}">
            <app-dettaglio-provvedimenti [isInModal]="isInModal" #divTemplateDettaglio
              [provvedimentoId]="provvedimentoId"
              [gotoBtnOptions]="{show:true,label:isInModal?'Chiudi':'Torna a elenco provvedimenti'}"
              (gotoBtnFunction)="gotoBackList()">
            </app-dettaglio-provvedimenti>
          </div>

          <div class="tab-pane " [ngClass]="{ 'active':activeTab==='tabDatiGenerali'}" id="divTabDatiGeneraliContent"
            [hidden]="mode!='insertedit'">



            <!--***-->
            <div class="position-sticky sticky-top pt-4 pb-4 elemento-aggiorna shadow-custom"
              [hidden]="mode!='insertedit'"
              [ngClass]="{'sticky-top-custom-modal':isDetailView || isInFastInsert,'sticky-top-custom':!isDetailView && !isInFastInsert }">
              <div class=" row  justify-content-center">
                <div class="col-lg-4 text-center " [hidden]="isDetailView">
                  <button [disabled]="loading" (click)="onSubmit()" class="btn btn-primary btn-block" type="button">
                    Salva&nbsp;<i class="fas fa-save"></i>
                    <img *ngIf="loading" class="pl-3 float-right"
                      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </button>
                </div>
                <div class="col-lg-4 text-center">
                  <button (click)="gotoBackList()" [disabled]="loading"
                    class="btn btn-secondary btn-block font-weight-bold" type="button">{{isDetailView || !showBackButton?'Chiudi':'Torna a
                      elenco
                      provvedimenti'}}</button>
                </div>
              </div>
            </div>
            <!--***-->


            <form [formGroup]="addSchoolYearForm" (ngSubmit)="onSubmit()">
              <input type="hidden" formControlName="id" [formGroup]="addSchoolYearForm" />

              <div class="col-lg-12 mt-5 ">

                <!--***-->
                <div class=" row">
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                    <label for="descrizione">
                      <h5 class="mandatory">Data:</h5>
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12"
                      [ngClass]="{ 'is-invalid': submitted && f.scopo.errors }"
                      style="display:flex; padding-right:0px;padding-left:0px">
                      <input [readonly]="this.sicerAttiSelezionati && this.sicerAttiSelezionati.length>0" type="text"
                        formControlName="data" class="form-control" bsDatepicker [formGroup]="addSchoolYearForm"
                        [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY',containerClass:'theme-dark-blue' }"
                        #data="bsDatepicker" [maxDate]="maxDate"
                        style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
                      <button
                        [hidden]="isDetailView || (this.sicerAttiSelezionati && this.sicerAttiSelezionati.length>0)"
                        class="btn btn-primary" (click)="data.toggle()" type="button"
                        style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>

                    <div *ngIf="submitted && f.data.errors" class="invalid-feedback" style="display: block;">
                      <div *ngIf="f.data.errors.required">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>


                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                    <label for="descrizione">
                      <h5 class="">Scopo:</h5>
                    </label>
                    <div class="row">
                      <div class="col-lg-10 col-md-10 col-sm-10 col-10">
                        <ng-select [formGroup]="addSchoolYearForm" formControlName="scopo" [items]="scopiProvvedimento"
                          bindValue="id" bindLabel="descrizione" placeholder="Seleziona scopo provvedimento"
                          [ngClass]="{ 'is-invalid': submitted && f.scopo.errors }">
                        </ng-select>
                      </div>




                      <div [hidden]="isDetailView" class="col-lg-2 col-md-2 col-sm-2 col-2 mt-auto pb-1"><a
                          href="javascript:;;" class="bottone-inserisci" (click)="openAddScopoProvvedimento();"><i
                            class="fas fa-plus"></i></a>

                      </div>
                      <div *ngIf="submitted && f.scopo.errors" class="invalidFieldMessage" style="display: block;">
                        <div *ngIf="f.scopo.errors" class="invalid-feedback pl-3" style="display: block;">
                          Campo obbligatorio
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!--***-->
                <div class="row ">
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                    <label for="descrizione">
                      <h5 class="mandatory">Tipo:</h5>
                    </label>

                    <div class="row">
                      <div class="col-lg-10 col-md-10 col-sm-10 col-10">
                        <div [ngClass]="{ 'is-invalid': submitted && f.tipo.errors }">
                          <ng-select [readonly]="this.sicerAttiSelezionati && this.sicerAttiSelezionati.length>0"
                            [formGroup]="addSchoolYearForm" formControlName="tipo" [items]="tipiProvvedimento"
                            bindValue="id" bindLabel="provvedimento" placeholder="Seleziona tipo provvedimento">
                          </ng-select>
                        </div>

                      </div>

                      <div [hidden]="isDetailView" class="col-lg-2 col-md-2 col-sm-2 col-2 mt-auto pb-1">
                        <a href="javascript:;;" class="bottone-inserisci" (click)="openAddTipoProvvedimento();">
                          <i class="fas fa-plus"></i></a>
                      </div>
                      <div *ngIf="submitted && f.tipo.errors" class="invalidFieldMessage" style="display: block;">
                        <div *ngIf="f.tipo.errors" class="invalid-feedback pl-3" style="display: block;">
                          Campo obbligatorio
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-12 ">
                    <label for="numero">
                      <h5 class="mandatory">Numero:</h5>
                    </label>
                    <input [formGroup]="addSchoolYearForm" formControlName="numero" maxlength="255" type="text" value=""
                      class="form-control" [readonly]="this.sicerAttiSelezionati && this.sicerAttiSelezionati.length>0"
                      placeholder="Inserisci il numero" [ngClass]="{
                            'is-invalid': submitted && f.numero.errors
                        }" />
                    <div *ngIf="submitted && f.numero.errors" class="invalid-feedback" style="display: block;">
                      <div *ngIf="f.numero.errors.required">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                </div>
                <!--***-->
                <div class="row">
                  <div class="form-group col-lg-12 col-md-12 col-sm-12 col-12">
                    <label for="oggetto">
                      <h5 class="mandatory">Oggetto:</h5>
                    </label>
                    <textarea [formGroup]="addSchoolYearForm" formControlName="oggetto" type="text" value=""
                      class="form-control" [readonly]="this.sicerAttiSelezionati && this.sicerAttiSelezionati.length>0"
                      placeholder="Inserisci l'oggetto" [ngClass]="{
                                'is-invalid': submitted && f.oggetto.errors
                            }" id="exampleFormControlTextarea1" rows="2"></textarea>
                    <!-- <input [formGroup]="addSchoolYearForm" formControlName="oggetto" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci l'oggetto" [ngClass]="{
                              'is-invalid': submitted && f.oggetto.errors
                          }" />-->
                    <div *ngIf="submitted && f.oggetto.errors" class="invalid-feedback" style="display: block;">
                      <div *ngIf="f.oggetto.errors.required">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" row">
                  <div class="form-group col-lg-12 col-md-12 col-sm-12 col-12">
                    <label for="note">
                      <h5 class="">Note:</h5>
                    </label>
                    <textarea [formGroup]="addSchoolYearForm" formControlName="note" type="text" value=""
                      class="form-control" placeholder="Inserisci le note" [ngClass]="{
                                  'is-invalid': submitted && f.note.errors
                              }" rows="2"></textarea>
                    <div *ngIf="submitted && f.note.errors" class="invalid-feedback" style="display: block;">
                      <div *ngIf="f.oggetto.note.required">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                </div>

                <div class=" row">
                  <div class="form-group col-lg-12 col-md-12 col-sm-12 col-12">
                    <label for="pnrrRilevante">
                      <h5 class="">Rilevante per PNRR:</h5>
                    </label>
                    <div>
                      <div class="form-check  form-check-inline">
                        <label for="pnrrRilevante1" class="form-check-label">
                          <input [formGroup]="addSchoolYearForm" id="pnrrRilevante1" formControlName="pnrrRilevante"
                            type="radio" value="1" class="form-check-input" [ngClass]="{
                                                  'is-invalid': submitted && f.pnrrRilevante.errors
                                                  }" (change)="pnrrRilevanteChange()" />Si
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <label for="pnrrRilevante2" class="form-check-label">
                        <input [formGroup]="addSchoolYearForm" id="pnrrRilevante2" formControlName="pnrrRilevante"
                          type="radio" value="0" class="form-check-input" [ngClass]="{
                                                              'is-invalid': submitted && f.pnrrRilevante.errors
                                                              }" (change)="pnrrRilevanteChange()" />No
                        </label>
                      </div>
                    </div>


                    <div *ngIf="submitted && f.pnrrRilevante.errors" class="invalidFieldMessage"
                      style="display: block;">
                      <div *ngIf="f.pnrrRilevante.errors" class="invalid-feedback" style="display: block;">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" [hidden]="!isPnrrRipartizioneRisorseVisible()">
                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                    <label for="pnrrSoggettoProponente">
                      <h5 class="mandatory">Amministrazione titolare:</h5>
                    </label>

                    <div [ngClass]="{ 'is-invalid': submitted && f.pnrrSoggettoProponente.errors }">
                      <div class="overflow-box">
                        <ng-select [items]="soggettiProponenti" bindValue="id" bindLabel="denominazione"
                          placeholder="Seleziona amministrazione titolare" [formGroup]="addSchoolYearForm"
                          formControlName="pnrrSoggettoProponente">
                        </ng-select>
                      </div>
                    </div>
                    <div *ngIf="submitted && f.pnrrSoggettoProponente.errors" class="invalid-feedback"
                      style="display: block;">
                      <div *ngIf="f.pnrrSoggettoProponente.errors.required">
                        Campo obbligatorio
                      </div>
                    </div>


                  </div>


                </div>
                <div class="row" [hidden]="!isPnrrRipartizioneRisorseVisible()">

                  <div class="form-group col-lg-12 col-md-12 col-sm-12 col-12">
                    <label for="pnrrPubblicatoInGu">
                      <h5 class="mandatory">Pubblicato in GU:</h5>
                    </label>
                    <div>
                      <div class="form-check  form-check-inline">

                        <input [formGroup]="addSchoolYearForm" id="pnrrPubblicatoInGu1"
                          formControlName="pnrrPubblicatoInGu" type="radio" value="1" class="form-check-input"
                          [ngClass]="{
                                                  'is-invalid': submitted && f.pnrrPubblicatoInGu.errors
                                                  }" (change)="pnrrPubblicatoInGuChange()" />

                        <label for="pnrrPubblicatoInGu1" class="form-check-label">Si</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input [formGroup]="addSchoolYearForm" id="pnrrPubblicatoInGu2"
                          formControlName="pnrrPubblicatoInGu" type="radio" value="0" class="form-check-input"
                          [ngClass]="{
                                                                'is-invalid': submitted && f.pnrrPubblicatoInGu.errors
                                                                }" (change)="pnrrPubblicatoInGuChange()" />
                        <label for="pnrrPubblicatoInGu2" class="form-check-label">No</label>
                      </div>
                    </div>


                    <div *ngIf="submitted && f.pnrrPubblicatoInGu.errors" class="invalidFieldMessage"
                      style="display: block;">
                      <div *ngIf="f.pnrrPubblicatoInGu.errors" class="invalid-feedback" style="display: block;">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                </div>


                <div class="row " [hidden]="!isPnrrRipartizioneRisorseVisible() || f.pnrrPubblicatoInGu.value!='1'">

                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                    <label for="pnrrNumeroGu">
                      <h5 class="mandatory">Numero GU:</h5>
                    </label>
                    <input [formGroup]="addSchoolYearForm" formControlName="pnrrNumeroGu" maxlength="255" type="text"
                      value="" class="form-control" placeholder="Inserisci il numero GU" [ngClass]="{
                            'is-invalid': submitted && f.pnrrNumeroGu.errors
                        }" />

                    <div *ngIf="submitted && f.pnrrNumeroGu.errors" class="invalidFieldMessage" style="display: block;">
                      <div *ngIf="f.pnrrNumeroGu.errors" class="invalid-feedback" style="display: block;">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>


                  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                    <label for="pnrrDataGu">
                      <h5 class="mandatory">Data GU:</h5>
                    </label>
                    <div [ngClass]="{ 'is-invalid': submitted && f.pnrrDataGu.errors }"
                      class="col-lg-12 col-md-12 col-sm-12 col-12"
                      style="display:flex; padding-right:0px;padding-left:0px">
                      <input type="text" formControlName="pnrrDataGu" class="form-control" bsDatepicker
                        [formGroup]="addSchoolYearForm"
                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY', adaptivePosition: true, containerClass:'theme-dark-blue' }"
                        #pnrrDataGu="bsDatepicker"
                        style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
                      <button [hidden]="isDetailView" class="btn btn-primary" (click)="pnrrDataGu.toggle()"
                        type="button" style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div *ngIf="submitted && f.pnrrDataGu.errors" class="invalidFieldMessage" style="display: block;">
                      <div *ngIf="f.pnrrDataGu.errors" class="invalid-feedback" style="display: block;">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <!--********************************************-->
              <div class="col-lg-12 mt-5" [hidden]="!isPnrrRipartizioneRisorseVisible()">
                <hr>
              </div>

              <div class="col-lg-12 mt-4 " [hidden]="!isPnrrRipartizioneRisorseVisible()">
                <div class="row justify-content-between">

                  <div class="col-lg-6 col-sm-6 col-6 mt-3">
                    <h5 for="" class="">Linee finanziamento PNRR/PNC</h5>
                  </div>
                  <div [hidden]="isDetailView" class="col-lg-1 col-sm-2 col-3 text-center mt-3">
                    <a class="bottone-inserisci" (click)="openModalPnrrLineeFinanziamento(null);">
                      <i class="fas fa-plus pointer"></i></a>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 mt-3 table-responsive-lg " [hidden]="!isPnrrRipartizioneRisorseVisible()">
                <table class="table table-hover table-colore">
                  <thead class="">
                    <th class="px-1">codice</th>
                    <th class="px-1">missione</th>
                    <th class="px-1">componente</th>
                    <th class="px-1">descrizione</th>
                    <th class="px-1">misura</th>
                    <th class="px-1">tipologia</th>
                    <th class="px-1">quota pnrr</th>
                    <th class="px-1">quota pnc</th>
                    <th class="casella-icona-tx px-1">{{isDetailView?'dettaglio':'modifica'}}</th>
                    <th [hidden]="isDetailView" class="casella-icona-tx px-1">elimina</th>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngIf="pnrrLineeFinanziamento && pnrrLineeFinanziamento.length && getPnrrLineeFinanziamentoWithoutDeleted().length>0">
                      <tr *ngFor="let item of getPnrrLineeFinanziamentoWithoutDeleted()" [ngClass]="{'text-primary': item.assorbimentoDaDecreto}">
                        <td class="small px-1">{{item.pnrrLineaFinanziamento.codice}}</td>
                        <td class="small px-1">{{item.pnrrLineaFinanziamento.missione.descrizione}}</td>
                        <td class="small px-1">{{item.pnrrLineaFinanziamento.componente.descrizione}}</td>
                        <td class="small px-1">{{item.pnrrLineaFinanziamento.descrizione}}</td>
                        <td class="small px-1">{{item.pnrrLineaFinanziamento.misuraPnrr}}</td>
                        <td class="small px-1">{{item.pnrrLineaFinanziamento.tipologia}}</td>
                        <td class="small px-1">{{printValueAsCurrency(item.importoPnrr)}}</td>
                        <td class="small px-1">{{printValueAsCurrency(item.importoFc)}}</td>
                        <td class="small px-1"><a (click)="editPnrrLineaFinanziamento(item)" href="javascript:;;"
                            data-toggle="tooltip" data-placement="top" title="{{isDetailView?'dettaglio':'modifica'}}">
                            <i [ngClass]="{'fa-edit': !isDetailView,'fa-eye': isDetailView,'detail-icon': isDetailView}"
                              class="far  casella-icona-tx pointer"></i> </a>
                        </td>
                        <td class="small px-1" [hidden]="isDetailView"><a (click)="removePnrrLineaFinanziamento(item)"
                            href="javascript:;;" data-toggle="tooltip" data-placement="top" title="elimina"><i
                              class="far fa-trash-alt casella-icona-tx"></i> </a>
                        </td>
                      </tr>
                    </ng-container>
                    <tr
                      *ngIf="!(pnrrLineeFinanziamento && pnrrLineeFinanziamento.length && getPnrrLineeFinanziamentoWithoutDeleted().length>0)">
                      <td colspan="9">Nessuna linea di finanziamento PNRR/PNC selezionata</td>
                    </tr>
                  </tbody>
                </table>
                <div *ngIf="submitted && pnrrLineeFinanziamentoIsError" class="invalidFieldMessage">
                  <div class="invalid-feedback" style="display: block;">
                    Selezionare almeno una linea di finanziamento PNRR/PNC
                  </div>
                </div>
              </div>
              <div class="col-lg-12" [hidden]="!isPnrrRipartizioneRisorseVisible()">
                <span class="text-primary">* In azzurro le linee finanziamento PNRR/PNC che assorbono da altro provvedimento</span>
              </div>



              <!--capitoli di bilancio-->

              <div class="col-lg-12 mt-5" [hidden]="isPnrrRipartizioneRisorseVisible()">
                <hr>
              </div>


              <div class="col-lg-12 mt-4" [hidden]="isPnrrRipartizioneRisorseVisible()">
                <div class="row justify-content-between">

                  <div class="col-lg-6 col-sm-6 col-6 mt-3">
                    <h5 for="" class="">Capitoli di bilancio</h5>
                  </div>
                  <div [hidden]="isDetailView" class="col-lg-1 col-sm-2 col-3 text-center mt-3">
                    <a class="bottone-inserisci" (click)="openModalCapitoliBilancio($event);">
                      <i class="fas fa-plus pointer"></i></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-3 table-responsive-lg" [hidden]="isPnrrRipartizioneRisorseVisible()">
                <table class="table table-hover table-colore">
                  <thead class="">
                    <th>codice</th>
                    <th>descrizione</th>
                    <th>anno</th>
                    <th>importo</th>
                    <th [hidden]="isDetailView" class="casella-icona-tx">elimina</th>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="capitoliBilancio && capitoliBilancio.length && capitoliBilancio.length>0">
                      <tr *ngFor="let item of capitoliBilancio">
                        <td>{{item.capitoloBilancioCodice?item.capitoloBilancioCodice:item.codice}}</td>
                        <td>{{item.capitoloBilancioDescrizione?item.capitoloBilancioDescrizione:item.descrizione}}</td>
                        <td>{{item.anno}}</td>
                        <td>{{item.importo | currency:'EUR':'symbol-narrow':'1.2-2':'it' }}</td>
                        <td [hidden]="isDetailView"><a (click)="removeCapitoloBilancio(item)" href="javascript:;;"
                            data-toggle="tooltip" data-placement="top" title="elimina"><i
                              class="far fa-trash-alt casella-icona-tx"></i> </a>
                        </td>
                      </tr>
                    </ng-container>
                    <tr *ngIf="!(capitoliBilancio && capitoliBilancio.length && capitoliBilancio.length>0)">
                      <td colspan="5">Nessun capitolo di bilancio selezionato</td>
                    </tr>
                  </tbody>
                </table>
                <div *ngIf="submitted && capitoliBilancioIsError" class="invalidFieldMessage">
                  <div *ngIf="capitoliBilancioIsError" class="invalid-feedback" style="display: block;">
                    Selezionare almeno un capitolo di bilancio
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-5">
                <hr>
              </div>

              <!--fine capitoli di bilancio-->
              <!--soggetti giuridici-->
              <div class="col-lg-12 mt-4">
                <div class="row justify-content-between">

                  <div class="col-lg-6 col-sm-6 col-6 mt-3">
                    <h5 for="" class="mandatory">
                      {{isPnrrRipartizioneRisorseVisible()?'Soggetti attuatori':'Soggetti giuridici'}}</h5>
                  </div>
                  <div [hidden]="isDetailView" class="col-lg-1 col-sm-2 col-3 text-center mt-3">
                    <a href="javascript:;;" class="bottone-inserisci" (click)="openModalSoggettiGiuridici();"><i
                        class="fas fa-plus"></i></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-3 table-responsive-lg">
                <table class="table table-hover table-colore">
                  <thead class="">
                    <th>Sigla Prov.</th>
                    <th>Codice</th>
                    <th>Denominazione</th>
                    <th [hidden]="isDetailView" class="casella-icona-tx">elimina</th>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="soggettiGiuridici && soggettiGiuridici.length && soggettiGiuridici.length>0">
                      <tr *ngFor="let item of soggettiGiuridici">
                        <td>{{item.siglaProvincia?item.siglaProvincia:item.provincia}}</td>
                        <td>{{item.codiceIdentificativo}}</td>
                        <td>{{item.denominazione}}</td>
                        <td [hidden]="isDetailView"><a [hidden]="item.hideDeleteButton"
                            (click)="removeSoggettoGiuridico(item)" href="javascript:;;" data-toggle="tooltip"
                            data-placement="top" title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i> </a>
                        </td>
                      </tr>
                    </ng-container>
                    <tr *ngIf="!( soggettiGiuridici && soggettiGiuridici.length && soggettiGiuridici.length>0)">
                      <td colspan="5">Nessun soggetto selezionato</td>
                    </tr>
                  </tbody>
                </table>
                <div *ngIf="submitted && soggettiGiuridiciIsError" class="invalidFieldMessage">
                  <div *ngIf="soggettiGiuridiciIsError" class="invalid-feedback" style="display: block;">
                    Selezionare almeno un soggetto
                  </div>
                </div>
              </div>

              <div class="col-lg-12 mt-5">
                <hr>
              </div>
              <!-- fine soggetti giuridici-->
              <!-- pnrr ripartizione risorse-->
              <div class="col-lg-12 mt-4" [hidden]="!isPnrrRipartizioneRisorseVisible()">
                <div class="row justify-content-between">

                  <div class="col-lg-6 col-sm-6 col-6 mt-3">
                    <h5 for="" class="mandatory" [ngClass]="{
                        'mandatory': necessitaRipartizione()
                    }">Pnrr ripartizione risorse</h5>
                  </div>
                  <div [hidden]="isDetailView" class="col-lg-1 col-sm-2 col-3 text-center mt-3">
                    <a href="javascript:;;" class="bottone-inserisci" (click)="openModalPnrrRipartizioneRisorse();"><i
                        class="fas fa-plus"></i></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-3 table-responsive-lg" [hidden]="!isPnrrRipartizioneRisorseVisible()">
                <table class="table table-hover table-colore">
                  <thead class="">
                    <th class="px-1">codice</th>
                    <th class="px-1">missione</th>
                    <th class="px-1">componente</th>
                    <th class="px-1">descrizione</th>
                    <th class="px-1">misura</th>
                    <th class="px-1">tipologia</th>
                    <th class="px-1">anno</th>
                    <th class="px-1">soggetto attuatore</th>
                    <th class="px-1">quota assegnata PNRR</th>
                    <th class="px-1">quota assegnata PNC</th>
                    <th [hidden]="isDetailView" class="casella-icona-tx px-1">elimina</th>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngIf="pnrrRipartizioneRisorse && pnrrRipartizioneRisorse.length && pnrrRipartizioneRisorse.length>0">
                      <tr *ngFor="let item of pnrrRipartizioneRisorse">
                        <td class="small px-1">{{item.pnrrLineaFinanziamento.codice}}</td>
                        <td class="small px-1">{{item.pnrrLineaFinanziamento.missione.descrizione}}</td>
                        <td class="small px-1">{{item.pnrrLineaFinanziamento.componente.descrizione}}</td>
                        <td class="small px-1">{{item.pnrrLineaFinanziamento.descrizione}}</td>
                        <td class="small px-1">{{item.pnrrLineaFinanziamento.misuraPnrr}}</td>
                        <td class="small px-1">{{item.pnrrLineaFinanziamento.tipologia}}</td>
                        <td class="small px-1">{{item.anno}}</td>
                        <td class="small px-1">
                          {{item.ruoloSoggettoGiuridico?item.ruoloSoggettoGiuridico.ruolo + ' - ':''}}
                          {{item.soggettoGiuridico?item.soggettoGiuridico.siglaProvincia + ' - ' + item.soggettoGiuridico.codiceIdentificativo + ' - ' + item.soggettoGiuridico.denominazione:''}}
                        </td>
                        <td class="small px-1"
                          [ngClass]="{'text-primary': item.ruoloSoggettoGiuridico && item.ruoloSoggettoGiuridico.codice && item.ruoloSoggettoGiuridico.codice.toUpperCase()=='SOGGETTO_ATTUATORE_RIL_BIL' }">
                          {{printValueAsCurrency(item.valorePnrr)}}</td>
                        <td class="small px-1"
                          [ngClass]="{'text-primary': item.ruoloSoggettoGiuridico && item.ruoloSoggettoGiuridico.codice && item.ruoloSoggettoGiuridico.codice.toUpperCase()=='SOGGETTO_ATTUATORE_RIL_BIL' }">
                          {{printValueAsCurrency(item.valoreFc)}}</td>
                        <td class="small px-1" [hidden]="isDetailView"><a [hidden]="item.hideDeleteButton"
                            (click)="removePnrrRipartizioneRisorse(item)" href="javascript:;;" data-toggle="tooltip"
                            data-placement="top" title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i> </a>
                        </td>
                      </tr>
                    </ng-container>
                    <tr
                      *ngIf="!( pnrrRipartizioneRisorse && pnrrRipartizioneRisorse.length && pnrrRipartizioneRisorse.length>0)">
                      <td colspan="11">Nessuna ripartizione selezionata</td>
                    </tr>
                  </tbody>
                </table>
                <div *ngIf="submitted && pnrrRipartizioneRisorseIsInError" class="invalidFieldMessage">
                  <div *ngIf="pnrrRipartizioneRisorseIsInError" class="invalid-feedback" style="display: block;">
                    Inserire almeno una ripartizione delle risorse per ogni linea di finanziamento PNRR
                  </div>
                </div>
              </div>

              <!--fine pnrr ripartizione risorse-->
              <div [hidden]="!isPnrrRipartizioneRisorseVisible()">
                <div class="col-lg-12 mt-5 mb-4">
                  <hr>
                </div>
                <div class=" col-lg-12">
                  <div class=" row">
                    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-12 ">
                      <label for="pnrrRegione">
                        <h5 class="">Quota Pnrr Regione Lazio sogg. beneficiario:</h5>
                      </label>
                      <input [formGroup]="addSchoolYearForm" formControlName="pnrrRegione" maxlength="255" type="text"
                        value="" class="form-control" [readonly]="true" [imask]="mask" [unmask]="true" />
                    </div>

                    <div class="form-group col-lg-6 col-md-6 col-sm-12 col-12 ">
                      <label for="fcRegione">
                        <h5 class="">Quota Pnc Regione Lazio sogg. beneficiario:</h5>
                      </label>
                      <input [formGroup]="addSchoolYearForm" formControlName="fcRegione" maxlength="255" type="text"
                        value="" class="form-control" [readonly]="true" [imask]="mask" [unmask]="true" />
                    </div>

                  </div>

                </div>
                <div class="col-lg-12 mt-5 mb-4">
                  <hr>
                </div>
              </div>

              <div class=" col-lg-12">
                <h5>Documentazione</h5>
                <tabset class="mt-5 tab-wrapper">
                  <tab heading="Protocolli">
                    <!--div protocolli-->
                    <div class="col-lg-12 mt-4 mb-5">
                      <div class="" [hidden]="isDetailView">
                        <form [formGroup]="frm_protocolli">
                          <!-- *****  -->
                          <!--<div class="row justify-content-between">
            <div class="col-lg-6 col-sm-6 col-6 mt-3">
                <h5 class="" for="">Cerca Protocollo</h5>
            </div>
        </div>-->

                          <div class="form-row">
                            <div class="form-group col-md-4">
                              <label for="inputAnno4">
                                <h5>Anno:</h5>
                              </label>
                              <input type="text" [formGroup]="frm_protocolli" formControlName="protocollo_anno"
                                class="form-control" />
                            </div>
                            <div class="form-group col-md-5">
                              <label for="inputProtocollo4">
                                <h5>N° Protocollo:</h5>
                              </label>
                              <input type="text" [formGroup]="frm_protocolli" formControlName="protocollo_numero"
                                class="form-control" />
                            </div>
                            <div class="form-group col-md-3 ">
                              <label for="buttonCerca1">
                                <h5 class="d-none d-sm-block">&nbsp;</h5>
                              </label>

                              <button
                                [disabled]="loading || !frm_protocolli.controls['protocollo_anno'] || !frm_protocolli.controls['protocollo_anno'].value || frm_protocolli.controls['protocollo_anno'].value.length!=4 || !frm_protocolli.controls['protocollo_numero'] || !frm_protocolli.controls['protocollo_numero'].value || frm_protocolli.controls['protocollo_numero'].value.length<=0 "
                                type="button" class="btn btn-info btn-block bold" (click)="acquisisciProtocolli()">
                                Cerca&nbsp;<i class="fas fa-search pointer" style="color:#fff; font-size: 18px;"></i>
                                <img *ngIf="loading" class="pl-3 float-right"
                                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                              </button>


                            </div>
                          </div>







                        </form>


                        <div class="col-lg-12 mt-3 table-responsive-lg" [hidden]="isDetailView">
                          <table class="table table-hover table-colore">
                            <thead class="">
                              <th scope="col">tipologia</th>
                              <th scope="col">numero&nbsp;protocollo</th>
                              <th scope="col">data</th>
                              <th class="casella-descrizione-tx" scope="col" class="casella-descrizione-tx">oggetto</th>
                              <th class="casella-icona-tx" scope="col" class="casella-icona-tx">aggiungi</th>

                            </thead>
                            <tbody>
                              <ng-container *ngIf="protocolli && protocolli.length>0">
                                <tr *ngFor="let item of protocolli;let i = index;">
                                  <td class="casella-icona-tx">{{item.tipoProtocollo}}</td>
                                  <td>

                                    <a class="pointer font-weight-bold text-0083e0 " title="mostra allegati"
                                      data-toggle="collapse" role="button" aria-expanded="false"
                                      [attr.aria-controls]="'.tr_collapse_' + i"
                                      [attr.data-target]="'.tr_collapse_' + i">
                                      <i class="fas fa-list"></i>&nbsp;{{item.numeroProtocollo}}
                                    </a>
                                    <br />
                                    <div class="ml-0" id="documenti-{{item.annoProtocollo}}-{{item.numeroProtocollo}}"
                                      class="collapse {{'tr_collapse_' + i}}">

                                      <p style="color: red;" *ngIf="item.messaggio && item.messaggio !=''">
                                        {{item.messaggio}}
                                      </p>
                                      <ul class="pl-2" *ngIf="!(item.messaggio && item.messaggio !='')">
                                        <li class="text-lowercase" *ngFor="let allegato of item.allegati">
                                          <small><a href="javascript:;"
                                              (click)="downloadDocumento(allegato.codice, allegato.nome)">{{allegato.nome}}</a></small>
                                        </li>
                                      </ul>


                                    </div>

                                  </td>
                                  <td><small>{{printDateValue(item.dataProtocollo)}}</small></td>
                                  <td><small>{{item.oggetto}}</small></td>
                                  <td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;<a class="bottone-inserisci"
                                      (click)="aggiungiProtocollo(item.numeroProtocollo);" href="javascript:;;"><i
                                        class="fas fa-plus "></i></a>
                                  </td>
                                </tr>
                              </ng-container>
                              <tr *ngIf="(!protocolli || protocolli.length <=0)">
                                <td colspan="4">Nessun protocollo trovato</td>
                              </tr>
                            </tbody>
                          </table>

                        </div>





                      </div>

                      <!--accordion-->
                      <accordion [isAnimated]="true">
                        <accordion-group
                          heading="Protocolli in Entrata ({{protocolliEntrata && protocolliEntrata.length? protocolliEntrata.length:0}})">

                          <div class="col-lg-12 mt-3 table-responsive-lg ">

                            <table class="table table-hover table-colore ">
                              <thead>
                                <tr>
                                  <th scope="col ">numero protocollo</th>
                                  <th scope="col ">data<br />protocollo</th>
                                  <th scope="col ">data<br />acquisizione</th>
                                  <th scope="col ">oggetto</th>
                                  <th scope="col ">note</th>
                                  <th scope="col " class="casella-icona-tx " [hidden]="isDetailView ">elimina</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container
                                  *ngIf=" protocolliEntrata &&  protocolliEntrata.length &&  protocolliEntrata.length>0">
                                  <tr *ngFor="let item of  protocolliEntrata">
                                    <td><small>
                                        <a class="pointer font-weight-bold text-0083e0" title="mostra allegati"
                                          data-toggle="collapse" role="button" aria-expanded="false"
                                          [attr.aria-controls]="'.dtw_tr_collapse_' + item.id"
                                          [attr.data-target]="'.dtw_tr_collapse_' + item.id">
                                          <i class="fas fa-list"></i>&nbsp;{{item.numeroProtocollo}}</a>
                                        <br />
                                        <div id="documenti-{{item.annoProtocollo}}-{{item.numeroProtocollo}}"
                                          class="collapse {{'dtw_tr_collapse_' + item.id}}">

                                          <p style="color: red;" *ngIf="item.messaggio && item.messaggio !=''">
                                            {{row.messaggio}}</p>
                                          <ul class="pl-2" *ngIf="!(item.messaggio && item.messaggio !='')">
                                            <li class="text-lowercase" *ngFor="let allegato of item.allegati">
                                              <a href="javascript:;"
                                                (click)="downloadDocumento(allegato.codice, allegato.nome)">{{allegato.nome}}</a>
                                            </li>
                                          </ul>
                                        </div>
                                      </small>
                                    </td>
                                    <td><small>{{printDateValue(item.dataProtocollo)}}</small></td>
                                    <td><small>{{printDateValue(item.dataAcquisizione)}}</small></td>
                                    <td><small><span class="help"
                                          title="{{item.oggetto}}">{{trimText(item.oggetto,100)}}</span></small></td>
                                    <td><small><a class=" border-0 text-center" (click)="editProsaDocument(item)"
                                          data-toggle="tooltip" data-placement="top" title="aggiorna note"><i
                                            class="far fa-edit pointer"></i></a><span class="help"
                                          title="{{item.note}}">{{trimText(item.note,100)}}</span></small></td>
                                    <td [hidden]="isDetailView"><a (click)="deleteProsaDocument(item)"
                                        href="javascript:;;" data-toggle="tooltip" data-placement="top"
                                        title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i>
                                      </a>
                                    </td>
                                  </tr>
                                </ng-container>
                                <tr
                                  *ngIf="!(protocolliEntrata && protocolliEntrata.length &&  protocolliEntrata.length>0)">
                                  <td colspan="6">Nessun protocollo presente</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>




                        </accordion-group>
                      </accordion>
                      <accordion [isAnimated]="true">
                        <accordion-group
                          heading="Protocolli in Uscita ({{protocolliUscita &&  protocolliUscita.length?protocolliUscita.length:0}})">

                          <div class="col-lg-12 mt-3 table-responsive-lg ">

                            <table class="table table-hover table-colore ">
                              <thead>
                                <tr>
                                  <th scope="col ">numero protocollo</th>
                                  <th scope="col ">data<br />protocollo</th>
                                  <th scope="col ">data<br />acquisizione</th>
                                  <th scope="col ">oggetto</th>
                                  <th scope="col ">note</th>
                                  <th scope="col " class="casella-icona-tx " [hidden]="isDetailView ">elimina</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container
                                  *ngIf="protocolliUscita &&  protocolliUscita.length &&  protocolliUscita.length>0">
                                  <tr *ngFor="let item of  protocolliUscita">
                                    <td><small>
                                        <a class="pointer font-weight-bold text-0083e0" title="mostra allegati"
                                          data-toggle="collapse" role="button" aria-expanded="false"
                                          [attr.aria-controls]="'.dtw_tr_collapse_' + item.id"
                                          [attr.data-target]="'.dtw_tr_collapse_' + item.id">
                                          <i class="fas fa-list"></i>&nbsp;{{item.numeroProtocollo}}</a>
                                        <br />
                                        <div id="documenti-{{item.annoProtocollo}}-{{item.numeroProtocollo}}"
                                          class="collapse {{'dtw_tr_collapse_' + item.id}}">

                                          <p style="color: red;" *ngIf="item.messaggio && item.messaggio !=''">
                                            {{row.messaggio}}</p>
                                          <ul class="pl-2" *ngIf="!(item.messaggio && item.messaggio !='')">
                                            <li class="text-lowercase" *ngFor="let allegato of item.allegati">
                                              <a href="javascript:;"
                                                (click)="downloadDocumento(allegato.codice, allegato.nome)">{{allegato.nome}}</a>
                                            </li>
                                          </ul>
                                        </div>
                                      </small>
                                    </td>
                                    <td><small>{{printDateValue(item.dataProtocollo)}}</small></td>
                                    <td><small>{{printDateValue(item.dataAcquisizione)}}</small></td>
                                    <td><small><span class="help"
                                          title="{{item.oggetto}}">{{trimText(item.oggetto,100)}}</span></small></td>
                                    <td><small><a class=" border-0 text-center" (click)="editProsaDocument(item)"
                                          data-toggle="tooltip" data-placement="top" title="aggiorna note"><i
                                            class="far fa-edit pointer"></i></a><span class="help"
                                          title="{{item.note}}">{{trimText(item.note,100)}}</span></small></td>
                                    <td [hidden]="isDetailView"><a (click)="deleteProsaDocument(item)"
                                        href="javascript:;;" data-toggle="tooltip" data-placement="top"
                                        title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i>
                                      </a>
                                    </td>
                                  </tr>
                                </ng-container>
                                <tr
                                  *ngIf="!(protocolliUscita &&  protocolliUscita.length &&  protocolliUscita.length>0)">
                                  <td colspan="6">Nessun protocollo presente</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>


                        </accordion-group>
                      </accordion>
                      <accordion [isAnimated]="true">
                        <accordion-group
                          heading="Protocolli Interni ({{protocolliInterni &&  protocolliInterni.length? protocolliInterni.length:0}})">
                          <div class="col-lg-12 mt-3 table-responsive-lg ">

                            <table class="table table-hover table-colore ">
                              <thead>
                                <tr>
                                  <th scope="col ">numero protocollo</th>
                                  <th scope="col ">data<br />protocollo</th>
                                  <th scope="col ">data<br />acquisizione</th>
                                  <th scope="col ">oggetto</th>
                                  <th scope="col ">note</th>
                                  <th scope="col " class="casella-icona-tx " [hidden]="isDetailView ">elimina</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container
                                  *ngIf="protocolliInterni &&  protocolliInterni.length &&  protocolliInterni.length>0">
                                  <tr *ngFor="let item of  protocolliInterni">
                                    <td><small>
                                        <a class="pointer font-weight-bold text-0083e0" title="mostra allegati"
                                          data-toggle="collapse" role="button" aria-expanded="false"
                                          [attr.aria-controls]="'.dtw_tr_collapse_' + item.id"
                                          [attr.data-target]="'.dtw_tr_collapse_' + item.id">
                                          <i class="fas fa-list"></i>&nbsp;{{item.numeroProtocollo}}</a>
                                        <br />
                                        <div id="documenti-{{item.annoProtocollo}}-{{item.numeroProtocollo}}"
                                          class="collapse {{'dtw_tr_collapse_' + item.id}}">

                                          <p style="color: red;" *ngIf="item.messaggio && item.messaggio !=''">
                                            {{row.messaggio}}</p>
                                          <ul class="pl-2" *ngIf="!(item.messaggio && item.messaggio !='')">
                                            <li class="text-lowercase" *ngFor="let allegato of item.allegati">
                                              <a href="javascript:;"
                                                (click)="downloadDocumento(allegato.codice, allegato.nome)">{{allegato.nome}}</a>
                                            </li>
                                          </ul>
                                        </div>
                                      </small>
                                    </td>
                                    <td><small>{{printDateValue(item.dataProtocollo)}}</small></td>
                                    <td><small>{{printDateValue(item.dataAcquisizione)}}</small></td>
                                    <td><small><span class="help"
                                          title="{{item.oggetto}}">{{trimText(item.oggetto,100)}}</span></small></td>
                                    <td><small><a class=" border-0 text-center" (click)="editProsaDocument(item)"
                                          data-toggle="tooltip" data-placement="top" title="aggiorna note"><i
                                            class="far fa-edit pointer"></i></a><span class="help"
                                          title="{{item.note}}">{{trimText(item.note,100)}}</span></small></td>
                                    <td [hidden]="isDetailView"><a (click)="deleteProsaDocument(item)"
                                        href="javascript:;;" data-toggle="tooltip" data-placement="top"
                                        title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i>
                                      </a>
                                    </td>
                                  </tr>
                                </ng-container>
                                <tr
                                  *ngIf="!(protocolliInterni &&  protocolliInterni.length &&  protocolliInterni.length>0)">
                                  <td colspan="6">Nessun protocollo presente</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </accordion-group>
                      </accordion>

                    </div>

                    <!--fine div protocolli-->
                  </tab>

                  <tab heading="Documenti">
                    <div class="col-lg-12">
                      <div class="row justify-content-between">

                        <div class="col-lg-6 col-sm-6 col-6 mt-4">
                          <h5 for="" class="">Documenti</h5>
                        </div>
                        <div [hidden]="isDetailView" class="col-lg-1 col-sm-2 col-3 text-center mt-3">
                          <a href="javascript:;;" class="bottone-inserisci" (click)="addDocumento();"><i
                              class="fas fa-plus"></i></a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 mt-3 mb-5 table-responsive-lg">
                      <table class="table table-hover table-colore">
                        <thead class="">
                          <th>documento</th>
                          <th class="casella-icona-tx">scarica</th>
                          <th [hidden]="isDetailView" class="casella-icona-tx">elimina</th>
                        </thead>
                        <tbody>
                          <ng-container *ngIf="documenti && documenti.length && documenti.length>0 && hasDocuments()">
                            <ng-container *ngFor="let item of documenti">


                              <tr *ngIf="item.status!='delete'">
                                <td>
                                  <uploadfile [externalConfig]="{elementName:item.id
                                        ,hideUploadButton:true,
                                        doUploadOnFileChange:true
                                        ,mandatory:false
                                        ,apiUrl:uploadFileUrl  ,
                                        maxSize:50,
                                        accept:[],
                                        fileExists:item.filename && item.filename!='',
                                        hideDownloadButton:true,
                                        fixedFilename:item.originalFileName
                                        }" (onAfterUpload)="onAfterFileUpload($event)"
                                    (onDelete)="onDeleteFile($event)" (onDownload)="onDownloadFile($event)"
                                    (onFileChanged)="onFileChanged($event)">
                                  </uploadfile>
                                </td>
                                <td><a *ngIf="item.filename!='' && item.status!='update'" (click)="onDownloadFile(item)"
                                    href="javascript:;;" data-toggle="tooltip" data-placement="top" title="Scarica"><i
                                      class="fas fa-download casella-icona-tx"></i> </a>
                                </td>
                                <td [hidden]="isDetailView"><a (click)="removeDocumento(item)" href="javascript:;;"
                                    data-toggle="tooltip" data-placement="top" title="elimina"><i
                                      class="far fa-trash-alt casella-icona-tx"></i> </a>
                                </td>
                              </tr>
                            </ng-container>
                          </ng-container>
                          <tr *ngIf="!(documenti && documenti.length && documenti.length>0 && hasDocuments()  )">
                            <td colspan="5">Nessun documento inserito</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </tab>

                  <tab heading="SICER - Atti" *ngIf="false">
                    <div class="col-lg-12 mt-4 mb-5">
                      <div class="" [hidden]="isDetailView">
                        <form [formGroup]="frm_sicer">
                          <!-- *****  -->
                          <div class="form-row">
                            <div class="form-group col-md-4">
                              <label for="sicer_anno">
                                <h5>Anno:</h5>
                              </label>
                              <input type="text" [formGroup]="frm_sicer" formControlName="sicer_anno"
                                class="form-control" />
                            </div>
                            <div class="form-group col-md-5">
                              <label for="sicer_numero">
                                <h5>N° Atto:</h5>
                              </label>
                              <input type="text" [formGroup]="frm_sicer" formControlName="sicer_numero"
                                class="form-control" />
                            </div>
                            <div class="form-group col-md-3 ">
                              <label for="buttonCerca1">
                                <h5 class="d-none d-sm-block">&nbsp;</h5>
                              </label>

                              <button
                                [disabled]="loading || !frm_sicer.controls['sicer_anno'] || !frm_sicer.controls['sicer_anno'].value || frm_sicer.controls['sicer_anno'].value.length!=4 || !frm_sicer.controls['sicer_numero'] || !frm_sicer.controls['sicer_numero'].value || frm_sicer.controls['sicer_numero'].value.length<=0 "
                                type="button" class="btn btn-info btn-block bold" (click)="acquisisciSicerAtti()">
                                Cerca&nbsp;<i class="fas fa-search pointer" style="color:#fff; font-size: 18px;"></i>
                                <img *ngIf="loading" class="pl-3 float-right"
                                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                              </button>


                            </div>
                          </div>


                        </form>


                        <div class="col-lg-12 mt-3 table-responsive-lg" [hidden]="isDetailView">
                          <table class="table table-hover table-colore">
                            <thead class="">
                              <th scope="col">numero&nbsp;atto</th>
                              <th scope="col">data</th>
                              <th scope="col">tipologia</th>
                              <th class="casella-descrizione-tx" scope="col" class="casella-descrizione-tx">oggetto</th>
                              <th class="casella-icona-tx" scope="col" class="casella-icona-tx">aggiungi</th>

                            </thead>
                            <tbody>
                              <ng-container *ngIf="sicerAtti && sicerAtti.length>0">
                                <tr *ngFor="let item of sicerAtti;let i = index;">
                                  <td>
                                    <a class="pointer font-weight-bold text-0083e0 " title="scarica allegato"
                                      href="javascript:;" (click)="downloadSicer(item)">{{item.numero}}
                                    </a>
                                  </td>
                                  <td><small>{{printDateValue(item.data)}}</small></td>
                                  <td class="casella-icona-tx">{{item.tipo}}</td>
                                  <td><small>{{item.oggetto}}</small></td>
                                  <td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;<a class="bottone-inserisci"
                                      (click)="aggiungiSicerAtto(item);" href="javascript:;;"><i
                                        class="fas fa-plus "></i></a>
                                  </td>
                                </tr>
                              </ng-container>
                              <tr *ngIf="(!sicerAtti || sicerAtti.length <=0)">
                                <td colspan="4">Nessun atto trovato</td>
                              </tr>
                            </tbody>
                          </table>

                        </div>
                      </div>

                      <accordion [isAnimated]="true">
                        <accordion-group
                          heading="SICER ATTI ({{sicerAttiSelezionati &&  sicerAttiSelezionati.length? sicerAttiSelezionati.length:0}})">
                          <div class="col-lg-12 mt-3 table-responsive-lg ">

                            <table class="table table-hover table-colore ">
                              <thead>
                                <tr>
                                  <th scope="col ">numero atto</th>
                                  <th scope="col ">data</th>
                                  <th scope="col ">tipologia</th>
                                  <th scope="col ">oggetto</th>
                                  <th scope="col ">note</th>
                                  <th scope="col " class="casella-icona-tx " [hidden]="isDetailView ">elimina</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container
                                  *ngIf="sicerAttiSelezionati &&  sicerAttiSelezionati.length &&  sicerAttiSelezionati.length>0">
                                  <tr *ngFor="let item of  sicerAttiSelezionati">
                                    <td><small>
                                        <a class="pointer font-weight-bold text-0083e0 " title="scarica allegato"
                                          href="javascript:;" (click)="downloadSicer(item)">{{item.numero}}
                                        </a>
                                      </small>
                                    </td>
                                    <td><small>{{printDateValue(item.data)}}</small></td>
                                    <td><small>{{printDateValue(item.tipologia)}}</small></td>
                                    <td><small><span class="help"
                                          title="{{item.oggetto}}">{{trimText(item.oggetto,100)}}</span></small></td>
                                    <td><small><a class=" border-0 text-center" (click)="editSicerAtto(item)"
                                          data-toggle="tooltip" data-placement="top" title="aggiorna note"><i
                                            class="far fa-edit pointer"></i></a><span class="help"
                                          title="{{item.note}}">{{trimText(item.note,100)}}</span></small></td>
                                    <td [hidden]="isDetailView"><a (click)="deleteSicerAtto(item)" href="javascript:;;"
                                        data-toggle="tooltip" data-placement="top" title="elimina"><i
                                          class="far fa-trash-alt casella-icona-tx"></i>
                                      </a>
                                    </td>
                                  </tr>
                                </ng-container>
                                <tr
                                  *ngIf="!(sicerAttiSelezionati &&  sicerAttiSelezionati.length &&  sicerAttiSelezionati.length>0)">
                                  <td colspan="6">Nessun atto presente</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </accordion-group>
                      </accordion>
                    </div>


                  </tab>
                </tabset>

              </div>
            </form>


          </div>
        </div>
      </div>
    </div>



  </div>
</div>



<div class="" [hidden]="mode!='list'">
  <hr />
  <div *ngIf="!filters" class="col-lg-12 mb-3"><button class="btn btn-lg btn-primary btn-block" type="button" style="margin:15px 0"
      (click)="onInsert();" *ngIf="enableSection(section, ['insert'])">
      Inserisci Nuovo Provvedimento
    </button></div>


  <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;"
    [dtOptions]="dtOptions">
    <ng-template #btnEditTemplate let-row="row" let-value="value">
      <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top"
        title="{{enableSection(section, ['update']) ? 'modifica dati' : 'visualizza dettaglio'}}">&nbsp;&nbsp;&ensp;<i [ngClass]="enableSection(section, ['update']) ? 'far fa-edit pointer' : 'far fa-eye pointer detail-icon'"></i></a>
    </ng-template>
    <ng-template #btnDeleteTemplate let-row="row" let-value="value">
      <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top"
        title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
    </ng-template>

    <ng-template #extraFilterTemplate>
      <form class="form-inline justify-content-center px-0 px-sm-3">
        <label class="mb-sm-2 mb-md-0">Rilevante PNRR:&nbsp;</label>

        <ng-select style="letter-spacing: -0.5px;" class="" [items]="itemsComboFilterPnrr" bindValue="id"
          bindLabel="descrizione" placeholder="" (change)="filterRilevantePnrr($event)">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
          </ng-template>
        </ng-select>

      </form>

    </ng-template>
    <ng-template #tripletteFieldValueTemplate let-row="row" let-value="value">
        <ul class="pl-2" *ngIf="value && value !=''">
            <li class="" *ngFor="let val of getTriplette(value)">{{val}}</li>
        </ul>
    </ng-template>

    <ng-template #pnrrFieldValueTemplate let-row="row" let-value="value">
        <span >{{ printPnrrFieldValue(row) }}</span>
    </ng-template>


  </ngx-datatable-wrapper>
</div>

<ng-template #templateAddScopoProvvedimento>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">Aggiungi scopo provvedimento</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-lg-12">
      <scopi-provvedimento-component (onSubmitOk)="refreshScopoProvvedimentoList($event)" [fastEditObject]="fakeObj">
      </scopi-provvedimento-component>
    </div>
  </div>
</ng-template>

<ng-template #templateAddTipoProvvedimento>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">Aggiungi tipo provvedimento</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-lg-12">
      <tipi-provvedimento-component (onSubmitOk)="refreshTipoProvvedimentoList($event)" [fastEditObject]="fakeObj">
      </tipi-provvedimento-component>
    </div>
  </div>
</ng-template>

<ng-template #templateEditNoteModal>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">Modifica note</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalEditProsaNote()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editNoteProtocollo">
      <input type="hidden" [formGroup]="editNoteProtocollo" formControlName="id" />
      <div class="form-group">
        <div class="col-12">
          <h5><label for="note" class="">Note:</label></h5>
          <textarea [formGroup]="editNoteProtocollo" rows="3" formControlName="note_protocollo" maxlength="255"
            type="text" value="" class="form-controlh" placeholder="Inserisci le note" [ngClass]="{
                'form-control': true
                }"></textarea>
        </div>
      </div>


      <div class="col-12 mb-3">
        <button class="btn btn-lg btn-primary btn-block" type="button" style="margin:15px 0"
          (click)="salvaNoteProtocollo()">
          Salva
        </button></div>
    </form>
  </div>
</ng-template>

<ng-template #templateAddPnrrRipartizioneRisorse>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">Aggiungi ripartizione risorse</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-lg-12">
      <form [formGroup]="pnrrRipartizioneRisorseForm" (ngSubmit)="addPnrrRisorsa()">
        <div class="col-lg-12 mt-3">

          <div class="form-group row">
            <div class="col-lg-12 mt-4">
              <div class="row justify-content-between">

                <div class="col-lg-6 col-sm-6 col-6 mt-3">
                  <h5 for="" class="mandatory">Linee finanziamento PNRR/PNC</h5>
                </div>
                <div [hidden]="isDetailView" class="col-lg-1 col-sm-2 col-3 text-center mt-3">
                  <a class="bottone-inserisci" (click)="openModalRefSelectLineaFinPnrrFcPerRipartizione();">
                    <i class="fas fa-plus pointer"></i></a>
                </div>
              </div>
            </div>

            <div class="col-lg-12 mt-3 table-responsive-lg">
              <table class="table table-hover table-colore">
                <thead class="">
                  <th>codice</th>
                  <th>missione</th>
                  <th>componente</th>
                  <th>descrizione</th>
                  <th>misura</th>
                  <th>tipologia</th>
                  <th [hidden]="isDetailView" class="casella-icona-tx">elimina</th>
                </thead>
                <tbody>
                  <ng-container *ngIf="pnrrLineaFinanziamentoSelected">
                    <tr>
                      <td>{{pnrrLineaFinanziamentoSelected.codice}}</td>
                      <td>{{pnrrLineaFinanziamentoSelected.missione.descrizione}}</td>
                      <td>{{pnrrLineaFinanziamentoSelected.componente.descrizione}}</td>
                      <td>{{pnrrLineaFinanziamentoSelected.descrizione}}</td>
                      <td>{{pnrrLineaFinanziamentoSelected.misuraPnrr}}</td>
                      <td>{{pnrrLineaFinanziamentoSelected.tipologia}}</td>
                      <td [hidden]="isDetailView"><a (click)="removeSelectedPnrrLineaFinanziamento()"
                          href="javascript:;;" data-toggle="tooltip" data-placement="top" title="elimina"><i
                            class="far fa-trash-alt casella-icona-tx"></i> </a>
                      </td>
                    </tr>
                  </ng-container>
                  <tr *ngIf="!(pnrrLineeFinanziamento)">
                    <td colspan="8">Nessuna linea di finanziamento PNRR/FPNCC selezionata</td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoSelectedIsError"
                class="invalidFieldMessage">
                <div class="invalid-feedback" style="display: block;">
                  Selezionare almeno una linea di finanziamento PNRR/PNC
                </div>
              </div>
            </div>
          </div>


          <div class="form-group row">

            <div class="col-lg-6 col-md-6 col-sm-12 col-6">
              <label for="soggettoGiuridico">
                <h5 class="mandatory">Soggetto attuatore:</h5>
              </label>

              <div class="form-group"
                [ngClass]="{ 'is-invalid': submittedPnrrRisorsa && formPnrrRipartizioneRisorse.soggettoGiuridico.errors }">
                <ng-select [formGroup]="pnrrRipartizioneRisorseForm" formControlName="soggettoGiuridico"
                  [items]="soggettiGiuridiciTmp" bindValue="id" bindLabel="descrizione" placeholder="Seleziona soggetto"
                  (change)="ripartizioneSoggettoGiuridicoChange()">
                </ng-select>
              </div>
              <div *ngIf="submittedPnrrRisorsa && formPnrrRipartizioneRisorse.soggettoGiuridico.errors"
                class="invalidFieldMessage" style="display: block;">
                <div *ngIf="formPnrrRipartizioneRisorse.soggettoGiuridico.errors" class="invalid-feedback"
                  style="display: block;">
                  Campo obbligatorio
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-6">
              <label for="ruoloSoggettoGiuridico">
                <h5 class="mandatory">Ruolo soggetto attuatore:</h5>
              </label>

              <div class="form-group"
                [ngClass]="{ 'is-invalid': submittedPnrrRisorsa && formPnrrRipartizioneRisorse.ruoloSoggettoGiuridico.errors }">
                <ng-select [formGroup]="pnrrRipartizioneRisorseForm" formControlName="ruoloSoggettoGiuridico"
                  [items]="ruoliSoggettiGiuridiciList" bindValue="id" bindLabel="ruolo" placeholder="Seleziona ruolo">
                </ng-select>
              </div>
              <div *ngIf="submittedPnrrRisorsa && formPnrrRipartizioneRisorse.ruoloSoggettoGiuridico.errors"
                class="invalidFieldMessage" style="display: block;">
                <div *ngIf="formPnrrRipartizioneRisorse.ruoloSoggettoGiuridico.errors" class="invalid-feedback"
                  style="display: block;">
                  Campo obbligatorio
                </div>
              </div>


            </div>

          </div>

          <div class="form-group row">

            <div class="col-lg-6 col-md-6 col-sm-12 col-6">
              <label for="anno">
                <h5 class="mandatory">Anno:</h5>
              </label>
              <input [formGroup]="pnrrRipartizioneRisorseForm" formControlName="anno" maxlength="4" type="text"
                class="form-control" placeholder="Inserisci l'anno" [ngClass]="{
                            'is-invalid': submittedPnrrRisorsa && formPnrrRipartizioneRisorse.anno.errors
                            }" [imask]="maskYear" [unmask]="true" />
              <div *ngIf="submittedPnrrRisorsa && formPnrrRipartizioneRisorse.anno.errors" class="invalidFieldMessage"
                style="display: block;">
                <div *ngIf="formPnrrRipartizioneRisorse.anno.errors" class="invalid-feedback" style="display: block;">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-6">
              <label for="valorePnrr">
                <h5 class="mandatory">Quota assegnata PNRR:</h5>
              </label>
              <input [formGroup]="pnrrRipartizioneRisorseForm" formControlName="valorePnrr" maxlength="255" type="text"
                class="form-control" placeholder="Inserisci la quota assegnata PNRR" [ngClass]="{
                          'is-invalid': submittedPnrrRisorsa && formPnrrRipartizioneRisorse.valorePnrr.errors
                          }" [imask]="mask" [unmask]="true" />
              <div *ngIf="submittedPnrrRisorsa && formPnrrRipartizioneRisorse.valorePnrr.errors"
                class="invalid-feedback" style="display: block;">
                <div *ngIf="formPnrrRipartizioneRisorse.valorePnrr.errors.required">
                  Campo obbligatorio
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-6">
              <label for="valoreFc">
                <h5 class="mandatory">Quota assegnata PNC:</h5>
              </label>
              <input [formGroup]="pnrrRipartizioneRisorseForm" formControlName="valoreFc" maxlength="255" type="text"
                class="form-control" placeholder="Inserisci la quota assegnata PNC" [ngClass]="{
                            'is-invalid': submittedPnrrRisorsa && formPnrrRipartizioneRisorse.valoreFc.errors
                            }" [imask]="mask" [unmask]="true" />
              <div *ngIf="submittedPnrrRisorsa && formPnrrRipartizioneRisorse.valoreFc.errors" class="invalid-feedback"
                style="display: block;">
                <div *ngIf="formPnrrRipartizioneRisorse.valoreFc.errors.required">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>





          <button [disabled]="loading" class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0 ">
            <img *ngIf="loading " class="pl-3 float-right "
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            Aggiungi
          </button>

        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #templateAddPnrrLineaFinanziamento>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">Aggiungi Linea finanziamento PNRR</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="">
      <form [formGroup]="pnrrLineaFinanziamentoForm" (ngSubmit)="savePnrrLineaFinanziamento()">
        <input type="hidden" formControlName="id" [formGroup]="pnrrLineaFinanziamentoForm" />
        <div class="col-lg-12 ">
          <div class="row">
            <div class="col-lg-12 ">
              <div class="row justify-content-between">

                <div class="col-lg-6 col-sm-6 col-6 mt-3">
                  <h5 for="" class="mandatory">Linee finanziamento PNRR/PNC</h5>
                </div>
                <div [hidden]="isDetailView || isEditPnrrLineaFinanziamento"
                  class="col-lg-1 col-sm-2 col-3 text-center mt-3">
                  <a class="bottone-inserisci" (click)="openModalRefSelectLineaFinPnrrFc();"><i
                      class="fas fa-plus pointer"></i></a>
                </div>
              </div>
            </div>

            <div class="col-lg-12 mt-3 table-responsive-lg ">
              <table class="table table-hover table-colore">
                <thead class="">
                  <th>codice</th>
                  <th>missione</th>
                  <th>componente</th>
                  <th>descrizione</th>
                  <th>misura</th>
                  <th>tipologia</th>
                  <th [hidden]="isDetailView || isEditPnrrLineaFinanziamento" class="casella-icona-tx">elimina</th>
                </thead>
                <tbody>
                  <ng-container *ngIf="pnrrLineaFinanziamentoSelected">
                    <tr>
                      <td>{{pnrrLineaFinanziamentoSelected.codice}}</td>
                      <td>{{pnrrLineaFinanziamentoSelected.missione.descrizione}}</td>
                      <td>{{pnrrLineaFinanziamentoSelected.componente.descrizione}}</td>
                      <td>{{pnrrLineaFinanziamentoSelected.descrizione}}</td>
                      <td>{{pnrrLineaFinanziamentoSelected.misuraPnrr}}</td>
                      <td>{{pnrrLineaFinanziamentoSelected.tipologia}}</td>
                      <td [hidden]="isDetailView || isEditPnrrLineaFinanziamento"><a
                          (click)="removeSelectedPnrrLineaFinanziamento()" href="javascript:;;" data-toggle="tooltip"
                          data-placement="top" title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i> </a>
                      </td>
                    </tr>
                  </ng-container>
                  <tr *ngIf="!(pnrrLineeFinanziamento)">
                    <td colspan="8">Nessuna linea di finanziamento PNRR/PNC selezionata</td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoSelectedIsError"
                class="invalidFieldMessage">
                <div class="invalid-feedback" style="display: block;">
                  Selezionare una linea di finanziamento PNRR/PNC
                </div>
              </div>
            </div>

            <div class="col-lg-12 mb-4">
              <hr>
            </div>

          </div>


          <div class="form-group row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <label for="assorbimentoDaDecreto">
                <h5 class="mandatory">Assorbimento da altro provvedimento:</h5>
              </label>
              <div>
                <div class="form-check  form-check-inline">

                  <input [attr.readonly]="assorbimentoIsReadonly" [formGroup]="pnrrLineaFinanziamentoForm"
                    id="assorbimentoDaDecreto1" formControlName="assorbimentoDaDecreto" type="radio" value="1"
                    class="form-check-input" [ngClass]="{
                                                'is-invalid': submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.assorbimentoDaDecreto.errors
                                                }" (change)="assorbimentoDaDecretoChange()" />

                  <label for="assorbimentoDaDecreto1" class="form-check-label">Si</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [attr.readonly]="assorbimentoIsReadonly" [formGroup]="pnrrLineaFinanziamentoForm"
                    id="assorbimentoDaDecreto2" formControlName="assorbimentoDaDecreto" type="radio" value="0"
                    class="form-check-input" [ngClass]="{
                                                              'is-invalid': submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.assorbimentoDaDecreto.errors
                                                              }" (change)="assorbimentoDaDecretoChange()" />
                  <label for="assorbimentoDaDecreto2" class="form-check-label">No</label>
                </div>
              </div>


              <div
                *ngIf="submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.assorbimentoDaDecreto.errors"
                class="invalidFieldMessage" style="display: block;">
                <div *ngIf="pnrrLineaFinanziamentoForm.controls.assorbimentoDaDecreto.errors" class="invalid-feedback"
                  style="display: block;">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>


          <div class="form-group row"
            [hidden]="!(pnrrLineaFinanziamentoForm.controls.assorbimentoDaDecreto.value=='1')">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <label for="decretoAssorbimento">
                <h5 class="mandatory">Provvedimento:</h5>
              </label>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div
                    [ngClass]="{ 'is-invalid': submittedPnrrLineaFinanziamento && (pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.errors || decretoSelezionatoIsError) }">
                    <ng-select [attr.readonly]="assorbimentoIsReadonly" [formGroup]="pnrrLineaFinanziamentoForm"
                      formControlName="decretoAssorbimento" [items]="provvedimentiPnrrList" bindValue="id"
                      bindLabel="descrizioneEstesa" placeholder="Seleziona un provvedimento"
                      (change)="decretoAssorbimentoChange($event)">
                    </ng-select>
                  </div>

                </div>
                <div
                  *ngIf="submittedPnrrLineaFinanziamento && (pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.errors || decretoSelezionatoIsError)"
                  class="invalidFieldMessage" style="display: block;">
                  <div
                    *ngIf="pnrrLineaFinanziamentoForm.controls.decretoAssorbimento.errors || decretoSelezionatoIsError"
                    class="invalid-feedback pl-3" style="display: block;">
                    Campo obbligatorio
                  </div>
                </div>
              </div>

            </div>
          </div>


          <div class=" row">

            <div class="col-lg-12 mb-4">
              <hr>
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-6 ">
              <label for="pnrr">
                <h5 class="">Pnrr:</h5>
              </label>
              <input [formGroup]="pnrrLineaFinanziamentoForm" formControlName="pnrr" maxlength="255" type="text"
                class="form-control" placeholder="" [imask]="mask" [unmask]="true" readonly />
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-6 ">
              <label for="pnc">
                <h5 class="">Pnc:</h5>
              </label>
              <input [formGroup]="pnrrLineaFinanziamentoForm" formControlName="pnc" maxlength="255" type="text"
                class="form-control" placeholder="" [imask]="mask" [unmask]="true" readonly />
            </div>

          </div>
          <div class="row">

            <div class="form-group col-lg-6 col-md-6 col-sm-6">
              <label for="quotaAssegnataPnrr">
                <h5 class="">Quota assegnata Pnrr:</h5>
              </label>
              <input [formGroup]="pnrrLineaFinanziamentoForm" formControlName="quotaAssegnataPnrr" maxlength="255"
                type="text" class="form-control" placeholder="" [imask]="mask" [unmask]="true" readonly />
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-6">
              <label for="quotaAssegnataFc">
                <h5 class="">Quota assegnata Pnc:</h5>
              </label>
              <input [formGroup]="pnrrLineaFinanziamentoForm" formControlName="quotaAssegnataFc" maxlength="255"
                type="text" class="form-control" placeholder="" [imask]="mask" [unmask]="true" readonly />
            </div>


          </div>
          <div class=" row">
            <div class="form-group col-lg-6 col-md-6 col-sm-6">
              <label for="quotaDisponibilePnrr">
                <h5 class="">Quota disponibile Pnrr:</h5>
              </label>
              <input [formGroup]="pnrrLineaFinanziamentoForm" formControlName="quotaDisponibilePnrr" maxlength="255"
                type="text" class="form-control" placeholder="" [imask]="mask" [unmask]="true" readonly />
            </div>

            <div class="form-group col-lg-6 col-md-6 col-sm-6 ">
              <label for="quotaDisponibileFc">
                <h5 class="">Quota disponibile Pnc:</h5>
              </label>
              <input [formGroup]="pnrrLineaFinanziamentoForm" formControlName="quotaDisponibileFc" maxlength="255"
                type="text" class="form-control" placeholder="" [imask]="mask" [unmask]="true" readonly />
            </div>

          </div>

          <div class=" row">
            <div class="form-group col-lg-6 col-md-6 col-sm-6 ">
              <label for="importoPnrr">
                <h5 class="mandatory">Quota Pnrr Nazionale:</h5>
              </label>
              <input [formGroup]="pnrrLineaFinanziamentoForm" formControlName="importoPnrr" maxlength="255" type="text"
                class="form-control" placeholder="Inserisci la quota pnrr nazionale" [ngClass]="{
                            'is-invalid': submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.importoPnrr.errors
                            }" [imask]="mask" [unmask]="true" />
              <div *ngIf="submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.importoPnrr.errors"
                class="invalid-feedback" style="display: block;">
                <div *ngIf="pnrrLineaFinanziamentoForm.controls.importoPnrr.errors.required">
                  Campo obbligatorio
                </div>
              </div>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-6 ">
              <label for="importoFc">
                <h5 class="mandatory">Quota Pnc Nazionale:</h5>
              </label>
              <input [formGroup]="pnrrLineaFinanziamentoForm" formControlName="importoFc" maxlength="255" type="text"
                class="form-control" placeholder="Inserisci la quota pnc nazionale" [ngClass]="{
                              'is-invalid': submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.importoFc.errors
                              }" [imask]="mask" [unmask]="true" />
              <div *ngIf="submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.importoFc.errors"
                class="invalid-feedback" style="display: block;">
                <div *ngIf="pnrrLineaFinanziamentoForm.controls.importoFc.errors.required">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>
          <div class=" row">
            <div class="form-group col-lg-6 col-md-6 col-sm-12 ">
              <label for="vecchioImportoPnrr">
                <h5 class="">Vecchio valore quota pnrr nazionale:</h5>
              </label>
              <input [formGroup]="pnrrLineaFinanziamentoForm" formControlName="vecchioImportoPnrr" maxlength="255"
                type="text" class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                            'is-invalid': submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.vecchioImportoPnrr.errors
                            }" [imask]="mask" [unmask]="true" />
              <div
                *ngIf="submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.vecchioImportoPnrr.errors"
                class="invalid-feedback" style="display: block;">
                <div *ngIf="pnrrLineaFinanziamentoForm.controls.vecchioImportoPnrr.errors.required">
                  Campo obbligatorio
                </div>
              </div>
            </div>
            <div class="form-group col-lg-6 col-md-6 col-sm-12 ">
              <label for="vecchioImportoFc">
                <h5 class="">Vecchio valore quota pnc nazionale:</h5>
              </label>
              <input [formGroup]="pnrrLineaFinanziamentoForm" formControlName="vecchioImportoFc" maxlength="255"
                type="text" class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                              'is-invalid': submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.vecchioImportoFc.errors
                              }" [imask]="mask" [unmask]="true" />
              <div
                *ngIf="submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.vecchioImportoFc.errors"
                class="invalid-feedback" style="display: block;">
                <div *ngIf="pnrrLineaFinanziamentoForm.controls.vecchioImportoFc.errors.required">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>


          <ng-template #popoverContentNonRilevanteRL>
            <span>Non rilevante Regione Lazio non necessita di ripartizione risorse PNRR/PNC</span>
          </ng-template>
          <div class="form-group row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <label for="rilevanteNazionale">
                <h5 class="mandatory">
                  <a
                  type="button"
                  class="help"
                    [popover]="popoverContentNonRilevanteRL"
                    placement="top"
                    triggers="mouseenter:mouseleave">

                    <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                    </i>

                  </a>
                  &nbsp;Non rilevante Regione Lazio:
                </h5>
              </label>
              <div>
                <div class="form-check  form-check-inline">

                  <input [formGroup]="pnrrLineaFinanziamentoForm" id="rilevanteNazionale1"
                    formControlName="rilevanteNazionale" type="radio" value="1" class="form-check-input" [ngClass]="{
                                                'is-invalid': submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.rilevanteNazionale.errors
                                                }" />

                  <label for="rilevanteNazionale1" class="form-check-label">Si</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [formGroup]="pnrrLineaFinanziamentoForm" id="rilevanteNazionale2"
                    formControlName="rilevanteNazionale" type="radio" value="0" class="form-check-input" [ngClass]="{
                                                              'is-invalid': submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.rilevanteNazionale.errors
                                                              }" />
                  <label for="rilevanteNazionale2" class="form-check-label">No</label>
                </div>
              </div>


              <div
                *ngIf="submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.rilevanteNazionale.errors"
                class="invalidFieldMessage" style="display: block;">
                <div *ngIf="pnrrLineaFinanziamentoForm.controls.rilevanteNazionale.errors" class="invalid-feedback"
                  style="display: block;">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>



          <div class="form-group row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <label for="avviso">
                <h5 class="mandatory">E' un avviso?</h5>
              </label>
              <div>
                <div class="form-check  form-check-inline">

                  <input [formGroup]="pnrrLineaFinanziamentoForm" id="avviso1" formControlName="avviso" type="radio"
                    value="1" class="form-check-input" [ngClass]="{
                                                'is-invalid': submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.avviso.errors
                                                }" (change)="pnrrAvvisoChange()" />

                  <label for="avviso1" class="form-check-label">Si</label>
                </div>
                <div class="form-check form-check-inline">
                  <input [formGroup]="pnrrLineaFinanziamentoForm" id="avviso2" formControlName="avviso" type="radio"
                    value="0" class="form-check-input" [ngClass]="{
                                                              'is-invalid': submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.avviso.errors
                                                              }" (change)="pnrrAvvisoChange()" />
                  <label for="avviso2" class="form-check-label">No</label>
                </div>
              </div>


              <div *ngIf="submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.avviso.errors"
                class="invalidFieldMessage" style="display: block;">
                <div *ngIf="pnrrLineaFinanziamentoForm.controls.avviso.errors" class="invalid-feedback"
                  style="display: block;">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row" [hidden]="pnrrLineaFinanziamentoForm.controls.avviso.value!='1'">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <label for="scadenza">
                <h5 class="mandatory">Scadenza:</h5>
              </label>

              <div
                [ngClass]="{ 'is-invalid': submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.scadenza.errors }"
                class="col-lg-12 col-md-12 col-sm-12 col-12" style="display:flex; padding-right:0px;padding-left:0px">
                <input type="text" formControlName="scadenza" class="form-control" bsDatepicker
                  [formGroup]="pnrrLineaFinanziamentoForm"
                  [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY', adaptivePosition: true, containerClass:'theme-dark-blue' }"
                  #scadenza="bsDatepicker" style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
                <button [hidden]="isDetailView" class="btn btn-primary" (click)="scadenza.toggle()" type="button"
                  style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                  <i class="fas fa-calendar-alt"></i>
                </button>
              </div>



              <div *ngIf="submittedPnrrLineaFinanziamento && pnrrLineaFinanziamentoForm.controls.scadenza.errors"
                class="invalidFieldMessage" style="display: block;">
                <div *ngIf="pnrrLineaFinanziamentoForm.controls.scadenza.errors" class="invalid-feedback"
                  style="display: block;">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>






          <button [hidden]="isDetailView" [disabled]="loading" class="btn btn-lg btn-primary btn-block" type="submit"
            style="margin:15px 0 ">
            <img *ngIf="loading" class="pl-3 float-right "
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            Salva
          </button>

        </div>
      </form>
    </div>
  </div>
</ng-template>
