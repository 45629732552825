import { CurrencyPipe } from "@angular/common";
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    Input
} from "@angular/core";
import * as olControls from "ol/control";
import { Vector as VectorLayer } from "ol/layer";
import Map from "ol/Map";
import Overlay from "ol/Overlay";
import * as olProj from "ol/proj";
import * as olStyle from "ol/style";
import View from "ol/View";
import { GeoService } from "src/app/modules/web-gis/services/geo-service.service";
import { AuthService } from "src/app/services";
import { ProgettoService } from "../../../services";

interface LayerAttribution {
    url: string;
    text1: string;
    text2: string;
}
@Component({
    selector: "app-map",
    templateUrl: "./map.component.html",
    styleUrls: ["./map.component.css"],
})
export class MapComponent implements OnInit, AfterViewInit {
    @ViewChild("popup") popup: ElementRef;
    showPage = false;
    ab = 'test';
    @ViewChild('screen', { static: false }) screen: ElementRef;
    @ViewChild('canvas', { static: false }) canvas: ElementRef;
    @ViewChild('downloadLink', { static: false }) downloadLink: ElementRef;

    @Input() showOnPnrrDashboard: boolean = false;

    //popup
    popupOverlay: Overlay;
    popupHidden = true;
    popupSelectedLayer: string;
    popUpInfo = {};
    popUpKeys = [];
    popUpValues = [];

    tipoInterventoIcons = {
        road: false,
        rail: false,
        metro: false,
        bike: false,
        parkland: false,
        emobility: false,
        skilift: false,
        points: false,
        port: false,
        metrostop: false,
        station: false,
        other_points: false,
        other_lines: false,
        park: false,
        nodes: false,
        asl: false,
        plants: false,
        networks_satellites: false,
        bonifiche_territorio_punti: false,
        bonifiche_territorio_linee: false,
        aeroporti: false,
        musei_cultura: false,
        luoghi_di_culto: false,
        cimiteri: false,
        hospitals: false,
        impianti_sportivi: false,
        interventi_sul_sociale: false,
        stazioni_autolinee: false,
        impianti_energie_rinnovabili: false,
    };

    isPopupActive = true;

    legendIcon = "close";
    map: any;
    selectedFeature;

    allInterventionsWithStatus: any[] = [];

    VIEWER_LAYER_TITLE = "Visualizzazione";

    checkedAll = "checked";
    checkedProj = "";
    checkedInt = "";

    projectStyle = [
        new olStyle.Style({
            stroke: new olStyle.Stroke({
                color: "#aa66cc",
                width: 3, //spessore strade salvate
            }),
            fill: new olStyle.Fill({
                color: "rgba(0, 0, 255, 0.1)",
            }),
        }),
        new olStyle.Style({
            image: new olStyle.Circle({
                radius: 6,
                stroke: new olStyle.Stroke({
                    color: "white", //edificio salvato
                    width: 2,
                }),
                fill: new olStyle.Fill({
                    color: "#aa66cc",
                }),
            }),
        }),
    ];

    baseMapsLayers = [];

    attribution: LayerAttribution;
    statusMode = true;
    showIcon = false;

    filter = null;

    constructor(
        private geoService: GeoService,
        private projectService: ProgettoService
        , private authService: AuthService
    ) { }

    ngOnInit() {
        this.baseMapsLayers = [
            { title: "CartoDb", code: "carto" },
            { title: "Google Strade", code: "gstreet" },
            { title: "Google Satellite", code: "gsat" },
            { title: "Google Terreno", code: "gterrain" },
            { title: "Google Ibrido", code: "ghybrid" },
            { title: "Stamen Toner", code: "toner" },
            { title: "Stamen Watercolor", code: "watercolor" },
        ];

        this.map = this.getMap(null, null, null);
    }

    ngAfterViewInit(): void {
        this.map.addLayer(this.geoService.getCartoDbLayer());
        this.setPopup();

        this.map.updateSize();
        this.geoService
            .getTileWMS(`inframob:province_lazio_2020`, `Province`)
            .subscribe(
                (res) => {
                    this.map.addLayer(res);
                },
                (error) => console.log(`${error}`)
            );

        if(this.showOnPnrrDashboard)this.applySwitch('interventions');
    }

    setInterventions(interventions: any[]) {
        this.allInterventionsWithStatus = [...interventions];
        this.showPage = true;
        let source = this.geoService
            .getLayerByTitle(this.map, "Province")
            .getSource();
        source.changed();
        this.map.updateSize();        
    }

    showMap() {
        this.showPage = true;
        this.map.updateSize();
    }
    hideMap() {
        window.setTimeout(() => (this.showPage = false), 10);
        this.map.updateSize();
    }

    setStatus(status: string) {
        const interventions = this.geoService
            .getLayerByTitle(this.map, this.VIEWER_LAYER_TITLE)
            .getSource()
            .getFeatures();

        const features = []; 

        interventions.forEach((i) => {
            if (i.values_["stato"] === status) {
                i.setStyle(undefined);
                features.push(i);
            } else {
                i.setStyle(new olStyle.Style({}));
            }
        });        
    }

    updateMapSize() {
        this.map.updateSize();
    }

    createViewerLayer(arrayProjectID: number[], cqlFilter: string) {
        this.filter = null;
        if (arrayProjectID && arrayProjectID.length > 0 && arrayProjectID[0] >= 0) {
            this.projectService
                .getInterventiSimpleData({
                    filterProjectId: arrayProjectID[0],
                    filterSimpleInterventionId: -1,
                    filterStatus: -1,
                    filterAreeGestionaliDirezioneEsterne: -1,
                    filterTipoClassificazione: -1,
                    filterSoggettoGiuridico: -1,
                    filterAnno: "all",
                    filterProvinciaSoggettoGiuridico: "all",
                    filterTemaProprietario: -1,
                    filterFrom: -1,
                    filterTo: -1,
                    filterTipologiaIntervento: -1
                })
                .subscribe((interventions) => {
                    let intIds = [];
                    interventions.forEach((i) => {
                        intIds.push(i.id);
                    });
                    let ids = JSON.stringify(intIds);
                    ids = "(" + ids.slice(1, -1) + ")";
                    this.filter = `cql_filter=intervento_id in ${ids}`;
                    this.getNewViewerLayer(this.filter);
                });
        } else {
            this.getNewViewerLayer(this.filter);
        }
    }

    getNewViewerLayer(filter) {
        this.geoService.removeLayerByTitle(this.map, this.VIEWER_LAYER_TITLE);
        this.geoService
            .getGeoJson(
                `inframob:coordinate_intervento`,
                `EPSG:4326`,
                undefined,
                filter
            )
            .subscribe((geoJSon) => {
                console.log(geoJSon);
                let that = this;
                geoJSon.features.forEach((f) => {
                    let interv = that.allInterventionsWithStatus.find(
                        (e) => e.id === f.properties.intervento_id
                    );
                    if (interv) {
                        let statusTmp = null;
                        if (interv.validationStatus) {
                            switch (interv.validationStatus) {
                                case "IDLE":
                                case "REJECTED":
                                    statusTmp = "Da validare";
                                    break;
                                case "APPROVED":
                                    statusTmp = "Validato";
                                    break;
                                case "TERMINATO":
                                    statusTmp = "Completato";
                                    break;
                                case "WAITING":
                                case "WAITING_FOR_APPROVAL_FIRST_LEVEL":
                                case "WAITING_FOR_APPROVAL_SECOND_LEVEL":
                                case "WAITING_FOR_REMOVE_APPROVAL":
                                    statusTmp = "In attesa di validazione";
                                    break;
                                case "FABBISOGNO":
                                    statusTmp = "Fabbisogno";
                                    break;
                                case "IBRIDO":
                                    statusTmp = "Parzialmente finanziato";
                                    break;
                                case "TERRITORIALE":
                                    statusTmp = "Territoriale";
                                    break;
                                default:
                                    statusTmp = "purple";
                                    break;
                            }
                        }

                        let direzioni = "";
                        if (interv.areeTematiche && interv.areeTematiche.length > 0) {
                            interv.areeTematiche.forEach((d) => {
                                direzioni += `${d.descrizione} |`;
                            });
                            direzioni = direzioni.slice(0, -1);
                        }

                        let importi = "";

                        if (interv.importi && interv.importi.length > 0) {
                            interv.importi.sort((a, b) => a.anno - b.anno);
                            interv.importi.forEach((i) => {
                                importi += `<br>&nbsp;&nbsp;&nbsp;&nbsp;<strong>${i.anno
                                    }:</strong> ${this.printValueAsCurrency(i.importo)}`;
                            });
                           
                        }

                        f.properties["stato"] = statusTmp;
                        f.properties["progetto"] = interv.isMain ? "Si" : "No";
                        f.properties["codice"] = interv.codice;
                        f.properties["cup"] = interv.cup;
                        f.properties["descrizione"] = interv.descrizione;
                        f.properties["direzioni"] = direzioni;
                        f.properties["importi"] = importi;
                        f.properties["visibile"] = true;
                        if (interv.pnrrLineaFinanziamento && interv.pnrrLineaFinanziamento.codice != '')
                            f.properties["Linea finanziamento PNRR/PNC"] = interv.pnrrLineaFinanziamento.codice;
                    }
                });
                let setBulletsColor = this.projectStyleFunction.bind(this, this.showOnPnrrDashboard);
                this.geoService.createLayerFromGeoJson(
                    this.map,
                    geoJSon,
                    this.VIEWER_LAYER_TITLE,
                    setBulletsColor, 
                   
                    2,
                    true,
                    false
                );

                let switchValue;

                if (this.checkedAll) {
                    switchValue = "all";
                }
                if (this.checkedProj) {
                    switchValue = "projects";
                }
                if (this.checkedInt) {
                    switchValue = "interventions";
                }
                console.log("valueeeee: " + switchValue);
                this.applySwitch(switchValue);
            });
    }

    iconLayerStyleFunction(feature, resolution) {
        if (feature) {
            const type = feature.get("type");
            const visibile = feature.get("visibile");
            let imageUrl;
            if (visibile) {
                switch (type) {
                    case "road":
                        imageUrl = "assets/img/strada.png";
                        break;
                    case "rail":
                        imageUrl = "assets/img/ferrovia.png";
                        break;
                    case "metro":
                        imageUrl = "assets/img/metro_00.png";
                        break;
                    case "bike":
                        imageUrl = "assets/img/ciclabile.png";
                        break;
                    case "parkland":
                        imageUrl = "assets/img/parchi.png";
                        break;
                    case "emobility":
                        imageUrl = "assets/img/emobility.png";
                        break;
                    case "skilift":
                        imageUrl = "assets/img/imp-risalita.png";
                        break;
                    case "points":
                        imageUrl = "assets/img/scuola.png";
                        break;
                    case "port":
                        imageUrl = "assets/img/porto_00.png";
                        break;
                    case "metrostop":
                        imageUrl = "assets/img/staz-metro.png";
                        break;
                    case "station":
                        imageUrl = "assets/img/staz-ferroviaria.png";
                        break;
                    case "other_lines":
                        imageUrl = "assets/img/altre-vie-comunicazione.png";
                        break;
                    case "other_points":
                        imageUrl = "assets/img/edificio.png";
                        break;
                    case "park":
                        imageUrl = "assets/img/parcheggi.png";
                        break;
                    case "nodes":
                        imageUrl = "assets/img/nodi.png";
                        break;
                    case 'asl':
                        imageUrl = "assets/img/asl.png";
                        break;
                    case "plants":
                        imageUrl = "assets/img/plants.png";
                        break;
                    case "networks_satellites":
                        imageUrl = "assets/img/networks_satellites.png";
                        break;
                    case "bonifiche_territorio_punti":
                        imageUrl = "assets/img/bonifiche_territorio_punti.png";
                        break;
                    case "bonifiche_territorio_linee":
                        case "bonifiche_lines":
                        imageUrl = "assets/img/bonifiche_territorio_linee.png";
                        break;
                    case "aeroporti":
                        imageUrl = "assets/img/aeroporti.png";
                        break;
                    case "musei_cultura":
                        imageUrl = "assets/img/musei_cultura.png";
                        break;
                    case "luoghi_di_culto":
                        imageUrl = "assets/img/luoghi_di_culto.png";
                        break;
                    case "cimiteri":
                        imageUrl = "assets/img/cimiteri.png";
                        break;
                    case "hospitals":
                        imageUrl = "assets/img/hospital.png";
                        break;
                    case "impianti_sportivi":
                        imageUrl = "assets/img/impianti_sportivi.png";
                        break;
                    case "interventi_sul_sociale":
                        imageUrl = "assets/img/interventi_sul_sociale.png";
                        break;
                    case "stazioni_autolinee":
                        imageUrl = "assets/img/stazioni_autolinee.png";
                        break;
                    case "impianti_energie_rinnovabili":
                        imageUrl = "assets/img/impianti_energie_rinnovabili.png";
                        break;
                    case "opere_urbanizzazione":
                            imageUrl = "assets/img/opere_urbanizzazione.png";
                            break;
                    default:
                        imageUrl = "assets/img/strada.png";
                }

                let style = new olStyle.Style({
                    image: new olStyle.Icon({
                        src: imageUrl,
                    }),
                });
                return style;
            }
        }
    }

    projectStyleFunction(showOnPnrrDashboard, feature, resolution) {
        const status = feature.get("stato");
        const isMain = feature.get("progetto");
        const visibile = feature.get("visibile");
        let color;
        if (status && visibile) {
            switch (status) {
                case "Da validare":
                    color = "#76bed0";
                    break;
                case "Validato":
                    color = "#28a745";
                    break;
                case "Completato":
                    color = "#0083e0";
                    break;
                case "In attesa di validazione":
                    color = "#ffc107";
                    break;
                case "Fabbisogno":
                    color = "#dc3545";
                    break;
                case "Territoriale":
                    color = "#3AB795";
                    break;
                case "Parzialmente finanziato":
                    color = "#577590";
                    break;
                default:
                    //rifiutato
                    color = "#dcd6f7";
                    break;
            }

            if (isMain === "Si" && !showOnPnrrDashboard) {
                color = "#aa66cc";
            }

            if (showOnPnrrDashboard && status !== "Territoriale") {
                color = "#aa66cc";
            }
            let style = [
                new olStyle.Style({
                    stroke: new olStyle.Stroke({
                        color: color,
                        width: 3, //spessore strade salvate
                    }),
                    fill: new olStyle.Fill({
                        color: "rgba(0, 0, 255, 0.1)",
                    }),
                }),
                new olStyle.Style({
                    image: new olStyle.Circle({
                        radius: 6,
                        stroke: new olStyle.Stroke({
                            color: "white", //edificio salvato
                            width: 2,
                        }),
                        fill: new olStyle.Fill({
                            color: color,
                        }),
                    }),
                }),
            ];
            return style;
        }
    }

    interventionTypeStyleFunction(tipoInterventoIcons, feature, resolution) {
        const type = feature.get("type");
        const isMain = feature.get("progetto");
        const visibile = feature.get("visibile");
        let count = 0;

        let color;

        if (type && visibile) {
            switch (type) {
                case "road":
                    color = "#002742";
                    tipoInterventoIcons.road = true;
                    break;
                case "rail":
                    color = "#0083e0";
                    tipoInterventoIcons.rail = true;
                    break;
                case "metro":
                    color = "#f0ad4e";
                    tipoInterventoIcons.metro = true;
                    break;
                case "bike":
                    color = "#007e32";
                    tipoInterventoIcons.bike = true;
                    break;
                case "parkland":
                    color = "#95c11f";
                    tipoInterventoIcons.parkland = true;
                    break;
                case "emobility":
                    color = "#7ca454";
                    tipoInterventoIcons.emobility = true;
                    break;
                case "skilift":
                    color = "#ffc2ff";
                    tipoInterventoIcons.skilift = true;
                    break;
                case "points":
                    color = "#dc3545";
                    tipoInterventoIcons.points = true;
                    break;
                case "port":
                    color = "#5cb85c";
                    tipoInterventoIcons.port = true;
                    break;
                case "metrostop":
                    color = "#F58300";
                    tipoInterventoIcons.metrostop = true;
                    break;
                case "station":
                    color = "#67b7dc";
                    tipoInterventoIcons.station = true;
                    break;
                case "other_points":
                    color = "#b351a0";
                    tipoInterventoIcons.other_points = true;
                    break;
                case "other_lines":
                    color = "#bd2130";
                    tipoInterventoIcons.other_lines = true;
                    break;
                case "park":
                    color = "#F58300";
                    tipoInterventoIcons.park = true;
                    break;
                case "nodes":
                    color = "#006699";
                    tipoInterventoIcons.nodes = true;
                    break;
                case 'asl':
                    color = "#2e2e85";
                    tipoInterventoIcons.asl = true;
                    break;
                case "plants":
                    color = "#653d0d";
                    tipoInterventoIcons.plants = true;
                    break;
                case "networks_satellites":
                    color = "#9b165c";
                    tipoInterventoIcons.networks_satellites = true;
                    break;
                case "bonifiche_territorio_punti":
                    color = "#46ac63";
                    tipoInterventoIcons.bonifiche_territorio_punti = true;
                    break;
                case "bonifiche_territorio_linee":
                case "bonifiche_lines":
                    color = "#1c6632";
                    tipoInterventoIcons.bonifiche_territorio_linee = true;
                    break;
                    case "aeroporti":
                        color = "#006699";
                        tipoInterventoIcons.aeroporti = true;
                        break;
                    case "musei_cultura":
                        color = "#ac7e47";
                        tipoInterventoIcons.musei_cultura = true;
                        break;
                    case "luoghi_di_culto":
                        color = "#632285";
                        tipoInterventoIcons.luoghi_di_culto = true;
                        break;
                    case "cimiteri":
                        color = "#006699";
                        tipoInterventoIcons.cimiteri = true;
                        break;
                    case "hospitals":
                        color = "#3d7dc1";
                        tipoInterventoIcons.hospitals = true;
                        break;
                        case "impianti_sportivi":
                            color = "#333";
                            tipoInterventoIcons.impianti_sportivi = true;
                            break;
                        case "interventi_sul_sociale":
                            color = "#ba1010";
                            tipoInterventoIcons.interventi_sul_sociale = true;
                            break;
                        case "stazioni_autolinee":
                            color = "#006699";
                            tipoInterventoIcons.stazioni_autolinee = true;
                            break;
                        case "impianti_energie_rinnovabili":
                            color = "#98c100";
                            tipoInterventoIcons.impianti_energie_rinnovabili = true;
                            break;
                        case "opere_urbanizzazione":
                            color = "#006699";
                default:
                    console.log("type not found",type);
                    color = "#006699";
            }

            
            let style = [
                new olStyle.Style({
                    stroke: new olStyle.Stroke({
                        color: color,
                        width: 3, //spessore strade salvate
                    }),
                    fill: new olStyle.Fill({
                        color: "rgba(0, 0, 255, 0.1)",
                    }),
                }),
                new olStyle.Style({
                    image: new olStyle.Circle({
                        radius: 6,
                        stroke: new olStyle.Stroke({
                            color: "white", //edificio salvato
                            width: 2,
                        }),
                        fill: new olStyle.Fill({
                            color: color,
                        }),
                    }),
                }),
            ];
            return style;
        }
    }

    toggleMapSwitch() {
        const layer = this.geoService.getLayerByTitle(
            this.map,
            this.VIEWER_LAYER_TITLE
        );
        if (!this.statusMode) {
            let setBulletsColor = this.projectStyleFunction.bind(this, this.showOnPnrrDashboard);
            layer.setStyle(setBulletsColor);
            this.statusMode = true;
            this.showIcon = false;
            this.geoService.hideLayerByTitle(this.map, "Icone");
        } else {
            let setIcons = this.interventionTypeStyleFunction.bind(this, this.tipoInterventoIcons);
            layer.setStyle(setIcons);
            this.statusMode = false;
        }
    }

    getMap(center: number[], zoom: number, controls: any) {
        return new Map({
            target: "map",
            controls: controls
                ? controls
                : olControls.defaults({
                    attribution: false,
                    zoom: true,
                    rotate: false,
                }),
            view: new View({
                extent: [
                    1089033.260000294,
                    4913779.4862301685,
                    1693954.0098315454,
                    5369272.358529974,
                ],
                center: olProj.fromLonLat(center ? center : [12.5, 41.87]),
                zoom: zoom ? zoom : 8.1,
            }),
        });
    }

    chooseBasemap($event) {
        const oldMap = this.geoService.getLayerByTitle(this.map, "basemap");
        let newMap;

        this.geoService.setZindexToLayer(this.map, "Province", 33);
        switch ($event.code) {
            case "carto":
                this.map.removeLayer(oldMap);
                this.map.addLayer(this.geoService.getCartoDbLayer());
                this.attribution = {
                    url: "https://www.openstreetmap.org/copyright",
                    text1: "© OpenStreetMap",
                    text2: "contributors.",
                };
                break;
            case "gstreet":
                this.map.removeLayer(oldMap);
                this.map.addLayer(this.geoService.getGoogleLayer("street"));
                this.attribution = {
                    url: "https://maps.google.it",
                    text1: "© Google",
                    text2: "",
                };
                break;
            case "gsat":
                this.map.removeLayer(oldMap);
                this.map.addLayer(this.geoService.getGoogleLayer("sat"));
                this.attribution = {
                    url: "https://maps.google.it",
                    text1: "© Google",
                    text2: "",
                };
                break;
            case "gterrain":
                this.map.removeLayer(oldMap);
                this.map.addLayer(this.geoService.getGoogleLayer("terrain"));
                this.attribution = {
                    url: "https://maps.google.it",
                    text1: "© Google",
                    text2: "",
                };
                break;
            case "ghybrid":
                this.map.removeLayer(oldMap);
                this.map.addLayer(this.geoService.getGoogleLayer("hybrid"));
                this.attribution = {
                    url: "https://maps.google.it",
                    text1: "© Google",
                    text2: "",
                };
                break;
            case "toner":
                this.map.removeLayer(oldMap);
                this.map.addLayer(this.geoService.getStamenLayer("toner"));
                this.attribution = {
                    url: "http://maps.stamen.com",
                    text1: "© Stamen",
                    text2: "",
                };
                break;
            case "watercolor":
                this.map.removeLayer(oldMap);
                this.map.addLayer(this.geoService.getStamenLayer("watercolor"));
                this.attribution = {
                    url: "http://maps.stamen.com",
                    text1: "© Stamen",
                    text2: "",
                };
                break;
        }
    }

    setPopup() {
        this.popupOverlay = new Overlay({
            element: this.popup.nativeElement,
            offset: [9, 9],
            autoPan: true,
            autoPanAnimation: {
                duration: 1000,
            },
        });
        this.map.addOverlay(this.popupOverlay);
        this.map.on("pointermove", (event) => {
            this.popup.nativeElement.hidden = true;
            this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
                if (layer && layer.get("title") && this.isPopupActive) {
                    this.showPopupInfo(feature, layer, event.coordinate);
                    return feature;
                }
            });
        });
    }

    showPopupInfo(feature, layer, coordinate) {
        if (layer instanceof VectorLayer) {
            this.popupSelectedLayer = layer.get("title");

            this.popUpKeys = Object.keys(feature.values_);

            this.popUpValues = Object.values(feature.values_);
            const indexGeom = this.popUpKeys.indexOf("geometry");
            if (indexGeom >= 0) {
                this.popUpKeys.splice(indexGeom, 1);
                this.popUpValues.splice(indexGeom, 1);
            }
            const indexGid = this.popUpKeys.indexOf("gid");
            if (indexGid >= 0) {
                this.popUpKeys.splice(indexGid, 1);
                this.popUpValues.splice(indexGid, 1);
            }
            const indexProgetto = this.popUpKeys.indexOf("progetto");
            if (indexProgetto >= 0) {
                this.popUpKeys.splice(indexProgetto, 1);
                this.popUpValues.splice(indexProgetto, 1);
            }
            const indexInterventoId = this.popUpKeys.indexOf("intervento_id");
            if (indexInterventoId >= 0) {
                this.popUpKeys.splice(indexInterventoId, 1);
                this.popUpValues.splice(indexInterventoId, 1);
            }
            const indexStato = this.popUpKeys.indexOf("stato");
            if (indexStato >= 0) {
                this.popUpKeys.splice(indexStato, 1);
                this.popUpValues.splice(indexStato, 1);
            }
            const indexType = this.popUpKeys.indexOf("type");
            if (indexType >= 0) {
                this.popUpKeys.splice(indexType, 1);
                this.popUpValues.splice(indexType, 1);
            }
            const indexDate = this.popUpKeys.indexOf("date");
            if (indexDate >= 0) {
                this.popUpKeys.splice(indexDate, 1);
                this.popUpValues.splice(indexDate, 1);
            }
            const indexVisibile = this.popUpKeys.indexOf("visibile");
            if (indexVisibile >= 0) {
                this.popUpKeys.splice(indexVisibile, 1);
                this.popUpValues.splice(indexVisibile, 1);
            }
            const indexHidden = this.popUpKeys.indexOf("icon_hidden");
            if (indexHidden >= 0) {
                this.popUpKeys.splice(indexHidden, 1);
                this.popUpValues.splice(indexHidden, 1);
            }          

            for (let index = 0; index < this.popUpKeys.length; index++) {
                if (this.popUpKeys[index] !== "geometry")
                    this.popUpInfo[`${this.popUpKeys[index]}`] = this.popUpValues[index];
            }

            this.popup.nativeElement.hidden = false;

            this.popupOverlay.setPosition(coordinate);
        }
        return true;
    }

    printValueAsCurrency(value) {
        value = typeof value === "number" ? value : value.replace(",", ".").trim();
        const format = "1.2-2";
        const currency = "€";
        const currentLocale: string = "it";
        return new CurrencyPipe(currentLocale).transform(
            value,
            "EUR",
            currency,
            format,
            "it-IT"
        );
    }

    applySwitch($event) {
        console.log($event);
        switch ($event) {
            case "all":
                this.showAll();
                this.checkedAll = "checked";
                this.checkedProj = "";
                this.checkedInt = "";
                break;
            case "projects":
                this.showProjects();
                this.checkedAll = "";
                this.checkedProj = "checked";
                this.checkedInt = "";
                break;
            case "interventions":
                this.showInterventions();
                this.checkedAll = "";
                this.checkedProj = "";
                this.checkedInt = "checked";
                break;
        }
    }

    showAll() {
        const source = this.geoService
            .getLayerByTitle(this.map, this.VIEWER_LAYER_TITLE)
            .getSource();
        const features = source.getFeatures();

        
        features.forEach((f) => {
            if (f.get("visibile")) {
                f.setStyle(undefined);
                f.values_["icon_hidden"] = false;
            } else {
                f.values_["icon_hidden"] = true;
            }        
        });
        source.changed();
        if (this.showIcon) {
            this.drawIconLayer();
        }
    }

    showProjects() {
        const source = this.geoService
            .getLayerByTitle(this.map, this.VIEWER_LAYER_TITLE)
            .getSource();
        const features = source.getFeatures();
        features.forEach((f) => {
            if (f.values_.progetto === "Si") {
                if (f.get("visibile")) f.setStyle(undefined);
                f.values_["icon_hidden"] = false;
            } else {
                f.setStyle(new olStyle.Style({}));
                f.values_["icon_hidden"] = true;
                console.log("");
            }
        });
        source.changed();
        if (this.showIcon) {
            this.drawIconLayer();
        }
    }

    showInterventions() {
        const source = this.geoService
            .getLayerByTitle(this.map, this.VIEWER_LAYER_TITLE)
            .getSource();
        const features = source.getFeatures();
        features.forEach((f) => {
            if (f.values_.progetto === "No") {
                if (f.get("visibile")) f.setStyle(undefined);
                f.values_["icon_hidden"] = false;
            } else {
                f.setStyle(new olStyle.Style({}));
                f.values_["icon_hidden"] = true;                
            }
        });
        source.changed();
        if (this.showIcon) {
            this.drawIconLayer();
        }
    }

    exportMap() {
        console.log('Dentro exportMap...');
        document.getElementById('export-png')['disabled'] = true;
        console.log('Disabilito bottone...');
        let mapCanvas = document.createElement("canvas");
        let size = this.map.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        let mapContext = mapCanvas.getContext("2d");
        let that = this;
        Array.prototype.forEach.call(
            document.querySelectorAll("canvas"),
            function (canvas) {
                console.log('Dentro callback elemento canvas...');
                if (canvas.width > 0) {
                    let opacity = canvas.parentNode.style.opacity;
                    mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
                    let transform = canvas.style.transform;
                    // Get the transform parameters from the style's transform matrix
                    let matrix = transform
                        .match(/^matrix\(([^\(]*)\)$/)[1]
                        .split(",")
                        .map(Number);
                    // Apply the transform to the export map context
                    CanvasRenderingContext2D.prototype.setTransform.apply(
                        mapContext,
                        matrix
                    );
                    mapContext.drawImage(canvas, 0, 0);
                    console.log('Draw image...');
                    mapContext.globalAlpha = 0.3;
                    let logo = new Image();
                    logo.src = "assets/img/logo_inframob.svg";

                    logo.onload = function () {
                        console.log('Dentro logo onload...');
                        mapContext.drawImage(logo, 15, 15, 65, 39);
                        console.log('Draw logo...');
                        let link = document.getElementById("image-download");
                        link["href"] = mapCanvas.toDataURL(); //ricorda il CORS
                        console.log('Genera link...');
                        window.setTimeout(() => {
                            console.log('Riabilito bottone...');
                            document.getElementById('export-png')['disabled'] = false;
                        },
                            5000
                        );
                        link.click();
                        console.log('Click...');                        
                    };
                }
            }
        );

    }

    toggleIconLayer() {
        this.showIcon = !this.showIcon;
        if (!this.statusMode && this.showIcon) {
            this.drawIconLayer();
        } else {
            this.geoService.hideLayerByTitle(this.map, "Icone");
            this.showIcon = false;
        }
    }

    drawIconLayer() {
        this.geoService.removeLayerByTitle(this.map, "Icone");
        this.geoService.createIconLayerForDashboard(
            this.map,
            this.VIEWER_LAYER_TITLE,
            "Icone",
            this.iconLayerStyleFunction,
            false
        );
    }

    isAdmin() {
        return this.authService.isAdmin();
    }
    isGr34() {
        return this.authService.isGr34();
    }
}
