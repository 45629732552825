<form [formGroup]="form">
  <div class="form-row">
    <div class="col-md-6 mb-3">
      <label for="validationDefault01">Tipo Provvedimento</label>
      <ng-select
        formControlName="tipo" [items]="tipiProvvedimento"
        bindValue="id" bindLabel="provvedimento" placeholder="Seleziona tipo provvedimento">
      </ng-select>
    </div>
    <div class="col-md-6 mb-3">
      <label for="validationDefault02">Data Provvedimento</label>
      <div class="d-flex">
      <input type="text"
        formControlName="data" class="form-control" bsDatepicker
        placeholder="Seleziona data provvedimento"
        [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY',containerClass:'theme-dark-blue' }"
        #data="bsDatepicker"
        style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
      <button
        class="btn btn-primary" (click)="data.toggle()" type="button"
        style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
        <i class="fas fa-calendar-alt"></i>
      </button>
    </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-6 mb-3">
      <label for="validationDefault03">Numero Provvedimento</label>
      <input formControlName="numero" maxlength="255" type="text" class="form-control" placeholder="Inserisci il numero di provvedimento"/>
    </div>
    <div class="col-md-6 mb-3">
      <label for="validationDefault04">Oggetto Provvedimento</label>
      <input formControlName="oggetto" type="text" class="form-control" id="validationDefault05" placeholder="Inserisci l'oggetto del provvedimento">
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-6 mb-3">
      <label for="validationDefault07">Rilevante PNRR:&nbsp;</label>
      <ng-select formControlName="pnrr" [items]="itemsComboFilterPnrr" bindValue="id"
        placeholder="Seleziona se rilevante PNRR" bindLabel="descrizione">
        <ng-template ng-option-tmp let-item="item">
          <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
        </ng-template>
      </ng-select>
    </div>
  </div>

  <button class="btn btn-info btn-block bold" type="button" [disabled]="checkDisableSearch()" (click)="onSubmit()">Cerca
    <i class="fas fa-search pointer" style="color: #fff; font-size: 18px;"></i>
  </button>
</form>

<provvedimenti-component *ngIf="loadComponent"
  [filters]="filters"
  (disableButtonEmit)="disableFilters($event)"
  (disableSearchEmitter)="setDisableSearchButton($event)"></provvedimenti-component>

<hr *ngIf="loadComponent">
