import { Component, OnInit } from '@angular/core';
import{AuthenticationIAMService} from "src/app/services/authentication-iam.service";
@Component({
  selector: 'app-login-iam',
  templateUrl: './login-iam.component.html',
  styleUrls: ['./login-iam.component.css']
})
export class LoginIamComponent implements OnInit {

  constructor(private authenticationIAMService :AuthenticationIAMService) { }

  ngOnInit(): void {
  }


  iamLogin(){
    return this.authenticationIAMService.login();
}

}
