import {
    Component,
    Input,
    OnInit,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    ElementRef,
    Renderer2,
    ViewChild,
} from "@angular/core";
import * as moment from 'moment';
import { Observable } from "rxjs";

@Component({
    selector: "gestione-massiva-aree-tematiche-details-table-component",
    templateUrl: "./gestione-massiva-aree-tematiche-details-table.component.html",
    styleUrls: ["./gestione-massiva-aree-tematiche-details-table.component.css"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GestioneMassivaAreeTematicheDetailsTableComponent implements OnInit {


    _tabellaDettaglioObj: any;

    @Input()
    set tabellaDettaglioObj(value) {
        this.codesSelected = [];
        this._tabellaDettaglioObj = value;

    } // Observable<any>;

    get tabellaDettaglioObj() {
        return this._tabellaDettaglioObj;
    }

    _observable$: Observable<any>;
    @Input()
    set observable$(value) {
        this.codesSelected = [];
        this._observable$ = value;
    }
    get observable$() {
        return this._observable$;
    }

    constructor(private renderer: Renderer2, private el: ElementRef) { }
    ngOnInit(): void { }


    get currentObject(): any {
        if (this.observable$) return this.observable$;
        if (this.tabellaDettaglioObj) return this.tabellaDettaglioObj;
    }

    trackByFn(index, item) {
        return index;
    }

    printDate(date) {
        if (!date) return '';
        let v = moment(date).toDate();
        //v.setHours(v.getHours() - v.getTimezoneOffset() / 60);
        return moment(v).format('DD/MM/YYYY');
    }

    
    getAreeTematiche(fase) {
        let result = [];
        fase.areeTematiche.forEach(
            x => result.push(`${x.descrizione}`)
        );
        return result;
    }

    getClassificazioni(fase) {
        let result = [];
        fase.classificazioni.forEach(
            x => result.push(`${x.codice} - ${x.tipoClassificazione}`)
        );
        return result;
    }

   
   


    printValueAsDateWithCurrentDateAsDefault(value) {
        if (!value) value = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())).toDate();
        return this.printValueAsDate(value);
    }

    printValueAsDate(value) {
        if (!value || value == '') return '';
        let v = moment(value).toDate();
        //v.setHours(v.getHours() - v.getTimezoneOffset() / 60);
        return moment(v).format('DD/MM/YYYY');
    }

    

    trimText(text, size) {
        if (!size) return text;
        if (text.length <= size) return text;
        return text.substring(0, size) + '...';
    }

    @Input()
    showSelectColumn = false;

    codesSelected = [];
    selectedFasi = [];

    addRemoveSelection(fase: any) {
        let codice = fase.id;
        if (!this.codesSelected) this.codesSelected = [];
        if (!this.selectedFasi) this.selectedFasi = [];
        if (this.codesSelected.includes(codice)) {
            this.codesSelected = this.codesSelected.filter(e => e != codice);
            this.selectedFasi = this.selectedFasi.filter(e => e.id != codice);
        }
        else {
            this.codesSelected.push(codice);
            this.selectedFasi.push(fase);
            //verifico se per tutto il selezionato ho gli stessi valori, riempio gia i campi

        }
        this.codesSelectedChanged.emit(this.codesSelected);
        this.classificazioniSelectedChanged.emit(this.selectedFasi);


    }

    @Output()
    codesSelectedChanged: EventEmitter<any> = new EventEmitter();

    @Output()
    classificazioniSelectedChanged: EventEmitter<any> = new EventEmitter();


    @ViewChild('fasiTable') elementRef: ElementRef;

    addRemoveFromParent(childRowHtmlClass, $event, parentObject) {
        if (!this.codesSelected) this.codesSelected = [];
        if (!this.selectedFasi) this.selectedFasi = [];
        console.log($event);
        let checked = $event.target.checked;
        let selector = `tr[class*='${childRowHtmlClass}'] input`;
        let elements = this.elementRef.nativeElement.querySelectorAll(selector);
        elements.forEach(
            elem => this.renderer.setProperty(elem, 'checked', checked)
        );
        parentObject.interventi.forEach(
            fase => {
                if (!checked) {
                    this.codesSelected = this.codesSelected.filter(e => e != fase.id);
                    this.selectedFasi = this.selectedFasi.filter(e => e.id != fase.id);
                }
                else {
                    if (!this.codesSelected.find(e => e == fase.id)) {
                        this.codesSelected.push(fase.id);
                        this.selectedFasi.push(fase);
                    }
                }
            }
        );        
        this.addRemoveSelection(parentObject);
        this.codesSelectedChanged.emit(this.codesSelected);
        this.classificazioniSelectedChanged.emit(this.selectedFasi);

    }

}
