<hr />

<accordion [isAnimated]="true" class="mb-5">
  <accordion-group heading="Ricerca in Finanziamenti">
    <app-search-finanziamento></app-search-finanziamento>
  </accordion-group>

  <accordion-group heading="Ricerca in Progetti">
    <app-search-progetto></app-search-progetto>
  </accordion-group>

  <accordion-group heading="Ricerca in Interventi">
    <app-search-intervento></app-search-intervento>
  </accordion-group>

  <accordion-group heading="Ricerca in Provvedimenti">
    <app-search-provvedimento></app-search-provvedimento>
  </accordion-group>

  <accordion-group heading="Ricerca in Documentazione">
    <app-search-documentazione></app-search-documentazione>
  </accordion-group>
</accordion>
