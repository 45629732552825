import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener, ChangeDetectorRef, AfterContentChecked, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { GeoService, AuthService } from "src/app/services";
import { NaturaTipologieService } from "../../services/natura-tipologie.service";
import { TipologiaInterventiService } from "../../services/tipologia-interventi.service";
import {
  AggregatoService,
  DashboardService,
  PnrrService,
} from "../../services";
import {  Router } from "@angular/router";
import { MappaNonDisegnabileComponent } from "../mappa-non-disegnabile/mappa-non-disegnabile.component";
import { DashboardPnrrNonDisegnabileService } from "./dashboard-pnrr-non-disegnabile.service";
import { ChartPnrrNaturaImportiComponent } from "../chart-pnrr-natura-importi/chart-pnrr-natura-importi.component";
import { ChartPnrrNaturaTipologieComponent } from "../chart-pnrr-natura-tipologie/chart-pnrr-natura-tipologie.component";

@Component({
  selector: "app-dashboard-pnrr-non-disegnabile",
  templateUrl: "./dashboard-pnrr-non-disegnabile.component.html",
  styleUrls: ["./dashboard-pnrr-non-disegnabile.component.css"],
})
export class DashboardPnrrNonDisegnabileComponent implements OnInit, OnDestroy,AfterContentChecked {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // Fuznione che rimuove la classe width dal menu filtri
    if(event.target.innerWidth<992)
      this.menuFiltri.nativeElement.classList.remove('width');
    else
      this.menuFiltri.nativeElement.classList.add('width');
  }

  @Input()
  isPublic:boolean= false;

  // Variabili che popolano le combo
  allProvinciaItems: any[] = [];
  provinciaItems: any[] = [];
  provinciaFiltered: any = [];

  allComuneItems: any[] = [];
  comuneItems: any[] = [];
  comuneFiltered: any = [];

  allNaturaItems: any = [];
  naturaItems: any = [];

  allTipologiaItems: any = [];
  tipologiaItems: any = [];

  dettaglioItems: any = [];

  allFinanziamentoMissionsPnrrComboItems: any[] = [];
  finanziamentoMissionsPnrrComboItems: any[] = [];
  allFinanziamentoComponentsPnrrComboItems: any[] = [];
  finanziamentoComponentsPnrrComboItems: any[] = [];
  allFinanziamentoLinesPnrrComboItems: any[] = [];
  finanziamentoLinesPnrrComboItems: any[] = [];
  //.................................

  myForm: FormGroup;
  showLoading: number = 0;

  // Array di Gestione filtri inseriti
  conditions = [];
  filteredConditions = [];
  //...................................

  allDettagli = [];

  nonLocalizzati = 0;

  // Variabili passaggio dati ai grafici
  tipologieChartData;
  chartMisureImportiData;
  chartsFeatures;
  chartsFeaturesLinee;
  chartsFeaturesProvince;
  chartsFeaturesComuni;
  //......................................

  _totalNumTipologies = 0;

  // Oggetto di gestione filtri che si interfaccia con il back-end
  filtersObject = {
    provinceCode: null,
    municipalityCode: null,
    natureCode: null,
    tipologyCode: null,
    detailCode: null,
    pnrrMissionCode: null,
    pnrrComponentCode: null,
    pnrrLineCode: null,
  };

  filtersActive = {};

  muniChartsEnabler: boolean;

  set totalNumTipologies(val) {
    this._totalNumTipologies = val;
  }
  get totalNumTipologies() {
    return this._totalNumTipologies;
  }

  @ViewChild("mapPnrr")
  mapPnrr: MappaNonDisegnabileComponent;

  @ViewChild("menuFiltri")
  menuFiltri: ElementRef;

  @ViewChild("chartStep1")
  chartStep1: ElementRef;

  isMenuFiltroShown: boolean;

  private menuFiltriChanges: MutationObserver
    private _serviceSubscription;

  constructor(
    private geoService: GeoService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private naturaTipologieService: NaturaTipologieService,
    private tipologiaService: TipologiaInterventiService,
    private aggregatoService: AggregatoService,
    private pnrrService: PnrrService,
    private router: Router,
    private dashboardService: DashboardService,
    private service:DashboardPnrrNonDisegnabileService,
    private cdref: ChangeDetectorRef

  ) {
    this._serviceSubscription = this.service.onRendering.subscribe(
        {
            next: (event: any) => {
            console.log('service:',event);
            this.showLoading+= event?1:-1;
        }
    })
  }
  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }

  ngOnInit(): void {
    this.myForm = this.initializeFormGroup();
    this.getProvince();
    this.getComune();
    this.getNature();
    this.getTipologie();
    this.getDettagli();
    this.getFinanziamentoPnrrComboItems();

    this.f.tipologie.disable();
    this.f.dettagli.disable();

    this.f.pnrrComponente.disable();
    this.f.pnrrLinea.disable();

    this.filterIntervention(this.filtersObject, true);    

  }

  ngAfterViewInit() {
    console.log(this.mapPnrr);
    
    if(window.innerWidth<992 && this.menuFiltri)
      this.menuFiltri.nativeElement.classList.remove('width');

    this.setObserver();
  }

  ngOnDestroy(): void {
    this.menuFiltriChanges.disconnect();
    if (this._serviceSubscription)    this._serviceSubscription.unsubscribe();
  }

  setObserver(){
    const element = this.menuFiltri.nativeElement;

    this.menuFiltriChanges = new MutationObserver((mutations) => {
          if(mutations[0].attributeName === 'class') {
            if(this.menuFiltri.nativeElement.classList.value.indexOf('show')>=0)
              this.isMenuFiltroShown = true;
            else
              this.isMenuFiltroShown = false;
          }
        }
    );

    this.menuFiltriChanges.observe(element, {
      attributes: true,
    });
  }

anchorList = [
  "step0",
  "step1",  
]

  anchorListSelected = 0;

  toGoNext() {
    this.chartStep1.nativeElement.scrollIntoView();    
  }


  

  getProvince() {
    this.geoService.getProvinces("12").subscribe((res) => {
      this.allProvinciaItems = [...res];
      this.provinciaItems = [...res];
      this.provinciaFiltered = this.provinciaItems;
    });
  }

  getComune() {
    this.geoService.getMunicipalitiesWithIstat("12").subscribe((res) => {
      const keyValues = res.map((x) => {
        const kv = {
          key: x["istatCode"],
          value: x["name"],
          parentCode: x["provinceCode"],
        };
        return kv;
      });

      this.allComuneItems = [...keyValues].sort((a,b) => a.value < b.value ? -1 : 1);
      this.comuneItems = this.allComuneItems;
      this.comuneFiltered = this.allComuneItems;
    });
  }

  getNature() {
    this.naturaTipologieService.getNaturaTipologie().subscribe((res) => {
      const keyValues = res.map((x) => {
        const kv = { key: x["codice"], value: x["codice"] + " - " + x["etichetta"] };
        return kv;
      });
      this.allNaturaItems = [...keyValues];
      this.naturaItems = [...keyValues];
    });
  }

  getTipologie() {
    this.tipologiaService.getTipologiaInterventi().subscribe((res) => {
      const keyValues = res.map((x) => {
        const kv = {
          key: x["codice"],
          value: x["descrizione"],
          parentCode: x["naturaTipologia"].codice,
        };
        return kv;
      });
      this.allTipologiaItems = [...keyValues].sort((a,b) => a.value < b.value ? -1 : 1);;
      this.tipologiaItems = this.allTipologiaItems;
    });
  }

  getDettagli() {
    this.aggregatoService.getAll().subscribe((res) => {
      let filter: any[];
      let kp: any[] = [];
      for (let tipo of this.tipologiaItems)
        tipo.dettagli = res
          .filter((x) => x.tipologiaIntervento["codice"] == tipo.key)
          .map((x) => {
            return { key: x.id, value: x.name };
          });      
    });
  }

  getFinanziamentoPnrrComboItems() {
    this.pnrrService.getAllLineaFinanziamento().subscribe((res) => {
      res = res.sort((a, b) => a.codice.localeCompare(b.codice));

      const buildFMFinanziamento = new Set();
      const buildSMFinanziamento = new Set();
      const buildTMFinanziamento = new Set();

      res.forEach((data) => {
        const dataSplitted = data.codice.split("-");

        buildFMFinanziamento.has(dataSplitted[0])
          ? null
          : buildFMFinanziamento.add(data.missione.descrizione);
        buildSMFinanziamento.has(dataSplitted[0] + "-" + dataSplitted[1])
          ? null
          : buildSMFinanziamento.add({
              value: data.componente.descrizione,
              parentCode: data.missione.codice,
            });
        buildTMFinanziamento.add({
          value: data.codice.split("I")[1] + " - " + data.descrizione,
          key: data.codice,
          parentCode: data.componente.codice,
        });
      });


      
        this.allFinanziamentoMissionsPnrrComboItems = Array.from(buildFMFinanziamento);
        this.allFinanziamentoMissionsPnrrComboItems = this.allFinanziamentoMissionsPnrrComboItems.map(data => ({ value: data, key: data.split(' - ')[0] }));
        this.allFinanziamentoComponentsPnrrComboItems = Array.from(buildSMFinanziamento);
        this.allFinanziamentoComponentsPnrrComboItems = this.allFinanziamentoComponentsPnrrComboItems.map(data => ({
          value: data.value, key: data.value.split(' - ')[0].trim(), parentCode: data.parentCode
        }));
        this.allFinanziamentoComponentsPnrrComboItems = Array.from(new Set(this.allFinanziamentoComponentsPnrrComboItems.map(x=>JSON.stringify(x)))).map(x=>JSON.parse(x));
      
        this.allFinanziamentoLinesPnrrComboItems =        Array.from(buildTMFinanziamento);

      this.finanziamentoMissionsPnrrComboItems =  this.allFinanziamentoMissionsPnrrComboItems;
      this.finanziamentoComponentsPnrrComboItems =        this.allFinanziamentoComponentsPnrrComboItems;
      this.finanziamentoLinesPnrrComboItems =        this.allFinanziamentoLinesPnrrComboItems;
    });
  }

  findDettagliByTipologia(tipologia: string) {
    const details = this.tipologiaItems.find((x) => x.key === tipologia);
    this.dettaglioItems = details.dettagli;
    console.log(details);
  }

  initializeFormGroup() {
    return this.formBuilder.group({
      province: [null],
      comuni: [null],
      nature: [null],
      tipologie: [null],
      dettagli: [null],
      pnrrLinea: [null],
      pnrrMissione: [null],
      pnrrComponente: [null],
    });
  }

  get f() {
    return this.myForm.controls;
  }

  onChangeFilter(event, fieldName, formControlName, fieldTitle) {
    this.conditions = this.conditions.filter((c) => c.name != fieldName);

    if ( event?.key) {
      const key = event.key;

      switch (formControlName) {
        case "province":
          this.conditions = this.conditions.filter((c) => c.name != fieldName);
          this.provinciaFiltered = [this.provinciaItems.find(x => x.key == key)];
          this.comuneItems = this.allComuneItems.filter(
            (x) => x.parentCode == key
          );
          this.comuneFiltered = this.allComuneItems.filter(
            (x) => x["parentCode"] == key
          );
          this.addSimpleCondition(key, fieldName, fieldTitle, event.value);
          this.filtersObject.provinceCode = event.key;
          break;
        case "comuni":
          this.conditions = this.conditions.filter((c) => c.name != fieldName);
          this.comuneFiltered = [this.comuneItems.find(x => x.key == key)];
          this.provinciaItems = this.allProvinciaItems.filter(x =>
            x.key == this.comuneItems.find(x => x.key == key)["parentCode"]
          );
          this.f.province.setValue(this.provinciaItems[0].key);
          this.addSimpleCondition(key, fieldName, fieldTitle, event.value);
          this.filtersObject.municipalityCode = event.key;
          break;
        case "nature":
          this.conditions = this.conditions.filter((c) => c.name != fieldName);
          this.tipologiaItems = this.allTipologiaItems.filter(
            (x) => x.parentCode == key
          );
          this.addIncludesCondition(key, fieldName, fieldTitle, event.value);
          this.f.tipologie.enable();
          this.filtersObject.natureCode = event.key;
          break;
        case "tipologie":
          this.findDettagliByTipologia(key);
          this.conditions = this.conditions.filter((c) => c.name != fieldName);
          this.naturaItems = this.allNaturaItems.filter(x =>
            x.key == this.tipologiaItems.find(x => x.key == key)["parentCode"]
          );
          this.f.nature.setValue(this.naturaItems[0].key);
          this.f.dettagli.enable();

          
          this.addIncludesCondition(key, fieldName, fieldTitle, event.value);
          this.filtersObject.tipologyCode = event.key;
          break;
        case "dettagli":
          this.conditions = this.conditions.filter((c) => c.name != fieldName);
          this.addIncludesCondition(key, fieldName, fieldTitle, event.value);
          this.filtersObject.detailCode = event.key;
          break;
        case "pnrrMissione":
          this.conditions = this.conditions.filter((c) => c.name != fieldName);
          this.f.pnrrComponente.enable();

          this.finanziamentoComponentsPnrrComboItems =
            this.allFinanziamentoComponentsPnrrComboItems.filter(
              (x) => x.parentCode == key
            );

          this.addIncludesCondition(key, fieldName, fieldTitle, event.value);
          this.filtersObject.pnrrMissionCode = event.key;
          break;
        case "pnrrComponente":
            //le condizioni devono essere filtrate per missione + componente altrimenti la componente non so di quale missione sia, in quanto C1 puo' essere su piu missioni
          this.conditions = this.conditions.filter((c) => c.name != fieldName);
          this.f.pnrrLinea.enable();
           const missione = this.f.pnrrMissione.value;//contiene il codice missione es: M3
           const chiave = missione + key;
          this.finanziamentoLinesPnrrComboItems =
            this.allFinanziamentoLinesPnrrComboItems.filter(
              (x) => x.key.startsWith(chiave)
            );

          this.addIncludesCondition(key, fieldName, fieldTitle, event.value);
          this.filtersObject.pnrrComponentCode = event.key;
          break;
        case "pnrrLinea":
          this.conditions = this.conditions.filter((c) => c.name != fieldName);
          this.addIncludesCondition(key, fieldName, fieldTitle, event.value);
          this.filtersObject.pnrrLineCode = event.key;
          break;
      }
    } else {
      switch (formControlName) {
        case "province":
          this.provinciaItems = this.allProvinciaItems;
          this.provinciaFiltered = this.allProvinciaItems;
          this.comuneItems = this.allComuneItems;
          this.comuneFiltered = this.allComuneItems;

          this.conditions = this.conditions.filter(
            (x) => x.name != "pro_com_t"
          );
          this.f.comuni.setValue(null);
          this.filtersObject.provinceCode = null;
          this.filtersObject.municipalityCode = null;
          break;
        case "comuni":
          this.comuneItems = this.allComuneItems;
          this.comuneFiltered = this.allComuneItems;
          this.provinciaItems = this.allProvinciaItems;
          this.provinciaFiltered = this.allProvinciaItems;


          this.conditions = this.conditions.filter((x) => x.name != "cod_prov");
          this.f.province.setValue(null);
          this.filtersObject.provinceCode = null;
          this.filtersObject.municipalityCode = null;
          break;
        case "nature":
          this.dettaglioItems = [];
          this.naturaItems = this.allNaturaItems;
          this.tipologiaItems = this.allTipologiaItems;

          this.conditions = this.conditions.filter(
            (x) => x.name != "tipologie" && x.name != "dettagli"
          );

          this.f.tipologie.disable();
          this.f.dettagli.disable();

          this.f.tipologie.setValue(null);
          this.f.dettagli.setValue(null);

          this.filtersObject.natureCode = null;
          this.filtersObject.tipologyCode = null;
          this.filtersObject.detailCode = null;
          break;
        case "tipologie":
          this.dettaglioItems = [];

          this.conditions = this.conditions.filter((x) => x.name != "dettagli");

          this.f.dettagli.disable();

          this.f.dettagli.setValue(null);

          this.filtersObject.tipologyCode = null;
          this.filtersObject.detailCode = null;
          break;
        case "dettagli":
          this.filtersObject.detailCode = null;
          break;
        case "pnrrMissione":
          this.conditions = this.conditions.filter(
            (x) => x.name != "pnrrComponente" && x.name != "pnrrLinea"
          );
          this.finanziamentoMissionsPnrrComboItems =
            this.allFinanziamentoMissionsPnrrComboItems;
          this.finanziamentoComponentsPnrrComboItems =
            this.allFinanziamentoComponentsPnrrComboItems;
          this.finanziamentoLinesPnrrComboItems =
            this.allFinanziamentoLinesPnrrComboItems;

          this.f.pnrrComponente.disable();
          this.f.pnrrLinea.disable();

          this.f.pnrrComponente.setValue(null);
          this.f.pnrrLinea.setValue(null);

          this.filtersObject.pnrrMissionCode = null;
          this.filtersObject.pnrrComponentCode = null;
          this.filtersObject.pnrrLineCode = null;
          break;
        case "pnrrComponente":
          this.conditions = this.conditions.filter(
            (x) => x.name != "pnrrLinea"
          );
          this.finanziamentoLinesPnrrComboItems =
            this.allFinanziamentoLinesPnrrComboItems;

          this.f.pnrrLinea.disable();

          this.f.pnrrLinea.setValue(null);

          this.filtersObject.pnrrComponentCode = null;
          this.filtersObject.pnrrLineCode = null;
          break;
        case "pnrrLinea":
          this.filtersObject.pnrrLineCode = null;
          break;
      }
    }
    this.conditions = [...this.conditions];
  }

  setResultNumber(val) {
    setTimeout(() => {
      this.getTotal();
    }, 0);
  }

  setInterventiNonLocalizzati(val) {
    setTimeout(() => {
      this.nonLocalizzati = val;
    }, 0);
  }

  setChartsFeatures(data) {
    setTimeout(() => {
      this.chartsFeatures = { features: data };
      this.chartsFeaturesLinee = {
        features: data,
        linee: this.finanziamentoLinesPnrrComboItems,
      };
      this.chartsFeaturesProvince = {
        features: data,
        province: this.provinciaFiltered,
      };
      this.chartsFeaturesComuni = {
        features: data,
        comuni: this.comuneFiltered,
      };
    }, 0);
  }

  setEnabledFields(event) {
    console.log(event);

    switch (event) {
      case "provincia":
        this.myForm.get("comuni").setValue(null);
        this.myForm.get("comuni").disable();
        break;
      case "asl":
        break;
      default:
        this.myForm.get("comuni").enable();
    }
    this.conditions = [...[]];
  }

  getOrder(fieldTitle) {
    switch (fieldTitle) {
      case "Provincia":
        return 0;
      case "Comune":
        return 1;
      case "Natura":
        return 2;
      case "Tipologia":
        return 3;
      case "Dettaglio":
        return 4;
      case "Missione":
        return 5;
      case "Componente":
        return 6;
      case "Linea":
        return 7;
      default:
        return 0;
    }
  }

  addSimpleCondition(key, fieldName, fieldTitle, fieldValue) {
    this.conditions.push({
      condition: (feature) => feature.values_[fieldName] == key,
      name: fieldName,
      order: this.getOrder(fieldTitle),
      key,
      fieldTitle,
      fieldValue,
    });
    this.conditions = this.conditions.sort((a, b) => a.order - b.order);
  }

  addIncludesCondition(key, fieldName, fieldTitle, fieldValue) {
    this.conditions.push({
      condition: (feature) => feature.values_[fieldName].includes(key),
      name: fieldName,
      order: this.getOrder(fieldTitle),
      key,
      fieldTitle,
      fieldValue,
    });
    this.conditions = this.conditions.sort((a, b) => a.order - b.order);
  }

  onSubmit(){
    // Cerca cliccato facciamo partire i filtri
    this.filteredConditions = this.conditions;
    this.filterIntervention(this.filtersObject, true);
  }





  resetFilters() {
    this.provinciaItems = this.allProvinciaItems;
    this.provinciaFiltered = this.allProvinciaItems;
    this.comuneItems = this.allComuneItems;
    this.comuneFiltered = this.allComuneItems;
    this.dettaglioItems = [];
    this.naturaItems = this.allNaturaItems;
    this.tipologiaItems = this.allTipologiaItems;

    this.f.comuni.setValue(null);
    this.f.province.setValue(null);
    this.f.nature.setValue(null);
    this.f.tipologie.setValue(null);
    this.f.dettagli.setValue(null);
    this.f.pnrrLinea.setValue(null);
    this.f.pnrrMissione.setValue(null);
    this.f.pnrrComponente.setValue(null);

    this.f.tipologie.disable();
    this.f.dettagli.disable();

    this.f.pnrrComponente.disable();
    this.f.pnrrLinea.disable();

    this.conditions = [...[]];

    this.filtersObject.provinceCode = null;
    this.filtersObject.municipalityCode = null;
    this.filtersObject.natureCode = null;
    this.filtersObject.tipologyCode = null;
    this.filtersObject.detailCode = null;
    this.filtersObject.pnrrMissionCode = null;
    this.filtersObject.pnrrComponentCode = null;
    this.filtersObject.pnrrLineCode = null;

    this.filtersObject.provinceCode = null;
    this.filtersObject.municipalityCode = null;
    this.filtersObject.natureCode = null;
    this.filtersObject.tipologyCode = null;
    this.filtersObject.detailCode = null;
    this.filtersObject.pnrrMissionCode = null;
    this.filtersObject.pnrrComponentCode = null;
    this.filtersObject.pnrrLineCode = null;

    this.filterIntervention(this.filtersObject, false);
  }

  filterIntervention(filterObj, isSearch) {
    if(!filterObj.provinceCode)
      this.muniChartsEnabler = false;
    else
      this.muniChartsEnabler = true;

    if(this.filtersActive !== filterObj)
      this.filtersActive = { ...filterObj };

    if(!isSearch)
      this.filteredConditions = this.conditions;
    this.loadPnrrChartData(filterObj);
    this.countInterventiPlurilocalizzatiDisegnatiInAltroLuogo(filterObj);
  }

  getNonLocalizzati() {
    return this.mapPnrr && this.mapPnrr.getNonLocalizzati();    
  }

  getTotal() {
    return (
      this.mapPnrr &&
      this.mapPnrr.geTotalNumTipologies() + this.getNonLocalizzati()
    );    
  }

  isCustomHeader() {
    return this.authService.getHeaderCode() != 'generale';
  }
  getHeaderLabel() {
      return this.isCustomHeader() ? 'Piano Nazionale Ripresa e Resilienza' : 'Direzione Regionale Infrastrutture e Mobilità';
  }

  

  _pnrrChartData = null;

  loadPnrrChartData(filterObj) {
    console.log('loadPnrrChartData',filterObj);
    this.dashboardService.getPnrrData(filterObj).subscribe((x) => {
      console.log(x);
      this._pnrrChartData = x;
    });
  }

  cntLocalizzatiAltrove =0;
  localizzatiAltrove = [];
  countInterventiPlurilocalizzatiDisegnatiInAltroLuogo(filterObj:any){
    this.cntLocalizzatiAltrove = 0;
    //eseguo solo se ho filtrato per comune o provincia
    if ((filterObj.provinceCode && filterObj.provinceCode!='') || (filterObj.municipalityCode && filterObj.municipalityCode!=''))
        this.geoService.countInterventiPlurilocalizzatiDisegnatiInAltroLuogo(filterObj).subscribe(
            x=>{this.localizzatiAltrove = x;this.cntLocalizzatiAltrove = x?.length}
        );
  }


  @ViewChild("chartTipologieRisorse")
  chartTipologieRisorse: ChartPnrrNaturaImportiComponent;

  @ViewChild("pillsRisorseNatura")
  pillsRisorseNatura:ElementRef;

  reloadtipologieRisorse(){
    const that = this;
    this.pillsRisorseNatura.nativeElement.style.opacity = '0';
    window.setTimeout(()=>{that.chartTipologieRisorse.reloadData();that.pillsRisorseNatura.nativeElement.style.opacity = '100';},300);    
  }

  @ViewChild("chartTipologieInterventi")
  chartTipologieInterventi: ChartPnrrNaturaTipologieComponent;
  @ViewChild("pillsInterventiNatura")
  pillsInterventiNatura:ElementRef;

  reloadtipologieInterventi(){
    const that = this;
    this.pillsInterventiNatura.nativeElement.style.opacity = '0';
    window.setTimeout(()=>{that.chartTipologieInterventi.reloadData();that.pillsInterventiNatura.nativeElement.style.opacity = '100';},300);    
  }

}
