<!--
<div *ngIf="observable$">
<div *ngFor="let progetto of observable$.progetti">
    {{printCodice(progetto.codice)}}
</div>
</div>-->
<div class="col-lg-12">
    <div class=" fixedHeaderTable col-lg-12  table-responsive table-wrapper-scroll-y scrollbar-y my-5 px-0" style="max-height: 600px;">
        <table class="table table-hover table-colore tableFixHead">
            <thead style="z-index: 90000;">

                <th class="fixedmeY bg-white " style="min-width: 290px;">
                    <button [disabled]="doingExportExcel && doingExportExcel.value" (click)="exportDetailTable();" type="button" class="btn btn-info btn-block font-weight-bold">Esporta&nbsp;<i class="fas fa-file-export"></i><img
                        *ngIf="doingExportExcel && doingExportExcel.value"
                        class="pl-3 float-right"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                      /></button></th>
                <th class="bg-white " style="z-index: 90000;">&nbsp;</th>
                <ng-container *ngIf="currentObject && currentObject.progetti">
                    <th class="bg-white text-right" style=" min-width: 150px; z-index: 90000;" *ngFor="let anno of tabellaDettaglioObjArrayAnni">
                        <span class="pr-4">{{anno}}</span>
                    </th>
                </ng-container>
                <th class="bg-white text-right" style="min-width: 180px; z-index: 90000;">
                    <span class="pr-4">totale</span>
                </th>

            </thead>
            <tbody>
                <ng-container *ngIf="currentObject && currentObject.progetti as progetti">
                    <ng-container *ngFor="let progetto of progetti;let i=index;">
                        <tr>
                            <td class="fixedme" style="max-width: 290px;">
                                <a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')" class="pointer font-weight-bold text-0083e0"
                                    data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.tr_collapse_' + i" [attr.data-target]="'.tr_collapse_' + i">
                                    <i class="fas fa-arrow-circle-down">
          </i>&nbsp;progetto {{isGr34OrAdmin()?'/ fabbisogno':''}} {{progetto.codice}}
                                </a>
                                <div class="" style="font-size: 13px;">{{progetto.descrizione}}</div>
                            </td>
                            <td style="min-width: 290px; font-size: 13px;">
                                <ul class="list-group font-14">
                                    <li class="list-group-item font-14">valore progetto{{isGr34OrAdmin()?'/fabbisogno':''}}</li>
                                    <li class="list-group-item font-14">di cui finanziato</li>
                                    <li class="list-group-item font-14">da finanziare</li>
                                </ul>
                            </td>
                            <td style="min-width: 150px;" *ngFor="let anno of tabellaDettaglioObjArrayAnni">
                                <ul class="list-group font-14 text-right">
                                    <li class="list-group-item font-14">{{printValueAsCurrency(getValore(progetto,anno))}}</li>
                                    <li class="list-group-item font-14">{{printValueAsCurrency(getFinanziato(progetto,anno))}}</li>
                                    <li class="list-group-item font-14">{{printValueAsCurrency(getValore(progetto,anno) - getFinanziato(progetto,anno))}}</li>
                                </ul>
                            </td>
                            <td style="min-width: 180px;">
                                <ul class="list-group font-14 text-right">
                                    <li class="list-group-item font-14">
                                        <strong>{{printValueAsCurrency(getTotaleValore(progetto))}}</strong></li>
                                    <li class="list-group-item font-14">
                                        <strong>{{printValueAsCurrency(getTotaleFinanziato(progetto))}}</strong></li>
                                    <li class="list-group-item font-14">
                                        <strong>{{printValueAsCurrency(getTotaleValore(progetto) -  getTotaleFinanziato(progetto) )}}</strong></li>
                                </ul>
                            </td>
                        </tr>
                        <ng-container *ngIf="progetto && progetto.interventi">
                            <ng-container *ngFor="let intervento of progetto.interventi;let j = index;">
                                <tr class="background-table collapse {{'tr_collapse_' + i}}">
                                    <th class="fixedme" style="max-width: 290px;" scope="row">
                                        intervento {{intervento.codice}}
                                        <div class="" style="font-size: 13px; font-weight: normal;">
                                            {{intervento.descrizione}}</div>
                                    </th>

                                    <td style="min-width: 290px; font-size: 13px;">
                                        <ul class="list-group font-14">
                                            <li class="list-group-item font-14">valore intervento</li>
                                            <li class="list-group-item font-14">di cui finanziato</li>
                                            <li class="list-group-item font-14">da finanziare</li>
                                        </ul>
                                    </td>

                                    <td style="min-width: 150px;" *ngFor="let anno of tabellaDettaglioObjArrayAnni">
                                        <ul class="list-group font-14 text-right">
                                            <li class="list-group-item font-14">{{printValueAsCurrency(getValore(intervento,anno))}}</li>
                                            <li class="list-group-item font-14">{{printValueAsCurrency(getFinanziato(intervento,anno))}}</li>
                                            <li class="list-group-item font-14">{{printValueAsCurrency(getValore(intervento,anno) - getFinanziato(intervento,anno))}}</li>
                                        </ul>
                                    </td>
                                    <td style="min-width: 180px;">
                                        <ul class="list-group font-14 text-right">
                                            <li class="list-group-item font-14 ">
                                                <strong>{{printValueAsCurrency(getTotaleValore(intervento))}}</strong></li>
                                            <li class="list-group-item font-14">
                                                <strong>{{printValueAsCurrency(getTotaleFinanziato(intervento))}}</strong></li>
                                            <li class="list-group-item font-14">
                                                <strong>{{printValueAsCurrency(getTotaleValore(intervento) -  getTotaleFinanziato(intervento) )}}</strong></li>
                                        </ul>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>

                    </ng-container>

                    <ng-container *ngIf="!(currentObject && currentObject.progetti && currentObject.progetti.length>0)">
                        <tr>
                            <td colspan="20">Nessun progetto {{isGr34OrAdmin()?'/ fabbisogno':''}} presente</td>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>




        </table>

    </div>
</div>