import { CurrencyPipe } from '@angular/common';
import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    TemplateRef,
    Input,
    ElementRef,
    Output,
    EventEmitter
} from "@angular/core";
import { registerLocaleData } from '@angular/common';
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { InterventoService, FinanziamentoService, ProgettoService } from '../../services';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from '../../../../components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component'
import localeIt from '@angular/common/locales/it';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OrderPipe } from 'ngx-order-pipe';
import { ProgettoDivDettaglioService } from '../progettoDivDettaglio/progetto-div-dettaglio.service';
registerLocaleData(localeIt, 'it');
defineLocale('it', itLocale);
import {IsDirtyHelper} from "../../../../helpers/is-dirty.helper"
import { AuthService } from 'src/app/services';

@Component({
    selector: 'intervento-finanziamento-component',
    templateUrl: './intervento-finanziamento.component.html',
    styleUrls: ['./intervento-finanziamento.component.css']
})
export class InterventoFinanziamentoComponent implements OnInit, OnDestroy {

    public mask: any = {
        mask: 'KK €',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            }
        }
    };

    ngOnDestroy(): void {
        this.clearSubscriptions();
    }

    submitted: boolean = false;
    loading: boolean = false;
    anniList: any;
    esistonoInterventi= false;
    private _interventoId: number;
    readOnlyForm:boolean = false;
    @Input()
    set interventoId(value) {
        //console.log(`inside set interventoId= ${value}`);
        this._interventoId = value;
        if (!value) return;
        //this.reloadData();
    }
    get interventoId() {
        //console.log(`inside get interventoId= ${this._interventoId}`);
        return this._interventoId;
    }

    modalSubscriptions = [];

    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('direzioneValueTemplate', { static: true })
    public direzioneValueTemplate: TemplateRef<any>;


    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    @ViewChild('importoValueTemplate', { static: true })
    public importoValueTemplate: TemplateRef<any>;




    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    templateList = [];

    dtOptions: any;

    rows: any[];
    columns = [
        {
            name: '', prop: 'id', sortable: true, filtrable: false, maxWidth: 0, width: 0, visible: false
            // 'buttonsTemplate'
        }
        , {
            name: 'codice'
            , prop: 'finanziamento.codice'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            , minWidth: 100
        }

        , {
            name: 'finanziamento'
            , prop: 'finanziamento.titolo'
            , sortable: true
            , filtrable: true
            , flexGrow: 5
            , minWidth: 240
        }
        , {
            name: 'importo'
            , prop: 'importo'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'importoValueTemplate'
            , flexGrow: 3
            , minWidth: 200
        }
        , {
            name: 'modifica'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60

        }
        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60

        }
    ];



    modalTitle: string = '';
    modalButtonLabel: string = '';

    modalRef: BsModalRef;
    modalRefTemplateImporti: BsModalRef;

    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };


    @Output()
    reloadFinanziamentiForSubInterventi: EventEmitter<any> = new EventEmitter();

    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;

    @ViewChild('templateModalImporti', { static: true })
    public templateModalImporti: TemplateRef<any>;


    finanziamentiList: any[];

    editForm: FormGroup;
    currentIntervento: any;
    importiIsError: boolean = false;
    importiList = [];
    constructor(
        private toastr: ToastService
        , private modalService: BsModalService
        , private localeService: BsLocaleService
        , private interventoService: InterventoService
        , private finanziamentoService: FinanziamentoService
        , private formBuilder: FormBuilder
        , private el: ElementRef
        , private orderPipe: OrderPipe
        , private progettoDivDettaglioService: ProgettoDivDettaglioService
        ,private isDirtyHelper:IsDirtyHelper
        ,private authService:AuthService
        ,private progettoService:ProgettoService
    ) {
        this.localeService.use("it");
        this.dtOptions = {
            rows: this.rows
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: false// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: false// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: false
            , scrollbarH: "true"
        };
        this.editForm = this.initializeFormGroup();
        this.editFormImporti = this.initializeFormGroupImporti();
    }

    setIsDirty(value:boolean){
        this.isDirtyHelper.isDirty = value;
    }

    initializeFormGroup(): FormGroup {
        let frm =  this.formBuilder.group({
            id: [null],
            finanziamentoId: [null, [Validators.required]],
            importo: [{ value: null, disabled: true }],
            disponibilitaFinanziamento: [{ value: null, disabled: true }],
            importoFinanziamento: [{ value: null, disabled: true }]
        });
        frm.valueChanges.subscribe(val=>
            this.setIsDirty(frm.dirty)
            );
        return frm;
    }

    initializeFormGroupImporti(): FormGroup {
        return this.formBuilder.group({
            id: [null],
            anno: ['', [Validators.required, Validators.maxLength(4), Validators.minLength(4)]],
            importo: [null, [Validators.required]]
            , disponibilita: [{ value: null, disabled: true }]
            , importoInterventoAnnualita: [{ value: null, disabled: true }]
        });
    }


    ngOnInit() {
        this.templateList = [
            { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }
            , { name: 'btnEditTemplate', template: this.btnEditTemplate }
            , { name: 'direzioneValueTemplate', template: this.direzioneValueTemplate }
            , { name: 'importoValueTemplate', template: this.importoValueTemplate }


        ];
        window.scroll(0, 0);
        //this.getAllFinanziamenti();     
    }

    deepCopyArray(source){
        let ris = [];
        source.forEach(val => ris.push(Object.assign({}, val)));
        return ris;
    }


    onFinanziamentoChange($event) {
        // if ((!$event && !this.previousFinanziamentoValue) || ($event?$event.id:-1) == this.previousFinanziamentoValue)return;
        let disponibilita = '';
        let importo = '';
        this.anniList = [];

        if ($event) {
            disponibilita = $event['disponibilitaResidua'] ? ($event['disponibilitaResidua'].toFixed(2)) : '0';
            importo = $event['importoComplessivo'] ? $event['importoComplessivo'].toFixed(2) : '';
            this.anniList = this.deepCopyArray($event.annualita);
            this.anniList = this.intersect(this.anniList, this.annualitaIntervento);
            this.anniList = this.sortImporti(this.anniList);
            this.annualitaInterventoTemp = this.deepCopyArray(this.annualitaIntervento);
        }

        this.editForm.controls.disponibilitaFinanziamento.setValue(disponibilita);
        this.editForm.controls.importoFinanziamento.setValue(importo);
        return true;
    }

    private intersect(a: any[], b: any[]) {
        return a.filter(x => b.find(y => y['anno'] == x['anno']));
        //return a.filter(Set.prototype.has, new Set(b));
    }

    annualitaIntervento = [];

    loadDataCounter = 0;
    intervento = null;
    getInterventoData(id: number,callback?:any) {
        let that = this;
        this.loadDataCounter++;   
        this.esistonoInterventi = false;
        this.interventoService.get(id).subscribe(
            result => {
                this.intervento = result;
                that.columns[4].visible = true;
                that.columns[5].visible = true;
                that.currentIntervento = result;
                let status = (result.validazione?(result.validazione.validationStatus?result.validazione.validationStatus:0):0).toString().toUpperCase();
                that.readOnlyForm = (status =='1' 
                || status =='APPROVED' 
                || status =='WAITING_FOR_APPROVAL_FIRST_LEVEL' || status =='4'
                || status =='WAITING_FOR_APPROVAL_SECOND_LEVEL' || status =='5'
                || status =='TERMINATO'
                || result.isReadOnly
                ); 
                if (that.readOnlyForm){
                    that.columns[4].visible = false;
                    that.columns[5].visible = false;
                }
                if (!result.isMain) that.getAllFinanziamentiOfMainIntervento(id,callback);
                else that.getAllFinanziamenti(callback);
                that.ngxDatatableWrapper.dtOptions.columns = that.columns;
                that.ngxDatatableWrapper.dtOptions = that.ngxDatatableWrapper.dtOptions;
                that.ngxDatatableWrapper.refresh();
                this.loadDataCounter--;   
                this.esistonoInterventi = false;
                if (result.isMain)
                    this.progettoService.getInterventiCount(result.progettoId).subscribe(
                        res => {
                            that.esistonoInterventi = res - 1 > 0;
                            
                        }
                    );

            }

        );

        this.loadDataCounter++;   
        this.ngxDatatableWrapper.isLoading = true;
        this.ngxDatatableWrapper.setInitialData([]);
        this.interventoService.getFinanziamenti(id).subscribe(
            result => {
                that.loadDataCounter--;   
                that.ngxDatatableWrapper.dtOptions.columns = that.columns;
                that.ngxDatatableWrapper.dtOptions = that.ngxDatatableWrapper.dtOptions;
                that.ngxDatatableWrapper.setInitialData(this.orderPipe.transform(result, 'finanziamento.titolo'));
                this.ngxDatatableWrapper.isLoading = false;
                //console.log(`result= ${JSON.stringify(result)}`);
                that.loadDataCounter++;   
                that.interventoService.getAnnualita(id).subscribe(
                    res => {
                        that.annualitaIntervento = res;

                        res.forEach(annualitaIntervento => {
                            annualitaIntervento['impegnato'] = 0.0;
                            result.forEach(finanziamento => {
                                let annualitaFinanziamento = finanziamento.annualita.find(ann => ann['anno'] == annualitaIntervento['anno']);
                                annualitaIntervento['impegnato'] += annualitaFinanziamento ? annualitaFinanziamento.importo : 0.0;
                                annualitaIntervento['impegnato'] = parseFloat(annualitaIntervento['impegnato'].toFixed(2));
                            });
                        });
                        that.loadDataCounter--;   

                    }
                );
            }
        );
    }

    getAllFinanziamenti(callback:any) {
        let that = this;
        this.loadDataCounter++;   
        this.loading = true;
        this.finanziamentoService.getAll().subscribe(
            result => {
                this.loading = false;
                //se è pnrr, devo mostrare tutti i finanziamenti non pnrr e quelli pnrr della tripletta che ho selezionato
                if (that.intervento && that.intervento.pnrrRilevante){
                    const lineaId = that.intervento.pnrrLineaFinanziamento.id;
                    result = result.filter(x=>!x['pnrrRilevante'] || (x['pnrrLineaFinanziamento'] && x['pnrrLineaFinanziamento'].id == lineaId));
                }
                
                that.finanziamentiList = this.orderPipe.transform(result, 'titolo');;//id e titolo
                that.finanziamentiList = that.finanziamentiList.map(x=>{return {...x,titoloLungo:(x.codice?x.codice + ' - ':'') + x.titolo};});
                that.finanziamentiList.sort((a,b)=>a.id - b.id);
                if (callback)callback();
                this.loadDataCounter--;   
            }
        );
    }

    getAllFinanziamentiOfMainIntervento(id: number,callback:any) {
        let that = this;
        this.loadDataCounter++;   
        this.loading = true;
        this.interventoService.getFinanziamentiOfMainIntervento(id).subscribe(
            result => {
                this.loading = false;
                let finList = [];
                if (that.intervento?.pnrrRilevante){
                    const lineaId = that.intervento.pnrrLineaFinanziamento.id;
                    result = result.filter(x=>x['finanziamento']['pnrrRilevante'] && (x['finanziamento']['pnrrLineaFinanziamento'] && x['finanziamento']['pnrrLineaFinanziamento'].id == lineaId));
                }
                else{
                    result = result.filter(x=>!x['finanziamento']['pnrrRilevante'] );
                }
                result.forEach(x => {
                    let fin = x.finanziamento;
                    fin.importoComplessivo = x.importo;
                    let sumDisponibilita = 0;
                    x.annualita.forEach(a=>{
                        sumDisponibilita += a.disponibilita;
                        }
                    );
                    fin.disponibilitaResidua = sumDisponibilita;
                    fin.annualita = x.annualita;
                    finList.push(fin);
                });
                that.finanziamentiList = that.orderPipe.transform(finList, 'titolo');;//id e titolo
                that.finanziamentiList = that.finanziamentiList.map(x=>{return {...x,titoloLungo:(x.codice?x.codice + ' - ':'') + x.titolo};});
                that.finanziamentiList.sort((a,b)=>a.id - b.id);
                if (callback)callback();
                this.loadDataCounter--;   
            }
        );
    }





    annualitaInterventoTemp ;

    openAddModal() {
        //// this.selectedInterventoData = JSON.stringify({interventoId:null,projectId:this.projectId});        
        this.modalTitle = 'Associa nuovo finanziamento';
        this.editForm.reset();
        this.importiList = [];
        this.modalButtonLabel = 'Inserisci';
        this.manageEditOfFinanziamentoSelect();
        this.modalRef = this.modalService.show(this.templateEditAdd, this.config);
        this.setIsDirty(false);
        this.annualitaInterventoTemp = this.deepCopyArray(this.annualitaIntervento);
    }



    onEdit(row, notOpenModal?: boolean) {
        console.log(`into onEdit`);
        if (this.loading) return;
        //  this.selectedInterventoData = JSON.stringify({interventoId:row.id,projectId:this.projectId});        
        this.modalTitle = 'Modifica associazione finanziamento';
        this.modalButtonLabel = 'Aggiorna';
        this.editForm.setValue(
            {
                id: row.id
                , finanziamentoId: row.finanziamento.id
                , importo: '' + row.importo
                , disponibilitaFinanziamento: null,
                importoFinanziamento: null
            }
        );
        this.onFinanziamentoChange(this.finanziamentiList.find(x=>x.id == row.finanziamento.id));
        row.annualita.sort((a, b) => a.anno - b.anno);
        //this.onFinanziamentoChange(row.finanziamento);
        this.importiList = this.deepCopyArray(row.annualita);
        this.manageEditOfFinanziamentoSelect();
        this.editForm.controls.finanziamentoId.disable();
        if (!notOpenModal) this.modalRef = this.modalService.show(this.templateEditAdd, this.config);
        this.setIsDirty(false);
        this.annualitaInterventoTemp = this.deepCopyArray(this.annualitaIntervento);
    }

    onDelete(row) {
        if (this.loading) return;
        console.log(row);
        if (!confirm(`Stai per eliminare l'associazione con il finanziamento "${row.finanziamento.titolo} - ${row.importo}", proseguire?`)) return;
        this.loading = true;
        let myService = this.interventoService;
        myService.deleteFinanziamento(this.interventoId, row.id).subscribe(
            result => {
                this.loading = false;
                //this.ngxDatatableWrapper.deleteRow(row);
                this.toastr.success('Associazione con il finanziamento eliminata con successo', null, {
                    timeOut: 2000, disableTimeOut: false
                });
                
                this.progettoDivDettaglioService.reload();
                this.getInterventoData(this._interventoId);
                this.reloadFinanziamentiForSubInterventi.emit();
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile eliminare l'associazione con il finanziamento: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                console.log('error');
            });
    }

    clearSubscriptions() {
        this.modalSubscriptions.forEach(x => x.unsubscribe());
        this.modalSubscriptions = [];
    }

    closeModal() {
        if(!this.isDirtyHelper.checkIsDirty())return false;
        this.modalRef.hide();
    }

    onSubmit() {
        this.submitted = true;
        this.editForm.updateValueAndValidity();
        for (let c in this.editForm.controls) {
            this.editForm.controls[c].updateValueAndValidity();
        }
        let errors = false;
        for (let c in this.editForm.controls) {
            errors = errors || this.editForm.controls[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.editForm.controls[c].errors)
            );
        }
        if (this.editForm.invalid || errors) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.editForm.controls) {
                if (this.editForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.editForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.editForm.get(el)["controls"]) {
                            if (this.editForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }

        const obj = this.editForm.getRawValue();

        /*let sommaImporti = 0.0;
        this.importiList.forEach(x=>sommaImporti +=parseFloat(x.importo));
        if (sommaImporti > parseFloat(obj.importo)){
            this.toastr.error("La somma degli importi annuali è maggiore del valore dell'importo del finanziamento");
            return false;
        }*/

        obj.finanziamento = this.finanziamentiList.find(x => x.id == obj.finanziamentoId);
        obj.interventoId = this.interventoId;
        obj.annualita = this.importiList;
        this.loading = true;
        this.interventoService.saveFinanziamento(obj).subscribe(res => {
            this.loading = false;
            this.submitted = false;
            this.setIsDirty(false);
            this.toastr.success("Finanziamento salvato con successo!");
            this.getInterventoData(this._interventoId,()=>this.onEdit(res,true));
            //this.onEdit(res,true);            
            this.progettoDivDettaglioService.reload();
            this.reloadFinanziamentiForSubInterventi.emit();
        }, error => {
            this.loading = false;
            this.toastr.error(`Impossibile salvare il finanziamento: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
            });
            //this.toastr.error("Errore di sistema");
        });
    }

    calculateSumOfImporti() {
        let result = 0.0;
        if (this.importiList) {
            this.importiList.forEach(x => result += parseFloat(x.importo.toFixed(2)));
        }
        return result;
    }

    manageEditOfFinanziamentoSelect() {
        if (this.importiList && this.importiList.length > 0) this.editForm.controls.finanziamentoId.disable();
        else this.editForm.controls.finanziamentoId.enable();
    }


    removeImporto(item) {


        //let annIntervento = this.annualitaIntervento.find(x => x.anno == $event['anno']);
        //let importoInterventoAnnualita = annIntervento.importo - annIntervento['impegnato'];


        this.importiList = this.sortImporti(this.importiList.filter(x => x.anno != item.anno));
        this.toastr.success(`Importo eliminato con successo`, null, {
            timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
        });
        
        this.editForm.controls.importo.setValue(this.calculateSumOfImporti().toString());
        //v- 31/08/2020: aggiorno la disponibilita del finanziamento
        let disponibilita =parseFloat(( parseFloat(this.editForm.getRawValue().disponibilitaFinanziamento) + parseFloat(item.importo)).toFixed(2));
        this.editForm.controls.disponibilitaFinanziamento.setValue(disponibilita.toString());
        //v- 31/08/2020: aggiorno temporaneamente la disponibilita del finanziamento per l'anno eliminato
        this.anniList.find(x=>x.anno == item.anno).disponibilita = parseFloat((parseFloat(this.anniList.find(x=>x.anno == item.anno).disponibilita) + parseFloat(item.importo)).toFixed(2));
        let annIntervento = this.annualitaInterventoTemp.find(x => x.anno == item['anno']);
        annIntervento['impegnato'] -=item.importo;

        this.manageEditOfFinanziamentoSelect();
        this.setIsDirty(true);
    }

    closeModalImporti() {
        this.modalRefTemplateImporti.hide();
        this.editForm.controls.importo.setValue(this.calculateSumOfImporti().toString());
        this.manageEditOfFinanziamentoSelect();
    }
    editFormImporti: FormGroup;
    submittedImporti: boolean = false;
    modalTitleImporti: string = "Inserisci nuovo importo";
    modalButtonLabelImporti: string = "Inserisci";



    openModalImportiAdd() {
        this.modalTitleImporti = "Inserisci nuovo importo";
        this.modalButtonLabelImporti = "Inserisci";
        this.editFormImporti.reset();
        this.submittedImporti = false;
        this.modalRefTemplateImporti = this.modalService.show(this.templateModalImporti, this.config);
        /*this.editForm.setValue({
            id: row.id,
            descrizione:row.descrizione,            
        });*/
    }

    onSubmitImporti() {
        this.submittedImporti = true;
        var errors = false;
        for (let c in this.editFormImporti.controls) {
            errors = errors || this.editFormImporti.controls[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.editFormImporti.controls[c].errors)
            );
        }


        console.log("errors:" + errors);
        if (this.editFormImporti.invalid || errors
        ) {
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (var el in this.editFormImporti.controls) {
                if (this.editFormImporti.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.editFormImporti.controls[el] instanceof FormGroup) {
                        for (var el1 in this.editFormImporti.get(el)["controls"]) {
                            if (this.editFormImporti.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            console.log("invalid");
            return false;
        }
        let importoObj = this.editFormImporti.value;
        console.log(`this.editFormImporti.value = ${JSON.stringify(this.editFormImporti.value)}`);
        if (!this.importiList) this.importiList = [];
        if (this.importiList.filter(x => x.anno == importoObj.anno).length > 0) {
            this.toastr.error(`Esiste già un importo per l'anno ${importoObj.anno}`, null, {
                timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
            });
            return false;
        }

        importoObj.importo = parseFloat(importoObj.importo);
        this.importiList.push(importoObj);
        this.importiList = this.sortImporti(this.importiList);
        this.toastr.success(`Importo aggiunto con successo`, null, {
            timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
        });
        
        this.closeModalImporti();
        this.submittedImporti = false;
        //v: 31/08/2020: calcolo il valore della disponibilita anche se ancora non ho salvato
        let disponibilita = parseFloat((parseFloat(this.editForm.getRawValue().disponibilitaFinanziamento) - importoObj.importo).toFixed(2));
        this.editForm.controls.disponibilitaFinanziamento.setValue(disponibilita.toString());
        //v- 31/08/2020: aggiorno temporaneamente la disponibilita del finanziamento per l'anno aggiunto
        let annualita = this.anniList.find(x=>x.anno == importoObj.anno);
        annualita.disponibilita = parseFloat(((annualita.disponibilita) - importoObj.importo).toFixed(2));
        this.annualitaInterventoTemp.find(x=>x.anno == importoObj.anno).impegnato = parseFloat(importoObj.importo);
        annualita['impegnato']  = parseFloat(importoObj.importo);
        this.setIsDirty(true);
    }
    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
    gotoBackBtnClick() {
        this.gotoBtnFunction.emit(true);
    }

    printImportoGridValue(row) {
        console.log('hi');
        if (row.annualita) {
            let html = '';
            row.annualita.forEach(element => {
                html += `<li>${element.anno}</li>`;
            });
            return `<ul>${html}</ul>`;
        }
        else return this.printValueAsCurrency(row.importo);
    }
    printValueAsCurrency(value) {
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }
    sortImporti(arr) {
        return this.orderPipe.transform(arr, 'anno');
    }

    onChangeAnnoImporto($event) {
        this.editFormImporti.controls.disponibilita.setValue(($event.disponibilita.toString()));
        let annIntervento = this.annualitaInterventoTemp.find(x => x.anno == $event['anno']);
        let importoInterventoAnnualita = (annIntervento.importo - annIntervento['impegnato']).toFixed(2);
        this.editFormImporti.controls.importoInterventoAnnualita.setValue(importoInterventoAnnualita.toString());
    }


    reloadData(){
        if (this._interventoId)this.getInterventoData(this._interventoId);
    }


    @ViewChild('templateModalNuovoFinanziamento', { static: true })
    public templateModalNuovoFinanziamento: TemplateRef<any>;
    modalRefTemplateNuovoFinanziamento: BsModalRef;

    closeModalNuovoFinanziamento(){
        this.modalRefTemplateNuovoFinanziamento.hide();

        if (!this.intervento.isMain) this.getAllFinanziamentiOfMainIntervento(this.intervento.id,null);
        else this.getAllFinanziamenti(null);

    }
    fakeFinanziamentoId:-1;
    openNewFinanziamentoModal(){
        let config = {
            animated: true,
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl"
        };
        this.modalRefTemplateNuovoFinanziamento = this.modalService.show(this.templateModalNuovoFinanziamento, config);
        if (!this.fakeFinanziamentoId)this.fakeFinanziamentoId = -1;
        else this.fakeFinanziamentoId = null;
        
    }

    saveNewFinanziamentoEmitter($event){
        this.toastr.warning(`Ricordati di associare il finanziamento appena creato alla progettualità / intervento`, null, {
            timeOut: 10000, disableTimeOut: false
        });

        
    }

    isGr34(){
        return this.authService.isGr34();
    }
    isAdmin(){
        return this.authService.isAdmin();
    }
}