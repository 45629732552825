import {
    Component
} from "@angular/core";

@Component({
    templateUrl: "./dashboard-pnrr-pubblica.component.html",
    styleUrls: ["./dashboard-pnrr-pubblica.component.css"]
})
export class DashboardPnrrPubblicaComponent {
    constructor() { }

}
