import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    TemplateRef,
    Input,
    Output,
    EventEmitter,
    AfterViewInit,
} from "@angular/core";
import { DocumentazioneComponent } from "../documentazione/documentazione.component";

@Component({
    selector: "documentazione-completa-component",
    templateUrl: "documentazione-completa.component.html",
    styleUrls: ["documentazione-completa.component.css"],
})
export class DocumentazioneCompletaComponent implements OnInit,AfterViewInit {
    ngAfterViewInit(): void {
        this.documentazioneComponent.loadAllData();
    }
    ngOnInit(): void {
        
    }

    @ViewChild("documentazioneComponent", { static: true })
    documentazioneComponent: DocumentazioneComponent;
}