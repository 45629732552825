<accordion [isAnimated]="true">
  <accordion-group [id]="capitolo.id" #accordionDatiStatistici >
    <button
      class="arrow-toggle btn btn-link btn-block justify-content-between d-flex w-100 shadow-none flex-row-reverse"
      accordion-heading type="button" >
      <a class=" pull-left float-left text-white" title="{{ capitolo.descrCpt }}" aria-label="rimuovi capitolo">
        <i title="Rimuovi capitolo" aria-hidden="true" class="fas fa-times-circle text-white" (click)="onDeleteCapitolo($event, capitolo)"></i>
          Capitolo {{ capitolo.codCpt }}&emsp;
        <i class="fas fa-question-circle" aria-hidden="true"></i>
      </a>
    </button>

    <app-capitolo-info [capitolo]="capitolo"></app-capitolo-info>

    <div class="card ">
      <h5 class="card-header text-white bg-info" style="background-color: #17a2b8!important;" [innerHtml]="checkTitleByService()"></h5>

      <!-- Cerca -->
      <div class="form-group row justify-content-end mt-3 pr-3 mb-0">
        <label for="colFormLabelSm" class="col-sm-1 col-form-label col-form-label-sm text-right pr-0">Cerca:</label>
        <div class="col-sm-3">
          <input type="text" class="form-control form-control-sm" (keyup)="searchImpegniAccertamenti($event)">
        </div>
      </div>

      <div class="card-body " style="padding-left: 0.25rem!important; padding-right: 0.25rem!important;">
        <div class="table-responsive scrollbar-y" >
          <div class="p-5 container-xl text-center" [hidden]="!loadingImpegni">
            <app-loading message="Caricamento in corso..."></app-loading>
          </div>

          <div *ngIf="!loadingImpegni && impegniAccertamenti.length === 0" class="alert alert-warning" role="alert">
            {{ getNoDataPresent() }}
          </div>

          <div class="p-5 container-xl text-center" [hidden]="!savingData">
            <app-loading message="Salvataggio in corso..."></app-loading>
          </div>

          <table class="table small" [hidden]="savingData" *ngIf="!loadingImpegni && impegniAccertamenti.length > 0">
            <thead class=" ">
              <tr class=" ">
                <th class="pr-2 pl-1" vscope="col">
                  <div class="custom-control custom-switch px-0 ">
                    <ngx-input-switch [hidden]="readOnlyForm"
                      title="seleziona/deseleziona tutti gli accertamenti"
                      label=""
                      [value]="getSwitchDataSelectUnselectAll(accertamento)"
                      (change)="onAbilitateAllImpegniAccertamenti(capitolo)"
                      *ngIf="checkSelectAllPresence()">
                    </ngx-input-switch>
                  </div>
                </th>
                <th class="px-1" scope="col">Anno {{ getLabelFieldsByType() }}</th>
                <th class="px-1" scope="col">Numero {{ getLabelFieldsByType() }}</th>
                <th class="px-1" scope="col">Descrizione {{ getLabelFieldsByType() }}</th>
                <th class="px-1" scope="col">Soggetto {{ getLabelSoggettoByType() }}</th>
                <th class="px-1" scope="col">Importo Iniziale</th>
                <th class="px-1" scope="col">Importo Modifiche</th>
                <th class="px-1" scope="col">Valore Attuale</th>
                <th class="px-1" scope="col">Numero Provv.</th>
                <th class="px-1" scope="col">Data Provv.</th>
                <th class="px-1" scope="col">Anno {{ getLabelFieldsByType() }} Ribaltato</th>
                <th class="px-1" scope="col">Numero {{ getLabelFieldsByType() }} Ribaltato</th>
                <th class="px-4" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let accertamento of impegniAccertamenti">
                <tr *ngIf="!(accertamento.isChangeSoggetto && accertamento.annoPerenzione)">
                  <td class="pr-2 pl-1">
                    <div class="custom-control custom-switch px-0" *ngIf="!accertamento.isChangeSoggetto && !accertamento.annoPerenzione" [hidden]="readOnlyForm">
                      <ngx-input-switch
                        title="seleziona/deseleziona accertamento"
                        label=""
                        [value]="getSwitchData(accertamento)"
                        (change)="onAbilitateImpegnoAccertamento(accertamento)">
                      </ngx-input-switch>
                    </div>
                  </td>
                  <td class="px-1">{{ !accertamento.isChangeSoggetto ? accertamento.annoImpacc : '' }}</td>
                  <td class="px-1">{{ !accertamento.isChangeSoggetto ? accertamento.numeroImpacc : '' }}</td>
                  <td class="px-1">
                    <span class="line-clamp" title="{{ accertamento.descrImpacc }}" >{{ !accertamento.isChangeSoggetto ? accertamento.descrImpacc : '' }}</span>
                  </td>
                  <td class="px-1" [ngClass]="{ 'border-top-0 border-left': accertamento.isChangeSoggetto }" *ngIf="!accertamento.annoPerenzione">{{ accertamento.ragsocSoggetto }}</td>
                  <td class="px-1" [ngClass]="{ 'border-0': accertamento.isChangeSoggetto }" style="text-align: right;" *ngIf="!accertamento.annoPerenzione">{{ insertDotForThousands(accertamento.importoIniImpacc) }}</td>
                  <td class="px-1" [ngClass]="{ 'border-0': accertamento.isChangeSoggetto }" style="text-align: right;" *ngIf="!accertamento.annoPerenzione">{{ insertDotForThousands(accertamento.importoModifica) }}</td>
                  <td class="px-1" [ngClass]="{ 'border-0': accertamento.isChangeSoggetto }" style="text-align: right;" *ngIf="!accertamento.annoPerenzione">{{ insertDotForThousands(accertamento.valoreAttualeImpacc) }}</td>
                  <td class="px-1" [ngClass]="{ 'border-0': accertamento.isChangeSoggetto }" *ngIf="!accertamento.annoPerenzione">{{ accertamento.numApprovAtto }}</td>
                  <td class="px-1" [ngClass]="{ 'border-0': accertamento.isChangeSoggetto }" *ngIf="!accertamento.annoPerenzione">{{ getDataFormat(accertamento.dataAtto) }}</td>
                  <td class="px-1" [ngClass]="{ 'border-0': accertamento.isChangeSoggetto }" *ngIf="!accertamento.annoPerenzione">{{ accertamento.annoImpacc > accertamento.annoImpaccRib ? '' : accertamento.annoImpaccRib }}</td>
                  <td class="px-1" [ngClass]="{ 'border-0': accertamento.isChangeSoggetto }" *ngIf="!accertamento.annoPerenzione">{{ accertamento.annoImpacc > accertamento.annoImpaccRib ? '' : accertamento.numeroImpaccRib }}</td>
                  <td colspan="8" class="px-1" [ngClass]="{ 'border-top-0 border-left': accertamento.isChangeSoggetto }" *ngIf="accertamento.annoPerenzione">IMPEGNO IN PERENZIONE DAL {{ accertamento.annoPerenzione }}<br>ENTRA IN SICER PER VISUALIZZARE I DETTAGLI</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </accordion-group>
</accordion>
