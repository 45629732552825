import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/services';
import { SicerGestioneMicroservicesService } from 'src/app/modules/inframob/services/sicer-gestione-microservices.service';
//import { sicerMicroservicesConfig } from '../../../../../environments/sicer-microservices-config';

@Component({
  selector: 'app-sicer-gestione-microservices',
  templateUrl: './sicer-gestione-microservices.component.html',
  styleUrls: ['./sicer-gestione-microservices.component.css']
})
export class SicerGestioneMicroservicesComponent implements OnInit {

  loadingServices: any = {};

  microservices: any =null;// sicerMicroservicesConfig;

  constructor(private sicerGestioneMsService: SicerGestioneMicroservicesService,
    private toastService: ToastService) {
  }

  checkResponse(data: any) {
    if (data){
      this.toastService.success(`Schedulazione avviata`)
    }
    else{
      this.toastService.error("Errore, servizio di schedulazione non disponibile")
    }
  }

  scheduleMs = function (item: any) {
    this.loadingServices[item.id]=item.id;
    this.sicerGestioneMsService.scheduleMs(item.endpoint, item.params)
      .subscribe((data: any) => {
        this.data = data;
        this.checkResponse(data)
      },
        (error: any) => {
          console.log("Error");
          console.log(error);
          this.toastService.error("Errore, servizio di schedulazione non disponibile")
          delete this.loadingServices[item.id];
        },
        () => {
          delete this.loadingServices[item.id];
        });
  }

  ngOnInit(): void {
  }

}
