import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { MotivazioniService } from "./motivazioni-service";
import { SoggettoResponsabileService } from "./soggetto-responsabile.service";
import { AreeTematicheService } from "./aree-tematiche.service";
import { TipoFinanziamentoService } from "./tipi-finanziamento.service";

@Injectable({
    providedIn: "root"
})
export class StorageService {
    elencoPriorita: Observable<any>;
    elencoRup: Observable<any>;
    elencoAreeTematiche: Observable<any>;
    elencoTipiFinanziamento: Observable<any>;
    private storage_key_priorita = 'priorita';
    private storage_key_rup = 'elencorup';
    private storage_key_aree_tematiche = 'elencoareetematiche';
    private storage_key_tipi_finanziamento = 'tipi_finanziamento';

    private elencoPrioritaSubject: BehaviorSubject<any>;
    private elencoRupSubject: BehaviorSubject<any>;
    private elencoAreeTematicheSubject: BehaviorSubject<any>;
    private elencoTipiFinanziamentoSubject: BehaviorSubject<any>;

    constructor(
        private motivazioniService: MotivazioniService
        ,private soggettoResponsabileService:SoggettoResponsabileService
        , private areeTematicheService:AreeTematicheService
        ,private tipiFinanziamentoService:TipoFinanziamentoService,
        ){
        this.elencoPrioritaSubject = new BehaviorSubject<any>(
            JSON.parse(localStorage.getItem(this.storage_key_priorita))
        );
        this.elencoPriorita = this.elencoPrioritaSubject.asObservable();

        this.elencoRupSubject = new BehaviorSubject<any>(
            JSON.parse(localStorage.getItem(this.storage_key_rup))
        );
        this.elencoRup = this.elencoRupSubject.asObservable();

        this.elencoAreeTematicheSubject = new BehaviorSubject<any>(
            JSON.parse(localStorage.getItem(this.storage_key_aree_tematiche))
        );
        this.elencoAreeTematiche = this.elencoAreeTematicheSubject.asObservable();

        
        this.elencoTipiFinanziamentoSubject = new BehaviorSubject<any>(
            JSON.parse(localStorage.getItem(this.storage_key_tipi_finanziamento))
        );
        this.elencoTipiFinanziamento = this.elencoTipiFinanziamentoSubject.asObservable();
        
    }    

    setElencoPriorita(elencoPriorita){
        localStorage.setItem(this.storage_key_priorita,JSON.stringify(elencoPriorita));
        this.elencoPrioritaSubject.next(elencoPriorita);
    }
    

    reloadPriorita(){
        this.motivazioniService.getPriorita().subscribe(
            result => {
                let prioritaMotivazioniList = null;
                prioritaMotivazioniList = result.map((i) => {
                    i["label"] = `${i.priorita} - ${i.motivazione}`;
                    return i;
                }).sort(
                    (a, b) => b['priorita'] == a['priorita'] ? 0 : a['priorita'] > b['priorita'] ? 1 : -1
                );
                this.setElencoPriorita(prioritaMotivazioniList);
            }
        );
    }

    setElencoRup(elencoRup){
        localStorage.setItem(this.storage_key_rup,JSON.stringify(elencoRup));
        this.elencoRupSubject.next(elencoRup);
    }

    reloadRup(){
        this.soggettoResponsabileService.getAllBySottoTipologia('rup').subscribe((result) => {
            let soggettiRespArray = result;
            soggettiRespArray.forEach((element) => {
                element["label"] = `${element.cognome} ${element.nome} - ${element.tipologia?.descrizione}`;
            });
            soggettiRespArray.sort((a, b) => (a['label'] > b['label']) ? 1 : ((b['label'] > a['label']) ? -1 : 0));
            this.setElencoRup(soggettiRespArray);
            

        });
    }

    setAreeTematiche(elencoAree){
        localStorage.setItem(this.storage_key_aree_tematiche,JSON.stringify(elencoAree));
        this.elencoAreeTematicheSubject.next(elencoAree);
    }

    reloadAreeTematiche(){
        this.areeTematicheService.getAll().subscribe((res) => {
            let areeTematicheCombo = res.map(e=>{
                if (e.disabled){
                    let newItem = Object.assign({},e);
                    newItem.descrizione = `(disattiva) ${e.descrizione}`;
                    newItem.disabled = false;
                    return newItem;
                }
                return e;
            });
            this.setAreeTematiche(areeTematicheCombo);
        });
    }

    setTipiFinanziamento(elenco){
        localStorage.setItem(this.storage_key_tipi_finanziamento,JSON.stringify(elenco));
        this.elencoTipiFinanziamentoSubject.next(elenco);
    }

    reloadTipiFinanziamento(){
        this.tipiFinanziamentoService.getAll().subscribe((res) => {            
            this.setTipiFinanziamento(res);
        });
    }

    

}