import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    ElementRef,
    TemplateRef    
} from "@angular/core";
import { registerLocaleData } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import {  BsModalRef } from 'ngx-bootstrap/modal';
import {
      
     TipoClassificazioneService, TemaPrioritarioService
    , ProgettoService, InterventoService,StorageService
} from '../../services';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import localeIt from '@angular/common/locales/it';
import { Subject } from "rxjs";
registerLocaleData(localeIt, 'it');
defineLocale('it', itLocale);
import IMask from 'imask';
import { debounceTime, map } from "rxjs/operators";

@Component({
    selector: 'spostamento-massivo-interventi-component',
    templateUrl: './spostamento-massivo-interventi.component.html',
    styleUrls: ['./spostamento-massivo-interventi.component.css']
})
export class SpostamentoMassivoInterventiComponent implements OnInit, OnDestroy {

    public mask: any = {
        mask: 'Z KK',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '',
                scale: 0,
                padFractionalZeros: false,
                radix: ','
            },
            Z: {
                mask: IMask.MaskedEnum,
                enum: ['+', '-']
            }
        }
    };

    ngOnDestroy(): void {
        if(this.elencoAreeSubscription)this.elencoAreeSubscription.unsubscribe();
    }

    submitted: boolean = false;
    loading: boolean = false;
    readOnlyForm: boolean = false;

    projectId: number;

    modalSubscriptions = [];
    
    
    


    modalTitle: string = '';
    modalButtonLabel: string = '';

    modalRef: BsModalRef;
    modalRefSogResp: BsModalRef;

    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };

    tipologieClassificazioni = [];
    temiPrioritariCombo = [];

    editForm: FormGroup;
    filterForm: FormGroup;

    classificazioni = [];

    templateListSogResp = [];
    allProjects = [];
    allDestinationProjects = [];

    soggettoResponsabileIsError: boolean = false;

    @ViewChild('btnSelectSoggResp', { static: true })
    public btnSelectSoggResp: TemplateRef<any>;



    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;

    constructor(
        private toastr: ToastService
        , private tipologiaClassificazioneService: TipoClassificazioneService
        , private localeService: BsLocaleService
        , private formBuilder: FormBuilder
        , private el: ElementRef         
        , private temaPrioritarioService: TemaPrioritarioService
        , private projectService: ProgettoService
        , private interventoService:InterventoService
        ,private storageService:StorageService
    ) {
        this.localeService.use("it");        
        this.editForm = this.initializeFormGroup();
        this.filterForm = this.initializeFilterFormGroup();
    }

    setIsDirty(value: boolean) {
        return;
    }


    initializeFilterFormGroup(): FormGroup {
        let frm = this.formBuilder.group({
            project: [null],
            objectSearchText: [null],
            areeTematicheCombo: [null],
            tipologiaClassificazioni: [null],
            temiPrioritari: [null]
        });
        frm.controls.objectSearchText.valueChanges
            .pipe(
                map((i: any) => i),
                debounceTime(500)
            )
            .subscribe(
                data => {
                    this.setObjectSearchText(data);
                }
            );
        return frm;
    }


    initializeFormGroup(): FormGroup {
        let frm = this.formBuilder.group({
            destinationProject:[null, [Validators.required]]
        });
        return frm;
    }




    get f() {
        return this.editForm.controls;
    }

    ngOnInit() {
        this.templateListSogResp = [{ name: 'btnSelectSoggResp', template: this.btnSelectSoggResp }];
        window.scroll(0, 0);

        this.getTipologieClassificazioni();
        this.getTemiPrioritari();
        this.getAreeTematiche();
        this.getAllProjects();
    }




    loadDataCounter: number = 0;
    reloadData() {
        this.loadDataCounter = 0;

    }



    getTemiPrioritari() {
        this.temaPrioritarioService.getAll().subscribe((temi) => {
            this.temiPrioritariCombo = temi;
            
        });
    }



    getTipologieClassificazioni() {
        this.tipologiaClassificazioneService.getAll().subscribe((x) => {
            this.tipologieClassificazioni = [
                { id: -2, tipo: "Nessuna classificazione" },
                ...x,
            ];
        });
    }

    getAllProjects() {
        this.projectService.getAll().subscribe((res) => {
            this.allProjects = res
                .filter((p) => !p.isDeleted && p.numeroInterventi >0)
                .map((p) => {
                    p["label"] = `${p.codice} - ${p.descrizione}`;
                    return p;
                });
                this.allDestinationProjects = res
                .filter((p) => !p.isDeleted )
                .map((p) => {
                    p["label"] = `${p.codice} - ${p.descrizione}`;
                    return p;
                });
        });
    }



    codesSelected = null;
    flagSoggResp = true;//true = sostituisci
    panelClass = "";


    codesSelectedChanged(codes) {
        this.codesSelected = codes;
    }

    

    changeFlagSoggResp() {
        this.flagSoggResp = !this.flagSoggResp;
        this.panelClass = this.flagSoggResp ? "" : "card-header-info";
    }




    onSubmit() {
        this.submitted = true;
        if ((!this.codesSelected) || this.codesSelected.length <= 0) {
            this.toastr.error(`Nessun progettualità / fabbisogno / intervento selezionato`, null, {
                timeOut: 2000,disableTimeOut: false
            });
            return;
        }

        let errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;            
        }
        if (errors || this.editForm.invalid || this.soggettoResponsabileIsError) {
            console.log("invalid");
            let invalidElements = []; 
            for (let el in this.editForm.controls) {
                if (this.editForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.editForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.editForm.get(el)["controls"]) {
                            if (this.editForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }
        //no errors
        
        let destinazione = this.f.destinationProject.value;
        
        let myService = this.interventoService;

        if (!confirm(`Procedere allo spostamento massivo dei ${this.codesSelected.length} elementi selezionati?`)) return false;
        this.loading = true;
        this.submitted = false;
        myService.spostaInterventi(destinazione, 
        this.codesSelected).subscribe(
            result => {
                this.loading = false;
                this.toastr.success('Spostamento effettuato con successo', null, {
                    timeOut: 2000, disableTimeOut: false
                });
                this.editForm.reset();
                this.loadTableData();
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile salvare gli elementi:${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );

    }



    




    printValueAsDate(value) {
        if (!value || value == '') return "";
        return moment(value).format('DD/MM/YYYY');
    }
    printSoggettiResponsabiliDescription(value: string | undefined) {
        if (!value || value == '') return '';
        return value.split('|').join('<br />');
    }

    


    loadTableData() {
        this.codesSelected = [];
        if (
            this.filterObject.filterAreaTematica == -1 &&
            this.filterObject.filterTipologiaClassificazioni == -1 &&
            this.filterObject.temaPrioritarioId == -1 &&
            this.filterObject.mainObjectId == -1 &&
            this.filterObject.mainObjectStringFilter == '' &&
            this.filterObject.projectId == -1
        ) {
            //svuoto i filtri!!
            this.editForm.reset();
            this.tabellaDettaglioObj = [];
            this.subjectTabDettaglio.next(this.tabellaDettaglioObj);
            return;
        }

        //chiamo il metodo
        this.projectService
            .getInterventiSimpleDataWithProjects({
                filterProjectId: this.filterObject.projectId,
                filterSimpleInterventionId: this.filterObject.mainObjectId,
                filterStatus: 7,/*posso spostare soltanto i fabbisogni*/
                filterAreeGestionaliDirezioneEsterne: this.filterObject.filterAreaTematica,
                filterTipoClassificazione: this.filterObject.filterTipologiaClassificazioni,
                filterSoggettoGiuridico: -1,
                filterAnno: 'all',
                filterProvinciaSoggettoGiuridico: 'all',
                filterTemaProprietario: this.filterObject.temaPrioritarioId,
                filterFrom: -1,
                filterTo: -1,
                descriptionSearchString: this.filterObject.mainObjectStringFilter,
                filterTipologiaIntervento: -1
            })
            .subscribe((result) => {
                let res = [];
                result
                    .filter((x) => x.isMain)
                    .forEach((progetto) => {
                        res.push(progetto);
                        progetto["interventi"] = result.filter(
                            (x) =>
                                !x.isMain  &&
                                x["progettoId"] == progetto["progettoId"]
                        );
                    });
                this.tabellaDettaglioObj = {progetti:res};
                this.subjectTabDettaglio.next(this.tabellaDettaglioObj);

            });
        
    }


    tabellaDettaglioObj: any;
    tabellaDettaglioObjLoaded = false;
    subjectTabDettaglio = new Subject<any>();
    observable$ = this.subjectTabDettaglio.asObservable();

    /*gestione filtri*/


    filterObject = {
        filterAreaTematica: -1
        , filterTipologiaClassificazioni: -1
        , temaPrioritarioId: -1
        , projectId: -1
        , mainObjectId: -1
        , mainObjectStringFilter: ''
    };


    areeTematicheCombo: any[];
    tipologieResponsabiliCombo: any[];





    //tipologieFase
    private elencoAreeSubscription = null;
    getAreeTematiche() {
       
        this.elencoAreeSubscription = this.storageService.elencoAreeTematiche.subscribe(
            x=>{
                console.log(x);
                this.areeTematicheCombo = x || [];
            }
            );
    }


    setAreaTematicaFilter($event) {
        this.filterObject.filterAreaTematica = $event ? $event.id : -1;
        this.loadTableData();
    }

    setTipologiaClassificazioniFilter($event) {
        this.filterObject.filterTipologiaClassificazioni = $event ? $event.id : -1;
        this.loadTableData();
    }



    setTemiPrioritario($event) {
        this.filterObject.temaPrioritarioId = $event ? $event.id : -1;
        this.loadTableData();
    }

    completerParams = {
        labelField: "parentTitle",
        placeholder: "Cerca intervento; prog./fabbisogno",
        ajax: this.filterComboMainObjectId,
        context: this,
        items: [],
    };


    setObjectSearchText($event) {
        if ($event && $event.length >= 3) {
            this.filterObject.mainObjectStringFilter = $event;            
        } else {
            this.filterObject.mainObjectStringFilter = '';
        }
        this.loadTableData();
    }

    setInterventionCompleter($event) {
        if ($event) {
            this.filterObject.mainObjectId = $event.id;
        } else {
            this.filterObject.mainObjectId = -1;
            this.completerParams.items = [];
        }

        this.loadTableData();
    }

    onChangeInterventionCompleter($event) {
        !$event.target.value && this.setInterventionCompleter(null);
    }


    filterComboMainObjectId(filterString, callBack) {
        this['context'].projectService
            .getInterventiSimpleDataWithProjects({
                filterProjectId: -1,
                filterSimpleInterventionId: -1,
                filterStatus: -1,/*soltanto fabbisogni, e per i progetti come faccio?*/
                filterAreeGestionaliDirezioneEsterne: -1,
                filterTipoClassificazione: -1,
                filterSoggettoGiuridico: -1,
                filterAnno: 'all',
                filterProvinciaSoggettoGiuridico: 'all',
                filterTemaProprietario: -1,
                filterFrom: -1,
                filterTo: -1,
                descriptionSearchString: filterString
            })
            .subscribe((result) => {
                let res = [];
                result.forEach(
                    x => {
                        if (
                            (
                                x.codice.toLowerCase().includes(filterString.toLowerCase())
                            || x.descrizione.toLowerCase().includes(filterString.toLowerCase())
                            || (x.noteResponsabile && x.noteResponsabile.toLowerCase().includes(filterString.toLowerCase()))
                            )&&(!x.isMain && x.validationStatus =='FABBISOGNO' || x.validationStatus ==7)
                        ) {
                            res.push({ id: x.id, parentTitle: x.codice + ' - ' + x.descrizione });
                        }
                    }
                );
                callBack(res);
            });
    }

    description = '';
    setProject($event) {
        this.description = $event ? $event.descrizione : "";
        this.filterObject.projectId = $event ? $event.id : -1;
        this.loadTableData();
    }

}