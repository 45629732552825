import { CurrencyPipe } from '@angular/common';
import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    ElementRef,
    TemplateRef,
    Input,
    Output,
    EventEmitter
} from "@angular/core";
import { registerLocaleData } from '@angular/common';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, FormControl } from "@angular/forms";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { InterventoService, FinanziamentoService,
     TipologiaFaseService, SoggettoResponsabileService, CapitoloBilancioService
     ,PnrrService,
     ProgettoService
    } from '../../services';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { Fase, TipologiaFase } from '../../models';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from '../../../../components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component'
import localeIt from '@angular/common/locales/it';
import { GenericSelectionModalComponent } from '../genericSelectionModal/generic-selection-modal.component';
registerLocaleData(localeIt, 'it');
defineLocale('it', itLocale);
import { IsDirtyHelper } from "../../../../helpers/is-dirty.helper"
import { AuthService } from 'src/app/services';

@Component({
    selector: 'fasi-component',
    templateUrl: './fasi.component.html',
    styleUrls: ['./fasi.component.css']
})
export class FasiComponent implements OnInit, OnDestroy {
section:string ='';

    public currentMask =null;
    public maskImporto: any = {
        mask: 'KK €',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            }
        }
    };

    public maskNumero: any = {
        mask: 'KK',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            }
        }
    };

    public maskPercentuale: any = {
        mask: 'KK %',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            }
        }
    };

    ngOnDestroy(): void {
        this.clearSubscriptions();
    }

    submitted: boolean = false;
    loading: boolean = false;
    fasi: Fase[];
    @Input()
    readOnlyForm: boolean = false;

    projectId: number;
    private _finanziamentoId: number;
    private _interventoId: number;

    esistonoInterventi = false;
    tabsOnOneRow = true;

    targetItaEuItems=[{descrizioneEstesa:'ITA',id:'ITA'},{descrizioneEstesa:'EU',id:'EU'}];
    unitaDiMisuraItems=[
        {descrizioneEstesa:'Euro',id:'euro'},
        {descrizioneEstesa:'Euro (milioni)',id:'euro (milioni)'},
        {descrizioneEstesa:'Giorni',id:'giorni'},
        {descrizioneEstesa:'Percentuale',id:'percentuale'},
        {descrizioneEstesa:'Numero',id:'numero'}
    ];

    modalSubscriptions = [];
    //@ViewChild('buttonsTemplate',{static:false}) buttonsTemplate: TemplateRef<any>;
    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;



    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    @ViewChild('hideTemplate', { static: true })
    public hideTemplate: TemplateRef<any>;
    @ViewChild('soggettirespoTemplate', { static: true })
    public soggettirespoTemplate: TemplateRef<any>;


    @ViewChild('fieldNoteTemplate', { static: true })
    public fieldNoteTemplate: TemplateRef<any>;

    @ViewChild('fieldFlussoDiCassaTemplate', { static: true })
    public fieldFlussoDiCassaTemplate: TemplateRef<any>;

    @ViewChild('fieldDocumentazioneTemplate', { static: true })
    public fieldDocumentazioneTemplate: TemplateRef<any>;


    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    templateList = [];

    dtOptions: any;
    rows: any[];
    columns = [
        {
            name: '', prop: 'id', sortable: true, filtrable: false, maxWidth: 0, width: 0, visible: false
            ,exportable:false
            // 'buttonsTemplate'
        }

        , {
            name: 'tipologia'
            , prop: 'tipologia'
            , sortable: true
            , filtrable: true
            , minWidth: 120
            , flexGrow: 3
        }
        , {
            name: 'data<br />prevista inizio'
            , prop: 'dataPrevistaInizio'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'dateValueTemplate'
            , minWidth: 90
            , flexGrow: 2
        }
        , {
            name: 'data inizio'
            , prop: 'dataInizio'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'dateValueTemplate'
            , minWidth: 90
            , flexGrow: 2
        }
        , {
            name: 'data<br />prevista fine'
            , prop: 'dataPrevistaFine'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'dateValueTemplate'
            , minWidth: 80
            , flexGrow: 2
        }
        , {
            name: 'data fine'
            , prop: 'dataFine'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'dateValueTemplate'
            , minWidth: 80
            , flexGrow: 2
        }
        , {
            name: 'soggetti<br />responsabili'
            , prop: 'soggettiResponsabiliDescrizione'
            , sortable: false
            , filtrable: true
            , cellTemplate: 'soggettirespoTemplate'
            , minWidth: 90
            , flexGrow: 2
        }
        , {
            name: 'note'
            , prop: 'note'
            , sortable: false
            , filtrable: true
            , cellTemplate: 'fieldNoteTemplate'
            , minWidth: 50
            , flexGrow: 1
        }
        , {
            name: 'flusso di<br />cassa'
            , prop: 'flussoDiCassa'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'fieldFlussoDiCassaTemplate'// 'buttonsTemplate'
            , minWidth: 65
            , flexGrow: 1
            ,exportFunction:(row)=>{
                return row.flussoDiCassa?row.previsioneDiImporto:""
            }

        }
        , {
            name: 'documentazione'
            , prop: 'documentazionePresente'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'fieldDocumentazioneTemplate'// 'buttonsTemplate'

            , flexGrow: 2


        }
        , {
            name: 'modifica'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , minWidth: 50
            , flexGrow: 1
            ,exportable:false

        }
        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , minWidth: 50
            , flexGrow: 1
            ,exportable:false

        }
    ];




    modalTitle: string = '';
    modalButtonLabel: string = '';

    modalRef: BsModalRef;
    modalRefSogResp: BsModalRef;
    modalRefCapitoloBilancio: BsModalRef;

    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };

    tipologieFase = Array<TipologiaFase>();

    editForm: FormGroup;

    soggettiResponsabili = [];
    allSoggettiResponsabili = [];


    soggettoResponsabileIsError: boolean = false;


    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;




    constructor(
        private toastr: ToastService
        , private modalService: BsModalService
        , private tipologiaFaseService: TipologiaFaseService
        , private localeService: BsLocaleService
        , private formBuilder: FormBuilder
        , private el: ElementRef,
        private soggettoResponsabileService: SoggettoResponsabileService
        , private finanziamentoService: FinanziamentoService
        , private interventoService: InterventoService
        , private isDirtyHelper: IsDirtyHelper
        , private capitoloBilancioService: CapitoloBilancioService
        ,private pnrrService:PnrrService
        ,private authService:AuthService
        ,private progettoService:ProgettoService
    ) {
        this.localeService.use("it");
        this.dtOptions = {
            rows: this.rows
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: false// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap ngx-datatable-fasi"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: false// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: false
            , scrollbarH: "true"
            ,id:'ngx-datatable-fasi'
            /*,buttons:{excel:true}*/
        };

        this.editForm = this.initializeFormGroup();
        this.motivazioneForm = this.initializeMotivazioneForm();
    }

    setIsDirty(value: boolean) {
        this.isDirtyHelper.isDirty = value;
    }


    initializeFormGroup(): FormGroup {
        let frm = this.formBuilder.group({
            id: [null],
            tipologiaFaseFase: [null, [Validators.required]],
            tipologiaFaseTitolo: [null, [Validators.required]],
            tipologia: [null, [Validators.required]],
            dataInizio: [null, []],
            dataFine: [null, [this.checkDate('dataInizio')]],
            dataPrevistaInizio: [null, [Validators.required]],
            dataPrevistaFine: [null, [Validators.required, this.checkDate('dataPrevistaInizio')]]
            , note: [null]
            , flussoDiCassa: [null]
            , previsioneDiImporto: [null]
            ,codice:[null]
            ,unitaDiMisura:[null]
            ,targetItaEu:[null]
            ,indicatoreQualitativo:[null]
            ,riferimentoDiPartenzaNazionale:[null]
            ,riferimentoDiPartenzaRegionale:[null]
            ,valoreTargetNazionale:[null]
            ,valoreTargetRegionale:[null]
            ,valoreTargetAttualeNazionale:[null]
            ,valoreTargetAttualeRegionale:[null]
            ,valoreTargetRegionaleIntervento:[null]
        });
        frm.valueChanges.subscribe(val =>
            this.setIsDirty(frm.dirty)
        );
        return frm;
    }


    checkDate(startDateCtrlName: any): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (!control || !control.parent) return null;
            if (!control.value || control.value == '') return null;

            let mainCtrl = control.parent.get(startDateCtrlName) as FormControl;
            if (!mainCtrl.value ||
                mainCtrl.value == ''
                || control.value < mainCtrl.value)
                return { checkDate: true };

            return null;

        }
    }


    get f() {
        return this.editForm.controls;
    }

    ngOnInit() {
        this.templateList = [
            { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }
            , { name: 'btnEditTemplate', template: this.btnEditTemplate }
            , { name: 'hideTemplate', template: this.hideTemplate }
            , { name: 'soggettirespoTemplate', template: this.soggettirespoTemplate }
            , { name: 'fieldNoteTemplate', template: this.fieldNoteTemplate }
            , { name: 'fieldFlussoDiCassaTemplate', template: this.fieldFlussoDiCassaTemplate }
            ,{name:'fieldDocumentazioneTemplate', template:this.fieldDocumentazioneTemplate}


        ];
        window.scroll(0, 0);
        this.getSoggettiResponsabili();        
    }



    @Input()
    set finanziamentoId(value) {
        this._finanziamentoId = value;
        if (!value) return;
        this.currentIntervento = null;
        this.section='Finanziamenti';
        this.isMandatorySoggettoResponsabile = true;
        this.esistonoInterventi = false;
        this.tabsOnOneRow = true;        
    }
    get finanziamentoId() {
        return this._finanziamentoId;
    }

    @Input()
    set interventoId(value) {
        this._interventoId = value;
        if (!value) return;
        this.section='Interventi';
        this.isMandatorySoggettoResponsabile = true;       
    }
    get interventoId() {
        return this._interventoId;
    }


    loadDataCounter: number = 0;
    reloadData() {
        this.loadDataCounter = 0;
        if (this._finanziamentoId) {
            this.getFinanziamentoData(this._finanziamentoId);
            this.getTipologieFase(true);
        }
        if (this._interventoId) {
            this.getInterventoData(this._interventoId);
            this.getTipologieFase(false);
        }
        if(this._pnrrLineaFinanziamentoId){
            this.getTipologieFase(false,this.getFasiByPnrrLineaFinanziamentoId(this._pnrrLineaFinanziamentoId));

        }
    }



    getFinanziamentoData(id: number) {
        this.loadDataCounter++;
        this._finanziamentoId = id;
        let that = this;
        this.finanziamentoService.getById(id).subscribe(result => {
            let status = (result.validazione ? (result.validazione.validationStatus ? result.validazione.validationStatus : 0) : 0).toString().toUpperCase();
            that.readOnlyForm = ( status == 'WAITING_FOR_APPROVAL_FIRST_LEVEL' || status == '4'
                || status == 'WAITING_FOR_APPROVAL_SECOND_LEVEL' || status == '5'
                || status == 'TERMINATO'
                || result.isReadOnly
                || !that.canEdit()
            );
            that.columns[9].visible = true;
            that.columns[10].visible = true;
            if (that.readOnlyForm) {
                //nascondo colonne edit e delete
                that.columns[9].visible = false;
                that.columns[10].visible = false;

            }
            that.ngxDatatableWrapper.dtOptions.columns = that.columns;
            that.ngxDatatableWrapper.dtOptions = that.ngxDatatableWrapper.dtOptions;
            that.ngxDatatableWrapper.refresh();
            this.getFasiByFinanziamentoId(id);

            this.loadDataCounter--;
        });

    }


    getFasiByInterventoId(id: number) {
        this.loadDataCounter++;
        this.interventoService.getFasi(id).subscribe(
            result => {
                result.forEach(
                    x => {
                        x['tipologia'] = `${x.tipologiaFase.fase} - ${x.tipologiaFase.titolo} - ${x.tipologiaFase.descrizione}`
                        x['soggettiResponsabiliDescrizione'] = x.soggettiResponsabili.map(
                            (x) => `${x.cognome} ${x.nome}`
                        ).join('|');
                    }
                );
                this.ngxDatatableWrapper.setInitialData(result);
                this.loadDataCounter--;
            }
        );
    }
    currentIntervento: any;
    getInterventoData(id: number) {
        let that = this;
        this.loadDataCounter++;
        this.interventoService.get(id).subscribe(
            result => {
                that.currentIntervento = result;
                let status = (result.validazione ? (result.validazione.validationStatus ? result.validazione.validationStatus : 0) : 0).toString().toUpperCase();
                that.readOnlyForm = (status == 'WAITING_FOR_APPROVAL_FIRST_LEVEL' || status == '4'
                    || status == 'WAITING_FOR_APPROVAL_SECOND_LEVEL' || status == '5'
                    || status == 'TERMINATO'
                    || result.isReadOnly
                    || !that.canEdit()
                );
                that.columns[9].visible = true;
                that.columns[10].visible = true;
                if (that.readOnlyForm) {
                    that.columns[9].visible = false;
                    that.columns[10].visible = false;
                }
                that.ngxDatatableWrapper.dtOptions.columns = that.columns;
                that.ngxDatatableWrapper.dtOptions = that.ngxDatatableWrapper.dtOptions;
                that.ngxDatatableWrapper.refresh();
                this.getFasiByInterventoId(this._interventoId);
                this.loadDataCounter--;
                this.esistonoInterventi = false;
                this.tabsOnOneRow = false;
                if (result.isMain){
                    this.progettoService.getInterventiCount(result.progettoId).subscribe(
                        res => {
                            that.esistonoInterventi = res - 1 > 0;
                            this.tabsOnOneRow = that.esistonoInterventi;
                        }
                    );
                }
            }
        );
    }


    getFasiByFinanziamentoId(id: number) {
        this.loadDataCounter++;
        this.finanziamentoService.getFasi(id).subscribe(
            result => {
                result.forEach(
                    x => {
                        x['tipologia'] = `${x.tipologiaFase.fase} - ${x.tipologiaFase.titolo} - ${x.tipologiaFase.descrizione}`
                        x['soggettiResponsabiliDescrizione'] = x.soggettiResponsabili.map(
                            (x) => `${x.cognome} ${x.nome}`
                        ).join('|');
                    }
                );
                this.ngxDatatableWrapper.setInitialData(result);
                this.loadDataCounter--;
            }
        );
    }


    tipologieFasiFasi = [];
    allTipologieFasi = [];
    TipologieFasiTitoli = [];
    TipologieFasiDescrizioni = [];
    getTipologieFase(isEntrata: boolean,callBack = null) {
        let that = this;
        this.tipologiaFaseService.getAll().subscribe(
            result => {
                result = result.filter(x => x['tipologia'] == 'entrata' || x['tipologia'].toString().toUpperCase() == 'PNRR'
                    || (!isEntrata && x['tipologia'] != 'entrata'));

                if (!this.isAdmin()){
                    const o = this.authService.getUserInfo();
                    const aree = o.areeTematiche;
                    if (aree){
                        const areeId = aree.map(x=>x.id);
                        result = result.filter(x=>{
                            if (!x['areeTematiche'] || x['areeTematiche'].length<=0)return false;
                            let trovato = false;
                            for(const a of x['areeTematiche']){
                                trovato = trovato || areeId.includes(a.id);
                            }
                            return trovato;
                        });
                    }
                }
                result.forEach(
                    x =>{ 
                        x['descrizioneEstesaFull'] = `${x.fase} - ${x.titolo} - ${x.descrizione}`;
                        x['descrizioneEstesa'] = `${x.descrizione}`;
                        }
                    );
                
                result.sort((a,b)=>a['descrizioneEstesaFull']<b['descrizioneEstesaFull']?-1:1);
                this.tipologieFasiFasi =[...new Set(result.map(x=>x.fase))];
                that.tipologieFase = [];
                that.allTipologieFasi = result;
                if (callBack)callBack();
            }
        );
    }

    getSoggettiResponsabili() {
        this.soggettoResponsabileService.getAll().subscribe(
            result => {
                this.allSoggettiResponsabili = result;
            }
        );
    }

    onSubmit() {
        this.submitted = true;
        this.editForm.updateValueAndValidity();

        this.capitoliBilancioIsError = false;
        this.soggettoResponsabileIsError =this.isMandatorySoggettoResponsabile && ( !(this.soggettiResponsabili && this.soggettiResponsabili.length > 0));

        for (let c in this.editForm.controls) {
            this.editForm.controls[c].updateValueAndValidity();
        }
        let errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;            
        }
        if (errors || this.editForm.invalid || this.soggettoResponsabileIsError || this.capitoliBilancioIsError) {
            console.log("invalid");
            let invalidElements = [];
            for (let el in this.editForm.controls) {
                if (this.editForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.editForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.editForm.get(el)["controls"]) {
                            if (this.editForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }
        //no errors


        if (this.currentFase){
            let fase = this.editForm.value;
            fase.dataPrevistaInizio = fase.dataPrevistaInizio ? moment(fase.dataPrevistaInizio).format('YYYY-MM-DD') : null;
            fase.dataPrevistaFine = fase.dataPrevistaFine ? moment(fase.dataPrevistaFine).format('YYYY-MM-DD') : null;
            let dataPI = null;
            let dataPIString = '';
            if (this.currentFase &&this.currentFase.dataPrevistaInizio) {
                dataPI = moment(this.currentFase.dataPrevistaInizio).toDate();
                dataPI.setHours(dataPI.getHours() - dataPI.getTimezoneOffset() / 60);
                dataPIString = moment(dataPI).format('YYYY-MM-DD');
            }
            let dataPF = null;
            let dataPFString = '';
            if (this.currentFase &&this.currentFase.dataPrevistaFine) {
                dataPF = moment(this.currentFase.dataPrevistaFine).toDate();
                dataPF.setHours(dataPF.getHours() - dataPF.getTimezoneOffset() / 60);
                dataPFString = moment(dataPF).format('YYYY-MM-DD');
            }
            console.log(moment(dataPI).format('YYYY-MM-DD'));
            console.log(moment(dataPF).format('YYYY-MM-DD'));
            if (this.currentFase && (!dataPF || !dataPI || !fase.dataPrevistaFine || !fase.dataPrevistaInizio
                || fase.dataPrevistaInizio != dataPIString
                || fase.dataPrevistaFine != dataPFString
                )){
                this.openConfirmSaveModal();
                return;
            }

        }

        this.doSave();

    }

    openAddModal() {
        this.soggettoResponsabileIsError = false;
        this.soggettiResponsabili = [];
        this.capitoliBilancio = [];
        this.submitted = false;
        this.editForm.reset();
        this.flussoDiCassaOnChange(null);
        this.modalTitle = 'Nuova fase';
        this.modalButtonLabel = 'Inserisci'
        this.modalRef = this.modalService.show(this.templateEditAdd, this.config);

        this.currentFase = null;
        this.historyMotivazioni = null;
        this.editForm.controls.codice.enable();
        this.editForm.controls.unitaDiMisura.enable();
        this.editForm.controls.targetItaEu.enable();
        this.editForm.controls.indicatoreQualitativo.enable();
        this.editForm.controls.riferimentoDiPartenzaRegionale.enable();
        this.editForm.controls.riferimentoDiPartenzaNazionale.enable();
        this.editForm.controls.valoreTargetNazionale.enable();
        this.editForm.controls.valoreTargetRegionale.enable();
        
        if(!this._pnrrLineaFinanziamentoId){
            this.editForm.controls.codice.disable();
            this.editForm.controls.unitaDiMisura.disable();
            this.editForm.controls.targetItaEu.disable();
            this.editForm.controls.indicatoreQualitativo.disable();
            this.editForm.controls.riferimentoDiPartenzaRegionale.disable();
            this.editForm.controls.valoreTargetRegionale.disable();
            this.editForm.controls.riferimentoDiPartenzaNazionale.disable();
            this.editForm.controls.valoreTargetNazionale.disable();
        }
        this.unitaDiMisuraChange(null);
        this.setIsDirty(false);
    }

    closeModal() {
        if (!this.isDirtyHelper.checkIsDirty()) return false;
        this.modalRef.hide();
    }


    currentFase = null;
    onEdit(row) {
        this.currentFase = row;

        this.historyMotivazioni = this.currentFase.historyChangesOnDates!=''?JSON.parse(this.currentFase.historyChangesOnDates):null;
        if (this.historyMotivazioni)this.historyMotivazioni.sort((a,b)=>(new Date(b.data).getTime())-(new Date(a.data).getTime()));
        if (this.loading) return;
        this.soggettoResponsabileIsError = false;
        this.submitted = false;
        this.modalTitle = 'Modifica fase';
        this.modalButtonLabel = "Aggiorna";
        this.modalRef = this.modalService.show(this.templateEditAdd, this.config);
        let dataI = null;
        if (row.dataInizio) {
            dataI = moment(row.dataInizio).toDate();
            dataI.setHours(dataI.getHours() - dataI.getTimezoneOffset() / 60);
        }
        let dataF = null;
        if (row.dataFine) {
            dataF = moment(row.dataFine).toDate();
            dataF.setHours(dataF.getHours() - dataF.getTimezoneOffset() / 60);
        }
        let dataPI = null;
        if (row.dataPrevistaInizio) {
            dataPI = moment(row.dataPrevistaInizio).toDate();
            dataPI.setHours(dataPI.getHours() - dataPI.getTimezoneOffset() / 60);
        }
        let dataPF = null;
        if (row.dataPrevistaFine) {
            dataPF = moment(row.dataPrevistaFine).toDate();
            dataPF.setHours(dataPF.getHours() - dataPF.getTimezoneOffset() / 60);
        }
        this.soggettiResponsabili = row.soggettiResponsabili;
        if (row.capitoliDiBilancio) this.capitoliBilancio = row.capitoliDiBilancio;
        this.editForm.setValue({
            id: row.id,
            tipologiaFaseFase:row.tipologiaFase.fase,
tipologiaFaseTitolo : row.tipologiaFase.titolo,
            tipologia: row.tipologiaFase.id,
            dataInizio: dataI,
            dataFine: dataF,
            dataPrevistaInizio: dataPI,
            dataPrevistaFine: dataPF,
            note: row.note
            , flussoDiCassa: row.flussoDiCassa ? row.flussoDiCassa : false,
            previsioneDiImporto: row.previsioneDiImporto ? row.previsioneDiImporto.toString() : null
            ,codice:row.codice
            ,unitaDiMisura:row.unitaDiMisura
            ,targetItaEu:row.targetItaEu
            ,indicatoreQualitativo:row.indicatoreQualitativo,
            riferimentoDiPartenzaRegionale:row.riferimentoDiPartenzaRegionale,
            riferimentoDiPartenzaNazionale:row.riferimentoDiPartenzaNazionale,
            valoreTargetNazionale:row.valoreTargetNazionale,
            valoreTargetAttualeNazionale:row.valoreTargetAttualeNazionale,
            valoreTargetRegionale:row.valoreTargetRegionale,
            valoreTargetAttualeRegionale:row.valoreTargetAttualeRegionale,
            valoreTargetRegionaleIntervento:row.valoreTargetRegionaleIntervento
        });

        this.tipologiaFaseFaseChangeValue(row.tipologiaFase.fase);
        this.editForm.controls.tipologiaFaseTitolo.setValue( row.tipologiaFase.titolo);
        this.tipologiaFaseTitoloChangeValue(row.tipologiaFase.titolo);
        this.editForm.controls.tipologia.setValue( row.tipologiaFase.id);

        this.flussoDiCassaOnChange(null);

        this.editForm.controls.codice.enable();
        this.editForm.controls.unitaDiMisura.enable();
        this.editForm.controls.targetItaEu.enable();
        this.editForm.controls.indicatoreQualitativo.enable();
        this.editForm.controls.riferimentoDiPartenzaNazionale.enable();
        this.editForm.controls.valoreTargetNazionale.enable();
        this.editForm.controls.riferimentoDiPartenzaRegionale.enable();
        this.editForm.controls.valoreTargetRegionale.enable();
        if(!this._pnrrLineaFinanziamentoId){
            this.editForm.controls.codice.disable();
            this.editForm.controls.unitaDiMisura.disable();
            this.editForm.controls.targetItaEu.disable();
            this.editForm.controls.indicatoreQualitativo.disable();
            this.editForm.controls.riferimentoDiPartenzaRegionale.disable();
            this.editForm.controls.valoreTargetRegionale.disable();
            this.editForm.controls.riferimentoDiPartenzaNazionale.disable();
            this.editForm.controls.valoreTargetNazionale.disable();
        }
        if(row.unitaDiMisura)this.unitaDiMisuraChange(this.unitaDiMisuraItems.find(x=>x.id ==row.unitaDiMisura));
        else this.unitaDiMisuraChange(null);
        this.setIsDirty(false);
    }

    onDelete(row) {
        if (this.loading) return;
        if (!confirm(`Stai per eliminare la fase "${row['tipologia']}", proseguire?`)) return;
        this.loading = true;
        let myService = this._interventoId ?
            this.interventoService
            : this._finanziamentoId ? this.finanziamentoService
            : this._pnrrLineaFinanziamentoId?this.pnrrService:null;
        let currentId = this._interventoId ? this._interventoId : this._finanziamentoId?this._finanziamentoId
        : this._pnrrLineaFinanziamentoId?this._pnrrLineaFinanziamentoId:null;
        myService.deleteFase(currentId, row.id).subscribe(
            result => {
                this.loading = false;
                this.ngxDatatableWrapper.deleteRow(row);
                this.toastr.success('Fase eliminata con successo', null, {
                    timeOut: 2000,disableTimeOut: false
                });
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile eliminare la fase selezionata: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000,disableTimeOut: false
                });
                console.log('error');
            });
    }
    printValueAsDate(value) {
        if (!value || value == '') return "";
        return moment(value).format('DD/MM/YYYY');
    }
    printValueAsDateTime(date) {
        if (!date) return '';
        let v = moment(date).toDate();
        return moment(v).format('DD/MM/YYYY HH:mm:ss');
    }

    printValueAsCurrency(value) {
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }


    removeSoggettoResponsabile(item: any) {
        this.soggettiResponsabili = this.soggettiResponsabili.filter(x => x.id != item.id);
        this.setIsDirty(true);
    }

    clearSubscriptions() {
        this.modalSubscriptions.forEach(x => x.unsubscribe());
        this.modalSubscriptions = [];
    }

    addSoggettoResponsabile() {
        let modalDefinition = {
            pageTitle: 'Seleziona i soggetti responsabili'
        };

        let modalDefinitionNgx = {
            rows: []
            , totalElements: 0
            , columns: [
                {
                    name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
                    , resizeable: false
                }

                , {
                    name: 'cognome'
                    , prop: 'cognome'

                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 100

                }
                , {
                    name: 'nome'
                    , prop: 'nome'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 100
                }
                , {
                    name: 'email'
                    , prop: 'email'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 200
                }
                , {
                    name: 'tipologia'
                    , prop: 'tipologia.descrizione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 3
                    , minWidth: 200
                }
            ]
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , ajax: this.getSoggettiResponsabiliDtNgx
            , scrollbarH: "true"
        };

        const initialState = { modalDefinition: modalDefinition, dtOptionsNgx: modalDefinitionNgx };
        let config = {
            animated: true,
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl"
        };
        this.modalRefSogResp = this.modalService.show(GenericSelectionModalComponent, Object.assign({}, config, { initialState }));
        this.clearSubscriptions();
        let subscription = this.modalRefSogResp.content.onSubmitOk.subscribe(
            data => {
                console.log('inside ok of add resp');
                this.onSelectSoggResp(data);


            }
        );
        this.modalSubscriptions.push(subscription);

    }

    onSelectSoggResp(item) {
        if (this.soggettiResponsabili.find(x => x.id == item.id)) return;
        this.soggettiResponsabili.push(item);
        this.toastr.success('Soggetto responsabile inserito con successo', null, {
            timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
        });
        this.setIsDirty(true);
    }

    getSoggettiResponsabiliDtNgx = (dataTablesParameters: any, callback: any) => {
        this.soggettoResponsabileService.getAllDt(dataTablesParameters)
            .subscribe(resp => {
                callback(resp);
            });
    }
    getSoggResp(value) {
        return value.split('|');
    }

    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
    gotoBackBtnClick() {
        if (!this.isDirtyHelper.checkIsDirty()) return false;
        this.gotoBtnFunction.emit(true);
    }
    _isInModal: boolean = false;
    @Input()
    set isInModal(value: boolean) {
        this._isInModal = value;
    }
    get isInModal() { return this._isInModal; }


    //flusso di cassa
    flussoDiCassaOnChange($event) {
        this.setIsDirty(true);
        if (this.editForm.controls.flussoDiCassa.value) {
            this.editForm.controls.previsioneDiImporto.setValidators([Validators.required]);;
        }
        else {
            this.editForm.controls.previsioneDiImporto.clearValidators();
        }
    }
    //capitoli di bilancio
    capitoliBilancioIsError = false;
    capitoliBilancio = [];
    removeCapitoloBilancio(item) {
        console.log(`inside removeCapitoloBilancio id=${item.id}`);
        if (confirm(`Rimuovere il capitolo di bilancio '${item.capitoloBilancioDescrizione}'?`))
            this.capitoliBilancio = this.capitoliBilancio.filter(x => x.id != item.id);
        this.setIsDirty(true);
    }

    getCapitoliDiBilancioDt = (dataTablesParameters: any, callback: any) => {
        this.capitoloBilancioService.getAllDt(dataTablesParameters)
            .subscribe(resp => {
                callback(resp);
            });
    }

    openModalCapitoliBilancio() {
        let modalDefinition = {
            pageTitle: 'Seleziona i capitoli di bilancio'
        };

        let modalDefinitionNgx = {
            rows: []
            , totalElements: 0
            , columns: [
                {
                    name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
                    , resizeable: false
                },
                {
                    name: ' codice'
                    , prop: 'codice'
                    , sortable: true
                    , filtrable: true
                    , flexGrow: 2
                    , minWidth: 60
                }
                , {
                    name: 'descrizione'
                    , prop: 'descrizione'
                    , sortable: true
                    , filtrable: true
                    , flexGrow: 10
                    , minWidth: 200                   

                }
                , {
                    name: 'entrata'
                    , prop: 'isEntrata'
                    , cellTemplate: 'booleanValueTemplate'
                    , sortable: true
                    , filtrable: true
                    , flexGrow: 2
                    , minWidth: 60
                }
            ]
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , ajax: this.getCapitoliDiBilancioDt
            , scrollbarH: "true"
        };

        const initialState = { modalDefinition: modalDefinition, dtOptionsNgx: modalDefinitionNgx };
        let config = {
            animated: true,
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl"
        };
        this.modalRefCapitoloBilancio = this.modalService.show(GenericSelectionModalComponent, Object.assign({}, config, { initialState }));
        this.clearSubscriptions();
        let subscription = this.modalRefCapitoloBilancio.content.onSubmitOk.subscribe(
            data => {
                console.log('inside ok of add capitolo');
                this.onSelectCaptioloDiBilancio(data);


            }
        );
        this.modalSubscriptions.push(subscription);

    }

    onSelectCaptioloDiBilancio(item) {
        if (this.capitoliBilancio.find(x => x.id == item.id)) return;
        this.capitoliBilancio.push(item);
        this.toastr.success('Capitolo di bilancio aggiunto con successo', null, {
            timeOut: 2000, disableTimeOut: false
        });
        this.setIsDirty(true);
    }

    doSave(){
        this.closeConfirmSaveModal();

        this.editForm.controls.codice.enable();
        this.editForm.controls.unitaDiMisura.enable();
        this.editForm.controls.targetItaEu.enable();
        this.editForm.controls.indicatoreQualitativo.enable();
        this.editForm.controls.riferimentoDiPartenzaNazionale.enable();
        this.editForm.controls.valoreTargetNazionale.enable();
        this.editForm.controls.riferimentoDiPartenzaRegionale.enable();
        this.editForm.controls.valoreTargetRegionale.enable();
        let fase = this.editForm.value;
        if(!this._pnrrLineaFinanziamentoId){
            this.editForm.controls.codice.disable();
            this.editForm.controls.unitaDiMisura.disable();
            this.editForm.controls.targetItaEu.disable();
            this.editForm.controls.indicatoreQualitativo.disable();
            this.editForm.controls.riferimentoDiPartenzaNazionale.disable();
            this.editForm.controls.valoreTargetNazionale.disable();
            this.editForm.controls.riferimentoDiPartenzaRegionale.disable();
            this.editForm.controls.valoreTargetRegionale.disable();
        }


        fase.dataPrevistaInizio = fase.dataPrevistaInizio ? moment(fase.dataPrevistaInizio).format('YYYY-MM-DD') : null;
        fase.dataInizio = fase.dataInizio ? moment(fase.dataInizio).format('YYYY-MM-DD') : null;
        fase.dataPrevistaFine = fase.dataPrevistaFine ? moment(fase.dataPrevistaFine).format('YYYY-MM-DD') : null;
        fase.dataFine = fase.dataFine ? moment(fase.dataFine).format('YYYY-MM-DD') : null;
        fase.tipologiaFase = new TipologiaFase();
        fase.tipologiaFase.id = fase.tipologia;
        fase.soggettiResponsabili = this.soggettiResponsabili;
        fase.capitoliDiBilancio = this.capitoliBilancio;


        let dataPrevInizio = moment(fase.dataPrevistaInizio, 'YYYY-MM-DD');
        let dataPrevFine = moment(fase.dataPrevistaFine, 'YYYY-MM-DD');
        //per i flussi di cassa, le date prevista inizio e fine devono essere nello stesso mese
        if (fase.flussoDiCassa
            &&
            (
                dataPrevInizio.year() != dataPrevFine.year()
                ||
                dataPrevInizio.month() != dataPrevFine.month()
            )
        ) {
            this.toastr.error('Errore: per i flussi di cassa la data prevista inizio e prevista fine devono essere nello stesso mese ed anno', null, {
                timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
            });
            return;
        }


        let dataPI = null;
        let dataPIString = '';
        if (this.currentFase && this.currentFase.dataPrevistaInizio) {
            dataPI = moment(this.currentFase.dataPrevistaInizio).toDate();
            dataPI.setHours(dataPI.getHours() - dataPI.getTimezoneOffset() / 60);
            dataPIString = moment(dataPI).format('YYYY-MM-DD');
        }
        let dataPF = null;
        let dataPFString = '';
        if (this.currentFase && this.currentFase.dataPrevistaFine) {
            dataPF = moment(this.currentFase.dataPrevistaFine).toDate();
            dataPF.setHours(dataPF.getHours() - dataPF.getTimezoneOffset() / 60);
            dataPFString = moment(dataPF).format('YYYY-MM-DD');
        }
        if (this.currentFase && (!dataPF || !dataPI || !fase.dataPrevistaFine || !fase.dataPrevistaInizio
            || fase.dataPrevistaInizio != dataPIString
            || fase.dataPrevistaFine != dataPFString
            )){


//aggiungo la motivazione all'oggetto
            let motivazione = this.motivazioneForm.controls.motivazione.value;
            let motivazioni = this.currentFase.historyChangesOnDates && this.currentFase.historyChangesOnDates!=''?JSON.parse(this.currentFase.historyChangesOnDates):[];

            motivazioni.push({
                data:new Date()
                ,dataPrevistaInizio: dataPIString
                ,dataPrevistaFine: dataPFString
                ,motivazione:motivazione
            });

            fase.historyChangesOnDates = JSON.stringify(motivazioni);

        }


        this.loading = true;
        this.submitted = false;
        let myService =
            this._finanziamentoId ?
                this.finanziamentoService :
                this._interventoId ? this.interventoService
                    : this._pnrrLineaFinanziamentoId?this.pnrrService:null;


        let currentId =
            this._finanziamentoId ? this._finanziamentoId :
                this._interventoId ? this._interventoId
                : this._pnrrLineaFinanziamentoId?this._pnrrLineaFinanziamentoId:null;
        myService.saveFase(currentId, fase).subscribe(
            result => {
                this.loading = false;
                this.toastr.success('Fase salvata con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                this.setIsDirty(false);
                this.modalRef.hide();
                result['tipologia'] = `${result.tipologiaFase.fase} - ${result.tipologiaFase.titolo} - ${result.tipologiaFase.descrizione}`
                result['soggettiResponsabiliDescrizione'] = result.soggettiResponsabili.map(
                    (x) => `${x.cognome} ${x.nome}`
                ).join('|');                
                this.reloadData();
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile salvare la fase:${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );
    }


    //motivazione modifica date previta inizio e prevista fine

    motivazioneForm:FormGroup;
    @ViewChild('modalConfirmSave', { static: true })
    public modalConfirmSave: TemplateRef<any>;

    historyMotivazioni = null;

    modalRefMotivazione: BsModalRef;

    initializeMotivazioneForm(): FormGroup {
        let frm = this.formBuilder.group({

            motivazione: [null, [Validators.required]],
        });
        return frm;
    }

    closeConfirmSaveModal(){
        if (this.modalRefMotivazione)  this.modalRefMotivazione.hide();
    }
    openConfirmSaveModal() {
        this.submitted = false;
        this.motivazioneForm.reset();
        let config = {
            animated: true,
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-lg"
        };
        this.modalRefMotivazione = this.modalService.show(this.modalConfirmSave, config);
    }

_pnrrLineaFinanziamentoId = null;
@Input()
    set pnrrLineaFinanziamentoId(value) {
        console.log(value);
        this._pnrrLineaFinanziamentoId = value;
        if (!value) return;
        this.isMandatorySoggettoResponsabile = false;
        this.section='PNRR - Linee di Finanziamento - Fasi';
        this.readOnlyForm = false;
        this.reloadData();
    }
    get pnrrLineaFinanziamentoId() {
        return this._pnrrLineaFinanziamentoId;
    }


    getFasiByPnrrLineaFinanziamentoId(id: number) {
        this.loadDataCounter++;
        this.pnrrService.getFasi(id).subscribe(
            result => {
                result.forEach(
                    x => {
                        x['tipologia'] = `${x.tipologiaFase.fase} - ${x.tipologiaFase.titolo} - ${x.tipologiaFase.descrizione}`
                        x['soggettiResponsabiliDescrizione'] = x.soggettiResponsabili.map(
                            (x) => `${x.cognome} ${x.nome}`
                        ).join('|');
                    }
                );
                this.ngxDatatableWrapper.setInitialData(result);
                this.loadDataCounter--;
            }
        );
    }

    canEdit() {
        return (this.enableSection(this.section, ['update'], null,true/* this.isAdmin() || this.isGruppoPnrr()*/));
    }
    /**
* Controlla se l'utente può accedere o meno alla sezione.
* @param section il nome della sezione
* @param operations le operazioni da poter effettuare sulla sezione
* @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
* @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
* @returns true se il controllo è andato a buon fine, false altrimenti
*/
    enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
        return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
    }
    isGruppoPnrr() {
        return this.authService.isGruppoPnrr();
    }

    isAdmin() {
        return this.authService.isAdmin();
    }

    isMandatorySoggettoResponsabile = true;

    unitaDiMisuraChange($event){
        if (!$event)this.currentMask = null;
        switch($event?.id){
            case "numero":
            case "giorni":
                this.currentMask = this.maskNumero;
                break;
            case "euro":
            case "euro (milioni)":
                this.currentMask = this.maskImporto;
                break;
            case "percentuale":
                this.currentMask = this.maskPercentuale;
                break;

        }
    }
    documentazioneChanged($event){
        this.reloadData();
    }

    tipologiaFaseFaseChangeValue($event){
        console.log($event);
        this.tipologieFase = [];
        if (!$event){
            this.TipologieFasiTitoli = [];
            return;
        }
        this.f.tipologia.setValue(null);
        this.f.tipologiaFaseTitolo.setValue(null);
        this.TipologieFasiTitoli =  [...new Set(this.allTipologieFasi.filter(x=>x.fase ==$event).map(x=>x.titolo))];
    }
    tipologiaFaseTitoloChangeValue($event){
        console.log($event);
        if (!$event){
            this.tipologieFase = [];
            return;
        }
        this.f.tipologia.setValue(null);
        const fase =this.f.tipologiaFaseFase.value;        
        this.tipologieFase = this.allTipologieFasi.filter(x=>x.fase == fase && x.titolo == $event);
    }

}
