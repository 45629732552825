import { Component, Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
//import { DirtyComponent } from '../models/dirty-component';
import{IsDirtyHelper} from '../helpers/is-dirty.helper';

@Injectable({
  providedIn: 'root'
})
export class DirtyCheckGuard implements CanDeactivate<Component> {
    constructor(private isDirtyHelper:IsDirtyHelper){

    }
  canDeactivate(
    component: Component,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let canContinue = this.isDirtyHelper.checkIsDirty();
    return canContinue;
    /*let isDirty = this.isDirtyHelper.isDirty;
    if (isDirty) {
      let ris = confirm('Proseguendo le modifiche correnti andranno perse, continuare?');
      if (ris)this.isDirtyHelper.isDirty = false;
      return ris;
    } else {
      return true;
    }*/
  }

}
