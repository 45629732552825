import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Menu } from "../models/menu";
import { AppConfigService } from "./app-config.service";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.menu}`;

  constructor(private http: HttpClient) {}

  getAllMenu() {
    return this.http.get<Menu[]>(`${this.apiUrl}`);
  }

  getMenuById(id: number) {
    return this.http.get<Menu>(`${this.apiUrl}/${id}`);
  }

  insertMenu(menu: Menu) {
    return this.http.post<Menu>(`${this.apiUrl}/insert`, menu);
  }

  updateMenu(menu: Menu) {
    return this.http.put<Menu>(`${this.apiUrl}/update/${menu.id}`, menu);
  }

  deleteMenu(id: number) {
    return this.http.delete<Menu>(`${this.apiUrl}/delete/${id}`);
  }
}
