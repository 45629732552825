// file iniziale dash-case.pipe.ts generato da Angular CLI
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
defineLocale('it', itLocale);

@Pipe({
  name: 'italianCurrencyPipe'
})
export class ItalianCurrencyPipe implements PipeTransform {
    private currentLocale:string = 'it';
    private thousandSeparator:string =".";
    private decimalSeparator:string = ",";
    private currencySymbol:string = "€";
    constructor(){}
  transform(value: any, args?: any): any {
      //value must have . as decimal separator, so perform a replace      
      if (!value)return value;
      value = (typeof value === 'number')?value : value.replace(",",".").trim();
      let format =(args && args.format)?args.format: '1.2-2';  
      let currency = (args && args.currency )?'symbol':'';
    return new CurrencyPipe(this.currentLocale).transform(value,'EUR',currency,format,'it-IT');      
  }
  parse(value:any):string{
    return (value || '').replace(new RegExp('\\' + this.currencySymbol, 'g'), '').replace(new RegExp('\\' + this.thousandSeparator, 'g'),'').trim();
  }
  toFloat(value:string|number):number{       
      return (typeof value === 'number')?value:(value==''?0: parseFloat(this.parse(value).replace(new RegExp('\\' +this.decimalSeparator , 'g'),'.')));
  }  
}