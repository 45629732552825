<div class="container-xl">
    <!--<div class=" pt-4 elemento-aggiorna">
          <div class=" row  justify-content-center">
              <div class="col-lg-3 col-md-6 text-center" *ngIf="gotoBtnOptions && gotoBtnOptions.show">
                  <button (click)="gotoBackBtnClick()" [disabled]="loading" class="btn btn-light btn-block" type="button">
            {{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}
          </button>
              </div>
          </div>
      </div>
      <div class="mb-5">
          <hr />
      </div>-->

    <div class="position-sticky sticky-top  pt-4 pb-4 elemento-aggiorna mb-5 shadow-custom" [ngClass]="{'sticky-top-custom-modal':( intervento && !intervento.isMain),'sticky-top-custom':(!intervento ||( intervento && intervento.isMain)) }">
        <div class=" row  justify-content-center">
            <div class="col-lg-4 col-md-6 text-center" *ngIf="gotoBtnOptions && gotoBtnOptions.show">
                <button (click)="gotoBackBtnClick()" [disabled]="loading" class="btn btn-secondary btn-block font-weight-bold" type="button">
                    {{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}&nbsp;<i class="fas fa-times"
                        *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i>
                </button>
            </div>
        </div>

    </div>
    <!--***-->


    <accordion [isAnimated]="true">
        <accordion-group heading="Dati generali">


            <div class="row justify-content-center">
                <div class=" col-lg-6">
                    <table class="table">
                        <tr>
                            <td class="text-right">id:</td>
                            <td>{{intervento?intervento.id:''}}</td>
                        </tr>

                        <tr>
                            <td class="text-right">codice:</td>
                            <td>{{intervento?intervento.codice:''}}</td>
                        </tr>

                        <tr style="vertical-align: top;">
                            <td class="text-right">descrizione:</td>
                            <td style="vertical-align: top;">{{intervento?intervento.descrizione:''}}</td>
                        </tr>
                        <tr>
                            <td class="text-right">anno&nbsp;di&nbsp;riferimento:</td>
                            <td>{{intervento?intervento.annoRiferimento:''}}</td>
                        </tr>
                        <tr>
                            <td class="text-right">codice&nbsp;direzione:</td>
                            <td>{{intervento?intervento.codiceDirezione:''}}</td>
                        </tr>

                    </table>
                </div>

            </div>

            <div class="mb-5 ">
                <hr>
            </div>

            <div class="row justify-content-between">
                <div class=" col-lg-6">
                    <table class="table table-bordered border border-light rounded shadow">
                        <tr>
                            <td colspan="3">
                                <h5><label class="p-1">Aree&nbsp;gestionali&nbsp;direzione&nbsp;e/o&nbsp;esterne</label>
                                </h5>
                            </td>
                        </tr>
                        <tr>
                            <th class="">descrizione</th>
                            <th class="">codice ipa</th>
                            <th class="">struttura</th>
                        </tr>
                        <ng-container *ngIf="intervento && intervento.areeTematiche && intervento.areeTematiche.length>0">
                            <tr *ngFor="let item of intervento.areeTematiche">
                                <td>{{item.descrizione}}</td>
                                <td>{{item.codiceIpa}}</td>
                                <td>{{item.struttura}}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!(intervento && intervento.areeTematiche && intervento.areeTematiche.length>0)">
                            <tr>
                                <td colspan="3">Nessuna area selezionata</td>
                            </tr>
                        </ng-container>
                    </table>
                </div>
                <div class=" col-lg-6">
                    <table class="table table-bordered  border border-light rounded shadow">
                        <tr>
                            <td colspan="3">
                                <h5><label class="p-1">Classificazioni</label></h5>
                            </td>
                        </tr>
                        <tr>
                            <th class="">codice</th>
                            <th class="">tipo</th>
                            <th class="">descrizione</th>
                        </tr>

                        <ng-container *ngIf="intervento && intervento.classificazioni && intervento.classificazioni.length>0">
                            <tr *ngFor="let item of intervento.classificazioni" style="vertical-align: top;">
                                <td>{{item.codice}}</td>
                                <td>{{item.tipoClassificazione}}</td>
                                <td>{{item.descrizione}}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!(intervento && intervento.classificazioni && intervento.classificazioni.length>0)">
                            <tr>
                                <td colspan="3">Nessuna classificazione selezionata</td>
                            </tr>
                        </ng-container>

                    </table>
                </div>


            </div>

            <div class="row justify-content-between">


                <div class=" col-lg-6">
                    <table class="table table-bordered border border-light rounded shadow">
                        <tr>
                            <td colspan="4">
                                <h5><label class="p-1">Soggetti&nbsp;giuridici</label></h5>
                            </td>
                        </tr>
                        <tr>
                            <th class="">sigla prov.</th>
                            <th class="">codice</th>
                            <th class="">denominazione</th>
                            <th class="">ruolo</th>
                        </tr>
                        <ng-container *ngIf="intervento && intervento.soggettiGiuridici && intervento.soggettiGiuridici.length>0">
                            <tr *ngFor="let item of intervento.soggettiGiuridici" style="vertical-align: top;">
                                <td>{{item.siglaProvincia}}</td>
                                <td>{{item.codiceIdentificativo}}</td>
                                <td>{{item.denominazione}}</td>
                                <td>{{item.ruolo}}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!(intervento && intervento.soggettiGiuridici && intervento.soggettiGiuridici.length>0)">
                            <tr>
                                <td colspan="4">Nessuna soggetto giuridico selezionato</td>
                            </tr>
                        </ng-container>
                    </table>
                </div>
                <div class=" col-lg-6">
                    <table class="table table-bordered  border border-light rounded shadow">
                        <tr>
                            <td colspan="3">
                                <h5><label class="p-1">Temi prioritari</label></h5>
                            </td>
                        </tr>
                        <tr>

                            <th class="">descrizione</th>
                        </tr>


                        <ng-container *ngIf="intervento && intervento.temiPrioritari && intervento.temiPrioritari.length>0">
                            <tr *ngFor="let item of intervento.temiPrioritari" style="vertical-align: top;">
                                <td>{{item.descrizione}}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!(intervento && intervento.temiPrioritari && intervento.temiPrioritari.length>0)">
                            <tr>
                                <td colspan="">Nessun tema prioritario selezionato</td>
                            </tr>
                        </ng-container>

                    </table>
                </div>


            </div>

        </accordion-group>
    </accordion>

    <!--tabella sal erogazioni-->
    <div class="col-lg-6 col-sm-6 col-6 mt-5">
        <h5 for="" class="">Tabella Giustificativi</h5>
    </div>


    <div class="container">

        <div class="fixedHeaderTable col-lg-12 mt-3 table-responsive table-wrapper-scroll-y scrollbar-y px-0">
            <table class="table table-hover table-colore">
                <thead class="">
                    <th class="fixedmeY " style="min-width: 240px;"></th>
                    <th class="" style="min-width: 240px;"></th>
                    <ng-container *ngIf="intervento && intervento.importi">
                        <th  class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                            {{annualita.anno}}</th>
                    </ng-container>
                    <th class="text-right" style="min-width: 180px;">totale</th>
                </thead>
                <tbody>

                    <ng-container *ngIf="intervento && listaSalNormali && listaSalNormali.length>0">
                        <ng-container *ngFor="let sal of listaSalNormali;let i=index">
                            <!--[ngClass]="{'bg-danger': i%2==0,'bg-success':i%2!=0}"-->
                            <tr>

                                <td class="fixedme" style="min-width: 240px;">
                                    <a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')" class="pointer font-weight-bold text-0083e0"
                                        data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.tr_collapse_fin_' + i" [attr.data-target]="'.tr_collapse_fin_' + i">
                                        <i class="fas fa-arrow-circle-down" title="espandi"></i>&nbsp;{{sal.categoria.titolo}}
                                    </a>
                                    <div class="" style="font-size: 13px;">{{sal.descrizione}}</div>
                                </td>
                                <td style="min-width: 240px; font-size: 13px;"></td>

                                <td class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                    <!--commento perche questo è il valore complessivo del finanziamento, non quello associato al progetto {{printValueAsCurrency(getImportoPerAnno(finanziamento.finanziamento,anno),false)}}&nbsp;€</td>-->
                                    {{printValueAsCurrency(getSalImportoPerAnno(sal,annualita.anno),false)}}&nbsp;€
                                </td>

                                <td class="text-right" style="min-width: 180px;">
                                    <!--commento perche questo è il valore complessivo del finanziamento, non quello associato al progetto  <strong>{{printValueAsCurrency(getTotale(finanziamento.finanziamento),false)}}&nbsp;€</strong>-->
                                    <strong>{{printValueAsCurrency(sal.importo,false)}}&nbsp;€</strong>
                                </td>

                            </tr>

                            <!--righe interventi-->

                            <!--<ng-container *ngFor="let erogazione of sal.erogazioni;let j = index">
                                <tr class="background-table {{'tr_collapse_fin_' + i}}">
                                    <th class="fixedme" scope="row" style="min-width: 240px;">
                                        erogazione {{erogazione.tipologia.titolo}}
                                        <div class="" style="font-size: 13px; font-weight: normal;"></div>
                                    </th>
                                    <td style="min-width: 240px; font-size: 13px;"></td>
                                    <td style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                        {{printValueAsCurrency(getImportoPerAnnoErogazione(erogazione,annualita.anno),false)}}&nbsp;€
                                    </td>

                                    <td style="min-width: 180px;">
                                        <strong>{{printValueAsCurrency(erogazione.importo,false)}}&nbsp;€</strong></td>
                                </tr>
                            </ng-container>-->


                            <!--<tr>
                                <td colspan="1" class="fixedHeaderTable fixedme bold" style="min-width: 240px;">
                                    erogato
                                </td>
                                <td></td>
                                <td style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                    {{printValueAsCurrency(getErogatoPerAnnoPerSal(sal,annualita.anno),false)}}&nbsp;€
                                </td>
                                <td style="min-width: 180px;">
                                    <strong>{{printValueAsCurrency(getErogatoTotalePerSal(sal),false)}}&nbsp;€</strong>
                                </td>
                            </tr>-->

                            <!--<tr class="border-custom border-primary">
                                <td colspan="1" class="fixedHeaderTable fixedme bold" style="min-width: 240px;">
                                    da erogare
                                </td>
                                <td></td>
                                <td style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                    {{printValueAsCurrency(getSalImportoPerAnno(sal,annualita.anno) - getErogatoPerAnnoPerSal(sal,annualita.anno),false)}}&nbsp;€
                                </td>
                                <td style="min-width: 180px;">
                                    <strong>{{printValueAsCurrency(sal.importo - getErogatoTotalePerSal(sal),false)}}&nbsp;€</strong>
                                </td>
                            </tr>-->



                        </ng-container>
                    </ng-container>


                </tbody>


                <tfoot>
                    <tr>
                        <th class="fixedmefooter">totale Giustificativi emessi</th>
                        <th></th>
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                <!--{{printValueAsCurrency(getTotaleSommaAnnoFinanziamentiAssegnato(anno),false)}}&nbsp;€</th>-->
                                {{printValueAsCurrency(getTotaleTuttiSalPerAnno(listaSalNormali, annualita.anno),false)}}&nbsp;€
                            </th>

                        </ng-container>
                        <!--<th>{{printValueAsCurrency(getTotaleFinanziamentiUtilizzato(),false)}}&nbsp;€</th>-->
                        <th class="text-right">{{printValueAsCurrency(getTotaleTuttiSal(listaSalNormali),false)}}&nbsp;€</th>
                    </tr>
                    <!--<tr>
                        <th class="fixedmefooter">totale importi erogati</th>
                        <th></th>
                        <ng-container *ngIf="intervento">
                            <th style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                {{printValueAsCurrency(getTotaleErogatoPerAnno(listaSalNormali,annualita.anno),false)}}&nbsp;€
                            </th>
                        </ng-container>
                        <th>{{printValueAsCurrency(getTotaleErogato(listaSalNormali),false)}}&nbsp;€</th>

                    </tr>-->
                    <tr>
                        <th class="fixedmefooter">IMPORTO INTERVENTO</th>
                        <th></th>
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                {{printValueAsCurrency(annualita.importo,false)}}&nbsp;€
                            </th>
                        </ng-container>

                        <th class="text-right">{{printValueAsCurrency(getImportoTotaleIntervento(),false)}}&nbsp;€</th>
                    </tr>
                    <!--<tr>
                        <th class="fixedmefooter">differenza da erogare su SAL emessi</th>
                        <th></th>
                        <ng-container *ngIf="intervento">
                            <th style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                {{printValueAsCurrency(getTotaleTuttiSalPerAnno(listaSalNormali, annualita.anno)-getTotaleErogatoPerAnno(listaSalNormali,annualita.anno),false)}}&nbsp;€
                            </th>
                        </ng-container>

                        <th>
                            {{printValueAsCurrency(getTotaleTuttiSal(listaSalNormali)-getTotaleErogato(listaSalNormali),false)}}&nbsp;€
                        </th>
                    </tr>-->
                    <!--<tr>
                        <th class="fixedmefooter">differenza da erogare su intervento</th>
                        <th></th>
                        <ng-container *ngIf="intervento">
                            <th style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                {{printValueAsCurrency(annualita.importo-getTotaleErogatoPerAnno(listaSalNormali,annualita.anno),false)}}&nbsp;€
                            </th>
                        </ng-container>

                        <th>{{printValueAsCurrency(getImportoTotaleIntervento()-getTotaleErogato(listaSalNormali),false)}}&nbsp;€
                        </th>
                    </tr>-->
                </tfoot>
            </table>
        </div>

        <div class="mt-5">
            <hr>
        </div>

    </div>



    <!--tabella sal erogazioni-->
    <div class="col-lg-6 col-sm-6 col-6 mt-5">
        <h5 for="" class="">Tabella SAL / Altro / Erogazioni</h5>
    </div>


    <div class="container">

        <div class="fixedHeaderTable col-lg-12 mt-3 table-responsive table-wrapper-scroll-y scrollbar-y px-0">
            <table class="table table-hover table-colore">
                <thead class="">
                    <th class="fixedmeY" style="min-width: 240px;"></th>
                    <th style="min-width: 240px;"></th>
                    <ng-container *ngIf="intervento && intervento.importi">
                        <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                            {{annualita.anno}}</th>
                    </ng-container>
                    <th class="text-right" style="min-width: 180px;">totale</th>
                </thead>
                <tbody>

                    <ng-container *ngIf="intervento && listaSalSa && listaSalSa.length>0">
                        <ng-container *ngFor="let sal of listaSalSa;let i=index">
                            <tr>

                                <td class="fixedme" style="min-width: 240px;">
                                    <a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')" class="pointer font-weight-bold text-0083e0"
                                        data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.tr_collapse_fin_sa_' + i" [attr.data-target]="'.tr_collapse_fin_sa_' + i">
                                        <i class="fas  fa-arrow-circle-down" title="espandi"></i>&nbsp; {{sal.categoria.titolo}}

                                    </a>
                                    <div class="" style="font-size: 13px;">{{sal.descrizione}}</div>
                                </td>
                                <td style="min-width: 240px; font-size: 13px;"></td>

                                <td class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                    <!--commento perche questo è il valore complessivo del finanziamento, non quello associato al progetto {{printValueAsCurrency(getImportoPerAnno(finanziamento.finanziamento,anno),false)}}&nbsp;€</td>-->
                                    {{printValueAsCurrency(getSalImportoPerAnno(sal,annualita.anno),false)}}&nbsp;€
                                </td>

                                <td class="text-right" style="min-width: 180px;">
                                    <!--commento perche questo è il valore complessivo del finanziamento, non quello associato al progetto  <strong>{{printValueAsCurrency(getTotale(finanziamento.finanziamento),false)}}&nbsp;€</strong>-->
                                    <strong>{{printValueAsCurrency(sal.importo,false)}}&nbsp;€</strong>
                                </td>

                            </tr>

                            <!--righe interventi-->

                            <ng-container *ngFor="let erogazione of sal.erogazioni;let j = index">
                                <tr class="background-table {{'tr_collapse_fin_sa_' + i}}">
                                    <th class="fixedme" scope="row" style="min-width: 240px;">
                                        erogazione {{erogazione.tipologia.titolo}}
                                        <div class="" style="font-size: 13px; font-weight: normal;"></div>
                                    </th>
                                    <td style="min-width: 240px; font-size: 13px;"></td>

                                    <td class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                        {{printValueAsCurrency(getImportoPerAnnoErogazione(erogazione,annualita.anno),false)}}&nbsp;€
                                    </td>

                                    <td class="text-right" style="min-width: 180px;">
                                        <strong>{{printValueAsCurrency(erogazione.importo,false)}}&nbsp;€</strong></td>
                                </tr>
                            </ng-container>

                            <!--[ngClass]="{'bg-danger': i%2==0,'bg-success':i%2!=0}"-->
                            <tr>
                                <td colspan="1" class="fixedHeaderTable fixedme bold" style="min-width: 240px;">
                                    erogato
                                </td>
                                <td></td>
                                <td class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                    {{printValueAsCurrency(getErogatoPerAnnoPerSal(sal,annualita.anno),false)}}&nbsp;€
                                </td>
                                <td class="text-right" style="min-width: 180px;">
                                    <strong>{{printValueAsCurrency(getErogatoTotalePerSal(sal),false)}}&nbsp;€</strong>
                                </td>
                            </tr>
                            <!-- [ngClass]="{'bg-danger': i%2==0,'bg-success':i%2!=0}"-->
                            <tr class="border-custom border-primary">
                                <td colspan="1" class="fixedHeaderTable fixedme bold" style="min-width: 240px;">
                                    da erogare
                                </td>
                                <td></td>
                                <td class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                    {{printValueAsCurrency(getSalImportoPerAnno(sal,annualita.anno) - getErogatoPerAnnoPerSal(sal,annualita.anno),false)}}&nbsp;€
                                </td>
                                <td class="text-right" style="min-width: 180px;">
                                    <strong>{{printValueAsCurrency(sal.importo - getErogatoTotalePerSal(sal),false)}}&nbsp;€</strong>
                                </td>
                            </tr>



                        </ng-container>
                    </ng-container>


                </tbody>


                <tfoot>
                    <tr>
                        <th class="fixedmefooter">totale SAL/Altro emessi</th>
                        <th>A</th>
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                <!--{{printValueAsCurrency(getTotaleSommaAnnoFinanziamentiAssegnato(anno),false)}}&nbsp;€</th>-->
                                {{printValueAsCurrency(getTotaleTuttiSalPerAnno(listaSalSa, annualita.anno),false)}}&nbsp;€
                            </th>

                        </ng-container>
                        <!--<th>{{printValueAsCurrency(getTotaleFinanziamentiUtilizzato(),false)}}&nbsp;€</th>-->
                        <th class="text-right">{{printValueAsCurrency(getTotaleTuttiSal(listaSalSa),false)}}&nbsp;€</th>
                    </tr>
                    <tr>
                        <th class="fixedmefooter">totale Giustificativi emessi</th>
                        <th>B</th>
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                <!--{{printValueAsCurrency(getTotaleSommaAnnoFinanziamentiAssegnato(anno),false)}}&nbsp;€</th>-->
                                {{printValueAsCurrency(getTotaleTuttiSalPerAnno(listaSalNormali, annualita.anno),false)}}&nbsp;€
                            </th>

                        </ng-container>
                        <!--<th>{{printValueAsCurrency(getTotaleFinanziamentiUtilizzato(),false)}}&nbsp;€</th>-->
                        <th class="text-right">{{printValueAsCurrency(getTotaleTuttiSal(listaSalNormali),false)}}&nbsp;€</th>
                    </tr>



                    <tr>
                        <th class="fixedmefooter">totale importi erogati </th>
                        <th>C</th>
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                {{printValueAsCurrency(getTotaleErogatoPerAnno(listaSalSa,annualita.anno),false)}}&nbsp;€
                            </th>
                            <!--{{printValueAsCurrency(getTotaleSommaAnnoFinanziamenti(anno),false)}}&nbsp;€</th>-->
                        </ng-container>
                        <!--<th>{{printValueAsCurrency(getTotaleFinanziamenti(),false)}}&nbsp;€</th>-->
                        <th class="text-right">{{printValueAsCurrency(getTotaleErogato(listaSalSa),false)}}&nbsp;€</th>

                    </tr>

                    <!--<tr>
                        <th class="fixedmefooter">totale importi erogati</th>
                        <th>D</th>
                        <ng-container *ngIf="intervento">
                            <th style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                {{printValueAsCurrency(getTotaleErogatoPerAnno(listaSalNormali,annualita.anno),false)}}&nbsp;€
                            </th>
                            
                        </ng-container>                        
                        <th>{{printValueAsCurrency(getTotaleErogato(listaSalNormali),false)}}&nbsp;€</th>

                    </tr>-->


                    <tr>
                        <th class="fixedmefooter">IMPORTO INTERVENTO</th>
                        <th>D</th>
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                {{printValueAsCurrency(annualita.importo,false)}}&nbsp;€
                            </th>
                        </ng-container>

                        <th class="text-right">{{printValueAsCurrency(getImportoTotaleIntervento(),false)}}&nbsp;€</th>
                    </tr>
                    <tr>
                        <th class="fixedmefooter">differenza da erogare su SAL/Altro emessi</th>
                        <th>A-C</th>
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                {{printValueAsCurrency(getTotaleTuttiSalPerAnno(listaSalSa, annualita.anno)-getTotaleErogatoPerAnno(listaSalSa,annualita.anno),false)}}&nbsp;€
                            </th>
                        </ng-container>

                        <th class="text-right">{{printValueAsCurrency(getTotaleTuttiSal(listaSalSa)-getTotaleErogato(listaSalSa),false)}}&nbsp;€
                        </th>
                    </tr>
                    <!--<tr>
                        <th class="fixedmefooter">differenza da erogare su SAL emessi</th>
                        <th>B-D</th>
                        <ng-container *ngIf="intervento">
                            <th style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                {{printValueAsCurrency(getTotaleTuttiSalPerAnno(listaSalNormali, annualita.anno)-getTotaleErogatoPerAnno(listaSalNormali,annualita.anno),false)}}&nbsp;€
                            </th>
                        </ng-container>

                        <th>
                            {{printValueAsCurrency(getTotaleTuttiSal(listaSalNormali)-getTotaleErogato(listaSalNormali),false)}}&nbsp;€
                        </th>
                    </tr>-->


                    <tr>
                        <th class="fixedmefooter">differenza da erogare su intervento</th>
                        <th>D-C</th>
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                &nbsp;
                            </th>
                        </ng-container>

                        <th class="text-right">{{printValueAsCurrency(getImportoTotaleIntervento()-getTotaleErogato(listaSalSa),false)}}&nbsp;€
                        </th>
                    </tr>


                 
                </tfoot>
            </table>
        </div>

        <div class="mt-5">
            <hr>
        </div>

    </div>




    <!--tabella capitoli-->
    <div class="col-lg-6 col-sm-6 col-6 mt-5">
        <h5 for="" class="">Tabella Capitoli di bilancio</h5>
    </div>
    <div class="container">

        <div class="fixedHeaderTable col-lg-12 mt-3 table-responsive table-wrapper-scroll-y scrollbar-y px-0">
            <table class="table table-hover table-colore">
                <thead class="">
                    <th class="fixedmeY" style="min-width: 240px;"></th>
                    <!--<th style="min-width: 240px;">fase</th>-->
                    <ng-container *ngIf="intervento">
                        <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">{{annualita.anno}}
                        </th>
                    </ng-container>
                    <!--<th style="min-width: 150px;">coperto</th>-->
                    <!--<th style="min-width: 150px;">da coprire</th>-->
                    <th class="text-right" style="min-width: 180px;">totale</th>
                </thead>
                <tbody>

                    <!--1###-->
                    <ng-container *ngIf="intervento && listaCapitoli && listaCapitoli.length>0">
                        <ng-container *ngFor="let capitolo of listaCapitoli;let i = index">
                            <tr>

                                <td class="fixedme" style="min-width: 240px;">
                                    <a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')" class="pointer font-weight-bold text-0083e0"
                                        data-toggle="collapse" role="button" aria-expanded="false">
                                        <i class="fas  fa-arrow-circle-down" title="espandi"></i>&nbsp;capitolo {{capitolo.codice}}
                                    </a>
                                    <div class="" style="font-size: 13px;">{{capitolo.descrizione}}</div>
                                </td>

                                <!--<td style="min-width: 240px; font-size: 13px;">{{getFaseCorrente(progetto)}}</td>-->
                                <td class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                    {{printValueAsCurrency(getImportoPerAnnoCapitolo(capitolo,annualita.anno),false)}}&nbsp;€
                                </td>
                                <!-- <td style="min-width: 150px;">{{printValueAsCurrency(getCoperto(progetto),false)}}&nbsp;€</td>-->
                                <!--<td style="min-width: 150px;">{{printValueAsCurrency(getDaCoprire(progetto),false)}}&nbsp;€</td>-->
                                <td class="text-right" style="min-width: 180px;">
                                    <strong>{{printValueAsCurrency(getTotaleCapitolo(capitolo),false)}}&nbsp;€</strong>
                                </td>

                            </tr>

                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!(listaCapitoli && listaCapitoli.length>0)">
                        <tr>
                            <td colspan="20">Nessun capitolo di bilancio presente</td>
                        </tr>
                    </ng-container>


                </tbody>
                <tfoot>
                    <tr>
                        <th class="fixedmefooter">totale stanziato in bilancio</th>
                        <!--<th></th>-->
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                {{printValueAsCurrency(getTotaleSommaAnnoCapitoli(annualita.anno),false)}}&nbsp;€</th>
                        </ng-container>
                        <!--<th>{{printValueAsCurrency(getSommaCopertoTotale(),false)}}&nbsp;€</th>-->
                        <!--<th>{{printValueAsCurrency(getSommaDaCoprireTotale(),false)}}&nbsp;€</th>-->
                        <th class="text-right">{{printValueAsCurrency(getTotaleSommaCapitoli(),false)}}&nbsp;€</th>
                    </tr>
                    <tr>
                        <th class="fixedmefooter">valore intervento</th>
                        <!--<th></th>-->
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                {{printValueAsCurrency(getImportoPerAnno(intervento,annualita.anno),false)}}&nbsp;€</th>
                        </ng-container>
                        <!--<th>{{printValueAsCurrency(getCoperto(intervento),false)}}&nbsp;€</th>-->
                        <!--<th>{{printValueAsCurrency(getDaCoprire(intervento),false)}}&nbsp;€</th>-->

                        <th class="text-right">{{printValueAsCurrency(getTotaleIntervento(intervento),false)}}&nbsp;€</th>
                    </tr>
                    <tr>
                        <th class="fixedmefooter">Differenza ancora da allocare in bilancio</th>
                        <!-- <th></th>-->
                        <ng-container *ngIf="intervento">
                            <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of intervento.importi">
                                {{printValueAsCurrency(getImportoPerAnno(intervento,annualita.anno)-getTotaleSommaAnnoCapitoli(annualita.anno),false)}}&nbsp;€
                            </th>
                        </ng-container>
                        <!--<th style="min-width: 150px;">{{printValueAsCurrency(getDisponibilitaCoperto(),false)}}&nbsp;€</th>-->
                        <!--<th style="min-width: 150px;">{{printValueAsCurrency(getDisponibilitaDaCoprire(),false)}}&nbsp;€</th>-->

                        <th class="text-right">
                            {{printValueAsCurrency(getTotaleIntervento(intervento)-getTotaleSommaCapitoli(),false)}}&nbsp;€
                        </th>
                    </tr>
                </tfoot>


            </table>
        </div>


        <div class=" mt-5">
            <hr>
        </div>
    </div>




</div>