import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "./app-config.service";
import { Document } from "../models/document";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class DocumentService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${
    AppConfigService.env.endpoints.document
  }`;
  constructor(private http: HttpClient) {}
  

  getAllDocuments(): Observable<Document[]> {
    return this.http.get<Document[]>(`${this.apiUrl}all`);
  }

  

  saveDocument(form: FormData) {
    return this.http.post<Document>(`${this.apiUrl}`, form);
  }

  

  downloadAttach(n: Document) {
    return this.http.get(`${this.apiUrl}${n.id}/file`, {
      observe: "response",
      responseType: "blob"
    });
  }

  deleteDocument(n: Document) {
    return this.http.delete(`${this.apiUrl}${n.id}`);
  }  
}
