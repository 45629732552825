import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TitleLogoService {

 title = "Gestione lavori pubblici"

 logo = "assets/img/logo-glp.svg"

 ente = {
  nome: "Provincia di Viterbo",
  sitoWeb: "https://www.provincia.viterbo.it/",
  logo: "assets/img/stemma-p-viterbo.png"
};

  constructor() { }
}
