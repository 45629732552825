import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { TipologiaSoggettoResponsabile } from '../models';




@Injectable({
    providedIn: "root"
})
export class TipologiaSoggettoResponsabileService {
    private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}TipologieSoggettoResponsabile/`;
    constructor(private http: HttpClient) { }

    getAllDt(dataTablesParameters: any) {
        return this.http.post<any>(
            `${this.apiUrl}alldt`,
            dataTablesParameters
        );
    }
    getAll() {
        return this.http.get<TipologiaSoggettoResponsabile[]>(
            `${this.apiUrl}`            
        );
    }

    insert(s: TipologiaSoggettoResponsabile) {
        return this.http.post<any>(`${this.apiUrl}`, s);
    }
    update(s: TipologiaSoggettoResponsabile) {
        return this.http.put<any>(`${this.apiUrl}${s.id}`, s);
    }
    delete(id: number) {
        return this.http.delete<any>(`${this.apiUrl}${id}`);
    }
    save(s:TipologiaSoggettoResponsabile){
        if (!s.id)return this.insert(s);
        return this.update(s);
    }

}
