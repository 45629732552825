import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { Finanziamento, Fase, FinanziamentoAnnualita, Provvedimento } from '../models';
import { SicerImpegnoAccertamento } from "../models/sicer-impegno-accertamento";

@Injectable({
    providedIn: "root"
})
export class FinanziamentoService {
    private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}Finanziamenti/`;
    constructor(private http: HttpClient) { }

    getById(id:number){
        return this.http.get<any>(
            `${this.apiUrl}${id}`
        );
    }

    esportaPdf(id:number){
        /*return this.http.get<any>(
            `${this.apiUrl}${id}/pdf`
        );*/
        return this.http.get(
            `${this.apiUrl}${id}/pdf`
            , {
            observe: "response",
            responseType: "blob",
          });
    }

    esportaExcel(id:number){
        /*return this.http.get<any>(
            `${this.apiUrl}${id}/pdf`
        );*/
        return this.http.get(
            `${this.apiUrl}${id}/excel`
            , {
            observe: "response",
            responseType: "blob",
          });
    }

    esportaExcelCompleto(id:number){
        /*return this.http.get<any>(
            `${this.apiUrl}${id}/pdf`
        );*/
        return this.http.get(
            `${this.apiUrl}${id}/excelCompleto`
            , {
            observe: "response",
            responseType: "blob",
          });
    }



    getAllDt(dataTablesParameters: any, filters: any) {
        return this.http.post<any>(
            `${this.apiUrl}alldt`,
            {
                dtParams: dataTablesParameters,
                filters: filters
            }
        );
    }

    getStoricoDt(finId:number,dataTablesParameters: any) {
        return this.http.post<any>(
            `${this.apiUrl}${finId}/history/alldt`,
            dataTablesParameters
        );
    }

    getDettaglioStorico(storicoId,finId){
        return this.http.get<any>(`${this.apiUrl}${finId}/history/${storicoId}`);
    }

    getAllDaValidareDt(dataTablesParameters: any) {
        return this.http.post<any>(
            `${this.apiUrl}allDtDaValidare`,
            dataTablesParameters
        );
    }
    getAll() {
        return this.http.get<Finanziamento[]>(
            `${this.apiUrl}`
        );
    }

    insert(s: Finanziamento) {
        return this.http.post<any>(`${this.apiUrl}`, s);
    }
    update(s: Finanziamento) {
        return this.http.put<any>(`${this.apiUrl}${s.id}`, s);
    }

    delete(id: number,motivazione:string='') {
        return this.http.delete<any>(`${this.apiUrl}${id}?motivazione=${motivazione}`);
    }

    save(s:Finanziamento){
        if (!s.id)return this.insert(s);
        return this.update(s);
    }

    getFasi(progettoId:number){
        return this.http.get<Fase[]>(`${this.apiUrl}${progettoId}/Fasi`);
    }
    deleteFase(progettoId:number,faseId:number){
        return this.http.delete<boolean>(`${this.apiUrl}${progettoId}/Fasi/${faseId}`);
    }
    saveFase(progettoId:number,fase:any){
        if (fase.id && fase.id>0)return this.updateFase(progettoId,fase);
        return this.insertFase(progettoId,fase);
    }
    insertFase(progettoId:number,fase:any) {
        return this.http.post<any>(`${this.apiUrl}${progettoId}/Fasi`, fase);
    }
    updateFase(progettoId:number,fase:any) {
        return this.http.put<any>(`${this.apiUrl}${progettoId}/Fasi/${fase.id}`, fase);
    }

    saveAnnualita(finId:number,annualita:any){
        if (annualita.id && annualita.id>0)return this.updateAnnualita(finId,annualita);
        return this.insertAnnualita(finId,annualita);
    }
    insertAnnualita(finId:number,annualita:any){
        return this.http.post<any>(`${this.apiUrl}${finId}/Annualita`, annualita);
    }
    updateAnnualita(finId:number,annualita:any){
        return this.http.put<any>(`${this.apiUrl}${finId}/Annualita/${annualita.id}`, annualita);
    }
    deleteAnnualita(finId:number,annualita:any){
        return this.http.delete<any>(`${this.apiUrl}${finId}/Annualita/${annualita.id}`);
    }
    getAnnualita(progettoId:number){
        return this.http.get<FinanziamentoAnnualita[]>(`${this.apiUrl}${progettoId}/Annualita`);
    }

    getProvvedimenti(finId:number){
        return this.http.get<any[]>(`${this.apiUrl}${finId}/Provvedimenti`);
    }
    bindProvvedimento(finId:number,provvedimento:any){
        return this.http.post<Provvedimento>(`${this.apiUrl}${finId}/Provvedimenti`,provvedimento);
    }
    unbindProvvedimento(finId:number,provvId:number){
        return this.http.delete<any>(`${this.apiUrl}${finId}/Provvedimenti/${provvId}`);
    }

    getProgetti(finId:number){
        return this.http.get<any[]>(`${this.apiUrl}${finId}/progetti`);
    }

    manageValidation(finId:number, action:boolean){
        return this.http.put<any>(`${this.apiUrl}${finId}/validazione/${action}`,null);
    }

    checkAndCreateAnnualitaFromRipartizioni(finId:number,type:string){
        return this.http.put<any>(`${this.apiUrl}${finId}/checkAndCreateAnnualitaFromRipartizioni?type=${type}`,null);
    }


    getSicerCapitoliBilancioByFinanziamentoId(id: number){
        return this.http.get<any>(`${this.apiUrl}${id}/SicerCapitoliBilancio`);
    }

    associaSicerCapitolo(finId:any,pkCpt:any){
      return this.http.put<any>(`${this.apiUrl}${finId}/SicerCapitoliBilancio/${pkCpt}`,null);
    }

    disassociaSicerCapitolo(finId: any, pkCpt: any, interventiIds: string[]) {
      return this.http.delete<any>(`${this.apiUrl}${finId}/SicerCapitoliBilancio/${pkCpt}`);
    }

    getSicerAccertamentiSelezionatiByFinanziamentoId(id: number) {
      return this.http.get<any[]>(`${this.apiUrl}${id}/SicerImpegniAccertamenti`);
    }

    associaSicerImpegnoAccertamento(id: number, pkImpAcc: string, pkCpt: string) {
      return this.http.put<any[]>(`${this.apiUrl}${id}/SicerImpegniAccertamenti/${pkImpAcc}/SicerCapitoliBilancio/${pkCpt}`, null);
    }

    disassociaSicerImpegnoAccertamento(id: number, pkImpAcc: string, interventiIds: string[]) {
      return this.http.delete<any[]>(`${this.apiUrl}${id}/SicerImpegniAccertamenti/${pkImpAcc}`);
    }

    getSicerOrdinativiByPkImpaccAndCodFiscSoggetto(pkImpacc: string, codFiscSoggetto: string) {
      return this.http.get<any[]>(`${this.apiUrl}${pkImpacc}/SicerImpegniAccertamenti/${codFiscSoggetto}/SicerLiquidazioniOrdinativi`);
    }

    getDtSicerOrdinativiByFinanziamentoIdAndAccertamentoId(dtParams: any, finanziamentoId: number, accertamentoId: string) {
      return this.http.post<any[]>(`${this.apiUrl}${accertamentoId}/SicerImpegniAccertamenti/dt/${finanziamentoId}/SicerLiquidazioniOrdinativi`,
        dtParams
      );
    }

    associaMassivaSicerImpegnoAccertamento(id: number, pkCpt: string, interventoId: number) {
        return this.http.put<SicerImpegnoAccertamento[]>(`${this.apiUrl}${id}/SicerImpegniAccertamenti/SicerCapitoliBilancio/${pkCpt}/massivo`, null);
    }

    disassociaMassivaSicerImpegnoAccertamento(id: number, pkCapitolo: string) {
        return this.http.delete<SicerImpegnoAccertamento[]>(`${this.apiUrl}${pkCapitolo}/SicerCapitolo/${id}/SicerImpegniAccertamenti/massivo`);
    }
    
    saveMassiveImpAccs(id: number, toSaveImpAccs: SicerImpegnoAccertamento[]) {
        return this.http.post<SicerImpegnoAccertamento[]>(`${this.apiUrl}SicerCapitolo/${id}/SicerImpegniAccertamenti/massivo`, toSaveImpAccs);
    }
    
    deleteMassiveImpAccs(id: number, toDeleteImpAccs: SicerImpegnoAccertamento[]) {
        return this.http.request('delete', `${this.apiUrl}SicerCapitolo/${id}/SicerImpegniAccertamenti/massivo`, { body: toDeleteImpAccs });
    }
}
