import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  templateUrl: "./inframob.component.html",
})
export class InframobComponent {
  constructor(public router: Router) {
    //console.log("asd");
  }
}
