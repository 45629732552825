import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import {  StatoAvanzamentoProgetto } from '../models';


class DataTablesResponse {
    data: StatoAvanzamentoProgetto[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}

@Injectable({
    providedIn: "root"
})
export class StatoAvanzamentoProgettoService {
    private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}StatiAvanzamentoProgetto/`;
    private apiUrlProjects = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}Progetti/`;
    constructor(private http: HttpClient) { }

    getAllDt(dataTablesParameters: any) {
        return this.http.post<DataTablesResponse>(
            `${this.apiUrl}alldt`,
            dataTablesParameters
        );
    }

    getAllDtByProjectId(dataTablesParameters: any,projectId:number) {
        return this.http.post<DataTablesResponse>(
            `${this.apiUrlProjects}${projectId}/StatiAvanzamentoDt`,
            dataTablesParameters
        );
    }

    getAll() {
        return this.http.get<StatoAvanzamentoProgetto[]>(
            `${this.apiUrl}`
        );
    }
    insert(s: StatoAvanzamentoProgetto) {
        return this.http.post<any>(`${this.apiUrl}`, s);
    }
    update(s: StatoAvanzamentoProgetto) {
        return this.http.put<any>(`${this.apiUrl}${s.id}`, s);
    }
    delete(id: number) {
        return this.http.delete<any>(`${this.apiUrl}${id}`);
    }

}
