<div class="container-fluid px-0 bg-celeste">
  <div
    class="text-center p-5 position-absolute top-50 start-50 translate-middle bg-white border border-dark rounded shadow"
    style="z-index: 999999999" *ngIf="">
    <app-loading message="Caricamento in corso..."></app-loading>
  </div>


    <app-header-dashboard></app-header-dashboard>

    <!-- Inizio menu filtri -->
    <div class="row pb-3 pt-1 bg-0083e0 mb-5" style="z-index: 13000" [formGroup]="myForm">
      <!-- Filtro Linee di Finanziamento PNRR/PNC - Missioni -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label>
          <h6 class="mb-0 text-white bold">
            Linee di Finanziamento PNRR / PNC - Missioni
          </h6>
        </label>
        <ng-select [items]="pnrrMissioniItems" formControlName="pnrrMissioni" [multiple]="true" [selectableGroup]="true"
          [selectableGroupAsModel]="false" groupBy="gruppo" [closeOnSelect]="false" bindValue="id"
          bindLabel="descrizione" placeholder="Seleziona una o più linee di finanziamento">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{ index }}" type="checkbox" [checked]="item$.selected" />
            <span title="{{ item.descrizione }}">&nbsp;{{ item.descrizione }}</span>
          </ng-template>
        </ng-select>
      </div>
      <!-- Filtro Linee di Finanziamento PNRR/PNC - Componenti -->

      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label>
          <h6 class="mb-0 text-white bold">
            Linee di Finanziamento PNRR / PNC - Componenti
          </h6>
        </label>
        <ng-select [items]="pnrrComponentiItems" formControlName="pnrrComponenti" [multiple]="true"
          [selectableGroup]="true" [selectableGroupAsModel]="false" groupBy="gruppo" [closeOnSelect]="false"
          bindValue="id" bindLabel="valore" placeholder="Seleziona una o più linee di finanziamento">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{ index }}" type="checkbox" [checked]="item$.selected" />
            <span title="{{ item.valore }}">&nbsp;{{ item.valore }}</span>
          </ng-template>
        </ng-select>
      </div>
      <!-- Filtro Linee di Finanziamento PNRR/PNC - Linee -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label>
          <h6 class="mb-0 text-white bold">
            Linee di Finanziamento PNRR / PNC - Linee
          </h6>
        </label>
        <ng-select [items]="pnrrLineeItems" formControlName="pnrrLinee" [multiple]="true" [selectableGroup]="true"
          [selectableGroupAsModel]="false" groupBy="gruppo" [closeOnSelect]="false" bindValue="id" bindLabel="valore"
          placeholder="Seleziona una o più linee di finanziamento">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{ index }}" type="checkbox" [checked]="item$.selected" />
            <span title="{{ item.valore }}">&nbsp;{{ item.valore }}</span>
          </ng-template>
        </ng-select>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label for="progetto">
          <h6 class="mb-0 text-white bold">Progetto</h6>
        </label>
        <ng-select bindValue="id" bindLabel="descrizioneEstesa" formControlName="progetto"
          placeholder="Seleziona progetto" [items]="progettiItems">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizione }}">
              {{ item.codice }} - {{ item.descrizione }}
            </div>
          </ng-template>
        </ng-select>
      </div>

      <!-- Filtro -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label title="intervento">
          <h6 class="mb-0 text-white bold">
            Intervento <small>(min 3 caratteri)</small>
          </h6>
        </label>
        <app-completer [cpOptions]="completerParams" (onSelectEmitter)="setIntervention($event)"
          (keyup)="setIntervention($event)">
        </app-completer>
      </div>

      <!-- Filtro Anno -->
      <div class="col-xl-1 col-lg-2 col-md-6 pt-1">
        <label title="anno">
          <h6 class="mb-0 text-white bold">Anno</h6>
        </label>
        <ng-select formControlName="anno" bindValue="item" bindLabel="item" placeholder="Seleziona anno"
          [items]="anniItems">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item }}">{{ item }}</div>
          </ng-template>
        </ng-select>
      </div>

      <!-- Filtro sogg attuatore -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label title="soggAttuatore">
          <h6 class="mb-0 text-white bold">Soggetto Attuatore</h6>
        </label>
        <ng-select formControlName="soggAttuatore" bindValue="id" bindLabel="denominazione"
          placeholder="Seleziona soggetto attuatore" [items]="soggettiAttuatoriItems">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.denominazione }}">{{ item.denominazione }}</div>
          </ng-template>
        </ng-select>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 pt-1 align-self-end align-items-end">
        <div class="d-flex align-self-end align-items-end">
          <!-- Bottone Cerca -->
          <div class="col-lg-6 pl-0 align-self-end align-items-end">
            <button [disabled]="showLoading" type="button" class="mt-1 btn btn-info btn-block" (click)="doSearch()">
              Cerca
              <img alt="loading" *ngIf="showLoading" class="pl-3 float-right"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </button>
          </div>

          <!-- Bottone Esporta -->
          <div class="col-lg-6 pr-0 align-self-end align-items-end">
            <button [disabled]="showLoading" type="button" class="mt-1 btn btn-info btn-block" (click)="doExcel()">
              Esporta
              <img alt="loading" *ngIf="showLoading" (click)="doExcel()" class="pl-3 float-right"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fine menu filtri -->

    <ng-container>
      <div class="row">
        <div class="col-lg-12 col-xl-4">
          <div class="bg-white shadow-sm p-3 mb-3 rounded">
            <app-chart-tot-spesa-effettiva-spesa-prevista [percentualeTotProgetti]="
                percentualeTotaleProgettiImpegnatoPrevisto
              " [label]="'Totale Spesa Impegnata su Spesa Prevista'"></app-chart-tot-spesa-effettiva-spesa-prevista>
          </div>

          <div class="bg-white shadow-sm p-3 mb-3 rounded">
            <app-chart-tot-spesa-effettiva-spesa-prevista [percentualeTotProgetti]="
                percentualeTotaleProgettiEffettivoImpegnato
              " [label]="'Totale Spesa Effettiva su Spesa Impegnata'"></app-chart-tot-spesa-effettiva-spesa-prevista>
          </div>
        </div>
        <div class="col-lg-12 col-xl-8">
          <div class="bg-white shadow-sm p-3 mb-4 rounded">
            <h4>Rendicontazione Spese</h4>
            <div class="table-responsive table-wrapper-scroll-y scrollbar-y scrollbar-x" style="max-height: 82vh;">
              <table class="table table-sm table-hover small">
                <thead>
                  <tr>
                    <td></td>
                    <th scope="col">Codice</th>
                    <th scope="col">Importo Previsto</th>
                    <th scope="col">Importo Impegnato</th>
                    <th scope="col">Percentuale (impegnato/previsto)</th>
                    <th scope="col">Importo Effettivo</th>
                    <th scope="col">Percentuale (effettivo/impegnato)</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let progetto of interventiSicerContabilita">
                    <ng-container [ngTemplateOutlet]="templateProgetto"
                      [ngTemplateOutletContext]="{ progetto: progetto }">
                    </ng-container>
                  </ng-container>
                </tbody>
                <tfoot>
                  <tr class="bg-dark text-white">
                    <td></td>
                    <th scope="col">Totale</th>
                    <th scope="col">
                      {{
                      printValueAsCurrency(sommaTotaleImportoPrevistoProgetti)
                      }}
                    </th>
                    <th scope="col">
                      {{
                      printValueAsCurrency(sommaTotaleImportoImpegnatoProgetti)
                      }}
                    </th>
                    <td>
                      <div class="bg-dark"></div>
                    </td>
                    <th scope="col">
                      {{
                      printValueAsCurrency(sommaTotaleImportoEffettivoProgetti)
                      }}
                    </th>
                    <td>
                      <div class="bg-dark"></div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Inizio target e milestone -->

    <div class="row">
      <div class="col-lg-12">
        <div class="bg-white shadow-sm p-3 mb-3 rounded">
          <app-chart-avanzamento-mt></app-chart-avanzamento-mt>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="bg-white shadow-sm p-3 mb-3 rounded">
          <app-pnrr-target-milestone></app-pnrr-target-milestone>
        </div>
      </div>
    </div>

    <!-- Fine target e milestone -->

</div>

<ng-template #templateProgetto let-progetto="progetto">
  <tr>
    <td class="table-celeste">
      <button class="btn btn-link text-dark py-0" type="button" [attr.data-target]="'.progetto_' + progetto.id"
        aria-expanded="false" (click)="caricaAnnualitaEInterventi(progetto.id)">
        <i class="fas fa-chevron-down"></i>
      </button>
    </td>
    <th class="table-celeste" scope="row">
      Progetto {{ progetto.codiceIntervento }}
    </th>
    <th class="table-celeste" scope="row">
      {{ printValueAsCurrency(progetto.sommaImportoPrevisto) }}
    </th>
    <th class="table-celeste" scope="row">
      {{ printValueAsCurrency(progetto.sommaImportoImpegnato) }}
    </th>
    <td class="table-celeste">
      <div class="progress mt-1 position-relative">
        <div class="position-absolute bold start-50 translate-middle top-50"
          style="z-index: 900000; color: #000000 !important">
          {{ progetto.percentualeImpegnatoPrevisto }} %
        </div>
        <div class="progress-bar bg-success opacity" role="progressbar"
          [ngStyle]="{ width: progetto.percentualeImpegnatoPrevisto + '%' }"
          [attr.aria-valuenow]="progetto.percentualeImpegnatoPrevisto"
          [attr.title]="progetto.percentualeImpegnatoPrevisto + '%'" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </td>
    <th class="table-celeste" scope="row">
      {{ printValueAsCurrency(progetto.sommaImportoEffettivo) }}
    </th>

    <td class="table-celeste">
      <div class="progress mt-1 position-relative">
        <div class="position-absolute bold start-50 translate-middle top-50"
          style="z-index: 900000; color: #000000 !important">
          {{ progetto.percentualeEffettivoImpegnato }} %
        </div>
        <div class="progress-bar bg-success opacity" role="progressbar"
          [ngStyle]="{ width: progetto.percentualeEffettivoImpegnato + '%' }"
          [attr.aria-valuenow]="progetto.percentualeEffettivoImpegnato"
          [attr.title]="progetto.percentualeEffettivoImpegnato + '%'" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </td>
  </tr>

  <!-- Inizio annualità per progetto -->

  <ng-container *ngIf="
      annualitaProgettoSelezionato &&
      annualitaProgettoSelezionato['prog_' + progetto.id]
    ">
    <ng-container *ngFor="let anno of annualitaProgettoSelezionato['prog_' + progetto.id]">
      <tr [hidden]="!arrExpandStatus['prog_' + progetto.id]" class=" table-grigio small progetto_{{ progetto.id }}"
        *ngIf="anno.annoImporto !== null">
        <td></td>
        <td>Annualità {{ anno.annoImporto }}</td>
        <td>
          {{
          anno.importo
          ? printValueAsCurrency(anno.importo)
          : printValueAsCurrency(0)
          }}
        </td>
        <td>
          {{
          anno.importoImpegno
          ? printValueAsCurrency(anno.importoImpegno)
          : printValueAsCurrency(0)
          }}
        </td>
        <td>
          <div class="progress mt-1 position-relative">
            <div class="position-absolute bold start-50 translate-middle top-50"
              style="z-index: 900000; color: #000000 !important">
              {{ anno.percentualeImpegnatoPrevisto }} %
            </div>
            <div class="progress-bar bg-info opacity" role="progressbar"
              [ngStyle]="{ width: anno.percentualeImpegnatoPrevisto + '%' }"
              [attr.aria-valuenow]="anno.percentualeImpegnatoPrevisto"
              [attr.title]="anno.percentualeImpegnatoPrevisto + '%'" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </td>
        <td>
          {{
          anno.importoLiquidazioni
          ? printValueAsCurrency(anno.importoLiquidazioni)
          : printValueAsCurrency(0)
          }}
        </td>

        <td>
          <div class="progress mt-1 position-relative">
            <div class="position-absolute bold start-50 translate-middle top-50"
              style="z-index: 900000; color: #000000 !important">
              {{ anno.percentualeEffettivoImpegnato }} %
            </div>
            <div class="progress-bar bg-info opacity" role="progressbar"
              [ngStyle]="{ width: anno.percentualeEffettivoImpegnato + '%' }"
              [attr.aria-valuenow]="anno.percentualeEffettivoImpegnato"
              [attr.title]="anno.percentualeEffettivoImpegnato + '%'" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </td>
        <!-- Fine annualità per progetto  -->
      </tr>
    </ng-container>
  </ng-container>
  <!-- Inizio Interventi per progetto -->

  <ng-container *ngIf="
      interventiProgettoSelezionato &&
      interventiProgettoSelezionato['prog_' + progetto.id]
    ">
    <ng-container *ngFor="
        let intervento of interventiProgettoSelezionato['prog_' + progetto.id]
      ">
      <tr [hidden]="!arrExpandStatus['prog_' + progetto.id]" class=" table-grigio small progetto_{{ progetto.id }}"
        *ngIf="intervento.codiceIntervento !== null">
        <td>
          <button class="btn btn-link text-dark py-0" type="button" [attr.data-target]="'.intervento_' + progetto.id"
            aria-expanded="false" (click)="caricaAnnualitaInterventoSelezionato(intervento)">
            <i class="fas fa-chevron-down"></i>
          </button>
        </td>
        <th scope="row">Intervento {{ intervento.codiceIntervento }}</th>

        <!-- {{
          anno.importo
            ? printValueAsCurrency(anno.importo)
            : printValueAsCurrency(0)
        }} -->

        <th scope="row">
          {{
          intervento.sommaImportoPrevisto !== 0
          ? printValueAsCurrency(intervento.sommaImportoPrevisto)
          : printValueAsCurrency(0)
          }}
        </th>
        <th scope="row">
          {{
          intervento.sommaImportoImpegnato !== 0
          ? printValueAsCurrency(intervento.sommaImportoImpegnato)
          : printValueAsCurrency(0)
          }}
        </th>
        <td>
          <div class="progress mt-1 position-relative">
            <div class="position-absolute bold start-50 translate-middle top-50"
              style="z-index: 900000; color: #000000 !important">{{ intervento.percentualeImpegnatoPrevisto }} %</div>

            <div class="progress-bar bg-success opacity" role="progressbar" [ngStyle]="{
                width: intervento.percentualeImpegnatoPrevisto + '%'
              }" [attr.aria-valuenow]="intervento.percentualeImpegnatoPrevisto"
              [attr.title]="intervento.percentualeImpegnatoPrevisto + '%'" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </td>
        <th scope="row">
          {{
          intervento.sommaImportoEffettivo !== 0
          ? printValueAsCurrency(intervento.sommaImportoEffettivo)
          : printValueAsCurrency(0)
          }}
        </th>
        <td>
          <div class="progress mt-1 position-relative">
            <div class="position-absolute bold start-50 translate-middle top-50"
              style="z-index: 900000; color: #000000 !important">
              {{ intervento.percentualeEffettivoImpegnato }} %
            </div>
            <div class="progress-bar bg-success opacity" role="progressbar" [ngStyle]="{
                width: intervento.percentualeEffettivoImpegnato + '%'
              }" [attr.aria-valuenow]="intervento.percentualeEffettivoImpegnato"
              [attr.title]="intervento.percentualeEffettivoImpegnato + '%'" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </td>
      </tr>
      <!-- Annualita per interventi -->

      <ng-container *ngFor="
          let annualitaIntervento of annualitaInterventoSelezionato[
            'int_' + intervento.id
          ]
        ">
        <tr [hidden]="!arrExpandStatus['prog_' + intervento.id]"
          class=" table-grigio small intervento_{{ progetto.id }}">
          <td></td>
          <td>Annualita {{ annualitaIntervento.annoImporto }}</td>
          <td>
            {{
            annualitaIntervento.importo !== null
            ? printValueAsCurrency(annualitaIntervento.importo)
            : printValueAsCurrency(0)
            }}
          </td>
          <td>
            {{
            annualitaIntervento.importoImpegno !== null
            ? printValueAsCurrency(annualitaIntervento.importoImpegno)
            : printValueAsCurrency(0)
            }}
          </td>
          <td>
            <div class="progress mt-1 position-relative">
              <div class="position-absolute bold start-50 translate-middle top-50"
                style="z-index: 900000; color: #000000 !important">{{ annualitaIntervento.percentualeImpegnatoPrevisto
                }} %</div>
              <div class="progress-bar bg-info opacity" role="progressbar" [ngStyle]="{
                  width: annualitaIntervento.percentualeImpegnatoPrevisto + '%'
                }" [attr.aria-valuenow]="
                  annualitaIntervento.percentualeImpegnatoPrevisto
                " [attr.title]="
                  annualitaIntervento.percentualeImpegnatoPrevisto + '%'
                " aria-valuemin="0" aria-valuemax="100">

              </div>
            </div>
          </td>
          <td>
            {{
            annualitaIntervento.importoLiquidazioni !== null
            ? printValueAsCurrency(annualitaIntervento.importoLiquidazioni)
            : printValueAsCurrency(0)
            }}
          </td>
          <td>
            <div class="progress mt-1 position-relative">
              <div class="position-absolute bold start-50 translate-middle top-50"
                style="z-index: 900000; color: #000000 !important"> {{ annualitaIntervento.percentualeEffettivoImpegnato
                }} % </div>
              <div class="progress-bar bg-info opacity" role="progressbar" [ngStyle]="{
                  width: annualitaIntervento.percentualeEffettivoImpegnato + '%'
                }" [attr.aria-valuenow]="
                  annualitaIntervento.percentualeEffettivoImpegnato
                " [attr.title]="
                  annualitaIntervento.percentualeEffettivoImpegnato + '%'
                " aria-valuemin="0" aria-valuemax="100">

              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
