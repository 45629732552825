<div class="p-5 container-xl text-center" [hidden]="loadDataCounter<=0">
  <app-loading message="Caricamento in corso..."></app-loading>
</div>


<div class="position-sticky sticky-top pt-4 pb-4 elemento-aggiorna shadow-custom test"

  [ngClass]="{'sticky-top-custom-modal': isInModal,
  'sticky-top-custom':!isInModal ,

  'sticky-top-custom-sal': tabsOnOneRow }"
  [hidden]="loadDataCounter>0">
  <div class=" row  justify-content-center ">


    <div class=" text-center test2"
      [ngClass]="{ ' col-lg-4 col-md-6': !(gotoBtnOptions && gotoBtnOptions.showIcon) , 'col-lg-2' : gotoBtnOptions && gotoBtnOptions.showIcon}">
      <button (click)="gotoBackBtnClick()" *ngIf="gotoBtnOptions && gotoBtnOptions.show" [disabled]="loading"
        class="btn btn-secondary btn-block font-weight-bold"
        type="button">{{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}&nbsp;<i class="fas fa-times"
          *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i></button>
    </div>
  </div>

</div>
<div class="row mx-0">
  <div class="titolo col-12">
    <h3 class="mt-3">Documentazione</h3>
  </div>
</div>

<div class="mt-5">

  <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;"
    [dtOptions]="dtOptions">

    <ng-template #noteProtocolloTemplate let-row="row" let-value="value">
        <span class="help"
          title="{{value}}">{{trimText(value,100)}}</span>
      </ng-template>

    <ng-template #oggettoProtocolloTemplate let-row="row" let-value="value">
      <span class="help" title="{{value}}">{{trimText(value,100)}}</span>
    </ng-template>
    <ng-template #tipoTemplate let-row="row" let-value="value">
      <span>{{printTipologiaCell(row,value)}}</span>
    </ng-template>
    <ng-template #movimentoTemplate let-row="row" let-value="value">
      <span>{{printMovimentoCell(row,value)}}</span>
    </ng-template>
    <ng-template #tipologiaEntitaTemplate let-row="row" let-value="value">
      <span>{{printTipologiaEntitaTemplateCell(row,value)}}</span>
    </ng-template>
    <ng-template #numeroDocumentoTemplate let-row="row" let-value="value">
      <ng-container *ngIf="row.tipologiaEntita=='PROVVEDIMENTO'">{{value}}</ng-container>
      <ng-container *ngIf="row.tipologiaEntita=='SICER'">
        <a class="pointer font-weight-bold text-0083e0 " title="scarica allegato" href="javascript:;"
          (click)="downloadSicer(row)">{{value}}
        </a>
      </ng-container>

      <ng-container *ngIf="row.tipologiaEntita=='PROSA'">
        <a class="pointer font-weight-bold text-0083e0" title="mostra allegati" data-toggle="collapse" role="button"
          aria-expanded="false" [attr.aria-controls]="'.dtw_tr_collapse_prosa_' + row.id"
          [attr.data-target]="'.dtw_tr_collapse_prosa_' + row.id" >
          <i class="fas fa-list"></i>&nbsp;{{row.numero}}</a>
        <br />
        <div id="documenti-prosa-{{row.numero}}" class="collapse {{'dtw_tr_collapse_prosa_' + row.id}}" >

          <ul class="pl-2">
            <ng-container *ngFor="let allegato of row.allegatiArray">
            <li class="text-lowercase"  >
              <a href="javascript:;" (click)="downloadDocumentoProsa(allegato.codice, allegato.nome)"
               >{{allegato.nome}}</a>
            </li>
            </ng-container>
          </ul>
        </div>
      </ng-container>
      <ng-container *ngIf="row.tipologiaEntita =='GENERICO'">
        <a class=" border-0 text-center" (click)="downloadDocument(row)" data-toggle="tooltip" data-placement="top"
          title="scarica">&nbsp;&nbsp;&ensp;<i class="fas fa-download pointer"></i></a>
      </ng-container>
    </ng-template>

  </ngx-datatable-wrapper>
</div>

<div class="mb-5">

  <accordion [isAnimated]="true">
    <accordion-group heading="Inserisci / Visualizza Documentazione e Provvedimenti">
      <documentazione-component [isInModal]="interventoData" [finanziamentoId]="_finanziamentoIdForDocumentComponent"
        [interventoId]="_interventoIdForDocumentComponent"
        [gotoBtnOptions]="{show:false,label:gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}"
        (onSubmitEmitter)="documentazioneChanged($event)"
        (associaProvvedimentiChangedEmitter)="associaProvvedimentiChanged($event)" #documentazioneComponent>
      </documentazione-component>

    </accordion-group>
  </accordion>




</div>
