import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    TemplateRef,
    Input,
    ElementRef,
    EventEmitter,
    Output
} from "@angular/core";
import { registerLocaleData, CurrencyPipe } from '@angular/common';
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { InterventoService, CategoriaSalService,ProtocolloService } from '../../services';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from '../../../../components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component'
import localeIt from '@angular/common/locales/it';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { GenericSelectionModalComponent } from '../genericSelectionModal/generic-selection-modal.component';
import { AppConfigService } from "src/app/services/app-config.service";
import * as FileSaver from "file-saver";
registerLocaleData(localeIt, 'it');
defineLocale('it', itLocale);
import {IsDirtyHelper} from "../../../../helpers/is-dirty.helper"
import { AuthService } from "src/app/services";

@Component({
    selector: 'intervento-sal-component',
    templateUrl: './intervento-sal.component.html',
    styleUrls: ['./intervento-sal.component.css']
})
export class InterventoSalComponent implements OnInit, OnDestroy {
section:string='Interventi';
    public uploadFileUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.uploadFile}`;

    public mask: any = {
        mask: 'KK €',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            }
        }
    };

    ngOnDestroy(): void {
    }

    submitted: boolean = false;
    loading: boolean = false;
    readOnlyForm:boolean = false;

    categorieSal = [];

    private _interventoId: number;

    loadDataCounter:number = 0;

    reloadData(){
        this.loadDataCounter = 0;
        if (this._interventoId)this.getInterventoData(this._interventoId);
    }

    @Output() onSubmitOk: EventEmitter<any> = new EventEmitter();

    @Input()
    set interventoId(value) {
        //console.log(`inside set finanziamentoId= ${value}`);
        this._interventoId = value;
        if (!value) return;
        //this.getInterventoData(value);
    }
    get interventoId() {
       // console.log(`inside get interventoId= ${this._interventoId}`);
        return this._interventoId;
    }


    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnDetailTemplate', { static: true })
    public btnDetailTemplate: TemplateRef<any>;




    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    @ViewChild('salGiustificativoTemplate', { static: true })
    public salGiustificativoTemplate: TemplateRef<any>;



    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    templateList = [];

    dtOptions: any;

    rows: any[];
    columns = [
        {
            name: '', prop: 'id', sortable: true, filtrable: false, maxWidth: 0, width: 0, visible: false
            // 'buttonsTemplate'
        }

        , {
            name: 'categoria'
            , prop: 'categoria.titolo'
            , sortable: true
            , filtrable: true
            , flexGrow: 3
            , minWidth: 120
        }
        , {
            name: 'importo'
            , prop: 'importo'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'currencyValueTemplate'
            , flexGrow: 2
            , minWidth: 120
        }
        , {
            name: 'data giustificativo<br />o competenza SAL/Altro'
            , prop: 'dataLavori'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'dateValueTemplate'
            , flexGrow: 2
            , minWidth: 120
        }
        , {
            name: 'descrizione'
            , prop: 'descrizione'
            , sortable: true
            , filtrable: true
            , flexGrow: 3
            , minWidth: 120
        }
        ,{
            name:'SAL/Altro /<br />Giustificativo'
            ,prop:'anticipazioneASoggettoAttuatore'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            ,cellTemplate:'salGiustificativoTemplate'
            , minWidth: 120
        }
        , {
            name: 'modifica'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60

        }
        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60

        }
    ];



    modalTitle: string = '';
    modalButtonLabel: string = '';

    modalRef: BsModalRef;

    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };



    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;


    editForm: FormGroup;

    constructor(
        private toastr: ToastService
        , private modalService: BsModalService
        , private localeService: BsLocaleService
        , private interventoService: InterventoService
        , private formBuilder: FormBuilder
        , private el: ElementRef
        , private categoriaSalService: CategoriaSalService
        ,private isDirtyHelper:IsDirtyHelper
        , private protocolloService:ProtocolloService
        ,private authService:AuthService
    ) {
        this.localeService.use("it");
        this.dtOptions = {
            rows: this.rows
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: false// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: false// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: false
        };
        this.editForm = this.initializeFormGroup();
        this.getCategorieSal();


    }


    setIsDirty(value:boolean){
        this.isDirtyHelper.isDirty = value;
    }

    initializeFormGroup(): FormGroup {
        let frm= this.formBuilder.group({
            id: [null],
            data: [null],
            descrizione: [null]
            , categoriaId: [null, [Validators.required]],
            importo: [null, [Validators.required]]
            , dataLavori: [null, [Validators.required]]
            , dataQuietanza: [null]
            , dataEmissioneCertificatoPagamento: [null]
            ,anticipazioneASoggettoAttuatore:[null,Validators.required]
        });
        frm.valueChanges.subscribe(val=>
            this.setIsDirty(frm.dirty)
            );
        return frm;
    }



    ngOnInit() {
        this.templateList = [
            { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }
            , { name: 'btnEditTemplate', template: this.btnEditTemplate }
            ,{name:'btnDetailTemplate',template:this.btnDetailTemplate}
            ,{name:'salGiustificativoTemplate',template:this.salGiustificativoTemplate}
        ];
        window.scroll(0, 0);

        this.frm_protocolli = this.formBuilder.group(
            {protocollo_anno:[null]
            ,protocollo_numero:[null]}
        );
        this.editNoteProtocollo = this.formBuilder.group(
            {
                note_protocollo:[null]
                ,id:[null]
            }
        );
    }



    getSalByInterventoId(id: number) {
        this.loadDataCounter++;
        this.interventoService.getSal(id).subscribe(
            result => {
                this.ngxDatatableWrapper.setInitialData(result);
                this.loadDataCounter--;
            }
        );
    }
currentIntervento:any;
    getInterventoData(id: number) {
        this.loadDataCounter++;
        let that = this;
        this.interventoService.get(id).subscribe(
            result => {
                let status = (result.validazione?(result.validazione.validationStatus?result.validazione.validationStatus:0):0).toString().toUpperCase();
                /*that.readOnlyForm = (status =='1'
                || status =='APPROVED'
                || status =='WAITING_FOR_APPROVAL_FIRST_LEVEL' || status =='4'
                || status =='WAITING_FOR_APPROVAL_SECOND_LEVEL' || status =='5'
                || status =='TERMINATO'
                || result.isReadOnly
                ); */
                that.readOnlyForm =
                ((status !='APPROVED' && status != '1')
                && !result.allowSalWithoutValidation ) || result.isReadOnly || !this.canEdit();
                this.currentIntervento = result;
                this.columns[7].visible = true;
                this.columns[6].name = 'modifica';
                this.columns[6].cellTemplate = 'btnEditTemplate';
                if (this.readOnlyForm){
                    this.columns[7].visible = false;
                    //this.columns[8].visible = false;
                    this.columns[6].name = 'dettaglio';
                    this.columns[6].cellTemplate = 'btnDetailTemplate';
                }

                this.ngxDatatableWrapper.dtOptions.columns = this.columns;
                this.ngxDatatableWrapper.dtOptions = this.ngxDatatableWrapper.dtOptions;
                this.ngxDatatableWrapper.refresh();
                this.getSalByInterventoId(this._interventoId);
                this.loadDataCounter--;
            }
        );
    }


    getCategorieSal() {
        this.categoriaSalService.getAll().subscribe(
            result => {
                this.categorieSal = result;//id e titolo
            }
        );

    }




//isSal == 0 -> Giustificativo
//isSal==1 -> sal, ex sal a SA
    openAddModal(isSal:number) {
        //// this.selectedInterventoData = JSON.stringify({interventoId:null,projectId:this.projectId});
        this.modalTitle = 'Nuovo SAL';
        if(isSal<=0)this.modalTitle = 'Nuovo Giustificativo';
        this.erogazioniList = [];
        this.documenti = [];
        this.salList = [];
        this.editForm.reset();
        this.frm_protocolli.reset();
        this.modalButtonLabel = 'Inserisci';
        this.editForm.controls.anticipazioneASoggettoAttuatore.enable();
        this.editForm.controls.anticipazioneASoggettoAttuatore.setValue(isSal>=1?'true':'false');
        this.modalRef = this.modalService.show(this.templateEditAdd, this.config);
        this.setIsDirty(false);
        this.loadProtocolliTable();
        this.currentNewProtocolloId = -1;
        this.protocolli = [];
    }



    onEdit(row, notOpenModal?: boolean) {
        //  this.selectedInterventoData = JSON.stringify({interventoId:row.id,projectId:this.projectId});
        this.modalTitle = 'Modifica SAL/Altro';
        this.modalButtonLabel = 'Aggiorna';
        this.erogazioniList = row.erogazioni;
        this.documenti = row.documenti;
        this.salList = row.afronteDeiSal;
        this.frm_protocolli.reset();
        let data = null;
        /*if (row.data){
            data = moment(row.data).toDate();
            data.setHours(data.getHours() - data.getTimezoneOffset() / 60);
        }
        moment(row.data).toDate();
        data.setHours(data.getHours() - data.getTimezoneOffset() / 60);*/
        let dataLavori = null;
        if (row.dataLavori) {
            dataLavori = moment(row.dataLavori).toDate();
            dataLavori.setHours(dataLavori.getHours() - dataLavori.getTimezoneOffset() / 60);
        }
        let dataQuietanza = null;
        if (row.dataQuietanza) {
            dataQuietanza = moment(row.dataQuietanza).toDate();
            dataQuietanza.setHours(dataQuietanza.getHours() - dataQuietanza.getTimezoneOffset() / 60);
        }

        let dataEmissioneCertificatoPagamento = null;
        if (row.dataEmissioneCertificatoPagamento) {
            dataEmissioneCertificatoPagamento = moment(row.dataEmissioneCertificatoPagamento).toDate();
            dataEmissioneCertificatoPagamento.setHours(dataEmissioneCertificatoPagamento.getHours() - dataEmissioneCertificatoPagamento.getTimezoneOffset() / 60);
        }
        this.editForm.setValue(
            {
                id: row.id
                , categoriaId: row.categoria.id
                , importo: '' + row.importo
                , data: data
                , descrizione: row.descrizione
                , dataLavori: dataLavori
                , dataQuietanza: dataQuietanza
                , dataEmissioneCertificatoPagamento: dataEmissioneCertificatoPagamento
                ,anticipazioneASoggettoAttuatore:row.anticipazioneASoggettoAttuatore!=null?row.anticipazioneASoggettoAttuatore.toString():null
            }
        );
        this.editForm.enable();
        this.editForm.controls.anticipazioneASoggettoAttuatore.disable();
        if(this.readOnlyForm)this.editForm.disable();
        if (!notOpenModal) this.modalRef = this.modalService.show(this.templateEditAdd, this.config);
        this.setIsDirty(false);
        this.loadProtocolliTable();
        this.currentNewProtocolloId = -1;
        this.protocolli = [];
    }

    onDelete(row) {
        console.log(row);
        if (!confirm(`Stai per eliminare il SAL/Altro "${row.data} - ${row.categoria.titolo}", proseguire?`)) return;
        this.loading = true;
        let myService = this.interventoService;
        myService.deleteSal(this.interventoId, row.id).subscribe(
            result => {
                this.loading = false;
                this.ngxDatatableWrapper.deleteRow(row);
                this.toastr.success('SAL/Altro eliminato con successo', null, {
                    timeOut: 2000, disableTimeOut: false
                });
                this.onSubmitOk.emit(row);
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile eliminare il SAL/Altro: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                console.log('error');
            });
    }


    closeModal() {
        if(!this.isDirtyHelper.checkIsDirty())return false;
        this.modalRef.hide();
    }

    onSubmit() {
        this.submitted = true;
        this.editForm.updateValueAndValidity();
        for (let c in this.editForm.controls) {
            this.editForm.controls[c].updateValueAndValidity();
        }
        let errors = false;
        for (let c in this.editForm.controls) {
            errors = errors || this.editForm.controls[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.editForm.controls[c].errors)
            );
        }
        if (this.editForm.invalid || errors) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.editForm.controls) {
                if (this.editForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.editForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.editForm.get(el)["controls"]) {
                            if (this.editForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }

        const obj = this.editForm.value;
        obj.anticipazioneASoggettoAttuatore = this.editForm.getRawValue().anticipazioneASoggettoAttuatore;
        obj.erogazioni = this.erogazioniList;
        obj.data = this.editForm.controls.data.value&& this.editForm.controls.data.value!=''?  moment(this.editForm.controls.data.value, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'):null;//.utc(false).toDate();

        obj.dataLavori = obj.dataLavori && obj.dataLavori != '' ? moment(this.editForm.controls.dataLavori.value, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss') : null;//.utc(false).toDate();
        obj.dataQuietanza = obj.dataQuietanza && obj.dataQuietanza != '' ? moment(this.editForm.controls.dataQuietanza.value, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss') : null;//.utc(false).toDate();
        obj.dataEmissioneCertificatoPagamento = obj.dataEmissioneCertificatoPagamento && obj.dataEmissioneCertificatoPagamento != '' ? moment(this.editForm.controls.dataEmissioneCertificatoPagamento.value, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss') : null;//.utc(false).toDate();
        obj.documenti = this.documenti.filter(x => !(x.id < 0 && x['status'] == 'deleted'));
        //TODO: salvare documenti
        obj.categoria = this.categorieSal.find(x => x.id == obj.categoriaId);
        obj.interventoId = this.interventoId;
        this.loading = true;
        obj.afronteDeiSal = this.salList;
        this.interventoService.saveSal(obj).subscribe(res => {
            //elimino i documenti di prosa
            let id = res.sal.id;
            this.protocolliDeleted.forEach(
                x=>{
                    this.protocolloService.delete(id,'SAL',x.id).subscribe(r=>this.loadProtocolliTable() );
                }
            );

            this.allProtocolli
                .filter(x=>x['status']=='NEW')
                .forEach(
                    x=>this.protocolloService.insert(id,'SAL',x).subscribe( r=>this.loadProtocolliTable())
                    );

            this.allProtocolli
                        .filter(x=>x['status']=='EDIT')
                        .forEach(x=>
                            this.protocolloService.saveNote( x.id,x.note).subscribe(r=>this.loadProtocolliTable() )
                        );






            this.loading = false;
            this.submitted = false;
            this.toastr.success("SAL/Altro salvato con successo!");
            this.setIsDirty(false);
            if (res.warningMsg && res.warningMsg !=''){
                this.toastr.warning(res.warningMsg, null, {
                    timeOut: 2000, disableTimeOut: false
                });
            }
            if (!obj.id) {
                //new row
                this.ngxDatatableWrapper.addRow(res.sal);
                this.onEdit(res.sal, true);
            }
            else {
                this.onEdit(res.sal, true);
                this.ngxDatatableWrapper.updateRow(res.sal);
            }
            this.onSubmitOk.emit(res.sal);

        }
        , error => {
            this.loading = false;
            this.toastr.error(`Impossibile salvare il SAL/Altro: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                timeOut: 2000, disableTimeOut: false
            });
            console.log('error');
        }
        );
    }

    erogazioniList = [];
    modalRefSelectErogazioni: BsModalRef;

    removeErogazioni(item) {
        console.log(`inside removeSal id=${item.id}`);
        this.erogazioniList = this.erogazioniList.filter(x => x.id != item.id);
        this.toastr.success('Erogazione rimosso con successo', null, {
            timeOut: 2000, disableTimeOut: false
        });
        this.setIsDirty(true);
    }


    openModalErogazioni() {
        let that = this;
        let modalDefinition = {
            columns: [
                { title: "ID", data: "id", searchable: true, orderable: true, width: '40px' },
                { title: "tipologia", data: "tipologia.titolo", searchable: true, orderable: true },
                {
                    title: "importo", data: "importo", searchable: true, orderable: true
                    , render: function (data, type, row, meta) {
                        return that.printValueAsCurrency(data)
                    }
                },
                {
                    title: "data<br />prevista", data: "dataPrevista", searchable: true, orderable: true
                    , render: function (data, type, row, meta) {
                        return that.printValueAsDate(data)
                    }
                },
                {
                    title: "data<br />effettiva", data: "dataEffettiva", searchable: true, orderable: true
                    , render: function (data, type, row, meta) {
                        return that.printValueAsDate(data)
                    }
                }
            ],
            ajax: this.getErogazioniDt
            , pageTitle: 'Seleziona le erogazioni'
        };

        let modalDefinitionNgx = {
            rows: []
            , totalElements: 0
            , columns: [
                {
                    name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
                    , resizeable: false
                }

                , {
                    name: 'tipologia'
                    , prop: 'tipologia.titolo'

                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 100

                }
                , {
                    name: 'importo'
                    , prop: 'importo'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 2
                    , minWidth: 100
                }
                , {
                    name: 'data<br />prevista'
                    , prop: 'dataPrevista'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , cellTemplate: 'dateValueTemplate'
                    , flexGrow: 1
                    , minWidth: 100
                }
                , {
                    name: 'data<br />effettiva'
                    , prop: 'dataEffettiva'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , cellTemplate: 'dateValueTemplate'
                    , flexGrow: 1
                    , minWidth: 100
                }
            ]
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , ajax: this.getErogazioniDtNgx
            , scrollbarH: "true"
        };


        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: 'modal-xl'
        };
        const initialState = { modalDefinition: modalDefinition, dtOptionsNgx: modalDefinitionNgx };

        this.modalRefSelectErogazioni = this.modalService.show(GenericSelectionModalComponent, Object.assign({}, config, { initialState }));
        this.clearSubscriptions();
        let subscription = this.modalRefSelectErogazioni.content.onSubmitOk.subscribe(
            data => {
                console.log('inside ok of SAL');
                if (!this.erogazioniList) this.erogazioniList = [];
                let existsObj = this.erogazioniList.find(x => x.id == data.id);
                if (existsObj) return;//already added
                this.erogazioniList.push(data);
                this.erogazioniList = Object.assign([]
                    , this.erogazioniList
                );

                this.toastr.success('Erogazione aggiunta con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                this.setIsDirty(true);
            }
        );
        this.modalSubscriptions.push(subscription);
    }
    modalSubscriptions = [];
    clearSubscriptions() {
        this.modalSubscriptions.forEach(x => x.unsubscribe());
        this.modalSubscriptions = [];
    }
    getErogazioniDt = (dataTablesParameters: any, callback: any) => {
        this.interventoService.getErogazioniDt(this.interventoId, dataTablesParameters)
            .subscribe(resp => {
                callback({
                    recordsTotal: resp.recordsTotal,
                    recordsFiltered: resp.recordsFiltered,
                    data: resp.data
                });
            });
    }
    getErogazioniDtNgx = (dataTablesParameters: any, callback: any) => {
        this.interventoService.getErogazioniDt(this.interventoId, dataTablesParameters)
            .subscribe(resp => {
                callback(resp);
            });
    }

    printValueAsDate(value) {
        if (!value || value == '') return '';
        let v = moment(value).toDate();
        //v.setHours(v.getHours() - v.getTimezoneOffset() / 60);
        return moment(v).format('DD/MM/YYYY');
    }
    printValueAsCurrency(value) {
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }
    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
    gotoBackBtnClick() {
        this.gotoBtnFunction.emit(true);
    }


    //gestione files

    documenti = [];
    currentDocumentNewId = 0;

    addDocumento() {
        if (!this.documenti) this.documenti = [];
        this.documenti = [{ id: --this.currentDocumentNewId, filename: '', status: '', originalFileName: '' }, ...this.documenti];
        this.setIsDirty(true);
    }
    removeDocumento(item) {
        if (!this.documenti) return;
        //this.documenti = this.documenti.filter(x=>x.id !=item.id);
        this.documenti.forEach(x => {
            if (x.id == item.id) { x['status'] = 'delete'; }
        });
        this.documenti = [...this.documenti];
        this.setIsDirty(true);
    }
    onFileChanged($event) {
        console.log('onfileChanged');
        this.setIsDirty(true);
    }
    onAfterFileUpload($event) {
        console.log('onAfterFileUpload');
        let filePath = $event.filePath;
        let id = $event.name;
        let originalFileName = $event.originalFileName;
        let document = this.documenti.forEach(x => {
            if (x.id == id) {
                x['filename'] = filePath;
                x['status'] = 'update';
                x['originalFileName'] = originalFileName;
            }
        });
        this.setIsDirty(true);

    }
    onDeleteFile($event) { this.setIsDirty(true);}

    onDownloadFile($event) {
        this.interventoService.downloadSalDocument(this.interventoId, this.editForm.controls.id.value, $event).subscribe(
            res => {
                let filename = $event.originalFileName;
                FileSaver.saveAs(
                    res.body,
                    `${filename}`
                );
            }
        );
    }
    hasDocuments() {
        return this.documenti && this.documenti.filter(x => x['status'] != 'delete').length > 0;
    }

    salList = [];
    salListIsError = false;
    modalRefSelectSal: BsModalRef;



    removeSal(item) {
        console.log(`inside removeSal id=${item.id}`);
        this.salList = this.salList.filter(x => x.id != item.id);
        this.toastr.success('Sal rimosso con successo', null, {
            timeOut: 2000, disableTimeOut: false
        });
        this.setIsDirty(true);
    }

    closeModalSelectSal() {
        this.modalRefSelectSal.hide();
    }

    openModalSAL() {
        //v-03/09/2020: un erogazione può essere associata ad un solo sal

        let that = this;
        let modalDefinition = {
             pageTitle: 'Seleziona i SAL/Altro'
        };

        let modalDefinitionNgx = {
            rows: []
            , totalElements: 0
            , columns: [
                {
                    name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
                    , resizeable: false
                }

                , {
                    name: 'categoria'
                    , prop: 'categoria.titolo'

                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 100

                }
                , {
                    name: 'importo'
                    , prop: 'importo'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 2
                    , minWidth: 100
                }
                , {
                    name: 'data<br /> competenza SAL/Altro'
                    , prop: 'dataLavori'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , cellTemplate: 'dateValueTemplate'
                    , flexGrow: 1
                    , minWidth: 100
                }

            ]
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , ajax: this.getSalDtNgx
            , scrollbarH: "true"
        };

        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: 'modal-xl'
        };
        const initialState = { modalDefinition: modalDefinition, dtOptionsNgx: modalDefinitionNgx };





        this.modalRefSelectSal = this.modalService.show(GenericSelectionModalComponent, Object.assign({}, config, { initialState }));
        this.clearSubscriptions();
        let subscription = this.modalRefSelectSal.content.onSubmitOk.subscribe(
            data => {
                console.log('inside ok of SAL');
                if (!this.salList) this.salList = [];
                if (this.salList.length>0){
                    this.toastr.warning('Giustificativo già associato ad un SAL/Altro', null, {
                        timeOut: 2000, disableTimeOut: false
                    });
                    return;
                }
                let existsObj = this.salList.find(x => x.id == data.id);
                if (existsObj) return;//already added
                this.salList.push(data);
                this.salList = Object.assign([]
                    , this.salList
                );

                this.toastr.success('SAL/Altro aggiunto con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                this.setIsDirty(true);

            }
        );
        this.modalSubscriptions.push(subscription);
    }

    getSalDtNgx = (dataTablesParameters: any, callback: any) => {
        this.interventoService.getSalDt(this.interventoId, dataTablesParameters)
            .subscribe(resp => {
                callback(resp);
            });
    }


/*****PROSA *******/
    frm_protocolli:FormGroup;
    editNoteProtocollo:FormGroup;



    loadProtocolliTable(){
        /*this.loadDataProtocolliEntrata();
        this.loadDataProtocolliUscita();
        this.loadDataProtocolliInterni(); */
        this.loadAllProtocolliData();
        this.protocolliDeleted = [];
    }


    allProtocolli = [];
    get protocolliEntrata(){
        return this.allProtocolli? this.allProtocolli.filter(x=>x.tipoProtocollo=='I'):[];
    }
    get protocolliUscita(){
        return this.allProtocolli? this.allProtocolli.filter(x=>x.tipoProtocollo=='U'):[];
    }
    get protocolliInterni(){
        return this.allProtocolli? this.allProtocolli.filter(x=>x.tipoProtocollo=='D'):[];
    }

    loadAllProtocolliData(){
        let currentId = this.editForm.controls.id.value;
        this.allProtocolli = [];
        if (!currentId) return;
        this.protocolloService.getAllData(currentId,'SAL','').subscribe(result=>this.allProtocolli = result);
    }


    protocolli =[];

    currentNewProtocolloId = -1;
    protocolliDeleted = [];

    acquisisciProtocolli(){
        let anno = this.frm_protocolli.controls.protocollo_anno.value;
        let numero = this.frm_protocolli.controls.protocollo_numero.value;

      /*this.protocolli =
      [{
		"contenuto": "",
		"dataDocumento": "2020-10-25T23:00:00.000Z",
		"dataProtocollo": "2020-10-26T00:00:00.000Z",
		"esito": {
			"codiceEsito": "000",
			"descrizioneEsito": "Operazione eseguita senza errori"
		},
		"id": 4098022,
		"isContenuto": true,
		"mittentiDestinatari": [],
		"nomeFileContenuto": "REGLAZIO.REGISTRO UFFICIALE.2020.0914708.pdf",
		"note": "",
		"numeroProtocollo": "914708",
		"numeroProtocolloEsterno": "161892",
		"oggetto": "POSTA CERTIFICATA: Ferrovia Regionale Roma- Civitacastellana- Viterbo. Necessità finanziamenti manutenzione straordinaria ed interventi di rinnovo dell'armamento ferroviario.  - ATAC S.p.A. - Protocollo numero 161892 del 26/10/2020",
		"registro": "REGISTRO UFFICIALE",
		"timbro": false,
		"tipoProtocollo": "I",
		"ufficioCompetente": " GR3411 - PIANIFICAZIONE DEL TRASPORTO PUBBLICO REGIONALE E PROGRAMMAZIONE E GESTIONE DELLE FERROVIE GR3400 - INFRASTRUTTURE E MOBILITA'",
		"vociTitolario": [],
		"allegati": [{
				"nome": "documentoprotocollo.pdf",
				"codice": "4098022"
			}, {
				"nome": "__body.html",
				"codice": "8363775"
			}
		]
	}
];
      return;*/

        this.loading = true;
        this.protocolloService.getAll(anno,numero).subscribe(
            result=>{this.protocolli = result;this.loading = false;}
        );
    }


    @ViewChild('templateEditNoteModal', { static: true })
    public templateEditNoteModal: TemplateRef<any>;
    editProsaDocument(data){
        this.editNoteProtocollo.controls.note_protocollo.setValue(data.note);
        this.editNoteProtocollo.controls.id.setValue(data.id);
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-lg"
        };
        this.modalRef = this.modalService.show(this.templateEditNoteModal, config);
    }

    closeModalEditProsaNote(){
        this.modalRef.hide();
    }

    salvaNoteProtocollo(){
        let note = this.editNoteProtocollo.controls.note_protocollo.value;
        let id = this.editNoteProtocollo.controls.id.value;
        this.closeModalEditProsaNote();

        let protocollo = this.allProtocolli.find(x=>x.id == id);
        if (!protocollo){
            this.toastr.error(`Protocollo non trovato`, null, {
                timeOut: 2000, disableTimeOut: false
            });
            return;
        }
        if (protocollo['status']!='NEW')protocollo.status = 'EDIT';
        protocollo.note = note;
        this.toastr.success("Note del protocollo salvate con successo", null, {
            timeOut: 2000,
         disableTimeOut: false,
        });



    }

    trimText(text, size) {
        if (!size) return text;
        if (text.length <= size) return text;
        return text.substring(0, size) + '...';
    }
    printDateValue(value){
        let v = moment(value).toDate();
        //v.setHours(v.getHours() - v.getTimezoneOffset() / 60);
        return moment(v).format('DD/MM/YYYY');
    }
    downloadDocumento(codice,nome){
        console.log(`${codice} - ${nome}`);
        this.protocolloService.isPresente(codice,nome).subscribe(
            result=>{
                if (result){
                    this.protocolloService.download(codice,nome).subscribe(
                        res=>{
                            let filename = nome.replace(/^.*[\\\/]/, "");
                            FileSaver.saveAs(res.body, filename);
                        }
                    );
                }
                else{
                    this.toastr.error(`Documento ${nome} non presente su Prosa`, null, {
                        timeOut: 3000,/*closeButton:true,*/ disableTimeOut: false
                    });
                }
            }
        );
    }


    aggiungiProtocollo(numero){
        let protocollo = this.protocolli.find(x=>x.numeroProtocollo ==numero);
        if (!protocollo){
            this.toastr.error(`Documento ${numero} non trovato`, null, {
                timeOut: 3000,/*closeButton:true,*/ disableTimeOut: false
            });
            return;
        }

        if (this.allProtocolli.find(x=>x.numeroProtocollo==protocollo.numeroProtocollo)
        ){
            this.toastr.warning(`Protocollo già presente`, null, {
                timeOut: 3000, disableTimeOut: false
            });
            return;
        }


        let id = this.currentNewProtocolloId--;
        protocollo.id = id;
        protocollo.status = 'NEW';
        this.allProtocolli.push(protocollo);

        this.setIsDirty(true);
        this.toastr.success("Documento aggiunto con successo", null, {
            timeOut: 2000,
             disableTimeOut: false,
        });

    }

    deleteProsaDocument(data: any) {
        const result: boolean = confirm(
            `Sei sicuro di voler eliminare il protocollo selezionato?`
        );
        if (result) {
            this.allProtocolli = this.allProtocolli.filter(x=>x.numeroProtocollo!=data.numeroProtocollo);
            //protocolliDeleted
            if (data['status'] != 'NEW'){
                this.protocolliDeleted.push(data);
            }
            this.toastr.success("Protocollo eliminato con successo", null, {
                timeOut: 2000,
                disableTimeOut: false,
            });
            this.setIsDirty(true);
        }
    }

    canEdit() {
        return (this.enableSection(this.section, ['update'],false,true));
    }    


    /**
* Controlla se l'utente può accedere o meno alla sezione.
* @param section il nome della sezione
* @param operations le operazioni da poter effettuare sulla sezione
* @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
* @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
* @returns true se il controllo è andato a buon fine, false altrimenti
*/
    enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
        return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
    }
}
