import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { AuthService } from "src/app/services/auth.service";
import { AppConfigService } from "src/app/services/app-config.service";
import { HttpClient } from "@angular/common/http";
import * as FileSaver from "file-saver";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { UploadService } from "src/app/services/upload.service";
import { ToastService } from 'src/app/services/toast.service';
import { Router } from '@angular/router';
import { SendEmailUserModalComponent } from './accountListSendEmailModal/account-list-send-email-modal';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { UpdatePermissionsComponent } from '../updatePermissions/update-permissions.component';
import { Roles } from "src/app/helpers/constants";

@Component({
  templateUrl: "./account-list.component.html",
  styleUrls: ["./account-list.component.css"]
})
export class AccountListComponent implements OnInit {
  modalRef: BsModalRef;
  modalOpts: ModalOptions = {
    backdrop: "static",
    ignoreBackdropClick: true,
    keyboard: false
  };

  dtOptionsNgx: any;
  @ViewChild('btnDeleteTemplate', { static: true })
  public btnDeleteTemplate: TemplateRef<any>;
  @ViewChild('btnUpdatePermissionsTemplate', { static: true })
  public btnUpdatePermissionsTemplate: TemplateRef<any>;
  @ViewChild('btnSendEmailTemplate', { static: true })
  public btnSendEmailTemplate: TemplateRef<any>;
  @ViewChild('btndocsTemplate', { static: true })
  public btndocsTemplate: TemplateRef<any>;
  @ViewChild('btnEnableTemplate', { static: true })
  public btnEnableTemplate: TemplateRef<any>;
  @ViewChild('btnNotificationsDisabledTemplate', { static: true })
  public btnNotificationsDisabledTemplate: TemplateRef<any>;

  templateList = [];

  @ViewChild('usernameTemplate', { static: true })
  public usernameTemplate: TemplateRef<any>;
  @ViewChild('ngxDatatableWrapper')
  ngxDatatableWrapper: NgxDatatableWrapperComponent;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private http: HttpClient,
    private modalService: BsModalService,
    // private mailService: ComunicationService,
    private documentService: UploadService,
    private toastr: ToastService,
    private router: Router
  ) {
    this.dtOptionsNgx = {
      rows: []
      , totalElements: 0
      , columns: this.columns
      , columnMode: ColumnMode.flex
      , headerHeight: "50"
      , footerHeight: "50"
      , rowHeight: "auto"
      , externalSorting: true// true
      , loadingIndicator: 'loading'
      , class: "bootstrap"
      , summaryRow: true
      , summaryPosition: "'bottom'"
      , externalPaging: true// true
      , count: 0
      , offset: 0
      , limit: 10
      , serverSide: true
      , ajax: this.loadData
      , scrollbarH:"true"
    };
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.modalOpts);
  }

  refreshGrid() {
    this.ngxDatatableWrapper.refresh();
  }

  onDeleteDocs(info: any) {
    console.log('here');
    if (info.isDocsOk && confirm(`Sei sicuro di voler eliminare i documenti dell'utente ${info.username}?`)) {
      this.documentService.deleteDocuments(info.username).subscribe(res => {
        console.log(`DOCUMENTS OF ${info.username} DELETED`);
        this.refreshGrid();
        this.toastr.success(`Documenti dell'utente ${info.username} eliminati con successo`);
      });
    }
  }

  downloadDoc(info: any, docType: string) {
    if (info.isDocsOk) {
      this.userService.getDocument(info.username, docType).subscribe(res => {
        const ext = docType === 'id' ? info.idFilePath.split('.').pop() : info.fcFilePath.split('.').pop();
        const fname = `${info.username}.${docType=='id'?'cartaIdentita':'codiceFiscale'}.${ext}`;
        FileSaver.saveAs(res.body, fname);
      });
    }
  }

  ngOnInit() {
    this.templateList = [
      { name: 'btnSendEmailTemplate', template: this.btnSendEmailTemplate },
      { name: 'btndocsTemplate', template: this.btndocsTemplate },
      { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate },
      { name: 'btnUpdatePermissionsTemplate', template: this.btnUpdatePermissionsTemplate },
      { name: 'btnEnableTemplate', template: this.btnEnableTemplate },
      { name: 'usernameTemplate', template: this.usernameTemplate },
      { name: 'btnNotificationsDisabledTemplate', template: this.btnNotificationsDisabledTemplate }
    ];
    window.scroll(0, 0);
  }

  enableHandler(info: any): void {
    if (info.isDocsOk) {
      const confirmMsg = info.enabled ? `Stai per disattivare l'utente ${info.username}, continuare?`:`Stai per abilitare l'utente ${info.username}, continuare?`;
      if (!confirm(confirmMsg)) return;
      this.userService.toggleUserStatus(info.username).subscribe(completed => {
        if (completed) {
          this.toastr.success(`Utente ${info.username} ${info.enabled ? 'disabilitato' : 'abilitato'} con successo`);
          this.authService.setIsEnabled();
          this.refreshGrid();
        }
      });
      console.log("ENABLE: " + info.firstName);
    }
  }

  /*tokenHandler(info: any): void {
      if (!confirm(`Inviare un nuovo link di attivazione all'utente ${info.username}?`))return ;
    this.userService
      .resendActivationLink(info.username)
      .subscribe(completed => {
        if (completed) {
          this.toastr.success("Link di Attivazione inviato");
        }
        else{
            this.toastr.warning("Link di Attivazione già validato");
        }
      });
    console.log(`TOKEN sent: ${info.firstName}`);
  }*/

  sendHandler(info: any): void {
    const config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      /*  currentObj: {to:info.email,userId:info.id,username:info.username}*/
    };
    const initialState = { currentObj: { to: info.email, userId: info.id, username: info.username } };
    this.modalRef = this.modalService.show(SendEmailUserModalComponent, { ...config, ...initialState });
    this.modalRef.content.currentObj = initialState.currentObj;
  }

  updatePermissions(row: any): void {
    this.userService.getAllSections().subscribe(resp => {
      console.log(resp);
      const initialState = {
        ...{ userId: row.id },
        ...{ username: row.username },
        ...{ allSections: resp }
      };
      const config = {
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg'
      };
      this.modalRef = this.modalService.show(
        UpdatePermissionsComponent,
        Object.assign({}, config, { initialState })
      );
  
      this.modalRef.content.onClose.subscribe((_) => {
        this.modalRef.hide();
        this.refreshGrid();
      });
    }, error => {
      this.toastr.error('Errore');
    });
  }

  deleteHandler(info: any): void {
    const result: boolean = confirm(
      `Sei sicuro di voler eliminare l'utente ${info.username}?`
    );
    if (result) {
      this.userService.deleteUser(info.username).subscribe(deleted => {
        if (deleted) {
          this.toastr.success("Utente eliminato con successo");
          this.refreshGrid();
        }
      });
      console.log("DELETE: " + info.firstName);
    }
  }

  openAccountInfo(data: any): void {
    this.router.navigate([`account/me/${data.username}`]);
  }

  columns = [
    {
      name: 'id',
      prop: 'id',
      visible: false,
      sortable: true,
      filtrable: true,
      width: 10,
      resizeable: false
    }, {
      name: 'username'
      , prop: 'username'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , cellTemplate: 'usernameTemplate'
      , flexGrow: 3
      , minWidth: 200
    }
    , {
        name: 'utente'
        , prop: 'fullName'
        , sortable: true
        , filtrable: true
        , resizeable: false
        , draggable: false
        , flexGrow: 3
        , minWidth: 200
      }
    
    , {
      name: 'tipo utente'
      , prop: 'userType'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , flexGrow: 2
      , minWidth: 120
    }, {
      name: 'email'
      , prop: 'email'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , flexGrow: 3
      , minWidth: 160
    }, /*{
      name: 'documentazione'
      , prop: 'isDocsOk'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , cellTemplate: 'btndocsTemplate'
      , flexGrow: 2
      , minWidth: 110
    },*/ {
      name: 'invio email'
      , prop: 'isDocsOk'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , cellTemplate: 'btnSendEmailTemplate'
      , flexGrow: 1
      , minWidth: 60
    }, {
      name: 'notifiche'
      , prop: 'notificationsDisabled'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , cellTemplate: 'btnNotificationsDisabledTemplate'
      , flexGrow: 1
      , minWidth: 60
    }, {
      name: 'abilitato'
      , prop: 'enabled'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , cellTemplate: 'btnEnableTemplate'
      , flexGrow: 1
      , minWidth: 60
    }, {
      name: 'gestione<br>permessi'
      , prop: 'sections'
      , sortable: false
      , filtrable: false
      , resizeable: false
      , draggable: false
      , cellTemplate: 'btnUpdatePermissionsTemplate'
      , flexGrow: 1
      , minWidth: 60
    }, {
      name: 'elimina'
      , prop: null
      , sortable: false
      , filtrable: false
      , cellTemplate: 'btnDeleteTemplate'
      , resizeable: false
      , draggable: false
      , flexGrow: 1
      , minWidth: 60
    }
  ];

  loadData = (dataTablesParameters: any, callback: any) => {
    this.http
      .post<any>(
        `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.user_protected}accounts`,
        dataTablesParameters
      )
      .subscribe(resp => {
        callback(resp);
      });
  };

  rowIsAdmin(row) {
    return row.roleCode === Roles.role_admin;
  }

  changeNotificationsDisabledValueClick(item) {
    const msg = item.notificationsDisabled ? "Abilitare la ricezione delle notifiche per l'utente selezionato?" : "Disabilitare la ricezione delle notifiche per l'utente selezionato?";
    if (!confirm(msg)) return;

    this.userService.toggleNotificationsDisabled(item.username).subscribe(
      x => {
        this.toastr.success(`Ricezione notifiche per l'utente ${item.username} ${item.notificationsDisabled ? 'abilitate' : 'disabilitate'} con successo`);
        this.refreshGrid();
    });
  }
}
