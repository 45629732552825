<div class="col-lg-12 mt-3 table-responsive-lg">
  <div class="container sfondo-bianco">
    <table class="table table-hover table-colore">
      <thead class=" ">
        <th>Servizi Sicer Schedulabili</th>
        <th>Azione</th>
      </thead>
      <tbody>
        <ng-container>
          <tr *ngFor="let item of microservices">
            <td>{{item.label}}</td>
            <td><a (click)="scheduleMs(item)" href="javascript:;;" data-toggle="tooltip" data-placement="top"
                title="schedula">
                <i *ngIf="!loadingServices[item.id]; else schedule_icon_spinning"
                  class="fas fa-play casella-icona-tx"></i>
                <ng-template #schedule_icon_spinning><i
                    class="fas fa-spinner fa-spin casella-icona-tx"></i></ng-template>
              </a>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>