import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  TemplateRef,  
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ToastService } from "src/app/services/toast.service";
import { NgxDatatableWrapperComponent } from "src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component";
import {
  FormGroup,
  FormBuilder,
  Validators,  
} from "@angular/forms";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ColumnMode } from "@swimlane/ngx-datatable";
import {
  SubInterventiService,
  StorageService,
  StatoAttuazioneService,
  InterventoService,
} from "../../services";
import * as moment from "moment";
import { CurrencyPipe } from "@angular/common";
import { IsDirtyHelper } from "../../../../helpers/is-dirty.helper";
import { AuthService } from "src/app/services";

@Component({
  selector: "app-sub-interventi",
  templateUrl: "./sub-interventi.component.html",
  styleUrls: ["./sub-interventi.component.css"],
})
export class SubInterventiComponent implements OnInit {
  tabsOnOneRow = false;

  constructor(
    private toastr: ToastService,
    private el: ElementRef,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private subInterventiService: SubInterventiService,
    private isDirtyHelper: IsDirtyHelper,
    private storageService: StorageService,
    private statoAttuazioneService: StatoAttuazioneService,
    private authService:AuthService,
    private interventoService:InterventoService
  ) {
    this.dtOptions = {
      rows: this.rows,
      columns: this.columns,
      columnMode: ColumnMode.flex,
      headerHeight: "50",
      footerHeight: "50",
      rowHeight: "auto",
      externalSorting: true, // true
      loadingIndicator: "loading",
      class: "bootstrap",
      summaryRow: true,
      summaryPosition: "'bottom'",
      externalPaging: true, // true
      count: 0,
      offset: 0,
      limit: 10,
      serverSide: true,
      scrollbarH: "true",
      ajax: this.loadDocumentazione,
    };
  }

  ngOnInit(): void {
    this.templateList = [
      {
        name: "codiceSubInterventotemplate",
        template: this.codiceSubInterventotemplate,
      },
      { name: "fieldNoteTemplate", template: this.fieldNoteTemplate },
      { name: "btnDeleteTemplate", template: this.btnDeleteTemplate },
      { name: "btnEditTemplate", template: this.btnEditTemplate },
      {name:'fieldDocumentazioneTemplate', template:this.fieldDocumentazioneTemplate}
    ];
    this.editForm = this.initializeFormGroup();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  section: string = "interventi";
  submitted: boolean = false;
  submitLabel: string = "";
  loading: boolean = false;
  private _subInterventoId: number;

  private modalInit = false;

  editForm: FormGroup;

  modalRef: BsModalRef;
  modalTitle: string = "";

  rupItems = [];
  statiAttuazioneItems = [];

  isDataStipulaDisabled = true;
  dataConsegnaValue = null;
  dataConsegnaMinValue = null;
  dataConsegnaMaxValue = null;

  storiaImportiSal = [];

  isInEdit = false;
  readOnlyForm=false;

  templateList = [];

  dtOptions: any;
  rows: any[];

  columns = [
    {
      name: "codice sub-intervento",
      prop: "codice",
      cellTemplate: "codiceSubInterventotemplate",
      sortable: true,
      filtrable: true,
      resizeable: false,
      draggable: false,
      flexGrow: 1,      
    },
    {
      name: "descrizione",
      prop: "descrizione",
      sortable: true,
      filtrable: true,
      flexGrow: 1,
      cellTemplate: "descrizioneTemplate",
    },
    {
      name: "importo qte",
      prop: "importoQte",
      cellTemplate: "currencyValueTemplate",
      sortable: true,
      filtrable: true,
      resizeable: false,
      draggable: false,
      flexGrow: 1,
      
    },
    {
      name: "stato di attuazione",
      prop: "statoAttuazione.descrizione",
      cellTemplate: "statoAttuazioneTemplate",
      sortable: true,
      filtrable: true,
      resizeable: false,
      draggable: false,
      flexGrow: 1,
      
    },
    {
      name: "note",
      prop: "note",
      cellTemplate: "fieldNoteTemplate",
      sortable: true,
      filtrable: true,
      resizeable: false,
      draggable: false,
      flexGrow: 1,
      
    }
, {
        name: 'documentazione'
        , prop: 'documentazionePresente'
        , sortable: true
        , filtrable: true
        , cellTemplate: 'fieldDocumentazioneTemplate'// 'buttonsTemplate'

        , flexGrow: 1


    },
    {
      name: "modifica",
      prop: "id",
      sortable: false,
      filtrable: false,
      cellTemplate: "btnEditTemplate", // 'buttonsTemplate'
      flexGrow: 1,
      
    },
    {
      name: "elimina",
      prop: "id",
      sortable: false,
      filtrable: false,
      cellTemplate: "btnDeleteTemplate", // 'buttonsTemplate'
      flexGrow: 1,
      
    },
  ];

  loadDocumentazione = (dataTablesParameters: any, callback: any) => {
    this.subInterventiService
      .getAlldt(dataTablesParameters, this._interventoId)
      .subscribe((resp) => callback(resp));
  };

  private elencoSubscription = [];

  public mask: any = {
    mask: "KK €",
    lazy: false, // make placeholder always visible
    blocks: {
      KK: {
        mask: Number,
        thousandsSeparator: ".",
        scale: 2,
        padFractionalZeros: true,
        radix: ",",
      },
    },
  };

  @Input() codiceIntervento: string;

  @ViewChild("subInterventoModal", { static: true })
  public subInterventoModal: TemplateRef<any>;

  @ViewChild("ngxDatatableWrapper")
  ngxDatatableWrapper: NgxDatatableWrapperComponent;

  @ViewChild("fieldNoteTemplate", { static: true })
  public fieldNoteTemplate: TemplateRef<any>;

  @ViewChild("codiceSubInterventotemplate", { static: true })
  public codiceSubInterventotemplate: TemplateRef<any>;

  @ViewChild("btnEditTemplate", { static: true })
  public btnEditTemplate: TemplateRef<any>;

  @ViewChild("btnDeleteTemplate", { static: true })
  public btnDeleteTemplate: TemplateRef<any>;

  @ViewChild('fieldDocumentazioneTemplate', { static: true })
    public fieldDocumentazioneTemplate: TemplateRef<any>;
  @Input()
  gotoBtnOptions: any;

  @Output()
  gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
  gotoBackBtnClick() {
    this.gotoBtnFunction.emit(true);
  }

  @Output()
  afterInsertOrDeleteEvent:EventEmitter<boolean> = new EventEmitter();

  _isInModal: boolean = false;
  @Input()
  set isInModal(value: boolean) {
    this._isInModal = value;
  }
  get isInModal() {
    return this._isInModal;
  }

  @Input()
  set subInterventoId(value) {
    this._subInterventoId = value;
    this.section = "Sub Interventi";
    this._interventoId = null;
    if (!value) return;
  }
  get subInterventoId() {
    return this._subInterventoId;
  }

  private _interventoId: number;
  @Input()
  set interventoId(value) {
    this._interventoId = value;
    this.section = "Interventi";
    this._subInterventoId = null;
    if (!value) return;
    this.getInterventoData();
  }

  get interventoId() {
    return this._interventoId;
  }

  setIsDirty(value: boolean) {
    this.isDirtyHelper.isDirty = value;
  }

  initializeFormGroup(): FormGroup {
    let frm = this.formBuilder.group({
      id: [null],
      codice: [null],
      descrizione: [null],
      cig: [null, [Validators.required]],
      rupSelect: [null, [Validators.required]],
      importoQte: [null, [Validators.required]],
      importoAggiudicazione: [null],
      dataStipulaContratto: [null],
      dataConsegnaContratto: [null],
      durataContrattuale: [null, [Validators.min(0)]],
      dataFineLavoriPrev: [{ value: null, disabled: true }],
      statoAttuazione: [null],
      importoSal: [null],
      dataConclusioneLavori: [null],
      note: [null],
    });
    frm.valueChanges.subscribe((val) => this.setIsDirty(frm.dirty));
    return frm;
  }
  get f() {
    return this.editForm.controls;
  }


  getInterventoData() {
    if (!this._interventoId)return;
    this.interventoService.get(this._interventoId).subscribe(
        result => {
            let status = (result.validazione?(result.validazione.validationStatus?result.validazione.validationStatus:0):0).toString().toUpperCase();
            this.readOnlyForm = (/*status =='1' 
            || status =='APPROVED' 
            || status =='WAITING_FOR_APPROVAL_FIRST_LEVEL' || status =='4'
            || status =='WAITING_FOR_APPROVAL_SECOND_LEVEL' || status =='5'
            || status =='TERMINATO'
            || */result.isReadOnly
            ); 
        }

    );    
}



  getRupSubIntervento() {
    let subscription = this.storageService.elencoRup.subscribe((x) => {
      let kp = {};
      this.rupItems = x
        ? x.reduce(function (r, el) {
            let key = el.cognome + " " + el.nome;
            if (!kp[key]) {
              kp[key] = Object.assign({}, el);
              r.push(kp[key]);
            }
            return r;
          }, [])
        : [];
    });
    this.elencoSubscription.push(subscription);
  }

  getStatoAttuazione() {
    let subscription = this.statoAttuazioneService
      .getAll()
      .subscribe((result) => {
        this.statiAttuazioneItems = result;
      });
    this.elencoSubscription.push(subscription);
  }

  openAddSubInterventimodal() {
    this.submitted = false;
    this.isInEdit = false;
    this.submitLabel = "Inserisci";
    this.modalTitle = "Inserisci Sub Intervento";
    let config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: "modal-lg",
      initialState: {},
    };
    this.isDataStipulaDisabled = true;
    this.modalRef = this.modalService.show(this.subInterventoModal, config);
  }

  setDataStipula($event) {
    if (!this.modalInit) {
      this.modalInit = true;
      this.dataConsegnaMinValue = null;
      return;
    }
    if (!$event) {
      this.dataConsegnaMinValue = null;
      return;
    }
    this.dataConsegnaMinValue = $event;
    this.editForm.updateValueAndValidity();
  }

  setDataConclusione($event) {
    if (!this.modalInit) {
      this.modalInit = true;
      this.dataConsegnaMaxValue = null;
      return;
    }
    if (!$event) {
      this.dataConsegnaMaxValue = null;
      return;
    }
    this.dataConsegnaMaxValue = $event;
    this.editForm.updateValueAndValidity();
  }

  setDataConsegna($event) {
    if (!this.modalInit) {
      this.modalInit = true;
      return;
    }
    if (!$event) {
      this.dataConsegnaValue = null;
      this.f.dataFineLavoriPrev.setValue(null);
      return;
    }

    // Erase data errors if they were fixed
    if (
      this.editForm.value.dataStipulaContratto &&
      this.editForm.value.dataConsegnaContratto >=
        this.editForm.value.dataStipulaContratto
    ) {
      this.f.dataStipulaContratto.setErrors(null);
      this.f.dataConsegnaContratto.setErrors(null);
    }

    if (
      this.editForm.value.dataConsegnaContratto <=
      this.editForm.value.dataConclusioneLavori
    ) {
      this.f.dataConclusioneLavori.setErrors(null);
      this.f.dataConsegnaContratto.setErrors(null);
    }

    this.dataConsegnaValue = $event;

    if (
      (this.editForm.value.durataContrattuale ||
        this.editForm.value.durataContrattuale == 0) &&
      this.editForm.value.durataContrattuale >= 0
    ) {
      let resultData = moment($event)
        .add(this.editForm.value.durataContrattuale, "d")
        .toDate();
      this.f.dataFineLavoriPrev.setValue(resultData);
    }
  }

  setDurataContrattuale() {
    if (
      !this.editForm.value.dataConsegnaContratto ||
      this.editForm.value.durataContrattuale == null ||
      this.editForm.value.durataContrattuale == undefined ||
      this.editForm.value.durataContrattuale < 0
    ) {
      this.f.dataFineLavoriPrev.setValue(null);
      return;
    }

    let resultData = moment(this.editForm.value.dataConsegnaContratto)
      .add(this.editForm.value.durataContrattuale, "d")
      .toDate();
    this.f.dataFineLavoriPrev.setValue(resultData);
  }

  onSubmit() {
    this.submitted = true;
    let errors = false;

    //Check data errors, if so specify which error
    if (
      this.editForm.value.dataStipulaContratto &&
      this.editForm.value.dataConsegnaContratto &&
      this.editForm.value.dataConsegnaContratto <
        this.editForm.value.dataStipulaContratto
    )
      this.f.dataStipulaContratto.setErrors({ dataStipulaHigher: true });

    if (
      this.editForm.value.dataConclusioneLavori &&
      this.editForm.value.dataConsegnaContratto &&
      this.editForm.value.dataConsegnaContratto >
        this.editForm.value.dataConclusioneLavori
    )
      this.f.dataConclusioneLavori.setErrors({ dataConclusioneLower: true });

    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }
    if (this.editForm.invalid || errors) {
      console.log("invalid");
      let invalidElements = []; 
      for (let el in this.editForm.controls) {
        if (this.editForm.controls[el].invalid) {
          console.log(`set focus on ${el}`);
          if (this.editForm.controls[el] instanceof FormGroup) {
            for (let el1 in this.editForm.get(el)["controls"]) {
              if (this.editForm.get(el)["controls"][el1].invalid) {
                invalidElements = this.el.nativeElement.querySelectorAll(
                  `[formcontrolname="${el1}"]`
                );
                break;
              }
            }
          } else {
            invalidElements = this.el.nativeElement.querySelectorAll(
              `[formcontrolname="${el}"]`
            );
          }
          break;
        }
      }
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }

      if (
        this.f.dataStipulaContratto.errors &&
        this.f.dataStipulaContratto.errors.dataStipulaHigher
      )
        this.toastr.error(
          "Errore la data stipula contratto deve essere minore o uguale della data di consegna",
          null,
          { timeOut: 4000 }
        );

      if (
        this.f.dataConclusioneLavori.errors &&
        this.f.dataConclusioneLavori.errors.dataConclusioneLower
      )
        this.toastr.error(
          "Errore la data conclusione lavori deve essere maggiore o uguale della data di consegna",
          null,
          { timeOut: 4000 }
        );

      return false;
    }
    this.loading = true;

    let data = {
      id: this.editForm.value.id,
      codice: this.editForm.value.codice
        ? this.editForm.value.codice
        : this.codiceIntervento,
      rup: { id: this.editForm.value.rupSelect },
      descrizione: this.editForm.value.descrizione,
      cig: this.editForm.value.cig,
      importoQte: this.editForm.value.importoQte
        ? this.editForm.value.importoQte
        : 0,
      importoAggiudicazione: this.editForm.value.importoAggiudicazione
        ? this.editForm.value.importoAggiudicazione
        : null,
      dataStipula: this.editForm.value.dataStipulaContratto
        ? moment(this.editForm.value.dataStipulaContratto).format("YYYY-MM-DD")
        : null,
      dataConsegna: this.editForm.value.dataConsegnaContratto
        ? moment(this.editForm.value.dataConsegnaContratto).format("YYYY-MM-DD")
        : null,
      durataContrattuale: this.editForm.value.durataContrattuale,
      fineLavori: this.f.dataFineLavoriPrev.value
        ? moment(this.f.dataFineLavoriPrev.value).format("YYYY-MM-DD")
        : null,
      statoAttuazione: { id: this.editForm.value.statoAttuazione },
      importoSal: parseFloat(this.editForm.value.importoSal),
      dataConclusione: this.editForm.value.dataConclusioneLavori
        ? moment(this.editForm.value.dataConclusioneLavori).format("YYYY-MM-DD")
        : null,
      note: this.editForm.value.note,
      intervento: { id: this._interventoId },
    };

    this.subInterventiService.save(data, this._interventoId).subscribe(
      (res) => {
        this.submitted = false;
        this.modalRef.hide();
        if (!data.id) {
          this.toastr.success("Sub intervento inserito con successo!");
          this.modalInit = false;
          this.afterInsertOrDeleteEvent.emit(true);
        } else {
          this.toastr.success("Sub intervento modificato con successo!");
          this.modalInit = false;
        }
        this.ngxDatatableWrapper.refresh();
        this.editForm.reset();
        this.loading = false;
      },
      (error) => {
        console.log(error);
        if (
          error.error.trace &&
          error.error.trace.includes("DataIntegrityViolationException")
        )
          this.toastr.error("Errore codice già precedentemente inserito");
        else {
          let errResponse = error.error.message
            ? error.error.message
            : error.error.error_description
            ? error.error.error_description
            : error.error;

          errResponse = JSON.parse(errResponse);
          if (errResponse.causa == "ImportoQteMaggioreAnnualita")
            this.f.importoQte.setErrors({ maggAnnualita: true });
          if (errResponse.causa == "ImportoSalMinorePrec")
            this.f.importoSal.setErrors({ minPrecedente: true });

          this.toastr.error(errResponse.errore, null, {
            timeOut: 4000,
            /*closeButton:true,*/ disableTimeOut: false,
          });
          this.loading = false;
        }
      }
    );
  }

  onEdit(row) {
    console.log(row);
    // Init variables for edit modal
    this.submitted = false;
    this.isInEdit = true;
    this.submitLabel = "Aggiorna";
    this.storiaImportiSal = row.historyChangesOnImportoSalChange
      ? JSON.parse(row.historyChangesOnImportoSalChange).reverse()
      : null;
    this.modalTitle = "Modifica Sub Intervento";
    let config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: "modal-lg",
      initialState: {},
    };
    this.modalRef = this.modalService.show(this.subInterventoModal, config);
    this.editForm.setValue({
      id: row.id,
      codice: row.codice,
      descrizione: row.descrizione,
      cig: row.cig,
      rupSelect: row.rup ? row.rup.id : null,
      importoQte: row.importoQte,
      importoAggiudicazione: row.importoAggiudicazione,
      dataStipulaContratto: row.dataStipula
        ? moment(row.dataStipula).toDate()
        : null,
      dataConsegnaContratto: row.dataConsegna
        ? moment(row.dataConsegna).toDate()
        : null,
      durataContrattuale: row.durataContrattuale,
      dataFineLavoriPrev: row.fineLavori
        ? moment(row.fineLavori).toDate()
        : null,
      statoAttuazione: row.statoAttuazione ? row.statoAttuazione.id : null,
      importoSal: row.importoSal,
      dataConclusioneLavori: row.dataConclusione
        ? moment(row.dataConclusione).toDate()
        : null,
      note: row.note,
    });
    this.dataConsegnaValue = this.editForm.value.dataConsegnaContratto;
    this.dataConsegnaMinValue = this.editForm.value.dataStipulaContratto;
    this.dataConsegnaMaxValue = this.editForm.value.dataConclusioneLavori;
    if (
      this.editForm.value.dataStipulaContratto &&
      this.editForm.value.dataConsegnaContratto >=
        this.editForm.value.dataStipulaContratto
    ) {
      this.f.dataStipulaContratto.setErrors(null);
      this.f.dataConsegnaContratto.setErrors(null);
    }

    if (
      this.editForm.value.dataConsegnaContratto <=
      this.editForm.value.dataConclusioneLavori
    ) {
      this.f.dataConclusioneLavori.setErrors(null);
      this.f.dataConsegnaContratto.setErrors(null);
    }

    if (this.readOnlyForm)this.editForm.disable();
    else this.editForm.enable();
  }

  onDelete(row) {
    console.log(row);
    if (
      !confirm(
        `Stai per eliminare il sub-intervento ${row.codice}, proseguire?`
      )
    )
      return;
    this.loading = true;

    let subFunctionOk = (result) => {
      this.loading = false;
      this.ngxDatatableWrapper.refresh();
      this.toastr.success("Sub-intervento eliminato con successo", null, {
        timeOut: 2000,
        /*closeButton:true,*/ disableTimeOut: false,
      });
      this.afterInsertOrDeleteEvent.emit(true);
    };
    let subFunctionKo = (error) => {
      this.loading = false;

      this.toastr.error(
        `Impossibile eliminare il sub-intervento: ${
          error.error.message
            ? error.error.message
            : error.error.error_description
            ? error.error.error_description
            : error.error
        }`,
        null,
        {
          timeOut: 2000,
          /*closeButton:true,*/ disableTimeOut: false,
        }
      );
      console.log("error");
    };

    this.subInterventiService
      .delete(row.id, this._interventoId)
      .subscribe(subFunctionOk, subFunctionKo);
  }

  printValueAsDate(value) {
    if (!value || value == "") return "";
    return moment(value).format("DD/MM/YYYY");
  }

  printValueAsDateTime(date) {
    if (!date) return "";
    let v = moment(date).toDate();    
    return moment(v).format("DD/MM/YYYY HH:mm:ss");
  }

  printValueAsCurrency(value) {
    value = typeof value === "number" ? value : value.replace(",", ".").trim();
    const format = "1.2-2";
    const currency = "€";
    const currentLocale: string = "it";
    return new CurrencyPipe(currentLocale).transform(
      value,
      "EUR",
      currency,
      format,
      "it-IT"
    );
  }

  reloadAllData() {
    this.getRupSubIntervento();
    this.getStatoAttuazione();
    this.ngxDatatableWrapper.refresh();
  }

  closeModal() {
    this.editForm.reset();
    this.modalRef.hide();
    this.isInEdit = false;
    this.dataConsegnaValue = null;
    this.dataConsegnaMinValue = null;
    this.dataConsegnaMaxValue = null;
    this.dataConsegnaMinValue = null;
    this.dataConsegnaMaxValue = null;
    this.modalInit = false;
  }

  clearSubscriptions() {
    this.elencoSubscription.forEach((x) => x.unsubscribe());
    this.elencoSubscription = [];
  }

  canEdit() {
    return this.enableSection(this.section, ["update"], null, true);
  }
  /**
   * Controlla se l'utente può accedere o meno alla sezione.
   * @param section il nome della sezione
   * @param operations le operazioni da poter effettuare sulla sezione
   * @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
   * @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
   * @returns true se il controllo è andato a buon fine, false altrimenti
   */
  enableSection(
    section: string,
    operations: string[],
    atLeastOne: boolean = false,
    enableOnNotFound: boolean = true
  ): boolean {
    return this.authService.checkPermissionBySectionAndOperations(
      section,
      operations,
      atLeastOne,
      enableOnNotFound
    );
  }
  isGruppoPnrr() {
    return this.authService.isGruppoPnrr();
  }

  isAdmin() {
    return this.authService.isAdmin();
  }
  isGr34() {
    return this.authService.isGr34();
  }

  documentazioneChanged($event){
    this.ngxDatatableWrapper.refresh();
}
}
