import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";

@Injectable({
  providedIn: "root",
})
export class SicerBilancioService {

  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}SicerBilancio/`;

  constructor(private http: HttpClient) {}

  getAllCapitoliDt(dataTablesParameters: any, interventoId: number) {
    return this.http.post<any>(
      `${this.apiUrl}simCapitoli/${interventoId}/alldt`,
      dataTablesParameters
    );
  }

  getAllScrittureBilancio(codCapitolo: string) {
    return this.http.get<any>(
      `${this.apiUrl}simCapitoli/${codCapitolo}/scrittureBilancio`
    );
  }

  getImpegniAccertamentiByCodiceCapitoloAndId(codiceCapitolo: string, type: string, id: number, interventoId: number) {
    return this.http.get<any>(
      `${this.apiUrl}simCapitoli/${codiceCapitolo}/impegniAccertamenti/${id}/${interventoId}/${type}`
    );
  }
}
