import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import { ToastService } from 'src/app/services/toast.service';
import { debounceTime } from 'rxjs/operators';
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { IsDirtyHelper } from "src/app/helpers/is-dirty.helper";
import { OrderPipe } from 'ngx-order-pipe';
import {  InterventoService,ProgettoService} from '../../services';
import { CurrencyPipe } from '@angular/common';
import IMask from 'imask';
@Component({
    selector: 'spostamento-annualita-component',
    templateUrl: './spostamento-annualita.component.html',
    styleUrls: ['./spostamento-annualita.component.css']
})
export class SpostamentoAnnualitaComponent
    implements OnInit {

    @Output() onSubmitOk = new EventEmitter();
    @Input() interventoId: number;
    editForm: FormGroup;

    loadDataCounter:number = 0;

    listaAnnualita :any[];

    public mask: any = {
        mask: 'Z KK €',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            },
            Z:{
                mask:IMask.MaskedEnum,
                enum:['+','-']
            }
        }
    };

    constructor(
        private toastr: ToastService
        , public bsModalRef: BsModalRef
        ,private formBuilder:FormBuilder
        ,private isDirtyHelper:IsDirtyHelper
        ,private interventoService:InterventoService
        ,private progettoService:ProgettoService
        , private orderPipe: OrderPipe
    ) {
        
    }

    setIsDirty(value:boolean){
        this.isDirtyHelper.isDirty = value;
    }

    initializeFormGroup(): FormGroup {
        let frm = this.formBuilder.group({
            interventoArray:this.formBuilder.array([])
            ,finanziamentiArray:this.formBuilder.array([])
        });

        frm.valueChanges.subscribe(val=>
            this.setIsDirty(frm.dirty)
            );

        return frm;
    }
    get f() {
        return this.editForm.controls;
    }
    get interventoArray(){
        return this.editForm.get('interventoArray') as FormArray;
    }
    get finanziamentiArray(){
        return this.editForm.get('finanziamentiArray') as FormArray;
    }


    ngOnInit(): void {
        this.editForm = this.initializeFormGroup();
        this.getDetail();
    }

    loading=false;
    save() {
        let obj = {
            annualitaIntervento: this.arrayImportiInterventoModificati
            ,annualitaFinanziamenti:this.matriceImportiModificatiFinanziamenti
        };
        this.loading = true;
        this.interventoService.spostaAnnualita(this.interventoId, obj).subscribe(
            result => {
                this.setIsDirty(false);
                this.closeModal();
                this.loading = false;                
                this.toastr.success('Spostamento annualità avvenuto con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                                
            }, error => {
                this.loading = false;            
                this.toastr.error(`Errore: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                    timeOut: 5000, disableTimeOut: false
                });
                console.log('error');
            }
        );


        
        //this.closeModal();
    }

    closeModal() {
        if(!this.isDirtyHelper.checkIsDirty())return false;
        this.bsModalRef.hide();
        this.onSubmitOk.emit(null);
    }

    getDetail(): void {
        if (this.interventoId && this.interventoId > 0) {
            this.getDettaglioIntervento();
        }
    }

    interventoData: any;
    mainInterventoData:any;
    getDettaglioIntervento() {
        this.loadDataCounter++;   
        let that = this;
        this.interventoService.get(this.interventoId).subscribe(
            result => {
                that.interventoData = result; 
                that.listaFinanziamenti = result.finanziamenti;
                that.getMainInterventoData(result.progettoId);
                that.getAnnualitaByInterventoId(this.interventoId);
                that.loadDataCounter--;
                
            });
    }

    cambioValoreAnnualitaIntervento(data,anno/*,index*/){
        console.log(`data=${data.importo} anno=${anno} `);
        let originValue = this.listaAnnualita.find(x=>x.anno ==anno).importo;
        console.log(`originValue=${originValue}`);
        let editedValue = parseFloat(data.importo.replace('+','').replace('-',''));
        if (isNaN(editedValue))return;
        let newValue = 0;
        if (data.importo.indexOf('-')!=-1)newValue = originValue - editedValue;
        else newValue = originValue + editedValue;
        //this.arrayImportiInterventoModificati[index] = newValue;
        this.arrayImportiInterventoModificati['' + anno] = newValue;
        let totaleModificati = 0;
        for(let annoTmp in this.arrayImportiInterventoModificati){
            if (annoTmp =='totale')continue;
            totaleModificati += this.arrayImportiInterventoModificati['' + annoTmp];
        }
        this.arrayImportiInterventoModificati['totale'] = totaleModificati;
        //for(let i=0;i<this.arrayImportiInterventoModificati.length-1;i++)totaleModificati += this.arrayImportiInterventoModificati[i];
        //this.arrayImportiInterventoModificati[this.arrayImportiInterventoModificati.length-1] = totaleModificati;
    }

    getAnnualitaByInterventoId(id: number) {
        this.loadDataCounter++;   
        let that = this;
        this.arrayImportiInterventoModificati = {};
        this.interventoService.getAnnualita(id).subscribe(
            result => {
                result = this.orderPipe.transform(result, 'anno');
                result.forEach(
                    a=>{
                        that.arrayImportiInterventoModificati['' + a.anno]= a.importo;
                        //let index = that.arrayImportiInterventoModificati.length-1;
                        let field = that.formBuilder.group({importo:new FormControl('')});
                        field.valueChanges.pipe(debounceTime(200)).subscribe(d=>that.cambioValoreAnnualitaIntervento(d,a.anno/*,index*/));
                        that.interventoArray.push(field);                        
                    }
                );

                that.listaAnnualita = result;
                that.arrayImportiInterventoModificati['totale'] = that.getTotaleAnnualita();
                //that.arrayImportiInterventoModificati.push(that.getTotaleAnnualita())
                that.loadDataCounter--;   
            }
        );
    }

    getMainInterventoData(projectId:number){
        this.loadDataCounter++;   
        let that = this;
        this.progettoService.get(projectId).subscribe(
            result => {
                that.mainInterventoData = result; 
                //that.listaFinanziamenti = result.finanziamenti;
                that.inizializzaMatriceImportiModificatiFinanziamenti();
                this.loadDataCounter--;
                
            });
    }

    ///ottengo il valore della somma delle annualita
    getTotaleAnnualita(){
        let somma = 0;
        if (this.listaAnnualita)this.listaAnnualita.forEach(a=>somma +=a.importo);
        return somma;
    }
    listaFinanziamenti:any[];

    getValoreFinanziamentoXAnno(id:number,anno:number){
        let fin = this.interventoData.finanziamenti.find(f=>f.finanziamento.id == id);
        if (!fin) return 0;
        let annualita = fin.annualita.find(a=>a.anno == anno);
        if (!annualita)return 0;
        return annualita.importo;
    }

    getValoreFinanziamentoTotale(id:number){
        let fin = this.interventoData.finanziamenti.find(f=>f.finanziamento.id == id);
        if (!fin) return 0;
        let somma = 0;
        fin.annualita.forEach(a => {
            somma +=a.importo;
        });
        return somma;
    }



    printValueAsCurrency(value) {
        if(!value)value =0;
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }

    arrayImportiInterventoModificati : {};
    matriceImportiModificatiFinanziamenti:{};

    cambioValoreAnnualitaFinanziamento(d,anno,codiceFin,finId){
        console.log(`anno=${anno} codiceFin=${codiceFin}`);
        let rigaMatrice = this.matriceImportiModificatiFinanziamenti[codiceFin];

        let originValue = this.getValoreFinanziamentoXAnno(finId,anno);
        console.log(`originValue=${originValue}`);
        let editedValue = parseFloat(d.importo.replace('+','').replace('-',''));
        if (isNaN(editedValue))return;
        let newValue = 0;
        if (d.importo.indexOf('-')!=-1)newValue = originValue - editedValue;
        else newValue = originValue + editedValue;
        //this.arrayImportiInterventoModificati[index] = newValue;
        rigaMatrice['' + anno] = parseFloat(newValue.toFixed(2));

        let totaleModificati = 0;
        for(let annoTmp in rigaMatrice){
            if (annoTmp =='totale')continue;
            totaleModificati += rigaMatrice['' + annoTmp];
        }
        rigaMatrice['totale'] = parseFloat(totaleModificati.toFixed(2));




    }


    inizializzaMatriceImportiModificatiFinanziamenti(){
        //listaFinanziamenti
        //this.interventoData.finanziamenti
        this.matriceImportiModificatiFinanziamenti = {};
        this.listaFinanziamenti.forEach(
            fin=>{
                let fieldJson = {};
                //this.listaAnnualita.forEach(a=>fieldJson[a.anno] = new FormControl(''));
                //let field = this.formBuilder.group(fieldJson);
                let field = this.formBuilder.group({anni:this.formBuilder.array([])});
                this.listaAnnualita.forEach(a=>{
                    let f = this.formBuilder.group({importo:new FormControl('')});
                    (field.get('anni') as FormArray).push(f);
                    f.valueChanges.pipe(debounceTime(200)).subscribe(d=>this.cambioValoreAnnualitaFinanziamento(d,a.anno,fin.finanziamento.codice,fin.finanziamento.id));
                });
                //field.valueChanges.pipe(debounceTime(200)).subscribe(d=>this.cambioValoreAnnualitaFinanziamento(d));
                this.finanziamentiArray.push(field);                        
                //let field = that.formBuilder.group({importo:new FormControl('')});
                let finanziamentoIntervento = this.interventoData.finanziamenti.find(f=>f.finanziamento.id == fin.finanziamento.id);
                let myObj = {};
                if (finanziamentoIntervento !=null){
                    
                    let totale = 0;
                    finanziamentoIntervento.annualita.forEach(
                        a=>{myObj['' + a.anno] = a.importo;totale +=a.importo;}
                        );
                    myObj['totale'] = totale;                
                }
                this.matriceImportiModificatiFinanziamenti[fin.finanziamento.codice] = myObj;
            }
        );
    }

    getTotaleFinanziamentiXAnno(anno){
        let somma = 0;
        for(let codice in this.matriceImportiModificatiFinanziamenti){
            let obj = this.matriceImportiModificatiFinanziamenti[codice];
            let importo = obj[anno];
            somma += importo?importo:0;
            /*for(let anno in obj){
                somma += obj[anno];
            }*/
        }
        return somma;
    }

    getTotaleFinanziamenti(){
        let somma = 0;
        for(let codice in this.matriceImportiModificatiFinanziamenti){
            let obj = this.matriceImportiModificatiFinanziamenti[codice];
            somma+=obj['totale'];
            /*for(let anno in obj){
                somma += obj[anno];
            }*/
        }
        return somma;
    }

    getAnno(index){
        return this.listaAnnualita[index].anno;
    }

    addAnnualita(){
        let lastAnno = this.listaAnnualita[this.listaAnnualita.length-1].anno;
        lastAnno++;
        let newAnnualita = {anno :lastAnno,importo:0,isNew:true};
        this.listaAnnualita.push(newAnnualita);
        this.arrayImportiInterventoModificati['' + lastAnno] = 0;
        let field = this.formBuilder.group({importo:new FormControl('')});
        field.valueChanges.pipe(debounceTime(200)).subscribe(d=>this.cambioValoreAnnualitaIntervento(d,lastAnno/*,index*/));
        this.interventoArray.push(field); 
        for(let index in this.finanziamentiArray.controls){
            let field = this.finanziamentiArray.controls[index];
            let fin = this.listaFinanziamenti[index];
            let f = this.formBuilder.group({importo:new FormControl('')});
            (field.get('anni') as FormArray).push(f);
            f.valueChanges.pipe(debounceTime(200)).subscribe(d=>this.cambioValoreAnnualitaFinanziamento(d,lastAnno,fin.finanziamento.codice,fin.finanziamento.id));
        }
    }

    getTotaleRigaVariazioneIntervento(){
        let somma = 0;
        this.interventoArray.controls.forEach(
            x=>somma += parseFloat(x.value.importo==''?0:x.value.importo)
            );
        return somma;
    }

    getTotaleRigaVariazioneFinanziamento(controlsArray){
        let somma = 0;
        controlsArray.controls['anni'].controls.forEach(
            x=>somma += parseFloat(x.value.importo==''?0:x.value.importo)
            );
        return somma;
    }

}