import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Injectable({
    providedIn: "root"
})
export class FormHelper {

    public cleanControls(form: FormGroup){
        if(form){
            for (const field in form.controls) { 
                const control = form.get(field);  
                control.setValue(null);
              }
            form.reset();
            //this.setIsDirty(false);
        }
    }
}