<div id="passaggio4" class="tab-pane">
    <div class="form-registrazione m-2">
        <h5>
            Ancora un ultimo passaggio per completare la tua registrazione
        </h5>

        <p>
            Il suo account è in fase di accreditamento. Quando sarà validato ne riceverà comunicazione tramite mail.
        </p>        

        <button class="btn btn-primary btn-block" routerLink="/login">
      TORNA ALLA LOGIN
    </button>
    </div>
</div>