import { CurrencyPipe } from "@angular/common";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ProgettoService, SoggettoGiuridicoService, SoggettoRuoloService } from "../../services";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { DashboardService } from "../../services";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "dashboard-erogazioni-component",
  templateUrl: "./dashboard-erogazioni.component.html",
  styleUrls: ["./dashboard-erogazioni.component.css"],
})
export class DashboardErogazioniComponent implements OnInit, AfterViewInit {
  myGroup: FormGroup;
  loadingCounter: number = 0;
  soggettiGiuridiciList = [];
  rolesList = [];
  submitted: boolean = false;

  data: any;
  private chart1: am4charts.PieChart;
  private chart3: am4charts.PieChart;
  private chart2: am4charts.XYChart;
  // private chart5: am4charts.XYChart;
  // private chart4: am4charts.XYChart;

  constructor(
    private soggettoGiuridicoService: SoggettoGiuridicoService,
    private soggettoRuoloService: SoggettoRuoloService,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private toastr: ToastService,
    private progettoService: ProgettoService
  ) {
    this.getSoggettiGiuridici();
    this.getRoles();
    this.myGroup = this.initializeFormGroup();
    this.loadInitialData();
  }

  ngAfterViewInit(): void {
    this.loadData();
  }
  ngOnInit(): void {
    //this.loadData();
    // throw new Error('Method not implemented.');
  }

  initializeFormGroup() {
    return this.formBuilder.group({
      soggetto: [null, Validators.required],
      ruolo: [null /*,Validators.required*/],
    });
  }

  loadData() {
    this.loadingCounter++;
    let loadFakeData = () => {
      this.data = {
        interventi: 72,
        erogazioniSa: 54,
        erogazioniNormali: 65,
        annualita: [
          {
            anno: 2020,
            erogazioniSaImporto: 10000000,
            erogazioniNormaleImporto: 9000000,
          },
          {
            anno: 2021,
            erogazioniSaImporto: 5000000,
            erogazioniNormaleImporto: 2000000,
          },
          {
            anno: 2022,
            erogazioniSaImporto: 13000000,
            erogazioniNormaleImporto: 7000000,
          },
        ],
      };
      this.renderCharts();
      this.loadingCounter--;
    };
    //window.setTimeout(loadFakeData,100);
    this.dashboardService.getDashboardErogazioni().subscribe((result) => {
      this.loadingCounter--;
      this.data = result;
      this.renderCharts();
    });
  }

  getSoggettiGiuridici() {
    this.loadingCounter++;
    this.soggettoGiuridicoService.getAll().subscribe((res) => {
      let ress: any[];
      ress = res;
      ress.map((i) => {
        i.descrizione = `${i.siglaProvincia} - ${i.codiceIdentificativo} - ${i.denominazione}`;
        return i;
      });
      this.soggettiGiuridiciList = ress;
      this.loadingCounter--;
    });
  }

  getRoles() {
    this.loadingCounter++;
    this.soggettoRuoloService.getAll().subscribe((res) => {
      this.rolesList = res;
      this.loadingCounter--;
    });
  }

  get f() {
    return this.myGroup.controls;
  }

  getTotaleErogazionexAnnualita(tipoErogazione) {
    let somma = 0;
    if (this.data)
      this.data.annualita.forEach((x) => (somma += x[tipoErogazione]));
    return somma;
  }

  printValueAsCurrency(value) {
    //return this.italianCurrencyPipe.parse(value) + " €";
    value = typeof value === "number" ? value : value.replace(",", ".").trim();
    const format = "1.2-2";
    const currency = "€";
    const currentLocale: string = "it";
    return new CurrencyPipe(currentLocale).transform(
      value,
      "EUR",
      currency,
      format,
      "it-IT"
    );
  }

  exportChart(chart, title) {
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = "left";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.background = "#ff0000";
    chart.exporting.menu.defaultStyles = true;
    chart.exporting.filePrefix = title;
    chart.exporting.menu.items = [
      {
        menu: [
          { type: "jpg", label: "JPG" },
          { type: "png", label: "PNG" },
          { type: "csv", label: "CSV" },
        ],
      },
    ];
    chart.exporting.menu.items[0].icon = "assets/img/export.png";
  }

  aggiungiLogo(logoChart) {
    // Add watermark
    let watermark = new am4core.Image();
    watermark.href = "assets/img/logo_blu.svg";
    logoChart.tooltipContainer.children.push(watermark);
    watermark.align = "right";
    watermark.valign = "bottom";
    watermark.opacity = 0.3;
    watermark.marginRight = 10;
    watermark.marginBottom = 5;
    watermark.disabled = true;

    // Enable watermark on export
    logoChart.exporting.events.on("exportstarted", function (ev) {
      watermark.disabled = false;
    });

    // Disable watermark when export finishes
    logoChart.exporting.events.on("exportfinished", function (ev) {
      watermark.disabled = true;
    });

    // Add watermark to validated sprites
    logoChart.exporting.validateSprites.push(watermark);
  }

  renderCharts() {
    am4core.useTheme(am4themes_animated);
    this.renderChart1();
    this.renderChart2();
    this.renderChart3();
  }
  renderChart1() {
    this.chart1 = am4core.create("chart1", am4charts.PieChart);
    am4core.options.autoDispose = true;
    let data = [
      { title: "Erogazioni", value: this.data.erogazioniSa },
      { title: "Erogazioni", value: this.data.erogazioniNormali },
    ];
    this.chart1.data = data;
    let pieSeries = this.chart1.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "title";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
    this.chart1.legend = new am4charts.Legend();
    let title = this.chart1.chartContainer.createChild(am4core.Label); //chart.titles.create();
    title.text = "Tipologia erogazioni (quantità)";
    title.align = "center";
    this.chart1.radius = am4core.percent(70);

    this.chart1.responsive.rules.push({
      relevant: function (target) {
        return false;
      },
      state: function (target, stateId) {
        return null;
      },
    });

    pieSeries.ticks.template.disabled = true;
    pieSeries.alignLabels = false;
    pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    pieSeries.labels.template.radius = am4core.percent(-40);
    pieSeries.labels.template.fill = am4core.color("white");

    this.exportChart(this.chart1, title.text);
    // Add percent sign to all numbers

    this.aggiungiLogo(this.chart1);
  }

  renderChart2() {
    let createSeries = (field, name, chart) => {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "anno";
      series.name = name;
      series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
      series.columns.template.height = am4core.percent(100);
      series.sequencedInterpolation = true;
      return series;
    };

    this.chart2 = am4core.create("chart2", am4charts.XYChart);
    am4core.options.autoDispose = true;
    let data = this.data.annualita;

    this.chart2.data = [...data];
    this.chart2.data.forEach((x) => (x.anno = x.anno.toString()));

    var categoryAxis = this.chart2.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "anno";
    categoryAxis.numberFormatter.numberFormat = "#";
    // categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;

    var valueAxis = this.chart2.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.opposite = false;
    let series = createSeries("erogazioniSaImporto", "Erogazioni", this.chart2);
    //createSeries("erogazioniNormaleImporto", "Erogazioni", this.chart2);

    valueAxis.renderer.labels.template.verticalCenter = "middle";
    valueAxis.renderer.labels.template.horizontalCenter = "right";

    this.chart2.legend = new am4charts.Legend();
    this.chart2.legend.position = "top";
    this.chart2.legend.paddingBottom = 20;
    this.chart2.legend.labels.template.maxWidth = 95;

    // Add horizotal scrollbar with preview
    // var scrollbarX = new am4charts.XYChartScrollbar();
    // scrollbarX.series.push(series);
    // this.chart2.scrollbarX = scrollbarX;

    // Add simple vertical scrollbar
    this.chart2.scrollbarX = new am4core.Scrollbar();

    // chart.legend.labels.template.maxWidth = 120

    this.chart2.responsive.enabled = true;

    // this.chart2.numberFormatter.numberFormat = "# a '€'";

    // this.chart2.numberFormatter.bigNumberPrefixes = [
    //   { number: 1e3, suffix: "K" },
    //   { number: 1e6, suffix: "Mln" },
    //   { number: 1e9, suffix: "Mrd" },
    // ];

    this.chart2.numberFormatter.numberFormat = "#.## a '€'";
    this.chart2.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "Mln" },
      { number: 1e9, suffix: "Mrd" },
    ];

    this.exportChart(this.chart2, "grafico");

    this.aggiungiLogo(this.chart2);
  }

  renderChart3() {
    this.chart3 = am4core.create("chart3", am4charts.PieChart);
    am4core.options.autoDispose = true;
    let data = [
      {
        title: "Erogazioni a S.A.",
        value: this.getTotaleErogazionexAnnualita("erogazioniSaImporto"),
      },
      {
        title: "Erogazioni",
        value: this.getTotaleErogazionexAnnualita("erogazioniNormaleImporto"),
      },
    ];
    this.chart3.data = data;
    let pieSeries = this.chart3.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "title";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
    this.chart3.legend = new am4charts.Legend();
    let title = this.chart3.chartContainer.createChild(am4core.Label); //chart.titles.create();
    title.text = "Tipologia erogazioni (valore)";
    title.align = "center";
    this.chart3.radius = am4core.percent(70);

    pieSeries.ticks.template.disabled = true;
    pieSeries.alignLabels = false;
    pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    pieSeries.labels.template.radius = am4core.percent(-40);
    pieSeries.labels.template.fill = am4core.color("white");

    this.exportChart(this.chart3, title.text);

    this.aggiungiLogo(this.chart3);
  }

  isFiltered: boolean = false;
  detailsObject: any;
  anniArray = [];
  undoSearch() {
    this.isFiltered = false;
  }

  ruoloId: any;
  soggettoId: any;

  obj: any = null;

  search() {
    this.ruoloId = this.f.ruolo.value ? this.f.ruolo.value : -1;
    this.soggettoId = this.f.soggetto.value;
    this.filterIntervento = this.filterIntervento;
    this.isFiltered = true;
  }

  getSalEmessiSa(item, anno) {
    if (!item) return 0;
    let ann = item.annualita.find((x) => x.anno == anno);
    return ann ? ann.salEmessiSa : 0;
  }
  getSalEmessi(item, anno) {
    if (!item) return 0;
    let ann = item.annualita.find((x) => x.anno == anno);
    return ann ? ann.salEmessi : 0;
  }
  getImportiErogati(item, anno) {
    if (!item) return 0;
    let ann = item.annualita.find((x) => x.anno == anno);
    return ann ? ann.importiErogati : 0;
  }
  getImportiErogatiSa(item, anno) {
    if (!item) return 0;
    let ann = item.annualita.find((x) => x.anno == anno);
    return ann ? ann.importiErogatiSa : 0;
  }

  getSalEmessiSaTotale(item) {
    if (!item) return 0;
    let somma = 0;
    this.anniArray.forEach(
      (anno) => (somma += this.getSalEmessiSa(item, anno))
    );
    return somma;
  }
  getSalEmessiTotale(item) {
    if (!item) return 0;
    let somma = 0;
    this.anniArray.forEach((anno) => (somma += this.getSalEmessi(item, anno)));
    return somma;
  }
  getImportiErogatiTotale(item) {
    if (!item) return 0;
    let somma = 0;
    this.anniArray.forEach(
      (anno) => (somma += this.getImportiErogati(item, anno))
    );
    return somma;
  }
  getImportiErogatiSaTotale(item) {
    if (!item) return 0;
    let somma = 0;
    this.anniArray.forEach(
      (anno) => (somma += this.getImportiErogatiSa(item, anno))
    );
    return somma;
  }
  getValore(item, anno) {
    if (!item) return 0;
    let ann = item.importi.find((x) => x.anno == anno);
    return ann ? ann.importo : 0;
  }

  getAC(item, anno) {
    if (!item) return 0;
    return (
      this.getSalEmessiSa(item, anno) - this.getImportiErogatiSa(item, anno)
    );
  }

  getBD(item, anno) {
    if (!item) return 0;
    return this.getSalEmessi(item, anno) - this.getImportiErogati(item, anno);
  }
  getEC(item, anno) {
    if (!item) return 0;
    return this.getValore(item, anno) - this.getImportiErogatiSa(item, anno);
  }
  getCD(item, anno) {
    if (!item) return 0;
    return (
      this.getImportiErogatiSa(item, anno) - this.getImportiErogati(item, anno)
    );
  }

  getValoreTotale(item) {
    if (!item) return 0;
    let somma = 0;
    this.anniArray.forEach((anno) => (somma += this.getValore(item, anno)));
    return somma;
  }
  getACTotale(item) {
    if (!item) return 0;
    return (
      this.getSalEmessiSaTotale(item) - this.getImportiErogatiSaTotale(item)
    );
  }
  getBDTotale(item) {
    if (!item) return 0;
    return this.getSalEmessiTotale(item) - this.getImportiErogatiTotale(item);
  }
  getECTotale(item) {
    if (!item) return 0;
    return this.getValoreTotale(item) - this.getImportiErogatiSaTotale(item);
  }
  getCDTotale(item) {
    if (!item) return 0;
    return (
      this.getImportiErogatiSaTotale(item) - this.getImportiErogatiTotale(item)
    );
  }

  getSalEmessiSaTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach(
      (anno) => (somma += this.getSalEmessiSaTotaleAnno(anno))
    );
    return somma;
  }
  getSalEmessiTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach(
      (anno) => (somma += this.getSalEmessiTotaleAnno(anno))
    );
    return somma;
  }
  getImportiErogatiSaTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach(
      (anno) => (somma += this.getImportiErogatiSaTotaleAnno(anno))
    );
    return somma;
  }
  getImportiErogatiTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach(
      (anno) => (somma += this.getImportiErogatiTotaleAnno(anno))
    );
    return somma;
  }
  getValoreTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach((anno) => (somma += this.getValoreTotaleAnno(anno)));
    return somma;
  }
  getACTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach((anno) => (somma += this.getACTotaleAnno(anno)));
    return somma;
  }
  getBDTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach((anno) => (somma += this.getBDTotaleAnno(anno)));
    return somma;
  }

  getECTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach((anno) => (somma += this.getECTotaleAnno(anno)));
    return somma;
  }

  getCDTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach((anno) => (somma += this.getCDTotaleAnno(anno)));
    return somma;
  }

  getSalEmessiSaTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getSalEmessiSa(progetto, anno))
    );
    return somma;
  }
  getSalEmessiTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getSalEmessi(progetto, anno))
    );
    return somma;
  }
  getImportiErogatiSaTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getImportiErogatiSa(progetto, anno))
    );
    return somma;
  }
  getImportiErogatiTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getImportiErogati(progetto, anno))
    );
    return somma;
  }

  getValoreTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getValore(progetto, anno))
    );
    return somma;
  }
  getACTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getAC(progetto, anno))
    );
    return somma;
  }

  getBDTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getBD(progetto, anno))
    );
    return somma;
  }
  getECTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getEC(progetto, anno))
    );
    return somma;
  }
  getCDTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getCD(progetto, anno))
    );
    return somma;
  }

  arrowUp = true;

  filterIntervento: string;

  filterObj = {
    descriptionSearchString: "",
  };

  onChangeIntervention($event) {
    !$event.target.value && this.setIntervention(null);
  }
  setIntervention($event) {
    this.filterIntervento = $event ? $event.codice : null;
  }

  completerParams = {
    labelField: "descrizioneEstesa",
    ajax: this.filterComboInterventi,
    placeholder: "Cerca prog/fabbisogno/intervento",
    items: [],
    context: this,
  };

  filterComboInterventi(filterString, callBack) {
    console.log(filterString);
    let filter = { ...this.filterObj };
    filter["descriptionSearchString"] = filterString;
    this["context"].progettoService.getInterventiListSimpleData(filter).subscribe((x) => {
      x = x.map((y) => {
        y["descrizioneEstesa"] = `${y.codice} - ${y.descrizione}`;
        return y;
      });
      callBack(x);
    })
  }


  loadInitialData() {
    this.dashboardService
      .getDashboardErogazioniDetails(-1, -1)
      .subscribe((result) => {
        let items = result.progetti.map((x) => {
          return {
            codice: x.progettoCodice,
            label: x.progettoCodice + " - " + x.progettoDescrizione,
          };
        });

        result.progetti.forEach((x) => {
          let interventi = x.interventi.map((y) => {
            return {
              codice: y.codice,
              label: y.codice + " - " + y.descrizione,
            };
          });
          items = [...new Set([...items, ...interventi])];
        });

        function getUniqueListBy(arr, key) {
          return [...new Map(arr.map((item) => [item[key], item])).values()];
        }

        items = getUniqueListBy(items, "codice");
        items.sort((a, b) => a.codice - b.codice);
      /*   this.completerParams = {
          items: items,
          labelField: "label",
          ajax: (filterString: string, callBack: (results: any[]) => void) => this.filterComboInterventi(filterString, callBack, this),
          placeholder: "Cerca prog/fabbisogno/intervento",
        }; */
      });
  }
}
