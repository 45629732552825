import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    TemplateRef,
    Input, EventEmitter, Output
} from "@angular/core";
import { registerLocaleData } from '@angular/common';
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { InterventoService, FinanziamentoService, ProvvedimentoService } from '../../services';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from '../../../../components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component'
import localeIt from '@angular/common/locales/it';
import { ProvvedimentiComponent } from '../provvedimenti/provvedimenti.component';
import { GenericSelectionModalComponent } from '../../components/genericSelectionModal/generic-selection-modal.component';
import { FinanziamentoDivDettaglioService } from "../finanziamentoDivDettaglio/finanziamento-div-dettaglio.service";
import { AuthService } from "src/app/services";

registerLocaleData(localeIt, 'it');
defineLocale('it', itLocale);

@Component({
    selector: 'associa-provvedimenti-component',
    templateUrl: './associa-provvedimenti.component.html',
    styleUrls: ['./associa-provvedimenti.component.css']
})
export class AssociaProvvedimentiComponent implements OnInit, OnDestroy {
section:string ='';
    public mask: any = {
        mask: 'KK €',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            }
        }
    };

    ngOnDestroy(): void {
        this.clearSubscriptions();
    }
    clearSubscriptions() {
        this.modalSubscriptions.forEach(x => x.unsubscribe());
        this.modalSubscriptions = [];
    }

    submitted: boolean = false;
    loading: boolean = false;
    readOnlyForm: boolean = false;
    loadDataCounter: number = 0;

    private _finanziamentoId: number;

    modalSubscriptions = [];
    //@ViewChild('buttonsTemplate',{static:false}) buttonsTemplate: TemplateRef<any>;
    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    @ViewChild('btnDetailTemplate', { static: true })
    public btnDetailTemplate: TemplateRef<any>;

    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    templateList = [];

    dtOptions: any;
    rows: any[];
    columns = [
        {
            name: '', prop: 'id', sortable: true, filtrable: false, maxWidth: 0, width: 0, visible: false
            // 'buttonsTemplate'
        }

        , {
            name: 'data'

            , prop: 'data'
            , cellTemplate: 'dateValueTemplate'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            , minWidth: 80
        }
        , {
            name: 'numero'

            , prop: 'numero'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            , minWidth: 70
        }
        , {
            name: 'oggetto'
            , prop: 'oggetto'
            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 4
            , minWidth: 150
        }
        , {
            name: 'tipo provvedimento'
            , prop: 'tipoProvvedimento.provvedimento'
            , sortable: true, filtrable: true
            , flexGrow: 3
            , minWidth: 120
        }
        , {
            name: 'scopo provvedimento'
            , prop: 'scopoProvvedimento.descrizione'
            , sortable: true
            , filtrable: true
            , flexGrow: 4
            , minWidth: 130
        }

        , {
            name: 'dettaglio'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDetailTemplate'// 'buttonsTemplate'
            , flexGrow: 2
            , minWidth: 60
        }

        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , flexGrow: 2
            , minWidth: 60

        }
    ];

    modalRef: BsModalRef;

    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };

    constructor(
        private toastr: ToastService
        , private modalService: BsModalService
        , private localeService: BsLocaleService
        , private finanziamentoService: FinanziamentoService
        , private provvedimentoService: ProvvedimentoService
        , private interventoService: InterventoService
        , private finanziamentoDivDettaglioService:FinanziamentoDivDettaglioService
        , private authService:AuthService
    ) {
        this.localeService.use("it");
        this.dtOptions = {
            rows: this.rows
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: false// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: false// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: false
            , scrollbarH: "true"
        };
    }

    ngOnInit() {
        this.templateList = [
            { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }
            , { name: 'btnEditTemplate', template: this.btnEditTemplate }
            , { name: 'btnDetailTemplate', template: this.btnDetailTemplate }
            ,{
                name: "tripletteFieldValueTemplate",
                template: this.tripletteFieldValueTemplate,
              }
        ];
        window.scroll(0, 0);
    }

    @ViewChild("tripletteFieldValueTemplate", { static: true })
  public tripletteFieldValueTemplate: TemplateRef<any>;

  getTriplette(tripletteJsonString){
      if (!tripletteJsonString || tripletteJsonString=='')return [];
      let r = JSON.parse(tripletteJsonString);
      r.sort((a,b)=>a<b?-1:1);
      return r;
  }

    private _interventoId: number;

    reloadData() {
        if (this._interventoId)
            this.getInterventoData(this._interventoId);
        if (this._finanziamentoId)
            this.getFinanziamentoData(this._finanziamentoId);
    }

    mainCode = '';

    @Input()
    set interventoId(value) {
       // console.log(`inside set _interventoId= ${value}`);
        const oldValue = this._interventoId;
        this._interventoId = value;
        if (!value) return;
        this._finanziamentoId = null;
        this.section = 'Interventi';

        oldValue !== value ? this.reloadData() : null;
        //this.getInterventoData(value);
    }
    get interventoId() {
       // console.log(`inside get _interventoId= ${this._interventoId}`);
        return this._interventoId;
    }

    @Input()
    set finanziamentoId(value) {
        console.log(`inside associa-provvedimenti set finanziamentoId= ${value}`);
        const oldValue = this._finanziamentoId;
        this._finanziamentoId = value;
        if (!value) return;
        this._interventoId = null;
        this.currentIntervento = null;
        this.section ='Finanziamenti';        

        this.reloadData();
        //this.getFinanziamentoData(value);
        oldValue !== value ? this.reloadData() : null;
    }
    get finanziamentoId() {
        //console.log(`inside get finanziamentoId= ${this._finanziamentoId}`);
        return this._finanziamentoId;
    }
    getFinanziamentoData(id: number) {
        this.loadDataCounter++;
        console.log('inside fasi-getfinanziamentodata');
        this._finanziamentoId = id;

        let that = this;
        this.finanziamentoService.getById(id).subscribe(result => {
            this.mainCode = result.codice;
            this.pnrrLineaFinanziamentoId = result.pnrrLineaFinanziamento ? result.pnrrLineaFinanziamento.id : null;
            let status = (result.validazione ? (result.validazione.validationStatus ? result.validazione.validationStatus : 0) : 0).toString().toUpperCase();
            that.readOnlyForm = (/*status =='1'
            || status =='APPROVED'
            || */status == 'WAITING_FOR_APPROVAL_FIRST_LEVEL' || status == '4'
                || status == 'WAITING_FOR_APPROVAL_SECOND_LEVEL' || status == '5'
                || status == 'TERMINATO'
                || result.isReadOnly
                || !that.canEdit()
            );
            that.columns[7].visible = true;
            //that.columns[7].visible = true;
            if (that.readOnlyForm) {
                //nascondo colonne edit e delete
                that.columns[7].visible = false;
                //that.columns[7].visible = false;

            }
            that.ngxDatatableWrapper.dtOptions.columns = that.columns;
            that.ngxDatatableWrapper.dtOptions = that.ngxDatatableWrapper.dtOptions;
            //this.ngxDatatableWrapper.refresh();
            this.getProvvedimentiByFinanziamentoId(id);
            this.loadDataCounter--;
        });
    }
    getProvvedimentiByInterventoId(id: number) {
        this.loadDataCounter++;
        this.interventoService.getProvvedimenti(id).subscribe(
            result => {
                this.ngxDatatableWrapper.setInitialData(result);
                this.loadDataCounter--;
            }
        );
    }
    currentIntervento: any;
    getInterventoData(id: number) {
        this.loadDataCounter++;
        let that = this;
        this.pnrrLineaFinanziamentoId = null;
        this.interventoService.get(id).subscribe(
            result => {
                this.mainCode = result.codice;
                that.currentIntervento = result;
                let status = (result.validazione ? (result.validazione.validationStatus ? result.validazione.validationStatus : 0) : 0).toString().toUpperCase();
                that.readOnlyForm = (/*status =='1'
                || status =='APPROVED'
                ||*/ status == 'WAITING_FOR_APPROVAL_FIRST_LEVEL' || status == '4'
                    || status == 'WAITING_FOR_APPROVAL_SECOND_LEVEL' || status == '5'
                    || status == 'TERMINATO'
                    || result.isReadOnly
                    || !that.canEdit()
                );
                that.columns[7].visible = true;
                if (that.readOnlyForm) {
                    that.columns[7].visible = false;
                }
                that.ngxDatatableWrapper.dtOptions.columns = that.columns;
                that.ngxDatatableWrapper.dtOptions = that.ngxDatatableWrapper.dtOptions;
                that.getProvvedimentiByInterventoId(this._interventoId);
                this.loadDataCounter--;
            }
        );
    }

    getProvvedimentiByFinanziamentoId(id: number) {
        this.loadDataCounter++;
        this.finanziamentoService.getProvvedimenti(id).subscribe(
            result => {
                this.ngxDatatableWrapper.setInitialData(result);
                this.loadDataCounter--;
            }
        );
    }

    openAddModal() {
        this.submitted = false;
        //this.modalRef = this.modalService.show(this.templateEditAdd, this.config);
    }

    onDelete(row) {
        if (this.loading) return;
        console.log(row);
        if (!confirm(`Stai per rimuovere il provvedimento "${row['data']} - ${row['numero']}", proseguire?`)) return;
        this.loading = true;
        let currentId = this._finanziamentoId ? this._finanziamentoId : this._interventoId;
        let service = this._finanziamentoId ? this.finanziamentoService : this.interventoService;
        service.unbindProvvedimento(currentId, row.id).subscribe(
            result => {
                this.loading = false;
                this.ngxDatatableWrapper.deleteRow(row);
                this.toastr.success('Provvedimento rimosso con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                if (this.onChangeOk)this.onChangeOk.emit(false);
            }
            ,error=>{
                this.toastr.warning(error && error.error && error.error.message?error.error.message:error.error, null, {
                    timeOut: 5000,/*closeButton:true,*/ disableTimeOut: false
                });
                this.loading = false;
            }
            );
    }
    closeModal() {
        if(this.modalRef)this.modalRef.hide();
    }
    openModalProvvedimenti() {

        let modalDefinition = {

            pageTitle: 'Seleziona i provvedimenti'
        };


        let modalDefinitionNgx = {
            rows: []
            , totalElements: 0
            , columns: [
                {
                    name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
                    , resizeable: false
                }

                , {
                    name: 'data'
                    , prop: 'data'

                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , cellTemplate: 'dateValueTemplate'
                    , flexGrow: 2
                    , minWidth: 90

                }
                , {
                    name: 'numero'
                    , prop: 'numero'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 70
                }
                , {
                    name: 'oggetto'
                    , prop: 'oggetto'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 6
                    , minWidth: 200
                }
                , {
                    name: 'tipo provvedimento'
                    , prop: 'tipoProvvedimentoDescrizione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 3
                    , minWidth: 130
                }
                , {
                    name: 'rilevante PNRR'
                    , prop: 'pnrrRilevante'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 75
                    , cellTemplate: 'booleanValueTemplate'
                }
                , {
                    name: 'Linee Finanziamento<br /> PNRR/FC'
                    , prop: 'triplette'
                    , sortable: false
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 75
                    , cellTemplate: 'tripletteFieldValueTemplate'
                }
                /*, {
                    name: 'scopo provvedimento'
                    , prop: 'scopoProvvedimentoDescrizione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 3
                    , minWidth: 140
                }*/
            ]
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , ajax: this.getProvvedimentiDTNgx
            , scrollbarH: "true"
        };


        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: 'modal-xl'
        };
        const initialState = { modalDefinition: modalDefinition
            , dtOptionsNgx: modalDefinitionNgx
            ,templateList: [
               {
                    name: "tripletteFieldValueTemplate",
                    template: this.tripletteFieldValueTemplate,
                  }
            ]
         };

        this.modalRef = this.modalService.show(GenericSelectionModalComponent, Object.assign({}, config, { initialState }));
        this.clearSubscriptions();
        let that = this;
        let subscription = this.modalRef.content.onSubmitOk.subscribe(
            data => {

                console.log('inside ok of provvedimenti');
                let parentId = that._finanziamentoId ? that._finanziamentoId : that._interventoId;
                let service = that._finanziamentoId ? that.finanziamentoService : that.interventoService;
                service.bindProvvedimento(parentId, data).subscribe(
                    ris => {
                        if (!ris) {
                            this.toastr.warning('Provvedimento già presente', null, {
                                timeOut: 5000,/*closeButton:true,*/ disableTimeOut: false
                            });
                            return;
                        };
                        this.ngxDatatableWrapper.addRow(ris);
                        this.toastr.success('Provvedimento inserito con successo', null, {
                            timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                        });
                        if (that.pnrrLineaFinanziamentoId && that._finanziamentoId){
                            //chiudo la modale
                            that.closeModal();
                        }
                        if (ris.pnrrRilevante)this.checkAndCreateAnnualitaFromRipartizioni();
                        if (that.onChangeOk)this.onChangeOk.emit(true);
                    }
                    ,error=>{
                        this.toastr.warning(error && error.error && error.error.message?error.error.message:error.error, null, {
                            timeOut: 5000,/*closeButton:true,*/ disableTimeOut: false
                        });
                    }

                );

            }
        );
        this.modalSubscriptions.push(subscription);
    }

    modalReftemplateModalPnrrPnc;

    checkAndCreateAnnualitaFromRipartizioniPnrr(){
        this.closeModalReftemplateModalPnrrPnc();
        this.checkAndCreateAnnualitaFromRipartizioni('pnrr');
    }
    checkAndCreateAnnualitaFromRipartizioniPnc(){
        this.closeModalReftemplateModalPnrrPnc();
        this.checkAndCreateAnnualitaFromRipartizioni('fc');
    }

    checkAndCreateAnnualitaFromRipartizioni(type=''){
        if (this.pnrrLineaFinanziamentoId && this._finanziamentoId){
            this.finanziamentoService.checkAndCreateAnnualitaFromRipartizioni(this._finanziamentoId,type).subscribe(
                result=>{
                    //se result è false, devo chiedere all'utente se è pnrr o pnc
                    //se è true significa che le ripartizioni sono tutte uguali e quindi il sistema riesce da solo
                    if (!result){
                        const config = {
                            backdrop: true,
                            ignoreBackdropClick: true,
                            keyboard: false
                        };
                        this.modalReftemplateModalPnrrPnc = this.modalService.show(this.templateModalPnrrPnc, config);
                    }
                    else{
                        this.toastr.success('Annualità create automaticamente dalle ripartizioni del provvedimento', null, {
                            timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                        });
                        this.finanziamentoDivDettaglioService.reload();
                    }
                }
            );
        }
    }

    closeModalReftemplateModalPnrrPnc(){
        if(this.modalReftemplateModalPnrrPnc)this.modalReftemplateModalPnrrPnc.hide();
    }

    getProvvedimentiDTNgx = (dataTablesParameters: any, callback: any) => {
        this.provvedimentoService.getAllDt(dataTablesParameters,this.pnrrLineaFinanziamentoId)
            .subscribe(resp => {
                callback(resp);
            });
    }
    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
    gotoBackBtnClick() {
        this.gotoBtnFunction.emit(true);
    }

    @ViewChild('provvedimentiComponent', { static: true })
    provvedimentiComponent: ProvvedimentiComponent;
    @ViewChild('templateDettaglioProvvedimento', { static: true })
    public templateDettaglioProvvedimento: TemplateRef<any>;

    @ViewChild('templateModalPnrrPnc', { static: true })
    templateModalPnrrPnc:TemplateRef<any>;

    modalRefDettaglioProvvedimento: BsModalRef;
    modalDettaglioTitle = 'Dettaglio provvedimento';
    provvedimentoDaMostrare: any;
    onDetail(row) {
        const config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl"
        };
        this.modalDettaglioTitle = 'Dettaglio provvedimento';
        this.modalRefDettaglioProvvedimento = this.modalService.show(this.templateDettaglioProvvedimento, config);
        this.provvedimentoDaMostrare = row;
        //this.provvedimentiComponent.onEdit(row);
    }

    closeDettaglioProvvedimentoModal() {
        this.modalRefDettaglioProvvedimento.hide();
        this.provvedimentoDaMostrare = null;
    }
    _isInModal: boolean = false;
    @Input()
    set isInModal(value: boolean) {
        this._isInModal = value;
    }
    get isInModal() { return this._isInModal; }

    openModalNuovoProvvedimento() {
        const config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl"
        };
        this.modalDettaglioTitle = 'Nuovo provvedimento';
        this.modalRefDettaglioProvvedimento = this.modalService.show(this.templateDettaglioProvvedimento, config);
        this.provvedimentoDaMostrare = { id: -1 };
    }

    saveNewProvvedimentoEmitter($event) {
        let msg = `Vuoi associare il provvedimento appena creato al finanziamento ${this.mainCode}?`;
        if (this._interventoId && this._interventoId > 0) msg = `Vuoi associare il provvedimento appena creato alla progettualità / intervento ${this.mainCode}?`;
        if (!confirm(msg)) return;
        let parentId = this._finanziamentoId ? this._finanziamentoId : this._interventoId;
        let service = this._finanziamentoId ? this.finanziamentoService : this.interventoService;
        this.provvedimentoService.getById($event).subscribe(
            data => {
                service.bindProvvedimento(parentId, data).subscribe(
                    ris => {
                        if (!ris) {
                            this.toastr.warning('Provvedimento già presente', null, {
                                timeOut: 5000,/*closeButton:true,*/ disableTimeOut: false
                            });
                            return;
                        };
                        this.ngxDatatableWrapper.addRow(ris);
                        this.toastr.success('Provvedimento inserito con successo', null, {
                            timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                        });
                        if (this.onChangeOk)this.onChangeOk.emit(true);
                    }

                );
            }
        );
    }

    @Output() onChangeOk: EventEmitter<any> = new EventEmitter();

    pnrrLineaFinanziamentoId = null;

    canEdit() {
        return (this.enableSection(this.section, ['update'], null,true));
    }
    /**
* Controlla se l'utente può accedere o meno alla sezione.
* @param section il nome della sezione
* @param operations le operazioni da poter effettuare sulla sezione
* @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
* @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
* @returns true se il controllo è andato a buon fine, false altrimenti
*/
    enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
        return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
    }
    isGruppoPnrr() {
        return this.authService.isGruppoPnrr();
    }

    isAdmin() {
        return this.authService.isAdmin();
    }
}
