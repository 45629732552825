import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-alert-message-modal',
  templateUrl: './alert-message-modal.component.html',
  styleUrls: ['./alert-message-modal.component.css']
})
export class AlertMessageModalComponent implements OnInit {
  // Input testo dinamico
  @Input() headerTitle: string;
  @Input() alertMessage: string;
  
  // Input labels bottoni submit (questo e' l'array che cicla il numero di bottoni)
  @Input() submitBtnLable: string[] = [];
  
  // Input classi bottoni submit
  @Input() submitBtnClass: string[] = [];

  // Input abilitazione bottoni 
  @Input() escapeEnable: boolean = true;
  @Input() submitEnable: boolean = true;

  @Output() onSubmitAlarm = new EventEmitter();

  constructor(
      public bsModalRef: BsModalRef
  ){}
 
  ngOnInit(): void {
      
  }
 
  sendChoice(choice: string){
    this.bsModalRef.hide()
    this.onSubmitAlarm.emit(choice);
  }

}
