<div class="p-5 container-xl text-center" [hidden]="loadDataCounter<=0">
    <app-loading message="Caricamento in corso..."></app-loading>
</div>

<div class="container sfondo_bianco" [hidden]="loadDataCounter>0">

    <hr />

    <!--inizio filtri-->

    <div class="row pb-3 pt-1  mx-0 mb-3 rounded" [ngClass]="{'bg-info':!flagSoggResp,'bg-0083e0':flagSoggResp}" [formGroup]="filterForm">
        <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
            <label for="mainObjectTypeFilter">
    <h6 class="mb-0 text-white bold">
      Prog./Fabbisogni - Interventi - Finanziamenti
    </h6>
  </label>
            <ng-select [items]="mainTypesCombo" bindValue="k" bindLabel="v" placeholder="Seleziona prog./fabbisogni; inter.; finanz." formControlName="mainObjectTypeFilter" (change)="setMainObjectTypeFilter($event)">
                <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.v }}">{{ item.v }}</div>
                </ng-template>
            </ng-select>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
            <label for="importoFinanziamento">
    <h6 class="mb-0 text-white bold">
      Prog./Fabbisogno - Inter. - Finanz.
      <small>(min 3 caratteri)</small>
    </h6>
  </label>
            <app-completer [cpOptions]="completerParams" (onSelectEmitter)="setInterventionCompleter($event)" (keyup)="onChangeInterventionCompleter($event)">
            </app-completer>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
            <label for="areeTematicheCombo">
    <h6 class="mb-0 text-white bold">
      Aree gestionali direzione e/o esterne
    </h6>
  </label>
            <ng-select [items]="areeTematicheCombo" bindValue="id" bindLabel="descrizione" formControlName="areeTematicheCombo" placeholder="Seleziona area gestionale direz. e/o esterna " (change)="setAreaTematicaFilter($event)">
                <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
                </ng-template>
            </ng-select>
        </div>


        <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
            <label for="tipologiaFase">
    <h6 class="mb-0 text-white bold">Tipologia Fase</h6>
  </label>
            <ng-select formControlName="tipologiaFase" [items]="tipologieFase" bindValue="id" bindLabel="descrizioneEstesa" placeholder="Seleziona tipologia fase" (change)="setTipologiaFaseFilter($event)">
                <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.descrizioneEstesa }}">{{ item.descrizioneEstesa }}</div>
                </ng-template>
            </ng-select>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
            <label for="tipologiaResponsabile">
    <h6 class="mb-0 text-white bold">Tipologia Soggetti Responsabili</h6>
  </label>
            <ng-select formControlName="tipologiaResponsabile" [items]="tipologieResponsabiliCombo" bindValue="id" bindLabel="descrizione" placeholder="Seleziona tipologia soggetto responsabile" (change)="setTipologiaResponsabile($event)">
                <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
                </ng-template>
            </ng-select>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
            <label for="soggettoResponsabile">
    <h6 class="mb-0 text-white bold">
      Soggetto Responsabile <small>(min 3 caratteri)</small>
    </h6>
  </label>
            <app-completer [cpOptions]="completerParamsSoggetti" (onSelectEmitter)="setSoggettiCompleter($event)" (keyup)="onChangeSoggettiCompleter($event)">
            </app-completer>
        </div>
    </div>
    <!--fine filtri-->
    <!--accordion-->
    <accordion [isAnimated]="true">
        <accordion-group heading="Gestione valori fase" [panelClass]="panelClass">
            <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
        </accordion-group>
    </accordion>
    <!--inizio tabella-->

    <div class="col-lg-6 col-sm-6 col-6 mt-5">
        <h5 for="" class="">Tabella fasi</h5>
    </div>
    <div class="container">

        <!--<button *ngIf="!tabellaDettaglioObjLoaded" (click)="tabellaDettaglioObjLoaded = true" class="btn btn-primary my-2">Mostra tabella di dettaglio</button>        -->

        <gestione-massiva-fasi-details-table-component [observable$]="observable$ | async" [tabellaDettaglioObj]="tabellaDettaglioObj" [showSelectColumn]="true" (codesSelectedChanged)="codesSelectedChanged($event)" (fasiSelectedChanged)="fasiSelectedChanged($event)">
        </gestione-massiva-fasi-details-table-component>


    </div>


</div>
<!--fine tabella-->

<!--inizio contenuto accordion-->
<ng-template #templateEditAdd>

    <div class="">
        <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
            <div class="col-lg-12 mt-3">
                <div class="form-group row">
                    <div class="col-sm-12">
                        <label for="tipologia"><h5>Tipologia:</h5></label>
                        <div>
                            <ng-select [formGroup]="editForm" formControlName="tipologia" [items]="tipologieFase" bindValue="id" bindLabel="descrizioneEstesa" placeholder="Seleziona la tipologia">
                                <ng-template ng-option-tmp let-item="item">
                                    <div title="{{ item.descrizioneEstesa }}">
                                        {{ item.descrizioneEstesa }}
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <!--1-->

                <div class="form-row">
                    <div class="form-group col-sm-6">

                        <label for="dataPrevistaInizio"><h5>Data prevista inizio:</h5></label>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12" style="display:flex; padding-right:0px;padding-left:0px">
                            <input type="text" formControlName="dataPrevistaInizio" class="form-control" bsDatepicker [formGroup]="editForm" [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY', adaptivePosition: true, containerClass:'theme-dark-blue' }" #dataPrevistaInizio="bsDatepicker"
                                style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
                            <button class="btn " [ngClass]="{'btn-info':!flagSoggResp,'btn-primary':flagSoggResp}" (click)="dataPrevistaInizio.toggle()" type="button" style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                                     <i class="fas fa-calendar-alt"></i>
                                </button>

                            <label class="mb-0 ml-2 mt-1" for="dataPrevistaInizioGiorni"><h5>+/-</h5></label>
                            <input class="form-control" type="text" formControlName="dataPrevistaInizioGiorni" [imask]="mask" [unmask]="true" />
                        </div>



                    </div>
                    <!--2-->


                    <div class="form-group col-sm-6">


                        <label for="dataPrevistaFine"><h5>Data prevista fine:</h5></label>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12" style="display:flex; padding-right:0px;padding-left:0px">
                            <input type="text" formControlName="dataPrevistaFine" class="form-control" bsDatepicker [formGroup]="editForm" [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY',containerClass:'theme-dark-blue' }" #dataPrevistaFine="bsDatepicker"
                                style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
                            <button class="btn " [ngClass]="{'btn-info':!flagSoggResp,'btn-primary':flagSoggResp}" (click)="dataPrevistaFine.toggle()" type="button" style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                            <i class="fas fa-calendar-alt"></i>
                        </button>

                            <label class="mb-0 ml-2 mt-1" for="dataPrevistaFineGiorni"><h5>+/-</h5></label>
                            <input class="form-control" type="text" formControlName="dataPrevistaFineGiorni" [imask]="mask" [unmask]="true" />

                        </div>


                    </div>

                </div>

                <!--3-->
                <div class="form-group row">
                    <div class="col-sm-12">
                        <label for="tipologia">
              <h5 class="">Note:</h5>
            </label>
                        <textarea [formGroup]="editForm" formControlName="note" type="text" [ngClass]="{
                'form-control': true                
              }" placeholder="Inserisci eventuali note" rows="3"></textarea>
                    </div>
                </div>


            </div>
            <div class="col-lg-12 mt-5">
                <hr [ngClass]="{'border-info':!flagSoggResp}">
            </div>
            <!--tabella-->

            <div class="col-lg-12 mt-4">
                <div class="row justify-content-between">

                    <div class="col-lg-7 col-md-6 col-sm-12 mt-3">
                        <h5 for="" class="">Soggetti responsabili</h5>
                    </div>




                    <div class="col-lg-4 col-md-5 col-sm-7 col-9 mt-3">

                        <div class="custom-control custom-switch  pl-0 ml-0 text-md-right">

                            <input type="checkbox" checked="" class="box custom-control-input" id="customSwitch2" (click)="changeFlagSoggResp()">
                            <label class="mr-5 bold">Aggiungi</label>
                            <label class="custom-control-label" for="customSwitch2">Sostituisci</label>
                        </div>
                    </div>


                    <div class="col-lg-1 col-md-1 col-sm-2 col-3 text-center mt-3 " id="colorOfSwitch2">
                        <a href="javascript:;;" class="bottone-inserisci" [ngClass]="{'text-info':!flagSoggResp}" (click)="addSoggettoResponsabile();">
                            <i class="fas fa-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 mt-3 table-responsive-lg">
                <table class="table table-hover table-colore">
                    <thead class="">
                        <th>soggetto</th>
                        <th>tipologia</th>
                        <th class="casella-icona-tx">elimina</th>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="soggettiResponsabili && soggettiResponsabili.length && soggettiResponsabili.length>0">
                            <tr *ngFor="let item of soggettiResponsabili">
                                <td>{{item.cognome}} {{item.nome}}</td>
                                <td>{{item.tipologia.descrizione}}</td>
                                <td><a (click)="removeSoggettoResponsabile(item)" href="javascript:;;" data-toggle="tooltip" data-placement="top" title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i> </a>
                                </td>
                            </tr>
                        </ng-container>
                        <tr *ngIf="!(soggettiResponsabili && soggettiResponsabili.length && soggettiResponsabili.length>0)">
                            <td colspan="3">Nessun soggetto responsabile selezionato</td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="submitted && soggettoResponsabileIsError" class="invalidFieldMessage">
                    <div *ngIf="soggettoResponsabileIsError" class="invalid-feedback" style="display: block;">
                        Selezionare almeno un soggetto responsabile
                    </div>
                </div>
            </div>

            <div class="col-lg-12 mb-3">
                <button [disabled]="loading" class="btn btn-lg btn-block f-weight-600" [ngClass]="{'btn-info':!flagSoggResp,'btn-primary':flagSoggResp}" type="submit">
          <img *ngIf="loading" class="pl-3 float-right"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          Applica a fasi selezionate
        </button>
            </div>
        </form>
    </div>
</ng-template>
<!--fine contenuto accordion-->

<ng-template #templateModalSogResp>
    <div class="modal-header  text-center">
        <h4 class="modal-title pull-left">Seleziona Soggetto responsabile</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefSogResp.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <ngx-datatable-wrapper #ngxDatatableWrapperSogResp [templates]="templateListSogResp" style="width: 100%; height: auto;" [dtOptions]="dtOptionsSoggResp">
        </ngx-datatable-wrapper>
        <ng-template #btnSelectSoggResp let-row="row" let-value="value" style="display:none;">
            <button [disabled]="loading" class="btn btn_icone btn-primary btn-sm btn-round" (click)="onSelectSoggResp(row)" data-toggle="tooltip" data-placement="top" title="Seleziona"><i class="fas fa-plus"></i> </button>
        </ng-template>
    </div>
</ng-template>