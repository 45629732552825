<div class="container bg-white">
  <div class="row justify-content-center mb-5">
    <div class="col-lg-8">
      <h3 class="text-center font-weight-bold mb-4 mt-3">
        Account
      </h3>
      <form [formGroup]="myGroup" class="">
        <div class="row">
          <div class="col-md-12" *ngIf="userInfo.username != '' && hasRoles() && !isManager()">
            <p>Tipo utente: <span class="text-uppercase font-weight-bold">{{ roleDescription }}</span></p>
          </div>
        </div>
        <div class="form-group" *ngIf="userInfo.username != '' && isManager()">
          <label>
            <span class="mandatory">Tipo utente:</span>
          </label>

          <ng-select [formGroup]="myGroup" formControlName="userType" [items]="userTypes" bindValue="key"
            bindLabel="value" (change)="onChangeUserType($event)" placeholder="Seleziona tipologia utente"
            [ngClass]="{ 'is-invalid': submitted && f.userType.errors }">
          </ng-select>

          <div *ngIf="submitted && f.userType.errors" class="invalidFieldMessage" style="display: block">
            <div *ngIf="f.userType.errors" class="invalid-feedback" style="display: block">
              Tipo di utente &egrave; obbligatorio
            </div>
          </div>
        </div>



        <div class="form-group" [hidden]="!isEsterno">
          <label for="enteAppartenenza" class="mandatory">Ente di appartenenza:</label>

          <ng-select [formGroup]="myGroup" formControlName="enteAppartenenza" [items]="listaEnti" bindValue="id"
            bindLabel="denominazione" placeholder="Seleziona ente"
            [ngClass]="{'is-invalid': submitted && f.enteAppartenenza.errors}">
          </ng-select>

          <div *ngIf="submitted && f.enteAppartenenza.errors" class="invalidFieldMessage" style="display: block">
            <div *ngIf="f.enteAppartenenza.errors" class="invalid-feedback" style="display: block">
              campo &egrave; obbligatorio
            </div>
          </div>
        </div>
        <div class="alert alert-info" [hidden]="!isEsterno">
          <p>
            Gentile nuovo utente, ti invitiamo a compilare e sottoscrivere
            l’autocertificazione
            <a href="/inframob/assets/docs/Autocertificazione.doc" target="_blank">scaricabile qui</a>. Appena
            conclusa questa procedura di registrazione, ti
            chiediamo di inviare la certificazione ed i documenti
            richiesti all’indirizzo
            <a href="mailto:inframob@regione.lazio.it">test@test.it</a>. L’utenza verrà attivata a verifiche
            concluse.
          </p>
        </div>



        <div *ngIf="canSeeAreeAppartenenza && !isEsterno()" id="myContainer" class="form-group px-3 form-istituzione">
          <label class="">Area di appartenenza:</label>

          <div class="form-inline">
            <div [ngClass]="{ 'is-invalid': selectedAreaInvalid }" class="col-md-9 col-xl-10 px-0">
              <ng-select [formGroup]="myGroup" formControlName="selectedArea" [items]="areeTematiche" bindValue="id"
                placeholder="Seleziona" bindLabel="descrizione" selectOnTab="true" [searchable]="true"
                [clearable]="false" notFoundText="Nessun risultato trovato...">
              </ng-select>
            </div>
            <div class="col-md-3 col-xl-2 pr-0 pl-0 pl-md-2">
              <button class="btn btn-sm btn-secondary btn-block font-weight-bold" (click)="setArea()"
                [disabled]="saving">
                <i class="fas fa-arrow-right"></i>&nbsp;&nbsp;INSERISCI
              </button>
            </div>
          </div>
          <div *ngIf="areaFieldInvalid" class="invalidFieldMessage">
            <div class="invalid-feedback" style="display: block">
              Selezionare almeno un'area
            </div>
          </div>

          <div id="instList">
            <div *ngFor="let area of userInfo.areeTematiche; index as i" class="form-inline mpi_inseriti">
              <input #school{{i}} type="text" class="form-control col-md-9 col-xl-10"
                placeholder="{{ area.descrizione }}" readonly />
              <div class="col-md-3 col-xl-2 pr-0 pl-0 pl-md-2">
                <button [disabled]="saving" class="btn btn-sm btn-primary btn-block" (click)="deleteArea(area)">
                  <i class="far fa-trash-alt text-white"></i>&nbsp;&nbsp;ELIMINA
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--<div class="row mx-0">
            <div class="col-md-12 riepilogodati">
              <p>
                Codice fiscale:
                <span>{{ userInfo.fiscalCode | uppercase }}</span>
              </p>
            </div>
          </div>-->

        <div class="row ">
          <div class="col-md-12 ">
            <p class="">
              Username:
              <span class="font-weight-bold">{{ userInfo.username }}</span>
            </p>
          </div>
        </div>

        <!--<div class="row mx-0">
            <div class="col-md-6 riepilogodati">
              <p>
                Cognome:
                <span>{{ userInfo.lastName | titlecase }}</span>
              </p>
            </div>

            <div class="col-md-6 riepilogodati">
              <p>
                Nome:
                <span>{{ userInfo.firstName | titlecase }}</span>
              </p>
            </div>
          </div>

          <div class="row mx-0">
            <div class="col-md-6 riepilogodati">
              <p>
                Data di nascita:
                <span>{{ userInfo.birthDay }}</span>
              </p>
            </div>

            <div class="col-md-6 riepilogodati">
              <p>
                Nazione di nascita:
                <span>{{ userInfo.nationName }}</span>
              </p>
            </div>
          </div>

          <div class="row mx-0">
            <div class="col-md-6 riepilogodati">
              <p>
                Provincia di nascita:
                <span>{{ userInfo.provinceName }}</span>
              </p>
            </div>

            <div class="col-md-6 riepilogodati">
              <p>
                Comune di nascita:
                <span>{{ userInfo.municipalityName }}</span>
              </p>
            </div>
          </div>

          <div class="row mx-0">
            <div class="col-md-12 riepilogodati">
              <p>
                Sesso:
                <span>{{ userInfo.male?'MASCHIO':'FEMMINA' }}</span>
              </p>
            </div>
          </div>-->


        <div class="form-group">
          <label>
            <span class="mandatory">Cognome:</span>
          </label>
          <input formControlName="lastname" class="form-control" type="text" [ngClass]="{
                    'is-invalid': submitted && myGroup.controls.lastname.errors
                  }" />


          <div *ngIf="submitted && myGroup.controls.lastname.errors" class="invalid-feedback" style="display: block">
            <div *ngIf="myGroup.controls.lastname.errors.required">
              Cognome &egrave; obbligatorio
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>
            <span class="mandatory">Nome:</span>
          </label>
          <input formControlName="firstname" class="form-control" type="text" [ngClass]="{
                    'is-invalid': submitted && myGroup.controls.firstname.errors
                  }" />


          <div *ngIf="submitted && myGroup.controls.firstname.errors" class="invalid-feedback" style="display: block">
            <div *ngIf="myGroup.controls.firstname.errors.required">
              Nome &egrave; obbligatorio
            </div>
          </div>
        </div>


        <div class="form-group">
          <label>
            <span class="mandatory">Email:</span>
          </label>
          <input formControlName="email" class="form-control" type="email" [ngClass]="{
                    'is-invalid': submitted && myGroup.controls.email.errors
                  }" />


          <div *ngIf="submitted && myGroup.controls.email.errors" class="invalid-feedback" style="display: block">
            <div *ngIf="myGroup.controls.email.errors.required">
              Email &egrave; obbligatoria
            </div>
            <div *ngIf="myGroup.controls.email.errors.email">
              Email non valida
            </div>
          </div>

        </div>

        <div *ngIf="isAdmin()" class="form-group">
          <label for="menu">Menù:</label>

          <ng-select [formGroup]="myGroup" formControlName="menu" [items]="menus" bindValue="id" bindLabel="code"
            placeholder="Seleziona menù">
          </ng-select>

        </div>

        <!--<hr *ngIf="!isAdmin() || userInfo.isDocsOk" />

          <div *ngIf="!isAdmin() || userInfo.isDocsOk" class="row mx-0 pb-3">
            <div class="col-md-12 text-center bold">
              DOCUMENTI ALLEGATI IN FASE DI REGISTRAZIONE
            </div>
          </div>

          <div *ngIf="!isAdmin() || userInfo.isDocsOk" class="row mx-0 pb-3">
            <label class="col-md riepilogodati">CARTA IDENTITA' o PASSAPORTO&nbsp;&nbsp;</label>
                        <button (click)="downloadDocument('id')" class="btn btn-sm btn-success btn-block col-md">
              documento allegato
            </button>
                    </div>

                    <div *ngIf="!isAdmin() || userInfo.isDocsOk" class="row mx-0 pb-3">
                        <label class="col-md riepilogodati">CODICE FISCALE&nbsp;&nbsp;</label>
                        <button (click)="downloadDocument('fc')" class="btn btn-sm btn-success btn-block col-md" name="fc">
              documento allegato
            </button>
                    </div>

                    <hr />

                    <div *ngIf="!isAdmin() || userInfo.isDocsOk" class="row">
                        <button (click)="deleteMyAccount()" id="contenuto_btn_elimina" type="button" class="btn btn-primary mx-auto" style="margin-top: 10px" [disabled]="saving">
              <i class="fa fa-trash" aria-hidden="true"></i>
              &nbsp;ELIMINA SOTTOSCRIZIONE
            </button>
                    </div>-->



        <button [disabled]="saving" class="btn btn-primary btn-lg btn-block "
          (click)="editAccount()">
          Salva <span *ngIf="saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>

      </form>
    </div>

  </div>
</div>
