import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { Aggregato } from '../models';

class DataTablesResponse {
  data: Aggregato[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class AggregatoService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}Aggregati/`;
  private publicApiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob_public}Aggregati/`;
  constructor(private http: HttpClient) { }

  getAlldt(dataTablesParameters: any) {
      return this.http.post<DataTablesResponse>(
          `${this.apiUrl}alldt`,
          dataTablesParameters
      );
  }
  getAll() {
      return this.http.get<Aggregato[]>(`${this.publicApiUrl}`);
  }
  insert(s: Aggregato) {
      return this.http.post<any>(`${this.apiUrl}`, s);
  }
  update(s: Aggregato) {
      return this.http.put<any>(`${this.apiUrl}`, s);
  }
  delete(id: number) {
      return this.http.delete<any>(`${this.apiUrl}${id}`);
  }
  save(s:any){
    if (s.id) return this.update(s);
    else return this.insert(s);
  }
}
