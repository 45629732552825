import {
    Component,
    Input,
    OnInit,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
} from "@angular/core";
import * as moment from 'moment';
import { Observable } from "rxjs";
import { AuthService } from "src/app/services";

@Component({
    selector: "dashboard-fasi-details-table-component",
    templateUrl: "./dashboard-fasi-details-table.component.html",
    styleUrls: ["./dashboard-fasi-details-table.component.css"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardFasiDetailsTableComponent implements OnInit {



    @Input()
    tabellaDettaglioObj: any; // Observable<any>;

    @Input()
    observable$: Observable<any>;

    constructor(private authService:AuthService) { }
    ngOnInit(): void { }


    get currentObject(): any {
        if (this.observable$) return this.observable$;
        if (this.tabellaDettaglioObj) return this.tabellaDettaglioObj;
    }

    trackByFn(index, item) {
        return index;
    }

    printDate(date) {
        if (!date) return '';
        let v = moment(date).toDate();
        //v.setHours(v.getHours() - v.getTimezoneOffset() / 60);
        return moment(v).format('DD/MM/YYYY');
    }

    printScostamentoRitardo(fase) {
        //if (!fase.dataPrevistaInizio || !fase.dataInizio)return '';
        let dataPrevistaInizio = moment(fase.dataPrevistaInizio).toDate();
        let dataInizio = moment(fase.dataInizio ? fase.dataInizio : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())).toDate();
        return ((Math.abs(dataInizio.getTime() - dataPrevistaInizio.getTime())) / (24 * 3600 * 1000)).toFixed(0);
    }
    printScostamentoScaduto(fase) {
        //if (!fase.dataPrevistaFine || !fase.dataFine)return '';
        let dataPrevistaFine = moment(fase.dataPrevistaFine).toDate();
        let dataFine = moment(fase.dataFine ? fase.dataFine : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())).toDate();
        return ((Math.abs(dataFine.getTime() - dataPrevistaFine.getTime())) / (24 * 3600 * 1000)).toFixed(0)
    }
    getSoggettoResponsabileText(fase) {
        let result = [];
        if (!fase.soggettiResponsabili)return '';
        fase.soggettiResponsabili.forEach(
            x => result.push(`${x.tipologia.descrizione} - ${x.cognome} ${x.nome}`)
        );
        return result;
    }

    printStatoFase(fase) {
        let dataAttuale = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())).toDate();
        if (fase.dataFine) return "conclusa";
        if (!fase.dataInizio) {
            let dataPrevistaInizio = moment(fase.dataPrevistaInizio).toDate();
            if (dataAttuale > dataPrevistaInizio) return "in ritardo";
            return 'futura';
        }
        let dataPrevistaFine = moment(fase.dataPrevistaFine).toDate();
        if (dataPrevistaFine < dataAttuale) return "scaduta";
        if (
            ((dataPrevistaFine.getTime() - dataAttuale.getTime()) / (24 * 3600 * 1000)) <= 7
        ) return "in scadenza";
        return "in corso";
    }
    getColorStatoFase(fase) {
        let calcolaColore = (giorniRitardo) => {
            if (giorniRitardo <= 30) return 'bg-ffc533 d-inline-block';
            if (giorniRitardo > 30 && giorniRitardo <= 60) return 'bg-f48c06 d-inline-block';
            if (giorniRitardo > 60 && giorniRitardo <= 90) return 'bg-fdcf02 d-inline-block';
            if (giorniRitardo > 90) return 'bg-a3000 d-inline-block';
            //1-30gg = .bg-ffc533
            //31-60gg = .bg-f48c06
            //61-90gg = .bg-fdcf02
            //>90gg = .bg-a3000
            return 'd-none';
        }

        let dataAttuale = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())).toDate();
        if (fase.dataFine) return 'd-none';
        if (!fase.dataInizio) {
            let dataPrevistaInizio = moment(fase.dataPrevistaInizio).toDate();
            if (dataAttuale > dataPrevistaInizio) return calcolaColore(((Math.abs(dataAttuale.getTime() - dataPrevistaInizio.getTime())) / (24 * 3600 * 1000)).toFixed(0));
            return 'd-none';
        }
        let dataPrevistaFine = moment(fase.dataPrevistaFine).toDate();
        if (dataPrevistaFine < dataAttuale) return calcolaColore(((Math.abs(dataAttuale.getTime() - dataPrevistaFine.getTime())) / (24 * 3600 * 1000)).toFixed(0));
        return 'd-none';
    }

    getColorStatoFase1(fase) {
        let calcolaColore = (giorniRitardo) => {
            if (giorniRitardo <= 30) return 'bassa';
            if (giorniRitardo > 30 && giorniRitardo <= 60) return 'medio-bassa';
            if (giorniRitardo > 60 && giorniRitardo <= 90) return 'media';
            if (giorniRitardo > 90) return 'alta';
            //1-30gg = .bg-ffc533
            //31-60gg = .bg-f48c06
            //61-90gg = .bg-fdcf02
            //>90gg = .bg-a3000
            return 'd-none';
        }

        let dataAttuale = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())).toDate();
        if (fase.dataFine) return 'd-none';
        if (!fase.dataInizio) {
            let dataPrevistaInizio = moment(fase.dataPrevistaInizio).toDate();
            if (dataAttuale > dataPrevistaInizio) return calcolaColore(((Math.abs(dataAttuale.getTime() - dataPrevistaInizio.getTime())) / (24 * 3600 * 1000)).toFixed(0));
            return 'd-none';
        }
        let dataPrevistaFine = moment(fase.dataPrevistaFine).toDate();
        if (dataPrevistaFine < dataAttuale) return calcolaColore(((Math.abs(dataAttuale.getTime() - dataPrevistaFine.getTime())) / (24 * 3600 * 1000)).toFixed(0));
        return 'd-none';
    }


    printValueAsDateWithCurrentDateAsDefault(value) {
        if (!value) value = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())).toDate();
        return this.printValueAsDate(value);
    }

    printValueAsDate(value) {
        if (!value || value == '') return '';
        let v = moment(value).toDate();
        //v.setHours(v.getHours() - v.getTimezoneOffset() / 60);
        return moment(v).format('DD/MM/YYYY');
    }

    getFasi(progetto) {
        if (progetto.fasi && progetto.fasi.length > 0) return progetto.fasi;
        return progetto.fasiProgetto;
    }

    /*getPopOverText(text){
        console.log(text);
        return text;
    } */

    trimText(text, size) {
        if (!size) return text;
        if (text.length <= size) return text;
        return text.substring(0, size) + '...';
    }

    @Input()
    showSelectColumn = false;

    codesSelected = [];
    
    addRemoveSelection(codice:string){
        if (!this.codesSelected) this.codesSelected = [];
        if (this.codesSelected.includes(codice)){
            this.codesSelected = this.codesSelected.filter(e => e !=codice);
        }
        else{
            this.codesSelected.push(codice);
        }
        this.codesSelectedChanged.emit(this.codesSelected);
    }

    @Output()
    codesSelectedChanged: EventEmitter<any> = new EventEmitter();

    isGr34(){
        return this.authService.isGr34();
    }
    isAdmin(){
        return this.authService.isAdmin();
    }

}
