<ng-template #templateEditAdd>
    <div class="col-lg-12">
        <form [formGroup]="addSchoolYearForm" (ngSubmit)="onSubmit()">
            <input type="hidden" [formGroup]="addSchoolYearForm" formControlName="id" />
            <div class="row">
                <div class="form-group col-lg-6" *ngIf="!isMotivazioni">

                    <h5><label for="priorita" class="mandatory">Priorità:</label></h5>
                    <input [formGroup]="addSchoolYearForm" formControlName="priorita" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci il titolo" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submitted && f.priorita.errors
                      }" />
                    <div *ngIf="submitted && f.priorita.errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="f.priorita.errors.required">
                            Campo obbligatorio
                        </div>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'col-lg-12' : isMotivazioni, 'col-lg-6':!isMotivazioni}">
                    <h5> <label for="motivazione" class="mandatory">Motivazione:</label></h5>
                    <input [formGroup]="addSchoolYearForm" formControlName="motivazione" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci la fase" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submitted && f.motivazione.errors
                      }" />
                    <div *ngIf="submitted && f.motivazione.errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="f.motivazione.errors.required">
                            Campo obbligatorio
                        </div>
                    </div>
                </div>
                

            </div>            
            <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
            {{buttonLabel}} 
        </button>
        </form>
    </div>
</ng-template>

<div class="container sfondo_bianco">
    <hr />
    <accordion [isAnimated]="true">
        <accordion-group heading="{{isMotivazioni?'Inserisci nuova motivazione':'Inserisci nuova priorità'}}">
            <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
        </accordion-group>
    </accordion>
    <div class="mt-5">

        <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptions">
            <ng-template #btnEditTemplate let-row="row" let-value="value">
                <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top" title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
            </ng-template>
            <ng-template #btnDeleteTemplate let-row="row" let-value="value">
                <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
            </ng-template>            
        </ngx-datatable-wrapper>
    </div>
</div>
<ng-template #templateModal>
    <div class="modal-header  text-center">
        <h4 class="modal-title pull-left">{{isMotivazioni?'Modifica motivazione':'Modifica priorità motivazione'}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
    </div>
    <div class="modal-body">
        <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
    </div>
</ng-template>