import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-row-menu',
  templateUrl: './row-menu.component.html',
  styleUrls: ['./row-menu.component.css']
})
export class RowMenuComponent implements OnInit {

  @ViewChild('view')
  rowContainer: ElementRef;
  @Input()
  menuItem: any;
  initialY: number = null;
  offsetTop: number = 100;

  constructor() { }

  ngOnInit() { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initialY = this.rowContainer.nativeElement.getBoundingClientRect().y - this.offsetTop;
    }, 1000);
  }

  goToView() {
    this.initialY !== null ? window.scrollTo({ top: this.initialY, behavior: 'smooth' }) : null;
  }
}
