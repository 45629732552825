<div [formGroup]="myGroup" class="tab-pane" id="passaggio2">
    <div class="form-registrazione">

        <div class="user-type">
            <div class="form-check form-check-inline">
                <input
                  [formGroup]="myGroup"
                  id="tipoPrincipale1"
                  formControlName="tipoPrincipale"
                  type="radio"
                  value="1"
                  class="form-check-input"
                  [ngClass]="{
                    'is-invalid': submitted && f.tipoPrincipale.errors
                  }"
                  (click)="tipoPrincipaleClick($event,1)"
                />
    
                <label for="tipoPrincipale1" class="form-check-label">Regione Lazio / Partecipate</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  [formGroup]="myGroup"
                  id="tipoPrincipale2"
                  formControlName="tipoPrincipale"
                  type="radio"
                  value="0"
                  class="form-check-input"
                  [ngClass]="{
                    'is-invalid': submitted && f.tipoPrincipale.errors
                  }"
                  (click)="tipoPrincipaleClick($event,0)"
                />
                <label for="tipoPrincipale2" class="form-check-label">Altro Ente</label>
              </div>
        </div>

        <div class="user-type" [hidden]="!f.tipoPrincipale.value || f.tipoPrincipale.value=='1'">
            <label for="enteAppartenenza" class="mandatory">Ente di appartenenza:</label><br />

            <div class="form-group" [ngClass]="{ 'is-invalid': submitted && f.enteAppartenenza.errors }">
                <ng-select [formGroup]="myGroup" formControlName="enteAppartenenza" [items]="listaEnti" bindValue="id" bindLabel="denominazione"  placeholder="Seleziona ente">
                </ng-select>
            </div>
            <div *ngIf="submitted && f.enteAppartenenza.errors" class="invalidFieldMessage" style="display: block;">
                <div *ngIf="f.enteAppartenenza.errors" class="invalid-feedback" style="display: block;">
                    campo &egrave; obbligatorio
                </div>
            </div>
        </div>
        <div class="user-type" [hidden]="!f.tipoPrincipale.value || f.tipoPrincipale.value=='1'">
            <p>Gentile nuovo utente, ti invitiamo a compilare e sottoscrivere l’autocertificazione <a href="/inframob/assets/docs/Autocertificazione.doc" target="_blank">scaricabile qui</a>. Appena conclusa questa procedura di registrazione, ti chiediamo di inviare la certificazione ed i documenti richiesti all’indirizzo <a href="mailto:inframob@regione.lazio.it">inframob@regione.lazio.it</a>. L’utenza verrà attivata a verifiche concluse.</p>
        </div>



        <div class="user-type" [hidden]="!f.tipoPrincipale.value || f.tipoPrincipale.value=='0'">
            <label for="userType" class="mandatory">TIPOLOGIA UTENTE:</label><br />

            <div class="form-group" [ngClass]="{ 'is-invalid': submitted && f.userType.errors }">
                <ng-select [formGroup]="myGroup" formControlName="userType" [items]="userTypesForDropDown" bindValue="key" bindLabel="value" (change)="onChangeUserType($event)" placeholder="Seleziona tipologia utente">
                </ng-select>
            </div>
            <div *ngIf="submitted && f.userType.errors" class="invalidFieldMessage" style="display: block;">
                <div *ngIf="f.userType.errors" class="invalid-feedback" style="display: block;">
                    Tipo di utente &egrave; obbligatorio
                </div>
            </div>
        </div>



        <!-- start -->
        <div [hidden]="!f.tipoPrincipale.value || f.tipoPrincipale.value=='0'" id="myContainer" *ngIf="isExternal()|| isAreaStaff() || isDirectionalGroup()" 
        class="form-group form-istituzione">
            <label for="selectedArea mandatory">AREA DI APPARTENENZA:</label><br />

            <div class="form-inline">
                <div [ngClass]="{ 'is-invalid': selectedAreaInvalid }" style="width:80%;">

                    <ng-select [formGroup]="myGroup" formControlName="selectedArea" [items]="areeTematiche" bindValue="id" placeholder="Seleziona" bindLabel="descrizione" selectOnTab="true" [searchable]="true" [clearable]="false" notFoundText="Nessun risultato trovato...">
                    </ng-select>
                </div>

                <button class="btn btn-sm btn-secondary btn-block col-xl-2 col-lg-3 col-md-3 col-sm-12 m-2" (click)="setArea()">
          <i class="fas fa-arrow-right"></i> &nbsp;INSERISCI
        </button>
            </div>

            <div *ngIf="selectedAreaInvalid" class="invalidFieldMessage">
                <div class="invalid-feedback" style="display: block;">
                    Selezionare almeno un'area
                </div>
            </div>

            <div id="instList">
                <div *ngFor="let area of myUser.areeTematiche; index as i" class="form-inline mpi_inseriti">
                    <input #school{{i}} type="text" class="form-control" placeholder="{{ area.descrizione }}" readonly style="display:inline; width:80%;" />
                    <button class="btn btn-sm btn-primary btn-block col-xl-2 col-lg-3 col-md-3 col-sm-12 m-2" style="margin:5px 0;width:25%;float:right;" (click)="deleteArea(area)">
            <i class="far fa-trash-alt text-white"></i> &nbsp;ELIMINA
          </button>
                </div>
            </div>
        </div>


        
        <!-- end -->
        <div class="form-group" >
            <label for="email" class="mandatory">Cognome:</label>
            <input [formGroup]="myGroup" type="text" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" placeholder="Inserisci il tuo cognome" />
            <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.lastname.errors.required">Cognome &egrave; obbligatorio</div>                
            </div>
        </div>
        <div class="form-group">
            <label for="email" class="mandatory">Nome:</label>
            <input [formGroup]="myGroup" type="text" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" placeholder="Inserisci il tuo nome" />
            <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.firstname.errors.required">Nome &egrave; obbligatorio</div>                
            </div>
        </div>
        <div class="form-group">
            <label for="fiscalCode" class="mandatory">Codice Fiscale:</label>
            <input [formGroup]="myGroup" type="text" formControlName="fiscalCode" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fiscalCode.errors }" placeholder="Inserisci il tuo codice fiscale" />
            <div *ngIf="submitted && f.fiscalCode.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.fiscalCode.errors.required">codice fiscale &egrave; obbligatorio</div>                
            </div>
        </div>

        <div class="form-group">
            <label for="email" class="mandatory">Email:</label>
            <input [formGroup]="myGroup" type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Inserisci la tua email" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.email.errors.required">Email &egrave; obbligatoria</div>
                <div *ngIf="f.email.errors.email">Email non valida</div>
            </div>
        </div>
        

        <div class="form-group">
            <label for="emailconf" class="mandatory">Conferma email:</label>
            <input [formGroup]="myGroup" type="email" (paste)="$event.preventDefault()" formControlName="emailconf" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emailconf.errors }" placeholder="Inserisci la tua email" />
            <div *ngIf="submitted && f.emailconf.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.emailconf.errors.required">Email &egrave; obbligatoria</div>
                <div *ngIf="f.emailconf.errors.email">Email non valida</div>
                <div *ngIf="f.emailconf.errors.matchOther">Email e conferma email non sono corrispondenti</div>
            </div>
        </div>
        <div class="my-3">
            <button class="btn btn-lg btn-primary btn-block" (click)="onSubmit()">
        CONFERMA I TUOI DATI
      </button>
        </div>
    </div>
</div>