<div class="modal-header text-center">
    <h4 class="modal-title pull-left">Seleziona capitolo di bilancio</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">


    <section class="fascia-grey-login" style="margin:0px;padding-top:20px">
        <div class="container">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <form [formGroup]="myGroup" novalidate>
                        <div class="form-registrazione ">

                            <div class="form-group user-type ">
                                <div class="row">
                                    <h5> <label class="mandatory col-lg-12">Capitolo di bilancio:</label></h5>
                                    <div class="col-lg-11 col-10">
                                        <div [ngClass]="{ 'is-invalid': submitted && !capitoloSelected }">
                                            <app-completer [cpOptions]="completerParams" (onSelectEmitter)="capitoloOnChange($event) ">
                                            </app-completer>
                                        </div>
                                        <!-- <div class="form-group " [ngClass]="{ 'is-invalid': submitted && f.capitolo.errors } ">
                      <ng-select [formGroup]="myGroup" formControlName="capitolo " [items]="capitoli " bindValue="id "
                        bindLabel="descrizioneEstesa "  placeholder="Seleziona capitolo di bilancio " (change)="capitoloOnChange($event) ">
                      </ng-select>
                    </div>-->
                                    </div>


                                    <div class="col-lg-1 col-2">
                                        <a href="javascript:;; " class="bottone-modifica" (click)="openEditCapitoliBilancio();">
                                            <i class="far fa-edit"></i></a>

                                    </div>

                                </div>
                                <div *ngIf="submitted && !capitoloSelected" class="invalidFieldMessage" style="display: block;">
                                    <div class="invalid-feedback" style="display: block;">
                                        Campo obbligatorio
                                    </div>
                                </div>
                            </div>


                            <div class="form-group">
                                <h5><label for="anno" class="mandatory ">Anno :</label></h5>
                                <ng-select [formGroup]="myGroup" formControlName="anno" [items]="anniList" bindValue="k" bindLabel="k" placeholder="Seleziona un anno" [ngClass]="{ 'is-invalid': submitted && f.anno.errors} ">
                                </ng-select>
                                <!--<input [formGroup]="myGroup" formControlName="anno " type="text " mask="0000 "
                      [showMaskTyped]="true " [ngClass]="{ 'form-control': true, 'is-invalid': submitted && f.anno.errors } " placeholder="Inserisci l 'anno" />-->
                                <div *ngIf="submitted && f.anno.errors" class="invalid-feedback" style="display: block;">
                                    <div *ngIf="f.anno.errors.required">
                                        Campo obbligatorio
                                    </div>
                                    <!--<div *ngIf="f.anno.errors.minlength">
                        Anno deve essere di 4 cifre
                      </div>
                      <div *ngIf="f.anno.errors.range">
                        Anno deve essere compreso tra il {{minYear}} e il {{maxYear}}
                      </div>-->

                                </div>
                            </div>
                            <div class="form-group">
                                <h5><label for="importo" class="mandatory">Importo:</label></h5>
                                <input [formGroup]="myGroup" formControlName="importo" type="text" [showMaskTyped]="false" mask="separator.2" thousandSeparator="." suffix=" €" class="form-control" placeholder="Inserisci l'importo " [ngClass]="{
                            'form-control': true, 'is-invalid': submitted && f.importo.errors } " />
                                <div *ngIf="submitted && f.importo.errors" class="invalid-feedback " style="display: block;">
                                    <div *ngIf="f.importo.errors.required">
                                        Campo obbligatorio
                                    </div>
                                </div>
                            </div>







                            <button class="btn btn-lg btn-primary btn-block" type="button" (click)="add()" style="margin:15px 0">
                Aggiungi
              </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>
<ng-template #templateEditCapitoloBilancio>
    <div class="modal-header text-center">
        <h4 class="modal-title pull-left">Modifica capitolo di bilancio</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalEditCapitoliBilancio()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <capitoli-bilancio-component (onSubmitOk)="refreshCapitoloBilancio($event)" [fastEditObject]="capitoloSelected">
        </capitoli-bilancio-component>
    </div>
</ng-template>