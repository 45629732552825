import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as FileSaver from "file-saver";
import { AuthService } from 'src/app/services/auth.service';
import { DocumentService } from '../../services/document.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { HttpClient } from '@angular/common/http';
import { ToastService } from 'src/app/services/toast.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';

@Component(
  {
    templateUrl: 'documents.component.html',
    styleUrls: ['documents.component.css']
  }
)
export class DocumentsComponent implements OnInit {
  dtOptions: any = {};
  submitted = false;
  attach: File = new File([""], "");

  addDocumentForm: FormGroup;

  dtOptionsNgx: any;
  @ViewChild('btnDeleteTemplate', { static: true })
  public btnDeleteTemplate: TemplateRef<any>;
  @ViewChild('btnDownloadTemplate', { static: true })
  public btnDownloadTemplate: TemplateRef<any>;
  templateList = [];
  @ViewChild('ngxDatatableWrapper')
  ngxDatatableWrapper: NgxDatatableWrapperComponent;

  constructor(
    private http: HttpClient,
    private el: ElementRef,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private documentService: DocumentService
    , private toastr: ToastService
  ) {
    console.log('into DocumentsComponent.constructor');
    this.dtOptionsNgx = {
      rows: []
      , totalElements: 0
      , columns: this.columns
      , columnMode: ColumnMode.flex
      , headerHeight: "50"
      , footerHeight: "50"
      , rowHeight: "auto"
      , externalSorting: true// true
      , loadingIndicator: 'loading'
      , class: "bootstrap"
      , summaryRow: true
      , summaryPosition: "'bottom'"
      , externalPaging: true// true
      , count: 0
      , offset: 0
      , limit: 10
      , serverSide: true
      , ajax: this.loadData
      , scrollbarH: "true"
    };
  }



  ngOnInit() {
    console.log('into DocumentsComponent.ngoninit');
    this.addDocumentForm = this.formBuilder.group({
      title: [null, Validators.required],
      attach: [null, Validators.required]
    });
    this.templateList = [
      { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }
      , { name: 'btnDownloadTemplate', template: this.btnDownloadTemplate }
    ];
  }





  // convenience getter for easy access to form fields
  get f() { return this.addDocumentForm.controls; }

  fileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];

      this.attach = file;
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addDocumentForm.invalid) {
      const invalidElements = this.el.nativeElement.querySelectorAll('.is-invalid');
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }
      return;
    }
    const formData: FormData = new FormData();
    formData.append("attach", this.attach, this.attach.name);
    formData.append("title", this.f.title.value);

    this.documentService.saveDocument(formData).subscribe(res => {
      this.attach = new File([""], "");
      this.submitted = false;
      this.addDocumentForm.reset();
      this.refreshGrid();
      this.toastr.success(`Documento salvato con successo`);
    });

  }

  isAdmin() {
    return this.authService.isAdmin() || this.authService.isManager();
  }



  downloadDocument(data: any) {
    this.documentService.downloadAttach(data).subscribe(res => {
      let filename = data.filePath.replace(/^.*[\\\/]/, '')
      FileSaver.saveAs(res.body, filename);
      //this.attach = new File([""], "");
      //this.modNews = null;
    });
  }


  deleteDocument(data: any) {
    const result: boolean = confirm(`Sei sicuro di voler eliminare il documento selezionato?`);
    if (result) {
      this.documentService.deleteDocument(data).subscribe(deleted => {
        if (deleted) {
          //alert(`News eliminata con successo`);
          this.toastr.success(`Documento eliminato con successo`);
          this.refreshGrid();
        }
      });
    }
  }






  columns = [
    {
      name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
      , resizeable: false
    }

    , {
      name: 'titolo documentazione'
      , prop: 'title'

      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , flexGrow: 14
      , minWidth: 200

    }
    , {
      name: 'scarica'
      , prop: null
      , sortable: false
      , filtrable: false
      , resizeable: false
      , draggable: false
      , cellTemplate: 'btnDownloadTemplate'
      , flexGrow: 1
      , minWidth: 60
    }

    , {
      name: 'elimina'
      , prop: null
      ,visible:this.isAdmin()
      , sortable: false
      , filtrable: false
      , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
      //, width: 10
      , resizeable: false
      , draggable: false
      , flexGrow: 1
      , minWidth: 60
    }
  ];

  loadData = (dataTablesParameters: any, callback: any) => {
    let that = this;
    that.http
      .post<any>(
        `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.document}alldt`,
        dataTablesParameters
      )
      .subscribe(resp => {
        callback(resp);
      });
  };
  refreshGrid() {
    this.ngxDatatableWrapper.refresh();
  }
}
