import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    TemplateRef,
    Input,
    Output,
    EventEmitter,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as FileSaver from "file-saver";
import { DocumentazioneService, InterventoService, FinanziamentoService, ProtocolloService, SicerService } from "../../services/index";
import { ToastService } from "src/app/services/toast.service";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { NgxDatatableWrapperComponent } from "src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component";
import { IsDirtyHelper } from "../../../../helpers/is-dirty.helper"
import * as moment from 'moment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from "../../../../services/auth.service";
import { AssociaProvvedimentiComponent } from "../associaProvvedimenti/associa-provvedimenti.component";
@Component({
    selector: "documentazione-component",
    templateUrl: "documentazione.component.html",
    styleUrls: ["documentazione.component.css"],
})
export class DocumentazioneComponent implements OnInit {
section:string ='';
    cntProtocolliInterni = 0;
    cntProtocolliEntrata = 0;
    cntProtocolliUscita = 0;

    dtOptions: any = {};
    submitted = false;
    attach: any;
    addDocumentForm: FormGroup;
    frm_protocolli: FormGroup;
    editNoteProtocollo: FormGroup;
    editNoteSicer: FormGroup;

    dtOptionsNgx: any;
    dtOptionsProtocolliEntrata: any;
    dtOptionsProtocolliUscita: any;
    dtOptionsProtocolliInterni: any;
    @ViewChild("btnDeleteTemplate", { static: true })
    public btnDeleteTemplate: TemplateRef<any>;
    @ViewChild("btnDownloadTemplate", { static: true })
    public btnDownloadTemplate: TemplateRef<any>;
    templateList = [];
    @ViewChild("ngxDatatableWrapper")
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    @ViewChild("ngxDatatableWrapperProtocolliEntrata")
    ngxDatatableWrapperProtocolliEntrata: NgxDatatableWrapperComponent;
    @ViewChild("ngxDatatableWrapperProtocolliUscita")
    ngxDatatableWrapperProtocolliUscita: NgxDatatableWrapperComponent;
    @ViewChild("ngxDatatableWrapperProtocolliInterni")
    ngxDatatableWrapperProtocolliInterni: NgxDatatableWrapperComponent;


    @ViewChild("ngxDatatableWrapperSicerAtti")
    ngxDatatableWrapperSicerAtti: NgxDatatableWrapperComponent;
    dtOptionsSicerAtti: any;
    @ViewChild("btnDeleteSicerAttiTemplate", { static: true })
    public btnDeleteSicerAttiTemplate: TemplateRef<any>;
    @ViewChild("noteSicerAttiTemplate", { static: true })
    public noteSicerAttiTemplate: TemplateRef<any>;

    @ViewChild("numeroAttoTemplate", { static: true })
    public numeroAttoTemplate: TemplateRef<any>;



    frm_sicer: FormGroup;


    @ViewChild("btnDeleteProsaTemplate", { static: true })
    public btnDeleteProsaTemplate: TemplateRef<any>;
    @ViewChild("numeroProtocolloTemplate", { static: true })
    public numeroProtocolloTemplate: TemplateRef<any>;


    @ViewChild("btnEditProsaTemplate", { static: true })
    public btnEditProsaTemplate: TemplateRef<any>;

    @ViewChild("noteProtocolloTemplate", { static: true })
    public noteProtocolloTemplate: TemplateRef<any>;

    @ViewChild("oggettoProtocolloTemplate", { static: true })
    public oggettoProtocolloTemplate: TemplateRef<any>;







    what = ""; //"finanziamenti" or "interventi"

    private _finanziamentoId: number;
    @Input()
    set finanziamentoId(value) {
        this.showAllData = false;
        console.log(`inside documentazione_component set finanziamentoId= ${value}`);
        this._finanziamentoId = value;
        this.section='Finanziamenti';
        if (!value) return;
        this.currentIntervento = null;
        this._interventoId = null;
        this._classificazioneId = null;
        this._faseId = null;
        this._subInterventoId= null;
        this.what = "Finanziamenti";
        this.getFinanziamentoData(value);        
    }
    get finanziamentoId() {
        return this._finanziamentoId;
    }


    private _classificazioneId: number;
    @Input()
    set classificazioneId(value) {
        this.showAllData = false;
        console.log(`inside set _classificazioneId= ${value}`);
        this._classificazioneId = value;
        this.section = 'Classificazioni';
        if (!value) return;
        this.currentIntervento = null;
        this._interventoId = null;
        this._finanziamentoId = null;
        this._subInterventoId= null;
        this._faseId=null;

        this.what = "Classificazioni";
        this.getClassificazioneData(value);
        this.loadProtocolliTable();
    }
    get classificazioneId() {
        return this._classificazioneId;
    }





    private _interventoId: number;
    @Input()
    set interventoId(value) {
        this.showAllData = false;
        this._interventoId = value;
        this.section = 'Interventi';
        if (!value) return;
        this.what = 'Interventi';
        this._finanziamentoId = null;
        this._classificazioneId = null;
        this._faseId = null;
        this._subInterventoId= null;
        this.getInterventoData(value);      
    }
    get interventoId() {
        return this._interventoId;
    }


    constructor(
        private el: ElementRef,
        private formBuilder: FormBuilder,
        private documentService: DocumentazioneService,
        private toastr: ToastService,
        private interventoService: InterventoService,
        private finanziamentoService: FinanziamentoService
        , private isDirtyHelper: IsDirtyHelper
        , private protocolloService: ProtocolloService,
        private modalService: BsModalService,
        private authService: AuthService,
        private sicerService: SicerService
    ) {
        
        this.dtOptionsNgx = {
            rows: [],
            totalElements: 0,
            columns: this.columns,
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            ajax: this.loadData,
            scrollbarH: "true",
        };

        this.dtOptionsProtocolliUscita = {
            rows: [],
            totalElements: 0,
            columns: this.columnsProtocolli,
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            ajax: this.loadDataProtocolliUscita,
            scrollbarH: "true",
            sorts: [{ dir: "asc", prop: "codice" }],
        };
        this.dtOptionsProtocolliEntrata = {
            rows: [],
            totalElements: 0,
            columns: this.columnsProtocolli,
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            ajax: this.loadDataProtocolliEntrata,
            scrollbarH: "true",
            sorts: [{ dir: "asc", prop: "codice" }],
        };
        this.dtOptionsProtocolliInterni = {
            rows: [],
            totalElements: 0,
            columns: this.columnsProtocolli,
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            ajax: this.loadDataProtocolliInterni,
            scrollbarH: "true",
            sorts: [{ dir: "asc", prop: "codice" }],
        };

        this.dtOptionsSicerAtti = {
            rows: [],
            totalElements: 0,
            columns: this.columnsSicerAtti,
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            ajax: this.loadSicerAtti,
            scrollbarH: "true",
            sorts: [{ dir: "asc", prop: "numero" }],
        };
    }

    setIsDirty(value: boolean) {
        this.isDirtyHelper.isDirty = value;
    }

    ngOnInit() {
        this.addDocumentForm = this.formBuilder.group({
            title: [null, Validators.required],
            attach: [null, Validators.required],
        });

        this.editNoteProtocollo = this.formBuilder.group(
            {
                note_protocollo: [null]
                , id: [null]
            }
        );
        this.editNoteSicer = this.formBuilder.group(
            {
                note: [null]
                , id: [null]
            }
        );


        this.addDocumentForm.valueChanges.subscribe(val =>
            this.setIsDirty(this.addDocumentForm.dirty)
        );
        this.templateList = [
            { name: "btnDeleteTemplate", template: this.btnDeleteTemplate },
            { name: "btnDownloadTemplate", template: this.btnDownloadTemplate },
            { name: "btnDeleteProsaTemplate", template: this.btnDeleteProsaTemplate },
            { name: "numeroProtocolloTemplate", template: this.numeroProtocolloTemplate },
            { name: "btnEditProsaTemplate", template: this.btnEditProsaTemplate },
            { name: "noteProtocolloTemplate", template: this.noteProtocolloTemplate },
            { name: "oggettoProtocolloTemplate", template: this.oggettoProtocolloTemplate },
            { name: "noteSicerAttiTemplate", template: this.noteSicerAttiTemplate },
            { name: "btnDeleteSicerAttiTemplate", template: this.btnDeleteSicerAttiTemplate },
            { name: "numeroAttoTemplate", template: this.numeroAttoTemplate },

        ];


        this.frm_protocolli = this.formBuilder.group(
            {
                protocollo_anno: [null]
                , protocollo_numero: [null]
            }
        );
        this.frm_sicer = this.formBuilder.group(
            {
                sicer_anno: [null]
                , sicer_numero: [null]
            }
        );
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.addDocumentForm.controls;
    }

    fileChange(event) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            const file: File = fileList[0];

            this.attach = file;
            this.setIsDirty(true);
        }
    }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.addDocumentForm.invalid) {
            const invalidElements = this.el.nativeElement.querySelectorAll(
                ".is-invalid"
            );
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }
            return;
        }
        const formData: FormData = new FormData();
        formData.append("attach", this.attach, this.attach.name);
        formData.append("title", this.f.title.value);

        if (!this.what && this.what == "") return;
        let currentId = this.getCurrentId();

        this.documentService
            .saveDocument(this.what, currentId, formData)
            .subscribe((res) => {
                this.attach = new File([""], "");
                this.submitted = false;
                this.addDocumentForm.reset();
                this.refreshGrid();
                this.toastr.success(`Documento salvato con successo`);
                this.setIsDirty(false);
                this.onSubmitEmitter.emit(res);
            }
                , error => {
                    this.toastr.error(`Impossibile salvare il documento: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                        timeOut: 2000,disableTimeOut: false
                    });
                    console.log('error');
                });
    }

    downloadDocument(data: any) {    
            this.documentService
            .downloadAttachById(  data.id)
            .subscribe((res) => {
                let filename = data.filePath.replace(/^.*[\\\/]/, "");
                FileSaver.saveAs(res.body, filename);               
            });
    }

    deleteDocument(data: any) {
        const result: boolean = confirm(
            `Sei sicuro di voler eliminare il documento selezionato?`
        );
        if (result) {
            if (!this.what && this.what == "") return;
            let currentId = this.getCurrentId();
            this.documentService
                .deleteDocument(this.what, currentId, data)
                .subscribe((deleted) => {
                    this.toastr.success("Documento eliminato con successo", null, {
                        timeOut: 2000,
               disableTimeOut: false,
                    });
                    this.refreshGrid();
                    this.onSubmitEmitter.emit(null);
                }
                    , error => {
                        this.toastr.error(`Impossibile eliminare il documento: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                            timeOut: 2000, disableTimeOut: false
                        });
                        console.log('error');
                    }
                );
        }
    }

    columns = [
        {
            name: "id",
            prop: "id",
            visible: false,
            sortable: true,
            filtrable: true,
            width: 10,
            resizeable: false,
        },
        {
            name: "codice",
            prop: "codice",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 2,
            minWidth: 60,
        }
        ,
        {
            name: "descrizione",
            prop: "descrizione",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 4,
            minWidth: 120,
        },

        {
            name: "titolo documentazione",
            prop: "title",

            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 4,
            minWidth: 200,
        },
        {
            name: "scarica",
            prop: null,
            sortable: false,
            filtrable: false,
            resizeable: false,
            draggable: false,
            cellTemplate: "btnDownloadTemplate",
            flexGrow: 1,
            minWidth: 55,
        },

        {
            name: "elimina",
            prop: null,
            sortable: false,
            filtrable: false,
            cellTemplate: "btnDeleteTemplate", // 'buttonsTemplate'
            //, width: 10
            resizeable: false,
            draggable: false,
            flexGrow: 1,
            minWidth: 65,
        },
    ];


    columnsProtocolli = [
        {
            name: "id",
            prop: "id",
            visible: false,
            sortable: true,
            filtrable: true,
            width: 10,
            resizeable: false,
        },

        {
            name: "codice",
            prop: "codice",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 2,
            minWidth: 60,
        }
        ,
        {
            name: "descrizione",
            prop: "descrizione",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 4,
            minWidth: 120,
        },



        {
            name: "numero protocollo",
            prop: "numeroProtocollo",
            cellTemplate: "numeroProtocolloTemplate",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 4,
            minWidth: 120,
        },
        {
            name: "data<br />protocollo",
            prop: "dataProtocollo",
            cellTemplate: "dateValueTemplate",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 2,
            minWidth: 60,
        },
        {
            name: "data<br />acquisizione",
            prop: "dataAcquisizione",
            cellTemplate: "dateValueTemplate",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 2,
            minWidth: 60,
        },
        {
            name: "oggetto",
            prop: "oggetto",
            cellTemplate: 'oggettoProtocolloTemplate',
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 4,
            minWidth: 120,
        },
        {
            name: "note",
            prop: "note",
            cellTemplate: 'noteProtocolloTemplate',
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 3,
            minWidth: 120,
        },



        {
            name: "elimina",
            prop: null,
            sortable: false,
            filtrable: false,
            cellTemplate: "btnDeleteProsaTemplate", // 'buttonsTemplate'
            //, width: 10
            resizeable: false,
            draggable: false,
            flexGrow: 1,
            minWidth: 50,
        },
    ];


    columnsSicerAtti = [
        {
            name: "id",
            prop: "id",
            visible: false,
            sortable: true,
            filtrable: true,
            width: 10,
            resizeable: false,
        },
        {
            name: "codice",
            prop: "codice",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 2,
            minWidth: 60,
        }
        ,
        {
            name: "descrizione",
            prop: "descrizione",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 4,
            minWidth: 120,
        },


        {
            name: "numero",
            prop: "numero",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 2,
            minWidth: 60,
            cellTemplate: "numeroAttoTemplate",
        }
        ,
        {
            name: "tipologia",
            prop: "tipo",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 2,
            minWidth: 60,
        }
        ,
        {
            name: "data",
            prop: "data",
            cellTemplate: "dateValueTemplate",
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 2,
            minWidth: 60,
        },
        {
            name: "oggetto",
            prop: "oggetto",
            cellTemplate: 'oggettoProtocolloTemplate',
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 4,
            minWidth: 120,
        },
        {
            name: "note",
            prop: "note",
            cellTemplate: 'noteSicerAttiTemplate',
            sortable: true,
            filtrable: true,
            resizeable: false,
            draggable: false,
            flexGrow: 3,
            minWidth: 120,
        },



        {
            name: "elimina",
            prop: null,
            sortable: false,
            filtrable: false,
            cellTemplate: "btnDeleteSicerAttiTemplate", // 'buttonsTemplate'
            //, width: 10
            resizeable: false,
            draggable: false,
            flexGrow: 1,
            minWidth: 50,
        },
    ];

    loadData = (dataTablesParameters: any, callback: any) => {
        if (!this.what && this.what == '' && !this.showAllData) return;
        let currentId = this.getCurrentId();
        if (this.showAllData) currentId = -1;
        if (!currentId) return;
        this.documentService.getAllDt(this.what, currentId, dataTablesParameters)
            .subscribe(resp => {
                callback(resp)
            });
    };
    refreshGrid() {
        if (this.ngxDatatableWrapper) {
            this.ngxDatatableWrapper.dtOptions.columns = this.columns;
            this.ngxDatatableWrapper.dtOptions = this.ngxDatatableWrapper.dtOptions;
            this.ngxDatatableWrapper.refresh();
        }
        this.loadProtocolliTable();
    }

    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
    gotoBackBtnClick() {
        if (!this.isDirtyHelper.checkIsDirty()) return false;
        this.gotoBtnFunction.emit(true);
    }

    @Output()
    onSubmitEmitter: EventEmitter<any> = new EventEmitter();

    @Output()
    associaProvvedimentiChangedEmitter:EventEmitter<any> = new EventEmitter();

    readOnlyForm: boolean = false;
    currentIntervento: any;
    getInterventoData(id: number) {
        this.interventoService.get(id).subscribe(
            result => {
                this.currentIntervento = result;
                this.columns[1].visible = false;
                this.columns[1].filtrable = false;
                this.columns[2].visible = false;
                this.columns[2].filtrable = false;
                let status = (result.validazione ? (result.validazione.validationStatus ? result.validazione.validationStatus : 0) : 0).toString().toUpperCase();
                this.readOnlyForm = ( status == 'TERMINATO'
                    || result.isReadOnly || this.showAllData || !this.canEdit()
                );
                this.columns[5].visible = true;
                if (this.readOnlyForm) {
                    this.columns[5].visible = false;
                }
                this.refreshGrid();
            }
        );
    }


    getClassificazioneData(id: number) {
        if (this.columns.length == 6) this.columns[5].visible = true;
        if (this.showAllData && (this.columns.length == 6)) {
            //nascondo colonne edit e delete
            this.columns[5].visible = false;

        }
        if (this.columns.length == 6) {
            this.columns[1].visible = false;
            this.columns[1].visible = false;
            this.columns[2].filtrable = false;
            this.columns[2].filtrable = false;
            this.columns.splice(2, 1);
            this.columns.splice(1, 1);
        }
        this.refreshGrid();
    }

    getFinanziamentoData(id: number) {
        let that = this;

        this.finanziamentoService.getById(id).subscribe(result => {
            let status = (result.validazione ? (result.validazione.validationStatus ? result.validazione.validationStatus : 0) : 0).toString().toUpperCase();
            that.readOnlyForm = (status == 'WAITING_FOR_APPROVAL_FIRST_LEVEL' || status == '4'
                || status == 'WAITING_FOR_APPROVAL_SECOND_LEVEL' || status == '5'
                || status == 'TERMINATO'
                || result.isReadOnly || this.showAllData
                || !this.canEdit()
            );
            if (that.columns.length == 6) that.columns[5].visible = true;
            if (that.readOnlyForm && (that.columns.length == 6)) {
                //nascondo colonne edit e delete
                that.columns[5].visible = false;

            }
            if (that.columns.length == 6) {
                that.columns[1].visible = false;
                that.columns[1].visible = false;
                that.columns[2].filtrable = false;
                that.columns[2].filtrable = false;
                that.columns.splice(2, 1);
                that.columns.splice(1, 1);
            }
            this.refreshGrid();
        });
    }

    _isInModal: boolean = false;
    @Input()
    set isInModal(value: boolean) {
        this._isInModal = value;
    }
    get isInModal() { return this._isInModal; }

    protocolli = [];
    loading = false;
    acquisisciProtocolli() {
       /* this.protocolli = [{"contenuto":"","dataDocumento":"2022-04-11T22:00:00.000Z",
        "dataProtocollo":"2022-04-11T23:00:00.000Z"
        ,"esito":{"codiceEsito":"000","descrizioneEsito":"Operazione eseguita senza errori"}
        ,"id":6221872,"isContenuto":true,"mittentiDestinatari":[]
        ,"nomeFileContenuto":"REGLAZIO.REGISTRO UFFICIALE.2022.0364100.pdf","note":"","numeroProtocollo":"364100","numeroProtocolloEsterno":"5940","oggetto":"POSTA CERTIFICATA: COTRAL-RL_Fondi per il rinnovo parco autobus:integrazione- PU22041205940","registro":"REGISTRO UFFICIALE","timbro":false,"tipoProtocollo":"I","ufficioCompetente":" GR3400 - INFRASTRUTTURE E MOBILITA'","vociTitolario":[],"allegati":[{"nome":"documentoprotocollo.pdf","codice":"6221872"},{"nome":"__body.txt","codice":"13846234"}]}];
return;*/
        let anno = this.frm_protocolli.controls.protocollo_anno.value;
        let numero = this.frm_protocolli.controls.protocollo_numero.value;
        this.loading = true;

        this.protocolloService.getAll(anno, numero).subscribe(
            result => { this.protocolli = result; this.loading = false; }
        );
    }

    printDateValue(value) {
        let v = moment(value).toDate();
        return moment(v).format('DD/MM/YYYY');
    }

    downloadDocumento(codice, nome) {
        console.log(`${codice} - ${nome}`);
        this.protocolloService.isPresente(codice, nome).subscribe(
            result => {
                if (result) {
                    this.protocolloService.download(codice, nome).subscribe(
                        res => {
                            let filename = nome.replace(/^.*[\\\/]/, "");
                            FileSaver.saveAs(res.body, filename);
                        }
                    );
                }
                else {
                    this.toastr.error(`Documento ${nome} non presente su Prosa`, null, {
                        timeOut: 3000, disableTimeOut: false
                    });
                }
            }
        );
    }

    aggiungiProtocollo(numero) {
        let protocollo = this.protocolli.find(x => x.numeroProtocollo == numero);
        if (!protocollo) {
            this.toastr.error(`Documento ${numero} non trovato`, null, {
                timeOut: 3000, disableTimeOut: false
            });
            return;
        }
        if(protocollo.dataProtocollo)protocollo.dataProtocollo = moment(protocollo.dataProtocollo).toDate();
        if(protocollo.dataDocumento)protocollo.dataDocumento = moment(protocollo.dataDocumento).toDate();
        if(protocollo.dataDocumento)protocollo.dataDocumento = new Date( moment(protocollo.dataDocumento).format('YYYY-MM-DD'));
        if(protocollo.dataProtocollo)protocollo.dataProtocollo = new Date( moment(protocollo.dataProtocollo).format('YYYY-MM-DD'));
        if (!this.what && this.what == "") return;
        let currentId = this.getCurrentId();
        this.protocolloService.insert(currentId, this.what, protocollo).subscribe(
            result => {
                this.toastr.success("Documento aggiunto con successo", null, {
                    timeOut: 2000,
       disableTimeOut: false,
                });
                this.loadProtocolliTable();
                this.onSubmitEmitter.emit(result);
                //ricaricare tabella
            }
            , error => {
                this.toastr.error(`Errore durante il salvataggio del documento`, null, {
                    timeOut: 3000, disableTimeOut: false
                });
            }
        );
    }

    loadProtocolliTable(id=null,type=null) {
        if (this.showAllData) {
            this.columnsProtocolli[8].visible = false;
            this.columnsSicerAtti[8].visible = false;
        }
        else {
            this.columnsProtocolli[1].visible = false;
            this.columnsProtocolli[2].visible = false;
            this.columnsSicerAtti[1].visible = false;
            this.columnsSicerAtti[2].visible = false;

        }
        if (this.ngxDatatableWrapperProtocolliEntrata) {
            this.ngxDatatableWrapperProtocolliEntrata.dtOptions.columns = this.columnsProtocolli;
            this.ngxDatatableWrapperProtocolliEntrata.dtOptions = this.ngxDatatableWrapperProtocolliEntrata.dtOptions;
            this.ngxDatatableWrapperProtocolliEntrata.refresh();
        }
        if (this.ngxDatatableWrapperProtocolliUscita) {
            this.ngxDatatableWrapperProtocolliUscita.dtOptions.columns = this.columnsProtocolli;
            this.ngxDatatableWrapperProtocolliUscita.dtOptions = this.ngxDatatableWrapperProtocolliUscita.dtOptions;
            this.ngxDatatableWrapperProtocolliUscita.refresh();
        }
        if (this.ngxDatatableWrapperProtocolliInterni) {
            this.ngxDatatableWrapperProtocolliInterni.dtOptions.columns = this.columnsProtocolli;
            this.ngxDatatableWrapperProtocolliInterni.dtOptions = this.ngxDatatableWrapperProtocolliInterni.dtOptions;
            this.ngxDatatableWrapperProtocolliInterni.refresh();
        }
        if (this.ngxDatatableWrapperSicerAtti) {
            this.ngxDatatableWrapperSicerAtti.dtOptions.columns = this.columnsSicerAtti;
            this.ngxDatatableWrapperSicerAtti.dtOptions = this.ngxDatatableWrapperSicerAtti.dtOptions;
            this.ngxDatatableWrapperSicerAtti.refresh();
        }

        if (this.tabProvvedimentiContent){
            this.tabProvvedimentiContent.finanziamentoId = this.finanziamentoId;
            this.tabProvvedimentiContent.interventoId = this.interventoId;
            this.tabProvvedimentiContent.reloadData();
        }


    }

    loadDataProtocolliEntrata = (dataTablesParameters: any, callback: any) => {
        if (!this.what && this.what == '' && !this.showAllData) return;
        let currentId = this.getCurrentId();
        if (this.showAllData) currentId = -1;
        if (!currentId) return;
        this.protocolloService.getAllDt(currentId, this.what, dataTablesParameters, 'ENTRATA')
            .subscribe(resp => {
                this.cntProtocolliEntrata = resp.recordsTotal;
                callback(resp)
            });
    };


    loadDataProtocolliInterni = (dataTablesParameters: any, callback: any) => {
        if (!this.what && this.what == '' && !this.showAllData) return;
        let currentId = this.getCurrentId();
        if (this.showAllData) currentId = -1;
        if (!currentId) return;
        this.protocolloService.getAllDt(currentId, this.what, dataTablesParameters, 'INTERNI')
            .subscribe(resp => {
                this.cntProtocolliInterni = resp.recordsTotal;
                callback(resp)
            });
    };

    loadDataProtocolliUscita = (dataTablesParameters: any, callback: any) => {
        if (!this.what && this.what == '' && !this.showAllData) return;
        let currentId = this.getCurrentId();
        if (this.showAllData) currentId = -1;
        if (!currentId) return;
        this.protocolloService.getAllDt(currentId, this.what, dataTablesParameters, 'USCITA')
            .subscribe(resp => {
                this.cntProtocolliUscita = resp.recordsTotal;
                callback(resp)
            });
    };


    deleteProsaDocument(data: any) {
        const result: boolean = confirm(
            `Sei sicuro di voler eliminare il protocollo selezionato?`
        );
        if (result) {
            if (!this.what && this.what == "") return;
            let currentId = this.getCurrentId();
            this.protocolloService
                .delete(currentId, this.what, data['id'])
                .subscribe((deleted) => {
                    if (deleted) {
                        this.toastr.success("Protocollo eliminato con successo", null, {
                            timeOut: 2000,
              disableTimeOut: false,
                        });
                        this.refreshGrid();
                        this.onSubmitEmitter.emit(null);
                    }
                }
                    , error => {
                        this.toastr.error(`Impossibile eliminare il protocollo: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                            timeOut: 2000, disableTimeOut: false
                        });
                        console.log('error');
                    }
                );
        }
    }

    @ViewChild('templateEditNoteModal', { static: true })
    public templateEditNoteModal: TemplateRef<any>;
    modalRef: BsModalRef;
    editProsaDocument(data) {
        this.editNoteProtocollo.controls.note_protocollo.setValue(data.note);
        this.editNoteProtocollo.controls.id.setValue(data.id);
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-lg"
        };
        this.modalRef = this.modalService.show(this.templateEditNoteModal, config);
    }

    closeModalEditProsaNote() {
        this.modalRef.hide();
    }

    salvaNoteProtocollo() {
        let note = this.editNoteProtocollo.controls.note_protocollo.value;
        let id = this.editNoteProtocollo.controls.id.value;
        this.closeModalEditProsaNote();
        this.protocolloService
            .saveNote(id, note)
            .subscribe((result) => {
                if (result) {
                    this.toastr.success("Note del protocollo salvate con successo", null, {
                        timeOut: 2000,
                        disableTimeOut: false,
                    });
                    this.refreshGrid();
                }
            }
                , error => {
                    this.toastr.error(`Impossibile salvare le note del protocollo: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                        timeOut: 2000, disableTimeOut: false
                    });
                    console.log('error');
                }
            );
    }

    trimText(text, size) {
        if (!size) return text;
        if (text.length <= size) return text;
        return text.substring(0, size) + '...';
    }

    userIsManager() {
        return this.authService.isManager();
    }

    showAllData = false;

    loadAllData() {
        this.showAllData = true;
        this.columns[5].visible = false;
        this.columnsProtocolli[1].visible = true;
        this.columnsProtocolli[2].visible = true;
        console.log(`into loadAllData`);
        this.refreshGrid();
    }


    sicerAtti = [];
    sicerAttiSelezionati = [];
    cntSicerAtti = 0;
    downloadSicer(item) {
        this.sicerService.download(item.anno, item.numero,item.direzione).subscribe(
            x => {
                let filename = item.numero + '.pdf';
                FileSaver.saveAs(
                    x.body,
                    `${filename}`
                );
            }
        );
    }
    acquisisciSicerAtti() {
        /*this.sicerAtti=[ {
            anno:2021,data:'2021-04-19',numero:'G04295'
            ,oggetto:'Impegno di spesa di euro 53.600.910,62 in favore dei Comuni del Lazio interessati (esclusa Roma Capitale) quale acconto, salvo conguaglio, del concorso finanziario (ex art. 30, comma 2, lett. c) della L.R. n. 30/98) agli oneri dei loro servizi di TPL per l\'anno 2021. Capitolo di spesa U0000D41920 (MISS/PRG: U.10.02.000, PCF: U.1.04.01.02.000) del Bilancio di Previsione della Regione Lazio per l\'esercizio finanziario 2021. Spesa di parte corrente. Codice intervento MIR n. I202100102'
            ,tipologia:'Determinazione'
        }];
        return;*/
        let anno = this.frm_sicer.controls.sicer_anno.value;
        let numero = this.frm_sicer.controls.sicer_numero.value;

        /*this.sicerAtti = [{
            tipo:"Determinazione",anno:"2021",numero:"G14125",data:"2021-11-18",oggetto:"Procedura ristretta ai sensi dell'art. 61 del D.lgs. n.50/2016 finalizzata alla conclusione di un Accordo Quadro con un operatore economico per l'affidamento di un appalto di fornitura di nuovi treni da adibire al trasporto pubblico per le ferrovie regionali Roma - Lido di Ostia e Roma - Civita Castellana - Viterbo. Nomina Responsabile Unico del Procedimento (RUP), ai sensi dell'art. 31 e 32, co. 2, del d.lgs. n. 50/2016, approvazione Quadro Tecnico Economico post gara e schema del 1 Contratto applicativo. CUP F70B18000000003 - CIG 7389386A39.  Cod. Inframob P20.0016-0002",note:""
        }];
        return;*/
        this.loading = true;
        this.sicerService.getAll(anno, numero).subscribe(
            result => { this.sicerAtti = result; this.loading = false; }
        );
    }



    @ViewChild('templateEditNoteModalSicerAtti', { static: true })
    public templateEditNoteModalSicerAtti: TemplateRef<any>;

    editSicerAttiDocument(data) {
        this.editNoteSicer.controls.note.setValue(data.note);
        this.editNoteSicer.controls.id.setValue(data.id);
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-lg"
        };
        this.modalRef = this.modalService.show(this.templateEditNoteModalSicerAtti, config);
    }
    salvaNoteSicer() {
        let note = this.editNoteSicer.controls.note.value;
        let id = this.editNoteSicer.controls.id.value;
        this.closeModalEditProsaNote();
        this.sicerService
            .saveNote(id, note)
            .subscribe((result) => {
                if (result) {
                    this.toastr.success("Note dell'atto salvate con successo", null, {
                        timeOut: 2000,
                        disableTimeOut: false,
                    });
                    this.refreshGrid();
                }
            }
                , error => {
                    this.toastr.error(`Impossibile salvare le note dell'atto: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                        timeOut: 2000, disableTimeOut: false
                    });
                    console.log('error');
                }
            );
    }

    deleteSicerAtti(data: any) {
        const result: boolean = confirm(
            `Sei sicuro di voler eliminare l'atto selezionato?`
        );
        if (result) {
            if (!this.what && this.what == "") return;
            let currentId = this.getCurrentId();
            this.sicerService
                .delete(currentId, this.what, data['id'])
                .subscribe((deleted) => {
                    if (deleted) {
                        this.toastr.success("Atto eliminato con successo", null, {
                            timeOut: 2000,
               disableTimeOut: false,
                        });
                        this.refreshGrid();
                        this.onSubmitEmitter.emit(null);
                    }
                }
                    , error => {
                        this.toastr.error(`Impossibile eliminare l'atto: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                            timeOut: 2000, disableTimeOut: false
                        });
                        console.log('error');
                    }
                );
        }
    }

    loadSicerAtti = (dataTablesParameters: any, callback: any) => {
        if (!this.what && this.what == '' && !this.showAllData) return;
        let currentId = this.getCurrentId();
        if (this.showAllData) currentId = -1;
        if (!currentId) return;
        this.sicerService.getAllDt(currentId, this.what, dataTablesParameters)
            .subscribe(resp => {
                this.cntSicerAtti = resp.recordsTotal;
                callback(resp)
            });
    };

    aggiungiSicerAtto(item) {
        if (!this.what && this.what == "") return;
        let currentId = this.getCurrentId();
        this.sicerService.insert(currentId, this.what, item).subscribe(
            result => {
                this.toastr.success("Atto aggiunto con successo", null, {
                    timeOut: 2000,
       disableTimeOut: false,
                });
                this.loadProtocolliTable();
                this.onSubmitEmitter.emit(result);
                //ricaricare tabella
            }
            , error => {
                this.toastr.error(`Errore durante il salvataggio dell'atto`, null, {
                    timeOut: 3000, disableTimeOut: false
                });
            }
        );
    }

    canEdit() {
        return (this.enableSection(this.section, ['update'], null, true));
    }
    /**
* Controlla se l'utente può accedere o meno alla sezione.
* @param section il nome della sezione
* @param operations le operazioni da poter effettuare sulla sezione
* @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
* @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
* @returns true se il controllo è andato a buon fine, false altrimenti
*/
    enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
        return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
    }
    isGruppoPnrr() {
        return this.authService.isGruppoPnrr();
    }

    isAdmin() {
        return this.authService.isAdmin();
    }


    @ViewChild("tabProvvedimentiContent", { static: true })
    tabProvvedimentiContent: AssociaProvvedimentiComponent;


    associaProvvedimentiChanged($event){
        this.associaProvvedimentiChangedEmitter.emit($event);
    }

    activeTab:string = 'tabProsa';
    confirmChangeTab($event,tabIndex){
        switch(tabIndex){
            case 0:
                this.activeTab = "tabProsa";
                break;
            case 1:
                this.activeTab = "tabGenerico";
                break;
            case 2:
                this.activeTab = "tabSicer";
                break;
            case 3:
                this.activeTab = "tabProvvedimenti";
                break;
        }
    }


    private _faseId: number;
    @Input()
    set faseId(value) {
        this.showAllData = false;
        console.log(`inside set _faseId= ${value}`);
        this._faseId = value;
        this.section = 'Fasi';
        
        if (!value) return;
        this.currentIntervento = null;
        this._interventoId = null;
        this._finanziamentoId = null;
        this._classificazioneId = null;
        this._subInterventoId= null;

        this.what = "Fasi";
        this.getFaseData(value);
        this.loadProtocolliTable();
    }
    get faseId() {
        return this._faseId;
    }

    getFaseData(id: number) {
        if (this.columns.length == 6) this.columns[5].visible = true;
        if (this.showAllData && (this.columns.length == 6)) {
            //nascondo colonne edit e delete
            this.columns[5].visible = false;

        }
        if (this.columns.length == 6) {
            this.columns[1].visible = false;
            this.columns[1].visible = false;
            this.columns[2].filtrable = false;
            this.columns[2].filtrable = false;
            this.columns.splice(2, 1);
            this.columns.splice(1, 1);
        }
        this.refreshGrid();
    }


    private _subInterventoId: number;
    @Input()
    set subInterventoId(value) {
        this.showAllData = false;
        console.log(`inside set _subInterventoId= ${value}`);
        this._subInterventoId = value;
        this.section = 'SubInterventi';
        if (!value) return;
        this.currentIntervento = null;
        this._interventoId = null;
        this._finanziamentoId = null;
        this._classificazioneId = null;
        this._faseId = null;

        this.what = "SubInterventi";
        this.getSubInterventoData(value);
        this.loadProtocolliTable();
    }
    get subInterventoId() {
        return this._subInterventoId;
    }

    getSubInterventoData(id: number) {
        if (this.columns.length == 6) this.columns[5].visible = true;
        if (this.showAllData && (this.columns.length == 6)) {
            //nascondo colonne edit e delete
            this.columns[5].visible = false;

        }
        if (this.columns.length == 6) {
            this.columns[1].visible = false;
            this.columns[1].visible = false;
            this.columns[2].filtrable = false;
            this.columns[2].filtrable = false;
            this.columns.splice(2, 1);
            this.columns.splice(1, 1);
        }
        this.refreshGrid();
    }

    getCurrentId(){
        return this._finanziamentoId
        ? this._finanziamentoId
        : this._interventoId?this._interventoId:
        this._classificazioneId?this._classificazioneId
        :this._faseId?this._faseId:this.subInterventoId;
    }


}
