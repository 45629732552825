import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, HostListener, Input, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.css']
})
export class PopoverComponent implements OnInit, AfterViewInit {

  @ViewChild(PopoverDirective)
  popover: PopoverDirective;
  @ViewChild('popBtn')
  popBtn: ElementRef;
  @Output()
  functionEmitter = new EventEmitter<boolean>();
  @Input()
  html: string;
  @Input()
  class: string;
  @Input()
  styles: {};
  @Input()
  placement: string;
  @Input()
  text = '';
  @Input()
  htmlImage: string;

  visible = false;

  htmlSanitized: SafeHtml;

  constructor(
    private el: ElementRef,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.htmlSanitized = this.domSanitizer.bypassSecurityTrustHtml(this.html);
  }

  @HostListener('document:click', ['$event'])
  checkClick($event: any) {
    if (this.el.nativeElement.contains($event.target)) {
      if (this.visible) {
        if (this.popBtn.nativeElement.contains($event.target)) {
          this.popover.hide();
          this.visible = false;
        }
      } else {
        this.popover.show();
        this.visible = true;
      }
    } else {
      this.popover.hide();
      this.visible = false;
    }
  }
}
