import { Injectable } from "@angular/core";
import { ToastrService,IndividualConfig  } from 'ngx-toastr';

@Injectable({ providedIn: "root" })
export class ToastService {
  
  constructor(private toastr:ToastrService) {
    // clear alert message on route change    
  }

  

  success(message:string,title?:string, override?: Partial<IndividualConfig>){
    this.toastr.success(message,title,override);
  }
  error(message:string,title?:string, override?: Partial<IndividualConfig>){
    this.toastr.error(message,title,override);
  }
  warning(message:string,title?:string, override?: Partial<IndividualConfig>){
    this.toastr.warning(message,title,override);
  }  
}
