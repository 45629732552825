<ng-template #templateEditAdd>
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()" class="col-lg-12">
        <input type="hidden" [formGroup]="editForm" formControlName="id" />
        <div class="row">
            <div class="form-group col-lg-6">
                <h5><label for="cognome" class="mandatory">Cognome:</label></h5>
                <input [formGroup]="editForm" formControlName="cognome" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci il cognome" [ngClass]="{
                    'form-control': true,
                    'is-invalid': submitted && f.cognome.errors
                    }" />
                <div *ngIf="submitted && f.cognome.errors" class="invalid-feedback" style="display: block;">
                    <div *ngIf="f.cognome.errors.required">
                        Campo obbligatorio
                    </div>
                </div>
            </div>

            <div class="form-group col-lg-6">
                <h5><label for="nome" class="mandatory">Nome:</label></h5>
                <input [formGroup]="editForm" formControlName="nome" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci il nome" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submitted && f.nome.errors
                      }" />
                <div *ngIf="submitted && f.nome.errors" class="invalid-feedback" style="display: block;">
                    <div *ngIf="f.nome.errors.required">
                        Campo obbligatorio
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="form-group">
            <h5><label for="email" class="mandatory">Email:</label></h5> <span>nota: è possibile inserire ulteriori indirizzi email seperati da ;</span>
         <input [formGroup]="editForm" formControlName="email" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci l'email" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submitted && f.email.errors
                      }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.email.errors.required">
                    Campo obbligatorio
                </div>
                <div *ngIf="f.email.errors.email">
                    Valore non valido
                </div>
            </div>
        </div>-->


        <!--

        <div class="form-group">
            <h5><label for="email" class="mandatory">Email:</label></h5>
            <div formArrayName="emails"
            *ngFor="let item of editForm.get('emails').controls; let i = index;"
            >
            <div [formGroupName]="i"  class="input-group" style="margin-bottom: 10px;">
                <i *ngIf="i!=0" class="far fa-trash-alt mr-2 pointer" style="padding-top: 10px; padding-bottom:10px;" title="elimina email" (click)="removeEmail(i);"></i>

                <input [formGroup]="editForm" formControlName="email" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci l'email" [ngClass]="{ 'form-control': true, 'is-invalid': submitted && editForm.get('emails').controls[i].controls['email'].errors }" />
                <i *ngIf="i == editForm.get('emails').controls.length-1" class="fas fa-plus-circle ml-2 pointer" style="padding-top: 10px; padding-bottom:10px;" title="aggiungi email" (click)="addEmail();"></i>               
            </div>

            <div *ngIf="submitted && editForm.get('emails').controls[i].controls['email'].errors" class="invalid-feedback" style="display: block;" style="margin-bottom:10px;">
                <div *ngIf="editForm.get('emails').controls[i].controls['email'].errors.required">
                    Campo obbligatorio
                </div>
                <div *ngIf="editForm.get('emails').controls[i].controls['email'].errors.email">
                    Valore non valido
                </div>
            </div>
            </div>
        </div>-->



        <!--***-->
        <div class="form-group">
            <div class="row justify-content-between">

                <div class=" col-lg-6 col-sm-6 col-6 ">
                    <h5><label for="email" class="mandatory">Email:</label></h5>
                </div>
                <div class="col-lg-1 col-sm-2 col-3 text-right">
                    <a class="bottone-inserisci" (click)="addEmail();"><i class="fas fa-plus" title="clicca per aggiungere un'ulteriore email"></i></a>
                </div>
            </div>
            <div formArrayName="emails" *ngFor="let item of editForm.get('emails').controls; let i = index;">
                <div class="input-group mb-3" [formGroupName]="i">

                    <input [formGroup]="editForm" formControlName="email" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci l'email" [ngClass]="{ 'form-control': true, 'is-invalid': submitted && editForm.get('emails').controls[i].controls['email'].errors }"
                    />

                    <i *ngIf="editForm.get('emails').controls.length!=1" class="far fa-trash-alt mx-2 pointer" style="padding-top: 10px; padding-bottom:10px;" title="elimina email" (click)="removeEmail(i);"></i>
                    <div *ngIf="submitted && editForm.get('emails').controls[i].controls['email'].errors" class="invalid-feedback" style="display: block;" style="margin-bottom:10px;">
                        <div *ngIf="editForm.get('emails').controls[i].controls['email'].errors.required">
                            Campo obbligatorio
                        </div>
                        <div *ngIf="editForm.get('emails').controls[i].controls['email'].errors.email">
                            Valore non valido
                        </div>
                    </div>
                </div>


            </div>



        </div>

        <!--***-->










        <div class="form-group">
            <h5><label for="tipologia" class="mandatory">Tipologia:</label></h5>
            <div [ngClass]="{ 'is-invalid': submitted && f.tipologia.errors }">
                <ng-select [formGroup]="editForm" formControlName="tipologia" [items]="tipologie" bindValue="id" bindLabel="descrizione" placeholder="Seleziona la tipologia">
                </ng-select>
            </div>
            <div *ngIf="submitted && f.tipologia.errors" class="invalidFieldMessage" style="display: block;">
                <div *ngIf="f.tipologia.errors" class="invalid-feedback" style="display: block;">
                    Campo obbligatorio
                </div>
            </div>
        </div>
        <button [disabled]="loading" class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
      {{buttonLabel}}
      <img *ngIf="loading" class="pl-3 float-right"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
    </button>
    </form>
</ng-template>

<div class="container sfondo_bianco">
    <hr />
    <accordion [isAnimated]="true">
        <accordion-group heading="Inserisci Nuovo soggetto responsabile">
            <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
        </accordion-group>
    </accordion>
    <hr />

    <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptions">
        <ng-template #btnEditTemplate let-row="row" let-value="value">
            <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top" title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit"></i></a>
        </ng-template>
        <ng-template #btnDeleteTemplate let-row="row" let-value="value">
            <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt"></i></a>
        </ng-template>
        <ng-template #dateTemplate let-row="row" let-value="value">
            {{printValueAsDate(value)}}
        </ng-template>
        <ng-template #emailTemplate let-row="row" let-value="value">
            <ul *ngIf="value && value !=''">
                <ng-container *ngFor="let val of getEmailsForGrid(value)">
                    <li *ngIf=" val && val!=''">{{val}}</li>
                </ng-container>

            </ul>
        </ng-template>

    </ngx-datatable-wrapper>

    <ng-template #templateModal>
        <div class="modal-header  text-center">
            <h4 class="modal-title pull-left">Modifica soggetto responsabile</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="closeEditModal()">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
        </div>
    </ng-template>


</div>