import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";




@Injectable({
    providedIn: "root"
})
export class MonitoraggioService {
    private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}Monitoraggio/`;
    constructor(private http: HttpClient) { }

    getAllDistanzeInterventoDt(dataTablesParameters: any) {
        return this.http.post<any>(
            `${this.apiUrl}distanzeIntervento/alldt`,
            dataTablesParameters
        );
    }
    getAllDistanzeIntervento() {
        return this.http.get<any[]>(
            `${this.apiUrl}distanzeIntervento`            
        );
    }


    getAllSitiReteNaturaDt(dataTablesParameters: any) {
        return this.http.post<any>(
            `${this.apiUrl}sitiReteNatura/alldt`,
            dataTablesParameters
        );
    }
    getAllSitiReteNatura() {
        return this.http.get<any[]>(
            `${this.apiUrl}sitiReteNatura`            
        );
    }

    insertDistanzaIntervento(s: any) {
        return this.http.post<any>(`${this.apiUrl}distanzeIntervento`, s);
    }
    updateDistanzaIntervento(s: any) {
        return this.http.put<any>(`${this.apiUrl}distanzeIntervento/${s.id}`, s);
    }
    deleteDistanzaIntervento(id: number) {
        return this.http.delete<any>(`${this.apiUrl}distanzeIntervento/${id}`);
    }
    saveDistanzaIntervento(s:any){
        if (!s.id)return this.insertDistanzaIntervento(s);
        return this.updateDistanzaIntervento(s);
    }


    insertSitoReteNatura(s: any) {
        return this.http.post<any>(`${this.apiUrl}sitiReteNatura`, s);
    }
    updateSitoReteNatura(s: any) {
        return this.http.put<any>(`${this.apiUrl}sitiReteNatura/${s.id}`, s);
    }
    deleteSitoReteNatura(id: number) {
        return this.http.delete<any>(`${this.apiUrl}sitiReteNatura/${id}`);
    }
    saveSitoReteNatura(s:any){
        if (!s.id)return this.insertSitoReteNatura(s);
        return this.updateSitoReteNatura(s);
    }

}
