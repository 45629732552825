<form [formGroup]="form">
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="inputTextCod">Codice Capitolo </label>
      <input readonly type="text" class="form-control" id="inputTextCod" placeholder="text" formControlName="cod_cpt">
    </div>
    <div class="form-group col-md-6">
      <label for="inputTextDescr">Descrizione Capitolo</label>
      <textarea readonly class="form-control" id="inputTextDescr" rows="1" formControlName="descr_cpt"></textarea>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table small">
      <thead>
        <tr>
          <th scope="col">Anno Esercizio</th>
          <th scope="col">Importo Competenza</th>
          <th scope="col">Importo Cassa</th>
          <th scope="col">Importo Competenza Anno + 1</th>
          <th scope="col">Importo Competenza Anno + 2</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let bilancio of capitolo?.scrittureBilancio">
          <tr>
            <th scope="row">{{ bilancio.annoEsercizio }}</th>
            <td>{{ insertDotForThousands(bilancio.importoCompN) }}</td>
            <td>{{ insertDotForThousands(bilancio.importoCassaN) }}</td>
            <td>{{ insertDotForThousands(bilancio.importoCompN1) }}</td>
            <td>{{ insertDotForThousands(bilancio.importoCompN2) }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</form>
