<div class="collapse" [id]="createDivId()">
  <div class="card">
    <h5
      class="card-header text-white px-2"
      style="background-color: #17a2b8 !important; font-size: 18px"
    >
      Ordinativi - Importo:

      {{ this.insertDotForThousands(this.totImportoOrdinativi) }} €
      <a title="Somma dell'importo degli ordinativi">
        <i class="fas fa-question-circle small" aria-hidden="true"></i>
      </a>
    </h5>
    <div
      class="card-body bg-info-50"
      style="
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
      "
    >
      <div class="table-responsive scrollbar-y">
        <table class="table">
          <thead>
            <tr>
              <th class="px-1" scope="col">
                Anno<br />{{ getLabelFieldsByType() }}
              </th>
              <th class="px-1" scope="col">
                Numero<br />{{ getLabelFieldsByType() }}
              </th>
              <th class="px-1" scope="col" *ngIf="checkFieldShow()">
                Anno<br />Liq.
              </th>
              <th class="px-1" scope="col" *ngIf="checkFieldShow()">
                Numero<br />Liq.
              </th>
              <th class="px-1" scope="col">Ragsoc<br />Soggetto</th>
              <th class="px-1" scope="col">Anno<br />Ordinativo</th>
              <th class="px-1" scope="col">Numero<br />Ordinativo</th>
              <th class="px-1" scope="col">Data<br />Emissione Ordinativo</th>
              <th class="px-1" scope="col">Importo<br />Ordinativo</th>
              <th class="px-1" scope="col">Stato<br />Ordinativo</th>
              <th class="px-1" scope="col">Riscontro<br />Ordinativo</th>
              <th class="px-1" scope="col">Codice<br />Capitolo</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let ordinativo of ordinativi">
              <tr>
                <td class="px-1">{{ ordinativo.annoImpacc }}</td>
                <td class="px-1">{{ ordinativo.numeroImpacc }}</td>
                <td class="px-1" *ngIf="checkFieldShow()">
                  {{ ordinativo.annoLiq }}
                </td>
                <td class="px-1" *ngIf="checkFieldShow()">
                  {{ ordinativo.numLiq }}
                </td>
                <td class="px-1">{{ ordinativo.ragsocSoggetto }}</td>
                <td class="px-1">{{ ordinativo.annoOrd }}</td>
                <td class="px-1">{{ ordinativo.numeroOrd }}</td>
                <td class="px-1">{{ getDataFormat(ordinativo.dtEmisOrd) }}</td>
                <td class="px-1" style="text-align: right">
                  {{ insertDotForThousands(ordinativo.importoScadenza) }}
                </td>
                <td class="px-1">{{ ordinativo.descStatoOrd }}</td>
                <td class="px-1">
                  {{ getDataFormat(ordinativo.dtRiscontroOrd) }}
                </td>
                <td class="px-1">{{ ordinativo.codCpt }}</td>
              </tr>
            </ng-container>

            <tr class="bg-dark">
              <td class="px-1 text-white" colspan="5">
                {{ getImportoTitleByType() }}: 
                <span class="bold">{{
                  insertDotForThousands(getValoreAttualeByType())
                }}</span> €
              </td>
              <td class="px-1 text-white" colspan="4">
                IMPORTO TOTALE ORDINATIVI: 
                <span class="bold">{{
                  insertDotForThousands(totImportoOrdinativi)
                }}</span> €
              </td>
              <td class="px-1 text-white" colspan="3">
                DISPONIBILITA' AD EMETTERE ORDINATIVI: 
                <span class="bold">{{
                  insertDotForThousands(disponibilita)
                }}</span> €
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
