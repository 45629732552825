<ng-template #templateEditAdd>
  <form [formGroup]="addMissionForm" (ngSubmit)="onSubmit()" class="col-lg-12">
    <input type="hidden" [formGroup]="addMissionForm" formControlName="id" />
    <div class="row">
      <div class="form-group col-xl-12 col-lg-12">
        <h5> <label for="codice" class="mandatory">
            &nbsp;Codice:</label></h5>
        <div class="">
          <input [formGroup]="addMissionForm" formControlName="codice" type="text" value="" class="form-control"
            placeholder="Inserisci il codice" [ngClass]="{
              'form-control': true,
              'is-invalid': submitted && f.codice.errors
            }" />
          <div *ngIf="submitted && f.codice.errors" class="invalid-feedback" style="display: block;">
            <div *ngIf="f.codice.errors">
              Campo obbligatorio
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-xl-12 col-lg-12">
        <h5><label for="descrizione" class="mandatory">Descrizione:</label></h5>
        <div class="">
          <input [formGroup]="addMissionForm" formControlName="descrizione" type="text" value="" class="form-control"
            placeholder="Inserisci la descrizione" [ngClass]="{
              'form-control': true,
              'is-invalid': submitted && f.descrizione.errors
            }" />
          <div *ngIf="submitted && f.descrizione.errors" class="invalid-feedback" style="display: block;">
            <div *ngIf="f.descrizione.errors">
              Campo obbligatorio
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center my-3">
      <button #saveBtn class="btn btn-primary col-lg-4 " type="submit">
        Salva
      </button>
    </div>

  </form>
</ng-template>

<div class="col-12 mb-5" >
  <hr />
  <accordion [isAnimated]="true" *ngIf="checkPermission('insert')">
    <accordion-group heading="Inserisci Nuova Missione">
      <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
    </accordion-group>
  </accordion>

  <div class="mt-4">
    <h4 class="titoletti">Lista Missioni</h4>

    <div [hidden]="!visible">
      <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;"
        [dtOptions]="dtOptionsNgx">
        <ng-template #btnEditTemplate let-row="row" let-value="value">
          <a *ngIf="checkPermission('update')" [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top"
            title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
        </ng-template>
        <ng-template #btnDeleteTemplate let-row="row" let-value="value">
          <a *ngIf="checkPermission('delete')" [ngClass]="{
        'disabled': loading
        }" class=" border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top"
            title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt"></i></a>
        </ng-template>
      </ngx-datatable-wrapper>
    </div>
  </div>
</div>

<ng-template #templateModal>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">Modifica missione</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
  </div>
</ng-template>
