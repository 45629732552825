import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-menu',
  templateUrl: './card-menu.component.html',
  styleUrls: ['./card-menu.component.css']
})
export class CardMenuComponent implements OnInit {

  @Input()
  title: string;
  @Input()
  url: string;
  @Input()
  icon: string;
  @Input()
  item: any;

  constructor(
    private router: Router,
    private ref: ElementRef
  ) { }

  ngOnInit() { }

  @HostListener('document:click', ['$event'])
  checkClick($event: any) {
    if (this.ref.nativeElement.contains($event.target)) {
      if (this.item.externallink) {
        window.open(this.item.link,'_blank');
      } else {
        this.router.navigateByUrl(this.url);
      }
    }
  }
}
