export class Municipality {
    id: string;
    istatCode: string;
    cadastreCode: string;
    name: string;
    fromDate: string;
    toDate: string;
    provinceId: number;
    provinceName: string;
    provinceAcronym: string;
    province:any;
  }
  