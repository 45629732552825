<ng-template #templateEditAdd>
    <div class="col-lg-12">
        <form [formGroup]="editForm" (ngSubmit)="onSubmit()" class="row">
            <input type="hidden" [formGroup]="editForm" formControlName="id" />
            <div class="form-group col-lg-6">
                <h5><label for="codiceIdentificativo" class="mandatory">Codice Identificativo:</label></h5>
                <input [formGroup]="editForm" formControlName="codiceIdentificativo" minlength="11" maxlength="11" type="text" value="" class="form-control" placeholder="Inserisci il codice identificativo" [ngClass]="{
            'form-control': true,
                'is-invalid': submitted && f.codiceIdentificativo.errors
            }" />
                <div *ngIf="submitted && f.codiceIdentificativo.errors" class="invalid-feedback" style="display: block;">
                    <div *ngIf="f.codiceIdentificativo.errors.required">
                        Campo obbligatorio
                    </div>
                    <div *ngIf="f.codiceIdentificativo.errors.minlength">
                        Codice Identificativo deve essere di 11 caratteri
                    </div>
                </div>
            </div>



            <ng-template #popoverContentIpa>
              <span>Indice delle Pubbliche Amministrazioni</span>
            </ng-template>
            <div class="form-group col-lg-6">
                <h5>
                  <label for="codiceipa" class="mandatory">
                    <a type="button" class="help" [popover]="popoverContentIpa" placement="bottom" triggers="mouseenter:mouseleave">

                    <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                    </i>
                    </a>&nbsp;Codice Ipa:
                  </label>
                </h5>
                <input [formGroup]="editForm" formControlName="codiceipa" maxlength="10" type="text" value="" class="form-control" placeholder="Inserisci il codice ipa" [ngClass]="{
            'form-control': true,
                'is-invalid': submitted && f.codiceipa.errors
            }" />
                <div *ngIf="submitted && f.codiceipa.errors" class="invalid-feedback" style="display: block;">
                    <div *ngIf="f.codiceipa.errors.required">
                        Campo obbligatorio
                    </div>
                </div>
            </div>
            <div class="form-group col-lg-6">
                <h5><label for="denominazione" class="mandatory">Denominazione:</label></h5>
                <input [formGroup]="editForm" formControlName="denominazione" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci la denominazione" [ngClass]="{
            'form-control': true,
                'is-invalid': submitted && f.denominazione.errors
            }" />
                <div *ngIf="submitted && f.denominazione.errors" class="invalid-feedback" style="display: block;">
                    <div *ngIf="f.denominazione.errors.required">
                        Campo obbligatorio
                    </div>
                </div>
            </div>
            <div class="form-group col-lg-6">
                <h5><label for="provincia" class="mandatory">Provincia:</label></h5>
                <div [ngClass]="{ 'is-invalid': submitted && f.provincia.errors }">
                    <ng-select [formGroup]="editForm" formControlName="provincia" [items]="provinces" bindValue="id" bindLabel="name" (change)="onProvincesChange($event)" placeholder="Seleziona tipo classificazione">
                    </ng-select>
                </div>

                <div *ngIf="submitted && f.provincia.errors" class="invalidFieldMessage" style="display: block;">
                    <div *ngIf="f.provincia.errors" class="invalid-feedback" style="display: block;">
                        Campo obbligatorio
                    </div>
                </div>
            </div>

            <div class="col-lg-12 mb-3">
                <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
            {{buttonLabel}}
        </button>
            </div>
        </form>
    </div>
</ng-template>
<div class="container sfondo_bianco">
    <hr />
    <accordion [isAnimated]="true">
        <accordion-group heading="Inserisci Nuovo Soggetto Giuridico">
            <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
        </accordion-group>
    </accordion>
    <div class="mt-5">

        <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptions">
            <ng-template #btnEditTemplate let-row="row" let-value="value">
                <a [ngClass]="{
        'disabled': loading
        }" class=" border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top" title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
            </ng-template>
            <ng-template #btnDeleteTemplate let-row="row" let-value="value">
                <a [ngClass]="{
        'disabled': loading
        }" class=" border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
            </ng-template>
        </ngx-datatable-wrapper>
    </div>
</div>
<ng-template #templateModal>
    <div class="modal-header  text-center">
        <h4 class="modal-title pull-left">Modifica soggetto-giuridico</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
<span aria-hidden="true">&times;</span>
</button>
    </div>
    <div class="modal-body">
        <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
    </div>
</ng-template>
