<div class="col-12 mb-5">
    <div class="col-md-12">
        <p class="text-sm-left mt-3">
            In questa pagina è possibile <b>inserire una nuova Nazione, visualizzare la lista delle Nazioni presenti e
      modificarne i relativi dati</b>.
        </p>
    </div>

    <hr />
    <accordion [isAnimated]="true">
        <accordion-group heading="Inserisci Nuova Nazione">
            <form [formGroup]="addNationForm" (ngSubmit)="onSubmit()" class="col-lg-12">

                <div class="row">
                    <!-- *** -->
                    <div class="form-group col-xl-6 col-lg-6">
                        <h5><label for="name" class="mandatory">Nome
            Nazione:</label></h5>
                        <div class="">
                            <input [formGroup]="addNationForm" formControlName="name" type="text" value="" class="form-control" placeholder="Inserisci il nome della nazione" [ngClass]="{
              'form-control': true,
              'is-invalid': submitted && f.name.errors
            }" />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.name.errors.required">
                                    Nome Nazione &egrave; obbligatorio
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-template #codiceStato>
                      <span>Codice Stato</span>
                    </ng-template>
                    <div class="form-group col-xl-6 col-lg-6">
                        <h5>
                          <label for="atCode" class="mandatory">
                          <a type="button"
                          class="help"
                         [popover]="codiceStato"
                          placement="bottom"
                          triggers="mouseenter:mouseleave">

                            <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;"></i>
                           </a>&nbsp;Codice AT (Cittadinanza):</label></h5>
                        <div class="">
                            <input [formGroup]="addNationForm" formControlName="atCode" type="text" value="" maxlength="4" class="form-control" placeholder="Inserisci il codice AT della nazione" [ngClass]="{
          'form-control': true,
          'is-invalid': submitted && f.atCode.errors
        }" />
                            <div *ngIf="submitted && f.atCode.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.atCode.errors.required">
                                    Codice AT (Cittadinanza) &egrave; obbligatorio
                                </div>
                                <div *ngIf="f.atCode.errors.minlength">
                                    Codice AT (Cittadinanza) deve essere di 4 caratteri
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-xl-6 col-lg-6">
                        <h5><label for="citizenship" class="mandatory">Cittadinanza:</label></h5>
                        <div class="">
                            <input [formGroup]="addNationForm" formControlName="citizenship" type="text" value="" class="form-control" maxlength="255" placeholder="Inserisci la cittadinanza" [ngClass]="{
              'form-control': true,
              'is-invalid': submitted && f.citizenship.errors
            }" />
                            <div *ngIf="submitted && f.citizenship.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.citizenship.errors.required">
                                    Cittadinanza &egrave; obbligatoria
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-6 col-lg-6">
                        <h5><label class="mandatory">Continente:</label></h5>
                        <div class="">
                            <ng-select formControlName="continent" [items]="continentsAdd" bindValue="id" placeholder="Seleziona" bindLabel="value" selectOnTab="true" [searchable]="true" [clearable]="false" (change)="onChangeNewContinent($event)" notFoundText="Nessun risultato trovato..."
                                [ngClass]="{
              'is-invalid': submitted && f.continent.errors
            }">
                            </ng-select>
                            <div *ngIf="submitted && f.continent.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.continent.errors.required">
                                    Continente &egrave; obbligatorio
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- *** -->
                    <div class="form-group col-xl-6 col-lg-6">
                        <h5><label for="istatCode" class="mandatory">Cod.
            Istat:</label></h5>
                        <div class="">
                            <input [formGroup]="addNationForm" formControlName="istatCode" type="text" value="" maxlength="3" class="form-control" placeholder="Inserisci il codice istat della nazione" [ngClass]="{
              'form-control': true,
              'is-invalid': submitted && f.istatCode.errors
            }" />
                            <div *ngIf="submitted && f.istatCode.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.istatCode.errors.required">
                                    Codice Istat &egrave; obbligatorio
                                </div>
                                <div *ngIf="f.istatCode.errors.minlength">
                                    Codice Istat deve essere di 3 caratteri
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-xl-6 col-lg-6">
                        <h5> <label for="isoCode" class="mandatory">Cod.
            Iso:</label></h5>
                        <div class="">
                            <input [formGroup]="addNationForm" formControlName="isoCode" type="text" value="" maxlength="3" class="form-control" placeholder="Inserisci il codice iso della nazione" [ngClass]="{
              'form-control': true,
              'is-invalid': submitted && f.isoCode.errors
            }" />
                            <div *ngIf="submitted && f.isoCode.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.isoCode.errors.required">
                                    Codice Iso &egrave; obbligatorio
                                </div>
                                <div *ngIf="f.isoCode.errors.minlength">
                                    Codice Iso deve essere di 3 caratteri
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--<div class="row">

                    <div class="form-group col-xl-6 col-lg-6">
                        <h5><label class="">Continente
            <span aria-hidden="true" class="text-danger font-weight-bold">*</span>:</label></h5>
                        <div class="">
                            <ng-select formControlName="continent" [items]="continentsAdd" bindValue="id" placeholder="Seleziona" bindLabel="value" selectOnTab="true" [searchable]="true" [clearable]="false" (change)="onChangeNewContinent($event)" notFoundText="Nessun risultato trovato..."
                                [ngClass]="{
              'is-invalid': submitted && f.continent.errors
            }">
                            </ng-select>
                            <div *ngIf="submitted && f.continent.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.continent.errors.required">
                                    Continente &egrave; obbligatorio
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->

                <div class="row">
                    <!-- *** -->
                    <div class="form-group col-xl-6 col-lg-6">
                        <h5><label class="">Data Inizio Validità:</label></h5>

                        <div class="input-group">
                            <input formControlName="fromDate" type="text" class="form-control" bsDatepicker #dp="bsDatepicker" [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true, containerClass: 'theme-dark-blue', isAnimated: true
              }" [ngClass]="{
                'is-invalid': submitted && f.fromDate.errors
              }" />
                            <button type="button" class="btn btn-primary mt-0" (click)="dp.show()" [attr.aria-expanded]="dp.isOpen">
              <i class="fas fa-calendar-alt"></i>
            </button>
                        </div>
                    </div>

                    <div class="form-group col-xl-6 col-lg-6">
                        <h5><label class="" style="letter-spacing:-0.2px">Data
            Fine Validità:</label></h5>

                        <div class="input-group">
                            <input formControlName="toDate" type="text" class="form-control" bsDatepicker #dp2="bsDatepicker" [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true,
                containerClass: 'theme-dark-blue',
                isAnimated: true
              }" [ngClass]="{
                'is-invalid': submitted && f.toDate.errors
              }" />
                            <button type="button" class="btn btn-primary mt-0" (click)="dp2.show()" [attr.aria-expanded]="dp.isOpen">
              <i class="fas fa-calendar-alt"></i>
            </button>
                        </div>
                    </div>
                </div>

                <div class="text-center my-3">
                    <button class="btn btn-primary col-lg-4 " type="submit">
          INSERISCI NUOVA NAZIONE
          &nbsp;<i aria-hidden="true" class="fa fa-plus"></i>
        </button>
                </div>

            </form>
        </accordion-group>
    </accordion>

    <div class="mt-4">
        <h4 class="titoletti">Lista Nazioni presenti in GLP</h4>

        <div class="form-group clearfix">
            <label for="continents" class="mandatory">Continente:
    </label>
            <ng-select [items]="continents" bindValue="id" placeholder="Seleziona" bindLabel="value" selectOnTab="true" [searchable]="true" [clearable]="false" (change)="onChangeContinent($event)" notFoundText="Nessun risultato trovato...">
            </ng-select>
        </div>
        <div [hidden]="!visible">
            <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsNgx">
                <ng-template #btnEditTemplate let-row="row" let-value="value">
                    <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="editHandler(row)" data-toggle="tooltip" data-placement="top" title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer" ></i></a>
                </ng-template>
            </ngx-datatable-wrapper>
        </div>
    </div>
</div>
