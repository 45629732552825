import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { ServiceType } from 'src/app/modules/inframob/models/service-type';
import { SicerImpegnoAccertamento } from 'src/app/modules/inframob/models/sicer-impegno-accertamento';
import { SicerCapitolo } from 'src/app/modules/inframob/models/sicer-capitolo';
import { FinanziamentoService, InterventoService } from 'src/app/modules/inframob/services';
import { ToastService } from 'src/app/services';

@Component({
  selector: 'app-sicer-bilancio-capitolo-dettaglio',
  templateUrl: './sicer-bilancio-capitolo-dettaglio.component.html',
  styleUrls: ['./sicer-bilancio-capitolo-dettaglio.component.css']
})
export class SicerBilancioCapitoloDettaglioComponent implements OnInit {

  @Input()
  capitolo: SicerCapitolo;
  @Input()
  impegniAccertamenti: SicerImpegnoAccertamento[];
  @Input()
  impAccsSelezionati: SicerImpegnoAccertamento[];
  @Input()
  finanziamentoId: number;
  @Input()
  interventoId: number;
  @Input()
  progettoId: number;
  @Input()
  interventi: any[];
  @Input()
  type: ServiceType;
  @Output()
  disassociaCapitoloEmitter = new EventEmitter<string>();
  @Output()
  associaAccertamentiEmitter = new EventEmitter<SicerImpegnoAccertamento[]>();
  @Output()
  disassociaAccertamentiEmitter = new EventEmitter<{ impAccs: SicerImpegnoAccertamento[], codCpt: string }>();
  @Output()
  associaMassivoAccertamentiEmitter = new EventEmitter<SicerImpegnoAccertamento[]>();
  @Output()
  disassociaMassivoAccertamentiEmitter = new EventEmitter<SicerImpegnoAccertamento[]>();

    @Input()readOnlyForm=false;

  loadingImpegni: boolean = true;
  savingData: boolean = false;
  searchValue: string = null;

  allImpegniAccertamenti: SicerImpegnoAccertamento[];

  constructor(
    private toastr: ToastService,
    private finanziamentoService: FinanziamentoService,
    private interventoService: InterventoService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    switch (true) {
      case changes.impegniAccertamenti !== undefined:
        this.loadingImpegni = !this.impegniAccertamenti;
        this.sortImpegniAccertamenti();
        break;
    }
  }

  private sortImpegniAccertamenti() {
    const field = this.type === ServiceType.FINANZIAMENTO ? 'finanziamentoId' : 'interventoId';
    this.impegniAccertamenti
        ? this.impegniAccertamenti = this.impegniAccertamenti.sort((a, b) =>
              !!a[field]
                ? !!b[field]
                    ? a.pkImpacc.localeCompare(b.pkImpacc) || a.minimoOrdineModifica.toString().localeCompare(b.minimoOrdineModifica.toString())
                    : -1
                : !!b[field]
                    ? 1
                    : a.pkImpacc.localeCompare(b.pkImpacc) || a.minimoOrdineModifica.toString().localeCompare(b.minimoOrdineModifica.toString())
          )
        : null;
  }

  onDeleteCapitolo($event: any, data: any) {
    $event.stopPropagation();
    $event.preventDefault();

    let id = null;
    let service = null;
    switch (this.type) {
      case ServiceType.FINANZIAMENTO:
        service = this.finanziamentoService;
        id = this.finanziamentoId;
        break;
      case ServiceType.PROGETTO:
      case ServiceType.PROGETTOINTERVENTO:
      case ServiceType.INTERVENTO:
        service = this.interventoService;
        id = this.interventoId;
        break;
    }

    const found = this.impegniAccertamenti.filter(impAcc => this.impAccsSelezionati.find(elem => elem.pkCpt === impAcc.pkCpt));
    if (found.length > 0) {
      this.toastr.error('Per questo capitolo sono stati trovati' + (this.type === ServiceType.FINANZIAMENTO ? 'accertamenti' : ' impegni') + ' selezionati. Deselezionarli per poter rimuovere il capitolo selezionato');
      return;
    }
    service.disassociaSicerCapitolo(id, data.pkCpt).subscribe(resp => {
      this.showToast('success', 'Capitolo rimosso con successo');
      this.disassociaCapitoloEmitter.emit(data.pkCpt);
    });
  }

  onAbilitateImpegnoAccertamento(data: SicerImpegnoAccertamento) {
    this.savingData = true;

    let id = null;
    let service = null;
    let checkAssocia = false;
    switch (this.type) {
      case ServiceType.FINANZIAMENTO:
        service = this.finanziamentoService;
        checkAssocia = data.finanziamentoId ? false : true;
        id = this.finanziamentoId;
        break;
      case ServiceType.PROGETTO:
      case ServiceType.PROGETTOINTERVENTO:
      case ServiceType.INTERVENTO:
        service = this.interventoService;
        checkAssocia = data.interventoId ? false : true;
        id = this.interventoId;
        break;
    }

    checkAssocia
      ? service.associaSicerImpegnoAccertamento(id, data.pkImpacc, data.pkCpt).subscribe(resp => {
          // this.showToast('success', this.type === ServiceType.FINANZIAMENTO ? 'Accertamento aggiunto con successo' : 'Impegno aggiunto con successo');
          let adds = [];
          resp.forEach((elem: SicerImpegnoAccertamento) => {
            adds = adds.concat(this.manageImpegniAccertamenti(elem, this.interventoId, this.finanziamentoId));
          });
          this.sortImpegniAccertamenti();
          this.associaAccertamentiEmitter.emit(adds);
          this.savingData = false;
        }, error => {
          this.savingData = false;
          this.showToast('error', 'Errore');
        })
      : service.disassociaSicerImpegnoAccertamento(id, data.pkImpacc).subscribe(resp => {
          // this.showToast('success', this.type === ServiceType.FINANZIAMENTO ? 'Accertamento rimosso con successo' : 'Impegno rimosso con successo');
          let removes = [];
          resp.forEach((elem: SicerImpegnoAccertamento) => {
            removes = removes.concat(this.manageImpegniAccertamenti(elem, null, null));
          });
          this.sortImpegniAccertamenti();
          this.disassociaAccertamentiEmitter.emit({ impAccs: removes, codCpt: null });
          this.savingData = false;
        }, error => {
          this.savingData = false;
          this.showToast('error', 'Errore');
        });
  }

  private manageImpegniAccertamenti(elem: SicerImpegnoAccertamento, interventoId: number, finanziamentoId: number): SicerImpegnoAccertamento[] {
    const elems = this.impegniAccertamenti.filter(impAcc => impAcc.pkImpacc === elem.pkImpacc);
    elems.forEach(val => {
      val.interventoId = interventoId;
      val.finanziamentoId = finanziamentoId;
    });
    return elems;
  }

  searchImpegniAccertamenti($event) {
    this.searchValue = $event.target.value.toUpperCase();

    if (!this.impegniAccertamenti) return;

    this.allImpegniAccertamenti ? null : this.allImpegniAccertamenti = this.impegniAccertamenti;

    this.impegniAccertamenti = this.searchValue !== ''
          ? this.allImpegniAccertamenti.filter(s => {
              return this.getCoalesceData(s.annoImpacc).toString().includes(this.searchValue) ||
                     this.getCoalesceData(s.numeroImpacc).toString().includes(this.searchValue) ||
                     this.getCoalesceData(s.descrImpacc).toUpperCase().includes(this.searchValue) ||
                     this.getCoalesceData(s.ragsocSoggetto).toUpperCase().includes(this.searchValue) ||
                     this.getCoalesceData(this.insertDotForThousands(s.importoIniImpacc)).toString().includes(this.searchValue) ||
                     this.getCoalesceData(this.insertDotForThousands(s.importoModifica)).toString().includes(this.searchValue) ||
                     this.getCoalesceData(this.insertDotForThousands(s.valoreAttualeImpacc)).toString().includes(this.searchValue) ||
                     this.getCoalesceData(s.numApprovAtto).includes(this.searchValue) ||
                     this.getCoalesceData(s.numeroImpaccRib).toString()?.includes(this.searchValue) ||
                     this.getCoalesceData(s.annoImpaccRib).toString()?.includes(this.searchValue)
            })
          : this.allImpegniAccertamenti;
  }

  private getCoalesceData(data: any) {
    return data ? data : '';
  }

  onAbilitateAllImpegniAccertamenti(data: SicerCapitolo) {
    this.savingData = true;

    let id = null;
    let service = null;
    switch (this.type) {
      case ServiceType.FINANZIAMENTO:
        service = this.finanziamentoService;
        id = this.finanziamentoId;
        break;
      case ServiceType.PROGETTO:
      case ServiceType.PROGETTOINTERVENTO:
      case ServiceType.INTERVENTO:
        service = this.interventoService;
        id = this.interventoId;
        break;
    }

    const checkAssocia = this.getSwitchDataSelectUnselectAll() ? false : true;

    checkAssocia
      ? service.associaMassivaSicerImpegnoAccertamento(id, data.pkCpt, this.progettoId ? this.progettoId : -1).subscribe(resp => {
          // this.showToast('success', this.type === ServiceType.FINANZIAMENTO ? 'Accertamenti aggiunti con successo' : 'Impegni aggiunti con successo');
          this.impegniAccertamenti.forEach(impAcc => {
            if (impAcc.annoPerenzione) return;

            impAcc.interventoId = this.interventoId;
            impAcc.finanziamentoId = this.finanziamentoId;
          });
          this.sortImpegniAccertamenti();
          this.associaMassivoAccertamentiEmitter.emit(resp);
          this.savingData = false;
        }, error => {
          this.savingData = false;
          this.showToast('error', 'Errore');
        })
      : service.disassociaMassivaSicerImpegnoAccertamento(id, data.pkCpt).subscribe(resp => {
          // this.showToast('success', this.type === ServiceType.FINANZIAMENTO ? 'Accertamenti rimossi con successo' : 'Impegni rimossi con successo');
          this.impegniAccertamenti.forEach(impAcc => {
            impAcc.interventoId = null;
            impAcc.finanziamentoId = null;
          });
          this.sortImpegniAccertamenti();
          this.disassociaMassivoAccertamentiEmitter.emit(resp);
          this.savingData = false;
        }, error => {
          this.savingData = false;
          this.showToast('error', 'Errore');
        });
  }

  showToast(type: string, msg: string, timeout: number = 2000, disableTimeOut: boolean = false) {
    switch (type) {
      case 'success':
        this.toastr.success(msg, null, {
          timeOut: timeout,
          disableTimeOut: disableTimeOut,
        });
        break;
      case 'error':
        this.toastr.error(msg, null, {
          timeOut: timeout,
          disableTimeOut: disableTimeOut,
        });
        break;
    }
  }

  getSwitchData(accertamento: any): boolean {
    switch (this.type) {
      case ServiceType.FINANZIAMENTO:
        return accertamento.finanziamentoId;
      case ServiceType.PROGETTO:
      case ServiceType.PROGETTOINTERVENTO:
      case ServiceType.INTERVENTO:
        return accertamento.interventoId;
    }
  }

  getSwitchDataSelectUnselectAll(): boolean {
    let allSelected = true;
    switch (this.type) {
      case ServiceType.FINANZIAMENTO:
        this.impegniAccertamenti.forEach(acc => {
          allSelected = allSelected && acc.finanziamentoId !== null;
        });
        return allSelected;
      case ServiceType.PROGETTO:
      case ServiceType.PROGETTOINTERVENTO:
      case ServiceType.INTERVENTO:
        this.impegniAccertamenti.forEach(imp => {
          allSelected = allSelected && (imp.interventoId !== null || !!imp.annoPerenzione);
        });
        return allSelected;
    }
  }

  checkTitleByService(): string {
    let total = 0;
    this.impegniAccertamenti?.forEach(impAcc => {
      total += impAcc.interventoId ? impAcc.valoreAttualeImpacc : 0;
    });
    return this.type === ServiceType.FINANZIAMENTO
                ? 'Lista Accertamenti'
                : this.type === ServiceType.PROGETTOINTERVENTO
                  ? `Lista Impegni - Importo:
                      ${this.insertDotForThousands(total)} € <a title="Somma dell'importo attuale degli impegni selezionati">
                      <i class="fas fa-question-circle small" aria-hidden="true"></i>
                    </a>`
                  : 'Lista Impegni';
  }

  getNoDataPresent(): string {
    return this.type === ServiceType.FINANZIAMENTO
              ? 'Nessun accertamento trovato per il capitolo ' + this.capitolo.codCpt
              : 'Nessun impegno trovato per il capitolo ' + this.capitolo.codCpt;
  }

  insertDotForThousands(baseValue: number): string {
    if (baseValue === null || baseValue === undefined) return '';

    const value = baseValue.toFixed(2).toString().replace('.', ',');
    return value
              ? value.replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
              : '';
  }

  getDataFormat(date: Date): string {
    return date ? moment(date).format('DD/MM/YYYY') : '';
  }

  getLabelFieldsByType(): string {
    return this.type === ServiceType.FINANZIAMENTO ? 'Acc.' : 'Imp.';
  }

  getLabelSoggettoByType() {
    return this.type === ServiceType.FINANZIAMENTO ? 'Debitore' : 'Creditore';
  }

  checkSelectAllPresence(): boolean {
    return this.type !== ServiceType.INTERVENTO && !this.searchValue;
  }
}
