import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProgettoService ,InterventoService,FinanziamentoService} from '../../services';
import { FinanziamentoDivDettaglioService } from './finanziamento-div-dettaglio.service';
import { Subscription } from 'rxjs';

@Component({
    selector: "finanziamento-div-dettaglio-component",
    templateUrl: "./finanziamento-div-dettaglio.component.html",
    styleUrls: ["./finanziamento-div-dettaglio.component.css"]
})
export class FinanziamentoDivDettaglioComponent implements OnInit, OnDestroy {

    finanziamentoId: number;
    private subscription: Subscription;
    //private subscriptionFinanziamento: Subscription;

    constructor(
         private finanziamentoDivDettaglioService: FinanziamentoDivDettaglioService
        ,private finanziamentoService:FinanziamentoService
    ) { }

    ngOnInit() {        
        this.subscription = this.finanziamentoDivDettaglioService.getId().subscribe(id => {
            this.finanziamentoId = id;                    
            this.getFinanziamentoById();
        });     
        //this.finanziamentoDivDettaglioService.setId(this.finanziamentoId);   
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        //this.subscriptionFinanziamento.unsubscribe();
    }

    printValueAsCurrency(value) {
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }

    valoreFinanziamento = '0';
    valoreFinanziamentoAllocato = '0';//usato dai progetti
    valoreFinanziamentoUtilizzato = '0';//utilizzato dagli interventi

    disponibilitaFinanziamento = '0';

    descrizioneFinanziamento = '';
    codiceFinanziamento = '';

    getFinanziamentoById(){
        let obj = null;
        if (!this.finanziamentoId)return;
        this.finanziamentoService.getById(this.finanziamentoId).subscribe(
            result=>{
                window.setTimeout(()=>{
                    this.descrizioneFinanziamento = result.titolo;
                    this.codiceFinanziamento = result.codice;
                    this.calcolaValoreFinanziamento(result);
                },10);
                
            }
        );
    }
    
    calcolaValoreFinanziamento(obj){
        let valoreFinanziamento = 0;
        let disponibilitaFinanziamento = 0;
        let valoreFinanziamentoAllocato = 0;
        let valoreFinanziamentoUtilizzato = 0;
        if (!obj || !obj.annualita)return;
        valoreFinanziamentoUtilizzato += obj.importoUtilizzato;
        obj.annualita.forEach(
            annualita =>{
                disponibilitaFinanziamento+=annualita.disponibilita;
                valoreFinanziamento += annualita.importo;
                valoreFinanziamentoAllocato += annualita.importoImpegnato;                
            }
        );
        this.valoreFinanziamento = valoreFinanziamento.toString();       
        this.valoreFinanziamentoAllocato = valoreFinanziamentoAllocato.toString();
        this.disponibilitaFinanziamento = disponibilitaFinanziamento.toString();
        this.valoreFinanziamentoUtilizzato = valoreFinanziamentoUtilizzato.toString();
    }
}