<div class="modal-header text-center">
    <h4 class="modal-title pull-left">Spostamento annualità</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="p-5 container-xl text-center" [hidden]="loadDataCounter<=0">
        <app-loading message="Caricamento in corso..."></app-loading>
    </div>
    <div [hidden]="loadDataCounter>0" class="container">
        <div class="col-lg-12 ">
            <form [formGroup]="editForm" novalidate>
                <!--tabella-->
                <div class="fixedHeaderTable col-lg-12 mt-3 table-responsive table-wrapper-scroll-y scrollbar-y px-0">
                    <table *ngIf="listaAnnualita && interventoData" class="table table-colore">
                        <!--intestazione intervento-->
                        <thead>
                            <tr>
                                <th class="p-2" style="min-width: 220px;">Intervento</th>
                                <th class="p-2" style="min-width: 165px;">&nbsp;</th>
                                <th class="p-2" style="min-width: 150px;" *ngFor="let annualita of listaAnnualita;let i =index;">{{annualita.anno}}{{annualita.isNew?'*':''}}</th>
                                <th class="p-2" style="min-width: 180px;">
                                    <a (click)="addAnnualita()" title="aggiungi annualità" class="pointer" style="cursor: pointer;"><i class="fas fa-plus-circle"></i></a>&nbsp; Totale
                                </th>
                            </tr>
                        </thead>
                        <!--fine intestazione intervento-->
                        <tbody>
                            <!--dettaglio intervento con titolo e vecchi valori-->
                            <tr class="background-table">
                                <th class="p-2">Intervento&nbsp;{{interventoData.codice}}</th>
                                <td class="p-2">
                                    <ul class="list-group">
                                        <li class="list-group-item text-right py-1" style=" border: 1px solid rgba(0,0,0,.0); background-color: rgba(0,0,0,.0);">valore&nbsp;precedente</li>
                                        <li class="list-group-item text-right py-1" style="height: 40px; border: 1px solid rgba(0,0,0,.0); background-color: rgba(0,0,0,.0);">_+/-</li>
                                        <li class="list-group-item text-right py-1" style=" border: 1px solid rgba(0,0,0,.0); background-color: rgba(0,0,0,.0);">valore&nbsp;successivo</li>

                                    </ul>
                                </td>
                                <td class="p-2" *ngFor="let annualita of listaAnnualita;let i =index;" formArrayName="interventoArray">
                                    <ul class="list-group">
                                        <li class="list-group-item py-1">{{printValueAsCurrency(annualita.importo)}} </li>
                                        <li class="list-group-item py-1" [formGroupName]="i"><input type="text" formControlName="importo" [imask]="mask" [unmask]="true" /></li>
                                        <li class="list-group-item py-1" [ngClass]="{'text-danger':arrayImportiInterventoModificati['' + annualita.anno]<0}">{{printValueAsCurrency(arrayImportiInterventoModificati['' + annualita.anno])}}</li>

                                    </ul>
                                </td>
                                <!-- <td *ngFor="let annualita of listaAnnualita;let i =index;">{{printValueAsCurrency(annualita.importo)}} 
                                </td>-->
                                <td class="p-2">
                                    <ul class="list-group">
                                        <li class="list-group-item py-1">{{printValueAsCurrency(getTotaleAnnualita())}}</li>
                                        <li class="list-group-item py-1" style="height: 40px;"
                                        [ngClass]="{'text-danger':getTotaleRigaVariazioneIntervento()!=0}"
                                        >{{printValueAsCurrency(getTotaleRigaVariazioneIntervento())}}</li>
                                        <li class="list-group-item py-1" [ngClass]="{'text-danger':getTotaleAnnualita() !=arrayImportiInterventoModificati['totale']}"  >{{printValueAsCurrency(arrayImportiInterventoModificati['totale'])}}</li>

                                    </ul>
                                </td>
                                <!-- <td>{{printValueAsCurrency(getTotaleAnnualita())}}</td> -->
                            </tr>
                            <!--fine dettaglio intervento con titolo e vecchi valori-->



                            <!--inizio sezione finanziamenti-->
                            <!-- <tr *ngIf="listaFinanziamenti && listaFinanziamenti.length>0">
                                <td class="text-center bold" colSpan="{{3 + listaAnnualita.length}}">Finanziamenti</td>
                            </tr> -->

                            <ng-container *ngFor="let finanziamento of finanziamentiArray.controls;let j=index" formArrayName="finanziamentiArray">
                                <ng-container formGroupName="{{j}}">
                                    <tr class="background-table-green">
                                        <td class="p-2">
                                            <span class="bold">Finanziamento&nbsp;{{listaFinanziamenti[j].finanziamento.codice}}</span>
                                            <div class="" style="font-size: 13px;">{{listaFinanziamenti[j].finanziamento.titolo}}</div>
                                        </td>
                                        <td class="p-2">
                                            <ul class="list-group">
                                                <li class="list-group-item text-right py-1" style=" border: 1px solid rgba(0,0,0,.0); background-color: rgba(0,0,0,.0);">valore&nbsp;precedente</li>
                                                <li class="list-group-item text-right py-1" style="height: 40px; border: 1px solid rgba(0,0,0,.0); background-color: rgba(0,0,0,.0);">_+/-</li>
                                                <li class="list-group-item text-right py-1" style=" border: 1px solid rgba(0,0,0,.0); background-color: rgba(0,0,0,.0);">valore&nbsp;successivo</li>

                                            </ul>
                                        </td>
                                        <ng-container formArrayName="anni">
                                            <td class="p-2" *ngFor="let annualita of finanziamento.get('anni').controls;let anniIndex =index;">
                                                <ul class="list-group">
                                                    <li class="list-group-item py-1"> {{printValueAsCurrency(getValoreFinanziamentoXAnno(listaFinanziamenti[j].finanziamento.id,getAnno(anniIndex)))}}</li>
                                                    <li class="list-group-item py-1" [formGroupName]="anniIndex"><input type="text" formControlName="importo" [imask]="mask" [unmask]="true" /></li>
                                                    <li class="list-group-item py-1" [ngClass]="{'text-danger':matriceImportiModificatiFinanziamenti[listaFinanziamenti[j].finanziamento.codice][getAnno(anniIndex)]<0}">{{printValueAsCurrency(matriceImportiModificatiFinanziamenti[listaFinanziamenti[j].finanziamento.codice][getAnno(anniIndex)])}}</li>

                                                </ul>



                                            </td>
                                        </ng-container>
                                        <td class="p-2">
                                            <ul class="list-group">
                                                <li class="list-group-item py-1">{{printValueAsCurrency(getValoreFinanziamentoTotale(listaFinanziamenti[j].finanziamento.id))}}</li>
                                                <li class="list-group-item py-1" style="height: 40px;"
                                                [ngClass]="{'text-danger':getTotaleRigaVariazioneFinanziamento(finanziamento)!=0}"
                                                >{{printValueAsCurrency(getTotaleRigaVariazioneFinanziamento(finanziamento))}}</li>
                                                <li class="list-group-item py-1"
                                                [ngClass]="{'text-danger':getValoreFinanziamentoTotale(listaFinanziamenti[j].finanziamento.id)!=matriceImportiModificatiFinanziamenti[listaFinanziamenti[j].finanziamento.codice]['totale']}"
                                                >{{printValueAsCurrency(matriceImportiModificatiFinanziamenti[listaFinanziamenti[j].finanziamento.codice]['totale'])}}</li>

                                            </ul>

                                        </td>
                                    </tr>



                                </ng-container>
                            </ng-container>
                            <!--fine riga nuovi valori  con campi in sola lettura-->

                            <!--fine sezione finanziamenti-->
                        </tbody>
                        <!--footer con i totali-->
                        <tfoot>
                            <tr>
                                <th class="p-2">Totale Intervento</th>
                                <th class="p-2">&nbsp;</th>
                                <th class="p-2" *ngFor="let annualita of listaAnnualita;let i =index;">
                                    {{printValueAsCurrency(arrayImportiInterventoModificati['' + annualita.anno])}}
                                </th>
                                <th class="p-2">
                                    {{printValueAsCurrency(arrayImportiInterventoModificati['totale'])}}
                                </th>
                            </tr>
                            <tr *ngIf="listaFinanziamenti && listaFinanziamenti.length>0">
                                <th class="p-2">Totale finanziamenti</th>
                                <th class="p-2">&nbsp;</th>
                                <th class="p-2" *ngFor="let annualita of listaAnnualita;let i =index;">
                                    {{printValueAsCurrency(getTotaleFinanziamentiXAnno(annualita.anno))}}</th>
                                <th class="p-2">{{printValueAsCurrency(getTotaleFinanziamenti())}}</th>
                            </tr>
                            <tr *ngIf="listaFinanziamenti && listaFinanziamenti.length>0">
                                <th class="p-2">Differenza da coprire</th>
                                <th class="p-2">&nbsp;</th>
                                <th class="p-2" *ngFor="let annualita of listaAnnualita;let i =index;">
                                    <span
                                    [ngClass]="{'text-danger':arrayImportiInterventoModificati['' + annualita.anno] - getTotaleFinanziamentiXAnno(annualita.anno)<0}"
                                    >{{printValueAsCurrency(arrayImportiInterventoModificati['' + annualita.anno] - getTotaleFinanziamentiXAnno(annualita.anno))}}</span>
                                </th>
                                <th class="p-2">{{printValueAsCurrency(arrayImportiInterventoModificati['totale'] - getTotaleFinanziamenti())}}</th>
                            </tr>
                        </tfoot>
                        <!--fine footer con i totali-->

                    </table>
                </div>
                <!--fine tabella-->
                <!--bottone salva-->
                <div>
                    <button [disabled]="loading" class="btn btn-lg btn-primary btn-block" type="button" (click)="save()" style="margin:15px 0">
                        <img *ngIf="loading " class="pl-3 float-right "
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        Salva
          </button>
                </div>
                <!--fine bottone salva-->
            </form>
        </div>
    </div>

</div>