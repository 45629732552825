import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { DashboardPnrrNonDisegnabileService } from '../dashboard-pnrr-non-disegnabile/dashboard-pnrr-non-disegnabile.service';


@Component({
  selector: 'app-chart-instogramma-in-pila',
  templateUrl: './chart-instogramma-in-pila.component.html',
  styleUrls: ['./chart-instogramma-in-pila.component.css']
})
export class ChartInstogrammaInPilaComponent implements OnInit, OnDestroy {

  chart: any;

  renderingStatus = false;

  
  @Input() set chartData(data){
    this.myfunc(data);
  };

  @Input() natureNames: any[] =[];

  constructor(private service: DashboardPnrrNonDisegnabileService) { }

  ngOnInit(): void {    
  }

    myfunc(data?){

    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    if (this.chart)this.chart.dispose();
    this.chart = am4core.create("chartdiv", am4charts.XYChart);
    this.chart.numberFormatter.numberFormat = "#.#";

    // Add horizotal scrollbar
    this.chart.scrollbarX = new am4core.Scrollbar();
    const chartData = [];
    if(data && data.features){
      const mapped = data.features.flatMap(d => d.values_['pnrr']);
      const groupedBynatura = this.groupBy(mapped, 'naturaCode');

      for(let n of this.natureNames){
        const element = {};
        element['natura'] = n['value'];

        element["edificiScolastici"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Edifici Scolastici' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["strade"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Strade' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["stazioniFerroviarie"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Stazioni ferroviarie' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["metro"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Metro' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["stazioniMetro"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Stazione Metro' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["porti"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Porti' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["parcheggi"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Parcheggi' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["nodiDiScambio"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Nodi di scambio' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["parchiEareeVerdi"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Parchi e aree verdi' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["e_mobility"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'E-Mobility' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["infrastruttureFerroviarie"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Infrastrutture Ferroviarie' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["edificiGenerici"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Edifici generici' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["ciclabili"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Ciclabili' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["altreVieDiComunicazione"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Altre vie di comunicazione' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["materialeRotabileGomma"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Materiale rotabile gomma' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["materialeRotabileFerro"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Materiale rotabile ferro' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["apparecchiatureMediche"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Apparecchiature mediche' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["incarichiDiCollaborazione"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Incarichi di collaborazione' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["digitalizzazione"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Digitalizzazione' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["arteEcultura"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Arte e cultura' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["potenziamentoServiziScolasticiEuniversitari"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Potenziamento servizi scolastici e universitari' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["formazione"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Formazione' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["studiRicerca"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Studi Ricerca' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["ospediali_Presidi"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Ospediali - Presidi' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["attrezzature"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Attrezzature' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["ediliziaCimiteriale"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Edilizia Cimiteriale' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["luoghiDiCulto"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Luoghi di Culto' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["imipianti_acqua_luce_gas"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Imipianti (Acqua-Luce-Gas)' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["retiEsatelliti"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Reti e satelliti' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["asl"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'ASL' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["museiBibliotecheCultura"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Musei Biblioteche Cultura' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["aereoporti"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Aereoporti' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["bonificheTerritori_linee"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Bonifiche territori (linee)' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["bonificheTerritori_punti"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Bonifiche territori (punti)' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["contributiAdAltriSoggetti"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Contributi ad altri soggetti' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["interventiSulSociale_servizi"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Interventi sul sociale (Servizi)' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["operazioniSocietarie"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Operazioni Societarie' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["incentiviAdAttivitaProduttive"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Incentivi ad attività produttive' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["impiantiSportivi"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Impianti Sportivi' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["stazioniAutolinee"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Stazioni Autolinee' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["interventiSulSociale"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Interventi sul sociale' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["impianti_EnergieRinnovabili"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Impianti (Energie rinnovabili)' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["impiantiDiRisalita"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Impianti di risalita' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["opereDiUrbanizzazione"] = !groupedBynatura[n.key] ? 0 : groupedBynatura[n.key].filter(n => n.descrizione == 'Opere di Urbanizzazione' ).map(n => n.quantita).reduce( (a,b) => a+b,0);
        element["none"] = "none";

        chartData.push(element);
      }
      console.log(chartData);

      // Add data
      this.chart.data = chartData;
    }
    if(!this.renderingStatus){
      this.renderingStatus = true;
      this.service.doRendering(true);
    }
    // Create axes
    let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "natura";
    categoryAxis.renderer.grid.template.location = 0;


    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.extraMax = 0.1;
    valueAxis.calculateTotals = true;


    let label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 320;
    label.fontSize = 10;

    this.chart.colors.list = [


      am4core.color("#006600"),//acquisto beni
      am4core.color("#008000"),//acquisto beni
      am4core.color("#329932"),//acquisto beni
      am4core.color("#66b266"),//acquisto beni


      am4core.color("#000066"),//acquisto o realizzazione di servizi
      am4core.color("#000099"),//acquisto o realizzazione di servizi
      am4core.color("#0000cc"),//acquisto o realizzazione di servizi
      am4core.color("#0000ff"),//acquisto o realizzazione di servizi
      am4core.color("#3232ff"),//acquisto o realizzazione di servizi
      am4core.color("#6666ff"),//acquisto o realizzazione di servizi
      am4core.color("#9999ff"),//acquisto o realizzazione di servizi

      //am4core.color("#330700"),//realizzazione di lavori pubblici
      am4core.color("#4c0b00"),//realizzazione di lavori pubblici
      am4core.color("#660e00"),//realizzazione di lavori pubblici
      am4core.color("#7f1200"),//realizzazione di lavori pubblici
      am4core.color("#991600"),//realizzazione di lavori pubblici
      am4core.color("#b21900"),//realizzazione di lavori pubblici
      am4core.color("#cc1d00"),//realizzazione di lavori pubblici
      am4core.color("#e52100"),//realizzazione di lavori pubblici
      am4core.color("#ff2500"),//realizzazione di lavori pubblici
      am4core.color("#ff3a19"),//realizzazione di lavori pubblici
      am4core.color("#ff5032"),//realizzazione di lavori pubblici
      am4core.color("#ff664c"),//realizzazione di lavori pubblici
      am4core.color("#ff7c66"),//realizzazione di lavori pubblici
      am4core.color("#ff927f"),//realizzazione di lavori pubblici
      am4core.color("#ffa799"),//realizzazione di lavori pubblici
      am4core.color("#ffbdb2"),//realizzazione di lavori pubblici
      am4core.color("#ffd3cc"),//realizzazione di lavori pubblici
      // -- //
      //am4core.color("#4c3100"),//realizzazione di lavori pubblici
      am4core.color("#664200"),//realizzazione di lavori pubblici
      am4core.color("#7f5200"),//realizzazione di lavori pubblici
      am4core.color("#996300"),//realizzazione di lavori pubblici
      am4core.color("#b27300"),//realizzazione di lavori pubblici
      am4core.color("#cc8400"),//realizzazione di lavori pubblici
      am4core.color("#e59400"),//realizzazione di lavori pubblici
      am4core.color("#ffa500"),//realizzazione di lavori pubblici
      am4core.color("#ffae19"),//realizzazione di lavori pubblici
      am4core.color("#ffb732"),//realizzazione di lavori pubblici
      am4core.color("#ffc04c"),//realizzazione di lavori pubblici
      am4core.color("#ffc966"),//realizzazione di lavori pubblici
      am4core.color("#ffd27f"),//realizzazione di lavori pubblici
      am4core.color("#ffdb99"),//realizzazione di lavori pubblici
      am4core.color("#ffe4b2"),//realizzazione di lavori pubblici
      am4core.color("#ff9999"),//realizzazione di lavori pubblici
      am4core.color("#ff6666"),//realizzazione di lavori pubblici
      am4core.color("#ff3232"),//realizzazione di lavori pubblici

      am4core.color("#800080"),//concessione di contributi

      am4core.color("#00ffff"),//concessione di incentivi

      am4core.color("#ff00ff"),//sottoscrizione iniziale

    ];


    this.createSeries("materialeRotabileFerro","Materiale rotabile ferro");
    this.createSeries("materialeRotabileGomma","Materiale rotabile gomma");
    this.createSeries("apparecchiatureMediche","Apparecchiature mediche");
    this.createSeries("attrezzature","Attrezzature");

    this.createSeries("incarichiDiCollaborazione","Incarichi di collaborazione");
    this.createSeries("digitalizzazione","Digitalizzazione");
    this.createSeries("arteEcultura","Arte e cultura");
    this.createSeries("potenziamentoServiziScolasticiEuniversitari","Potenziamento servizi scolastici e universitari");
    this.createSeries("formazione","Formazione");
    this.createSeries("studiRicerca","Studi Ricerca");
    this.createSeries("interventiSulSociale_servizi","Interventi sul sociale (Servizi)");




    this.createSeries("edificiScolastici","Edifici Scolastici");
    this.createSeries("strade","Strade");
    this.createSeries("stazioniFerroviarie","Stazioni ferroviarie");
    this.createSeries("metro","Metro");
    this.createSeries("stazioniMetro","Stazione Metro");
    this.createSeries("porti","Porti");
    this.createSeries("parcheggi","Parcheggi");
    this.createSeries("nodiDiScambio","Nodi di scambio");
    this.createSeries("parchiEareeVerdi","Parchi e aree verdi");
    this.createSeries("e_mobility","E-Mobility");
    this.createSeries("infrastruttureFerroviarie","Infrastrutture Ferroviarie");
    this.createSeries("edificiGenerici","Edifici generici");
    this.createSeries("ciclabile","Ciclabili");
    this.createSeries("altreVieDiComunicazione","Altre vie di comunicazione");
    this.createSeries("ospedali_presidi","Ospediali - Presidi");
    this.createSeries("ediliziaCimiteriale","Edilizia Cimiteriale");
    this.createSeries("luoghiDiCulto","Luoghi di Culto");
    this.createSeries("impianti_acqua_luce_gas","Imipianti (Acqua-Luce-Gas)");
    this.createSeries("retiEsatelliti","Reti e satelliti");
    this.createSeries("asl","ASL");
    this.createSeries("museiBibliotecheCultura","Musei Biblioteche Cultura");
    this.createSeries("aereoporti","Aereoporti");
    this.createSeries("bonificheTerritori_linee","Bonifiche territori (linee)");
    this.createSeries("bonificheTerritori_punti","Bonifiche territori (punti)");
    this.createSeries("impiantiSportivi","Impianti Sportivi");
    this.createSeries("stazioniAutolinee","Stazioni Autolinee");
    this.createSeries("interventiSulSociale","Interventi sul sociale");
    this.createSeries("impianti_EnergieRinnovabili","Impianti (Energie rinnovabili)");
    this.createSeries("impiantiDiRisalita","Impianti di risalita");
    this.createSeries("opereDiUrbanizzazione","Opere di Urbanizzazione");

    this.createSeries("contributiAdAltriSoggetti","Contributi ad altri soggetti");

    this.createSeries("incentiviAdAttivitaProduttive","Incentivi ad attività produttive");

    this.createSeries("operazioniSocietarie","Operazioni Societarie");
     //this.createSeries("none","none");





    let title = this.chart.titles.create();
      title.text = "Quota di interventi previsti per natura e tipologia";
      title.fontSize = 18;
      //title.marginBottom = 30;

       // Enable export
    this.aggiungiLogo(this.chart);

    this.chart.exporting.menu = new am4core.ExportMenu();

    this.chart.exporting.menu.align = "left";
    this.chart.exporting.menu.verticalAlign = "top";
    this.chart.exporting.menu.background = "#ff0000";
    this.chart.exporting.menu.defaultStyles = true;
    //this.chart.exporting.filePrefix = title;
    this.chart.exporting.menu.items = [
        {
            menu: [
                { type: "jpg", label: "JPG" },
                { type: "png", label: "PNG" },
                { type: "csv", label: "CSV" },
            ],
        },
    ];
    this.chart.exporting.menu.items[0].icon = "assets/img/export.png";


    // Create series for total
    var totalSeries = this.chart.series.push(new am4charts.ColumnSeries());
    totalSeries.dataFields.valueY = "none";
    totalSeries.dataFields.categoryX = "natura";
    totalSeries.stacked = true;
    totalSeries.hiddenInLegend = true;
    totalSeries.columns.template.strokeOpacity = 0;

    var totalBullet = totalSeries.bullets.push(new am4charts.LabelBullet());
    totalBullet.dy = -20;
    totalBullet.label.text = "Totale: {valueY.total}";
    totalBullet.label.hideOversized = false;
    totalBullet.label.fontSize = 16;
    //totalBullet.label.background.fill = totalSeries.stroke;
    //totalBullet.label.background.fillOpacity = 0.2;
    //totalBullet.label.padding(5, 10, 5, 10);

    // Legend
    this.chart.legend = new am4charts.Legend();
    this.chart.legend.position = "right";
    this.chart.legend.scrollable = true;
    this.chart.legend.itemContainers.template.paddingTop = 1;
    this.chart.legend.itemContainers.template.paddingBottom = 1;


    let markerTemplate = this.chart.legend.markers.template;
    markerTemplate.width = 12;
    markerTemplate.height = 12;
    this.chart.legend.labels.template.fontSize = 13;

    this.chart.events.on("ready", () => {
      if(this.renderingStatus){
        this.renderingStatus = false;
        this.service.doRendering(false);
      }
    });
  }

  // Create series
  createSeries(field, name) {
    if(!this.chart) return;
    // Set up series
    let series = this.chart.series.push(new am4charts.ColumnSeries());
    series.name = name;
    series.dataFields.valueY = field;
    series.dataFields.categoryX = "natura";
    series.sequencedInterpolation = true;

    // Make it stacked
   series.stacked = true;

    // Configure columns
    series.columns.template.width = am4core.percent(40);
    series.columns.template.tooltipText = "{categoryX}[/]\n[font-size:14px]{name}: [bold]{valueY}";

    // Add label
    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{valueY}";
    labelBullet.locationY = 0.5;
    labelBullet.label.fontSize = 12;
    labelBullet.label.hideOversized = true;
    labelBullet.label.fill = am4core.color('#ffffff');


    return series;
  }

  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  ngOnDestroy(){
    this.chart && this.chart.dispose();
  }

  aggiungiLogo(logoChart) {
    // Add watermark
    const watermark = new am4core.Image();
    watermark.href = "assets/img/logo_blu.svg";
    logoChart.tooltipContainer.children.push(watermark);
    watermark.align = "right";
    watermark.valign = "bottom";
    watermark.opacity = 0.3;
    watermark.marginRight = 10;
    watermark.marginBottom = 5;
    watermark.disabled = true;

    // Enable watermark on export
    logoChart.exporting.events.on("exportstarted", function (ev) {
        watermark.disabled = false;
    });

    // Disable watermark when export finishes
    logoChart.exporting.events.on("exportfinished", function (ev) {
        watermark.disabled = true;
    });

    // Add watermark to validated sprites
    logoChart.exporting.validateSprites.push(watermark);
  }
  /*---*/
}
