<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
    <div class="modal-header text-center">
        <h4 class="modal-title pull-left">Modifica Dati Provincia</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="col-lg-12">
            <div class="row">
                <!-- *** -->
                <div class="form-group col-xl-6 col-lg-6">
                    <h5><label for="name" class="mandatory">Nome:</label></h5>
                    <div class="">
                        <input [formGroup]="editForm" formControlName="name" type="text" value="" class="form-control" placeholder="Inserisci il nome della provincia" [ngClass]="{
                'form-control': true,
                'is-invalid': submitted && f.name.errors
                }" />
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.name.errors.required">
                                Nome &egrave; obbligatorio
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group col-xl-6 col-lg-6">
                    <h5><label for="code" class="mandatory">Codice:</label></h5>
                    <div class="">
                        <input [formGroup]="editForm" formControlName="code" type="text" value="" class="form-control" maxlength="3" placeholder="Inserisci il codice della provincia" [ngClass]="{
                'form-control': true,
                    'is-invalid': submitted && f.code.errors
                }" />
                        <div *ngIf="submitted && f.code.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.code.errors.required">
                                Codice &egrave; obbligatorio
                            </div>
                            <div *ngIf="f.code.errors.minlength">
                                Il codice deve essere di 3 caratteri
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- *** -->
                <div class="form-group col-xl-3 col-lg-3">
                    <h5> <label for="name" class="mandatory">Acronimo:</label></h5>
                    <div class="">
                        <input [formGroup]="editForm" formControlName="acronym" type="text" value="" maxlength="2" class="form-control" placeholder="Inserisci l'acronimo della regione" [ngClass]="{
                'form-control': true,
                    'is-invalid': submitted && f.acronym.errors
                }" />
                        <div *ngIf="submitted && f.acronym.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.acronym.errors.required">
                                Acronimo &egrave; obbligatorio
                            </div>
                            <div *ngIf="f.acronym.errors.minlength">
                                L'acronimo deve essere di 2 caratteri
                            </div>
                        </div>
                    </div>
                </div>


                <ng-template #cittaMetropolitana>
                  <span>
                    Città Metropolitana
                  </span>
                </ng-template>
                <div class="form-group col-xl-3 col-lg-3">
                    <label for="isCittametropolitana"> <h5 class="mandatory"> <a type="button" class="help"
                      [popover]="cittaMetropolitana"
                      placement="top" triggers="mouseenter:mouseleave">

                        <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                        </i>
                       </a>&nbsp;CM:</h5></label>



                    <div class="">
                        <ng-select formControlName="isCittametropolitana" [items]="yesNo" bindValue="k" placeholder="Seleziona" bindLabel="v" selectOnTab="true" [searchable]="true" [clearable]="false" notFoundText="Nessun risultato trovato..." [ngClass]="{
                    'is-invalid': submitted && f.isCittametropolitana.errors
                }">
                        </ng-select>
                        <div *ngIf="submitted && f.isCittametropolitana.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.isCittametropolitana.errors.required">
                                CM &egrave; obbligatoria
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group col-xl-6 col-lg-6">
                    <h5><label for="istat" class="mandatory">Regione:</label></h5>
                    <div class="">
                        <ng-select formControlName="region" [items]="regions" bindValue="id" placeholder="Seleziona" bindLabel="value" selectOnTab="true" [searchable]="true" [clearable]="false" notFoundText="Nessun risultato trovato..." [ngClass]="{
                    'is-invalid': submitted && f.region.errors
                }">
                        </ng-select>
                        <div *ngIf="submitted && f.region.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.region.errors.required">
                                Regione &egrave; obbligatoria
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <!--***-->
                <div class="form-group col-xl-6 col-lg-6">
                    <h5><label class="">Data Inizio Validità:</label></h5>

                    <div class="input-group">
                        <input placeholder="Seleziona la data di inizio validità" formControlName="fromDate" type="text" class="form-control" bsDatepicker #dp="bsDatepicker" (bsValueChange)="onChangeFromDate($event)" [bsConfig]="{
                        dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true,
                        containerClass: 'theme-dark-blue',
                        isAnimated: true
                    }" [ngClass]="{
                        'is-invalid': submitted && f.fromDate.errors
                    }" />
                        <button type="button" class="btn btn-primary mt-0" (click)="dp.show()" [attr.aria-expanded]="dp.isOpen">
                        <i class="fas fa-calendar-alt"></i>
                    </button>
                    </div>
                </div>

                <div class="form-group col-xl-6 col-lg-6">
                    <h5> <label class="">Data Fine Validità:</label></h5>

                    <div class="input-group">
                        <input placeholder="Seleziona la data di fine validità" formControlName="toDate" type="text" class="form-control" bsDatepicker #dp2="bsDatepicker" (bsValueChange)="onChangeToDate($event)" [bsConfig]="{
                        dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true,
                        containerClass: 'theme-dark-blue',
                        isAnimated: true
                    }" [ngClass]="{
                        'is-invalid': submitted && f.toDate.errors
                    }" />
                        <button type="button" class="btn btn-primary mt-0" (click)="dp2.show()" [attr.aria-expanded]="dp.isOpen">
                        <i class="fas fa-calendar-alt"></i>
                    </button>
                    </div>
                </div>
            </div>

            <div class="text-center my-3">
                <button class="btn btn-primary" type="submit" style="font-weight: bold;" [disabled]="!editForm.dirty">
                AGGIORNA PROVINCIA / CM
                &nbsp;<i aria-hidden="true" class="fa fa-redo-alt"></i>
            </button>
            </div>

        </div>
    </div>
</form>
