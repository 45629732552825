
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SicerGestioneMicroservicesService {

  private protectedUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.sicer_microservices}`;
  scheduleServiceUrl = "schedule"
  constructor(private http: HttpClient) { }

  scheduleMs(microservice: any, params: any) {
    return this.http.post<any>(`${this.protectedUrl}${this.scheduleServiceUrl}`,
      microservice,
      params ? { params } : undefined
    );
  }
}