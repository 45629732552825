import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    TemplateRef
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TipologiaErogazione } from '../../models';
import { TipologiaErogazioneService } from '../../services';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { ColumnMode } from '@swimlane/ngx-datatable';


@Component({
    selector: 'tipologie-erogazioni-component',
    templateUrl: './tipologie-erogazioni.component.html',
    styleUrls: ['./tipologie-erogazioni.component.css']
})
export class TipologieErogazioniComponent
    implements OnInit {
    dtOptions: any = {};
    submitted: boolean = false;
    loading:boolean=false;
    editForm: FormGroup;
    modalRef: BsModalRef;
    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };
    open: boolean = false;
    buttonLabel: string = "Inserisci";

    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;
    @ViewChild('templateModal', { static: true })
    public templateModal: TemplateRef<any>;

    //@ViewChild('buttonsTemplate',{static:false}) buttonsTemplate: TemplateRef<any>;
    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    
    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    templateList = [];

    columns = [
        {
            name: 'id', prop: 'id',visible:false, sortable: true, filtrable: true, width: 10
        }
        
        , { name: 'titolo&ensp;'
        , prop: 'titolo'
        , sortable: true
        , filtrable: true 
        , flexGrow: 2
        , minWidth: 100
        

    }        
        , { name: 'descrizione'
        , prop: 'descrizione'
        , sortable: true
        , filtrable: true        
        //,cellClass: 'mobile-hidden desktop-hidden'
        //,headerClass: 'mobile-hidden desktop-hidden'
        , flexGrow: 12
            , minWidth: 200
     }
     , {
        name: 'modifica'
        , prop: 'id'
        , sortable: false
        , filtrable: false
        , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
        , flexGrow: 1
            , minWidth: 60

    }
    , {
        name: 'elimina'
        , prop: 'id'
        , sortable: false
        , filtrable: false
        , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
        , flexGrow: 1
        , minWidth: 60

    }        
    ];


    constructor(
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private el: ElementRef,
        private localeService: BsLocaleService,
        private toastr: ToastService,
        private modalService: BsModalService,
        private currentService: TipologiaErogazioneService,
    ) {
        this.localeService.use("it");
        this.dtOptions = {
            rows: []
            ,totalElements:0
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: false// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: false// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: false
            , scrollbarH: "true"
        };
        this.getTipologie();
    }    

    ngOnInit() {
        window.scroll(0, 0);
        this.templateList = [ { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }, { name: 'btnEditTemplate', template: this.btnEditTemplate }];
        this.editForm = this.initializeFormGroup();
    }


    getTipologie() {
        this.currentService.getAll().subscribe(
            result => {
                
                this.ngxDatatableWrapper.setInitialData(result);

            }
        );

    }

    initializeFormGroup() {
        return this.formBuilder.group({
            id: [null],
            descrizione: [null, [Validators.required, Validators.maxLength(255)]],
            titolo:[null,[Validators.required]]
        });
    }

    get f() {
        return this.editForm.controls;
    }


    onSubmit() {
        this.submitted = true;
        this.editForm.updateValueAndValidity();
        let errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );
        }
        if (this.editForm.invalid || errors) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.editForm.controls) {
                if (this.editForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.editForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.editForm.get(el)["controls"]) {
                            if (this.editForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }

        const obj = this.editForm.value;
        
        this.currentService.save(obj).subscribe(res => {
            this.toastr.success("Tipologia salvata con successo!");
            this.submitted = false;
            this.open = false;
            this.editForm.reset();
            
            this.buttonLabel="Inserisci";
            if (!obj.id){
                //new row
                this.ngxDatatableWrapper.addRow(res);                        
            }
            else{
                this.modalRef.hide();
                this.ngxDatatableWrapper.updateRow(obj);                                                
            }

        }, error => {
            this.toastr.error("Errore di sistema");
        });
    }

   

    onEdit(row) {
        this.submitted = false;
        console.log(row);
        this.buttonLabel = "Aggiorna";
        this.modalRef = this.modalService.show(this.templateModal, this.config);
        this.editForm.setValue({
            id: row.id,
            descrizione:row.descrizione,   
            titolo:row.titolo
        });
    }

    onDelete(row) {
        console.log(row);
        if (!confirm(`Stai per eliminare la tipologia "${row.titolo}", proseguire?`)) return;
        this.loading = true;
        this.currentService.delete(row.id).subscribe(
            result => {
                this.loading = false;
                this.ngxDatatableWrapper.deleteRow(row);
                this.toastr.success('Tipologia eliminata con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile eliminare la tipologia: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                console.log('error');
            });
    }
    closeModal(){
        this.modalRef.hide();
        this.editForm.reset();
        this.buttonLabel="Inserisci";
    }    
}
