import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxDatatableWrapperComponent } from '../_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services';
import { UserSection } from 'src/app/models/userSection';
import { AccordionPanelComponent } from 'ngx-bootstrap/accordion';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-update-permissions',
  templateUrl: './update-permissions.component.html',
  styleUrls: ['./update-permissions.component.css']
})
export class UpdatePermissionsComponent implements OnInit {

  @ViewChild(NgxDatatableWrapperComponent)
  ngxDatatableWrapper: NgxDatatableWrapperComponent;

  @ViewChild('templateEditAdd', { static: true })
  public templateEditAdd: TemplateRef<any>;
  @ViewChild('btnInsertPermissionTemplate', { static: true })
  public btnInsertPermissionTemplate: TemplateRef<any>;
  @ViewChild('btnUpdatePermissionTemplate', { static: true })
  public btnUpdatePermissionTemplate: TemplateRef<any>;
  @ViewChild('btnReadPermissionTemplate', { static: true })
  public btnReadPermissionTemplate: TemplateRef<any>;
  @ViewChild('btnDeletePermissionTemplate', { static: true })
  public btnDeletePermissionTemplate: TemplateRef<any>;
  @ViewChild('templateAddPermissions', { static: true })
  public templateAddPermissions: TemplateRef<any>;
  @ViewChild('btnDeleteTemplate', { static: true })
  public btnDeleteTemplate: TemplateRef<any>;

  @ViewChild(AccordionPanelComponent, { static: false })
  accordion: AccordionPanelComponent;

  @Input()
  userId: number;
  @Input()
  username: string;
  @Input()
  allSections: any[] = [];

  sectionsSet: string[] = [];
  sectionsToSet: any[] = [];
  modalRef: BsModalRef;
  dtOptionsNgx: any;
  templateList = [];
  mapCheckboxValues: Map<string, UserSection> = new Map<string, UserSection>();
  fakeReload = false;
  currentData: any = null;
  modifiedPermissions: boolean = false;
  public onClose: Subject<boolean>;
  currentDtParams = null;
  currentCallback = null;
  toConfirm: boolean = false;

  constructor(
    private userService: UserService,
    public bsModalRef: BsModalRef,
    private authService: AuthService,
    private toastr: ToastrService
  ) {
    this.dtOptionsNgx = {
      rows: []
      , totalElements: 0
      , columns: this.columns
      , columnMode: ColumnMode.flex
      , headerHeight: "50"
      , footerHeight: "50"
      , rowHeight: "auto"
      , externalSorting: true // true
      , loadingIndicator: 'loading'
      , class: "bootstrap"
      , summaryRow: true
      , summaryPosition: "'bottom'"
      , externalPaging: true // true
      , count: 0
      , offset: 0
      , limit: 10
      , serverSide: true
      , ajax: this.loadData
      , scrollbarH: "true"
    };

    this.onClose = new Subject();
  }

  columns = [
    {
        name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
        , resizeable: false
    },
    {
        name: 'user_id', prop: 'userId', visible: false, sortable: true, filtrable: true, width: 10
        , resizeable: false
    },
    {
        name: 'section_id', prop: 'sectionId', visible: false, sortable: true, filtrable: true, width: 10
        , resizeable: false
    },
    { name: 'sezione'
      , prop: 'name'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , flexGrow: 3
      , minWidth: 100
    }
    , { name: 'lettura'
      , prop: 'read'
      , sortable: false
      , filtrable: false
      , resizeable: false
      , draggable: false
      , cellTemplate: 'btnReadPermissionTemplate'
      , flexGrow: 2
      , minWidth: 60
    }
    , { name: 'inserimento'
      , prop: 'insert'
      , sortable: false
      , filtrable: false
      , resizeable: false
      , draggable: false
      , cellTemplate: 'btnInsertPermissionTemplate'
      , flexGrow: 2
      , minWidth: 60
    }
    , { name: 'modifica'
      , prop: 'update'
      , sortable: false
      , filtrable: false
      , resizeable: false
      , draggable: false
      , cellTemplate: 'btnUpdatePermissionTemplate'
      , flexGrow: 2
      , minWidth: 60
    }
    , { name: 'cancellazione'
      , prop: 'delete'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , cellTemplate: 'btnDeletePermissionTemplate'
      , flexGrow: 2
      , minWidth: 60
    }
    , {
      name: 'elimina'
      , prop: null
      , sortable: false
      , filtrable: false
      , cellTemplate: 'btnDeleteTemplate'
      , resizeable: false
      , draggable: false
      , flexGrow: 1
      , minWidth: 60
    }
  ];

  ngOnInit() {
    this.templateList = [
      { name: 'btnInsertPermissionTemplate', template: this.btnInsertPermissionTemplate },
      { name: 'btnUpdatePermissionTemplate', template: this.btnUpdatePermissionTemplate },
      { name: 'btnReadPermissionTemplate', template: this.btnReadPermissionTemplate },
      { name: 'btnDeletePermissionTemplate', template: this.btnDeletePermissionTemplate },
      { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }
    ];
  }

  onCloseModal() {
    this.mapCheckboxValues = new Map<string, UserSection>();
    this.checkClosingModal();
  }

  private checkClosingModal() {
    if (this.toConfirm) {
      const time = this.accordion.isOpen ? 0 : 400;
      this.accordion.isOpen = true;
      setTimeout(() => {
        if (confirm('Hai delle modifiche non salvate\nContinuare?')) {
          if (this.modifiedPermissions) {
            this.onClose.next(true);
          } else {
            this.bsModalRef.hide();
          }
        }
      }, time);
    } else {
      if (this.modifiedPermissions) {
        this.onClose.next(true);
      } else {
        this.bsModalRef.hide();
      }
    }
  }

  toConfirmHandler(value: boolean) {
    this.toConfirm = value;
  }

  enableHandler($event: any, row: any, name: string) {
    const value = $event.target.checked;
    row[name] = value;
    this.userService.updateUserPermissionsByUserId(this.userId, row).subscribe(resp => {
      const elem = this.mapCheckboxValues.get(row.name);
      elem[name] = value;
      this.mapCheckboxValues.set(row.name, elem);

      this.checkReloadOwnPermissions();
      this.toastr.success('Permessi aggiornati con successo!');
    }, error => {
      this.toastr.error('Errore');
      row[name] = !value;
      this.fakeReload = true;
      this.refreshGrid();
    });
  }

  addPermissionHandler(permission: UserSection) {
    this.accordion.isOpen = false;
    this.toConfirm = false;
    this.toastr.success('Nuovi permessi aggiunti con successo!');
    permission['sectionId'] = permission.section.id;
    permission['userId'] = permission['userId'] ? permission['userId'] : permission['id'].userId;
    permission.name = permission.section.name;
    this.currentData.data.push(permission);
    this.currentData.recordsFiltered++;
    this.currentData.recordsTotal++;
    this.fakeReload = true;
    this.modifiedPermissions = true;
    this.checkReloadOwnPermissions();
    this.refreshGrid();
  }

  deleteHandler(row: any) {
    if (confirm('Sei sicuro di voler eliminare i permessi relativi a ' + row.name + '?')) {
      this.userService.deleteUserPermissionsByUserIdAndSectionId(this.userId, row.sectionId).subscribe(resp => {
        this.toastr.success('Permessi cancellati con successo!');
        this.mapCheckboxValues.delete(row.name);
        this.sectionsSet = this.sectionsSet.filter(section => section !== row.name);
        this.checkReloadOwnPermissions();
        this.currentData.data = this.currentData.data.filter(data => data.name !== row.name);
        this.currentData.recordsFiltered--;
        this.currentData.recordsTotal--;
        this.modifiedPermissions = true;
        if (this.currentData.data.length === 0) {
          this.resetGrid();
        } else {
          this.refreshGrid();
        }
      }, error => {
        this.toastr.error('Errore');
      });
    }
  }

  private checkReloadOwnPermissions() {
    const token = this.authService.getCurrentToken();
    const user = this.authService.getCurrentUser();
    if (this.username === user.user_name) {
      this.authService.fetchPermissionsByToken(token);
    }
  }

  private refreshGrid() {
    this.loadData(this.currentDtParams, this.currentCallback);
  }

  private resetGrid() {
    this.ngxDatatableWrapper.reset();
  }

  loadData = (dataTablesParameters: any, callback: any) => {
    this.currentDtParams = dataTablesParameters;
    this.currentCallback = callback;

    if (this.fakeReload) {
      this.fakeReload = false;
      this.executeLoadData(this.currentData);
      callback(this.currentData);
    } else {
      this.userService.getAllPermissionsById(dataTablesParameters, this.userId).subscribe(resp => {
        this.currentData = resp;
        this.executeLoadData(resp);
        callback(resp);
      }, error => {
        this.toastr.error('Errore');
      });
    }
  };

  executeLoadData(resp: any) {
    resp.data.forEach(data => {
      const elem = this.mapCheckboxValues.get(data.name);
      if (!elem) {
        this.mapCheckboxValues.set(data.name, data);
        this.sectionsSet.push(data.name);
      }
    });

    this.sectionsToSet = [];
    this.allSections.forEach(section => {
      if (!this.sectionsToSet.find(sezione => sezione.key === section.value) && !this.sectionsSet.includes(section.value)) {
        this.sectionsToSet.push(section);
      }
    });

    this.sectionsToSet = [...this.sectionsToSet];
  }
}
