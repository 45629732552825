<div class="p-5 container-xl text-center" [hidden]="loadDataCounter<=0">
  <app-loading message="Caricamento in corso..."></app-loading>
</div>
<div class="container-xl" [hidden]="loadDataCounter>0">

  <div class="position-sticky sticky-top   pt-4 pb-4 elemento-aggiorna mb-5 shadow-custom"
    [ngClass]="{'sticky-top-custom-modal': (isInModal) ||(currentIntervento && !currentIntervento.isMain),'sticky-top-custom':!isInModal &&(!currentIntervento ||( currentIntervento && currentIntervento.isMain) )
    ,'sticky-top-custom-sal': tabsOnOneRow
        }">
    <div class=" row  justify-content-center">
      <div class="col-lg-4 col-md-6 text-center " [hidden]="readOnlyForm">
        <button [disabled]="loading" (click)="openAddModal()" class="btn btn-primary btn-block" type="button">
          Aggiungi nuova fase&nbsp;<i class="fas fa-plus"></i>
        </button>
      </div>
      <div class="col-lg-4  col-md-6 text-center " *ngIf="gotoBtnOptions && gotoBtnOptions.show">
        <button (click)="gotoBackBtnClick()" [disabled]="loading" class="btn btn-secondary btn-block font-weight-bold"
          type="button">
          {{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}
          &nbsp;<i class="fas fa-times" *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i>
        </button>
      </div>
    </div>

  </div>
  <!--***-->


  <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;"
    [dtOptions]="dtOptions">
    <ng-template #btnEditTemplate let-row="row" let-value="value">
      <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top"
        title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
    </ng-template>
    <ng-template #btnDeleteTemplate let-row="row" let-value="value">
      <a [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top"
        title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
    </ng-template>
    <ng-template #soggettirespoTemplate let-row="row" let-value="value">
      <div *ngFor="let name of getSoggResp(value)">
        <span>{{ name }}</span>
      </div>
    </ng-template>
    <ng-template #fieldNoteTemplate let-row="row" let-value="value">
      <i class="fas font-size-18 text-secondary" [ngClass]="{
                      ' fa-comment-slash': !value,
                      ' fa-comment text-info help px-1': value
                    }" popover="{{value}}" placement="left" triggers="mouseenter:mouseleave"></i>
    </ng-template>
    <ng-template #fieldFlussoDiCassaTemplate let-row="row" let-value="value">
      <i class="fas fa-euro-sign font-size-18 " [ngClass]="{
                      ' text-secondary': !value,
                      ' text-success text-info help px-1': value
                    }"
        popover="{{value && row.previsioneDiImporto ? printValueAsCurrency(row.previsioneDiImporto):''}}"
        placement="left" triggers="mouseenter:mouseleave"></i>
    </ng-template>

    <ng-template #fieldDocumentazioneTemplate let-row="row" let-value="value">
        <i class="fas fa-file font-size-18 " [ngClass]="{
                        ' text-secondary': !value,
                        ' text-success text-info help px-1': value
                      }"
          popover="{{value  ? 'Documentazione presente':'Documentazione non presente'}}"
          placement="left" triggers="mouseenter:mouseleave"></i>
      </ng-template>

  </ngx-datatable-wrapper>
</div>
<ng-template #templateEditAdd>


  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">{{modalTitle}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
      <input type="hidden" [formGroup]="editForm" formControlName="id" />

      <div class="mt-3">

        <div class="form-group row">
            <div class="col-sm-12">
              <label for="tipologiaFaseFase">
                <h5 class="mandatory">Tipologia fase:</h5>
              </label>
              <div [ngClass]="{ 'is-invalid': submitted && f.tipologiaFaseFase.errors }">
                <ng-select [formGroup]="editForm" formControlName="tipologiaFaseFase" [items]="tipologieFasiFasi"
                   placeholder="Seleziona la tipologia della fase"
                   (change)="tipologiaFaseFaseChangeValue($event)"
                   >
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item }}">
                      {{ item }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div *ngIf="submitted && f.tipologiaFaseFase.errors" class="invalidFieldMessage" style="display: block;">
                <div *ngIf="f.tipologiaFaseFase.errors" class="invalid-feedback" style="display: block;">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-12">              
              <div [ngClass]="{ 'is-invalid': submitted && f.tipologiaFaseTitolo.errors }">
                <ng-select [formGroup]="editForm" formControlName="tipologiaFaseTitolo" [items]="TipologieFasiTitoli"
                   placeholder="Seleziona il titolo della fase"
                   (change)="tipologiaFaseTitoloChangeValue($event)"
                   >
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item }}">
                      {{ item }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div *ngIf="submitted && f.tipologiaFaseTitolo.errors" class="invalidFieldMessage" style="display: block;">
                <div *ngIf="f.tipologiaFaseTitolo.errors" class="invalid-feedback" style="display: block;">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>
        <div class="form-group row">
          <div class="col-sm-12">            
            <div [ngClass]="{ 'is-invalid': submitted && f.tipologia.errors }">
              <ng-select [formGroup]="editForm" formControlName="tipologia" [items]="tipologieFase" bindValue="id"
                bindLabel="descrizioneEstesa" placeholder="Seleziona la descrizione della fase">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.descrizioneEstesa }}">
                    {{ item.descrizioneEstesa }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div *ngIf="submitted && f.tipologia.errors" class="invalidFieldMessage" style="display: block;">
              <div *ngIf="f.tipologia.errors" class="invalid-feedback" style="display: block;">
                Campo obbligatorio
              </div>
            </div>
          </div>
        </div>

        <div class=" form-row">
          <div class="form-group col-sm-6">
            <a type="button" [hidden]="!historyMotivazioni" title="clicca per leggere le motivazioni" class=""
              placement="right" delay="300" [popover]="popTemplate">

              <i class="fas fa-info-circle font-size-18 text-warning pr-1"></i>
            </a>

            <ng-template #popTemplate>
              <div class="overflow-auto scroll-h pr-1 mt-2" style="max-height: 430px;">
                <ul class="list-group mb-2" *ngFor="let item of historyMotivazioni">
                  <li class="list-group-item list-group-item-primary py-1"><span class="bold">Data
                      modifica:</span><br /><span>{{printValueAsDateTime(item.data)}}</span></li>
                  <!--ho messo lo span per dinacimizzare il dato-->
                  <li class="list-group-item  py-1"><span class="bold">Motivo della
                      modifica:</span><br /><span>{{item.motivazione}}</span></li>
                  <li class="list-group-item list-group-item-danger py-1"><span class="bold">Data prevista
                      inizio:</span><br /><span>{{printValueAsDate(item.dataPrevistaInizio)}}</span></li>
                  <li class="list-group-item list-group-item-success py-1"><span class="bold">Data prevista
                      fine:</span><br /><span>{{printValueAsDate(item.dataPrevistaFine)}}</span></li>
                </ul>
              </div>
            </ng-template>


            <label for="dataPrevistaInizio">
              <h5 class="mandatory">Data prevista inizio:</h5>
            </label>
            <div [ngClass]="{ 'is-invalid': submitted && f.dataPrevistaInizio.errors }"
              class="col-lg-12 col-md-12 col-sm-12 col-12" style="display:flex; padding-right:0px;padding-left:0px">
              <input type="text" formControlName="dataPrevistaInizio" class="form-control" bsDatepicker
                [formGroup]="editForm"
                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY', adaptivePosition: true, containerClass:'theme-dark-blue' }"
                #dataPrevistaInizio="bsDatepicker"
                style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
              <button class="btn btn-primary" (click)="dataPrevistaInizio.toggle()" type="button"
                style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>
            <div *ngIf="submitted && f.dataPrevistaInizio.errors" class="invalid-feedback" style="display: block;">
              <div *ngIf="f.dataPrevistaInizio.errors.required">
                Campo obbligatorio
              </div>
            </div>
          </div>

          <div class="form-group col-sm-6">


            <label for="dataInizio">
              <h5 class="">Data inizio:</h5>
            </label>
            <div [ngClass]="{ 'is-invalid': submitted && f.dataInizio.errors }"
              class="col-lg-12 col-md-12 col-sm-12 col-12" style="display:flex; padding-right:0px;padding-left:0px">
              <input type="text" formControlName="dataInizio" class="form-control" bsDatepicker [formGroup]="editForm"
                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY', adaptivePosition: true,containerClass:'theme-dark-blue' }"
                #dataInizio="bsDatepicker" style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
              <button class="btn btn-primary" (click)="dataInizio.toggle()" type="button"
                style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>
            <div *ngIf="submitted && f.dataInizio.errors" class="invalid-feedback" style="display: block;">
              <div *ngIf="f.dataInizio.errors.required">
                Campo obbligatorio
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-6">
            <label for="dataPrevistaFine">
              <h5 class="mandatory">Data prevista fine:</h5>
            </label>
            <div [ngClass]="{ 'is-invalid': submitted && f.dataPrevistaFine.errors }"
              class="col-lg-12 col-md-12 col-sm-12 col-12" style="display:flex; padding-right:0px;padding-left:0px">
              <input type="text" formControlName="dataPrevistaFine" class="form-control" bsDatepicker
                [formGroup]="editForm"
                [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY',containerClass:'theme-dark-blue' }"
                #dataPrevistaFine="bsDatepicker"
                style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
              <button class="btn btn-primary" (click)="dataPrevistaFine.toggle()" type="button"
                style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>
            <div *ngIf="submitted && f.dataPrevistaFine.errors" class="invalid-feedback" style="display: block;">
              <div *ngIf="f.dataPrevistaFine.errors.required">
                Campo obbligatorio
              </div>
            </div>
          </div>

          <div class="form-group col-sm-6">
            <label for="dataFine">
              <h5 class="">Data fine:</h5>
            </label>
            <div [ngClass]="{ 'is-invalid': submitted && f.dataFine.errors }"
              class="col-lg-12 col-md-12 col-sm-12 col-12" style="display:flex; padding-right:0px;padding-left:0px">
              <input type="text" formControlName="dataFine" class="form-control" bsDatepicker [formGroup]="editForm"
                [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY',containerClass:'theme-dark-blue' }"
                #dataFine="bsDatepicker" style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
              <button class="btn btn-primary" (click)="dataFine.toggle()" type="button"
                style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>
            <div *ngIf="submitted && f.dataFine.errors" class="invalid-feedback" style="display: block;">
              <div *ngIf="f.dataFine.errors.required">
                Campo obbligatorio
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <hr>
        </div>
        <!--flusso di cassa-->
        <div class="form-group">
          <h5><label for="" class="">Flusso di cassa</label></h5>
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="customSwitch2" [formGroup]="editForm"
              formControlName="flussoDiCassa" (change)="flussoDiCassaOnChange($event)" />
            <label class="custom-control-label" for="customSwitch2"></label>
          </div>
        </div>

        <div *ngIf="editForm.controls.flussoDiCassa.value">
          <div class="form-group ">
            <div class="">
              <h5> <label for="previsioneDiImporto" class="mandatory">Previsione di importo:</label></h5>
              <input [formGroup]="editForm" formControlName="previsioneDiImporto" maxlength="255" type="text"
                class="form-control" placeholder="Inserisci l'importo" [ngClass]="{
                'is-invalid': submitted && editForm.controls.previsioneDiImporto.errors
                }" [imask]="maskImporto" [unmask]="true" />
              <div *ngIf="submitted && editForm.controls.previsioneDiImporto.errors" class="invalid-feedback"
                style="display: block;">
                <div *ngIf="editForm.controls.previsioneDiImporto.errors.required">
                  Campo obbligatorio
                </div>
              </div>

            </div>
          </div>
          <!--inizio capitoli di bilancio-->
          <div class="col-lg-12 mt-4">
            <div class="row justify-content-between">

              <div class="col-lg-6 col-sm-6 col-6 mt-3">
                <h5 class="">Capitoli di bilancio</h5>
              </div>
              <div class="col-lg-1 col-sm-2 col-3 text-center mt-3">
                <a class="bottone-inserisci" (click)="openModalCapitoliBilancio($event);">
                  <i class="fas fa-plus pointer"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mt-3 table-responsive-lg">
            <table class="table table-hover table-colore">
              <thead class="">
                <th>codice</th>
                <th>descrizione</th>
                <th>entrata</th>
                <th class="casella-icona-tx">elimina</th>
              </thead>
              <tbody>
                <ng-container *ngIf="capitoliBilancio && capitoliBilancio.length && capitoliBilancio.length>0">
                  <tr *ngFor="let item of capitoliBilancio">
                    <td>{{item.codice}}</td>
                    <td>{{item.descrizione}}</td>
                    <td>{{item.isEntrata?"Si":"No" }}</td>
                    <td><a (click)="removeCapitoloBilancio(item)" href="javascript:;;" data-toggle="tooltip"
                        data-placement="top" title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i> </a>
                    </td>
                  </tr>
                </ng-container>
                <tr *ngIf="!(capitoliBilancio && capitoliBilancio.length && capitoliBilancio.length>0)">
                  <td colspan="5">Nessun capitolo di bilancio selezionato</td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="submitted && capitoliBilancioIsError" class="invalidFieldMessage">
              <div *ngIf="capitoliBilancioIsError" class="invalid-feedback" style="display: block;">
                Selezionare almeno un capitolo di bilancio
              </div>
            </div>
          </div>
          <div class=" mt-5">
            <hr>
          </div>
          <!--fine capitoli di bilancio-->
        </div>
        <!--fine flusso di cassa-->


        <div class="form-row">
          <div class="form-group col-sm-12">
            <label for="note">
              <h5 class="">Note:</h5>
            </label>
            <textarea [formGroup]="editForm" formControlName="note" type="text" [ngClass]="{
                'form-control': true,
                'is-invalid': submitted && f.note.errors
              }" placeholder="Inserisci eventuali note" rows="3"></textarea>
          </div>
        </div>


      </div>
      <div class="mt-5">
        <hr>
      </div>

      <div class=" mt-4">
        <div class="row justify-content-between">

          <div class="col-lg-6 col-sm-6 col-6 mt-3">
            <h5 for="" [ngClass]="{'mandatory':isMandatorySoggettoResponsabile}">Soggetti responsabili</h5>
          </div>
          <div class="col-lg-1 col-sm-2 col-3 text-center mt-3">
            <a href="javascript:;;" class="bottone-inserisci" (click)="addSoggettoResponsabile();"><i
                class="fas fa-plus"></i></a>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mt-3 table-responsive-lg">
        <table class="table table-hover table-colore">
          <thead class="">
            <th>soggetto</th>
            <th>tipologia</th>
            <th class="casella-icona-tx">elimina</th>
          </thead>
          <tbody>
            <ng-container *ngIf="soggettiResponsabili && soggettiResponsabili.length && soggettiResponsabili.length>0">
              <tr *ngFor="let item of soggettiResponsabili">
                <td>{{item.cognome}} {{item.nome}}</td>
                <td>{{item.tipologia.descrizione}}</td>
                <td><a (click)="removeSoggettoResponsabile(item)" href="javascript:;;" data-toggle="tooltip"
                    data-placement="top" title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i> </a>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="!(soggettiResponsabili && soggettiResponsabili.length && soggettiResponsabili.length>0)">
              <td colspan="3">Nessun soggetto responsabile selezionato</td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="submitted && soggettoResponsabileIsError" class="invalidFieldMessage">
          <div *ngIf="soggettoResponsabileIsError" class="invalid-feedback" style="display: block;">
            Selezionare almeno un soggetto responsabile
          </div>
        </div>
      </div>

      <div class=" mt-5">
        <hr>
      </div>


<!--campi pnrr -->

<div class=" form-row">
    <div class="form-group col-sm-6">
      <label for="codice">
        <h5 class="">Codice:</h5>
      </label>
      <div [ngClass]="{ 'is-invalid': submitted && f.codice.errors }"
        class="col-lg-12 col-md-12 col-sm-12 col-12" style="display:flex; padding-right:0px;padding-left:0px">
        <input type="text" formControlName="codice" class="form-control"
          [formGroup]="editForm"
          >
      </div>
      <div *ngIf="submitted && f.codice.errors" class="invalid-feedback" style="display: block;">
        <div *ngIf="f.codice.errors.required">
          Campo obbligatorio
        </div>
      </div>
    </div>

    <div class="form-group col-sm-6">
      <label for="unitaDiMisura">
        <h5 class="">Unita di misura:</h5>
      </label>
      <div [ngClass]="{ 'is-invalid': submitted && f.unitaDiMisura.errors }">
        <ng-select [formGroup]="editForm" formControlName="unitaDiMisura" [items]="unitaDiMisuraItems" bindValue="id"
          bindLabel="descrizioneEstesa" placeholder="Seleziona" (change)="unitaDiMisuraChange($event);">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizioneEstesa }}">
              {{ item.descrizioneEstesa }}
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div *ngIf="submitted && f.unitaDiMisura.errors" class="invalid-feedback" style="display: block;">
        <div *ngIf="f.unitaDiMisura.errors.required">
          Campo obbligatorio
        </div>
      </div>
    </div>
  </div>


  <div class="form-row">
    <div class="form-group col-sm-6">
      <label for="targetItaEu">
        <h5 class="">ITA-EU:</h5>
      </label>
      <div [ngClass]="{ 'is-invalid': submitted && f.targetItaEu.errors }">
        <ng-select [formGroup]="editForm" formControlName="targetItaEu" [items]="targetItaEuItems" bindValue="id"
          bindLabel="descrizioneEstesa" placeholder="Seleziona">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizioneEstesa }}">
              {{ item.descrizioneEstesa }}
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div *ngIf="submitted && f.targetItaEu.errors" class="invalidFieldMessage" style="display: block;">
        <div *ngIf="f.targetItaEu.errors" class="invalid-feedback" style="display: block;">
          Campo obbligatorio
        </div>
      </div>
    </div>

    <div class="form-group col-sm-6">
      <label for="indicatoreQualitativo">
        <h5 class="">Indicat. qualitativo:</h5>
      </label>
      <textarea [formGroup]="editForm" formControlName="indicatoreQualitativo" type="text" [ngClass]="{
          'form-control': true,
          'is-invalid': submitted && f.indicatoreQualitativo.errors
        }" placeholder="" rows="3"></textarea>
    </div>
  </div>


  <div class="form-row">
    <div class="form-group col-sm-6">
      <label for="riferimentoDiPartenzaNazionale">
        <h5 class="">Riferimento di partenza nazionale:</h5>
      </label>
      <div [ngClass]="{ 'is-invalid': submitted && f.riferimentoDiPartenzaNazionale.errors }"
        class="col-lg-12 col-md-12 col-sm-12 col-12" style="display:flex; padding-right:0px;padding-left:0px">
        <input type="text" formControlName="riferimentoDiPartenzaNazionale" class="form-control"
          [formGroup]="editForm"           [imask]="currentMask" [unmask]="true"
          >
      </div>
      <div *ngIf="submitted && f.riferimentoDiPartenzaNazionale.errors" class="invalid-feedback" style="display: block;">
        <div *ngIf="f.riferimentoDiPartenzaNazionale.errors.required">
          Campo obbligatorio
        </div>
      </div>
    </div>
    <div class="form-group col-sm-6">
        <label for="riferimentoDiPartenzaRegionale">
          <h5 class="">Riferimento di partenza regionale:</h5>
        </label>
        <div [ngClass]="{ 'is-invalid': submitted && f.riferimentoDiPartenzaRegionale.errors }"
          class="col-lg-12 col-md-12 col-sm-12 col-12" style="display:flex; padding-right:0px;padding-left:0px">
          <input type="text" formControlName="riferimentoDiPartenzaRegionale" class="form-control"
            [formGroup]="editForm"           [imask]="currentMask" [unmask]="true"
            >
        </div>
        <div *ngIf="submitted && f.riferimentoDiPartenzaRegionale.errors" class="invalid-feedback" style="display: block;">
          <div *ngIf="f.riferimentoDiPartenzaRegionale.errors.required">
            Campo obbligatorio
          </div>
        </div>
      </div>
</div>
<div class="form-row">
    <div class="form-group col-sm-6">
      <label for="valoreTargetNazionale">
        <h5 class="">Valore target nazionale:</h5>
      </label>
      <div [ngClass]="{ 'is-invalid': submitted && f.valoreTargetNazionale.errors }"
        class="col-lg-12 col-md-12 col-sm-12 col-12" style="display:flex; padding-right:0px;padding-left:0px">
        <input type="text" formControlName="valoreTargetNazionale" class="form-control"
          [formGroup]="editForm"       [imask]="currentMask" [unmask]="true"
          >
      </div>
      <div *ngIf="submitted && f.valoreTargetNazionale.errors" class="invalid-feedback" style="display: block;">
        <div *ngIf="f.valoreTargetNazionale.errors.required">
          Campo obbligatorio
        </div>
      </div>
    </div>

    <div class="form-group col-sm-6">
        <label for="valoreTargetRegionale">
          <h5 class="">Valore target regionale:</h5>
        </label>
        <div [ngClass]="{ 'is-invalid': submitted && f.valoreTargetRegionale.errors }"
          class="col-lg-12 col-md-12 col-sm-12 col-12" style="display:flex; padding-right:0px;padding-left:0px">
          <input type="text" formControlName="valoreTargetRegionale" class="form-control"
            [formGroup]="editForm"       [imask]="currentMask" [unmask]="true"
            >
        </div>
        <div *ngIf="submitted && f.valoreTargetRegionale.errors" class="invalid-feedback" style="display: block;">
          <div *ngIf="f.valoreTargetRegionale.errors.required">
            Campo obbligatorio
          </div>
        </div>
      </div>
  </div>
  <div class="form-row" [hidden]="_pnrrLineaFinanziamentoId">
    <div class="form-group col-sm-6">
&nbsp;      
    </div>
    <div class="form-group col-sm-6">
        <label for="valoreTargetRegionaleIntervento">
          <h5 class="">Valore target intervento:</h5>
        </label>
        <div [ngClass]="{ 'is-invalid': submitted && f.valoreTargetRegionaleIntervento.errors }"
          class="col-lg-12 col-md-12 col-sm-12 col-12" style="display:flex; padding-right:0px;padding-left:0px">
          <input type="text" formControlName="valoreTargetRegionaleIntervento" class="form-control"
            [formGroup]="editForm"     [imask]="currentMask" [unmask]="true"
            >
        </div>
        <div *ngIf="submitted && f.valoreTargetRegionaleIntervento.errors" class="invalid-feedback" style="display: block;">
          <div *ngIf="f.valoreTargetRegionaleIntervento.errors.required">
            Campo obbligatorio
          </div>
        </div>
      </div>
  </div>


  <div class="form-row" [hidden]="_pnrrLineaFinanziamentoId">
    <div class="form-group col-sm-6">
      <label for="valoreTargetAttualeNazionale">
        <h5 class="">Valore target attuale nazionale:</h5>
      </label>
      <div [ngClass]="{ 'is-invalid': submitted && f.valoreTargetAttualeNazionale.errors }"
        class="col-lg-12 col-md-12 col-sm-12 col-12" style="display:flex; padding-right:0px;padding-left:0px">
        <input type="text" formControlName="valoreTargetAttualeNazionale" class="form-control"
          [formGroup]="editForm"     [imask]="currentMask" [unmask]="true"
          >
      </div>
      <div *ngIf="submitted && f.valoreTargetAttualeNazionale.errors" class="invalid-feedback" style="display: block;">
        <div *ngIf="f.valoreTargetAttualeNazionale.errors.required">
          Campo obbligatorio
        </div>
      </div>
    </div>
    <div class="form-group col-sm-6">
        <label for="valoreTargetAttualeRegionale">
          <h5 class="">Valore target attuale regionale:</h5>
        </label>
        <div [ngClass]="{ 'is-invalid': submitted && f.valoreTargetAttualeRegionale.errors }"
          class="col-lg-12 col-md-12 col-sm-12 col-12" style="display:flex; padding-right:0px;padding-left:0px">
          <input type="text" formControlName="valoreTargetAttualeRegionale" class="form-control"
            [formGroup]="editForm"     [imask]="currentMask" [unmask]="true"
            >
        </div>
        <div *ngIf="submitted && f.valoreTargetAttualeRegionale.errors" class="invalid-feedback" style="display: block;">
          <div *ngIf="f.valoreTargetAttualeRegionale.errors.required">
            Campo obbligatorio
          </div>
        </div>
      </div>
  </div>



  <!--fine nuovi campi pnrr-->

<!--documentazione-->
<div [hidden]="!editForm || !editForm.controls || !editForm.controls.id.value">
    <div class=" mt-5">
        <hr>
      </div>

    <documentazione-component (onSubmitEmitter)="documentazioneChanged($event)"  [faseId]="editForm.controls.id.value"
    [gotoBtnOptions]="{show:false,label:gotoBtnOptions?gotoBtnOptions.label:''}"
    >
  </documentazione-component>
</div>
<!--fine documentazione-->


      <div class="col-lg-12 mb-3 px-0">
        <button [disabled]="loading" class="btn btn-lg btn-primary btn-block" type="submit">
          <img *ngIf="loading" class="pl-3 float-right"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          {{modalButtonLabel}}
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #modalConfirmSave>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">Conferma salvataggio</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeConfirmSaveModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="motivazioneForm">
      <div class="col-lg-12 mt-3">
        <p>Inserire la motivazione per la modifica delle date prevista inizio e prevista fine</p>

        <div class="form-group">
          <label>
            <h5 class="mandatory">Motivazione:</h5>
          </label>
          <div>
            <textarea maxlength="255" class="form-control" [ngClass]="{
                    'is-invalid': submitted && motivazioneForm.controls.motivazione.errors
                    }" [formGroup]="motivazioneForm" rows="5" formControlName="motivazione"></textarea>
            <div *ngIf="submitted && motivazioneForm.controls.motivazione.errors" class="invalidFieldMessage"
              style="display: block;">
              <div *ngIf="motivazioneForm.controls.motivazione.errors" class="invalid-feedback" style="display: block;">
                Campo obbligatorio
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="row justify-content-center">
        <button [disabled]="loading" (click)="doSave()" class=" btn btn-lg btn-danger mx-1">
          <img *ngIf="loading " class="pl-3 float-right "
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          Salva
        </button>
        <button [disabled]="loading" (click)="closeConfirmSaveModal();" class="  btn btn-lg btn-secondary mx-1">
          <img *ngIf="loading " class="pl-3 float-right "
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          Annulla
        </button>
      </div>
    </form>
  </div>
</ng-template>
