<div class="container-fluid px-0 bg-celeste" [formGroup]="myForm">
  <div
  class="text-center p-5 position-absolute top-50 start-50 translate-middle bg-white border border-dark rounded shadow"
  style="z-index: 999999999"
    *ngIf="showLoading"
  >
    <app-loading message="Caricamento in corso..."></app-loading>
  </div>

    <app-header-dashboard></app-header-dashboard>

    <!-- Inizio menu filtri -->
    <div class="row pb-3 pt-1 bg-0083e0" style="z-index: 13000">
      <!-- filtro progettualita' -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label>
          <h6 class="mb-0 text-white bold">Progettualità</h6>
        </label>
        <ng-select
          title="{{ description }}"
          [items]="allProjects"
          bindValue="codice"
          bindLabel="label"
          placeholder="Seleziona progettualità"
          formControlName="project"
          (change)="setProject($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizione }}">{{ item.label }}</div>
          </ng-template>
        </ng-select>
      </div>
      <!-- Filtro Intervento -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label for=" importoFinanziamento ">
          <h6 class="mb-0 text-white bold">
            Intervento <small>(min 3 caratteri)</small>
          </h6>
        </label>

        <app-completer
          [cpOptions]="completerParams"
          (onSelectEmitter)="setIntervention($event)"
          (keyup)="onChangeIntervention($event)"
          (onSelectEmitter)="setInterventionCompleter($event)"
        >
        </app-completer>
      </div>

      <!-- Filtro Tipologia intervento -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label>
          <h6 class="mb-0 text-white bold">Tipologia Intervento</h6>
        </label>
        <ng-select
          [items]="tipologieInterventoForSelectItems"
          bindValue="key"
          bindLabel="label"
          formControlName="status"
          placeholder="Seleziona tipologia intervento"
          (change)="setTipologiaIntervento($event)"
          [multiple]="true"
          [selectableGroup]="true"
          [selectableGroupAsModel]="false"
          groupBy="gruppo"
        >

          <ng-template
            ng-optgroup-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <input
              id="item-{{ index }}"
              type="checkbox"
              [checked]="item$.selected"
            />
            {{ item.gruppo | uppercase }}
          </ng-template>
          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <input
              id="item-{{ index }}"
              type="checkbox"
              [checked]="item$.selected"
            />
            <span title="{{ item.label }}">&nbsp;{{ item.label }}</span>
          </ng-template>
        </ng-select>
      </div>
      <!-- Filtro Aree gestionali direzione e/o esterne -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1" [hidden]="true">
        <label for="importoFinanziamento ">
          <h6 class="mb-0 text-white bold">
            Aree gestionali direzione e/o esterne
          </h6>
        </label>
        <ng-select
          [items]="areeTematicheCombo"
          bindValue="id"
          bindLabel="descrizione"
          formControlName="direction"
          placeholder="Seleziona area gestionale direzione e/o esterna "
          (change)="setAreaTematica($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
          </ng-template>
        </ng-select>
      </div>
      <!-- Filtro Soggetti attuatori -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label>
          <h6 class="mb-0 text-white bold">Soggetti Attuatori</h6>
        </label>
        <ng-select
          [items]="soggettiAttuatoriItems"
          formControlName="attuatore"
          bindValue="id"
          bindLabel="denominazione"
          placeholder="Seleziona soggetto"
          (change)="setSoggettoAttuatore($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.denominazione }}">{{ item.denominazione }}</div>
          </ng-template>
        </ng-select>
      </div>
      <!-- Filtro Territotiale / Attuazione regionale -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label title="PNRR Territotiale / Attuazione regionale">
          <h6 class="mb-0 text-white bold">PNRR Territoriale / Attuazione regionale</h6>
        </label>
        <ng-select
          [items]="pnrrComboItems"
          formControlName="pnrrPnc"
          bindValue="key"
          bindLabel="value"
          placeholder="Seleziona"
          (change)="setPnrrFilter($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.value }}">{{ item.value }}</div>
          </ng-template>
        </ng-select>
      </div>
      <!-- Filtro PNRR/PNC -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label title="PNRR / PNC">
          <h6 class="mb-0 text-white bold">PNRR / PNC</h6>
        </label>
        <ng-select
          [items]="pnrrPncComboItems"
          formControlName="pnrrPnc"
          bindValue="key"
          bindLabel="value"
          placeholder="Seleziona"
          (change)="setPnrrPncFilter($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.value }}">{{ item.value }}</div>
          </ng-template>
        </ng-select>
      </div>
      <!-- Filtro Linee di Finanziamento PNRR/PNC - Missioni -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label>
          <h6 class="mb-0 text-white bold">
            Linee di Finanziamento PNRR / PNC - Missioni
          </h6>
        </label>
        <ng-select
          [items]="finanziamentoMissionsPnrrComboItems"
          formControlName="finanziamentoPnrrPnc"
          [multiple]="true"
          [selectableGroup]="true"
          [selectableGroupAsModel]="false"
          groupBy="gruppo"
          [closeOnSelect]="false"
          bindValue="codice"
          bindLabel="valore"
          placeholder="Seleziona una o più linee di finanziamento"
          (change)="setLineeFinanziamento($event, 'mission')"
        >
          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <input
              id="item-{{ index }}"
              type="checkbox"
              [checked]="item$.selected"
            />
            <span title="{{ item.valore }}">&nbsp;{{ item.valore }}</span>
          </ng-template>
        </ng-select>
      </div>
      <!-- Filtro Linee di Finanziamento PNRR/PNC - Componenti -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label>
          <h6 class="mb-0 text-white bold">
            Linee di Finanziamento PNRR / PNC - Componenti
          </h6>
        </label>
        <ng-select
          [items]="finanziamentoComponentsPnrrComboItems"
          formControlName="finanziamentoPnrrPnc"
          [multiple]="true"
          [selectableGroup]="true"
          [selectableGroupAsModel]="false"
          groupBy="gruppo"
          [closeOnSelect]="false"
          bindValue="codice"
          bindLabel="valore"
          placeholder="Seleziona una o più linee di finanziamento"
          (change)="setLineeFinanziamento($event, 'component')"
        >
          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <input
              id="item-{{ index }}"
              type="checkbox"
              [checked]="item$.selected"
            />
            <span title="{{ item.valore }}">&nbsp;{{ item.valore }}</span>
          </ng-template>
        </ng-select>
      </div>
      <!-- Filtro Linee di Finanziamento PNRR/PNC - Linee -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label>
          <h6 class="mb-0 text-white bold">
            Linee di Finanziamento PNRR / PNC - Linee
          </h6>
        </label>
        <ng-select
          [items]="finanziamentoLinesPnrrComboItems"
          formControlName="finanziamentoPnrrPnc"
          [multiple]="true"
          [selectableGroup]="true"
          [selectableGroupAsModel]="false"
          groupBy="gruppo"
          [closeOnSelect]="false"
          bindValue="codice"
          bindLabel="valore"
          placeholder="Seleziona una o più linee di finanziamento"
          (change)="setLineeFinanziamento($event, 'line')"
        >
          <!-- <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.codice }}">{{ item.codice }}</div>
          </ng-template> -->

          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <input
              id="item-{{ index }}"
              type="checkbox"
              [checked]="item$.selected"
            />
            <span title="{{ item.valore }}">&nbsp;{{ item.valore }}</span>
          </ng-template>
        </ng-select>
      </div>

      <!-- Filtro Provincia -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label title="Provincia">
          <h6 class="mb-0 text-white bold">Provincia</h6>
        </label>
        <ng-select
          [items]="provinciaItems"
          formControlName="province"
          bindValue="key"
          bindLabel="value"
          placeholder="Seleziona provincia"
          (change)="setProvincia($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.value }}">{{ item.value }}</div>
          </ng-template>
        </ng-select>
      </div>

      <!-- Filtro Comune -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label title="Comune">
          <h6 class="mb-0 text-white bold">Comune</h6>
        </label>
        <ng-select
          [items]="comuneItems"
          formControlName="municipality"
          bindValue="key"
          bindLabel="value"
          placeholder="Seleziona comune"
          (change)="setComune($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.value }}">{{ item.value }}</div>
          </ng-template>
        </ng-select>
      </div>

      <!-- Filtro Aggregato -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label title="Dettaglio tipologia">
          <h6 class="mb-0 text-white bold">Dettaglio tipologia</h6>
        </label>
        <ng-select
          [items]="aggregatoItems"
          formControlName="dettaglioTipologia"
          bindValue="id"
          bindLabel="name"
          placeholder="Seleziona dettaglio tipologia"
          (change)="setAggregato($event)"
          [multiple]="true"
          [selectableGroup]="true"
          [selectableGroupAsModel]="false"
          groupBy="gruppo"
        >
          <ng-template
            ng-optgroup-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <input
              id="item-{{ index }}"
              type="checkbox"
              [checked]="item$.selected"
            />
            {{ item.gruppo | uppercase }}
          </ng-template>
          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <input
              id="item-{{ index }}"
              type="checkbox"
              [checked]="item$.selected"
            />
            <span title="{{ item.name }}">&nbsp;{{ item.name }}</span>
          </ng-template>
        </ng-select>
      </div>

      <div
        class="col-xl-3 col-lg-4 col-md-6 col-sm-12 pt-1 align-self-end align-items-end"
      >
        <div class="d-flex align-self-end align-items-end">
          <!-- Bottone Cerca -->
          <div class="col-lg-6 pl-0 align-self-end align-items-end">
            <button
              [disabled]="showLoading"
              type="button"
              class="mt-1 btn btn-info btn-block"
              (click)="filterInterventions(fillInterventions, true)"
            >
              Cerca
            </button>
          </div>

          <!-- Bottone Esporta -->
          <div class="col-lg-6 pr-0 align-self-end align-items-end" [hidden]="isPublic">
            <button
              [disabled]="showLoading || loadingExportExcel"
              type="button"
              class="mt-1 btn btn-info btn-block"
              (click)="esportaExcel()"
            >
              Esporta
              <img
                *ngIf="loadingExportExcel"
                class="pl-3 float-right"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fine menu filtri -->

    <!-- Inizio messaggi ricerca -->
    <ng-container *ngIf="!showPage">
      <div
        *ngIf="!noresult"
        [hidden]="loading"
        class="jumbotron container my-5 border shadow-sm my-3 rounded bg-white"
      >
        <div class="container text-center">
          <h4 class="bold">Dashboard PNRR</h4>
          <p class="h5">
            <small>
              {{
                (isFiltered()
                  ? "Premere "
                  : "Selezionare almeno un filtro e premere ") +
                  "sul pulsante Cerca per attivare la visualizzazione dei grafici del PNRR."
              }}
            </small>
          </p>
        </div>
      </div>
      <ng-container *ngIf="noresult">
        <div
          [hidden]="loading"
          class="jumbotron container my-5 border shadow-sm my-3 rounded bg-white"
        >
          <div class="container text-center">
            <p class="h5">
              <small>La ricerca non ha prodotto risultati</small>
            </p>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <!-- Fine messaggi ricerca -->

    <!-- Inizio Sezione Grafici 1 -->
    <ng-container *ngIf="showPage">
      <div class="row">
        <div class="col-xl-6 col-lg-7 col-md-12 col-sm-12">
          <div class="row">
            <!-- interventi -->
            <div class="col-lg-6 col-md-6">
              <div class="shadow-sm bg-white card my-2 rounded">
                <div class="card-body">
                  <h6
                    class="text-center border-bottom text-secondary bold font14"
                  >
                  <a
                      type="button"
                      class="help"
                      [popover]="popoverContentProgetti"
                      placement="bottom"
                      triggers="mouseenter:mouseleave"
                    >
                      <i
                        class="far fa-question-circle text-0083e0 help"
                        style="font-size: 14px"
                      >
                      </i>
                    </a>
                    <span class="text-uppercase">N° Progettualità</span>
                    <span *ngIf="isFiltered()"
                      ><small>&nbsp;(filtrati)</small></span
                    >
                  </h6>

                  <h6 class="text-center bold mb-0">
                    {{ countMain() }}
                    <small>&nbsp;di cui con interventi</small>
                    {{ countSubIntervents() }}
                  </h6>
                </div>
              </div>

              <div class="shadow-sm bg-white card my-2 rounded">
                <div class="card-body">
                  <h6
                    class="text-center border-bottom text-secondary bold font14"
                  >
                  <a
                      type="button"
                      class="help"
                      [popover]="popoverContentProgettiValore"
                      placement="bottom"
                      triggers="mouseenter:mouseleave"
                    >
                      <i
                        class="far fa-question-circle text-0083e0 help"
                        style="font-size: 14px"
                      >
                      </i>
                    </a>

                    <span>VALORE TOTALE</span>
                    <span *ngIf="isFiltered()"
                      ><small>&nbsp;(filtrati)</small></span
                    >
                  </h6>

                  <h6 class="text-center bold mb-0 text-67b7dc">
                    {{ printValueAsCurrency(valoreTotaleProgetti) }}
                  </h6>
                </div>
              </div>
            </div>

            <ng-template #popoverContentInterventi>
              <span>Nota Bene: i progetti che non hanno interventi al loro interno sono considerati loro stessi degli interventi</span>
            </ng-template>

            <ng-template #popoverContentProgetti>
                <span>Nota Bene: Il numero totale delle progettualità comprende sia i progetti con interventi sia i progetti senza interventi sottostanti, quindi progetti che devono ancora essere sviluppati in interventi</span>
              </ng-template>
              <ng-template #popoverContentProgettiValore>
                <span>Nota Bene: Il valore totale dei progetti è computato al lordo di eventuali residui, ad eccezione dei progetti in capo alla Direzione Infrastrutture e Mobilità in cui il valore è computato al netto dei residui</span>
              </ng-template>

            <div class="col-lg-6 col-md-6">
              <div class="shadow-sm bg-white card my-2 rounded">
                <div class="card-body">
                  <h6
                    class="text-center border-bottom text-secondary bold font14"
                  >
                    <a [hidden]="true"
                      type="button"
                      class="help"
                      [popover]="popoverContentInterventi"
                      placement="bottom"
                      triggers="mouseenter:mouseleave"
                    >
                      <i
                        class="far fa-question-circle text-0083e0 help"
                        style="font-size: 14px"
                      >
                      </i>
                    </a>
                    &nbsp;<span>N° INTERVENTI</span>
                    <span *ngIf="isFiltered()"
                      ><small>&nbsp;(filtrati)</small></span
                    >
                  </h6>

                  <h6 class="text-center bold mb-0">{{ countNotMain() }}</h6>
                </div>
              </div>

              <div class="shadow-sm bg-white card my-2 rounded">
                <div class="card-body">
                  <h6
                    class="text-center border-bottom text-secondary bold font14"
                  >
                    <span>VALORE TOTALE</span>
                    <span *ngIf="isFiltered()"
                      ><small>&nbsp;(filtrati)</small></span
                    >
                  </h6>

                  <h6 class="text-center bold mb-0 text-67b7dc">
                    {{ printValueAsCurrency(totInt) }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <!-- Grafico a torta -->
          <div class="row">
            <div class="col-md-12">
              <div
                id="chartAttuazione"
                class="bg-white chart-attuazione border shadow-sm my-3 rounded"
              ></div>
            </div>
          </div>
        </div>

        <div class="col-xl-6 col-lg-5 col-md-12 col-sm-12 mt-2 mb-3">
          <app-map [showOnPnrrDashboard]="true"></app-map>
        </div>
      </div>

      <!-- Grafico a barre -->
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div
            id="chartTipologiaIntervento"
            class="bg-white chart-tipologia-intervento border shadow-sm my-3 rounded"
          ></div>
        </div>
      </div>
    </ng-container>
    <!-- Fine Sezione Grafici 1 -->

</div>

