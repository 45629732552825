<div class="collapse" [id]="'collapse' + impegno.id">
  <div class="card mb-5">
    <h5
      class="card-header text-white px-2"
      style="background-color: #002742 !important; font-size: 18px"
    >
      Liquidazioni e Ordinativi di pagamento - Importo Liquidazioni:

      {{ this.insertDotForThousands(this.totImportoLiquidazioni) }} €
      <a title="Somma dell'importo delle liquidazioni">
        <i class="fas fa-question-circle small" aria-hidden="true"></i>
      </a>

      &nbsp;&nbsp; Importo Ordinativi:
      {{ this.insertDotForThousands(this.totImportoOrdinativi) }} €
      <a title="Somma dell'importo degli ordinativi">
        <i class="fas fa-question-circle small" aria-hidden="true"></i>
      </a>
    </h5>
    <div
      class="card-body bg-002742-50"
      style="
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
      "
    >
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th class="px-1" scope="col">Anno<br />Liq.</th>
              <th class="px-1" scope="col">Numero<br />Liq.</th>
              <th class="px-1" scope="col">Numero<br />Approv.<br />Atto</th>
              <th class="px-1" scope="col">Data<br />Atto</th>
              <th class="px-1" scope="col">Ragsoc<br />Soggetto</th>
              <th class="px-1" scope="col">Anno<br />Ordinativo</th>
              <th class="px-1" scope="col">Data Emissione<br />Ordinativo</th>
              <th class="px-1" scope="col">Importo<br />Liq.</th>
              <th class="px-1" scope="col">Flag<br />Fattura</th>
              <th class="px-1" scope="col">Anno<br />Imp.</th>
              <th class="px-1" scope="col">Numero<br />Imp.</th>
              <th class="px-1" scope="col">Codice<br />Capitolo</th>
              <th class="px-0" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let liquidazione of liquidazioni">
              <tr>
                <td class="px-1">{{ liquidazione.annoLiq }}</td>
                <td class="px-1">{{ liquidazione.numLiq }}</td>
                <td class="px-1">{{ liquidazione.numApprovAtto }}</td>
                <td class="px-1">{{ getDataFormat(liquidazione.dataAtto) }}</td>
                <td class="px-1">{{ getRagSoc(liquidazione) }}</td>
                <td class="px-1">{{ liquidazione.annoOrd }}</td>
                <td class="px-1">
                  {{ getDataFormat(liquidazione.dtEmisOrd) }}
                </td>
                <td class="px-1" style="text-align: right">
                  {{ insertDotForThousands(liquidazione.importoScadenza) }}
                </td>
                <td class="px-1">{{ liquidazione.flagFattura }}</td>
                <td class="px-1">{{ liquidazione.annoImpacc }}</td>
                <td class="px-1">{{ liquidazione.numeroImpacc }}</td>
                <td class="px-1">{{ impegno.codCpt }}</td>
                <td class="px-0">
                  <button
                    class="btn btn-primary btn-sm"
                    [id]="impegno.id.toString() + liquidazione.id.toString()"
                    type="button"
                    [disabled]="
                      !liquidazione ||
                      !liquidazione.ordinativi ||
                      liquidazione.ordinativi.length === 0
                    "
                    [title]="
                      checkTitleButton(
                        impegno.id.toString() + liquidazione.id.toString(),
                        liquidazione
                      )
                    "
                    data-toggle="collapse"
                    aria-expanded="false"
                    [attr.data-target]="
                      setTargetAndControl(impegno, liquidazione)
                    "
                    [attr.aria-controls]="
                      setTargetAndControl(impegno, liquidazione)
                    "
                    (click)="
                      removeFocus(
                        impegno.id.toString() + liquidazione.id.toString()
                      )
                    "
                  >
                    <span>Ordinativi&nbsp;</span>
                    <i
                      *ngIf="
                        !(
                          !liquidazione ||
                          !liquidazione.ordinativi ||
                          liquidazione.ordinativi.length === 0
                        )
                      "
                      class="d-inline fas"
                      [ngClass]="{
                        'fa-chevron-up': checkButtonIsExpanded(
                          impegno.id.toString() + liquidazione.id.toString()
                        ),
                        'fa-chevron-down': !checkButtonIsExpanded(
                          impegno.id.toString() + liquidazione.id.toString()
                        )
                      }"
                    ></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td colspan="15" class="px-0">
                  <app-dettaglio-ordinativo
                    [accertamento]="impegno"
                    [ordinativi]="liquidazione.ordinativi"
                    [liquidazione]="liquidazione"
                    [type]="type"
                  >
                  </app-dettaglio-ordinativo>
                </td>
              </tr>
            </ng-container>

            <tr class="bg-dark">
              <td class="px-1 text-white" colspan="5">
                VALORE ATTUALE IMPACC: 
                <span class="bold">{{
                  insertDotForThousands(impegno.valoreAttualeImpacc)
                }}</span> €
              </td>
              <td class="px-1 text-white" colspan="6">
                IMPORTO TOTALE LIQUIDAZIONI: 
                <span class="bold">{{
                  insertDotForThousands(totImportoLiquidazioni)
                }}</span> €
              </td>
              <td class="px-1 text-white" colspan="4">
                DISPONIBILITA': 
                <span class="bold">{{
                  insertDotForThousands(disponibilita)
                }}</span> €
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
