<ng-template #templateEditAdd>
  <form [formGroup]="editForm" (ngSubmit)="onSubmit()" class="col-lg-12">
    <input type="hidden" [formGroup]="editForm" formControlName="id" />

    <div class="form-group">
      <h5><label for="code" class="mandatory">Codice:</label></h5>
      <input
        [formGroup]="editForm"
        formControlName="code"
        maxlength="255"
        type="text"
        class="form-control"
        placeholder="Inserisci il codice"
        [ngClass]="{
          'form-control': true,
          'is-invalid': submitted && f.code.errors
        }"
      />
      <div
        *ngIf="submitted && f.code.errors"
        class="invalid-feedback"
        style="display: block"
      >
        <div *ngIf="f.code.errors.required">Campo obbligatorio</div>
      </div>
    </div>
    <div class="form-group">
      <h5><label for="comment">Commento:</label></h5>
      <textarea
        [formGroup]="editForm"
        formControlName="comment"
        type="text"
        class="form-control"
        placeholder="Inserisci commento"
        [ngClass]="{
          'form-control': true,
          'is-invalid': submitted && f.comment.errors
        }"
      ></textarea>
      <div
        *ngIf="submitted && f.comment.errors"
        class="invalid-feedback"
        style="display: block"
      >
        <div *ngIf="f.comment.errors.required">Campo obbligatorio</div>
      </div>
    </div>

    <div class="form-group">
      <h5><label for="value" class="mandatory">Valore:</label></h5>
      <textarea
        rows="15"
        [formGroup]="editForm"
        formControlName="value"
        type="text"
        class="form-control"
        placeholder="Inserisci valore"
        [ngClass]="{
          'form-control': true,
          'is-invalid': submitted && f.value.errors
        }"
      ></textarea>
      <div
        *ngIf="submitted && f.value.errors"
        class="invalid-feedback"
        style="display: block"
      >
        <div *ngIf="f.value.errors.required">Campo obbligatorio</div>
      </div>
    </div>

    <button
      class="btn btn-lg btn-primary btn-block"
      type="submit"
      style="margin: 15px 0"
    >
      {{ buttonLabel }}
    </button>
  </form>
</ng-template>

<div class="container sfondo_bianco">
  <hr />
  <accordion [isAnimated]="true">
    <accordion-group heading="Inserisci nuovo menù">
      <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
    </accordion-group>
  </accordion>

  <div class="mt-5">
    <ngx-datatable-wrapper
      #ngxDatatableWrapper
      [templates]="templateList"
      [dtOptions]="dtOptions"
      style="width: 100%; height: auto"
    >
      <ng-template #btnEditTemplate let-row="row" let-value="value">
        <a
          [ngClass]="{
            disabled: loading
          }"
          class="border-0 text-center"
          (click)="onEdit(row)"
          data-toggle="tooltip"
          data-placement="top"
          title="modifica dati"
          >&nbsp;&nbsp;&ensp;<i class="far fa-edit"></i
        ></a>
      </ng-template>
      <ng-template #btnDeleteTemplate let-row="row" let-value="value">
        <!-- If code name è generale disabilito il delete  -->
        <ng-container *ngIf="row.code !== 'generale'">
          <a
            [ngClass]="{
              disabled: loading
            }"
            class="border-0 text-center"
            (click)="onDelete(row)"
            data-toggle="tooltip"
            data-placement="top"
            title="elimina"
            >&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt"></i
          ></a>
        </ng-container>
      </ng-template>
    </ngx-datatable-wrapper>
  </div>

  <ng-template #templateModal>
    <div class="modal-header text-center">
      <h4 class="modal-title pull-left">Modifica menu</h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="closeModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
    </div>
  </ng-template>
</div>
