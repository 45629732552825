import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { HttpClientModule } from "@angular/common/http";
import { NgSelectModule } from "@ng-select/ng-select";
import { ReactiveFormsModule } from "@angular/forms";
import { AppLoadingModule } from "src/app/components/_components/loading/loading.module";


@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    NgSelectModule,
    ReactiveFormsModule,
    AppLoadingModule,
  ],
  exports: [DashboardComponent],
})
export class WebGisModule {}
