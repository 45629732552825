export class CapitoloBilancio {
    id: number;
    descrizione: string;    
    isEntrata: boolean;
    codice:string;
    annualita:CapitoloBilancioAnnualita[];
}
export class CapitoloBilancioAnnualita{
    id:number;
    anno:number;
    stanziamentoCompetenza: number;
    stanziamentoCassa: number;
    variazioniCompetenza: number;
    variazioniCassa: number;
    impegnato: number;
}