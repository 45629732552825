import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class DocumentazioneService {

    //private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}Classificazioni/`;
    private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}`;

    constructor(private http: HttpClient) { }


    getAllDt(what: string, id: number, dataTablesParameters: any) {
        if (id <= 0) {
            return this.http.post<any>(
                `${this.apiUrl}documentazione/alldt`,
                dataTablesParameters
            );
        }

        return this.http.post<any>(
            `${this.apiUrl}${what}/${id}/documenti/alldt`,
            dataTablesParameters
        );
    }


    getAllDtWithProvvedimenti(what: string, id: number, dataTablesParameters: any) {
        return this.http.post<any>(
            `${this.apiUrl}documentazione/allDtWithProvvedimenti/${what}/${id}`,
            dataTablesParameters
        );
    }


    saveDocument(what: string, id: number, form: FormData) {
        return this.http.post<any>(`${this.apiUrl}${what}/${id}/documenti`, form);
    }

    downloadAttach(what: string, id: number, n: any) {
        return this.http.get(`${this.apiUrl}${what}/${id}/documenti/${n.id}/file`, {
            observe: "response",
            responseType: "blob"
        });
    }

    downloadAttachById(id: number) {
        return this.http.get(`${this.apiUrl}documentazione/${id}/file`, {
            observe: "response",
            responseType: "blob"
        });
    }



    deleteDocument(what: string, id: number, n: any) {
        return this.http.delete(`${this.apiUrl}${what}/${id}/documenti/${n.id}`);
    }
}
