<hr />
<div class="p-5 container-xl text-center" [hidden]="!isInLoading">
    <app-loading message="Caricamento in corso..."></app-loading>
</div>
<form [formGroup]="myGroup" [hidden]="isInLoading" class="form-registrazione" novalidate>
    <div class="user-type">
        <h5><label for="indirizzo">Codice Capitolo:</label></h5>

        <div class="form-group" [ngClass]="{ 'is-invalid': submitted && f.codiceCapitolo.errors }">
            <input class="form-control" [formGroup]="myGroup" formControlName="codiceCapitolo" maxlength="255" type="text" placeholder="Seleziona il codice capitolo" />
        </div>
        <div *ngIf="submitted && f.codiceCapitolo.errors" class="invalidFieldMessage" style="display: block;">
            <div *ngIf="f.codiceCapitolo.errors" class="invalid-feedback" style="display: block;">
                Inserire codice capitolo
            </div>
        </div>
    </div>
    <button class="btn btn-lg btn-primary btn-block" type="button" (click)="request()" style="margin:15px 0">
        Richiedi
    </button>
</form>