import { Component, OnInit, Input, Renderer2 } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { GeoService } from "src/app/services/geo.service";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import * as moment from "moment";
import { AppConfigService } from "src/app/services/app-config.service";
import { ToastService } from 'src/app/services/toast.service';
import { Subject } from "rxjs";
import { Municipality } from "src/app/models/municipality";
import { KeyValue } from 'src/app/models/keyValue';

@Component({
  selector: "app-edit-municipality-modal",
  templateUrl: "./edit-municipality-modal.component.html",
  styleUrls: ["./edit-municipality-modal.component.css"]
})
export class EditMunicipalityModalComponent implements OnInit {
  @Input() currentMunicipality: Municipality;
  public onClose: Subject<boolean>;
  editForm: FormGroup;
  modalRef: BsModalRef;
  modalOpts: ModalOptions = {
    backdrop: "static",
    ignoreBackdropClick: true,
    keyboard: false
  };
  provinces: KeyValue[];
  submitted: boolean = false;
  yesNo: Array<{ k: string; v: string }> = [
    { k: "NO", v: "NO" },
    { k: "SI", v: "SI" }
  ];  
  constructor(
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    public bsModalRef: BsModalRef,
    private localeService: BsLocaleService,
    private geoService: GeoService,
    private toastr: ToastService
  ) {
    this.localeService.use("it");    
  }

  ngOnInit() {
    this.geoService.getProvinces(AppConfigService.env.region_code).subscribe(res => {
      this.provinces = res;
    });    
    this.editForm = this.initializeFormGroup();
    this.onClose = new Subject();
  }

  initializeFormGroup() {
    console.log(
      moment(this.currentMunicipality.fromDate, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      )
    );
    return this.formBuilder.group({
      name: [
        this.currentMunicipality.name,
        [Validators.required, Validators.maxLength(255)]
      ],
      istatCode: [
        this.currentMunicipality.istatCode,
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
      ],
      cadastreCode: [
        this.currentMunicipality.cadastreCode,
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)]
      ],
      fromDate: [
        this.currentMunicipality.fromDate
          ? moment(this.currentMunicipality.fromDate, "YYYY-MM-DD").format(
              "DD/MM/YYYY"
            )
          : ""
      ],
      toDate: [
        this.currentMunicipality.toDate
          ? moment(this.currentMunicipality.toDate, "YYYY-MM-DD").format(
              "DD/MM/YYYY"
            )
          : ""
      ],
      province: [this.currentMunicipality.provinceId, [Validators.required]],      
    });
  }

  onChangeNewProvince($event: KeyValue) {
    this.currentMunicipality.provinceId = $event.id;    
  }

  get f() {
    return this.editForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.editForm.updateValueAndValidity();

    var errors = false;
    for (var c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }
    if (this.editForm.invalid || errors) {
      console.log("invalid");
      let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
      for (var el in this.editForm.controls) {
        if (this.editForm.controls[el].invalid) {
          console.log(`set focus on ${el}`);
          if (this.editForm.controls[el] instanceof FormGroup) {
            for (var el1 in this.editForm.get(el)["controls"]) {
              if (this.editForm.get(el)["controls"][el1].invalid) {
                invalidElements = this.renderer.selectRootElement(`[formcontrolname="${el1}"]`, true);
                break;
              }
            }
          } else {
            invalidElements = this.renderer.selectRootElement(`[formcontrolname="${el}"]`, true);
          }
          break;
        }
      }
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }

      return false;
    }

    const newMunicipality: Municipality = new Municipality();
    newMunicipality.id = this.currentMunicipality.id;
    newMunicipality.name = this.f.name.value;
    newMunicipality.istatCode = this.f.istatCode.value;
    newMunicipality.cadastreCode = this.f.cadastreCode.value;
    newMunicipality.fromDate = this.f.fromDate.value
      ? moment(this.f.fromDate.value, "DD/MM/YYYY").format("YYYY-MM-DD")
      : null;
    newMunicipality.toDate = this.f.toDate.value
      ? moment(this.f.toDate.value, "DD/MM/YYYY").format("YYYY-MM-DD")
      : null;
    newMunicipality.provinceId = this.f.province.value;
    this.geoService.createMunicipality(newMunicipality).subscribe(res => {
      this.toastr.success( "Comune aggiornato con successo!");
      this.onClose.next(true);
    });
  }

  onChangeFromDate(value: Date) {
    value ? value.setHours(value.getHours() + 2) : null;
    this.currentMunicipality.fromDate = value
      ? moment(value).format("DD/MM/YYYY")
      : null;
  }

  onChangeToDate(value: Date) {
    value ? value.setHours(value.getHours() + 2) : null;
    this.currentMunicipality.toDate = value
      ? moment(value).format("DD/MM/YYYY")
      : null;
  }
}
