import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-pnrr-target-milestone",
  templateUrl: "./pnrr-target-milestone.component.html",
  styleUrls: ["./pnrr-target-milestone.component.css"],
})
export class PnrrTargetMilestoneComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
  }

  
}