<div class="modal-header text-center">
  <h4 class="modal-title pull-left">{{ headerTitle }}</h4>
  <button
    type="button"
    class="close pull-right"
    *ngIf="escapeEnable"
    aria-label="Close"
    (click)="sendChoice('escape')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <section class="fascia-grey-login" style="margin: 0px; padding-top: 20px">
    <h6 class="modal-title pull-left">{{ alertMessage }}</h6>
  </section>
</div>
<ng-container *ngIf="submitEnable">
    <div class="modal-footer">
  <button
    *ngFor="let item of submitBtnLable; let i = index"
    class="btn"
    [ngClass]="submitBtnClass[i] ? submitBtnClass[i] : 'btn-primary'"
    type="button"
    (click)="sendChoice(submitBtnLable[i])"
  >
    {{ submitBtnLable[i] }}
  </button>
</div>
</ng-container>
