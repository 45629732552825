import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FinanziamentoService, InterventoService, ProvvedimentoService, PnrrService } from '../../services';
import * as moment from 'moment';
@Component({
    selector: 'storico-dettaglio-component',
    templateUrl: './storico-dettaglio.component.html',
    styleUrls: ['./storico-dettaglio.component.css']
})
export class StoricoDettaglioComponent
    implements OnInit {

    @Input() modalDefinition: any;//{columnsDefinition:{},ajaxMethod:function()}
    @Input() id: number;
    @Input() finanziamentoId: number;
    @Input() interventoId: number;
    @Input() provvedimentoId: number;
    @Input() pnrrLineaFinanziamentoId: number;
    @Input() what: string;
    @Output() onSubmitOk = new EventEmitter();
    storicoObj: any;
    loading: boolean = false;

    newObjectData: any;
    oldObjectData: any;
    newObjectPrperties = [];

    constructor(private finanziamentoService: FinanziamentoService
        , private interventoService: InterventoService
        , private provvedimentoService: ProvvedimentoService
        , public bsModalRef: BsModalRef
        , private pnrrService: PnrrService
    ) {

    }

    ngOnInit(): void {
        this.getDettaglio();
    }
    intervento: any;
    getDatiGeneraliIntervento() {
        if (!this.id || this.intervento) return;
        //dati generali
        this.interventoService.getAlsoIfDeleted(this.interventoId).subscribe(
            x => {
                this.intervento = x;
                this.getDettaglio();
            }
        );
    }

    getDettaglio() {
        let service = null;
        let extObj = null;
        if (this.what.toLowerCase() == 'finanziamento') {
            service = this.finanziamentoService;
            extObj = this.finanziamentoId;
        }
        if (this.what.toLowerCase() == 'pnrrlineafinanziamento') {
            service = this.pnrrService;
            extObj = this.pnrrLineaFinanziamentoId;
        }
        if (this.what.toLowerCase() == 'provvedimento') {
            service = this.provvedimentoService;
            extObj = this.provvedimentoId;
        }
        if ((this.what.toLowerCase() == 'intervento') || (this.what.toLowerCase() == 'progetto')) {
            service = this.interventoService;
            extObj = this.interventoId;
            if (!this.intervento) {
                this.getDatiGeneraliIntervento();
                return;
            }
        }
        this.loading = true;
        let that = this;
        service.getDettaglioStorico(this.id, extObj).subscribe(
            result => {
                that.loading = false;
                that.storicoObj = result;

                that.newObjectData = that.storicoObj.newObject && that.storicoObj.newObject != '' ? JSON.parse(that.storicoObj.newObject) : null;
                that.oldObjectData = that.storicoObj.oldObject && that.storicoObj.oldObject != '' ? JSON.parse(that.storicoObj.oldObject) : null;
                //console.log(`newObjectData=${that.newObjectData}`);
                //console.log(`oldObjectData=${that.oldObjectData}`);
                if (that.newObjectData) {
                    that.newObjectPrperties = Object.keys(that.newObjectData);
                }
                else {
                    if (that.oldObjectData) {
                        that.newObjectPrperties = Object.keys(that.oldObjectData);
                    }
                }
            }
            , error => {
                that.loading = false;
            }
        );


    }


    closeModal(): void {
        this.bsModalRef.hide();
    }

    getAzioneLabel() {
        if (!this.storicoObj) return '';
        switch (this.storicoObj.action.toUpperCase()) {
            case 'UPDATE':
                return 'Aggiornamento';
                break;
            case 'DELETE':
                return 'Cancellazione';
                break;
            case 'INSERT':
                return 'Inserimento';
                break;
        }
    }
    storicoColor() {
        if (!this.storicoObj) return '';
        switch (this.storicoObj.action.toUpperCase()) {
            case 'UPDATE':
                return 'text-0083e0';
                break;
            case 'DELETE':
                return 'text-danger';
                break;
            case 'INSERT':
                return 'text-success';
                break;
        }
    }
    storicoBorder() {
        if (!this.storicoObj) return '';
        switch (this.storicoObj.action.toUpperCase()) {
            case 'UPDATE':
                return 'border-0083e0';
                break;
            case 'DELETE':
                return 'border-danger';
                break;
            case 'INSERT':
                return 'border-success';
                break;
        }
    }



    getAzioneData() {
        if (!this.storicoObj) return '';
        let v = moment(this.storicoObj.date).toDate();
        //v.setHours(v.getHours() - v.getTimezoneOffset() / 60);
        return moment(v).format('DD/MM/YYYY HH:mm:ss');
    }

    getAzioneUtente() {
        if (!this.storicoObj) return '';
        return this.storicoObj.username;
    }
    getAzioneDescrizione() {
        if (!this.storicoObj) return '';
        return this.storicoObj.actionDescription;
    }


    printFieldName(item) {
        switch (item.toLowerCase()) {
            case 'tipostrumentoattuativo':
                return 'Strumento attuativo';
            case 'percentualecofinanziamento':
                return 'Cofinanziamento';
            case 'tipofinanziamento':
                return 'Fonte';
            case 'areetematiche':
                return 'Aree gestionali direzione e/o esterne';
            case 'importocomplessivo':
                return 'Importo complessivo';
            case 'disponibilitaresidua':
                return 'Disponibilità residua';
            case 'annoriferimento':
                return 'anno di riferimento';
            case 'codicedirezione':
                return 'codice direzione';
            case 'noteresponsabile':
                return 'note responsabile';
            case 'presenteinbdu':
                return 'presente in bdu';
            case 'validationstatus':
                return 'stato';
            case 'validationrequestdate':
                return 'Data richiesta validazione';
            case 'validationchangestatusdate':
                return 'Data cambio di stato';
            case 'validationapproveduserlabel':
                return 'Utente validatore';
            case 'validationrequestuserlabel':
                return 'Utente della richiesta';
            case 'accertatoimpegnato':
                return this.what == 'finanziamento' ? 'accertato' : 'impegnato';
            case 'accertamenti':
                return this.what == 'finanziamento' ? 'accertamenti' : 'impegni';
            case 'tipologiafase':
                return 'tipologia';
            case 'datainizio':
                return 'data inizio';
            case 'datafine':
                return 'data fine';
            case 'dataprevistainizio':
                return 'data prevista inizio';
            case 'dataprevistafine':
                return 'data prevista fine';
            case 'soggettiresponsabili':
                return 'soggetti responsabili';
            case 'tipoprovvedimento':
                return 'tipo provvedimento';
            case 'scopoprovvedimento':
                return 'scopo provvedimento';
            case 'soggettigiuridici':
                return 'soggetti giuridici';
            case 'title':
                return 'Titolo';
            case 'filepath':
                return 'documento';
            case 'disponibilita':
                return 'disponibilità';
            case "dataemissionecertificatopagamento":
                return 'data emissione certificato pagamento';
            case "datalavori":
                return 'data giustificativo';
            case 'dataquietanza':
                return 'data quietanza';
            case 'anticipazioneasoggettoattuatore':
                return 'SAL o Giustificativo';
            case 'dataprevista':
                return 'data prevista';
            case 'dataeffettiva':
                return this.storicoObj && this.storicoObj.objectClass && this.storicoObj.objectClass.toLowerCase() === 'erogazione' ? 'data erogazione' : 'data effettiva';
            case 'dataprovvedimento':
                return 'data provvedimento';
            case 'numeroprovvedimento':
                return 'numero provvedimento';
            case 'statoavanzamentolavori':
                return 'stato avanzamento lavori';
            case 'temiprioritari':
                return 'temi prioritari';
            case 'tipoprovvedimentodescrizione':
                return 'tipo provvedimento';
            case 'scopoprovvedimentodescrizione':
                return 'scopo provvedimento';
            case 'provvedimentocapitolibilancio':
                return 'capitoli di bilancio';
            case 'allowsalwithoutvalidation':
                return 'permetti SAL senza validazione';
            case 'tipo':
                return this.storicoObj && this.storicoObj.objectClass.toLowerCase() =='siceratto'?'tipologia':'tipologia intervento';
                
            case 'importoaggiudicato':
                return 'importo aggiudicato';
            case 'importobaseasta':
                return 'importo base d\'asta';
            case 'prioritamotivazione':
                return 'priorità e motivazione';
            case 'flussodicassa':
                return 'Flusso di cassa';
            case 'previsionediimporto':
                return 'Previsione di importo';
            case 'capitolidibilancio':
                return 'Capitoli di bilancio';
            case 'nonlocalizzabile':
                return 'Non localizzabile';
            case 'motivazionecancellazione':
                return 'Motivazione cancellazione';
            case 'lastupdateuser':
                return 'Utente ultima modifica';
            case 'drawtype':
                return 'Tipologia';
            case 'statoattuazione':
                return 'Stato attuazione';
            case 'statoattuazionenote':
                return 'Note';
            case 'sitiretenatura':
                return 'Siti rete natura';
            case 'parametritecnici':
                return 'Parametri tecnici previsti';
            case 'parametritecnicieffettivi':
                return 'Parametri tecnici effettivi';
            case 'tipologiastatistici':
                return 'Tipologia';
            case 'parametritecniciunitadimisura':
                return 'Unità di misura';
            case 'statisticiextrafield1':
                return 'Opere d\'arte > 30% / moli';
            case 'statisticiextrafield2':
                return 'Classificazione prevalente /binario / corsia ';
            case 'statisticiextrafield3':
                return 'elettrificazione';
            case 'pnrrsoggettoproponente':
                return 'Amministrazione titolare';
            case 'pnrrrilevante':
                return 'Pnrr rilevante';
            case 'pnrrpubblicatoingu':
                return 'Pubblicato in GU'
            case 'pnrrdatagu':
                return 'Data pubblicazione in GU'
            case 'pnrrnumerogu':
                return 'Numero pubblicazione in GU'
            case 'fc':
                return 'PNC';
            case 'misurapnrr':
                return 'Misura PNRR';
            case 'amministrazionetitolare':
                return 'Amministrazione Titolare';
            case 'valoreripartitoregione':
                return 'Ripartito Regione Lazio (Territorio)';
            case 'valoreipotesidestinato':
                return 'Ripartito Nazionale';
            case 'quotaassegnatapnrr':
                return 'Quota assegnata PNRR';
            case 'quotaassegnatafc':
                return 'Quota assegnata PNC';
            case 'decretodiripartodafare':
                return 'Decreto di riparto da fare';
            case 'decretodiripartoassegnato':
                return 'Decreto di riparto assegnato';
            case 'bandoregionaledaripartire':
                return 'Bando regionale da ripartire';
            case 'bandoregionaleripartito':
                return 'Bando regionale ripartito';
            case 'bandoregionalebandito':
                return 'Bando regionale bandito';
            case 'bandoregionaleassegnato':
                return 'Bando regionale assegnato';
            case 'bandoministerialedabandire':
                return 'Bando ministeriale da bandire';
            case 'bandoministerialebandito':
                return 'Bando ministeriale bandito';
            case 'bandoministerialeassegnato':
                return 'Bando ministeriale assegnato';
            case 'beneficiariodiretto':
                return 'Beneficiario Diretto';
            case 'coprogrammazione':
                return 'Co-Programmazione';
            case 'beneficiariodirettodescrizione':
                return 'Beneficiario diretto descrizione';
            case 'coprogrammazionedescrizione':
                return 'Co-Programmazione descrizione';
            case 'attuazionedescrizione':
                return 'Attuazione descrizione';
            case 'notedatiservizio':
                return 'Note Dati di Servizio';
            case 'impattoterritoriale':
                return 'Impatto Territoriale';
            case 'pnrrsoggettoproponente':
                return 'Amministrazione titolare';
            case 'pnrrlineefinanziamento':
                return 'Linee finanziamento PNRR/PNC';
            case 'pnrrripartizionerisorse':
                return 'Pnrr ripartizione risorse';
            case 'description':
                return 'Descrizione';
            case 'datadocumento':
                return 'Data documento';
            case 'dataprotocollo':
                return 'Data protocollo';
            case 'nomefilecontenuto':
                return 'Nome file';
            case 'numeroprotocollo':
                return 'Numero protocollo';
            case 'numeroprotocolloesterno':
                return 'Numero protocollo esterno';
            case 'dataacquisizione':
                return 'Data acquisizione';
            case 'tipoprotocollo':
                return 'Tipo protocollo';
            case 'ufficiocompetente':
                return 'Ufficio competente';


        }
        return item;
    }

    printFieldValue(item) {
        if (this.storicoObj.action.toUpperCase() == 'DELETE') {
            return this.printOldFieldValue(item);
        }
        if (!this.newObjectData || this.storicoObj.action.toUpperCase() == 'DELETE') return '';
        let temp = this.newObjectData[item];
        return this.prettyPrintValue(item, temp);
    }
    printOldFieldValue(item) {
        if (!this.oldObjectData) return '';
        let temp = this.oldObjectData[item];
        return this.prettyPrintValue(item, temp);
    }

    getSubItemObjects(item, obj) {
        if (!obj) return [];
        if (item.toLowerCase() == 'finanziamento') {
            return Object.keys(obj[item]);
        }
        return obj[item];
    }

    getSubItemObjectsActualVersion(item) {
        let oldArr = this.oldObjectData ? this.oldObjectData[item] : [];
        let newArr = this.newObjectData ? this.newObjectData[item] : [];

        let resultArr = [];
        if (oldArr)
            oldArr.forEach(old => {
                let newObj = newArr.find(x => x.id == old.id);

                if (!newObj) {
                    resultArr.push(old);
                    old.isDeleted = true;
                }
                else {
                    for (let campo in newObj) {
                        if (campo == 'createdBy' || campo == 'updateBy' || campo == 'createdOn' || campo == 'updatedOn') continue;
                        let valore = newObj[campo];
                        let valoreOld = old[campo];
                        if (JSON.stringify(valore) != JSON.stringify(valoreOld)) newObj.isUpdated = true;
                    }
                    resultArr.push(newObj);
                }
            });
        newArr.forEach(newObj => {
            let oldObj = oldArr ? oldArr.find(x => x.id == newObj.id) : null;

            if (!oldObj) {
                newObj.isNew = true;
                resultArr.push(newObj);
            }
        });
        return resultArr;
    }

    getSubItemClass(item) {
        if (item.isDeleted) return 'text-danger text-delete';
        if (item.isNew) return 'text-success';
        if (item.isUpdated) return 'text-0083e0';
        return '';
    }

    isChildrenComplexObject(itemName) {
        return itemName.toLowerCase() == 'finanziamento'
            || itemName.toLowerCase() == 'statoavanzamentolavori'
            || itemName.toLowerCase() == 'sitiretenatura'
            || itemName.toLowerCase() == 'pnrrlineefinanziamento'
            || itemName.toLowerCase() == 'pnrrripartizionerisorse'
            ;
    }

    getComplexObjectHtml(itemName, obj, isNewPart) {
        if (!obj && this.storicoObj.action.toUpperCase() != 'DELETE') return "";
        if (this.storicoObj.action.toUpperCase() == 'DELETE') obj = this.oldObjectData;

        if (itemName.toLowerCase() == 'pnrrlineefinanziamento') {
            let lineeOld = this.oldObjectData ? this.oldObjectData.pnrrLineeFinanziamento : null;
            let lineeNew = this.newObjectData ? this.newObjectData.pnrrLineeFinanziamento : null;
            if ((lineeOld && !isNewPart) || (lineeNew && isNewPart)) {
                

                //aggiungo alle mie linee quelle old che ho cancellato!!

                if (isNewPart && lineeOld && lineeNew) {
                    lineeOld.forEach(x => {
                        if (!lineeNew.find(y => y.pnrrLineaFinanziamento.codice == x.pnrrLineaFinanziamento.codice)) {
                            lineeNew.push(x);
                            x.isDeleted = true;
                        }
                    }
                    );
                }
                let linee = lineeOld;
                if (isNewPart) linee = lineeNew;


                let cssClass = '';



                /*if (element.isUpdated) cssClass = 'text-0083e0';
                    if (element.isDeleted) cssClass = 'text-danger text-delete';
                    if (element.isNew) cssClass = 'text-success';*/

                if (linee) linee.sort((a, b) => a.pnrrLineaFinanziamento.codice < b.pnrrLineaFinanziamento.codice ? -1 : 1);
                //if(lineeNew) lineeNew.sort((a,b)=>a.pnrrLineaFinanziamento.codice<b.pnrrLineaFinanziamento.codice?-1:1);
                let htmlStr = `<table class="table table-bordered">
                        <thead><tr>
                        <th scope="col" style="width:10%;" class="font-14">codice</th>
                        <th scope="col" style="width:10%;" class="font-14">tipologia</th>
                        <th scope="col" style="width:10%;" class="font-14">quota pnrr</th>
                        <th scope="col" style="width:10%;" class="font-14">quota pnc</th>
                        </tr></thead>`;
                htmlStr += `<tbody>`;
                for (const linea of linee) {
                    if (isNewPart) {

                        cssClass = '';
                        //calcolo il colore della riga
                        const codice = linea.pnrrLineaFinanziamento.codice;
                        if (linea.isDeleted){
                            cssClass = 'text-danger text-delete';
                        }
                        //cerco se esiste in quella vecchia, se non c'è è nuova
                        else if (!lineeOld || !lineeOld.find(x => x.pnrrLineaFinanziamento.codice == codice)) {
                            cssClass = 'text-success';
                        }
                        else {
                            const oldLinea = lineeOld.find(x => x.pnrrLineaFinanziamento.codice == codice);
                            if (
                                oldLinea.importoPnrr!= linea.importoPnrr
                                || oldLinea.importoFc!= linea.importoFc
                                || oldLinea.rilevanteNazionale!= linea.rilevanteNazionale
                                || oldLinea.avviso!= linea.avviso
                                || oldLinea.assorbimentoDaDecreto!= linea.assorbimentoDaDecreto
                                || oldLinea.scadenza!= linea.scadenza
                                //|| oldLinea.decretoAssorbimento!= linea.decretoAssorbimento
                                || oldLinea.vecchioImportoPnrr!= linea.vecchioImportoPnrr
                                || oldLinea.vecchioImportoFc!= linea.vecchioImportoFc
                                || oldLinea.quotaAssegnataDecretoAssorbimentoPnrr!= linea.quotaAssegnataDecretoAssorbimentoPnrr
                                || oldLinea.quotaAssegnataDecretoAssorbimentoFc!= linea.quotaAssegnataDecretoAssorbimentoFc
                                ) cssClass = 'text-0083e0';
                        }
                    }


                    htmlStr += `<tr>
                                <td scope="col" class="font-14 ${cssClass}">${linea.pnrrLineaFinanziamento.codice}</td>
                                <td scope="col" class="font-14 ${cssClass}">${linea.pnrrLineaFinanziamento.tipologia}</td>
                                <td scope="col" class="font-14 ${cssClass}">${this.printValueAsCurrency(linea.importoPnrr)}</td>
                                <td scope="col" class="font-14 ${cssClass}">${this.printValueAsCurrency(linea.importoFc)}</td>
                            </tr>`;
                }
                htmlStr += `</tbody>`;
                htmlStr += `</table>`;
                return htmlStr;
            }
            return '';
        }

        if (itemName.toLowerCase() == 'pnrrripartizionerisorse') {
            let lineeOld = this.oldObjectData ? this.oldObjectData.pnrrRipartizioneRisorse : null;
            let lineeNew = this.newObjectData ? this.newObjectData.pnrrRipartizioneRisorse : null;
            if ((lineeOld && !isNewPart) || (lineeNew && isNewPart)) {
                

                //aggiungo alle mie linee quelle old che ho cancellato!!

                if (isNewPart && lineeOld && lineeNew) {
                    lineeOld.forEach(x => {
                        if (!lineeNew.find(
                            y => y.pnrrLineaFinanziamento.codice == x.pnrrLineaFinanziamento.codice
                            
                                && x.anno == y.anno 
                                && x.ruoloSoggettoGiuridico.id == y.ruoloSoggettoGiuridico.id
                                && x.soggettoGiuridico.id == y.soggettoGiuridico.id
                            )
                            ) {
                            lineeNew.push(x);
                            x.isDeleted = true;
                        }
                    }
                    );
                }
                let linee = lineeOld;
                if (isNewPart) linee = lineeNew;


                let cssClass = '';
 

                if (linee) linee.sort((a, b) => a.pnrrLineaFinanziamento.codice < b.pnrrLineaFinanziamento.codice ? -1 : 1);
                //if(lineeNew) lineeNew.sort((a,b)=>a.pnrrLineaFinanziamento.codice<b.pnrrLineaFinanziamento.codice?-1:1);
                let htmlStr = `<table class="table table-bordered">
                        <thead><tr>
                        <th scope="col" style="width:10%;" class="font-14">codice</th>
                        <th scope="col" style="width:10%;" class="font-14">anno</th>
                        <th scope="col" style="width:10%;" class="font-14">soggetto attuatore</th>
                        <th scope="col" style="width:10%;" class="font-14">quota assegnata pnrr</th>
                        <th scope="col" style="width:10%;" class="font-14">quota assegnata pnc</th>
                        </tr></thead>`;
                htmlStr += `<tbody>`;
                for (const linea of linee) {
                    if (isNewPart) {

                        cssClass = '';
                        //calcolo il colore della riga
                        if (linea.isDeleted){
                            cssClass = 'text-danger text-delete';
                        }
                        //cerco se esiste in quella vecchia, se non c'è è nuova
                        else if (
                            !lineeOld || 
                            !lineeOld.find(
                                y => y.pnrrLineaFinanziamento.codice == linea.pnrrLineaFinanziamento.codice
                            
                                && linea.anno == y.anno 
                                && linea.ruoloSoggettoGiuridico.id == y.ruoloSoggettoGiuridico.id
                                && linea.soggettoGiuridico.id == y.soggettoGiuridico.id
                                )
                            ) {
                            cssClass = 'text-success';
                        }
                        else {
                            const oldLinea = lineeOld.find(
                                y => y.pnrrLineaFinanziamento.codice == linea.pnrrLineaFinanziamento.codice
                            
                                && linea.anno == y.anno 
                                && linea.ruoloSoggettoGiuridico.id == linea.ruoloSoggettoGiuridico.id
                                && linea.soggettoGiuridico.id == y.soggettoGiuridico.id
                                );
                            if (
                                oldLinea.valoreFc!= linea.valoreFc
                                || oldLinea.valorePnrr!= linea.valorePnrr                                
                                ) cssClass = 'text-0083e0';
                        }
                    }


                    htmlStr += `<tr>
                                <td scope="col" class="font-14 ${cssClass}">${linea.pnrrLineaFinanziamento.codice}</td>
                                <td scope="col" class="font-14 ${cssClass}">${linea.anno}</td>
                                <td scope="col" class="font-14 ${cssClass}">${linea.ruoloSoggettoGiuridico.ruolo} - ${linea.soggettoGiuridico.denominazione}</td>
                                <td scope="col" class="font-14 ${cssClass}">${this.printValueAsCurrency(linea.valorePnrr)}</td>
                                <td scope="col" class="font-14 ${cssClass}">${this.printValueAsCurrency(linea.valoreFc)}</td>
                            </tr>`;
                }
                htmlStr += `</tbody>`;
                htmlStr += `</table>`;
                return htmlStr;
            }
            return '';
        }




        if (itemName.toLowerCase() == 'statoavanzamentolavori' && obj[itemName][0]) {
            let subObj = obj[itemName][0];
            let currentClass = isNewPart && this.storicoObj.action.toUpperCase() == 'DELETE' ? 'text-danger text-delete' : '';
            if (isNewPart && this.storicoObj.action.toUpperCase() == 'INSERT') currentClass = 'text-success';
            let htmlStr = ``;
            htmlStr += '<table class="table table-bordered">';
            htmlStr += '<tbody>';
            htmlStr += `<tr><th scope="col" style="width:10%;">categoria</th><td scope="col" style="width:30%;" class="${currentClass}">${subObj ? subObj['categoria']['titolo'] : ''}</td></tr>`;
            htmlStr += `<tr><th scope="col" style="width:10%;">importo</th><td scope="col" style="width:30%;"  class="${currentClass}">${this.printValueAsCurrency(subObj ? subObj['importo'] : '')}</td></tr>`;
            let value = subObj['dataLavori'];


            if (typeof value == 'string') {
                value = moment(value);
                value = value.format('DD/MM/YYYY');
            }
            else {
                let dateStr = `${value.year}-${value.monthValue.toString().padStart(2, '0')}-${value.dayOfMonth.toString().padStart(2, '0')}`;
                value = moment(dateStr).format('DD/MM/YYYY');
            }


            htmlStr += `<tr><th scope="col" style="width:10%;">data lavori e somministranze</th><td scope="col" style="width:30%;"  class="${currentClass}">${value}</td></tr>`;
            htmlStr += `<tr><th scope="col" style="width:10%;">descrizione</th><td scope="col" style="width:30%;"  class="${currentClass}">${subObj['descrizione']}</td></tr>`;
            value = subObj['anticipazioneASoggettoAttuatore'];
            if (value != null && value.toString() == 'false') value = 'Giustificativo';
            if (value == true) value = 'SAL/Altro';
            htmlStr += `<tr><th scope="col" style="width:10%;">somme a soggetto attuatore</th><td scope="col" style="width:30%;"  class="${currentClass}">${value}</td></tr>`;
            htmlStr += '</tbody>';
            htmlStr += '</table>';
            return htmlStr;
        }
        if (itemName.toLowerCase() == 'finanziamento') {
            //obj = obj[itemName];
            let htmlStr = `<span class="font-14">${obj[itemName]['tipologia']} - ${obj[itemName]['titolo']}</span><br />`;
            htmlStr += '<table class="table table-bordered">';
            htmlStr += '<thead><tr>';
            htmlStr += '<th scope="col" style="width:10%;">anno</th><th scope="col" style="width:30%;">importo</th>';
            htmlStr += '</tr></thead>';

            let annualita = obj['annualita'];
            if (annualita) {

                annualita.sort((a, b) => a.anno - b.anno);
                htmlStr += '<tbody>';

                //se sono sulla parte nuova devo dare lo stesso ordine del vecchio cosi da allineare i nuovi elementi e le cancellazioni
                if (isNewPart && this.oldObjectData && this.oldObjectData['annualita']) {
                    let newArray = [];
                    this.oldObjectData['annualita'].forEach(
                        old => {
                            let newVal = annualita.find(x => x.anno == old.anno);
                            if (!newVal) old['isDeleted'] = true;
                            else
                                if (newVal.importo != old.importo) {
                                    old.importo = newVal.importo;
                                    old.isUpdated = true;
                                }
                            newArray.push(old);
                        }
                    );
                    annualita.forEach(newElem => {
                        if (!this.oldObjectData['annualita'].find(x => x.anno == newElem.anno)) {
                            newElem.isNew = true;
                            newArray.push(newElem);
                        }
                    });
                    annualita = newArray;
                }


                annualita.forEach(element => {
                    let cssClass = '';
                    if (isNewPart) {
                        //   let oldValue = '';
                        /*let oldAnnualita = this.oldObjectData?this.oldObjectData['annualita']:null;
                        if (oldAnnualita)oldValue = oldAnnualita.find(x=>x.anno == element.anno)?oldAnnualita.find(x=>x.anno == element.anno).importo:'';
                        if (oldValue =='')cssClass = 'text-success';
                        if (oldValue !='' && oldValue != element['importo'])cssClass='text-0083e0';*/
                        if (element.isUpdated) cssClass = 'text-0083e0';
                        if (element.isDeleted) cssClass = 'text-danger text-delete';
                        if (element.isNew) cssClass = 'text-success';
                    }
                    /*else{
                        let newAnnualita = this.newObjectData?this.newObjectData['annualita']:null;
                        cssClass = (newAnnualita && newAnnualita.find(x=>x.anno == element.anno))?'':'text-danger text-delete';
                    }*/
                    htmlStr += `<tr><td scope="row" class="${cssClass}">${element['anno']}</td><td  class="${cssClass}">${this.printValueAsCurrency(element['importo'])}</td></tr>`;
                });
                htmlStr += '</tbody>';
            }
            htmlStr += '</table>';
            return htmlStr;
        }
        if (itemName.toLowerCase() == 'sitiretenatura' && obj && obj['sitiReteNatura']) {


            if (isNewPart && this.oldObjectData) {
                //cerco quelli eliminati, li aggiungo al nuovo e li segno rossi
                //per quelli che abbiamo gia, controllo se sono modificati
                //per quelli che non ho in origine ma in destinazione, metto nuovo
                this.oldObjectData.sitiReteNatura.forEach(
                    x => {
                        let newItem = obj.sitiReteNatura.find(y => y.sitoReteNatura.codice == x.sitoReteNatura.codice);
                        if (!newItem) { obj.sitiReteNatura.push(x); x.isDeleted = true; }
                        else {
                            newItem.isUpdated = (
                                newItem.distanza.nome != x.distanza.nome
                                || newItem.sitoReteNatura.tipologia != x.sitoReteNatura.tipologia
                                || newItem.sitoReteNatura.nome != x.sitoReteNatura.nome
                            )
                        }
                    }
                );
                obj.sitiReteNatura.forEach(
                    x => {
                        let oldItem = this.oldObjectData.sitiReteNatura.find(y => y.sitoReteNatura.codice == x.sitoReteNatura.codice);
                        if (!oldItem) x.isNew = true;
                    }
                );
            }
            obj['sitiReteNatura'].sort((a, b) => a.sitoReteNatura.codice - b.sitoReteNatura.codice);

            let htmlStr = `<table class="table table-bordered">
                        <thead><tr>
                        <th scope="col" style="width:10%;">tipologia</th>
                        <th scope="col" style="width:10%;">codice</th>
                        <th scope="col" style="width:10%;">descrizione</th>
                        <th scope="col" style="width:10%;">distanza</th>
                        </tr></thead>`;
            htmlStr += `<tbody>`;
            obj['sitiReteNatura'].forEach(x => {

                let cssClass = '';
                if (x.isUpdated) cssClass = 'text-0083e0';
                if (x.isDeleted) cssClass = 'text-danger text-delete';
                if (x.isNew) cssClass = 'text-success';
                htmlStr += `<tr class="${cssClass}">
                                <td>${x.sitoReteNatura.tipologia}</td>
                                <td>${x.sitoReteNatura.codice}</td>
                                <td>${x.sitoReteNatura.nome}</td>
                                <td>${x.distanza.nome}</td>
                            </tr>`;
            });
            htmlStr += '</tbody></table>';

            return htmlStr;

        }
        return "";
    }


    printSubItemValue(subItem, mainItemName) {

        if (subItem['descrizioneEstesa']) return subItem['descrizioneEstesa'];
        if (subItem['descrizione']) return subItem['descrizione'];
        if (subItem['motivazione']) return subItem['motivazione'];

        if (mainItemName.toLowerCase() == 'accertamenti') {
            return 'n° ' + subItem['numero'] + ': ' + this.printValueAsCurrency(subItem['valore']);

        }
        if (mainItemName.toLowerCase() == 'soggettigiuridici') {
            return `${subItem['siglaProvincia']} - ${subItem['codiceIpa']} - ${subItem['denominazione']}`;
        }

        if (mainItemName.toLowerCase() == 'provvedimentocapitolibilancio') {
            return `${subItem['capitoloBilancioCodice']} - ${subItem['capitoloBilancioDescrizione']} - ${subItem['anno']} - ${this.printValueAsCurrency(subItem['importo'])}`;
        }
        if (mainItemName.toLowerCase() == 'soggettiresponsabili') {
            return `${subItem['cognome']} ${subItem['nome']}`;
        }
        if (mainItemName.toLowerCase() == 'capitolidibilancio') {
            return `${subItem['codice']} - ${subItem['descrizione']}`;
        }
        return '';
    }


    prettyPrintValue(name, value) {
        if (value instanceof Object) {
            if (name == 'tipologiaFase') {
                return `${value['fase']} - ${value['titolo']} - ${value['descrizione']}`;
                //restituisco il nome composto
            }
            if (name == 'amministrazioneTitolare' || name == 'pnrrSoggettoProponente') {
                return `${value['codiceIpa']} - ${value['denominazione']}`;
            }
            if (value['descrizione']) return value['descrizione'];
            if (value['tipoFinanziamento']) return value['tipoFinanziamento'];
            if (value['provvedimento']) return value['provvedimento'];
            if (value['titolo']) return value['titolo'];
            if (value.year && value.dayOfMonth && value.monthValue) {
                let dateStr = `${value.year}-${value.monthValue.toString().padStart(2, '0')}-${value.dayOfMonth.toString().padStart(2, '0')}`;
                return moment(dateStr).format('DD/MM/YYYY');
            }
            if (name == 'prioritaMotivazione') {
                return `${value['priorita']} - ${value['motivazione']}`;
            }

            if (name === 'rup') {
                return `${value['nome']} ${value['cognome']}`
            }

        }
        else {
            if (value != null && value.toString() == 'false') value = 'No';
            if (value !== 1 && value !== '1' && value == true) value = 'Si';
            function isIsoDate(str) {
                if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)
                    && !/\d{4}-\d{2}-\d{2}/.test(str)
                ) return false;
                return true;
                //var d = new Date(str); 
                //return d.toISOString()===str;
            }
            if (value && value != '' && typeof value == 'string' && isIsoDate(value) && moment(value).isValid()) {
                console.log(value);
                return moment(value).format('DD/MM/YYYY');
            }

        }
        if (name == 'validationStatus') {
            if (!value) value = 'IDLE';
            switch (value.toString().toUpperCase()) {
                case 'REJECTED':
                    value = 'Rifiutato';
                    break;
                case 'APPROVED':
                    value = 'Validato';
                    break;
                case 'FABBISOGNO':
                    value = 'Fabbisogno';
                    break;
                case 'IDLE':
                    value = '';
                    break;
                case 'WAITING_FOR_APPROVAL_FIRST_LEVEL':
                    value = 'In attesa di validazione di primo livello';
                    break;
                case 'WAITING_FOR_APPROVAL_SECOND_LEVEL':
                    value = 'In attesa di validazione di secondo livello';
                    break;
                case 'TERMINATO':
                    value = 'Completato';
                    break;
            }
        }
        switch (name.toLowerCase()) {
            case 'anticipazioneasoggettoattuatore':
                if (value == 'Si') value = 'SAL/Altro';
                else value = 'Giustificativo';
                break;
            case 'importo':
            case 'importocomplessivo':
            case 'disponibilitaresidua':
            case 'disponibilita':
            case 'importoaggiudicato':
            case 'importobaseasta':
                return this.printValueAsCurrency(value);
            case 'percentualecofinanziamento':
                return value + ' %';
            case 'tipo':
                switch (value) {
                    case "road":
                        return "Strade";

                    case "rail":
                        return "Infrastrutture Ferroviare";

                    case "metro":
                        return "Metro";

                    case "bike":
                        return "Ciclabili";

                    case "skilift":
                        return "Impianti di risalita";

                    case "points":
                        return "Edifici scolastici";

                    case "port":
                        return "Porti";

                    case "metrostop":
                        return "Stazioni metro";

                    case "station":
                        return "Stazioni ferroviarie";

                    case "other_lines":
                        return "Altre vie di comunicazione";

                    case "other_points":
                        return "Edifici generici";

                    case "park":
                        return "Parcheggi";
                    case "nodes":
                        return "Nodi di scambio";

                    case "parkland":
                        return "Parchi e aree verdi";
                    case "emobility":
                        return "E-Mobility";
                    case "materiale_rotabile_ferro":
                        return "Materiale rotabile ferro";
                    case "materiale_rotabile_gomma":
                        return "Materiale rotabile gomma";
                    case 'formazione':
                        return  'Formazione';
                default:
                    return value;            


                }
                break;
            case 'statoattuazione':
                switch (value) {

                    case "intervento_sospeso_temporaneamente":
                        return "Intervento sospeso temporaneamente";
                    case "intervento_sospeso_totalmente":
                        return "Intervento sospeso totalmente";
                    case "intervento_in_corso":
                        return "Intervento in corso";
                    case "intervento_realizzato":
                        return "Intervento realizzato";

                }
                break;
        }
        return value;
    }

    isGoodProperty(item) {
        console.log(item);
        if (!item) return false;
        switch (item.toLowerCase()) {
            case 'allegati':
            case 'abilitapnrr':
            case 'abilitafc':
            case 'pnrrlineafinanziamentoprincipaleid':
            //case 'pnrrripartizionerisorse':
            //case 'pnrrlineefinanziamento':
            //case 'pnrrsoggettoproponente':
            case 'pnrr_soggetto_proponente_id':
            case 'finanziamenti_documenti':
            case 'validationrequestuseremail':
            case 'createdby':
            case 'createdon':
            case 'updateby':
            case 'updatedby':
            case 'updatedon':
            case 'id':
            case 'documenti':
            case 'validazione':
            case 'isreadonly':
            case 'provvedimenti':
            case 'annualita':
            case 'fasi':
            case 'isdeleted':
            case 'ismain':
            case 'coordinateinterventi':
            case 'progettoid':
            case 'importoimpegnato':
            case 'interventoid':
            case 'intervento':
            case 'erogazioni':
            case 'lastupdateuser':
            case 'importofinanziamentiutilizzato':
            case 'tipi_provvedimento_id':
            case 'scopi_provvedimento_id':
            case 'tipo_strumento_attuativo_id':
            case 'tipo_finanziamento_id':
            case 'intervento_id':
            case 'interventi_documenti':
            case 'historychangesondates':
            case 'tipologia_fase_id':
            case 'finanziamentoid':
            case 'disponibilitafinanziamento':
            case 'importofinanziamento':
            case 'finanziamento_id':
            case 'categoria_id':
            case 'tipologia_id':
            case 'tipo_provvedimento_id':
            case 'hanoteintervento':
            case 'type':
            case 'priorita_motivazione_id':
            case 'rupselect':
            case 'rup_id':
            case 'coordinateintervento':
            case 'validation_request_user_id':
            case 'validation_approved_user_id':
            case 'validationrequestuser':
            case 'validationapproveduser':
            case 'categoriaid':
            case 'afrontedeisal':
            case 'document':
            case 'stato_attuazione_id':
            case 'triplette':
            case 'readonly':
                return false;
            case 'disponibilita':
                if (!this.intervento || !this.intervento.isMain) return false;
                return true;
            case 'accertatoimpegnato':
            case 'accertamenti':
                if (this.intervento && this.intervento.isMain) return false;
                return true;
            case 'tipoprovvedimentoid':
            case 'scopoprovvedimentoid':
            case 'scopoprovvedimentodescrizione':
            case 'tipoprovvedimentodescrizione':
            case 'monitoraggio':
                return false;
            case 'dataprevista':
            case 'anno':
                return this.storicoObj && this.storicoObj.objectClass && this.storicoObj.objectClass.toLowerCase() === 'erogazione' ? false : true;
            case 'salid':
            case 'provvedimentoid':
            case 'classificazioneid':
                    return false;
        }
        return true;
    }

    isChildrenObject(item) {
        switch (item.toLowerCase()) {
            case 'areetematiche':
            case 'classificazioni':
            case 'temiprioritari':
            case 'accertamenti':
            case 'soggettiresponsabili':
            case 'soggettigiuridici':
            case 'finanziamento':
            case 'statoavanzamentolavori':
            case 'provvedimentocapitolibilancio':
            case 'motivazioni':
            case 'capitolidibilancio':
            case 'sitiretenatura':
            case 'pnrrlineefinanziamento':
            case 'pnrrripartizionerisorse':
                /*case 'prioritamotivazione':*/
                return true;
        }
        return false;
    }


    getClassName(item) {
        if (this.isCampoEliminato(item)) return 'text-danger text-delete';
        if (this.isNuovoCampo(item)) return 'text-success';
        if (this.isCampoModificato(item)) return 'text-0083e0';
        return '';
    }

    isCampoEliminato(item) {
        if (this.storicoObj.action.toUpperCase() == 'DELETE') return true;
        let val = this.printFieldValue(item);
        let valold = this.printOldFieldValue(item);
        return (!val || val == '') && val != valold;
    }

    isCampoNonModificato(item) {
        if (this.storicoObj.action.toUpperCase() == 'DELETE') return false;
        let oldVal = this.printOldFieldValue(item);
        let newVal = this.printFieldValue(item);
        return oldVal == newVal;
    }
    isCampoModificato(item) {
        if (this.storicoObj.action.toUpperCase() == 'DELETE') return false;
        let oldVal = this.printOldFieldValue(item);
        let newVal = this.printFieldValue(item);
        return oldVal != newVal;
    }
    isNuovoCampo(item) {
        if (this.storicoObj.action.toUpperCase() == 'DELETE') return false;
        let val = this.printFieldValue(item);
        let valold = this.printOldFieldValue(item);
        return (!valold || valold == '') && val != valold;
    }

    printValueAsCurrency(value) {
        if (value == null) return '0,00 €';
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }

}