<div class="col-lg-12">
    <div class=" fixedHeaderTable col-lg-12  table-responsive table-wrapper-scroll-y scrollbar-y my-5 px-0" style="max-height: 600px;">
        <table class="table table-hover table-colore tableFixHead">
            <thead class="">
                <th class="fixedmeY bg-white" style="min-width: 290px; z-index:90000">Finanziamento</th>
                <th class="bg-white" style="z-index:90000">Fonte / Strumento attuativo</th>
                <th class="bg-white" style="z-index:90000">Aree gestionali direzione e/o esterne</th>
                <th class="bg-white" style="z-index:90000">&nbsp;</th>
                <ng-container *ngIf="currentObject">
                    <th class="bg-white text-right " style="min-width: 150px; z-index:90000" *ngFor="let anno of arrayAnni">
                      <span class="px-4"> {{anno}} </span> 
                    </th>
                </ng-container>
                <th class="bg-white text-right" style="min-width: 180px; z-index:90000">
                    <span class="px-4">totale</span>
                </th>
            </thead>
            <tbody>
                <ng-container *ngIf="currentObject as finanziamenti">
                    <ng-container *ngFor="let finanziamento of finanziamenti;let i=index;">
                        <tr>
                            <td class="fixedme" style="max-width: 290px;">
                                <span class="font-weight-bold ">
                                    &nbsp;{{finanziamento.finanziamentoCodice}}
                                </span>
                                <div class="" style="font-size: 13px; font-weight: normal;">
                                    {{ finanziamento.titolo}}
                                </div>
                            </td>
                            <td style="min-width: 290px; font-size: 13px;">
                                <ul>
                                    <li *ngIf="finanziamento.tipoFonte && finanziamento.tipoFonte.tipoFinanziamento">{{finanziamento.tipoFonte?finanziamento.tipoFonte.tipoFinanziamento:''}}</li>
                                    <li *ngIf="finanziamento.tipoStrumentoAttuativo && finanziamento.tipoStrumentoAttuativo.descrizione">{{finanziamento.tipoStrumentoAttuativo?finanziamento.tipoStrumentoAttuativo.descrizione:''}}</li>
                                </ul>
                            </td>
                            <td style="min-width: 290px; font-size: 13px;">
                                <ul>
                                    <li *ngFor="let area of finanziamento.areeTematiche">{{area.descrizione}}</li>
                                </ul>
                            </td>


                            <td style="min-width: 290px; font-size: 13px;">
                                <ul class="list-group font-14">
                                    <li class="list-group-item font-14">valore finanziamento</li>
                                    <li class="list-group-item font-14">di cui destinato</li>
                                    <li class="list-group-item font-14">disponibile</li>
                                </ul>
                            </td>
                            <td class="" style="min-width: 150px;" *ngFor="let anno of arrayAnni">
                                <ul class="list-group font-14 text-right">
                                    <li class="list-group-item font-14">{{printValueAsCurrency( getValoreFinanziamento(finanziamento,anno))}}</li>
                                    <li class="list-group-item font-14">{{printValueAsCurrency(getImpegnatoFinanziamento(finanziamento,anno))}}</li>
                                    <li class="list-group-item font-14">{{printValueAsCurrency(getDisponibilitaFinanziamento(finanziamento,anno))}}</li>
                                </ul>
                            </td>
                            <td class="" style="min-width: 180px;">
                                <ul class="list-group font-14 text-right">
                                    <li class="list-group-item font-14">
                                        <strong>{{printValueAsCurrency(getValoreFinanziamentoTotale(finanziamento))}}</strong></li>
                                    <li class="list-group-item font-14">
                                        <strong>{{printValueAsCurrency(getImpegnatoFinanziamentoTotale(finanziamento))}}</strong></li>
                                    <li class="list-group-item font-14">
                                        <strong>{{printValueAsCurrency(getDisponibilitaFinanziamentoTotale(finanziamento))}}</strong></li>
                                </ul>
                            </td>
                        </tr>


                    </ng-container>

                    <ng-container *ngIf="!(currentObject &&  currentObject.length>0)">
                        <tr>
                            <td colspan="20">Nessun finanziamento presente</td>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>

            <tfoot *ngIf="(currentObject &&  currentObject.length>0)">
                <tr>
                    <th class="fixedme">totale</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th style="min-width: 290px; font-size: 13px;">
                        <ul class="list-group font-14 text-body">
                            <li class="list-group-item font-14">valore finanziamento</li>
                            <li class="list-group-item font-14">di cui destinato</li>
                            <li class="list-group-item font-14">disponibile</li>
                        </ul>
                    </th>
                    <th style="min-width: 150px;" *ngFor="let anno of arrayAnni">
                        <ul class="list-group font-14 text-body text-right">
                            <li class="list-group-item font-14">{{printValueAsCurrency( getValoreTotale(anno))}}</li>
                            <li class="list-group-item font-14">{{printValueAsCurrency(getImpegnatoTotale(anno))}}</li>
                            <li class="list-group-item font-14">{{printValueAsCurrency(getDisponibilitaTotale(anno))}}</li>
                        </ul>
                    </th>
                    <th style="min-width: 180px;">
                        <ul class="list-group font-14 text-body text-right">
                            <li class="list-group-item font-14">
                                <strong>{{printValueAsCurrency(getValoreTotale())}}</strong></li>
                            <li class="list-group-item font-14">
                                <strong>{{printValueAsCurrency(getImpegnatoTotale())}}</strong></li>
                            <li class="list-group-item font-14">
                                <strong>{{printValueAsCurrency(getDisponibilitaTotale())}}</strong></li>
                        </ul>
                    </th>

                </tr>

            </tfoot>




        </table>

    </div>
</div>