<div class="p-5 container-xl text-center" [hidden]="true">
  <app-loading message="Caricamento in corso..."></app-loading>
</div>


<div class="container-xl">
  <!--<div class=" pt-4 elemento-aggiorna">
    <div class=" row  justify-content-center">
      <div class="col-lg-3 col-md-6 text-center" [hidden]="readOnlyForm">
        <button [disabled]="loading" (click)="openAddModal()" class="btn btn-primary btn-block" type="button">
          Associa nuovo finanziamento
        </button>
      </div>
      <div class="col-lg-3 col-md-6 text-center" *ngIf="gotoBtnOptions && gotoBtnOptions.show">
        <button (click)="gotoBackBtnClick()" [disabled]="loading" class="btn btn-light btn-block" type="button">
          {{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}
        </button>
      </div>
    </div>
  </div>

  <div class="mb-5">
    <hr />
  </div>-->

  <div class="position-sticky sticky-top   pt-4 pb-4 elemento-aggiorna mb-5 shadow-custom"
    [ngClass]="{'sticky-top-custom-modal': currentIntervento && !currentIntervento.isMain,'sticky-top-custom':!currentIntervento ||( currentIntervento && currentIntervento.isMain) , 'sticky-top-custom-sal': esistonoInterventi}">
    <div class=" row  justify-content-center">
        <div class="col-lg-4 col-md-6 text-center" [hidden]="readOnlyForm ||(intervento && intervento.pnrrRilevante)">
            <button [disabled]="loading" (click)="openNewFinanziamentoModal()" class="btn btn-info btn-block font-weight-bold" type="button">
              <img *ngIf="loading " class="pl-3 float-right "
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              Crea Nuovo finanziamento&nbsp;<i class="fas fa-plus"></i>
            </button>
          </div>

      <div class="col-lg-4 col-md-6 text-center" [hidden]="readOnlyForm">
        <button [disabled]="loading" (click)="openAddModal()" class="btn btn-primary btn-block" type="button">
          <img *ngIf="loading " class="pl-3 float-right "
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          Associa finanziamento&nbsp;<i class="fas fa-link"></i>
        </button>
      </div>
      <div class="col-lg-4 col-md-6 text-center" *ngIf="gotoBtnOptions && gotoBtnOptions.show">
        <button (click)="gotoBackBtnClick()" [disabled]="loading" class="btn btn-secondary btn-block font-weight-bold"
          type="button">
          {{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}
          &nbsp;<i class="fas fa-times" *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i>
        </button>
      </div>
    </div>
  </div>
  <!--***-->

  <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;"
    [dtOptions]="dtOptions">
    <ng-template #btnEditTemplate let-row="row" let-value="value">
      <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top"
        title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
    </ng-template>
    <ng-template #btnDeleteTemplate let-row="row" let-value="value">
      <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top"
        title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
    </ng-template>
    <ng-template #direzioneValueTemplate let-row="row" let-value="value">
      <div *ngFor="let direzione of value">
        <span>{{ direzione.descrizione }}</span>
      </div>
    </ng-template>
    <ng-template #importoValueTemplate let-row="row" let-value="value" let-index="index">
      <table class="w-100 table table-borderless" id="detail_importi_{{row.id}}">
        <tr class="collapse trdetail" *ngFor="let val of sortImporti(row.annualita)">

          <td class="pt-0 pb-1 pl-0 pr-1">{{val.anno}}:</td>
          <td class="pt-0 pb-1 text-0083e0 pl-0">{{printValueAsCurrency(val.importo)}}</td>
        <tr>
          <td class="pt-0 pb-1 pl-0 pr-1">
            <a class="show-detail-anchor collapse show" data-toggle="collapse"
              attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i
                class="fas fa-list-ul pointer"></i></a>
            <!--<a class="trdetail show-detail-anchor collapse show" data-toggle="collapse" attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i class="fas fa-edit pointer"></i></a>-->
          </td>
          <td class="pt-0 pb-1 pl-0">{{ printValueAsCurrency(value)}}</td>
        </tr>
      </table>
    </ng-template>
  </ngx-datatable-wrapper>
</div>

<ng-template #templateEditAdd>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">{{modalTitle}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
      <input type="hidden" [formGroup]="editForm" formControlName="id" />

      <div class="col-lg-12 mt-3">

        <div class="form-group row">
          <div class="col-sm-12">
            <label for="finanziamentoId">
              <h5 class="mandatory">Finanziamento:</h5>
            </label>
            <div [ngClass]="{ 'is-invalid': submitted && editForm.controls['finanziamentoId'].errors }">
              <ng-select [formGroup]="editForm" formControlName="finanziamentoId" [items]="finanziamentiList"
                bindValue="id" bindLabel="titoloLungo" placeholder="Seleziona il finanziamento"
                (change)="onFinanziamentoChange($event)">
              </ng-select>
            </div>
            <div *ngIf="submitted && editForm.controls['finanziamentoId'].errors" class="invalidFieldMessage"
              style="display: block;">
              <div *ngIf="editForm.controls['finanziamentoId'].errors" class="invalid-feedback" style="display: block;">
                Campo obbligatorio
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12">
            <label for="importoFinanziamento">
              <h5 class="">Importo
                finanziamento{{currentIntervento && !currentIntervento.isMain?" associato al progetto"  +(isGr34() || isAdmin()?" / fabbisogno":'') :""}}:
              </h5>
            </label>
            <input [formGroup]="editForm" formControlName="importoFinanziamento" maxlength="255" type="text"
              class="form-control" [imask]="mask" [unmask]="true" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12">
            <label for="disponibilitaFinanziamento">
              <h5 class="">Disponibilità
                finanziamento{{currentIntervento && !currentIntervento.isMain?" associata al progetto" +(isGr34() || isAdmin()?" / fabbisogno":''):""}}:
              </h5>
            </label>
            <input [formGroup]="editForm" formControlName="disponibilitaFinanziamento" maxlength="255" type="text"
              class="form-control" [imask]="mask" [unmask]="true" />
          </div>
        </div>


        <div class="form-group row">
          <div class="col-sm-12">
            <label for="importoComplessivo">
              <h5 class="">Importo totale associazione:</h5>
            </label>
            <input [formGroup]="editForm" formControlName="importo" maxlength="255" type="text" class="form-control"
              placeholder="Inserisci l'importo" [ngClass]="{
                                'is-invalid': submitted && editForm.controls.importo.errors
                                }" [imask]="mask" [unmask]="true" />
            <div *ngIf="submitted && editForm.controls.importo.errors" class="invalid-feedback" style="display: block;">
              <div *ngIf="editForm.controls.importo.errors.required">
                Campo obbligatorio
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 mt-5">
        <hr>
      </div>

      <div class="col-lg-12 mt-4">
        <div class="row justify-content-between">

          <div class="col-lg-6 col-sm-6 col-6 mt-3">
            <h5 for="" class="">Importi (per anno):</h5>
          </div>
          <div class="col-lg-1 col-sm-2 col-3 text-center mt-3">
            <a href="javascript:;;" class="bottone-inserisci" (click)="openModalImportiAdd();"><i
                class="fas fa-plus"></i></a>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mt-3 table-responsive-lg">
        <table class="table table-hover table-colore">
          <thead class="">
            <th>anno</th>
            <th>importo</th>
            <th class="casella-icona-tx">elimina</th>
          </thead>
          <tbody>
            <ng-container *ngIf="importiList && importiList.length && importiList.length>0">
              <tr *ngFor="let item of importiList">
                <td>{{item.anno}}</td>
                <td><span>{{item.importo| italianCurrencyPipe  }} €</span>
                </td>
                <td><a (click)="removeImporto(item)" href="javascript:;;" data-toggle="tooltip" data-placement="top"
                    title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i> </a>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="!(importiList && importiList.length && importiList.length>0)">
              <td colspan="4">Nessun importo selezionato</td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="submitted && importiIsError" class="invalidFieldMessage">
          <div *ngIf="importiIsError" class="invalid-feedback" style="display: block;">
            Selezionare almeno un importo
          </div>
        </div>
      </div>
      <div class="col-lg-12 mb-3"><button [disabled]="loading" class="btn btn-lg btn-primary btn-block" type="submit">
          <img *ngIf="loading" class="pl-3 float-right"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          {{modalButtonLabel}}
        </button></div>
    </form>
  </div>
</ng-template>



<ng-template #templateModalImporti>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">{{modalTitleImporti}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalImporti()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editFormImporti" (ngSubmit)="onSubmitImporti()">
      <input type="hidden" [formGroup]="editFormImporti" formControlName="id" />

      <div class="col-lg-12 mt-3">
        <div class="form-group">
          <h5><label for="anno" class="mandatory">Anno:</label></h5>          
          <div [ngClass]="{ 'is-invalid': submitted && editFormImporti.controls.anno.errors }">
            <ng-select [formGroup]="editFormImporti" formControlName="anno" [items]="anniList"
              (change)="onChangeAnnoImporto($event);" bindValue="anno" bindLabel="anno" placeholder="Seleziona l'anno">
            </ng-select>
          </div>
          <div *ngIf="submittedImporti && editFormImporti.controls.anno.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="editFormImporti.controls.anno.errors.required">
              Campo obbligatorio
            </div>
            <div *ngIf="editFormImporti.controls.anno.errors.minlength">
              Campo deve essere di 4 cifre
            </div>
          </div>
        </div>
        <div class="form-group">
          <h5><label for="disponibilita" class="">Disponibilità
              finanziamento{{currentIntervento && !currentIntervento.isMain?" associata al progetto" +(isGr34() || isAdmin()?" / fabbisogno":''):""}}
              per l'anno selezionato:</label></h5>
          <input [formGroup]="editFormImporti" formControlName="disponibilita" type="text" [imask]="mask"
            [unmask]="true" class="form-control" placeholder="" class="form-control" />
        </div>
        <div class="form-group">
          <h5><label for="importoInterventoAnnualita" class="">Valore
              {{currentIntervento && !currentIntervento.isMain?"intervento":"progetto" +(isGr34() || isAdmin()?" / fabbisogno":'')}} per l'anno
              selezionato:</label></h5>
          <input [formGroup]="editFormImporti" formControlName="importoInterventoAnnualita" type="text" [imask]="mask"
            [unmask]="true" class="form-control" placeholder="" class="form-control" />
        </div>
        <div class="form-group">
          <h5><label for="importo" class="mandatory">Importo:</label></h5>
          <input [formGroup]="editFormImporti" formControlName="importo" type="text" [showMaskTyped]="false"
            mask="separator.2" thousandSeparator="." suffix=" €" class="form-control" placeholder="Inserisci l'importo"
            [ngClass]="{
                                'form-control': true,
                                'is-invalid': submittedImporti && editFormImporti.controls.importo.errors
                                }" />
          <div *ngIf="submittedImporti && editFormImporti.controls.importo.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="editFormImporti.controls.importo.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>

      </div>

      <div class="col-lg-12 mb-3"><button [disabled]="loading" class="btn btn-lg btn-primary btn-block" type="submit">
          <img *ngIf="loading" class="pl-3 float-right"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          {{modalButtonLabelImporti}}
        </button></div>
    </form>
  </div>
</ng-template>



<ng-template #templateModalNuovoFinanziamento>
    <div class="modal-header  text-center">
      <h4 class="modal-title pull-left">Nuovo Finanziamento</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalNuovoFinanziamento()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <finanziamento-component #finanziamentoComponent 
        [gotoBtnOptions]="{show:true,label:'Chiudi'}" 
        (gotoBtnFunction)="closeModalNuovoFinanziamento($event)"
        [finanziamentoId]="fakeFinanziamentoId"
        [isInModal]="true"
        (saveElementEmitter)="saveNewFinanziamentoEmitter($event)"
         >
        </finanziamento-component>      
    </div>
  </ng-template>


