<div class="col-12 mb-5">
    <div class="col-md-12">
        <p class="text-sm-left mt-3">
            In questa pagina è possibile <b>inserire un nuova nuova Provincia / Città Metropolitana, visualizzare la lista delle Province e città metropolitane presenti e modificarne i relativi dati</b>.
        </p>
    </div>

    <hr />
    <accordion [isAnimated]="true">
        <accordion-group heading="Inserisci Nuova Provincia / Città Metropolitana">
            <form [formGroup]="addProvinceForm" (ngSubmit)="onSubmit()" class="col-lg-12">

                <div class="row">
                    <!-- *** -->
                    <div class="form-group col-xl-6 col-lg-6">
                        <h5><label for="name" class="mandatory">Nome:</label></h5>
                        <div class="">
                            <input [formGroup]="addProvinceForm" formControlName="name" type="text" value="" class="form-control" placeholder="Inserisci il nome della provincia" [ngClass]="{
              'form-control': true,
              'is-invalid': submitted && f.name.errors
            }" />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.name.errors.required">
                                    Nome &egrave; obbligatorio
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-xl-6 col-lg-6">
                        <h5><label for="code" class="mandatory">Codice:</label></h5>
                        <div class="">
                            <input [formGroup]="addProvinceForm" formControlName="code" type="text" value="" maxlength="3" class="form-control" placeholder="Inserisci il codice della provincia" [ngClass]="{
              'form-control': true,
              'is-invalid': submitted && f.code.errors
            }" />
                            <div *ngIf="submitted && f.code.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.code.errors.required">
                                    Codice &egrave; obbligatorio
                                </div>
                                <div *ngIf="f.code.errors.minlength">
                                    Il codice deve essere di 3 caratteri
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- *** -->
                    <div class="form-group col-xl-3 col-lg-3">
                        <h5><label for="name" class="mandatory">Acronimo:</label></h5>
                        <div class="">
                            <input [formGroup]="addProvinceForm" formControlName="acronym" type="text" value="" maxlength="2" class="form-control" placeholder="Inserisci l'acronimo" [ngClass]="{
              'form-control': true,
              'is-invalid': submitted && f.acronym.errors
            }" />
                        </div>
                        <div *ngIf="submitted && f.acronym.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.acronym.errors.required">
                                Acronimo &egrave; obbligatorio
                            </div>
                            <div *ngIf="f.acronym.errors.minlength">
                                L'acronimo deve essere di 2 caratteri
                            </div>

                        </div>
                    </div>
                      <ng-template #cittaMetropolitana>
                        <span>Città Metropolitana</span>
                      </ng-template>


                    <div class="form-group col-xl-3 col-lg-3">
                      <h5 >
                        <label for="isCittametropolitana" class="mandatory">

                            <a type="button" class="help"
                            [popover]="cittaMetropolitana"
                            placement="top"
                            triggers="mouseenter:mouseleave"><i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                            </i>
                            </a>&nbsp;CM:

                        </label>
                      </h5>

                        <div class="">
                            <ng-select formControlName="isCittametropolitana" [items]="yesNo" bindValue="k" placeholder="Seleziona" bindLabel="v" selectOnTab="true" [searchable]="true" [clearable]="false" notFoundText="Nessun risultato trovato..." [ngClass]="{
              'is-invalid': submitted && f.isCittametropolitana.errors
            }">
                            </ng-select>
                            <div *ngIf="submitted && f.isCittametropolitana.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.isCittametropolitana.errors.required">
                                    CM &egrave; obbligatoria
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-xl-6 col-lg-6">
                        <label for="istat"><h5 class="mandatory">Regione:</h5></label>
                        <div class="">
                            <ng-select formControlName="region" [items]="regionsAdd" bindValue="id" placeholder="Seleziona" bindLabel="value" selectOnTab="true" [searchable]="true" [clearable]="false" (change)="onChangeNewRegionId($event)" notFoundText="Nessun risultato trovato..."
                                [ngClass]="{
              'is-invalid': submitted && f.region.errors
            }">
                            </ng-select>
                            <div *ngIf="submitted && f.region.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.region.errors.required">
                                    Regione &egrave; obbligatoria
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- *** -->
                    <div class="form-group col-xl-6 col-lg-6">
                        <h5><label class="">Data Inizio Validità:</label></h5>

                        <div class="input-group">
                            <input formControlName="fromDate" type="text" class="form-control" bsDatepicker #dp="bsDatepicker" [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true,
                containerClass: 'theme-dark-blue',
                isAnimated: true
              }" [ngClass]="{
                'is-invalid': submitted && f.fromDate.errors
              }" />
                            <button type="button" class="btn btn-primary mt-0" (click)="dp.show()" [attr.aria-expanded]="dp.isOpen">
              <i class="fas fa-calendar-alt"></i>
            </button>
                        </div>
                    </div>
                    <div class="form-group col-xl-6 col-lg-6">
                        <h5><label class="">Data Fine Validità:</label></h5>

                        <div class="input-group">
                            <input formControlName="toDate" type="text" class="form-control" bsDatepicker #dp2="bsDatepicker" [bsConfig]="{
                dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true,
                containerClass: 'theme-dark-blue',
                isAnimated: true
              }" [ngClass]="{
                'is-invalid': submitted && f.toDate.errors
              }" />
                            <button type="button" class="btn btn-primary mt-0" (click)="dp2.show()" [attr.aria-expanded]="dp.isOpen">
              <i class="fas fa-calendar-alt"></i>
            </button>
                        </div>
                        <div *ngIf="submitted && f.toDate.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.toDate.errors.required">
                                Data Fine Validità &egrave; obbligatoria
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text-center my-3">
                    <button class="btn btn-primary" type="submit">
          INSERISCI NUOVA PROVINCIA / CM
          &nbsp;<i aria-hidden="true" class="fa fa-plus"></i>
        </button>
                </div>
            </form>
        </accordion-group>
    </accordion>

    <div class="mt-4">
        <h4 class="titoletti">Lista Province e CM presenti in GLP</h4>
        <div class="form-group clearfix">
            <label for="provinces">Regione:<span aria-hidden="true" class="text-danger font-weight-bold">*</span></label>
            <ng-select [items]="regions" bindValue="id" placeholder="Seleziona" bindLabel="value" selectOnTab="true" [searchable]="true" [clearable]="false" (change)="onChangeRegion($event)" notFoundText="Nessun risultato trovato...">
            </ng-select>
        </div>
        <div [hidden]="!visible">
            <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsNgx">
                <ng-template #btnEditTemplate let-row="row" let-value="value">
                    <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="editHandler(row)" data-toggle="tooltip" data-placement="top" title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer" ></i></a>
                </ng-template>
            </ngx-datatable-wrapper>
        </div>
    </div>
</div>
