export class JwtUser {
  fiscalCode: string;
  user_name: string;
  scope: string[];
  exp: number;
  authorities: string[];
  jti: string;
  client_id: string;
  fP: boolean;
  pswExp: boolean;
  isLdapUser:boolean;
}
