import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { JwtUser } from "src/app/models/jwt-user";
import { UserService } from "src/app/services/user.service";
import { AccountInfo } from "src/app/models/accountInfo";
import { ActivatedRoute } from "@angular/router";
import * as FileSaver from "file-saver";
import { AlertService } from "src/app/services/alert.service";
import { AppStrings } from "src/app/resources/strings";
import { RolesDescriptions,Roles } from "src/app/helpers/constants";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastService } from "src/app/services/toast.service";
import * as moment from "moment";
import {
  AreeTematicheService,
  SoggettoGiuridicoService,
} from "src/app/modules/inframob/services";
import { AreaTematica } from "src/app/modules/inframob/models";
import { Subscription } from "rxjs";
import { MenuService } from "src/app/services/menu.service";
import { Menu } from "src/app/models/menu";
import { is } from "@amcharts/amcharts4/core";
@Component({
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.css"],
})
export class AccountComponent implements OnInit, OnDestroy {
  myGroup: FormGroup;
  currentUser: JwtUser;
  userInfo: AccountInfo;
  submitted: boolean = false;
  saving: boolean = false;
  codes: any[] = []; //used for the schools list
  noResultText: string = `Nessun elemento trovato`;
  roleDescription: string = "";
  selectedAreaInvalid: boolean = false;
  canSeeAreeAppartenenza: boolean = false;


  userTypes = [
    {
      value: RolesDescriptions.role_admin,
      key: Roles.role_admin,
    },
    {
      value: RolesDescriptions.role_directional_group,
      key: Roles.role_directional_group,
    },
    {
      value: RolesDescriptions.role_area_staff,
      key: Roles.role_area_staff,
    },
    {
      value: RolesDescriptions.role_external,
      key: Roles.role_external,
    },
    {
      value: RolesDescriptions.role_viewer,
      key: Roles.role_viewer,
    },
  ];

  isEsterno() {
    return Roles.role_external == this.getRoleTypeCode();
  }

  listaEnti = [];
  getListaEnti() {
    this.soggettoGiuridicoService
      .getAll()
      .subscribe((x) => (this.listaEnti = x));
  }

  areeTematiche: AreaTematica[];
  completedAreeTematiche: AreaTematica[];

  subscription: Subscription;
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastService,
    private areeTematicheService: AreeTematicheService,
    private soggettoGiuridicoService: SoggettoGiuridicoService,
    private menuService: MenuService
  ) {}

  menus: Menu[];

  ngOnInit() {
    window.scroll(0, 0);
    this.scrollTop();
    this.myGroup = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      selectedArea: [],
      lastname: [null, Validators.required],
      firstname: [null, Validators.required],
      userType: [null, Validators.required],
      enteAppartenenza: [null],
      menu: [null],
    });
    this.currentUser = new JwtUser();
    this.userInfo = new AccountInfo();
    this.loadUserData();
    if (!this.isManager()) this.myGroup.controls.userType.clearValidators();
    this.getListaEnti();

    this.menuService.getAllMenu().subscribe((menu) => {
      this.menus = menu;
    });
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

  currentUserAreeTematicheIds = [];
  getAreeTematiche() {
    this.areeTematicheService.getAll().subscribe((x) => {
      x.sort((a, b) => {
        if (a.descrizione > b.descrizione) {
          return 1;
        }
        if (b.descrizione > a.descrizione) {
          return -1;
        }
        return 0;
      });
      this.currentUserAreeTematicheIds = [];
      this.userInfo.areeTematiche.forEach((x) => {
        this.currentUserAreeTematicheIds.push(x.id);
      });
      this.areeTematiche = x.filter((k) => {
        return (
          this.currentUserAreeTematicheIds.indexOf(k.id) <= -1 && !k.disabled
        );
      });
      this.completedAreeTematiche = x;
    });
  }

  loadUserData() {
    const username: string = this.route.snapshot.paramMap.get("user");

    if (!username) {
      this.subscription = this.authService.currentUser.subscribe((u) => {
        this.currentUser = u;
        this.userService.getInfo(null).subscribe((u) => {
          if (u == null) {
            this.toastr.success(AppStrings.ALERT_USER_FIRST_ACCESS, null, {
              disableTimeOut: true,
            });
            return;
          }
          this.userInfo = { ...u };
          //this.userInfo.birthDay = moment(new Date(this.userInfo.birthDate)).format('DD/MM/YYYY');
          this.myGroup.controls.email.setValue(this.userInfo.email);
          this.myGroup.controls.lastname.setValue(this.userInfo.lastName);
          this.myGroup.controls.firstname.setValue(this.userInfo.firstName);
          this.myGroup.controls.userType.setValue(this.getRoleTypeCode());
          this.myGroup.controls.enteAppartenenza.setValue(
            this.userInfo["soggettoGiuridicoId"]
          );

          if (this.isEsterno()) {
            this.myGroup.controls.userType.disable();
            this.f.enteAppartenenza.setValidators([Validators.required]);
            this.f.enteAppartenenza.updateValueAndValidity();
          } else {
            this.myGroup.controls.userType.enable();
            this.f.enteAppartenenza.clearValidators();
            this.f.enteAppartenenza.updateValueAndValidity();
          }

          this.roleDescription = this.getRoleTypeDescription();
          if (!this.authService.isEnabled())
            this.toastr.success(AppStrings.ALERT_WAITING_SUC, null, {
              disableTimeOut: true,
            });
          this.getAreeTematiche();
          this.canSeeAreeAppartenenza = this.canSeeAreeAppartenenzaFunct();
        });
      });
    } else {
      this.userService.getInfo(username).subscribe((u) => {
        this.userInfo = { ...u };
        //this.userInfo.birthDay = moment(new Date(this.userInfo.birthDate)).format('DD/MM/YYYY');

        const menuId = this.userInfo.menuId;

        if (menuId !== null) {
          this.myGroup.controls.menu.setValue(menuId);
        }

        this.roleDescription = this.getRoleTypeDescription();
        this.myGroup.controls.userType.setValue(this.getRoleTypeCode());
        this.myGroup.controls.email.setValue(this.userInfo.email);
        this.myGroup.controls.lastname.setValue(this.userInfo.lastName);
        this.myGroup.controls.firstname.setValue(this.userInfo.firstName);
        this.myGroup.controls.enteAppartenenza.setValue(
          this.userInfo["soggettoGiuridicoId"]
        );
        this.getAreeTematiche();
        this.canSeeAreeAppartenenza = this.canSeeAreeAppartenenzaFunct();
        if (this.isEsterno()) {
          this.myGroup.controls.userType.disable();
          this.f.enteAppartenenza.setValidators([Validators.required]);
          this.f.enteAppartenenza.updateValueAndValidity();
        } else {
          this.myGroup.controls.userType.enable();
          this.f.enteAppartenenza.clearValidators();
          this.f.enteAppartenenza.updateValueAndValidity();
        }
      });
    }
  }

  downloadDocument(fileName: string) {
    this.userService
      .getDocument(this.userInfo.username, fileName)
      .subscribe((res) => {
        if (fileName === "id") {
          let ext = this.userInfo.idFilePath.split(".").pop();
          FileSaver.saveAs(
            res.body,
            `${this.userInfo.username}.cartaIdentita.${ext}`
          );
        }
        if (fileName === "fc") {
          let ext = this.userInfo.fcFilePath.split(".").pop();
          FileSaver.saveAs(
            res.body,
            `${this.userInfo.username}.codiceFiscale.${ext}`
          );
        }
      });
  }

  deleteMyAccount() {
    this.userService.sendDeleteLink().subscribe((res) => {
      this.toastr.success(
        "Il link per effettuare l'eliminazione della sottoscrizione è stato inviato alla sua casella di posta.",
        null,
        {
          disableTimeOut: true,
        }
      );
      this.scrollTop();
    });
  }

  editAccount() {
    const menuIdSelected = this.myGroup.controls.menu.value;
    const selectedMenu = menuIdSelected
      ? this.menus.find((menuId) => menuId.id === menuIdSelected)
      : null;

    this.submitted = true;

    this.selectedAreaInvalid =
      this.canSeeAreeAppartenenzaFunct() &&
      (!this.userInfo.areeTematiche || this.userInfo.areeTematiche.length <= 0);
    // stop here if form is invalid
    if (this.myGroup.invalid || this.selectedAreaInvalid) {
      return;
    }

    this.userInfo.menuId = this.isAdmin()
      ? selectedMenu?.id
      : this.userInfo.menuId;

    this.userInfo.email = this.myGroup.controls.email.value;
    this.userInfo.lastName = this.myGroup.controls.lastname.value;
    this.userInfo.firstName = this.myGroup.controls.firstname.value;
    this.userInfo["soggettoGiuridicoId"] =
      this.myGroup.controls.enteAppartenenza.value;
    this.userInfo["type"] = this.myGroup.controls.userType.value;
    this.saving = true;
    if (!this.canSeeAreeAppartenenzaFunct()) this.userInfo.areeTematiche = [];

    let areeIsChanged = false;
    if (this.currentUser.user_name == this.userInfo.username) {
      this.currentUserAreeTematicheIds.forEach(
        (o) =>
          (areeIsChanged =
            areeIsChanged ||
            !this.userInfo.areeTematiche.find((d) => d.id == o))
      );
      this.userInfo.areeTematiche.forEach(
        (o) =>
          (areeIsChanged =
            areeIsChanged ||
            !this.currentUserAreeTematicheIds.find((d) => d == o.id))
      );
    }
    this.userService.editAccount(this.userInfo).subscribe((_) => {
      this.toastr.success(`Salvataggio avvenuto con successo`);
      this.saving = false;
      this.currentUserAreeTematicheIds = [];
      this.userInfo.areeTematiche.forEach((x) => {
        this.currentUserAreeTematicheIds.push(x.id);
      });
      if (areeIsChanged) {
        this.toastr.success(
          `La tua utenza è stata disconnessa in quanto sono stati modificati i privilegi di accesso e necessita di una nuova abilitazione`
        );
        this.authService.logout();
      }
    });
  }

  scrollTop() {
    window.scroll(0, 0);
  }

  isAdmin() {
    return this.authService.isAdmin();
  }

  hasRoles() {
    return this.authService.hasRoles();
  }

  getRoleTypeDescription() {
    let role = this.userInfo.authorities.find(
      (x) =>
        x.type == Roles.role_area_staff ||
        x.type == Roles.role_admin ||
        x.type == Roles.role_directional_group ||
        x.type == Roles.role_external ||
        x.type == Roles.role_viewer
    );
    return RolesDescriptions[role.type.toLowerCase()];
  }

  getRoleTypeCode() {
    let role = this.userInfo.authorities.find(
      (x) =>
        x.type == Roles.role_area_staff ||
        x.type == Roles.role_admin ||
        x.type == Roles.role_directional_group ||
        x.type == Roles.role_external ||
        x.type == Roles.role_viewer
    );
    return role.code;
  }

  isAreaStaff() {
    if (!this.userInfo || !this.userInfo.authorities) return false;
    let role = this.userInfo.authorities.find(
      (x) => x.type == Roles.role_area_staff
    );
    return role != null;
  }

  isExternal() {
    if (!this.userInfo || !this.userInfo.authorities) return false;
    let role = this.userInfo.authorities.find(
      (x) => x.type == Roles.role_external
    );
    return role != null;
  }

  isDirectionalGroup() {
    if (!this.userInfo || !this.userInfo.authorities) return false;
    let role = this.userInfo.authorities.find(
      (x) => x.type == Roles.role_directional_group
    );
    return role != null;
  }

  isViewer() {
    if (!this.userInfo || !this.userInfo.authorities) return false;
    let role = this.userInfo.authorities.find(
      (x) => x.type == Roles.role_viewer
    );
    return role != null;
  }

  get f() {
    return this.myGroup.controls;
  }

  setArea() {
    let selectedAreaId = this.f.selectedArea.value;
    if (!selectedAreaId) return;
    const selected: AreaTematica = this.areeTematiche.find(
      (s) => s.id === selectedAreaId
    );
    if (!this.userInfo.areeTematiche.find((x) => x.id == selected.id)) {
      this.userInfo.areeTematiche.push(selected);
      this.areeTematiche = this.areeTematiche.filter(
        (item) => item.id !== selected.id
      );
      this.f.selectedArea.patchValue("");
    }
    selectedAreaId = 0;
  }

  deleteArea(area: AreaTematica) {
    this.selectedAreaInvalid = false;
    this.userInfo.areeTematiche = this.userInfo.areeTematiche.filter(
      (item) => item.id !== area.id
    );
    //this.areeTematiche.push(area);
    //let areeTematicheTmp = Object.assign([], this.areeTematiche);
    let areeTematicheTmp = [...this.areeTematiche];
    areeTematicheTmp.push(area);
    areeTematicheTmp.sort((a, b) => {
      if (a.descrizione > b.descrizione) {
        return 1;
      }
      if (b.descrizione > a.descrizione) {
        return -1;
      }
      return 0;
    });
    this.areeTematiche = areeTematicheTmp;
  }

  isManager() {
    return this.authService.isManager();
  }

  canSeeAreeAppartenenzaFunct() {
    /*return this.isExternal() 
    || this.isAreaStaff() 
    || this.isDirectionalGroup() 
    ||(this.isManager() 
    &&*/
    return (
      this.myGroup.controls.userType.value == Roles.role_external ||
      this.myGroup.controls.userType.value ==
        Roles.role_area_staff ||
      this.myGroup.controls.userType.value ==
        Roles.role_directional_group
    );
  }

  onChangeUserType($event) {
    console.log(`${this.myGroup.controls.userType.value}`);
    this.canSeeAreeAppartenenza = this.canSeeAreeAppartenenzaFunct();
  }
}
