<div class="p-5 container-xl text-center" [hidden]="loadDataCounter<=0">
    <app-loading message="Caricamento in corso..."></app-loading>
</div>
<div class="container-xl" [hidden]="loadDataCounter>0">

    <div class="position-sticky sticky-top   pt-4 pb-4 elemento-aggiorna mb-5 shadow-custom" [ngClass]="{'sticky-top-custom-modal':isInModal ||( interventoData && !interventoData.isMain),'sticky-top-custom':!isInModal && (!interventoData ||( interventoData && interventoData.isMain)),'sticky-top-custom-sal': tabsOnOneRow }">
        <div class=" row  justify-content-center ">
            <div class=" col-lg-4 col-md-6 text-center" [hidden]="readOnlyForm || isValidated">
                <button [disabled]="loading" (click)="openAddModal()" class="btn btn-primary btn-block" type="button">
          <img *ngIf="loading " class="pl-3 float-right "
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          Aggiungi nuova annualità&nbsp;<i class="fas fa-plus"></i>
        </button>
            </div>

            <div class="col-lg-3 col-md-6 text-center" [hidden]=" readOnlyForm || !interventoData || interventoData.numSubInterventi>0 ">
                <button [disabled]="loading" (click)="openSpostaAnnualitaModal()" class="btn btn-info btn-block font-weight-bold" type="button">
          <img *ngIf="loading" class="pl-3 float-right "
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          Sposta annualità&nbsp;<i class="fas fa-arrows-alt-h"></i>
        </button>
            </div>
            <div class="col-lg-4 col-md-6 text-center" *ngIf="gotoBtnOptions && gotoBtnOptions.show">
                <button (click)="gotoBackBtnClick()" [disabled]="loading" class="btn btn-secondary btn-block font-weight-bold" type="button">
          {{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}
          &nbsp;<i class="fas fa-times" *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i>
        </button>
            </div>
        </div>

    </div>
    <!--***-->


    <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptions">
        <ng-template #btnEditTemplate let-row="row" let-value="value">
            <a [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top" title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
        </ng-template>
        <ng-template #btnDeleteTemplate let-row="row" let-value="value">
            <a [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
        </ng-template>

        <ng-template #accertatoFieldTemplateOld let-row="row" let-value="value">
            {{printAccertatoValue(row,value)}}
        </ng-template>
        <ng-template #accertatoFieldTemplate let-row="row" let-value="value" let-index="index">
            <table class="w-100 table table-borderless" id="detail_importi_{{row.id}}">
                <tr class="collapse trdetail" *ngFor="let val of (row.accertamenti)">
                    <ng-container *ngIf="row.accertatoImpegnato">
                        <td class="pt-0 pb-1 pl-0 pr-1">{{val.numero}}:</td>
                        <td class="pt-0 pb-1 text-0083e0 pl-0">{{printValueAsCurrency(val.valore)}}</td>
                    </ng-container>
                    <tr>
                        <td class="pt-0 pb-1 pl-0 pr-1">
                            <a class="show-detail-anchor collapse show" data-toggle="collapse" attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i
                class="fas fa-list-ul pointer"></i></a>
                            <!--<a class="trdetail show-detail-anchor collapse show" data-toggle="collapse" attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i class="fas fa-edit pointer"></i></a>-->
                        </td>
                        <td class="pt-0 pb-1 pl-0">{{printAccertatoValue(row,value)}}</td>
                    </tr>

            </table>
        </ng-template>


    </ngx-datatable-wrapper>
</div>




<ng-template #templateEditAdd>
    <div class="modal-header  text-center">
        <h4 class="modal-title pull-left">{{modalTitle}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
            <input type="hidden" [formGroup]="editForm" formControlName="id" />

            <div class="col-lg-12 mt-3">
                <div class="form-group">
                    <h5><label for="anno" class="mandatory">Anno:</label></h5>
                    <div [ngClass]="{ 'is-invalid': submitted && f.anno.errors }" *ngIf="interventoId && interventoData && !interventoData.isMain">
                        <ng-select [formGroup]="editForm" formControlName="anno" [items]="anniList" bindValue="anno" bindLabel="anno" placeholder="Seleziona un anno" (change)="annoListChanged($event)">
                        </ng-select>
                    </div>
                    <input *ngIf="!(interventoId && interventoData && !interventoData.isMain)" [formGroup]="editForm" formControlName="anno" maxlength="4" type="text" class="form-control" placeholder="Inserisci l'anno" [ngClass]="{
                            'is-invalid': submitted && editForm.controls.anno.errors
                            }" [imask]="maskYear" [unmask]="true" />
                    <div *ngIf="submitted && editForm.controls.anno.errors" class="invalidFieldMessage" style="display: block;">
                        <div *ngIf="editForm.controls.anno.errors" class="invalid-feedback" style="display: block;">
                            Campo obbligatorio
                        </div>
                    </div>
                </div>
                <div class="form-group " *ngIf="interventoId && interventoData && !interventoData.isMain">
                    <div class="">
                        <h5><label for="disponibilita" class="">Capacità residua progetto{{isGr34() || isAdmin()?' / fabbisogno':''}}:</label></h5>
                        <input [formGroup]="editForm" formControlName="disponibilita" maxlength="255" type="text" class="form-control " placeholder="Inserisci la capacità residua" [ngClass]="{ 'is-invalid': submitted && editForm.controls.disponibilita.errors
                      } " [imask]="mask" [unmask]="true" />
                        <div *ngIf="submitted && editForm.controls.disponibilita.errors " class="invalid-feedback " style="display: block; ">
                            <div *ngIf="editForm.controls.disponibilita.errors.required ">
                                Campo obbligatorio
                            </div>
                        </div>

                    </div>
                </div>

                <div class="form-group ">
                    <div class="">
                        <h5> <label for="importo" class="mandatory">Importo:</label></h5>
                        <input [formGroup]="editForm" formControlName="importo" maxlength="255" type="text" class="form-control" placeholder="Inserisci l'importo" [ngClass]="{
                            'is-invalid': submitted && editForm.controls.importo.errors
                            }" [imask]="mask" [unmask]="true" />
                        <div *ngIf="submitted && editForm.controls.importo.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="editForm.controls.importo.errors.required">
                                Campo obbligatorio
                            </div>
                        </div>

                    </div>
                </div>


                <div class="form-group" *ngIf="!(interventoData && interventoData.isMain && interventoData.numSubInterventi>0)">
                    <h5><label for="" class="">{{interventoId?'Impegnato:':'Accertato:'}}</label></h5>
                    <div class="custom-control custom-switch">

                        <input type="checkbox" class="custom-control-input" id="customSwitchAnnualita1" [formGroup]="editForm" formControlName="accertatoImpegnato" (change)="accertatoImpegnatoOnChange($event)" />
                        <label class="custom-control-label" for="customSwitchAnnualita1"></label>
                    </div>
                </div>


                <div class=" mt-4" *ngIf="editForm.controls.accertatoImpegnato.value">
                    <div class="row justify-content-between">

                        <div class="col-lg-6 col-sm-6 col-6 mt-3">
                            <h5 for="" class="">{{interventoId?'Impegni':'Accertamenti'}}</h5>
                        </div>
                        <div class="col-lg-2 col-sm-2 col-3 text-center mt-3">
                            <a class="bottone-inserisci" (click)="openModalAggiungiAccertatoImpegnato($event);">
                              <i class="fas fa-plus"></i></a>
                        </div>
                    </div>
                </div>

                <div class=" mt-3 table-responsive" *ngIf="editForm.controls.accertatoImpegnato.value">
                    <form [formGroup]="accertatoImpegnatoFg">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="px-1" scope="col">numero</th>
                                    <th class="px-1" scope="col">valore</th>
                                    <th class="px-1 casella-icona-tx" scope="col" >elimina</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="accertatoImpegnatoList && accertatoImpegnatoList.length && accertatoImpegnatoList.length>0">
                                    <tr *ngFor="let item of accertatoImpegnatoList">
                                        <td class="px-1">
                                            <input type="hidden" formControlName="accertato_id_{{item.idHtml}}" />
                                            <input type="text" formControlName="accertato_number_{{item.idHtml}}" />
                                            <div *ngIf="submitted && accertatoImpegnatoFg.controls['accertato_number_' + item.idHtml].errors" class="invalid-feedback" style="display: block;">
                                                <div *ngIf="accertatoImpegnatoFg.controls['accertato_number_' + item.idHtml].errors.required">
                                                    Campo obbligatorio
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-1" > 
                                            <input type="text" formControlName="accertato_value_{{item.idHtml}}" [imask]="mask" [unmask]="true" />
                                        </td>
                                        <td class="px-1"><a (click)="removeAccertatoImpegnato(item)" data-toggle="tooltip" data-placement="top" title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i> </a>
                                        </td>
                                    </tr>

                                </ng-container>
                                <tr *ngIf="!(accertatoImpegnatoList &&  accertatoImpegnatoList.length && accertatoImpegnatoList.length>0)">
                                    <td colspan="4">Nessun {{interventoId?'Impegno':'Accertamento'}} selezionato</td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                    <div *ngIf="submitted && (!accertatoImpegnatoList || accertatoImpegnatoList.length<=0)" class="invalidFieldMessage">
                        <div class="invalid-feedback" style="display: block;">
                            Selezionare almeno un {{interventoId?'Impegno':'Accertamento'}}
                        </div>
                    </div>
                </div>






                <button [disabled]="loading" class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0 ">
          <img *ngIf="loading " class="pl-3 float-right "
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          {{modalButtonLabel}}
        </button>


            </div>
        </form>
    </div>
</ng-template>