<div class=" " [ngClass]="
    router.url.includes('dashboard-finanziaria') ||
    router.url.includes('dashboard-fasi') ||
    router.url.includes('dashboard-stato-finanziamento') ||
    router.url.includes('dashboard-stato-intervento') ||
    router.url.includes('dashboard-interventi') ||
    router.url.includes('dashboard-pnrr') ||
    router.url.includes('dashboard-pkmg') ||
    router.url.includes('dashboard-non-disegnabile') ||
    router.url.includes('dashboard-entrata-uscita') ||
    router.url.includes('dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr')||
    router.url.includes('dashboard-sisma') ||
    router.url.includes('cruscotto-note-atti') 
      ? 'container-fluid bg-celeste main'
      : 'container bg-white'
  ">
    <router-outlet></router-outlet>
</div>
