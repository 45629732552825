import { Progetto } from './progetto';
//import { TipoStatoProgetto } from './tipo-stato-progetto';

export class StatoAvanzamentoProgetto{
    id:number;
    data:Date;
    progetto:Progetto;
    //tipoStatoProgetto:TipoStatoProgetto;
    tipoStatoProgettoId:number;//only view
    tipoStatoProgettoDescrizione:string;//only view
    progettoId:number;//only view
}