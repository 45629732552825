import { Component, Input, Output, EventEmitter,  ElementRef,OnInit } from '@angular/core';
import {  BsModalRef } from 'ngx-bootstrap/modal';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UserService  } from '../../../services';
import { ToastService } from 'src/app/services/toast.service';
defineLocale('it', itLocale);


@Component(
    {templateUrl: 'account-list-send-email-modal.html'
    ,styleUrls:['account-list-send-email-modal.css']
        ,selector: 'account-list-send-email-modal'
    }    
)
export class SendEmailUserModalComponent implements OnInit  {
    @Input() currentObj={subject:'',body:'',to:'',from:'',userId:0,username:'',sender:''};
    @Output() onSubmitOk = new EventEmitter();

    registerForm: FormGroup;
    loading = false;
    submitted = false;    
    
    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: FormBuilder   ,
        private el: ElementRef     ,
        private localeService: BsLocaleService  ,
        private userService: UserService  ,
        private toastr:ToastService    
    ) {
        this.localeService.use('it');        
    }

    ngOnInit() 
    {
        console.log(`into SendEmailUserModalComponent.ngOnInit: currentObj = ${JSON.stringify(this.currentObj)}`);                       
        this.createForm();
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }
    
    onSubmit() {
        this.submitted = true;
      // stop here if form is invalid
      if (this.registerForm.invalid) {
          const invalidElements = this.el.nativeElement.querySelectorAll('.is-invalid');
          if (invalidElements.length > 0) {
              invalidElements[0].focus();                
          }
          return;            
      }
      this.currentObj.subject = this.f.subject.value;
      this.currentObj.body = this.f.body.value;
      this.registerForm.reset();
      this.bsModalRef.hide();
      this.sendEmail();
    }


    sendEmail(){        
        this.userService.sendMessageToTheUserByEmail(this.currentObj.userId
            ,{subject:this.currentObj.subject,message:this.currentObj.body}
            ).subscribe(
            ris => {
                this.toastr.success("Messaggio inviato con successo");
                if (ris)this.onSubmitOk.emit(this.currentObj);
                else this.onSubmitOk.emit(null);
            }            
        );
    }

    createForm(){
        this.registerForm = this.formBuilder.group({
            subject: [null, [Validators.required,Validators.maxLength(255)]]
            , body: [null,[Validators.required]]            
        });
    }
}
