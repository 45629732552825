/* Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { PnrrService } from "../../services";
import { CurrencyPipe } from "@angular/common";
import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";
import { DashboardPnrrNonDisegnabileService } from "../dashboard-pnrr-non-disegnabile/dashboard-pnrr-non-disegnabile.service";

@Component({
  selector: 'app-chart-pnrr-linee',
  templateUrl: './chart-pnrr-linee.component.html',
  styleUrls: ['./chart-pnrr-linee.component.css']
})
export class ChartPnrrLineeComponent implements OnInit {

  importsPerMission: any;

  renderingStatus = false;

  chart: any;

  @Input() lineeData: any;


  @Input() set chartData(mapFeatures){
    if(mapFeatures){
      const features = mapFeatures;
      const groupedByLinea = this.groupBy(features, "codiceLinea");
      const linee = mapFeatures.linee;

      let lineeData = [];

        for(let codice in groupedByLinea)
        {
            let linea = groupedByLinea[codice][0];
            const totaleQuantita = linea.numElementi;
            const totaleImporti = linea.importo;
            lineeData.push({
                category: linea.codiceLinea,
                value1: totaleImporti,
                value2: totaleQuantita,
                value1Formatted: this.printValueAsCurrency(totaleImporti),
                value2Formatted: totaleQuantita,
                categoryExtended: linea.descrizioneLinea
              })
        }


      lineeData.sort( (a,b) => a.category > b.category ? -1 : 1);

      this.grafico(lineeData);
    }
  };

  constructor(private service: DashboardPnrrNonDisegnabileService) { }

  ngOnInit(): void {
    am4core.useTheme(am4themes_animated);
  }

/* Chart code */
// Themes begin

// Themes end



  grafico(data){
    if(!this.renderingStatus){
      this.renderingStatus = true;
      this.service.doRendering(true);
    }
    let interfaceColors = new am4core.InterfaceColorSet();
    if (this.chart)this.chart.dispose();
    this.chart = am4core.create("chartGamma", am4charts.XYChart);

    this.chart.data = data;
    // the following line makes value axes to be arranged vertically.
    this.chart.topAxesContainer.layout = "horizontal";
    this.chart.topAxesContainer.reverseOrder = true;

     // Enable export
     this.aggiungiLogo(this.chart);

     this.chart.exporting.menu = new am4core.ExportMenu();

     this.chart.exporting.menu.align = "left";
     this.chart.exporting.menu.verticalAlign = "top";
     this.chart.exporting.menu.background = "#ff0000";
     this.chart.exporting.menu.defaultStyles = true;
     //this.chart.exporting.filePrefix = title;
     this.chart.exporting.menu.items = [
         {
             menu: [
                 { type: "jpg", label: "JPG" },
                 { type: "png", label: "PNG" },
                 { type: "csv", label: "CSV" },
             ],
         },
     ];
     this.chart.exporting.menu.items[0].icon = "assets/img/export.png";


    const missionsColors = [

      "#fdf2cd",//M6
      "#e2d7ec",//M5
      "#f2c799",//M4
      "#fb9999",//M3
      "#acd86a",//M2
      "#c6dbf0",//M1


    ]

    let totaleRisorse = 0;
    let totaleMissioni = 0;
    data.forEach(item => {
      totaleRisorse += item.value1;
      totaleMissioni += item.value2;
    })


    let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.stroke = interfaceColors.getFor("background");
    categoryAxis.renderer.grid.template.strokeOpacity = 1;
    categoryAxis.renderer.grid.template.location = 1;
    categoryAxis.renderer.minGridDistance = 15;
    categoryAxis.fontSize = 12;
    let label = categoryAxis.renderer.labels.template;
    label.tooltipText = "{category} - {categoryExtended}";

    //Asse X1
    let valueAxis1 = this.chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis1.tooltip.disabled = true;
    valueAxis1.renderer.baseGrid.disabled = true;
    valueAxis1.marginRight = 30;
    valueAxis1.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
    valueAxis1.renderer.gridContainer.background.fillOpacity = 0.05;
    valueAxis1.renderer.grid.template.stroke = interfaceColors.getFor("background");
    valueAxis1.renderer.grid.template.strokeOpacity = 1;
    valueAxis1.title.text = `Risorse: ${this.printValueAsCurrency(totaleRisorse)}`;
    valueAxis1.title.fontSize =18;
    valueAxis1.renderer.opposite = true;
    valueAxis1.fontSize = 12;
    valueAxis1.language.locale = am4lang_it_IT;
    valueAxis1.numberFormatter.numberFormat = "# a '€'";
    valueAxis1.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "Mln" },
      { number: 1e9, suffix: "Mrd" },
    ];


    let series1 = this.chart.series.push(new am4charts.ColumnSeries());
    series1.dataFields.categoryY = "category";
    series1.dataFields.valueX = "value1";
    series1.xAxis = valueAxis1;
    series1.name = "Series 1";
    series1.clustered = false;
    series1.tooltip.autoTextColor = false;
    series1.tooltip.label.fill = am4core.color("#000000");
    series1.tooltipText = "{value1Formatted} [/]";

    series1.columns.template.adapter.add("fill", function(fill, target) {
      switch(target.dataItem.categoryY.substring(0,2)){
        case 'M1':
          return missionsColors[5]
        break;
        case 'M2':
          return missionsColors[4]
        break;
        case 'M3':
          return missionsColors[3]
        break;
        case 'M4':
          return missionsColors[2]
        break;
        case 'M5':
          return missionsColors[1]
        break;
        case 'M6':
          return missionsColors[0]
        break;
      }
    });
    series1.columns.template.adapter.add("stroke", function(stroke, target) {
      switch(target.dataItem.categoryY.substring(0,2)){
        case 'M1':
          return missionsColors[5]
        break;
        case 'M2':
          return missionsColors[4]
        break;
        case 'M3':
          return missionsColors[3]
        break;
        case 'M4':
          return missionsColors[2]
        break;
        case 'M5':
          return missionsColors[1]
        break;
        case 'M6':
          return missionsColors[0]
        break;
      }
    });

    //Asse X2
    let valueAxis2 = this.chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis2.tooltip.disabled = true;
    valueAxis2.renderer.baseGrid.disabled = true;
    valueAxis2.marginRight = 30;
    valueAxis2.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
    valueAxis2.renderer.gridContainer.background.fillOpacity = 0.05;
    valueAxis2.renderer.grid.template.stroke = interfaceColors.getFor("background");
    valueAxis2.renderer.grid.template.strokeOpacity = 1;
    valueAxis2.title.text = `Interventi: ${totaleMissioni}`;
    valueAxis2.calculateTotals = true;
    valueAxis2.title.fontSize =18;
    valueAxis2.renderer.opposite = true;
    valueAxis2.fontSize = 12;
    valueAxis2.language.locale = am4lang_it_IT;
    valueAxis2.numberFormatter.numberFormat = "# a";
    valueAxis2.numberFormatter.bigNumberPrefixes = [
       { number: 1e3, suffix: "K" },
       { number: 1e6, suffix: "Mln" },
       { number: 1e9, suffix: "Mrd" },
     ];


    let series2 = this.chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.categoryY = "category";
    series2.dataFields.valueX = "value2";
    series2.xAxis = valueAxis2;
    series2.name = "Series 2";
    series2.clustered = false;
    series2.tooltip.autoTextColor = false;
    series2.tooltip.label.fill = am4core.color("#000000");
    series2.tooltipText = "{value2Formatted.formatNumber('#')} [/]";

    series2.columns.template.adapter.add("fill", function(fill, target) {
      switch(target.dataItem.categoryY.substring(0,2)){
        case 'M1':
          return missionsColors[5]
        break;
        case 'M2':
          return missionsColors[4]
        break;
        case 'M3':
          return missionsColors[3]
        break;
        case 'M4':
          return missionsColors[2]
        break;
        case 'M5':
          return missionsColors[1]
        break;
        case 'M6':
          return missionsColors[0]
        break;
      }
    });
    series2.columns.template.adapter.add("stroke", function(stroke, target) {
      switch(target.dataItem.categoryY.substring(0,2)){
        case 'M1':
          return missionsColors[5]
        break;
        case 'M2':
          return missionsColors[4]
        break;
        case 'M3':
          return missionsColors[3]
        break;
        case 'M4':
          return missionsColors[2]
        break;
        case 'M5':
          return missionsColors[1]
        break;
        case 'M6':
          return missionsColors[0]
        break;
      }
    });

    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.lineX.disabled = true;
    this.chart.cursor.lineY.disabled = true;
    //chart.cursor.behavior = "zoomY";

    let scrollbarY = new am4core.Scrollbar();
    this.chart.scrollbarY = scrollbarY;
    this.chart.scrollbarY.parent = this.chart.leftAxesContainer;

    let that = this;
    this.chart.events.on("ready", () => {
      const asse = that.chart.yAxes.values[0];
      const catCount = that.chart.data.length;
      if (catCount-15 >= 0 && catCount > 0) asse.zoomToIndexes(catCount-15, catCount);
      if(this.renderingStatus){
        this.renderingStatus = false;
        this.service.doRendering(false);
      }
    });

    this.chart.events.on('maxsizechanged', (e) => {
      if(window.innerWidth <= 1366 && window.innerWidth > 1199.98){

        this.chart.paddingLeft = -15;

        categoryAxis.renderer.labels.template.dx = 10;

        // valueAxes
        const xValue1 = e.target.xAxes.values[0];
        const xValue2 = e.target.xAxes.values[1];

        // Set valueAxes font size
        xValue1.title.fontSize = 14;
        xValue2.title.fontSize = 14;

        // Set valueAxes margin Right
        xValue1.marginRight = 30;
        xValue2.marginRight = 30;

        // Make valueAxes titles fit in chart
        xValue1.title.dx = 0;
        xValue2.title.dx = 0;
      }
      else if(window.innerWidth <= 1199.98 && window.innerWidth > 575.98){
        this.chart.paddingLeft = -15;

        categoryAxis.renderer.labels.template.dx = 10;

        // valueAxes
        const xValue1 = e.target.xAxes.values[0];
        const xValue2 = e.target.xAxes.values[1];

        // Set valueAxes font size
        xValue1.title.fontSize = 18;
        xValue2.title.fontSize = 18;

        // Set valueAxes margin Right
        xValue1.marginRight = 30;
        xValue2.marginRight = 30;

        // Make valueAxes titles fit in chart
        xValue1.title.dx = 0;
        xValue2.title.dx = 0;
      }
      else if(window.innerWidth <= 575.98){

        this.chart.paddingLeft = -15;

        categoryAxis.renderer.labels.template.dx = 10;

        // valueAxes
        const xValue1 = e.target.xAxes.values[0];
        const xValue2 = e.target.xAxes.values[1];

        // Set valueAxes font size
        xValue1.title.fontSize = 12;
        xValue2.title.fontSize = 12;

        // Set valueAxes margin Right
        xValue1.marginRight = 10;
        xValue2.marginRight = 15;

        // Make valueAxes titles fit in chart
        xValue1.title.dx = -33;
        xValue2.title.dx = -50;

      }
      else{
        this.chart.paddingLeft = 0;

        categoryAxis.renderer.labels.template.dx = 0;

        // valueAxes
        const xValue1 = e.target.xAxes.values[0];
        const xValue2 = e.target.xAxes.values[1];

        // Set valueAxes font size
        xValue1.title.fontSize = 18;
        xValue2.title.fontSize = 18;

        // Set valueAxes margin Right
        xValue1.marginRight = 30;
        xValue2.marginRight = 30;

        // Make valueAxes titles fit in chart
        xValue1.title.dx = 0;
        xValue2.title.dx = 0;
      }
    });
  }

  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  printValueAsCurrency(value) {
    //return this.italianCurrencyPipe.parse(value) + " €";
    value = typeof value === "number" ? value : value.replace(",", ".").trim();
    const format = "1.2-2";
    const currency = "€";
    const currentLocale: string = "it";
    return new CurrencyPipe(currentLocale).transform(
      value,
      "EUR",
      currency,
      format,
      "it-IT"
    );
  }

  ngOnDestroy(){
    this.chart && this.chart.dispose();
  }

  aggiungiLogo(logoChart) {
    // Add watermark
    const watermark = new am4core.Image();
    watermark.href = "assets/img/logo_blu.svg";
    logoChart.tooltipContainer.children.push(watermark);
    watermark.align = "right";
    watermark.valign = "bottom";
    watermark.opacity = 0.3;
    watermark.marginRight = 10;
    watermark.marginBottom = 5;
    watermark.disabled = true;

    // Enable watermark on export
    logoChart.exporting.events.on("exportstarted", function (ev) {
        watermark.disabled = false;
    });

    // Disable watermark when export finishes
    logoChart.exporting.events.on("exportfinished", function (ev) {
        watermark.disabled = true;
    });

    // Add watermark to validated sprites
    logoChart.exporting.validateSprites.push(watermark);


  }
  /*---*/


}



