<ng-template #templateEditAdd>
  <form [formGroup]="editForm" (ngSubmit)="onSubmit()" class="col-lg-12">
    <input type="hidden" [formGroup]="editForm" formControlName="id" />

    <div class="form-group">
      <h5><label for="titolo" class="mandatory">Titolo:</label></h5>
      <input [formGroup]="editForm" formControlName="titolo" maxlength="255" type="text" class="form-control"
        placeholder="Inserisci il titolo" [ngClass]="{
                    'form-control': true,
                    'is-invalid': submitted && f.titolo.errors
                    }" />
      <div *ngIf="submitted && f.titolo.errors" class="invalid-feedback" style="display: block;">
        <div *ngIf="f.titolo.errors.required">
          Campo obbligatorio
        </div>
      </div>
    </div>
    <div class="form-group">
      <h5><label for="macroCategoriaId" class="mandatory">Macro categoria:</label></h5>
      <div [ngClass]="{ 'is-invalid': submitted && editForm.controls['macroCategoriaId'].errors }">
        <ng-select [formGroup]="editForm" formControlName="macroCategoriaId" [items]="macroCategorie" bindValue="id"
          bindLabel="titolo" placeholder="Seleziona la macro categoria">
        </ng-select>
      </div>
      <div *ngIf="submitted && editForm.controls['macroCategoriaId'].errors" class="invalidFieldMessage"
        style="display: block;">
        <div *ngIf="editForm.controls['macroCategoriaId'].errors" class="invalid-feedback" style="display: block;">
          Campo obbligatorio
        </div>
      </div>
    </div>
    <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
      {{buttonLabel}}
    </button>
  </form>
</ng-template>

<div class="container sfondo_bianco">
  <hr />
  <!--<accordion [isAnimated]="true">
        <accordion-group heading="Inserisci nuova categoria SAL">            
        </accordion-group>
    </accordion>-->
  <div class="panel-heading card-header panel-enabled" role="tab" style="cursor: pointer;" (click)="toggleInsertForm()">
    <div class="panel-title">
      <div class="accordion-toggle" role="button" [attr.aria-expanded]="isOpen">
        <button class="btn btn-link"> Inserisci nuova categoria SAL </button>
      </div>
    </div>
  </div>
  <div [hidden]="!isOpen" class="card card-body" [ngClass]="{'fade-in':isOpen, 'fade-out':!isOpen}">
    <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
  </div>
  <div class="mt-5">

    <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;"
      [dtOptions]="dtOptions">
      <ng-template #btnEditTemplate let-row="row" let-value="value">
        <a [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top"
          title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit"></i></a>
      </ng-template>
      <ng-template #btnDeleteTemplate let-row="row" let-value="value">
        <a [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top"
          title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt"></i></a>
      </ng-template>
    </ngx-datatable-wrapper>
  </div>

  <ng-template #templateModal>
    <div class="modal-header  text-center">
      <h4 class="modal-title pull-left">Modifica categoria</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
    </div>
  </ng-template>


</div>
