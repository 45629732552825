<div class="container">
  <hr />

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="row">
      <h4 _ngcontent-lxo-c44="" class="titoletti">Menu</h4>
    </div>
  </div>
  <div *ngFor="let item of menuItems">
    <app-row-menu [menuItem]="item"></app-row-menu>
  </div>

  <hr />

  <app-comunications></app-comunications>
</div>
