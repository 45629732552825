import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  TemplateRef,
  OnDestroy
} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { GeoService } from "src/app/services/geo.service";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { Nation } from "../../../models/nation";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { EditNationModalComponent } from './edit-nation-modal/edit-nation-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as moment from "moment";
import { KeyValue } from 'src/app/models/keyValue';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';




@Component({
  selector: "app-nation-management",
  templateUrl: "./nation-management.component.html",
  styleUrls: ["./nation-management.component.css"]
})
export class NationManagementComponent
  implements OnInit, OnDestroy {
  currentContinentId: number = -1;
  visible = false;
  continents: KeyValue[];
  continentsAdd: KeyValue[];
  submitted: boolean = false;
  addNationForm: FormGroup;
  modalRef: BsModalRef;
  open: boolean = false;
  dtOptionsNgx: any;
  @ViewChild('btnEditTemplate', { static: true })
  public btnEditTemplate: TemplateRef<any>;
  @ViewChild('ngxDatatableWrapper')
  ngxDatatableWrapper: NgxDatatableWrapperComponent;
  templateList = [];

  constructor(
    private http: HttpClient,
    private geoService: GeoService,
    private formBuilder: FormBuilder,
    private localeService: BsLocaleService,
    private el: ElementRef,
    private toastr: ToastService,
    private modalService: BsModalService
  ) {
    this.localeService.use("it");
    this.dtOptionsNgx = {
      rows: []
      , totalElements: 0
      , columns: this.columns
      , columnMode: ColumnMode.flex
      , headerHeight: "50"
      , footerHeight: "50"
      , rowHeight: "auto"
      , externalSorting: true// true
      , loadingIndicator: 'loading'
      , class: "bootstrap"
      , summaryRow: true
      , summaryPosition: "'bottom'"
      , externalPaging: true// true
      , count: 0
      , offset: 0
      , limit: 10
      , serverSide: true
      , ajax: this.loadData
      , scrollbarH: "true"
    };
  }

  refreshGrid() {
    this.ngxDatatableWrapper.refresh();
  }

  ngOnDestroy(): void {

  }
  ngOnInit() {
    window.scroll(0, 0);
    this.geoService.getContinents().subscribe(res => {
      this.continents = res;
      this.continentsAdd = res;
    });
    this.addNationForm = this.initializeFormGroup();
    this.templateList = [
      { name: 'btnEditTemplate', template: this.btnEditTemplate }
    ];
  }



  onChangeContinent($event: KeyValue) {
    this.currentContinentId = $event.id;
    if (this.currentContinentId > 0) {
      this.visible = true;
    }
    this.refreshGrid();
  }

  onChangeNewContinent($event: KeyValue) {
    this.currentContinentId = $event.id;
  }

  initializeFormGroup() {
    return this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(255)]],
      atCode: [
        "",
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)]
      ],
      istatCode: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(3)]
      ],
      citizenship: [
        "",
        [Validators.required, Validators.maxLength(255)]
      ],
      isoCode: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(3)]
      ],
      continent: [null, [Validators.required]],
      fromDate: [
        null,
        [
          // Validators.required,
          // Validators.minLength(10),
          // Validators.maxLength(10)
        ]
      ],
      toDate: [
        null,
        [
          // Validators.required,
          // Validators.minLength(10),
          // Validators.maxLength(10)
        ]
      ]
    });
  }

  get f() {
    return this.addNationForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.addNationForm.updateValueAndValidity();

    let errors = false;
    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }
    if (this.addNationForm.invalid || errors) {
      console.log("invalid");
      let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
      for (let el in this.addNationForm.controls) {
        if (this.addNationForm.controls[el].invalid) {
          console.log(`set focus on ${el}`);
          if (this.addNationForm.controls[el] instanceof FormGroup) {
            for (let el1 in this.addNationForm.get(el)["controls"]) {
              if (this.addNationForm.get(el)["controls"][el1].invalid) {
                invalidElements = this.el.nativeElement.querySelectorAll(
                  `[formcontrolname="${el1}"]`
                );
                break;
              }
            }
          } else {
            invalidElements = this.el.nativeElement.querySelectorAll(
              `[formcontrolname="${el}"]`
            );
          }
          break;
        }
      }
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }

      return false;
    }

    const newNation: Nation = new Nation();
    newNation.name = this.f.name.value;
    newNation.atCode = this.f.atCode.value;
    newNation.istatCode = this.f.istatCode.value;
    newNation.isoCode = this.f.isoCode.value;
    newNation.fromDate = this.f.fromDate.value;
    newNation.toDate = this.f.toDate.value;
    newNation.idContinent = this.currentContinentId;
    newNation.citizenship = this.f.citizenship.value;

    this.geoService.createNation(newNation).subscribe(res => {
      this.toastr.success("Nazione aggiunta con successo!");
      this.refreshGrid();
      this.submitted = false;
      this.open = false;
      this.addNationForm.reset();
    }, error => {
      this.toastr.error("Codice già presente");
    })
  }

  editHandler(info: any): void {
    const initialState = { currentNation: info as Nation };
    initialState.currentNation.idContinent = this.currentContinentId;
    const config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: "modal-lg"
    };
    this.modalRef = this.modalService.show(
      EditNationModalComponent,
      Object.assign({}, config, { initialState })
    );

    this.modalRef.content.onClose.subscribe(_ => {
      this.modalRef.hide();
      this.refreshGrid();
    });
  }



  columns = [
    {
      name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
      , resizeable: false
    }

    , {
      name: 'codice at'
      , prop: 'atCode'

      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , flexGrow: 2
      , minWidth: 60

    },
    {
      name: 'codice istat'
      , prop: 'istatCode'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , flexGrow: 2
      , minWidth: 60

    }
    ,
    {
      name: 'codice iso'
      , prop: 'isoCode'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , flexGrow: 2
      , minWidth: 60

    }
    ,
    {
      name: 'nome'
      , prop: 'name'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , flexGrow: 3
      , minWidth: 100
    }
    ,
    {
      name: 'inizio validità'
      , prop: 'fromDate'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , cellTemplate: 'dateTimeValueTemplate'
      , flexGrow: 2
      , minWidth: 100
    }
    ,
    {
      name: 'fine validità'
      , prop: 'toDate'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , cellTemplate: 'dateTimeValueTemplate'
      , flexGrow: 2
      , minWidth: 100
    }


    , {
      name: 'modifica'
      , prop: null
      , sortable: false
      , filtrable: false
      , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
      //, width: 10
      , resizeable: false
      , draggable: false
      , flexGrow: 1
      , minWidth: 50
    }
  ];


  loadData = (dataTablesParameters: any, callback: any) => {
    let that = this;
    that.http
      .post<any>(
        `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.geo_protected}continents/${this.currentContinentId}/nations`,
        dataTablesParameters
      )
      .subscribe(resp => {
        callback(resp);
      });
  };
}
