import { Component, OnDestroy, OnInit } from "@angular/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  DashboardService,
  PnrrService,
  SoggettoResponsabileService,
  TipoClassificazioneService,
  TipologiaFaseService,
  TipologiaSoggettoResponsabileService,
  StorageService,
  SoggettoGiuridicoService,
} from "../../services";

import { map } from "rxjs/operators";
import { FasiDashboardDto } from "../../models/fasi-dashboard-dto";
import { Subject } from "rxjs";
import * as FileSaver from "file-saver";
import { ToastService } from "src/app/services/toast.service";
import * as moment from "moment";
import { AuthService, GeoService } from "src/app/services";
import { Router } from "@angular/router";
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from "ngx-bootstrap/datepicker";
defineLocale('it', itLocale);


class Classificazione {
  codice: string;
  count: number = 0;
  importoI: number = 0;
  importoF: number = 0;
  differenza: number = 0;
  descrizione: string;
  inCorso: number = 0;
  concluse: number = 0;
  inScadenza: number = 0;
  inRitardo: number = 0;
  future: number = 0;
  scadute: number = 0;
  totale: number = 0;
}
@Component({
  selector: "app-dashboard-fasi",
  templateUrl: "./dashboard-fasi.component.html",
  styleUrls: ["./dashboard-fasi.component.css"],
})
export class DashboardFasiComponent implements OnInit, OnDestroy {
  myForm: FormGroup;
  //filters
  mainTypesCombo: Array<{ k: string; v: string }> = [
    { k: "i", v: "Interventi" },
    {
      k: "p",
      v:
        this.isGr34() || this.isAdmin()
          ? "Progettualità/fabbisogni"
          : "Progettualità",
    },
    { k: "f", v: "Finanziamenti" },
    { k: "t", v: "Tutti" },
  ];

  flussoDiCassaItems: Array<{ k: string; v: string }> = [
    { k: "1", v: "Si" },
    { k: "0", v: "No" },
  ];

  allCompleterElementi = [];
  allCompleterFInanziamenti = [];
  allCompleterInterventi = [];

  completerParams = {
    labelField: "parentLabel",
    placeholder:
      this.isAdmin() || this.isGr34()
        ? "Cerca intervento; progettualità/fabbisogno; finanziamento"
        : "Cerca intervento; progettualità; finanziamento",
    ajax: this.filterComboInterventi,
    items: [],
    context: this,
  };

  completerParamsSoggetti = {};
  areeTematicheCombo = [];
  avanzamentoCombo: Array<{ k: string; v: string }> = [
    { k: "7", v: "Future" },
    { k: "1", v: "In scadenza" },
    { k: "2", v: "Concluse" },
    { k: "3", v: "In corso" },
    { k: "4", v: "In ritardo" },
    { k: "5", v: "Scadute" },
    { k: "6", v: "Nessuna fase" },
  ];
  classificazioniCombo = [];
  tipologieResponsabiliCombo = [];
  tipologieFasiCombo = [];
  soggettiCombo = [];

  filterMain = "t";
  completerId = -1;
  completerObject = null;
  filterArea = -1;
  filterStatoFasi = -1;
  filterClassificazione = -1;
  filterTipologiaResponsabile = -1;
  filterTipologiaFasi = -1;
  completerSoggettiId = -1;
  filterPnrr = "-1";
  filterSoggettoAttuatore = -1;


filterTipologieFaseFase = null;
filterTipologieFaseTitolo = null;


  filterLineeFinanziamentiPnrr: { missions: []; components: []; lines: [] } = {
    missions: null,
    components: null,
    lines: null,
  };
  filterRp = -1;
  filterRup = -1;
  filterComune = -1;
  filteredElements: FasiDashboardDto[] = [];
  description: string = "";

  chartStatoFasi;
  chartAvanzamentoFasi;
  chartSoggettiResponasabili;
  chartTipologieFasi;
  chart6;

  classificationsCodes = [];
  classifications = [];
  classificazioniGrafico: Classificazione[] = [];
  elencoClassificazioni = [];

  isHiddenChart6: boolean = true;
  showPage: boolean = false;
  showLoading: boolean = false;

  noresult = false;
  isShowingFiltered = false;

  pnrrComboItems = [];
  finanziamentoMissionsPnrrComboItems: any[] = [];
  finanziamentoComponentsPnrrComboItems: any[] = [];
  finanziamentoLinesPnrrComboItems: any[] = [];

  soggettiAttuatoriItems = [];

  isSisma: boolean = false;
  soggettiRPCombo = [];

  constructor(
    private formBuilder: FormBuilder,
    private classificazioniService: TipoClassificazioneService,
    private tipologiaResponsabileService: TipologiaSoggettoResponsabileService,
    private tipologiaFaseService: TipologiaFaseService,
    private dashboardService: DashboardService,
    private soggettiService: SoggettoResponsabileService,
    private toastr: ToastService,
    private authService: AuthService,
    private pnrrService: PnrrService,
    private storageService: StorageService,
    private soggettiGiuridiciService: SoggettoGiuridicoService,
    private router: Router,
    private geoService: GeoService,
    private localeService: BsLocaleService,
  ) {
    this.isSisma = this.router.url.includes("dashboard-fasi-sisma");
    this.getAllMunicipalities();
    this.localeService.use('it');
  }

  ngOnInit() {
    am4core.useTheme(am4themes_animated);
    this.myForm = this.initializeFormGroup();
    this.storageService.elencoRup.subscribe((x) => {
      this.soggettiRPCombo = x;
      console.log("this.soggettiRPCombo", this.soggettiRPCombo);
    });
  }

  ngOnDestroy() {
    if (this.showPage) {
      this.disposeCharts();
    }
    if (this.elencoAreeSubscription) this.elencoAreeSubscription.unsubscribe();
  }

  initializeFormGroup() {
    const form = this.formBuilder.group({
      main: [null, null],
      completer: [{ value: null, disabled: false }, null],
      direction: [{ value: null, disabled: false }, null],
      statoFasi: [{ value: null, disabled: false }, null],
      classification: [{ value: null, disabled: false }, null],
      tipologiaResponsabile: [{ value: null, disabled: false }, null],
      flussoDiCassa: [null],
      completer1: [null],
      pnrrPnc: [null],
      finanziamentoPnrrPnc: [null],
      attuatore: [null],
      rp: [null],
      rup: [null],
      comune:[null],
      dataPrevistaInizio:[null],
      dataPrevistaFine:[null],
      dataInizio:[null],
      dataFine:[null],
      tipologia:[null],
      tipologiaFaseTitolo:[null],
      tipologiaFaseFase:[null]
    });

    this.getAreeTematiche();
    this.getClassificazioni();
    this.getTipologieResponsabili();
    this.getTipologieFasi();
    this.getSoggettiResponsabili();
    this.fillPnrrComboItems();
    this.fillFinanziamentoPnrrComboItems();
    this.getSoggettiAttuatori();
    return form;
  }

  getSoggettiAttuatori() {
    this.soggettiGiuridiciService.getSoggettiAttuatori(1).subscribe((x) => {
      this.soggettiAttuatoriItems = x;
      this.soggettiAttuatoriItems.sort((a, b) =>
        a.denominazione < b.denominazione ? -1 : 1
      );
    });
  }

  filterComboInterventi(filterString, callBack) {
    const o = {
      elementoId: -1,
      tabella: "t",
      descriptionSearchString: filterString,
    };
    this["context"].dashboardService
      .getDashboardFasiObjectList(o)
      .subscribe((x) => {
        function getUniqueArrayElements(arr) {
          const u = [];
          arr.forEach((element) => {
            if (!u.find((x) => x.parentCode == element.parentCode))
              u.push(element);
          });
          return u;
        }

        x = getUniqueArrayElements(x);
        callBack(x);
      });
  }

  fillPnrrComboItems() {
    const arr1 = [
      { key: "0", value: "No" },
      { key: "1", value: "Tutti" },
    ];
    const arr2 = [
      { key: "2", value: "Attuazione regionale" },
      { key: "3", value: "Territoriale" },
    ];
    this.pnrrComboItems = [...arr1, ...arr2];
  }

  fillFinanziamentoPnrrComboItems() {
    this.pnrrService.getAllLineaFinanziamento().subscribe((res) => {
      res = res.sort((a, b) => a.codice.localeCompare(b.codice));

      const buildFMFinanziamento = new Set();
      const buildSMFinanziamento = new Set();
      const buildTMFinanziamento = new Set();
      res.forEach((data) => {
        let dataSplitted = data.codice.split("I");

        if (dataSplitted.length <= 1) dataSplitted = data.codice.split("R");

        buildFMFinanziamento.has(data.missione.codice)
          ? null
          : buildFMFinanziamento.add(data.missione.descrizione);
        buildSMFinanziamento.has(data.missione.codice + data.componente.codice)
          ? null
          : buildSMFinanziamento.add(
              dataSplitted[0] + " - " + data.componente.descrizione
            );
        buildTMFinanziamento.add({
          valore: data.codice + " - " + data.descrizione,
          codice: data.codice,
        });
      });

      this.finanziamentoMissionsPnrrComboItems =
        Array.from(buildFMFinanziamento);
      this.finanziamentoMissionsPnrrComboItems =
        this.finanziamentoMissionsPnrrComboItems.map((data) => ({
          valore: data,
          codice: data.split(" - ")[0],
        }));
      this.finanziamentoComponentsPnrrComboItems =
        Array.from(buildSMFinanziamento);
      this.finanziamentoComponentsPnrrComboItems =
        this.finanziamentoComponentsPnrrComboItems.map((data) => ({
          valore: data,
          codice: data.split(" - ")[0],
        }));
      this.finanziamentoLinesPnrrComboItems = Array.from(buildTMFinanziamento);
    });
  }

  get f() {
    return this.myForm.controls;
  }

  /* FILTERS AND EVENTS */

  filter(init: boolean, renderData: boolean) {
    this.doFilter(
      this.filterMain,
      this.completerId,
      this.filterArea,
      this.filterClassificazione,
      this.filterTipologiaResponsabile,
      this.filterTipologiaFasi,
      this.filterStatoFasi,
      this.completerSoggettiId,
      init,
      this.filterFlussoDiCassa,
      this.filterPnrr,
      this.filterLineeFinanziamentiPnrr,
      renderData
    );
  }

  disableFilters() {
    this.myForm.disable();
  }

  enableFilters() {
    this.myForm.enable();
  }

  setSoggettoAttuatore($event) {
    this.filterSoggettoAttuatore = $event ? $event.id : -1;
  }

  doFilter(
    type: string,
    id: number,
    idAreaTematica: number,
    idTipoClassificazione: number,
    idTipologiaResponsabile: number,
    idTipologiaFase: number,
    codiceStatoFase: number,
    idSoggetto: number,
    init: boolean,
    flussoDiCassa: number,
    filterPnrr: any,
    filterLineeFinanziamentiPnrr: any,
    renderData: boolean
  ) {
    if (renderData) {
      this.disableFilters();
      this.showLoading = true;
    } else {
      this.showPage = false;
    }

    if (this.completerObject != null) {
      switch (this.completerObject.parentType) {
        case "INTERVENTO":
          type = "i";
          break;
        case "PROGETTO":
          type = "p";
          break;
        case "FINANZIAMENTO":
          type = "f";
          break;
      }
    }


    let dataPrevistaInizio = this.myForm.value.dataPrevistaInizio;
    let dataPrevistaFine = this.myForm.value.dataPrevistaFine;
    let dataInizio = this.myForm.value.dataInizio;
    let dataFine = this.myForm.value.dataFine;
    if (dataPrevistaInizio)dataPrevistaInizio = [moment(dataPrevistaInizio[0]).format('YYYY-MM-DD'),moment(dataPrevistaInizio[1]).format('YYYY-MM-DD')];
    if (dataPrevistaFine)dataPrevistaFine = [moment(dataPrevistaFine[0]).format('YYYY-MM-DD'),moment(dataPrevistaFine[1]).format('YYYY-MM-DD')];
    if (dataInizio)dataInizio = [moment(dataInizio[0]).format('YYYY-MM-DD'),moment(dataInizio[1]).format('YYYY-MM-DD')];
    if (dataFine)dataFine = [moment(dataFine[0]).format('YYYY-MM-DD'),moment(dataFine[1]).format('YYYY-MM-DD')];
    


    this.dashboardService
      .getDashboardFasiData(
        type,
        id,
        -1,
        codiceStatoFase,
        this.isSisma ? this.getAreaSismaId() : idAreaTematica,
        idTipoClassificazione,
        idTipologiaResponsabile,
        idTipologiaFase,
        idSoggetto,
        flussoDiCassa,
        filterPnrr,
        filterLineeFinanziamentiPnrr,
        this.filterSoggettoAttuatore,
        this.filterRp,
        this.filterRup,
        this.filterComune,
        dataPrevistaInizio,
        dataPrevistaFine,
        dataInizio,
        dataFine,
        this.filterTipologieFaseFase,
        this.filterTipologieFaseTitolo
      )
      .subscribe((res) => {
        this.noresult = renderData && (!res || res.length === 0);
        this.showPage = renderData && !this.noresult;

        setTimeout(() => {
          this.completeDoFilter(renderData, res);
        }, 1);
        if (renderData) {
          this.executeGetTabellaDettaglioObjAsync(renderData);
        }
      });
  }

  private async completeDoFilter(renderData, res) {
    this.filteredElements = res;

    if (!renderData) {
      this.showLoading = false;
      this.enableFilters();
      return;
    }

    this.drawCharts();
    this.showLoading = false;
    this.enableFilters();
  }

  private elencoAreeSubscription = null;
  getAreeTematiche() {
    this.elencoAreeSubscription =
      this.storageService.elencoAreeTematiche.subscribe((x) => {
        this.areeTematicheCombo = x || [];
      });
  }

  getAreaSismaId() {
    return this.areeTematicheCombo.find((x) => x.struttura === "DU0100").id;
  }

  getClassificazioni() {
    this.classificazioniService.getAll().subscribe((x) => {
      this.classificazioniCombo = x;
    });
  }

  getTipologieResponsabili() {
    this.tipologiaResponsabileService.getAll().subscribe((result) => {
      this.tipologieResponsabiliCombo = result;
    });
  }

  getTipologieFasi() {
    this.tipologiaFaseService
      .getAll()
      .pipe(
        map((data) => {
          return data.map((elem) => {
            return {
              k: elem.id,
              v: `${elem.fase}-${elem.titolo}-${elem.descrizione}`,
              descrizioneEstesaFull: `${elem.fase} - ${elem.titolo} - ${elem.descrizione}`,
            descrizioneEstesa: `${elem.descrizione}`,
                fase:elem.fase,
                titolo:elem.titolo,
                descrizione:elem.descrizione
            };
          });
        })
      )
      .subscribe((mappedRes) => {
        this.tipologieFasiCombo = mappedRes;
        this.tipologieFasiFasi =[...new Set(mappedRes.map(x=>x.fase))];
      });
  }

  getSoggettiResponsabili() {
    let that = this;
    this.soggettiService.getAll().subscribe((result) => {
      that.soggettiCombo = result;
      that.soggettiCombo.forEach((element) => {
        element[
          "label"
        ] = `${element.cognome} ${element.nome} - ${element.tipologia.descrizione}`;
      });
      this.completerParamsSoggetti = {
        items: this.soggettiCombo,
        labelField: "label",
        ajax: null,
        placeholder: "Cerca  ",
      };
    });
  }

  setMain($event) {
    this.filterMain = $event ? $event.k : "t";
  }

  setAreaTematica($event) {
    this.filterArea = $event ? $event.id : -1;
  }

  setInterventionCompleter($event) {
    if ($event) {
      let type = $event.parentType;
      this.completerObject = $event;
      this.completerId = $event
        ? type == "FINANZIAMENTO"
          ? $event.finanziamentoId
          : $event.interventoId
        : -1;
    } else {
      this.completerId = -1;
      this.completerParams.items = [];
      this.completerObject = null;
    }
    this.description = $event && $event.parentTitle;
  }

  onChangeInterventionCompleter($event) {
    !$event.target.value && this.setInterventionCompleter(null);
  }

  onChangeSoggettiCompleter($event) {
    !$event.target.value && this.setSoggettiCompleter(null);
  }

  setStatoFasi($event) {
    this.filterStatoFasi = $event ? $event.k : -1;
    if (this.filterStatoFasi !== 6) {
    }
  }

  setClassificazione($event) {
    this.filterClassificazione = $event ? $event.id : -1;
    $event ? (this.isHiddenChart6 = false) : (this.isHiddenChart6 = true);
  }

  setPnrrFilter($event) {
    this.filterPnrr = $event ? $event.key : "-1";
  }

  setLineeFinanziamento($event, type) {
    const values = $event.map((data) => data.codice.toUpperCase());
    switch (type) {
      case "mission":
        this.filterLineeFinanziamentiPnrr.missions = values;
        break;
      case "component":
        this.filterLineeFinanziamentiPnrr.components = values;
        break;
      case "line":
        this.filterLineeFinanziamentiPnrr.lines = values;
        break;
    }
  }

  setTipologiaResponsabile($event) {
    this.filterTipologiaResponsabile = $event ? $event.id : -1;
  }

  setTipologiaFase($event) {
    this.filterTipologiaFasi = $event ? $event.k : -1;
  }

  filterFlussoDiCassa = -1;
  setFlussoDiCassa($event) {
    this.filterFlussoDiCassa = $event ? $event.k : -1;
  }

  setSoggettiCompleter($event) {
    this.completerSoggettiId = $event ? $event.id : -1;
  }

  isFiltered(): boolean {
    if (
      this.completerId !== -1 ||
      this.filterFlussoDiCassa !== -1 ||
      this.filterArea >= 0 ||
      this.filterStatoFasi >= 0 ||
      this.filterClassificazione >= 0 ||
      this.filterTipologiaResponsabile >= 0 ||
      this.filterTipologiaFasi >= 0 ||
      this.completerSoggettiId >= 0 ||
      (this.filterLineeFinanziamentiPnrr &&
        ((this.filterLineeFinanziamentiPnrr.missions &&
          this.filterLineeFinanziamentiPnrr.missions.length > 0) ||
          (this.filterLineeFinanziamentiPnrr.components &&
            this.filterLineeFinanziamentiPnrr.components.length > 0) ||
          (this.filterLineeFinanziamentiPnrr.lines &&
            this.filterLineeFinanziamentiPnrr.lines.length > 0))) ||
      this.filterPnrr !== "-1" ||
      this.filterRp != -1 ||
      this.filterRup != -1 ||
      this.filterComune !=-1 ||
      this.myForm.value.dataPrevistaInizio ||
      this.myForm.value.dataPrevistaFine ||
      this.myForm.value.dataInizio ||
      this.myForm.value.dataFine ||
      (this.filterTipologieFaseFase && this.filterTipologieFaseFase!='') ||
        (this.filterTipologieFaseTitolo && this.filterTipologieFaseTitolo!='')
    )
      this.isShowingFiltered = true;
    else this.isShowingFiltered = false;
    return this.isShowingFiltered;




  }

  /* CHARTS */
  drawCharts() {
    this.disposeCharts();
    this.drawChartStatoFasi();
    this.drawChartAvanzamentoFasi();
    this.drawChartSoggettiResponsabili();
    this.drawChartTipologieFasi();
    if (this.filterClassificazione >= 0) {
      this.setClassificazioniPerGrafici();
      this.renderChart6();
    }

    this.enableFilters();
  }

  countConcluse(data: FasiDashboardDto[]): number {
    let count = 0;
    data.forEach((element) => {
      const dataFine = element.dataFine ? moment(element.dataFine) : null;
      dataFine && count++;
    });
    return count;
  }

  countFuture(data: FasiDashboardDto[]): number {
    let count = 0;
    const now = moment(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
    ).toDate();
    data.forEach((element) => {
      if (
        !element.dataInizio &&
        moment(element.dataPrevistaInizio).toDate() >= now
      )
        count++;
    });
    return count;
  }

  countNiente(data: FasiDashboardDto[]): number {
    let count = 0;
    const now = moment().startOf("day");
    data.forEach((element) => {
      const dataPrevistaInizio = element.dataPrevistaInizio
        ? moment(element.dataPrevistaInizio)
        : null;

      dataPrevistaInizio && now < dataPrevistaInizio && count++;
    });
    return count;
  }

  countInCorso(data: FasiDashboardDto[]): number {
    let count = 0;
    const now = moment().startOf("day");
    data.forEach((element) => {
      const dataInizio = element.dataInizio ? moment(element.dataInizio) : null;
      const dataFine = element.dataFine ? moment(element.dataFine) : null;
      const dataPrevistaFine = element.dataPrevistaFine
        ? moment(element.dataPrevistaFine)
        : null;

      dataInizio &&
        dataPrevistaFine &&
        !dataFine &&
        dataInizio <= now &&
        dataPrevistaFine >= now &&
        !(
          dataPrevistaFine.diff(now, "days") <= 7 &&
          dataPrevistaFine.diff(now, "days") >= 0
        ) &&
        count++;
    });
    return count;
  }

  countInRitardo(
    data: FasiDashboardDto[],
    startInterval: number,
    endInterval: number
  ): number {
    let count = 0;
    const now = moment().startOf("day");
    data.forEach((element) => {
      const dataInizio = element.dataInizio ? moment(element.dataInizio) : null;
      const dataPrevistaInizio = element.dataPrevistaInizio
        ? moment(element.dataPrevistaInizio)
        : null;
      if (!startInterval || endInterval === null) {
        dataPrevistaInizio &&
          !dataInizio &&
          dataPrevistaInizio < now &&
          count++;
      } else {
        if (endInterval && endInterval > 0) {
          dataPrevistaInizio &&
            !dataInizio &&
            dataPrevistaInizio < now &&
            now.diff(dataPrevistaInizio, "days") >= startInterval &&
            now.diff(dataPrevistaInizio, "days") <= endInterval &&
            count++;
        } else {
          dataPrevistaInizio &&
            !dataInizio &&
            dataPrevistaInizio < now &&
            now.diff(dataPrevistaInizio, "days") >= startInterval &&
            count++;
        }
      }
    });
    return count;
  }

  countScadute(
    data: FasiDashboardDto[],
    startInterval: number,
    endInterval: number
  ): number {
    let count = 0;
    const now = moment().startOf("day");
    data.forEach((element) => {
      const dataInizio = element.dataInizio ? moment(element.dataInizio) : null;
      const dataFine = element.dataFine ? moment(element.dataFine) : null;
      const dataPrevistaFine = element.dataPrevistaFine
        ? moment(element.dataPrevistaFine)
        : null;
      if (!startInterval || endInterval === null) {
        dataInizio &&
          !dataFine &&
          dataPrevistaFine &&
          now > dataPrevistaFine &&
          count++;
      } else {
        if (endInterval && endInterval > 0) {
          dataInizio &&
            !dataFine &&
            dataPrevistaFine &&
            now > dataPrevistaFine &&
            now.diff(dataPrevistaFine, "days") >= startInterval &&
            now.diff(dataPrevistaFine, "days") <= endInterval &&
            count++;
        } else {
          dataInizio &&
            !dataFine &&
            dataPrevistaFine &&
            now > dataPrevistaFine &&
            now.diff(dataPrevistaFine, "days") >= startInterval &&
            count++;
        }
      }
    });
    return count;
  }

  countInScadenza(data: FasiDashboardDto[]): number {
    let count = 0;
    const now = moment().startOf("day");
    data.forEach((element) => {
      const dataInizio = element.dataInizio ? moment(element.dataInizio) : null;
      const dataFine = element.dataFine ? moment(element.dataFine) : null;
      const dataPrevistaFine = element.dataPrevistaFine
        ? moment(element.dataPrevistaFine)
        : null;
      !dataFine &&
        dataInizio &&
        dataPrevistaFine &&
        dataPrevistaFine.diff(now, "days") <= 7 &&
        dataPrevistaFine.diff(now, "days") >= 0 &&
        count++;
    });
    return count;
  }

  disposeCharts() {
    if (this.chartStatoFasi) this.chartStatoFasi.dispose();
    if (this.chartAvanzamentoFasi) this.chartAvanzamentoFasi.dispose();
    if (this.chartSoggettiResponasabili)
      this.chartSoggettiResponasabili.dispose();
    if (this.chartTipologieFasi) this.chartTipologieFasi.dispose();
  }

  exportChart(chart, title) {
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = "left";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.background = "#ff0000";
    chart.exporting.menu.defaultStyles = true;
    chart.exporting.filePrefix = title;
    chart.exporting.menu.items = [
      {
        menu: [
          { type: "jpg", label: "JPG" },
          { type: "png", label: "PNG" },
          { type: "csv", label: "CSV" },
        ],
      },
    ];
    chart.exporting.menu.items[0].icon = "assets/img/export.png";
  }

  aggiungiLogo(logoChart) {
    // Add watermark
    const watermark = new am4core.Image();
    watermark.href = "assets/img/logo_blu.svg";
    logoChart.tooltipContainer.children.push(watermark);
    watermark.align = "right";
    watermark.valign = "bottom";
    watermark.opacity = 0.3;
    watermark.marginRight = 10;
    watermark.marginBottom = 5;
    watermark.disabled = true;

    // Enable watermark on export
    logoChart.exporting.events.on("exportstarted", function (ev) {
      watermark.disabled = false;
    });

    // Disable watermark when export finishes
    logoChart.exporting.events.on("exportfinished", function (ev) {
      watermark.disabled = true;
    });

    // Add watermark to validated sprites
    logoChart.exporting.validateSprites.push(watermark);
  }

  drawChartStatoFasi() {
    // Themes begin

    function am4themes_myTheme(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#67b7dc"),
          am4core.color("#6e95de"),
          am4core.color("#40a73f"),
          am4core.color("#f9c417"),
          am4core.color("#dc5633"),
          am4core.color("#b03333"),
        ];
      }
    }

    am4core.useTheme(am4themes_myTheme);

    this.chartStatoFasi = am4core.create("chart1", am4charts.PieChart);

    // Add chart title
    const title = this.chartStatoFasi.titles.create();
    title.marginTop = 15;
    title.marginBottom = 10;
    title.text = "Fasi stato di avanzamento (Quantità)";

    const countConcluse = this.countConcluse(this.filteredElements);
    const countFuture = this.countFuture(this.filteredElements);
    const countInCorso = this.countInCorso(this.filteredElements);
    const countInRitardo = this.countInRitardo(
      this.filteredElements,
      null,
      null
    );
    const countScadute = this.countScadute(this.filteredElements, null, null);
    const countInScadenza = this.countInScadenza(this.filteredElements);

    // Add data
    this.chartStatoFasi.data = [
      { name: "future", size: countFuture },
      { name: "in corso", size: countInCorso },
      { name: "concluse", size: countConcluse },
      { name: "in scadenza", size: countInScadenza },
      { name: "in ritardo", size: countInRitardo },
      { name: "scadute", size: countScadute },
    ];

    // Add label
    this.chartStatoFasi.innerRadius = 100;
    const label = this.chartStatoFasi.seriesContainer.createChild(
      am4core.Label
    );
    label.text =
      "" +
      (countInCorso +
        countConcluse +
        countInScadenza +
        countInRitardo +
        countScadute +
        countFuture);
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 40;

    this.aggiungiLogo(this.chartStatoFasi);

    // Add a legend
    this.chartStatoFasi.legend = new am4charts.Legend();
    this.chartStatoFasi.legend.position = "right";
    const markerTemplate = this.chartStatoFasi.legend.markers.template;
    markerTemplate.width = 14;
    markerTemplate.height = 14;
    this.chartStatoFasi.legend.itemContainers.template.cursorOverStyle =
      am4core.MouseCursorStyle.default;

    this.chartStatoFasi.legend.itemContainers.template.togglable = false;

    this.chartStatoFasi.radius = am4core.percent(70);

    // Add and configure Series
    const pieSeries = this.chartStatoFasi.series.push(
      new am4charts.PieSeries()
    );
    pieSeries.dataFields.value = "size";
    pieSeries.dataFields.category = "name";
    this.exportChart(this.chartStatoFasi, title.text);
  }

  getGravita(
    data: FasiDashboardDto[],
    name: string,
    start: number,
    end: number
  ) {
    const countInRit = this.countInRitardo(data, start, end);
    const countScadute = this.countScadute(data, start, end);
    const tot = countInRit + countScadute;

    const res = {
      name1: name,
      intervallo: `da ${start} a ${end != 0 ? end : "∞"} gg`,
      units: tot,
      pie: [
        {
          value: countInRit,
          title: "in ritardo",
        },
        {
          value: countScadute,
          title: "scadute",
        },
      ],
    };
    return res;
  }

  drawChartAvanzamentoFasi() {
    const data = [
      this.getGravita(this.filteredElements, "bassa", 1, 30),
      this.getGravita(this.filteredElements, "medio-bassa", 31, 60),
      this.getGravita(this.filteredElements, "media", 61, 90),
      this.getGravita(this.filteredElements, "alta", 91, 0),
    ];

    // Create chart instance
    this.chartAvanzamentoFasi = am4core.create("chart2", am4charts.XYChart);
    this.chartAvanzamentoFasi.hiddenState.properties.opacity = 0; // this creates initial fade-in

    // Add data
    this.chartAvanzamentoFasi.data = data;

    // Add chart title
    const title = this.chartAvanzamentoFasi.titles.create();

    this.aggiungiLogo(this.chartAvanzamentoFasi);

    title.marginBottom = 10;
    title.text = "Scostamento in ritardo/scadute (Quantità)";

    // Create axes
    const categoryAxis = this.chartAvanzamentoFasi.xAxes.push(
      new am4charts.CategoryAxis()
    );
    categoryAxis.dataFields.category = "intervallo";
    categoryAxis.renderer.grid.template.disabled = true;

    const valueAxis = this.chartAvanzamentoFasi.yAxes.push(
      new am4charts.ValueAxis()
    );
    valueAxis.min = 0;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.grid.template.strokeOpacity = 0.07;

    // Create series
    const series = this.chartAvanzamentoFasi.series.push(
      new am4charts.ColumnSeries()
    );
    series.dataFields.valueY = "units";
    series.dataFields.categoryX = "intervallo";
    series.dataFields["name1"] = "name1";
    series.tooltip.pointerOrientation = "vertical";
    series.columns.template.column.fillOpacity = 0.8;

    const columnTemplate = series.columns.template;
    // add tooltip on column, not template, so that slices could also have tooltip
    columnTemplate.column.tooltipText =
      "Gravità: {name1}\nPeriodo: {categoryX}\nQuantità: {valueY}";
    columnTemplate.column.tooltipY = 0;
    columnTemplate.column.cornerRadiusTopLeft = 20;
    columnTemplate.column.cornerRadiusTopRight = 20;
    columnTemplate.strokeOpacity = 0;

    const that = this;
    // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
    columnTemplate.adapter.add("fill", function (fill, target) {
      let color = that.chartAvanzamentoFasi.colors.getIndex(
        target.dataItem.index * 1
      );
      return color;
    });

    // create pie chart as a column child
    const pieChart = series.columns.template.createChild(am4charts.PieChart);
    pieChart.width = am4core.percent(80);
    pieChart.height = am4core.percent(80);
    pieChart.align = "center";
    pieChart.valign = "middle";
    pieChart.dataFields.data = "pie";

    const pieSeries = pieChart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "title";
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    pieSeries.slices.template.strokeWidth = 1;

    pieSeries.slices.template.adapter.add("stroke", function (stroke, target) {
      return that.chartAvanzamentoFasi.colors.getIndex(
        target.parent.parent.dataItem.index * 1
      );
    });

    pieSeries.slices.template.adapter.add("fill", function (fill, target) {
      return am4core.color("#ffffff");
    });

    pieSeries.slices.template.adapter.add(
      "fillOpacity",
      function (fillOpacity, target) {
        return (target.dataItem.index + 1) * 0.2;
      }
    );

    pieSeries.hiddenState.properties.startAngle = -90;
    pieSeries.hiddenState.properties.endAngle = 270;

    // this moves the pie out of the column if column is too small
    pieChart.adapter.add("verticalCenter", function (verticalCenter, target) {
      let point = am4core.utils.spritePointToSprite(
        { x: 0, y: 0 },
        target.seriesContainer,
        that.chartAvanzamentoFasi.plotContainer
      );
      point.y -= target.dy;

      if (
        point.y >
        that.chartAvanzamentoFasi.plotContainer.measuredHeight - 15
      ) {
        target.dy = -target.seriesContainer.measuredHeight - 15;
      } else {
        target.dy = 0;
      }
      return verticalCenter;
    });

    this.chartAvanzamentoFasi.colors.list = [
      am4core.color("#ffc533"),
      am4core.color("#f48c06"),
      am4core.color("#dc2f02"),
      am4core.color("#a30000"),
    ];

    const legend = new am4charts.Legend();
    legend.parent = this.chartAvanzamentoFasi.chartContainer;

    legend.itemContainers.template.togglable = false;
    legend.itemContainers.template.cursorOverStyle =
      am4core.MouseCursorStyle.default;

    legend.marginTop = 10;
    const markerTemplate = legend.markers.template;
    markerTemplate.width = 14;
    markerTemplate.height = 14;

    series.events.on("ready", function (ev) {
      const legenddata = [];
      series.columns.each(function (column) {
        legenddata.push({
          name: "Gravità: " + column.dataItem["name1"],
          fill: column.fill,
          columnDataItem: column.dataItem,
        });
      });
      legend.data = legenddata;
    });
    this.exportChart(this.chartAvanzamentoFasi, title.text);
  }

  showChart3 = false;
  drawChartSoggettiResponsabili() {
    /* Chart code */
    this.showChart3 = false;
    const soggetti = {};
    let data = [];

    this.filteredElements.forEach((e) => {
      const inCorso = this.countInCorso([e]);
      const concluse = this.countConcluse([e]);
      const inScadenza = this.countInScadenza([e]);
      const future = this.countFuture([e]);
      const inRitardo = this.countInRitardo([e], null, null);
      const scadute = this.countScadute([e], null, null);

      if (e.soggettiResponsabili)
        e.soggettiResponsabili.forEach((s) => {
          if (soggetti[`${s.cognome + s.nome}`] == null) {
            soggetti[`${s.cognome + s.nome}`] = {
              id: s.id,
              nome: s.nome,
              cognome: s.cognome,
              "asse-x": `${s.cognome} ${s.nome}`,
              "in-corso": 0,
              concluse: 0,
              "in-scadenza": 0,
              "in-ritardo": 0,
              future: 0,
              scadute: 0,
              totale: 0,
              none: 0,
            };
            data.push(soggetti[`${s.cognome + s.nome}`]);
          }
          soggetti[`${s.cognome + s.nome}`]["in-corso"] += inCorso;
          soggetti[`${s.cognome + s.nome}`]["concluse"] += concluse;
          soggetti[`${s.cognome + s.nome}`]["in-scadenza"] += inScadenza;
          soggetti[`${s.cognome + s.nome}`]["in-ritardo"] += inRitardo;
          soggetti[`${s.cognome + s.nome}`]["scadute"] += scadute;
          soggetti[`${s.cognome + s.nome}`]["future"] += future;
          soggetti[`${s.cognome + s.nome}`]["totale"] =
            soggetti[`${s.cognome + s.nome}`]["in-corso"] +
            soggetti[`${s.cognome + s.nome}`]["concluse"] +
            soggetti[`${s.cognome + s.nome}`]["in-scadenza"] +
            soggetti[`${s.cognome + s.nome}`]["in-ritardo"] +
            soggetti[`${s.cognome + s.nome}`]["scadute"] +
            soggetti[`${s.cognome + s.nome}`]["future"];
        });
    });

    if (this.completerSoggettiId > 0) {
      data = data.filter((s) => s.id === this.completerSoggettiId);
    }

    // Create chart instance
    this.chartSoggettiResponasabili = am4core.create(
      "chart3",
      am4charts.XYChart
    );

    this.chartSoggettiResponasabili.scrollbarX = new am4core.Scrollbar();

    // Add chart title
    let title = this.chartSoggettiResponasabili.titles.create();

    this.aggiungiLogo(this.chartSoggettiResponasabili);

    title.marginBottom = 10;
    title.text = "Fasi per soggetto responsabile (Quantità)";

    // Add data
    this.chartSoggettiResponasabili.data = data;
    // Create axes
    const categoryAxis = this.chartSoggettiResponasabili.xAxes.push(
      new am4charts.CategoryAxis()
    );
    categoryAxis.dataFields.category = "asse-x";
    categoryAxis.renderer.grid.template.location = 0;

    const valueAxis = this.chartSoggettiResponasabili.yAxes.push(
      new am4charts.ValueAxis()
    );
    valueAxis.renderer.inside = false;
    valueAxis.renderer.labels.template.disabled = false;
    valueAxis.min = 0;
    valueAxis.calculateTotals = true;
    valueAxis.extraMax = 0.1; // aggiungo un 10% sopra l'ultima colonna
    const that = this;
    createSeries("future", "future");
    createSeries("in-corso", "in corso");
    createSeries("concluse", "concluse");
    createSeries("in-scadenza", "in scadenza");
    createSeries("in-ritardo", "in ritardo");
    createSeries("scadute", "scadute");

    const totalSeries = this.chartSoggettiResponasabili.series.push(
      new am4charts.ColumnSeries()
    );
    totalSeries.dataFields.valueY = "none";
    totalSeries.dataFields.categoryX = "asse-x";
    totalSeries.stacked = true;
    totalSeries.hiddenInLegend = true;
    totalSeries.columns.template.strokeOpacity = 0;

    const totalBullet = totalSeries.bullets.push(new am4charts.LabelBullet());
    totalBullet.dy = -20;
    totalBullet.label.text = "Totale: {valueY.total}";
    totalBullet.label.hideOversized = false;
    totalBullet.label.fontSize = 18;
    totalBullet.label.background.fill = totalSeries.stroke;
    totalBullet.label.background.fillOpacity = 0;
    totalBullet.label.padding(5, 10, 5, 10);

    // Create series

    function createSeries(field, name) {
      // Set up series
      const series = that.chartSoggettiResponasabili.series.push(
        new am4charts.ColumnSeries()
      );
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "asse-x";
      series.sequencedInterpolation = true;

      // Make it stacked
      series.stacked = true;

      // Configure columns
      series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText =
        "[bold]{name}: {valueY}[/]\n[font-size:14px]{categoryX}";

      // Add label
      const labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;

      return series;
    }

    // Legend
    this.chartSoggettiResponasabili.legend = new am4charts.Legend();

    const markerTemplate =
      this.chartSoggettiResponasabili.legend.markers.template;
    markerTemplate.width = 14;
    markerTemplate.height = 14;
    this.chartSoggettiResponasabili.events.on("ready", () => {
      const asse = that.chartSoggettiResponasabili.xAxes.values[0];
      const catCount = that.chartSoggettiResponasabili.data.length;
      if (catCount > 10) asse.zoomToIndexes(0, 10);
      this.showChart3 = true;
    });

    this.exportChart(this.chartSoggettiResponasabili, title.text);
  }

  showChart4 = false;
  drawChartTipologieFasi() {
    this.showChart4 = false;
    /* Chart code */
    const tipologieSet = [
      ...new Set(this.filteredElements.map((e) => e.tipologiaFase)),
    ];
    const data = [];
    let i = 0;
    tipologieSet.forEach((tipologia) => {
      i++;
      const id = tipologia.id;
      const name = `${tipologia.fase}-\n${tipologia.titolo}-\n${tipologia.descrizione}`;
      const myData = this.filteredElements.filter(
        (e) => e.tipologiaFase === tipologia
      );
      let res = data.find((x) => x.id == id);
      if (!res) {
        res = {
          id: id,
          "asse-x": name,
          "in-corso": this.countInCorso(myData),
          concluse: this.countConcluse(myData),
          "in-scadenza": this.countInScadenza(myData),
          "in-ritardo": this.countInRitardo(myData, null, null),
          scadute: this.countScadute(myData, null, null),
          future: this.countFuture(myData),
          totale: 0,
          none: 0,
        };
        data.push(res);
      } else {
        res["future"] += this.countFuture(myData);
        res["in-corso"] += this.countInCorso(myData);
        res["concluse"] += this.countConcluse(myData);
        res["in-scadenza"] += this.countInScadenza(myData);
        res["in-ritardo"] += this.countInRitardo(myData, null, null);
        res["scadute"] += this.countScadute(myData, null, null);
      }
      res.totale =
        res["in-corso"] +
        res.concluse +
        res["in-scadenza"] +
        res["in-ritardo"] +
        res["future"] +
        res.scadute;
    });
    // Create chart instance
    this.chartTipologieFasi = am4core.create("chart4", am4charts.XYChart);

    this.chartTipologieFasi.scrollbarX = new am4core.Scrollbar();

    // Add chart title
    const title = this.chartTipologieFasi.titles.create();

    this.aggiungiLogo(this.chartTipologieFasi);

    title.marginBottom = 10;
    title.text = "Fasi per tipologie (Quantità)";

    // Add data
    this.chartTipologieFasi.data = data;

    // Create axes
    const categoryAxis = this.chartTipologieFasi.xAxes.push(
      new am4charts.CategoryAxis()
    );
    categoryAxis.dataFields.category = "asse-x";
    categoryAxis.renderer.grid.template.location = 0;

    let label = categoryAxis.renderer.labels.template;

    label.truncate = true;
    label.maxWidth = 40;
    label.fontSize = 12;
    label.tooltipText = "[font-size:12px]{category}";

    categoryAxis.tooltip.label.maxWidth = 600;
    categoryAxis.tooltip.label.wrap = true;

    categoryAxis.events.on("sizechanged", function (ev) {
      const axis = ev.target;
      const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      axis.renderer.labels.template.maxWidth = cellWidth;
    });
    const valueAxis = this.chartTipologieFasi.yAxes.push(
      new am4charts.ValueAxis()
    );
    valueAxis.renderer.inside = false;
    valueAxis.renderer.labels.template.disabled = false;
    valueAxis.min = 0;
    valueAxis.calculateTotals = true;
    valueAxis.extraMax = 0.1; // aggiungo un 10% sopra l'ultima colonna
    const that = this;
    createSeries("future", "future");
    createSeries("in-corso", "in corso");
    createSeries("concluse", "concluse");
    createSeries("in-scadenza", "in scadenza");
    createSeries("in-ritardo", "in ritardo");
    createSeries("scadute", "scadute");

    // Create series
    function createSeries(field, name) {
      // Set up series
      const series = that.chartTipologieFasi.series.push(
        new am4charts.ColumnSeries()
      );
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "asse-x";
      series.sequencedInterpolation = true;

      // Make it stacked
      series.stacked = true;

      // Configure columns
      series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText =
        "[bold]{name}: {valueY}[/]\n[font-size:14px]{categoryX}";

      series.tooltip.label.wrap = true;
      series.tooltip.label.width = 900;

      // Add label
      const labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;

      return series;
    }
    const totalSeries = this.chartTipologieFasi.series.push(
      new am4charts.ColumnSeries()
    );
    totalSeries.dataFields.valueY = "none";
    totalSeries.dataFields.categoryX = "asse-x";
    totalSeries.stacked = true;
    totalSeries.hiddenInLegend = true;
    totalSeries.columns.template.strokeOpacity = 0;

    const totalBullet = totalSeries.bullets.push(new am4charts.LabelBullet());
    totalBullet.dy = -20;
    totalBullet.label.text = "Totale: {valueY.total}";
    totalBullet.label.hideOversized = false;
    totalBullet.label.fontSize = 18;
    totalBullet.label.background.fill = totalSeries.stroke;
    totalBullet.label.background.fillOpacity = 0;
    totalBullet.label.padding(5, 10, 5, 10);
    // Legend
    this.chartTipologieFasi.legend = new am4charts.Legend();

    this.chartTipologieFasi.events.on("ready", () => {
      const asse = that.chartTipologieFasi.xAxes.values[0];
      const catCount = that.chartTipologieFasi.data.length;
      if (catCount > 10) asse.zoomToIndexes(0, 10);
      this.showChart4 = true;
    });

    const markerTemplate = this.chartTipologieFasi.legend.markers.template;
    markerTemplate.width = 14;
    markerTemplate.height = 14;

    this.exportChart(this.chartTipologieFasi, title.text);
  }

  loadElencoClassificazioni() {
    const that = this;
    this.classificazioniService
      .getClassificazioni(this.filterClassificazione)
      .subscribe((result) => {
        that.elencoClassificazioni = result;
        that.filter(false, false);
      });
  }

  setCodiciClassificazioni() {
    let classificazioni = [];

    this.filteredElements.forEach((i) => {
      classificazioni = [...classificazioni, ...i.classificazioni];
    });
    this.classifications = classificazioni;

    const classificazioniArray = [];

    this.classifications.forEach((c) => {
      const classificazioneTmp = this.elencoClassificazioni.find(
        (x) => x.id == c.id
      );
      const classificazione = {
        codice: c.codice,
        idTipologia: c.idTipoClassificazione,
        descrizione: classificazioneTmp ? classificazioneTmp.descrizione : "",
      };
      if (
        !classificazioniArray.find((x) => x.codice === classificazione.codice)
      ) {
        classificazioniArray.push(classificazione);
      }
    });

    this.classificationsCodes = [...new Set(classificazioniArray)];
    if (this.filterClassificazione >= 0) {
      this.classificationsCodes = this.classificationsCodes.filter((c) => {
        if (c.idTipologia === this.filterClassificazione) return c;
      });
    }

    return this.classificationsCodes;
  }

  setClassificazioniPerGrafici() {
    const that = this;
    that.setCodiciClassificazioni();
    that.classificazioniGrafico = [];
    that.filteredElements.forEach((e) => {
      e.classificazioni.forEach((c) => {
        that.classificationsCodes.forEach((code) => {
          if (code.codice && c.codice === code.codice) {
            const classif = new Classificazione();
            classif.codice = code.codice;
            classif.descrizione = code.descrizione;
            classif.count++;
            classif.inCorso = that.countInCorso([e]);
            classif.concluse = that.countConcluse([e]);
            classif.inScadenza = that.countInScadenza([e]);
            classif.future = that.countFuture([e]);
            classif.inRitardo = that.countInRitardo([e], null, null);
            classif.scadute = that.countScadute([e], null, null);
            if (!that.classificazioniGrafico[code.codice]) {
              that.classificazioniGrafico[code.codice] = new Classificazione();
            }
            that.classificazioniGrafico[code.codice].count += classif.count;
            that.classificazioniGrafico[code.codice].codice = code.codice;
            that.classificazioniGrafico[code.codice].descrizione =
              code.descrizione;
            that.classificazioniGrafico[code.codice].importoI +=
              classif.importoI;
            that.classificazioniGrafico[code.codice].importoF +=
              classif.importoF;
            const diff = classif.importoI - classif.importoF;
            that.classificazioniGrafico[code.codice].differenza += diff;
            that.classificazioniGrafico[code.codice].concluse +=
              classif.concluse;
            that.classificazioniGrafico[code.codice].future += classif.future;
            that.classificazioniGrafico[code.codice].inCorso += classif.inCorso;
            that.classificazioniGrafico[code.codice].inRitardo +=
              classif.inRitardo;
            that.classificazioniGrafico[code.codice].inScadenza +=
              classif.inScadenza;
            that.classificazioniGrafico[code.codice].scadute += classif.scadute;
          }
        });
      });
    });

    const data = [];
    for (let elementName in that.classificazioniGrafico) {
      const element = that.classificazioniGrafico[elementName];

      const obj = {};
      obj["asse-x"] = element.codice;
      obj["totale"] = element.count;
      obj["concluse"] = element.concluse;
      obj["future"] = element.future;
      obj["in-corso"] = element.inCorso;
      obj["in-ritardo"] = element.inRitardo;
      obj["in-scadenza"] = element.inScadenza;
      obj["scadute"] = element.scadute;
      obj["none"] = 0;
      data.push(obj);
    }
    return data;
  }

  renderChart6() {
    // Create chart instance
    const data = this.setClassificazioniPerGrafici();
    this.chartTipologieFasi = am4core.create("chart6", am4charts.XYChart);

    this.chartTipologieFasi.scrollbarX = new am4core.Scrollbar();

    // Add chart title
    const title = this.chartTipologieFasi.titles.create();

    this.aggiungiLogo(this.chartTipologieFasi);

    title.marginBottom = 10;
    title.text = "Fasi per classificazioni (Quantità)";

    // Add data
    this.chartTipologieFasi.data = data;

    // Create axes
    const categoryAxis = this.chartTipologieFasi.xAxes.push(
      new am4charts.CategoryAxis()
    );
    categoryAxis.dataFields.category = "asse-x";
    categoryAxis.renderer.grid.template.location = 0;

    const label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.maxWidth = 120;
    label.fontSize = 12;

    const valueAxis = this.chartTipologieFasi.yAxes.push(
      new am4charts.ValueAxis()
    );
    valueAxis.renderer.inside = false;
    valueAxis.renderer.labels.template.disabled = false;
    valueAxis.min = 0;
    valueAxis.calculateTotals = true;
    valueAxis.extraMax = 0.1; // aggiungo un 10% sopra l'ultima colonna
    const that = this;
    createSeries("future", "future");
    createSeries("in-corso", "in corso");
    createSeries("concluse", "concluse");
    createSeries("in-scadenza", "in scadenza");
    createSeries("in-ritardo", "in ritardo");
    createSeries("scadute", "scadute");

    // Create series
    function createSeries(field, name) {
      // Set up series
      const series = that.chartTipologieFasi.series.push(
        new am4charts.ColumnSeries()
      );
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "asse-x";
      series.sequencedInterpolation = true;

      // Make it stacked
      series.stacked = true;

      // Configure columns
      series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText =
        "[bold]{name}: {valueY}[/]\n[font-size:14px]{categoryX}";
      series.columns.template.column.fillOpacity = 0.8;

      // Add label
      const labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;

      return series;
    }
    const totalSeries = this.chartTipologieFasi.series.push(
      new am4charts.ColumnSeries()
    );
    totalSeries.dataFields.valueY = "none";
    totalSeries.dataFields.categoryX = "asse-x";
    totalSeries.stacked = true;
    totalSeries.hiddenInLegend = true;
    totalSeries.columns.template.strokeOpacity = 0;

    const totalBullet = totalSeries.bullets.push(new am4charts.LabelBullet());
    totalBullet.dy = -20;
    totalBullet.label.text = "Totale: {valueY.total}";
    totalBullet.label.hideOversized = false;
    totalBullet.label.fontSize = 18;
    totalBullet.label.background.fill = totalSeries.stroke;
    totalBullet.label.background.fillOpacity = 0;
    totalBullet.label.padding(5, 10, 5, 10);
    // Legend
    this.chartTipologieFasi.legend = new am4charts.Legend();

    const markerTemplate = this.chartTipologieFasi.legend.markers.template;
    markerTemplate.width = 14;
    markerTemplate.height = 14;

    this.exportChart(this.chartTipologieFasi, title.text);
  }

  /*TABELLA DI DETTAGLIO*/

  datiDettaglioCaricati = false;

  async executeGetTabellaDettaglioObjAsync(renderData: boolean) {
    this.datiDettaglioCaricati = false;
    let mainType = "";
    let idFin = -1;
    let idInt = -1;
    let idPro = -1;
    if (this.completerObject) {
      switch (this.completerObject.parentType) {
        case "INTERVENTO":
          idInt = this.completerObject.interventoId;
          break;
        case "PROGETTO":
          idPro = this.completerObject.interventoId;
          break;
        case "FINANZIAMENTO":
          idFin = this.completerObject.finanziamentoId;
          break;
      }
    }
    if (this.filterMain) {
      switch (this.filterMain) {
        case "i":
          mainType = "INTERVENTI";
          break;
        case "p":
          mainType = "PROGETTI";
          break;
        case "f":
          mainType = "FINANZIAMENTI";
          break;
      }
    }

    let dataPrevistaInizio = this.myForm.value.dataPrevistaInizio;
    let dataPrevistaFine = this.myForm.value.dataPrevistaFine;
    let dataInizio = this.myForm.value.dataInizio;
    let dataFine = this.myForm.value.dataFine;
    if (dataPrevistaInizio)dataPrevistaInizio = [moment(dataPrevistaInizio[0]).format('YYYY-MM-DD'),moment(dataPrevistaInizio[1]).format('YYYY-MM-DD')];
    if (dataPrevistaFine)dataPrevistaFine = [moment(dataPrevistaFine[0]).format('YYYY-MM-DD'),moment(dataPrevistaFine[1]).format('YYYY-MM-DD')];
    if (dataInizio)dataInizio = [moment(dataInizio[0]).format('YYYY-MM-DD'),moment(dataInizio[1]).format('YYYY-MM-DD')];
    if (dataFine)dataFine = [moment(dataFine[0]).format('YYYY-MM-DD'),moment(dataFine[1]).format('YYYY-MM-DD')];

    console.log("carico dettaglio");
    this.dashboardService
      .getDashboardFasiDetailTableData(
        idPro,
        idInt,
        idFin,
        this.isSisma ? this.getAreaSismaId() : this.filterArea,
        this.filterClassificazione,
        mainType,
        this.filterTipologiaResponsabile,
        this.filterTipologiaFasi,
        this.filterStatoFasi,
        this.completerSoggettiId,
        this.filterFlussoDiCassa,
        -1,
        this.filterPnrr,
        this.filterLineeFinanziamentiPnrr,
        this.filterSoggettoAttuatore,
        this.filterRp,
        this.filterRup,
        this.filterComune,
        dataPrevistaInizio,
        dataPrevistaFine,
        dataInizio,
        dataFine,
        this.filterTipologieFaseFase,
        this.filterTipologieFaseTitolo
      )
      .subscribe((result) => {
        console.log("dettaglio caricato dal server");
        this.datiDettaglioCaricati = true;
        if (renderData) {
          this.getTabellaDettaglioObj(result);
        }
      });
  }

  tabellaDettaglioObj: any;

  subjectTabDettaglio = new Subject<any>();
  observable$ = this.subjectTabDettaglio.asObservable();
  tabellaDettaglioObjLoaded = false;

  getTabellaDettaglioObj(obj) {
    this.tabellaDettaglioObj = obj;
    this.subjectTabDettaglio.next(this.tabellaDettaglioObj);
  }
  loadingExportExcel = false;

  esportaExcel() {
    let mainType = "";
    let idFin = -1;
    let idInt = -1;
    let idPro = -1;
    if (this.completerObject) {
      switch (this.completerObject.parentType) {
        case "INTERVENTO":
          idInt = this.completerObject.interventoId;
          break;
        case "PROGETTO":
          idPro = this.completerObject.interventoId;
          break;
        case "FINANZIAMENTO":
          idFin = this.completerObject.finanziamentoId;
          break;
      }
    }
    if (this.filterMain) {
      switch (this.filterMain) {
        case "i":
          mainType = "INTERVENTI";
          break;
        case "p":
          mainType = "PROGETTI";
          break;
        case "f":
          mainType = "FINANZIAMENTI";
          break;
      }
    }

let dataPrevistaInizio = this.myForm.value.dataPrevistaInizio;
    let dataPrevistaFine = this.myForm.value.dataPrevistaFine;
    let dataInizio = this.myForm.value.dataInizio;
    let dataFine = this.myForm.value.dataFine;
    if (dataPrevistaInizio)dataPrevistaInizio = [moment(dataPrevistaInizio[0]).format('YYYY-MM-DD'),moment(dataPrevistaInizio[1]).format('YYYY-MM-DD')];
    if (dataPrevistaFine)dataPrevistaFine = [moment(dataPrevistaFine[0]).format('YYYY-MM-DD'),moment(dataPrevistaFine[1]).format('YYYY-MM-DD')];
    if (dataInizio)dataInizio = [moment(dataInizio[0]).format('YYYY-MM-DD'),moment(dataInizio[1]).format('YYYY-MM-DD')];
    if (dataFine)dataFine = [moment(dataFine[0]).format('YYYY-MM-DD'),moment(dataFine[1]).format('YYYY-MM-DD')];

    this.loadingExportExcel = true;
    this.dashboardService
      .getDashboardFasiDetailTableDataExport(
        idPro,
        idInt,
        idFin,
        this.isSisma ? this.getAreaSismaId() : this.filterArea,
        this.filterClassificazione,
        mainType,
        this.filterTipologiaResponsabile,
        this.filterTipologiaFasi,
        this.filterStatoFasi,
        this.completerSoggettiId,
        this.filterFlussoDiCassa,
        -1,
        this.filterPnrr,
        this.filterLineeFinanziamentiPnrr,
        this.filterSoggettoAttuatore,
        this.filterRp,
        this.isSisma,
        this.filterRup,
        this.filterComune,
        dataPrevistaInizio,
        dataPrevistaFine,
        dataInizio,
        dataFine,
        this.filterTipologieFaseFase,
        this.filterTipologieFaseTitolo
      )
      .subscribe(
        (res) => {
          const filename = `esportazione_fasi_${moment(new Date()).format(
            "YYYY_MM_DDTHH_mm_ss"
          )}.xlsx`;
          FileSaver.saveAs(res.body, `${filename}`);
          this.loadingExportExcel = false;
        },
        (error) => {
          this.toastr.error(
            `Errore: ${
              error.error.message
                ? error.error.message
                : error.error.error_description
                ? error.error.error_description
                : error.error
            }`,
            null,
            {
              timeOut: 2000,
              disableTimeOut: false,
            }
          );
          this.loadingExportExcel = false;
        }
      );
  }

  isCustomHeader() {
    return this.authService.getHeaderCode() != "generale";
  }
  getHeaderLabel() {
    return this.isCustomHeader()
      ? "Piano Nazionale Ripresa e Resilienza"
      : "Direzione Regionale Infrastrutture e Mobilità";
  }
  isGr34() {
    return this.authService.isGr34();
  }
  isAdmin() {
    return this.authService.isAdmin();
  }
  setRP($event: any) {
    this.filterRp = $event ? $event.id : -1;
  }

  setRUP($event: any) {
    this.filterRup = $event ? $event.id : -1;
  }

  setComune($event: any) {
    this.filterComune = $event ? $event.id : -1;
  }

  municipalities = [];
  getAllMunicipalities() {
    this.geoService.getMunicipalitiesWithIstat("12").subscribe((res) => {
      this.municipalities = res.map((x) => {
        return {
          id: x["id"],
          label: x["name"],
        };
      });
    });
  }


  tipologieFase = [];
  TipologieFasiTitoli = [];
  tipologieFasiFasi = [];
  tipologiaFaseFaseChangeValue($event){
    console.log($event);
    this.tipologieFase = [];
    this.filterTipologieFaseFase = $event;
    if (!$event){
        this.TipologieFasiTitoli = [];
        return;
    }
    this.f.tipologia.setValue(null);
    this.f.tipologiaFaseTitolo.setValue(null);
    this.TipologieFasiTitoli =  [...new Set(this.tipologieFasiCombo.filter(x=>x.fase ==$event).map(x=>x.titolo))];
    
}
tipologiaFaseTitoloChangeValue($event){
    console.log($event);
    this.filterTipologieFaseTitolo = $event;
    if (!$event){
        this.tipologieFase = [];
        return;
    }
    this.f.tipologia.setValue(null);
    const fase =this.f.tipologiaFaseFase.value;        
    this.tipologieFase = this.tipologieFasiCombo.filter(x=>x.fase == fase && x.titolo == $event);
    
}

}
