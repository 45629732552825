export class SubIntervento{
    id: number;

    codice: string;

    rup: any;
    descrizione: string;
    importoQte: number;
    importoAggiudicazione: number;
    dataStipula: Date;
    dataConsegna: Date;
    durataContrattuale: number;
    fineLavori: Date;
    statoAttuazione: any;
    importoSal: number;
    dataConclusione: Date;
    note: string;
    intervento: any;
    isDeleted: boolean;
}