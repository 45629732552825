import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
export class DashboardPnrrNonDisegnabileService {
    public onRendering: EventEmitter<boolean> = new EventEmitter<boolean>();

    public doRendering(value:boolean) {
        // do something, then...
        this.onRendering.emit(value);
    }
}