import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AuthService, ToastService } from "src/app/services";
import { ServiceType } from "../../models/service-type";
import { FinanziamentoService, InterventoService, ProgettoService } from "../../services";
import { Intervento } from '../../models/intervento';
import { SicerImpegnoAccertamento } from "../../models/sicer-impegno-accertamento";
import { IsDirtyHelper } from "src/app/helpers/is-dirty.helper";

@Component({
  selector: 'sicer-bilancio-component',
  templateUrl: './sicer-bilancio.component.html',
  styleUrls: ['./sicer-bilancio.component.css']
})
export class SicerBilancioComponent implements OnInit {

  constructor(
    private finanziamentoService: FinanziamentoService,
    private interventoService: InterventoService,
    private progettoService: ProgettoService,
    private authService: AuthService,
    private toastr: ToastService,
    private isDirtyHelper: IsDirtyHelper
  ) { }

  loading: boolean = false;
  loadDataCounter = 0;
  section = '';
  modalSubscriptions = [];
  readOnlyForm = false;

  interventi: Intervento[];
  esistonoInterventi = false;
  tabsOnOneRow = true;

  dataForReload = { finanziamentoId: null, interventoId: null, progettoId: null };

  toSaveImpAccs: SicerImpegnoAccertamento[] = [];
  toDeleteImpAccs: SicerImpegnoAccertamento[] = [];

  baseImpAccs: SicerImpegnoAccertamento[];

  @Input()
  type: ServiceType;

  private _finanziamentoId: number;
  @Input()
  set finanziamentoId(value) {
    this.section = 'Finanziamenti';
    !value ? this._finanziamentoId = null : null;
    this.dataForReload.finanziamentoId = value;
    this.dataForReload.interventoId = value ? null : this.dataForReload.interventoId;
    this.dataForReload.progettoId = null;
    this.esistonoInterventi = false;
    this.tabsOnOneRow = true;
  }
  get finanziamentoId() {
    return this._finanziamentoId;
  }

  private _interventoId: number;
  @Input()
  set interventoId(value) {
    this.section = 'Interventi';
    !value ? this._interventoId = null : null;
    this.dataForReload.interventoId = value;
    this.dataForReload.finanziamentoId = value ? null : this.dataForReload.finanziamentoId;
    this.esistonoInterventi = false;
    this.tabsOnOneRow = true;
  }
  get interventoId() {
    return this._interventoId;
  }

  progettoId: number;

  ngOnInit(): void {}

  reloadAllData() {
    this._interventoId = this.dataForReload.interventoId;
    this._finanziamentoId = this.dataForReload.finanziamentoId;
    this.esistonoInterventi = false;
    if (this.finanziamentoId) this.getFinanziamentoData(this.finanziamentoId);
    if (this.interventoId) this.getInterventoData(this.interventoId);
  }

  resetSubData() {
    this._finanziamentoId = null;
    this.progettoId = null;
    this._interventoId = null;
    this.toSaveImpAccs = [];
    this.toDeleteImpAccs = [];
  }

  getFinanziamentoData(id: number) {
    this.loadDataCounter++;
    this.finanziamentoService.getById(id).subscribe(result => {
      this.loadDataCounter--;
    });
  }

  getInterventoData(id: number) {
    this.loadDataCounter++;
    this.loading = true;
    this.interventoService.get(id).subscribe(
      result => {
        this.loading = false;
        this.readOnlyForm = result.isReadOnly;
        this.loadDataCounter--;
        this.progettoId = this.type === ServiceType.INTERVENTO ? result.progettoId : this.progettoId;
        if (result.isMain) {
          if (!this.interventi) {
            this.progettoService.getInterventi(result.progettoId).subscribe(
              res => {
                this.interventi = res;
                this.esistonoInterventi = res.length - 1 > 0;
                this.tabsOnOneRow = this.esistonoInterventi;
              }
            );
          }
        }
      });
  }

  @Input()
  gotoBtnOptions: any;

  @Output()
  gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
  gotoBackBtnClick() {
    this.gotoBtnFunction.emit(true);
  }

  _isInModal: boolean = false;
  @Input()
  set isInModal(value: boolean) {
    this._isInModal = value;
  }
  get isInModal() { return this._isInModal; }

  /**
    * Controlla se l'utente può accedere o meno alla sezione.
    * @param section il nome della sezione
    * @param operations le operazioni da poter effettuare sulla sezione
    * @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
    * @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
    * @returns true se il controllo è andato a buon fine, false altrimenti
  */
  enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
    return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
  }
  isGruppoPnrr() {
    return this.authService.isGruppoPnrr();
  }
  isAdmin() {
    return this.authService.isAdmin();
  }
  isGr34() {
    return this.authService.isGr34();
  }

  //variabile che contiene i capitoli legati al finanziamento o intervento
  capitoliSelezionati = [];

  canEdit() {
    return (this.enableSection(this.section, ['update']));
  }

  onSubmit() {
    let dataCount = (this.toSaveImpAccs.length > 0 ? 1 : 0) + (this.toDeleteImpAccs.length > 0 ? 1 : 0);
    switch (this.type) {
      case ServiceType.FINANZIAMENTO:
        if (this.toSaveImpAccs.length > 0) {
          this.finanziamentoService.saveMassiveImpAccs(this.finanziamentoId, this.toSaveImpAccs).subscribe(res => {
            dataCount--;
            dataCount === 0
              ? this.completeUpdateImpAccs('Accertamenti')
              : null;
            this.toSaveImpAccs = [];
          });
        }
        if (this.toDeleteImpAccs.length > 0) {
          this.finanziamentoService.deleteMassiveImpAccs(this.finanziamentoId, this.toDeleteImpAccs).subscribe(res => {
            dataCount--;
            dataCount === 0
              ? this.completeUpdateImpAccs('Accertamenti')
              : null;
            this.toDeleteImpAccs = [];
          });
        }
        break;
      default:
        if (this.toSaveImpAccs.length > 0) {
          this.interventoService.saveMassiveImpAccs(this.interventoId, this.toSaveImpAccs).subscribe(res => {
            dataCount--;
            dataCount === 0
              ? this.completeUpdateImpAccs('Impegni')
              : null;
            this.toSaveImpAccs = [];
          });
        }
        if (this.toDeleteImpAccs.length > 0) {
          this.interventoService.deleteMassiveImpAccs(this.interventoId, this.toDeleteImpAccs).subscribe(res => {
            dataCount--;
            dataCount === 0
              ? this.completeUpdateImpAccs('Impegni')
              : null;
            this.toDeleteImpAccs = [];
          });
        }
        break;
    }
  }

  private completeUpdateImpAccs(msgToast: string) {
    this.baseImpAccs = this.baseImpAccs.concat(this.toSaveImpAccs);
    this.baseImpAccs = this.baseImpAccs.filter(impAcc => !this.toDeleteImpAccs.find(elem => elem.pkImpacc === impAcc.pkImpacc));
    this.toastr.success(msgToast + ' aggiornati con successo');
    this.isDirtyHelper.isDirty = false;
  }

  checkDisabled(): boolean {
    return this.toSaveImpAccs.length === 0 && this.toDeleteImpAccs.length === 0;
  }

  manageUpdateImpAccs($event: { impAccs: SicerImpegnoAccertamento[], type: string }) {
    switch ($event.type) {
      case 'binding':
        this.toSaveImpAccs = this.toSaveImpAccs.concat($event.impAccs);
        this.toDeleteImpAccs = this.toDeleteImpAccs.filter(impAcc => !$event.impAccs.find(elem => elem.pkImpacc === impAcc.pkImpacc));
        break;
      case 'unbinding':
        this.toDeleteImpAccs = this.toDeleteImpAccs.concat($event.impAccs);
        this.toSaveImpAccs = this.toSaveImpAccs.filter(impAcc => !$event.impAccs.find(elem => elem.pkImpacc === impAcc.pkImpacc));
        break;
    }

    this.filterImpAccs();
    this.checkIsDirty();
  }

  getBaseImpAccs($event: SicerImpegnoAccertamento[]) {
    this.baseImpAccs = JSON.parse(JSON.stringify($event));
    this.filterImpAccs();
  }

  private filterImpAccs() {
    this.toSaveImpAccs = this.toSaveImpAccs.filter(impAcc => !this.baseImpAccs.find(elem => elem.pkImpacc === impAcc.pkImpacc));
    this.toDeleteImpAccs = this.toDeleteImpAccs.filter(impAcc => this.baseImpAccs.find(elem => elem.pkImpacc === impAcc.pkImpacc));
  }

  updateImpAccsByPkCpt($event: string) {
    this.toSaveImpAccs = this.toSaveImpAccs.filter(impAcc => impAcc.pkCpt !== $event);
    this.toDeleteImpAccs = this.toDeleteImpAccs.filter(impAcc => impAcc.pkCpt !== $event);

    this.checkIsDirty();
  }

  private checkIsDirty() {
    this.isDirtyHelper.isDirty = this.toSaveImpAccs.length > 0 || this.toDeleteImpAccs.length > 0;
  }
}
