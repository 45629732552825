import { InterventoService } from "src/app/modules/inframob/services";
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { ServiceType } from "../../../models/service-type";
import { FinanziamentoService } from "../../../services";
import { ToastService } from "src/app/services";
import { SicerImpegnoAccertamento } from "../../../models/sicer-impegno-accertamento";
import * as moment from "moment";
import { Intervento } from "../../../models";

//jQuery
declare var $: any;

@Component({
  selector: "app-sicer-bilancio-accertamenti",
  templateUrl: "./sicer-bilancio-accertamenti.component.html",
  styleUrls: ["./sicer-bilancio-accertamenti.component.css"],
})
export class SicerBilancioAccertamentiComponent implements OnInit {
  @Input()
  impAccsSelezionati: SicerImpegnoAccertamento[];
  @Input()
  finanziamentoId: number;
  @Input()
  interventoId: number;
  @Input()
  interventi: Intervento[];
  @Input()
  type: ServiceType;
  @Output()
  disassociaAccertamentiEmitter = new EventEmitter<{
    impAccs: SicerImpegnoAccertamento[];
    codCpt: string;
  }>();

  allAccertamenti: SicerImpegnoAccertamento[] = null;
  loadingImpegni = true;

  constructor(
    private toastr: ToastService,
    private finanziamentoService: FinanziamentoService,
    private interventoService: InterventoService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    switch (true) {
      case changes.impAccsSelezionati !== undefined:
        this.impAccsSelezionati = this.impAccsSelezionati
          ? this.impAccsSelezionati.sort(
              (a, b) =>
                a.codCpt.localeCompare(b.codCpt) ||
                a.pkImpacc.localeCompare(b.pkImpacc)
            )
          : null;
        this.loadingImpegni = !this.impAccsSelezionati ? true : false;
        break;
    }
  }

  disassociaSicerImpegnoAccertamento(data: SicerImpegnoAccertamento) {
    let id = null;
    let service = null;
    switch (this.type) {
      case ServiceType.FINANZIAMENTO:
        service = this.finanziamentoService;
        id = this.finanziamentoId;
        break;
      case ServiceType.PROGETTO:
      case ServiceType.PROGETTOINTERVENTO:
      case ServiceType.INTERVENTO:
        service = this.interventoService;
        id = this.interventoId;
        break;
    }

    service.disassociaSicerImpegnoAccertamento(id, data.pkImpacc).subscribe(
      (resp) => {
        this.toastr.success(
          this.type === ServiceType.FINANZIAMENTO
            ? "Accertamento rimosso con successo"
            : "Impegno rimosso con successo",
          null,
          {
            timeOut: 2000,
            disableTimeOut: false,
          }
        );
        this.disassociaAccertamentiEmitter.emit({
          impAccs: resp,
          codCpt: data.codCpt,
        });
      },
      (error) => {
        this.toastr.error("Errore");
      }
    );
  }

  fetchLiquidazioniOrdinativi(data: SicerImpegnoAccertamento[]) {
    data.forEach((elem) => {
      const acc = this.impAccsSelezionati.find(
        (impAcc) => impAcc.id === elem.id
      );
      if (acc) {
        const numOrdine =
          acc.minimoOrdineModifica === 0
            ? acc.isChangeSoggetto
              ? -1
              : 0
            : acc.minimoOrdineModifica;
        this.type === ServiceType.FINANZIAMENTO
          ? this.finanziamentoService
              .getSicerOrdinativiByPkImpaccAndCodFiscSoggetto(
                acc.pkImpacc,
                acc.codFiscSoggetto
              )
              .subscribe((resp) => {
                acc.ordinativi = resp;
              })
          : this.type === ServiceType.PROGETTO ||
            this.type === ServiceType.INTERVENTO
          ? this.interventoService
              .getSicerLiquidazioniByInterventoIdAndPkImpaccAndRagsocSoggetto(
                this.interventoId,
                acc.pkImpacc,
                acc.ragsocSoggetto,
                acc.codSoggetto,
                numOrdine
              )
              .subscribe((resp) => {
                resp.sort(
                  (a, b) =>
                    a.numLiq.localeCompare(b.numLiq) ||
                    a.codCpt.localeCompare(b.codCpt)
                );
                acc.liquidazioni = resp;
                acc.liquidazioni.forEach((liqui) => {
                  this.interventoService
                    .getSicerLiquidazioniOrdinativiByPkImpaccAndNumLiq(
                      acc.pkImpacc,
                      liqui.numLiq
                    )
                    .subscribe((res) => {
                      liqui.ordinativi = res;
                    });
                });
              })
          : null;
      }
    });
  }

  checkHeaderByService(): string {
    let total = 0;
    let totalLiquidazioni = 0;
    let totalOrdinativi = 0;
    if (this.impAccsSelezionati) {
      this.impAccsSelezionati.forEach((impAcc) => {
        if (!impAcc.annoPerenzione) {
          total += impAcc.valoreAttualeImpacc;
          if (this.type === ServiceType.FINANZIAMENTO) {
            impAcc.ordinativi?.forEach((ord) => {
              totalOrdinativi += ord.importoScadenza;
            });
          } else {
            impAcc.liquidazioni?.forEach((liq) => {
              totalLiquidazioni += liq.importoScadenza;
              liq.ordinativi?.forEach((ord) => {
                totalOrdinativi += ord.importoScadenza;
              });
            });
          }
        }
      });
    }
    return `${
      this.type === ServiceType.FINANZIAMENTO ? "Accertamenti" : "Impegni"
    } selezionati:
            ${this.insertDotForThousands(total)} € <a title="Somma dell'importo attuale degli ${
              this.type === ServiceType.FINANZIAMENTO
                ? "accertamenti"
                : "impegni"
            } selezionati">
              <i class="fas fa-question-circle small" aria-hidden="true"></i>
            </a>
            
            
            ${
              this.type === ServiceType.FINANZIAMENTO
                ? `ordinativi:
                
                ${this.insertDotForThousands(
                  totalOrdinativi
                )} € <a title="Somma dell'importo degli ordinativi">
                <i class="fas fa-question-circle small" aria-hidden="true"></i>
              </a>`
                : `liquidazioni:
                

                
                ${this.insertDotForThousands(
                  totalLiquidazioni
                )} € <a title="Somma dell'importo delle liquidazioni">
                <i class="fas fa-question-circle small" aria-hidden="true"></i>
              </a>
                ordinativi:
                
                ${this.insertDotForThousands(
                  totalOrdinativi
                )} € <a title="Somma dell'importo degli ordinativi">
                <i class="fas fa-question-circle small" aria-hidden="true"></i>
              </a>`
            }`;
  }

  getLiquidazioniOrdinativiByService(): string {
    return this.type === ServiceType.FINANZIAMENTO
      ? "Ordinativi"
      : "Liquidazioni";
  }

  checkServiceTypeFinanziamento(): boolean {
    return this.type === ServiceType.FINANZIAMENTO;
  }

  checkServiceTypeInterventoOrProgetto(): boolean {
    return (
      this.type === ServiceType.INTERVENTO || this.type === ServiceType.PROGETTO
    );
  }

  checkDisabledShowLiquidazioniOrdinativi(
    accertamento: SicerImpegnoAccertamento
  ): boolean {
    return (
      !accertamento ||
      !accertamento[
        this.checkServiceTypeFinanziamento() ? "ordinativi" : "liquidazioni"
      ] ||
      accertamento[
        this.checkServiceTypeFinanziamento() ? "ordinativi" : "liquidazioni"
      ].length === 0
    );
  }

  checkTitleButton(accertamento: SicerImpegnoAccertamento): string {
    const isExpanded = this.checkButtonIsExpanded(accertamento);
    return this.checkDisabledShowLiquidazioniOrdinativi(accertamento)
      ? this.checkServiceTypeFinanziamento()
        ? "Non sono presenti ordinativi"
        : "Non sono presenti liquidazioni"
      : this.checkServiceTypeFinanziamento()
      ? isExpanded
        ? "Collassa ordinativi"
        : "Espandi ordinativi"
      : isExpanded
      ? "Collassa liquidazioni"
      : "Espandi liquidazioni";
  }

  checkButtonIsExpanded(accertamento: SicerImpegnoAccertamento): any {
    const checkExp = JSON.parse($("#" + accertamento.id).attr("aria-expanded"));
    return checkExp ? JSON.parse(checkExp) : false;
  }

  insertDotForThousands(baseValue: number): string {
    if (baseValue === null || baseValue === undefined) return "";

    const value = baseValue.toFixed(2).toString().replace(".", ",");
    return value ? value.replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ".") : "";
  }

  getDataFormat(date: Date): string {
    return date ? moment(date).format("DD/MM/YYYY") : "";
  }

  getLabelFieldsByType(): string {
    return this.type === ServiceType.FINANZIAMENTO ? "Acc." : "Imp.";
  }

  setTargetAndControl(accertamento: SicerImpegnoAccertamento): string {
    return "#collapse" + accertamento.id;
  }

  getTitleByType(): string {
    return this.type === ServiceType.FINANZIAMENTO
      ? "rimuovi accertamento"
      : "rimuovi impegno";
  }

  removeFocus(id: number) {
    setTimeout(() => {
      $("#" + id).blur();
    }, 0);
  }

  getLabelSoggettoByType() {
    return this.type === ServiceType.FINANZIAMENTO ? "Debitore" : "Creditore";
  }
}
