import {
  Component,
  EventEmitter,
  OnInit,
  OnDestroy,
  Input,
  Output,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { AuthService, ToastService } from "src/app/services";
import {
  FinanziamentoService,
  InterventoService,
  SicerBilancioService,
  ProgettoService,
  SicerService,
} from "../../../services";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { GenericSelectionModalComponent } from "../../genericSelectionModal/generic-selection-modal.component";
import { SicerCapitolo } from "../../../models/sicer-capitolo";
import { ServiceType } from "../../../models/service-type";
import { SicerImpegnoAccertamento } from "../../../models/sicer-impegno-accertamento";
import { SicerBilancioAccertamentiComponent } from "../sicer-bilancio-accertamenti/sicer-bilancio-accertamenti.component";
import { Intervento } from "../../../models/intervento";

@Component({
  selector: "sicer-bilancio-capitolo-component",
  templateUrl: "./sicer-bilancio-capitolo.component.html",
  styleUrls: ["./sicer-bilancio-capitolo.component.css"],
})
export class SicerBilancioCapitoloComponent implements OnInit, OnDestroy {
  @Input()
  type: ServiceType;
  @Input()
  interventi: Intervento[];
  @Input()
  toDeleteImpAccs: SicerImpegnoAccertamento[];
  @Output()
  impAccsUpdateEmitter = new EventEmitter<{
    impAccs: SicerImpegnoAccertamento[];
    type: string;
  }>();
  @Output()
  baseImpAccsEmitter = new EventEmitter<SicerImpegnoAccertamento[]>();
  @Output()
  dissassociaCapitoloEmitter = new EventEmitter<string>();

@Input()readOnlyForm:boolean=false;

  @ViewChild(SicerBilancioAccertamentiComponent, { static: false })
  sicerBilancioAccertamentiComponent: SicerBilancioAccertamentiComponent;

  @ViewChild("extraRichiestaSicerTemplate", { static: true })
  public extraRichiestaSicerTemplate: TemplateRef<any>;

  loading: boolean = false;
  loadDataCounter = 0;
  section = "";
  modalSubscriptions = [];
  modalRef: BsModalRef;

  impAccsSelezionati: SicerImpegnoAccertamento[] = null;

  constructor(
    private toastr: ToastService,
    private finanziamentoService: FinanziamentoService,
    private interventoService: InterventoService,
    private authService: AuthService,
    private modalService: BsModalService,
    private sicerBilancioService: SicerBilancioService,
    private progettoService: ProgettoService,
    private sicerService: SicerService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  clearSubscriptions() {
    this.modalSubscriptions.forEach((x) => x.unsubscribe());
    this.modalSubscriptions = [];
  }

  private _finanziamentoId: number;
  @Input()
  set finanziamentoId(value) {
    this._finanziamentoId = value;
    this.section = "Finanziamenti";
    this._interventoId = value ? null : this._interventoId;
    if (!value) return;
    this.reloadAllData();
  }
  get finanziamentoId() {
    return this._finanziamentoId;
  }

  private _interventoId: number;
  @Input()
  set interventoId(value) {
    this._interventoId = value;
    this.section = "Interventi";
    this._finanziamentoId = value ? null : this._finanziamentoId;
    if (!value || (this.type === ServiceType.INTERVENTO && !this.subProgettoId))
      return;
    this.reloadAllData();
  }
  get interventoId() {
    return this._interventoId;
  }

  private _subProgettoId: number;
  @Input()
  set subProgettoId(value) {
    this._subProgettoId = value;
    this.section = "Interventi";
    this._finanziamentoId = null;
    if (!value || !this.interventoId) return;
    this.reloadAllData();
  }
  get subProgettoId() {
    return this._subProgettoId;
  }

  progettoId: number;

  reloadAllData() {
    this.capitoliSelezionati = [];
    if (this.finanziamentoId)
      this.getCapitoliByFinanziamentoId(this.finanziamentoId);
    if (this.interventoId) this.getCapitoliByInterventoId(this.interventoId);
  }

  // variabile che contiene i capitoli legati al finanziamento o intervento
  capitoliSelezionati: SicerCapitolo[] = [];

  openModalSelezionaCapitolo() {
    const modalDefinition = {
      columns: [
        {
          name: "id",
          prop: "id",
          visible: false,
          sortable: true,
          filtrable: true,
          width: 10,
          resizeable: false,
        },
        {
          name: "codice",
          prop: "codCpt",
          sortable: true,
          filtrable: true,
          resizeable: false,
          draggable: false,
          flexGrow: 1,
          minWidth: 65,
        },
        {
          name: "Verso",
          prop: "versoCpt",
          filtrable: true,
          sortable: true,
          visible: false,
        },
        {
          name: "descrizione",
          prop: "descrCpt",
          sortable: true,
          filtrable: true,
          resizeable: false,
          draggable: false,
          flexGrow: 2,
          minWidth: 100,
        },
      ],
      ajax: this.getCapitoliDt,
      rows: [],
      totalElements: 0,
      columnMode: ColumnMode.flex,
      headerHeight: "50",
      footerHeight: "50",
      rowHeight: "auto",
      externalSorting: true, // true
      loadingIndicator: "loading",
      class: "bootstrap",
      summaryRow: true,
      summaryPosition: "'bottom'",
      externalPaging: true, // true
      count: 0,
      offset: 0,
      limit: 10,
      serverSide: true,
      scrollbarH: "true",
      extraRichiestaSicerTemplate: this.extraRichiestaSicerTemplate,
    };

    const config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: "modal-xl",
    };
    const initialState = {
      modalDefinition: { pageTitle: "Seleziona i capitoli" },
      dtOptionsNgx: modalDefinition,
    };

    this.modalRef = this.modalService.show(
      GenericSelectionModalComponent,
      Object.assign({}, config, { initialState })
    );
    this.clearSubscriptions();
    const subscription = this.modalRef.content.onSubmitOk.subscribe(
      (data: SicerCapitolo) => {
        if (!this.capitoliSelezionati) this.capitoliSelezionati = [];
        const existsObj = this.capitoliSelezionati.find(
          (x) => x.id === data.id
        );
        if (existsObj) {
          // capitolo già aggiunto
          this.toastr.error("Capitolo già inserito!", null, {
            timeOut: 2000,
            disableTimeOut: false,
          });
          return;
        }
        this.capitoliSelezionati.push(data);
        this.capitoliSelezionati.sort((a, b) =>
          a.codCpt.localeCompare(b.codCpt)
        );

        this.toastr.success("Capitolo inserito con successo", null, {
          timeOut: 2000,
          disableTimeOut: false,
        });
        this.getScrittureBilancio(data.codCpt);
        this.bindCapitoloToEntitaPrincipale(data);
        this.getImpegniAccertamenti(data);
      }
    );
    this.modalSubscriptions.push(subscription);
  }

  getCapitoliDt = (dataTablesParameters: any, callback: any) => {
    const columnVersoCpt = dataTablesParameters.columns.find(
      (x) => x.data === "versoCpt"
    );
    if (columnVersoCpt) {
      columnVersoCpt.search.value = this._finanziamentoId ? "E" : "U";
    }

    if (this.type === ServiceType.INTERVENTO) {
      !this.progettoId
        ? this.getMainInterventoByProjectId(this.subProgettoId, (id) => {
            this.progettoId = id;
            this.sicerBilancioService
              .getAllCapitoliDt(dataTablesParameters, this.progettoId)
              .subscribe((resp) => {
                callback({
                  recordsTotal: resp.recordsTotal,
                  recordsFiltered: resp.recordsFiltered,
                  data: resp.data,
                });
              });
          })
        : this.sicerBilancioService
            .getAllCapitoliDt(dataTablesParameters, this.progettoId)
            .subscribe((resp) => {
              callback({
                recordsTotal: resp.recordsTotal,
                recordsFiltered: resp.recordsFiltered,
                data: resp.data,
              });
            });
    } else {
      this.sicerBilancioService
        .getAllCapitoliDt(dataTablesParameters, -1)
        .subscribe((resp) => {
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: resp.data,
          });
        });
    }
  };

  getMainInterventoByProjectId(id, callback) {
    this.progettoService.get(id).subscribe((result) => {
      callback(result.id);
    });
  }

  getScrittureBilancio = (codiceCapitolo: string) => {
    this.loadDataCounter++;
    this.sicerBilancioService
      .getAllScrittureBilancio(codiceCapitolo)
      .subscribe((resp) => {
        resp = resp.sort((a, b) =>
          a.annoEsercizio < b.annoEsercizio ? 1 : -1
        );
        this.loadDataCounter--;
        for (const capitolo of this.capitoliSelezionati) {
          const scritture = resp.filter((x) => x.codCpt === capitolo.codCpt);
          if (scritture && scritture.length > 0) {
            capitolo.scrittureBilancio = [];
            for (const scrittura of scritture) {
              let anno = capitolo.scrittureBilancio.find(
                (x) => x.annoEsercizio === scrittura.annoEsercizio
              );
              if (!anno) {
                anno = {
                  annoEsercizio: scrittura.annoEsercizio,
                  importoCompN: 0.0,
                  importoCassaN: 0.0,
                  importoCompN1: 0.0,
                  importoCompN2: 0.0,
                };
                capitolo.scrittureBilancio.push(anno);
              }
              anno.importoCassaN +=
                scrittura.importoCassaN !== "-" ? scrittura.importoCassaN : 0;
              anno.importoCompN +=
                scrittura.importoCompN !== "-" ? scrittura.importoCompN : 0;
              anno.importoCompN1 +=
                scrittura.importoCompN1 !== "-" ? scrittura.importoCompN1 : 0;
              anno.importoCompN2 +=
                scrittura.importoCompN2 !== "-" ? scrittura.importoCompN2 : 0;
            }
          }
        }
      });
  };

  getImpegniAccertamenti(capitolo: SicerCapitolo) {
    let type = null;
    let id = null;
    switch (this.type) {
      case ServiceType.FINANZIAMENTO:
        type = "FINANZIAMENTO";
        id = this.finanziamentoId;
        break;
      case ServiceType.PROGETTO:
        type = "PROGETTO";
        id = this.interventoId;
        break;
      case ServiceType.PROGETTOINTERVENTO:
        type = "PROGETTOINTERVENTO";
        id = this.interventoId;
        break;
      case ServiceType.INTERVENTO:
        type = "INTERVENTO";
        id = this.progettoId;
        break;
    }
    if (this.type === ServiceType.INTERVENTO) {
      !this.progettoId
        ? this.getMainInterventoByProjectId(
            this.subProgettoId,
            (id: number) => {
              this.progettoId = id;
              this.executeGetImpegniAccertamenti(
                capitolo,
                type,
                this.progettoId,
                this.interventoId
              );
            }
          )
        : this.executeGetImpegniAccertamenti(
            capitolo,
            type,
            this.progettoId,
            this.interventoId
          );
    } else {
      this.executeGetImpegniAccertamenti(capitolo, type, id, -1);
    }
  }

  executeGetImpegniAccertamenti(
    capitolo: SicerCapitolo,
    type: string,
    id: number,
    currId: number
  ) {
    this.sicerBilancioService
      .getImpegniAccertamentiByCodiceCapitoloAndId(
        capitolo.codCpt,
        type,
        id,
        currId
      )
      .subscribe((resp) => {
        resp.sort(
          (a, b) =>
            a.codCpt.localeCompare(b.codCpt) ||
            a.pkImpacc.localeCompare(b.pkImpacc)
        );
        capitolo.impegniAccertamenti = resp;
      });
  }

  getCapitoliByFinanziamentoId = (finanziamentoId) => {
    this.loadDataCounter++;
    this.finanziamentoService
      .getSicerCapitoliBilancioByFinanziamentoId(finanziamentoId)
      .subscribe((resp) => {
        this.loadDataCounter--;
        this.capitoliSelezionati = resp;
        for (const capitolo of this.capitoliSelezionati) {
          this.getScrittureBilancio(capitolo.codCpt);
          this.getImpegniAccertamenti(capitolo);
        }
      });

    this.getImpegniAccertamentiByFinanziamentoId(finanziamentoId);
  };

  private sortImpegniAccertamenti() {
    this.impAccsSelezionati
      ? (this.impAccsSelezionati = this.impAccsSelezionati.sort(
          (a, b) =>
            a.codCpt.localeCompare(b.codCpt) ||
            a.pkImpacc.localeCompare(b.pkImpacc)
        ))
      : null;
  }

  getImpegniAccertamentiByFinanziamentoId(finanziamentoId: number) {
    this.finanziamentoService
      .getSicerAccertamentiSelezionatiByFinanziamentoId(finanziamentoId)
      .subscribe((resp) => {
        this.toDeleteImpAccs.length > 0
          ? (resp = resp.filter(
              (impAcc) =>
                !this.toDeleteImpAccs.find(
                  (elem) => elem.pkImpacc === impAcc.pkImpacc
                )
            ))
          : null;
        this.impAccsSelezionati = resp;
        this.baseImpAccsEmitter.emit(resp);
        this.sortImpegniAccertamenti();

        this.impAccsSelezionati.forEach((acc) => {
          this.finanziamentoService
            .getSicerOrdinativiByPkImpaccAndCodFiscSoggetto(
              acc.pkImpacc,
              acc.codFiscSoggetto
            )
            .subscribe((resp) => {
              acc.ordinativi = resp;
            });
        });
      });
  }

  getCapitoliByInterventoId = (interventoId) => {
    this.loadDataCounter++;
    this.interventoService
      .getSicerCapitoliBilancioByInterventoId(interventoId)
      .subscribe((resp) => {
        this.loadDataCounter--;
        this.capitoliSelezionati = resp;
        for (const capitolo of this.capitoliSelezionati) {
          this.getScrittureBilancio(capitolo.codCpt);
          this.getImpegniAccertamenti(capitolo);
        }
      });

    this.getImpegniAccertamentiByInterventoId(this.interventoId);
  };

  getImpegniAccertamentiByInterventoId(interventoId: number) {
    if (!interventoId) return;

    this.interventoService
      .getSicerImpegniSelezionatiByInterventoId(interventoId)
      .subscribe((resp) => {
        this.toDeleteImpAccs.length > 0
          ? (resp = resp.filter(
              (impAcc) =>
                !this.toDeleteImpAccs.find(
                  (elem) => elem.pkImpacc === impAcc.pkImpacc
                )
            ))
          : null;
        this.impAccsSelezionati = resp;
        this.baseImpAccsEmitter.emit(resp);
        this.sortImpegniAccertamenti();

        if (
          this.type === ServiceType.INTERVENTO ||
          this.type === ServiceType.PROGETTO
        ) {
          this.impAccsSelezionati.forEach((acc) => {
            const numOrdine =
              acc.minimoOrdineModifica === 0
                ? acc.isChangeSoggetto
                  ? -1
                  : 0
                : acc.minimoOrdineModifica;
            this.interventoService
              .getSicerLiquidazioniByInterventoIdAndPkImpaccAndRagsocSoggetto(
                interventoId,
                acc.pkImpacc,
                acc.ragsocSoggetto,
                acc.codSoggetto,
                numOrdine
              )
              .subscribe((resp) => {
                acc.liquidazioni = resp;
              });
          });
        }
      });
  }

  bindImpegnoAccertamento(impAccs: SicerImpegnoAccertamento[]) {
    impAccs.forEach((elem) => {
      elem.interventoId = this.interventoId;
      elem.finanziamentoId = this.finanziamentoId;
      this.impAccsSelezionati.push(elem);
    });
    this.sicerBilancioAccertamentiComponent?.fetchLiquidazioniOrdinativi(
      impAccs
    );
    this.sortImpegniAccertamenti();
    this.impAccsUpdateEmitter.emit({ impAccs, type: "binding" });
  }

  unbindImpegnoAccertamento(data: {
    impAccs: SicerImpegnoAccertamento[];
    codCpt: string;
  }) {
    data.impAccs.forEach((elem) => {
      this.updateImpegniAccertamenti(elem, this.impAccsSelezionati);
      const cap = data.codCpt
        ? this.capitoliSelezionati.find((cap) => cap.codCpt === data.codCpt)
        : null;
      cap
        ? this.updateImpegniAccertamenti(elem, cap.impegniAccertamenti, false)
        : null;
    });
    this.impAccsUpdateEmitter.emit({
      impAccs: data.impAccs,
      type: "unbinding",
    });
  }

  massiveBindImpegniAccertamenti(impAccs: SicerImpegnoAccertamento[]) {
    impAccs.forEach((data) => {
      data.interventoId = this.interventoId;
      data.finanziamentoId = this.finanziamentoId;
      this.impAccsSelezionati.push(data);
    });
    this.impAccsUpdateEmitter.emit({ impAccs, type: "binding" });
    this.sicerBilancioAccertamentiComponent?.fetchLiquidazioniOrdinativi(
      impAccs
    );
    this.sortImpegniAccertamenti();
  }

  massiveUnbindImpegniAccertamenti(impAccs: SicerImpegnoAccertamento[]) {
    impAccs.forEach((elem) => {
      this.updateImpegniAccertamenti(elem, this.impAccsSelezionati);
    });
    this.impAccsUpdateEmitter.emit({ impAccs, type: "unbinding" });
  }

  private updateImpegniAccertamenti(
    elem: SicerImpegnoAccertamento,
    vector: any[],
    removeElement: boolean = true
  ) {
    const elems = vector?.filter((impAcc) => impAcc.pkImpacc === elem.pkImpacc);
    elems.forEach((val) => {
      val.interventoId = null;
      val.finanziamentoId = null;
      const index = vector.map((acc) => acc.id).indexOf(val.id);
      index >= 0 ? (vector[index].interventoId = null) : null;
      index >= 0 ? (vector[index].finanziamentoId = null) : null;
      index >= 0 && removeElement ? vector.splice(index, 1) : null;
    });
  }

  manageDisassociaCapitolo($event: string) {
    this.dissassociaCapitoloEmitter.emit($event);
    this.capitoliSelezionati = this.capitoliSelezionati.filter(
      (cap) => cap.pkCpt !== $event
    );
    this.type === ServiceType.FINANZIAMENTO
      ? this.getImpegniAccertamentiByFinanziamentoId(this.finanziamentoId)
      : this.getImpegniAccertamentiByInterventoId(this.interventoId);
  }

  bindCapitoloToEntitaPrincipale = (data: any) => {
    const service = this._finanziamentoId
      ? this.finanziamentoService
      : this.interventoService;
    const id = this._finanziamentoId
      ? this._finanziamentoId
      : this._interventoId;
    this.loadDataCounter++;
    service.associaSicerCapitolo(id, data.pkCpt).subscribe((resp) => {
      this.loadDataCounter--;
    });
  };

  getCapitoloLabelByTipe(): string {
    return this.type === ServiceType.FINANZIAMENTO ? "di Entrata" : "di Uscita";
  }

  checkVisibileImpAccSelezionati(): boolean {
    return this.type !== ServiceType.PROGETTOINTERVENTO;
  }

  checkRecapTotali(): boolean {
    return (
      this.type === ServiceType.PROGETTOINTERVENTO &&
      this.capitoliSelezionati.length >= 1
    );
  }

  getRecapData() {
    return `IMPORTO: 
            <span class="bold">${this.insertDotForThousands(
              this.getTotaleImporti()
            )} € <a title="Somma dell'importo attuale degli impegni selezionati">
            <i class="fas fa-question-circle small" aria-hidden="true"></i>
          </a></span>`;
  }

  getTotaleImporti() {
    let total = 0;
    this.impAccsSelezionati?.forEach((impAcc) => {
      total +=
        impAcc.interventoId && !impAcc.annoPerenzione
          ? impAcc.valoreAttualeImpacc
          : 0;
    });
    return total;
  }

  async requestCodiceCapitolo(){
    let result = await prompt("Inserisci il codice capitolo");
    if(result){
      this.sicerService.requestCapitalCode(result).subscribe(res => {
        console.log(res);
        if(!res) this.toastr.error(
            "Errore Generale",
            null,
            { 
              timeOut: 4000, 
              disableTimeOut: false, 
            }
        );

        else if(res.esito == "KO") this.toastr.error(
            res.errore,
            null,
            { 
              timeOut: 4000, 
              disableTimeOut: false, 
            }
        );

        else if(res.errore == null) this.toastr.success(
            res.esito,
            null, 
            {
              timeOut: 4000,
              disableTimeOut: false,
            }
        );
      },
      (err) => {
        console.log(err);
      })
    }
  }

  insertDotForThousands(baseValue: number): string {
    if (baseValue === null || baseValue === undefined) return "";

    const value = baseValue.toFixed(2).toString().replace(".", ",");
    return value ? value.replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ".") : "";
  }

  /**
   * Controlla se l'utente può accedere o meno alla sezione.
   * @param section il nome della sezione
   * @param operations le operazioni da poter effettuare sulla sezione
   * @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
   * @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
   * @returns true se il controllo è andato a buon fine, false altrimenti
   */
  enableSection(
    section: string,
    operations: string[],
    atLeastOne: boolean = false,
    enableOnNotFound: boolean = true
  ): boolean {
    return this.authService.checkPermissionBySectionAndOperations(
      section,
      operations,
      atLeastOne,
      enableOnNotFound
    );
  }
  isGruppoPnrr() {
    return this.authService.isGruppoPnrr();
  }
  isAdmin() {
    return this.authService.isAdmin();
  }
  isGr34() {
    return this.authService.isGr34();
  }
  canEdit() {
    return this.enableSection(this.section, ["update"]);
  }
}
