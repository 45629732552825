import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { TipologiaSismaService } from "../../services/tipologia-sisma.service";
import { CategoriaSismaService } from "../../services/categoria-sisma.service";
import { DatiSismaService } from "../../services/dati-sisma.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IsDirtyHelper } from "../../../../helpers/is-dirty.helper";
import { ToastService } from "src/app/services/toast.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { InterventoService, StorageService } from "../../services";
import { AuthService } from "src/app/services";

@Component({
  selector: "app-interventi-sisma",
  templateUrl: "./interventi-sisma.component.html",
  styleUrls: ["./interventi-sisma.component.css"],
})
export class InterventiSismaComponent implements OnInit {
    section: string = 'Interventi';
  tipologieSisma = [];
  categorieSisma = [];
  soggettiResp = [];
  _datiSisma: any;
  myForm: FormGroup;
  submitted: boolean = false;

  modalRef: BsModalRef;
  open: boolean = false;
  loading: boolean = false;
  readOnlyForm:boolean = false;

  private _interventoId: number;
  @Input()
  set interventoId(value) {
    if (!value) return;
    this._interventoId = value;
    if (this.tipologieSisma && this.tipologieSisma.length > 0)
        this.getInterventoData();
  }

  get interventoId() {
    return this._interventoId;
  }

  @Input()
  gotoBtnOptions: any;

  @Output()
  gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
  gotoBackBtnClick() {
    this.gotoBtnFunction.emit(true);
  }

  constructor(
    private el: ElementRef,
    private toastr: ToastService,
    private datiSismaService: DatiSismaService,
    private formBuilder: FormBuilder,
    private isDirtyHelper: IsDirtyHelper,
    private tipologiaSismaService: TipologiaSismaService,
    private categoriaSismaService: CategoriaSismaService,
    private storageService:StorageService,
    private interventoService:InterventoService,
    private authService:AuthService
  ) {
    this.myForm = this.initializeFormGroup();
  }

  ngOnInit(): void {
    this.tipologiaSismaService.getAll().subscribe((resp) => {
      this.tipologieSisma = resp;
      this.categoriaSismaService.getAll().subscribe((resp) => {
        this.categorieSisma = resp;

        this.storageService.elencoRup.subscribe(
          x => {
              this.soggettiResp = x;               
              console.log(this.soggettiResp);
              if (this._interventoId)
              this.getInterventoData();
          }
      );  
    });
  });
}

  


  getInterventoData(){
    if (this._interventoId)
    this.interventoService.get(this._interventoId).subscribe(
        result => {
            this.loading = false;
            this.readOnlyForm = result.isReadOnly;
            this.readOnlyForm = this.readOnlyForm || !this.canEdit();
            this.datiSismaService
            .getDataByInterventoId(this._interventoId)
            .subscribe((resp) => {
              this._datiSisma = resp;
              this.fillForm();
            });
        });
  }

  initializeFormGroup() {
    let frm = this.formBuilder.group({
      tipologie: [null],
      categorie: [null],
      soggettiResp: [null]
    });
    frm.valueChanges.subscribe((val) => this.setIsDirty(frm.dirty));
    return frm;
  }

  _isInModal: boolean = false;
  @Input()
  set isInModal(value: boolean) {
    this._isInModal = value;
  }
  get isInModal() {
    return this._isInModal;
  }

  reloadAllData() {
    this.fillForm();
    this.isDirtyHelper.isDirty = false;
  }

  set datiSisma(value: any) {
    this._datiSisma = value;
    if (!value) return;
  }

  fillForm() {
    if (this._datiSisma) {
      this.myForm.setValue({
        tipologie: this._datiSisma["tipologiaSisma"]?this._datiSisma["tipologiaSisma"].id:null,
        categorie: this._datiSisma["categoriaSisma"]? this._datiSisma["categoriaSisma"].id:null,
        soggettiResp: this._datiSisma["soggettoResp"]?this._datiSisma["soggettoResp"].id:null
      });
    }
    if (this.readOnlyForm)this.myForm.disable();
    else this.myForm.enable();
  }

  get f() {
    return this.myForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.myForm.updateValueAndValidity();
    let errors = false;
    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }
    if (this.myForm.invalid || errors) {
      console.log("invalid");
      let invalidElements = [];
      for (let el in this.myForm.controls) {
        if (this.myForm.controls[el].invalid) {
          console.log(`set focus on ${el}`);
          if (this.myForm.controls[el] instanceof FormGroup) {
            for (let el1 in this.myForm.get(el)["controls"]) {
              if (this.myForm.get(el)["controls"][el1].invalid) {
                invalidElements = this.el.nativeElement.querySelectorAll(
                  `[formcontrolname="${el1}"]`
                );
                break;
              }
            }
          } else {
            invalidElements = this.el.nativeElement.querySelectorAll(
              `[formcontrolname="${el}"]`
            );
          }
          break;
        }
      }
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }
      console.log(this.f);
      return false;
    }

    let data = {
      id: this._datiSisma ? this._datiSisma["id"] : null,
      tipologiaSisma: this.myForm.value.tipologie?{ id: this.myForm.value.tipologie }:null,
      categoriaSisma: this.myForm.value.categorie?{ id: this.myForm.value.categorie }:null,
      soggettoResp:this.myForm.value.soggettiResp?{id:this.myForm.value.soggettiResp}:null,
      intervento: { id: this._interventoId },
    };

    this.loading = true;

    this.datiSismaService.save(data, this._interventoId).subscribe(
      (res) => {
        this.loading = false;
        this.toastr.success("Dati sisma salvati con successo!");
        this.submitted = false;
        this.open = false;
        this.myForm.reset();
        this.myForm.setValue({
          tipologie: data.tipologiaSisma?data.tipologiaSisma.id:null,
          categorie: data.categoriaSisma?data.categoriaSisma.id:null,
          soggettiResp: data.soggettoResp?data.soggettoResp.id:null
        });
        this.datiSismaService.getDataByInterventoId(this._interventoId).subscribe((resp) => {
          this._datiSisma = resp;
        });
      },
      (error) => {
        this.loading = false;
        this.toastr.error("Errore di sistema");
      }
    );
  }

  setIsDirty(value: boolean) {
    this.isDirtyHelper.isDirty = value;
  }

  closeModal() {
    this.submitted = false;
    this.open = false;
    this.myForm.reset();
    this.modalRef.hide();
  }
  canEdit() {
    return (this.enableSection(this.section, ['update'], null, true));
}


/**
* Controlla se l'utente può accedere o meno alla sezione.
* @param section il nome della sezione
* @param operations le operazioni da poter effettuare sulla sezione
* @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
* @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
* @returns true se il controllo è andato a buon fine, false altrimenti
*/
enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
    return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
}
isGruppoPnrr() {
    return this.authService.isGruppoPnrr();
}

isAdmin() {
    return this.authService.isAdmin();
}
isGr34(){
    return this.authService.isGr34();
}
}
