import { Component, OnInit } from "@angular/core";
import { AuthService, UserService } from "src/app/services/index";
import { Router } from "@angular/router";
import { MenuSelectedService } from "src/app/services/menu-selected.service";
import { TitleLogoService } from "src/app/services/title-logo.service";
import { JwtUser } from "src/app/models/jwt-user";

@Component({
  selector: 'app-bar-menu',
  templateUrl: './bar-menu.component.html',
  styleUrls: ['./bar-menu.component.css']
})
export class BarMenuComponent implements OnInit {

  menuItems = [];
  headerCode = null;
  currentUser: JwtUser;
  title: string;
  ente: any;

  constructor(
    private authService: AuthService,
    public router: Router,
    private userService: UserService,
    private menuSelectedService: MenuSelectedService,
    private titleLogoService: TitleLogoService
  ) {
    this.currentUser = this.authService.getCurrentUser();
    this.menuItems = this.authService.getMenu();
    this.headerCode = this.authService.getHeaderCode();
    if (!this.menuItems || this.menuItems.length <= 0) {
      this.userService.getInfo('').subscribe(
        res => {
          this.authService.setMenu(res['menu']);
          this.menuItems = this.authService.getMenu();
          this.menuItems = this.menuItems.filter(x=>this.canAccess(x));
          this.authService.setHeaderCode(res['headerCode']);
          this.headerCode = this.authService.getHeaderCode();
        }
      );
    }
  }

  ngOnInit(): void {
    this.title = this.titleLogoService.title;
    this.ente = this.titleLogoService.ente;
  }

  isHeaderCustom() {
    return (this.headerCode && this.headerCode !== 'generale');
  }

  goToHome(): void {
    this.router.navigate(['/']);
  }

  gotoRowMenu(item: any) {
    this.menuSelectedService.notifier.next(item.title);
  }

  canAccess(item) {
    if (item.permissions && item.permissions.length > 0) {
      if (this.currentUser.authorities == null) return false;
      let ris = false;

      item.permissions.forEach((element) => {
        ris = ris || this.currentUser.authorities.includes(element);
        if (ris) return;
      });
      return ris;
    }
    return true;
  }
}
