import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { SicerImpegnoAccertamento } from 'src/app/modules/inframob/models/sicer-impegno-accertamento';
import { SicerLiquidazione } from 'src/app/modules/inframob/models/sicer-liquidazione';
import { SicerOrdinativo } from 'src/app/modules/inframob/models/sicer-ordinativo';
import { ServiceType } from '../../../../models/service-type';

@Component({
  selector: 'app-dettaglio-ordinativo',
  templateUrl: './dettaglio-ordinativo.component.html',
  styleUrls: ['./dettaglio-ordinativo.component.css']
})
export class DettaglioOrdinativoComponent implements OnInit, OnChanges {

  @Input()
  accertamento: SicerImpegnoAccertamento;
  @Input()
  ordinativi: SicerOrdinativo[];
  @Input()
  liquidazione: SicerLiquidazione;
  @Input()
  type: ServiceType;

  disponibilita: number = 0;
  totImportoOrdinativi: number = 0;

  dtOptions: any;

  constructor(
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    switch (true) {
      case changes.ordinativi !== undefined:
        this.totImportoOrdinativi = 0;
        this.ordinativi?.forEach(ord => {
          this.totImportoOrdinativi += ord.importoScadenza;
        });
        this.disponibilita = (this.type === ServiceType.FINANZIAMENTO
                                ? this.accertamento?.valoreAttualeImpacc
                                : this.liquidazione?.importoScadenza) - this.totImportoOrdinativi;
        break;
    }
  }

  createDivId() {
    return this.accertamento
              ? 'collapse' + this.accertamento.id + (this.type === ServiceType.FINANZIAMENTO
                ? ''
                : this.liquidazione
                    ? this.liquidazione.id + (this.ordinativi ? this.ordinativi.length.toString() : '')
                    : '-1')
              : 'collapse-1';
  }

  getValoreAttualeByType() {
    return this.type === ServiceType.FINANZIAMENTO
              ? this.accertamento?.valoreAttualeImpacc
              : this.liquidazione?.importoScadenza;
  }

  getImportoTitleByType(): string {
    return this.type === ServiceType.FINANZIAMENTO
              ? 'VALORE ATTUALE ACCERTAMENTO  '
              : 'VALORE ATTUALE LIQUIDAZIONE';
  }

  insertDotForThousands(baseValue: number): string {
    if (baseValue === null || baseValue === undefined) return '';

    const value = baseValue.toFixed(2).toString().replace('.', ',');
    return value
              ? value.replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
              : '';
  }

  getDataFormat(date: Date): string {
    return date ? moment(date).format('DD/MM/YYYY') : '';
  }

  getLabelFieldsByType(): string {
    return this.type === ServiceType.FINANZIAMENTO ? 'Acc.' : 'Imp.';
  }

  checkFieldShow(): boolean {
    return this.type === ServiceType.INTERVENTO || this.type === ServiceType.PROGETTO;
  }
}
