<div class="p-5 container-xl text-center" [hidden]="loadDataCounter<=0">
  <app-loading message="Caricamento in corso..."></app-loading>
</div>

<div class="container-xl" [hidden]="loadDataCounter>0">



  <div class="   pt-4 pb-4 elemento-aggiorna mb-5 shadow-custom"
    >
    <div class=" row  justify-content-center">
        <div class="col-lg-4 col-md-6 text-center" [hidden]="readOnlyForm || pnrrLineaFinanziamentoId">
            <button [disabled]="loading" (click)="openModalNuovoProvvedimento()" class="btn btn-info btn-block font-weight-bold" type="button">
              Crea nuovo provvedimento&nbsp;<i class="fas fa-plus"></i>
            </button>
          </div>
      <div class="col-lg-4 col-md-6 text-center" [hidden]="readOnlyForm">
        <button [disabled]="loading" (click)="openModalProvvedimenti()" class="btn  btn-primary btn-block" type="button">
          Associa provvedimento&nbsp;<i class="fas fa-link"></i>
        </button>
      </div>
      <!--<div class="col-lg-4 col-md-6 text-center" *ngIf="gotoBtnOptions && gotoBtnOptions.show">
        <button (click)="gotoBackBtnClick()" [disabled]="loading" class="btn btn-secondary btn-block font-weight-bold"
          type="button">
          {{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}
          &nbsp;<i class="fas fa-times" *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i>
        </button>
      </div>-->
    </div>

  </div>



  <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;"
    [dtOptions]="dtOptions">
    <ng-template #btnEditTemplate let-row="row" let-value="value">
      <a [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top"
        title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
    </ng-template>
    <ng-template #btnDetailTemplate let-row="row" let-value="value">
      <a [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onDetail(row)" data-toggle="tooltip" data-placement="top"
        title="dettaglio">&nbsp;&nbsp;&ensp;<i class="far fa-eye pointer detail-icon"></i></a>
    </ng-template>
    <ng-template #btnDeleteTemplate let-row="row" let-value="value">
      <a [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top"
        title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
    </ng-template>
  </ngx-datatable-wrapper>
</div>

<ng-template #templateDettaglioProvvedimento>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">{{modalDettaglioTitle}}</h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="closeDettaglioProvvedimentoModal();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <provvedimenti-component (closeModalEmitter)="closeDettaglioProvvedimentoModal($event);" #provvedimentiComponent
      [provvedimentoDaMostrare]="provvedimentoDaMostrare" (saveElementEmitter)="saveNewProvvedimentoEmitter($event)"
      ></provvedimenti-component>
  </div>
</ng-template>
<ng-template #tripletteFieldValueTemplate let-row="row" let-value="value">
    <ul class="pl-2" *ngIf="value && value !=''">
        <li class="" *ngFor="let val of getTriplette(value)">{{val}}</li>
    </ul>
</ng-template>

<ng-template #templateModalPnrrPnc>
    <div class="modal-header  text-center">
      <h4 class="modal-title pull-left">Informazione provvedimento</h4>        
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                Per il provvedimento selezionato sono presenti sia ripartizioni PNRR sia ripartizioni PNC<br />
                Selezionare la tipologia delle ripartizioni
            </div>            
        </div>
        <div class="row">
            <div class="col-6">
                <button  class="btn btn-sm btn-secondary btn-block col-xl-3 col-lg-3 col-md-3 col-sm-12 m-2" (click)="checkAndCreateAnnualitaFromRipartizioniPnrr()">PNRR</button>
            </div>            
            <div class="col-6">
                <button  class="btn btn-sm btn-secondary btn-block col-xl-3 col-lg-3 col-md-3 col-sm-12 m-2" (click)="checkAndCreateAnnualitaFromRipartizioniPnc()">PNC</button>
            </div>            
        </div>       
    </div>    
  </ng-template>