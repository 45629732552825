import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { CurrencyPipe } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy  
} from "@angular/core";
import * as moment from "moment";
@Component({
  selector: "app-chart-gantt",
  templateUrl: "./chart-gantt.component.html",
  styleUrls: ["./chart-gantt.component.css"],
})
export class ChartGanttComponent implements  AfterViewInit, OnDestroy {
  gantt;
  _datChart;
  chartDivName = "";
  element;
  importoTotale = 0;
  importiPerTipologia = [];
  htmlClasses = "bg-white chartfase border shadow-sm my-3 rounded";
  hasPhases = false;
  chartHeigth = "500px";
  fakeStartDate = "2020-12-01"; //Serve se vogliamo farli partire dallo stesso istante
  @Input() set dataChart(element) {
    this.element = element;
  }

  constructor(private cdRef: ChangeDetectorRef) {
    this.chartDivName = this.makeRandomString().toLowerCase();
  }
  ngAfterViewInit(): void {
    const projectTitle = this.element.item.finanziamentoId
      ? ``
      : `${this.element.item.codiceProgetto} - ${this.element.item.titoloProgetto}`;
    const elementTitle = `${this.element.item.codice} - ${this.element.item.titolo}`;
    am4core.options.autoSetClassName = true;
    let phases = this.element.item.fasi;
    
    let tipologie = [];//array di oggetti che ha un campo data e un array di fasi 
    phases = phases.sort((a,b)=>moment(a.dataPrevistaInizio)<moment(b.dataPrevistaInizio)?-1:1);

    //devo gestire l'ordinamento delle tipologie per data prevista inzio, quindi la prima tipologia deve essere quella che inzia prima
    

    this._datChart = phases.map((phase) => {
      const f = {
        "name":     `${phase.tipologiaFase.titolo} - ${phase.tipologiaFase.descrizione} - ${phase.tipologiaFase.fase}`,
        "fromDate" : moment(phase.dataPrevistaInizio, "YYYY-MM-DD").startOf("month").format("YYYY-MM-DD"),
        "toDate" : moment(phase.dataPrevistaFine, "YYYY-MM-DD").add(1, "M").startOf("month").format("YYYY-MM-DD"),
        "color" : phase.coloreTipologia,
        "tipologia" : phase.tipologiaFase.fase,
        "descrizione" : phase.tipologiaFase.descrizione,
        "titolo" : phase.tipologiaFase.titolo,
        "colorFasi" : phase.coloreFasi,
        "importo" : phase.flussoDiCassa? phase.tipologiaFase.tipologia =='entrata' ? phase.previsioneDiImporto        : -1 * phase.previsioneDiImporto      : 0,
        "labelTooltip"    :`${phase.tipologiaFase.titolo} - ${phase.tipologiaFase.descrizione} - ${phase.tipologiaFase.fase}`,
        "fromRealDate" :      phase.dataInizio && moment(phase.dataInizio, "YYYY-MM-DD").startOf("month").format("YYYY-MM-DD"), 
        "toRealDate" :      phase.dataFine &&      moment(phase.dataFine, "YYYY-MM-DD").add(1, "M").startOf("month").format("YYYY-MM-DD"),
        "colorPrevisto" : "#6794dc"
      }; 
      
      f["importoFormattato"]= f["importo"] !== 0 ? this.printValueAsCurrency(f["importo"]) : "";
        

      if ( !f["toRealDate"]) {
        //se c'è' data inizio e data fine è nulla, allora ci metto today
        const today = new Date();
        f["toRealDate"] = moment(today, "YYYY-MM-DD")
          .add(1, "M")
          .startOf("month")
          .format("YYYY-MM-DD");
      }

      
      if(!tipologie[f.tipologia])tipologie[f.tipologia] = {fasi:[],data:moment("01/01/2300","DD/MM/YYYY")};
      tipologie[f.tipologia].fasi.push(f);
      const dataFase = moment(f["fromDate"],'YYYY-MM-DD');
      if (tipologie[f.tipologia].data>dataFase)tipologie[f.tipologia].data = dataFase;
      return f;
    });
    
    this._datChart = [];
    tipologie.sort((a,b)=>a.data-b.data);
    for(let t in tipologie)this._datChart = this._datChart.concat(tipologie[t].fasi);


    if (this._datChart.length > 0) {
      this.hasPhases = true;
        this.renderGantt(
            this._datChart,
            projectTitle,
            elementTitle,           
            this.element.color
        );
    } else {
      this.hasPhases = false;
    }
    this.cdRef.detectChanges();
  }

  

  

  renderGantt(data, projTitle, elementTitle,  parentColor) {
    const that = this;
    /// Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    const rowHeight = data.length * 69;
    const minRowHeight = 600;
    this.chartHeigth =  (rowHeight > minRowHeight ? rowHeight : minRowHeight) + "px";

    this.gantt = am4core.create(`div_${this.chartDivName}`, am4charts.XYChart);
    this.gantt.hiddenState.properties.opacity = 0; // this creates initial fade-in

    this.gantt.paddingRight = 30;
    this.gantt.dateFormatter.inputDateFormat = "yyyy-MM"; //"yyyy-MM-dd HH:mm";

    this.gantt.language.locale = am4lang_it_IT;

    const colorSet = new am4core.ColorSet();
    colorSet.saturation = 0.4;
    const title = this.gantt.titles.create();
    
    const title1 = projTitle;
    const title2 = elementTitle;
    title.text = `${title1.replace(/(\r\n|\n|\r)/gm, "")} \n ${title2.replace(
      /(\r\n|\n|\r)/gm,
      ""
    )} `;
    
    title.fontSize = 16;
    title.marginBottom = 30;
    title.align = "left";

    this.gantt.data = data;
    //sono tutte fasi
    

    const categoryAxis = this.gantt.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.cellStartLocation = 0.0;
    categoryAxis.renderer.cellEndLocation = 1;

    
    const label = categoryAxis.renderer.labels.template;
    
    label.truncate = true;
    label.maxWidth = 303;
    label.fontSize = 12;
    label.tooltipText = "{labelTooltip}";
    categoryAxis.tooltip.label.pointerOrientation = "top";
    categoryAxis.tooltip.label.fontSize = 12;
    categoryAxis.tooltip.label.maxWidth = 160;
    categoryAxis.tooltip.label.wrap = true;

    let dateAxis = this.gantt.xAxes.push(new am4charts.DateAxis());
    dateAxis.dateFormatter.dateFormat = "yyyy-MM";
    dateAxis.renderer.minGridDistance = 70;
    dateAxis.baseInterval = { count: 1, timeUnit: "month" };
    dateAxis.dateFormats.setKey("month", "MMM yyyy");

    
    dateAxis.renderer.tooltipLocation = 0;
    dateAxis.renderer.opposite = true;
    dateAxis.renderer.grid.template.location = 0;

    //DATE PREVISTE
    const series1 = this.gantt.series.push(new am4charts.ColumnSeries());
    series1.columns.template.tooltipText = "{labelTooltip}";
    series1.dataFields.openDateX = "fromDate";
    series1.dataFields.dateX = "toDate";
    series1.dataFields.categoryY = "name";

    series1.columns.template.propertyFields.fill = "colorFasi"; // get color from data
    series1.columns.template.propertyFields.stroke = "colorFasi";
    series1.columns.template.strokeOpacity = 0;
    series1.columns.template.column.fillOpacity = 1;
    series1.columns.template.height = am4core.percent(60);
    series1.zIndex = 10;
    series1.name = "Fasi";
    series1.dataFields.valueY = "{importo}";

    series1.clustered = false;

    this.gantt.scrollbarX = new am4core.Scrollbar();
    this.gantt.scrollbarX.exportable = false;
    this.gantt.scrollbarY = new am4core.Scrollbar();
    this.gantt.scrollbarY.exportable = false;

    //DATE EFFETTIVE
    const series2 = this.gantt.series.push(new am4charts.ColumnSeries());
    series2.columns.template.tooltipText = "{labelTooltip}";
    series2.dataFields.openDateX = "fromRealDate";
    series2.dataFields.dateX = "toRealDate";
    series2.dataFields.categoryY = "name";

    series2.columns.template.propertyFields.fill = "colorPrevisto"; // get color from data
    series2.columns.template.propertyFields.stroke = "colorPrevisto";
    series2.columns.template.strokeOpacity = 0;
    series2.columns.template.column.fillOpacity = 1;
    series2.columns.template.height = am4core.percent(40);
    series2.zIndex = 30;
    series2.name = "Fasi Effettive";
    series2.dataFields.valueY = "{importo}";
    series2.clustered = false;

    const series = [];
    
    function createFill(paramObj:any) {      
      const fillAxis = that.gantt.yAxes.push(new am4charts.CategoryAxis());
      fillAxis.dataFields.category = "name";
      fillAxis.renderer.grid.template.disabled = true;
      fillAxis.renderer.labels.template.disabled = true;
      fillAxis.renderer.inversed = true;
      fillAxis.renderer.cellStartLocation =  categoryAxis.renderer.cellStartLocation;
      fillAxis.renderer.cellEndLocation =   paramObj.span - fillAxis.renderer.cellStartLocation;

      const fillSeries = that.gantt.series.push(new am4charts.ColumnSeries());
      fillSeries.clustered = false;
      fillSeries.yAxis = fillAxis;
      
      fillSeries.columns.template.tooltipText = paramObj.tipologia;

      fillSeries.dataFields.openDateX = paramObj.fromRealDate && paramObj.fromRealDate <= paramObj.date?"fromRealDate":"fromDate";

      fillSeries.dataFields.dateX = paramObj.toRealDate && paramObj.toRealDate <= paramObj.date?"toRealDate":"toDate";

      
      fillSeries.dataFields.categoryY = "name";
      fillSeries.fill = paramObj.color;
      fillSeries.stroke = paramObj.color;
      fillSeries.strokeWidth = 0;
      fillSeries.zIndex = 8;
      fillSeries.data = [
        {
          fromDate: paramObj.openDate,
          toDate: paramObj.date,
          name: paramObj.category,
        },
      ];
      fillSeries.name = paramObj.tipologia;
      fillSeries.columns.template.height = am4core.percent(100);
      if (series[paramObj.tipologia]) {
        fillSeries.hiddenInLegend = true;
        series[paramObj.tipologia].push(fillSeries);
      } else {
        series[paramObj.tipologia] = [fillSeries];
      }

      const bullet = fillSeries.bullets.push(new am4charts.LabelBullet());
      bullet.label.text = paramObj.importo || "";
      bullet.label.fontSize = 16;
      bullet.label.rotation = 0;
      bullet.label.fill = "#333";

      return fillSeries;
    }

//crea lo sfondino blu per l'intervento/progetto/finanziamento
    function createFillParent(code, elementName, date, openDate, color, span) {



        const fillAxis = that.gantt.yAxes.push(new am4charts.CategoryAxis());
        fillAxis.dataFields.category = "name";
        fillAxis.renderer.grid.template.disabled = true;
        fillAxis.renderer.labels.template.disabled = true;
        fillAxis.renderer.inversed = true;
        fillAxis.renderer.cellStartLocation =
        categoryAxis.renderer.cellStartLocation;
        fillAxis.renderer.cellEndLocation =
        span - fillAxis.renderer.cellStartLocation;

        const fillSeries = that.gantt.series.push(new am4charts.ColumnSeries());
        fillSeries.clustered = false;
        fillSeries.yAxis = fillAxis;
        fillSeries.columns.template.height = am4core.percent(100);
        fillSeries.dataFields.openDateX = "fromDate";
        fillSeries.dataFields.dateX = "toDate";
        fillSeries.dataFields.categoryY = "name";
        fillSeries.fill = color;
        fillSeries.stroke = color;
        fillSeries.strokeWidth = 0;
        fillSeries.columns.template.column.fillOpacity = 1;
        fillSeries.data = [
        {
            fromDate: openDate,
            toDate: date,
            name: elementName,
        },
        ];
        fillSeries.name = code;
        fillSeries.columns.template.height = am4core.percent(100);
        fillSeries.hiddenInLegend = true;
      }

    this.aggiungiLogo(this.gantt);

    if (data.length > 0) {
      const tipologie = [];

      data.forEach((element) => {
        
        //mentre ciclo per creare le tipolgie, sistemo la data di fine
        if(element.fromRealDate && !element.toRealDate)
            element.toRealDate = moment(element.fromRealDate ).set("date", 1).add(1, "M").format('YYYY-MM-DD');
    
        let tipologia = tipologie.find((x) => x.tipologia == element.tipologia);

        if (!tipologia) {
          tipologia = {
            name: element.name,
            tipologia: element.tipologia,
            color: element.color,
            fromDate: element.fromDate,
            fromRealDate: element.fromRealDate,
            toDate: element.toDate,
            toRealDate: element.toRealDate,
            rowCount: 1,
          };
          tipologie.push(tipologia);
        } 
        else 
        {
          tipologia.rowCount++;
          tipologia.fromDate = moment
            .min(
              moment(tipologia.fromDate, "YYYY-MM-DD"),
              moment(element.fromDate, "YYYY-MM-DD")
            )
            .format("YYYY-MM-DD");
          tipologia.toDate = moment
            .max(
              moment(tipologia.toDate, "YYYY-MM-DD"),
              moment(element.toDate, "YYYY-MM-DD")
            )
            .format("YYYY-MM-DD");

          tipologia.fromRealDate =
            element.fromRealDate && tipologia.fromRealDate
              ? moment
                  .min(
                    moment(tipologia.fromRealDate, "YYYY-MM-DD"),
                    moment(element.fromRealDate, "YYYY-MM-DD")
                  )
                  .format("YYYY-MM-DD")
              : element.fromRealDate
              ? moment(element.fromRealDate, "YYYY-MM-DD")
              : tipologia.fromRealDate
              ? moment(tipologia.fromRealDate, "YYYY-MM-DD")
              : null;
          tipologia.toRealDate =
            element.toRealDate && tipologia.toRealDate
              ? moment
                  .min(
                    moment(tipologia.toRealDate, "YYYY-MM-DD"),
                    moment(element.toRealDate, "YYYY-MM-DD")
                  )
                  .format("YYYY-MM-DD")
              : element.toRealDate
              ? moment(element.toRealDate, "YYYY-MM-DD")
              : tipologia.toRealDate
              ? moment(tipologia.toRealDate, "YYYY-MM-DD")
              : null;

            if ( tipologia.toRealDate && !(
              typeof tipologia.toRealDate === "string" || tipologia.toRealDate instanceof String
            ) ) {
              tipologia.toRealDate = tipologia.toRealDate.format("YYYY-MM-DD");
            }
          
            if (tipologia.fromRealDate && !(
              typeof tipologia.fromRealDate === "string" || tipologia.fromRealDate instanceof String
            )) {              
              tipologia.fromRealDate =  tipologia.fromRealDate.format("YYYY-MM-DD");
            }          
        }
      });

      tipologie.forEach(
        (element) => {
            let inizio =
            element.fromRealDate && element.fromRealDate <= element.fromDate
                ? moment(element.fromRealDate, "YYYY-MM-DD").set("date", 1)
                : moment(element.fromDate, "YYYY-MM-DD").set("date", 1);
            
            //inizio
            //ottengo le fasi della categoria corrente
            let fasi = data.filter( (d) => d.tipologia === element.tipologia );

            let importoPerMese = fasi.reduce((a, b) => a + b.importo, 0);

            const uniqueNames = new Set(
            data
                .filter((d) => d.tipologia === element.tipologia)
                .map((t) => t.name)
            );
            
            const fromDates = fasi.map(d => d.fromDate );
            const toDates = fasi.map(d => d.toDate);
            const toRealDates = fasi.map(d => d.toRealDate);
            const fromRealDates = fasi.map(d => d.fromRealDate);
            let min, max;
            const all = [...fromDates, ...toDates, ...toRealDates, ...fromRealDates];
            if(all.length > 0){        
                let filtered = all.filter(function (el) {
                    return el != null;
                });
    
                min = filtered.reduce(function (a, b) { return a < b ? a : b; }); 
                max = filtered.reduce(function (a, b) { return a > b ? a : b; });
            }
    


            createFill(
                {
                tipologia:element.tipologia,
                category:element.name,
                date:min,
                openDate:max,
                color:am4core.color(element.color, 0.3),
                span:uniqueNames.size,
                importo:importoPerMese && this.printValueAsCurrency(importoPerMese)
            }
            );
            
            inizio.add(1, "M");
            //fine

            if (element.fromRealDate) {
                let inizioReale = moment(element.fromRealDate, "YYYY-MM-DD").set(
                    "date",
                    1
                );
            let fineReale = element.toRealDate
                ? moment(element.toRealDate, "YYYY-MM-DD").set("date", 1)
                : moment(new Date().toISOString().split("T")[0], "YYYY-MM-DD").set(
                    "date",
                    1
                );
            let first = true;
            fineReale.add(1, "M");
            for (
                ;
                inizioReale < fineReale ||
                (first &&
                inizioReale.format("YYYY-MM") == fineReale.format("YYYY-MM"));

            ) {
                first = false;
                let finetmp = moment(inizioReale);
                finetmp.add(1, "M");
                

                data.filter((d) => {
                if (!d.toRealDate) {
                    d.toRealDate = moment(
                    new Date().toISOString().split("T")[0],
                    "YYYY-MM-DD"
                    )
                    .set("date", 1)
                    .add(1, "M")
                    .format("YYYY-MM-DD");
                }
                d.tipologia === element.tipologia &&
                    ((moment(d.fromRealDate, "YYYY-MM-DD").format("YYYY-MM") ==
                    moment(d.toRealDate, "YYYY-MM-DD").format("YYYY-MM") &&
                    moment(d.fromRealDate, "YYYY-MM-DD").format("YYYY-MM") ==
                        inizio.format("YYYY-MM") &&
                    finetmp.format("YYYY-MM") ==
                        moment(d.toRealDate, "YYYY-MM-DD").format("YYYY-MM")) ||
                    (moment(d.fromRealDate, "YYYY-MM-DD").format("YYYY-MM") !=
                        moment(d.toRealDate, "YYYY-MM-DD").format("YYYY-MM") &&
                        moment(d.fromRealDate, "YYYY-MM-DD") <= inizioReale &&
                        finetmp <= moment(d.toRealDate, "YYYY-MM-DD")));
                });            
                inizioReale.add(1, "M");
            }
            }
      });//fine ciclo per tipologie


      //inizio
      

//FILL intervento / finanziamento
      const fromDates = data.map(d => d.fromDate );
      const toDates = data.map(d => d.toDate);
      const toRealDates = data.map(d => d.toRealDate);
      const fromRealDates = data.map(d => d.fromRealDate);

      const all = [...fromDates, ...toDates, ...toRealDates, ...fromRealDates];

      const filtered = all.filter(function (el) {
        return el != null;
      });

      const min = filtered.reduce(function (a, b) { return a < b ? a : b; }); 
      const max = filtered.reduce(function (a, b) { return a > b ? a : b; });

      createFillParent(
        "",
        data[0].name,
        min,
        max,
        am4core.color(parentColor, 0.1),
        data.length
      );
      //fine


      this.gantt.events.on("ready", function (event) {
        that.gantt.xAxes.values[0].zoomToIndexes(0, 6);
      });

      for (let tipologia in series) {
        let s = series[tipologia];
        s[0].events.on("hidden", function () {
          for (let i = 1; i < s.length; i++) s[i].hide();
        });
        s[0].events.on("shown", function () {
          for (let i = 1; i < s.length; i++) s[i].show();
        });
      }

      this.gantt.legend = new am4charts.Legend();      
    }
    this.exportChart(this.gantt, title.text);
  }

  aggiungiLogo(logoChart) {
    // Add watermark
    let watermark = new am4core.Image();
    watermark.href = "assets/img/logo_blu.svg";
    logoChart.tooltipContainer.children.push(watermark);
    watermark.align = "right";
    watermark.valign = "bottom";
    watermark.opacity = 0.3;
    watermark.marginRight = 10;
    watermark.marginBottom = 5;
    watermark.disabled = true;

    // Enable watermark on export
    logoChart.exporting.events.on("exportstarted", function (ev) {
      watermark.disabled = false;
    });

    // Disable watermark when export finishes
    logoChart.exporting.events.on("exportfinished", function (ev) {
      watermark.disabled = true;
    });

    // Add watermark to validated sprites
    logoChart.exporting.validateSprites.push(watermark);
  }

  exportChart(chart, title) {
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = "right";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.background = "#ff0000";
    chart.exporting.menu.defaultStyles = true;
    chart.exporting.filePrefix = title;
    chart.exporting.menu.items = [
      {
        menu: [
          { type: "jpg", label: "JPG" },
          { type: "png", label: "PNG" },
          { type: "csv", label: "CSV" },
        ],
      },
    ];
    chart.exporting.menu.items[0].icon = "assets/img/export.png";
  }

  printValueAsCurrency(value) {
    value = typeof value === "number" ? value : value.replace(",", ".").trim();
    const format = "1.2-2";
    const currency = "€";
    const currentLocale: string = "it";
    return new CurrencyPipe(currentLocale).transform(
      value,
      "EUR",
      currency,
      format,
      "it-IT"
    );
  }

  makeRandomString() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  ngOnDestroy() {
    this.gantt && this.gantt.dispose();
  }
}
