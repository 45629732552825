import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";


@Injectable({
  providedIn: "root",
})
export class SicerService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}atti/`;
  constructor(private http: HttpClient) {}

  
  getAll(anno:string,numero:string) {
    return this.http.get<any[]>(`${this.apiUrl}?anno=${anno}&numero=${numero}`);
  }
  
    

    download(anno:string,numero:string,direzione:string) {
        return this.http.get(`${this.apiUrl}download?anno=${anno}&numero=${numero}&direzione=${direzione}`, {
          observe: "response",
          responseType: "blob"
        });
      }

      saveAll(mainId:number,tipo:string,sicerAtti:any){
          return this.http.put(`${this.apiUrl}?mainId=${mainId}&tipo=${tipo}`,sicerAtti);
      }

    

    getAllDt(id:number,what,dataTablesParameters: any) {
        return this.http.post<any>(
            `${this.apiUrl}alldt?objectId=${id}&type=${what}`,
            dataTablesParameters
        );
    }

    getAllData(id:number,what:string){
        return this.http.get<any>(
            `${this.apiUrl}all?objectId=${id}&type=${what}`            
        );
    }
    saveNote(id:number,note:string){
        return this.http.put<any>(`${this.apiUrl}${id}/note`,{'note':note});
    }

    delete (idParent:number,what:string,id:number){
        return this.http.delete<any>(`${this.apiUrl}${id}?objectId=${idParent}&type=${what}`);
    }
    
    insert(id,what,item){
        return this.http.post<any>(`${this.apiUrl}?objectId=${id}&type=${what}`, item);
    }
    
    requestCapitalCode(codiceCapitolo:string){
        return this.http.get<any>(`${this.apiUrl}richiesta/capitolo?codiceCapitoloSicerAtti=${codiceCapitolo}`);
    }

    getDatiCruscotto(filterObject:any,dataTablesParameters: any) {
        return this.http.post<any>(
            `${this.apiUrl}cruscotto`,
            {dataTablesParameters,filterObject}
        );
    }
    getExportCruscotto(filterObject:any){
        return this.http.post(
            `${this.apiUrl}cruscotto/export`,{filterObject},
            {
              observe: "response",
              responseType: "blob",
            }
          );
    }

    updateLavorato(id:number){
        return this.http.put(
        `${this.apiUrl}${id}/lavorato`,null
          );
    }
    updateNoteLavorazione(id:number,note:string){
        return this.http.put(
        `${this.apiUrl}${id}/noteLavorazione`,{noteLavorazione:note}
          );
    }

    sendEmailToRup(id:number,body:string,dest:string){
        return this.http.post(
            `${this.apiUrl}${id}/emailToRup`,{body,dest}
              );
    }
    

}
