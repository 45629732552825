import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { CurrencyPipe } from "@angular/common";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { DashboardService } from "../../services";
import * as moment from "moment";
import * as FileSaver from "file-saver";
import { ToastService } from "src/app/services";

@Component({
  selector: "app-erogazioni-charts-components",
  templateUrl: "./erogazioni-charts.component.html",
  styleUrls: ["./erogazioni-charts.component.css"],
})
export class ErogazioniChartsComponent implements OnInit, OnChanges {
  constructor(
    private dashboardService: DashboardService,
    private toastr: ToastService
  ) {}

  @Input() ruoloId: any;
  @Input() soggettoId: any;
  @Input() filterIntervento: any;

  @Input() filterSoggettoGiuridico: any;


  loadingCounter: number = 0;

  detailsObject: any;
  anniArray = [];

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    let that = this;
    if (
      changes["ruoloId"] ||
      changes["soggettoId"] ||
      changes["filterIntervento"] || 
      changes["filterSoggettoGiuridico"]
    ) {
      this.dashboardService
        .getDashboardErogazioniDetails(
          this.ruoloId,
          this.soggettoId,
          this.filterIntervento
        )
        .subscribe((result) => {
          that.anniArray = result.annualita;
          that.detailsObject = result.progetti;
          that.anniArray.sort((a, b) => a - b);
          this.renderChart9();
          this.renderChart8();
        });
    }
  }

  private chart9: am4charts.XYChart;
  private chart8: am4charts.XYChart;

  getSalEmessiSaTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getSalEmessiSa(progetto, anno))
    );
    return somma;
  }

  esporta() {
    let ruoloId = this.ruoloId;
    let soggettoId = this.soggettoId;
    this.dashboardService
        .getDashboardErogazioniDetailsExport(ruoloId, soggettoId,this.filterIntervento)
        .subscribe(
            (res) => {
                let filename = `esportazione_pagamenti_${moment(new Date()).format(
                    "YYYY_MM_DDTHH_mm_ss"
                )}.xlsx`;
                FileSaver.saveAs(res.body, `${filename}`);
            },
            (error) => {
                this.toastr.error(
                    `Errore: ${error.error.message
                        ? error.error.message
                        : error.error.error_description
                            ? error.error.error_description
                            : error.error
                    }`,
                    null,
                    {
                        timeOut: 2000,
                        disableTimeOut: false,
                    }
                );
                console.log("error");
            }
        );
}
  printValueAsCurrency(value) {
    //return this.italianCurrencyPipe.parse(value) + " €";
    value = typeof value === "number" ? value : value.replace(",", ".").trim();
    const format = "1.2-2";
    const currency = "€";
    const currentLocale: string = "it";
    return new CurrencyPipe(currentLocale).transform(
      value,
      "EUR",
      currency,
      format,
      "it-IT"
    );
  }

  getSalEmessiSa(item, anno) {
    if (!item) return 0;
    let ann = item.annualita.find((x) => x.anno == anno);
    return ann ? ann.salEmessiSa : 0;
  }

  getSalEmessiTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getSalEmessi(progetto, anno))
    );
    return somma;
  }

  getSalEmessi(item, anno) {
    if (!item) return 0;
    let ann = item.annualita.find((x) => x.anno == anno);
    return ann ? ann.salEmessi : 0;
  }

  getImportiErogatiSaTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getImportiErogatiSa(progetto, anno))
    );
    return somma;
  }

  getImportiErogatiSa(item, anno) {
    if (!item) return 0;
    let ann = item.annualita.find((x) => x.anno == anno);
    return ann ? ann.importiErogatiSa : 0;
  }

  renderChart9() {
    let createSeries = (field, name, chart) => {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "anno";
      series.name = name;
      series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
      series.columns.template.height = am4core.percent(100);
      series.sequencedInterpolation = true;

      // let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      // valueLabel.label.text = "{valueY}";
      // valueLabel.label.horizontalCenter = "left";
      // valueLabel.label.dx = 10;
      // valueLabel.label.hideOversized = false;
      // valueLabel.label.truncate = false;

      // let categoryLabel = series.bullets.push(new am4charts.LabelBullet());
      // categoryLabel.label.text = "{name}";
      // categoryLabel.label.horizontalCenter = "right";
      // categoryLabel.label.dx = -10;
      // categoryLabel.label.fill = am4core.color("#fff");
      // categoryLabel.label.hideOversized = false;
      // categoryLabel.label.truncate = false;
    };

    this.chart9 = am4core.create("chart9", am4charts.XYChart);
    am4core.options.autoDispose = true;
    this.chart9.responsive.enabled = true;

    // Add simple vertical scrollbar
    this.chart9.scrollbarX = new am4core.Scrollbar();

    // chart.legend.labels.template.maxWidth = 120

    this.chart9.responsive.enabled = true;

    let title = this.chart9.chartContainer.createChild(am4core.Label); //chart.titles.create();
    title.text = "Stato copertura SAL/ALTRO per anno";
    title.align = "center";
    let data = [];
    this.anniArray.forEach((anno) => {
      let obj = {
        anno: anno.toString(),
        salSaImporto: this.getSalEmessiSaTotaleAnno(anno),
        salNormaleImporto: this.getSalEmessiTotaleAnno(anno),
        /* erogazioniNormaleImporto: this.getImportiErogatiTotaleAnno(anno),*/
        erogazioniSaImporto: this.getImportiErogatiSaTotaleAnno(anno),
      };
      data.push(obj);
    });

    this.chart9.data = data;
    var categoryAxis = this.chart9.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "anno";
    categoryAxis.numberFormatter.numberFormat = "#";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;

    var valueAxis = this.chart9.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.opposite = false;
    createSeries("salSaImporto", "SAL/ALTRO", this.chart9);
    createSeries("erogazioniSaImporto", "Erogazioni", this.chart9);
    createSeries("salNormaleImporto", "Giustificativi", this.chart9);

    this.chart9.legend = new am4charts.Legend();
    this.chart9.legend.position = "top";
    this.chart9.legend.paddingBottom = 20;
    this.chart9.legend.labels.template.maxWidth = 95;
    this.chart9.responsive.rules.push({
      relevant: function (target) {
        return false;
      },
      state: function (target, stateId) {
        return null;
      },
    });
    this.chart9.numberFormatter.numberFormat = "# a '€'";

    this.chart9.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "Mln" },
      { number: 1e9, suffix: "Mrd" },
    ];

    this.exportChart(this.chart9, title.text);

    this.aggiungiLogo(this.chart9);
  }

  getSalEmessiSaTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach(
      (anno) => (somma += this.getSalEmessiSaTotaleAnno(anno))
    );
    return somma;
  }

  getSalEmessiTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach(
      (anno) => (somma += this.getSalEmessiTotaleAnno(anno))
    );
    return somma;
  }

  getImportiErogatiSaTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach(
      (anno) => (somma += this.getImportiErogatiSaTotaleAnno(anno))
    );
    return somma;
  }

  getImportiErogatiTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach(
      (anno) => (somma += this.getImportiErogatiTotaleAnno(anno))
    );
    return somma;
  }

  getImportiErogatiTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getImportiErogati(progetto, anno))
    );
    return somma;
  }

  getImportiErogati(item, anno) {
    if (!item) return 0;
    let ann = item.annualita.find((x) => x.anno == anno);
    return ann ? ann.importiErogati : 0;
  }

  getValoreTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach((anno) => (somma += this.getValoreTotaleAnno(anno)));
    return somma;
  }

  getValoreTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getValore(progetto, anno))
    );
    return somma;
  }

  getValore(item, anno) {
    if (!item) return 0;
    let ann = item.importi.find((x) => x.anno == anno);
    return ann ? ann.importo : 0;
  }

  getACTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach((anno) => (somma += this.getACTotaleAnno(anno)));
    return somma;
  }

  getACTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getAC(progetto, anno))
    );
    return somma;
  }

  getAC(item, anno) {
    if (!item) return 0;
    return (
      this.getSalEmessiSa(item, anno) - this.getImportiErogatiSa(item, anno)
    );
  }

  getBDTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach((anno) => (somma += this.getBDTotaleAnno(anno)));
    return somma;
  }

  getBDTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getBD(progetto, anno))
    );
    return somma;
  }

  getBD(item, anno) {
    if (!item) return 0;
    return this.getSalEmessi(item, anno) - this.getImportiErogati(item, anno);
  }

  getSalEmessiTotale(item) {
    if (!item) return 0;
    let somma = 0;
    this.anniArray.forEach((anno) => (somma += this.getSalEmessi(item, anno)));
    return somma;
  }

  getImportiErogatiTotale(item) {
    if (!item) return 0;
    let somma = 0;
    this.anniArray.forEach(
      (anno) => (somma += this.getImportiErogati(item, anno))
    );
    return somma;
  }

  getECTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach((anno) => (somma += this.getECTotaleAnno(anno)));
    return somma;
  }

  getECTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getEC(progetto, anno))
    );
    return somma;
  }

  getEC(item, anno) {
    if (!item) return 0;
    return this.getValore(item, anno) - this.getImportiErogatiSa(item, anno);
  }

  getValoreTotale(item) {
    if (!item) return 0;
    let somma = 0;
    this.anniArray.forEach((anno) => (somma += this.getValore(item, anno)));
    return somma;
  }

  getACTotale(item) {
    if (!item) return 0;
    return (
      this.getSalEmessiSaTotale(item) - this.getImportiErogatiSaTotale(item)
    );
  }

  getECTotale(item) {
    if (!item) return 0;
    return this.getValoreTotale(item) - this.getImportiErogatiSaTotale(item);
  }
  getCDTotale(item) {
    if (!item) return 0;
    return (
      this.getImportiErogatiSaTotale(item) - this.getImportiErogatiTotale(item)
    );
  }

  getBDTotale(item) {
    if (!item) return 0;
    return this.getSalEmessiTotale(item) - this.getImportiErogatiTotale(item);
  }

  getImportiErogatiSaTotale(item) {
    if (!item) return 0;
    let somma = 0;
    this.anniArray.forEach(
      (anno) => (somma += this.getImportiErogatiSa(item, anno))
    );
    return somma;
  }

  getSalEmessiSaTotale(item) {
    if (!item) return 0;
    let somma = 0;
    this.anniArray.forEach(
      (anno) => (somma += this.getSalEmessiSa(item, anno))
    );
    return somma;
  }

  getCDTotaleComplessivo() {
    let somma = 0;
    this.anniArray.forEach((anno) => (somma += this.getCDTotaleAnno(anno)));
    return somma;
  }

  getCDTotaleAnno(anno) {
    if (!this.detailsObject) return 0;
    let somma = 0;
    this.detailsObject.forEach(
      (progetto) => (somma += this.getCD(progetto, anno))
    );
    return somma;
  }

  getCD(item, anno) {
    if (!item) return 0;
    return (
      this.getImportiErogatiSa(item, anno) - this.getImportiErogati(item, anno)
    );
  }

  renderChart8() {
    let createSeries = (field, name, chart) => {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.percentage = "percentage";
      series.dataFields.categoryX = "what";
      series.name = name;
      series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
      series.columns.template.height = am4core.percent(100);
      series.sequencedInterpolation = true;
      series.clustered = false;
      return series;
    };

    this.chart8 = am4core.create("chart8", am4charts.XYChart);
    am4core.options.autoDispose = true;

    let title = this.chart8.chartContainer.createChild(am4core.Label); //chart.titles.create();
    title.text = "Stato copertura SAL/ALTRO";
    title.align = "center";

    let data = [];
    let salSa = this.getSalEmessiSaTotaleComplessivo();
    let sal = this.getSalEmessiTotaleComplessivo();
    let eroSa = this.getImportiErogatiSaTotaleComplessivo();
    let ero = /*this.getImportiErogatiTotaleComplessivo()*/ 0;

    let obj = {
      what: "SAL",
      sal: salSa,
      erogazioni: eroSa,
      percentage: ((eroSa * 100.0) / salSa).toFixed(0),
    };

    data.push(obj);
    obj = {
      what: "Giustificativi",
      sal: sal,
      erogazioni: ero,
      percentage: ((ero * 100.0) / sal).toFixed(0),
    };
    data.push(obj);

    this.chart8.colors.list = [
      am4core.color("#4CAF50"),

      am4core.color("#2e7d32"),
    ];
    this.chart8.data = data;

    var categoryAxis = this.chart8.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "what";
    categoryAxis.numberFormatter.numberFormat = "#";
    //categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;

    var valueAxis = this.chart8.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.opposite = false;
    let series1 = createSeries("sal", "Sal/Altro", this.chart8);
    let series2 = createSeries("erogazioni", "Erogazioni", this.chart8);
    series2.calculatePercent = true;
    series2.columns.template.width = am4core.percent(50);
    var bullet1 = series2.bullets.push(new am4charts.LabelBullet());
    bullet1.interactionsEnabled = false;
    bullet1.label.text = "{percentage}%";
    bullet1.label.fill = am4core.color("#ffffff");
    bullet1.locationY = 0.5;
    bullet1.locationX = 0.5;

    this.chart8.legend = new am4charts.Legend();
    this.chart8.legend.position = "top";
    this.chart8.legend.paddingBottom = 20;
    this.chart8.legend.labels.template.maxWidth = 95;
    // Add percent sign to all numbers
    this.chart8.numberFormatter.numberFormat = "# a '€'";

    this.chart8.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "Mln" },
      { number: 1e9, suffix: "Mrd" },
    ];
    this.exportChart(this.chart8, title.text);

    this.aggiungiLogo(this.chart8);
  }

  exportChart(chart, title) {
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = "left";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.background = "#ff0000";
    chart.exporting.menu.defaultStyles = true;
    chart.exporting.filePrefix = title;
    chart.exporting.menu.items = [
      {
        menu: [
          { type: "jpg", label: "JPG" },
          { type: "png", label: "PNG" },
          { type: "csv", label: "CSV" },
        ],
      },
    ];
    chart.exporting.menu.items[0].icon = "assets/img/export.png";
  }

  aggiungiLogo(logoChart) {
    // Add watermark
    let watermark = new am4core.Image();
    watermark.href = "assets/img/logo_blu.svg";
    logoChart.tooltipContainer.children.push(watermark);
    watermark.align = "right";
    watermark.valign = "bottom";
    watermark.opacity = 0.3;
    watermark.marginRight = 10;
    watermark.marginBottom = 5;
    watermark.disabled = true;

    // Enable watermark on export
    logoChart.exporting.events.on("exportstarted", function (ev) {
      watermark.disabled = false;
    });

    // Disable watermark when export finishes
    logoChart.exporting.events.on("exportfinished", function (ev) {
      watermark.disabled = true;
    });

    // Add watermark to validated sprites
    logoChart.exporting.validateSprites.push(watermark);
  }
}
