import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  TemplateRef
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { GeoService } from "src/app/services/geo.service";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Region } from 'src/app/models/region';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EditRegionModalComponent } from './edit-region-modal/edit-region-modal.component';
import * as moment from "moment";
import { KeyValue } from 'src/app/models/keyValue';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';

@Component({
  selector: "app-region-management",
  templateUrl: "./region-management.component.html",
  styleUrls: ["./region-management.component.css"]
})
export class RegionManagementComponent
  implements OnInit ,OnDestroy{
  currentNationId: number = -1;
  visible = false;
  nations: KeyValue[];
  nationsAdd: KeyValue[];
  submitted: boolean = false;
  addRegionForm: FormGroup;
  modalRef: BsModalRef;
  open: boolean = false;

  dtOptionsNgx:any;
  @ViewChild('btnEditTemplate', { static: true })
  public btnEditTemplate: TemplateRef<any>;
  @ViewChild('ngxDatatableWrapper')
  ngxDatatableWrapper: NgxDatatableWrapperComponent;
  templateList=[];

  constructor(
    private http: HttpClient,
    private geoService: GeoService,
    private formBuilder: FormBuilder,
    private el: ElementRef,
    private localeService: BsLocaleService,
    private toastr: ToastService,
    private modalService: BsModalService
  ) {
    this.localeService.use("it");
    this.dtOptionsNgx = {
        rows: []
        , totalElements: 0
        , columns: this.columns
        , columnMode: ColumnMode.flex
        , headerHeight: "50"
        , footerHeight: "50"
        , rowHeight: "auto"
        , externalSorting: true// true
        , loadingIndicator: 'loading'
        , class: "bootstrap"
        , summaryRow: true
        , summaryPosition: "'bottom'"
        , externalPaging: true// true
        , count: 0
        , offset: 0
        , limit: 10
        , serverSide: true
        ,ajax:this.loadData
        , scrollbarH:"true"
    };
  }

  ngOnDestroy(): void {

}
  refreshGrid(){
    this.ngxDatatableWrapper.refresh();
}
  ngOnInit() {
    window.scroll(0, 0);
    this.geoService.getNations().subscribe(res => {
      this.nationsAdd = res;
      this.nations = res;
      let italy = this.nations.find(x=>x.key == '0001');
      this.onChangeNation(italy);
    });
    this.addRegionForm = this.initializeFormGroup();
    this.templateList = [
        {name:'btnEditTemplate',template:this.btnEditTemplate}
];
  }


  onChangeNation($event: KeyValue) {
    this.currentNationId = $event.id;
    if (this.currentNationId > 0) {
      this.visible = true;
    }
    this.refreshGrid();
  }

  onChangeNewNation($event: KeyValue) {
    this.currentNationId = $event.id;
  }

  initializeFormGroup() {
    return this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(255)]],
      code: [
        "",
        [Validators.required, Validators.minLength(2), Validators.maxLength(2)]
      ],
      fromDate: [
        null,
        // [Validators.required, Validators.minLength(10), Validators.maxLength(10)]
      ],
      toDate: [
        null,
        // [Validators.required, Validators.minLength(10), Validators.maxLength(10)]
      ],
      nation: [null, [Validators.required]]
    });
  }

  get f() {
    return this.addRegionForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.addRegionForm.updateValueAndValidity();
    let errors = false;
    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }
    if (this.addRegionForm.invalid || errors) {
      console.log("invalid");
      let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
      for (let el in this.addRegionForm.controls) {
        if (this.addRegionForm.controls[el].invalid) {
          console.log(`set focus on ${el}`);
          if (this.addRegionForm.controls[el] instanceof FormGroup) {
            for (let el1 in this.addRegionForm.get(el)["controls"]) {
              if (this.addRegionForm.get(el)["controls"][el1].invalid) {
                invalidElements = this.el.nativeElement.querySelectorAll(
                  `[formcontrolname="${el1}"]`
                );
                break;
              }
            }
          } else {
            invalidElements = this.el.nativeElement.querySelectorAll(
              `[formcontrolname="${el}"]`
            );
          }
          break;
        }
      }
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }

      return false;
    }

    const newRegion: Region = new Region();
    newRegion.name = this.f.name.value;
    newRegion.code = this.f.code.value;
    newRegion.fromDate = this.f.fromDate.value;
    newRegion.toDate = this.f.toDate.value;
    //we manage only italian's regions, so we can put Italy code inside idNation
    let italy = this.nations.find(x=>x.key == '0001');
    newRegion.idNation = italy.id;
    //newRegion.idNation = this.f.nation.value;

    this.geoService.createRegion(newRegion).subscribe(res => {
      this.toastr.success( "Regione aggiunta con successo!");
      this.refreshGrid();
      this.submitted = false;
      this.open = false;
      this.addRegionForm.reset();
    }, error => {
      this.toastr.error( "Codice già presente");
    })
  }

  editHandler(info: any): void {
    const initialState = { currentRegion: info as Region };
    initialState.currentRegion.idNation = this.currentNationId;
    const config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: "modal-lg"
    };
    this.modalRef = this.modalService.show(
      EditRegionModalComponent,
      Object.assign({}, config, { initialState })
    );

    this.modalRef.content.onClose.subscribe(_ => {
      this.modalRef.hide();
      this.refreshGrid();
    });
  }

  columns = [
    {
        name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
        , resizeable: false
    }

    , { name: 'codice'
    , prop: 'code'

    , sortable: true
    , filtrable: true
    , resizeable: false
    , draggable: false
    , flexGrow: 2
        , minWidth: 60

  }
    ,
    { name: 'nome'
    , prop: 'name'
    , sortable: true
    , filtrable: true
    , resizeable: false
    , draggable: false
    , flexGrow: 3
        , minWidth: 60
   }
   ,
    { name: 'inizio validità'
    , prop: 'fromDate'
    , sortable: true
    , filtrable: true
    , resizeable: false
    , draggable: false
    , cellTemplate :'dateTimeValueTemplate'
    , flexGrow: 2
        , minWidth: 60
   }
   ,
    { name: 'fine validità'
    , prop: 'toDate'
    , sortable: true
    , filtrable: true
    , resizeable: false
    , draggable: false
    , cellTemplate :'dateTimeValueTemplate'
    , flexGrow: 2
        , minWidth: 60
   }


    , {
        name: 'modifica'
        , prop: null
        , sortable: false
        , filtrable: false
        , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
        //, width: 10
        , resizeable: false
        , draggable: false
        , flexGrow: 1
        , minWidth: 60
    }
];



  loadData = (dataTablesParameters: any, callback: any) => {
    if (this.currentNationId > 0) {
      let that = this;
      that.http
        .post<any>(
          `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.geo_protected}nations/${this.currentNationId}/regions`,
          dataTablesParameters
        )
        .subscribe(resp => {
          callback(resp);
        });
    };
  }
}
