import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { ColumnMode } from "@swimlane/ngx-datatable";
import * as FileSaver from "file-saver";
import {
  ProgettoService,
  ProtocolloService,
  SicerService,
  SoggettoGiuridicoService,
  StorageService,
} from "../../services";
import { GeoService } from "src/app/services/geo.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { defineLocale } from "ngx-bootstrap/chronos";
import { itLocale } from "ngx-bootstrap/locale";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { NgxDatatableWrapperComponent } from "src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component";
import * as moment from "moment";
import { ToastService } from "src/app/services/toast.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AuthService } from "src/app/services";
defineLocale("it", itLocale);

@Component({
  selector: "cruscotto-note-atti-component",
  templateUrl: "./cruscotto-note-atti.component.html",
  styleUrls: ["./cruscotto-note-atti.component.css"],
})
export class CruscottoNoteAttiComponent implements OnInit {
  filterForm: FormGroup;
  emailForm: FormGroup;

  @ViewChild("btnEditTemplate", { static: true })
  public btnEditTemplate: TemplateRef<any>;
  @ViewChild("fieldNoteTemplate", { static: true })
  public fieldNoteTemplate: TemplateRef<any>;
  @ViewChild("numeroDocumentoTemplate", { static: true })
  public numeroDocumentoTemplate: TemplateRef<any>;
  @ViewChild("oggettoProtocolloTemplate", { static: true })
  public oggettoProtocolloTemplate: TemplateRef<any>;
  @ViewChild("cupRpRupfieldTemplate", { static: true })
  public cupRpRupfieldTemplate: TemplateRef<any>;
  @ViewChild("lavoratoTemplate", { static: true })
  public lavoratoTemplate: TemplateRef<any>;
  @ViewChild("modalInvioEmail", { static: true })
  public modalInvioEmail: TemplateRef<any>;
  @ViewChild("templateEditNote", { static: true })
  public templateEditNote: TemplateRef<any>;

  @ViewChild("tipologiaEntitaTemplate", { static: true })
  public tipologiaEntitaTemplate: TemplateRef<any>;

  @ViewChild("btnSendEmailTemplate", { static: true })
  public btnSendEmailTemplate: TemplateRef<any>;

  yesNoItems: Array<{ k: string; v: string }> = [
    { k: "1", v: "Si" },
    { k: "0", v: "No" },
  ];

  idAreaSisma: number = -1;

  soggettiRPCombo = [];
  soggettiCombo = [];

  completerParams = {
    labelField: "descrizioneEstesa",
    placeholder: "Cerca intervento",
    ajax: this.filterComboInterventi,
    items: [],
    context: this,
  };

  @ViewChild("ngxDatatableWrapper")
  ngxDatatableWrapper: NgxDatatableWrapperComponent;

  dtOptions: any;

  templateList = [];

  modalRef: BsModalRef;

  constructor(
    private sicerService: SicerService,
    private soggettiService: SoggettoGiuridicoService,
    private geoService: GeoService,
    private storageService: StorageService,
    private projectService: ProgettoService,
    private localeService: BsLocaleService,
    private toastr: ToastService,
    private modalService: BsModalService,
    private protocolloService: ProtocolloService,
    private authService: AuthService
  ) {
    this.dtOptions = {
      rows: [],
      totalElements: 0,
      columns: [
        {
          name: "id",
          prop: "id",
          visible: false,
          sortable: true,
          filtrable: true,
          //width: 10,
          flexGrow: 1,
        },
        {
          name: "lavorato",
          prop: "lavorato",
          sortable: true,
          filtrable: true,
          cellTemplate: "lavoratoTemplate",
          //minWidth: 50,
          //maxWidth: 60,
          flexGrow: 1,
        },

        {
          name: "note",
          prop: "noteLavorazione",
          sortable: false,
          filtrable: false,
          cellTemplate: "fieldNoteTemplate",
          //minWidth: 50,
          //maxWidth: 60,
          flexGrow: 1,
        },
        {
          name: "cod.prog intervento",
          prop: "parentCode",
          sortable: true,
          filtrable: true,
          flexGrow: 2,
          //minWidth: 60,
        },
        {
          name: "descrizione prog/interv.",
          prop: "parentDescription",
          sortable: true,
          filtrable: true,
          flexGrow: 4,
          //minWidth: 100,
          cellTemplate: "oggettoProtocolloTemplate",
        },
        {
          name: "cup / rup / rp",
          prop: "cup",
          sortable: false,
          filtrable: false,
          flexGrow: 3,
          //minWidth: 90,
          cellTemplate: "cupRpRupfieldTemplate",
        } /*,
          {
            name: "rup",
            prop: "rup",
            sortable: true,
            filtrable: true,
            visible:false,
            flexGrow: 3,
            minWidth: 60,
          },
          {
            name: "rp",
            prop: "rp",
            sortable: true,
            filtrable: true,
            flexGrow: 3,
            minWidth: 60,
            visible:false
          }*/,
        {
          name: "tipologia",
          prop: "tipologiaEntita",
          cellTemplate: "tipologiaEntitaTemplate",
          sortable: true,
          filtrable: true,
          resizeable: false,
          draggable: false,
          flexGrow: 2,
          //minWidth: 60,
        },
        {
          name: "data doc.",
          prop: "dataDocumento",
          cellTemplate: "dateValueTemplate",
          sortable: true,
          filtrable: true,
          resizeable: false,
          draggable: false,
          flexGrow: 2,
          //minWidth: 60,
        },
        {
          name: "numero",
          prop: "numero",
          sortable: true,
          filtrable: true,
          flexGrow: 3,
          //minWidth: 60,
          cellTemplate: "numeroDocumentoTemplate",
        },
        {
          name: "desc. documento",
          prop: "oggetto",
          cellTemplate: "oggettoProtocolloTemplate",
          sortable: true,
          filtrable: true,
          resizeable: false,
          draggable: false,
          flexGrow: 4,
          //minWidth: 100,
        },
        {
          name: "comune",
          prop: "comune",
          sortable: true,
          filtrable: true,
          flexGrow: 2,
          //minWidth: 40,
        },
        {
          name: "sogg. attuatore",
          prop: "soggettoAttuatore",
          sortable: true,
          filtrable: true,
          flexGrow: 3,
          //minWidth: 60,
          visible: true,
        },
        {
          name: "invio email",
          prop: "dataEmail",
          sortable: true,
          filtrable: false,
          resizeable: false,
          draggable: false,
          cellTemplate: "btnSendEmailTemplate",
          flexGrow: 2,
          //minWidth: 80,

        } /*,
            {
              name: "data ultima<br />email inviata",
              prop: "dataEmail",
              cellTemplate: "dateValueTemplate",
              sortable: true,
              filtrable: true,
              resizeable: false,
              draggable: false,
              flexGrow: 2,
              minWidth: 60,
              visible:false
            }*/,
        /*,
          {
            name: "modifica",
            prop: "id",
            sortable: false,
            filtrable: false,
            cellTemplate: "btnEditTemplate", // 'buttonsTemplate'
            flexGrow: 1,
            minWidth: 60,
          },*/
      ],
      columnMode: ColumnMode.flex,
      headerHeight: "50",
      footerHeight: "50",
      rowHeight: "auto",
      externalSorting: true, // true
      loadingIndicator: "loanding",
      class: "bootstrap",
      summaryRow: true,
      summaryPosition: "'bottom'",
      externalPaging: true, // true
      count: 0,
      offset: 0,
      limit: 10,
      serverSide: true,
      scrollbarH: "true",
      ajax: this.getAllData,
      sorts: [{ dir: "asc", prop: "codice" }],
    };

    this.getSoggettiAttuatori();
    this.getAllMunicipalities();
    this.getAreaTematicaSisma();
    this.storageService.elencoRup.subscribe((x) => {
      this.soggettiRPCombo = x;
      console.log("this.soggettiRPCombo", this.soggettiRPCombo);
    });

    this.filterForm = new FormGroup({
      cup: new FormControl(null),
      rp: new FormControl(null),
      rup: new FormControl(null),
      dataDocumento: new FormControl(null),
      noteLavorazione: new FormControl(null),
      comune: new FormControl(null),
      soggettoAttuatore: new FormControl(null),
      lavorato: new FormControl(null),
      interventoId: new FormControl(null),
    });
    this.localeService.use("it");
    this.emailForm = new FormGroup({
      emailTo: new FormControl(null, Validators.required),
      body: new FormControl(null, Validators.required),
      subject: new FormControl(null),
      id: new FormControl(null),
      tipo: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.templateList = [
      { name: "btnEditTemplate", template: this.btnEditTemplate },
      { name: "fieldNoteTemplate", template: this.fieldNoteTemplate },
      {
        name: "numeroDocumentoTemplate",
        template: this.numeroDocumentoTemplate,
      },
      {
        name: "oggettoProtocolloTemplate",
        template: this.oggettoProtocolloTemplate,
      },
      { name: "btnSendEmailTemplate", template: this.btnSendEmailTemplate },
      { name: "cupRpRupfieldTemplate", template: this.cupRpRupfieldTemplate },
      { name: "lavoratoTemplate", template: this.lavoratoTemplate },
      {
        name: "tipologiaEntitaTemplate",
        template: this.tipologiaEntitaTemplate,
      },
    ];
  }

  municipalities = [];
  getAllMunicipalities() {
    this.geoService.getMunicipalitiesWithIstat("12").subscribe((res) => {
      this.municipalities = res.map((x) => {
        return {
          id: x["id"],
          label: x["name"],
        };
      });
    });
  }

  filterComboInterventi(filterString, callBack) {
    console.log(filterString);
    const f = {
      descriptionSearchString: filterString,
      filterAreeGestionaliDirezioneEsterne: this.idAreaSisma,
    };
    this["context"].projectService
      .getInterventiListSimpleData(f)
      .subscribe((x) => {
        x = x.map((y) => {
          y["descrizioneEstesa"] = `${y.codice} - ${y.descrizione}`;
          return y;
        });
        callBack(x);
      });
  }

  getSoggettiAttuatori() {
    this.soggettiService.getSoggettiAttuatori(1).subscribe((x) => {
      this.soggettiCombo = x;
      this.soggettiCombo.sort((a, b) =>
        a.denominazione < b.denominazione ? -1 : 1
      );
    });
  }

  getAllData = (dataTablesParameters: any, callback: any) => {
    this.sicerService
      .getDatiCruscotto(this.getFormValues(), dataTablesParameters)
      .subscribe((resp) => {
        resp.data = resp.data.map(
            x=>{x.allegatiArray =this.getAllegatiProsa(x.allegati);return x;}
            );
        callback(resp);
      });
  };

  downloadSicer(item) {
    this.sicerService
      .download(item.anno, item.numero, item.direzione)
      .subscribe((x) => {
        let filename = item.numero + ".pdf";
        FileSaver.saveAs(x.body, `${filename}`);
      });
  }
  trimText(text, size) {
    if (!size) return text;
    if (text.length <= size) return text;
    return text.substring(0, size) + "...";
  }
  emailDestItems = [];
  onSendEmail(row: any) {
    if (!row.rup && !row.rp) {
      this.toastr.error("RUP e RP non presenti per il documento selezionato");
      return;
    }
    this.modalRef = this.modalService.show(this.modalInvioEmail, {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: "modal-lg",
    });
    this.emailDestItems = [];
    if (row.rup)
      this.emailDestItems.push({ key: "rup", value: `RUP: ${row.rup}` });
    if (row.rp) this.emailDestItems.push({ key: "rp", value: `RP: ${row.rp}` });
    this.emailForm.setValue({
      id: row.id,
      body: null,
      subject: `numero documento: ${
        row.numero
      } - data documento: ${this.printValueAsDate(row.dataDocumento)}`,
      emailTo: null,
      tipo: row.tipologiaEntita,
    });
  }

  onFind() {
    this.ngxDatatableWrapper.refresh();
  }

  getFormValues() {
    let v = this.filterForm.value;
    if (v.dataDocumento) {
      let dataPF = moment(v.dataDocumento).toDate();
      dataPF.setHours(dataPF.getHours() - dataPF.getTimezoneOffset() / 60);
      v.dataDocumento = dataPF;
    }
    return v;
  }

  onChangeIntervention($event) {
    !$event.target.value && this.setIntervention(null);
  }

  setIntervention($event) {
    this.filterForm.get("interventoId").setValue($event ? $event.id : null);
  }

  getAreaTematicaSisma() {
    this.storageService.elencoAreeTematiche.subscribe((x) => {
      this.idAreaSisma = x.find((x) => x.struttura == "DU0100").id;
    });
  }

  printValueAsDate(value) {
    if (!value || value == "") return "";
    let v = moment(value).toDate();
    return moment(v).format("DD/MM/YYYY");
  }

  exporting: boolean = false;

  onExport() {
    this.exporting = true;
    this.sicerService.getExportCruscotto(this.getFormValues()).subscribe(
      (res) => {
        const filename = `esportazione_cruscotto_${moment(new Date()).format(
          "YYYY_MM_DDTHH_mm_ss"
        )}.xlsx`;
        FileSaver.saveAs(res.body, `${filename}`);
        this.exporting = false;
      },
      (error) => {
        this.toastr.error(`Errore: ${error?.error?.message}`, null, {
          timeOut: 2000,
          disableTimeOut: false,
        });
        this.exporting = false;
      }
    );
  }
  changeStatus(row: any) {
    if (
      !confirm(
        `modificare il valore di "lavorato" per il documento selezionato?`
      )
    ) {
      return;
    }
    if (row.tipologiaEntita == "PROSA") {
      this.protocolloService.updateLavorato(row.id).subscribe((result) => {
        this.toastr.success("Stato 'lavorato' salvato con successo!");
        this.onFind();
      });
    } else {
      this.sicerService.updateLavorato(row.id).subscribe((result) => {
        this.toastr.success("Stato 'lavorato' salvato con successo!");
        this.onFind();
      });
    }
  }

  updateNoteLavorazione() {
    const v = this.modalForm.value;
    this.closeInvioEmailModal();
    if (v.tipo == "PROSA") {
      this.protocolloService
        .updateNoteLavorazione(v.id, v.note)
        .subscribe((result) => {
          this.toastr.success("Note salvate con successo!");
          this.onFind();
        });
    } else {
      this.sicerService
        .updateNoteLavorazione(v.id, v.note)
        .subscribe((result) => {
          this.toastr.success("Note salvate con successo!");
          this.onFind();
        });
    }
  }

  modalForm: FormGroup;
  editNoteLavorazione(id, noteLavorazione, tipo) {
    this.modalForm = new FormGroup({
      note: new FormControl(noteLavorazione),
      id: new FormControl(id),
      tipo: new FormControl(tipo),
    });
    //this.modalForm.reset();

    this.modalRef = this.modalService.show(this.templateEditNote, {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: "modal-md",
    });
  }

  submittedEmail: boolean = false;
  closeInvioEmailModal() {
    this.modalRef.hide();
    this.emailForm.reset();
    this.submittedEmail = false;
  }

  onInvioEmail() {
    this.submittedEmail = true;
    if (this.emailForm.invalid) return false;
    const body = this.emailForm.value.body;
    const dest = this.emailForm.value.emailTo;
    const id = this.emailForm.value.id;
    const tipo = this.emailForm.value.tipo;
    this.closeInvioEmailModal();
    if (tipo == "PROSA") {
      this.protocolloService.sendEmailToRup(id, body, dest).subscribe((x) => {
        this.toastr.success("Email inviata con successo!");
        this.onFind();
      });
    } else {
      this.sicerService.sendEmailToRup(id, body, dest).subscribe((x) => {
        this.toastr.success("Email inviata con successo!");
        this.onFind();
      });
    }
  }

  downloadDocumentoProsa(codice, nome) {
    console.log(`downloaddocumentoProsa`,codice,nome);
    this.protocolloService.isPresente(codice, nome).subscribe((result) => {
      if (result) {
        this.protocolloService.download(codice, nome).subscribe((res) => {
          let filename = nome.replace(/^.*[\\\/]/, "");
          FileSaver.saveAs(res.body, filename);
        });
      } else {
        this.toastr.error(`Documento ${nome} non presente su Prosa`, null, {
          timeOut: 3000,
          /*closeButton:true,*/ disableTimeOut: false,
        });
      }
    });
  }

  getAllegatiProsa(allegati: string) {
    if (!allegati || allegati == "") return [];
    return JSON.parse(allegati);
  }

  printTipologiaEntitaTemplateCell(row, value) {
    const val = row.tipologiaEntita;
    switch (val) {
      case "PROSA":
        return "Protocolli - " + row.tipoProtocollo;
      case "SICER":
        return "SICER - Atti";
    }
  }
  isCustomHeader() {
    return this.authService.getHeaderCode() != 'generale';
  }
  getHeaderLabel() {
      return this.isCustomHeader() ? 'Piano Nazionale Ripresa e Resilienza' : 'Direzione Regionale Infrastrutture e Mobilità';
  }
  
}
