import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import  * as env  from 'src/assets/config/appConfig.json';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  static env: any;

  constructor(
    private http: HttpClient
  ) { }

  load() {
    console.log("loading application configuration...");
    return this.getAppConfigPromise();    
  }

  private getAppConfigPromise() {
    const jsonFile = 'assets/config/appConfig.json';    
    AppConfigService.env = env['default'];
    /*return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile).toPromise().then((response: any) => {
        AppConfigService.env = <any>response;
        resolve();
      }).catch((response: any) => {
        console.log(response);
        reject(`Could not load file ${jsonFile}: ${JSON.stringify(response)}`);
      });
    });*/
  }

}

export default AppConfigService.env;
