<!-- Filtri -->
<div class="row my-4" [formGroup]="myForm" >
  <!-- Filtro missione  -->
  <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
    <label>
      <h6 class="mb-0 text-dark">
        Linee di Finanziamento PNRR / PNC - Missioni
      </h6>
    </label>
    <ng-select
      [items]="pnrrMissioniItems"
      formControlName="pnrrMissioni"
      [multiple]="true"
      [selectableGroup]="true"
      [selectableGroupAsModel]="false"
      groupBy="gruppo"
      [closeOnSelect]="false"
      bindValue="id"
      bindLabel="descrizione"
      placeholder="Seleziona una o più linee di finanziamento"
      class="small"
    >
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <input
          id="item-{{ index }}"
          type="checkbox"
          [checked]="item$.selected"
        />
        <span title="{{ item.descrizione }}"
          >&nbsp;{{ item.descrizione }}</span
        >
      </ng-template>
    </ng-select>
  </div>

  <!-- Filtro componente -->

  <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
    <label>
      <h6 class="mb-0 text-dark">
        Linee di Finanziamento PNRR / PNC - Componenti
      </h6>
    </label>
    <ng-select
      [items]="pnrrComponentiItems"
      formControlName="pnrrComponenti"
      [multiple]="true"
      [selectableGroup]="true"
      [selectableGroupAsModel]="false"
      groupBy="gruppo"
      [closeOnSelect]="false"
      bindValue="id"
      bindLabel="valore"
      placeholder="Seleziona una o più linee di finanziamento"
      class="small"
    >
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <input
          id="item-{{ index }}"
          type="checkbox"
          [checked]="item$.selected"
        />
        <span title="{{ item.valore }}">&nbsp;{{ item.valore }}</span>
      </ng-template>
    </ng-select>
  </div>

  <!-- Filtro Linee finanziamento -->

  <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
    <label>
      <h6 class="mb-0 text-dark">
        Linee di Finanziamento PNRR / PNC - Linee
      </h6>
    </label>
    <ng-select
      [items]="pnrrLineeItems"
      formControlName="pnrrLinee"
      [multiple]="true"
      [selectableGroup]="true"
      [selectableGroupAsModel]="false"
      groupBy="gruppo"
      [closeOnSelect]="false"
      bindValue="id"
      bindLabel="valore"
      placeholder="Seleziona una o più linee di finanziamento"
      class="small"
    >
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <input
          id="item-{{ index }}"
          type="checkbox"
          [checked]="item$.selected"
        />
        <span title="{{ item.valore }}">&nbsp;{{ item.valore }}</span>
      </ng-template>
    </ng-select>
  </div>

  <!-- Bottone cerca  -->

  <div class="col-xl-1 col-lg-2 col-12 col-sm-6 align-self-end align-items-end">
    <button
      [disabled]="showLoading"
      type="button"
      class="mt-3 btn btn-info btn-block"
      (click)="doSearch()"
    >
      Cerca
      <img
        alt="loading"
        *ngIf="showLoading"
        class="pl-3 float-right"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
      />
    </button>
  </div>
  <!-- Bottone Esporta -->
  <div class="col-xl-1 col-lg-2 col-12 col-sm-6 align-self-end align-items-end">
    <button [disabled]="showLoading" type="button" class="mt-3 btn btn-info btn-block" (click)="doExcel()">
      Esporta
      <img alt="loading" *ngIf="showLoading" (click)="doExcel()" class="pl-3 float-right"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
    </button>
  </div>

</div>

<!-- Fine filtri  -->

<!-- Inizio tabella -->
<div class="row">
  <div class="col-12">

      <div class="table-responsive table-wrapper-scroll-y scrollbar-y scrollbar-x" style="max-height: 90vh;">
      <table class="table table-sm table-hover small">
        <thead>
          <tr>
            <td></td>
            <th scope="col">Nr. Seq. M&T</th>
            <th scope="col">M&T</th>
            <th scope="col">Nome M&T</th>
            <th scope="col">Valore previsto</th>
            <th scope="col">Valore effettivo</th>
            <th scope="col">Percentuale raggiunta</th>
            <th scope="col">Data scadenza</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let targetMilestone of allPnrrTargetMilestone">
            <ng-container
              [ngTemplateOutlet]="templateTargetMilestone"
              [ngTemplateOutletContext]="{ codice: targetMilestone }"
            >
            </ng-container>
          </ng-container>
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
    </div>

</div>

<ng-template #templateTargetMilestone let-targetMilestone="codice">
  <tr>
    <td class="table-arancione">
      <button
        *ngIf="targetMilestone.children && targetMilestone.children.length > 0"
        class="btn btn-link text-dark py-0"
        type="button"
        aria-expanded="false"
        [attr.data-target]="'.progetto_' + targetMilestone.id"
        (click)="caricaChildren(targetMilestone.id)"
      >
        <i class="fas fa-chevron-down"></i>
      </button>
    </td>
    <th class="table-arancione text-nowrap" scope="row">
      {{ targetMilestone.codice }}
    </th>
    <th class="table-arancione" scope="row">
      {{ targetMilestone.tipologia }}
    </th>
    <th class="table-arancione" scope="row">
      {{ targetMilestone.descrizione }}
    </th>
    <th class="table-arancione" scope="row">
      {{ targetMilestone.valorePrevisto }}
    </th>
    <th class="table-arancione" scope="row">
      {{ targetMilestone.valoreEffettivo }}
    </th>
    <th class="table-arancione" scope="row">
      <div
        class="progress-bar"
        [ngClass]="{
          'barra-rossa':
            targetMilestone.dataFine > targetMilestone.dataPrevistaFine,
          'barra-verde':
            targetMilestone.dataFine <= targetMilestone.dataPrevistaFine
        }"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {{ targetMilestone.percentuale }} %
      </div>
    </th>

    <th class="table-arancione text-nowrap" scope="row">
      {{ printValueAsDate(targetMilestone.dataPrevistaFine) }}
    </th>
  </tr>

  <!-- Progetti per M&T (Children) Secondo livello-->
  <ng-container
    *ngIf="
      progettiMtSelezionato &&
      progettiMtSelezionato['prog_' + targetMilestone.id]
    "
  >
    <ng-container
      *ngFor="let prog of progettiMtSelezionato['prog_' + targetMilestone.id]"
    >
      <tr
        [hidden]="!arrExpandStatus['prog_' + targetMilestone.id]"
        class=" table-celeste small progetto_{{ targetMilestone.id }}"
      >
        <td>
          <button
            *ngIf="prog.children && prog.children.length > 0"
            class="btn btn-link text-dark py-0"
            type="button"
            [attr.data-target]="'.intervento_' + prog.id"
            aria-expanded="false"
            (click)="caricaInterventoProgettoMt(prog)"
          >
            <i class="fas fa-chevron-down"></i>
          </button>
        </td>
        <td>Progetto {{ prog.codice }}</td>
        <td>{{ prog.tipologia }}</td>
        <td>{{ prog.descrizione }}</td>
        <td>{{ prog.valorePrevisto }}</td>
        <td>{{ prog.valoreEffettivo }}</td>

        <td>
          <div class="progress-bar" [ngClass]="{
            'barra-rossa':
              prog.dataFine > prog.dataPrevistaFine,
            'barra-verde':
              prog.dataFine <= prog.dataPrevistaFine
          }"
          aria-valuemin="0"
          aria-valuemax="100">
            {{ prog.percentuale }} %
          </div>
        </td>

        <td class="text-nowrap">
          {{ printValueAsDate(prog.dataPrevistaFine) }}
        </td>
      </tr>

      <!-- Interventi per progetto  Terzo livello -->

      <ng-container
        *ngFor="
          let intervento of interventiProgettoMtSelezionato['int_' + prog.id]
        "
      >
        <tr
          [hidden]="!arrExpandStatus['prog_' + prog.id]"
          class=" table-grigio small intervento_{{ prog.id }}"
        >
          <td></td>
          <td>Intervento {{ intervento.codice }}</td>
          <td>{{ intervento.tipologia }}</td>
          <td>{{ intervento.descrizione }}</td>
          <td>{{ intervento.valorePrevisto }}</td>
          <td>{{ intervento.valoreEffettivo }}</td>
          <td>
            <div  class="progress-bar"
            [ngClass]="{
              'barra-rossa':
                intervento.dataFine > intervento.dataPrevistaFine,
              'barra-verde':
                intervento.dataFine <= intervento.dataPrevistaFine
            }"
            aria-valuemin="0"
            aria-valuemax="100">
              {{ intervento.percentuale }} %
            </div>
          </td>
          <td class="text-nowrap">
            {{ printValueAsDate(intervento.dataPrevistaFine) }}
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
