import { Component, OnInit,OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {   SoggettoGiuridicoService, StorageService } from '../../../services';
import { TipologiaInterventiService } from '../../../services/tipologia-interventi.service';

@Component({
  selector: 'app-search-progetto',
  templateUrl: './search-progetto.component.html',
  styleUrls: ['./search-progetto.component.css']
})
export class SearchProgettoComponent implements OnInit,OnDestroy {

  form: FormGroup;
  soggettiGiuridici = [];
  soggettiRespArray = [];
  areeTematicheCombo: any[] = [];
  tipologieIntervento = [];
  filters: any = null;

  public maskCodice: any = {
    mask: 'KKKKKKKKKKKKKKKKKK €',
    lazy: false,  // make placeholder always visible
    blocks: {
      KKKKKKKKKKKKKKKKKK: {
        mask: Number,
        thousandsSeparator: '.',
        scale: 2,
        padFractionalZeros: true,
        radix: ','
      }
    }
  };

  loadComponent = false;
  disableSearch = false;

  constructor(
    private soggettoGiuridicoService: SoggettoGiuridicoService,
    private formBuilder: FormBuilder,
    private storageService:StorageService
    ,private tipologiaInterventiService: TipologiaInterventiService
  ) { }
    ngOnDestroy(): void {
        if(this.elencoAreeSubscription)this.elencoAreeSubscription.unsubscribe();
        if (this.elencoRupSubscription)this.elencoRupSubscription.unsubscribe();
    }

  ngOnInit(): void {
    window.scroll(0, 0);

    this.getSoggettiGiuridici();
    this.getSoggettiResponsabili();
    this.getAreeTematiche();
    this.getInterventionTypes();

    this.form = this.initializeFormGroup();
  }

  private initializeFormGroup() {
    return this.formBuilder.group({
      codice: [null],
      importo: [null],
      descrizione: [null],
      areaGestionale: [null],
      soggetti: [null],
      rup: [null],
      cup: [null],
      tipologiaIntervento: [null]
    });
  }

  getSoggettiGiuridici() {
    this.soggettoGiuridicoService.getAll().subscribe(
        res => {
            let ress: any[]; 
            ress = res; 
            ress.map((i) => {
                i.descrizione = `${i.siglaProvincia} - ${i.codiceIdentificativo} - ${i.denominazione}`;
                return i;
            });
            this.soggettiGiuridici = ress;
        }
    );
  }

  

  private elencoRupSubscription = null;
  getSoggettiResponsabili() {

      this.elencoRupSubscription = this.storageService.elencoRup.subscribe(
          x => {
              this.soggettiRespArray = x;               
              console.log(this.soggettiRespArray);
          }
      );
  }



  private elencoAreeSubscription = null;
    getAreeTematiche() {
       
        this.elencoAreeSubscription = this.storageService.elencoAreeTematiche.subscribe(
            x=>{
                console.log(x);
                this.areeTematicheCombo = x || [];
            }
            );
    }

    getInterventionTypes() {
        this.tipologiaInterventiService.getTipologiaInterventi().subscribe(
            (result) => {
                this.tipologieIntervento = result;                
            },
            (error) => {
                console.log(error);
            }
        );

    }



  

  checkDisableSearch(): boolean {
    return this.disableSearch || (!this.f.rup.value && !this.f.cup.value && !this.f.tipologiaIntervento.value && !this.f.codice.value && !this.f.descrizione.value && !this.f.areaGestionale.value && (!this.f.soggetti.value || this.f.soggetti.value.length === 0) && !this.f.importo.value);
  }

  get f() {
    return this.form.controls;
  }

  disableFilters($event: boolean) {
    $event ? this.form.disable() : this.form.enable();
    this.disableSearch = $event;
  }

  setDisableSearchButton($event: boolean) {
    this.disableSearch = $event;
  }

  onSubmit() {
    this.disableSearch = true;
    this.loadComponent = true;
    this.filters = this.form.getRawValue();
  }
}
