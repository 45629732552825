import { SicerLiquidazione } from './sicer-liquidazione';
import { SicerOrdinativo } from './sicer-ordinativo';

export class SicerImpegnoAccertamento {
  id: string;
  utIns: string;
  dtIns: Date;
  utVar: string;
  dtVar: Date;
  flgValidita: string;
  lastOperation: string;
  azienda: string;
  pkCpt: string;
  codCpt: string;
  versoCpt: string;
  pkImpacc: string;
  pkCatenaCrono: string;
  versoImpacc: string;
  statoImpacc: string;
  annoImpacc: number;
  numeroImpacc: number;
  numModificaImpacc: number;
  annoFinanziarioImpacc: number;
  annoChiusuraImpacc: number;
  flagPerente: string;
  annoPerenzione: string;
  descrImpacc: string;
  importoIniImpacc: number;
  flagPrenotazione: string;
  tipoPrenotazione: string;
  descTipoPrenotazione: string;
  codPcf: string;
  descPcf: string;
  codStruttura: string;
  descStruttura: string;
  codComponente: string;
  descComponente: string;
  annoPropAtto: number;
  numPropAtto: string;
  numApprovAtto: string;
  dataAtto: string;
  descAtto: string;
  flagEsecutiva: string;
  dtEsecutivita: string;
  tipoAtto: string;
  descTipoAtto: string;
  codAzione: string;
  descCodAzione: string;
  codGestGsa: string;
  descCodGestGsa: string;
  codTransUe: string;
  descCodTransUe: string;
  codTesoreria: string;
  descTesoreria: string;
  descrCpt: string;
  dtVariazione: Date;
  finanziamentoId: number;
  interventoId: number;
  importoModifica: number;
  annoImpaccRib: number;
  numeroImpaccRib: number;
  ragsocSoggetto: string;
  codSoggetto: string;
  codFiscSoggetto: string;
  valoreAttualeImpacc: number;

  ordinativi: SicerOrdinativo[];
  liquidazioni: SicerLiquidazione[];

  isChangeSoggetto: boolean;

  minimoOrdineModifica: number;
}
