import { CurrencyPipe } from '@angular/common';
import {
    Component,
    OnInit,
    ViewChild,
    Input,
    EventEmitter,
    TemplateRef,
    Injectable,
    AfterViewInit,
    ElementRef,
    Output,
} from "@angular/core";
import {  BehaviorSubject } from "rxjs";
import{debounceTime,map} from 'rxjs/operators';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { ColumnMode, SortType, ContextmenuType } from '@swimlane/ngx-datatable';
import { FormBuilder, FormGroup } from '@angular/forms';
//import Table2Excel from 'table2excel.js';
defineLocale('it', itLocale);
import * as FileSaver from "file-saver";
declare var $: any;
import * as XLSX from 'xlsx';

//const Table2Excel = window.Table2Excel;
export class DtInputParams {
    draw: number = 0;
    length: number = 10;
    start: number = 0;
    search: { value: string, regex: boolean };
    order: Array<{ dir: string, column: number }>;
    columns: Array<{
        data: string
        , name: string
        , searchable: boolean
        , orderable: boolean
        , search: { value: string, regex: boolean }
    }>;

}


export class GridModel<T> {
    pageSize: number;
    totalElements: number;
    totalPages: number;
    currentPageNumber: number;
    sortBy: string;
    sortDir: string;
    _data: Array<T>;
    recordsFiltered: number;
    filter: string;

    constructor(defaultSortBy: string = 'Id', defaultSortDir: string = 'asc') {
        this.pageSize = 10;
        this.totalElements = 0;
        this.recordsFiltered = 0;
        this.totalPages = 0;
        this.currentPageNumber = 0;
        this._data = new Array<T>();
        this.sortBy = defaultSortBy;
        this.sortDir = defaultSortDir;
    }
    set data(a: Array<T>) {
        this._data = a;
    }
    get data(): Array<T> {
        return this._data;
    }

}

export class PageLimitOption {
    value: number;
    text: string;
    constructor(text: string, value: number) {
        this.value = value;
        this.text = text;
    }
}

export class DtOptions {
    pageLimitOptions?: PageLimitOption[] = [
        new PageLimitOption('10', 10)
        , new PageLimitOption('50', 50)
        , new PageLimitOption('100', 100)
        , new PageLimitOption('Tutti', 2147483647)
    ];
    pageSize: number = 20;
    class?: string;
    targetMarkerTemplate?: any;
    rows?: any;
    groupRowsBy?: string;
    groupedRows?: any[];
    columns?: any[];
    selected?: any[];
    scrollbarV?: boolean;
    headerHeight: number = 30;
    footerHeight: number = 0;
    externalPaging: boolean = false;
    externalSorting: boolean = false;
    limit?: number | undefined;
    scrollbarH: boolean = false;
    rowHeight: number | 'auto' | ((row?: any) => number) = 30;
    columnMode: ColumnMode | keyof typeof ColumnMode = ColumnMode.standard;
    offset?: number;
    hideRowsPerPage: boolean = false;
    hideSearch: boolean = false;
    loadingIndicator: boolean = false;
    resizeable?: boolean = false;
    draggable?: boolean = false;
    id?:string='datatable';
    buttons?:any={excel:false};

    /**
     * Type of row selection. Options are:
     *
     *  - `single`
     *  - `multi`
     *  - `checkbox`
     *  - `multiClick`
     *  - `cell`
     *
     * For no selection pass a `falsey`.
     * Default value: `undefined`
     */
    selectionType?: any;
    reorderable: boolean = true;
    swapColumns: boolean = true;
    sortType: SortType = SortType.single;
    sorts: any[] = [];

    cssClasses: any = {
        sortAscending: 'datatable-icon-up',
        sortDescending: 'datatable-icon-down',
        pagerLeftArrow: 'datatable-icon-left',
        pagerRightArrow: 'datatable-icon-right',
        pagerPrevious: 'datatable-icon-prev',
        pagerNext: 'datatable-icon-skip'
    };

    //callbackEvent on serverSide
    ajax: any;// EventEmitter<any> = new EventEmitter();
    serverSide: boolean = false;
    extraFilterTemplate:TemplateRef<any> = null;
    
    extraRichiestaSicerTemplate:TemplateRef<any> = null;

    /**
     * Message overrides for localization
     *
     * emptyMessage     [default] = 'No data to display'
     * totalMessage     [default] = 'total'
     * selectedMessage  [default] = 'selected'
     */
    messages = {
        // Message to show when array is presented
        // but contains no values
        emptyMessage: 'Nessun dato da visualizzare',

        // Footer total message
        totalMessage: 'elementi',

        // Footer selected message
        selectedMessage: 'selezionato'
    };

    /**
     * Row specific classes.
     * Similar implementation to ngClass.
     *
     *  [rowClass]="'first second'"
     *  [rowClass]="{ 'first': true, 'second': true, 'third': false }"
     */
    rowClass: any;

    /**
     * A boolean/function you can use to check whether you want
     * to select a particular row based on a criteria. Example:
     *
     *    (selection) => {
     *      return selection !== 'Ethel Price';
     *    }
     */
    selectCheck: any;

    /**
     * A function you can use to check whether you want
     * to show the checkbox for a particular row based on a criteria. Example:
     *
     *    (row, column, value) => {
     *      return row.name !== 'Ethel Price';
     *    }
     */
    displayCheck: (row: any, column?: any, value?: any) => boolean;

    /**
     * A boolean you can use to set the detault behaviour of rows and groups
     * whether they will start expanded or not. If ommited the default is NOT expanded.
     *
     */
    groupExpansionDefault: boolean = false;

    /**
     * Property to which you can use for custom tracking of rows.
     * Example: 'name'
     */
    trackByProp: string;

    /**
     * Property to which you can use for determining select all
     * rows on current page or not.
     *
     * @memberOf DatatableComponent
     */
    selectAllRowsOnPage: boolean = false;

    /**
     * A flag for row virtualization on / off
     */
    virtualization: boolean = true;

    /**
     * Tree from relation
     */
    treeFromRelation: string;

    /**
     * Tree to relation
     */
    treeToRelation: string;

    /**
     * A flag for switching summary row on / off
     */
    summaryRow: boolean = false;

    /**
     * A height of summary row
     */
    summaryHeight: number = 30;

    /**
     * A property holds a summary row position: top/bottom
     */
    summaryPosition: string = 'top';

    /**
     * Body was scrolled typically in a `scrollbarV:true` scenario.
     */
    scroll: EventEmitter<any> = new EventEmitter();

    /**
     * A cell or row was focused via keyboard or mouse click.
     */
    activate: EventEmitter<any> = new EventEmitter();

    /**
     * A cell or row was selected.
     */
    select: EventEmitter<any> = new EventEmitter();

    /**
     * Column sort was invoked.
     */
    sort: EventEmitter<any> = new EventEmitter();

    /**
     * The table was paged either triggered by the pager or the body scroll.
     */
    page: EventEmitter<any> = new EventEmitter();

    /**
     * Columns were re-ordered.
     */
    reorder: EventEmitter<any> = new EventEmitter();

    /**
     * Column was resized.
     */
    resize: EventEmitter<any> = new EventEmitter();

    /**
     * The context menu was invoked on the table.
     * type indicates whether the header or the body was clicked.
     * content contains either the column or the row that was clicked.
     */
    tableContextmenu = new EventEmitter<{ event: MouseEvent; type: ContextmenuType; content: any }>(false);
    /**
     * A row was expanded ot collapsed for tree
     */
    treeAction: EventEmitter<any> = new EventEmitter();
}
@Injectable()
@Component({
    selector: 'ngx-datatable-wrapper',
    templateUrl: './ngx-datatable-wrapper.component.html',
    styleUrls: ['./ngx-datatable-wrapper.component.css']
})
export class NgxDatatableWrapperComponent implements OnInit,AfterViewInit {

    @ViewChild('datatable', { static: true })
    public datatable: TemplateRef<any>;

    @ViewChild('hideTemplate', { static: true })
    public hideTemplate: TemplateRef<any>;
    @ViewChild('normalValueTemplate', { static: true })
    public normalValueTemplate: TemplateRef<any>;

    @ViewChild('dateValueTemplate', { static: true })
    public dateValueTemplate: TemplateRef<any>;
    @ViewChild('dateTimeValueTemplate', { static: true })
    public dateTimeValueTemplate: TemplateRef<any>;

    @ViewChild('booleanValueTemplate', { static: true })
    public booleanValueTemplate: TemplateRef<any>;

    @ViewChild('currencyValueTemplate', { static: true })
    public currencyValueTemplate: TemplateRef<any>;

    @Output() initialized: EventEmitter<any> = new EventEmitter();

    
    @Input() set reloadData(value){
        console.log(`inside reloadData with:${value}`);
        if (value){
            this.refresh();
        }
    }

    

public ngAfterViewInit(){
    //this.refresh();
    //console.log('afterviewinit');
    this.clearHeaderColumnsTitles();
    this.initialized.emit(true);
}

    private _pageLimitOptions: PageLimitOption[] = [
        new PageLimitOption('10', 10)
        , new PageLimitOption('20', 20)
        , new PageLimitOption('50', 50)
        , new PageLimitOption('100', 100)
        , new PageLimitOption('Tutti', 2147483647)
    ];
    public get pageLimitOptions() {
        return this._pageLimitOptions;
    }
    private currentPageLimit: number = 10;

    public originalRows: any[];

    _isLoading = false;
    public set isLoading(value:boolean){
        this._isLoading = value;
    }
    public get isLoading():boolean{
        return this._isLoading;
    }

    private _gridModel: GridModel<any>;
    /*get gridModel():Page{
        return this._page;
    }*/
    public set gridModel(obj: any) {
        this._gridModel = obj;
    }
    public get gridModel() {
        return this._gridModel;
    }

    public addRow(obj: any) {
        let rows = [obj, ...this.gridModel.data];
        this.gridModel.data = rows;
        this.gridModel.totalElements++;
        if (this.originalRows) {
            this.originalRows = [obj, ...this.originalRows];
        }
    }

    public updateRow(obj: any) {
        let rows = [...this.gridModel.data];
        rows[rows.findIndex(r => r.id == obj.id)] = obj;
        this.gridModel.data = rows;
        if (this.originalRows) {
            this.originalRows[this.originalRows.findIndex(r => r.id == obj.id)] = obj;
        }
    }
    public deleteRow(obj: any) {
        let rows = this.gridModel.data.filter(x => x.id != obj.id);
        this.gridModel.data = rows;
        this.gridModel.totalElements--;
        if (this.originalRows) {
            this.originalRows = this.originalRows.filter(x => x.id != obj.id);
        }
    }

    //this methods is useful only for client side because we need to save the first set of data, so we can apply filters
    public setInitialData(rows: any[]) {
        //console.log(`setinitialdata`);
        this.dtOptions = this.dtOptions;
        this.originalRows = rows;
        this.gridModel.data = [...this.originalRows];
        this.gridModel.totalElements = this.gridModel.data.length;
        this.clearHeaderColumnsTitles();
    }

    public columns = [];

    private _dtInputParams: DtInputParams;

    public get dtInputParams(){
        return this._dtInputParams;
    }

    private _dtOptions = new BehaviorSubject<DtOptions>(null);

    private _originalColumns = [];

    public get originalColumns(){
        return this._originalColumns;
    }

    @Input() templates;



    @Input() set dtOptions(opt: DtOptions) {
       // console.log(`inside set dtOptions`);
       if (!this.originalRows && opt.rows)this.originalRows = opt.rows;
        this._originalColumns = opt.columns;
        opt.columns = opt.columns.filter(x => x.visible != false);
        this.columns = opt.columns.map((col: any) => ({
            ...col,
            /*name: col.name.replace('<br />','\n'),*/
            //headerTemplate: this.customHeaderTemplate,
            /*cellTemplateTmp:col.cellTemplate?col.cellTemplate:(col.visible==false?'hideTemplate':'normalValueTemplate'),*/
            cellTemplate: this.templates.find(
                x => x.name == col.cellTemplate) ?
                this.templates.find(x => x.name == col.cellTemplate)['template']
                :
                col.cellTemplate == 'dateValueTemplate' ? this.dateValueTemplate
                    :
                    col.cellTemplate == 'booleanValueTemplate' ? this.booleanValueTemplate
                        :
                        col.cellTemplate == 'currencyValueTemplate' ? this.currencyValueTemplate
                            :
                            col.cellTemplate == 'dateTimeValueTemplate' ? this.dateTimeValueTemplate
                            :
                            null

            /*,
            headerTemplate: this[col.headerTemplate]*/
            , width: col.visible == false ? -10000 : col.width
            , resizeable: col.resizeable ? col.resizeable : false
            , draggable: col.draggable ? col.draggable : false

        }));
        //this._dtOptions = opt;
        if (opt.pageLimitOptions) this._pageLimitOptions = opt.pageLimitOptions;
        //set first option value as default
        this.gridModel.pageSize = this._pageLimitOptions[0].value;
        if (opt.pageSize) {//check if the value is inside the array, otherwise the page size is the first option
            if (this._pageLimitOptions.find(x => x.value == opt.pageSize))
                this.gridModel.pageSize = opt.pageSize;
        }
        this.currentPageLimit = this._pageLimitOptions[0].value;
        //let cols = this.columns.filter(x=>x.visible != false);
        //this.columns = cols;
        if (opt !== undefined) {
            this._dtOptions.next(opt);
        }
        this.clearHeaderColumnsTitles();
    }

    clearHeaderColumnsTitles(){
        let thList = $('.datatable-header .datatable-header-cell');
        if (thList.length>0){
            for(let index=0; index< thList.length;index++)
                {
                    let x = thList[index];
                  let title =  x.title;// $(x).attr('title');
                  title = title?title.replace('<br/>',' ').replace('<br />',' ').replace('<br>',' ').replace('</br>',' '):null
                  //$(x).attr('title',title);
                  x.title = title;
                }
            
        }
        //console.log(`${thList}`);
    }

    get dtOptions(): DtOptions {
        // console.log(`inside get dtOptions`);
        return this._dtOptions.getValue();
    }

    messages = {
        // Message to show when array is presented
        // but contains no values
        emptyMessage: 'Nessun dato da visualizzare',

        // Footer total message
        totalMessage: 'elementi',

        // Footer selected message
        selectedMessage: 'selezionato'
    };


    public loadPage(pageEvent = { offset: 0 }) {
       // console.log(`loadPage with offset= ${pageEvent.offset}`);
        this.gridModel.currentPageNumber = pageEvent.offset;
        //this.onFetchDataRequired.emit(this.gridModel);        
        if (this.dtOptions.serverSide) {
            this.clearData();
            this.isLoading = true;
            this.dtOptions.ajax(this.updateDtInputParams(), (obj: any) => this.ajaxCallBack(obj));
        }

    }

    clearData(){
        this.gridModel = {...this.gridModel,data:[],totalElements:0};
        
    }

    private ajaxCallBack(obj: { recordsTotal: number, recordsFiltered: number, data: any[] }) {
        //console.log('inside ajaxCallBack');
        this.gridModel.data = obj.data;
        this.gridModel.totalElements = obj.recordsTotal;
        if ((obj.recordsFiltered && obj.recordsFiltered > 0)|| obj.data.length==0)  this.gridModel.totalElements = obj.recordsFiltered;
        //this.dtOptions.loadingIndicator = false;
        this.isLoading = false;
    }

    private baseDtInputParams() {
        // console.log('updateDtInputParams');
 
         if (this.dtOptions.sorts){
             this.gridModel.sortBy = this.dtOptions.sorts[0].prop;
             this.gridModel.sortDir = this.dtOptions.sorts[0].dir;
         }
 
         this.dtInputParams.draw++;
         this.dtInputParams.length = this.gridModel.pageSize;
         this.dtInputParams.start = 0;
         if (this.gridModel.sortBy && this.gridModel.sortBy !== '' && this._originalColumns) {
             let colIndex = this._originalColumns.findIndex(x => x.prop ? x.prop == this.gridModel.sortBy : x.name == this.gridModel.sortBy);
             if (colIndex<0)colIndex =0;
             this.dtInputParams.order = [{ dir: this.gridModel.sortDir, column: colIndex }];
             if(this.gridModel.sortBy!='id'){
                 this.dtInputParams.order.push({dir:'asc',column:this._originalColumns.findIndex(x => x.prop ? x.prop == 'id' : x.name == 'id')});
             }
         }
         this.dtInputParams.columns = this._originalColumns.map(
             ({ name, prop, sortable, filtrable,search }) => ({
                 data: prop ? prop : 'null'
                 , name: prop?name:''
                 , searchable: filtrable!=null?filtrable:true
                 , orderable: sortable!=null?sortable:true
                 
                 , search:search!=null?search: { value: '', regex: false }
             })
         );
         this.dtInputParams.search = { value: this.gridModel.filter, regex: false };
         return this.dtInputParams;
     }

    private updateDtInputParams() {
       // console.log('updateDtInputParams');

        if (this.dtOptions.sorts){
            this.gridModel.sortBy = this.dtOptions.sorts[0].prop;
            this.gridModel.sortDir = this.dtOptions.sorts[0].dir;
        }

        this.dtInputParams.draw++;
        this.dtInputParams.length = this.gridModel.pageSize;
        this.dtInputParams.start = this.gridModel.currentPageNumber * this.gridModel.pageSize;
        if (this.gridModel.sortBy && this.gridModel.sortBy !== '' && this._originalColumns) {
            let colIndex = this._originalColumns.findIndex(x => x.prop ? x.prop == this.gridModel.sortBy : x.name == this.gridModel.sortBy);
            if (colIndex<0)colIndex =0;
            this.dtInputParams.order = [{ dir: this.gridModel.sortDir, column: colIndex }];
            if(this.gridModel.sortBy!='id'){
                this.dtInputParams.order.push({dir:'asc',column:this._originalColumns.findIndex(x => x.prop ? x.prop == 'id' : x.name == 'id')});
            }
        }
        this.dtInputParams.columns = this._originalColumns.map(
            ({ name, prop, sortable, filtrable,search }) => ({
                data: prop ? prop : 'null'
                , name: prop?name:''
                , searchable: filtrable!=null?filtrable:true
                , orderable: sortable!=null?sortable:true
                
                , search:search!=null?search: { value: '', regex: false }
            })
        );
        this.dtInputParams.search = { value: this.gridModel.filter, regex: false };
        return this.dtInputParams;
    }



    constructor(private formBuilder:FormBuilder
       ) {
        this.gridModel = new GridModel<any>();
        this._dtInputParams = new DtInputParams();
        this.searchForm = formBuilder.group({ filterText: [''] });
        this.searchForm.get('filterText').valueChanges
        .pipe(
            map((i: any) => i),
            debounceTime(500)
          )
        .subscribe(
            data=>{/*console.log(data);*/this.onFilterChange(data);}
        );
       // console.log('constructor');
    }


    searchForm:FormGroup;

    ngOnInit(): void {
        //console.log('ngoninit');
        this.gridModel.totalElements = this.gridModel.data.length;
        //this._dtOptionsInternal = new DtOptions();
        this._dtOptions.subscribe(gridModel => {
            //console.log(`inside subscribe with: ${gridModel}`);
            //this._dtOptionsInternal = gridModel;
            this.updateDtInputParams();
            if (this.dtOptions.rows) {
                this.gridModel.data = this.dtOptions.rows;
                this.gridModel.totalElements = this.gridModel.data.length;
            }
            //this.isLoading = false;

            //if (gridModel.messages) this.messages = Object.assign(this.messages, gridModel.messages);
            if (gridModel.messages) this.messages = {...this.messages, ...gridModel.messages};

        }, err => console.log(err));
        this.loadPage();
    }

    //the follow variable is used to understand if it's the default sorting or not
    //sortDone=false;


    public onSort(event) {
        //this.sortDone = true;
        console.log(`inside onSort: ${event}`);

        this.gridModel.sortBy = event.sorts[0].prop;
        this.gridModel.sortDir = event.sorts[0].dir;        
        this.dtOptions.sorts = event.sorts;
        //add sort by id as default as second filter, to give always the same results
        /*if(this.gridModel.sortBy!='id'){
            this.dtOptions.sorts.push({dir:'asc',prop:'id'});
        }*/

        if (this.dtOptions.serverSide) {
            this.loadPage();
            return;
        }
        const rows = [...this.gridModel.data];
        const sort = event.sorts[0];
        rows.sort((a, b) => {
            return a[sort.prop].toString().localeCompare(b[sort.prop]) * (sort.dir === 'desc' ? -1 : 1);
        });
        this.originalRows.sort((a, b) => {
            return a[sort.prop].toString().localeCompare(b[sort.prop]) * (sort.dir === 'desc' ? -1 : 1);
        });

        this.gridModel.data = rows;
        this.loadPage();
    }

    public onLimitChange(limit: any): void {
        console.log(`onLimitChange=${limit}`);
        this.gridModel.pageSize = this.currentPageLimit = parseInt(limit, 10);
        this.loadPage();
    }

    public onFilterChange(filter: string): void {
        //console.log(`onFilterChange=${filter}`);
        this.gridModel.filter = filter;

        if (!this.dtOptions.serverSide) {
            if ((this.gridModel.filter != '' || this.gridModel.data.length != this.originalRows.length)) {
                let rows = [...this.originalRows];
                rows = rows.filter(
                    x => {
                        let ris = false;
                        for (let property in x) {
                         //   console.log(property);
                            let col = this.columns.find(x => x.prop == property);
//                            console.log(`col: ${col?col.name:col}`);
                            if (col && col['filtrable'] === false) continue;
                            if (col && col['filterFunction'] && x[property]!=null){
                                if(col['filterFunction'](this.gridModel.filter.toLowerCase(),x[property].toString().toLowerCase()))ris = true;
                            }
                            else{
                                if (x[property] && x[property].toString().toLowerCase().includes(this.gridModel.filter.toLowerCase())) ris = true;
                            }
                            //console.log(`key= ${property} value = ${x[property]}`);
                            //console.log(`x[property].toString().includes(dataTablesParameters.search.value)= ${x[property].toString().includes(this.gridModel.filter)}`);
                            
                        }
                        return ris;
                    }
                );
                this.gridModel.data = rows;
                this.gridModel.totalElements = rows.length;
            }
        }
        this.loadPage();
    }

    ngOnDestroy(): void {
        this._dtOptions.unsubscribe();
    }

    printValueAsDate(value) {
        if (!value || value == '') return '';
        let v = moment(value).toDate();
        //v.setHours(v.getHours() - v.getTimezoneOffset() / 60);
        return moment(v).format('DD/MM/YYYY');
    }
    printValueAsDateTime(value){
        if (!value || value == '') return '';
        let v = moment(value).toDate();
        //v.setHours(v.getHours() - v.getTimezoneOffset() / 60);
        return moment(v).format('DD/MM/YYYY HH:mm:ss');
    }
    printValueAsCurrency(value) {
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }

    public reset(): void {
        let rows = [...this.gridModel.data];
        this.columns = [...this.columns];
        this.gridModel.data = rows;
        if (this.dtOptions.serverSide) {
            this.isLoading = true;
            //this.dtOptions.loadingIndicator = true;
            this.dtOptions.ajax(this.baseDtInputParams(), (obj: any) => this.ajaxCallBack(obj));
        }
    }

    public refresh():void{
        let rows = [...this.gridModel.data];
        this.columns = [...this.columns];
        this.gridModel.data = rows;
        if (this.dtOptions.serverSide) {
            this.isLoading = true;
            //this.dtOptions.loadingIndicator = true;
            this.dtOptions.ajax(this.updateDtInputParams(), (obj: any) => this.ajaxCallBack(obj));
        }
    }

    public exportExcelFunction():void{
        //if (this.dtOptions.exportExcelFunction)this.dtOptions.exportExcelFunction();
        let rowsToExport = [];
        let rows = this.gridModel.data;
        
        for(let rowIndex in rows){
            let row = rows[rowIndex];            
            let rowToExport = {};
            for(let i in this.dtOptions.columns){
                let column = this.dtOptions.columns[i];
                if (column.exportable===false)continue;
                let value =row[column.prop];
                if (column.exportFunction){
                    value = column.exportFunction(row);
                }
                let columnName = column.name;
                columnName = columnName?columnName.replace('<br/>',' ').replace('<br />',' ').replace('<br>',' ').replace('</br>',' ').replace('<br>',' ').replace('&ensp;',' ').replace('<br>',' ').replace('&nbsp;',' '):null
                rowToExport[columnName] = value;
            }
            rowsToExport.push(rowToExport);
        }
        let ws = XLSX.utils.json_to_sheet(rowsToExport);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet");

        

        XLSX.writeFile(wb,  ('export.xlsx' ));
    }

    

    
/*

    public exportExcel(){
        
        //return;
       let origin = document.getElementById(this.dtOptions.id);
       //let headers = table.getElementsByClassName('datatable-header-cell-label');
       //for(let elem of table.getElementsByClassName('datatable-header-cell-label'))
       // console.log(elem?elem.innerText:null);

        let body=document.body,
        parent=document.getElementById(this.dtOptions.id),
        rows=parent.getElementsByTagName("datatable-body-row"),
        table=document.createElement("table"),
        thead = document.createElement('thead'),
        tbody=document.createElement("tbody"),        
        headers = origin.getElementsByClassName('datatable-header-cell-label'),
        x=rows.length,        
        y=headers.length,
        divExportExcel = document.getElementById('div_export_excel')
        ;

        //faccio header
        table.appendChild(thead);  
        let tHeadRow = document.createElement('tr');
        thead.appendChild(tHeadRow);      
        for(let j=0;j<y;j++){
            let cell =document.createElement('th');
            cell.innerHTML = headers[j].innerHTML;
            tHeadRow.appendChild(cell);
        }
        //header fatto


        table.appendChild(tbody);

        for(let i=0;i<x;i++){

            let row = document.createElement("tr");
            let cells=rows[i].querySelectorAll(".datatable-body-cell-label");
            
            for(let j=0;j<y;j++){
                let cell=document.createElement('td');
                cell.innerHTML=cells[j].innerHTML;
                row.appendChild(cell);
            }

            tbody.appendChild(row);
        }
        divExportExcel.innerHTML = '';
        divExportExcel.appendChild(table);
        this.exportTry();
    }

    @ViewChild('div_export_excel') divExportExcel: ElementRef;



exportTry(){
    var elt = document.getElementById('div_export_excel');
	//var wb = XLSX.utils.table_to_book(elt);
    let ws = XLSX.utils.json_to_sheet(this.gridModel.data);
    var wb = XLSX.utils.book_new();
XLSX.utils.book_append_sheet(wb, ws, "Sheet");
    XLSX.writeFile(wb,  ('SheetJSTableExport.xlsx' ));


}*/

/*
if(document.getElementById("testScript"))
            document.getElementById("testScript").remove();
        var testScript = document.createElement("script");
      testScript.setAttribute("id", "testScript");      
      testScript.text =`
            console.log('script');
            thList1 = $('.datatable-header .datatable-header-cell');
            for(let index=0; index< thList1.length;index++)
                {
                    let x = thList1[index];
                  let title =  $(x).attr('title');
                  title = title?title.replace('<br/>',' ').replace('<br />',' ').replace('<br>',' ').replace('</br>',' '):null
                  $(x).attr('title',title);
                  //x.title = title;
                }

      `;
      document.body.appendChild(testScript);

*/ 


    
}