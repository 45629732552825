import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "./app-config.service";
import { KeyValue } from "../models/keyValue";
import {Continent} from "../models/continent";
import { Region } from "../models/region";
import { Municipality } from "../models/municipality";
import { Province } from '../models/province';
import { Nation } from '../models/nation';


@Injectable({
  providedIn: "root"
})
export class GeoService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.geo}`;
  private protectedUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.geo_protected}`;

  constructor(private http: HttpClient) {}

  getContinents() {
    return this.http.get<KeyValue[]>(`${this.apiUrl}continents`);
  }

  getNations() {
    return this.http.get<KeyValue[]>(`${this.apiUrl}nazioni`);
  }

  getItalianRegions() {
    return this.http.get<KeyValue[]>(`${this.apiUrl}regioni`);
  }

  getProvinces(countryCode: string) {
    return this.http.get<KeyValue[]>(
      `${this.apiUrl}regioni/${countryCode}/province`
    );
  }

  getMunicipalities(provinceCode: string) {
    return this.http.get<KeyValue[]>(
      `${this.apiUrl}province/${provinceCode}/comuni`
    );
  }

  getAllMunicipalitiesByRegion(region_code: string) {
    return this.http.get<KeyValue[]>(
      `${this.apiUrl}province/regione/comuni/${region_code}`
    );
  }

  createMunicipality(m: Municipality) {
    return this.http.post<Boolean>(
      `${this.protectedUrl}municipalities`,
      m
    );
  }

  

  createProvince(m: Province) {
    return this.http.post<Boolean>(
      `${this.protectedUrl}provinces`,
      m
    );
  }

  createNation(m: Nation) {
    return this.http.post<Boolean>(
      `${this.protectedUrl}nations`,
      m
    )
  }

  createRegion(m: Region) {
    return this.http.post<Boolean>(
      `${this.protectedUrl}regions`,
      m
    )
  }

  

  getProvincesByRegion(regionId: string) {
    return this.http.get<KeyValue[]>(
      `${this.protectedUrl}regions/${regionId}/provinces`
    );
  }

  getItalianMunicipalities(withNations: boolean) {
    return this.http.get<KeyValue[]>(
      `${this.apiUrl}nazioni/italia/comuni?nations=${withNations}`
    );
  }

  getItalianMunicipalitiesAndNation() {
    return this.http.get<KeyValue[]>(
      `${this.apiUrl}nazioni/italia/comuni?nazioni=true`
    );
  }

  getActualProvinces(withMetropolitanCities: boolean) {
    return this.http.get<KeyValue[]>(
      `${this.apiUrl}/Provinces?withMetropolitanCities=${
        withMetropolitanCities ? 1 : 0
      }`
    );
  }

  getActualMetropolitancities() {
    return this.http.get<KeyValue[]>(
      `${this.apiUrl}/provinces/metropolitancities`
    );
  }

  getActualUnionOfMunicipalities(provinceCode: string) {
    return this.http.get<KeyValue[]>(
      `${this.apiUrl}/provinces/${provinceCode}/UnionOfMunicipalities`
    );
  }
  getActualMunicipalities(provinceCode: string) {
    return this.http.get<KeyValue[]>(
      `${this.apiUrl}/Provinces/${provinceCode}/ActualMunicipalities`
    );
  }

  getCitizenships() {
    return this.http.get<KeyValue[]>(`${this.apiUrl}cittadinanze`);
  }

getMunicipalitiesByProvinceCode(provinceCode:number,dataTablesParameters:any){
    return this.http
      .post<any>(
        `${this.protectedUrl}provinces/${provinceCode}/municipalities`,
        dataTablesParameters
      )
}

getAllProvinceDt(regionId:number,dataTablesParameters:any){
    return this.http
    .post<any>(
      `${this.protectedUrl}regions/${regionId}/provinces`,
      dataTablesParameters
    )
}


getAllGeoProvinces(){
  return this.http.get<any>(
    `${this.apiUrl}geo/province`,
  )
}

getAllGeoMunicipalities(){
  return this.http.get<any>(
    `${this.apiUrl}geo/municipalities`,
  )
}
getAllGeoAsl(){
  return this.http.get<any>(
    `${this.apiUrl}geo/asl`,
  )
}
  
getAllQuantityInLocations(filtersObject){
  return this.http.get<any>(
    `${this.apiUrl}geo/quantity/locations`, {params: {filterObject: JSON.stringify(filtersObject)}},
  )
}
getChartDataComuniDashboardNonDisegnabile(filtersObject: any){
  return this.http.get<any>(
    `${this.apiUrl}geo/chart/municipalities`, {params: {filterObject: JSON.stringify(filtersObject)}}
  )
}

getChartDataProvincieDashboardNonDisegnabile(filtersObject: any){
  return this.http.get<any>(
    `${this.apiUrl}geo/chart/provinces`, {params: {filterObject: JSON.stringify(filtersObject)}}
  )
}

getChartDataNatureTipologieDashboardNonDisegnabile(filtersObject: any){
  return this.http.get<any>(
    `${this.apiUrl}geo/chart/nature/tipologie/numinterventi`, {params: {filterObject: JSON.stringify(filtersObject)}}
  )
}

getChartDataImportiNatureTipologieDashboardNonDisegnabile(filtersObject: any){
  return this.http.get<any>(
    `${this.apiUrl}geo/chart/nature/tipologie/importi`, {params: {filterObject: JSON.stringify(filtersObject)}}
  )
}

getMunicipalitiesWithIstat(region_code){
  return this.http.get<any[]>(
    `${this.apiUrl}province/regione/comuni-istat/${region_code}`
  );
}

getProvincesWithIstat(countryCode: string) {
  return this.http.get<any[]>(
    `${this.apiUrl}regioni/${countryCode}/province`
  );
}

countInterventiPlurilocalizzatiDisegnatiInAltroLuogo(filtersObject: any){
    return this.http.get<any>(
      `${this.apiUrl}geo/chart/pluri_localizzati_altro_luogo`, {params: {filterObject: JSON.stringify(filtersObject)}}
    )
  }


}
