import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { ToastService } from 'src/app/services';
import { InterventoService } from '../../services/intervento.service';
import { BdapDirective } from './bdap.directive';

@Component({
  selector: 'bdap-component',
  templateUrl: './bdap.component.html',
  styleUrls: ['./bdap.component.scss']
})
export class BdapComponent extends BdapDirective implements OnInit {

  @Input()
  interventoId: number;
  @Input()
  cup: string;
  @Input()
  gotoBtnOptions: any;

  _isInModal: boolean = false;
  @Input()
  set isInModal(value: boolean) {
    this._isInModal = value;
  }
  get isInModal() { return this._isInModal; }

  @Output()
  gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('ngxDtWrpFondo')
  ngxDtWrpFondo: NgxDatatableWrapperComponent;
  @ViewChild('ngxDtWrpQuadro')
  ngxDtWrpQuadro: NgxDatatableWrapperComponent;
  @ViewChild('ngxDtWrpAggiudicazione')
  ngxDtWrpAggiudicazione: NgxDatatableWrapperComponent;
  @ViewChild('ngxDtWrpSoggetti')
  ngxDtWrpSoggetti: NgxDatatableWrapperComponent;
  @ViewChild('ngxDtWrpIndicatori')
  ngxDtWrpIndicatori: NgxDatatableWrapperComponent;
  @ViewChild('ngxDtWrpPercettori')
  ngxDtWrpPercettori: NgxDatatableWrapperComponent;
  @ViewChild('ngxDtWrpGiustificativo')
  ngxDtWrpGiustificativo: NgxDatatableWrapperComponent;
  @ViewChild('ngxDtWrpPagamentiBDAP')
  ngxDtWrpPagamentiBDAP: NgxDatatableWrapperComponent;
  @ViewChild('ngxDtWrpPagamentiSIOPE')
  ngxDtWrpPagamentiSIOPE: NgxDatatableWrapperComponent;
  @ViewChild('ngxDtWrpPagamentiFattura')
  ngxDtWrpPagamentiFattura: NgxDatatableWrapperComponent;
  @ViewChild('ngxDtWrpCosti')
  ngxDtWrpCosti: NgxDatatableWrapperComponent;

  dtOptionsFondo: any;
  dtOptionsQuadro: any;
  dtOptionsAggiudicazione: any;
  dtOptionsSoggetti: any;
  dtOptionsIndicatori: any;
  dtOptionsPercettori: any;
  dtOptionsGiustificativo: any;
  dtOptionsPagamentiBDAP: any;
  dtOptionsPagamentiSIOPE: any;
  dtOptionsPagamentiFattura: any;
  dtOptionsCosti: any;

  templateList: any[] = [];
  @ViewChild("rigthAlignDataTemplate", { static: true })
  public rigthAlignDataTemplate: TemplateRef<any>;

  fondo: any[] = [];
  indicatori: any[] = [];
  impegni: any[] = [];
  soggettiCorrelati: any[] = [];
  quadroEconomico: any[] = [];
  procedureAggiudicazione: any[] = [];
  percettori: any[] = [];
  giustificativi: any[] = [];
  pagamenti: any[] = [];

  dtDataSize: Map<string, boolean> = new Map<string, boolean>();

  noAccessDataLabel = 'ACCESSO ALLA RISORSA NON PERMESSO';
  noAccessDataFieldLabel = 'Accesso alla risorsa non permesso';

  constructor(
    private interventoService: InterventoService
    ,private toastr: ToastService,
  ) {
    super();

    this.dtOptionsFondo = this.getDtOptions('fondo', this.loadDataFondo);
    this.dtOptionsQuadro = this.getDtOptions('quadro', this.loadDataQuadro);
    this.dtOptionsAggiudicazione = this.getDtOptions('aggiudicazione', this.loadDataAggiudicazione);
    this.dtOptionsSoggetti = this.getDtOptions('soggetti', this.loadDataSoggetti);
    this.dtOptionsIndicatori = this.getDtOptions('indicatori', this.loadDataIndicatori);
    this.dtOptionsPagamentiBDAP = this.getDtOptions('pagamentiBDAP', this.loadDataPagamentiBDAP);
    this.dtOptionsPercettori = this.getDtOptions('percettori', this.loadDataPercettori);
    this.dtOptionsGiustificativo = this.getDtOptions('giustificativo', this.loadDataGiustificativo);
    this.dtOptionsPagamentiSIOPE = this.getDtOptions('pagamentiSIOPE', this.loadDataPagamentiSIOPE);
    this.dtOptionsPagamentiFattura = this.getDtOptions('pagamentiFattura', this.loadDataPagamentiFattura);
    this.dtOptionsCosti = this.getDtOptions('costi', this.loadDataCosti);

    this.dtDataSize.set('fondo', true);
    this.dtDataSize.set('quadro', true);
    this.dtDataSize.set('aggiudicazione', true);
    this.dtDataSize.set('soggetti', true);
    this.dtDataSize.set('indicatori', true);
    this.dtDataSize.set('impegno', true);
    this.dtDataSize.set('percettori', true);
    this.dtDataSize.set('giustificativo', true);
    this.dtDataSize.set('pagamentiSIOPE', true);
    this.dtDataSize.set('pagamentiFattura', true);
    this.dtDataSize.set('costi', true);
  }

  ngOnInit() {
    this.templateList = [
      { name: "rigthAlignDataTemplate", template: this.rigthAlignDataTemplate }
    ];
  }

  ngAfterViewInit(): void {

  }

  gotoBackBtnClick() {
    this.gotoBtnFunction.emit(true);
  }

  reloadAllData() {
    if (!this.cup) return;

    this.ngxDtWrpFondo.gridModel.data = [];
    this.ngxDtWrpFondo.refresh();

    this.ngxDtWrpQuadro.gridModel.data = [];
    this.ngxDtWrpQuadro.refresh();

    this.ngxDtWrpAggiudicazione.gridModel.data = [];
    this.ngxDtWrpAggiudicazione.refresh();

    this.ngxDtWrpSoggetti.gridModel.data = [];
    this.ngxDtWrpSoggetti.refresh();

    this.ngxDtWrpIndicatori.gridModel.data = [];
    this.ngxDtWrpIndicatori.refresh();

    this.ngxDtWrpPagamentiBDAP.gridModel.data = [];
    this.ngxDtWrpPagamentiBDAP.refresh();

    this.ngxDtWrpPercettori.gridModel.data = [];
    this.ngxDtWrpPercettori.refresh();

    this.ngxDtWrpGiustificativo.gridModel.data = [];
    this.ngxDtWrpGiustificativo.refresh();

    this.ngxDtWrpPagamentiSIOPE.gridModel.data = [];
    this.ngxDtWrpPagamentiSIOPE.refresh();

    this.ngxDtWrpCosti.gridModel.data = [];
    this.ngxDtWrpCosti.refresh();

    this.ngxDtWrpPagamentiFattura.gridModel.data = [];
    this.ngxDtWrpPagamentiFattura.refresh();
  }

  loadDataFondo = (dataTablesParameters: any, callback: any) => {
    if (!this.interventoId) return;
    this.interventoService.getBdapData(dataTablesParameters, this.interventoId, 'A9')
      .subscribe(resp => {
        if (!resp) return;

        const a9 = resp?.data[0]?.A9;

        this.dtDataSize.set('fondo', a9);
        if (a9) {
          const results = a9?.results;
          results ? resp.data = results : resp = this.createBaseEmptyDt();
          results ? resp.recordsTotal = resp.recordsTotal : null;
          results ? resp.recordsFiltered = resp.recordsFiltered : null;
          resp.data.forEach(data => {
            data.regione = this.checkFieldValue(data.regione) ? '' : 'CF1';
           data.codFonte = data.codFonte + ' - ' + data.descFonte;
        });
          callback(resp);
        }
    }
    ,error=>{this.toastr.error("Errore: La Banca Dati Amministrazioni Pubbliche non è al momento disponibile. Prova la consultazione più tardi");}
    );
  };

  loadDataQuadro = (dataTablesParameters: any, callback: any) => {
    if (!this.interventoId) return;
    this.interventoService.getBdapData(dataTablesParameters, this.interventoId, 'A11')
      .subscribe(resp => {
        if (!resp) return;

        const a11 = resp?.data[0]?.A11;

        this.dtDataSize.set('quadro', a11);
        if (a11) {
          const results = a11?.results;
          results ? resp.data = results : resp = this.createBaseEmptyDt();
          results ? resp.recordsTotal = resp.recordsTotal : null;
          results ? resp.recordsFiltered = resp.recordsFiltered : null;
          resp.data.forEach(data => { data.codVoceDiSpesa = data.codVoceDiSpesa + ' - ' + data.descVoceDiSpesa; });
          callback(resp);
        }
    });
  };

  loadDataAggiudicazione = (dataTablesParameters: any, callback: any) => {
    if (!this.interventoId) return;
    this.interventoService.getBdapData(dataTablesParameters, this.interventoId, 'procedureAggiudicazione')
      .subscribe(resp => {
        if (!resp) return;

        const a26 = resp?.data[0]?.A26;
        const a28 = resp?.data[0]?.A28;
        const a29 = resp?.data[0]?.A29;

        const existsData = a26 || a28 || a29;
        this.dtDataSize.set('aggiudicazione', existsData);

        if (existsData) {
          const a26Result = this.getData(a26);
          const a28Result = this.getData(a28);
          const a29Result = this.getData(a29);
          a26Result && a26Result.length > 0 ? a26Result.forEach(data => data.codRuoloSub = data.codRuolo) : null;
          const vector = this.getLongestVector([ a26Result, a28Result, a29Result ]);
          const respData = [];
          for (var i = 0; i < vector.length; i++) {
            respData.push({});
            this.fillDataFields(respData, a26Result, resp, i, i, 'A26', 'A26');
            if (vector === a28Result) {
              this.fillJoinDataAggiudicazione(respData, a28Result, a29Result, resp, i, 'A28', 'A28');
            } else if (vector === a29Result) {
              this.fillJoinDataAggiudicazione(respData, a29Result, a28Result, resp, i, 'A29', 'A29');
            } else {
              if (a28Result[i]) {
                this.fillJoinDataAggiudicazione(respData, a28Result, a29Result, resp, i, 'A28', 'A28');
              } else if (a29Result[i]) {
                this.fillJoinDataAggiudicazione(respData, a29Result, a28Result, resp, i, 'A29', 'A29');
              }
            }
          }

          resp.data = respData;
          resp.recordsTotal = respData.length;
          resp.recordsFiltered = respData.length;
          resp.data.forEach(data => {
            data.regione = this.checkFieldValue(data.regione) ? '' : 'CF1';
            data.importoStep = this.checkFieldValue(data.codStep) ? data.importoStep : data.importoStep === this.noAccessDataFieldLabel ? this.noAccessDataFieldLabel : '';
            data.codStep = this.checkFieldValue(data.codStep) ? data.codStep + ' - ' + data.descStep : data.codStep === this.noAccessDataFieldLabel ? this.noAccessDataFieldLabel : '';
            data.codTipo = this.checkFieldValue(data.codTipo) ? data.codTipo + ' - ' + data.descTipo : data.codTipo === this.noAccessDataFieldLabel ? this.noAccessDataFieldLabel : '';
            data.codRuolo = this.checkFieldValue(data.codRuolo) ? data.codRuolo + ' - ' + data.descRuolo : data.codTipo === this.noAccessDataFieldLabel ? this.noAccessDataFieldLabel : '';
          });
          callback(resp);
        }
    });
  };

  private fillJoinDataAggiudicazione(respData: any[], baseFillDataResp: any[], dataToJoin: any[], resp: any, indexBaseFill: number, fieldBaseFill: string, fieldJoinFill: string) {
    this.fillDataFields(respData, baseFillDataResp, resp, indexBaseFill, indexBaseFill, fieldBaseFill, fieldBaseFill);
    const indexJoin = dataToJoin.map(val => val?.cig).indexOf(baseFillDataResp[indexBaseFill]?.cig);
    if (indexJoin >= 0) {
      dataToJoin[indexBaseFill] ? this.fillDataFields(respData, dataToJoin, resp, indexBaseFill, indexBaseFill, fieldJoinFill, fieldJoinFill) : null;
      this.fillDataFields(respData, dataToJoin, resp, indexBaseFill, indexJoin, fieldJoinFill, fieldJoinFill);
    }
  }

  loadDataSoggetti = (dataTablesParameters: any, callback: any) => {
    if (!this.interventoId) return;
    this.interventoService.getBdapData(dataTablesParameters, this.interventoId, 'A26')
      .subscribe(resp => {
        if (!resp) return;

        const a26 = resp?.data[0]?.A26;

        this.dtDataSize.set('soggetti', a26);
        if (a26) {
          const results = a26?.results;
          results ? resp.data = results : resp = this.createBaseEmptyDt();
          results ? resp.recordsTotal = resp.recordsTotal : null;
          results ? resp.recordsFiltered = resp.recordsFiltered : null;
          resp.data.forEach(data => {
            data.regione = this.checkFieldValue(data.regione) ? '' : 'CF1';
            data.codRuolo = data.codRuolo + ' - ' + data.descRuolo });
          callback(resp);
        }
    });
  };

  loadDataIndicatori = (dataTablesParameters: any, callback: any) => {
    if (!this.interventoId) return;
    this.interventoService.getBdapData(dataTablesParameters, this.interventoId, 'A17')
      .subscribe(resp => {
        if (!resp) return;

        const a17 = resp?.data[0]?.A17;

        this.dtDataSize.set('indicatori', a17);
        if (a17) {
          const results = a17?.results;
          results ? resp.data = results : resp = this.createBaseEmptyDt();
          results ? resp.recordsTotal = resp.recordsTotal : null;
          results ? resp.recordsFiltered = resp.recordsFiltered : null;
          resp.data.forEach(data => { data.codTipoIndicatore = data.codTipoIndicatore + ' - ' + data.descTipoIndicatore; });
          callback(resp);
        }
    });
  };

  loadDataPercettori = (dataTablesParameters: any, callback: any) => {
    if (!this.interventoId) return;
    this.interventoService.getBdapData(dataTablesParameters, this.interventoId, 'pagamentiFattura')
      .subscribe(resp => {
        if (!resp) return;

        const fattura = resp?.data[0]?.pagamentiFattura;

        this.dtDataSize.set('percettori', fattura);

        if (fattura) {
          const results = fattura?.results;
          results ? resp.data = results : resp = this.createBaseEmptyDt();
          results ? resp.recordsTotal = resp.recordsTotal : null;
          results ? resp.recordsFiltered = resp.recordsFiltered : null;
          callback(resp);
        }
    });
  };

  loadDataGiustificativo = (dataTablesParameters: any, callback: any) => {
    if (!this.interventoId) return;
    this.interventoService.getBdapData(dataTablesParameters, this.interventoId, 'dettaglioFattura')
      .subscribe(resp => {
        if (!resp) return;

        const fattura = resp?.data[0]?.dettaglioFattura;

        const existsData = fattura;
        this.dtDataSize.set('giustificativo', existsData);

        if (existsData) {
          const fatturaResult = this.getData(fattura);
          const respData = [];
          for (var i = 0; i < fatturaResult.length; i++) {
            respData.push({});
            this.fillDataFields(respData, fatturaResult, resp, i, i, 'dettaglioFattura', 'dettaglioFattura');
          }

          resp.data = respData;
          resp.data.forEach(data => {
            data.tipologiaDocumento = data.codiceTipoDocumento  + ' - ' + data.tipoDocumento;
            data.importoNetto = this.insertDotForThousands(+(data.importoTotaleDocumento.replaceAll('.', '').replace(',', '.')) - +(data.importoTotaleIvaDocumento.replaceAll('.', '').replace(',', '.')));
            data.statoFattura = data.codiceStatoFattura  + ' - ' + data.descrizStatoFattura;
          });
          callback(resp);
        }
    });
  };

  loadDataPagamentiBDAP = (dataTablesParameters: any, callback: any) => {
    if (!this.interventoId) return;
    this.interventoService.getBdapData(dataTablesParameters, this.interventoId, 'A15')
      .subscribe(resp => {
        if (!resp) return;

        const a15 = resp?.data[0]?.A15;

        this.dtDataSize.set('pagamentiBDAP', a15);
        if (a15) {
          const results = a15?.results;
          results ? resp.data = results : resp = this.createBaseEmptyDt();
          results ? resp.recordsTotal = resp.recordsTotal : null;
          results ? resp.recordsFiltered = resp.recordsFiltered : null;
          resp.data.forEach(data => {
            data.causale = data.codiceCausale + ' - ' + data.descrizioneCausale;
          });
          callback(resp);
        }
    });
  };

  loadDataPagamentiSIOPE = (dataTablesParameters: any, callback: any) => {
    if (!this.interventoId) return;
    this.interventoService.getBdapData(dataTablesParameters, this.interventoId, 'siope')
      .subscribe(resp => {
        if (!resp) return;

        const siope = resp?.data[0]?.siope;

        this.dtDataSize.set('pagamentiSIOPE', siope);

        if (siope) {
          const results = siope?.results;
          results ? resp.data = results : resp = this.createBaseEmptyDt();
          results ? resp.recordsTotal = resp.recordsTotal : null;
          results ? resp.recordsFiltered = resp.recordsFiltered : null;
          resp.data.forEach(data => {
            data.investimento = data.codInv + ' - ' + data.descInv;
          });
          callback(resp);
        }
    });
  };

  loadDataPagamentiFattura = (dataTablesParameters: any, callback: any) => {
    if (!this.interventoId) return;
    this.interventoService.getBdapData(dataTablesParameters, this.interventoId, 'pagamentiFattura')
      .subscribe(resp => {
        if (!resp) return;

        const fattura = resp?.data[0]?.pagamentiFattura;

        this.dtDataSize.set('pagamentiFattura', fattura);

        if (fattura) {
          const results = fattura?.results;
          results ? resp.data = results : resp = this.createBaseEmptyDt();
          results ? resp.recordsTotal = resp.recordsTotal : null;
          results ? resp.recordsFiltered = resp.recordsFiltered : null;
          resp.data.forEach(data => {
            data.investimento = data.codInv + ' - ' + data.descInv;
          });
          callback(resp);
        }
    });
  };

  loadDataCosti = (dataTablesParameters: any, callback: any) => {
    if (!this.interventoId) return;
    this.interventoService.getBdapData(dataTablesParameters, this.interventoId, 'A16')
      .subscribe(resp => {
        if (!resp) return;

        const a16 = resp?.data[0]?.A16;

        this.dtDataSize.set('costi', a16);
        if (a16) {
          const results = a16?.results;
          results ? resp.data = results : resp = this.createBaseEmptyDt();
          results ? resp.recordsTotal = resp.recordsTotal : null;
          results ? resp.recordsFiltered = resp.recordsFiltered : null;
          callback(resp);
        }
    });
  };

  private fillDataFields(respData: any[], result: any[], resp: any, indexRespData: number, indexIterateVector: number, field: string, completeField: string) {
    const elem = result && result.length > 0
                    ? result[indexIterateVector]
                    : resp?.data[0]?.[field]?.totalRecords === 0
                          ? { empty: true }
                          : null;
    elem && !elem.empty
        ? Object.keys(elem).forEach(key => respData[indexRespData][key] = elem[key])
        : !elem && !result
            ? this.fillDataWithNoAccessPermission(respData[indexRespData], completeField)
            : null;
  }

  private getLongestVector(vectors: any[]): any[] {
    let longest = vectors[0];
    vectors.forEach(vector => {
      longest = !longest || (vector && vector.length > longest.length) ? vector : longest;
    });
    return longest;
  }

  private getData(data: any): any[] {
    return data
            ? data.totalRecords > 0
              ? data.results
                ? data.results
                : []
              : []
            : null;
  }

  private checkFieldValue(value: string) {
    return value && value !== this.noAccessDataFieldLabel;
  }

  private fillDataWithNoAccessPermission(resp: any[], fields: string) {
    switch (fields) {
      case 'A9':
        resp['codFonte'] = this.noAccessDataFieldLabel;
        resp['cfCofinanziatore'] = this.noAccessDataFieldLabel;
        resp['importo'] = this.noAccessDataFieldLabel;
        return;
      case 'A11':
        resp['codVoceDiSpesa'] = this.noAccessDataFieldLabel;
        resp['importo'] = this.noAccessDataFieldLabel;
        return;
      case 'A15':
        resp['codicePagamento'] = this.noAccessDataFieldLabel;
        resp['tipologiaPagamento'] = this.noAccessDataFieldLabel;
        resp['dataPagamento'] = this.noAccessDataFieldLabel;
        resp['importo'] = this.noAccessDataFieldLabel;
        resp['causale'] = this.noAccessDataFieldLabel;
        return;
      case 'A16':
        resp['anno'] = this.noAccessDataFieldLabel;
        resp['importoDaRealizzare'] = this.noAccessDataFieldLabel;
        resp['importoRealizzato'] = this.noAccessDataFieldLabel;
        return;
      case 'A17':
        resp['codTipoIndicatore'] = this.noAccessDataFieldLabel;
        resp['valProgramIniz'] = this.noAccessDataFieldLabel;
        resp['valConclusione'] = this.noAccessDataFieldLabel;
        return;
      case 'A26':
        resp['codFiscale'] = this.noAccessDataFieldLabel;
        resp['codRuoloSub'] = this.noAccessDataFieldLabel;
        resp['regione'] = this.noAccessDataFieldLabel;
        return;
      case 'A28':
        resp['codTipo'] = this.noAccessDataFieldLabel;
        resp['importo'] = this.noAccessDataFieldLabel;
        return;
      case 'A29':
        resp['cig'] = this.noAccessDataFieldLabel;
        resp['codStep'] = this.noAccessDataFieldLabel;
        resp['dataEffettiva'] = this.noAccessDataFieldLabel;
        resp['importoStep'] = this.noAccessDataFieldLabel;
        return;
      case 'dettaglioFattura':
        resp['idUnivoco'] = this.noAccessDataFieldLabel;
        resp['idUnivocoFatturaPcc'] = this.noAccessDataFieldLabel;
        resp['cig'] = this.noAccessDataFieldLabel;
        resp['tipologiaDocumento'] = this.noAccessDataFieldLabel;
        resp['dataEmissione'] = this.noAccessDataFieldLabel;
        resp['dataScadenza'] = this.noAccessDataFieldLabel;
        resp['importoTotaleDocumento'] = this.noAccessDataFieldLabel;
        resp['importoTotaleIvaDocumento'] = this.noAccessDataFieldLabel;
        resp['importoNetto'] = this.noAccessDataFieldLabel;
        resp['codiceFiscaleCommittente'] = this.noAccessDataFieldLabel;
        resp['tipoDocumento'] = this.noAccessDataFieldLabel;
        resp['codiceFiscaleFornitore'] = this.noAccessDataFieldLabel;
        resp['denomCommittente'] = this.noAccessDataFieldLabel;
        resp['numeroIdFiscaleFornitore'] = this.noAccessDataFieldLabel;
        resp['codiceFiscaleFornitore'] = this.noAccessDataFieldLabel;
        resp['denomFornitore'] = this.noAccessDataFieldLabel;
        resp['statoFattura'] = this.noAccessDataFieldLabel;
        return;
      case 'pagamentiFattura':
        resp['cig'] = this.noAccessDataFieldLabel;
        resp['numeroIdFiscaleFornitore'] = this.noAccessDataFieldLabel;
        resp['codiceFiscaleFornitore'] = this.noAccessDataFieldLabel;
        resp['denomFornitore'] = this.noAccessDataFieldLabel;
        resp['codiceFiscaleCommittente'] = this.noAccessDataFieldLabel;
        resp['denomCommittente'] = this.noAccessDataFieldLabel;
        resp['importoTotalePagato'] = this.noAccessDataFieldLabel;
        resp['dataPagamento'] = this.noAccessDataFieldLabel;
        resp['idUnivoco'] = this.noAccessDataFieldLabel;
        resp['idUnivocoFatturaPcc'] = this.noAccessDataFieldLabel;
        resp['numeroFattura'] = this.noAccessDataFieldLabel;
        resp['dataPagamento'] = this.noAccessDataFieldLabel;
        resp['numeroMandatoPagamento'] = this.noAccessDataFieldLabel;
        return;
    }
  }

  private createBaseEmptyDt() {
    return {
      data: [],
      draw: 0,
      error: null,
      recordsFiltered: 0,
      recordsTotal: 0
    };
  }

  private insertDotForThousands(baseValue: number): string {
    if (baseValue === null || baseValue === undefined) return '';

    const value = baseValue.toFixed(2).toString().replace('.', ',');
    return value
              ? value.replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
              : '';
  }
}
