import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  TemplateRef,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ToastService } from "src/app/services/toast.service";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AggregatoService } from "../../services";
import { NaturaTipologieService } from '../../services/natura-tipologie.service';
import { TipologiaInterventiService } from '../../services/tipologia-interventi.service';
import { NgxDatatableWrapperComponent } from "src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Aggregato } from "../../models";

@Component({
  selector: "app-gestione-aggregati",
  templateUrl: "./gestione-aggregati.component.html",
  styleUrls: ["./gestione-aggregati.component.css"],
})
export class GestioneAggregatiComponent implements OnInit {
  @ViewChild("templateEditAdd", { static: true })
  public templateEditAdd: TemplateRef<any>;
  @ViewChild("templateModal", { static: true })
  public templateModal: TemplateRef<any>;

  @ViewChild("btnEditTemplate", { static: true })
  public btnEditTemplate: TemplateRef<any>;

  @ViewChild("btnDeleteTemplate", { static: true })
  public btnDeleteTemplate: TemplateRef<any>;

  @ViewChild("ngxDatatableWrapper")
  ngxDatatableWrapper: NgxDatatableWrapperComponent;

  columns = [
    {
      name: "id",
      prop: "id",
      visible: false,
      sortable: true,
      filtrable: true,
      width: 10,
    },
    {
      name: "natura intervento",
      prop: "tipologiaIntervento.naturaTipologia.etichetta",
      sortable: true,
      filtrable: true,
      flexGrow: 10,
    },
    {
      name: "tipologia intervento",
      prop: "tipologiaIntervento.descrizione",
      sortable: true,
      filtrable: true,
      flexGrow: 10,
    },
    {
      name: "codice",
      prop: "codice",
      sortable: true,
      filtrable: true,
      flexGrow: 10      
    }
    ,
    {
      name: "dettaglio tipologia",
      prop: "name",
      sortable: true,
      filtrable: true,
      flexGrow: 12,
      minWidth: 200,
    },
    {
      name: "modifica",
      prop: "id",
      sortable: false,
      filtrable: false,
      cellTemplate: "btnEditTemplate", // 'buttonsTemplate'
      flexGrow: 1,
      minWidth: 60,
    },
    {
      name: "elimina",
      prop: "id",
      sortable: false,
      filtrable: false,
      cellTemplate: "btnDeleteTemplate", // 'buttonsTemplate'
      flexGrow: 1,
      minWidth: 60,
    },
  ];

  dtOptions: any = {};
  visible = false;
  submitted: boolean = false;
  addAggregato: FormGroup;
  modalRef: BsModalRef;

  templateList = [];

  types = [];
  allComboTipologie = [];
  naturaList = [];


  buttonLabel: string = "Inserisci";
  loading: boolean = false;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-lg",
  };

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private el: ElementRef,
    private localeService: BsLocaleService,
    private toastr: ToastService,
    private modalService: BsModalService,
    private currentService: AggregatoService,
    private naturaTipologieService: NaturaTipologieService,
    private tipologiaInterventiService: TipologiaInterventiService
  ) {
    this.localeService.use("it");
    this.dtOptions = {
      rows: [],
      totalElements: 0,
      columns: this.columns,
      columnMode: ColumnMode.flex,
      headerHeight: "50",
      footerHeight: "50",
      rowHeight: "auto",
      externalSorting: true, // true
      loadingIndicator: "loanding",
      class: "bootstrap",
      summaryRow: true,
      summaryPosition: "'bottom'",
      externalPaging: true, // true
      count: 0,
      offset: 0,
      limit: 10,
      serverSide: true,
      ajax: this.loadData,
      scrollbarH: "true",
    };
  }

  ngOnInit() {
    this.templateList = [
      { name: "btnDeleteTemplate", template: this.btnDeleteTemplate },
      { name: "btnEditTemplate", template: this.btnEditTemplate },
    ];
    window.scroll(0, 0);
    this.initializeTipologieIntervento();
    this.addAggregato = this.initializeFormGroup();
    this.getNaturaInterventoList(null);
  }

  initializeFormGroup() {
    let frm = this.formBuilder.group({
      id: [null],
      name: [null, [Validators.required, Validators.maxLength(255)]],
      tipologiaIntervento: [{ value: null }, [Validators.required]],
      natura: [null, [Validators.required]]
      ,codice:[null]
    });
    return frm;
  }

  initializeTipologieIntervento() {
    const tipologie = [];
    const tipi = this.tipologiaInterventiService.getTipologiaInterventi().subscribe(
        (tipi) => {
            tipi.forEach((t: any) => {
                const tipo = {
                    key: t.codice, label: t.descrizione,
                    value: t.descrizione
                    , disegnabile: t.disegnabile, naturaTipologia: t.naturaTipologia
                };
                tipologie.push(tipo);
            }
            )
            this.allComboTipologie = [...tipologie];
            this.types = [...tipologie];
        }
    );
  }

  get f() {
    return this.addAggregato.controls;
  }

  getNaturaInterventoList(callBack) {
    console.log('getNaturaInterventoList, callBack!=null',callBack!=null);
    this.f.tipologiaIntervento.disable();
    this.naturaTipologieService.getNaturaTipologie().subscribe(
        result => {
            this.naturaList = [];
            result.sort((a, b) => a.codice <= b.codice ? -1 : 1);
            result.forEach(x => this.naturaList.push({ key: x.codice, value: x.codice + ' - ' + x.etichetta }));
            if(callBack)callBack();
        }
    );
  }

  loadData = (dataTablesParameters: any, callback: any) => {
    this.currentService.getAlldt(dataTablesParameters).subscribe(resp => callback(resp))
  };

  checkTipologiaOnNaturaInterventoChange(naturalKey){
    this.types = [...this.allComboTipologie];
    this.types = this.types.filter(x => x.naturaTipologia.codice == naturalKey);
    this.f.tipologiaIntervento.enable();
    let t = this.f.tipologiaIntervento.value;
    if (!this.types.find(x => x.key == t)) {
        this.f.tipologiaIntervento.setValue(null);
    }
  }

  setNaturaIntervento($event) {
    this.types = [];
    this.f.tipologiaIntervento.disable();
    if (!$event || !$event.key) {
        this.f.tipologiaIntervento.setValue(null);
        return;
    }
    this.checkTipologiaOnNaturaInterventoChange($event.key);
  }

  onSubmit() {
    this.submitted = true;
    this.addAggregato.updateValueAndValidity();
    let errors = false;
    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }
    if (this.addAggregato.invalid || errors) {
      console.log("invalid");
      let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
      for (let el in this.addAggregato.controls) {
        if (this.addAggregato.controls[el].invalid) {
          console.log(`set focus on ${el}`);
          if (this.addAggregato.controls[el] instanceof FormGroup) {
            for (let el1 in this.addAggregato.get(el)["controls"]) {
              if (this.addAggregato.get(el)["controls"][el1].invalid) {
                invalidElements = this.el.nativeElement.querySelectorAll(
                  `[formcontrolname="${el1}"]`
                );
                break;
              }
            }
          } else {
            invalidElements = this.el.nativeElement.querySelectorAll(
              `[formcontrolname="${el}"]`
            );
          }
          break;
        }
      }
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }

      return false;
    }
    let tipologyToBeSent = this.types.find(x => x.key == this.f.tipologiaIntervento.value);
    if(!tipologyToBeSent){ 
      this.toastr.error("Errore di sistema");
      return; 
    }
    tipologyToBeSent.codice = tipologyToBeSent.key;
    let obj: Aggregato = {
      id: this.addAggregato.value.id,
      name: this.addAggregato.value.name,
      tipologiaIntervento: tipologyToBeSent,
      codice:this.addAggregato.value.codice
    };

    let subFunctionOk = (res) => {
      this.toastr.success("Dettaglio tipologia salvata con successo!");
      this.submitted = false;
      this.addAggregato.reset();
      this.buttonLabel = "Inserisci";
      if (obj.id)
        this.modalRef.hide();

      this.f.tipologiaIntervento.disable();
      this.ngxDatatableWrapper.refresh();
    };
    let subFunctionKo = (error) => {
      this.toastr.error("Errore di sistema");
    };

    this.currentService.save(obj).subscribe(subFunctionOk, subFunctionKo);
  }

  onEdit(row) {
    this.submitted = false;
    console.log(row);
    let codiceNatura = row.tipologiaIntervento.naturaTipologia.codice;
    this.buttonLabel = "Aggiorna";
    this.modalRef = this.modalService.show(this.templateModal, this.config);
    this.checkTipologiaOnNaturaInterventoChange(codiceNatura);
    this.addAggregato.setValue({
      id: row.id,
      name: row.name,
      natura: codiceNatura,
      tipologiaIntervento: row.tipologiaIntervento.codice
      ,codice:row.codice
    });
  }

  onDelete(row) {
    console.log(row);
    if (
      !confirm(
        `Stai per eliminare il dettaglio tipologia ${row.name}, proseguire?`
      )
    )
      return;
    this.loading = true;

    let subFunctionOk = (result) => {
      this.loading = false;
      this.ngxDatatableWrapper.refresh();
      this.toastr.success("Dettaglio tipologia eliminato con successo", null, {
        timeOut: 2000,
        /*closeButton:true,*/ disableTimeOut: false,
      });
    };
    let subFunctionKo = (error) => {
      this.loading = false;

      this.toastr.error(
        `Impossibile eliminare il dettaglio tipologia: ${
          error.error.message
            ? error.error.message
            : error.error.error_description
            ? error.error.error_description
            : error.error
        }`,
        null,
        {
          timeOut: 2000,
          /*closeButton:true,*/ disableTimeOut: false,
        }
      );
      console.log("error");
    };

    this.currentService.delete(row.id).subscribe(subFunctionOk, subFunctionKo);
  }
  closeModal() {
    this.modalRef.hide();
    this.addAggregato.reset();
    this.f.tipologiaIntervento.disable();
    this.buttonLabel = "Inserisci";
  }
}
