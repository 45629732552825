<div class="bg-45">
<div class="container-fluid">

  <div class="row">
      <div class="col-xl-4 ">
        <div class="ml-xl-2 ml-0 mr-0 px-md-3 px-0 mt-5 pt-3">
        <p class="shadow-sm border rounded text-002742">
          <span class="display-3 ">G</span><span class="h3">estione</span> <span class="display-3">I</span><span class="h3">nvestimenti</span>
        </p>
      </div>
        <div class="ml-xl-2 ml-0 mr-0 my-5 p-md-3 p-0">
<p class="shadow-sm border rounded"><span class="h1 display-2 text-0083e0 testo-bordato">P</span><span class="h3 text-002742">iano</span></p>
<p class="shadow-sm border rounded" style="margin-left:60px"><span class="display-3 text-0083e0 testo-bordato">N</span><span class="h3 text-002742">azionale</span></p>
<p class="shadow-sm border rounded" style="margin-left:120px"><span class="display-3 text-0083e0 testo-bordato">R</span><span class="h3 text-002742">ipresa e</span></p>
<p class="shadow-sm border mb-0 rounded" style="margin-left:180px"><span class="display-3 text-0083e0 testo-bordato">R</span><span class="h3 text-002742">esilienza</span></p>
      </div>
    </div>
    <div class="col-xl-3 align-self-center">
      <div class=" shadow-sm border align-self-center p-3 p-xl-5 text-center rounded" style="background-color: rgba(255, 255, 255, 0.8);">
        <div class="px-0">
        <button class=" btn btn-lg btn-primary px-xl-5 mx-xl-4 mt-3" (click)="iamLogin()">Accedi</button>
       <div class="mt-3">
        <a _ngcontent-rbd-c46="" href="/inframob/assets/docs/Manuale_Registrazione_e_Accreditamento_Utente.pdf" target="_blank"
        class="pointer text-uppercase text-decoration-none text-002742" >
        <i _ngcontent-rbd-c46="" class="fas fa-book"></i>
        &nbsp;Guida al primo accesso</a>
        </div>

      </div>
      </div>


    </div>
      <div class="col-xl-5 align-self-center">
          <div class="bg-monitor d-flex justify-content-center">
          </div>
      </div>

  </div>




</div>
</div>
