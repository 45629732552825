import { Injectable } from "@angular/core";
import { AppConfigService } from "./app-config.service";
import { HttpClient } from "@angular/common/http";
import { Role } from "../models/role";

@Injectable({
  providedIn: "root"
})
export class RoleService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.role}`;

  constructor(private http: HttpClient) {}

  getAllRoles() {
    return this.http.get<Role[]>(`${this.apiUrl}names`);
  }
}
