
export enum Roles {
    "role_reg"= "ROLE_REGISTER",
    "role_enabled"= "ROLE_ENABLED",
    "role_admin"= "ROLE_ADMIN",
    "role_directional_group"= "ROLE_DIRECTIONAL_GROUP",
    "role_area_staff"= "ROLE_AREA_STAFF",
    "role_external"= "ROLE_EXTERNAL",
    "role_manager"= "ROLE_MANAGER",
    "role_viewer"= "ROLE_VIEWER"
  }

  export enum RolesDescriptions {
    "role_enabled"= "Utente abilitato",
    "role_admin"= "Direzione",
    "role_directional_group"= "Dirigente",
    "role_area_staff"= "Personale di area",
    "role_reg"= "Utente registrato",
    "role_external"= "Esterno",
    "role_manager"= "Gestore",
    "role_viewer"= "Lettore"
  }  