<div class="container bg-white">
    <div class="col-lg-12 mt-3">
        <app-title-page></app-title-page>

        <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsNgx">
            <ng-template #btnSendEmailTemplate let-row="row" let-value="value">
							<a class=" border-0 text-center" class=" border-0 text-center" (click)="sendHandler(row)" data-toggle="tooltip" data-placement="top" title="invio mail a utente">&nbsp;&nbsp;&ensp;
              	<i class="far fa-envelope pointer" ></i>
							</a>
            </ng-template>

            <ng-template #btnEnableTemplate let-row="row" let-value="value">
                <a [ngClass]="{'fa-check-circle-disabilitato':!value,'fa-check-circle-abilitato':value}" class=" border-0 text-center" (click)="enableHandler(row)" data-toggle="tooltip" data-placement="top" title="{{!value?'abilita utente':'disabilita utente'}}">&nbsp;&nbsp;&ensp;
                <i class="far fa-check-circle pointer" ></i></a>
            </ng-template>

            <ng-template #btnNotificationsDisabledTemplate let-row="row" let-value="value">
                <a class=" border-0 text-center" (click)="changeNotificationsDisabledValueClick(row)" data-toggle="tooltip" data-placement="top" title="{{value?'abilita ricezione notifiche':'disabilita ricezione notifiche'}}">&nbsp;&nbsp;&ensp;
                <i class="far pointer" [ngClass]="{'fa-bell-slash':value,'fa-bell':!value}" ></i></a>
            </ng-template>

            <ng-template #btndocsTemplate let-row="row" let-value="value">
                <a [ngClass]="{'fa-id-card-disabilitato':!value,'fa-id-card-abilitato':value}" class=" border-0 text-center" (click)="downloadDoc(row,'id')" data-toggle="tooltip" data-placement="top" title="{{!value?'carta di identita mancante':'carta di identità'}}">&nbsp;&nbsp;&ensp;
                <i class="far fa-id-card pointer" ></i></a>
                <a [ngClass]="{'fa-credit-card-disabilitato':!value,'fa-credit-card-abilitato':value}" class=" border-0 text-center" (click)="downloadDoc(row,'fc')" data-toggle="tooltip" data-placement="top" title="{{!value?'codice fiscale mancante':'codice fiscale'}}">&nbsp;&nbsp;&ensp;
              <i class="far fa-credit-card pointer"></i></a>
                <a *ngIf="value" [ngClass]="{'fa-times-circle-disabilitato':!value,'fa-times-circle-abilitato':value}" class=" border-0 text-center" (click)="onDeleteDocs(row)" data-toggle="tooltip" data-placement="top" title="elimina documenti">&nbsp;&nbsp;&ensp;
                    <i class="far fa-times-circle pointer" ></i></a>
            </ng-template>

            <ng-template #btnUpdatePermissionsTemplate let-row="row" let-value="value">
                <a class=" border-0 text-center" class=" border-0 text-center" (click)="updatePermissions(row)" data-toggle="tooltip" data-placement="top" title="{{!value || value.length === 0 ? 'gestisci permessi' : 'permessi già modificati'}}">&nbsp;&nbsp;&ensp;
                    <i class="fa fa-key pointer" [ngClass]="{'fa-key-disabilitato':!value || value.length === 0}" aria-hidden="true"></i>
                </a>
            </ng-template>

            <ng-template #btnDeleteTemplate let-row="row" let-value="value">
                <a *ngIf="!rowIsAdmin(row)" [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="deleteHandler(row)" data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer" ></i></a>
            </ng-template>

            <ng-template #usernameTemplate let-row="row" let-value="value">
                <a class="" href="javascript:;;" (click)="openAccountInfo(row)">{{value}}</a>
            </ng-template>

        </ngx-datatable-wrapper>

    </div>
</div>
<button style="display: none" id="fake" type="button" class="btn btn-primary" data-target="#template" data-toggle="modal" data-backdrop="static" data-keyboard="false" (click)="openModal(template)">
    Create template modal
  </button>

<ng-template #template>
    <div class="modal-header text-center">
        <h4 class="modal-title pull-left">Invia mail all'utente</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
        <form id="data" method="post" class="form-registrazione">
            <div class="form-group">
                <label>Destinatario:</label>
                <input id="dest" type="text" class="form-control" readonly />
            </div>
            <div class="form-group">
                <label>Oggetto:</label>
                <input type="text" id="subject" name="subject" value="" class="form-control" placeholder="Oggetto della mail" />
            </div>

            <div class="form-group" style="margin-bottom:20px">
                <label>Testo della mail: &nbsp;</label>
                <textarea name="message" class="form-control" rows="10" id="message"></textarea>
                <br />
            </div>

            <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0" (click)="sendMail()">
          INVIA
        </button>
            <span class="clearfix"></span>
        </form>
    </div>
</ng-template>