<div class="p-5 container-xl text-center" [hidden]="!loading">
    <app-loading message="Caricamento in corso..."></app-loading>
</div>
<div class="container-xl" [hidden]="loading">
    <div class="position-sticky sticky-top  pt-4 pb-4 elemento-aggiorna mb-5 shadow-custom" [ngClass]="{'sticky-top-custom-modal': isInModal,'sticky-top-custom':!isInModal  }">
        <div class=" row  justify-content-center">
            <div class="col-lg-4 col-md-6 text-center" *ngIf="gotoBtnOptions && gotoBtnOptions.show">
                <button (click)="gotoBackBtnClick()" [disabled]="loading" class="btn btn-secondary btn-block font-weight-bold" type="button">
    {{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}&nbsp;<i class="fas fa-times" *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i>
  </button>
            </div>
        </div>

    </div>

    <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptions">
        <ng-template #btnDetailTemplate let-row="row" let-value="value">
            <a [ngClass]="{
                'disabled': loading
                }" class=" border-0 text-center" (click)="onDetail(row)" data-toggle="tooltip" data-placement="top" title="dettaglio">&nbsp;&nbsp;&ensp;<i class="far pointer fa-eye detail-iocn"  ></i></a>
        </ng-template>

        <ng-template #tipologiaValueTemplate let-row="row" let-value="value">
            <span>{{value=='DELETE'?'Cancellazione':(value=='INSERT'?'Inserimento':'Aggiornamento')}}</span>
        </ng-template>
    </ngx-datatable-wrapper>
</div>