<div id="map-container" class="dash-1 position-relative">
  <div id="map" class="dashboard-sintesi">
    <div #popup [hidden]="true" class="shadow card bg-light mb-3" style="max-width: 22rem; z-index: 12000">
      <!--<div class="card-header text-white bold">Info</div>-->
      <div class="card-body">
        <p class="mb-1" style="font-size: 14px" *ngFor="let s of popUpKeys; let i = index" [attr.data-index]="i">
          <span class="bold">{{ s | titlecase }}</span>: <span [innerHTML]="popUpValues[i]" class=""></span>
        </p>
      </div>
    </div>

    <div class="position-absolute switch-center p-2 rounded"
      style="top: 1em; z-index: 1; background: rgba(255, 255, 255, 0.4)">
      <div class="btn-group btn-group-toggle flex">
        <label class="btn btn-primary pointer btn-sm border-right border-white" id="label_chk_all"
          [ngClass]="{ attiva: checkedAll }" [hidden]="showOnPnrrDashboard">
          <input [checked]="checkedAll" (change)="applySwitch('all')" type="radio" name="inlineRadioOptions"
            id="inlineRadio1" checked />
{{(isAdmin() || isGr34()) && !showOnPnrrDashboard?'Progettualità/Fabbisogni - Interventi':'Progettualità - Interventi'}}
        </label>

        <label class="btn btn-primary pointer btn-sm border-right border-white" id="label_chk_p"
          [ngClass]="{ attiva: checkedProj }"  [hidden]="showOnPnrrDashboard">
          <input [checked]="checkedProj" (change)="applySwitch('projects')" type="radio" name="inlineRadioOptions"
            id="inlineRadio2" />
            {{(isAdmin() || isGr34()) && !showOnPnrrDashboard ?'Progettualità/Fabbisogni':'Progettualità'}}
        </label>
        <label class="btn btn-primary pointer border-right border-white btn-sm" id="label_chk_i"
          [ngClass]="{ attiva: checkedInt }">
          <input [checked]="checkedInt" (change)="applySwitch('interventions')" type="radio" name="inlineRadioOptions"
            id="inlineRadio3" />
          Interventi
        </label>
      </div>
    </div>

    <div class="rounded position-absolute switch-left-right p-1 bottom-map1"
      style="z-index: 7000; background: rgba(255, 255, 255, 0.4)">
      <label class="switch m-1">
        <input (click)="toggleMapSwitch()" id="editor" class="switch-input" type="checkbox" />
        <span *ngIf="!showOnPnrrDashboard" class="switch-label" data-on="Tipologia Intervento" data-off="Stato Finaziamento"></span>
        <span *ngIf="showOnPnrrDashboard" class="switch-label" data-on="Tipologia Intervento" data-off="Attuazione"></span>
        <span class="switch-handle"></span>
      </label>
    </div>
    <!--seleziona mappa-->

    <div [hidden]="false" class="rounded form-group position-absolute bottom-map mb-0"
      style="right: 15px; z-index: 2000; background: rgba(255, 255, 255, 0.4)">
      <!--<h6 class="text-0083e0 " for="exampleFormControlSelect1">Seleziona Tipologia Mappa</h6>-->
      <div class="d-flex m-1">
        <i class="fas fa-map my-2 mr-1 text-0083e0" style="font-size: 18px"></i>
        <ng-select style="min-width: 185px" bindValue="code" bindLabel="title" groupBy="group"
          placeholder="Seleziona Mappa" selectOnTab="true" [clearable]="true" [searchable]="true"
          [items]="baseMapsLayers" (change)="chooseBasemap($event)">
        </ng-select>
      </div>
    </div>
  </div>

  <button id="export-png" crossorigin="anonymous"
    class="mask rounded btn btn-default position-absolute bottom-map2 pointer text-primary "
    style="left: 15px; z-index: 50000; background: rgba(255, 255, 255, 0.4)" (click)="exportMap()"><img
      src="assets/img/export.png" alt="pulsante esportazione" title="esporta png" class="opacity" style="
        width: 1.2em;
        height: 1.2em;
        min-width: 32px;
        min-height: 32px;
        padding: 0.05em 0.15em;
      " />
    <span class="pt-1 d-none d-sm-inline-block">Esporta PNG</span></button>
  <!--<a id="export-png" crossorigin="anonymous" class="rounded btn btn-default position-absolute bottom-map2 pointer text-primary" style="left: 30px; z-index: 3000; background: rgba(255, 255, 255, 0.4)" (click)="exportMap()"><i class="far fa-file-image"></i> Scarica PNG</a>-->

  <a crossorigin="anonymous" id="image-download" #downloadLink download="mappa.png"></a>

  <div [hidden]="statusMode" class="rounded position-absolute p-1" style="
      bottom: 4.5em;
      right: 15px;
      z-index: 7000;
      background: rgba(255, 255, 255, 0.4);
    ">
    <label class="switch1 m-1">
      <input type="checkbox" class="switch1-input" id="icones" name="scales" [checked]="showIcon"
        (change)="toggleIconLayer()" />

      <span class="switch1-label" data-on="Icone On" data-off="Icone Off"></span>
      <span class="switch1-handle" for="icones"></span>
    </label>
  </div>


</div>
<div [hidden]="!statusMode" class="collapse legend_editor show" id="legend_editor">
  <div class="card card-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between px-3 flex-wrap">
        <div class="border-left border-dark pl-1">
          <i class="fas fa-circle no-hover" style="color: #3AB795"></i>
          <span class="pl-1" style="font-size: 12px">rilevanza territoriale</span>
        </div>

        <div class="border-left border-dark pl-1" *ngIf="!showOnPnrrDashboard" [hidden]="!isAdmin() && !isGr34()">
          <i class="fas fa-circle no-hover" style="color: #dc3545"></i>
          <span class="pl-1" style="font-size: 12px">fabbisogno</span>
        </div>

        <div class="border-left border-dark pl-1" *ngIf="!showOnPnrrDashboard">
          <i class="fas fa-circle no-hover" style="color: #76bed0"></i>
          <span class="pl-1" style="font-size: 12px">da validare</span>
        </div>

        <div class="border-left border-dark pl-1" *ngIf="!showOnPnrrDashboard">
          <i class="fas fa-circle no-hover" style="color: #ff8800"></i>
          <span class="pl-1" style="font-size: 12px">
            in attesa di validazione
          </span>
        </div>

        <div class="border-left border-dark pl-1" *ngIf="!showOnPnrrDashboard">
          <i class="fas fa-circle no-hover" style="color: #007e33"></i>
          <span class="pl-1" style="font-size: 12px">validato</span>
        </div>

        <div class="border-left border-dark pl-1" *ngIf="!showOnPnrrDashboard">
          <i class="fas fa-circle no-hover" style="color: #0083e0"></i>
          <span class="pl-1" style="font-size: 12px">completato</span>
        </div>

        <div class="border-left border-dark pl-1" *ngIf="!showOnPnrrDashboard">
          <i class="fas fa-circle no-hover" style="color: #577590"></i>
          <span class="pl-1" style="font-size: 12px">
            parzialmente finaziato
          </span>
        </div>

        <!-- <div class="border-left border-dark pl-1"><i class="fas fa-circle no-hover" style="color: #dcd6f7"></i></div>
                  <p class="pl-1 pr-2 mb-0" style="font-size: 12px;">rifiutato</p> -->

        <div class="border-left border-dark pl-1" *ngIf="!showOnPnrrDashboard">
          <i class="fas fa-circle no-hover" [ngStyle]="{'color': '#aa66cc'}"></i>
          <span class="pl-1" style="font-size: 12px">progetto</span>
        </div>

        <div class="border-left border-dark pl-1" *ngIf="showOnPnrrDashboard">
          <i class="fas fa-circle no-hover" [ngStyle]="{'color': '#aa66cc'}" ></i>
          <span class="pl-1" style="font-size: 12px">attuazione regionale</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- legenda tipologie -->

<div [hidden]="statusMode" class="collapse legend_editor show" id="legend_editor">
  <div class="card px-3 pb-2 pt-1">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between px-1 flex-wrap">
        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.road">
          <i class="fas fa-road " style="color: #002742"></i>
          <span class="d-block" style="font-size: 12px">strada</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.park">
          <i class="fab fa-product-hunt " style="color: #6495ed"></i>
          <span class="d-block" style="font-size: 12px">parcheggi</span>
        </div>


        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.nodes">
          <i class="fas fa-parking " style="color: #006699"></i>
          <span class="d-block" style="font-size: 12px">nodi di scambio</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.rail">
          <i class="fas fa-train " style="color: #0083e0"></i>

          <span class="d-block" style="font-size: 12px">ferrovia</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.station">
          <span class="pr-1"><svg height="16" width="16" viewBox="0 0 16 16" class="bi bi-geo-fill mb-1"
              fill="#67b7dc" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z" />
              </svg></span>
              <span class="d-block" style="font-size: 12px">staz. ferroviaria</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.metro">
          <i class="fas fa-subway " style="color: #f0ad4e"></i>
          <span class="d-block" style="font-size: 12px">metro</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.metrostop">
          <span class="bold " style="font-size: 16px; color: #f58300">M</span>
          <span class="d-block" style="font-size: 12px">staz. metro</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.port">
          <i class="fas fa-anchor " style="color: #5cb85c"></i>
          <span class="d-block" style="font-size: 12px">porto</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.bike">
          <i class="fas fa-bicycle " style="color: #007e32"></i>
          <span class="d-block"
            style="font-size: 12px">ciclabile</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.parkland">
          <i class="fas fa-tree " style="color: #95c11f"></i>
          <span class="d-block" style="font-size: 12px">parchi e aree verdi</span>
        </div>
        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.emobility">
          <i class="fas fa-plug " style="color: #7ca454"></i>
          <span class="d-block" style="font-size: 12px">e-mobility</span>
        </div>
        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.skilift">
          <i class="fas fa-tram " style="color: #ffc2ff"></i>
          <span class="d-block" style="font-size: 12px">imp. di
            risalita</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.other_points">
          <i class="far fa-building " style="color: #b351a0"></i>
          <span class="d-block"
            style="font-size: 12px">edificio</span>
        </div>
        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.points">
          <i class="fas fa-school " style="color: #dc3545"></i>
          <span class="d-block"
            style="font-size: 12px">scuola</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.other_lines">
          <i class="fas fa-bacon " style="color: #bd2130"></i>
          <span class="d-block" style="font-size: 12px">altre vie di com.</span>
        </div>



        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.hospitals">
          <i class="fas fa-clinic-medical" style="color: #3d7dc1"></i>
          <span class="d-block" style="font-size: 12px">ospedali - presidi</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.luoghi_di_culto">
          <i class="fas fa-church" style="color: #632285"></i>
          <span class="d-block" style="font-size: 12px">luoghi di culto</span>
        </div>



        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.plants">
          <i class="fas fa-industry" style="color: #653d0d"></i>
          <span class="d-block" style="font-size: 12px">impianti (Acqua-Luce-Gas)</span>
        </div>


        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.networks_satellites">
          <i class="fas fa-satellite-dish" style="color: #9b165c"></i>
          <span class="d-block" style="font-size: 12px">Reti e satelliti</span>
        </div>


        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.asl">
          <i class="fas fa-briefcase-medical" style="color: #2e2e85"></i>
          <span class="d-block" style="font-size: 12px">asl</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.musei_cultura">
          <i class="fas fa-landmark" style="color: #ac7e47"></i>
          <span class="d-block" style="font-size: 12px">musei biblioteche cultura</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.interventi_sul_sociale">
          <i class="fas fa-hand-holding-heart" style="color: #ba1010"></i>
          <span class="d-block" style="font-size: 12px">interventi sul sociale</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.bonifiche_territorio_linee">
          <i class="fas fa-leaf" style="color: #1c6632"></i>
          <span class="d-block" style="font-size: 12px">bonifiche territorio (linee)</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.bonifiche_territorio_punti">
          <i class="fab fa-pagelines" style="color: #46ac63"></i>
          <span class="d-block" style="font-size: 12px">bonifiche territorio (punti)</span>
        </div>



        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.cimiteri">
          <i class="fas fa-cross" style="color: #333"></i>
          <span class="d-block" style="font-size: 12px">edilizia cimiteriale</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.aeroporti">
          <i class="fas fa-plane" style="color: #333"></i>
          <span class="d-block" style="font-size: 12px">aeroporti</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.stazioni_autolinee">
          <i class="fas fa-bus-alt" style="color: #333"></i>
          <span class="d-block" style="font-size: 12px">stazioni - autolinee</span>
        </div>


        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.impianti_energie_rinnovabili">
          <i class="fas fa-seedling" style="color: #98c100"></i>
          <span class="d-block" style="font-size: 12px">impianti (energie rinnovabili)</span>
        </div>

        <div class="border-left border-dark pl-1 text-secondary text-center" *ngIf="tipoInterventoIcons.impianti_sportivi">
          <i class="fas fa-futbol" style="color: #333"></i>
          <span class="d-block" style="font-size: 12px">impianti sportivi</span>
        </div>


      </div>
    </div>
  </div>
</div>
