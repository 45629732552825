import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    TemplateRef,
    Output,
    EventEmitter,
    Input
  } from "@angular/core";
  import { FormGroup, FormBuilder, Validators } from "@angular/forms";
  import { HttpClient } from "@angular/common/http";
  import { ToastService } from 'src/app/services/toast.service';
  import { BsLocaleService } from 'ngx-bootstrap/datepicker';
  import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TipoProvvedimentoService } from '../../services';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { ColumnMode } from '@swimlane/ngx-datatable';
  
  
  @Component({
    selector: 'tipi-provvedimento-component',
    templateUrl: './tipi-provvedimento.component.html',
    styleUrls: ['./tipi-provvedimento.component.css']
  })
  export class TipiProvvedimentoComponent
    implements OnInit  {
    dtOptions: any = {};
    visible = false;
    submitted: boolean = false;
    addSchoolYearForm: FormGroup;
    modalRef: BsModalRef;
    open: boolean = false;   
    
    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;
    @ViewChild('templateModal', { static: true })
    public templateModal: TemplateRef<any>;

    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;


    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    columns = [
        {
            name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
        }       
        , {
            name: 'provvedimento'
            , prop: 'provvedimento'
            , sortable: true
            , filtrable: true
            //,cellClass: 'mobile-hidden desktop-hidden'
            //,headerClass: 'mobile-hidden desktop-hidden'
            , flexGrow: 14
        , minWidth: 200

        }
        , {
            name: 'modifica'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , flexGrow: 1
        , minWidth: 60


        }
        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60
    

        }
    ];


    @Output() onSubmitOk:EventEmitter<any> = new EventEmitter();
    _fastEditObject:any;
    @Input()
    public set fastEditObject(value:any){
        this._fastEditObject = value;
    }
    
    public get fastEditObject(){
        return this._fastEditObject;
    }

    templateList = [];

    constructor(
      private http: HttpClient,
      private formBuilder: FormBuilder,
      private el: ElementRef,
      private localeService: BsLocaleService,
      private toastr: ToastService,
      private modalService: BsModalService,
      private currentService:TipoProvvedimentoService,      
    ) {
      this.localeService.use("it");    
      this.dtOptions = {
        rows: []
        ,totalElements:0
        , columns: this.columns
        , columnMode: ColumnMode.flex
        , headerHeight: "50"
        , footerHeight: "50"
        , rowHeight: "auto"
        , externalSorting: false// true
        , loadingIndicator: 'loanding'
        , class: "bootstrap"
        , summaryRow: true
        , summaryPosition: "'bottom'"
        , externalPaging: false// true
        , count: 0
        , offset: 0
        , limit: 10
        , serverSide: false
        , scrollbarH:"true"
    };
    }
  
    ngOnInit() {
        this.templateList = [ { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }, { name: 'btnEditTemplate', template: this.btnEditTemplate }];
      window.scroll(0, 0);
      this.addSchoolYearForm = this.initializeFormGroup();
      this.getTipologie();
    }
  
    
  
    
  
    initializeFormGroup() {
      return this.formBuilder.group({
          id:[null],
        provvedimento: [null, [Validators.required, Validators.maxLength(255)]],                      
      });
    }
  
    get f() {
      return this.addSchoolYearForm.controls;
    }
    

    onSubmit() {
      this.submitted = true;
      this.addSchoolYearForm.updateValueAndValidity();
      let errors = false;
      for (let c in this.f) {
        errors = errors || this.f[c].errors !== null;
        console.log(
          "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
        );
      }
      if (this.addSchoolYearForm.invalid || errors) {
        console.log("invalid");
        let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
        for (let el in this.addSchoolYearForm.controls) {
          if (this.addSchoolYearForm.controls[el].invalid) {
            console.log(`set focus on ${el}`);
            if (this.addSchoolYearForm.controls[el] instanceof FormGroup) {
              for (let el1 in this.addSchoolYearForm.get(el)["controls"]) {
                if (this.addSchoolYearForm.get(el)["controls"][el1].invalid) {
                  invalidElements = this.el.nativeElement.querySelectorAll(
                    `[formcontrolname="${el1}"]`
                  );
                  break;
                }
              }
            } else {
              invalidElements = this.el.nativeElement.querySelectorAll(
                `[formcontrolname="${el}"]`
              );
            }
            break;
          }
        }
        if (invalidElements.length > 0) {
          invalidElements[0].focus();
        }
  
        return false;
      }
  
      
      let obj = this.addSchoolYearForm.value;

        this.currentService.save(obj).subscribe(res => {
            this.toastr.success("Tipo provvedimento salvata con successo!");
            this.submitted = false;
            this.open = false;
            this.addSchoolYearForm.reset();
            
            this.buttonLabel="Inserisci";
            if (!obj.id){
                //new row
if(this.ngxDatatableWrapper)                this.ngxDatatableWrapper.addRow(res);                        
            }
            else{
                this.closeModal();
               if(this.ngxDatatableWrapper) this.ngxDatatableWrapper.updateRow(obj);                                                
            }
            this.onSubmitOk.emit(res);

        }, error => {
            this.toastr.error("Errore di sistema");
        });
        
    }
  
    buttonLabel:string="Inserisci";
    loading:boolean=false;
    config = {
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };
    onEdit(row) {
        this.submitted = false;
        console.log(row);
        this.buttonLabel = "Aggiorna";
        this.modalRef = this.modalService.show(this.templateModal, this.config);
        this.addSchoolYearForm.setValue({
            id: row.id,
            provvedimento:row.provvedimento            
        });
    }
    onDelete(row) {
        console.log(row);
        if (!confirm(`Stai per eliminare il tipo provvedimento "${row.provvedimento}", proseguire?`)) return;
        this.loading = true;
        this.currentService.delete(row.id).subscribe(
            result => {
                this.loading = false;
                this.ngxDatatableWrapper.deleteRow(row);
                this.toastr.success('Tipo provvedimento eliminato con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile eliminare il tipo di provvedimento: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                console.log('error');
            });
    }
    closeModal(){
        if(this.modalRef)this.modalRef.hide();
        this.buttonLabel="Inserisci";
    }  

    getTipologie() {
        this.currentService.getAll().subscribe(
            result => {
                
                this.ngxDatatableWrapper.setInitialData(result);

            }
        );

    }
  }
  