import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";
import { JwtUser } from "src/app/models/jwt-user";
import { AppConfigService } from "src/app/services/app-config.service";
import { UserService } from "src/app/services";
import { TitleLogoService } from "src/app/services/title-logo.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  title: string;

  logo: string;

  ente: any;

  active: boolean = false;
  collapsed: boolean = true;
  menuTop = 0;
  menuFixed = false;
  items: string[] = [
    "The first choice!",
    "And another choice for you.",
    "but wait! A third!",
  ];
  currentUser: JwtUser;
  titleExtraText: string = "";
  headerCode:any = null;

  @ViewChild("menuElement") menuElement: ElementRef;

  constructor(
    private authService: AuthService,
    public router: Router,
    private userService:UserService,
    private titleLogoService: TitleLogoService
  ) {
    this.titleExtraText = AppConfigService.env.titleExtraText;
    this.headerCode = this.authService.getHeaderCode();
    if (!this.headerCode || this.headerCode === '') {
      this.userService.getInfo('').subscribe(
        res => {
          this.authService.setHeaderCode(res['headerCode']);
          this.headerCode = this.authService.getHeaderCode();
        }
      );
    }
  }

  ngOnInit() {
    this.authService.currentUser.subscribe((u) => (this.currentUser = u));
    //this.getIsLdapUser();
    this.title = this.titleLogoService.title;
    this.logo = this.titleLogoService.logo;
    this.ente = this.titleLogoService.ente;
  }

  signOut() {
    this.closeMenu();
    this.authService.logout();
    this.router.navigate(["/login"]);
  }

  isHeaderCustom(){
      return (this.headerCode && this.headerCode!='generale');
  }

  getCurrentUsername(): string {

    const userInfo = this.authService.getUserInfo();
    if (userInfo && userInfo.lastName){
        return `${this.capitalizeFirstLetter(userInfo.lastName)} ${this.capitalizeFirstLetter(userInfo.firstName)}`;
    }
    return this.authService.getCurrentUser().user_name;
  }

   capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

  activateMenu() {
    this.active = true;
  }

  closeMenu() {
    this.active = false;
  }

  toggleControls() {
    this.collapsed = !this.collapsed;
  }


  isUserlogged(): boolean {
    return this.authService.isLoggedIn();
  }

  isUserAdmin(): boolean {
    return this.authService.isAdmin();
  }
  isRegistered(): boolean {
    return this.authService.isRegistered();
  }

  goToHome(): void {
    this.router.navigate(["/"]);
  }

  goToAccount(){
    console.log('goToAccount');
    this.router.navigate(["account/me"]);
    this.toggleControls();
  }

  onHidden(): void {
    console.log("Dropdown is hidden");
  }
  onShown(): void {
    console.log("Dropdown is shown");
  }
  isOpenChange(): void {
    console.log("Dropdown state is changed");
  }

  isLdapUser = false;
  getIsLdapUser(){
    this.userService.getInfo("").subscribe((data) => {
      this.isLdapUser = data.ldapUser;
    });
  }
}
