import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import {
  Intervento,
  Fase,
  InterventoFinanziamento,
  Erogazione,
} from "../models";
import { StatoAvanzamentoLavori } from "../models/stato-avanzamento-lavori";
import { Observable } from "rxjs";
import { SicerLiquidazione } from "../models/sicer-liquidazione";
import { SicerOrdinativo } from "../models/sicer-ordinativo";
import { SicerImpegnoAccertamento } from "../models/sicer-impegno-accertamento";

@Injectable({
  providedIn: "root",
})
export class InterventoService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}Interventi/`;
  public uploadFileUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.uploadFile}`;
  constructor(private http: HttpClient) {}

  save(s: any, projectId?: number) {
    if (s.id) return this.update(s);
    return this.insert(s);
  }
  /*insert(s: Fase) {
        return this.http.post<any>(`${this.apiUrl}`, s);
    }*/

  getAllDaValidareDt(dataTablesParameters: any) {
    if (!dataTablesParameters) return this.getAllDaValidare();
    return this.http.post<any>(
      `${this.apiUrl}allDtDaValidare`,
      dataTablesParameters
    );
  }

  getAllDaValidare() {
    return this.http.get<any>(`${this.apiUrl}allDaValidare`);
  }

  update(s: Intervento) {
    return this.http.put<any>(`${this.apiUrl}${s.id}`, s);
  }

  concludiIntervento(id: number) {
    return this.http.put<any>(`${this.apiUrl}${id}/concludi`, null);
  }
  delete(id: number, motivazione: string = "") {
    return this.http.delete<any>(
      `${this.apiUrl}${id}?motivazione=${motivazione}`
    );
  }

  get(id: number) {
    return this.http.get<any>(`${this.apiUrl}${id}`);
  }
  getAlsoIfDeleted(id: number) {
    return this.http.get<any>(`${this.apiUrl}${id}?withDeleted=1`);
  }

  insert(s: Intervento) {
    return this.http.post<any>(`${this.apiUrl}`, s);
  }
  getFasi(progettoId: number) {
    return this.http.get<Fase[]>(`${this.apiUrl}${progettoId}/Fasi`);
  }
  deleteFase(progettoId: number, faseId: number) {
    return this.http.delete<boolean>(
      `${this.apiUrl}${progettoId}/Fasi/${faseId}`
    );
  }
  saveFase(progettoId: number, fase: any) {
    if (fase.id && fase.id > 0) return this.updateFase(progettoId, fase);
    return this.insertFase(progettoId, fase);
  }
  insertFase(progettoId: number, fase: any) {
    return this.http.post<any>(`${this.apiUrl}${progettoId}/Fasi`, fase);
  }
  updateFase(progettoId: number, fase: any) {
    return this.http.put<any>(
      `${this.apiUrl}${progettoId}/Fasi/${fase.id}`,
      fase
    );
  }
  getFinanziamenti(progettoId: number) {
    return this.http.get<InterventoFinanziamento[]>(
      `${this.apiUrl}${progettoId}/Finanziamenti`
    );
  }
  saveFinanziamento(finanziamento: InterventoFinanziamento) {
    if (finanziamento.id && finanziamento.id > 0)
      return this.updateFinanziamento(
        finanziamento.interventoId,
        finanziamento
      );
    return this.insertFinanziamento(finanziamento.interventoId, finanziamento);
  }
  insertFinanziamento(progettoId: number, finanziamento: any) {
    return this.http.post<any>(
      `${this.apiUrl}${progettoId}/Finanziamenti`,
      finanziamento
    );
  }
  updateFinanziamento(progettoId: number, finanziamento: any) {
    return this.http.put<any>(
      `${this.apiUrl}${progettoId}/Finanziamenti/${finanziamento.id}`,
      finanziamento
    );
  }
  deleteFinanziamento(interventoId: number, finanziamentoId: number) {
    return this.http.delete<any>(
      `${this.apiUrl}${interventoId}/Finanziamenti/${finanziamentoId}`
    );
  }

  getErogazioni(progettoId: number) {
    return this.http.get<Erogazione[]>(
      `${this.apiUrl}${progettoId}/Erogazioni`
    );
  }
  saveErogazione(erogazione: Erogazione) {
    if (erogazione.id && erogazione.id > 0)
      return this.updateErogazione(erogazione.interventoId, erogazione);
    return this.insertErogazione(erogazione.interventoId, erogazione);
  }
  insertErogazione(interventoId: number, erogazione: any) {
    return this.http.post<any>(
      `${this.apiUrl}${interventoId}/Erogazioni`,
      erogazione
    );
  }
  updateErogazione(interventoId: number, erogazione: any) {
    return this.http.put<any>(
      `${this.apiUrl}${interventoId}/Erogazioni/${erogazione.id}`,
      erogazione
    );
  }
  deleteErogazione(interventoId: number, erogazioneId: number) {
    return this.http.delete<any>(
      `${this.apiUrl}${interventoId}/Erogazioni/${erogazioneId}`
    );
  }
  getErogazioniDt(interventoId: number, dataTableInput: any) {
    return this.http.post<any>(
      `${this.apiUrl}${interventoId}/Erogazioni/alldt`,
      dataTableInput
    );
  }

  getSal(progettoId: number) {
    return this.http.get<StatoAvanzamentoLavori[]>(
      `${this.apiUrl}${progettoId}/Sal`
    );
  }
  getSalDt(interventoId: number, dataTableInput: any) {
    return this.http.post<any>(
      `${this.apiUrl}${interventoId}/Sal/alldt`,
      dataTableInput
    );
  }
  saveSal(erogazione: StatoAvanzamentoLavori) {
    if (erogazione.id && erogazione.id > 0)
      return this.updateSal(erogazione.interventoId, erogazione);
    return this.insertSal(erogazione.interventoId, erogazione);
  }
  insertSal(interventoId: number, erogazione: any) {
    return this.http.post<any>(`${this.apiUrl}${interventoId}/Sal`, erogazione);
  }
  updateSal(interventoId: number, erogazione: any) {
    return this.http.put<any>(
      `${this.apiUrl}${interventoId}/Sal/${erogazione.id}`,
      erogazione
    );
  }
  deleteSal(interventoId: number, erogazioneId: number) {
    return this.http.delete<any>(
      `${this.apiUrl}${interventoId}/Sal/${erogazioneId}`
    );
  }

  getProvvedimenti(interventoId: number) {
    return this.http.get<any>(`${this.apiUrl}${interventoId}/Provvedimenti`);
  }
  bindProvvedimento(interventoId: number, provvedimento: any) {
    return this.http.post<any>(
      `${this.apiUrl}${interventoId}/Provvedimenti`,
      provvedimento
    );
  }
  unbindProvvedimento(interventoId: number, provvId: number) {
    return this.http.delete<any>(
      `${this.apiUrl}${interventoId}/Provvedimenti/${provvId}`
    );
  }

  saveAnnualita(finId: number, annualita: any) {
    if (annualita.id && annualita.id > 0)
      return this.updateAnnualita(finId, annualita);
    return this.insertAnnualita(finId, annualita);
  }
  insertAnnualita(finId: number, annualita: any) {
    return this.http.post<any>(`${this.apiUrl}${finId}/Annualita`, annualita);
  }
  updateAnnualita(finId: number, annualita: any) {
    return this.http.put<any>(
      `${this.apiUrl}${finId}/Annualita/${annualita.id}`,
      annualita
    );
  }
  deleteAnnualita(finId: number, annualita: any) {
    return this.http.delete<any>(
      `${this.apiUrl}${finId}/Annualita/${annualita.id}`
    );
  }
  getAnnualita(progettoId: number) {
    return this.http.get<any[]>(`${this.apiUrl}${progettoId}/Annualita`);
  }

  downloadSalDocument(interventoId: number, salId: number, item: any) {
    return this.http.get(
      `${this.apiUrl}${interventoId}/Sal/${salId}/documents/${item.id}/download`,
      {
        observe: "response",
        responseType: "blob",
      }
    );
  }

  getFinanziamentiOfMainIntervento(interventoId: number) {
    return this.http.get<InterventoFinanziamento[]>(
      `${this.apiUrl}${interventoId}/Finanziamenti/GetFromMainIntervento`
    );
  }
  manageValidation(intId: number, action: boolean) {
    return this.http.put<any>(
      `${this.apiUrl}${intId}/validazione/${action}`,
      null
    );
  }

  getStoricoDt(finId: number, dataTablesParameters: any) {
    return this.http.post<any>(
      `${this.apiUrl}${finId}/history/alldt`,
      dataTablesParameters
    );
  }
  getDettaglioStorico(storicoId, finId) {
    return this.http.get<any>(`${this.apiUrl}${finId}/history/${storicoId}`);
  }

  esportaPdf(id: number) {
    /*return this.http.get<any>(
            `${this.apiUrl}${id}/pdf`
        );*/
    return this.http.get(`${this.apiUrl}${id}/pdf`, {
      observe: "response",
      responseType: "blob",
    });
  }

  esportaExcel(id: number) {
    /*return this.http.get<any>(
            `${this.apiUrl}${id}/pdf`
        );*/
    return this.http.get(`${this.apiUrl}${id}/excel`, {
      observe: "response",
      responseType: "blob",
    });
  }

  esportaExcelCompleto(id: number) {
    return this.http.get(`${this.apiUrl}${id}/excelCompleto`, {
      observe: "response",
      responseType: "blob",
    });
  }

  spostaAnnualita(interventoId: number, obj: any) {
    return this.http.put<any>(
      `${this.apiUrl}${interventoId}/spostaAnnualita`,
      obj
    );
  }

  getDrawedType(interventoId: number) {
    return this.http.get<string[]>(
      `${this.apiUrl}${interventoId}/geolocalizzazione/drawedtype`
    );
  }

  updateMassiveClassificazioni(classificazioniId, itemsId, flagAddRemove) {
    return this.http.put<any>(`${this.apiUrl}massiveClassificazioni`, {
      classificazioniId: classificazioniId,
      itemsId: itemsId,
      flagAddRemove: flagAddRemove,
    });
  }

  updateMassiveAreeTematiche(areeId, itemsId, flagAddRemove) {
    return this.http.put<any>(`${this.apiUrl}massiveAreeTematiche`, {
      classificazioniId: areeId,
      itemsId: itemsId,
      flagAddRemove: flagAddRemove,
    });
  }

  spostaInterventi(progettoDestinazioneId, itemsId) {
    return this.http.put<any>(`${this.apiUrl}spostaInterventi`, {
      progettoDestinazioneId: progettoDestinazioneId,
      itemsId: itemsId,
    });
  }

  salvaMonitoraggio(id, monitoraggio) {
    return this.http.put<any>(`${this.apiUrl}${id}/monitoraggi`, monitoraggio);
  }
  cambiaMonitoraggioAbilitatoFlag(id) {
    return this.http.put<any>(
      `${this.apiUrl}${id}/monitoraggi/changeStatus`,
      null
    );
  }

  getMonitoraggio(id) {
    return this.http.get<any>(`${this.apiUrl}${id}/monitoraggi`);
  }

  getMonitoraggiDt(dataTablesParameters: any) {
    return this.http.post<any>(
      `${this.apiUrl}monitoraggi/dt`,
      dataTablesParameters
    );
  }

  getInterventiDelFinanziamentoDt(finanziamentoId, dataTablesParameters: any) {
    return this.http.post<any>(
      `${this.apiUrl}finanziamento/${finanziamentoId}/dt`,
      dataTablesParameters
    );
  }

  getAllDt(dataTablesParameters: any, interventoId: number) {
    return this.http.post<any>(
      `${this.apiUrl}${interventoId}/dnsh/dt`,
      dataTablesParameters
    );
  }

  saveDnsh(dnsh: FormData, id: number) {
    return this.http.post<any>(`${this.apiUrl}${id}/dnsh`, dnsh);
  }

  deleteDocument(id: any) {
    return this.http.delete<any>(`${this.apiUrl}${id}/dnsh/delete`);
  }

  downloadDocument(id: any) {
    return this.http.get(`${this.apiUrl}${id}/dnsh/file/download`, {
      observe: "response",
      responseType: "blob",
    });
  }

  getBdapData(
    dataTablesParameters: any,
    interventoId: number,
    requestType: string
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}${interventoId}/bdap/${requestType}`,
      dataTablesParameters
    );
  }

  getSicerCapitoliBilancioByInterventoId(id: any) {
    return this.http.get<any>(`${this.apiUrl}${id}/SicerCapitoliBilancio`);
  }

  associaSicerCapitolo(intId: any, pkCpt: any) {
    return this.http.put<any>(
      `${this.apiUrl}${intId}/SicerCapitoliBilancio/${pkCpt}`,
      null
    );
  }

  disassociaSicerCapitolo(intId: any, pkCpt: any) {
    return this.http.delete<any>(
      `${this.apiUrl}${intId}/SicerCapitoliBilancio/${pkCpt}`
    );
  }

  getSicerImpegniSelezionatiByInterventoId(id: number) {
    return this.http.get<any[]>(`${this.apiUrl}${id}/SicerImpegniAccertamenti`);
  }

  associaSicerImpegnoAccertamento(id: number, pkImpAcc: string, pkCpt: string) {
    return this.http.put<any[]>(
      `${this.apiUrl}${id}/SicerImpegniAccertamenti/${pkImpAcc}/SicerCapitoliBilancio/${pkCpt}`,
      null
    );
  }

  disassociaSicerImpegnoAccertamento(id: number, pkImpAcc: string) {
    return this.http.delete<any>(
      `${this.apiUrl}${id}/SicerImpegniAccertamenti/${pkImpAcc}`
    );
  }

  getSicerLiquidazioniByInterventoIdAndPkImpaccAndRagsocSoggetto(
    interventoId: number,
    pkImpacc: string,
    ragsocSoggetto: string,
    codSoggetto: string,
    numeroOrdine: number
  ) {
    return this.http.get<SicerLiquidazione[]>(
      `${
        this.apiUrl
      }${pkImpacc}/${codSoggetto}/${numeroOrdine}/SicerImpegniAccertamenti/${interventoId}/SicerLiquidazioniOrdinativi?ragsocSoggetto=${encodeURIComponent(
        ragsocSoggetto
      )}`
    );
  }

  getSicerLiquidazioniOrdinativiByPkImpaccAndNumLiq(
    pkImpacc: string,
    numeroLiquidazione: string
  ) {
    return this.http.get<SicerOrdinativo[]>(
      `${this.apiUrl}${pkImpacc}/SicerImpegniAccertamenti/SicerLiquidazioniOrdinativi/${numeroLiquidazione}`
    );
  }

  getDtSicerLiquidazioniOrdinativiByInterventoIdAndImpegnoId(
    dtParams: any,
    interventoId: number,
    numeroLiquidazione: string,
    impegnoId: string
  ) {
    return this.http.post<any>(
      `${this.apiUrl}${impegnoId}/SicerImpegniAccertamenti/dt/${interventoId}/SicerLiquidazioniOrdinativi/${numeroLiquidazione}`,
      dtParams
    );
  }

  associaMassivaSicerImpegnoAccertamento(
    id: number,
    pkCpt: string,
    interventoId: number
  ) {
    return this.http.put<SicerOrdinativo[]>(
      `${this.apiUrl}${id}/SicerImpegniAccertamenti/${interventoId}/SicerCapitoliBilancio/${pkCpt}/massivo`,
      null
    );
  }

  disassociaMassivaSicerImpegnoAccertamento(id: number, pkCapitolo: string) {
    return this.http.delete<any>(
      `${this.apiUrl}${pkCapitolo}/SicerCapitolo/${id}/SicerImpegniAccertamenti/massivo`
    );
  }

  getDatiDettaglio(id: number) {
    return this.http.get<any>(`${this.apiUrl}${id}/datiDettaglio?isProject=0`);
  }

  saveMassiveImpAccs(id: number, toSaveImpAccs: SicerImpegnoAccertamento[]) {
    return this.http.post<SicerImpegnoAccertamento[]>(
      `${this.apiUrl}SicerCapitolo/${id}/SicerImpegniAccertamenti/massivo`,
      toSaveImpAccs
    );
  }

  deleteMassiveImpAccs(
    id: number,
    toDeleteImpAccs: SicerImpegnoAccertamento[]
  ) {
    return this.http.request(
      "delete",
      `${this.apiUrl}SicerCapitolo/${id}/SicerImpegniAccertamenti/massivo`,
      { body: toDeleteImpAccs }
    );
  }
}
