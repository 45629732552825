import { Component, OnInit ,OnDestroy} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TipoFinanziamento } from '../../../models';
import { StorageService} from '../../../services';

@Component({
  selector: 'app-search-finanziamento',
  templateUrl: './search-finanziamento.component.html',
  styleUrls: ['./search-finanziamento.component.css']
})
export class SearchFinanziamentoComponent implements OnInit,OnDestroy {

  form: FormGroup;
  tipiFinanziamento: TipoFinanziamento[];
  filters: any = null;

  public maskCodice: any = {
    mask: 'KKKKKKKKKKKKKKKKKK €',
    lazy: false,  // make placeholder always visible
    blocks: {
      KKKKKKKKKKKKKKKKKK: {
        mask: Number,
        thousandsSeparator: '.',
        scale: 2,
        padFractionalZeros: true,
        radix: ','
      }
    }
  };

  loadComponent = false;
  disableSearch = false;

  constructor(
    private storageService:StorageService,
    private formBuilder: FormBuilder
  ) { }
    ngOnDestroy(): void {
        if(this.elencoTipiFinSubscription)this.elencoTipiFinSubscription.unsubscribe();
    }

  ngOnInit(): void {
    window.scroll(0, 0);

    this.form = this.initializeFormGroup();

    this.getTipiFinanziamento();
  }

  private initializeFormGroup() {
    return this.formBuilder.group({
      codice: [null],
      importo: [null],
      descrizione: [null],
      note: [null],
      fonti: [null]
    });
  }

  private elencoTipiFinSubscription= null;
    getTipiFinanziamento() {
        this.elencoTipiFinSubscription = this.storageService.elencoTipiFinanziamento.subscribe(
            x=>{
                console.log(x);
                this.tipiFinanziamento = x?x:[];
            }
            );
    }

  checkDisableSearch(): boolean {
    return this.disableSearch || (!this.f.codice.value && !this.f.descrizione.value && !this.f.note.value && (!this.f.fonti.value || this.f.fonti.value.length === 0) && !this.f.importo.value);
  }

  get f() {
    return this.form.controls;
  }

  disableFilters($event: boolean) {
    $event ? this.form.disable() : this.form.enable();
    this.disableSearch = $event;
  }

  setDisableSearchButton($event: boolean) {
    this.disableSearch = $event;
  }

  onSubmit() {
    this.disableSearch = true;
    this.loadComponent = true;
    this.filters = this.form.getRawValue();
  }
}
