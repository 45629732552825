<div class="p-5 container-xl text-center" [hidden]="!loading">
  <app-loading message="Caricamento in corso..."></app-loading>
</div>
<div class="container-xl" [hidden]="loading">


  <div class="position-sticky sticky-top  pt-4 pb-4 elemento-aggiorna mb-5 shadow-custom sticky-top-custom-sal"
    [ngClass]="{'sticky-top-custom-modal': isInModal,'sticky-top-custom':!isInModal  }">
    <div class=" row  justify-content-center">
      <div class="col-lg-4 col-md-6 text-center" *ngIf="gotoBtnOptions && gotoBtnOptions.show">
        <button (click)="gotoBackBtnClick()" [disabled]="loading" class="btn btn-secondary btn-block font-weight-bold"
          type="button">
          {{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}&nbsp;<i class="fas fa-times"
            *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i>
        </button>
      </div>
    </div>

  </div>
  <!--***-->
  <accordion [isAnimated]="true">
    <accordion-group heading="Dati generali">


      <div class="row justify-content-center">
        <div class=" col-lg-6">
          <table class="table">


            <tr>
              <td class="text-right">titolo:</td>
              <td>{{finanziamento?finanziamento.titolo:''}}</td>
            </tr>

            <tr style="vertical-align: top;">
              <td class="text-right">Fonte:</td>
              <td style="vertical-align: top;">
                {{finanziamento && finanziamento.tipoFinanziamento?finanziamento.tipoFinanziamento.tipoFinanziamento:''}}
              </td>
            </tr>
            <tr>
              <td class="text-right">Strumento&nbsp;attuativo:</td>
              <td>
                {{finanziamento && finanziamento.tipoStrumentoAttuativo?finanziamento.tipoStrumentoAttuativo.descrizione:''}}
              </td>
            </tr>
            <tr>
              <td class="text-right">Scadenza:</td>
              <td>{{finanziamento?printScadenza():''}}</td>
            </tr>
            <tr>
              <td class="text-right">Importo&nbsp;complessivo:</td>
              <td>{{printValueAsCurrency(finanziamento?finanziamento.importoComplessivo:0)}}</td>
            </tr>
            <tr>
              <td class="text-right">Disponibilità&nbsp;residua:</td>
              <td>{{printValueAsCurrency(finanziamento?finanziamento.disponibilitaResidua:0)}}</td>
            </tr>

          </table>
        </div>

      </div>


    </accordion-group>
  </accordion>


  <!--tabella interventi-->
  <div class="col-lg-6 col-sm-6 col-6 mt-5">
    <h5 for="" class="">Tabella progetti {{isGr34()||isAdmin()?'/ fabbisogni':''}}</h5>
  </div>
  <div class="container">

    <div class="fixedHeaderTable col-lg-12 mt-3 table-responsive table-wrapper-scroll-y scrollbar-y px-0">
      <table class="table table-hover table-colore">
        <thead class="">
          <th class="fixedmeY" style="min-width: 240px;"></th>
          <th style="min-width: 240px;">fase</th>
          <ng-container *ngIf="finanziamento">
            <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of finanziamento.annualita">
              {{annualita.anno}}</th>
          </ng-container>
          <!--<th style="min-width: 150px;">coperto</th>-->
          <!--<th style="min-width: 150px;">da coprire</th>-->
          <th class="text-right" style="min-width: 180px;">totale</th>
        </thead>
        <tbody>

          <!--1###-->
          <ng-container *ngIf="finanziamento && listaInterventi && listaInterventi.length>0">
            <ng-container *ngFor="let progetto of listaInterventi;let i = index">
              <tr>

                <td class="fixedme" style="min-width: 240px;">
                  <a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')"
                    class="pointer font-weight-bold text-0083e0" data-toggle="collapse" role="button"
                    aria-expanded="false" [attr.aria-controls]="'.tr_collapse_' + i"
                    [attr.data-target]="'.tr_collapse_' + i">
                    <i class="fas fa-arrow-circle-down">
                    </i>&nbsp;progetto {{isGr34()||isAdmin()?'/ fabbisogno':''}} {{progetto.codice}}
                  </a>
                  <div class="" style="font-size: 13px;">{{progetto.descrizione}}</div>
                </td>

                <td style="min-width: 240px; font-size: 13px;">{{getFaseCorrente(progetto)}}</td>
                <td class="text-right" style="min-width: 150px;" *ngFor="let annualita of finanziamento.annualita">
                  {{printValueAsCurrency(getFinanziamentoPerAnno(progetto,annualita.anno),false)}}&nbsp;€</td>
                <!-- <td style="min-width: 150px;">{{printValueAsCurrency(getCoperto(progetto),false)}}&nbsp;€</td>-->
                <!--<td style="min-width: 150px;">{{printValueAsCurrency(getDaCoprire(progetto),false)}}&nbsp;€</td>-->
                <td class="text-right" style="min-width: 180px;">
                  <strong>{{printValueAsCurrency(getTotale(progetto),false)}}&nbsp;€</strong></td>

              </tr>
              <ng-container *ngIf="progetto && progetto.subInterventi.length>0">




                <ng-container *ngFor="let intervento of progetto.subInterventi;let j = index">

                  <tr class="background-table collapse {{'tr_collapse_' + i}}">
                    <th class="fixedme" scope="row" style="min-width: 240px;">
                      intervento {{intervento.codice}}
                      <div class="" style="font-size: 13px; font-weight: normal;">
                        {{intervento.descrizione}}</div>
                    </th>

                    <td style="min-width: 240px; font-size: 13px;">{{getFaseCorrente(intervento)}}</td>

                    <td class="text-right" style="min-width: 150px;" *ngFor="let annualita of finanziamento.annualita">
                      {{printValueAsCurrency(getFinanziamentoPerAnno(intervento,annualita.anno),false)}}&nbsp;€
                    </td>
                    <!--<td style="min-width: 150px;"></td>-->
                    <!--<td style="min-width: 150px;"></td>-->
                    <td class="text-right" style="min-width: 180px;">
                      <strong>{{printValueAsCurrency(getTotale(intervento),false)}}&nbsp;€</strong></td>
                  </tr>
                </ng-container>





                <tr>
                  <td colspan="1" class="fixedHeaderTable fixedme bold" style="min-width: 240px;">
                    destinato
                  </td>
                  <td></td>
                  <td class="text-right" style="min-width: 150px;" *ngFor="let annualita of finanziamento.annualita">
                    {{printValueAsCurrency(getUtilizzatoPerAnno(progetto,annualita.anno),false)}}&nbsp;€</td>
                  <td class="text-right" style="min-width: 180px;">
                    <strong>{{printValueAsCurrency(getTotaleUtilizzato(progetto),false)}}&nbsp;€</strong></td>
                </tr>
                <tr class="border-custom border-primary">
                  <td colspan="1" class="fixedHeaderTable fixedme bold" style="min-width: 240px;">
                    disponibile
                  </td>
                  <td></td>
                  <td class="text-right" style="min-width: 150px;" *ngFor="let annualita of finanziamento.annualita">
                    {{printValueAsCurrency(getFinanziamentoPerAnno(progetto,annualita.anno) - getUtilizzatoPerAnno(progetto,annualita.anno),false)}}&nbsp;€
                  </td>
                  <td class="text-right" style="min-width: 180px;">
                    <strong>{{printValueAsCurrency(getTotale(progetto) - getTotaleUtilizzato(progetto),false)}}&nbsp;€</strong>
                  </td>
                </tr>






              </ng-container>

            </ng-container>
          </ng-container>
          <ng-container *ngIf="!(listaInterventi && listaInterventi.length>0)">
            <tr>
              <td colspan="20">Nessun progetto {{isGr34()||isAdmin()?'/ fabbisogno':''}} presente</td>
            </tr>
          </ng-container>


        </tbody>


        <tfoot>
          <tr>
            <th class="fixedmefooter">totale</th>
            <th></th>
            <ng-container *ngIf="finanziamento">
              <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of finanziamento.annualita">
                {{printValueAsCurrency(getTotaleSommaAnno(annualita.anno),false)}}&nbsp;€</th>
            </ng-container>
            <!--<th>{{printValueAsCurrency(getSommaCopertoTotale(),false)}}&nbsp;€</th>-->
            <!--<th>{{printValueAsCurrency(getSommaDaCoprireTotale(),false)}}&nbsp;€</th>-->
            <th class="text-right">{{printValueAsCurrency(getTotaleSommaProgetti(),false)}}&nbsp;€</th>
          </tr>
          <tr>
            <th class="fixedmefooter">valore finanziamento</th>
            <th></th>
            <ng-container *ngIf="finanziamento">
              <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of finanziamento.annualita">
                {{printValueAsCurrency(getImportoPerAnno(finanziamento,annualita.anno),false)}}&nbsp;€</th>
            </ng-container>
            <!--<th>{{printValueAsCurrency(getCoperto(intervento),false)}}&nbsp;€</th>-->
            <!--<th>{{printValueAsCurrency(getDaCoprire(intervento),false)}}&nbsp;€</th>-->

            <th class="text-right">{{printValueAsCurrency(getTotaleFinanziamento(finanziamento),false)}}&nbsp;€</th>
          </tr>
          <tr>
            <th class="fixedmefooter">disponibilità</th>
            <th></th>
            <ng-container *ngIf="finanziamento">
              <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of finanziamento.annualita">
                {{printValueAsCurrency(getTotaleDisponibilitaFinanziamentoPerAnno(annualita.anno),false)}}&nbsp;€
              </th>
            </ng-container>
            <!--<th style="min-width: 150px;">{{printValueAsCurrency(getDisponibilitaCoperto(),false)}}&nbsp;€</th>-->
            <!--<th style="min-width: 150px;">{{printValueAsCurrency(getDisponibilitaDaCoprire(),false)}}&nbsp;€</th>-->

            <th class="text-right">{{printValueAsCurrency(getTotaleDisponibilitaFinanziamento(),false)}}&nbsp;€</th>
          </tr>
        </tfoot>
      </table>
    </div>


    <div class=" mt-5">
      <hr>
    </div>
  </div>


  <!--tabella capitoli-->
  <div class="col-lg-6 col-sm-6 col-6 mt-5">
    <h5 for="" class="">Tabella Capitoli di bilancio</h5>
  </div>
  <div class="container">

    <div class="fixedHeaderTable col-lg-12 mt-3 table-responsive table-wrapper-scroll-y scrollbar-y px-0">
      <table class="table table-hover table-colore">
        <thead class="">
          <th class="fixedmeY" style="min-width: 240px;"></th>
          <!--<th style="min-width: 240px;">fase</th>-->
          <ng-container *ngIf="finanziamento">
            <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of finanziamento.annualita">
              {{annualita.anno}}</th>
          </ng-container>
          <!--<th style="min-width: 150px;">coperto</th>-->
          <!--<th style="min-width: 150px;">da coprire</th>-->
          <th class="text-right" style="min-width: 180px;">totale</th>
        </thead>
        <tbody>

          <!--1###-->
          <ng-container *ngIf="finanziamento && listaCapitoli && listaCapitoli.length>0">
            <ng-container *ngFor="let capitolo of listaCapitoli;let i = index">
              <tr>

                <td class="fixedme" style="min-width: 240px;">
                  <a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')"
                    class="pointer font-weight-bold text-0083e0" data-toggle="collapse" role="button"
                    aria-expanded="false">
                    <i class="fas fa-arrow-circle-down"></i>&nbsp;capitolo {{capitolo.codice}}
                  </a>
                  <div class="" style="font-size: 13px;">{{capitolo.descrizione}}</div>
                </td>

                <!--<td style="min-width: 240px; font-size: 13px;">{{getFaseCorrente(progetto)}}</td>-->
                <td class="text-right" style="min-width: 150px;" *ngFor="let annualita of finanziamento.annualita">
                  {{printValueAsCurrency(getImportoPerAnnoCapitolo(capitolo,annualita.anno),false)}}&nbsp;€</td>
                <!-- <td style="min-width: 150px;">{{printValueAsCurrency(getCoperto(progetto),false)}}&nbsp;€</td>-->
                <!--<td style="min-width: 150px;">{{printValueAsCurrency(getDaCoprire(progetto),false)}}&nbsp;€</td>-->
                <td class="text-right" style="min-width: 180px;">
                  <strong>{{printValueAsCurrency(getTotaleCapitolo(capitolo),false)}}&nbsp;€</strong></td>

              </tr>

            </ng-container>
          </ng-container>
          <ng-container *ngIf="!(listaCapitoli && listaCapitoli.length>0)">
            <tr>
              <td colspan="20">Nessun capitolo di bilancio presente</td>
            </tr>
          </ng-container>


        </tbody>
        <tfoot>
          <tr>
            <th class="fixedmefooter">totale stanziato in bilancio</th>
            <!--<th></th>-->
            <ng-container *ngIf="finanziamento">
              <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of finanziamento.annualita">
                {{printValueAsCurrency(getTotaleSommaAnnoCapitoli(annualita.anno),false)}}&nbsp;€</th>
            </ng-container>
            <!--<th>{{printValueAsCurrency(getSommaCopertoTotale(),false)}}&nbsp;€</th>-->
            <!--<th>{{printValueAsCurrency(getSommaDaCoprireTotale(),false)}}&nbsp;€</th>-->
            <th class="text-right">{{printValueAsCurrency(getTotaleSommaCapitoli(),false)}}&nbsp;€</th>
          </tr>
          <tr>
            <th class="fixedmefooter">valore finanziamento</th>
            <!--<th></th>-->
            <ng-container *ngIf="finanziamento">
              <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of finanziamento.annualita">
                {{printValueAsCurrency(getImportoPerAnno(finanziamento,annualita.anno),false)}}&nbsp;€</th>
            </ng-container>
            <!--<th>{{printValueAsCurrency(getCoperto(intervento),false)}}&nbsp;€</th>-->
            <!--<th>{{printValueAsCurrency(getDaCoprire(intervento),false)}}&nbsp;€</th>-->

            <th class="text-right">{{printValueAsCurrency(getTotaleFinanziamento(finanziamento),false)}}&nbsp;€</th>
          </tr>
          <tr>
            <th class="fixedmefooter">Differenza ancora da allocare in bilancio</th>
            <!-- <th></th>-->
            <ng-container *ngIf="finanziamento">
              <th class="text-right" style="min-width: 150px;" *ngFor="let annualita of finanziamento.annualita">
                {{printValueAsCurrency(getImportoPerAnno(finanziamento,annualita.anno)-getTotaleSommaAnnoCapitoli(annualita.anno),false)}}&nbsp;€
              </th>
            </ng-container>
            <!--<th style="min-width: 150px;">{{printValueAsCurrency(getDisponibilitaCoperto(),false)}}&nbsp;€</th>-->
            <!--<th style="min-width: 150px;">{{printValueAsCurrency(getDisponibilitaDaCoprire(),false)}}&nbsp;€</th>-->

            <th class="text-right">
              {{printValueAsCurrency(getTotaleFinanziamento(finanziamento)-getTotaleSommaCapitoli(),false)}}&nbsp;€</th>
          </tr>
        </tfoot>


      </table>
    </div>


    <div class=" mt-5">
      <hr>
    </div>
  </div>




</div>
