<ng-template #templateEditAdd>
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()" class="col-lg-12">
        <input type="hidden" [formGroup]="editForm" formControlName="id" />
        <div class="form-group">
            <h5><label for="descrizione" class="mandatory">Descrizione:</label></h5>
            <input [formGroup]="editForm" formControlName="descrizione" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci la descrizione" [ngClass]="{
                    'form-control': true,
                    'is-invalid': submitted && f.descrizione.errors
                    }" />
            <div *ngIf="submitted && f.descrizione.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.descrizione.errors.required">
                    Campo obbligatorio
                </div>
            </div>
        </div>
        <div class="form-group" [ngClass]="{
        'is-invalid': submitted && f.cofinanziamento.errors
        }">
            <h5> <label for="" class="mandatory">Cofinanziamento:</label></h5>
            <div class="form-check form-check-inline ml-2">

                <input [formGroup]="editForm" id="cofinanziamento1" formControlName="cofinanziamento" type="radio" value="1" class="form-check-input" [ngClass]="{
                          'is-invalid': submitted && f.cofinanziamento.errors
                          }" />

                <label for="cofinanziamento1" class="form-check-label">Si</label>
            </div>
            <div class="form-check form-check-inline">
                <input [formGroup]="editForm" id="cofinanziamento2" formControlName="cofinanziamento" type="radio" value="0" class="form-check-input" [ngClass]="{
                                        'is-invalid': submitted && f.cofinanziamento.errors
                                        }" />
                <label for="cofinanziamento2" class="form-check-label">No</label>
            </div>

        </div>
        <div *ngIf="submitted && f.cofinanziamento.errors" class="invalid-feedback" style="display: block;">
            <div *ngIf="f.cofinanziamento.errors.required">
                Campo obbligatorio
            </div>
        </div>
        <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
      {{buttonLabel}}
    </button>
    </form>
</ng-template>

<div class="container sfondo_bianco">
    <hr />
    <accordion [isAnimated]="true">
        <accordion-group heading="Inserisci Nuova tipologia di strumento attuativo">
            <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
        </accordion-group>
    </accordion>
    <div class="mt-5">

        <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptions">
            <ng-template #btnEditTemplate let-row="row" let-value="value">
                <a [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top" title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit"></i></a>
            </ng-template>
            <ng-template #btnDeleteTemplate let-row="row" let-value="value">
                <a [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt"></i></a>
            </ng-template>
            <ng-template #dateTemplate let-row="row" let-value="value">
                {{printValueAsDate(value)}}
            </ng-template>
            <ng-template #booleanValueTemplate let-row="row" let-value="value">
                {{(value?"Si":"No")}}
            </ng-template>
        </ngx-datatable-wrapper>
    </div>
    <ng-template #templateModal>
        <div class="modal-header  text-center">
            <h4 class="modal-title pull-left">Modifica tipologia</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
        </div>
    </ng-template>


</div>