import { Location } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
//import { FinanziamentiComponent } from "../../finanziamenti/finanziamenti.component";
import { Router } from "@angular/router";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NgxDatatableWrapperComponent } from "src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component";
import {
  InterventoService,
  ProgettoService,
  ProvvedimentoService,
  TipoProvvedimentoService,
} from "../../../services";


@Component({
  selector: "app-dettaglio-provvedimenti",
  templateUrl: "./dettaglio-provvedimenti.component.html",
  styleUrls: ["./dettaglio-provvedimenti.component.css"],
})
export class DettaglioProvvedimentiComponent implements OnInit {
  modalRef: BsModalRef;
  config = {
    backdrop: false,
    ignoreBackdropClick: false,
    keyboard: true,
    class: "modal-lg",
  };
  _isInModal: boolean = false;
  @Input()
  set isInModal(value: boolean) {
    this._isInModal = value;
  }
  get isInModal() {
    return this._isInModal;
  }

  _provvedimentoId: number;
  @Input()
  set provvedimentoId(value: number) {
    this._provvedimentoId = value;
  }

  get provvedimentoId() {
    return this._provvedimentoId;
  }

  //loading: boolean = true;
  loading: boolean = false;

  @ViewChild("btnDetailTemplate", { static: true })
  public btnDetailTemplate: TemplateRef<any>;

  @ViewChild("tipologiaValueTemplate", { static: true })
  public tipologiaValueTemplate: TemplateRef<any>;

  @ViewChild("fieldNoteTemplate", { static: true })
  public fieldNoteTemplate: TemplateRef<any>;
  @ViewChild("direzioneFieldValueTemplate", { static: true })
  public direzioneFieldValueTemplate: TemplateRef<any>;

  @ViewChild("ngxDatatableWrapper")
  ngxDatatableWrapper: NgxDatatableWrapperComponent;

  dtOptions: any;

  columns = [
    {
      name: "",
      prop: "id",
      sortable: true,
      filtrable: false,
      maxWidth: 0,
      width: 0,
      visible: false,
      // 'buttonsTemplate'
    },

    {
      name: "provvedimento id",
      prop: "provvedimentoId",
      sortable: true,
      filtrable: true,
      minWidth: 75,
      visible: false,
      //cellTemplate: "dateTimeValueTemplate",
      flexGrow: 1,
    },
    {
      name: "codice",
      prop: "codice",
      sortable: true,
      filtrable: true,
      minWidth: 80,
      flexGrow: 2,
    },
    {
      name: "tipologia",
      prop: "tipologia",
      sortable: true,
      filtrable: true,
      minWidth: 90,
      flexGrow: 3,
    },
    {
      name: "descrizione",
      prop: "descrizione",
      sortable: true,
      filtrable: true,
      minWidth: 155,
      flexGrow: 4,
    },

    {
      name: "aree gestionali",
      prop: "descrizioneAree",
      sortable: true,
      filtrable: true,
      resizeable: false,
      draggable: false,
      cellTemplate: "direzioneFieldValueTemplate",
      flexGrow: 4,
      minWidth: 120,
    },
    {
      name: "note",
      prop: "note",
      sortable: true,
      filtrable: false,
      cellTemplate: "fieldNoteTemplate",
      flexGrow: 1,
      minWidth: 50,
    },

    {
      name: "dettaglio",
      prop: "id",
      sortable: false,
      filtrable: false,
      cellTemplate: "btnDetailTemplate", // 'buttonsTemplate'
      minWidth: 60,
      flexGrow: 1,
    },
  ];
  templateList = [];

  constructor(
    private provvedimentoService: ProvvedimentoService,
    private router: Router,
    private interventoService: InterventoService
  ) {
    this.dtOptions = {
      //rows: [{id: 1, date : new Date(), tipologia: "Intervento", descrizione: "Descrizione bla", aree: "Area 1, bla bla", note: "Note bla bla"} , {id: 2, date : new Date(), tipologia: "Finanziamento", descrizione: "Descrizione bla", aree: "Area 1, bla bla", note: "Note bla bla"}, {id: 3, date : new Date(), tipologia: "Progetto", descrizione: "Descrizione bla", aree: "Area 1, bla bla", note: "Note bla bla"}],
      rows: [],
      columns: this.columns,
      columnMode: ColumnMode.flex,
      headerHeight: "50",
      footerHeight: "50",
      rowHeight: "auto",
      externalSorting: true, // true
      loadingIndicator: "loading",
      class: "bootstrap",
      summaryRow: true,
      summaryPosition: "'bottom'",
      externalPaging: true, // true
      count: 0,
      offset: 0,
      limit: 10,
      //serverSide: true,
      serverSide: true,
      scrollbarH: "true",
      ajax: this.getTuttoLegatoAlProvvedimento,
      sorts: [{ dir: "desc", prop: "date" }],
    };
  }
  ngOnInit(): void {
    console.log(this._provvedimentoId);
    // this.provvedimentoService.getAllObjectsByProvvedimentoId(23).subscribe(res => {
    //   console.log(res);
    // });
    this.templateList = [
      { name: "btnDetailTemplate", template: this.btnDetailTemplate },
      { name: "tipologiaValueTemplate", template: this.tipologiaValueTemplate },
      { name: "fieldNoteTemplate", template: this.fieldNoteTemplate },
      {
        name: "direzioneFieldValueTemplate",
        template: this.direzioneFieldValueTemplate,
      },
    ];
    window.scroll(0, 0);
  }

  reloadAllData() {
    //this.loading = true;
    this.ngxDatatableWrapper.gridModel.data = [];
    this.ngxDatatableWrapper.refresh();
  }

  getTuttoLegatoAlProvvedimento = (dataTablesParameters: any, callback: any) => {
    let that = this;
    //that._provvedimentoId = 1;
    if (!that._provvedimentoId || that._provvedimentoId <= 0) return;
    this.provvedimentoService
      .getAllObjectsByProvvedimentoId(
        dataTablesParameters,
        that._provvedimentoId
      )
      .subscribe((resp) => {
        // resp.data.forEach((element) => {
        //   element.descrizioneAree = JSON.parse(
        //     element.descrizioneAree.replace(/\\"/gi, '"')
        //   );
        // });

        for (let i = 0; i < resp.data.length; i++) {
          resp.data[i].descrizioneAree = JSON.parse(
            resp.data[i].descrizioneAree.replace(/\\"/gi, '"')
          );
        }
        callback(resp);
        that.loading = false;
      });
  };

  @Input()
  gotoBtnOptions: any;

  @Output()
  gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
  gotoBackBtnClick() {
    this.gotoBtnFunction.emit(true);
  }

  goToFinanziamento(id) {
    this.router.navigate([`inframob/manageFinanziamenti/${id}`]);
  }

  goToIntervento(id) {
    this.interventoService.get(id).subscribe((x) => {
      this.router.navigate([`inframob/progetti/${x.progettoId}/${id}`]);
    });
  }

  goToProgetto(id) {
    this.router.navigate([`inframob/progetti/${id}?detail=1`]);
  }

  goToDetails($event) {
    console.log($event);
    switch ($event.tipologia) {
      case "finanziamento":
        this.goToFinanziamento($event.id);
        break;
      case "intervento":
        //this.getProjectId($event.id)
        this.goToIntervento($event.id);
        break;

      default:
        this.goToProgetto($event.progettoId);
        break;
    }
  }

  getProjectId(interventoId) {
    this.interventoService.get(interventoId).subscribe((x) => {});
  }

  printDirezioneFieldValue(data) {
    console.log("printDirezioneFieldValue");
    let str = "";
    let arr = [];
    if (data && data != "") {
      let areeDirezione = data; //JSON.parse(data);
      for (let o in areeDirezione) {
        arr.push(areeDirezione[o]["descrizioneEstesa"]);
        //}
        str = arr.join("<br />");
      }
      return arr;
    }
  }
}
