<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
<div class="row">
  <h4 class="titoletti">Comunicazioni</h4>
</div>
    <div class="row">

        <app-news
          [news]="ne"
          class="col-lg-4 col-md-12 col-sm-12 col-xs-12"
          *ngFor="let ne of returnedArray">
        </app-news>

    </div>   

<div class="row">
<div class="mx-auto m-4">
    <pagination
    [totalItems]="news.length"
    (pageChanged)="pageChanged($event)"
    [itemsPerPage]="3"
    [firstText]="'Prima'"
    [lastText]="'Ultima'"
    [nextText]="'Precedente'"
    [previousText]="'Successiva'">
    </pagination>
</div>
</div>
</div>