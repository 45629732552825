import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Nation } from 'src/app/models/nation';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/toast.service';
import { GeoService } from 'src/app/services/geo.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import * as moment from "moment";
import { KeyValue } from 'src/app/models/keyValue';

@Component({
  selector: 'app-edit-nation-modal',
  templateUrl: './edit-nation-modal.component.html',
  styleUrls: ['./edit-nation-modal.component.css']
})
export class EditNationModalComponent implements OnInit {
  @Input() currentNation: Nation;
  public onClose: Subject<boolean>;
  submitted: boolean = false;
  editForm: FormGroup;
  continents: KeyValue[];  
  constructor(
    private geoService: GeoService,
    private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private el: ElementRef,
    private localeService: BsLocaleService,
    private toastr: ToastService
  ) {
    this.localeService.use("it");    
  }

  ngOnInit() {
    this.geoService.getContinents().subscribe(res => {
      this.continents = res;
    });
    this.editForm = this.initializeFormGroup();
    this.onClose = new Subject();
  }

  initializeFormGroup() {
    return this.formBuilder.group({
      id: [this.currentNation.id],
      name: [this.currentNation.name, [Validators.required, Validators.maxLength(255)]],
      atCode: [
        this.currentNation.atCode,
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)]
      ],
      istatCode: [
        this.currentNation.istatCode,
        [Validators.required, Validators.minLength(3), Validators.maxLength(3)]
      ],
      citizenship: [
        this.currentNation.citizenship,
        [Validators.required, Validators.maxLength(255)]
      ],
      isoCode: [
        this.currentNation.isoCode,
        [Validators.required, Validators.minLength(3), Validators.maxLength(3)]
      ],
      continent: [this.currentNation.idContinent, [Validators.required]],
      fromDate: [
        this.currentNation.fromDate ?
          moment(this.currentNation.fromDate, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          )
        : "",
        // [Validators.required, Validators.minLength(10), Validators.maxLength(10)]
      ],
      toDate: [
        this.currentNation.toDate ?
          moment(this.currentNation.toDate, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          )
        : "",
        // [Validators.required, Validators.minLength(10), Validators.maxLength(10)]
      ]
    });
  }

  get f() {
    return this.editForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.editForm.updateValueAndValidity();
    let errors = false;
    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }
    if (this.editForm.invalid || errors) {
      console.log("invalid");
      let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
      for (let el in this.editForm.controls) {
        if (this.editForm.controls[el].invalid) {
          console.log(`set focus on ${el}`);
          if (this.editForm.controls[el] instanceof FormGroup) {
            for (let el1 in this.editForm.get(el)["controls"]) {
              if (this.editForm.get(el)["controls"][el1].invalid) {
                invalidElements = this.el.nativeElement.querySelectorAll(
                  `[formcontrolname="${el1}"]`
                );
                break;
              }
            }
          } else {
            invalidElements = this.el.nativeElement.querySelectorAll(
              `[formcontrolname="${el}"]`
            );
          }
          break;
        }
      }
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }
      return false;
    }

    const newNation: Nation = new Nation();
    newNation.id = this.f.id.value;
    newNation.name = this.f.name.value;
    newNation.atCode = this.f.atCode.value;
    newNation.istatCode = this.f.istatCode.value;
    newNation.isoCode = this.f.isoCode.value;
    newNation.idContinent = this.f.continent.value;
    newNation.citizenship = this.f.citizenship.value;
    newNation.fromDate = 
      this.f.fromDate.value ? moment(this.f.fromDate.value, "DD/MM/YYYY").format("YYYY-MM-DD") : null;
    newNation.toDate = 
      this.f.toDate.value ? moment(this.f.toDate.value, "DD/MM/YYYY").format("YYYY-MM-DD") : null;

    this.geoService.createNation(newNation).subscribe(res => {
      this.toastr.success( "Nazione aggiornata con successo!");
      this.onClose.next(true);
    }, error => {
      this.toastr.error( "Codice già presente");
    })
  }

  onChangeFromDate(value: Date) {
    value ? value.setHours(value.getHours() + 2) : null;
    this.currentNation.fromDate = value ? moment(value).format("DD/MM/YYYY") : null;
  }

  onChangeToDate(value: Date) {
    value ? value.setHours(value.getHours() + 2) : null;
    this.currentNation.toDate = value ? moment(value).format("DD/MM/YYYY") : null;
  }
}
