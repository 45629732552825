import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuSelectedService {

  public notifier = new Subject<string>();

  constructor() { }

  notify(menuName: string) {
    this.notifier.next(menuName);
  }
}
