<div [hidden]="mode!='list'">
    <hr />
    <div *ngIf="canInsert() && !filters" class="col-lg-12 mb-3"><button class="btn btn-lg btn-primary btn-block"
         type="button" (click)="onInsert();">
      Inserisci Nuovo Finanziamento
    </button></div>

    <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptions">

        <ng-template #extraFilterTemplate>
            <form class="form-inline justify-content-center px-0 px-sm-3">
                <div>
                    <label class="mb-sm-2 mb-md-0">Rilevante PNRR:&nbsp;</label>

                    <ng-select style="letter-spacing: -0.5px;" class="" [items]="itemsComboFilterPnrr" bindValue="id"
                      bindLabel="descrizione" placeholder="" (change)="filterRilevantePnrr($event)">
                      <ng-template ng-option-tmp let-item="item">
                        <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
                      </ng-template>
                    </ng-select>
                <label class="mb-sm-2 mb-md-0">Aree gestionali direzione e/o esterne:&nbsp;</label>

                <ng-select style="letter-spacing: -0.5px; min-width: 330px;" class="" [items]="areeTematicheCombo" bindValue="id" bindLabel="descrizione" placeholder="Seleziona area gestionale direzione e/o esterna " (change)="filterAreaTematica($event)">
                    <ng-template ng-option-tmp let-item="item">
                        <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
                    </ng-template>
                </ng-select>
                </div>
            </form>

        </ng-template>

        <ng-template #btnEditTemplate let-row="row" let-value="value">
            <a [ngClass]="{
                'disabled': loading
                }" class=" border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top" title="{{isReadOnly(row)?'dettaglio':'modifica dati'}}">&nbsp;&nbsp;&nbsp;<i class="far pointer"
          [ngClass]="{'fa-edit': !isReadOnly(row),'fa-eye': isReadOnly(row),'detail-icon': isReadOnly(row)}"></i></a>

        </ng-template>
        <ng-template #btnDeleteTemplate let-row="row" let-value="value">
            <a [hidden]="isReadOnly(row) || !canDelete()" [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
        </ng-template>
        <ng-template #annualitaFieldValueTemplate let-row="row" let-value="value" let-index="index">
            <table class="w-100 table table-borderless" id="detail_importi_{{row.id}}">
                <tr class="collapse trdetail" *ngFor="let val of sortImporti(row.annualita)">

                    <td class="pt-0 pb-1 pl-0 pr-1">{{val.anno}}:</td>
                    <td class="pt-0 pb-1 text-0083e0 pl-0">{{printValueAsCurrency(val.importo)}}</td>
                    <tr>
                        <td class="pt-0 pb-1 pl-0 pr-1">
                            <a class="show-detail-anchor collapse show" data-toggle="collapse" attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i
                class="fas fa-list-ul pointer"></i></a>                            
                        </td>
                        <td class="pt-0 pb-1 pl-0">{{ printValueAsCurrency(value)}}</td>
                    </tr>
            </table>
        </ng-template>
        <ng-template #disponibilitaFieldValueTemplate let-row="row" let-value="value" let-index="index">
            <table class="w-100 table table-borderless" id="detail_importi_{{row.id}}">
                <tr class="collapse trdetail" *ngFor="let val of sortImporti(row.annualita)">

                    <td class="pt-0 pb-1 pl-0 pr-1">{{val.anno}}:</td>
                    <td class="pt-0 pb-1 text-0083e0 pl-0">{{printValueAsCurrency(val.disponibilita)}}</td>
                    <tr>
                        <td class="pt-0 pb-1 pl-0 pr-1">
                            <a class="show-detail-anchor collapse show" data-toggle="collapse" attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i
                class="fas fa-list-ul pointer"></i></a>
                        </td>
                        <td class="pt-0 pb-1 pl-0">{{ printValueAsCurrency(value)}}</td>
                    </tr>
            </table>
        </ng-template>
        <ng-template #fonteStrumentoAttuativoValueTemplate let-row="row" let-value="value" let-index="index">
            <span class="text-lowercase">{{row.tipoFinanziamento?row.tipoFinanziamento.tipoFinanziamento:""}}</span><br />
            <span class="text-lowercase">{{row.tipoStrumentoAttuativo?row.tipoStrumentoAttuativo.descrizione:""}}</span>
        </ng-template>
        <ng-template #direzioneFieldValueTemplate let-row="row" let-value="value">
            <ul class="pl-2 text-lowercase" *ngIf="value && value !=''">
                <li *ngFor="let val of printDirezioneFieldValue(row.areeTematiche)">{{val}}</li>
            </ul>
        </ng-template>
        <ng-template #fieldNoteTemplate let-row="row" let-value="value">
            <i class="fas font-size-18 text-secondary" [ngClass]="{
                      ' fa-comment-slash': !value,
                      ' fa-comment text-info help px-1': value
                    }" popover="{{value}}" placement="left" triggers="mouseenter:mouseleave"></i>
        </ng-template>

        <ng-template #btnCheckTemplate let-row="row" let-value="value">
            <a class=" border-0 text-center" (click)="onRichiediValidazione(row)" data-toggle="tooltip" data-placement="top" title="{{printBtnValidazioneTitle(value) }}">
                <i class=" font-size-18  pointer" [ngClass]="{
                    'text-danger': value=='FABBISOGNO',
                    'text-success': value=='APPROVED',
                    'fa-check-circle': value=='APPROVED',
                    'fa-ban': value=='REJECTED',
                    'text-warning': value =='WAITING_FOR_APPROVAL_FIRST_LEVEL' || value =='WAITING_FOR_APPROVAL_SECOND_LEVEL',
                    'fa-pause-circle': value =='WAITING_FOR_APPROVAL_FIRST_LEVEL' || value =='WAITING_FOR_APPROVAL_SECOND_LEVEL',
                    'fa-adjust': value=='IBRIDO',
                    'text-dark': value=='IBRIDO',
                    'text-dark': (value=='IBRIDO' || value=='IDLE'),
                    'fa-circle': ( (value !='IBRIDO'
                    && value!='REJECTED'
                    && value!='APPROVED'
                    && value!='WAITING_FOR_APPROVAL_FIRST_LEVEL'
                    && value!='WAITING_FOR_APPROVAL_SECOND_LEVEL'
                    ))
                    ,'far': !value || value==''
                    ,'fas': value && value!=''
                    }">
        </i></a>
        </ng-template>
    </ngx-datatable-wrapper>
    <div class="bold text-center mb-3">Legenda:</div>
    <div class="d-flex justify-content-around mb-5 flex-wrap">
        <div><i class="far fa-circle font-size-18 no-hover"></i><span>&nbsp;&nbsp;vuoto</span></div>
        <div><i class="fas fa-pause-circle font-size-18 text-warning no-hover"></i><span>&nbsp;&nbsp;in attesa di
        validazione</span></div>
        <div><i class="fas fa-check-circle font-size-18 text-success no-hover"></i><span>&nbsp;&nbsp;validato</span></div>
        <div><i class="fas fa-ban font-size-18 no-hover"></i><span>&nbsp;&nbsp;rifiutato</span></div>
    </div>
</div>

<div class="container" [hidden]="mode!='insertedit'">
    <finanziamento-component #finanziamentoComponent [finanziamentoId]="finanziamentoId" [gotoBtnOptions]="{show:true,label:'Torna a elenco finanziamenti'}" (gotoBtnFunction)="gotoBackList($event)" >
    </finanziamento-component>
</div>





<ng-template #modalConfirmDelete>
    <div class="modal-header  text-center">
        <h4 class="modal-title pull-left">Conferma cancellazione finanziamento</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeConfirmDeleteModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="deleteForm">
            <div class="col-lg-12 mt-3">
                <p>Inserire la motivazione per l'eliminazione del finanziamento {{deleteRowTitle}}.</p>

                <div class="form-group">

                    <label for="tipologia">
                  <h5>Motivazione:</h5>
                </label>
                    <div [ngClass]="{ 'is-invalid': submitted && deleteForm.controls.motivazione.errors }">
                        <ng-select [items]="motivazioniCancellazione" bindValue="descrizione" bindLabel="descrizione" placeholder="Seleziona motivazione " formControlName="motivazione" (change)="setMotivazioneCancellazione($event)">
                        </ng-select>
                    </div>
                    <div *ngIf="submitted && deleteForm.controls.motivazione.errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="deleteForm.controls.motivazione.errors.required">
                            Campo obbligatorio
                        </div>
                    </div>




                </div>
                <div *ngIf="motivazioneCancellazioneSelected=='Altro'" class="form-group">
                    <label><h5>Altro:</h5></label>
                    <div>
                        <textarea maxlength="255" class="form-control" [ngClass]="{
                    'is-invalid': submitted && deleteForm.controls.txtMotivazioneCancellazione.errors
                    }" [formGroup]="deleteForm" rows="5" formControlName="txtMotivazioneCancellazione"></textarea>
                        <div *ngIf="submitted && deleteForm.controls.txtMotivazioneCancellazione.errors" class="invalidFieldMessage" style="display: block;">
                            <div *ngIf="deleteForm.controls.txtMotivazioneCancellazione.errors" class="invalid-feedback" style="display: block;">
                                Campo obbligatorio
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row justify-content-center">
                <button [disabled]="loading" (click)="doDelete()" class=" btn btn-lg btn-danger mx-1">
                          <img *ngIf="loading " class="pl-3 float-right "
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                          Elimina
                        </button>
                <button [disabled]="loading" (click)="closeConfirmDeleteModal();" class="  btn btn-lg btn-secondary mx-1">
                          <img *ngIf="loading " class="pl-3 float-right "
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                          Annulla
                        </button>
            </div>
        </form>
    </div>








</ng-template>
