import { Injectable } from "@angular/core";
import {
  HttpClient,  
} from "@angular/common/http";
import { AppConfigService } from "./app-config.service";
import { UploadFileResponse } from "../models/upload-file-response";

@Injectable({
  providedIn: "root"
})
export class UploadService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.file}`;

  constructor(private http: HttpClient) {}

  uploadDocuments(form: FormData) {
    return this.http.post<UploadFileResponse>(`${this.apiUrl}documents`, form);
  }

  deleteDocuments(username: string) {
    return this.http.delete<boolean>(`${this.apiUrl}documents/${username}`);
  }

  uploadFiles(form: FormData) {
    return this.http.post<UploadFileResponse>(`${this.apiUrl}uploadFile`, form);
  }

  getFile(uploadId: string, number: string, username: string) {
    return this.http.get(
      `${this.apiUrl}uploads/${username}/${uploadId}/files/${number}`,
      {
        observe: "response",
        responseType: "blob"
      }
    );
  }

  getSummary() {
    return this.http.get<number[]>(`${this.apiUrl}summary`);
  }
}
