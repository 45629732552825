<ng-template #templateEditAdd>
  <div id="tabs" #tabs class="sticky-top ">
    <ul class="nav nav-tabs bg-white pt-4 pb-1" style="z-index:3000">
      <li
        *ngIf="(!isEdit && checkPermission('insert')) || (isEdit && checkPermissions(['read', 'update'], 'PNRR - Linee di Finanziamento - Dati Generali', true))" class="mt-1 px-1 nav-item ">
        <a [ngClass]="{ 'active':activeTab==='tabDatiGenerali' } " (click)="confirmChangeTab($event,0); "
          class="tab-menu nav-link px-1 " href="#divDatiGenerali" data-toggle="tab">Dati generali</a>
      </li>
      <li
        [hidden]="!this.addNationForm.controls.id.value || !this.addNationForm.controls.id.value=='-1' || !checkPermission('update', 'PNRR - Linee di Finanziamento - Dati di Servizio')" class="mt-1 px-1 nav-item ">
        <a [ngClass]="{ 'active':activeTab==='tabDatiServizio' } " (click)="confirmChangeTab($event,2); "
          class="tab-menu nav-link px-1 " href="#divDatiServizio" data-toggle="tab">Dati di servizio</a>
      </li>
      <li class="mt-1 px-1 nav-item"
        [hidden]="!this.addNationForm.controls.id.value || !this.addNationForm.controls.id.value=='-1' || !checkPermissions(['read', 'update'], 'PNRR - Linee di Finanziamento - Fasi', true)">
        <a [ngClass]="{ 'active':activeTab==='tabFasi' } " (click)="confirmChangeTab($event,1); "
          class="tab-menu nav-link px-1 " href="#divFasi" data-toggle="tab">Fasi</a>
      </li>

      <li class="mt-1 px-1 nav-item"
          [hidden]="!this.addNationForm.controls.id.value || !this.addNationForm.controls.id.value=='-1' || !isAdmin()"
          [ngClass]="{ 'active':activeTab==='tabStorico'}">
        <a (click)="confirmChangeTab($event,3);" [ngClass]="{ 'active':activeTab==='tabStorico'}" class="tab-menu nav-link px-1" href="#divTemplateStorico" data-toggle="tab">Storico</a>
      </li>
    </ul>
  </div>

  <div class="tab-content ">
    <div class="tab-pane " id="divDatiServizio" [ngClass]="{ 'active':activeTab==='tabDatiServizio'  } "
      #divDatiServizio>
      <form [formGroup]="formDatiServizio" (ngSubmit)="onSubmitServizio()" class="col-lg-12 mt-5">
        <input type="hidden" [formGroup]="formDatiServizio" formControlName="id" />
        <div class="row">
          <h4>Risorse Destinate</h4>
        </div>
        <div class="row mt-3">
          <div class="form-group col-lg-6">
            <label for="valoreIpotesiDestinato">
              <h5 class="">Ripartito Nazionale:</h5>
            </label>
            <input [formGroup]="formDatiServizio" formControlName="valoreIpotesiDestinato" maxlength="255" type="text"
              class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                              'is-invalid': submitted && formDatiServizio.controls.valoreIpotesiDestinato.errors
                              }" [imask]="mask" [unmask]="true" />
            <div *ngIf="submitted && formDatiServizio.controls.valoreIpotesiDestinato.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="formDatiServizio.controls.valoreIpotesiDestinato.errors">
                Campo obbligatorio
              </div>
            </div>

          </div>

          <div class="form-group col-lg-6">
            <label for="valoreRipartitoRegione">
              <h5 class="">Ripartito Regione Lazio (Territorio):</h5>
            </label>
            <input [formGroup]="formDatiServizio" formControlName="valoreRipartitoRegione" maxlength="255" type="text"
              class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                                'is-invalid': submitted && formDatiServizio.controls.valoreRipartitoRegione.errors
                                }" [imask]="mask" [unmask]="true" />
            <div *ngIf="submitted && formDatiServizio.controls.valoreRipartitoRegione.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="formDatiServizio.controls.valoreRipartitoRegione.errors">
                Campo obbligatorio
              </div>
            </div>

          </div>
        </div>

        <div class="col-lg-12 mt-5">
          <hr>
        </div>
        <div class="row">
          <h4>Strumenti di Assegnazione</h4>
        </div>
        <div class="row mt-3">
          <div class="col-lg-12">
            <accordion [isAnimated]="true">
              <accordion-group heading="DECRETO DI RIPARTO">
                <div class="row">
                  <div class="form-group col-lg-6">
                    <label for="decretoDiRipartoDaFare">
                      <h5 class="">Da fare:</h5>
                    </label>
                    <input [formGroup]="formDatiServizio" formControlName="decretoDiRipartoDaFare" maxlength="255"
                      type="text" class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                    'is-invalid': submitted && formDatiServizio.controls.decretoDiRipartoDaFare.errors
                    }" />
                    <div *ngIf="submitted && formDatiServizio.controls.decretoDiRipartoDaFare.errors"
                      class="invalid-feedback" style="display: block;">
                      <div *ngIf="formDatiServizio.controls.decretoDiRipartoDaFare.errors">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-lg-6">
                    <label for="decretoDiRipartoAssegnato">
                      <h5 class="">Assegnato:</h5>
                    </label>
                    <input [formGroup]="formDatiServizio" formControlName="decretoDiRipartoAssegnato" maxlength="255"
                      type="text" class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                      'is-invalid': submitted && formDatiServizio.controls.decretoDiRipartoAssegnato.errors
                      }" />
                    <div *ngIf="submitted && formDatiServizio.controls.decretoDiRipartoAssegnato.errors"
                      class="invalid-feedback" style="display: block;">
                      <div *ngIf="formDatiServizio.controls.decretoDiRipartoAssegnato.errors">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                </div>
              </accordion-group>
            </accordion>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-lg-12">
            <accordion [isAnimated]="true">
              <accordion-group heading="AVVISO PER BANDO REGIONALE">
                <div class="row">
                  <div class="form-group col-lg-6">
                    <label for="bandoRegionaleDaRipartire">
                      <h5 class="">Da ripartire:</h5>
                    </label>
                    <input [formGroup]="formDatiServizio" formControlName="bandoRegionaleDaRipartire" maxlength="255"
                      type="text" class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                      'is-invalid': submitted && formDatiServizio.controls.bandoRegionaleDaRipartire.errors
                      }" />
                    <div *ngIf="submitted && formDatiServizio.controls.bandoRegionaleDaRipartire.errors"
                      class="invalid-feedback" style="display: block;">
                      <div *ngIf="formDatiServizio.controls.bandoRegionaleDaRipartire.errors">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-lg-6">
                    <label for="bandoRegionaleRipartito">
                      <h5 class="">Ripartito:</h5>
                    </label>
                    <input [formGroup]="formDatiServizio" formControlName="bandoRegionaleRipartito" maxlength="255"
                      type="text" class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                        'is-invalid': submitted && formDatiServizio.controls.bandoRegionaleRipartito.errors
                        }" />
                    <div *ngIf="submitted && formDatiServizio.controls.bandoRegionaleRipartito.errors"
                      class="invalid-feedback" style="display: block;">
                      <div *ngIf="formDatiServizio.controls.bandoRegionaleRipartito.errors">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-lg-6">
                    <label for="bandoRegionaleBandito">
                      <h5 class="">Bandito:</h5>
                    </label>
                    <input [formGroup]="formDatiServizio" formControlName="bandoRegionaleBandito" maxlength="255"
                      type="text" class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                      'is-invalid': submitted && formDatiServizio.controls.bandoRegionaleBandito.errors
                      }" />
                    <div *ngIf="submitted && formDatiServizio.controls.bandoRegionaleBandito.errors"
                      class="invalid-feedback" style="display: block;">
                      <div *ngIf="formDatiServizio.controls.bandoRegionaleBandito.errors">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-lg-6">
                    <label for="bandoRegionaleAssegnato">
                      <h5 class="">Assegnato:</h5>
                    </label>
                    <input [formGroup]="formDatiServizio" formControlName="bandoRegionaleAssegnato" maxlength="255"
                      type="text" class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                        'is-invalid': submitted && formDatiServizio.controls.bandoRegionaleAssegnato.errors
                        }" />
                    <div *ngIf="submitted && formDatiServizio.controls.bandoRegionaleAssegnato.errors"
                      class="invalid-feedback" style="display: block;">
                      <div *ngIf="formDatiServizio.controls.bandoRegionaleAssegnato.errors">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                </div>
              </accordion-group>
            </accordion>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-12">
            <accordion [isAnimated]="true">
              <accordion-group heading="BANDO MINISTERIALE">
                <div class="row">
                  <div class="form-group col-lg-6">
                    <label for="bandoMinisterialeDaBandire">
                      <h5 class="">Da bandire:</h5>
                    </label>
                    <input [formGroup]="formDatiServizio" formControlName="bandoMinisterialeDaBandire" maxlength="255"
                      type="text" class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                      'is-invalid': submitted && formDatiServizio.controls.bandoMinisterialeDaBandire.errors
                      }" />
                    <div *ngIf="submitted && formDatiServizio.controls.bandoMinisterialeDaBandire.errors"
                      class="invalid-feedback" style="display: block;">
                      <div *ngIf="formDatiServizio.controls.bandoMinisterialeDaBandire.errors">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-lg-6">
                    <label for="bandoMinisterialeBandito">
                      <h5 class="">Bandito:</h5>
                    </label>
                    <input [formGroup]="formDatiServizio" formControlName="bandoMinisterialeBandito" maxlength="255"
                      type="text" class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                        'is-invalid': submitted && formDatiServizio.controls.bandoMinisterialeBandito.errors
                        }" />
                    <div *ngIf="submitted && formDatiServizio.controls.bandoMinisterialeBandito.errors"
                      class="invalid-feedback" style="display: block;">
                      <div *ngIf="formDatiServizio.controls.bandoMinisterialeBandito.errors">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-lg-6">
                    <label for="bandoMinisterialeAssegnato">
                      <h5 class="">Assegnato:</h5>
                    </label>
                    <input [formGroup]="formDatiServizio" formControlName="bandoMinisterialeAssegnato" maxlength="255"
                      type="text" class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                      'is-invalid': submitted && formDatiServizio.controls.bandoMinisterialeAssegnato.errors
                      }" />
                    <div *ngIf="submitted && formDatiServizio.controls.bandoMinisterialeAssegnato.errors"
                      class="invalid-feedback" style="display: block;">
                      <div *ngIf="formDatiServizio.controls.bandoMinisterialeAssegnato.errors">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>
                </div>
              </accordion-group>
            </accordion>
          </div>
        </div>

        <div class="col-lg-12 mt-5">
          <hr>
        </div>
        <div class="row">
          <h4 class="">Ruolo (teorico/stimato) della regione nella programmazione/attuazione dell'investimento
          </h4>
        </div>

        <div class="row">
          <div class="form-group col-lg-12">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                <label for="programmazione">
                  <h5 class="">Programmazione:</h5>
                </label>
                <div>
                  <div class="form-check  form-check-inline">

                    <input [formGroup]="formDatiServizio" id="programmazione1" formControlName="programmazione"
                      type="radio" value="1" class="form-check-input" [ngClass]="{
                                                  'is-invalid': submitted && formDatiServizio.controls.programmazione.errors
                                                  }" />

                    <label for="programmazione1" class="form-check-label">Si</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input [formGroup]="formDatiServizio" id="programmazione2" formControlName="programmazione"
                      type="radio" value="0" class="form-check-input" [ngClass]="{
                                                                'is-invalid': submitted && formDatiServizio.controls.programmazione.errors
                                                                }" />
                    <label for="programmazione2" class="form-check-label">No</label>
                  </div>
                </div>


                <div *ngIf="submitted && formDatiServizio.controls.programmazione.errors" class="invalidFieldMessage"
                  style="display: block;">
                  <div *ngIf="formDatiServizio.controls.programmazione.errors" class="invalid-feedback"
                    style="display: block;">
                    Campo obbligatorio
                  </div>
                </div>
              </div>
              <!--fine programmazione-->
              <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                <label for="beneficiarioDiretto">
                  <h5 class="">Beneficiario diretto:</h5>
                </label>
                <div>
                  <div class="form-check  form-check-inline">

                    <input [formGroup]="formDatiServizio" id="beneficiarioDiretto1"
                      formControlName="beneficiarioDiretto" type="radio" value="1" class="form-check-input" [ngClass]="{
                                        'is-invalid': submitted && formDatiServizio.controls.beneficiarioDiretto.errors
                                        }" />

                    <label for="beneficiarioDiretto1" class="form-check-label">Si</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input [formGroup]="formDatiServizio" id="beneficiarioDiretto2"
                      formControlName="beneficiarioDiretto" type="radio" value="0" class="form-check-input" [ngClass]="{
                                                      'is-invalid': submitted && formDatiServizio.controls.beneficiarioDiretto.errors
                                                      }" />
                    <label for="beneficiarioDiretto2" class="form-check-label">No</label>
                  </div>
                </div>


                <div *ngIf="submitted && formDatiServizio.controls.beneficiarioDiretto.errors"
                  class="invalidFieldMessage" style="display: block;">
                  <div *ngIf="formDatiServizio.controls.beneficiarioDiretto.errors" class="invalid-feedback"
                    style="display: block;">
                    Campo obbligatorio
                  </div>
                </div>
              </div>
              <!--fine beneficiarioDiretto-->
              <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                <label for="beneficiarioDirettoDescrizione">
                  <h5 class=""></h5>
                </label>
                <div>
                  <input [formGroup]="formDatiServizio" formControlName="beneficiarioDirettoDescrizione" maxlength="255"
                    type="text" class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                                      'is-invalid': submitted && formDatiServizio.controls.beneficiarioDirettoDescrizione.errors
                                      }" />

                </div>


                <div *ngIf="submitted && formDatiServizio.controls.beneficiarioDirettoDescrizione.errors"
                  class="invalidFieldMessage" style="display: block;">
                  <div *ngIf="formDatiServizio.controls.beneficiarioDirettoDescrizione.errors" class="invalid-feedback"
                    style="display: block;">
                    Campo obbligatorio
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="form-group col-lg-12">
            <div class="row">

                <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                    <label for="impattoTerritoriale">
                      <h5 class="">Impatto territoriale:</h5>
                    </label>
                    <div>
                      <div class="form-check  form-check-inline">

                        <input [formGroup]="formDatiServizio" id="impattoTerritoriale1" formControlName="impattoTerritoriale" type="radio"
                          value="1" class="form-check-input" [ngClass]="{
                                                        'is-invalid': submitted && formDatiServizio.controls.impattoTerritoriale.errors
                                                        }" />

                        <label for="impattoTerritoriale1" class="form-check-label">Si</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input [formGroup]="formDatiServizio" id="impattoTerritoriale2" formControlName="impattoTerritoriale" type="radio"
                          value="0" class="form-check-input" [ngClass]="{
                                                                      'is-invalid': submitted && formDatiServizio.controls.impattoTerritoriale.errors
                                                                      }" />
                        <label for="impattoTerritoriale2" class="form-check-label">No</label>
                      </div>
                    </div>


                    <div *ngIf="submitted && formDatiServizio.controls.impattoTerritoriale.errors" class="invalidFieldMessage"
                      style="display: block;">
                      <div *ngIf="formDatiServizio.controls.impattoTerritoriale.errors" class="invalid-feedback"
                        style="display: block;">
                        Campo obbligatorio
                      </div>
                    </div>
                  </div>

              <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                <label for="coProgrammazione">
                  <h5 class="">Co-programmazione:</h5>
                </label>
                <div>
                  <div class="form-check  form-check-inline">

                    <input [formGroup]="formDatiServizio" id="coProgrammazione1" formControlName="coProgrammazione"
                      type="radio" value="1" class="form-check-input" [ngClass]="{
                                          'is-invalid': submitted && formDatiServizio.controls.coProgrammazione.errors
                                          }" />

                    <label for="coProgrammazione1" class="form-check-label">Si</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input [formGroup]="formDatiServizio" id="coProgrammazione2" formControlName="coProgrammazione"
                      type="radio" value="0" class="form-check-input" [ngClass]="{
                                                        'is-invalid': submitted && formDatiServizio.controls.coProgrammazione.errors
                                                        }" />
                    <label for="coProgrammazione2" class="form-check-label">No</label>
                  </div>
                </div>


                <div *ngIf="submitted && formDatiServizio.controls.coProgrammazione.errors" class="invalidFieldMessage"
                  style="display: block;">
                  <div *ngIf="formDatiServizio.controls.coProgrammazione.errors" class="invalid-feedback"
                    style="display: block;">
                    Campo obbligatorio
                  </div>
                </div>
              </div>
              <!--fine coProgrammazione-->
              <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                <label for="coProgrammazioneDescrizione">
                  <h5 class=""></h5>
                </label>
                <div>
                  <input [formGroup]="formDatiServizio" formControlName="coProgrammazioneDescrizione" maxlength="255"
                    type="text" class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                                    'is-invalid': submitted && formDatiServizio.controls.coProgrammazioneDescrizione.errors
                                    }" />

                </div>


                <div *ngIf="submitted && formDatiServizio.controls.coProgrammazioneDescrizione.errors"
                  class="invalidFieldMessage" style="display: block;">
                  <div *ngIf="formDatiServizio.controls.coProgrammazioneDescrizione.errors" class="invalid-feedback"
                    style="display: block;">
                    Campo obbligatorio
                  </div>
                </div>
              </div>
              <!--fine coProgrammazione-->
            </div>
          </div>

        </div>

        <div class="row">
          <div class="form-group col-lg-12">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                <label for="proponente">
                  <h5 class="">Proponente:</h5>
                </label>
                <div>
                  <div class="form-check  form-check-inline">

                    <input [formGroup]="formDatiServizio" id="proponente1" formControlName="proponente" type="radio"
                      value="1" class="form-check-input" [ngClass]="{
                                                    'is-invalid': submitted && formDatiServizio.controls.proponente.errors
                                                    }" />

                    <label for="proponente1" class="form-check-label">Si</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input [formGroup]="formDatiServizio" id="proponente2" formControlName="proponente" type="radio"
                      value="0" class="form-check-input" [ngClass]="{
                                                                  'is-invalid': submitted && formDatiServizio.controls.proponente.errors
                                                                  }" />
                    <label for="proponente2" class="form-check-label">No</label>
                  </div>
                </div>


                <div *ngIf="submitted && formDatiServizio.controls.proponente.errors" class="invalidFieldMessage"
                  style="display: block;">
                  <div *ngIf="formDatiServizio.controls.proponente.errors" class="invalid-feedback"
                    style="display: block;">
                    Campo obbligatorio
                  </div>
                </div>
              </div>


              <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                <label for="attuazione">
                  <h5 class="">Attuazione:</h5>
                </label>
                <div>
                  <div class="form-check  form-check-inline">

                    <input [formGroup]="formDatiServizio" id="attuazione1" formControlName="attuazione" type="radio"
                      value="1" class="form-check-input" [ngClass]="{
                                                    'is-invalid': submitted && formDatiServizio.controls.attuazione.errors
                                                    }" />

                    <label for="attuazione1" class="form-check-label">Si</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input [formGroup]="formDatiServizio" id="attuazione2" formControlName="attuazione" type="radio"
                      value="0" class="form-check-input" [ngClass]="{
                                                                  'is-invalid': submitted && formDatiServizio.controls.attuazione.errors
                                                                  }" />
                    <label for="attuazione2" class="form-check-label">No</label>
                  </div>
                </div>


                <div *ngIf="submitted && formDatiServizio.controls.attuazione.errors" class="invalidFieldMessage"
                  style="display: block;">
                  <div *ngIf="formDatiServizio.controls.attuazione.errors" class="invalid-feedback"
                    style="display: block;">
                    Campo obbligatorio
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                <label for="attuazioneDescrizione">
                  <h5 class=""></h5>
                </label>
                <div>
                  <input [formGroup]="formDatiServizio" formControlName="attuazioneDescrizione" maxlength="255"
                    type="text" class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                                    'is-invalid': submitted && formDatiServizio.controls.attuazioneDescrizione.errors
                                    }" />

                </div>


                <div *ngIf="submitted && formDatiServizio.controls.attuazioneDescrizione.errors"
                  class="invalidFieldMessage" style="display: block;">
                  <div *ngIf="formDatiServizio.controls.attuazioneDescrizione.errors" class="invalid-feedback"
                    style="display: block;">
                    Campo obbligatorio
                  </div>
                </div>
              </div>
              <!--fine attuazione-->


            </div>
          </div>

        </div>



        <!--note di servizio-->
        <div class="row mt-3">
          <div class="form-group col-lg-12">
            <label for="noteDatiServizio">
              <h5 class="">Note:</h5>
            </label>
            <textarea [formGroup]="formDatiServizio"
              [ngClass]="{ 'is-invalid': submitted && formDatiServizio.controls.noteDatiServizio.errors }"
              formControlName="noteDatiServizio" rows="5" cols="20" class="form-control"
              placeholder="Inserisci le note"></textarea>

            <div *ngIf="submitted && formDatiServizio.controls.noteDatiServizio.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="formDatiServizio.controls.noteDatiServizio.errors">
                Campo obbligatorio
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 mt-5">
          <hr>
        </div>
        <div class="text-center mt-3 my-3">
          <button class="btn btn-primary col-lg-4 " type="submit">
            Salva
          </button>
        </div>
      </form>
    </div>



    <div class="tab-pane " id="divDatiGenerali" [ngClass]="{ 'active':activeTab==='tabDatiGenerali'  } "
      #divDatiGenerali>


      <form #formDatiGenerali [formGroup]="addNationForm" (ngSubmit)="onSubmit()" class="col-lg-12 mt-5">
        <input type="hidden" [formGroup]="addNationForm" formControlName="id" />

        <div class="row">
          <div class="form-group col-xl-12 col-lg-12">
            <label for="missione">
              <h5 class="mandatory">
                Missione
              </h5>
            </label>
            <div class="">
              <ng-select [formGroup]="addNationForm" formControlName="missione" [items]="missioni" bindValue="id"
                placeholder="Seleziona missione" bindValue="id" bindLabel="descrizione" [ngClass]="{
    'is-invalid': submitted && f.missione.errors
  }" (change)="missioneChange($event)">
              </ng-select>
              <div *ngIf="submitted && f.missione.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.missione.errors">
                  campo obbligatorio
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-xl-12 col-lg-12">
            <label for="componente">
              <h5 class="mandatory">
                Componente
              </h5>
            </label>
            <div class="">
              <ng-select [formGroup]="addNationForm" formControlName="componente" [items]="componenti" bindValue="id"
                placeholder="Seleziona componente" bindValue="id" bindLabel="descrizione" [ngClass]="{
    'is-invalid': submitted && f.componente.errors
  }" (change)="resetCodice($event)">
              </ng-select>
              <div *ngIf="submitted && f.componente.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.componente.errors">
                  campo obbligatorio
                </div>
              </div>
            </div>
          </div>
        </div>


        <!---->

        <div class="form-row">
          <div class="form-group col-5 col-md-4 pr-0">
            <label for="codiceSecondaParte">
              <h5 class="mandatory">&nbsp;Codice:</h5>
            </label>
            <input [formGroup]="addNationForm" formControlName="codicePrimaParte" type="text" value=""
              class="form-control d-inline-block  rounded-0 border-right-0 text-right" style="border-top-left-radius: 0.25rem!important;
            border-bottom-left-radius: 0.25rem!important;" readonly />
          </div>
          <div class="form-group col-7 col-md-8 align-self-end pl-0">

            <input [formGroup]="addNationForm" formControlName="codiceSecondaParte" type="text" value=""
              class="form-control  d-inline-block rounded-0 border-left-0" style="border-top-right-radius: 0.25rem!important;
    border-bottom-Right-radius: 0.25rem!important;" placeholder="Inserisci il codice" [ngClass]="{
              'is-invalid': submitted && f.codiceSecondaParte.errors
            }" />
          </div>
          <div *ngIf="submitted && f.codiceSecondaParte.errors" class="invalid-feedback" style="display: block;">
            <div *ngIf="f.codiceSecondaParte.errors">
              Campo obbligatorio
            </div>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-xl-12 col-lg-12">
            <h5><label for="descrizione" class="mandatory">Descrizione:</label></h5>
            <div class="">
              <input [formGroup]="addNationForm" formControlName="descrizione" type="text" value="" class="form-control"
                placeholder="Inserisci la descrizione" [ngClass]="{
              'form-control': true,
              'is-invalid': submitted && f.descrizione.errors
            }" />
              <div *ngIf="submitted && f.descrizione.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.descrizione.errors">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-xl-12 col-lg-12">
            <h5><label for="misuraPnrr" class="mandatory">Misura PNRR:</label></h5>
            <div class="">
              <input [formGroup]="addNationForm" formControlName="misuraPnrr" type="text" value="" class="form-control"
                placeholder="Inserisci la misura PNRR" [ngClass]="{
                'form-control': true,
                'is-invalid': submitted && f.misuraPnrr.errors
              }" />
              <div *ngIf="submitted && f.misuraPnrr.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.misuraPnrr.errors">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-xl-12 col-lg-12">
            <label for="tipologia">
              <h5 class="mandatory">
                Tipologia
              </h5>
            </label>
            <div class="">
              <ng-select [formGroup]="addNationForm" formControlName="tipologia" [items]="tipologie" bindValue="id"
                placeholder="Seleziona tipologia" bindValue="id" bindLabel="descrizione" [ngClass]="{
  'is-invalid': submitted && f.tipologia.errors
}" (change)="tipologiaOnChange()">
              </ng-select>
              <div *ngIf="submitted && f.tipologia.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.tipologia.errors">
                  campo obbligatorio
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row" [hidden]="hidepnrrLineaFinanziamentoPrincipale()">
          <div class="form-group col-xl-12 col-lg-12">
            <label for="pnrrLineaFinanziamentoPrincipale">
              <h5 class="mandatory">
                Investimento / Riforma
              </h5>
            </label>
            <div class="">
              <ng-select [formGroup]="addNationForm" formControlName="pnrrLineaFinanziamentoPrincipale"
                [items]="pnrrLineeFinanziamentoInvestimentiRiforme" bindValue="id"
                placeholder="Seleziona investimento o riforma" bindValue="id" bindLabel="descrizioneEstesa" [ngClass]="{
    'is-invalid': submitted && f.pnrrLineaFinanziamentoPrincipale.errors
  }">
              </ng-select>
              <div *ngIf="submitted && f.pnrrLineaFinanziamentoPrincipale.errors" class="invalid-feedback"
                style="display: block;">
                <div *ngIf="f.tipologia.errors">
                  campo obbligatorio
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-xl-12 col-lg-12">
            <h5><label for="amministrazioneTitolare" class="mandatory">Amministrazione titolare:</label></h5>
            <div class="">
              <div [ngClass]="{ 'is-invalid': submitted && f.amministrazioneTitolare.errors }">
                <div class="overflow-box">
                  <ng-select [items]="soggettiProponenti" bindValue="id" bindLabel="descrizione"
                    placeholder="Seleziona amministrazione titolare" [formGroup]="addNationForm"
                    formControlName="amministrazioneTitolare">
                  </ng-select>
                </div>
              </div>
              <div *ngIf="submitted && f.amministrazioneTitolare.errors" class="invalid-feedback"
                style="display: block;">
                <div *ngIf="f.amministrazioneTitolare.errors">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="form-row">

          <div class="form-group col-lg-6">
            <label for="pnrr">
              <h5 class="mandatory">Pnrr:</h5>

              <!--<div class="custom-control custom-switch">
                <input type="checkbox" role="switch" class="custom-control-input" id="customSwitchAbilitaPnrr" [formGroup]="addNationForm" formControlName="customSwitchAbilitaPnrr"  />
                <label class="custom-control-label" for="customSwitchAbilitaPnrr"></label>
            </div>-->
            </label>
            <ngx-input-switch [hidden]="!isPnrrLineaFinanziamentoPrincipale" label="" [formGroup]="addNationForm" formControlName="abilitaPnrr" (change)="abilitaPnrrChange()"></ngx-input-switch>
            <input [formGroup]="addNationForm" formControlName="pnrr" maxlength="255" type="text" class="form-control"
              placeholder="Inserisci il pnrr" [ngClass]="{
                    'is-invalid': submitted && addNationForm.controls.pnrr.errors
                    }" [imask]="mask" [unmask]="true" />
            <div *ngIf="submitted && addNationForm.controls.pnrr.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="addNationForm.controls.pnrr.errors">
                Campo obbligatorio
              </div>
            </div>

          </div>





          <div class="form-group col-lg-6 switch">
            <label for="fc">
              <h5 class="mandatory">Pnc:</h5>

            </label>
            <ngx-input-switch [hidden]="!isPnrrLineaFinanziamentoPrincipale" label="" [formGroup]="addNationForm" formControlName="abilitaFc"  (change)="abilitaFcChange()"></ngx-input-switch>
            <input [formGroup]="addNationForm" formControlName="fc" maxlength="255" type="text" class="form-control"
              placeholder="Inserisci il valore del pnc" [ngClass]="{
                    'is-invalid': submitted && addNationForm.controls.fc.errors
                    }" [imask]="mask" [unmask]="true" />
            <div *ngIf="submitted && addNationForm.controls.fc.errors" class="invalid-feedback" style="display: block;">
              <div *ngIf="addNationForm.controls.fc.errors">
                Campo obbligatorio
              </div>
            </div>

          </div>

        </div>


        <div class="row">
          <div class="form-group col-xl-12 col-lg-12">
            <h5><label for="fonte" class="mandatory">Fonte:</label></h5>
            <div class="">
              <input [formGroup]="addNationForm" formControlName="fonte" type="text" value="" class="form-control"
                placeholder="Inserisci la fonte" [ngClass]="{
                'form-control': true,
                'is-invalid': submitted && f.fonte.errors
              }" />
              <div *ngIf="submitted && f.fonte.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.fonte.errors">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="row">
          <div class="form-group col-lg-6">
            <label for="quotaAssegnataPnrr">
              <h5 class="">Quota assegnata Pnrr:</h5>
            </label>
            <input readonly [formGroup]="addNationForm" formControlName="quotaAssegnataPnrr" maxlength="255" type="text"
              class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                          'is-invalid': submitted && addNationForm.controls.quotaAssegnataPnrr.errors
                          }" [imask]="mask" [unmask]="true" />
            <div *ngIf="submitted && addNationForm.controls.quotaAssegnataPnrr.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="addNationForm.controls.quotaAssegnataPnrr.errors">
                Campo obbligatorio
              </div>
            </div>

          </div>
          <div class="form-group col-lg-6">
            <label for="quotaAssegnataFc">
              <h5 class="">Quota assegnata Pnc:</h5>
            </label>
            <input readonly [formGroup]="addNationForm" formControlName="quotaAssegnataFc" maxlength="255" type="text"
              class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                          'is-invalid': submitted && addNationForm.controls.quotaAssegnataFc.errors
                          }" [imask]="mask" [unmask]="true" />
            <div *ngIf="submitted && addNationForm.controls.quotaAssegnataFc.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="addNationForm.controls.quotaAssegnataFc.errors">
                Campo obbligatorio
              </div>
            </div>

          </div>
        </div>

        <div class="row">
            <div class="form-group col-lg-6">
              <label for="quotaRipartitaPnrr">
                <h5 class="">Quota ripartita Pnrr:</h5>
              </label>
              <input readonly [formGroup]="addNationForm" formControlName="quotaRipartitaPnrr" maxlength="255" type="text"
                class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                            'is-invalid': submitted && addNationForm.controls.quotaRipartitaPnrr.errors
                            }" [imask]="mask" [unmask]="true" />
              <div *ngIf="submitted && addNationForm.controls.quotaRipartitaPnrr.errors" class="invalid-feedback"
                style="display: block;">
                <div *ngIf="addNationForm.controls.quotaRipartitaPnrr.errors">
                  Campo obbligatorio
                </div>
              </div>

            </div>
            <div class="form-group col-lg-6">
              <label for="quotaRipartitaFc">
                <h5 class="">Quota ripartita Pnc:</h5>
              </label>
              <input readonly [formGroup]="addNationForm" formControlName="quotaRipartitaFc" maxlength="255" type="text"
                class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                            'is-invalid': submitted && addNationForm.controls.quotaRipartitaFc.errors
                            }" [imask]="mask" [unmask]="true" />
              <div *ngIf="submitted && addNationForm.controls.quotaRipartitaFc.errors" class="invalid-feedback"
                style="display: block;">
                <div *ngIf="addNationForm.controls.quotaRipartitaFc.errors">
                  Campo obbligatorio
                </div>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-6">
              <label for="quotaRipartitaSarPnrr">
                <h5 class="">Quota Ripartita Pnrr Regione Lazio sog. beneficiario:</h5>
              </label>
              <input readonly [formGroup]="addNationForm" formControlName="quotaRipartitaSarPnrr" maxlength="255" type="text"
                class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                            'is-invalid': submitted && addNationForm.controls.quotaRipartitaSarPnrr.errors
                            }" [imask]="mask" [unmask]="true" />
              <div *ngIf="submitted && addNationForm.controls.quotaRipartitaSarPnrr.errors" class="invalid-feedback"
                style="display: block;">
                <div *ngIf="addNationForm.controls.quotaRipartitaSarPnrr.errors">
                  Campo obbligatorio
                </div>
              </div>

            </div>
            <div class="form-group col-lg-6">
              <label for="quotaRipartitaSarFc">
                <h5 class="">Quota Ripartita Pnc Regione Lazio sog. beneficiario:</h5>
              </label>
              <input readonly [formGroup]="addNationForm" formControlName="quotaRipartitaSarFc" maxlength="255" type="text"
                class="form-control" placeholder="Inserisci il valore" [ngClass]="{
                            'is-invalid': submitted && addNationForm.controls.quotaRipartitaSarFc.errors
                            }" [imask]="mask" [unmask]="true" />
              <div *ngIf="submitted && addNationForm.controls.quotaRipartitaSarFc.errors" class="invalid-feedback"
                style="display: block;">
                <div *ngIf="addNationForm.controls.quotaRipartitaSarFc.errors">
                  Campo obbligatorio
                </div>
              </div>

            </div>
          </div>

        <div class="row">
          <div class="form-group col-xl-12 col-lg-12">
            <h5><label for="note" class="">Note:</label></h5>
            <div class="">
              <textarea [formGroup]="addNationForm" [ngClass]="{ 'is-invalid': submitted && f.note.errors }"
                formControlName="note" rows="9" cols="20" class="form-control"
                placeholder="Inserisci le note"></textarea>


              <!--<input [formGroup]="addNationForm" formControlName="note" type="text" value="" class="form-control"
                placeholder="Inserisci le note" [ngClass]="{
                'form-control': true,
                'is-invalid': submitted && f.note.errors
              }" />-->
              <div *ngIf="submitted && f.note.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.note.errors">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="text-center my-3">
          <button class="btn btn-primary col-lg-4 " type="submit" [disabled]="setDisabledFormDatiGenerali()">
            Salva
          </button>
        </div>

      </form>

    </div>


    <div class="tab-pane " id="divFasi" #divFasi [ngClass]="{ 'active':activeTab==='tabFasi' } ">
      <fasi-component [isInModal]="true" [pnrrLineaFinanziamentoId]="this.addNationForm.controls.id.value" [readOnlyForm]="!checkPermission('insert', 'PNRR - Linee di Finanziamento - Fasi')"
        #tabFasiProgettoContent [gotoBtnOptions]="{show:false,label: 'Chiudi'} "></fasi-component>
    </div>

    <div class="tab-pane " id="divTemplateStorico" [ngClass]="{ 'active':activeTab==='tabStorico' } ">
        <!--***-->
        <storico-pnrr-linea-finanziamento-component [pnrrLineaFinanziamentoId]="this.addNationForm.controls.id.value" #divTemplateStorico [gotoBtnOptions]="{show:false,label: 'Chiudi'} " >
        </storico-pnrr-linea-finanziamento-component>
        <!--***-->
    </div>
  </div>
</ng-template>

<div class="col-12 mb-5">
  <hr />
  <accordion [isAnimated]="true" *ngIf="checkPermission('insert')">
    <accordion-group heading="Inserisci nuova Linea di finanziamento PNRR/PNC">
      <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
    </accordion-group>
  </accordion>

  <div class="mt-4 ">
    <h4 class="titoletti ">Lista linee di finanziamento PNRR/PNC</h4>

    <div [hidden]="!visible">
      <div class="px-sm-3 px-0 ">
        <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-danger btn-sm font-weight-bold mb-2 mr-1" (click)="esportaRR05()">
              Esporta RR.05 <i class="far fa-file-pdf" aria-hidden="true"></i>
              <img *ngIf="loadingExportExcel" class="pl-3 float-right"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </button>
            <button type="button" class="btn btn-info btn-sm font-weight-bold mb-2 mr-1" (click)="esportaRR05Excel()">
                Esporta RR.05 <i class="fas fa-file-excel" aria-hidden="true"></i>
                <img *ngIf="loadingExportExcel" class="pl-3 float-right"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </button>

            <button [hidden]="true" type="button" class="btn btn-orange btn-sm font-weight-bold mb-2 mr-1" (click)="openModalEsportaRR07()">
              Esporta RR.07 <i class="far fa-file-pdf" aria-hidden="true"></i>
              <img *ngIf="loadingExportExcel" class="pl-3 float-right"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </button>

            <button type="button" class="btn btn-info btn-sm font-weight-bold mb-2 mr-1" (click)="esportaExcelVerificaScostamenti()">
                Esporta excel verifica scostamenti <i class="fas fa-file-excel" aria-hidden="true"></i>
                <img *ngIf="loadingExportExcel" class="pl-3 float-right"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </button>


          <button [hidden]="true" type="button" class="btn btn-info btn-sm font-weight-bold mb-2" (click)="esportaExcel()">
            Esporta EXCEL <i class="fas fa-file-excel" aria-hidden="true"></i>
            <img *ngIf="loadingExportExcel" class="pl-3 float-right"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </button>
        </div>
      </div>
      <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;"
        [dtOptions]="dtOptionsNgx">
        <ng-template #btnEditTemplate let-row="row" let-value="value">
          <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top"
            title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
        </ng-template>
        <ng-template #btnDeleteTemplate let-row="row" let-value="value">
          <a *ngIf="checkPermission('delete')" [ngClass]="{
        'disabled': loading
        }" class=" border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top"
            title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt"></i></a>
        </ng-template>
      </ngx-datatable-wrapper>
    </div>
  </div>
</div>

<ng-template #templateModal>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">Modifica linea di finanziamento PNRR/PNC</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body ">
    <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
  </div>
</ng-template>

<!--inizio modale esportazione RR07-->
<ng-template #modalExportDataRR07>
    <div class="modal-header  text-center">
        <h4 class="modal-title pull-left">Opzioni esportazione</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalEsportazione()">
  <span aria-hidden="true">&times;</span>
</button>
    </div>
    <div class="modal-body" [formGroup]="exportForm">
        <div class="custom-control custom-switch">
            <input [formGroup]="exportForm" formControlName="escludiLineeSenzaRipartizioni" type="checkbox" class="custom-control-input" id="escludiLineeSenzaRipartizioni">
            <label class="custom-control-label" for="escludiLineeSenzaRipartizioni">Escludi linee finanziamento PNRR/PNC senza ripartizioni</label>
        </div>
        <button  class="btn btn-lg btn-primary btn-block" type="button" style="margin:15px 0" (click)="doEsportaRR07()">
        Esporta
    </button>
    </div>
</ng-template>
<!--fine modale esportazione-->
