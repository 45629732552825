<!--<div class="container-xl text-center" [hidden]="showPage">
    <app-loading message="Caricamento in corso..."></app-loading>
</div>-->


<div class="container">
    <div class="container-xl">
        <div class="pb-3">
            <div class=" row ">
                <div class="titolo col-md-12 ">
                    <h3 class="mt-3">Validazione</h3>
                </div>


            </div>

        </div>

        <div class="row mb-5">
            <div id="tabs" #tabs class="col-lg-12 ">
                <ul class="nav nav-tabs navscheda position-sticky sticky-top bg-white pt-4 pb-1" style="top:90px!important;">
                    <li class="nav-item mt-1 px-1" [ngClass]="{ 'active':activeTab==='tabFinanziamenti'}">
                        <a (click)="confirmChangeTab($event,0);" [ngClass]="{ 'active':activeTab==='tabFinanziamenti'}" class="nav-link" href="#divFinanziamenti" data-toggle="tab">Finanziamenti</a>
                    </li>
                    <li class="nav-item mt-1 px-1" [ngClass]="{ 'active':activeTab==='tabInterventi'}">
                        <a (click)="confirmChangeTab($event,1);" [ngClass]="{ 'active':activeTab==='tabInterventi'}" class="nav-link" href="#divInterventi" data-toggle="tab">Interventi</a>
                    </li>
                    <li class="nav-item mt-1 px-1" [ngClass]="{ 'active':activeTab==='tabValidati'}" *ngIf="isAdmin()">
                        <a (click)="confirmChangeTab($event,2);" [ngClass]="{ 'active':activeTab==='tabValidati'}" class="nav-link" href="#divValidati" data-toggle="tab">Validati</a>
                    </li>
                </ul>


            </div>
        </div>
        <!-- tabella -->

    </div>
    <!--tabella e bottone inserisci-->
    <div class="">




    </div>

    <!--tabs-->
    <div id="tabs" #tabs class="col-lg-12 px-0">
        <div class="tab-content">
            <div class="tab-pane " id="divFinanziamenti" [ngClass]="{ 'active':activeTab==='tabFinanziamenti'}">
                <ngx-datatable-wrapper #ngxDatatableWrapperFinanziamenti [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsFinanziamenti">
                    <ng-template #btnCheckTemplate let-row="row" let-value="value">
                        <a class=" border-0 text-center text-success" (click)="validaFinanziamento(row)" data-toggle="tooltip" data-placement="top" title="valida">
                            &nbsp;&nbsp;&nbsp;<i class="fas fa-check-circle pointer">
                        </i></a>
                    </ng-template>
                    <ng-template #btnBanTemplate let-row="row" let-value="value">
                        <a class=" border-0 text-center" data-toggle="tooltip" (click)="rifiutaFinanziamento(row)" data-placement="top" title="rifiuta">
                            &nbsp;&nbsp;&nbsp;<i class="fas fa-ban pointer">
                        </i></a>
                    </ng-template>
                    <ng-template #btnDettaglioFinanziamentoTemplate let-row="row" let-value="value">
                        <a class=" border-0 text-center" (click)="onDetailFinanziamento(row)" data-toggle="tooltip" data-placement="top" title="dettaglio">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="far fa-eye detail-icon pointer" ></i></a>
                    </ng-template>
                    <ng-template #direzioneFieldValueTemplate let-row="row" let-value="value">
                        <ul class=" pl-2" *ngIf="value && value !=''">
                            <li *ngFor="let val of printDirezioneFieldValue(value)">{{val}}</li>
                        </ul>
                    </ng-template>
                    <ng-template #datiRichiestaValueTemplate let-row="row" let-value="value">
                        {{printDatiRichiestaFieldValue(row)}}
                    </ng-template>
                    <ng-template #fonteStrumentoAttuativoValueTemplate let-row="row" let-value="value" let-index="index">
                        <span>{{row.tipoFinanziamento?row.tipoFinanziamento.tipoFinanziamento:""}}</span><br />
                        <span>{{row.tipoStrumentoAttuativo?row.tipoStrumentoAttuativo.descrizione:""}}</span>
                    </ng-template>
                </ngx-datatable-wrapper>
            </div>

            <div class="tab-pane " id="divInterventi" [ngClass]="{ 'active':activeTab==='tabInterventi'}">
                <ngx-datatable-wrapper #ngxDatatableWrapperInterventi [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsInterventi">


                    <ng-template #annualitaFieldValueTemplate let-row="row" let-value="value" let-index="index">
                        <table class="w-100 table table-borderless" id="detail_importi_{{row.id}}">
                            <tr class="collapse trdetail" *ngFor="let val of sortImporti(row.annualita)">

                                <td class="pt-0 pb-1 pl-0 pr-1">{{val.anno}}:</td>
                                <td class="pt-0 pb-1 text-0083e0 pl-0">{{printValueAsCurrency(val.importo)}}</td>
                                <tr>
                                    <td class="pt-0 pb-1 pl-0 pr-1">
                                        <a class="show-detail-anchor collapse show" data-toggle="collapse" attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i class="fas fa-list-ul pointer"></i></a>
                                        <!--<a class="trdetail show-detail-anchor collapse show" data-toggle="collapse" attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i class="fas fa-edit pointer"></i></a>-->
                                    </td>
                                    <td class="pt-0 pb-1 pl-0">{{ printValueAsCurrency(value)}}</td>
                                </tr>
                        </table>
                    </ng-template>
                    <ng-template #disponibilitaFieldValueTemplate let-row="row" let-value="value" let-index="index">
                        <table class="w-100 table table-borderless" id="detail_importi_{{row.id}}">
                            <tr class="collapse trdetail" *ngFor="let val of sortImporti(row.annualita)">

                                <td class="pt-0 pb-1 pl-0 pr-1">{{val.anno}}:</td>
                                <td class="pt-0 pb-1 text-0083e0 pl-0">{{printValueAsCurrency(val.disponibilita)}}</td>
                                <tr>
                                    <td class="pt-0 pb-1 pl-0 pr-1">
                                        <a class="show-detail-anchor collapse show" data-toggle="collapse" attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i class="fas fa-list-ul pointer"></i></a>
                                    </td>
                                    <td class="pt-0 pb-1 pl-0">{{ printValueAsCurrency(value)}}</td>
                                </tr>
                        </table>
                    </ng-template>
                    <ng-template #btnCheckInterventiTemplate let-row="row" let-value="value">
                        <a class=" border-0 text-center text-success" (click)="validaIntervento(row)" data-toggle="tooltip" data-placement="top" title="valida">&nbsp;
                            <i class="fas fa-check-circle pointer" >
                        </i></a>
                    </ng-template>
                    <ng-template #btnBanInterventiTemplate let-row="row" let-value="value">
                        <a class=" border-0 text-center" data-toggle="tooltip" (click)="rifiutaIntervento(row)" data-placement="top" title="rifiuta">&nbsp;
                            <i class="fas fa-ban pointer">
                        </i></a>
                    </ng-template>
                    <ng-template #btnDettaglioInterventoTemplate let-row="row" let-value="value">
                        <a class=" border-0 text-center" (click)="onDetailIntervento(row)" data-toggle="tooltip" data-placement="top" title="dettaglio"><i class="far fa-eye detail-icon pointer" ></i></a>
                    </ng-template>

                    <ng-template #annualitaInterventiFieldValueTemplate let-row="row" let-value="value" let-index="index">
                        <table class="w-100 table table-borderless" id="detail_importi_{{row.id}}">
                            <tr class="collapse trdetail" *ngFor="let val of sortImporti(row.importi)">

                                <td class="pt-0 pb-1 pl-0 pr-1">{{val.anno}}:</td>
                                <td class="pt-0 pb-1 text-0083e0 pl-0">{{printValueAsCurrency(val.importo)}}</td>
                                <tr>
                                    <td class="pt-0 pb-1 pl-0 pr-1">
                                        <a class="show-detail-anchor collapse show" data-toggle="collapse" attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i class="fas fa-list-ul pointer"></i></a>
                                        <!--<a class="trdetail show-detail-anchor collapse show" data-toggle="collapse" attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i class="fas fa-edit pointer"></i></a>-->
                                    </td>
                                    <td class="pt-0 pb-1 pl-0">{{ calcolaValoreIntervento(row)}}</td>
                                </tr>
                        </table>
                    </ng-template>
                </ngx-datatable-wrapper>
            </div>

            <div class="tab-pane " id="divValidati" [ngClass]="{ 'active':activeTab==='tabValidati'}">
                <ngx-datatable-wrapper #ngxDatatableWrapperValidati [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsValidati">
                    <ng-template #datiRichiestaValidateValueTemplate let-row="row" let-value="value">
                        {{printDatiRichiestaValidateFieldValue(row)}}
                    </ng-template>
                    <ng-template #datiRichiestaApprovazioneValueTemplate let-row="row" let-value="value">
                        {{printDatiApprovazioneValidateFieldValue(row)}}
                    </ng-template>

                    <ng-template #btnDettaglioValidatiTemplate let-row="row" let-value="value">
                        <a class=" border-0 text-center" (click)="onDetailValidati(row)" data-toggle="tooltip" data-placement="top" title="dettaglio">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="far fa-eye detail-icon pointer" ></i></a>
                    </ng-template>
                </ngx-datatable-wrapper>
            </div>

        </div>
    </div>

    <ng-template #modaleIntervento>
        <div class="modal-header text-center bg-0083e0">
            <h4 class="modal-title pull-left text-white">Dettaglio intervento</h4>
            <button type="button" class="close pull-right text-white" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body bg-trasparent">

            <intervento-component [interventoData]="selectedInterventoData" (closeModalEmitter)="closeModal($event);"></intervento-component>


        </div>
    </ng-template>

    <ng-template #modaleFinanziamento>
        <div class="modal-header text-center bg-0083e0">
            <h4 class="modal-title pull-left text-white">Dettaglio finanziamento</h4>
            <button type="button" class="close pull-right text-white" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body bg-trasparent">

            <finanziamento-component [finanziamentoId]="finanziamentoId" [gotoBtnOptions]="{show:true,label:'Chiudi',showIcon:true}" (gotoBtnFunction)="closeModal($event)" [isInModal]="'true'"></finanziamento-component>


        </div>
    </ng-template>

    <!-- fine cointainer xl-->
