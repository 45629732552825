<div class="p-5 container-xl text-center" [hidden]="loadDataCounter <= 0">
  <app-loading message="Caricamento in corso..."></app-loading>
</div>

<div class="position-sticky sticky-top pt-4 pb-4 elemento-aggiorna shadow-custom"


  [ngClass]="{'sticky-top-custom-modal': isInModal,'sticky-top-custom': !isInModal ,'sticky-top-custom-sal': !tabsOnOneRow && !isInModal }"

  [hidden]="loadDataCounter > 0">
  <div class=" row  justify-content-center ">
    <div class="text-center col-lg-2 col-md-2" [ngClass]="{ 'offset-lg-3': !gotoBtnOptions || !gotoBtnOptions.show }" [hidden]="readOnlyForm">
      <button [disabled]="checkDisabled()" (click)="onSubmit()" class="btn btn-primary btn-block" type="button">
        Salva&nbsp;<i class="fas fa-save"></i>
      </button>
    </div>
    <div class=" text-center"
      [ngClass]="{ ' col-lg-4 col-md-6': !(gotoBtnOptions && gotoBtnOptions.showIcon) , 'col-lg-2' : gotoBtnOptions && gotoBtnOptions.showIcon}">
      <button (click)="gotoBackBtnClick()" *ngIf="gotoBtnOptions && gotoBtnOptions.show" [disabled]="loading"
        class="btn btn-secondary btn-block font-weight-bold"
        type="button">{{ gotoBtnOptions ? gotoBtnOptions.label : 'Torna a elenco' }}&nbsp;<i class="fas fa-times"
          *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i>
      </button>
    </div>
  </div>
</div>

<div class="" [hidden]="loadDataCounter > 0">
  <div class="row mx-0">
    <div class="w-100 sicer-custom">
      <sicer-bilancio-capitolo-component
        [type]="type"
        [finanziamentoId]="finanziamentoId"
        [interventoId]="interventoId"
        [subProgettoId]="progettoId"
        [toDeleteImpAccs]="toDeleteImpAccs"
        [interventi]="interventi"
        (impAccsUpdateEmitter)="manageUpdateImpAccs($event)"
        (baseImpAccsEmitter)="getBaseImpAccs($event)"
        (dissassociaCapitoloEmitter)="updateImpAccsByPkCpt($event)"
        [readOnlyForm]="readOnlyForm">
      </sicer-bilancio-capitolo-component>
    </div>
  </div>
</div>
