import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CurrencyPipe } from "@angular/common";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { GeoService } from "src/app/services";
import { DashboardPnrrNonDisegnabileService } from '../dashboard-pnrr-non-disegnabile/dashboard-pnrr-non-disegnabile.service';

@Component({
  selector: 'app-chart-pnrr-risorse-provincie',
  templateUrl: './chart-pnrr-risorse-provincie.component.html',
  styleUrls: ['./chart-pnrr-risorse-provincie.component.css']
})
export class ChartPnrrRisorseProvincieComponent implements OnInit, OnDestroy {

  chart: any;

  renderingStatus = false;

  @Input() provinces: any;

  @Input() set filterManager(filters){
    this.getChartData(filters);
  }
  getChartData(filters: any){
    this.geoService.getChartDataProvincieDashboardNonDisegnabile(filters).subscribe(res=>{

      const chartData = [];

      res.map(x=>{
        if(x['nome']){
          const element = {};
          element['province'] = x['nome'];
          element["m1"] = x['pnrrMissioni'] == 'M1' ? x['importo'] : 0;
          element["m2"] = x['pnrrMissioni'] == 'M2' ? x['importo'] : 0;
          element["m3"] = x['pnrrMissioni'] == 'M3' ? x['importo'] : 0;
          element["m4"] = x['pnrrMissioni'] == 'M4' ? x['importo'] : 0;
          element["m5"] = x['pnrrMissioni'] == 'M5' ? x['importo'] : 0;
          element["m6"] = x['pnrrMissioni'] == 'M6' ? x['importo'] : 0;
          element['totale'] = "totale";
          const keeper = chartData.find(x=> x['province']==element['province']);
          if(keeper){
            keeper["m1"] += element["m1"];
            keeper["m2"] += element["m2"];
            keeper["m3"] += element["m3"];
            keeper["m4"] += element["m4"];
            keeper["m5"] += element["m5"];
            keeper["m6"] += element["m6"];
          }
          else
            chartData.push(element);
        }
      })
      chartData.sort((a,b)=>a.province<b.province ? -1 : 1);
      this.myfunc(chartData);
    })
  }

  constructor(private geoService: GeoService,private service:DashboardPnrrNonDisegnabileService) { }

  ngOnInit(): void {

  }

    myfunc(data){
      if(!this.renderingStatus){
        this.renderingStatus = true;
        this.service.doRendering(true);
      }
    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    if(data.length <=0 ){
      this.fullChartAsEmpty();
      return;
    }
    if(this.chart)this.chart.dispose();
    // Create chart instance
    this.chart = am4core.create("chartLambda", am4charts.XYChart);

    this.chart.colors.list = [

      am4core.color("#C6DBF0"),//M1
      am4core.color("#ACD86A"),//M2
      am4core.color("#FB9999"),//M3
      am4core.color("#F2C799"),//M4
      am4core.color("#E2D7EC"),//M5
      am4core.color("#FDF2CD"),//M6
    ];

    // Add data
    this.chart.data = data;

    let title = this.chart.titles.create();
      title.text = "Numero di risorse per provincia e missione PNRR";
      title.fontSize = 18;


    // Enable export
    this.aggiungiLogo(this.chart);

    this.chart.exporting.menu = new am4core.ExportMenu();

    this.chart.exporting.menu.align = "left";
    this.chart.exporting.menu.verticalAlign = "top";
    this.chart.exporting.menu.background = "#ff0000";
    this.chart.exporting.menu.defaultStyles = true;
    this.chart.exporting.menu.items = [
        {
            menu: [
                { type: "jpg", label: "JPG" },
                { type: "png", label: "PNG" },
                { type: "csv", label: "CSV" },
            ],
        },
    ];
    this.chart.exporting.menu.items[0].icon = "assets/img/export.png";


    //chart.maskBullets = false;
    let that = this;
    this.chart.numberFormatter.numberFormat = "#,###.00 '€'"
    this.chart.language.locale = am4lang_it_IT;

    // Add horizotal scrollbar
    this.chart.scrollbarX = new am4core.Scrollbar();

    // Create axes
    let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "province";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 55;


    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.extraMax = 0.1;
    valueAxis.calculateTotals = true;

    let label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 320;
    label.fontSize = 12;

    this.createSeries("m1", "M1", "Digitalizzazione, innovazione, competitività, cultura e turismo");
    this.createSeries("m2", "M2", "Rivoluzione verde e transizione ecologica");
    this.createSeries("m3", "M3", "Infrastrutture per una mobilità sostenibile");
    this.createSeries("m4", "M4", "Istruzione e ricerca");
    this.createSeries("m5", "M5", "Inclusione e coesione");
    this.createSeries("m6", "M6", "Salute");
    //this.createSeries("totale", "totale");


    // Create series for total
    var totalSeries = this.chart.series.push(new am4charts.ColumnSeries());
    totalSeries.dataFields.valueY = "totale";
    totalSeries.dataFields.categoryX = "province";
    totalSeries.stacked = true;
    totalSeries.hiddenInLegend = true;
    totalSeries.columns.template.strokeOpacity = 0;

    var totalBullet = totalSeries.bullets.push(new am4charts.LabelBullet());
    totalBullet.dy = -20;
    totalBullet.label.text = "Tot.: [bold]{valueY.total}";
    totalBullet.label.hideOversized = false;
    totalBullet.label.fontSize = 12;
    //totalBullet.label.background.fill = totalSeries.stroke;
    //totalBullet.label.background.fillOpacity = 0.2;
    totalBullet.label.padding(5, 0, 5, 0);

    totalBullet.tooltipText = "{categoryX}[/]\nTotale: [bold]{valueY.total}";

    // Legend
    this.chart.legend = new am4charts.Legend();
    let markerTemplate = this.chart.legend.markers.template;
    markerTemplate.width = 12;
    markerTemplate.height = 12;
    this.chart.legend.labels.template.fontSize = 12;
    this.chart.legend.itemContainers.template.tooltipText = "{dataContext.dummyData.description}"
    // Distanza elementi della legenda
    //columnChart.legend.itemContainers.template.paddingTop = 0;
    //this.chart.legend.itemContainers.template.paddingBottom = 0;
    this.chart.legend.itemContainers.template.marginLeft = 3;
    this.chart.legend.itemContainers.template.marginRight = 3;

    this.chart.events.on("ready", () => {
      if(this.renderingStatus){
        this.renderingStatus = false;
        this.service.doRendering(false);
      }
    });

    this.chart.events.on('maxsizechanged', (e) => {
      if(window.innerWidth <= 575.98){
          title.fontSize = 12;
          totalBullet.label.fontSize = 12;
      }
      else{
        title.fontSize = 18;
        totalBullet.label.fontSize = 12;
      }
    });

  }

  // Create series
  createSeries(field, name, description) {
    if(!this.chart) return;
    // Set up series
    let series = this.chart.series.push(new am4charts.ColumnSeries());
    series.name = name;
    series.dataFields.valueY = field;
    series.dataFields.categoryX = "province";
    series.sequencedInterpolation = true;
    series.dummyData = {
      description: description
    };

    // Make it stacked
    series.stacked = true;

    // Configure columns
    series.columns.template.width = am4core.percent(80);
    series.columns.template.tooltipText = "{categoryX}[/]\n{name}: [bold]{valueY}";

    // Add label
    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{valueY}";
    labelBullet.locationY = 0.5;
    labelBullet.label.fontSize = 12;
    labelBullet.label.hideOversized = true;

    return series;
  }

  printValueAsCurrency(value) {
    //return this.italianCurrencyPipe.parse(value) + " €";
    value = typeof value === "number" ? value : value.replace(",", ".").trim();
    const format = "1.2-2";
    const currency = "€";
    const currentLocale: string = "it";
    return new CurrencyPipe(currentLocale).transform(
      value,
      "EUR",
      currency,
      format,
      "it-IT"
    );
  }

  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  ngOnDestroy(){
    this.chart && this.chart.dispose();
  }

  aggiungiLogo(logoChart) {
    // Add watermark
    const watermark = new am4core.Image();
    watermark.href = "assets/img/logo_blu.svg";
    logoChart.tooltipContainer.children.push(watermark);
    watermark.align = "right";
    watermark.valign = "bottom";
    watermark.opacity = 0.3;
    watermark.marginRight = 10;
    watermark.marginBottom = 5;
    watermark.disabled = true;

    // Enable watermark on export
    logoChart.exporting.events.on("exportstarted", function (ev) {
        watermark.disabled = false;
    });

    // Disable watermark when export finishes
    logoChart.exporting.events.on("exportfinished", function (ev) {
        watermark.disabled = true;
    });

    // Add watermark to validated sprites
    logoChart.exporting.validateSprites.push(watermark);
  }
  /*---*/



  fullChartAsEmpty(){
    if(this.chart)this.chart.dispose();
    this.chart = am4core.create("chartLambda", am4charts.XYChart);

    this.chart.colors.list = [

      am4core.color("#C6DBF0"),//M1
      am4core.color("#ACD86A"),//M2
      am4core.color("#FB9999"),//M3
      am4core.color("#F2C799"),//M4
      am4core.color("#E2D7EC"),//M5
      am4core.color("#FDF2CD"),//M6
    ];

    this.chart.data = this.provinces.map(x=>{return {province: x["value"]}})

    //Create axes
    let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "province";
    categoryAxis.renderer.grid.template.location = 0;


    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.extraMax = 0.1;
    valueAxis.calculateTotals = true;

    const noDataMessagecontainer = this.chart.chartContainer.createChild(am4core.Container);
    noDataMessagecontainer.align = 'center';
    noDataMessagecontainer.isMeasured = false;
    noDataMessagecontainer.x = am4core.percent(50);
    noDataMessagecontainer.horizontalCenter = 'middle';
    noDataMessagecontainer.y = am4core.percent(50);
    noDataMessagecontainer.verticalCenter = 'middle';
    noDataMessagecontainer.layout = 'vertical';

    const messageLabel = noDataMessagecontainer.createChild(am4core.Label);
    messageLabel.text = 'La ricerca non ha restituito risultati';
    messageLabel.textAlign = 'middle';
    messageLabel.maxWidth = 300;
    messageLabel.wrap = true;

    this.chart.events.on("ready", () => {
      if(this.renderingStatus){
        this.renderingStatus = false;
        this.service.doRendering(false);
      }
    });

  //   // Add data
  //   this.chart.data = [];

    let title = this.chart.titles.create();
    title.text = "Numero di risorse per provincia e missione PNRR";
    title.fontSize = 18;
    // title.marginBottom = 30;

    // Enable export
    this.aggiungiLogo(this.chart);

    this.chart.exporting.menu = new am4core.ExportMenu();

    this.chart.exporting.menu.align = "left";
    this.chart.exporting.menu.verticalAlign = "top";
    this.chart.exporting.menu.background = "#ff0000";
    this.chart.exporting.menu.defaultStyles = true;
    //this.chart.exporting.filePrefix = title;
    this.chart.exporting.menu.items = [
        {
            menu: [
                { type: "jpg", label: "JPG" },
                { type: "png", label: "PNG" },
                { type: "csv", label: "CSV" },
            ],
        },
    ];
    this.chart.exporting.menu.items[0].icon = "assets/img/export.png";

    this.createSeries("m1", "M1", "Digitalizzazione, innovazione, competitività, cultura e turismo");
    this.createSeries("m2", "M2", "Rivoluzione verde e transizione ecologica");
    this.createSeries("m3", "M3", "Infrastrutture per una mobilità sostenibile");
    this.createSeries("m4", "M4", "Istruzione e ricerca");
    this.createSeries("m5", "M5", "Inclusione e coesione");
    this.createSeries("m6", "M6", "Salute");

    // Legend
    this.chart.legend = new am4charts.Legend();
    let markerTemplate = this.chart.legend.markers.template;
    markerTemplate.width = 12;
    markerTemplate.height = 12;
    this.chart.legend.labels.template.fontSize = 12;
    this.chart.legend.itemContainers.template.tooltipText = "{dataContext.dummyData.description}"
  }

}
