import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    TemplateRef
  } from "@angular/core";
  import { FormGroup, FormBuilder, Validators } from "@angular/forms";
  import { HttpClient } from "@angular/common/http";
  import { ToastService } from 'src/app/services/toast.service';
  import { BsLocaleService } from 'ngx-bootstrap/datepicker';
  import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';  
import { Classificazione, TipoClassificazione } from '../../models';
import { ClassificazioneService,TipoClassificazioneService } from '../../services';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { ColumnMode } from '@swimlane/ngx-datatable';
  
  @Component({
    selector: 'classificazione-component',
    templateUrl: './classificazione.component.html',
    styleUrls: ['./classificazione.component.css']
  })
  export class ClassificazioneComponent
    implements OnInit {
    dtOptions: any = {};
    visible = false;
    submitted: boolean = false;
    addSchoolYearForm: FormGroup;
    modalRef: BsModalRef;
    open: boolean = false; 
    tipiClassificazione:TipoClassificazione[]; 
    tipoClassificazioneSelected:TipoClassificazione;

    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;
    @ViewChild('templateModal', { static: true })
    public templateModal: TemplateRef<any>;

    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;


    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;
    
    columns = [
        {
            name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
        }
        , {
            name: 'codice&ensp;'
            , prop: 'codice'
            , sortable: true
            , filtrable: true
            , flexGrow: 1
            , minWidth: 60


        }
        , {
            name: 'ordine&ensp;'
            , prop: 'ordine'
            , sortable: true
            , filtrable: true
            , flexGrow: 1
            , minWidth: 60


        }
        , {
            name: 'tipo classificazione'
            , prop: 'tipoClassificazione.tipo'
            , sortable: true
            , filtrable: true
            , flexGrow: 3
            , minWidth: 120


        }
        , {
            name: 'descrizione'
            , prop: 'descrizione'
            , sortable: true
            , filtrable: true
            //,cellClass: 'mobile-hidden desktop-hidden'
            //,headerClass: 'mobile-hidden desktop-hidden'
            , flexGrow: 9
            , minWidth: 200
        }
        , {
            name: 'modifica'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60

        }
        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60

        }
    ];


    templateList = [];

    constructor(
      private http: HttpClient,
      private formBuilder: FormBuilder,
      private el: ElementRef,
      private localeService: BsLocaleService,
      private toastr: ToastService,
      private modalService: BsModalService,
      private classificazioneService:ClassificazioneService,
      private tipoClassificazioneService:TipoClassificazioneService
    ) {
      this.localeService.use("it"); 
      this.dtOptions = {
        rows: []
        ,totalElements:0
        , columns: this.columns
        , columnMode: ColumnMode.flex
        , headerHeight: "50"
        , footerHeight: "50"
        , rowHeight: "auto"
        , externalSorting: false// true
        , loadingIndicator: 'loanding'
        , class: "bootstrap"
        , summaryRow: true
        , summaryPosition: "'bottom'"
        , externalPaging: false// true
        , count: 0
        , offset: 0
        , limit: 10
        , serverSide: false
        ,scrollbarH:"true"
    };   
    }
  
    ngOnInit() {
      this.getTipiClassificazione();
      this.templateList = [ { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }, { name: 'btnEditTemplate', template: this.btnEditTemplate }];
        window.scroll(0, 0);
        this.addSchoolYearForm = this.initializeFormGroup();
        this.getTipologie();
    }
  
    
  
    initializeFormGroup() {
      return this.formBuilder.group({
          id:[null],
        codice: ["", [Validators.required, Validators.maxLength(255)]],        
        descrizione: ["", [Validators.required, Validators.maxLength(1000)]],        
        ordine: ["", [Validators.required]],        
        idTipoClassificazione: ["", [Validators.required]],        
      });
    }
  
    get f() {
      return this.addSchoolYearForm.controls;
    }
  
    getTipiClassificazione(){
        this.tipoClassificazioneService.getAll().subscribe(
            x=>this.tipiClassificazione = x
        );
    }

    onChangeIdTipoClassificazione($event:TipoClassificazione){
        this.tipoClassificazioneSelected = $event;
    }

    onSubmit() {
      this.submitted = true;
      this.addSchoolYearForm.updateValueAndValidity();
      let errors = false;
      for (let c in this.f) {
        errors = errors || this.f[c].errors !== null;
        console.log(
          "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
        );
      }
      if (this.addSchoolYearForm.invalid || errors) {
        console.log("invalid");
        let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
        for (let el in this.addSchoolYearForm.controls) {
          if (this.addSchoolYearForm.controls[el].invalid) {
            console.log(`set focus on ${el}`);
            if (this.addSchoolYearForm.controls[el] instanceof FormGroup) {
              for (let el1 in this.addSchoolYearForm.get(el)["controls"]) {
                if (this.addSchoolYearForm.get(el)["controls"][el1].invalid) {
                  invalidElements = this.el.nativeElement.querySelectorAll(
                    `[formcontrolname="${el1}"]`
                  );
                  break;
                }
              }
            } else {
              invalidElements = this.el.nativeElement.querySelectorAll(
                `[formcontrolname="${el}"]`
              );
            }
            break;
          }
        }
        if (invalidElements.length > 0) {
          invalidElements[0].focus();
        }
  
        return false;
      }
  
      const obj: Classificazione = new Classificazione();
      obj.codice = this.f.codice.value;
      obj.descrizione = this.f.descrizione.value;
      obj.ordine  = this.f.ordine.value;
      obj.idTipoClassificazione = this.tipoClassificazioneSelected.id;
      obj.tipoClassificazione = this.tipiClassificazione.find(x=>x.id ==obj.idTipoClassificazione );
      obj.id = this.f.id.value;
            

        this.classificazioneService.save(obj).subscribe(res => {
            this.tipoClassificazioneSelected = null;
            this.toastr.success("Classificazione salvata con successo!");
            this.submitted = false;
            this.open = false;
            this.addSchoolYearForm.reset();
            
            this.buttonLabel="Inserisci";
            if (!obj.id){
                //new row
                this.ngxDatatableWrapper.addRow(res);                        
            }
            else{                
                this.ngxDatatableWrapper.updateRow(obj);                                                
            }
            this.closeModal();

        }, error => {
            this.toastr.error("Errore di sistema");
        });
    }
  
    buttonLabel:string="Inserisci";
    loading:boolean=false;
    classificazioneId:any=null;
    isInInsert:boolean = true;
    config = {
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };
    onEdit(row) {
        this.isInInsert = false;
        this.submitted = false;
        this.classificazioneId = row.id;
        console.log(row);
        this.buttonLabel = "Aggiorna";
        this.modalRef = this.modalService.show(this.templateModal, this.config);
        this.addSchoolYearForm.setValue({
            id: row.id,
            codice:row.codice,
            ordine:row.ordine,
            descrizione:row.descrizione,
            idTipoClassificazione:row.tipoClassificazione.id            
        });
        this.tipoClassificazioneSelected = row.tipoClassificazione;
    }
    onDelete(row) {
        console.log(row);
        if (!confirm(`Stai per eliminare la classificazione "${row.codice}", proseguire?`)) return;
        this.loading = true;
        this.classificazioneService.delete(row.id).subscribe(
            result => {
                this.loading = false;
                this.ngxDatatableWrapper.deleteRow(row);
                this.toastr.success('Classificazione eliminata con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile eliminare la classificazione: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                console.log('error');
            });
    }
    closeModal(){
        this.modalRef.hide();
        this.addSchoolYearForm.reset();
        this.buttonLabel="Inserisci";
        this.tipoClassificazioneSelected = null;
        this.isInInsert = true;
        this.classificazioneId = null;
    }  

    getTipologie() {
        this.classificazioneService.getAll().subscribe(
            result => {
                
                this.ngxDatatableWrapper.setInitialData(result);

            }
        );

    }
  }
  