import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-geo-management",
  templateUrl: "./geo-management.component.html",
  styleUrls: ["./geo-management.component.css"]
})
export class GeoManagementComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
