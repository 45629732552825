import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { SicerService } from '../../services';
import { ToastService } from 'src/app/services';

@Component({
  selector: 'app-richiesta-capitolo-sicer',
  templateUrl: './richiesta-capitolo-sicer.component.html',
  styleUrls: ['./richiesta-capitolo-sicer.component.css']
})
export class RichiestaCapitoloSicerComponent implements OnInit {
  
  myGroup: FormGroup;

  submitted: boolean = false;
  isInLoading: boolean = false;

  constructor(
    private sicerService: SicerService,
    private toastr: ToastService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.myGroup = this.initializeFormGroup();
  }

  initializeFormGroup() {
      return this.formBuilder.group({
        codiceCapitolo: [null, [Validators.required]],
      });
  }

  get f() {
    return this.myGroup.controls;
  }

  request() {
    this.submitted = true;
    var errors = false;
    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }

    console.log("errors:" + errors);
    if (this.myGroup.invalid || errors) {
      console.log("invalid");
      return false;
    }
    this.isInLoading = true;

    //Chiama Webserver
    this.sicerService.requestCapitalCode(this.myGroup.value.codiceCapitolo).subscribe(res => {
      console.log(res);
      if(!res) this.toastr.error(
          "Errore Generale",
          null,
          { 
            timeOut: 4000, 
            disableTimeOut: false, 
          }
      );

      else if(res.esito == "KO") this.toastr.error(
          res.errore,
          null,
          { 
            timeOut: 4000, 
            disableTimeOut: false, 
          }
      );

      else if(res.errore == null) this.toastr.success(
          res.esito,
          null, 
          {
            timeOut: 4000,
            disableTimeOut: false,
          }
      );
      this.isInLoading = false;
    },
    (err) => {
      console.log(err);
    })
  }

}
