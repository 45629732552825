import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { Provvedimento, ProvvedimentoCapitoloBilancio, SoggettoGiuridico } from '../models';


class DataTablesResponse {
    data: Provvedimento[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}

@Injectable({
    providedIn: "root"
})
export class ProvvedimentoService {
    private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}Provvedimenti/`;
    private apiUrlProjects = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}Progetti/`;
    constructor(private http: HttpClient) { }

    getAllDt(dataTablesParameters: any,pnrrLineaFinanziamentoId:any=null) {
        if(!pnrrLineaFinanziamentoId)pnrrLineaFinanziamentoId = -1;
        return this.http.post<DataTablesResponse>(            
            `${this.apiUrl}alldt?pnrrLineaFinanziamentoId=${pnrrLineaFinanziamentoId}`,
            dataTablesParameters
        );
    }

    getAllDtDto(dataTablesParameters: any, filters: any) {
        return this.http.post<DataTablesResponse>(
            `${this.apiUrl}allDtAsDto`,
            {
                dtParams: dataTablesParameters,
                filters: filters
            }
        );
    }


    getAll() {
        return this.http.get<Provvedimento[]>(
            `${this.apiUrl}`
        );
    }

    getAllPnrrProvvedimenti(lineaId:number){
        return this.http.get<Provvedimento[]>(
            `${this.apiUrl}all/pnrr?pnrrLineaId=${lineaId}`
        );
    }


    getById(id) {
        return this.http.get<any>(
            `${this.apiUrl}${id}`
        );
    }

    insert(s: Provvedimento) {
        return this.http.post<any>(`${this.apiUrl}`, s);
    }
    update(s: Provvedimento) {
        return this.http.put<any>(`${this.apiUrl}${s.id}`, s);
    }
    delete(id: number) {
        return this.http.delete<any>(`${this.apiUrl}${id}`);
    }
    save(s: Provvedimento) {
        if (s.id) return this.update(s);
        return this.insert(s);
    }

    getCapitoliBilancio(id: number) {
        return this.http.get<ProvvedimentoCapitoloBilancio[]>(`${this.apiUrl}${id}/CapitoliBilancio`);
    }
    getSoggettiGiuridici(id: number) {
        return this.http.get<SoggettoGiuridico[]>(`${this.apiUrl}${id}/SoggettiGiuridici`);
    }

    downloadDocument(provvedimentoId: number, item: any) {


        return this.http.get(
            `${this.apiUrl}${provvedimentoId}/documents/${item.id}/download`
            , {
                observe: "response",
                responseType: "blob",
            });

    }

    getStoricoDt(finId:number,dataTablesParameters: any) {
        return this.http.post<any>(
            `${this.apiUrl}${finId}/history/alldt`,
            dataTablesParameters
        );
    }

    getDettaglioStorico(storicoId,finId){
        return this.http.get<any>(`${this.apiUrl}${finId}/history/${storicoId}`);
    }

    getAllObjectsByProvvedimentoId(dataTablesParameters: any ,provvedimentoId: number){
        return this.http.post<DataTablesResponse>(`${this.apiUrl}${provvedimentoId}/objects`, dataTablesParameters);
    }

}
