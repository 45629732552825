import { Injectable } from "@angular/core";
import { StorageService } from "../modules/inframob/services";

@Injectable({ providedIn: "root" })
export class NotificationService {
    constructor(private storageService: StorageService) {

    }

    public manageChanges(what: string) {
        switch (what) {
            case 'motivazione':
                this.storageService.reloadPriorita();
                break;
            case 'soggetto_responsabile':
                this.storageService.reloadRup();
                break;
            case 'aree_tematiche':
                this.storageService.reloadAreeTematiche();
                break;
            case 'tipo_finanziamento':
                this.storageService.reloadTipiFinanziamento();
                break;
        }
    }
}