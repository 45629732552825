import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "./app-config.service";
import { News } from "../models/news";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class NewsService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${
    AppConfigService.env.endpoints.news
  }`;
  constructor(private http: HttpClient) {}

  

  getAllNews(): Observable<News[]> {
    return this.http.get<News[]>(`${this.apiUrl}`);
  }

  getAllNewsEnabled(): Observable<News[]> {
    return this.http.get<News[]>(`${this.apiUrl}?enabled=1`);
  }

  getAllDt(dataTablesParameters:any){
      return this.http
      .post<any>(
        `${this.apiUrl}alldt`,
        dataTablesParameters
      );
  }

  saveNews(form: FormData) {
      if (form.get("id"))
        return this.http.put<News>(`${this.apiUrl}${form.get("id")}`, form);
        return this.http.post<News>(`${this.apiUrl}`, form);
  }

  deleteAttachFromNew(n: News) {
    return this.http.delete<boolean>(`${this.apiUrl}${n.id}/deleteAttach`);
  }

  downloadAttach(n: News) {
    return this.http.get(`${this.apiUrl}${n.id}/attachment`, {
      observe: "response",
      responseType: "blob"
    });
  }

  deleteNews(n: News) {
    return this.http.delete(`${this.apiUrl}${n.id}`);
  }

  enableNews(n: News) {
    return this.http.put(`${this.apiUrl}${n.id}/enable`,null);
  }
}
