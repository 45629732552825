<ng-template #templateEditAdd>
    <form [formGroup]="addSchoolYearForm" (ngSubmit)="onSubmit()">
        <div class="col-lg-12">
            <input type="hidden" [formGroup]="addSchoolYearForm" formControlName="id" />
            <div class="form-group">
                <h5><label for="descrizione" class="mandatory">Stato di attuazione:</label></h5>
                <input [formGroup]="addSchoolYearForm" formControlName="descrizione" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci lo stato di attuazione" [ngClass]="{
                    'form-control': true,
                    'is-invalid': submitted && f.descrizione.errors
                    }" />
                <div *ngIf="submitted && f.descrizione.errors" class="invalid-feedback" style="display: block;">
                    <div *ngIf="f.descrizione.errors.required">
                        Campo obbligatorio
                    </div>
                </div>
            </div>
        </div>
        <!--aree tematiche-->

      <div class="col-lg-12 mt-5">
        <hr />
      </div>

      <div class="col-lg-12 mt-4">
        <div class="row justify-content-between">
          <div class="col-lg-6 col-sm-6 col-6 mt-3">
            <h5 for="" class="">
              Aree gestionali direzione e/o esterne
            </h5>
          </div>
          <div class="col-lg-1 col-sm-2 col-3 text-center mt-3">
            <a
              href="javascript:;;"
              class="bottone-inserisci"
              (click)="openModalAT()"
              ><i class="fas fa-plus"></i
            ></a>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mt-3 table-responsive-lg">
        <table class="table table-hover table-colore">
          <thead class="">
            <th class="casella-descrizione-tx">descrizione</th>
            <th>codice ipa</th>
            <th>struttura</th>
            <th class="casella-icona-tx">elimina</th>
          </thead>
          <tbody>
            <ng-container *ngIf="areeTematiche && areeTematiche.length > 0">
              <tr *ngFor="let item of areeTematiche">
                <td>
                  {{ item.disabled ? "(disattiva) " : ""
                  }}{{ item.descrizione }}
                </td>
                <td>{{ item.codiceIpa }}</td>
                <td>{{ item.struttura }}</td>
                <td>
                  <a
                    (click)="removeAreaTematica(item)"
                    href="javascript:;;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="elimina"
                    ><i class="far fa-trash-alt casella-icona-tx"></i>
                  </a>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="!(areeTematiche && areeTematiche.length > 0)">
              <td colspan="4">Nessuna area direzione selezionata</td>
            </tr>
          </tbody>
        </table>        
      </div>




        <div class="col-lg-12 mb-3"><button class="btn btn-lg btn-primary btn-block" type="submit">
            {{buttonLabel}} 
        </button></div>
    </form>
</ng-template>
<div class="container sfondo_bianco">
    <hr />
    <accordion [isAnimated]="true">
        <accordion-group heading="Inserisci nuovo stato di attuazione">
            <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
        </accordion-group>
    </accordion>
    <div class="mt-5">

        <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptions">
            <ng-template #btnEditTemplate let-row="row" let-value="value">
                <a [ngClass]="{
        'disabled': loading
        }" class=" border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top" title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
            </ng-template>
            <ng-template #btnDeleteTemplate let-row="row" let-value="value">
                <a [ngClass]="{
        'disabled': loading
        }" class=" border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
            </ng-template>

            <ng-template #direzioneFieldValueTemplate let-row="row" let-value="value">
                <ul class="pl-2" *ngIf="row.areeTematiche">
                  <li
                    *ngFor="let val of row.areeTematiche"
                  >
                    {{ val.struttura }} - {{val.descrizione}}
                  </li>
                </ul>
              </ng-template>
        </ngx-datatable-wrapper>
    </div>
</div>
<ng-template #templateModal>
    <div class="modal-header  text-center">
        <h4 class="modal-title pull-left">Modifica stato di attuazione</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
    </div>
    <div class="modal-body">
        <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
    </div>
</ng-template>