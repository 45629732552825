import { OrderPipe } from 'ngx-order-pipe';
import { CurrencyPipe } from '@angular/common';
import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    TemplateRef,
    Input,
    ElementRef,
    Output,
    EventEmitter
} from "@angular/core";
import { registerLocaleData } from '@angular/common';
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { InterventoService,  TipologiaErogazioneService,TipoProvvedimentoService } from '../../services';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from '../../../../components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component'
import localeIt from '@angular/common/locales/it';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { GenericSelectionModalComponent } from '../genericSelectionModal/generic-selection-modal.component';
registerLocaleData(localeIt, 'it');
defineLocale('it', itLocale);
import {IsDirtyHelper} from "../../../../helpers/is-dirty.helper"
import { AuthService } from 'src/app/services';

@Component({
    selector: 'intervento-erogazioni-component',
    templateUrl: './intervento-erogazioni.component.html',
    styleUrls: ['./intervento-erogazioni.component.css']
})
export class InterventoErogazioniComponent implements OnInit, OnDestroy {
section:string='Interventi';
    public mask: any = {
        mask: 'KK €',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            }
        }
    };

    ngOnDestroy(): void {
    }

    submitted: boolean = false;
    loading: boolean = false;

    tipologieErogazioni = [];
    tipiProvvedimento=[];

    readOnlyForm:boolean=false;

    private _interventoId: number;

    salList = [];
    anniList=[];

    salListIsError:boolean = false;
    finanziamentoIsError:boolean = false;
    @Output() onSubmitOk: EventEmitter<any> = new EventEmitter();


    loadDataCounter:number = 0;



    @Input()
    set interventoId(value) {
        //console.log(`inside set interventoId= ${value}`);
        this._interventoId = value;
        if (!value) return;
        //this.getInterventoData(value);
       // this.getAnnualitaByInterventoId(this._interventoId);
        // this.getSalList(value);
    }
    get interventoId() {
       // console.log(`inside get interventoId= ${this._interventoId}`);
        return this._interventoId;
    }


    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;
    @ViewChild('btnDetailTemplate', { static: true })
    public btnDetailTemplate: TemplateRef<any>;

    @ViewChild('anticipazioneFieldTemplate', { static: true })
    public anticipazioneFieldTemplate: TemplateRef<any>;

    @ViewChild('btnSelectTemplate', { static: true })
    public btnSelectTemplate: TemplateRef<any>;





    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;



    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;



    templateList = [];

    dtOptions: any;

    rows: any[];
    columns = [
        {
            name: '', prop: 'id', sortable: true, filtrable: false, maxWidth: 0, width: 0, visible: false
            // 'buttonsTemplate'
        }

        , {
            name: 'tipologia'
            , prop: 'tipologia.titolo'
            , sortable: true
            , filtrable: true
            , minWidth: 120
            , flexGrow: 3
        }
        , {
            name: 'importo'
            , prop: 'importo'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'currencyValueTemplate'
            , minWidth: 120
            , flexGrow: 3
        }

        , {
            name: 'data<br />erogazione'
            , prop: 'dataEffettiva'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'dateValueTemplate'
            , minWidth: 130
            , flexGrow: 3
        }
        /*,{
            name:'somme a<br /> soggetto attuatore'
            ,prop:'statoAvanzamentoLavori'
            , sortable: true
            , filtrable: true
            , flexGrow: 3
            ,cellTemplate:'anticipazioneFieldTemplate'
            , minWidth: 120
        }*/
        , {
            name: 'modifica'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , minWidth: 60
            , flexGrow: 1

        }
        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , minWidth: 60
            , flexGrow: 1

        }
    ];





    modalTitle: string = '';
    modalButtonLabel: string = '';

    modalRef: BsModalRef;
    modalRefSelectSal: BsModalRef;

    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };



    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;


    editForm: FormGroup;

    constructor(
        private toastr: ToastService
        , private modalService: BsModalService
        , private localeService: BsLocaleService
        , private interventoService: InterventoService
        , private formBuilder: FormBuilder
        , private el: ElementRef
        , private tipologieErogazioniService: TipologiaErogazioneService
        , private tipoProvvedimentoService:TipoProvvedimentoService
        , private orderPipe: OrderPipe
        ,private isDirtyHelper:IsDirtyHelper
        ,private authService:AuthService
    ) {
        this.localeService.use("it");
        this.dtOptions = {
            rows: this.rows
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: false// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: false// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: false
            , scrollbarH: "true"
        };


        this.editForm = this.initializeFormGroup();
        this.editFormFinanziamento = this.initializeFormGroupFinanziamento();
        this.getTipologieErogazioni();
        this.getTipiProvvedimento();
    }
    setIsDirty(value:boolean){
        this.isDirtyHelper.isDirty = value;
    }

    initializeFormGroup(): FormGroup {
        let frm =  this.formBuilder.group({
            id: [null],
            dataPrevista: [null/*, [Validators.required]*/],
            dataEffettiva: [null,[Validators.required]],
            tipologiaId: [null, [Validators.required]],
            importo: [null, [Validators.required]],
            tipoProvvedimento: [null, [Validators.required]],
            numeroProvvedimento: [null, [Validators.required]],
            dataProvvedimento: [null, [Validators.required]],
            anno: [null/*, [Validators.required]*/]
        });
        frm.valueChanges.subscribe(val=>
            this.setIsDirty(frm.dirty)
            );
        return frm;
    }



    ngOnInit() {
        this.templateList = [
            { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }
            , { name: 'btnEditTemplate', template: this.btnEditTemplate }
            , { name: 'btnSelectTemplate', template: this.btnSelectTemplate }
            ,{name:'anticipazioneFieldTemplate',template:this.anticipazioneFieldTemplate}
            ,{name:'btnDetailTemplate',template:this.btnDetailTemplate}
        ];
        window.scroll(0, 0);
    }



    getErogazioniByInterventoId(id: number) {
        this.loadDataCounter++;
        this.interventoService.getErogazioni(id).subscribe(
            result => {
                this.ngxDatatableWrapper.dtOptions =this.ngxDatatableWrapper.dtOptions ;
                this.ngxDatatableWrapper.setInitialData(result);
                this.loadDataCounter--;
            }
        );
    }
currentIntervento:any;
    getInterventoData(id: number) {
        let that = this;
        this.loadDataCounter++;
        this.interventoService.get(id).subscribe(
            result => {
                let status = (result.validazione?(result.validazione.validationStatus?result.validazione.validationStatus:0):0).toString().toUpperCase();
                /*this.readOnlyForm = (status =='1'
                || status =='APPROVED'
                || status =='WAITING_FOR_APPROVAL_FIRST_LEVEL' || status =='4'
                || status =='WAITING_FOR_APPROVAL_SECOND_LEVEL' || status =='5'
                || status =='TERMINATO'
                || result.isReadOnly
                ); */
                that.readOnlyForm =( (status !='APPROVED' && status != '1')
                && !result.allowSalWithoutValidation)||result.isReadOnly || !this.canEdit();
                //that.readOnlyForm = status !='1' && status !='APPROVED';
                that.currentIntervento = result;
                that.columns[5].visible = true;
                that.columns[4].name = 'modifica';
                that.columns[4].cellTemplate = 'btnEditTemplate';
                if (that.readOnlyForm){
                    that.columns[5].visible = false;
                    that.columns[4].name = 'dettaglio';
                    that.columns[4].cellTemplate = 'btnDetailTemplate';
                    //this.columns[8].visible = false;
                }

                that.ngxDatatableWrapper.dtOptions.columns = that.columns;
                that.ngxDatatableWrapper.dtOptions = that.ngxDatatableWrapper.dtOptions;
                that.ngxDatatableWrapper.refresh();
                that.getAnnualitaByInterventoId(that._interventoId);
                that.getErogazioniByInterventoId(that._interventoId);
                that.loadDataCounter--;
                this.finanziamentiList = [];
                result.finanziamenti.forEach(fin=>{
                    fin.finanziamento.titoloLungo = `${fin.finanziamento.codice} - ${fin.finanziamento.titolo}`;
                    this.finanziamentiList.push(fin.finanziamento);
                });

            }
        );
    }

    /*getSalList(dataTablesParameters: any, callback, id: number) {
        this.interventoService.getSalDt(2, dataTablesParameters).subscribe(
            result => {
                this.ngxDatatableWrapperSal.setInitialData(result);
                //console.log(`result= ${JSON.stringify(result)}`);
            }
        );
    }*/


    getAnnualitaByInterventoId(id: number) {
        this.loadDataCounter++;
        this.interventoService.getAnnualita(id).subscribe(
            result => {
                result = this.orderPipe.transform(result, 'anno');
                this.anniList = result;
                this.loadDataCounter--;
            }
        );
    }

    getTipologieErogazioni() {
        this.tipologieErogazioniService.getAll().subscribe(
            result => {
                this.tipologieErogazioni = result;//id e titolo
            }
        );

    }

    getTipiProvvedimento(){
        this.tipoProvvedimentoService.getAll().subscribe(
            result => {
                this.tipiProvvedimento = result;//id e titolo
            }
        );
    }





    openAddModal() {

        //// this.selectedInterventoData = JSON.stringify({interventoId:null,projectId:this.projectId});
        this.modalTitle = 'Nuova erogazione';
        this.editForm.reset();
        this.salList = [];
        this.finanziamentiSelectedList = [];
        this.salListIsError = false;
        this.finanziamentoIsError = false;
        this.modalButtonLabel = 'Inserisci';
        this.modalRef = this.modalService.show(this.templateEditAdd, this.config);
        this.setIsDirty(false);
    }



    onEdit(row, notOpenModal?: boolean) {
        //  this.selectedInterventoData = JSON.stringify({interventoId:row.id,projectId:this.projectId});
        this.modalTitle = 'Modifica erogazione';
        this.modalButtonLabel = 'Aggiorna';
        this.salListIsError = false;
        this.finanziamentoIsError = false;
        this.salList = row.statoAvanzamentoLavori;
        this.finanziamentiSelectedList = row.finanziamenti;
        let dataPrevista = row.dataPrevista && row.dataPrevista != '' ? moment(row.dataPrevista).toDate() : null;
        if(dataPrevista)dataPrevista.setHours(dataPrevista.getHours() - dataPrevista.getTimezoneOffset() / 60);
        let dataEffettiva = row.dataEffettiva && row.dataEffettiva != '' ? moment(row.dataEffettiva).toDate() : null;
        if (dataEffettiva) dataEffettiva.setHours(dataEffettiva.getHours() - dataEffettiva.getTimezoneOffset() / 60);
        let dataProvvedimento = row.dataProvvedimento && row.dataProvvedimento!=''? moment(row.dataProvvedimento).toDate() : null;
        if (dataProvvedimento) dataProvvedimento.setHours(dataProvvedimento.getHours() - dataProvvedimento.getTimezoneOffset() / 60);
        this.editForm.setValue(
            {
                id: row.id
                , tipologiaId: row.tipologia.id
                , importo: '' + row.importo
                , dataPrevista: dataPrevista
                , dataEffettiva: dataEffettiva
                ,tipoProvvedimento:row.tipoProvvedimento?row.tipoProvvedimento.id:''
                ,numeroProvvedimento:row.numeroProvvedimento?row.numeroProvvedimento:''
                ,dataProvvedimento:dataProvvedimento
                ,anno :row.anno?row.anno:''
            }
        );
        this.editForm.enable();
        if(this.readOnlyForm)this.editForm.disable();
        if (!notOpenModal) this.modalRef = this.modalService.show(this.templateEditAdd, this.config);
        this.setIsDirty(false);
    }

    onDelete(row) {
        console.log(row);
        if (!confirm(`Stai per eliminare l'erogazione "${row.tipologia.titolo} - ${row.importo}", proseguire?`)) return;
        this.loading = true;
        let myService = this.interventoService;
        myService.deleteErogazione(this.interventoId, row.id).subscribe(
            result => {
                this.loading = false;
                this.ngxDatatableWrapper.deleteRow(row);
                this.toastr.success('Erogazione eliminata con successo', null, {
                    timeOut: 2000, disableTimeOut: false
                });
                this.onSubmitOk.emit(row);
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile eliminare l'erogazione: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                console.log('error');
            });
    }


    closeModal() {
        if(!this.isDirtyHelper.checkIsDirty())return false;
        this.modalRef.hide();
    }

    onSubmit() {
        this.submitted = true;
        this.editForm.updateValueAndValidity();
        for (let c in this.editForm.controls) {
            this.editForm.controls[c].updateValueAndValidity();
        }
        let errors = false;
        for (let c in this.editForm.controls) {
            errors = errors || this.editForm.controls[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.editForm.controls[c].errors)
            );
        }

        this.salListIsError = !this.salList || this.salList.length<=0;
        this.finanziamentoIsError = !this.finanziamentiSelectedList || this.finanziamentiSelectedList.length<=0;

        if (this.editForm.invalid || errors || this.salListIsError || this.finanziamentoIsError) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.editForm.controls) {
                if (this.editForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.editForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.editForm.get(el)["controls"]) {
                            if (this.editForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }

        const obj = this.editForm.value;
        obj.statoAvanzamentoLavori = this.salList;
        obj.finanziamenti = this.finanziamentiSelectedList;

        //obj.dataPrevista = moment(this.editForm.controls.dataPrevista.value, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss');//.utc(false).toDate();
        if (this.editForm.controls.dataPrevista.value && this.editForm.controls.dataPrevista.value != '') obj.dataPrevista = moment(this.editForm.controls.dataPrevista.value, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss');//.utc(false).toDate();
        if (this.editForm.controls.dataEffettiva.value && this.editForm.controls.dataEffettiva.value != '') obj.dataEffettiva = moment(this.editForm.controls.dataEffettiva.value, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss');//.utc(false).toDate();
        if (this.editForm.controls.dataProvvedimento.value && this.editForm.controls.dataProvvedimento.value != '') obj.dataProvvedimento = moment(this.editForm.controls.dataProvvedimento.value, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss');//.utc(false).toDate();
        obj.tipologia = this.tipologieErogazioni.find(x => x.id == obj.tipologiaId);
        obj.tipoProvvedimento = this.tipiProvvedimento.find(x=>x.id == obj.tipoProvvedimento);
        obj.interventoId = this.interventoId;


//v- 31/08/2020: il valore dell'erogazione deve essere <= della somma dei valori dei sal
//il calcolo lo lascio fare al server
        /*let sommaValoriSal = 0;
        obj.statoAvanzamentoLavori.forEach(element => {
            sommaValoriSal += element.importo;
        });
        if (parseFloat(obj.importo) >sommaValoriSal){
            this.toastr.error(`Impossibile salvare l'erogazione: l'importo è maggiore della somma degli importi dei SAL`, null, {
                timeOut: 2000, disableTimeOut: false
            });
            return;
        }*/
        this.loading = true;
        this.interventoService.saveErogazione(obj).subscribe(res => {
            this.loading = false;
            this.submitted = false;
            this.toastr.success("Erogazione salvata con successo!");

            let sommafin = 0;
            obj.finanziamenti.forEach(f=>sommafin += f.importo);
            if (sommafin.toFixed(2)!=parseFloat(obj.importo).toFixed(2))
                this.toastr.warning('Attenzione, la somma degli importi attribuiti ai finanziamenti è minore dell\'importo dell\'erogazione', null, {
                    timeOut: 2000,disableTimeOut: false
                });



            this.submitted = false;
            this.setIsDirty(false);
            if (!obj.id) {
                //new row
                this.ngxDatatableWrapper.addRow(res);
                this.onEdit(res, true);
            }
            else {
                this.onEdit(res, true);
                this.ngxDatatableWrapper.updateRow(res);
            }
            this.onSubmitOk.emit(res);

        }, error => {
            this.loading = false;
            this.toastr.error(`Errore: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`);
        });
    }

    removeSal(item) {
        console.log(`inside removeSal id=${item.id}`);
        this.salList = this.salList.filter(x => x.id != item.id);
        this.toastr.success('Sal rimosso con successo', null, {
            timeOut: 2000, disableTimeOut: false
        });
        this.setIsDirty(true);
    }

    closeModalSelectSal() {
        this.modalRefSelectSal.hide();
    }

    /*openModalSALold() {
        this.modalRefSelectSal = this.modalService.show(this.templateModalSelectSal, this.config);
    }
    addSal(row) {
        console.log('inside ok of addSal');
        let existsObj = this.salList.find(x => x.id == row.id);
        if (existsObj) return;//already added
        this.salList.push(row);
        this.salList = Object.assign([]
            , this.salList
        );

        this.toastr.success('SAL aggiunto con successo', null, {
            timeOut: 2000, disableTimeOut: false
        });
    }*/


    openModalSAL() {
        //v-03/09/2020: un erogazione può essere associata ad un solo sal
        if (this.salList.length>0){
            this.toastr.warning('Erogazione già associata ad un SAL/Altro', null, {
                timeOut: 2000, disableTimeOut: false
            });
            return;
        }
        let that = this;
        let modalDefinition = {
             pageTitle: 'Seleziona i SAL/Altro'
        };

        let modalDefinitionNgx = {
            rows: []
            , totalElements: 0
            , columns: [
                {
                    name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
                    , resizeable: false
                }

                , {
                    name: 'categoria'
                    , prop: 'categoria.titolo'

                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 100

                }
                , {
                    name: 'importo'
                    , prop: 'importo'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 2
                    , minWidth: 100
                }
                , {
                    name: 'data<br /> competenza SAL'
                    , prop: 'dataLavori'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , cellTemplate: 'dateValueTemplate'
                    , flexGrow: 1
                    , minWidth: 100
                }
                /*,{
                    name:'somme<br />a soggetto attuatore'
                    ,prop:'anticipazioneASoggettoAttuatore'
                    , sortable: true
                    , filtrable: true
                    , flexGrow: 1
                    ,cellTemplate:'booleanValueTemplate'
                    , minWidth: 60
                }*/
            ]
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , ajax: this.getSalDtNgx
            , scrollbarH: "true"
        };

        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: 'modal-xl'
        };
        const initialState = { modalDefinition: modalDefinition, dtOptionsNgx: modalDefinitionNgx };





        this.modalRefSelectSal = this.modalService.show(GenericSelectionModalComponent, Object.assign({}, config, { initialState }));
        this.clearSubscriptions();
        let subscription = this.modalRefSelectSal.content.onSubmitOk.subscribe(
            data => {
                console.log('inside ok of SAL');
                if (!this.salList) this.salList = [];
                if (this.salList.length>0){
                    this.toastr.warning('Erogazione già associata ad un SAL/Altro', null, {
                        timeOut: 2000, disableTimeOut: false
                    });
                    return;
                }
                let existsObj = this.salList.find(x => x.id == data.id);
                if (existsObj) return;//already added
                this.salList.push(data);
                this.salList = Object.assign([]
                    , this.salList
                );

                this.toastr.success('SAL/Altro aggiunto con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                this.setIsDirty(true);

            }
        );
        this.modalSubscriptions.push(subscription);
    }
    modalSubscriptions = [];
    clearSubscriptions() {
        this.modalSubscriptions.forEach(x => x.unsubscribe());
        this.modalSubscriptions = [];
    }

    getSalDtNgx = (dataTablesParameters: any, callback: any) => {
        this.interventoService.getSalDt(this.interventoId, dataTablesParameters)
            .subscribe(resp => {
                callback(resp);
            });
    }

    printValueAsDate(value) {
        if (!value || value == '') return '';
        let v = moment(value).toDate();
        //v.setHours(v.getHours() - v.getTimezoneOffset() / 60);
        return moment(v).format('DD/MM/YYYY');
    }

    printValueAsBoolean(value){
        if (value)return "Si";
        return "No";
    }
    printValueAsCurrency(value) {
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }

    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
    gotoBackBtnClick() {
        this.gotoBtnFunction.emit(true);
    }

    reloadData(){
        this.loadDataCounter = 0;
        if (this._interventoId)this.getInterventoData(this._interventoId);
    }

    printAnticipazioneTemplate(field){
        if (field && field.length>0 && field[0].anticipazioneASoggettoAttuatore)return "Si";
        return 'No';
    }

    finanziamentiList: any[];
    finanziamentiSelectedList :any[];
    modalRefTemplateFinanziamenti: BsModalRef;
    editFormFinanziamento: FormGroup;
    submittedFinanziamento: boolean = false;
    tempFinanziamentoId:number = -1;

    @ViewChild('templateAddFinanziamento', { static: true })
    public templateAddFinanziamento: TemplateRef<any>;

    initializeFormGroupFinanziamento(): FormGroup {
        return this.formBuilder.group({
            id: [null],
            importo: [null, [Validators.required]]
            , finanziamentoId: [null,[Validators.required]]
        });
    }


    closeModalFinanziamenti() {
        this.modalRefTemplateFinanziamenti.hide();
    }
    openModalFinanziamenti() {
        this.editFormFinanziamento.reset();
        this.submittedFinanziamento = false;
        this.modalRefTemplateFinanziamenti = this.modalService.show(this.templateAddFinanziamento, this.config);

    }

    removeFinanziamento(item) {
        console.log(`inside removeFinanziamento id=${item.id}`);
        this.finanziamentiSelectedList = this.finanziamentiSelectedList.filter(x => x.id != item.id);
        this.toastr.success('Finanziamento rimosso con successo', null, {
            timeOut: 2000, disableTimeOut: false
        });
        this.setIsDirty(true);
    }




    onSubmitFinanziamento() {
        this.submittedFinanziamento = true;
        var errors = false;
        for (let c in this.editFormFinanziamento.controls) {
            errors = errors || this.editFormFinanziamento.controls[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.editFormFinanziamento.controls[c].errors)
            );
        }


        console.log("errors:" + errors);
        if (this.editFormFinanziamento.invalid || errors
        ) {
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (var el in this.editFormFinanziamento.controls) {
                if (this.editFormFinanziamento.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.editFormFinanziamento.controls[el] instanceof FormGroup) {
                        for (var el1 in this.editFormFinanziamento.get(el)["controls"]) {
                            if (this.editFormFinanziamento.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            console.log("invalid");
            return false;
        }
        let importoObj = this.editFormFinanziamento.value;
        console.log(`this.editFormFinanziamento.value = ${JSON.stringify(this.editFormFinanziamento.value)}`);
        if (!this.finanziamentiSelectedList) this.finanziamentiSelectedList = [];


        importoObj.importo = parseFloat(importoObj.importo);
        let o = {id:this.tempFinanziamentoId--,
            importo:importoObj.importo
            ,finanziamento:this.finanziamentiList.find(f=>f.id == importoObj.finanziamentoId)};
        this.finanziamentiSelectedList.push(o);
        this.toastr.success(`Finanziamento aggiunto con successo`, null, {
            timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
        });

        this.closeModalFinanziamenti();
        this.submittedFinanziamento = false;
        this.setIsDirty(true);
    }

    canEdit() {
        return (this.enableSection(this.section, ['update'],false,true));
    }    


    /**
* Controlla se l'utente può accedere o meno alla sezione.
* @param section il nome della sezione
* @param operations le operazioni da poter effettuare sulla sezione
* @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
* @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
* @returns true se il controllo è andato a buon fine, false altrimenti
*/
    enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
        return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
    }



}
