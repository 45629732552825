<!--new version of menu -->

<nav class="navbar navbar-expand-md navbar-dark bg-0083e0">
    <!--hamburger menu-->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <!-- logo mobile-->
    <div class="d-block d-md-none text-white pointer text-uppercase" (click)="goToHome()">

        <h5 class="mb-0">{{title}}</h5>

    </div>

    <!-- fine hamburger menu-->
    <div class="collapse navbar-collapse" id="navbarMenu">
        <ul class="navbar-nav w-100 d-flex justify-content-around">

            <ng-container *ngFor="let item of items;let i = index">
                <!--link normale senza subItems-->
                <li *ngIf="!(item.subMenu && item.subMenu.length>0)" class="nav-item " [ngClass]="item.cssClass" [hidden]="!canAccess(item)">
                    <a class="nav-link btn btn-primary text-white px-md-1 px-lg-2" (click)="gotoPage(item,$event);">{{item.title}}</a>
                </li>
                <!--dropdown normale-->
                <!-- <li *ngIf="(item.subMenu && item.subMenu.length>0  && !item.megamenu)" class="nav-item dropdown " [ngClass]="item.cssClass" [hidden]="!canAccess(item)">
                    <a class="nav-link dropdown-toggle btn btn-primary text-white" id="{{getElementId(i)}}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{item.title}}</a>
                    <div class="dropdown-menu top-120 shadow-sm p-3 mb-5 bg-white rounded" attr.aria-labelledby="{{getElementId(i)}}">
                        <a *ngFor="let subItem of item.subMenu" class="dropdown-item py-3 item-megamenu" (click)="gotoPage(subItem,$event);">{{subItem.title}}<i aria-hidden="true" class="fas fa-chevron-right my-auto py-2  text-justify"></i></a>
                    </div>
                </li> -->
                <li *ngIf="(item.subMenu && item.subMenu.length>0  && !item.megamenu)" class="nav-item dropdown position-relative text-white" [ngClass]="item.cssClass" [hidden]="!canAccess(item)">
                    <a class="nav-link dropdown-toggle btn btn-primary text-white px-md-1 px-lg-2" id="{{getElementId(i)}}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{item.title}}</a>
                    <div class="dropdown-menu  position-absolute  shadow-sm p-3 mb-5 bg-white rounded top-120 menu-z" attr.aria-labelledby="{{getElementId(i)}}">
                        <div class="row">
                            <ng-container *ngFor="let subItem of item.subMenu">
                                <ng-container *ngIf="canAccess(subItem)">
                                    <div class="d-flex wf mx-3 item-megamenu border-bottom border-002742 pointer">
                                        <a class="dropdown-item py-3 px-1" (click)="gotoPage(subItem,$event);">{{subItem.title}}<i aria-hidden="true"
                                                class="fas fa-chevron-right my-auto py-1 pr-1 float-right"></i></a>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </li>

                <!--megamenu-->
                <li *ngIf="(item.subMenu && item.subMenu.length>0  && item.megamenu)" class="nav-item dropdown position-static text-white" [ngClass]="item.cssClass" [hidden]="!canAccess(item)">
                    <a class="nav-link dropdown-toggle btn btn-primary text-white px-md-1 px-lg-2" id="{{getElementId(i)}}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{item.title}}</a>
                    <div class="dropdown-menu position-absolute megamenu w-100 shadow-sm px-3 pb-3 pt-0 bg-white rounded menu-z" attr.aria-labelledby="{{getElementId(i)}}">
                        <div class="row ">
                            <ng-container *ngFor="let subItem of item.subMenu">
                                <ng-container *ngIf="canAccess(subItem)">
                                    <div class="d-flex wf mx-3  item-megamenu border-bottom border-002742 pointer">
                                        <a class="dropdown-item py-3 px-1" (click)="gotoPage(subItem,$event);">{{subItem.title}}
                                          <i aria-hidden="true" class="fas fa-chevron-right my-auto py-1 pr-1 float-right"></i>
                                        </a>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>
    </div>
</nav>


<!--old menu, with static columns-->
<nav class="navbar navbar-expand-md navbar-dark bg-primary " *ngIf="false">
    <!--hamburger menu-->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <!-- fine hamburger menu-->
    <div class="collapse navbar-collapse" id="navbarMenu">
        <ul class="navbar-nav w-100 d-flex justify-content-around">
            <ng-container *ngFor="let item of items;let i = index">
                <li *ngIf="!(item.subMenu && item.subMenu.length>0)" class="nav-item" [ngClass]="item.cssClass" [hidden]="!canAccess(item)">
                    <a class="nav-link btn btn-primary" (click)="gotoPage(item,$event);">{{item.title}}</a>
                </li>

                <!--dropdown normale-->
                <li *ngIf="(item.subMenu && item.subMenu.length>0  && !item.megamenu)" class="nav-item dropdown" [ngClass]="item.cssClass" [hidden]="!canAccess(item)">
                    <a class="nav-link dropdown-toggle btn btn-primary" id="{{getElementId(i)}}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{item.title}}</a>
                    <div class="dropdown-menu top-120 shadow-sm p-3 mb-5 bg-white rounded" attr.aria-labelledby="{{getElementId(i)}}">
                        <a *ngFor="let subItem of item.subMenu" class="dropdown-item btn btn-outline-primary" (click)="gotoPage(subItem,$event);">{{subItem.title}}</a>
                    </div>
                </li>
                <!--megamenu-->
                <li *ngIf="(item.subMenu && item.subMenu.length>0  && item.megamenu)" class="nav-item dropdown position-static" [ngClass]="item.cssClass" [hidden]="!canAccess(item)">
                    <a class="nav-link dropdown-toggle btn btn-primary" id="{{getElementId(i)}}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{item.title}}</a>
                    <div class="dropdown-menu position-absolute megamenu w-100 shadow-sm p-3 mb-5 bg-white rounded" attr.aria-labelledby="{{getElementId(i)}}">
                        <div class="row">
                            <div class="col-sm-6 col-lg-3" *ngFor="let subItem of item.subMenu">
                                <a [hidden]="!canAccess(thirdItem)" *ngFor="let thirdItem of subItem" class="dropdown-item btn btn-outline-primary py-2" (click)="gotoPage(thirdItem,$event);" href="javascript:;;">{{thirdItem.title}}</a>
                            </div>
                        </div>
                    </div>
                </li>

            </ng-container>

        </ul>
    </div>
</nav>
