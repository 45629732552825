import { Component, OnInit, Input } from "@angular/core";
import { News } from "src/app/models/news";
import { NewsService } from "src/app/services/news.service";
import * as FileSaver from "file-saver";

@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.css"]
})
export class NewsComponent implements OnInit {
  @Input() news: News;
  showExpandAnchor:boolean=false;
  showExpandedText:boolean=false;
  constructor(private newsService: NewsService) {}

  ngOnInit() {
      this.showExpandAnchor= this.news.message.trim().length>110;
        if (this.showExpandAnchor){
            console.log(`news.message.substr(0,110)=${this.news.message.trim().substr(0,110)}`);
            console.log(`news.message.substr(110,news.message.length-110)=${this.news.message.trim().substr(110,this.news.message.length-110)}`);
        }
  }

  downloadFile(news: News) {
    this.newsService.downloadAttach(news).subscribe(res => {
        let filename = news.filePath.replace(/^.*[\\\/]/, '');
        if (!filename || filename == '')filename = `attach.${news.fileExt}`;
      FileSaver.saveAs(res.body, filename);
    });
  }
  showExpandAnchorClick(){
    this.showExpandAnchor = false;
    this.showExpandedText = true;
  }
}
