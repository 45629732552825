<div class="container sfondo_bianco">
<h3 *ngIf="false" class="text-center mb-4">
    <b>{{interventoData && interventoId?'Modifica intervento':'Nuovo intervento'}}</b></h3>

    <progetto-div-dettaglio-component [datiDettaglioIntervento]="datiDettaglioIntervento" [datiDettaglioProgetto]="datiDettaglioProgetto" class="row  justify-content-star"></progetto-div-dettaglio-component>

<div id="tabs" #tabs>
    <ul class="nav nav-tabs position-sticky sticky-top  bg-white pt-4 pb-1">
        <li class=" nav-item mt-1 px-1">
            <a (click)="confirmChangeTab($event,0); " [ngClass]="{ 'active':activeTab==='tabDatiGenerali' } " class="tab-menu nav-link px-1 " href="#divTabDatiGeneraliProgettoContent" data-toggle="tab">Dati generali</a>
        </li>
        <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,7); " [ngClass]="{ 'active':activeTab==='tabAnnualita' } " class="tab-menu nav-link px-1 " href="#divTemplateAnnualita" data-toggle="tab">Annualità</a>
        </li>
        <li class="nav-item mt-1 px-1" [hidden]="isInInsertState ||(interventoObj && interventoObj.pnrrTerritoriale)">
            <a (click)="confirmChangeTab($event,2); " [ngClass]="{ 'active':activeTab==='tabFinanziamenti' } " class="tab-menu nav-link px-1 " href="#divTabFinanziamentiContent" data-toggle="tab">Finanziamenti</a>
        </li>
        <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,1); " [ngClass]="{ 'active':activeTab==='tabFasi' } " class="tab-menu nav-link px-1 " href="#divTabFasiContent" data-toggle="tab">Fasi intervento</a>
        </li>
        <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,13);" [ngClass]="{ 'active':activeTab==='tabProvvedimentiDocumenti'}"
            class="tab-menu nav-link px-1" href="#divProvvedimentiDocumenti" data-toggle="tab">Provvedimenti/Documentazione</a>
        </li>

        <!--<li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,6); " [ngClass]="{ 'active':activeTab==='tabProvvedimenti' } " class="tab-menu nav-link px-1 " href="#divTabProvvedimentiContent" data-toggle="tab">Provvedimenti</a>
        </li>-->
        <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,4); " [ngClass]="{ 'active':activeTab==='tabSal' } " class="tab-menu nav-link px-1 " href="#divTabSalContent" data-toggle="tab">
                <a type="button" class="default" popover="Stati di Avanzamento Lavori e/o tutte le somme da corrispondere previste nel quadro tecnico economico " triggers="mouseenter:mouseleave" placement="top">
                    <i class="far fa-question-circle help" style="font-size: 14px; ">
                    </i>
                </a>SAL/ALTRO
            </a>
        </li>

        <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,3); " [ngClass]="{ 'active':activeTab==='tabErogazioni' } " class="tab-menu nav-link px-1" href="#divTabErogazioniContent" data-toggle="tab">Erogazioni</a>
        </li>

        <!--<li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,8); " [ngClass]="{ 'active':activeTab==='tabDocumentazione' } " class="tab-menu nav-link px-1" href="#divTemplateAnnualita" data-toggle="tab">Documentazione</a>
        </li>-->
        <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,11); " [ngClass]="{ 'active':activeTab==='tabMonitoraggio' } " class="tab-menu nav-link px-1" href="#divTemplateMonitoraggio" data-toggle="tab">Altri dati</a>
        </li>
        <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,9); " [ngClass]="{ 'active':activeTab==='tabRiepilogo' } " class="tab-menu nav-link px-1" href="#divTemplateRiepilogo" data-toggle="tab">Riepilogo</a>
        </li>
        <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,10); " [ngClass]="{ 'active':activeTab==='tabStorico' } " class="tab-menu nav-link px-1" href="#divTemplateStorico" data-toggle="tab">Storico</a>
        </li>
        <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,12); " [ngClass]="{ 'active':activeTab==='tabDnsh' } " class="tab-menu nav-link px-1" href="#divTemplateDnsh" data-toggle="tab">DNSH</a>
        </li>
        <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,14); " [ngClass]="{ 'active':activeTab==='tabBdap' } " class="tab-menu nav-link px-1" href="#divTemplateBdap" data-toggle="tab">BDAP</a>
        </li>
        <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
          <a (click)="confirmChangeTab($event,15);" [ngClass]="{ 'active':activeTab==='tabSicerBilancio'}"
            class="tab-menu nav-link px-1" href="#divSicerBilancioContent" data-toggle="tab">SICER - Contabilità</a>
        </li>
        <li class="nav-item mt-1 px-1" [hidden]="isInInsertState || !finanziamentiIntervento || (finanziamentiIntervento && finanziamentiIntervento.length<1)">
          <a (click)="confirmChangeTab($event,16);" [ngClass]="{ 'active':activeTab==='tabSubInterventi'}"
            class="tab-menu nav-link px-1" href="#divSubInterventi" data-toggle="tab">Sub-Interventi</a>
        </li>
        <li class="nav-item mt-1 px-1" [hidden]="isInInsertState || sismaHidden">
          <a (click)="confirmChangeTab($event,17);" [ngClass]="{ 'active':activeTab==='tabSisma'}"
            class="tab-menu nav-link px-1" href="#divSismaContent" data-toggle="tab">Sisma</a>
        </li>
    </ul>
    <div class="tab-content ">
        <div class="tab-pane " id="divTabFasiContent" [ngClass]="{ 'active':activeTab==='tabFasi' } ">
            <fasi-component id="tabFasiProgettoContent" [interventoId]="interventoId" #tabFasiProgettoContent [gotoBtnOptions]="{show:true,label: 'Chiudi'} " (gotoBtnFunction)="closeModal($event)"></fasi-component>
        </div>
        <div class="tab-pane " id="divTabDatiGeneraliProgettoContent" [ngClass]="{ 'active':activeTab==='tabDatiGenerali' } ">
            <dati-generali-component #tabDatiGeneraliProgettoContent
            [interventoData]="interventoData" (onSubmitOk)="datiGeneraliSaved($event) "
            [gotoBtnOptions]="{show:true,label: 'Chiudi',showIcon:true} "
            (gotoBtnFunction)="closeModal($event) ">
            </dati-generali-component>
        </div>
        <div class="tab-pane " id="divTabFinanziamentiContent" [ngClass]="{ 'active':activeTab==='tabFinanziamenti' } ">
            <intervento-finanziamento-component [gotoBtnOptions]="{show:true,label: 'Chiudi',showIcon:true} " (gotoBtnFunction)="closeModal($event) " id="tabFinanziamentiContent" [interventoId]="interventoId" (reloadFinanziamentiForSubInterventi)="getInterventoFinanziamentiById(interventoId)" #tabFinanziamentiContent></intervento-finanziamento-component>
        </div>
        <div class="tab-pane " id="divTabErogazioniContent" [ngClass]="{ 'active':activeTab==='tabErogazioni' } ">
            <intervento-erogazioni-component [gotoBtnOptions]="{show:true,label: 'Chiudi',showIcon:true} " (gotoBtnFunction)="closeModal($event) " id="tabErogazioniContent" [interventoId]="interventoId" #tabErogazioniContent (onSubmitOk)="erogazioneSaved($event) "></intervento-erogazioni-component>
        </div>
        <div class="tab-pane " id="divTabSalContent" [ngClass]="{ 'active':activeTab==='tabSal' } ">
            <intervento-sal-component [gotoBtnOptions]="{show:true,label: 'Chiudi',showIcon:true} " (gotoBtnFunction)="closeModal($event) " id="tabSalContent" [interventoId]="interventoId" #tabSalContent (onSubmitOk)="salSaved($event) "></intervento-sal-component>
        </div>
        <div class="tab-pane " id="divTemplateAnnualita " [ngClass]="{ 'active':activeTab==='tabAnnualita' } ">
            <associa-annualita-component [gotoBtnOptions]="{show:true,label: 'Chiudi',showIcon:true} " (gotoBtnFunction)="closeModal($event) " id="templateAnnualita" [interventoId]="interventoId" #tabAnnualitaContent>
            </associa-annualita-component>
        </div>
        <!--<div class="tab-pane " id="divTabProvvedimentiContent" [ngClass]="{ 'active':activeTab==='tabProvvedimenti' } ">
            <associa-provvedimenti-component [gotoBtnOptions]="{show:true,label: 'Chiudi',showIcon:true} " (gotoBtnFunction)="closeModal($event)" id="templateProvvedimenti" [interventoId]="interventoId" #tabProvvedimentiContent>
            </associa-provvedimenti-component>
        </div>-->
        <!--<div class="tab-pane " id="divTabDocumentazioneContent" [ngClass]="{ 'active':activeTab==='tabDocumentazione' } ">
            <documentazione-component [gotoBtnOptions]="{show:true,label: 'Chiudi',showIcon:true} " (gotoBtnFunction)="closeModal($event)" [interventoId]="interventoId" #tabDocumentazioneContent>
            </documentazione-component>
        </div>-->
        <div class="tab-pane " id="divTemplateRiepilogoIntervento" [ngClass]="{ 'active':activeTab==='tabRiepilogo' } ">
            <!--***-->
            <riepilogo-intervento-component [interventoId]="interventoId" #tabRiepilogoInterventoContent
             [gotoBtnOptions]="{show:true,label: 'Chiudi',showIcon:true} "
             (gotoBtnFunction)="closeModal($event) ">
            </riepilogo-intervento-component>
            <!--***-->
        </div>
        <div class="tab-pane " id="divTemplateStorico" [ngClass]="{ 'active':activeTab==='tabStorico' } ">
            <storico-intervento-component
            [isInModal]="true" #divTemplateStorico
            [interventoId]="interventoId"
            [gotoBtnOptions]="{show:true,label: 'Chiudi',showIcon:true} "
            (gotoBtnFunction)="closeModal($event) ">
            </storico-intervento-component>
        </div>

        <div class="tab-pane" id="divTemplateMonitoraggio" [ngClass]="{ 'active':activeTab==='tabMonitoraggio' } ">
            <monitoraggio-component [isInModal]="true"
             #divTemplateMonitoraggio [interventoId]="interventoId"
              [gotoBtnOptions]="{show:true,label: 'Chiudi',showIcon:true} "
               (gotoBtnFunction)="closeModal($event) ">
            </monitoraggio-component>
        </div>

        <div class="tab-pane" id="divTemplateDnsh" [ngClass]="{ 'active':activeTab==='tabDnsh' } ">
          <dnsh-component *ngIf="activeTab==='tabDnsh'"
          [isInModal]="true" #divTemplateDnsh
          [interventoId]="interventoId"
          [gotoBtnOptions]="{show:true,label: 'Chiudi',showIcon:true} " (gotoBtnFunction)="closeModal($event) ">
          </dnsh-component>
        </div>
        <div class="tab-pane " id="divProvvedimentiDocumenti" [ngClass]="{ 'active':activeTab==='tabProvvedimentiDocumenti'}">
            <associa-provvedimenti-documenti-component [isInModal]="true" #divProvvedimentiDocumentiContent
              [interventoId]="interventoId"
              [gotoBtnOptions]="{show:true,label: 'Chiudi',showIcon:true}"
              (gotoBtnFunction)="closeModal($event) "
              >
            </associa-provvedimenti-documenti-component>
        </div>
        <div class="tab-pane" id="divTemplateBdap" [ngClass]="{ 'active':activeTab==='tabBdap' } ">
            <bdap-component *ngIf="activeTab==='tabBdap'" #divTemplateBdap [interventoId]="interventoId" [cup]="cup" [gotoBtnOptions]="{show:true,label: 'Chiudi',showIcon:true} " (gotoBtnFunction)="closeModal($event) "  [isInModal]="true">
            </bdap-component>
        </div>
        <div class="tab-pane " id="divSicerBilancioContent" [ngClass]="{ 'active':activeTab==='tabSicerBilancio'}">
          <sicer-bilancio-component [isInModal]="true" #divSicerBilancioContent
            [type]="type"
            [interventoId]="interventoId"
            [gotoBtnOptions]="{show:true,label: 'Chiudi',showIcon:true}"
            (gotoBtnFunction)="closeModal($event)"
            >
          </sicer-bilancio-component>
        </div>

        <div class="tab-pane " id="divSubInterventi" [ngClass]="{ 'active':activeTab==='tabSubInterventi'}">
          <app-sub-interventi [isInModal]="true" #divSubInterventi
          [interventoId]="interventoId"
          [isInModal]="true"
          [codiceIntervento]="codiceIntervento"
          [gotoBtnOptions]="{show:true, add: 'Aggiungi Sub Intervento',label: 'Chiudi',showIcon:true}"
          (gotoBtnFunction)="closeModal($event) "
          >
          </app-sub-interventi>
        </div>

        <div class="tab-pane " id="divSismaContent" [ngClass]="{ 'active':activeTab==='tabSisma'}" >
          <app-interventi-sisma [isInModal]="true" #divSismaContent
          [interventoId]="interventoId"
          [gotoBtnOptions]="{show:true, add: 'Aggiungi Sisma',label: 'Chiudi', showIcon:true}"
          (gotoBtnFunction)="closeModal($event) "
          >
          </app-interventi-sisma>
        </div>
    </div>
</div>
