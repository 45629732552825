<ng-container *ngIf="finanziamentoId && finanziamentoId>=0">

    <div class="container p-0">
        <div class="row">


            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="">Codice Finanziamento: <span class="text-0083e0">{{codiceFinanziamento}}</span></div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="">Descrizione&nbsp;Finanziamento: <span class="text-0083e0">{{descrizioneFinanziamento}}</span></div>

            </div>            
        </div>
       
    </div>



    

    <div class="container p-0">
        <hr>
        <div class="row justify-content-center">



            <div class="col-lg-5 col-md-6 col-sm-12 col-12 ">


                <table>

                    <tr>
                        <td class="text-right">Valore finanziamento:</td>
                        <td class="text-0083e0 text-left">{{printValueAsCurrency(valoreFinanziamento)}}</td>

                    </tr>
                    <tr>
                        <td class="text-right">Di cui allocato:</td>
                        <td class="text-0083e0 text-left">{{printValueAsCurrency(valoreFinanziamentoAllocato)}}</td>

                    </tr>
                    <tr>
                        <td class="text-right">Di cui destinato:</td>
                        <td class="text-0083e0 text-left">{{printValueAsCurrency(valoreFinanziamentoUtilizzato)}}</td>

                    </tr>
                    <tr>
                        <td class="text-right">Disponibilità:</td>
                        <td class="text-0083e0 text-left">{{printValueAsCurrency(disponibilitaFinanziamento)}}</td>

                    </tr>
                </table>

            </div>


            



        </div>


        <hr>
    </div>





</ng-container>


