import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    TemplateRef
} from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TipologiaSoggettoResponsabile, SoggettoResponsabile } from '../../models';
import { TipologiaSoggettoResponsabileService, SoggettoResponsabileService } from '../../services';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { ColumnMode } from '@swimlane/ngx-datatable';


@Component({
    selector: 'soggetto-responsabile-component',
    templateUrl: './soggetto-responsabile.component.html',
    styleUrls: ['./soggetto-responsabile.component.css']
})
export class SoggettoResponsabileComponent
    implements OnInit {
    dtOptions: any = {};
    submitted: boolean = false;
    loading: boolean = false;
    editForm: FormGroup;
    modalRef: BsModalRef;
    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };
    open: boolean = false;
    buttonLabel: string = "Inserisci";

    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;
    @ViewChild('templateModal', { static: true })
    public templateModal: TemplateRef<any>;

    //@ViewChild('buttonsTemplate',{static:false}) buttonsTemplate: TemplateRef<any>;
    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    @ViewChild('dateTemplate', { static: true })
    public dateTemplate: TemplateRef<any>;


    @ViewChild('emailTemplate', { static: true })
    public emailTemplate: TemplateRef<any>;
    

    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    templateList = [];

    columns = [
        {
            name: 'id'
            , prop: 'id'
            , sortable: true
            , filtrable: true
            //, width: 10
            , flexGrow: 1
            , minWidth: 60
        }

        , {
            name: 'cognome'
            , prop: 'cognome'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            , minWidth: 100
        }
        , {
            name: 'nome'
            , prop: 'nome'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            , minWidth: 100
        }
        , {
            name: 'email'
            , prop: 'email'
            , sortable: true
            , filtrable: true
            , flexGrow: 3
            , minWidth: 100
            ,cellTemplate:'emailTemplate'
        }
        , {
            name: 'tipologia'
            , prop: 'tipologia.descrizione'
            , sortable: true
            , filtrable: true
            , flexGrow: 7
            , minWidth: 200
        }
        , {
            name: 'modifica'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60

        }
        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60

        }
    ];

    tipologie = [];
    emails:FormArray;
    constructor(
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private el: ElementRef,
        private localeService: BsLocaleService,
        private toastr: ToastService,
        private modalService: BsModalService,
        private currentService: SoggettoResponsabileService,
        private tipologiaService: TipologiaSoggettoResponsabileService,
    ) {
        this.localeService.use("it");
        this.dtOptions = {
            rows: []
            , totalElements: 0
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: false// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: false// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: false
            ,scrollbarH:"true"
        };
        this.getTipologie();
        this.getSoggetti();
    }

    ngOnInit() {
        window.scroll(0, 0);
        this.templateList = [{name:'emailTemplate',template:this.emailTemplate},{ name: 'dateTemplate', template: this.dateTemplate }, { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }, { name: 'btnEditTemplate', template: this.btnEditTemplate }];
        this.editForm = this.initializeFormGroup();

    }


    getTipologie() {
        this.tipologiaService.getAll().subscribe(
            result => {

                this.tipologie = result;

            }
        );

    }
    getSoggetti() {
        this.currentService.getAll().subscribe(
            result => {

                this.ngxDatatableWrapper.setInitialData(result);

            }
        );

    }

    initializeFormGroup() {
        return this.formBuilder.group({
            id: [null],
            cognome: [null, [Validators.required, Validators.maxLength(255)]],
            nome: [null, [Validators.required, Validators.maxLength(255)]],
            //email: [null, [Validators.required, Validators.maxLength(255), Validators.email]],
            tipologia: [null, [Validators.required]]
            ,emails:this.formBuilder.array([
                this.formBuilder.group({email: [null, [Validators.required, Validators.maxLength(255), Validators.email]]})
            ]

            )
        });
    }

    get f() {
        return this.editForm.controls;
    }


    onSubmit() {
        this.submitted = true;
        this.editForm.updateValueAndValidity();
        let errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );
        }
        if (this.editForm.invalid || errors) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.editForm.controls) {
                if (this.editForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.editForm.controls[el] instanceof FormGroup || this.editForm.controls[el] instanceof FormArray) {
                        for (let el1 in this.editForm.get(el)["controls"]) {
                            if (this.editForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }

        const obj = this.editForm.value;
        obj.email = '';
        obj.emails.forEach(element => {
            obj.email += element.email +';';
        });

        obj.tipologia = { id: obj.tipologia, descrizione: this.tipologie.find(x => x.id == obj.tipologia).descrizione };
        this.loading = true;
        this.currentService.save(obj).subscribe(res => {
            this.loading = false;
            this.toastr.success("Soggetto responsabile salvato con successo!");
            this.clearForm();
            
            if (!obj.id) {
                //new row
                this.ngxDatatableWrapper.addRow(res);
            }
            else {
                this.modalRef.hide();
                this.ngxDatatableWrapper.updateRow(obj);
            }

        }, error => {
            this.loading = false;
            this.toastr.error("Errore di sistema");
        });
    }



    onEdit(row) {
        this.submitted = false;
        console.log(row);
        this.buttonLabel = "Aggiorna";
        this.modalRef = this.modalService.show(this.templateModal, this.config);
        this.editForm.reset();
        this.emails = this.editForm.get('emails') as FormArray;
        this.emails.clear();
        let emails = [];
        row.email.split(';').forEach(element => {
            if (element!=''){
            this.addEmail(element);
            emails.push({email:element});
            }
        });
        this.editForm.setValue({
            id: row.id,
            cognome: row.cognome,
            nome: row.nome,
            /*email: row.email,*/
            tipologia: row.tipologia.id,
            emails:emails
        });
    }

    onDelete(row) {
        console.log(row);
        if (!confirm(`Stai per eliminare il soggetto responsabile "${row.cognome} ${row.nome}", proseguire?`)) return;
        this.loading = true;
        this.currentService.delete(row.id).subscribe(
            result => {
                this.loading = false;
                this.ngxDatatableWrapper.deleteRow(row);
                this.toastr.success('Soggetto responsabile eliminato con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile eliminare il soggetto responsabile: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                console.log('error');
            });
    }
    printValueAsDate(value) {
        //return moment(value).format('DD/MM/YYYY');
        return value;
    }

    addEmail(text:string | null){
        this.emails = this.editForm.get('emails') as FormArray;
        let obj = this.formBuilder.group({email: [null, [Validators.required, Validators.maxLength(255), Validators.email]]});
        if (text)obj.setValue({email:text});
        this.emails.push(obj);
    }
    removeEmail(index:number){
        this.emails = this.editForm.get('emails') as FormArray;
        this.emails.removeAt(index);
    }
    closeEditModal(){
        this.modalRef.hide();
        this.clearForm();
    }
    clearForm(){
        this.submitted = false;
            this.open = false;
            this.editForm.reset();
            this.emails = this.editForm.get('emails') as FormArray;
            this.emails.clear();
            this.addEmail(null);
            this.buttonLabel = "Inserisci";
    }
    getEmailsForGrid(value){
        return value.split(';');
    }
}
