import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services";

@Component({
  selector: "pnrr-management",
  templateUrl: "./pnrr-management.component.html",
  styleUrls: ["./pnrr-management.component.css"]
})
export class PnrrManagementComponent implements OnInit {
  constructor(
    private authService: AuthService
  ) {}

  ngOnInit() {}

  /**
   * Controlla se l'utente può accedere o meno alla sezione.
   * @param section il nome della sezione
   * @param operations le operazioni da poter effettuare sulla sezione
   * @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
   * @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
   * @returns true se il controllo è andato a buon fine, false altrimenti
  */
   enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
    return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
  }
}
