import { Component, OnInit } from "@angular/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { PnrrService } from "../../../services";

@Component({
  selector: "app-chart-avanzamento-mt",
  templateUrl: "./chart-avanzamento-mt.component.html",
  styleUrls: ["./chart-avanzamento-mt.component.css"],
})
export class ChartAvanzamentoMtComponent implements OnInit {
  constructor(private pnrrService: PnrrService) {}

  ngOnInit(): void {
    this.graficoMT();
    this.loadData();
  }

  pnrrTargetMilestone = [];

  loadData() {
    this.pnrrService.getAllMissione().subscribe((missione: any) => {
      this.chartData = missione.map((missione) => {
        return {
          category: `${missione.descrizione}`,
          milestone: null,
          target: null,
        };
      });

      this.pnrrService.getAllPnrrTargetMilestone().subscribe((tM: any) => {
        this.chartData.forEach((data, index) => {
          const tm = tM[index];
          if (tm) {
            data.milestone = `${tm.numeroMilestone}`;
            data.target = `${tm.numeroTarget}`;
          }
        });

        this.graficoMT();
      });
    });
  }

  chartData: any[] = [];

  graficoMT() {
    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    let chart = am4core.create("chartMT", am4charts.XYChart);
    chart.colors.step = 2;

    am4core.options.autoDispose = true;

    chart.responsive.enabled = true;
    chart.scrollbarX = new am4core.Scrollbar();

    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    chart.legend.paddingBottom = 15;
    chart.legend.labels.template.maxWidth = 95;

    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 12;
    markerTemplate.height = 12;
    chart.legend.labels.template.fontSize = 13;


    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = "category";
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;

    let label = xAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 190;
    label.fontSize = 10;



    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;
    yAxis.extraMax = 0.1;

    function createSeries(value, name) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = "category";
      series.name = name;

       // Imposta il testo del tooltip
      series.columns.template.tooltipText = "[font-size:12px]{categoryX}\n{name}: {valueY}";


      series.events.on("hidden", arrangeColumns);
      series.events.on("shown", arrangeColumns);

      let bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.interactionsEnabled = false;
      bullet.dy = -10;
      bullet.label.text = "{valueY}";
      bullet.label.fill = am4core.color("#000");

      return series;
    }

    //Export chart
    chart.exporting.menu = new am4core.ExportMenu();

    chart.exporting.menu.align = "left";
    chart.exporting.menu.verticalAlign = "top";

    chart.exporting.menu.defaultStyles = true;
    chart.exporting.menu.items = [
      {
        menu: [
          { type: "jpg", label: "JPG" },
          { type: "png", label: "PNG" },
          { type: "csv", label: "CSV" },
        ],
      },
    ];

    chart.exporting.menu.items[0].icon = "assets/img/export.png";

    chart.data = this.chartData;

    createSeries("milestone", "Milestone");
    createSeries("target", "Target");
    //createSeries('third', 'The Third');

    let title = chart.titles.create();
    title.text = "Milestone & Target per Missione";
    title.fontSize = 22;
    title.marginBottom = 15;

    function arrangeColumns() {
      let series = chart.series.getIndex(0);

      let w =
        1 -
        xAxis.renderer.cellStartLocation -
        (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        let delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          let middle = chart.series.length / 2;

          let newIndex = 0;
          chart.series.each(function (series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            } else {
              series.dummyData = chart.series.indexOf(series);
            }
          });
          let visibleCount = newIndex;
          let newMiddle = visibleCount / 2;

          chart.series.each(function (series) {
            let trueIndex = chart.series.indexOf(series);
            let newIndex = series.dummyData;

            let dx = (newIndex - trueIndex + middle - newMiddle) * delta;

            series.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
            series.bulletsContainer.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
          });
        }
      }
    }
  }
}
