<div class="modal-header text-center">
    <h4 class="modal-title pull-left">Seleziona soggetti giuridici</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">




    <section class="fascia-grey-login" style="margin:0px;padding-top:20px">
        <div class="container">
            <div class="row clearfix">
                <div class="col-lg-12 ">
                    <form [formGroup]="myGroup" novalidate>
                        <div class="form-registrazione">

                            <div class="user-type">
                                <div class="d-flex justify-content-between">
                                    <h5><label for="role" class="mandatory">Ruolo:</label></h5>
                                    <div class="text-center " style="width:57.5px">
                                        <a class="bottone-inserisci" (click)="openModalSoggettoRuolo($event)"><i
                                            class="fas fa-plus"></i></a>
                                        </div>
                                </div>

                                <div class="form-group" [ngClass]="{ 'is-invalid': submitted && f.role.errors }">
                                    <ng-select [formGroup]="myGroup" formControlName="role" [items]="roles"
                                        bindValue="id" bindLabel="ruolo" placeholder="Seleziona ruolo">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && f.role.errors" class="invalidFieldMessage"
                                    style="display: block;">
                                    <div *ngIf="f.role.errors" class="invalid-feedback" style="display: block;">
                                        Ruolo soggetto &egrave; obbligatorio
                                    </div>
                                </div>
                            </div>
                                <div class="user-type">
                                    <div class="d-flex justify-content-between">
                                        <h5> <label for="soggettoGiuridico" class="mandatory">Soggetto Giuridico:</label>
                                        </h5>
                                        <div class="text-center " style="width:57.5px">
                                            <a class="bottone-inserisci" (click)="openModalSoggGiuridici($event)"><i
                                                class="fas fa-plus"></i></a>
                                            </div>
                                        </div>

                                    <div class="form-group"
                                        [ngClass]="{ 'is-invalid': submitted && f.soggettoGiuridico.errors }">
                                        <ng-select [formGroup]="myGroup" formControlName="soggettoGiuridico"
                                            [items]="soggettiGiuridici" bindValue="id" bindLabel="descrizione"
                                            placeholder="Seleziona soggetto giuridico">
                                        </ng-select>
                                    </div>
                                    <div *ngIf="submitted && f.soggettoGiuridico.errors" class="invalidFieldMessage"
                                        style="display: block;">
                                        <div *ngIf="f.soggettoGiuridico.errors" class="invalid-feedback"
                                            style="display: block;">
                                            Soggetto giuridico &egrave; obbligatorio
                                        </div>
                                    </div>
                                </div>


                            <button class="btn btn-lg btn-primary btn-block" type="button" (click)="add()"
                                style="margin:15px 0">
                                Aggiungi
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>

<ng-template #templateModalSoggGiuridici>
    <div class="modal-header text-center">
        <h4 class="modal-title pull-left">Inserisci nuovo soggetto giuridico</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalSoggettiGiuridici()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="soggGiuridiciForm" (ngSubmit)="onSubmitSoggettoGiuridico()" class="row">
            <input type="hidden" [formGroup]="soggGiuridiciForm" formControlName="id" />
            <div class="form-group col-lg-6">
                <h5><label for="codiceIdentificativo" class="mandatory">Codice Identificativo:</label></h5>
                <input [formGroup]="soggGiuridiciForm" formControlName="codiceIdentificativo" minlength="11"
                    maxlength="11" type="text" value="" class="form-control"
                    placeholder="Inserisci il codice identificativo" [ngClass]="{
        'form-control': true,
            'is-invalid': submittedSoggGiuridici && sg.codiceIdentificativo.errors
        }" />
                <div *ngIf="submittedSoggGiuridici && sg.codiceIdentificativo.errors" class="invalid-feedback"
                    style="display: block;">
                    <div *ngIf="sg.codiceIdentificativo.errors.required">
                        Campo obbligatorio
                    </div>
                    <div *ngIf="sg.codiceIdentificativo.errors.minlength">
                        Codice Identificativo deve essere di 11 caratteri
                    </div>
                </div>
            </div>



            <ng-template #popoverContentIpa>
                <span>Indice delle Pubbliche Amministrazioni</span>
            </ng-template>
            <div class="form-group col-lg-6">
                <h5>
                    <label for="codiceIpa" class="mandatory">
                        <a type="button" class="help" [popover]="popoverContentIpa" placement="bottom"
                            triggers="mouseenter:mouseleave">

                            <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                            </i>
                        </a>&nbsp;Codice Ipa:
                    </label>
                </h5>
                <input [formGroup]="soggGiuridiciForm" formControlName="codiceIpa" maxlength="10" type="text" value=""
                    class="form-control" placeholder="Inserisci il codice ipa" [ngClass]="{
        'form-control': true,
            'is-invalid': submittedSoggGiuridici && sg.codiceIpa.errors
        }" />
                <div *ngIf="submittedSoggGiuridici && sg.codiceIpa.errors" class="invalid-feedback"
                    style="display: block;">
                    <div *ngIf="sg.codiceIpa.errors.required">
                        Campo obbligatorio
                    </div>
                </div>
            </div>
            <div class="form-group col-lg-6">
                <h5><label for="denominazione" class="mandatory">Denominazione:</label></h5>
                <input [formGroup]="soggGiuridiciForm" formControlName="denominazione" maxlength="255" type="text"
                    value="" class="form-control" placeholder="Inserisci la denominazione" [ngClass]="{
        'form-control': true,
            'is-invalid': submittedSoggGiuridici && sg.denominazione.errors
        }" />
                <div *ngIf="submittedSoggGiuridici && sg.denominazione.errors" class="invalid-feedback"
                    style="display: block;">
                    <div *ngIf="sg.denominazione.errors.required">
                        Campo obbligatorio
                    </div>
                </div>
            </div>
            <div class="form-group col-lg-6">
                <h5><label for="provincia" class="mandatory">Provincia:</label></h5>
                <div [ngClass]="{ 'is-invalid': submittedSoggGiuridici && sg.provincia.errors }">
                    <ng-select [formGroup]="soggGiuridiciForm" formControlName="provincia" [items]="province"
                        bindValue="id" bindLabel="name" (change)="onProvincesChange($event)"
                        placeholder="Seleziona tipo classificazione">
                    </ng-select>
                </div>

                <div *ngIf="submittedSoggGiuridici && sg.provincia.errors" class="invalidFieldMessage"
                    style="display: block;">
                    <div *ngIf="sg.provincia.errors" class="invalid-feedback" style="display: block;">
                        Campo obbligatorio
                    </div>
                </div>
            </div>

            <div class="col-lg-12 mb-3">
                <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
                    Inserisci
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #templateModalSoggettoRuolo>

    <div class="modal-header text-center">
        <h4 class="modal-title pull-left">Inserisci nuovo soggetto ruolo</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalSoggettoRuolo()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div>

        <div class="modal-body">

            <form [formGroup]="soggettoRuoloForm" (ngSubmit)="onSubmitSoggettoRuolo()">
                <input type="hidden" [formGroup]="soggettoRuoloForm" formControlName="id" />
                <div class="form-group">
                    <div class="col-12">
                        <h5><label for="codice" class="mandatory">Codice:</label></h5>
                        <input [formGroup]="soggettoRuoloForm" formControlName="codice" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci il ruolo" [ngClass]="{
                            'form-control': true,
                            'is-invalid': submittedSoggettoRuolo && sr.codice.errors
                        }" />
                <div *ngIf="submittedSoggettoRuolo && sr.codice.errors" class="invalid-feedback" style="display: block;">
                    <div *ngIf="sr.codice.errors.required">
                        Campo obbligatorio
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-12">
                <h5><label for="ruolo" class="mandatory">Ruolo:</label></h5>
                <input [formGroup]="soggettoRuoloForm" formControlName="ruolo" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci il ruolo" [ngClass]="{
                    'form-control': true,
                    'is-invalid': submittedSoggettoRuolo && sr.ruolo.errors
                }" />
                <div *ngIf="submittedSoggettoRuolo && sr.ruolo.errors" class="invalid-feedback" style="display: block;">
                    <div *ngIf="sr.ruolo.errors.required">
                        Campo obbligatorio
                    </div>
                </div>
            </div>
        </div>
        
        
        <div class="col-12 mb-3"><button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
            Aggiungi
        </button></div>
    </form>
</div>
</div>
</ng-template>