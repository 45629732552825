import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef,
  TemplateRef
} from "@angular/core";
import { Province } from "src/app/models/province";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { GeoService } from "src/app/services/geo.service";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { EditProvinceModalComponent } from './edit-province-modal/edit-province-modal.component';
import * as moment from "moment";
import { KeyValue } from 'src/app/models/keyValue';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';



@Component({
  selector: "app-province-management",
  templateUrl: "./province-management.component.html",
  styleUrls: ["./province-management.component.css"]
})
export class ProvinceManagementComponent
  implements OnInit, OnDestroy {
  currentRegionId: number = -1;
  currentNewRegionId: number = -1;
  visible = false;
  regions: KeyValue[];
  regionsAdd: KeyValue[];
  submitted: boolean = false;
  addProvinceForm: FormGroup;
  modalRef: BsModalRef;
  open: boolean = false;
  yesNo: Array<{ k: boolean; v: string }> = [
    { k: false, v: "NO" },
    { k: true, v: "SI" }
  ];

  dtOptionsNgx: any;
  @ViewChild('btnEditTemplate', { static: true })
  public btnEditTemplate: TemplateRef<any>;
  @ViewChild('ngxDatatableWrapper')
  ngxDatatableWrapper: NgxDatatableWrapperComponent;
  templateList = [];

  constructor(
    private http: HttpClient,
    private geoService: GeoService,
    private formBuilder: FormBuilder,
    private el: ElementRef,
    private localeService: BsLocaleService,
    private toastr: ToastService,
    private modalService: BsModalService
  ) {
    this.localeService.use("it");
    this.dtOptionsNgx = {
      rows: []
      , totalElements: 0
      , columns: this.columns
      , columnMode: ColumnMode.flex
      , headerHeight: "50"
      , footerHeight: "50"
      , rowHeight: "auto"
      , externalSorting: true// true
      , loadingIndicator: 'loading'
      , class: "bootstrap"
      , summaryRow: true
      , summaryPosition: "'bottom'"
      , externalPaging: true// true
      , count: 0
      , offset: 0
      , limit: 10
      , serverSide: true
      , ajax: this.loadData
      , scrollbarH: "true"
    };
  }

  refreshGrid() {
    this.ngxDatatableWrapper.refresh();
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.geoService.getItalianRegions().subscribe(res => {
      this.regions = res;
      this.regionsAdd = res;
    });
    this.addProvinceForm = this.initializeFormGroup();
    this.templateList = [
      { name: 'btnEditTemplate', template: this.btnEditTemplate }
    ];
  }

  ngOnDestroy(): void {

  }

  onChangeNewRegionId($event: KeyValue) {
    this.currentNewRegionId = $event.id;
  }

  onChangeRegion($event: KeyValue) {
    this.currentRegionId = $event.id;
    if (this.currentRegionId > 0) {
      this.visible = true;
    }
    this.refreshGrid();
  }

  initializeFormGroup() {
    return this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(255)]],
      code: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(3)]
      ],
      acronym: [
        "",
        [Validators.required, Validators.minLength(2), Validators.maxLength(2)]
      ],
      isCittametropolitana: [null, [Validators.required]],
      region: [null, [Validators.required]],
      fromDate: [null],
      toDate: [null]
    });
  }

  get f() {
    return this.addProvinceForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.addProvinceForm.updateValueAndValidity();
    let errors = false;
    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }
    if (this.addProvinceForm.invalid || errors) {
      console.log("invalid");
      let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
      for (let el in this.addProvinceForm.controls) {
        if (this.addProvinceForm.controls[el].invalid) {
          console.log(`set focus on ${el}`);
          if (this.addProvinceForm.controls[el] instanceof FormGroup) {
            for (let el1 in this.addProvinceForm.get(el)["controls"]) {
              if (this.addProvinceForm.get(el)["controls"][el1].invalid) {
                invalidElements = this.el.nativeElement.querySelectorAll(
                  `[formcontrolname="${el1}"]`
                );
                break;
              }
            }
          } else {
            invalidElements = this.el.nativeElement.querySelectorAll(
              `[formcontrolname="${el}"]`
            );
          }
          break;
        }
      }
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }

      return false;
    }

    const newProvince: Province = new Province();
    newProvince.name = this.f.name.value;
    newProvince.code = this.f.code.value;
    newProvince.acronym = this.f.acronym.value;
    newProvince.isCittametropolitana = this.f.isCittametropolitana.value;
    newProvince.fromDate = this.f.fromDate.value;
    newProvince.toDate = this.f.toDate.value;
    newProvince.idRegion = this.currentNewRegionId;

    this.geoService.createProvince(newProvince).subscribe(res => {
      this.toastr.success("Provincia aggiunta con successo!");
      this.refreshGrid();
      this.submitted = false;
      this.open = false;
      this.addProvinceForm.reset();
    }, error => {
      this.toastr.error("Codice già presente");
    });
  }

  editHandler(info: any): void {
    const initialState = { currentProvince: info as Province };
    initialState.currentProvince.idRegion = this.currentRegionId;
    const config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: "modal-lg"
    };
    this.modalRef = this.modalService.show(
      EditProvinceModalComponent,
      Object.assign({}, config, { initialState })
    );

    this.modalRef.content.onClose.subscribe(_ => {
      this.modalRef.hide();
      this.refreshGrid();
    });
  }



  columns = [
    {
      name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
      , resizeable: false
    }

    , {
      name: 'codice'
      , prop: 'code'

      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , flexGrow: 1
      , minWidth: 60

    },
    {
      name: 'nome'
      , prop: 'name'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , flexGrow: 2
      , minWidth: 60

    }
    ,
    {
      name: 'acronimo'
      , prop: 'acronym'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , flexGrow: 2
      , minWidth: 60

    }
    ,
    {
      name: 'città metropolitana'
      , prop: 'isCittametropolitana'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , cellTemplate: 'booleanValueTemplate'
      , flexGrow: 2
      , minWidth: 60
    }
    ,
    {
      name: 'inizio validità'
      , prop: 'fromDate'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , cellTemplate: 'dateTimeValueTemplate'
      , flexGrow: 2
      , minWidth: 100
    }
    ,
    {
      name: 'fine validità'
      , prop: 'toDate'
      , sortable: true
      , filtrable: true
      , resizeable: false
      , draggable: false
      , cellTemplate: 'dateTimeValueTemplate'
      , flexGrow: 2
      , minWidth: 100
    }


    , {
      name: 'modifica'
      , prop: null
      , sortable: false
      , filtrable: false
      , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
      //, width: 10
      , resizeable: false
      , draggable: false
      , flexGrow: 1
      , minWidth: 60
    }
  ];

  loadData = (dataTablesParameters: any, callback: any) => {
    this.geoService.getAllProvinceDt(this.currentRegionId, dataTablesParameters)
      .subscribe(resp => {
        callback(resp);
      });
  };
}
