import {
  Component,
  OnInit,
  ViewChild,
  Renderer2,
  TemplateRef,
  OnDestroy
} from "@angular/core";
import { Municipality } from "src/app/models/municipality";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { GeoService } from "src/app/services/geo.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { itLocale } from "ngx-bootstrap/locale";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { defineLocale } from "ngx-bootstrap/chronos";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { EditMunicipalityModalComponent } from "./edit-municipality-modal/edit-municipality-modal.component";
import * as moment from "moment";
import { ToastService } from 'src/app/services/toast.service';
import { KeyValue } from 'src/app/models/keyValue';
defineLocale("it", itLocale);
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';


@Component({
  selector: "app-municipality-management",
  templateUrl: "./municipality-management.component.html",
  styleUrls: ["./municipality-management.component.css"]
})
export class MunicipalityManagementComponent
  implements OnInit,OnDestroy {
  currentProvinceId: number = -1;
  currentEditProvinceId: number = -1;
  currentEditAreaId: number = -1;
  visible = false;
  provinces: KeyValue[];
  provincesAdd: KeyValue[];
  submitted: boolean = false;
  addForm: FormGroup;
  yesNo: Array<{ k: string; v: string }> = [
    { k: "NO", v: "NO" },
    { k: "SI", v: "SI" }
  ];
  open: boolean = false;
  modalRef: BsModalRef;
  disabledArea: boolean = true;

  dtOptionsNgx:any;
  @ViewChild('btnEditTemplate', { static: true })
  public btnEditTemplate: TemplateRef<any>;
  @ViewChild('ngxDatatableWrapper')
  ngxDatatableWrapper: NgxDatatableWrapperComponent;
  templateList=[];
  constructor(
    private http: HttpClient,
    private geoService: GeoService,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private localeService: BsLocaleService,
    private modalService: BsModalService,
    private toastr: ToastService
  ) {
    this.localeService.use("it");
    this.dtOptionsNgx = {
        rows: []
        , totalElements: 0
        , columns: this.columns
        , columnMode: ColumnMode.flex
        , headerHeight: "50"
        , footerHeight: "50"
        , rowHeight: "auto"
        , externalSorting: true// true
        , loadingIndicator: 'loading'
        , class: "bootstrap"
        , summaryRow: true
        , summaryPosition: "'bottom'"
        , externalPaging: true// true
        , count: 0
        , offset: 0
        , limit: 10
        , serverSide: true
        ,ajax:this.loadData
        , scrollbarH:"true"
    };
  }
    ngOnDestroy(): void {

    }

  refreshGrid(){
    this.ngxDatatableWrapper.refresh();
}
  ngOnInit() {

    window.scroll(0, 0);
    this.geoService.getProvinces(AppConfigService.env.region_code).subscribe(res => {
      this.provinces = res;
      let arr = [];
     arr = [...res];// Object.assign(arr,res);
      this.provincesAdd = arr;
      this.provinces.push({ key: "Tutte", id: 0, value: "Tutte" });
    });

    this.addForm = this.initializeFormGroup();
    this.templateList = [
        {name:'btnEditTemplate',template:this.btnEditTemplate}
];
  }


  onChangeProvince($event: KeyValue) {
    this.currentProvinceId = $event.id;
    if (this.currentProvinceId > 0) {
      this.visible = true;
    }
    this.refreshGrid();
  }

  onChangeEditProvince($event: KeyValue) {
    this.currentEditProvinceId = $event.id;
  }

  initializeFormGroup() {
    return this.formBuilder.group({
      name: ["", [Validators.required, Validators.maxLength(255)]],
      istatCode: [
        "",
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
      ],
      cadastreCode: [
        "",
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)]
      ],

      fromDate: [null],
      toDate: [null],
      province: [null, [Validators.required]]
    });
  }

  get f() {
    return this.addForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.addForm.updateValueAndValidity();

    let errors = false;
    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }
    if (this.addForm.invalid || errors) {
      console.log("invalid");
      let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
      for (let el in this.addForm.controls) {
        if (this.addForm.controls[el].invalid) {
          console.log(`set focus on ${el}`);
          if (this.addForm.controls[el] instanceof FormGroup) {
            for (let el1 in this.addForm.get(el)["controls"]) {
              if (this.addForm.get(el)["controls"][el1].invalid) {
                invalidElements = this.renderer.selectRootElement(`[formcontrolname="${el1}"]`, true);
                break;
              }
            }
          } else {
            invalidElements = this.renderer.selectRootElement(`[formcontrolname="${el}"]`, true);
          }
          break;
        }
      }
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }

      return false;
    }

    const newMunicipality: Municipality = new Municipality();
    newMunicipality.name = this.f.name.value;
    newMunicipality.istatCode = this.f.istatCode.value;
    newMunicipality.cadastreCode = this.f.cadastreCode.value;
    newMunicipality.fromDate = this.f.fromDate.value;
    newMunicipality.toDate = this.f.toDate.value;
    newMunicipality.provinceId = this.currentEditProvinceId;
    this.geoService.createMunicipality(newMunicipality).subscribe(res => {
      this.toastr.success( "Comune aggiunto con successo!");
      this.refreshGrid();
      this.submitted = false;
      this.open = false;
      this.addForm.reset();
    }, error => {
      this.toastr.error("Codice Istat e/o Codice Catastale già presente/i");
    });
  }

  log(event: boolean) {
    console.log(`Accordion has been ${event ? "opened" : "closed"}`);
  }

  editHandler(info: any): void {
    const initialState = { currentMunicipality: info as Municipality };
    initialState.currentMunicipality.provinceId = this.currentProvinceId;
    const config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: "modal-lg"
    };
    this.modalRef = this.modalService.show(
      EditMunicipalityModalComponent,
      Object.assign({}, config, { initialState })
    );

    this.modalRef.content.onClose.subscribe(_ => {
      this.modalRef.hide();
      this.refreshGrid();
    });
    //this.modalRef.content.municipalityChange = x => this.rerender();
  }



  columns = [
    {
        name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
        , resizeable: false
    }

    , { name: 'nome'
    , prop: 'name'

    , sortable: true
    , filtrable: true
    , resizeable: false
    , draggable: false
    , flexGrow: 4
        , minWidth: 60

  },
  {
      name: 'codice istat'
    , prop: 'istatCode'
    , sortable: true
    , filtrable: true
    , resizeable: false
    , draggable: false
    , flexGrow: 2
        , minWidth: 60

  }
  ,
  {
      name: 'codice catastale'
    , prop: 'cadastreCode'
    , sortable: true
    , filtrable: true
    , resizeable: false
    , draggable: false
    , flexGrow: 2
        , minWidth: 60

  }
    ,
    { name: 'provincia'
    , prop: 'provinceName'
    , sortable: true
    , filtrable: true
    , resizeable: false
    , draggable: false
    , flexGrow: 3
        , minWidth: 60
   }
   ,
    { name: 'inizio validità'
    , prop: 'fromDate'
    , sortable: true
    , filtrable: true
    , resizeable: false
    , draggable: false
    , cellTemplate :'dateTimeValueTemplate'
    , flexGrow: 2
        , minWidth: 60
   }
   ,
    { name: 'fine validità'
    , prop: 'toDate'
    , sortable: true
    , filtrable: true
    , resizeable: false
    , draggable: false
    , cellTemplate :'dateTimeValueTemplate'
    , flexGrow: 2
        , minWidth: 60
   }


    , {
        name: 'modifica'
        , prop: null
        , sortable: false
        , filtrable: false
        , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
        //, width: 10
        , resizeable: false
        , draggable: false
        , flexGrow: 1
        , minWidth: 60
    }
];

  loadData = (dataTablesParameters: any, callback: any) => {
    console.log("here");
    //return;
    //if (!this.currentProvinceId || this.currentProvinceId <= 0) return;
    let that = this;
    this.geoService.getMunicipalitiesByProvinceCode(this.currentProvinceId,dataTablesParameters)
    .subscribe(resp => {
        callback(resp);
      });
  };
}
