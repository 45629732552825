import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FinanziamentoService } from '../../services';
import { StoricoDettaglioComponent } from "../../components/storicoDettaglio/storico-dettaglio.component";

@Component({
    selector: 'storico-finanziamento-component',
    templateUrl: './storico-finanziamento.component.html',
    styleUrls: ['./storico-finanziamento.component.css']
})
export class StoricoFinanziamentoComponent
    implements OnInit {

    modalRef: BsModalRef;

    _isInModal: boolean = false;
    @Input()
    set isInModal(value: boolean) {
        this._isInModal = value;
    }
    get isInModal() { return this._isInModal; }


    _finanziamentoId: number;
    @Input()
    set finanziamentoId(value: number) {
        this._finanziamentoId = value;
    }

    get finanziamentoId() {
        return this._finanziamentoId;
    }


    loading: boolean = true;

    @ViewChild('btnDetailTemplate', { static: true })
    public btnDetailTemplate: TemplateRef<any>;

    @ViewChild('tipologiaValueTemplate', { static: true })
    public tipologiaValueTemplate: TemplateRef<any>;


    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    dtOptions: any;

    columns = [
        {
            name: '', prop: 'id', sortable: true, filtrable: false, maxWidth: 0, width: 0, visible: false
            // 'buttonsTemplate'
        }

        , {
            name: 'data'
            , prop: 'date'
            , sortable: true
            , filtrable: true
            , minWidth: 140

            , cellTemplate: 'dateTimeValueTemplate'
            , flexGrow: 2
        }
        , {
            name: 'tipologia'
            , prop: 'action'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'tipologiaValueTemplate'
            , minWidth: 140
            , flexGrow: 2

        }
        , {
            name: 'descrizione'
            , prop: 'actionDescription'
            , sortable: true
            , filtrable: true
            , minWidth: 200
            , flexGrow: 5
        }

        , {
            name: 'utente'
            , prop: 'username'
            , sortable: true
            , filtrable: true
            , minWidth: 140
            , flexGrow: 3
        }

        , {
            name: 'dettaglio'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDetailTemplate'// 'buttonsTemplate'
            , minWidth: 70
            , flexGrow: 1

        }
    ];
    templateList = [];

    constructor(private finanziamentoService: FinanziamentoService
        , private modalService: BsModalService
    ) {
        this.dtOptions = {
            rows: []
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , scrollbarH: "true"
            , ajax: this.getStorico
            , sorts: [{ dir: 'desc', prop: 'date' }]
        };
    }
    ngOnInit(): void {
        this.templateList = [
            { name: 'btnDetailTemplate', template: this.btnDetailTemplate }
            , { name: 'tipologiaValueTemplate', template: this.tipologiaValueTemplate }
        ];
        window.scroll(0, 0);
    }

    reloadAllData() {
        this.loading = true;
        this.ngxDatatableWrapper.gridModel.data = [];
        this.ngxDatatableWrapper.refresh();
    }

    getStorico = (dataTablesParameters: any, callback: any) => {
        let that = this;
        if (!that._finanziamentoId) return;
        this.finanziamentoService.getStoricoDt(that._finanziamentoId, dataTablesParameters)
            .subscribe(resp => {
                callback(resp)
                that.loading = false;
            });
    }

    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
    gotoBackBtnClick() {
        this.gotoBtnFunction.emit(true);
    }

    onDetail(row) {
        let modalDefinition = {
            pageTitle: 'Dettaglio storico - finanziamento'
        };
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: 'modal-xl'
        };
        const initialState = { modalDefinition: modalDefinition,id:row.id,what:'finanziamento',finanziamentoId:this.finanziamentoId };

        this.modalRef = this.modalService.show(StoricoDettaglioComponent, Object.assign({}, config, { initialState }));
    }
}
