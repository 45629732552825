<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
    <div class="modal-header text-center">
        <h4 class="modal-title pull-left">Modifica Dati Regione</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="col-lg-12">
            <div class="row">
                <!--***-->
                <div class="form-group col-xl-6 col-lg-6">
                    <h5><label for="name" class="">Nome Regione 
                <span aria-hidden="true" class="text-danger font-weight-bold">*</span>:
            </label></h5>
                    <div class="">
                        <input [formGroup]="editForm" formControlName="name" type="text" value="" class="form-control" placeholder="Inserisci il nome della regione" [ngClass]="{
            'form-control': true,
                'is-invalid': submitted && f.name.errors
            }" />
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.name.errors.required">
                                Nome &egrave; obbligatorio
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group col-xl-6 col-lg-6">
                    <h5><label for="code" class="">Codice
                <span aria-hidden="true" class="text-danger font-weight-bold">*</span>:
            </label></h5>
                    <div class="">
                        <input [formGroup]="editForm" formControlName="code" type="text" value="" maxlength="2" class="form-control" placeholder="Inserisci il codice della regione" [ngClass]="{
            'form-control': true,
                'is-invalid': submitted && f.code.errors
            }" />
                        <div *ngIf="submitted && f.code.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.code.errors.required">
                                Codice &egrave; obbligatorio
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="false">
                <!-- *** -->
                <div class="form-group col-xl-6 col-lg-6">
                    <h5> <label class="">Nazione 
                <span aria-hidden="true" class="text-danger font-weight-bold">*</span>:
            </label></h5>
                    <div class="">
                        <ng-select formControlName="nation" [items]="nations" bindValue="id" placeholder="Seleziona" bindLabel="value" selectOnTab="true" [searchable]="true" [clearable]="false" notFoundText="Nessun risultato trovato..." [ngClass]="{
            'is-invalid': submitted && f.nation.errors
            }">
                        </ng-select>
                        <div *ngIf="submitted && f.nation.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.nation.errors.required">
                                Nazione &egrave; obbligatoria
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- *** -->
                <div class="form-group col-xl-6 col-lg-6">
                    <h5><label class="">Data Inizio Validità:</label></h5>

                    <div class="input-group">
                        <input placeholder="Seleziona la data di inizio validità" formControlName="fromDate" type="text" class="form-control" bsDatepicker #dp="bsDatepicker" (bsValueChange)="onChangeFromDate($event)" [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true,
                    containerClass: 'theme-dark-blue',
                    isAnimated: true
                }" [ngClass]="{
                    'is-invalid': submitted && f.fromDate.errors
                }" />
                        <button type="button" class="btn btn-primary mt-0" (click)="dp.show()" [attr.aria-expanded]="dp.isOpen">
                    <i class="fas fa-calendar-alt"></i>
                </button>
                    </div>
                    <!-- <div *ngIf="submitted && f.fromDate.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.fromDate.errors.required">
                    Data Inizio Validità &egrave; obbligatoria
                </div>
            </div> -->
                    <!-- <div
                *ngIf="submitted && f.dataDiNascitaAlunno.errors"
                class="invalid-feedback"
                style="display: block;"
            >
                <div *ngIf="f.dataDiNascitaAlunno.errors.required">
                Data Inizio Validità &egrave; obbligatoria
                </div>
                <div *ngIf="f.dataDiNascitaAlunno.errors">
                Data Inizio Validità &egrave; obbligatoria nel formato gg/mm/aaaa
                </div>
            </div> -->
                </div>

                <div class="form-group col-xl-6 col-lg-6">
                    <h5><label class="">Data Fine Validità:</label></h5>

                    <div class="input-group">
                        <input placeholder="Seleziona la data di fine validità" formControlName="toDate" type="text" class="form-control" bsDatepicker #dp2="bsDatepicker" (bsValueChange)="onChangeToDate($event)" [bsConfig]="{
                        dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true,
                        containerClass: 'theme-dark-blue',
                        isAnimated: true
                    }" [ngClass]="{
                        'is-invalid': submitted && f.toDate.errors
                    }" />
                        <button type="button" class="btn btn-primary mt-0" (click)="dp2.show()" [attr.aria-expanded]="dp.isOpen">
                    <i class="fas fa-calendar-alt"></i>
                </button>
                        <!-- <div
                    *ngIf="submitted && f.toDate.errors"
                    class="invalid-feedback"
                    style="display: block;"
                    >
                    <div *ngIf="f.toDate.errors.required">
                        Data Fine Validità &egrave; obbligatoria
                    </div>
                </div> -->
                    </div>
                    <!-- <div
                    *ngIf="submitted && f.dataDiNascitaAlunno.errors"
                    class="invalid-feedback"
                    style="display: block;"
                >
                    <div *ngIf="f.dataDiNascitaAlunno.errors.required">
                    Data Inizio Validità &egrave; obbligatoria
                    </div>
                    <div *ngIf="f.dataDiNascitaAlunno.errors">
                    Data Inizio Validità &egrave; obbligatoria nel formato gg/mm/aaaa
                    </div>
                </div> -->

                </div>
            </div>

            <div class="text-center my-3">
                <button class="btn btn-primary" type="submit" style="font-weight: bold;" [disabled]="!editForm.dirty">
    AGGIORNA REGIONE
    &nbsp;<i aria-hidden="true" class="fa fa-redo-alt"></i>
</button>
            </div>
        </div>
    </div>
</form>