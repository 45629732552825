import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { CurrencyPipe } from "@angular/common";
import {
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import {
    DashboardService,
    ProgettoService,
    FinanziamentoService,
    SoggettoGiuridicoService,
    PnrrService,
    StorageService,
    AggregatoService
} from "../../services";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";
import * as FileSaver from "file-saver";
import { Intervento, Progetto } from "../../models";

import { GeoService } from "../../../../services";

import { MapComponent } from "../dashboardFinanziaria/map/map.component";

import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

import { ToastService } from "src/app/services/toast.service";
import { AuthService } from "src/app/services";
import { TipologiaInterventiService } from "../../services/tipologia-interventi.service";
import { ImageHelper } from "src/app/helpers/image.helper";

class Annualita {
    anno: string;
    importoI: number;
    importoF: number;
    importoFormattatoI: any;
    importoFormattatoF: any;
}
@Component({
    selector: 'dashboard-pkmg-component',
    templateUrl: './dashboard-pkmg.component.html',
    styleUrls: ['./dashboard-pkmg.component.css']
})
export class DashboardPkmgComponent {
    private chartTipologiaIntervento;
    private chartAttuazione;

    @ViewChild(MapComponent)
    map: MapComponent;
    
    allProjects: Progetto[] = [];
    filteredInterventions: Intervento[] = [];

    areeTematicheCombo: any[] = [];
    interventiCombo: any[] = [];
    pnrrComboItems: any[] = [];
    soggettiAttuatoriItems = [];
    provinceSoggettiCombo = [];
    provinciaItems: any[] = [];
    comuneItems: any[] = [];
    aggregatoItems: any[] = [];

    finanziamentoMissionsPnrrComboItems: any[] = [];
    finanziamentoComponentsPnrrComboItems: any[] = [];
    finanziamentoLinesPnrrComboItems: any[] = [];

    myForm: FormGroup;
    exportForm: FormGroup;

    description: string;
    interventionDescription: string;

    loadingExportExcel: boolean = false;

    valoreTotaleProgetti: number = 0;
    // valoreTotaleFinanziatoProgetti: number = 0;

    totInt: number = 0;
    // totFin: number = 0;

    filterObject = {
        alwaysProject: -1,
        //filtro progetto e main id
        filterProjectId: -1, // projectId
        filterMainInterventionId: -1,
        //Anno
        filterAnno: 'all', // anno
        // filtro intervento
        filterSimpleInterventionId: -1,
        // filtro tipologia intervento
        filterTipologiaIntervento: [-1], // tipologiaIntervento
        // filtro area-tematica/direzione
        filterAreeGestionaliDirezioneEsterne: -1, // filterArea
        filterPnrr: 1,
        filterProvince: -1,
        filterComune: -1,
        filterAggregato: [-1],
        // filtro soggetti attuatori
        filterSoggettoAttuatore: -1,
        filterLineeFinanziamentiPnrr: { missions: null, components: null, lines: null }
    };

    noresult = false;

    isShowingFiltered = false;

    showPage = false;
    showLoading:boolean = true;

    fillInterventions = true;

    tipologieInterventi: any;

    onlyNormalIntervention = [];

    annualita: Annualita[];

    completerParams = {
        labelField: "descrizioneEstesa",
        placeholder: "Cerca intervento",
        ajax: null,
        items: [],
    };


    completerParamsFinanziamento = {
        labelField: "descrizioneEstesa",
        placeholder: "Cerca finanziamento",
        ajax: null,
        items: [],
    };

    constructor(
        private projectService: ProgettoService,
        private dashboardService: DashboardService,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private toastr: ToastService,
        private soggettiService: SoggettoGiuridicoService,
        private pnrrService: PnrrService,
        private storageService: StorageService,
        private tipologiaInterventiService: TipologiaInterventiService,
        private geoService: GeoService,
        private aggregatoService: AggregatoService        
    ) { }

    ngOnInit() {
        this.myForm = this.initializeFormGroup();
        this.disableFilters();
        //Get Progettualita'
        this.projectService.getAll().subscribe((res) => {
            this.allProjects = res
                .filter((p) => !p.isDeleted)
                .map((p) => {
                    p["label"] = `${p.codice} - ${p.descrizione}`;
                    return p;
                });
        });
        this.getTipologiaInterventi();
        this.getAreeTematiche();
        this.getSoggettiAttuatori();
        this.getProvince();
        this.showLoading = true;
        this.filterInterventions(this.fillInterventions, false);
        this.exportForm = this.formBuilder.group({
            conClassificazioni: [null]
        });

        this.fillPnrrComboItems();
        this.fillFinanziamentoPnrrComboItems();
    }

    ngOnDestroy(): void {
        this.disposeCharts();
        if (this.elencoAreeSubscription) this.elencoAreeSubscription.unsubscribe();        
    }

    disableFilters() {
        this.myForm.disable();
    }

    enableFilters() {
        this.myForm.enable();
    }

    disposeCharts() {
        this.chartTipologiaIntervento && this.chartTipologiaIntervento.dispose();
        this.chartAttuazione && this.chartAttuazione.dispose();
    }

    countNotMain() {
        return this.filteredInterventions.filter(
            (i) => !i.isMain
        ).length;
    }

    countMain() {
        return this.filteredInterventions.filter(
            (i) => i.isMain
        ).length;
    }

    countSubIntervents() {
        return this.filteredInterventions.filter(
            (i) => i.isMain && i.numSubInterventi > 0
        ).length
    }

    countTotImporti(annualita: Annualita[]) {
        this.totInt = 0;
        // this.totFin = 0;

        annualita &&
            annualita.length > 0 &&
            annualita.forEach((a) => {
                this.totInt += a.importoI;                
            });
    }


    initializeFormGroup() {
        return this.formBuilder.group({
            project: [null, null],
            intervention: [{ value: null, disabled: true }, null],
            status: [{ value: null, disabled: false }, null],
            direction: [{ value: null, disabled: false }, null],
            attuatore: [null],
            province: [null],
            municipality: [null],
            dettaglioTipologia: [null],
            pnrrPnc: [null],
            finanziamentoPnrrPnc: [null]
        });
    }

    get f() {
        return this.myForm.controls;
    }

    fillPnrrComboItems() {
        const arr1 = [
            // { key: '1', value: 'Tutti' }
        ];
        const arr2 = [
            { key: '2', value: 'Attuazione regionale' }
            , { key: '3', value: 'Territoriale' }
        ];
        this.pnrrComboItems = [...arr1, ...arr2];
    }

    fillFinanziamentoPnrrComboItems() {
        this.pnrrService.getAllLineaFinanziamento().subscribe((res) => {
            res = res.sort((a, b) => a.codice.localeCompare(b.codice));

            const buildFMFinanziamento = new Set();
            const buildSMFinanziamento = new Set();
            const buildTMFinanziamento = new Set();
            res.forEach(data => {
                let dataSplitted = data.codice.split('I');
                
                if (dataSplitted.length<=1)dataSplitted = data.codice.split('R');

                buildFMFinanziamento.has(data.missione.codice) ? null : buildFMFinanziamento.add(data.missione.descrizione);
                buildSMFinanziamento.has(data.missione.codice + data.componente.codice) ? null : buildSMFinanziamento.add(dataSplitted[0] + ' - ' + data.componente.descrizione);
                buildTMFinanziamento.add({ valore: data.codice + ' - ' + data.descrizione, codice: data.codice });
            });

            this.finanziamentoMissionsPnrrComboItems = Array.from(buildFMFinanziamento);
            this.finanziamentoMissionsPnrrComboItems = this.finanziamentoMissionsPnrrComboItems.map(data => ({ valore: data, codice: data.split(' - ')[0] }));
            this.finanziamentoComponentsPnrrComboItems = Array.from(buildSMFinanziamento);
            this.finanziamentoComponentsPnrrComboItems = this.finanziamentoComponentsPnrrComboItems.map(data => ({ valore: data, codice: data.split(' - ')[0] }));
            this.finanziamentoLinesPnrrComboItems = Array.from(buildTMFinanziamento);
        });
    }

    tipologieInterventoForSelectItems;

    tipologieInterventoForSelect () {
        if(!this.tipologieInterventi || this.tipologieInterventi.length == 0) {
            return []
        }
        let tipologieInterventiSelect = this.tipologieInterventi.map((tipologia) => {
            return {
                key: tipologia.codice,
                label: tipologia.descrizione
            }
        })
        this.tipologieInterventoForSelectItems = tipologieInterventiSelect;
    }

    getTipologiaIntervento(codiceTipo: string) {
        return this.tipologieInterventi.find((tipo: any) => tipo.codice == codiceTipo)
    }

    getKnownTipologiaInterventiTypes() {
        return this.tipologieInterventi.map((i: any) => i.codice);
    }

    getTipologiaInterventi() {
        this.tipologiaInterventiService.getTipologiaInterventi().subscribe(
            (result) => {
                this.tipologieInterventi = result;
                this.tipologieInterventoForSelect();
            },
            (error) => {
                console.log(error)
            }
        );

    }

    private elencoAreeSubscription = null;
    getAreeTematiche() {
        this.elencoAreeSubscription = this.storageService.elencoAreeTematiche.subscribe(
            x => {
                console.log(x);
                this.areeTematicheCombo = x ? x : [];
            }
        );
    }

    getSoggettiAttuatori() {
        this.soggettiService.getSoggettiAttuatori(1).subscribe(
            x => this.soggettiAttuatoriItems = x
        )
    }

    getProvince() {
        this.geoService.getProvinces("12").subscribe((res) => {

          let ress: any[];
          ress = res;
          ress.map((i) => {
            return i.provincia;
          });
          this.provinciaItems = ress;
        });
    }

    getComune() {
        this.f.municipality.setValue(null);
        this.geoService
          .getMunicipalities(this.myForm.value.province)
          .subscribe((res) => {
            let ress: any[];
            ress = res;
            ress.map((i) => {
              return i.municipality;
            });
            this.comuneItems = ress;
          });
      }

      resetDettaglioTipologiaChecks(kp: any){
        if(!this.f.dettaglioTipologia.value) return;

        let valid = [];
        kp.forEach( item => {
            this.myForm.value.dettaglioTipologia.find(dt => dt == item.id) ?
            valid.push(item.id) : null
        })
        return valid;
      }

      getAggregato() {
        this.aggregatoService.getAll()
        .subscribe(res =>{
          let filter: any[];
          let kp: any[] = [];
          this.myForm.value.status.map( item => {
              filter = res.filter(x => x.tipologiaIntervento.codice == item);
              kp = kp.concat(filter);
          })
          this.f.dettaglioTipologia.setValue(this.resetDettaglioTipologiaChecks(kp))
          this.aggregatoItems = kp;
        })
      }

    setProject($event) {
        this.description = $event ? $event.descrizione : "";
        this.filterObject.filterProjectId = $event ? $event.id : -1;
        this.filterObject.filterMainInterventionId = $event ? $event.mainInterventoId : -1;
        this.f.intervention.enable();
        this.f.intervention.setValue(null);
        this.filterObject.filterSimpleInterventionId = -1;
        if (!$event) {
            this.interventiCombo = [];
            this.f.intervention.disable();
        }
        this.fillInterventions = true;
        this.filterInterventions(true, false);
    }

    onChangeIntervention($event) {
        !$event.target.value && this.setIntervention(null);
    }

    setIntervention($event) {
        this.f.status.enable();
        this.filterObject.filterSimpleInterventionId = $event ? $event.id : -1;
        this.interventionDescription = $event && $event.descrizione;
        this.fillInterventions = false;
        this.filterInterventions(this.fillInterventions, false);
    }

    setTipologiaIntervento($event) {
        this.filterObject.filterTipologiaIntervento = $event ?
            $event.map(x => x.key)
    /*$event.code*/ : [];
        this.filterObject.filterAggregato = [];
        this.fillInterventions = false;
        this.filterInterventions(this.fillInterventions, false);
        this.getAggregato();
    }

    setAreaTematica($event) {
        this.filterObject.filterAreeGestionaliDirezioneEsterne = $event ? $event.id : -1;
        this.fillInterventions = false;
        this.filterInterventions(this.fillInterventions, false);
    }

    setSoggettoAttuatore($event) {
        this.filterObject.filterSoggettoAttuatore = $event ? $event.id : -1;
        this.fillInterventions = false;
        this.filterInterventions(this.fillInterventions, false);
    }

    setPnrrFilter($event) {
        this.filterObject.filterPnrr = $event ? $event.key : '1';
        this.fillInterventions = false;
        this.filterInterventions(this.fillInterventions, false);
    }

    setProvincia($event) {
        this.filterObject.filterProvince = $event ? $event.key : '-1';
        this.filterObject.filterComune = -1;
        this.fillInterventions = false;
        this.filterInterventions(this.fillInterventions, false);
        this.getComune();
    }

    setComune($event) {
        this.filterObject.filterComune = $event ? $event.key : '-1';
        this.fillInterventions = false;
        this.filterInterventions(this.fillInterventions, false);
    }

    setAggregato($event) {
        this.filterObject.filterAggregato = $event ?
        $event.map(x => x.id) : [];
        this.fillInterventions = false;
        this.filterInterventions(this.fillInterventions, false);
    }

    setLineeFinanziamento($event, type: string) {
        const values = $event.map(data => data.codice.toUpperCase());
        switch (type) {
            case 'mission':
                this.filterObject.filterLineeFinanziamentiPnrr.missions = values;
                break;
            case 'component':
                this.filterObject.filterLineeFinanziamentiPnrr.components = values;
                break;
            case 'line':
                this.filterObject.filterLineeFinanziamentiPnrr.lines = values;
                break;
        }
        this.fillInterventions = false;
        this.filterInterventions(this.fillInterventions, false);
    }

    aggiungiLogo(logoChart) {
        // Add watermark
        const watermark = new am4core.Image();
        watermark.href = "assets/img/logo_blu.svg";
        logoChart.tooltipContainer.children.push(watermark);
        watermark.align = "right";
        watermark.valign = "bottom";
        watermark.opacity = 0.3;
        watermark.marginRight = 10;
        watermark.marginBottom = 5;
        watermark.disabled = true;

        // Enable watermark on export
        logoChart.exporting.events.on("exportstarted", function (ev) {
            watermark.disabled = false;
        });

        // Disable watermark when export finishes
        logoChart.exporting.events.on("exportfinished", function (ev) {
            watermark.disabled = true;
        });

        // Add watermark to validated sprites
        logoChart.exporting.validateSprites.push(watermark);
    }

    printValueAsCurrency(value) {
        value = typeof value === "number" ? value : value.replace(",", ".").trim();
        const format = "1.2-2";
        const currency = "€";
        const currentLocale: string = "it";
        return new CurrencyPipe(currentLocale).transform(
            value,
            "EUR",
            currency,
            format,
            "it-IT"
        );
    }

    exportChart(chart, title) {
        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
        chart.exporting.menu.verticalAlign = "top";
        chart.exporting.menu.background = "#ff0000";
        chart.exporting.menu.defaultStyles = true;
        chart.exporting.filePrefix = title;
        chart.exporting.menu.items = [
            {
                menu: [
                    { type: "jpg", label: "JPG" },
                    { type: "png", label: "PNG" },
                    { type: "csv", label: "CSV" },
                ],
            },
        ];
        chart.exporting.menu.items[0].icon = "assets/img/export.png";
    }

    getValoriProgetti() {
        //ottengo i valori degli importi e dei finanziamenti dei progetti. Non importa di che anno visto che mi serve il totale
        // this.valoreTotaleFinanziatoProgetti = 0;
        this.valoreTotaleProgetti = 0;
        this.filteredInterventions
            .filter((x) => x.isMain)
            .forEach((element) => {
                if (element.importi)
                    element.importi.forEach(
                        (importo) => (this.valoreTotaleProgetti += importo.importo)
                    );                
            });
    }

    private generateBullet(tipologia: any) {
        return this.tipologiaInterventiService.getAssetIcon(this.getTipologiaIntervento(tipologia.codice))
    }

    private generateCounters() {
        const counters = {
            'unmapped': this.onlyNormalIntervention.filter((i) => !i.drawType).length
        }
        this.tipologieInterventi.forEach((tipoInter: any) => {
            let hits = this.onlyNormalIntervention.filter(
                (i) => i.drawType === tipoInter.codice
            ).length;
            counters[tipoInter.codice] = hits;
        })
        return counters;
    }

    private generateDataset(counters) {
        return Object.keys(counters).filter((key) => key != 'unmapped').map((counterName) => {
            let tipologia = this.getTipologiaIntervento(counterName);
            return {
                name: tipologia.descrizione,
                points: counters[counterName],
                color: this.chartTipologiaIntervento.colors.next(),
                bullet: this.generateBullet(tipologia)
            }
        })
    }

    async executeGetValoriProgettiAsync() {
        return new Promise((resolve) => {
            //console.log(`dentro executeGetValoriProgettiAsync`);
            this.getValoriProgetti();
        });
    }

    async renderCharts() {
        am4core.useTheme(am4themes_animated);

        this.renderChartAttuazione();
        this.renderChartTipologiaIntervento();

        this.showLoading = false;
        this.enableFilters();
    }

    async renderChartAttuazione() {
        this.chartAttuazione = am4core.create("chartAttuazione", am4charts.PieChart);

        this.chartAttuazione.responsive.enabled = true;


       // Responsive
       this.chartAttuazione.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 600) {
            return true;
          }
          return false;
        },
        state: function(target, stateId) {
          if (target instanceof am4charts.Legend) {
            var state = target.states.create(stateId);
            state.properties.position = "bottom";


            return state;
          }
          return null;
        }
      });


        let countAttuazioneRegionale = 0;

        let countRilevanzaTerritoriale = 0;

        this.onlyNormalIntervention.forEach((i) => {
            if(i.validationStatus == "TERRITORIALE")
                countRilevanzaTerritoriale++;
            else
                countAttuazioneRegionale++;
        });

        const data = [];

        countAttuazioneRegionale &&
            data.push({
                title: "Attuazione regionale",
                value: countAttuazioneRegionale,
                color: "#aa66cc",
            });

        countRilevanzaTerritoriale &&
            data.push({
                title: "Rilevanza territoriale",
                value: countRilevanzaTerritoriale,
                color: "#3AB795",
            });

        this.chartAttuazione.data = data;

        const pieSeries = this.chartAttuazione.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "title";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;
        pieSeries.labels.template.relativeRotation = 90;

        pieSeries.labels.template.adapter.add("radius", function (radius, target) {
            if (target.dataItem && target.dataItem.values.value.percent < 2) {
                return 0;
            }
            return radius;
        });

        pieSeries.labels.template.adapter.add("fill", function (color, target) {
            if (target.dataItem && target.dataItem.values.value.percent < 2) {
                return am4core.color("#000");
            }
            return color;
        });

        this.chartAttuazione.legend = new am4charts.Legend();
        this.chartAttuazione.legend.position = "right";
        this.chartAttuazione.legend.itemContainers.template.clickable = false;
        this.chartAttuazione.legend.itemContainers.template.focusable = false;
        this.chartAttuazione.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
        this.chartAttuazione.legend.maxWidth = undefined;
        const markerTemplate = this.chartAttuazione.legend.markers.template;
        markerTemplate.width = 14;
        markerTemplate.height = 14;


        // Add chart title
        const title = this.chartAttuazione.titles.create();
        title.marginTop = 15;
        title.marginBottom = 10;
        title.text = "Tipo Attuazione";
        this.chartAttuazione.radius = am4core.percent(70);       
        pieSeries.labels.template.maxWidth = 120;
        pieSeries.labels.template.wrap = true;

        pieSeries.slices.template.propertyFields.fill = "color";
        pieSeries.slices.template.propertyFields.stroke = "color";

        this.exportChart(this.chartAttuazione, title.text);

        this.aggiungiLogo(this.chartAttuazione);
    }

    async renderChartTipologiaIntervento() {
        

        // Create chart instance
        this.chartTipologiaIntervento = am4core.create("chartTipologiaIntervento", am4charts.XYChart);

        this.chartTipologiaIntervento.numberFormatter.numberFormat = "#.#";

        const counters = this.generateCounters();


        // Add chart title
        const title = this.chartTipologiaIntervento.titles.create();
        title.text = "Tipologia Interventi";
        title.marginBottom = 0;
        
        // Add data
        this.chartTipologiaIntervento.data = this.generateDataset(counters);
        this.chartTipologiaIntervento.data = this.chartTipologiaIntervento.data.filter(x=>x.points >0);

        // Create axes
        const categoryAxis = this.chartTipologiaIntervento.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "name";
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.renderer.inside = false;
        categoryAxis.renderer.labels.template.fill = am4core.color("#000");
        categoryAxis.renderer.labels.template.rotation = -45;
        categoryAxis.renderer.labels.template.fontSize = 12;
        categoryAxis.renderer.labels.template.verticalCenter = "top";
        categoryAxis.renderer.labels.template.horizontalCenter = "right";

        const valueAxis = this.chartTipologiaIntervento.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.strokeDasharray = "4,4";
        valueAxis.min = 0;

        // Do not crop bullets
        this.chartTipologiaIntervento.maskBullets = false;

        // Remove padding
        this.chartTipologiaIntervento.paddingBottom = 0;

        const label = categoryAxis.renderer.labels.template;
        label.truncate = true;
        label.maxWidth = 220;

        // Create series
        const series = this.chartTipologiaIntervento.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "points";
        series.dataFields.categoryX = "name";
        series.columns.template.propertyFields.fill = "color";
        series.columns.template.propertyFields.stroke = "color";
        series.columns.template.column.cornerRadiusTopLeft = 15;
        series.columns.template.column.cornerRadiusTopRight = 15;
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/b]";

        this.chartTipologiaIntervento.scrollbarX = new am4core.Scrollbar();

        // Add bullets
        const bullet = series.bullets.push(new am4charts.Bullet());
        const image = bullet.createChild(am4core.Image);
        image.horizontalCenter = "middle";
        image.verticalCenter = "bottom";
        image.dy = 20;
        image.y = am4core.percent(100);
        image.widthRatio = 0.6;
        image.propertyFields.href = "bullet";
        image.tooltipText = series.columns.template.tooltipText;
        image.propertyFields.fill = "color";
        image.filters.push(new am4core.DropShadowFilter());

        this.exportChart(this.chartTipologiaIntervento, "grafico");

        this.aggiungiLogo(this.chartTipologiaIntervento);
    }

    countImportiPerAnnualita() {
        const annualita: Annualita[] = [];
        this.filteredInterventions.forEach((i) => {
            if (
                (!i.isMain) &&
                i.importi &&
                i.importi.length > 0
            ) {
                i.importi.forEach((imp) => {
                    let currentAnnualita = annualita.find(
                        (x) => x.anno == imp.anno.toString()
                    );

                    if (!currentAnnualita) {
                        currentAnnualita = new Annualita();
                        currentAnnualita.anno = imp.anno.toString();
                        currentAnnualita.importoF = 0.0;
                        currentAnnualita.importoI = 0.0;
                        annualita.push(currentAnnualita);
                    }
                    currentAnnualita.importoI += imp.importo;
                });
            }

            if (
                (!i.isMain || i.numSubInterventi <= 0) &&
                i.finanziamenti &&
                i.finanziamenti[0]
            ) {
                i.finanziamenti[0].annualita.forEach((imp) => {
                    let currentAnnualita = annualita.find((x) => x.anno == imp.anno);
                    if (!currentAnnualita) {
                        currentAnnualita = new Annualita();
                        currentAnnualita.anno = imp.anno;
                        currentAnnualita.importoF = 0.0;
                        currentAnnualita.importoI = 0.0;
                        annualita.push(currentAnnualita);
                    }
                    currentAnnualita.importoF += imp.importo;
                });
            }
        });
        annualita.sort((a, b) => {
            return a["anno"] > b["anno"] ? 1 : a.anno < b.anno ? -1 : 0;
        });
        return annualita;
    }

    //se fiilIntervento è true riempie la combo degli interventi escludendo i main
    filterInterventions(fillIntervento: boolean, renderData: boolean) {
        if (renderData) {
            this.disableFilters();
            this.showLoading = true;
        } else {
            this.disposeCharts();
            this.showPage = false;
        }

        const arrayOfProjecId = this.filterObject.filterProjectId
            ? [this.filterObject.filterProjectId]
            : [-1];

        this.dashboardService.getDashboardPnrrSimplData(this.filterObject)
            .subscribe((res) => {
                this.noresult = renderData && (!res || res.length === 0);
                this.showPage = renderData && !this.noresult;

                setTimeout(() => {
                    this.completeFilterInterventions(fillIntervento, renderData, res, arrayOfProjecId);
                }, 1);
        }, error => console.log(error));
    }

    private completeFilterInterventions(fillIntervento: boolean, renderData: boolean, res: any[], arrayOfProjecId: number[]) {
        if (renderData) {
            this.filteredInterventions = res.map((i) => {
                i["label"] = `${i.codice} - ${i.descrizione}`;
                return i;
            });
        }
        if (fillIntervento) {
            this.filteredInterventions = res.map((i) => {
                i["label"] = `${i.codice} - ${i.descrizione}`;
                return i;
            });

            this.interventiCombo = this.filteredInterventions.filter(
                (i) => !i.isMain || i.numSubInterventi <= 0
            );
            this.completerParams = {
                items: this.interventiCombo,
                labelField: "label",
                ajax: null,
                placeholder: "Cerca intervento ",
            };
        }

        if (!renderData) {
            this.showLoading = false;
            this.enableFilters();
            return;
        }

        setTimeout(() => {
            this.map && this.map.setInterventions(this.filteredInterventions);
            const arrayOfMainId = this.filterObject.filterMainInterventionId
                ? [this.filterObject.filterMainInterventionId]
                : [-1];

            if (arrayOfMainId.length > 0 && arrayOfMainId[0] > 0) {
                this.map.createViewerLayer(arrayOfProjecId, null);
            } else {
                this.map.createViewerLayer(null, null);
            }
        }, 300);

        //layer con tutti gli interventi che non sono main
        this.onlyNormalIntervention = this.filteredInterventions.filter(
            (i) => !i.isMain
        );

        this.annualita = this.countImportiPerAnnualita();
        this.annualita && this.countTotImporti(this.annualita);

        this.disposeCharts();
        this.renderCharts();
        this.executeGetValoriProgettiAsync().then((x) => { });
        // this.loadDatiTabellaDettaglio();
    }

    isFiltered(): boolean {
        if (
            // this.filterObject.filterSoggetti !== -1 ||
            // this.filterObject.filterProvincia !== 'all' ||
            this.filterObject.filterProjectId >= 0 ||
            this.filterObject.filterMainInterventionId >= 0 ||
            this.filterObject.filterSimpleInterventionId >= 0 ||
            this.filterObject.filterAreeGestionaliDirezioneEsterne >= 0 ||
            (this.filterObject.filterTipologiaIntervento && this.filterObject.filterTipologiaIntervento.length > 0 && this.filterObject.filterTipologiaIntervento[0] != -1) ||
            (this.filterObject.filterLineeFinanziamentiPnrr &&
                ((this.filterObject.filterLineeFinanziamentiPnrr.missions && this.filterObject.filterLineeFinanziamentiPnrr.missions.length > 0) ||
                    (this.filterObject.filterLineeFinanziamentiPnrr.components && this.filterObject.filterLineeFinanziamentiPnrr.components.length > 0) ||
                    (this.filterObject.filterLineeFinanziamentiPnrr.lines && this.filterObject.filterLineeFinanziamentiPnrr.lines.length > 0))) ||
            (this.filterObject.filterPnrr > -1 && this.filterObject.filterPnrr != 1) ||
            this.filterObject.filterProvince > -1 ||
            this.filterObject.filterComune > -1 ||
            (this.filterObject.filterAggregato.length > 0 && this.filterObject.filterAggregato[0] != -1) ||
            this.filterObject.filterSoggettoAttuatore > -1
        )
            this.isShowingFiltered = true;
        else this.isShowingFiltered = false;
        return this.isShowingFiltered;
    }


    esportaExcel() {
        this.loadingExportExcel = true;
        this.dashboardService.esportaDashboardPnrrWithLatLong(this.filterObject).subscribe(
            (res) => {
                const filename = `esportazione_pnrr_${moment(new Date()).format(
                    "YYYY_MM_DDTHH_mm_ss"
                )}.xlsx`;
                FileSaver.saveAs(res.body, `${filename}`);
                this.loadingExportExcel = false;
            },
            (error) => {
                this.toastr.error(
                    `Errore: ${error.error.message
                        ? error.error.message
                        : error.error.error_description
                            ? error.error.error_description
                            : error.error
                    }`,
                    null,
                    {
                        timeOut: 2000,
                        disableTimeOut: false,
                    }
                );
                //console.log("error");
                this.loadingExportExcel = false;
            }
        );
    }

    isCustomHeader() {
        return this.authService.getHeaderCode() != 'generale';
    }
    getHeaderLabel() {
        return this.isCustomHeader() ? 'Piano Nazionale Ripresa e Resilienza' : 'Direzione Regionale Infrastrutture e Mobilità';
    }
    isAdmin() {
        return this.authService.isAdmin();
    }
    isGr34() {
        return this.authService.isGr34();
    }
}
