
<div  class="text-center p-5 position-absolute start-50 translate-middle bg-white border border-dark rounded shadow"
style="z-index: 999999999; top:50vh" [hidden]="showPage">
  <app-loading message="Caricamento in corso..."></app-loading>
</div>

<div [hidden]="!showPage" id="map-container" [formGroup]="myForm">
  <!--titolo modale-->
  <div class="modal-header text-center py-1">
    <h4 class="modal-title pull-left">Localizzazione</h4>
    <button type="button" class="close pull-left" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!--fine titolo modale-->
  <!--info-->
  <div class="card card-body divMsgClose" [ngClass]="{ divMsgOpen: divMsgOpen, divMsgClose: !divMsgOpen }"
    style="padding-bottom: 0 !important">
    <div class="text-justify overflow-auto px-3 infoH" [hidden]="!freehand" style="font-size: 14px">
      <strong>Mano libera:</strong> Questa modalità permette di disegnare sopra un layer in modo fedele grazie alla
      funzione calamita che ancora il disegno al layer sottostante. Per disegnare clicca e tieni premuto il bottone del
      mouse per disegnare
      un elemento, rilascia il bottone al termine; ripetere la procedura per inserire più elementi. Una volta terminato
      l'inserimento di tutti gli elementi premere il bottone "<span class="text-success">Salva&nbsp;<i
          class="fas fa-save"></i></span>" per confermare. Per unire due elementi, all'interno del menu laterale,
      spuntare le caselle degli elementi che si desidera unire e premere il bottone "<span
        class="text-success">Salva/Unisci&nbsp;<i class="fas fa-save"></i></span>"
      per confermare l'unione; nota bene non sarà più possibile dividere due elementi una volta salvata l'unione. E'
      possibile eliminare elementi prima e dopo il salvataggio, premendo il tasto "<span
        class="text-danger">Elimina&nbsp;<i style="font-size: 14px" class="far fa-trash-alt hover-normal"></i></span>"
      all'interno del menù laterale; nel caso di elementi già salvati sarà necessario premere il bottone "<span
        class="text-success">Salva&nbsp;<i class="fas fa-save"></i></span>" per confermare la cancellazione.
    </div>
    <div class="text-justify overflow-auto px-3 infoH" [hidden]="freehand" style="font-size: 14px">
      <strong>Polilinea:</strong> Questa modalità permette di disegnare una o più rette passanti per due punti, la
      funzione calamita è disabilitata quindi non necessità di un layer sottostante. Per disegnare clicca per inserire i
      punti dove passerà
      la linea, fai doppio click per terminare; ripetere la procedura per inserire più elementi. Una volta terminato
      l'inserimento di tutti gli elementi premere il bottone "<span class="text-success">Salva&nbsp;<i
          class="fas fa-save"></i></span>" per confermare. Per unire due elementi, all'interno del menù laterale,
      spuntare le caselle degli elementi che si desidera collegare e premere il bottone "<span
        class="text-success">Salva/Unisci&nbsp;<i class="fas fa-save"></i></span>"
      per confermare l'unione; nota bene non sarà più possibile dividere due elementi una volta salvata l'unione. E'
      possibile eliminare elementi prima e dopo il salvataggio, premendo il tasto "<span
        class="text-danger">Elimina&nbsp;<i style="font-size: 14px" class="far fa-trash-alt hover-normal"></i></span>"
      all'interno del menu laterale; nel caso di elementi già salvati sarà necessario premere il bottone "<span
        class="text-success">Salva&nbsp;<i class="fas fa-save"></i></span>" per confermare la cancellazione.
    </div>
    <div class="col-lg-12 text-center p-0">
      <button type="button" class="arrow" (click)="divMsgOpen = false">
        <span aria-hidden="true">
          <i class="fas fa-arrow-circle-up p-1"></i>
        </span>
      </button>
    </div>
  </div>

  <div id="collapseExample" class="collapse" [hidden]="arrowHidden">
    <div class="col-lg-12">
      <button type="button" class="arrow arrow-none mx-auto" [ngClass]="{ 'arrow-block': !divMsgOpen }"
        (click)="divMsgOpen = true">
        <span aria-hidden="true">
          <i class="fas fa-arrow-circle-down p-1"></i>
        </span>
      </button>
    </div>
  </div>

  <!--fine info -->

  <!-- modal body-->
  <div class="modal-body p-0">
    <!--switch-->

    <div [hidden]="readOnly" class="rounded position-absolute switch-left-right p-1"
      style="top: 0.5em; z-index: 6000; background: rgba(255, 255, 255, 0.4)">
      <label class="switch m-1">
        <input id="editor" class="switch-input" (click)="changeEditorMode()" type="checkbox" />
        <span class="switch-label" data-on="Visulizzatore" data-off="Editor"></span>
        <span class="switch-handle"></span>
      </label>
    </div>

    <!-- zoom -->
    <!--<div class="ol-zoom ol-unselectable ol-control" style="pointer-events: auto;">
          <button class="ol-zoom-in" type="button" title="Ingrandire" id="zoom-out">
              <span style="vertical-align: inherit;">
                  <span style="vertical-align: inherit;">+</span>
              </span>
          </button>
          <button class="ol-zoom-out" type="button" title="Riduci lo zoom" id="zoom-in">
              <span style="vertical-align: inherit;">
                  <span style="vertical-align: inherit;">-</span>
              </span>
          </button>
      </div>-->

    <!--seleziona mappa-->
    <div [hidden]="false" class="rounded form-group position-absolute position-custom-map-bg"
      style="z-index: 1010;background: rgba(255, 255, 255, 0.4);">
      <!--<h6 class="text-0083e0 " for="exampleFormControlSelect1">Seleziona Tipologia Mappa</h6>-->
      <div class="d-flex mx-3 my-1 " style="z-index: 1000">
        <i class="fas fa-map mt-1 mr-1 text-0083e0" style="font-size: 24px"></i>
        <ng-select id="map-background" class="custom-select-if" style="min-width: 185px; z-index: 1000"
          [items]="conf.baseMapsLayers" bindValue="code" bindLabel="title" groupBy="group" placeholder="Seleziona Mappa"
          formControlName="basemapChooser" selectOnTab="true" [clearable]="true" [searchable]="true"
          (change)="chooseBasemap($event)">
        </ng-select>
      </div>

    </div>

    <div class="position-absolute position-custom-search"
      style="  z-index: 1000; background: rgba(255, 255, 255, 0.4);">
      <div class="d-flex mx-3 my-1">
        <i class="fas fa-search-location mt-1 mr-1 text-0083e0" style="font-size: 24px"></i>

        <!--<input formControlName="geocodeSearchCity" placeholder="Citta" type="text" class="form-control col-lg-3">-->
        <input formControlName="geocodeSearchAddress" placeholder="es. via Bianchi Roma 00149" type="text"
          class="form-control form-control-sm " style="min-width: 185px; max-width: 65%;">

        <button class="btn btn-primary btn-sm" type="button" (click)="searchPlaces()">Cerca</button>

      </div>
      <div class="" [hidden]="isGeocodeHidden" *ngFor="let place of geocodedPlaces" style="z-index: 9000;">
        <ul class="list-group m-1" style="z-index: 21000;">
          <li class="list-group-item py-1">
            <div class="input-group">
              <a class="text-secondary" type="button" (click)="centerMap(place)"><i
                  class="text-info fas fa-search-location mr-1"></i>{{place.name}}</a>

            </div>
          </li>

        </ul>
      </div>
    </div>
    <!--***-->




    <div class="rounded form-group position-absolute attribuzione" style="
      /*min-width: 180px;*/

      left: 1em !important;
      z-index: 9000;
      background: rgba(255, 255, 255, 0.4);
    ">
      <!--attribuzione carte-->

      <div class=" " style="pointer-events: auto; display: contents">
        <p class="px-3 mb-0 py-1"><small>
            <a rel="nofollow" href="{{ this.attribution.url }}" target="_blank">{{
          this.attribution.text1
        }}</a> {{ this.attribution.text2 }}
          </small></p>
      </div>
    </div>

    <!--sidebar-->
    <div id="sidebar" class="sidebar collapsed sidebar-custom sidebar-top" style="z-index: 10000">
      <!-- Nav tabs -->
      <div class="sidebar-tabs sidebar-custom">
        <ul role="tablist">
          <li>
            <a id="geodraw" href="#interactions" role="tab"><i [hidden]="editor !== 'Editor'"
                class="fas fa-map-marker-alt" title="localizza"></i><i [hidden]="editor === 'Editor'"
                class="fas fa-map-marked-alt" title="lista interventi"></i></a>
          </li>

          <li id="layerslist" [hidden]="editor === 'Editor'">
            <a href="#layers" role="tab"><i class="fas fa-layer-group" title="lista layer"></i></a>
          </li>



          <li [hidden]="editor !== 'Editor'">
            <a>
              <i class="fas fa-route mt-2" title="numero elementi">
                <p>{{ this.streetFeatures.length }}</p>
              </i></a>
          </li>
          <li id="clone" [hidden]="editor !== 'Editor' || readOnly" (click)="cloneElement()">
            <a href="javascript:;" role="tab"><i class="fas fa-clone" title="{{cloneTextButton}}"></i></a>
          </li>

          <li [hidden]="editor !== 'Editor' || readOnly" (click)="saveDrawedInterventions()">
            <a role="tab"><i class="fas fa-save pointer" Title="salva"></i></a>
          </li>

          <li [hidden]="editor !== 'Editor'" (click)="zoomToHighLightedFeatures()">
            <a role="tab">
              <i class="fas fa-crosshairs pointer" style="font-size: 18px"
                title="centra su gli elementi selezionati"></i></a>
          </li>

          <li [hidden]="true">
            <a href="#filters" role="tab"><i class="fa fa-filter"></i></a>
          </li>
          <li [hidden]="true">
            <a href="#filters" role="tab"><i class="fa fa-filter"></i></a>
          </li>
        </ul>
        <ul style="bottom: 0">
          <li (click)="legendIconOnOff()">
            <a role="tab">
              <i class="fas fa-info pointer" style="font-size: 18px" title="legenda icone"></i></a>
          </li>
          <li>
            <a href="/inframob/manualistica/manualistica/manuale-localizzazione/" target="”_blank”">
              <i class="fas fa-book pointer" style="font-size: 18px" title="manuale utente"></i></a>
          </li>
        </ul>
      </div>

      <!-- Tab panes -->

      <div class="sidebar-content">
        <div class="sidebar-pane" id="layers">
          <h1 class="sidebar-header">
            Lista layer
            <span class="sidebar-close"><i class="fa fa-caret-left"></i></span>
          </h1>

          <!--<div [hidden]="false" class="form-group">
                      <h5 class="text-0083e0 " for="exampleFormControlSelect1">Seleziona Tipologia Mappa</h5>
                      <ng-select [items]="conf.baseMapsLayers" bindValue="code" bindLabel="title" groupBy="group"
                          placeholder="Seleziona layer" formControlName="basemapChooser" selectOnTab="true"
                          [clearable]="true" [searchable]="true" (change)="chooseBasemap($event)">
                      </ng-select>
                  </div>-->
          <div class="mt-3">
            <p>Selezionare i layer che si desidera visualizzare nella mappa.</p>
          </div>

          <hr />
          <div class="scroll-h" style="max-height: 490px; margin-right: -5px; margin-left: -5px">
            <div class="form-check pl-0">
              <ul class="text-0083e0 mb-0 pl-4" *ngFor="let title of conf.vectorLayers; let i = index"
                [attr.data-index]="i">
                <li id="{{ conf.vectorLayers[i].vectorLayerName }}{{
                  conf.vectorLayers[i].vectorLayerName
                }}">
                  <label class="form-check-label">
                    <input type="checkbox" id="{{ conf.vectorLayers[i].vectorLayerName }}" (change)="
                      toggleVisibility(
                        $event,
                        conf.vectorLayers[i].vectorLayerTitle
                      )
                    " class="form-check-input" />
                    {{ conf.vectorLayers[i].vectorLayerTitle }}</label>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="sidebar-pane pl-2 pr-3" id="interactions">
          <h1 class="sidebar-header" [hidden]="editor !== 'Editor'">
            Localizza
            <span class="sidebar-close"><i class="fa fa-caret-left"></i></span>
          </h1>
          <h1 class="sidebar-header" [hidden]="editor === 'Editor'">
            Stato Interventi
            <span class="sidebar-close"><i class="fa fa-caret-left"></i></span>
          </h1>

          <ng-select class="mt-2" [hidden]="editor !== 'Editor' || readOnly" [items]="layersForDrawing"
            bindValue="vectorLayerType" bindLabel="vectorLayerTitle" groupBy="group" placeholder="Seleziona layer"
            formControlName="drawingTypeChooser" selectOnTab="true" [clearable]="true" [searchable]="true"
            (change)="setDrawingLayer($event)">
          </ng-select>

          <ng-select [hidden]="checkHidden()" class="mt-2" [items]="geometryTypes" bindValue="code" bindLabel="title" groupBy="group"
            placeholder="Seleziona geometria" (change)="setGeometry($event)" formControlName="geometryChooser"
            selectOnTab="true" [clearable]="true" [searchable]="true">
          </ng-select>

          <div [hidden]="editor !== 'Editor' || !showFreehandChooser" class="row">
            <div class="col-lg-12 text-center">
              <hr class="my-2"/>
              <p class="mb-1" style="font-size: 18px">
                Seleziona la modalità di disegno
              </p>
              <div class="form-check form-check-inline text-0083e0">
                <input class="form-check-input pointer" [checked]="freehand" (click)="chooseDrawingType('freehand')"
                  type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                <label class="form-check-label pointer" for="inlineRadio1">Mano libera&nbsp;
                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                  </svg></label>
              </div>
              <div class="form-check form-check-inline text-0083e0">
                <input class="form-check-input pointer" type="radio" (click)="chooseDrawingType('polyline')"
                  name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                <label class="form-check-label pointer" for="inlineRadio2">Polilinea&nbsp;
                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-share" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                  </svg></label>
              </div>
            </div>
          </div>

          <div [hidden]="editor !== 'Editor' || readOnly" *ngIf="showButton">
            <hr class="my-2"/>
            <button [hidden]="editor !== 'Editor' " [disabled]="
              disableDraw ||
              (drawingType === 'proj' &&
                (drawingType !== 'proj' || !f.geometryChooser.value))
            " class="btn btn-primary btn-block btn-sm" (click)="enableDraw()" data-toggle="collapse"
              data-target="#collapseExample">
              {{
              this.isDrawing == true
                ? "Disattiva Modalità Inserimento"
                : "Attiva Modalità Inserimento"
            }}
            </button>
            <!--<hr>
                      <button *ngIf="canSave" class="btn btn-success btn-block"
                          (click)="saveDrawedInterventions()">Salva Intervento Strade&nbsp;<i class="fas fa-save"></i>
                      </button>-->
          </div>

          <div>


            <hr class="my-2" [hidden]="editor !== 'Editor'" />



            <button class="btn btn-info btn-block bold btn-sm" [hidden]="editor !== 'Editor'"
              (click)="cloneElement()">{{ cloneTextButton}}<i class="fas fa-clone"
                title="{{cloneTextButton}}"></i></button>


            <!-- pulsante KMZ commentato -->
            <hr class="my-2" [hidden]="editor !== 'Editor'" />
            <!--TODO  occhio agli hidden true-->
            <button [hidden]="true"  class="btn btn-primary btn-block bold btn-sm" data-toggle="collapse" data-target="#collapsekmz" aria-expanded="false" aria-controls="collapsekmz" [hidden]="editor !== 'Editor' || readOnly">Carica KMZ <i class="fas fa-file" title="Carica KMZ"></i></button>

            <div class="collapse " id="collapsekmz">
              <div>
                <ng-select class="mt-2"  [items]="layersForKmz" formControlName="kmzTypeChooser"
                bindValue="vectorLayerType" bindLabel="vectorLayerTitle" groupBy="group" placeholder="Seleziona tipologia"
                 selectOnTab="true" [clearable]="true" [searchable]="true"
                (change)="setKMZtype($event)">
              </ng-select>
              </div>
                <div class="input-group mt-2" [hidden]="editor !== 'Editor'">
                  <div class="custom-file uploadfile ">
                    <input type="file" class="custom-file-input" id="myInput" aria-describedby="myInput" >
                    <label class="custom-file-label text-truncate" for="myInput" style="max-width: 211px;">Seleziona
                      file</label>
                  </div>
                  <div class="input-group-append">
                    <button (click)="uploadKmz()" class="btn btn-primary" type="button" id="myInput">Carica</button>
                  </div>
                </div>
              </div>
            <hr class="my-2" [hidden]="editor !== 'Editor'" />



            <button [hidden]="editor !== 'Editor' || readOnly" [disabled]="!canSave"
              class="btn btn-success btn-block bold btn-sm" (click)="saveDrawedInterventions()">
              {{ this.merged ? "Salva/Unisci&nbsp;" : "Salva&nbsp;"
            }}<i class="fas fa-save" Alt="salva"></i>
            </button>
            <!-- <button [hidden]="editor !== 'Editor' || !showMergeButton" class="btn btn-info btn-block"
                          (click)="doMerge()">Unisci&nbsp;<i class="fas fa-save" Alt="salva"></i>
                      </button> -->
          </div>

          <div class="scroll-h mt-2" [ngClass]="list1Height ? 'mh400' : 'mh230'"
            style="margin-right: -5px; margin-left: -5px">
            <div [hidden]="editor !== 'Editor'" class="form-check pl-2 list-container mt-2 text-left"
              *ngFor="let s of streetFeatures; let i = index" [attr.data-index]="i">
              <ul (click)="
                nuovaFunzioneDiSelezioneEUnione($event, streetFeatures[i])
              " class="pointer text-0083e0 mb-0 pl-4">
                <li id="element{{ i }}" class="noDot" [ngClass]="
                  streetFeatures[i].saved ? 'text-save' : 'text-no-save'
                ">
                  <input id="checkMerge{{ i }}" [disabled]="
                    streetFeatures[i].feature.values_.type === 'points' ||
                    streetFeatures[i].feature.values_.type === 'port' ||
                    streetFeatures[i].feature.values_.type === 'station' ||
                    streetFeatures[i].feature.values_.type === 'park' ||
                    streetFeatures[i].feature.values_.type === 'nodes' ||
                    streetFeatures[i].feature.values_.type === 'parkland' ||
                    streetFeatures[i].feature.values_.type === 'emobility' ||
                    streetFeatures[i].feature.values_.type === 'metrostop' ||
                    streetFeatures[i].feature.values_.type === 'asl' ||
                    streetFeatures[i].feature.values_.type === 'plants' ||
                    streetFeatures[i].feature.values_.type === 'networks_satellites' ||
                    streetFeatures[i].feature.values_.type === 'bonifiche_territorio_punti' ||
                    streetFeatures[i].feature.values_.type === 'aeroporti' ||
                    streetFeatures[i].feature.values_.type === 'musei_cultura' ||
                    streetFeatures[i].feature.values_.type === 'cimiteri' ||
                    streetFeatures[i].feature.values_.type === 'luoghi_di_culto' ||
                    streetFeatures[i].feature.values_.type === 'hospitals' ||
                    streetFeatures[i].feature.values_.type === 'impianti_sportivi' ||
                    streetFeatures[i].feature.values_.type === 'interventi_sul_sociale' ||
                    streetFeatures[i].feature.values_.type === 'stazioni_autolinee' ||
                    streetFeatures[i].feature.values_.type === 'impianti_energie_rinnovabili' ||
                    streetFeatures[i].feature.values_.type === 'opere_urbanizzazione'
                  " title="spuntare per collegare" type="checkbox" class="form-check-input pointer" />
                  &nbsp;&nbsp;&nbsp;
                  <i class="fas fa-unlink"> </i> &nbsp;&nbsp;&nbsp;

                  <label class="pointer">
                    <span class="pointer" [ngSwitch]="streetFeatures[i].feature.values_.type">
                      <i *ngSwitchCase="'rail'" class="fas fa-train" title="elemento ferrovia"></i>
                      <i *ngSwitchCase="'road'" class="fas fa-road" title="elemento strada"></i>
                      <i *ngSwitchCase="'points'" class="fas fa-school" title="elemento edificio scolastico"></i>
                      <i *ngSwitchCase="'bike'" class="fas fa-bicycle" title="elemento pista ciclabile"></i>
                      <i *ngSwitchCase="'parkland'" class="fas fa-tree" title="elemento parchi e aree verdi"></i>
                      <i *ngSwitchCase="'emobility'" class="fas fa-plug" title="elemento e-mobility"></i>
                      <i *ngSwitchCase="'port'" class="fas fa-anchor" title="elemento porto"></i>
                      <span *ngSwitchCase="'metrostop'" title="elemento stazione metro" class="bold"
                        style="font-size: 18px">M</span>

                      <i *ngSwitchCase="'park'" class="fab fa-product-hunt" title="elemento parcheggi"></i>
                      <i *ngSwitchCase="'nodes'" class="fas fa-parking" title="elemento nodi di scambio"></i>
                      <span *ngSwitchCase="'station'" title="elemento stazione ferroviaria"><svg
                          *ngSwitchCase="'station'" height="16" width="16" viewBox="0 0 16 16"
                          class="bi bi-geo-fill mb-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd"
                            d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z" />
                        </svg></span>
                      <i *ngSwitchCase="'metro'" class="fas fa-subway" title="elemento metro"></i>
                      <i *ngSwitchCase="'other_lines'" class="fas fa-bacon"
                        title="elemento altre vie di comunicazione"></i>
                      <i *ngSwitchCase="'other_points'" class="fas fa-building" title="elemento edificio generico"></i>
                      <i *ngSwitchCase="'skilift'" class="fas fa-tram" title="elemento impianto di risalita"></i>

                      <i *ngSwitchCase="'asl'" class="fas fa-briefcase-medical" title="elemento Asl"></i>
                      <i *ngSwitchCase="'plants'" class="fas fa-industry" title="elemento impianti (Acqua-Luce-Gas)"></i>
                      <i *ngSwitchCase="'networks_satellites'" class="fas fa-satellite-dish" title="elemento Reti e satelliti"></i>
                      <i *ngSwitchCase="'bonifiche_territorio_punti'" class="fab fa-pagelines" title="bonifiche territorio (punti)"></i>
                      <i *ngSwitchCase="'bonifiche_territorio_linee'" class="fas fa-leaf" title="bonifiche territorio (linee)"></i>

                      <i *ngSwitchCase="'cimiteri'" class="fas fa-cross" title="edilizia cimiteriale"></i>
                      <i *ngSwitchCase="'musei_cultura'" class="fas fa-landmark" title="musei biblioteche cultura"></i>
                      <i *ngSwitchCase="'aeroporti'" class="fas fa-plane" title="aeroporti"></i>
                      <i *ngSwitchCase="'luoghi_di_culto'" class="fas fa-church" title="luoghi di culto"></i>
                      <i *ngSwitchCase="'hospitals'" class="fas fa-hospital" title="ospedali - presidi"></i>

                      <i *ngSwitchCase="'impianti_sportivi'" class="fas fa-futbol" title="impianti sportivi"></i>
                      <i *ngSwitchCase="'interventi_sul_sociale'" class="fas fa-hand-holding-heart" title="interventi sul sociale"></i>
                      <i *ngSwitchCase="'stazioni_autolinee'" class="fas fa-bus-alt" title="stazioni - autolinee"></i>
                      <i *ngSwitchCase="'impianti_energie_rinnovabili'" class="fas fa-seedling" title="impianti (energie rinnovabili)"></i>
                      <i *ngSwitchCase="'opere_urbanizzazione'" class="fas fa-hard-hat" title="opere di urbanizzazione"></i>


                    </span>
                    <span>&nbsp;&nbsp;&nbsp;elemento
                      {{ streetFeatures.length - i }}&nbsp;&nbsp;&nbsp;</span>
                    <i class="far fa-trash-alt pointer" title="elimina" (click)="delete(i)"></i></label>
                </li>
              </ul>
            </div>
          </div>

          <h5 class="mt-3" [hidden]="editor === 'Editor'">Lista Interventi</h5>

          <div class="scroll-h" style="max-height: 530px">
            <ul [hidden]="editor === 'Editor'" class="pl-0">
              <li class="list-none" (click)="removeHighLight()" style="color: #aa66cc">
                <i class="far fa-question-circle help" style="font-size: 14px" title="{{
                  currentIntervention ? currentIntervention.descrizione : ''
                }}"></i>
                <span style="letter-spacing: -1px" class="pointer look">
                  Progetto/Fabbisogno
                  {{
                  currentIntervention ? currentIntervention.codice : ""
                }}&nbsp;&nbsp;
                </span>

                <!-- icona occhio-->
                <span class="look">
                  <i *ngIf="getDrawedLength() > 1" (click)="showHideAllIntervention()" class="pointer fas" [ngClass]="{
                    'fa-eye': !isAllHidden,
                    'fa-eye-slash': isAllHidden
                  }" title="{{
                    !isAllHidden ? 'nascondi tutti' : 'mostra tutti'
                  }}">
                  </i>
                </span>

                <!-- lista interventi -->
                <div [hidden]="editor === 'Editor'" class="list-container mt-2"
                  *ngFor="let s of allInterventionsWithStatus; let i = index" [attr.data-index]="i">
                  <ul class="pl-1">
                    <li class="list-none" *ngIf="
                      allInterventionsWithStatus[i].drawed &&
                      ((allInterventionsWithStatus[i].isMain &&
                        isOnlyProject) ||
                        !allInterventionsWithStatus[i].isMain)
                    " [ngClass]="
                      allInterventionsWithStatus[i].validated === 'APPROVED'
                        ? 'text-ff8800'
                        : allInterventionsWithStatus[i].validated ===
                          'TERMINATO'
                        ? 'text-success'
                        : 'text-danger'
                    ">
                      <!--icona ?-->
                      <i class="far fa-question-circle help" style="font-size: 14px"
                        title="{{ allInterventionsWithStatus[i].descrizione }}">
                      </i>

                      <!--gruppo che reagisce al click seleziona-->
                      <span id="intervention{{ i }}" (click)="
                        highlightIntervent(
                          $event,
                          allInterventionsWithStatus[i],
                          i
                        )
                      ">
                        <!--icona tipologia-->
                        <span class="pointer" [ngSwitch]="allInterventionsWithStatus[i].type">
                          &#8627;
                          <i *ngSwitchCase="'rail'" class="fas fa-train" title="elemento ferrovia"></i>
                          <i *ngSwitchCase="'road'" class="fas fa-road" title="elemento strada"></i>
                          <i *ngSwitchCase="'points'" class="fas fa-school" title="elemento edificio"></i>
                          <i *ngSwitchCase="'bike'" class="fas fa-bicycle" title="elemento pista ciclabile"></i>
                          <i *ngSwitchCase="'parkland'" class="fas fa-tree" title="elemento parchi e aree verdi"></i>
                          <i *ngSwitchCase="'emobility'" class="fas fa-plug" title="elemento e-mobility"></i>
                          <i *ngSwitchCase="'port'" class="fas fa-anchor" title="elemento porto"></i>
                          <span *ngSwitchCase="'metrostop'" title="elemento stazione metro" class="bold"
                            style="font-size: 18px">M</span>
                          <i *ngSwitchCase="'park'" class="fab fa-product-hunt" title="elemento parcheggi"></i>
                          <i *ngSwitchCase="'nodes'" class="fas fa-parking" title="elemento nodi di scambio"></i>
                          <span *ngSwitchCase="'station'" title="elemento stazione ferroviaria">
                            <svg *ngSwitchCase="'station'" height="16" width="16" viewBox="0 0 16 16"
                              class="bi bi-geo-fill mb-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd"
                                d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z" />
                            </svg>
                          </span>
                          <i *ngSwitchCase="'metro'" class="fas fa-subway" title="elemento metro"></i>
                          <i *ngSwitchCase="'other_lines'" class="fas fa-bacon" title="elemento altre vie di comunicazione"></i>
                          <i *ngSwitchCase="'other_points'" class="fas fa-building" title="elemento edificio generico"></i>

                          <i *ngSwitchCase="'asl'" class="fas fa-briefcase-medical" title="elemento Asl"></i>
                          <i *ngSwitchCase="'plants'" class="fas fa-industry" title="elemento impianti (Acqua-Luce-Gas)"></i>
                          <i *ngSwitchCase="'networks_satellites'" class="fas fa-satellite-dish" title="elemento Reti e satelliti"></i>
                          <i *ngSwitchCase="'bonifiche_territorio_punti'" class="fab fa-pagelines" title="bonifiche territorio (punti)"></i>
                          <i *ngSwitchCase="'bonifiche_territorio_linee'" class="fas fa-leaf" title="bonifiche territorio (linee)"></i>
                          <i *ngSwitchCase="'cimiteri'" class="fas fa-cross" title="edilizia cimiteriale"></i>
                          <i *ngSwitchCase="'musei_cultura'" class="fas fa-landmark" title="musei biblioteche cultura"></i>
                          <i *ngSwitchCase="'aeroporti'" class="fas fa-plane" title="aeroporti"></i>
                          <i *ngSwitchCase="'luoghi_di_culto'" class="fas fa-church" title="luoghi di culto"></i>
                          <i *ngSwitchCase="'hospitals'" class="fas fa-hospital" title="ospedali - presidi"></i>
                          <i *ngSwitchCase="'impianti_sportivi'" class="fas fa-futbol" title="impianti sportivi"></i>
                          <i *ngSwitchCase="'interventi_sul_sociale'" class="fas fa-hand-holding-heart" title="interventi sul sociale"></i>
                          <i *ngSwitchCase="'stazioni_autolinee'" class="fas fa-bus-alt" title="stazioni - autolinee"></i>
                          <i *ngSwitchCase="'impianti_energie_rinnovabili'" class="fas fa-seedling" title="impianti (energie rinnovabili)"></i>
                          <i *ngSwitchCase="'opere_urbanizzazione'" class="fas fa-hard-hat" title="opere di urbanizzazione"></i>


                          <span *ngSwitchCase="'skilift'"><svg class="mb-1" *ngSwitchCase="'skilift'" height="16"
                              width="16" x="0px" y="0px" viewBox="-295 387 18 16" fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" d="M-283.9,390.3c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1
                                             S-284.5,390.3-283.9,390.3z M-276,389.8c-0.1-0.3-0.4-0.5-0.7-0.4l-16.9,4.5c-0.3,0.1-0.5,0.4-0.4,0.7c0.1,0.2,0.3,0.4,0.5,0.4
                                             c0,0,0.1,0,0.1,0l7.7-2.1v2.9h-6.2c-0.6,0-1.1,0.5-1.1,1.1v7.9c0,0.6,0.5,1.1,1.1,1.1h13.5c0.6,0,1.1-0.5,1.1-1.1V397
                                             c0-0.6-0.5-1.1-1.1-1.1h-6.2v-3.2l8-2.1C-276.1,390.4-275.9,390.1-276,389.8z M-287.8,401.5h-3.4v-3.4h3.4V401.5z M-282.2,398.1h3.4
                                             v3.4h-3.4V398.1z M-283.3,398.1v3.4h-3.4v-3.4H-283.3z M-287.3,391.4c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1
                                             c-0.6,0-1.1,0.5-1.1,1.1S-287.9,391.4-287.3,391.4z" /></svg></span>
                        </span>
                        <!-- testo -->
                        <span class="pointer">
                          &nbsp;intervento
                          {{ allInterventionsWithStatus[i].codice}}&nbsp;
                        </span>
                      </span>
                      <!-- icona occhio-->
                      <span class="look">
                        <i (click)="
                          showHideIntervention(allInterventionsWithStatus[i])
                        " class="fas pointer" [ngClass]="{
                          'fa-eye': allInterventionsWithStatus[i].visible,
                          'fa-eye-slash': !allInterventionsWithStatus[i]
                            .visible
                        }" title="{{
                          allInterventionsWithStatus[i].visible
                            ? 'nascondi'
                            : 'mostra'
                        }}">
                        </i>
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="sidebar-pane" id="filters">
          <h1 class="sidebar-header">
            Filtri<span class="sidebar-close"><i class="fa fa-caret-left"></i></span>
          </h1>
          <hr />
          <ng-select [items]="allFilterLayers" bindValue="values_.title" bindLabel="values_.title" groupBy="group"
            placeholder="Seleziona un layer" formControlName="layerChooser" selectOnTab="true" [clearable]="true"
            [searchable]="true" (change)="getFeatures($event)">
          </ng-select>
          <hr />
          <ng-select [items]="layerProperties" bindValue="key" bindLabel="key" groupBy="group"
            placeholder="Seleziona una proprietà" formControlName="propertyChooser" selectOnTab="true"
            [clearable]="true" [searchable]="true" (change)="getFeatureValues($event)">
          </ng-select>
          <hr />
          <ng-select [items]="propertiesValues" [multiple]="true" bindValue="key" bindLabel="key" groupBy="group"
            placeholder="Seleziona un valore" formControlName="valueChooser" selectOnTab="true" [clearable]="true"
            [searchable]="true" (change)="setFilter($event)">
          </ng-select>
          <br />
          <button class="btn btn-primary mx-auto d-block" (click)="addFilter()">
            Aggiungi Filtro
          </button>
          <hr />
          <div *ngFor="let filter of selectedFilters">
            {{ filter }}
          </div>
          <hr *ngIf="selectedFilters.length" />
          <div class="row">
            <button *ngIf="selectedFilters.length" class="btn btn-primary mx-auto d-block" (click)="applyFilters()">
              Applica Filtro
            </button>
            <button *ngIf="selectedFilters.length" class="btn btn-danger mx-auto d-block" (click)="removeFilters()">
              Rimuovi Filtro
            </button>
          </div>
        </div>

        <div class="sidebar-pane" id="settings">
          <h1 class="sidebar-header">
            Impostazioni<span class="sidebar-close"><i class="fa fa-caret-left"></i></span>
          </h1>
        </div>
      </div>
    </div>

    <!--fine sidebar-->
  </div>
  <!--fine modal body-->

  <!--mappa-->
  <div [ngClass]="{ pen: isDrawing, waiting: waiting }" id="map" class="">
    <!--card-->
    <div #popup [hidden]="true" [ngSwitch]="popupSelectedLayer" class="shadow card bg-light mb-3"
      style="max-width: 18rem">
      <!--<div class="card-header text-white bold">Info</div>-->
      <div class="card-body">
        <p class="mb-1" style="font-size: 14px" *ngFor="let s of popUpKeys; let i = index" [attr.data-index]="i">
          <span class="bold">{{ s | titlecase }}</span>: <span [innerHTML]="popUpValues[i]"></span>
        </p>
      </div>
    </div>
    <!--fine card-->
    <!--[ngClass]=" {'Editor':editor=='Editor'}"-->
    <!--legenda bottone 1-->
    <!--<div class="position-absolute margin-legend" style="z-index: 4000; left:15px; " id="editor">

          <button class="btn btn-primary btn-block look" type="button" data-toggle="collapse"
              (click)="changeEyeStatus()" data-target="#legend_editor" aria-expanded="true" aria-controls="legend">
              <span class="d-sm-none d-md-inline app">Legenda&nbsp;</span>
              <i class="far " [ngClass]=" {'fa-eye':eyeStatus=='opened','fa-eye-slash':eyeStatus=='closed'}"
                  title="{{eyeStatus=='opened'?'legenda aperta, clicca per nascondere ':'legenda chiusa, clicca per mostrare'}}"></i>
          </button>
      </div>-->
    <!--fine legenda bottone-->
    <div class="position-absolute margin-legend overflow-auto"
      style="max-height: 62px; z-index: 1000; left: 1em !important; max-width: 75%">
      <div class="card px-3" style="padding-top: 6px; padding-bottom: 6px">
        <span class="">{{ descrizione }}</span>
      </div>
    </div>

    <div ngClass class="rounded position-absolute p-3" style="
      margin-top: 125px;
      width: 248px;
      z-index: 11000;
      left: 70px;
      background: rgba(255, 255, 255, 0.4);
    " [ngClass]="{
      legendIcon: legendIcon == 'open',
      legendIconClose: legendIcon == 'close'
    }">
      <div class="rounded p-1" style="background: rgba(255, 255, 255, 0.9)">
        <div class="d-flex flex-column bold">
          <p class="border-left border-dark pl-1 text-secondary mb-2">
            <i class="fas fa-road pr-2"></i>strada
          </p>
          <p class="border-left border-dark pl-1 text-secondary mb-1">
            <i class="fab fa-product-hunt pr-2"></i>parcheggi
          </p>
          <p class="border-left border-dark pl-1 text-secondary mb-1">
            <i class="fas fa-parking pr-2"></i>nodi di scambio
          </p>

          <p class="border-left border-dark pl-1 text-secondary mb-2">
            <i class="fas fa-train pr-2"></i>ferrovia
          </p>

          <p class="border-left border-dark pl-1 text-secondary mb-2">
            <span class="pr-2"><svg height="16" width="16" viewBox="0 0 16 16" class="bi bi-geo-fill mb-1"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z" />
              </svg></span>stazione ferroviaria
          </p>

          <p class="border-left border-dark pl-1 text-secondary mb-1">
            <i class="fas fa-subway pr-2"></i>metro
          </p>

          <p class="border-left border-dark pl-1 text-secondary mb-1">
            <span class="bold pr-2" style="font-size: 18px">M</span>stazione metro
          </p>

          <p class="border-left border-dark pl-1 text-secondary mb-1">
            <i class="fas fa-anchor pr-2"></i>porto
          </p>

          <p class="border-left border-dark pl-1 text-secondary mb-1">
            <i class="far fa-building pr-2"></i>edifico
          </p>

          <p class="border-left border-dark pl-1 text-secondary mb-1">
            <i class="fas fa-tram pr-2"></i>impianto di risalita
          </p>

          <p class="border-left border-dark pl-1 text-secondary mb-2">
            <i class="fas fa-bicycle pr-2"></i>ciclabile
          </p>
          <p class="border-left border-dark pl-1 text-secondary mb-1">
            <i class="fas fa-tree pr-2"></i>parchi e aree verdi
          </p>

          <p class="border-left border-dark pl-1 text-secondary mb-2">
            <i class="fas fa-school pr-2"></i>scuola
          </p>
          <p class="border-left border-dark pl-1 text-secondary mb-1">
            <i class="fas fa-plug pr-2"></i>e-mobility
          </p>
          <p class="border-left border-dark pl-1 text-secondary mb-2">
            <i class="fas fa-bacon pr-2"></i>altre vie di comunicazione
          </p>
        </div>
      </div>
    </div>
  </div>
  <!--fine mappa-->
  <!--legenda corpo editor-->
  <div class="collapse legend_editor show" id="legend_editor">
    <div class="card card-body" *ngIf="editor == 'Visualizzazione'">
      <div class="row ">
        <div class="col-lg-8 col-md-11 col-sm-12 d-flex justify-content-between px-2 flex-wrap">

          <div class="border-left border-dark pl-1">
            <i class="fas fa-circle no-hover" style="color: #cc0000"></i>

            <!--<div class="px-3 mt-3 " style="border-top: 3px solid; border-color: #cc0000; "></div>-->

            <span class="pl-1 pr-1 mb-0">fabbisogno</span>
          </div>

          <div class="border-left border-dark pl-1">
            <i class="fas fa-circle no-hover" style="color: #ff8800"></i>

            <!--  <div class="px-3 mt-3 " style="border-top: 3px solid; border-color: #ff8800; "></div>-->
            <span class="pl-1 pr-1 mb-0">in lavorazione</span>
          </div>

          <div class="border-left border-dark pl-1">
            <i class="fas fa-circle no-hover" style="color: #007e33"></i>

            <!--   <div class="px-3 mt-3 " style="border-top: 3px solid; border-color: #007e33; "></div>-->
            <span class="pl-1 pr-2 mb-0">completato</span>
          </div>

          <div class="border-left border-dark pl-1">
            <i class="fas fa-circle no-hover" style="color: #33cccc"></i>

            <!-- <div class="px-3 mt-3 " style="border-top: 3px solid; border-color: #33cccc; "></div>-->
            <span class="pl-1 pr-1 mb-0">selezione</span>
          </div>

          <div class="border-left border-dark pl-1">
            <i class="fas fa-circle no-hover" style="color: #aa66cc"></i>

            <!-- <div class="px-3 mt-3 " style="border-top: 3px solid; border-color: #aa66cc; "></div>-->
            <span class="pl-1 pr-1 mb-0">progetto/fabbisogno</span>
          </div>
        </div>

        <!--<div class="col-lg-7 d-flex justify-content-between px-1">




                  <div class="border-left border-dark pl-1"><i class="fas fa-road"></i></div>


                  <p class="pl-1 pr-1 mb-0" style="letter-spacing: -1px;">strada</p>

                  <div class="border-left border-dark pl-1"><i class="fas fa-train"></i></div>

                  <p class="pl-1 pr-1 mb-0" style="letter-spacing: -1px;">ferrovia</p>


                  <div class="border-left border-dark pl-1"><svg height="16" width="16" viewBox="0 0 16 16"
                          class="bi bi-geo-fill mb-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd"
                              d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z" />
                      </svg></div>

                  <p class="pl-1 pr-1 mb-0" style="letter-spacing: -1px;">stazione ferroviaria</p>
                  <div class="border-left border-dark pl-1"><i class="fas fa-subway"></i></div>

                  <p class="pl-1 pr-1 mb-0" style="letter-spacing: -1px;">metro</p>
                  <div class="border-left border-dark pl-1"> <span class="bold" style="font-size: 18px;">M</span>
                  </div>

                  <p class="pl-1 pr-1 mb-0" style="letter-spacing: -1px;">stazione metro</p>


                  <div class="border-left border-dark pl-1"><i class="fas fa-anchor"></i></div>

                  <p class="pl-1 pr-1 mb-0" style="letter-spacing: -1px;">porto</p>


                  <div class="border-left border-dark pl-1"><i class="far fa-building"></i></div>

                  <p class="pl-1 pr-1 mb-0" style="letter-spacing: -1px;">edificio</p>


                  <div class="border-left border-dark pl-1"><i class="fas fa-tram"></i></div>

                  <p class="pl-1 pr-1 mb-0" style="letter-spacing: -1px;">impianto di risalita</p>

                  <div class="border-left border-dark pl-1"><i class="fas fa-bicycle"></i></div>

                  <p class="pl-1 pr-1 mb-0" style="letter-spacing: -1px;">ciclabile</p>




              </div>-->
      </div>

      <!-- <div class="card card-body " *ngIf="editor=='Editor'">
              <div class="d-flex ">
                  <div><img src="../../../../../assets/img/Green.png " alt=" " title=" " /></div>
                  <div class="px-3 mt-3 border-success " style="border-top: 3px solid; "></div>

                  <p class="pl-2 pr-3 mt-1 mb-0">elemento non salvato</p>
                  <div><img src="../../../../../assets/img/Blue.png " alt=" " title=" " /></div>
                  <div class="px-3 mt-3 " style="border-top: 3px solid; border-color: #0083E0; "></div>
                  <p class="pl-2 pr-3 mt-1 mb-0">elemento salvato&nbsp;&nbsp;</p>
                  <div><img src="../../../../../assets/img/Select.png " alt=" " title=" " /></div>
                  <div class="px-3 mt-3 " style="border-top: 3px solid; border-color: #ff674d; "></div>
                  <p class="pl-2 pr-3 mt-1 mb-0">elemento selezionato&nbsp;&nbsp;</p>
                  <div><img src="../../../../../assets/img/merge.png " alt=" " title=" " /></div>
                  <div class="px-3 mt-3 " style="border-top: 3px solid; border-color: #942911; "></div>
                  <p class="pl-2 pr-3 mt-1 mb-0">elementi uniti&nbsp;&nbsp;</p>
                  <div><img src="../../../../../assets/img/progetto_base.png " alt=" " title=" " /></div>
                  <div class="px-3 mt-3 " style="border-top: 3px solid; border-color: #aa66cc; "></div>
                  <p class="pl-2 pr-3 mt-1 mb-0">progetto / fabbisogno&nbsp;&nbsp;</p>

              </div>
          </div>-->
    </div>

    <div class="card card-body" *ngIf="editor == 'Editor'">
      <div class="row">
        <div class="col-lg-8 col-md-11 col-sm-12 d-flex justify-content-between px-2 flex-wrap">

          <div class="border-left border-dark pl-1">
            <i class="fas fa-circle no-hover" style="color: #007e33"></i>

            <span class="pl-1 pr-1 mb-0">non salvato</span>
          </div>


          <div class="border-left border-dark pl-1">
            <i class="fas fa-circle no-hover" style="color: #0083e0"></i>

            <span class="pl-1 pr-1 mb-0">salvato</span>
          </div>

          <div class="border-left border-dark pl-1">
            <i class="fas fa-circle no-hover" style="color: #33cccc"></i>


            <span class="pl-1 pr-2 mb-0">selezione</span>
          </div>

          <div class="border-left border-dark pl-1">
            <i class="fas fa-circle no-hover" style="color: #942911"></i>


            <span class="pl-1 pr-1 mb-0">unione</span>
          </div>

          <div class="border-left border-dark pl-1">
            <i class="fas fa-circle no-hover" style="color: #aa66cc"></i>
            <span class="pl-1 pr-1 mb-0">progetto/fabbisogno</span>

            <!-- <div class="px-3 mt-3 " style="border-top: 3px solid; border-color: #aa66cc; "></div>-->

          </div>
        </div>
      </div>
    </div>
    <!--fine legenda corpo editor-->
    <!--legenda corpo visualizzatore
  <div class="collapse legend_visualizzazione" id="legend_visualizzazione">
      <div class="card card-body ">
          <div class="d-flex ">

              <div><img src="../../../../../assets/img/fabbisogno.png " alt=" " title=" " /></div>
              <div class="p-3 mt-3 " style="border-top: 3px solid; border-color: #cc0000; "></div>

              <p class="pl-2 pr-3 mt-1 ">fabbisogno</p>
              <div><img src="../../../../../assets/img/lavorazione.png " alt=" " title=" " /></div>
              <div class="p-3 mt-3 " style="border-top: 3px solid; border-color: #ff8800; "></div>
              <p class="pl-2 pr-3 mt-1 ">lavorazione&nbsp;&nbsp;</p>
              <div><img src="../../../../../assets/img/completato.png " alt=" " title=" " /></div>
              <div class="p-3 mt-3 " style="border-top: 3px solid; border-color: #007e33; "></div>
              <p class="pl-2 pr-3 mt-1 ">completato&nbsp;&nbsp;</p>
          </div>

      </div>
  </div>
 fine legenda corpo visualizzatore-->
  </div>
</div>
