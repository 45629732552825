import { Component, OnInit, Input, ElementRef, Output, EventEmitter } from "@angular/core";
import {
    FormGroup,
    FormBuilder,
    Validators
} from "@angular/forms";
import { RegisterUser } from "../../../models/registerUser";
import { Role } from "../../../models/role";
import { AreeTematicheService,SoggettoGiuridicoService } from 'src/app/modules/inframob/services'
import { AreaTematica } from 'src/app/modules/inframob/models';
import { matchOtherValidator } from 'src/app/helpers/validators/matchOtherValidator';
import { AuthService } from "src/app/services";
import { RolesDescriptions,Roles } from "src/app/helpers/constants";


@Component({
    selector: "app-register-main-step",
    templateUrl: "./register-main-step.component.html",
    styleUrls: ["./register-main-step.component.css"]
})
export class RegisterMainStepComponent implements OnInit {
    myGroup: FormGroup;
    submitted: boolean = false;
    noResultText: string = "Nessuna istituzione trovata...";
    userTypes: Role[] = [
        { key: Roles.role_directional_group, value: RolesDescriptions.role_directional_group },
        { key: Roles.role_area_staff, value: RolesDescriptions.role_area_staff }
        /*,{ key: Roles.role_external, value: RolesDescriptions.role_external }*/
        ,{ key: Roles.role_viewer, value: RolesDescriptions.role_viewer }
    ];
    userTypesForDropDown : Role[]= [];
    @Input() myUser: RegisterUser;

    @Output()
    OnSubmitDone = new EventEmitter();
areaEsterna :AreaTematica;
    areeTematiche: AreaTematica[];
    completedAreeTematiche: AreaTematica[];
    selectedAreaInvalid: boolean = false;
    selectedAreaId: number = 0;
    roleAreaStaffKey = Roles.role_area_staff;
    roleDirectionalGroupKey = Roles.role_directional_group;
    roleExternalKey = Roles.role_external;


    listaEnti = [];
    constructor(
        private formBuilder: FormBuilder,
        private el: ElementRef,
        private areeTematicheService: AreeTematicheService,
        private authService:AuthService,
        private soggettoGiuridicoService:SoggettoGiuridicoService
    ) {         

    }

    isAreaStaff() {
        return this.f.userType.value === this.roleAreaStaffKey;
    }
    isDirectionalGroup() {
        return this.f.userType.value === this.roleDirectionalGroupKey;
    }
    isExternal(){
        return this.f.userType.value === this.roleExternalKey;
    }

    ngOnInit() {
        this.scrollTop();
        this.myGroup = this.formBuilder.group({
            userType: [null, Validators.required],
            email: [this.myUser.email, [Validators.required, Validators.email]],
            emailconf: [
                this.myUser.emailconf,
                [Validators.required, Validators.email, matchOtherValidator("email")]
            ], selectedArea: [null]
            ,firstname:[null,Validators.required]
            ,lastname:[null,Validators.required]
            ,fiscalCode:[null,Validators.required]
            ,tipoPrincipale:[null,Validators.required]
            ,enteAppartenenza:[null]
        });

        this.onChangeUserType({ type: this.myUser.type });
        this.getAreeTematiche();
        this.getListaEnti();
        
        if (this.authService.isIamToken()){
            const username = this.authService.getCurrentUser().user_name;
            this.myGroup.controls.fiscalCode.setValue(username);
            this.myGroup.controls.fiscalCode.disable();
        }
        this.userTypesForDropDown = this.userTypes;
    }

    onChangeUserType($event: any) {
        console.info("onChangeUserType=" + JSON.stringify($event));
    }

    getAreeTematiche() {
        this.areeTematicheService.getAll().subscribe(
            x => {
                x.sort((a, b) => {
                    if (a.descrizione > b.descrizione) {
                        return 1;
                    }
                    if (b.descrizione > a.descrizione) {
                        return -1;
                    }
                    return 0;
                });
                this.areeTematiche = x.filter((k) => {
                    return !k.disabled;
                  });
                this.completedAreeTematiche = x;
                this.areaEsterna = x.find(y=>y.struttura=='ESTERNA');
            }
        );
    }



    get f() {
        return this.myGroup.controls;
    }
    setArea() {
        let selectedAreaId = this.f.selectedArea.value;
        if(!selectedAreaId )return;
        const selected: AreaTematica = this.areeTematiche.find(
            s => s.id === selectedAreaId
        );
        if (!this.myUser.areeTematiche.find(x => x.id == selected.id)) {
            this.myUser.areeTematiche.push(selected);
            this.areeTematiche = this.areeTematiche.filter(item => item.id !== selected.id);
            this.f.selectedArea.patchValue("");
        }
        selectedAreaId = 0;
    }

    deleteArea(area: AreaTematica) {
        this.selectedAreaInvalid = false;
        this.myUser.areeTematiche = this.myUser.areeTematiche.filter(
            item => item.id !== area.id
        );
        //this.areeTematiche.push(area);  
        let areeTematicheTmp = Object.assign([], this.areeTematiche);
        areeTematicheTmp.push(area);
        areeTematicheTmp.sort((a, b) => {
            if (a.descrizione > b.descrizione) {
                return 1;
            }
            if (b.descrizione > a.descrizione) {
                return -1;
            }
            return 0;
        });
        this.areeTematiche = areeTematicheTmp;
    }


    onSubmit() {
        console.log("step2_onSubmit");
        this.submitted = true;
        var errors = false;

        this.selectedAreaInvalid = (this.isExternal() || this.isAreaStaff() || this.isDirectionalGroup()) &&(!this.myUser.areeTematiche ||this.myUser.areeTematiche.length<=0);

        for (var c in this.f) {
            errors = errors || this.f[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );
        }
        console.log("errors:" + errors);
        //Istitution case.

        if (this.myGroup.invalid || errors || this.selectedAreaInvalid) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (var el in this.myGroup.controls) {
                if (this.myGroup.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.myGroup.controls[el] instanceof FormGroup) {
                        for (var el1 in this.myGroup.get(el)["controls"]) {
                            if (this.myGroup.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }
        
        this.myUser.type = this.f.userType.value;
        this.myUser.email = this.f.email.value;
        this.myUser.emailconf = this.f.emailconf.value;
        this.myUser.lastName = this.f.lastname.value;
        this.myUser.firstName = this.f.firstname.value;
        this.myGroup.controls.fiscalCode.enable();
        this.myUser.fiscalCode = this.f.fiscalCode.value;
        this.myUser.soggettoGiuridicoId = this.f.enteAppartenenza.value;
        console.log("this.myUser:" + JSON.stringify(this.myUser));
        this.OnSubmitDone.emit(this.myUser);
        return true;
    }


    scrollTop() {
        window.scroll(0, 0);
    }

    getListaEnti(){
        this.soggettoGiuridicoService.getAll().subscribe(x=>this.listaEnti= x);
    }

    tipoPrincipaleClick($event,value){
        if (value=='1'){
            this.userTypesForDropDown = [...this.userTypes];   
            this.deleteArea(this.areaEsterna);   
            this.f.enteAppartenenza.clearValidators();
            this.f.enteAppartenenza.updateValueAndValidity();
        }
        if (value=='0'){
            this.userTypesForDropDown = [{ key: Roles.role_external, value: RolesDescriptions.role_external }];
            this.f.userType.setValue(Roles.role_external);
            this.f.selectedArea.setValue (this.areaEsterna.id);
            //this.f.userType.disable();
            this.setArea();
            this.f.enteAppartenenza.setValidators([Validators.required]);
            this.f.enteAppartenenza.updateValueAndValidity();
        }
        //console.log($event);
        //console.log(this.f.tipoPrincipale.value);
    }
}
