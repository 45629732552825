import { Injectable } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { Router, RouterStateSnapshot,CanActivate,ActivatedRouteSnapshot } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route:ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;
    const notExpectedRole = route.data.notExpectedRole;
    let foundExpectedRole = false;
    let foundNotExpectedRole = false;
    if (this.authService.isLoggedIn()){
        let auths = this.authService.currentUserValue.authorities;
        if (expectedRole && auths)
            for(var role in expectedRole){
                foundExpectedRole =  auths.includes(role);
                if (foundExpectedRole)return false;//break
            } 
        if(notExpectedRole && auths)       
            for(var role in notExpectedRole){
                foundNotExpectedRole =  auths.includes(role);
                if (foundNotExpectedRole)return false;//break
            }   
    }
    if (foundExpectedRole) return true;
    if(!foundNotExpectedRole)return true;

    this.router.navigate([""]);
    return false;
  }
}
