/* Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { CurrencyPipe } from "@angular/common";
import { Component, OnInit,OnDestroy } from "@angular/core";
import { Intervento } from "../../models";
import {
  ProgettoService,
  TipoClassificazioneService,
  DashboardService,
  StorageService
} from "../../services";
import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";
import * as moment from "moment";
import * as FileSaver from "file-saver";
import { ToastService } from "src/app/services/toast.service";
import { Subject } from "rxjs";

class ValidationStatus {
  code: number;
  label: string;
}
class Annualita {
  anno: string;
  importoI: number;
  importoF: number;
  importoFormattatoI: any;
  importoFormattatoF: any;
}

class Classificazione {
  codice: string;
  count: number = 0;
  importoI: number = 0;
  importoF: number = 0;
  differenza: number = 0;
  descrizione: string;
}
@Component({
  selector: "app-dashboard-stato-interventi",
  templateUrl: "./dashboard-stato-interventi.component.html",
  styleUrls: ["./dashboard-stato-interventi.component.css"],
})
export class DashboardStatoInterventiComponent implements OnInit ,OnDestroy{
  totInt = 0;
  totFin = 0;

  filteredInterventions: Intervento[] = [];
  onlyNormalIntervention = [];
  annualita: Annualita[];

  statuses: any[] = [
    { code: 0, label: "Da validare",gruppo:"Tutti" },
    { code: 1, label: "Validato" ,gruppo:"Tutti"},
    { code: 2, label: "In attesa di validazione" ,gruppo:"Tutti"},
    { code: 3, label: "Rifiutato" ,gruppo:"Tutti"},
    { code: 7, label: "Fabbisogno" ,gruppo:"Tutti"},
    { code: 8, label: "Parzialmente finanziato" ,gruppo:"Tutti"},
    { code: 9, label: "Completato" ,gruppo:"Tutti"},
  ];
  anniCombo = [];
  areeTematicheCombo = [];
  classificazioniCombo = [];

  //filtro stato finanziamento
  filterStatus = [-1];
  filterAnno = "all";
  filterArea = -1;
  filterClassificazione = -1;

  chartTorta;
  chartArea;
  chartBarre;
  chartClassificazione;
  chartAnno;

  isHiddenChart67 = true;

  classifications = [];
  classificationsCodes = [];
  classificazioniGrafico: Classificazione[] = [];
  elencoClassificazioni = [];

  completerParams = {};

  filterSimpleInterventionId = -1;
  interventionDescription = "";

  showPage = false;
  constructor(
    private projectService: ProgettoService,
    private classificazioniService: TipoClassificazioneService,
    private toastr: ToastService,
    private dashboardService: DashboardService
    ,private storageService:StorageService
  ) {}
    ngOnDestroy(): void {
        if(this.elencoAreeSubscription)this.elencoAreeSubscription.unsubscribe();
    }

  ngOnInit() {
    am4core.useTheme(am4themes_animated);
    this.temaColori();
    this.getAnni();
    this.getAreeTematiche();
    this.getClassificazioni();
    this.filterInterventions(true);
  }

  setInterventionStatus($event) {
    this.filterStatus = $event ?
    $event.map(x=>x.code)
    /*$event.code*/ : [];
    this.filterInterventions(false);
  }

  setAreaTematica($event) {
    this.filterArea = $event ? $event.id : -1;
    this.filterInterventions(false);
  }

  //se fiilIntervento è true riempie la combo degli interventi escludendo i main
  filterInterventions(fillIntervento: boolean) {
    const arrayOfProjecId = [-1];
    const arrayOfMainId = [-1];
    this.showPage = false;
    this.projectService
      .getInterventiSimpleData({
        filterProjectId: arrayOfProjecId[0],
        filterSimpleInterventionId: this.filterSimpleInterventionId,
        filterStatus: this.filterStatus,
        filterAreeGestionaliDirezioneEsterne: this.filterArea,
        filterTipoClassificazione: this.filterClassificazione,
        filterSoggettoGiuridico: -1, 
        filterAnno: this.filterAnno, 
        filterProvinciaSoggettoGiuridico: "all",
        filterTemaProprietario: -1,
        filterFrom: -1, 
        filterTo: -1,
        filterTipologiaIntervento:-1
      })
      .subscribe((res) => {
        this.filteredInterventions = res.map((i) => {
          i["label"] = `${i.codice} - ${i.descrizione}`;
          //console.log(i);
          return i;
        });

        //gli interventi che non sono main
        this.onlyNormalIntervention = this.filteredInterventions.filter(
          (i) => !i.isMain || i.numSubInterventi <= 0
        );
        this.completerParams = {
          items: this.onlyNormalIntervention,
          labelField: "label",
          ajax: null,
          placeholder: "Cerca intervento ",
        };
        this.annualita = this.countImportiPerAnnualita();
        this.annualita && this.countTotImporti(this.annualita);

        this.disposeCharts();
        this.renderCharts();
        this.showPage = true;
        //this.loadDatiTabellaDettaglio();
        //this.executeGetTabellaDettaglioObjAsync().then(()=>{});
        this.loadDatiTabellaDettaglio();
        //this.showPage = true;
      });
  }

  renderCharts() {
    this.drawChartTorta();
    this.drawChartArea();
    this.drawChartValidazione();
    if (this.filterClassificazione >= 0) {
      this.setClassificazioniPerGrafici();
      this.drawChartClassificazione();
    }
    this.drawChartAnno();
  }

  disposeCharts() {}
  //filtri
  getAnni() {
    const that = this;
    const years = [];
    this.projectService.getAnniInterventi().subscribe((anni) => {
      anni.map((a) => {
        const anno = { key: a, value: a };
        years.push(anno);
        that.anniCombo = [...years];
      });
    });
  }

  private elencoAreeSubscription = null;
    getAreeTematiche() {

        this.elencoAreeSubscription = this.storageService.elencoAreeTematiche.subscribe(
            x=>{
                console.log(x);
                this.areeTematicheCombo = x?x:[];
            }
            );
    }

  getClassificazioni() {
    this.classificazioniService.getAll().subscribe((x) => {
      this.classificazioniCombo = x;
    });
  }

  setAnno($event) {
    this.filterAnno = $event && $event.key !== "all" ? $event.key : "all";
    this.filterInterventions(false);
  }

  setClassificazione($event) {
    this.filterClassificazione = $event ? $event.id : -1;
    this.filterInterventions(false);
    $event ? (this.isHiddenChart67 = false) : (this.isHiddenChart67 = true);
  }

  /////////////////
  countImportiPerAnnualita() {
    const annualita: Annualita[] = [];
    this.filteredInterventions.forEach((i) => {
      if (
        (!i.isMain || i.numSubInterventi <= 0) &&
        i.importi &&
        i.importi.length > 0
      ) {
        i.importi.forEach((imp) => {
          let currentAnnualita = annualita.find(
            (x) => x.anno == imp.anno.toString()
          );

          if (!currentAnnualita) {
            currentAnnualita = new Annualita();
            currentAnnualita.anno = imp.anno.toString();
            currentAnnualita.importoF = 0.0;
            currentAnnualita.importoI = 0.0;
            annualita.push(currentAnnualita);
          }
          currentAnnualita.importoI += imp.importo;
        });
      }

      if (
        (!i.isMain || i.numSubInterventi <= 0) &&
        i.finanziamenti &&
        i.finanziamenti[0]
      ) {
        i.finanziamenti[0].annualita.forEach((imp) => {
          let currentAnnualita = annualita.find((x) => x.anno == imp.anno);
          if (!currentAnnualita) {
            currentAnnualita = new Annualita();
            currentAnnualita.anno = imp.anno;
            currentAnnualita.importoF = 0.0;
            currentAnnualita.importoI = 0.0;
            annualita.push(currentAnnualita);
          }
          currentAnnualita.importoF += imp.importo;
        });
      }
    });
    annualita.sort((a, b) => {
      return a["anno"] > b["anno"] ? 1 : a.anno < b.anno ? -1 : 0;
    });
    return annualita;
  }

  countTotImporti(annualita: Annualita[]) {
    this.totInt = 0;
    this.totFin = 0;

    annualita &&
      annualita.length > 0 &&
      annualita.forEach((a) => {
        this.totInt += a.importoI;
        this.totFin += a.importoF;
      });
  }

  printValueAsCurrency(value) {
    value = typeof value === "number" ? value : value.replace(",", ".").trim();
    const format = "1.2-2";
    const currency = "€";
    const currentLocale: string = "it";
    return new CurrencyPipe(currentLocale).transform(
      value,
      "EUR",
      currency,
      format,
      "it-IT"
    );
  }
  temaColori() {
    function am4themes_myTheme(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#ff8800"),
          am4core.color("#28a745"),
          am4core.color("#67b7dc"),
        ];
      }
    }
    am4core.useTheme(am4themes_myTheme);
  }
  // GRAFICI

  countImportoTotale(data: any[]) {
    let count = 0.0;
    this.onlyNormalIntervention.forEach((i) => {
      i.importi.forEach((imp) => {
        count += imp.importo;
      });
    });
    return count;
  }

  countImportoUtilizzati(data: any[]) {
    let count = 0.0;
    this.onlyNormalIntervention.forEach((i) => {
      i.finanziamenti.forEach((fin) => {
        fin.annualita.forEach((ann) => {
          count += ann.importo ? ann.importo : 0.0;
        });
      });
    });
    return count;
  }

  drawChartTorta() {
    // Create chart instance
    this.chartTorta = am4core.create("chart1", am4charts.PieChart);
    this.chartTorta.language.locale = am4lang_it_IT;
    // Add data
    let countTotale = this.countImportoTotale(this.onlyNormalIntervention);
    let countUtilizzati = this.countImportoUtilizzati(
      this.onlyNormalIntervention
    );
    const roundedUtil = (Math.round(countUtilizzati * 100) / 100).toFixed(2);
    const roundedDisp = (
      Math.round((countTotale - countUtilizzati) * 100) / 100
    ).toFixed(2);
    this.chartTorta.data = [
      { name: "destinato", size: roundedUtil },
      {
        name: "disponibile",
        size: roundedDisp,
      },
    ];

    // legend
    this.chartTorta.legend = new am4charts.Legend();
    this.chartTorta.legend.position = "right";
    let markerTemplate = this.chartTorta.legend.markers.template;
    markerTemplate.width = 14;
    markerTemplate.height = 14;

    this.chartTorta.radius = am4core.percent(80);

    // Set pie chart to be at 50% of the available space
    this.chartTorta.innerRadius = am4core.percent(60);

    this.chartTorta.legend.itemContainers.template.togglable = false;
    this.chartTorta.legend.itemContainers.template.events.on(
      "hit",
      function (ev) {
        var slice = ev.target.dataItem.dataContext.slice;
        slice.isActive = !slice.isActive;
      }
    );

    // Add label
    //this.chartTorta.innerRadius = 100;
    let label = this.chartTorta.seriesContainer.createChild(am4core.Label);
    label.text = this.printValueAsCurrency(
      (Math.round(countTotale * 100) / 100).toFixed(2)
    );
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 20;
    let title = this.chartTorta.titles.create();

    this.aggiungiLogo(this.chartTorta);

    title.marginTop = 15;
    title.marginBottom = 10;
    title.text = "Stato finanziario";
    this.exportChart(this.chartTorta, title.text);
    // Add and configure Series
    let pieSeries = this.chartTorta.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "size";
    pieSeries.dataFields.category = "name";
    pieSeries.alignLabels = false;

    this.chartTorta.numberFormatter.numberFormat = "#.## a '€'";
    this.chartTorta.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "Mln" },
      { number: 1e9, suffix: "Mrd" },
    ];
  }

  drawChartArea() {
    // Create chart instance
    let aree = [];
    this.onlyNormalIntervention.forEach((i) => {
      //console.log(i);
      let tot = 0.0;
      let util = 0.0;

      i.importi.forEach((imp) => {
        tot += imp.importo;
      });
      i.finanziamenti.forEach((fin) => {
        fin.annualita.forEach((ann) => {
          util += ann.importo ? ann.importo : 0.0;
        });
      });
      const disp = tot - util;

      i.areeTematiche.forEach((a) => {
        let area = {};
        //importi
        if (a.id && !aree["" + a.id]) {
          //nuova
          area["nome"] = a.descrizione;
          area["totale"] = 0.0;
          area["utilizzato"] = 0.0;
          area["disponibile"] = 0.0;
          area["none"] = 0.0;
          aree["" + a.id] = area;
        }
        const currentArea = aree["" + a.id];
        currentArea["totale"] += tot;
        currentArea["utilizzato"] += util;
        currentArea["disponibile"] += disp;
        currentArea["disponibile"] += disp;
      });
    });

    const data = [];
    aree.forEach((a) => {
      data.push(a);
    });

    this.chartArea = am4core.create("chart4", am4charts.XYChart);
    this.chartArea.language.locale = am4lang_it_IT;
    this.chartArea.scrollbarX = new am4core.Scrollbar();
    // Add chart title
    var title = this.chartArea.titles.create();

    this.aggiungiLogo(this.chartArea);

    title.marginBottom = 10;
    title.text = "Aree gestionali";

    // Add data
    this.chartArea.data = data;
    // Create axes
    let categoryAxis = this.chartArea.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "nome";
    categoryAxis.renderer.grid.template.location = 0;

    let valueAxis = this.chartArea.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = false;
    valueAxis.renderer.labels.template.disabled = false;
    valueAxis.min = 0;
    valueAxis.calculateTotals = true;

    valueAxis.extraMax = 0.1; // aggiungo un 10% sopra l'ultima colonna

    let that = this;
    createSeries("utilizzato", "destinato");
    createSeries("disponibile", "disponibile");

    var totalSeries = this.chartArea.series.push(new am4charts.ColumnSeries());
    totalSeries.dataFields.valueY = "none";
    totalSeries.dataFields.categoryX = "nome";
    totalSeries.stacked = true;
    totalSeries.hiddenInLegend = true;
    totalSeries.columns.template.strokeOpacity = 0;
    totalSeries.columns.template.width = am4core.percent(100);

    var totalBullet = totalSeries.bullets.push(new am4charts.LabelBullet());
    totalBullet.dy = -20;
    totalBullet.label.text = "[bold]Totale: {valueY.total}[/}";
    totalBullet.label.hideOversized = false;
    totalBullet.label.fontSize = 14;
    totalBullet.label.background.fill = totalSeries.stroke;
    totalBullet.label.background.fillOpacity = 0;
    totalBullet.label.padding(5, 0, 5, 0);
    totalBullet.label.tooltipText = "[bold]Totale: {valueY.total}[/}";

    // Create series
    function createSeries(field, name) {
      // Set up series
      let series = that.chartArea.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "nome";
      series.sequencedInterpolation = true;
      series.columns.template.column.fillOpacity = 0.8;

      // Make it stacked
      series.stacked = true;

      // Configure columns
      series.columns.template.width = am4core.percent(70);
      series.columns.template.tooltipText =
        "[bold]{name}: {valueY}[/]\n[font-size:14px]{categoryX}";

      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";

      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;
      labelBullet.label.truncate = true;

      return series;
    }
    // Enable export
    this.exportChart(this.chartArea, title.text);
    // Legend
    this.chartArea.legend = new am4charts.Legend();

    let markerTemplate = this.chartArea.legend.markers.template;
    markerTemplate.width = 14;
    markerTemplate.height = 14;
    this.chartArea.events.on("ready", () => {
      let asse = that.chartArea.xAxes.values[0];
      let catCount = that.chartArea.data.length;
      if (catCount > 0 && catCount > 10) asse.zoomToIndexes(0, 10);
    });

    let label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 120;
    //label.minWidth = 100;
    label.fontSize = 12;

    this.chartArea.numberFormatter.numberFormat = "#.## a '€'";
    this.chartArea.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "Mln" },
      { number: 1e9, suffix: "Mrd" },
    ];
  }

  drawChartValidazione() {
    // Create chart instance
    let stati = [];
    //TODO scommentami

    let daValidare = {
      nome: "Da validare",
      totale: 0.0,
      utilizzato: 0.0,
      disponibile: 0.0,
      none: 0,
    }; //diponibile è la diffrenza
    let validato = {
      nome: "Validato",
      totale: 0.0,
      utilizzato: 0.0,
      disponibile: 0.0,
      none: 0,
    };
    let completato = {
      nome: "Completato",
      totale: 0.0,
      utilizzato: 0.0,
      disponibile: 0.0,
      none: 0,
    };
    let fabbisogno = {
      nome: "Fabbisogno",
      totale: 0.0,
      utilizzato: 0.0,
      disponibile: 0.0,
      none: 0,
    };
    let ibrido = {
      nome: "Parzialmente Validato",
      totale: 0.0,
      utilizzato: 0.0,
      disponibile: 0.0,
      none: 0,
    };
    let rifiutato = {
      nome: "Rifiutato",
      totale: 0.0,
      utilizzato: 0.0,
      disponibile: 0.0,
      none: 0,
    };

    this.onlyNormalIntervention.forEach((i) => {
      switch (i.validationStatus) {
        case "REJECTED":
          i.importi.forEach((imp) => {
            rifiutato.totale += imp.importo;
          });
          i.finanziamenti.forEach((fin) => {
            fin.annualita.forEach((ann) => {
              rifiutato.utilizzato += ann.importo ? ann.importo : 0.0;
            });
          });
          rifiutato.disponibile = rifiutato.totale - rifiutato.utilizzato;
          break;
        case "IDLE":
        case "WAITING":
        case "WAITING_FOR_APPROVAL_FIRST_LEVEL":
        case "WAITING_FOR_APPROVAL_SECOND_LEVEL":
        case "WAITING_FOR_REMOVE_APPROVAL":
          i.importi.forEach((imp) => {
            daValidare.totale += imp.importo;
          });
          i.finanziamenti.forEach((fin) => {
            fin.annualita.forEach((ann) => {
              daValidare.utilizzato += ann.importo ? ann.importo : 0.0;
            });
          });
          daValidare.disponibile = daValidare.totale - daValidare.utilizzato;
          break;
        case "APPROVED":
          i.importi.forEach((imp) => {
            validato.totale += imp.importo;
          });
          i.finanziamenti.forEach((fin) => {
            fin.annualita.forEach((ann) => {
              validato.utilizzato += ann.importo ? ann.importo : 0.0;
            });
          });
          validato.disponibile = validato.totale - validato.utilizzato;
          break;
        case "TERMINATO":
          i.importi.forEach((imp) => {
            completato.totale += imp.importo;
          });
          i.finanziamenti.forEach((fin) => {
            fin.annualita.forEach((ann) => {
              completato.utilizzato += ann.importo ? ann.importo : 0.0;
            });
          });
          completato.disponibile = completato.totale - completato.utilizzato;
          break;
        case "FABBISOGNO":
          i.importi.forEach((imp) => {
            fabbisogno.totale += imp.importo;
          });
          i.finanziamenti.forEach((fin) => {
            fin.annualita.forEach((ann) => {
              fabbisogno.utilizzato += ann.importo ? ann.importo : 0.0;
            });
          });
          fabbisogno.disponibile = fabbisogno.totale - fabbisogno.utilizzato;
          break;
        case "IBRIDO":
          i.importi.forEach((imp) => {
            ibrido.totale += imp.importo;
          });
          i.finanziamenti.forEach((fin) => {
            fin.annualita.forEach((ann) => {
              ibrido.utilizzato += ann.importo ? ann.importo : 0.0;
            });
          });
          ibrido.disponibile = ibrido.totale - ibrido.utilizzato;
          break;
      }
    });

    const data = [
      daValidare,
      validato,
      completato,
      fabbisogno,
      ibrido,
      rifiutato,
    ];

    this.chartBarre = am4core.create("chart3", am4charts.XYChart);
    this.chartBarre.language.locale = am4lang_it_IT;
    this.chartBarre.scrollbarX = new am4core.Scrollbar();

    // Add chart title
    var title = this.chartBarre.titles.create();

    this.aggiungiLogo(this.chartBarre);

    title.marginBottom = 10;
    title.text = "Stato validazione";

    // Add data
    this.chartBarre.data = data;
    // Create axes
    let categoryAxis = this.chartBarre.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "nome";
    categoryAxis.renderer.grid.template.location = 0;

    let valueAxis = this.chartBarre.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = false;
    valueAxis.renderer.labels.template.disabled = false;
    valueAxis.min = 0;
    valueAxis.calculateTotals = true;

    valueAxis.extraMax = 0.1; // aggiungo un 10% sopra l'ultima colonna

    let that = this;

    //createSeriesNotStacked("totale", "importo");
    createSeries("utilizzato", "destinato");
    createSeries("disponibile", "disponibile");

    var totalSeries = this.chartBarre.series.push(new am4charts.ColumnSeries());
    totalSeries.dataFields.valueY = "none";
    totalSeries.dataFields.categoryX = "nome";
    totalSeries.stacked = true;
    totalSeries.hiddenInLegend = true;
    totalSeries.columns.template.strokeOpacity = 0;
    totalSeries.columns.template.width = am4core.percent(100);

    var totalBullet = totalSeries.bullets.push(new am4charts.LabelBullet());
    totalBullet.dy = -20;
    totalBullet.label.text = "[bold]Totale: {valueY.total}[/}";
    totalBullet.label.hideOversized = false;
    totalBullet.label.fontSize = 14;
    totalBullet.label.background.fill = totalSeries.stroke;
    totalBullet.label.background.fillOpacity = 0;
    //totalBullet.label.padding(5, 10, 5, 10);
    totalBullet.label.tooltipText = "[bold]Totale: {valueY.total}[/}";

    // Create series

    function createSeries(field, name) {
      // Set up series
      let series = that.chartBarre.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "nome";
      series.sequencedInterpolation = true;
      series.columns.template.column.fillOpacity = 0.8;

      // Make it stacked
      series.stacked = true;

      series.columns.template.width = am4core.percent(90);
      series.columns.template.tooltipText =
        "[bold]{name}: {valueY}[/]\n[font-size:14px]{categoryX}";

      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;

      return series;
    }

    // Enable export
    this.exportChart(this.chartBarre, title.text);
    // Legend
    this.chartBarre.legend = new am4charts.Legend();

    let markerTemplate = this.chartBarre.legend.markers.template;
    markerTemplate.width = 14;
    markerTemplate.height = 14;
    this.chartBarre.events.on("ready", () => {
      let asse = that.chartBarre.xAxes.values[0];
      let catCount = that.chartBarre.data.length;
      if (catCount > 0 && catCount > 10) asse.zoomToIndexes(0, 10);
    });

    this.chartBarre.numberFormatter.numberFormat = "#.## a '€'";
    this.chartBarre.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "Mln" },
      { number: 1e9, suffix: "Mrd" },
    ];
  }

  drawChartClassificazione() {
    this.chartClassificazione = am4core.create("chart6", am4charts.XYChart);
    this.chartClassificazione.language.locale = am4lang_it_IT;
    //this.chartClassificazione.language.locale = am4lang_it_IT;
    this.chartClassificazione.scrollbarX = new am4core.Scrollbar();
    //this.chartClassificazione.scrollbarY = new am4core.Scrollbar();

    // Add chart title
    var title = this.chartClassificazione.titles.create();

    this.aggiungiLogo(this.chartClassificazione);

    title.marginBottom = 10;
    title.text = "Classificazioni";

    // Add data
    let data = this.setClassificazioniPerGrafici();
    data.forEach((element) => {
      element["none"] = 0;
    });
    this.chartClassificazione.data = data;
    // Create axes
    let categoryAxis = this.chartClassificazione.xAxes.push(
      new am4charts.CategoryAxis()
    );
    categoryAxis.dataFields.category = "codice";
    categoryAxis.renderer.grid.template.location = 0;

    let valueAxis = this.chartClassificazione.yAxes.push(
      new am4charts.ValueAxis()
    );
    valueAxis.renderer.inside = false;
    valueAxis.renderer.labels.template.disabled = false;
    valueAxis.min = 0;
    valueAxis.calculateTotals = true;

    valueAxis.extraMax = 0.1; // aggiungo un 10% sopra l'ultima colonna

    let that = this;

    createSeries("importoF", "destinato");
    createSeries("diff", "disponibile");

    var totalSeries = this.chartClassificazione.series.push(
      new am4charts.ColumnSeries()
    );
    totalSeries.dataFields.valueY = "none";
    totalSeries.dataFields.categoryX = "codice";
    totalSeries.stacked = true;
    totalSeries.hiddenInLegend = true;
    totalSeries.columns.template.strokeOpacity = 0;
    totalSeries.columns.template.width = am4core.percent(100);

    var totalBullet = totalSeries.bullets.push(new am4charts.LabelBullet());
    totalBullet.dy = -20;
    totalBullet.label.text = "[bold]Totale: {valueY.total}[/}";
    totalBullet.label.hideOversized = false;
    totalBullet.label.fontSize = 14;
    totalBullet.label.background.fill = totalSeries.stroke;
    totalBullet.label.background.fillOpacity = 0;
    totalBullet.label.padding(5, 0, 5, 0);
    totalBullet.label.tooltipText = "[bold]Totale: {valueY.total}[/}";

    // Create series

    function createSeries(field, name) {
      // Set up series
      let series = that.chartClassificazione.series.push(
        new am4charts.ColumnSeries()
      );
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "codice";
      series.sequencedInterpolation = true;
      series.columns.template.column.fillOpacity = 0.8;

      // Make it stacked
      series.stacked = true;

      series.columns.template.width = am4core.percent(70);
      series.columns.template.tooltipText =
        "[bold]{name}: {valueY}[/]\n[font-size:14px]{categoryX}";

      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;

      return series;
    }
    // Enable export
    this.exportChart(this.chartClassificazione, title.text);
    // Legend
    this.chartClassificazione.legend = new am4charts.Legend();

    let markerTemplate = this.chartClassificazione.legend.markers.template;
    markerTemplate.width = 14;
    markerTemplate.height = 14;
    this.chartClassificazione.events.on("ready", () => {
      let asse = that.chartClassificazione.xAxes.values[0];
      let catCount = that.chartClassificazione.data.length;
      if (catCount > 0 && catCount > 10) asse.zoomToIndexes(0, 10);
    });

    this.chartClassificazione.numberFormatter.numberFormat = "#.## a '€'";
    this.chartClassificazione.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "Mln" },
      { number: 1e9, suffix: "Mrd" },
    ];
  }
  drawChartAnno() {
    let anni = [];
    this.onlyNormalIntervention.forEach((i) => {
      //console.log(this.anniCombo);
      this.anniCombo.forEach((anno) => {
        let elemento = {};
        const label = "anno_" + anno.key;
        if (!anni[label]) {
          //console.log();
          elemento["nome"] = anno.key;
          elemento["totale"] = 0.0;
          elemento["utilizzato"] = 0.0;
          elemento["disponibile"] = 0.0;
          elemento["none"] = 0.0;
          anni[label] = elemento;
        }
        let tot = 0.0;
        let util = 0.0;
        const annualitaImporti = i.importi.find(
          (imp) => imp.anno.toString() == anno.key
        );
        if (annualitaImporti) {
          tot += annualitaImporti.importo;
        }

        if (i.finanziamenti && i.finanziamenti.length > 0) {
          i.finanziamenti.forEach((f) => {
            const annualitaFin = f.annualita.find(
              (imp) => imp.anno.toString() == anno.key
            );
            if (annualitaFin) {
              util += annualitaFin.importo;
            }
          });
          // );
        }
        anni[label].totale += tot;
        anni[label].utilizzato += util;
        anni[label].disponibile = anni[label].totale - anni[label].utilizzato;
      });
    });

    const data = [];
    Object.keys(anni).forEach(function (x) {
      //anni[x]["none"] = 0;
      data.push(anni[x]);
    });
    data.sort((a, b) => {
      return parseInt(a.nome) - parseInt(b.nome);
    });
    this.chartAnno = am4core.create("chart7", am4charts.XYChart);
    this.chartAnno.language.locale = am4lang_it_IT;
    this.chartAnno.scrollbarX = new am4core.Scrollbar();

    // Add chart title
    var title = this.chartAnno.titles.create();

    this.aggiungiLogo(this.chartAnno);

    title.marginBottom = 10;
    title.text = "Annualità";

    // Add data
    this.chartAnno.data = data;
    // Create axes
    let categoryAxis = this.chartAnno.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "nome";
    categoryAxis.renderer.grid.template.location = 0;

    let valueAxis = this.chartAnno.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = false;
    valueAxis.renderer.labels.template.disabled = false;
    valueAxis.min = 0;
    valueAxis.calculateTotals = true;

    valueAxis.extraMax = 0.1; // aggiungo un 10% sopra l'ultima colonna

    let that = this;

    createSeries("utilizzato", "destinato");
    createSeries("disponibile", "disponibile");

    var totalSeries = this.chartAnno.series.push(new am4charts.ColumnSeries());
    totalSeries.dataFields.valueY = "none";
    totalSeries.dataFields.categoryX = "nome";
    totalSeries.stacked = true;
    totalSeries.hiddenInLegend = true;
    totalSeries.columns.template.strokeOpacity = 0;
    totalSeries.columns.template.width = am4core.percent(100);

    var totalBullet = totalSeries.bullets.push(new am4charts.LabelBullet());
    totalBullet.dy = -20;
    totalBullet.label.text = "[bold]Totale: {valueY.total}[/}";
    totalBullet.label.hideOversized = false;
    totalBullet.label.fontSize = 14;
    totalBullet.label.background.fill = totalSeries.stroke;
    totalBullet.label.background.fillOpacity = 0;
    totalBullet.label.padding(5, 0, 5, 0);
    totalBullet.label.tooltipText = "[bold]Totale: {valueY.total}[/}";

    // Create series

    function createSeries(field, name) {
      // Set up series
      let series = that.chartAnno.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "nome";
      series.sequencedInterpolation = true;
      series.columns.template.column.fillOpacity = 0.8;

      // Make it stacked
      series.stacked = true;

      series.columns.template.width = am4core.percent(70);
      series.columns.template.tooltipText =
        "[bold]{name}: {valueY}[/]\n[font-size:14px]{categoryX}";

      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;

      return series;
    }
    // Enable export
    this.exportChart(this.chartAnno, title.text);
    // Legend
    this.chartAnno.legend = new am4charts.Legend();

    let markerTemplate = this.chartAnno.legend.markers.template;
    markerTemplate.width = 14;
    markerTemplate.height = 14;
    this.chartAnno.events.on("ready", () => {
      let asse = that.chartAnno.xAxes.values[0];
      let catCount = that.chartAnno.data.length;
      if (catCount > 0 && catCount > 10) asse.zoomToIndexes(0, 10);
    });

    this.chartAnno.numberFormatter.numberFormat = "#.## a '€'";
    this.chartAnno.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "Mln" },
      { number: 1e9, suffix: "Mrd" },
    ];
  }

  exportChart(chart, title) {
    chart.exporting.menu = new am4core.ExportMenu();

    chart.exporting.menu.align = "left";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.background = "#ff0000";
    chart.exporting.menu.defaultStyles = true;
    chart.exporting.filePrefix = title;

    chart.exporting.menu.items = [
      {
        menu: [
          { type: "jpg", label: "JPG" },
          { type: "png", label: "PNG" },
          { type: "csv", label: "CSV" },
          // { type: "pdf", label: "PDF" },
        ],
      },
    ];
    chart.exporting.menu.items[0].icon = "assets/img/export.png";
    // chart.exporting.dataFields = { disponibile: "nome" };
  }

  aggiungiLogo(logoChart) {
    // Add watermark
    let watermark = new am4core.Image();
    watermark.href = "assets/img/logo_blu.svg";
    logoChart.tooltipContainer.children.push(watermark);
    watermark.align = "right";
    watermark.valign = "bottom";
    watermark.opacity = 0.3;
    watermark.marginRight = 10;
    watermark.marginBottom = 5;
    watermark.disabled = true;

    // Enable watermark on export
    logoChart.exporting.events.on("exportstarted", function (ev) {
      watermark.disabled = false;
    });

    // Disable watermark when export finishes
    logoChart.exporting.events.on("exportfinished", function (ev) {
      watermark.disabled = true;
    });

    // Add watermark to validated sprites
    logoChart.exporting.validateSprites.push(watermark);
  }

  //prova classificazioni
  setCodiciClassificazioni() {
    let classificazioni = [];

    this.onlyNormalIntervention.forEach((i) => {
      classificazioni = [...classificazioni, ...i.classificazioni];
    });
    this.classifications = classificazioni;

    const classificazioniArray = [];

    this.classifications.forEach((c) => {
      let classificazioneTmp = this.elencoClassificazioni.find(
        (x) => x.id == c.id
      );
      const classificazione = {
        codice: c.codice,
        idTipologia: c.idTipoClassificazione,
        descrizione: classificazioneTmp ? classificazioneTmp.descrizione : "",
      };
      if (
        !classificazioniArray.find((x) => x.codice === classificazione.codice)
      ) {
        classificazioniArray.push(classificazione);
      }
    });

    this.classificationsCodes = [...new Set(classificazioniArray)];
    if (this.filterClassificazione >= 0) {
      this.classificationsCodes = this.classificationsCodes.filter((c) => {
        if (c.idTipologia === this.filterClassificazione) return c;
      });
    }

    return this.classificationsCodes;
  }

  setClassificazioniPerGrafici() {
    this.setCodiciClassificazioni();
    this.classificazioniGrafico = [];
    this.onlyNormalIntervention.forEach((i) => {
      i.classificazioni.forEach((c) => {
        this.classificationsCodes.forEach((code) => {
          if (code.codice && c.codice === code.codice) {
            const classif = new Classificazione();
            classif.codice = code.codice;
            classif.descrizione = code.descrizione;
            classif.count++;
            const importi = i.importi;
            const finanziamenti = i.finanziamenti;
            importi.forEach((imp) => {
              classif.importoI += imp.importo;
            });
            finanziamenti.forEach((f) => {
              let annualita = f.annualita;
              annualita.forEach((a) => {
                classif.importoF = a.importo;
              });
            });
            if (!this.classificazioniGrafico[code.codice]) {
              this.classificazioniGrafico[code.codice] = new Classificazione();
            }
            this.classificazioniGrafico[code.codice].count += classif.count;
            this.classificazioniGrafico[code.codice].codice = code.codice;
            this.classificazioniGrafico[code.codice].descrizione =
              code.descrizione;
            this.classificazioniGrafico[code.codice].importoI +=
              classif.importoI;
            this.classificazioniGrafico[code.codice].importoF +=
              classif.importoF;
            const diff = classif.importoI - classif.importoF;
            this.classificazioniGrafico[code.codice].differenza += diff;
          }
        });
      });
    });

    let data = [];
    for (let elementName in this.classificazioniGrafico) {
      let element = this.classificazioniGrafico[elementName];

      let obj = {};
      obj["codice"] = element.codice;
      obj["value"] = element.count;
      obj["importoI"] = element.importoI;
      obj["importoF"] = element.importoF;
      obj["diff"] = element.differenza;
      obj["descrizione"] = element.descrizione;
      data.push(obj);
    }
    return data;
  }
  //

  onChangeIntervention($event) {
    !$event.target.value && this.setIntervention(null);
  }

  setIntervention($event) {
    this.filterSimpleInterventionId = $event ? $event.id : -1;
    this.interventionDescription = $event && $event.descrizione;
    this.filterInterventions(false);
  }

  //tabella dettaglio ed esportazione excel

  async executeGetTabellaDettaglioObjAsync() {
    this.tabellaDettaglioObjLoaded = false;
    return new Promise((resolve) => {
      //console.log(`dentro executeGetTabellaDettaglioObjAsync`);
      this.getTabellaDettaglioObj(this.filteredInterventions);
    });
  }
  tabellaDettaglioObj: any;
  tabellaDettaglioObjArrayAnni: any;
  tabellaDettaglioObjLoaded = false;
  subjectTabDettaglio = new Subject<any>();
  observable$ = this.subjectTabDettaglio.asObservable();

  getTabellaDettaglioObj(filteredInterventions) {
    this.tabellaDettaglioObjArrayAnni = [];
    this.tabellaDettaglioObj = {};
    this.tabellaDettaglioObj.progetti = [];
    let myTempArray = [];
    filteredInterventions
      .filter((x) => x.isMain)
      .forEach((progetto) => {
        myTempArray.push(progetto);
        progetto.importi.forEach((x) =>
          this.tabellaDettaglioObjArrayAnni.push(x.anno)
        );

        progetto["interventi"] = filteredInterventions.filter(
          (x) =>
            !x.isMain /*|| x.numSubInterventi <= 0*/ &&
            x["progettoId"] == progetto["progettoId"]
        );
      });
    var mySet = new Set(
      this.tabellaDettaglioObjArrayAnni.sort((a, b) => a - b)
    );
    this.tabellaDettaglioObjArrayAnni = [...mySet];

    this.tabellaDettaglioObj.progetti = myTempArray;
    this.tabellaDettaglioObj.anni = this.tabellaDettaglioObjArrayAnni;

    this.subjectTabDettaglio.next(this.tabellaDettaglioObj);

    //this.tabellaDettaglioObjLoaded = true;
  }

  loadDatiTabellaDettaglio() {
    this.tabellaDettaglioObjLoaded = false;
    /*const arrayOfProjecId = this.filterProjectId
      ? [this.filterProjectId]
      : [-1];*/
    let arrayOfProjecId = [-1];
    let arrayOfMainId = [-1];
    /*const arrayOfMainId = this.filterMainInterventionId
      ? [this.filterMainInterventionId]
      : [-1];*/

    this.projectService
      .getInterventiSimpleDataWithProjects({
        filterProjectId: arrayOfProjecId[0],
        filterSimpleInterventionId: this.filterSimpleInterventionId,
        filterStatus: this.filterStatus,
        filterAreeGestionaliDirezioneEsterne:  this.filterArea,
        filterTipoClassificazione: this.filterClassificazione,
        filterSoggettoGiuridico: -1,
        filterAnno: this.filterAnno,
        filterProvinciaSoggettoGiuridico: "all",
        filterTemaProprietario: -1, //id temi prioritari
        filterFrom: -1,
        filterTo: -1,
        descriptionSearchString: '',
        filterTipologiaIntervento: -1
    })
      .subscribe((result) => {
        this.getTabellaDettaglioObj(result);
      });
  }

  loadingExportExcel = false;
  esportaExcel() {
    this.loadingExportExcel = true;
    const arrayOfProjecId = [-1];
    const arrayOfMainId = [-1];
    this.dashboardService
      .getDashboardStatoInterventiExport({
        filterProjectId: arrayOfProjecId[0],
        filterSimpleInterventionId: this.filterSimpleInterventionId,
        filterStatus: this.filterStatus,
        filterAreeGestionaliDirezioneEsterne: this.filterArea,
        filterTipoClassificazione: this.filterClassificazione,
        filterSoggettoGiuridico: -1,
        filterAnno: this.filterAnno
      })
      .subscribe(
        (res) => {
          let filename = `esportazione_stato_interventi_${moment(
            new Date()
          ).format("YYYY_MM_DDTHH_mm_ss")}.xlsx`;
          FileSaver.saveAs(res.body, `${filename}`);
          this.loadingExportExcel = false;
        },
        (error) => {
          this.toastr.error(
            `Errore: ${
              error.error.message
                ? error.error.message
                : error.error.error_description
                ? error.error.error_description
                : error.error
            }`,
            null,
            {
              timeOut: 2000,
              disableTimeOut: false,
            }
          );
          //console.log("error");
          this.loadingExportExcel = false;
        }
      );
  }

  exportXlsDetailTableData($event){
    console.log('invoke exportXlsDetailTableData');
    this.loadingExportExcel = true;
    this.dashboardService
  .getDashboardFinanziariaDetailTableExport({
    filterProjectId: -1,
    filterSimpleInterventionId: this.filterSimpleInterventionId,
    filterStatus: this.filterStatus,
    filterAreeGestionaliDirezioneEsterne: this.filterArea,
    filterTipoClassificazione: this.filterClassificazione,
    filterSoggettoGiuridico: -1,
    filterProvinciaSoggettoGiuridico: "all",
    filterTemaProprietario: -1,
    filterFrom: -1,
    filterTo: -1 ,
    filterTipologiaIntervento: -1
  })
  .subscribe(
    (res) => {
        this.loadingExportExcel = false;
      let filename = `esportazione_stato_interventi_dettaglio_${moment(
        new Date()
      ).format("YYYY_MM_DDTHH_mm_ss")}.xls`;
      FileSaver.saveAs(res.body, `${filename}`);
      this.loadingExportExcel = false;
    },
    (error) => {
        this.loadingExportExcel = false;

      this.toastr.error(
        `Errore: ${
          error.error.message
            ? error.error.message
            : error.error.error_description
            ? error.error.error_description
            : error.error
        }`,
        null,
        {
          timeOut: 2000,
          disableTimeOut: false,
        }
      );
      //console.log("error");
      this.loadingExportExcel = false;

    }
  );
}
}
