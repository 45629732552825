<form [formGroup]="form">
  <div class="form-row">
    <div class="col-md-6 mb-3">
      <label for="validationDefault01">Codice Finanziamento</label>
      <input formControlName="codice" type="text" class="form-control" max="12" id="validationDefault03" placeholder="Inserisci il codice finanziamento">
    </div>
    <div class="col-md-6 mb-3">
      <label for="validationDefault02">Fonte Finanziamento</label>
      <ng-select formControlName="fonti" [items]="tipiFinanziamento" bindValue="id" [multiple]="true" [clearSearchOnAdd]="true" [closeOnSelect]="false"
        id="validationDefault04"
        bindLabel="tipoFinanziamento" placeholder="Seleziona una o più fonti">
      </ng-select>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-6 mb-3">
      <label for="validationDefault03">Descrizione</label>
      <input formControlName="descrizione" type="text" class="form-control" id="validationDefault05" placeholder="Inserisci la descrizione">
    </div>
    <div class="col-md-6 mb-3">
      <label for="validationDefault04">Note</label>
      <input formControlName="note" type="text" class="form-control" id="validationDefault05" placeholder="Inserisci le note">
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-6 mb-3">
      <label for="validationDefault06">Importo</label>
      <input formControlName="importo" type="text" [imask]="maskCodice"
            [unmask]="true" class="form-control" placeholder="Inserisci l'importo" [ngClass]="{
              'form-control': true
      }" />
    </div>
  </div>

  <button class="btn btn-info btn-block bold" type="button" [disabled]="checkDisableSearch()" (click)="onSubmit()">Cerca
    <i class="fas fa-search pointer" style="color: #fff; font-size: 18px;"></i>
  </button>
</form>

<finanziamenti-component *ngIf="loadComponent"
  [filters]="filters"
  (disableButtonEmit)="disableFilters($event)"
  (disableSearchEmitter)="setDisableSearchButton($event)"></finanziamenti-component>

<hr *ngIf="loadComponent">
