<div class="card-body px-2" >
  <h5 class="card-title h6 bold text-capitalize ">{{ title }}</h5>
  <p class="card-text text-muted">
    <span class="py-2 d-block"></span>
  </p>

  <div class="card-footer">
    <span class=""><i class="fa-lg {{ icon }}"></i></span>
  </div>
</div>
