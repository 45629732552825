<div class="p-5 container-xl text-center" [hidden]="showPage">
  <app-loading message="Caricamento in corso..."></app-loading>
</div>
<div class="container-xl " [hidden]="!showPage">
  <div class="pb-3">
      <div class=" row ">
          <div class="titolo col-md-12 ">
              <h3 class="mt-3">{{projectId?'Modifica progetto' : 'Nuovo progetto' + (isAdmin() || isGr34()?' / fabbisogno':'')}}</h3>
          </div>
          <!--<h5 *ngIf="projectId" class="colore-im col-lg-3 mb-3 mt-3 text-center">Codice Progetto: {{mainIntervento.codice}}</h5>
          <h5 *ngIf="projectId" class="colore-im col-lg-3 mb-3 mt-3 text-center">Descrizione Progetto: {{mainIntervento.descrizione}}</h5>
          <h5 *ngIf="projectId" class="colore-im col-lg-3 mb-3 mt-3 text-center">Valore Progetto: {{printValueAsCurrency(valoreProgetto)}}</h5>
          -->

      </div>
      <progetto-div-dettaglio-component  [datiDettaglioProgetto]="mainIntervento" class="row"></progetto-div-dettaglio-component>
  </div>

  <div class="row ">
    <div id="tabs" #tabs class="col-lg-12 ">
      <ul class="nav nav-tabs navscheda position-sticky sticky-top bg-white pt-4 pb-1" style="top:90px!important;">
          <li class="nav-item mt-1 px-1" [ngClass]="{ 'active':activeTab==='tabDatiGenerali'}">
              <a (click)="confirmChangeTab($event,0);" [ngClass]="{ 'active':activeTab==='tabDatiGenerali'}" class="tab-menu nav-link " href="#divTabDatiGeneraliProgettoContent" data-toggle="tab">Dati generali</a>
          </li>
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState" [ngClass]="{ 'active':activeTab==='tabAnnualita'}">
              <a (click)="confirmChangeTab($event,7);" [ngClass]="{ 'active':activeTab==='tabAnnualita'}" class="tab-menu nav-link" href="#divTemplateAnnualita" data-toggle="tab">Annualità</a>
          </li>
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState || (this.mainIntervento && this.mainIntervento['pnrrTerritoriale'])" [ngClass]="{ 'active':activeTab==='tabFinanziamenti'}">
              <a (click)="confirmChangeTab($event,2);" [ngClass]="{ 'active':activeTab==='tabFinanziamenti'}" class="tab-menu nav-link " href="#divTabFinanziamentiContent" data-toggle="tab">Finanziamenti</a>
          </li>
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState || esistonoSal || haSubInterventi" [ngClass]="{ 'active':activeTab==='tabInterventi'}">
              <a (click)="confirmChangeTab($event,5);" [ngClass]="{ 'active':activeTab==='tabInterventi'}" class="tab-menu nav-link " href="#divTabInterventiContent" data-toggle="tab">Interventi</a>
          </li>

          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState" [ngClass]="{ 'active':activeTab==='tabFasi'}">
              <a (click)="confirmChangeTab($event,1);" [ngClass]="{ 'active':activeTab==='tabFasi'}" class="tab-menu nav-link " href="#divTabFasiContent" data-toggle="tab" title="fasi progetto {{isAdmin() || isGr34()?'/ fabbisogno':''}}">Fasi</a>
          </li>          
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,14);" [ngClass]="{ 'active':activeTab==='tabProvvedimentiDocumenti'}"
            class="tab-menu nav-link " href="#divProvvedimentiDocumentiContent" data-toggle="tab">Provvedimenti/Documentazione</a>
          </li>
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState || esistonoInterventi" [ngClass]="{ 'active':activeTab==='tabSal'}">
              <a (click)="confirmChangeTab($event,4);" [ngClass]="{ 'active':activeTab==='tabSal'}" class="tab-menu nav-link " href="#divTabSalContent" data-toggle="tab">
                  <a type="button" class="default" popover="Stati di Avanzamento Lavori e/o tutte le somme da corrispondere previste nel quadro tecnico economico" triggers="mouseenter:mouseleave" placement="top">
                      <i class="far fa-question-circle help" style="font-size: 14px;">
                      </i>
                  </a>SAL/ALTRO
              </a>
          </li>

          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState || esistonoInterventi" [ngClass]="{ 'active':activeTab==='tabErogazioni'}">
              <a (click)="confirmChangeTab($event,3);" [ngClass]="{ 'active':activeTab==='tabErogazioni'}" class="tab-menu nav-link " href="#divTabErogazioniContent" data-toggle="tab">Erogazioni</a>
          </li>
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState || esistonoInterventi" [ngClass]="{ 'active':activeTab==='tabMonitoraggio'}">
              <a (click)="confirmChangeTab($event,12); " [ngClass]="{ 'active':activeTab==='tabMonitoraggio' } " class="tab-menu nav-link " href="#divTemplateMonitoraggio" data-toggle="tab">Altri dati</a>
          </li>


          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState || !esistonoInterventi" [ngClass]="{ 'active':activeTab==='tabRiepilogo'}">
              <a (click)="confirmChangeTab($event,8);" [ngClass]="{ 'active':activeTab==='tabRiepilogo'}" class="tab-menu nav-link " href="#divTemplateRiepilogo" data-toggle="tab">Riepilogo</a>
          </li>
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState || esistonoInterventi" [ngClass]="{ 'active':activeTab==='tabRiepilogoIntervento'}">
              <a (click)="confirmChangeTab($event,11);" [ngClass]="{ 'active':activeTab==='tabRiepilogoIntervento'}" class="tab-menu nav-link " href="#divTemplateRiepilogoIntervento" data-toggle="tab">Riepilogo</a>
          </li>
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState" [ngClass]="{ 'active':activeTab==='tabStorico'}">
              <a (click)="confirmChangeTab($event,9);" [ngClass]="{ 'active':activeTab==='tabStorico'}" class="tab-menu nav-link " href="#divTemplateStorico" data-toggle="tab">Storico</a>
          </li>
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState" [ngClass]="{ 'active':activeTab==='tabDnsh'}">
              <a (click)="confirmChangeTab($event,13);" [ngClass]="{ 'active':activeTab==='tabDnsh'}" class="tab-menu nav-link " href="#divTemplateDnsh" data-toggle="tab">DNSH</a>
          </li>
          <li class="nav-item mt-1 px-1"  [ngClass]="{ 'active':activeTab==='tabBdap'}" [hidden]="isInInsertState || esistonoInterventi">
              <a (click)="confirmChangeTab($event,15);" [ngClass]="{ 'active':activeTab==='tabBdap'}" class="tab-menu nav-link " href="#divTemplateBdap" data-toggle="tab">BDAP</a>
          </li>
          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState">
            <a (click)="confirmChangeTab($event,16);" [ngClass]="{ 'active':activeTab==='tabSicerBilancio'}"
              class="tab-menu nav-link" href="#divSicerBilancioContent" data-toggle="tab">SICER - Contabilità</a>
          </li>

          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState || !haFinanziamenti || esistonoInterventi">
            <a (click)="confirmChangeTab($event,18);" [ngClass]="{ 'active':activeTab==='tabSubInterventi'}"
              class="tab-menu nav-link px-1" href="#divSubInterventi" data-toggle="tab">Sub-Interventi</a>
          </li>

          <li class="nav-item mt-1 px-1" [hidden]="isInInsertState || sismaHidden">
            <a (click)="confirmChangeTab($event,17);" [ngClass]="{ 'active':activeTab==='tabSisma'}"
              class="tab-menu nav-link" href="#divSisma" data-toggle="tab">Sisma</a>
          </li>
      </ul>
      <div class="tab-content">
          <div class="tab-pane " id="divTabFasiContent" [ngClass]="{ 'active':activeTab==='tabFasi'}">
              <fasi-component id="tabFasiContent" [interventoId]="interventoId" #tabFasiContent [gotoBtnOptions]="getGotoOptions()" (gotoBtnFunction)="gotoBackList($event)"></fasi-component>
          </div>
          <div class="tab-pane " id="divTabDatiGeneraliProgettoContent" [ngClass]="{ 'active':activeTab==='tabDatiGenerali'}">
              <dati-generali-component #tabDatiGeneraliProgettoContent [projectData]="datiProgetto" [gotoBtnOptions]="gotoBtnOptions" (gotoBtnFunction)="gotoBackList($event)" (onSubmitOk)="datiGeneraliSaved($event)" (onFillFormCompleteOk)="datiGeneraliFormFillComplete($event)">
              </dati-generali-component>
          </div>
          <div class="tab-pane " id="divTabFinanziamentiContent" [ngClass]="{ 'active':activeTab==='tabFinanziamenti'}">
              <intervento-finanziamento-component [gotoBtnOptions]="getGotoOptions()" (gotoBtnFunction)="gotoBackList($event)" id="tabFinanziamentiContent" [interventoId]="interventoId" #tabFinanziamentiContent></intervento-finanziamento-component>
          </div>
          <div class="tab-pane " id="divTabErogazioniContent" [ngClass]="{ 'active':activeTab==='tabErogazioni' } ">
              <intervento-erogazioni-component [gotoBtnOptions]="getGotoOptions()" (gotoBtnFunction)="gotoBackList($event) " id="tabErogazioniContent" [interventoId]="interventoId" #tabErogazioniContent>
              </intervento-erogazioni-component>
          </div>
          <div class="tab-pane " id="divTabSalContent" [ngClass]="{ 'active':activeTab==='tabSal' } ">
              <intervento-sal-component [gotoBtnOptions]="getGotoOptions()" (gotoBtnFunction)="gotoBackList($event) " id="tabErogazioniContent" [interventoId]="interventoId" (onSubmitOk)="onSalSaved($event); " #tabSalContent>
              </intervento-sal-component>
          </div>
          <div class="tab-pane " id="divTabInterventiContent" [ngClass]="{ 'active':activeTab==='tabInterventi' } ">
              <interventi-component [gotoBtnOptions]="getGotoOptions()" (gotoBtnFunction)="gotoBackList($event) " (onInterventoDatiGeneraliSaved)="onInterventoDatiGeneraliSaved($event)" #tabInterventiContent
                                    [dataProject]="datiProgetto" [interventoId]="interventoId" [datiDettaglioProgetto]="mainIntervento">
							</interventi-component>
          </div>
          <div class="tab-pane " id="divTemplateAnnualita" [ngClass]="{ 'active':activeTab==='tabAnnualita' } ">
              <associa-annualita-component id="templateAnnualita" [interventoId]="interventoId" [gotoBtnOptions]="getGotoOptions()" (gotoBtnFunction)="gotoBackList($event) " (onSubmitEmitter)="annualitaChangedEmit($event) "
                  #tabAnnualitaContent>
              </associa-annualita-component>
          </div>          
          <div class="tab-pane " id="divTemplateRiepilogo" [ngClass]="{ 'active':activeTab==='tabRiepilogo' } ">
              <!--***-->
              <riepilogo-progetto-component [interventoId]="interventoId" #tabRiepilogoContent [gotoBtnOptions]="getGotoOptions() " (gotoBtnFunction)="gotoBackList($event) ">
              </riepilogo-progetto-component>
              <!--***-->
          </div>
          <div class="tab-pane " id="divTemplateRiepilogoIntervento" [ngClass]="{ 'active':activeTab==='tabRiepilogoIntervento' } ">
              <!--***-->
              <riepilogo-intervento-component [interventoId]="interventoId" #tabRiepilogoInterventoContent [gotoBtnOptions]="getGotoOptions()" (gotoBtnFunction)="gotoBackList($event) ">
              </riepilogo-intervento-component>
              <!--***-->
          </div>
          <div class="tab-pane " id="divTemplateStorico" [ngClass]="{ 'active':activeTab==='tabStorico' } ">
              <!--***-->
              <storico-intervento-component [interventoId]="interventoId" #divTemplateStorico [gotoBtnOptions]="getGotoOptions() " (gotoBtnFunction)="gotoBackList($event) ">
              </storico-intervento-component>
              <!--***-->
          </div>
          <div class="tab-pane" id="divTemplateMonitoraggio" [ngClass]="{ 'active':activeTab==='tabMonitoraggio' } ">
              <monitoraggio-component #divTemplateMonitoraggio [interventoId]="interventoId" [gotoBtnOptions]="getGotoOptions() " (gotoBtnFunction)="gotoBackList($event) ">
              </monitoraggio-component>
          </div>
          <div class="tab-pane" id="divTemplateDnsh" [ngClass]="{ 'active':activeTab==='tabDnsh' } ">
            <dnsh-component  #divTemplateDnsh [interventoId]="interventoId" [gotoBtnOptions]="getGotoOptions() " (gotoBtnFunction)="gotoBackList($event) ">
            </dnsh-component>
          </div>
          <div class="tab-pane " id="divProvvedimentiDocumentiContent" [ngClass]="{ 'active':activeTab==='tabProvvedimentiDocumenti'}">
            <associa-provvedimenti-documenti-component [isInModal]="false" #divProvvedimentiDocumentiContent
              [interventoId]="interventoId"
              [gotoBtnOptions]="getGotoOptions() " (gotoBtnFunction)="gotoBackList($event) "
              >
            </associa-provvedimenti-documenti-component>
          </div>
          <div class="tab-pane" id="divTemplateBdap" [ngClass]="{ 'active':activeTab==='tabBdap' } " [hidden]="isInInsertState || esistonoInterventi">
            <bdap-component #divTemplateBdap [interventoId]="interventoId" [cup]="mainIntervento?.cup" [gotoBtnOptions]="getGotoOptions() " (gotoBtnFunction)="gotoBackList($event) ">
            </bdap-component>
          </div>
          <div class="tab-pane " id="divSicerBilancioContent" [ngClass]="{ 'active':activeTab==='tabSicerBilancio'}">
            <sicer-bilancio-component [isInModal]="false" #divSicerBilancioContent
              [type]="type"
              [interventoId]="interventoId"
              [gotoBtnOptions]="getGotoOptions() " (gotoBtnFunction)="gotoBackList($event) "
              >
            </sicer-bilancio-component>
          </div>
          <div class="tab-pane " id="divSismaContent" [ngClass]="{ 'active':activeTab==='tabSisma'}">
            <app-interventi-sisma [isInModal]="false" #divSismaContent
            [interventoId]="interventoId"
            [gotoBtnOptions]="getGotoOptions() " (gotoBtnFunction)="gotoBackList($event) "
            >
            </app-interventi-sisma>
          </div>
          <div class="tab-pane " id="divSubInterventi" [ngClass]="{ 'active':activeTab==='tabSubInterventi'}">
            <app-sub-interventi  #divSubInterventi
            [interventoId]="interventoId"
            [isInModal]="false"
            [codiceIntervento]="codiceIntervento"
            [gotoBtnOptions]="{show:true, add: 'Aggiungi Sub Intervento',label: 'Torna a elenco progetti',showIcon:false}"
            (gotoBtnFunction)="gotoBackList($event) "
            (afterInsertOrDeleteEvent)="reloadAfterSubInterventi($event)"
            >
            </app-sub-interventi>
          </div>
      </div>
    </div>
  </div>
</div>
