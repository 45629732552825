import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { InterventoService, ProgettoService, FinanziamentoService } from '../../services/index';
import * as moment from 'moment';
import { AuthService } from 'src/app/services';

@Component(
    {
        selector: 'riepilogo-finanziamento-component',
        templateUrl: 'riepilogo-finanziamento.component.html',
        styleUrls: ['riepilogo-finanziamento.component.css']
    }
)
export class RiepilogoFinanziamentoComponent implements OnInit {

    private _finanziamentoId: number

    finanziamento: any;
    listaInterventi = [];
    loading: boolean = true;
    listaCapitoli = [];

    @Input()
    set finanziamentoId(value) {
        console.log(`inside set _finanziamentoId= ${value}`);
        this._finanziamentoId = value;
        if (!value) return;
    }
    get finanziamentoId() {
        console.log(`inside get _finanziamentoId= ${this._finanziamentoId}`);
        return this._finanziamentoId;
    }


    constructor(
        private el: ElementRef,
        private toastr: ToastService,
        private interventoService: InterventoService,
        private progettoService: ProgettoService,
        private finanziamentoService: FinanziamentoService,
        private authService:AuthService
    ) {
        console.log('into RiepilogoFinanziamentoComponent.constructor');

    }



    ngOnInit() {
        console.log('into RiepilogoFinanziamentoComponent.ngoninit');
    }

    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
    gotoBackBtnClick() {
        this.gotoBtnFunction.emit(true);
    }

    reloadData() {
        //dati generali
        this.loading = true;
        this.getProvvedimenti();
        this.finanziamentoService.getById(this._finanziamentoId).subscribe(
            x => {
                this.finanziamento = x;
                this.finanziamento['annualita'].sort((a, b) => a.anno - b.anno);
                this.getProgetti();

                //interventi
                /*this.progettoService.getInterventi(this.intervento.progettoId).subscribe(
                    result => {
                        result = result.filter(x => !x.isMain);//remove the main intervento                
                        this.listaInterventi = result;
                    }

                );*/

            }
        );
    }

    getProgetti() {
        this.finanziamentoService.getProgetti(this._finanziamentoId).subscribe(
            x => {
                this.listaInterventi = x;
                this.loading = false;

            }
        );
    }

    getProvvedimenti() {
        this.listaCapitoli = [];
        let that = this;
        this.finanziamentoService.getProvvedimenti(this.finanziamentoId).subscribe(
            x => {
                x.sort((a, b) => {
                    if (moment(a.data).toDate() < moment(b.data).toDate()) return -1;
                    if (moment(a.data).toDate() > moment(b.data).toDate()) return 1;
                    return 0;
                });
                //this.listaCapitoli = [];
                x.forEach(provvedimento => {
                    provvedimento.provvedimentoCapitoliBilancio.forEach(
                        capitolo => {
                            let cap = {
                                descrizione: capitolo.capitoloBilancioDescrizione
                                , codice: capitolo.capitoloBilancioCodice
                                , id: capitolo.capitoloBilancioId
                                , annualita: [{ anno: capitolo.anno, importo: capitolo.importo }]
                            };

                            let capitoloInArray = that.listaCapitoli.find(y => y.id == cap.id);
                            if (!capitoloInArray) {
                                that.listaCapitoli.push(cap);
                            }
                            else {
                                let annualitacapitolo = capitoloInArray.annualita.find(x => x.anno == capitolo.anno);
                                if (annualitacapitolo) {
                                    annualitacapitolo.importo = capitolo.importo;
                                }
                                else {
                                    capitoloInArray.annualita.push({ anno: capitolo.anno, importo: capitolo.importo });
                                }
                            }
                        }
                    );
                });
                // console.log(`listacapitoli=${that.listaCapitoli}`);
            }
        );

    }

    getFaseCorrente(subIntervento: any) {
        if (subIntervento.fasi && subIntervento.fasi.length > 0) {
            for (let i = 0; i < subIntervento.fasi.length; i++) {
                let fase = subIntervento.fasi[i];
                let startdate = moment(fase.dataInizio ? fase.dataInizio : fase.dataPrevistaInizio);
                let enddate = moment(fase.dataFine ? fase.dataFine : fase.dataPrevistaFine);
                let currentDate = moment(new Date());
                if (startdate <= currentDate && enddate >= currentDate) return `${fase.tipologiaFase.fase} - ${fase.tipologiaFase.titolo} - ${fase.tipologiaFase.descrizione}`;

            }
        }
        return "";
    }



    getFinanziamentoPerAnno(intervento: any, anno: number) {
        if (!intervento) return 0;
        let fin = intervento.finanziamenti.find(x => x.finanziamento.id == this.finanziamento.id);
        if (!fin) return 0;
        let annualita = fin.annualita.find(x => x.anno == anno);
        if (!annualita) return 0;
        return annualita.importo;
    }

    getTotaleSommaProgetti() {
        let somma = 0;
        if (this.listaInterventi) {
            this.listaInterventi.forEach(progetto => {
                somma += this.getTotale(progetto);
            });
        }
        return somma;
    }

    getImportoPerAnnoCapitolo(capitolo: any, anno: number) {
        //return 0;
        if (!this.finanziamento || !capitolo) return 0;
        let annualita = capitolo.annualita.find(x => x.anno == anno);
        if (!annualita) return 0;
        return annualita.importo;
    }
    getTotaleCapitolo(capitolo: any) {
        //return 0;
        if (!this.finanziamento || !capitolo) return 0;
        let somma = 0;
        this.finanziamento.annualita.forEach(annualita => {
            let valore = capitolo.annualita.find(y => y.anno == annualita.anno);
            somma += valore ? valore.importo : 0;
        });
        /*capitolo.annualita.forEach(element => {
            somma +=element.importo;
        });*/
        return somma;
    }

    getTotaleSommaAnnoCapitoli(anno: number) {
        if (!this.finanziamento || !this.listaCapitoli) return 0;
        let somma = 0;
        this.listaCapitoli.forEach(
            capitolo => {
                let annualita = capitolo.annualita.find(y => y.anno == anno);
                somma += annualita ? annualita.importo : 0;
            }
        );
        return somma;
    }

    getTotaleSommaCapitoli() {
        if (!this.finanziamento || !this.listaCapitoli) return 0;
        let somma = 0;
        this.finanziamento.annualita.forEach(annualita => {
            somma += this.getTotaleSommaAnnoCapitoli(annualita.anno);
        });
        return somma;
    }


    getImportoPerAnno(finanziamento: any, anno: number) {
        if (!finanziamento) return 0;
        if ((!finanziamento.annualita || finanziamento.annualita.length <= 0)) return (0);
        let importoAnno = 0;
        importoAnno = finanziamento.annualita.find(x => x.anno == anno);
        if (!importoAnno) return (0);
        return importoAnno['importo'];
    }


    getTotaleFinanziamento(finanziamento: any) {
        if (!finanziamento) return 0;
        let valore = 0;
        finanziamento.annualita.forEach(element => {
            valore += element.importo;
        });
        return valore;
    }

    getTotaleDisponibilitaFinanziamento() {
        let valoreTotaleFinanziamento = this.getTotaleFinanziamento(this.finanziamento);
        let sommaValoreTotaleUsatoInProgetti = 0;
        if (this.listaInterventi) {
            this.listaInterventi.forEach(x => sommaValoreTotaleUsatoInProgetti += this.getTotale(x));
        }
        return valoreTotaleFinanziamento - sommaValoreTotaleUsatoInProgetti;
    }
    getTotaleDisponibilitaFinanziamentoPerAnno(anno: number) {
        let valoreTotaleFinanziamento = this.getImportoPerAnno(this.finanziamento, anno);
        let sommaValoreTotaleUsatoInProgetti = 0;
        if (this.listaInterventi) {
            this.listaInterventi.forEach(x => sommaValoreTotaleUsatoInProgetti += this.getFinanziamentoPerAnno(x, anno));
        }
        return valoreTotaleFinanziamento - sommaValoreTotaleUsatoInProgetti;
    }



    getTotale(intervento: any) {
        if (!intervento) return 0;
        let fin = intervento.finanziamenti.find(x => x.finanziamento.id == this.finanziamento.id);
        if (!fin) return 0;

        let somma = 0;
        fin.annualita.forEach(element => {
            somma += element.importo;
        });
        return somma;
    }


    getUtilizzatoPerAnno(progetto: any, anno: number) {
        if (!progetto) return 0;
        let somma = 0;
        progetto.subInterventi.forEach(intervento => {
            somma += this.getFinanziamentoPerAnno(intervento, anno);
        });
        return somma;
    }

    getTotaleUtilizzato(progetto: any) {
        if (!progetto) return 0;
        let somma = 0;
        progetto.subInterventi.forEach(intervento => {
            this.finanziamento.annualita.forEach(annualita => {
                somma += this.getFinanziamentoPerAnno(intervento, annualita.anno);
            });

        });
        return somma;
    }

    getDaCoprire(subIntervento: any) {
        return 0;
        if (!subIntervento) return 0;
        let finanziato = 0;
        let somma = 0;
        subIntervento.importi.forEach(element => {
            somma += element.importo;
        });
        subIntervento.finanziamenti.forEach(element => {
            element.annualita.forEach(annualita => {
                finanziato += annualita.importo;
            });
        });
        let coperto = somma - finanziato;
        return coperto;

    }

    getCoperto(subIntervento: any) {
        return 0;
        if (!subIntervento) return 0;
        let finanziato = 0;
        subIntervento.finanziamenti.forEach(element => {
            element.annualita.forEach(annualita => {
                finanziato += annualita.importo;
            });
        });
        return finanziato;
    }


    getTotaleSommaAnno(anno: number) {
        let valore = 0;
        if (this.listaInterventi && this.listaInterventi.length > 0) {
            this.listaInterventi.forEach(subIntervento => {
                let importoAnno = this.getFinanziamentoPerAnno(subIntervento, anno);
                valore += (importoAnno ? importoAnno : 0);
            });
        }
        return valore;
    }

    getTotaleSommaInterventi() {
        return 0;
        let valore = 0;
        if (this.listaInterventi && this.listaInterventi.length > 0) {
            this.listaInterventi.forEach(
                subIntervento => {
                    valore += this.getTotale(subIntervento);
                }
            );
        }
        return valore;
    }

    getTotaleCapacitaResiduaProgetto() {
        return 0;
        /*let totaleInterventi = this.getTotaleSommaInterventi();
        let totaleProgetto = this.getTotale(this.intervento);
        return totaleProgetto - totaleInterventi;*/
    }

    getSommaCopertoTotale() {
        return 0;
        let valore = 0;
        if (this.listaInterventi && this.listaInterventi.length > 0) {
            this.listaInterventi.forEach(
                subIntervento => {
                    valore += this.getCoperto(subIntervento);
                }
            );
        }
        return valore;
    }



    getSommaDaCoprireTotale() {
        return 0;
        let valore = 0;
        if (this.listaInterventi && this.listaInterventi.length > 0) {
            this.listaInterventi.forEach(
                subIntervento => {
                    valore += this.getDaCoprire(subIntervento);
                }
            );
        }
        return valore;
    }




    printValueAsCurrency(value, withCurrency: boolean = true) {
        //return this.italianCurrencyPipe.parse(value) + " €";
        //console.log('printValueAsCurrency');
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        let currency = '€';
        if (!withCurrency) currency = "";
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }

    printScadenza() {
        if (this.finanziamento) {
            let scadenza = moment(this.finanziamento.scadenza).toDate();
            scadenza.setHours(scadenza.getHours() - scadenza.getTimezoneOffset() / 60);
            return scadenza.toLocaleDateString();
        }
        return '';
    }
    _isInModal: boolean = false;
    @Input()
    set isInModal(value: boolean) {
        this._isInModal = value;
    }
    get isInModal() { return this._isInModal; }

    arrowUp = true;

    test() {
        console.log('ciao');
    }

    isGr34(){
        return this.authService.isGr34();
    }
    isAdmin(){
        return this.authService.isAdmin();
    }
}
