import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { GeoService } from 'src/app/services/geo.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Province } from 'src/app/models/province';
import * as moment from "moment";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastService } from 'src/app/services/toast.service';
import { KeyValue } from 'src/app/models/keyValue';

@Component({
  selector: 'app-edit-province-modal',
  templateUrl: './edit-province-modal.component.html',
  styleUrls: ['./edit-province-modal.component.css']
})
export class EditProvinceModalComponent implements OnInit {
  @Input() currentProvince: Province;
  public onClose: Subject<boolean>;
  submitted: boolean = false;
  editForm: FormGroup;
  regions: KeyValue[];
  addProvinceForm: FormGroup;
  yesNo: Array<{ k: boolean; v: string }> = [
    { k: false, v: "NO" },
    { k: true, v: "SI" }
  ];  
  constructor(
    private geoService: GeoService,
    private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private el: ElementRef,
    private localeService: BsLocaleService,
    private toastr: ToastService
  ) {
    this.localeService.use("it");    
  }

  ngOnInit() {
    this.geoService.getItalianRegions().subscribe(res => {
      this.regions = res;
    });
    this.editForm = this.initializeFormGroup();
    this.onClose = new Subject();
  }

  initializeFormGroup() {
    return this.formBuilder.group({
      id: [this.currentProvince.id],
      name: [this.currentProvince.name, [Validators.required, Validators.maxLength(255)]],
      code: [
        this.currentProvince.code,
        [Validators.required, Validators.minLength(3), Validators.maxLength(3)]
      ],
      acronym: [
        this.currentProvince.acronym,
        [Validators.required, Validators.minLength(2), Validators.maxLength(2)]
      ],
      isCittametropolitana: [this.currentProvince.isCittametropolitana, [Validators.required]],
      region: [this.currentProvince.idRegion, [Validators.required]],
      fromDate: [
        this.currentProvince.fromDate ?
          moment(this.currentProvince.fromDate, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          )
        : "",
        [
          // Validators.required,
          // Validators.minLength(10),
          // Validators.maxLength(10)
        ]
      ],
      toDate: [
        this.currentProvince.toDate ?
          moment(this.currentProvince.toDate, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          )
        : "",
        [
          // Validators.required,
          // Validators.minLength(10),
          // Validators.maxLength(10)
        ]
      ]
    });
  }

  get f() {
    return this.editForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.editForm.updateValueAndValidity();
    let errors = false;
    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }
    if (this.editForm.invalid || errors) {
      console.log("invalid");
      let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
      for (let el in this.editForm.controls) {
        if (this.editForm.controls[el].invalid) {
          console.log(`set focus on ${el}`);
          if (this.editForm.controls[el] instanceof FormGroup) {
            for (let el1 in this.editForm.get(el)["controls"]) {
              if (this.editForm.get(el)["controls"][el1].invalid) {
                invalidElements = this.el.nativeElement.querySelectorAll(
                  `[formcontrolname="${el1}"]`
                );
                break;
              }
            }
          } else {
            invalidElements = this.el.nativeElement.querySelectorAll(
              `[formcontrolname="${el}"]`
            );
          }
          break;
        }
      }
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }
      return false;
    }

    const newProvince: Province = new Province();
    newProvince.id = this.f.id.value;
    newProvince.name = this.f.name.value;
    newProvince.code = this.f.code.value;
    newProvince.acronym = this.f.acronym.value;
    newProvince.isCittametropolitana = this.f.isCittametropolitana.value;
    newProvince.idRegion = this.f.region.value;
    newProvince.fromDate = 
      this.f.fromDate.value ? moment(this.f.fromDate.value, "DD/MM/YYYY").format("YYYY-MM-DD") : null;
    newProvince.toDate = 
      this.f.toDate.value ? moment(this.f.toDate.value, "DD/MM/YYYY").format("YYYY-MM-DD") : null;

    this.geoService.createProvince(newProvince).subscribe(res => {
      this.toastr.success( "Provincia aggiornata con successo!");
      this.onClose.next(true);
    }, error => {
      this.toastr.error( "Codice già presente");
    });
  }

  onChangeFromDate(value: Date) {
    value ? value.setHours(value.getHours() + 2) : null;
    this.currentProvince.fromDate = value ? moment(value).format("DD/MM/YYYY") : null;
  }

  onChangeToDate(value: Date) {
    value ? value.setHours(value.getHours() + 2) : null;
    this.currentProvince.toDate = value ? moment(value).format("DD/MM/YYYY") : null;
  }
}
