<div class="container-fluid px-0 bg-celeste ">


  <app-header-dashboard></app-header-dashboard>

  <!--filtri inizio-->

  <div class="row pb-3 pt-1 bg-0083e0" style="z-index: 9000;" [formGroup]="filterForm">


    <!--filtro intervento-->
    <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
      <label for=" importoFinanziamento ">
        <h6 class="mb-0 text-white bold">
          Intervento <small>(min 3 caratteri)</small>
        </h6>
      </label>

      <app-completer [cpOptions]="completerParams" (onSelectEmitter)="setIntervention($event)"
        (keyup)="onChangeIntervention($event)" >
      </app-completer>
    </div>

    <!--filtro per cup-->
    <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label for="cup">
            <h6 class="mb-0 text-white bold">
              CUP
            </h6>
          </label>
          <input type="text" formControlName="cup" class="form-control" style="display:block;"  />
    </div>




    <!--filtro per soggetti attuatori-->
    <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
      <label>
        <h6 class="mb-0 text-white bold">Soggetto Attuatore</h6>
      </label>
      <ng-select [items]="soggettiCombo" formControlName="soggettoAttuatore"   bindValue="id" bindLabel="denominazione"
        placeholder="Seleziona soggetto">
        <ng-template ng-option-tmp let-item="item">
          <div title="{{ item.denominazione }}">{{ item.denominazione }}</div>
        </ng-template>
      </ng-select>
    </div>



    <!--filtro per Comune-->
    <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
      <label title="Comune">
        <h6 class="mb-0 text-white bold">Comune</h6>
      </label>
      <ng-select  formControlName="comune"  [items]="municipalities"  bindValue="id" bindLabel="label"
        placeholder="Seleziona comune">
        <ng-template ng-option-tmp let-item="item">
          <div title="{{ item.label }}">{{ item.label }}</div>
        </ng-template>
      </ng-select>
    </div>
<!--filtro per rup-->
<div class="col-xl-3 col-lg-4 col-md-6 pt-1" >
    <label>
      <h6 class="mb-0 text-white bold">RUP</h6>
    </label>
    <ng-select [items]="soggettiRPCombo" formControlName="rup"   bindValue="id" bindLabel="label"
      placeholder="Seleziona RUP">
      <ng-template ng-option-tmp let-item="item">
        <div title="{{ item.label }}">{{ item.label }}</div>
      </ng-template>
    </ng-select>
  </div>

  <!--filtro per data documento-->
  <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
    <label><h6  class="mb-0 text-white bold">Data documento a partire da</h6></label>

    <div class="d-flex">
        <input type="text"  formControlName="dataDocumento"   bsDatepicker class="form-control"
                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY'
                , adaptivePosition: true, containerClass:'theme-dark-blue'
                    }"
                #dataDocumento="bsDatepicker"
                style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
              <button class="btn btn-002742" (click)="dataDocumento.toggle()" type="button"
                style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                <i class="fas fa-calendar-alt text-white"></i>
                </button>
    </div>


</div>


<!--filtro per RP -->
<div class="col-xl-3 col-lg-4 col-md-6 pt-1">
<label>
  <h6 class="mb-0 text-white bold">RP</h6>
</label>
<ng-select  formControlName="rp"  [items]="soggettiRPCombo" bindValue="id" bindLabel="label"
  placeholder="Seleziona RP" >
  <ng-template ng-option-tmp let-item="item">
    <div title="{{ item.label }}">{{ item.label }}</div>
  </ng-template>
</ng-select>
</div>

<!--filtro per noteLavorazione-->
<div class="col-xl-3 col-lg-4 col-md-6 pt-1">
    <label for="noteLavorazione">
        <h6 class="mb-0 text-white bold">
          Note
        </h6>
      </label>
      <input type="text" formControlName="noteLavorazione"  class="form-control" style="display:block;"  />
</div>
<!--filtro per lavorato-->
<div class="col-xl-1 col-lg-2 col-md-3 pt-1" >
    <label for="lavorato">
      <h6 class="mb-0 text-white bold">Lavorato</h6>
    </label>
    <ng-select
      [items]="yesNoItems"
      bindValue="k"
      bindLabel="v"
      placeholder="Seleziona"
      formControlName="lavorato"
    >
      <ng-template ng-option-tmp let-item="item">
        <div title="{{ item.v }}">{{ item.v }}</div>
      </ng-template>
    </ng-select>
  </div>


<!--bottoni-->
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 pt-1 align-self-end align-items-end">

      <div class="d-flex align-self-end align-items-end">
        <!--bottone cerca-->
        <div class="col-lg-6 pl-0 align-self-end align-items-end">
          <button  type="button" class="mt-1 btn btn-info btn-block"
            (click)="onFind()">
            Cerca
          </button>
        </div>
        <!--esporta-->
        <div class="col-lg-6 pl-0 align-self-end align-items-end">
            <button  type="button" class="mt-1 btn btn-info btn-block"
              (click)="onExport()" [disabled]="exporting">
              Esporta
              <img
            *ngIf="exporting"
            class="pl-3 float-right"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
          />
            </button>
          </div>
      </div>


    </div>
  </div>

  <!--filtri fine-->
  <div class="row bg-white mt-5">
    <div class="col-12">
    <!--tabella-->
    <ngx-datatable-wrapper
      #ngxDatatableWrapper
      [templates]="templateList"
      style="max-width: 100%; height: auto"
      [dtOptions]="dtOptions"
    >
    <ng-template #tipologiaEntitaTemplate let-row="row" let-value="value">
        <span>{{printTipologiaEntitaTemplateCell(row,value)}}</span>
      </ng-template>
      <ng-template #btnEditTemplate let-row="row" let-value="value">
        <a
          [ngClass]="{
            disabled: loading
          }"
          class="border-0 text-center"
          (click)="onEdit(row)"
          data-toggle="tooltip"
          data-placement="top"
          title="modifica dati"
          >&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i
        ></a>
      </ng-template>
      <ng-template #fieldNoteTemplate let-row="row" let-value="value" >
        <div><span  (click)="editNoteLavorazione(row.id,row.noteLavorazione,row.tipologiaEntita)" class="p-1"><i
          class=" pointer fas font-size-18 text-secondary"
          [ngClass]="{
            ' fa-comment-slash': !value,
            ' fa-comment text-info help px-1': value
          }"
          popover="{{ value }}"
          placement="auto"
          triggers="mouseenter:mouseleave"

        ></i></span></div>
      </ng-template>

      <ng-template #numeroDocumentoTemplate let-row="row" let-value="value">
        <ng-container *ngIf="row.tipologiaEntita=='SICER'">
          <a class="pointer font-weight-bold text-0083e0 " title="scarica allegato" href="javascript:;"
            (click)="downloadSicer(row)">{{value}}
          </a>
        </ng-container>

        <ng-container *ngIf="row.tipologiaEntita=='PROSA'">
            <a class="pointer font-weight-bold text-0083e0" title="mostra allegati" data-toggle="collapse" role="button"
              aria-expanded="false" [attr.aria-controls]="'.dtw_tr_collapse_prosa_' + row.id"
              [attr.data-target]="'.dtw_tr_collapse_prosa_' + row.id" >
              <i class="fas fa-list"></i>&nbsp;{{row.numero}}</a>
            <br />
            <div id="documenti-prosa-{{row.numero}}" class="collapse {{'dtw_tr_collapse_prosa_' + row.id}}" >

              <ul class="pl-2">
                <ng-container *ngFor="let allegato of row.allegatiArray">
                <li class="text-lowercase"  >
                  <a href="javascript:;" (click)="downloadDocumentoProsa(allegato.codice, allegato.nome)"
                   >{{allegato.nome}}</a>
                </li>
                </ng-container>
              </ul>
            </div>
          </ng-container>
      </ng-template>

      <ng-template #oggettoProtocolloTemplate let-row="row" let-value="value">
        <span class="help" title="{{ value }}">{{ trimText(value, 100) }}</span>
      </ng-template>
      <ng-template  #lavoratoTemplate let-row="row" let-value="value">
        <a class="pointer font-weight-bold text-0083e0"
            title="{{value?'modifica in non lavorato':'modifica in lavorato'}}"
          href="javascript:;"
          (click)="changeStatus(row)"
        >
            <i *ngIf="value" class="fas fa-check-circle font-size-18 no-hover text-success"></i>
            <i *ngIf="!value" class="fas fa-minus-circle font-size-18 no-hover text-danger"></i>
        </a>
      </ng-template>
      <ng-template #btnSendEmailTemplate let-row="row" let-value="value">
        <a
          class="border-0 text-center"
          (click)="onSendEmail(row)"
          data-toggle="tooltip"
          data-placement="top"
          title="invio mail a utente"
          >
          <i class="far fa-envelope pointer"></i>
        </a><br/>{{printValueAsDate(row.dataEmail)}}<br />{{row.emailInviataA}}
      </ng-template>
      <ng-template #cupRpRupfieldTemplate let-row="row" let-value="value">
        <ul class="pl-2"><li>CUP: {{row.cup}}</li><li>RUP: {{row.rup}}</li><li>RP: {{row.rp}}</li></ul>
      </ng-template>
    </ngx-datatable-wrapper>
  </div>
  </div>

</div>





<ng-template #modalInvioEmail>
    <div class="modal-header text-center">
        <h4 class="modal-title pull-left">Invia email al RUP/RP</h4>
        <button type="button" class="close pull-right" aria-label="Chiudi" (click)="closeInvioEmailModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <form [formGroup]="emailForm" novalidate>
            <input type="hidden" formControlName="id" />
            <input type="hidden" formControlName="tipo" />
            <label form="emailTo" class="mandatory">Destinatario:</label>

          <div [ngClass]="{ 'is-invalid': submittedEmail && emailForm.get('emailTo').errors }">
            <ng-select
            [items]="emailDestItems"
            formControlName="emailTo"
            bindValue="key"
            bindLabel="value"
            placeholder="Seleziona il destinatario"
          >
          </ng-select>
            <div *ngIf="submittedEmail && emailForm.get('emailTo').errors" class="invalid-feedback" style="display: block">
              <div *ngIf="emailForm.get('emailTo').errors.required">Campo obbligatorio</div>
            </div>
          </div>



            <br>
            <div class="form-group">
              <label for="subject">Oggetto </label>
              <input  formControlName="subject" type="text" class="form-control" readonly="readonly" >
            </div>
            <div class="form-group" style="margin-bottom:20px">
              <label>Testo della mail <span class="required" aria-required="true"> </span> &nbsp;</label>
              <textarea [ngClass]="{ 'is-invalid': submittedEmail && emailForm.get('body').errors }" formControlName="body" rows="9" cols="20" class="form-control" placeholder="Testo della mail"></textarea>
              <div *ngIf="submittedEmail && emailForm.get('body').errors" class="invalid-feedback" >
                <div >Testo dell'email &egrave; obbligatorio</div>
            </div>
            </div>
            <div class=" col-md-12 form-group"
              style="background-color:#fff;width:100%; padding:10px; margin-bottom:10px;text-align:center">
                <button
              class="btn btn-lg btn-primary btn-block"
              type="button"
              style="margin:15px 0"
              (click)="onInvioEmail()"
            >INVIA</button>
            </div>
          </form>
        </div>
      </div>

</ng-template>

<ng-template #templateEditNote>
    <div class="modal-header  text-center">
        <h4 class="modal-title pull-left">Note</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeInvioEmailModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="modalForm" (ngSubmit)="updateNoteLavorazione()">
            <input type="hidden" [formGroup]="modalForm" formControlName="id" />
            <input type="hidden" [formGroup]="modalForm" formControlName="tipo" />

            <div class="col-lg-12 mt-3">
                <div class="form-group">
                    <h5><label for="note" class="">Note:</label></h5>
                    <textarea rows="5"
                    [formGroup]="modalForm" formControlName="note"  class="form-control" placeholder="Inserisci le note" ></textarea>

                </div>

                <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0 ">
          <img *ngIf="loading " class="pl-3 float-right " alt="Salva"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          Salva
        </button>


            </div>
        </form>
    </div>
</ng-template>
