import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  TemplateRef,
  OnDestroy
} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { PnrrService } from "../../../services/pnrr.service";
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { KeyValue } from 'src/app/models/keyValue';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { AuthService } from "src/app/services/auth.service";

@Component({
    selector: "pnrr-componente-management",
    templateUrl: "./pnrr-componente-management.component.html",
    styleUrls: ["./pnrr-componente-management.component.css"]
})
export class PnrrComponenteManagementComponent
    implements OnInit, OnDestroy {
    visible = true;
    continents: KeyValue[];
    continentsAdd: KeyValue[];
    submitted: boolean = false;
    addComponenteForm: FormGroup;
    modalRef: BsModalRef;
    open: boolean = false;
    dtOptionsNgx: any;
    section: string = 'PNRR - Componenti';

    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;
    @ViewChild('templateModal', { static: true })
    public templateModal: TemplateRef<any>;

    //@ViewChild('buttonsTemplate',{static:false}) buttonsTemplate: TemplateRef<any>;
    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;
    templateList = [];

    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };

    constructor(
        private http: HttpClient,
        private pnrrService: PnrrService,
        private formBuilder: FormBuilder,
        private localeService: BsLocaleService,
        private el: ElementRef,
        private toastr: ToastService,
        private modalService: BsModalService,
        private authService:AuthService
    ) {
        this.localeService.use("it");
        this.dtOptionsNgx = {
            rows: []
            , totalElements: 0
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , ajax: this.loadData
            , scrollbarH: "true"
        };
    }

    refreshGrid() {
        this.ngxDatatableWrapper.refresh();
    }

    ngOnDestroy(): void {

    }
    ngOnInit() {
        window.scroll(0, 0);
        this.addComponenteForm = this.initializeFormGroup();
        this.templateList = [{ name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }, { name: 'btnEditTemplate', template: this.btnEditTemplate }];
        this.getMissioni();
    }


    initializeFormGroup() {
        return this.formBuilder.group({
            id: [null],
            descrizione: [{ value: null, disabled: !this.enableSection(this.section, ['update']) }, [Validators.required, Validators.maxLength(255)]],
            codice: [
                { value: null, disabled: !this.enableSection(this.section, ['update']) },
                [Validators.required]
            ],
            missione: [
                { value: null, disabled: !this.enableSection(this.section, ['update']) },
                [Validators.required]
            ]
        });
    }

    get f() {
        return this.addComponenteForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        this.addComponenteForm.updateValueAndValidity();

        let errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );
        }
        if (this.addComponenteForm.invalid || errors) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.addComponenteForm.controls) {
                if (this.addComponenteForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.addComponenteForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.addComponenteForm.get(el)["controls"]) {
                            if (this.addComponenteForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }

        const obj = this.addComponenteForm.value;
        obj.missione = this.missioni.find(x => x.id == obj.missione);
        this.pnrrService.saveComponente(obj).subscribe(res => {
            this.toastr.success("Componente salvata con successo!");
            this.refreshGrid();
            this.submitted = false;
            this.open = false;
            this.closeModal();
        }, error => {
            alert(error);
            //this.toastr.error("Codice già presente");
        })
    }

    columns = [
        {
            name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
            , resizeable: false
        }

        , {
            name: 'codice'
            , prop: 'codice'

            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 2
            , minWidth: 60

        }
        ,
        {
            name: 'missione'
            , prop: 'missione.descrizione'
            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 2
            , minWidth: 100
        },
        {
            name: 'descrizione'
            , prop: 'descrizione'
            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 3
            , minWidth: 100
        }

        , {
            name: 'modifica'
            , prop: null
            , visible: this.checkPermission('update')
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            //, width: 10
            , resizeable: false
            , draggable: false
            , flexGrow: 1
            , minWidth: 50
        }
        , {
            name: 'elimina'
            , prop: 'id'
            , visible: this.checkPermission('delete')
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60

        }
    ];

    loadData = (dataTablesParameters: any, callback: any) => {
        this.pnrrService.getAllComponenteDt(dataTablesParameters)
            .subscribe(resp => {
                callback(resp);
            });
    };

    onEdit(row) {
        this.submitted = false;
        //console.log(row);
        this.modalRef = this.modalService.show(this.templateModal, this.config);
        this.addComponenteForm.setValue({
            id: row.id,
            descrizione: row.descrizione,
            codice: row.codice,
            missione:row.missione? row.missione.id:null,
        });
    }
    loading: boolean = false;
    onDelete(row) {
        console.log(row);
        if (!confirm(`Stai per eliminare la componente "${row.descrizione}", proseguire?`)) return;
        this.loading = true;
        this.pnrrService.deleteComponente(row.id).subscribe(
            result => {
                this.loading = false;
                this.refreshGrid();
                this.toastr.success('Componente eliminata con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile eliminare la componente: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                console.log('error');
            });
    }
    closeModal() {
        if (this.modalRef)this.modalRef.hide();
        this.addComponenteForm.reset();
    }

    getMissioni() {
        this.pnrrService.getAllMissione().subscribe(result => this.missioni = result);
    }
    missioni=[];

    isAdmin(){
        return this.authService.isAdmin();
    }

  /**
   * Controlla se l'utente può accedere o meno alla sezione.
   * @param section il nome della sezione
   * @param operations le operazioni da poter effettuare sulla sezione
   * @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
   * @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
   * @returns true se il controllo è andato a buon fine, false altrimenti
  */
   enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
    return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
  }

  checkPermission(operation: string) {
    return (!this.isAdmin() && this.enableSection(this.section, [operation], false, false)) || (this.isAdmin() && this.enableSection(this.section, [operation]));
  }
}
