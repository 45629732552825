import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, map } from 'rxjs/operators';

@Component({
    selector: 'app-completer',
    templateUrl: './completer.component.html',
    styleUrls: ['./completer.component.css'],
})
export class CompleterComponent implements OnInit {

    @Input('cpOptions')
    set cpOptions(value: any) {
        this._params = {...this._params, ...value};
        this.all = this._params.items;
    }
    get cpOptions() {
        return this._params;
    }

    _disabled = false;
    @Input('disabled')
    set disabled(value:boolean){
        this._disabled = value;
        if (this.searchField){
            if (value)this.searchField.enable();
            else this.searchField.disable();
        }
    }
    get disabled():boolean{
        return this._disabled;
    }


    all = [];

    _params =
        {
            items: [],
            labelField: '',
            labelMaxLength: 100,
            ajax: null,
            placeholder:'Cerca...'
        };

    matches: any[] = [];
    text: string;
    visible = false;

    searchField: FormControl;

    @Output()
    public onSelectEmitter = new EventEmitter();


    constructor() {  
        //do nothing       
    }

    ngOnInit() {
        this.searchField = new FormControl();
        this.searchField.valueChanges
        .pipe(
            map((i: any) => i),
            debounceTime(500)
          )
        .subscribe(
            data=>{this.find(data);}
        );
     }

    find(event: any) {
        let value = event?(event.target?event.target.value:event):null;
        if (value && value.length > 2) {
            console.log(value);
            this.visible = true;

            if (this._params.ajax) {
                this._params.ajax(value, (obj: any) => this.getDataCallBack(obj));

                return;
            }


            this.matches = this.all.filter((element) =>
                element[this._params.labelField].toLowerCase().includes(value.toLowerCase())
            );
        } else {
            this.visible = false;
        }
    }

    selectedItems:any[];

    setValue(item: any) {
        if (!item.disabled) {
            if (!this.selectedItems)this.selectedItems = [];
            this.selectedItems.push(item);
            this.text = item[this._params.labelField];
            this.searchField.setValue(this.text,{emitEvent:false,emitModelToViewChange:true,emitViewToModelChange:false});
            const index = this.all.indexOf(item);
            if (index >= 0) {
                this.all.splice(index, 1);
                this.visible = false;                
            }
            this.visible = false;
            this.onSelectEmitter.emit(item);
        }
    }

    resetValue() {
        this.text = '';
        if (this.selectedItems)this.selectedItems.forEach(item=>this.all.push(item));
        this.selectedItems = [];
        
        this.searchField.setValue(this.text,{emitEvent:false});
        this.onSelectEmitter.emit(null);
    }

    getValue(): string {
        return this.searchField.value;
    }


    getDataCallBack(obj: any) {
        this.matches = obj;
    }

    truncate(str, n) {
        return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
    };

   
}
