<div class="p-5 container-xl text-center" [hidden]="loadDataCounter<=0">
    <app-loading message="Caricamento in corso..."></app-loading>
</div>

<div class="container sfondo_bianco" [hidden]="loadDataCounter>0">

    <hr />

    <!--inizio filtri-->

    <div class="row pb-3 pt-1  mx-0 mb-3 rounded" [ngClass]="{'bg-info':!flagSoggResp,'bg-0083e0':flagSoggResp}" [formGroup]="filterForm">
        <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
            <label for="mainObjectTypeFilter">
        <h6 class="mb-0 text-white bold">
          Progettualità - Fabbisogno
        </h6>
      </label>
            <ng-select title="{{ description }}" [items]="allProjects" bindValue="codice" bindLabel="label" placeholder="Seleziona progettualità/fabbisogno" formControlName="project" (change)="setProject($event)">
                <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.descrizione }}">{{ item.label }}</div>
                </ng-template>
            </ng-select>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
            <label for="importoFinanziamento">
        <h6 class="mb-0 text-white bold">
          Intervento
          <small>(min 3 caratteri)</small>
        </h6>
      </label>
            <app-completer [cpOptions]="completerParams" (onSelectEmitter)="setInterventionCompleter($event)" (keyup)="onChangeInterventionCompleter($event)">
            </app-completer>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
            <label for="importoFinanziamento">
        <h6 class="mb-0 text-white bold">
          Descrizione intervento
          <small>(min 3 caratteri)</small>
        </h6>
      </label>
            <input type="text" maxlength="200" formControlName="objectSearchText" class="form-control" placeholder="Inserisci la descrizione" />
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
            <label for="areeTematicheCombo">
        <h6 class="mb-0 text-white bold">
          Aree gestionali direzione e/o esterne
        </h6>
      </label>
            <ng-select [items]="areeTematicheCombo" bindValue="id" bindLabel="descrizione" formControlName="areeTematicheCombo" placeholder="Seleziona area gestionale direz. e/o esterna " (change)="setAreaTematicaFilter($event)">
                <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
                </ng-template>
            </ng-select>
        </div>


        <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
            <label for="tipologiaClassificazioni">
        <h6 class="mb-0 text-white bold">Tipologia Classificazioni</h6>
      </label>
            <ng-select formControlName="tipologiaClassificazioni" [items]="tipologieClassificazioni" bindValue="id" bindLabel="tipo" placeholder="Seleziona tipologia classificazioni" (change)="setTipologiaClassificazioniFilter($event)">
                <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.tipo }}">{{ item.tipo }}</div>
                </ng-template>
            </ng-select>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 pt-1">
            <label for="temiPrioritari">
        <h6 class="mb-0 text-white bold">Temi prioritari</h6>
      </label>
            <ng-select formControlName="temiPrioritari" [items]="temiPrioritariCombo" bindValue="id" bindLabel="descrizione" placeholder="Seleziona tema prioritario" (change)="setTemiPrioritario($event)">
                <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
                </ng-template>
            </ng-select>
        </div>
    </div>
    <!--fine filtri-->
    <div class="">
        <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
            <div class="row pb-3 pt-1  mx-0 mb-3 rounded border" [ngClass]="{'border-info':!flagSoggResp,'border-0083e0':flagSoggResp}">
                <div class="col-xl-12 col-lg-12 col-md-12 pt-1">
                    <label for="mainObjectTypeFilter">
            <h5 class="">
              Destinazione
            </h5>
          </label>
                    <div [ngClass]="{ 'is-invalid': submitted && f.destinationProject.errors }">
                        <ng-select title="{{ description }}" [items]="allDestinationProjects" bindValue="id" bindLabel="label" placeholder="Seleziona progettualità / fabbisogno" formControlName="destinationProject">
                            <ng-template ng-option-tmp let-item="item">
                                <div title="{{ item.descrizione }}">{{ item.label }}</div>
                            </ng-template>
                        </ng-select>

                    </div>
                    <div *ngIf="submitted && f.destinationProject.errors" class="invalid-feedback " style="display: block;">
                        <div *ngIf="f.destinationProject.errors.required">
                            Campo obbligatorio
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 mb-3">
                    <button [disabled]="loading" class="btn btn-lg btn-block f-weight-600 btn-primary" type="submit">
          <img *ngIf="loading" class="pl-3 float-right"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          Sposta elementi selezionati
        </button>
                </div>
            </div>
        </form>
    </div>
    <!--inizio tabella-->

    <div class="col-lg-6 col-sm-6 col-6 mt-5">
        <h5 for="" class="">Tabella Progettualità / Fabbisogni / Interventi</h5>
    </div>
    <div class="container">


        <spostamento-massivo-interventi-details-table-component [observable$]="observable$ | async" [tabellaDettaglioObj]="tabellaDettaglioObj" [showSelectColumn]="true" (codesSelectedChanged)="codesSelectedChanged($event)">
        </spostamento-massivo-interventi-details-table-component>


    </div>


</div>
<!--fine tabella-->