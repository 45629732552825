import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import * as olControls from "ol/control";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import GeoJSON from "ol/format/GeoJSON.js";
import Map from "ol/Map";
import Overlay from "ol/Overlay";
import * as olProj from "ol/proj";
import * as olStyle from "ol/style";
import View from "ol/View";
import * as Rainbow from "rainbowvis.js/rainbowvis.js";
import { GeoService } from "src/app/modules/web-gis/services/geo-service.service";
import { GeoService as GeoServizio } from "../../../../services";
import * as turf from "@turf/turf";
import Cluster from "ol/source/Cluster";
import { Municipality } from "src/app/models/municipality";
import { CurrencyPipe } from "@angular/common";
import { AuthService } from "src/app/services";
import { ProgettoService } from "../../services";
import domtoimage from 'dom-to-image';
import { DashboardPnrrNonDisegnabileService } from "../dashboard-pnrr-non-disegnabile/dashboard-pnrr-non-disegnabile.service";
interface LayerAttribution {
  url: string;
  text1: string;
  text2: string;
}


@Component({
  selector: "app-mappa-non-disegnabile",
  templateUrl: "./mappa-non-disegnabile.component.html",
  styleUrls: ["./mappa-non-disegnabile.component.css"],
})
export class MappaNonDisegnabileComponent implements OnInit, AfterViewInit {
  @ViewChild("popup") popup: ElementRef;

  @ViewChild("filterCollapseBtn") filterCollapseBtn: ElementRef;

  layerComuni: string = `comuni_lazio`;
  layerProvince: string = `province_lazio_2020`;
  layerAsl: string = `asl_lazio`;
  titleProvince = { label: `Province`, points: "points-prov", extended: "expanded-prov" };
  titleComuni = { label: `Comuni`, points: "points-muni", extended: "expanded-muni" };
  titleAsl = { label: `Asl`, points: "points-asl", extended: "expanded-asl" };
  map: any;

  checkedProv = "";
  checkedMuni = "checked";
  checkedAsl= "";

  opacityOutput = '1.00';

  showLoading: boolean = false;


  getCluster(mySource){
    return   new Cluster({
      distance: 50,
      minDistance: 5,
      source: mySource,
    });
  }

  baseMapsLayers = [];

  attribution: LayerAttribution;
  statusMode = true;
  showIcon = false;

  filter = null;

  allNonLocalizzati = 0;
  currentNonLocalizzati = 0
  arrayAllNonLocalizzati = [];

  totalNumTipologies = 0;
  @Output() risultatoTotale = new EventEmitter<number>();
  @Output() chartsFeatures = new EventEmitter<any>();
  @Output() resetFiltersOnLayerChange = new EventEmitter<any>();

  @Output() interventiNonLocalizzati = new EventEmitter<number>();

  chooseBasemap($event) {
    const oldMap = this.geoService.getLayerByTitle(this.map, "basemap");
    let newMap;

    this.geoService.setZindexToLayer(this.map, "Province", 33);
    switch ($event.code) {
        case "carto":
            this.map.removeLayer(oldMap);
            this.map.addLayer(this.geoService.getCartoDbLayer());
            this.attribution = {
                url: "https://www.openstreetmap.org/copyright",
                text1: "© OpenStreetMap",
                text2: "contributors.",
            };
            break;
        case "gstreet":
            this.map.removeLayer(oldMap);
            this.map.addLayer(this.geoService.getGoogleLayer("street"));
            this.attribution = {
                url: "https://maps.google.it",
                text1: "© Google",
                text2: "",
            };
            break;
        case "gsat":
            this.map.removeLayer(oldMap);
            this.map.addLayer(this.geoService.getGoogleLayer("sat"));
            this.attribution = {
                url: "https://maps.google.it",
                text1: "© Google",
                text2: "",
            };
            break;
        case "gterrain":
            this.map.removeLayer(oldMap);
            this.map.addLayer(this.geoService.getGoogleLayer("terrain"));
            this.attribution = {
                url: "https://maps.google.it",
                text1: "© Google",
                text2: "",
            };
            break;
        case "ghybrid":
            this.map.removeLayer(oldMap);
            this.map.addLayer(this.geoService.getGoogleLayer("hybrid"));
            this.attribution = {
                url: "https://maps.google.it",
                text1: "© Google",
                text2: "",
            };
            break;
        /* case "toner":
            this.map.removeLayer(oldMap);
            this.map.addLayer(this.geoService.getStamenLayer("toner"));
            this.attribution = {
                url: "http://maps.stamen.com",
                text1: "© Stamen",
                text2: "",
            };
            break;
        case "watercolor":
            this.map.removeLayer(oldMap);
            this.map.addLayer(this.geoService.getStamenLayer("watercolor"));
            this.attribution = {
                url: "http://maps.stamen.com",
                text1: "© Stamen",
                text2: "",
            };
            break; */
    }
}



  allDataInTheMap = {
    provinces: [],
    municipalities: [],
    asl: [],
  };

  layers = [ this.titleComuni, this.titleProvince, this.titleAsl];

  popupOverlay: Overlay;
  popupHidden = true;
  popupSelectedLayer: string;
  popUpInfo = {
    soggetto: {identificativo: '', nome: ''},
    missioni: [],
    totaliMissioni: {interventi: 0, importi: 0},
    tipologie: [],
    totaliTipologie: {interventi: 0, quantita: 0}
  };

  isPopupActive = true;

  rainbow: Rainbow;
  values = [];

  maxTotProvince: Number = 0;
  maxTotMunicipalities: Number = 0;
  maxTotAsl: Number = 0;

  sourceSRS = "EPSG:3857";
  destSRS = "EPSG:4326";

  styleCache: any = [];
  styleLength: any = [];

  numberOfcolors: number = 256;
  colorsRange: any[] = [];
  totalElements: number = 0;

  conditions = [];

  filtersObject = {
    provinceCode: null,
    municipalityCode: null,
    natureCode: null,
    tipologyCode: null,
    detailCode: null,
    pnrrMissionCode: null,
    pnrrComponentCode: null,
    pnrrLineCode: null,
  };

  @Input() set filtersSubmitted(value: any) {
    this.conditions = value;//value.map((x) => x.condition);
    if(this.conditions && this.conditions.length==0){
      this.currentNonLocalizzati = this.allNonLocalizzati;
    }

    this.closeMapPopup();
    this.search();
  }

  @Input() isMenuFiltroShown: boolean = false;

  @Input() set filtersActive(value){
    this.getAllQuantityInLoc(value);
  }

  _cntLocalizzatiAltrove = 0;
  _localizzatiAltrove = [];
  @Input() set cntLocalizzatiAltrove(value){
    this._cntLocalizzatiAltrove = value;
    console.log('this._cntLocalizzatiAltrove',this._cntLocalizzatiAltrove);
  }
  @Input() set localizzatiAltrove(value){
    this._localizzatiAltrove = value;
    console.log('this._localizzatiAltrove',this._localizzatiAltrove);
  }

  get hasFilterProvince(){
    return this.conditions?.find((x) => x.name == 'cod_prov') && ! this.conditions?.find((x) => x.name == 'pro_com_t');
  }

  get hasFilterMunicipality(){
    return this.conditions?.find((x) => x.name == 'pro_com_t');
  }


  //layers risultati
  myResults = [];
  polygonResults = [];
  //layers pallini
  pointsMuniLayer;
  pointsProvLayer;
  pointsAslLayer;

  //layers poligoni
  allMuniLayerFeatures = [];
  allProvLayerFeatures = [];
  allAslLayerFeatures = [];

  allInterventiPoints = [];
  resetPointsMuni = [];
  resetPointsProv = [];
  resetPointsAsl = [];

  quantitaPerProvincia = [];
  quantitaPerComune = [];

  clusteredLayer;

  readyProv = false;
  readyMuni = false;

  currentMode = 'comune';

  @Output() changeModeEvent = new EventEmitter<string>();



  constructor(
    private geoService: GeoService,
    private geoServizio: GeoServizio,
    private projectService: ProgettoService
    , private authService: AuthService
    ,private service:DashboardPnrrNonDisegnabileService
  ) {}


  ngOnInit(): void {
    this.map = this.getMap(null, null, null);

    this.getProvinces();
    this.getMunicipalities();
    this.getAllQuantityInLoc(this.filtersObject);
    // this.getAsl();
    this.baseMapsLayers = [
      { title: "CartoDb", code: "carto" },
      { title: "Google Strade", code: "gstreet" },
      { title: "Google Satellite", code: "gsat" },
      { title: "Google Terreno", code: "gterrain" },
      { title: "Google Ibrido", code: "ghybrid" },
      //{ title: "Stamen Toner", code: "toner" },
      //{ title: "Stamen Watercolor", code: "watercolor" },
    ];
  }

  ngAfterViewInit(): void {
    this.mapEventsInitializer();
    this.map.addLayer(this.geoService.getCartoDbLayer());
  }

  getProvinces() {
    this.geoServizio.getAllGeoProvinces().subscribe((res) => {
      this.arrayAllNonLocalizzati = res.map(r => r['localizzazioneQty']);
      this.allNonLocalizzati = this.arrayAllNonLocalizzati.reduce((a,b) => a+b, 0);

      this.allDataInTheMap.provinces = this.groupBy(res, "code");
      this.currentNonLocalizzati = this.allNonLocalizzati;
      this.addLayerProvince();
    });
  }

  getMunicipalities() {
    this.geoServizio.getAllGeoMunicipalities().subscribe((res) => {
      this.arrayAllNonLocalizzati = res.map(r => r['localizzazioneQty']);
      this.allNonLocalizzati = this.arrayAllNonLocalizzati.reduce((a,b) => a+b, 0);

      this.allDataInTheMap.municipalities = this.groupBy(res, "code");
      this.currentNonLocalizzati = this.allNonLocalizzati;
      // this.interventiNonLocalizzati.emit(this.allNonLocalizzati);
      this.addLayerComuni();
    });
  }

  getAllQuantityInLoc(filters){
    this.quantitaPerProvincia = [];
    this.quantitaPerComune = [];
    this.geoServizio.getAllQuantityInLocations(filters).subscribe((res)=>{

      for(let x of res){

        // Calcolo quantita localizzazioni per provincia
        const keeperProv = this.quantitaPerProvincia.find(p => p.provincia == x.nome);
        if(this.quantitaPerProvincia.length == 0 || !keeperProv){
          this.quantitaPerProvincia.push({
            provincia: x.nome,
            qtyPerType: [{
                descrizione: x.descrizione,
                quantita: x.quantitaTipologie
            }]
          });
        }
        else if(keeperProv){
          let qtyArray = keeperProv.qtyPerType;

          const qtyKeeper = qtyArray.find(q => q.descrizione == x.descrizione);
          if(qtyKeeper)
            qtyKeeper.quantita += x.quantitaTipologie;
          else{
            qtyArray.push({
              descrizione: x.descrizione,
              quantita: x.quantitaTipologie
            })
          }
        }

        // Calcolo quantita localizzazioni per comune
        const keeperMuni = this.quantitaPerComune.find(c => c.comune == x.code);
        if(this.quantitaPerComune.length == 0 || !keeperMuni){
          this.quantitaPerComune.push({
            comune: x.code,
            qtyPerType: [{
                descrizione: x.descrizione,
                quantita: x.quantitaTipologie
            }]
          });
        }
        else{
          let qtyArray = keeperMuni.qtyPerType;

          const qtyKeeper = qtyArray.find(q => q.descrizione == x.descrizione);
          if(qtyKeeper)
            qtyKeeper.quantita += x.quantitaTipologie;

          else{
            qtyArray.push({
              descrizione: x.descrizione,
              quantita: x.quantitaTipologie
            })
          }
        }
      }

    })
  }

  getAsl() {
    this.geoServizio.getAllGeoAsl().subscribe((res) => {
      this.allDataInTheMap.asl = this.groupBy(res, "code");
      this.addLayerAsl();
    });
  }

  getMap(center: number[], zoom: number, controls: any) {
    return new Map({
      target: "map",
      controls: controls
        ? controls
        : olControls.defaults({
            attribution: false,
            zoom: true,
            rotate: false,
          }),
      view: new View({
        // extent: [
        //   1089033.260000294, 4913779.4862301685, 1693954.0098315454,
        //   5369272.358529974,
        // ],
        center: olProj.fromLonLat(center ? center : [12.5, 41.82]),
        zoom: zoom ? zoom : 8.7,
      }),
    });
  }

  styleFunction(layerTitle, length, feature) {
    const amount = feature.values_["pnrr"].find(
      (f) => f.descrizione == "Totale"
    ).quantita;
    let color = "#FFF";
    for (let i = 0; i < this.colorsRange.length; i++) {
      if (amount <= this.colorsRange[i].maxValue) {
        color = this.colorsRange[i].color;
        break;
      }
      if (amount >= this.colorsRange[this.colorsRange.length - 1].maxValue) {
        color = this.colorsRange[this.colorsRange.length - 1].color;
        break;
      }
    }

    return new olStyle.Style({
      stroke: new olStyle.Stroke({
        color: "#808080",
        width: 1,
      }),
      fill: new olStyle.Fill({
        color: `#${color}`,
      }),
    });
  }

  addFeaturesProperties(geoJson: any, layerTitle) {
    const points = [];

    geoJson.features.forEach((f) => {
      let interventi;
      if(layerTitle == "Province")
        interventi = this.allDataInTheMap.provinces[f.properties.cod_prov];
      else if(layerTitle == "Comuni")
        interventi = this.allDataInTheMap.municipalities[f.properties.pro_com_t];
      // else
      //   interventi = this.allDataInTheMap.asl[f.properties.codice];

      let keyProperties = [];
      if (interventi)
        keyProperties = interventi.map((item) => {
         return {
            descrizione: item.descrizione,
            quantita: item.numInterventi,
            codice: item.code,
            naturaCode: item.naturaCode,
            tipologiaCode: item.tipologiaCode,
            dettaglio: item.dettaglio,
            pnrrLinea: item.pnrrLinee,
            pnrrMissione: item.pnrrMissioni,
            pnrrComponente: item.pnrrComponenti,
            importo: item.importo,
            localizzazioneQty: item.localizzazioneQty,
            quantitaTipologie: item.quantitaTipologie
          };
        });
      let tot;
      let nature = [], tipologie = [], dettagli = [], pnrrLinea = [], pnrrMissione = [], pnrrComponente = [], importo = [], localizzazioneQty = [], quantitaTipologie = [];

      if(layerTitle == "Province"){
        tot = keyProperties.filter((x) => x.codice == f.properties.cod_prov).map((x) => x.quantita).reduce((a, b) => a + b, 0);
        keyProperties.filter((x) => x.codice == f.properties.cod_prov).map(x=>{
          tipologie.push(x.tipologiaCode);
          nature.push(x.naturaCode);
          dettagli.push(x.dettaglio);
          pnrrLinea.push(x.pnrrLinea);
          pnrrMissione.push(x.pnrrMissione);
          pnrrComponente.push(x.pnrrComponente);
          importo.push(x.importo);
          localizzazioneQty.push(x.localizzazioneQty);
          quantitaTipologie.push(x.quantitaTipologie);
        })
      }
      else if(layerTitle == "Comuni"){
        tot = keyProperties.filter((x) => x.codice == f.properties.pro_com_t).map((x) => x.quantita).reduce((a, b) => a + b, 0);
        keyProperties.filter((x) => x.codice == f.properties.pro_com_t).map(x=>{
          tipologie.push(x.tipologiaCode);
          nature.push(x.naturaCode);
          dettagli.push(x.dettaglio);
          pnrrLinea.push(x.pnrrLinea);
          pnrrMissione.push(x.pnrrMissione);
          pnrrComponente.push(x.pnrrComponente);
          importo.push(x.importo);
          localizzazioneQty.push(x.localizzazioneQty);
          quantitaTipologie.push(x.quantitaTipologie);
        })
      }
      // else {
      //   tot = keyProperties.filter((x) => x.codice == f.properties['codice']).map((x) => x.quantita).reduce((a, b) => a + b, 0);
      //   keyProperties.filter((x) => x.codice == f.properties['codice']).map(x=>{
      //     tipologie.push(x.tipologiaCode);
      //     nature.push(x.naturaCode);
      //     dettagli.push(x.dettaglio);
      //     pnrrLinea.push(x.pnrrLinea);
      //     pnrrMissione.push(x.pnrrMissione);
      //     pnrrComponente.push(x.pnrrComponente);
      //     importo.push(x.importo);
      //   })
      // }

      keyProperties.push({ descrizione: "Totale", quantita: tot });
      f.properties[`pnrr`] = keyProperties;

      if(layerTitle == "Province")
        if (this.maxTotProvince < tot) this.maxTotProvince = tot;
      else if(layerTitle == "Comuni")
        if (this.maxTotMunicipalities < tot) this.maxTotMunicipalities = tot;
      else
        if (this.maxTotAsl < tot) this.maxTotAsl = tot;

      geoJson.features.sort((f1, f2) => {
        return (
          f1.properties["pnrr"] &&
            f2.properties["pnrr"] &&
            f1.properties.pnrr.find((x) => x.descrizione == "Totale").quantita -
              f2.properties.pnrr.find((x) => x.descrizione == "Totale")
                .quantita,
          0
        );
      });

      const natureArray = Array.from(new Set(nature));
      const tipologieArray = Array.from(new Set(tipologie));
      const dettagliArray = Array.from(new Set(dettagli));
      const pnrrLineaArray = Array.from(new Set(pnrrLinea));
      const pnrrMissioneArray = Array.from(new Set(pnrrMissione));
      const pnrrComponenteArray = Array.from(new Set(pnrrComponente));
      const importoArray = Array.from(new Set(importo));
      const localizzazioneQtyArray = Array.from(new Set(localizzazioneQty));
      const quantitaTipologieArray = Array.from(new Set(quantitaTipologie));

      f.properties[`nature`] = natureArray;
      f.properties[`tipologie`] = tipologieArray;
      f.properties[`dettagli`] = dettagliArray;
      f.properties[`pnrrLinea`] = pnrrLineaArray;
      f.properties[`pnrrMissione`] = pnrrMissioneArray;
      f.properties[`pnrrComponente`] = pnrrComponenteArray;
      f.properties[`importo`] = importoArray;
      f.properties[`localizzazioneQty`] = localizzazioneQtyArray;
      f.properties[`quantitaTipologie`] = quantitaTipologieArray;

      const point = turf.pointOnFeature(f);

      if(layerTitle == "Province"){
        point.properties["titolo"] = f.properties.den_prov;
        point.properties["cod_prov"] = f.properties.cod_prov;
      }
      else if(layerTitle == "Comuni"){
        point.properties["titolo"] = f.properties.comune;
        point.properties["cod_prov"] = f.properties.cod_prov;
        point.properties["pro_com_t"] = f.properties.pro_com_t;
      }
      // else {
      //   point.properties["titolo"] = f.properties['nome asl'];
      //   point.properties['codice'] = f.properties['codice'];
      // }
      point.properties["totale"] = tot;
      point.properties["pnrr"] = f.properties[`pnrr`];
      point.properties["nature"] = natureArray;
      point.properties["tipologie"] = tipologieArray;
      point.properties["dettagli"] = dettagliArray;
      point.properties["pnrrLinea"] = pnrrLineaArray;
      point.properties["pnrrMissione"] = pnrrMissioneArray;
      point.properties["pnrrComponente"] = pnrrComponenteArray;
      point.properties["importo"] = importoArray;
      point.properties["localizzazioneQty"] = localizzazioneQtyArray;
      point.properties["quantitaTipologie"] = quantitaTipologieArray;

      points.push(point);
    });

    const geo = {
      type: "FeatureCollection",
      features: points,
      totalFeatures: points.length,
    };
    return geo;
  }

  addLayer(layerName: string, layerTitle: string, layerVisibility: boolean) {
    let points = {};
    this.geoService
      .getGeoJson(`inframob:${layerName}`, `EPSG:4326`, undefined, null)
      .subscribe((geoJson) => {
        let setPolygonsStyle = this.styleFunction.bind(
          this,
          layerTitle,
          geoJson.features.length
        );
        if (layerTitle == this.titleProvince.label) {
          this.createProvincelayers(
            geoJson,
            layerTitle,
            setPolygonsStyle,
            layerVisibility
          );
        } else if(layerTitle == this.titleComuni.label){
          this.createMunicipalityLayers(
            geoJson,
            layerTitle,
            setPolygonsStyle,
            layerVisibility
          );
        }
        // else if(layerTitle == this.titleAsl.label){
        //   this.createAslLayers(
        //     geoJson,
        //     layerTitle,
        //     setPolygonsStyle,
        //     layerVisibility
        //   );
        // }
      });
  }

  createColorsRange(features) {
    this.colorsRange = [];
    if(features.length <= 0) return;
    //console.log(features);

    const elems = features
      .map((f) => f.properties["pnrr"].find((f) => f.descrizione == "Totale"))
      .map((f) => f.quantita);
    //this.totalElements = features.map(f => f.properties['pnrr'].find(f => f.descrizione == 'Totale')).map(f=> f.quantita).reduce((a,b) => a+b, 0);
    this.totalElements = elems.length > 0 ? Math.max(...elems) : 0;
    const min = elems.length > 0 ? Math.min(...elems) : 0;
    this.numberOfcolors = features.length; //FORMULA
    const interval = 100 / this.numberOfcolors;
    let rainbow = new Rainbow();
    rainbow.setNumberRange(
      1,
      this.numberOfcolors <= 1 ? 2 : this.numberOfcolors
    );
    let values = ["#f1f9fd", "#002742"];
    rainbow.setSpectrum(...values);
    let index = 0;

    for (let i = interval; i <= 100; i += interval) {
      const value = +(this.totalElements * (i / 100)).toFixed(0);
      const minValue = this.colorsRange[index-1] ? this.colorsRange[index-1].maxValue: min
      index++;
      this.colorsRange.push({
        color:  this.numberOfcolors > 1 ? rainbow.colourAt(index) : '002742',
        maxValue: value,
        minValue: minValue
      });
    }
    this.colorsRange[this.colorsRange.length-1].maxValue = this.totalElements;
    //console.log(this.colorsRange);
  }

  showLayer(layerTitle: string) {
    this.geoService.showLayerByTitle(this.map, layerTitle);
  }
  hideLayer(layerTitle: string) {
    this.geoService.hideLayerByTitle(this.map, layerTitle);
  }
  addLayerProvince() {
    this.addLayer(this.layerProvince, this.titleProvince.label, false);
  }
  addLayerComuni() {
    this.addLayer(this.layerComuni, this.titleComuni.label, true);
  }
  addLayerAsl() {
    this.addLayer(this.layerAsl, this.titleAsl.label, false);
  }
  changeLayer(choice: any) {
    const writer = new GeoJSON();
    if (choice == null || choice == this.titleProvince.label) {
      this.currentMode = 'provincia';
      this.changeModeEvent.emit(this.currentMode );
      const jsonFeats = writer.writeFeaturesObject(this.allProvLayerFeatures);
      this.createColorsRange(jsonFeats.features);
      this.showLayer(this.titleProvince.label);
      this.showLayer(this.titleProvince.points);
      this.hideLayer(this.titleAsl.label);
      this.hideLayer(this.titleAsl.points);
      this.hideLayer(this.titleComuni.label);
      this.hideLayer(this.titleComuni.points);

      this.checkedProv = "checked";
      this.checkedMuni = "";
      this.checkedAsl = "";

    } else if (choice == this.titleComuni.label) {
      this.currentMode = 'comune';
      this.changeModeEvent.emit(this.currentMode );
      const jsonFeats = writer.writeFeaturesObject(this.allMuniLayerFeatures);
      this.createColorsRange(jsonFeats.features);
      this.showLayer(this.titleComuni.label);
      this.showLayer(this.titleComuni.points);
      this.hideLayer(this.titleAsl.label);
      this.hideLayer(this.titleAsl.points);
      this.hideLayer(this.titleProvince.label);
      this.hideLayer(this.titleProvince.points);

      this.checkedProv = "";
      this.checkedMuni = "checked";
      this.checkedAsl = "";
    }else{
      this.currentMode = 'asl';
      this.changeModeEvent.emit(this.currentMode );
      const jsonFeats = writer.writeFeaturesObject(this.allAslLayerFeatures);
      this.createColorsRange(jsonFeats.features);
      this.showLayer(this.titleAsl.label);
      this.showLayer(this.titleAsl.points);
      this.hideLayer(this.titleComuni.label);
      this.hideLayer(this.titleComuni.points);
      this.hideLayer(this.titleProvince.label);
      this.hideLayer(this.titleProvince.points);

      this.checkedProv = "";
      this.checkedMuni = "";
      this.checkedAsl = "checked";
    }

    this.resetFiltersOnLayerChange.emit(this.polygonResults);
  }

  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  mapEventsInitializer(){
    this.setPopup();
    this.mapRendering();
  }

  setPopup() {
    this.popupOverlay = new Overlay({
      element: this.popup.nativeElement,
      offset: [9, 9],
      autoPan: true,
      autoPanAnimation: {
        duration: 1000,
      },
    });
    this.map.addOverlay(this.popupOverlay);
    this.map.on("click", (event) => {
      this.popup.nativeElement.hidden = true;
      this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
        if (layer && layer.get("title") && this.isPopupActive) {
          this.showPopupInfo(feature, layer, event.coordinate);
          return feature;
        }
      });
    });

  }

  mapRendering(){
    if(!this.showLoading){
      this.showLoading = true;
      this.service.doRendering(true);

    }
    this.map.once('rendercomplete', ()=> {
      if(this.showLoading){
        this.showLoading = false;
        this.service.doRendering(false);
      }
    });
  }

  showPopupInfo(feature, layer, coordinate) {
    // Reset dei dati del precedente popUp selezionato
    this.popUpInfo.missioni = [];
    this.popUpInfo.tipologie = [];
    this.popUpInfo.soggetto.identificativo = '';
    this.popUpInfo.soggetto.nome = '';
    this.popUpInfo.totaliMissioni.interventi = 0;
    this.popUpInfo.totaliMissioni.importi = 0;
    this.popUpInfo.totaliTipologie.interventi = 0;
    this.popUpInfo.totaliTipologie.quantita = 0;

    let qty = [];
    // Prendo il nome della provincia selezionata
    if (layer.values_.title == this.titleProvince.label) {
      this.popUpInfo.soggetto.identificativo = 'Provincia';
      this.popUpInfo.soggetto.nome = feature.values_.den_uts;

      qty = this.quantitaPerProvincia.find(x=>x[this.popUpInfo.soggetto.identificativo.toLowerCase()]==feature.values_['pnrr'][0].codice).qtyPerType;
    }

    // Prendo il nome del comune selezionata
    else if(layer.values_.title == this.titleComuni.label){
      this.popUpInfo.soggetto.identificativo = 'Comune';
      this.popUpInfo.soggetto.nome = feature.values_.comune;

      qty = this.quantitaPerComune.find(x=>x[this.popUpInfo.soggetto.identificativo.toLowerCase()]==feature.values_['pnrr'][0].codice).qtyPerType;

    }

    // Prendo il nome della asl selezionata
    else if(layer.values_.title == this.titleAsl.label){
      this.popUpInfo.soggetto.identificativo = 'Asl';
      this.popUpInfo.soggetto.nome = feature.values_["nome asl"];
    }


    // Calcolo per missione il numero interventi e la somma degli importi
    const groupedByMission =this.groupBy(feature.values_.pnrr, "pnrrMissione");
    const amounts = [];
    const missionTag = ['M1', 'M2', 'M3', 'M4', 'M5', 'M6']
    // inseriti importi e quantita missioni per missione
    for(let  key  of missionTag){
      if(key && key !== 'undefined'){
        const totaleQuantita =  groupedByMission[key] ? groupedByMission[key].map(g => g.quantita).reduce( (a,b) => a+b,0) : 0;
        const totaleImporti = groupedByMission[key] ? groupedByMission[key].map(g => g.importo).reduce( (a,b) => a+b,0) : 0;
        amounts.push({key,totaleQuantita,totaleImporti});
      }
    }
    // Ordino i dati da M1 a M6
    amounts.sort( (a,b) => a.key < b.key ? -1 : 1);

    // Inserisco i dati nell'oggetto che li mostra a video popUpInfo.missioni
    for(let mission of amounts){
      this.popUpInfo.missioni.push({codice: mission.key, interventi: mission.totaleQuantita, risorse: mission.totaleImporti});
    }

    // Calcolo e inserimento per tipologia del numero interventi e della somma delle quantita nelle localizzazioni
    feature.values_.pnrr.forEach((item) => {
      if(item && item !== undefined && item.descrizione != 'Totale'){
        const keeper = this.popUpInfo.tipologie.find(x=> x.descrizione == item.descrizione);
        if(keeper){
          keeper.quantita += item.quantita;
        }
        else
          this.popUpInfo.tipologie.push({descrizione: item.descrizione, quantita: item.quantita})
      }
    });

    for(let tipoQty of qty){
      const fTipo = this.popUpInfo.tipologie.find(x=> x.descrizione == tipoQty.descrizione);
      if(fTipo)
        fTipo['quantitaLoc'] = tipoQty.quantita;
      else{
          this.popUpInfo.tipologie.push({descrizione: tipoQty.descrizione, quantita: 0, quantitaLoc: tipoQty.quantita});
      }
    }

    // Calcolo totali missioni
    this.popUpInfo.missioni.map(x=>{
      this.popUpInfo.totaliMissioni.interventi += x.interventi;
      this.popUpInfo.totaliMissioni.importi += x.risorse;
    })

    // Calcolo totali tipologie
    this.popUpInfo.tipologie.map(x=>{
      this.popUpInfo.totaliTipologie.interventi += x.quantita;
      this.popUpInfo.totaliTipologie.quantita += x.quantitaLoc;
    })

    this.popup.nativeElement.hidden = false;

    this.popupOverlay.setPosition(coordinate);
    return true;
  }

  clusteredStyle = (feature: any) => {
    const size = feature
      .get("features")
      .map((f) => f.values_["totale"])
      .reduce((a, b) => a + b, 0);
    let style = this.styleCache[size];
    let oldLen = this.styleLength[size];
    const length = feature.values_['features'] && feature.values_['features'].length ? feature.values_['features'].length : 1;
    const color = "#0083E0";
    const colorBord = "#0083E080";
    const sizeBorder = length <= 1 ? 0 : 11;
    if (!style || (oldLen && oldLen != length)) {
      const radius = 14 + size / 69;
      const value = radius > 45 ? 45 : radius;
      style = new olStyle.Style({
        image: new olStyle.Circle({
          radius: value,
          stroke: new olStyle.Stroke({
            color: colorBord,
            width: sizeBorder,
          }),
          fill: new olStyle.Fill({
            color: color,
          }),
        }),
        text: new olStyle.Text({
          text: size.toString(),
          font: "bold 12px Arial, Verdana, Helvetica, sans-serif",
          fill: new olStyle.Fill({
            color: "#fff",
          }),
        }),
      });
      this.styleCache[size] = style;
      this.styleLength[size] = length;
    }
    return style;
  };

  createClusteredLayerFromGeoJson(
    map: any,
    geoJSON: any,
    title: string,
    style: any,
    minZoom: number,
    visible: boolean,
    isForDrawing: boolean
  ) {
    const geoJson = geoJSON;

    const features = new GeoJSON({
      featureProjection: "EPSG:3857",
    }).readFeatures(geoJson);
    const source = new VectorSource({
      features: features,
    });

    const clusterSource =this.getCluster(source);

    let that = this;

    const layer = new VectorLayer({
      title: title,
      source: clusterSource,
      visible: visible,
      style: that.clusteredStyle,
    });

    this.map.addLayer(layer);

  }




  search() {
    if (this.conditions.length > 0) {
      switch(this.currentMode){
        case 'comune':
          this.searchMuni();
          break;
        case 'provincia':
          this.searchProv();
          break;
        case 'asl':
          // this.searchAsl();
          break;
      }
    } else {
      this.resetMap();
    }

  }

  getNonLocalizzati(){
    return this.currentNonLocalizzati;
  }
  geTotalNumTipologies(){
    return this.totalNumTipologies;
  }

  get totaleInterventi(){
    return this.currentNonLocalizzati + this.totalNumTipologies;
  }

  searchMuni() {
    this.resetMuni();
    this.searchMuniPolygon();
    this.searchMuniPoints();
  }

  searchProv(){
    this.resetProv();
    this.searchProvPolygon();
    this.searchProvPoints();
  }

  searchAsl(){
    this.resetAsl();
    this.searchAslPolygon();
    this.searchAslPoints();
  }

  resetMap() {
    if (this.allMuniLayerFeatures.length > 0) {
      switch(this.currentMode){
        case 'comune':
          this.resetMuni();
          break;
        case 'provincia':
          this.resetProv();
          break;
        case 'asl':
          this.resetAsl();
          break
      }
    }
  }

  createProvincelayers(geoJson, layerTitle, style, layerVisibility) {
    //crea un geoJson di punti di province  con le info aggiunte
    const geoJsonPoints = this.addFeaturesProperties(geoJson, layerTitle);

    //layer di pallini NON clusterizzati (uno per provincia)
    this.pointsProvLayer = this.geoService.createLayerFromGeoJson(
      this.map,
      geoJsonPoints,
      "expanded-prov",
      undefined,
      5,
      false,
      false
    );

    this.allInterventiPoints = [...this.pointsProvLayer.getSource().getFeatures()];

    for(let i of this.allInterventiPoints){
      const clone = i.clone();
      this.resetPointsProv.push(clone)
    }

    //layer di pallini clusterizzati (uno per provincia)
    this.clusteredLayer = this.geoService.createClusteredLayerFromGeoJson(
      this.map,
      geoJsonPoints,
      "points-prov",
      this.clusteredStyle,
      null,
      layerVisibility,
      false
    );
    this.geoService.setZindexToLayer(this.map, "points-prov", 77);

    //layer di poligoni
    const layer = this.geoService.createLayerFromGeoJson(
      this.map,
      geoJson,
      layerTitle,
      style, //poligonStyle
      null,
      layerVisibility,
      false
    );

    this.allProvLayerFeatures = [...layer.getSource().getFeatures().map(f => f.clone())];
    this.chartsFeatures.emit(layer.getSource().getFeatures());
  }

  createMunicipalityLayers(geoJson, layerTitle, style, layerVisibility) {
    //crea un geoJson di punti di comuni  con le info aggiunte
    const geoJsonPoints = this.addFeaturesProperties(geoJson, layerTitle);

    //layer di pallini NON clusterizzati (uno per comune)
    this.pointsMuniLayer = this.geoService.createLayerFromGeoJson(
      this.map,
      geoJsonPoints,
      "expanded-muni",
      undefined,
      null,
      false,
      false
    );

    this.allInterventiPoints = [...this.pointsMuniLayer.getSource().getFeatures()];

    for(let i of this.allInterventiPoints){
      const clone = i.clone();
      this.resetPointsMuni.push(clone)
    }

    //layer di pallini clusterizzati (uno per comune)
    this.geoService.createClusteredLayerFromGeoJson(
      this.map,
      geoJsonPoints,
      "points-muni",
      this.clusteredStyle,
      null,
      layerVisibility,
      false
    );
    this.geoService.setZindexToLayer(this.map, "points-muni", 77);

    this.createColorsRange(geoJsonPoints.features);

    //layer di poligoni
    const layer = this.geoService.createLayerFromGeoJson(
      this.map,
      geoJson,
      layerTitle,
      style, //poligon
      null,
      layerVisibility,
      false
    );
    this.allMuniLayerFeatures = [...layer.getSource().getFeatures().map(f => f.clone())];
    this.calcolateTotal([...this.allMuniLayerFeatures.map(f => f.clone())]);
    this.chartsFeatures.emit(layer.getSource().getFeatures());
  }

  createAslLayers(geoJson, layerTitle, style, layerVisibility) {
    //crea un geoJson di punti di comuni  con le info aggiunte
    const geoJsonPoints = this.addFeaturesProperties(geoJson, layerTitle);

    //layer di pallini NON clusterizzati (uno per comune)
    this.pointsAslLayer = this.geoService.createLayerFromGeoJson(
      this.map,
      geoJsonPoints,
      "expanded-asl",
      undefined,
      null,
      false,
      false
    );

    this.allInterventiPoints = [...this.pointsAslLayer.getSource().getFeatures()];

    for(let i of this.allInterventiPoints){
      const clone = i.clone();
      this.resetPointsAsl.push(clone)
    }


    //layer di pallini clusterizzati (uno per comune)
    this.geoService.createClusteredLayerFromGeoJson(
      this.map,
      geoJsonPoints,
      "points-asl",
      this.clusteredStyle,
      null,
      layerVisibility,
      false
    );
    this.geoService.setZindexToLayer(this.map, "points-asl", 77);

    //layer di poligoni
    const layer = this.geoService.createLayerFromGeoJson(
      this.map,
      geoJson,
      layerTitle,
      style, //poligon
      null,
      layerVisibility,
      false
    );
    this.allAslLayerFeatures = [...layer.getSource().getFeatures()];
    this.chartsFeatures.emit(layer.getSource().getFeatures());
  }

  //funzioni comune
  searchMuniPolygon(){
    this.polygonResults = [];
    const muniLayer = this.geoService.getLayerByTitle(
      this.map,
      this.titleComuni.label
    );
    const featuresMuni = muniLayer.getSource().getFeatures().map(f => f.clone());
    featuresMuni.forEach(
      (elem) =>
        this.conditions.filter((f) => f.condition(elem) === true).length ===
          this.conditions.length && this.polygonResults.push(elem.clone())
    );

    const naturaCondition = this.conditions.find(c => c.name === 'nature')
    if(naturaCondition){
      for(let p of this.polygonResults){
        p.values_.pnrr = p.values_.pnrr.filter( f => f.naturaCode == naturaCondition.key)
      }
    }
    this.calcolateTotal(this.polygonResults);

    let mySource = new VectorSource({
      features: this.polygonResults,
    });
    muniLayer.setSource(mySource);
    let setPolygonsStyle = this.styleFunction.bind(
      this,
      "Comuni",
      featuresMuni.length
    );

    //Calcolo non localizzati
    this.currentNonLocalizzati = this.polygonResults.map(x=> x.values_['localizzazioneQty'][0]).reduce((a,b) => a+b, 0);
    this.interventiNonLocalizzati.emit(this.currentNonLocalizzati);

    muniLayer.setStyle(setPolygonsStyle);
    this.polygonResults.length > 0 && this.geoService.zoomToBoundaries(this.map, muniLayer);
    this.chartsFeatures.emit(this.polygonResults);
  }

  searchMuniPoints(){
    this.myResults = [];
    const muniLayer = this.pointsMuniLayer;

    const featuresMuni = muniLayer.getSource().getFeatures();
    featuresMuni.forEach(
      (elem) =>
        this.conditions.filter((f) => f.condition(elem) === true).length ===
          this.conditions.length && this.myResults.push(elem.clone())
    );

    const naturaCondition = this.conditions.find(c => c.name === 'nature')
    if(naturaCondition){
      for(let p of this.myResults){
        p.values_.pnrr = p.values_.pnrr.filter( f => f.naturaCode == naturaCondition.key)
      }
    }
    this.calcolateTotal(this.myResults);
    let mySource = new VectorSource({
      features: this.myResults,
    });

    const clusterSource =this.getCluster(mySource);

    const pointLayer = this.geoService.getLayerByTitle(
      this.map,
      this.titleComuni.points
    );
    pointLayer.setSource(clusterSource);
    const writer = new GeoJSON();
    const jsonFeats = writer.writeFeaturesObject(this.myResults);
    this.createColorsRange(jsonFeats.features);
    pointLayer.setStyle(this.clusteredStyle);
  }

  resetMuniPolygon(){
    const muniLayer = this.geoService.getLayerByTitle(
      this.map,
      this.titleComuni.label
    );

      const muniFeatures = [...this.allMuniLayerFeatures.map(f => f.clone())];
    let mySource = new VectorSource({
      features: muniFeatures,
    });

    this.calcolateTotal(muniFeatures);

    //this.calcolateTotal(this.allMuniLayerFeatures);
    muniLayer.setSource(mySource);
    let setPolygonsStyle = this.styleFunction.bind(
      this,
      "Comuni",
      this.allMuniLayerFeatures.length
    );

    muniLayer.setStyle(setPolygonsStyle);
    this.chartsFeatures.emit(this.allMuniLayerFeatures);
  }

  resetMuniPoints(){
    const muniLayer = this.geoService.getLayerByTitle(
      this.map,
      this.titleComuni.extended
    );
    const muniFeatures =[...this.resetPointsMuni.map(f => f.clone())]
    //const features = muniLayer.getSource().getFeatures();
    let mySource = new VectorSource({
      features: muniFeatures,
    });

    this.calcolateTotal(muniFeatures);

    const clusterSource =this.getCluster(mySource);

    const pointLayer = this.geoService.getLayerByTitle(
      this.map,
      this.titleComuni.points
    );
    pointLayer && pointLayer.setSource(clusterSource);
    const writer = new GeoJSON();
    const jsonFeats = writer.writeFeaturesObject(muniFeatures);
    this.createColorsRange(jsonFeats.features);
    pointLayer && pointLayer.setStyle(this.clusteredStyle);
    this.geoService.zoomToBoundaries(this.map, muniLayer);
  }
  //funzioni asl
  searchAslPolygon(){
    this.polygonResults = [];
    const aslLayer = this.geoService.getLayerByTitle(
      this.map,
      this.titleAsl.label
    );
    const featuresAsl = aslLayer.getSource().getFeatures();
    featuresAsl.forEach(
      (elem) =>
        this.conditions.filter((f) => f.condition(elem) === true).length ===
          this.conditions.length && this.polygonResults.push(elem.clone())
    );

    const naturaCondition = this.conditions.find(c => c.name === 'nature')
    if(naturaCondition){
      for(let p of this.polygonResults){
        p.values_.pnrr = p.values_.pnrr.filter( f => f.naturaCode == naturaCondition.key)
      }
    }
    this.calcolateTotal(this.polygonResults);


    let mySource = new VectorSource({
      features: this.polygonResults,
    });
    aslLayer.setSource(mySource);
    let setPolygonsStyle = this.styleFunction.bind(
      this,
      "Comuni",
      featuresAsl.length
    );
    aslLayer.setStyle(setPolygonsStyle);
    this.polygonResults.length > 0 && this.geoService.zoomToBoundaries(this.map, aslLayer);
    this.chartsFeatures.emit(this.polygonResults);
  }

  searchAslPoints(){
    this.myResults = [];
    const aslLayer = this.pointsAslLayer;

    const featuresAsl = aslLayer.getSource().getFeatures();
    featuresAsl.forEach(
      (elem) =>
        this.conditions.filter((f) => f.condition(elem) === true).length ===
          this.conditions.length && this.myResults.push(elem.clone())
    );

    const naturaCondition = this.conditions.find(c => c.name === 'nature')
    if(naturaCondition){
      for(let p of this.myResults){
        p.values_.pnrr = p.values_.pnrr.filter( f => f.naturaCode == naturaCondition.key)
      }
    }
    this.calcolateTotal(this.myResults);
    let mySource = new VectorSource({
      features: this.myResults,
    });

    const clusterSource =this.getCluster(mySource);

    const pointLayer = this.geoService.getLayerByTitle(
      this.map,
      this.titleAsl.points
    );
    pointLayer.setSource(clusterSource);
    const writer = new GeoJSON();
    const jsonFeats = writer.writeFeaturesObject(this.myResults);
    this.createColorsRange(jsonFeats.features);
    pointLayer.setStyle(this.clusteredStyle);
  }
  resetAslPolygon(){
    const asliLayer = this.geoService.getLayerByTitle(
      this.map,
      this.titleAsl.label
    );

      const aslFeatures = [...this.allAslLayerFeatures];
    let mySource = new VectorSource({
      features: aslFeatures,
    });

    this.calcolateTotal(aslFeatures);

    asliLayer.setSource(mySource);
    let setPolygonsStyle = this.styleFunction.bind(
      this,
      "Asl",
      this.allAslLayerFeatures.length
    );
    asliLayer.setStyle(setPolygonsStyle);
    this.chartsFeatures.emit(this.allAslLayerFeatures);
  }
  resetAslPoints(){
    const asliLayer = this.geoService.getLayerByTitle(
      this.map,
      this.titleAsl.extended
    );
    const aslFeatures =[...this.resetPointsAsl]
    //const features = muniLayer.getSource().getFeatures();
    let mySource = new VectorSource({
      features: aslFeatures,
    });

    this.calcolateTotal(aslFeatures);

    const clusterSource =this.getCluster(mySource);

    const pointLayer = this.geoService.getLayerByTitle(
      this.map,
      this.titleAsl.points
    );
    pointLayer.setSource(clusterSource);
    const writer = new GeoJSON();
    const jsonFeats = writer.writeFeaturesObject(aslFeatures);
    this.createColorsRange(jsonFeats.features);
    pointLayer.setStyle(this.clusteredStyle);
    this.geoService.zoomToBoundaries(this.map, asliLayer);
  }
  //funzioni provincia
  searchProvPolygon(){
    this.polygonResults = [];
    const provlayer = this.geoService.getLayerByTitle(
      this.map,
      this.titleProvince.label
    );
    const featuresProv = provlayer.getSource().getFeatures();
    featuresProv.forEach(
      (elem) =>
        this.conditions.filter((f) => f.condition(elem) === true).length ===
          this.conditions.length && this.polygonResults.push(elem.clone())
    );

    const naturaCondition = this.conditions.find(c => c.name === 'nature')
    if(naturaCondition){
      for(let p of this.polygonResults){
        p.values_.pnrr = p.values_.pnrr.filter( f => f.naturaCode == naturaCondition.key)
      }
    }
    this.calcolateTotal(this.polygonResults);

    let mySource = new VectorSource({
      features: this.polygonResults,
    });
    provlayer.setSource(mySource);
    let setPolygonsStyle = this.styleFunction.bind(
      this,
      "Province",
      featuresProv.length
    );

    //Calcolo non localizzati
    this.currentNonLocalizzati = this.polygonResults.map(x=> x.values_['localizzazioneQty'][0]).reduce((a,b) => a+b, 0);
    this.interventiNonLocalizzati.emit(this.currentNonLocalizzati);

    provlayer.setStyle(setPolygonsStyle);
    this.polygonResults.length > 0 &&  this.geoService.zoomToBoundaries(this.map, provlayer);
    this.chartsFeatures.emit(this.polygonResults);
  }

  searchProvPoints(){
    this.myResults = [];
    const provlayer = this.geoService.getLayerByTitle(
      this.map,
      this.titleProvince.extended
    );

    const featuresProv = provlayer.getSource().getFeatures();
    featuresProv.forEach(
      (elem) =>
        this.conditions.filter((f) => f.condition(elem) === true).length ===
          this.conditions.length && this.myResults.push(elem.clone())
    );

    const naturaCondition = this.conditions.find(c => c.name === 'nature')
    if(naturaCondition){
      for(let p of this.myResults){
        p.values_.pnrr = p.values_.pnrr.filter( f => f.naturaCode == naturaCondition.key)
      }
    }
    this.calcolateTotal(this.myResults);
    let mySource = new VectorSource({
      features: this.myResults,
    });

    const clusterSource =this.getCluster(mySource);

    const pointLayer = this.geoService.getLayerByTitle(
      this.map,
      this.titleProvince.points
    );
    pointLayer.setSource(clusterSource);
    const writer = new GeoJSON();
    const jsonFeats = writer.writeFeaturesObject(this.myResults);
    this.createColorsRange(jsonFeats.features);
    pointLayer.setStyle(this.clusteredStyle);
  }

  resetProvPolygon(){
    const provLayer = this.geoService.getLayerByTitle(
      this.map,
      this.titleProvince.label
    );

    let mySource = new VectorSource({
      features: this.allProvLayerFeatures,
    });

    //TODO: Aggiungere reset array come su muni

    provLayer.setSource(mySource);
    let setPolygonsStyle = this.styleFunction.bind(
      this,
      this.titleProvince.label,
      this.allProvLayerFeatures.length
    );
    provLayer.setStyle(setPolygonsStyle);
    this.geoService.zoomToBoundaries(this.map, provLayer);
    this.chartsFeatures.emit(this.allProvLayerFeatures);
  }

  resetProvPoints(){
    const provLayer = this.geoService.getLayerByTitle(
      this.map,
      this.titleProvince.extended
    );

    const a =[...this.resetPointsProv.map(f => f.clone())]
    const features = provLayer.getSource().getFeatures();
    let mySource = new VectorSource({
      features: features,
    });

    this.calcolateTotal(a);

    const clusterSource =this.getCluster(mySource);

    const pointLayer = this.geoService.getLayerByTitle(
      this.map,
      this.titleProvince.points
    );
    pointLayer.setSource(clusterSource);
    const writer = new GeoJSON();
    const jsonFeats = writer.writeFeaturesObject(features);
    this.createColorsRange(jsonFeats.features);
    pointLayer.setStyle(this.clusteredStyle);
    this.geoService.zoomToBoundaries(this.map, provLayer);
  }

  resetMuni(){
    this.resetMuniPoints();
    this.resetMuniPolygon();
  }

  resetProv(){
    this.resetProvPoints();
    this.resetProvPolygon();
  }

  resetAsl(){
    this.resetAslPoints();
    this.resetAslPolygon();
  }

  calcolateTotal(features){
    this.totalNumTipologies = 0;
    for(let f of features){
      //const clonedFeature = f.clone();
      let totale = 0;
      f.values_['totale'] = 0;
      // this.extractValidConditionData(f.values_['pnrr']);
      f.values_['pnrr'] = f.values_['pnrr'].filter(p => p.descrizione != 'Totale')
    if(this.conditions && this.conditions.length > 0){
      for(let c of this.conditions){
        switch(c.name){
          // case 'cod_prov'
          case 'tipologie':
            f.values_['pnrr'] = f.values_['pnrr'].filter(f => f.tipologiaCode == c.key);
          break
          case 'dettagli':
            f.values_['pnrr'] = f.values_['pnrr'].filter(f => f.dettaglio == c.key);
          break
          case 'pnrrMissione':
            f.values_['pnrr'] = f.values_['pnrr'].filter(f => f.pnrrMissione == c.key);
          break
          case 'pnrrComponente':
            f.values_['pnrr'] = f.values_['pnrr'].filter(f => f.pnrrComponente == c.key);
          break
          case 'pnrrLinea':
            f.values_['pnrr'] = f.values_['pnrr'].filter(f => f.pnrrLinea == c.key);
          break
        }
      }
    }
    for(let pnrr of f.values_['pnrr']){ // numero interventi
      totale += pnrr.quantita;
    }
    f.values_['pnrr'].push({descrizione: "Totale", quantita: totale });
    f.values_['totale'] = totale;

  }

  this.totalNumTipologies += features.map(f => f.values_['totale']).reduce( (a,b)=> a+b,0);
  //this.risultatoTotale.emit(this.totalNumTipologies);
  }

  exportMap() {
    console.debug('Dentro exportMap...');
    const legend = document.getElementById('collapseLegend');
    const filters = document.getElementById('collapseFilter');

    // Chiudo il menu dei filtri cosi' da stampare la mappa intera
    if(this.isMenuFiltroShown)
      this.filterCollapseBtn.nativeElement.click();

    // Disabilito il bottone esporta
    document.getElementById('export-png')['disabled'] = true;

    console.debug('Disabilito bottone...');
    var mapCanvas = document.createElement("canvas");
    var size = this.map.getSize();
    mapCanvas.width = size[0];
    mapCanvas.height = size[1];
    var mapContext = mapCanvas.getContext("2d");
    var that = this;
    Array.prototype.forEach.call(
        document.querySelectorAll("canvas"),
          function (canvas) {
            console.debug('Dentro callback elemento canvas...');
              if(canvas.width > 0) {
                var opacity = canvas.parentNode.style.opacity;
                mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
                var transform = canvas.style.transform;
                // Get the transform parameters from the style's transform matrix
                var matrix = transform
                    .match(/^matrix\(([^\(]*)\)$/)[1]
                    .split(",")
                    .map(Number);
                // Apply the transform to the export map context
                CanvasRenderingContext2D.prototype.setTransform.apply(
                    mapContext,
                    matrix
                );
                mapContext.drawImage(canvas, 0, 0);
                console.debug('Draw image...');
                mapContext.globalAlpha = 1;

                // Elimino il collapse dal dropdown dei filtri e lo rendo show
                filters.classList.remove('collapse');
                filters.classList.add('show');
                domtoimage.toJpeg(legend, { style: {backgroundColor: 'white'} }).then( function(legendURL) {
                      domtoimage.toJpeg(filters, { style: {backgroundColor: "white"} }).then(function (filtersURL) {
                        if(legendURL.length<10 && filtersURL.length<10){
                          that.exportOnlyMap(mapContext, mapCanvas);
                          return;
                        }
                        else if(legendURL.length>10 && filtersURL.length<10){
                          that.exportLegenda(mapContext, mapCanvas, legendURL);
                          return;
                        }
                        else if(legendURL.length<10 && filtersURL.length>10){
                          that.exportFilters(mapContext, mapCanvas, filtersURL);
                          return;
                        }
                        let image = new Image();
                        image.src = legendURL;

                        image.onload = function () {
                          mapContext.drawImage(image, 930, 15);
                          let image2 = new Image();
                          image2.src = filtersURL;

                          image2.onload = function () {
                            mapContext.drawImage(image2, 15, 15);
                            //let link = document.getElementById("image-download");
                            //link["href"] = mapCanvas.toDataURL(); //ricorda il CORS
                            //nascondiModaleCaricamento();
                            that.exportTrigger(mapContext, mapCanvas);
                          }

                        //link.click();
                        //$('#legenda').css('width', '').css('minWidth', '');
                        }
                      })
                })
              }
          }
      );

}

exportOnlyMap(mapContext, mapCanvas){
  let that = this;
  that.exportTrigger(mapContext, mapCanvas);
}

exportLegenda(mapContext, mapCanvas, dataURL){
  let image = new Image();
  image.src = dataURL;

  let that = this;
  image.onload = function () {
    mapContext.drawImage(image, 930, 15);

    that.exportTrigger(mapContext, mapCanvas);
  }
}

exportFilters(mapContext, mapCanvas, dataURL2){
  let image2 = new Image();
  image2.src = dataURL2;

  let that = this;
  image2.onload = function () {
    mapContext.drawImage(image2, 15, 15);
    //let link = document.getElementById("image-download");
    //link["href"] = mapCanvas.toDataURL(); //ricorda il CORS
    //nascondiModaleCaricamento();
    that.exportTrigger(mapContext, mapCanvas);
  }
}

// Per come l'esporta e' strutturato, azioniamo l'esporta sul logo che e' sempre visibile nella foto scaricata
exportTrigger(mapContext, mapCanvas){
  const filters = document.getElementById('collapseFilter');
  let logo = new Image();
  logo.src = "assets/img/logo_inframob.svg";

  logo.onload = function () {
    console.debug('Dentro logo onload...');
    mapContext.drawImage(logo, 15, 790, 65, 39);
    console.debug('Draw logo...');

    var link = document.getElementById("image-download");
    link["href"] = mapCanvas.toDataURL(); //ricorda il CORS
    console.debug('Genera link...');

    window.setTimeout(() => {
        console.debug('Riabilito bottone...');
        document.getElementById('export-png')['disabled'] = false;
    },5000);

    link.click();
    console.debug('Click...');

    // Riabilito il collapse dal dropdown dei filtri
    filters.classList.remove('show');
    filters.classList.add('collapse');
    //return;
  };
}

closeMapPopup(){
  if(this.popup)
      this.popup.nativeElement.hidden = true;
}

  closeOverlay(){
    this.popupOverlay.setPosition(undefined);

  }

  setOpacity(e: any){
    const opacity = parseFloat(e.target.value);
    this.map && this.map.getLayers().array_.forEach(layer => {
      if(layer.values_.title!='points-prov' && layer.values_.title!='points-muni' && layer.values_.title!='basemap')
      layer.setOpacity(opacity);
    });

    this.opacityOutput = opacity.toFixed(2);
  }

  printValueAsCurrency(value) {
    //return this.italianCurrencyPipe.parse(value) + " €";
    value = typeof value === "number" ? value : value.replace(",", ".").trim();
    const format = "1.2-2";
    const currency = "€";
    const currentLocale: string = "it";
    return new CurrencyPipe(currentLocale).transform(
      value,
      "EUR",
      currency,
      format,
      "it-IT"
    );
  }

  status: boolean = false;
clickEvent(){
    this.status = !this.status;
    this.closeMapPopup();
}



}
