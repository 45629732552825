import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";


class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}

@Injectable({
    providedIn: "root"
})
export class NaturaTipologieService {
    private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}naturatipologie/`;
    private publicApiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob_public}naturatipologie/`;
    constructor(private http: HttpClient) { }
    
    getNaturaTipologie() {
        return this.http.get<any[]>(
            `${this.publicApiUrl}`
        );
    }

    getAllDt(dataTablesParameters: any) {
        return this.http.post<DataTablesResponse>(
            `${this.apiUrl}allDt`,
            dataTablesParameters
        );
    }

    insert(s: any) {
        return this.http.post<any>(`${this.apiUrl}`, s);
    }

    update(s: any) {
        return this.http.put<any>(`${this.apiUrl}${s.id}`, s);
    }
 
    delete(id: number) {
        return this.http.delete<any>(`${this.apiUrl}${id}`);
    }

    save(s:any){
        if (s.id)return this.update(s);
        return this.insert(s);
    }
}

