import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { CurrencyPipe } from "@angular/common";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";
import { GeoService } from "src/app/services";
import { center } from '@turf/turf';
import { DashboardPnrrNonDisegnabileService } from '../dashboard-pnrr-non-disegnabile/dashboard-pnrr-non-disegnabile.service';

@Component({
  selector: 'app-chart-pnrr-natura-importi',
  templateUrl: './chart-pnrr-natura-importi.component.html',
  styleUrls: ['./chart-pnrr-natura-importi.component.css']
})
export class ChartPnrrNaturaImportiComponent implements OnInit, OnDestroy {

  chart: any;
  data = null;

  constructor(private geoService: GeoService,private service: DashboardPnrrNonDisegnabileService) { }

  @Input() set filterManager(filters){
    this.getChartData(filters);
  }

  getChartData(filters){
    this.geoService.getChartDataImportiNatureTipologieDashboardNonDisegnabile(filters).subscribe(res=>{
      const chartData = [];
        res.map(x=>{
        const element = {};
        const json = JSON.parse(x.descrizione);
        element['category'] = x['naturaCode'];
        element['nome'] = x['nome'];
        element['value'] = x['importo'];
        element['breakdown'] = [];
        for(let k of json){
          const found = element['breakdown'].find(e=>e.category==k.descrizione);

          if(found){
            found[k.missione] = k.importo;
            // found['totale'] += k.importo;
          }
          else{
            const newData = element['breakdown'].push({category: k.descrizione});
            element['breakdown'][newData-1][k.missione] = k.importo;
            element['breakdown'][newData-1]['totale'] = 0;
          }
        }
        chartData.push(element);
      })

      // Ordino le tipologie per nome a < b
      chartData.map(x=>{
        x['breakdown'].sort((a,b)=>a.category<b.category ? -1 : 1);
      })

      this.data = chartData;
      this.grafico(this.data);
    })
  }

  ngOnInit(): void {
    am4core.useTheme(am4themes_animated);

    // Unuse the theme from the first chart
    am4core.useTheme(am4themes_material);
    am4core.options.autoSetClassName = true;
  }



  grafico(data){

    if(data.length <=0 ){
      this.fullChartAsEmpty();
      return;
    }
    this.service.doRendering(true);
    // Create chart instance
    if (this.chart)this.chart.dispose();
    this.chart = am4core.create("chartRho", am4core.Container);
    this.chart.width = am4core.percent(100);
    this.chart.height = am4core.percent(100);
    this.chart.layout = "vertical";

    // Create chart for title
    let title3 = this.chart.createChild(am4core.Label);
    title3.width = am4core.percent(100);
    title3.height = 50;
    title3.align = "center";

    title3.text = "Numero di risorse per natura e tipologia";
    title3.textAlign = "middle";
    title3.fontSize = 18;
    title3.fontWeight = 400;
    title3.paddingTop = 15;


    let chartImportiNature = this.chart.createChild(am4core.Container);
    chartImportiNature.width = am4core.percent(100);
    chartImportiNature.height = am4core.percent(100);
    chartImportiNature.layout = "horizontal";

    // Cambio formato dei numeri in lingua italiana
    chartImportiNature.numberFormatter.numberFormat = "#,###.00 '€";
    chartImportiNature.language.locale = am4lang_it_IT;

    let totaleImportiNature = 0;
    data.map(x => {totaleImportiNature += x.value});

    const totaleImportiNatureFormatted = this.printValueAsCurrency(totaleImportiNature);


    // Enable export
  this.aggiungiLogo(this.chart);

  this.chart.exporting.menu = new am4core.ExportMenu();

  this.chart.exporting.menu.align = "left";
  this.chart.exporting.menu.verticalAlign = "top";
  this.chart.exporting.menu.background = "#ff0000";
  this.chart.exporting.menu.defaultStyles = true;
  this.chart.exporting.menu.items = [
      {
          menu: [
              { type: "jpg", label: "JPG" },
              { type: "png", label: "PNG" },
              { type: "csv", label: "CSV" },
          ],
      },
  ];
  this.chart.exporting.menu.items[0].icon = "assets/img/export.png";



    /**
     * Column chart
     */

    // Create chart instance
    let columnChart = chartImportiNature.createChild(am4charts.XYChart);
    columnChart.strokeWidth = 0;

    columnChart.paddingTop = 0;
    //columnChart.paddingBottom = 0;
    columnChart.paddingLeft = 0;
    columnChart.paddingRight = 0;
    if(window.innerWidth<=575.98){
      columnChart.language.locale = am4lang_it_IT;
      columnChart.numberFormatter.numberFormat = "#,###.00 a '€'";
      columnChart.numberFormatter.bigNumberPrefixes = [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "Mln" },
        { number: 1e9, suffix: "Mrd" },
      ];
    }


    // Create axes
    let categoryAxis = columnChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.minGridDistance = 0;

    let label = categoryAxis.renderer.labels.template;
    label.tooltipText = "{category}";

    let valueAxis = columnChart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.extraMax = 0.1;
    valueAxis.calculateTotals = true;
    valueAxis.fontSize = 12;

    const missionColors = [
      "#C6DBF0", //M1
      "#ACD86A", //M2
      "#FB9999", //M3
      "#F2C799", //M4
      "#E2D7EC", //M5
      "#FDF2CD", //M6
    ];

    // Create series
    let columnsSeries = [];
    columnsSeries.push(this.createSeries("M1", "M1", "Digitalizzazione, innovazione, competitività, cultura e turismo", columnChart, missionColors));
    columnsSeries.push(this.createSeries("M2", "M2", "Rivoluzione verde e transizione ecologica", columnChart, missionColors));
    columnsSeries.push(this.createSeries("M3", "M3", "Infrastrutture per una mobilità sostenibile", columnChart, missionColors));
    columnsSeries.push(this.createSeries("M4", "M4", "Istruzione e ricerca", columnChart, missionColors));
    columnsSeries.push(this.createSeries("M5", "M5", "Inclusione e coesione", columnChart, missionColors));
    columnsSeries.push(this.createSeries("M6", "M6", "Salute", columnChart, missionColors));

    // Create series for total
    let totalSeries = columnChart.series.push(new am4charts.ColumnSeries());
    totalSeries.dataFields.valueX = "totale";
    totalSeries.dataFields.categoryY = "category";
    totalSeries.stacked = true;
    totalSeries.hiddenInLegend = true;
    totalSeries.columns.template.strokeOpacity = 0;

    let totalBullet = totalSeries.bullets.push(new am4charts.LabelBullet());
    totalBullet.dx = 65;
    totalBullet.label.text = "Tot.: [bold]{valueX.total}";
    totalBullet.label.truncate = false;
    totalBullet.label.hideOversized = false;
    totalBullet.label.fontSize = 12;

    columnsSeries.push(totalSeries);

    // Legend
    columnChart.legend = new am4charts.Legend();
    let markerTemplateXY = columnChart.legend.markers.template;
    markerTemplateXY.width = 12;
    markerTemplateXY.height = 12;
    columnChart.legend.labels.template.fontSize = 12;
    columnChart.legend.itemContainers.template.tooltipText = "{dataContext.dummyData.description}";
    columnChart.legend.disabled = true;
    // Distanza elementi della legenda
    //columnChart.legend.itemContainers.template.paddingTop = 0;
    columnChart.legend.itemContainers.template.paddingBottom = 0;
    columnChart.legend.itemContainers.template.marginLeft = 0;
    columnChart.legend.itemContainers.template.marginRight = 0;


    // Add vertical scrollbar
    columnChart.scrollbarY = new am4core.Scrollbar();
    columnChart.scrollbarY.disabled = true;

    // Add Horizontal scrollbar
    columnChart.scrollbarX = new am4core.Scrollbar();
    columnChart.scrollbarX.disabled = true;

    // let title = columnChart.titles.create();
    //   title.text = "Quota di interventi previsti per natura e tipologia";
    //   title.fontSize = 18;
    //   title.marginBottom = 15;

    let labelCatAxis = categoryAxis.renderer.labels.template;
    labelCatAxis.truncate = true;
    labelCatAxis.maxWidth = 180;
    labelCatAxis.fontSize = 12;

    /**
     * Pie chart
     */

    // Create chart instance
    let pieChart = chartImportiNature.createChild(am4charts.PieChart);
    data.sort((a,b)=>a.category<b.category?-1:1)
    pieChart.data = data;
    pieChart.radius = am4core.percent(90);
    pieChart.innerRadius = am4core.percent(75);

    //Create legend
    pieChart.legend = new am4charts.Legend();
    pieChart.legend.position = "right";
    pieChart.legend.labels.template.text = "Natura {category} - {nome}";

    let markerTemplate = pieChart.legend.markers.template;
    markerTemplate.width = 12;
    markerTemplate.height = 12;
    pieChart.legend.labels.template.fontSize = 12;
    pieChart.legend.labels.template.truncate = false;
    pieChart.legend.labels.template.wrap = true;

    pieChart.legend.itemContainers.template.paddingTop = 0;
    pieChart.legend.itemContainers.template.paddingBottom = 3;

    pieChart.legend.maxWidth = 310;

    pieChart.legend.itemContainers.template.togglable = false;
    pieChart.legend.itemContainers.template.events.on("hit", function(ev) {
     var slice = ev.target.dataItem.dataContext.slice;
     slice.isActive = !slice.isActive;
    });




    //let title2 = pieChart.titles.create();
     // title2.text = "Quota di interventi previsti per natura";
     // title2.fontSize = 18;

      //pieChart.paddingTop = 15;

    // Add and configure Series
    let pieSeries = pieChart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "category";
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.labels.template.disabled = true;
    pieSeries.slices.template.tooltipText = "Natura {category} - {nome}:\n{value}";

    pieSeries.colors.list = [
am4core.color('#b066af'),
am4core.color('#4ca3b2'),
am4core.color('#9bca40'),
am4core.color('#f1cc4c'),
am4core.color('#4c70af'),
am4core.color('#b7fff9')

/*      am4core.color("#F46036"),
      am4core.color("#2E294E"),
      am4core.color("#1B998B"),
      am4core.color("#E71D36"),
      am4core.color("#C5D86D"),
*/
    ];

    // Set up labels
    let label1 = pieChart.seriesContainer.createChild(am4core.Label);
    if(totaleImportiNature>0)
      label1.text = totaleImportiNatureFormatted;
    else
      label1.text = "";
    label1.horizontalCenter = "middle";
    label1.fontSize = 22;
    label1.fontWeight = 600;
    label1.dy = -20;

    let label2 = pieChart.seriesContainer.createChild(am4core.Label);
    label2.text = "Totale Risorse";
    label2.horizontalCenter = "middle";
    label2.fontSize = 14;
    label2.dy = 20;


    const noDataMessagecontainer = columnChart.chartContainer.createChild(am4core.Container);
    noDataMessagecontainer.align = 'center';
    noDataMessagecontainer.isMeasured = false;
    noDataMessagecontainer.x = am4core.percent(50);
    noDataMessagecontainer.horizontalCenter = 'middle';
    noDataMessagecontainer.y = am4core.percent(50);
    noDataMessagecontainer.verticalCenter = 'middle';
    noDataMessagecontainer.layout = 'vertical';

     //noDataMessagecontainer.width = am4core.percent(50);
     //noDataMessagecontainer.height = am4core.percent(50);
     noDataMessagecontainer.background.fill = am4core.color("#000");
     noDataMessagecontainer.background.fillOpacity = 0.015;
    noDataMessagecontainer.background.stroke = am4core.color("#000");
    noDataMessagecontainer.background.strokeOpacity = 0.025;
    noDataMessagecontainer.background.strokeWidth = 1;

    const messageLabel = noDataMessagecontainer.createChild(am4core.Label);
    messageLabel.text = 'Seleziona una natura per visualizzare le tipologie associate';
    messageLabel.textAlign = 'middle';
    messageLabel.maxWidth = 600;
    messageLabel.wrap = true;
    messageLabel.fontSize = 18;
    messageLabel.paddingLeft = 5;
    messageLabel.paddingRight = 5;
    messageLabel.paddingTop = 15;
    messageLabel.paddingBottom = 15;


    // this.chart.numberFormatter.numberFormat = "#,###.00 '€'"
    // this.chart.language.locale = am4lang_it_IT;
    let that = this;

    let natureLabelSmall = "Totale Risorse";
    let natureLabelBig = "Totale Risorse";
    // am4core.ready(function() {
    // })
    // Auto-select first slice on load
    pieChart.events.on("ready", function(ev) {
      // pieSeries.slices.getIndex(0).isActive = true;
      that.service.doRendering(false);
    });


    // Set up toggling events
    pieSeries.slices.template.events.on("toggled", function(ev) {

      if (ev.target.isActive) {

        // Untoggle other slices
        pieSeries.slices.each(function(slice) {
          if (slice != ev.target) {
            slice.isActive = false;
          }
        });


        // Update column chart
        for(let cs of columnsSeries){
          cs.appeared = false;
          cs.show();
          // cs.strokeWidth = 1;
          // cs.stroke = ev.target.fill;
          cs.reinit();
        }
        noDataMessagecontainer.hide();
        categoryAxis.show();
        valueAxis.show();
        columnChart.strokeWidth = 1;
        columnChart.scrollbarY.disabled = false;
        columnChart.scrollbarX.disabled = false;
        columnChart.legend.disabled = false;

        columnChart.data = ev.target.dataItem.dataContext.breakdown;

        // Update labels
        label1.text = that.printValueAsCurrency(ev.target.dataItem.dataContext.value);
        label1.fill = ev.target.fill;

        natureLabelSmall = `Natura ${ev.target.dataItem.category}`;
        natureLabelBig = `Natura ${ev.target.dataItem.category} - ${ev.target.dataItem.dataContext.nome}`;

        if(window.innerWidth <= 575.98)
          label2.text = `Natura ${ev.target.dataItem.category}`;
        else
          label2.text = `Natura ${ev.target.dataItem.category} - ${ev.target.dataItem.dataContext.nome}`;
        label2.fontSize = 14;
        label2.wrap = true;
        label2.maxWidth = 260;
      }

      else{
        let isSliceClose = true;
        pieSeries.slices.each(function(slice) {
          if (slice.isActive == true)
            isSliceClose = false;
        });

      // Se si sono chiusi tutte le fette
        if(isSliceClose){
          label1.text = totaleImportiNatureFormatted;
          label1.fill = "#000000";
          noDataMessagecontainer.show();
          for(let cs of columnsSeries){
            cs.hide();
          }
          categoryAxis.hide();
          valueAxis.hide();
          columnChart.strokeWidth = 0;
          columnChart.scrollbarY.disabled = true;
          columnChart.scrollbarX.disabled = true;
          columnChart.legend.disabled = true;

          natureLabelBig = "Totale Risorse";
          natureLabelSmall = "Totale Risorse";
          label2.text = "Totale Risorse";
        }
      }

    });


    chartImportiNature.events.on('maxsizechanged', (e) => {
      if(window.innerWidth <= 1199.98 && window.innerWidth > 575.98){
        e.target.layout = "vertical";
        e.target.children.values[1].align ='center';
        pieChart.legend.position = 'right';
        title3.fontSize = 18;
        pieChart.legend.labels.template.text = "Natura {category} - {nome}";
        pieChart.legend.contentAlign = "left";
        label2.text = natureLabelBig;

        labelCatAxis.wrap = true;
        labelCatAxis.maxWidth = 180;

        chartImportiNature.numberFormatter.numberFormat = "#,###.00 '€";
        columnChart.numberFormatter.bigNumberPrefixes = [];
      }
      else if(window.innerWidth <= 575.98){
        e.target.layout = "vertical";
        e.target.children.values[1].align ='center';
        messageLabel.fontSize = 12;
        pieChart.legend.position = 'bottom';
        title3.fontSize = 12;

        pieChart.legend.labels.template.text = "Natura {category}";
        pieChart.legend.contentAlign = "center";
        label2.text = natureLabelSmall;

        labelCatAxis.wrap = true;
        labelCatAxis.maxWidth = 165;

        pieSeries.tooltip.label.maxWidth = 320;
        pieSeries.tooltip.label.wrap = true;

        label1.fontSize = 16;

        columnChart.numberFormatter.numberFormat = "#,###.00 a '€'";
        columnChart.numberFormatter.bigNumberPrefixes = [
          { number: 1e3, suffix: "K" },
          { number: 1e6, suffix: "Mln" },
          { number: 1e9, suffix: "Mrd" },
        ];
      }
      else{
        e.target.layout = "horizontal";
        e.target.children.values[1].align ='none';
        messageLabel.fontSize = 18;
        pieChart.legend.position = 'right';
        title3.fontSize = 18;
        pieChart.legend.labels.template.text = "Natura {category} - {nome}";
        pieChart.legend.contentAlign = "left";
        label2.text = natureLabelBig;

        labelCatAxis.wrap = true;
        labelCatAxis.maxWidth = 180;

        chartImportiNature.numberFormatter.numberFormat = "#,###.00 '€";
        columnChart.numberFormatter.bigNumberPrefixes = [];
      }
    });

  // });
}

fullChartAsEmpty(){
    if (this.chart)this.chart.dispose();
    this.chart = am4core.create("chartRho", am4core.Container);
    this.chart.width = am4core.percent(100);
    this.chart.height = am4core.percent(100);
    this.chart.layout = "horizontal";

    /**
     * Pie chart
     */

    // Create chart instance
    let pieChart = this.chart.createChild(am4charts.PieChart);
    pieChart.data = [
      {
        category: "01",
        value: 30
      }
    ];
    pieChart.innerRadius = am4core.percent(65);



    // Add and configure Series
    let pieSeries = pieChart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "category";
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.fillOpacity = 0.5;
    pieSeries.labels.template.disabled = true;
    pieSeries.tooltip.disabled = true;


    let slice = pieSeries.slices.template;
    slice.states.getKey("hover").properties.scale = 1;
    slice.states.getKey("active").properties.shiftRadius = 0;

    let label1 = pieChart.seriesContainer.createChild(am4core.Label);
    label1.text = "0";
    label1.horizontalCenter = "middle";
    label1.fontSize = 26;
    label1.fontWeight = 600;
    label1.dy = -20;

}

// Create series
createSeries(field, name, description, columnChart, missionColors) {
  if(!columnChart) return;
  // Set up series
  let series = columnChart.series.push(new am4charts.ColumnSeries());
  series.name = name;
  series.dataFields.valueX = field;
  series.dataFields.categoryY = "category";
  // series.sequencedInterpolation = true;
  series.strokeWidth = 0;
  series.dummyData = {
    description: description
  };

  switch(field){
    case 'M1':
      series.fill = missionColors[0]
    break;
    case 'M2':
      series.fill = missionColors[1]
    break;
    case 'M3':
      series.fill = missionColors[2]
    break;
    case 'M4':
      series.fill = missionColors[3]
    break;
    case 'M5':
      series.fill = missionColors[4]
    break;
    case 'M6':
      series.fill = missionColors[5]
    break;
  }

  // Make it stacked
  series.stacked = true;


  // Configure columns
  series.columns.template.width = am4core.percent(60);
  series.columns.template.tooltipText = "{name}: [bold]{valueX.formatNumber('#,###.00 €')}";


  // Add label
  let labelBullet = series.bullets.push(new am4charts.LabelBullet());
  labelBullet.label.text = "{valueX}";
  labelBullet.locationX = 0.5;
  labelBullet.label.fontSize = 12;
  labelBullet.label.hideOversized = true;

  return series;
}

ngOnDestroy(){
  this.chart && this.chart.dispose();
}

aggiungiLogo(logoChart) {
  // Add watermark
  const watermark = new am4core.Image();
  watermark.href = "assets/img/logo_blu.svg";
  logoChart.tooltipContainer.children.push(watermark);
  watermark.align = "right";
  watermark.valign = "bottom";
  watermark.opacity = 0.3;
  watermark.marginRight = 10;
  watermark.marginBottom = 5;
  watermark.disabled = true;

  // Enable watermark on export
  logoChart.exporting.events.on("exportstarted", function (ev) {
      watermark.disabled = false;
  });

  // Disable watermark when export finishes
  logoChart.exporting.events.on("exportfinished", function (ev) {
      watermark.disabled = true;
  });

  // Add watermark to validated sprites
  logoChart.exporting.validateSprites.push(watermark);
}
/*---*/

printValueAsCurrency(value) {
  //return this.italianCurrencyPipe.parse(value) + " €";
  value = typeof value === "number" ? value : value.replace(",", ".").trim();
  const format = "1.2-2";
  const currency = "€";
  const currentLocale: string = "it";
  return new CurrencyPipe(currentLocale).transform(
    value,
    "EUR",
    currency,
    format,
    "it-IT"
  );
}

reloadData(){
    this.grafico(this.data);
}



}
