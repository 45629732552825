import { NaturaTipologia } from './natura-tipologia';

export class TipologiaIntervento{
    id:number;
    codice:string;
    descrizione:string;
    disegnabile:boolean;
    note:number;
    icona:string
    naturaTipologia:NaturaTipologia;
    idNaturaTipologia:number;//for save purpose only
    iconaFilePath:string;
    iconaFileBase64: string;
    //tipoClassificazione:string;//for grid purpose only

}