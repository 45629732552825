<button
  #popBtn
  [popover]="popoverContent"
  #pop="bs-popover"
  placement="{{ placement }}"
  class="{{ class }}">
  <div [innerHtml]="htmlImage"></div>
  {{ text }}
</button>

<ng-template #popoverContent>
  <div [innerHTML]="htmlSanitized"></div>
</ng-template>
