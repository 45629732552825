import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  templateUrl: "popover-page.component.html",
  styleUrls: ["popover-page.component.css"],
  selector: "app-popover-page"
})
export class PopoverPageComponent implements OnInit {

  showScroll: boolean = true;
  showScrollHeight = 300;
  hideScrollHeight = 10;

  constructor(
    public router: Router
  ) {}

  html: string = null;
  color: string = null;

  ngOnInit(): void {
    this.checkUrlForHtml();
  }

  // @HostListener("window:scroll", [])
  // onWindowScroll() {
  //   if (
  //     (window.pageYOffset ||
  //       document.documentElement.scrollTop ||
  //       document.body.scrollTop) > this.showScrollHeight
  //   ) {
  //     this.showScroll = true;
  //   } else if (
  //     this.showScroll &&
  //     (window.pageYOffset ||
  //       document.documentElement.scrollTop ||
  //       document.body.scrollTop) < this.hideScrollHeight
  //   ) {
  //     this.showScroll = false;
  //   }
  // }

  checkUrlForVisibility(): boolean {
    if (this.router.url.includes('inframob/progetti') || 
        this.router.url.includes('inframob/manageProvvedimenti') ||
        this.router.url.includes('inframob/manageFinanziamenti') ||
        this.router.url.includes('inframob/validazioni')) {
      return true;
    }

    return false;
  }

  checkUrlForHtml(): string {
    if (this.router.url.includes('inframob/progetti')) {
      this.html = '<span class="bold">Elenco progettualità/fabbisogni</span>';
    } else if (this.router.url.includes('inframob/manageProvvedimenti')) {
      this.html = '<span class="bold">Gestione Provvedimenti</span>';
    } else if (this.router.url.includes('inframob/manageFinanziamenti')) {
      this.html = '<span class="bold">Gestione Finanziamenti</span>';
    } else if (this.router.url.includes('inframob/validazioni')) {
      this.html = '<span class="bold">Gestione Validazioni</span>';
    } else {
      this.html = null;
    }

    return this.html;
  }

  checkUrlForColor(): string {
    if (this.router.url.includes('inframob/progetti')) {
      this.color = '#0083e0';
    } else if (this.router.url.includes('inframob/manageProvvedimenti')) {
      this.color = '#ff668c';
    } else if (this.router.url.includes('inframob/manageFinanziamenti')) {
      this.color = '#A4CCB4';
    } else if (this.router.url.includes('inframob/validazioni')) {
      this.color = '#ffe08a';
    } else {
      this.color = null;
    }

    return this.color;
  }
}
