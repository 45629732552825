import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  TemplateRef,
  OnDestroy
} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { PnrrService, SoggettoGiuridicoService } from "../../../services/";
import { AuthService } from "src/app/services/auth.service";
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { KeyValue } from 'src/app/models/keyValue';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { FasiComponent } from "../../fasi/fasi.component";
import * as FileSaver from "file-saver";
import { StoricoPnrrLineaFinanziamentoComponent } from "../../storicoPnrrLineaFinanziamento/storico-pnrr-linea-finanziamento.component";

@Component({
    selector: "pnrr-linea-finanziamento-management",
    templateUrl: "./pnrr-linea-finanziamento-management.component.html",
    styleUrls: ["./pnrr-linea-finanziamento-management.component.css"]
})
export class PnrrLineaFinanziamentoManagementComponent
    implements OnInit, OnDestroy {
    public mask: any = {
        mask: 'KK €',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            }
        }
    };
    visible = true;
    continents: KeyValue[];
    continentsAdd: KeyValue[];
    submitted: boolean = false;
    addNationForm: FormGroup;
    formDatiServizio: FormGroup;
    modalRef: BsModalRef;
    open: boolean = false;
    dtOptionsNgx: any;
    section: string = 'PNRR - Linee di Finanziamento';
    isEdit: boolean = false;

    tipologie = [
        { id: 'investimento', descrizione: 'Investimento' },
        { id: 'intervento', descrizione: 'Intervento' },
        { id: 'riforma', descrizione: 'Riforma' },
        { id: 'sub-investimento', descrizione: 'Sub-Investimento' },
        { id: 'sub-riforma', descrizione: 'Sub-Riforma' }
    ];

    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;
    @ViewChild('templateModal', { static: true })
    public templateModal: TemplateRef<any>;

    //@ViewChild('buttonsTemplate',{static:false}) buttonsTemplate: TemplateRef<any>;
    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;
    templateList = [];

    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-xl"
    };

    constructor(
        private http: HttpClient,
        private pnrrService: PnrrService,
        private formBuilder: FormBuilder,
        private localeService: BsLocaleService,
        private el: ElementRef,
        private toastr: ToastService,
        private modalService: BsModalService,
        private soggettoGiuridicoService: SoggettoGiuridicoService
        ,private authService:AuthService
    ) {
        this.localeService.use("it");
        this.dtOptionsNgx = {
            rows: []
            , totalElements: 0
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , ajax: this.loadData
            , scrollbarH: "true"
            , sorts: [{ dir: "asc", prop: "codice" }]
        };
    }

    refreshGrid() {
        this.ngxDatatableWrapper.refresh();
    }

    ngOnDestroy(): void {

    }
    ngOnInit() {
        window.scroll(0, 0);
        this.addNationForm = this.initializeFormGroup();
        this.formDatiServizio = this.initializeFormGroupServizio();
        this.exportForm = this.initializeFormGroupEsportazioneRR07();
        this.templateList = [{ name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }, { name: 'btnEditTemplate', template: this.btnEditTemplate }];
        this.getMissioni();
        this.getComponenti();
        this.getPnrrLineeFinanziamento();
        this.getSoggettiGiuridici();
    }

    initializeFormGroupEsportazioneRR07(){
        return this.formBuilder.group({
            escludiLineeSenzaRipartizioni: [null]
        });
    }

    initializeFormGroupServizio() {
        return this.formBuilder.group({
            id: [null]
            , valoreIpotesiDestinato: [null]
            , valoreRipartitoRegione: [null]
            ,decretoDiRipartoDaFare: [null]
            ,decretoDiRipartoAssegnato: [null]
            ,bandoRegionaleDaRipartire: [null]
            ,bandoRegionaleRipartito: [null]
            ,bandoRegionaleBandito: [null]
            ,bandoRegionaleAssegnato: [null]
            ,bandoMinisterialeDaBandire: [null]
            ,bandoMinisterialeBandito:[null]
            ,bandoMinisterialeAssegnato: [null]
            ,programmazione: [null]
            ,attuazione: [null]
            ,proponente: [null]
            ,beneficiarioDiretto: [null]
            ,coProgrammazione: [null]
            ,beneficiarioDirettoDescrizione: [null]
            ,coProgrammazioneDescrizione: [null]
            ,noteDatiServizio: [null]
            ,impattoTerritoriale:[null]
            ,attuazioneDescrizione:[null]
        });
    }

    initializeFormGroup() {
        return this.formBuilder.group({
            id: [null],
            descrizione: [null, [Validators.required, Validators.maxLength(255)]],
            codicePrimaParte: [
                null
            ],
            codiceSecondaParte: [
                null,
                [Validators.required]
            ],
            tipologia: [
                null,
                [Validators.required]
            ],
            amministrazioneTitolare: [
                null,
                [Validators.required]
            ],
            pnrr: [
                null,
                [Validators.required]
            ],
            fc: [
                null,
                [Validators.required]
            ],
            fonte: [
                null,
                [Validators.required]
            ],
            missione: [
                null,
                [Validators.required]
            ],
            componente: [
                null,
                [Validators.required]
            ],
            misuraPnrr: [
                null,
                [Validators.required]
            ]
            , note: [null]
            , quotaAssegnataFc: [null]
            , quotaAssegnataPnrr: [null]
            , pnrrLineaFinanziamentoPrincipale: [null]
            ,abilitaPnrr:[null]
            ,abilitaFc:[null]
            ,quotaRipartitaPnrr:[null]
            ,quotaRipartitaFc:[null]
            ,quotaRipartitaSarPnrr:[null]
            ,quotaRipartitaSarFc:[null]
        });
    }

    private setDisabledFormDatiGenerali(): boolean {
        return (!this.isEdit && !this.checkPermission('insert')) ||
               (this.isEdit && !this.checkPermission('update', 'PNRR - Linee di Finanziamento - Dati Generali'));
    }

    get f() {
        return this.addNationForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        this.addNationForm.updateValueAndValidity();

        let errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );
        }
        if (this.addNationForm.invalid || errors) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.addNationForm.controls) {
                if (this.addNationForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.addNationForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.addNationForm.get(el)["controls"]) {
                            if (this.addNationForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }

        this.f.pnrr.enable();
        this.f.fc.enable();
        const obj = this.addNationForm.value;
        if (obj.tipologia == 'sub-riforma' || obj.tipologia == 'sub-investimento'){
            obj.abilitaFc = true;
            obj.abilitaPnrr = true;
        }

        obj.missione = this.missioni.find(x => x.id == obj.missione);
        obj.componente = this.componenti.find(x => x.id == obj.componente);
        obj.amministrazioneTitolare = this.soggettiProponenti.find(x => x.id == this.addNationForm.controls.amministrazioneTitolare.value);
        obj.pnrrLineaFinanziamentoPrincipaleId = obj.pnrrLineaFinanziamentoPrincipale;
        obj.codice = this.getCodicePrimaParte() + this.f.codiceSecondaParte.value;

//controllo che il valore pnrr e pnc sia >= delle quote
        const quotaAssegnataPnrr = this.addNationForm.getRawValue().quotaAssegnataPnrr;
        const quotaAssegnataPnc = this.addNationForm.getRawValue().quotaAssegnataFc;
        if (parseFloat(obj.pnrr)<parseFloat(quotaAssegnataPnrr) || parseFloat(obj.fc)<parseFloat(quotaAssegnataPnc))
        {
            this.toastr.error("Errore: I valori di PNRR o PNC sono inferiori delle quote assegnate");
            return;
        }

        this.pnrrService.saveLineaFinanziamento(obj).subscribe(res => {
            this.toastr.success("Linea di finanziamento PNRR/PNC salvata con successo!");
            this.refreshGrid();
            this.submitted = false;
            this.open = false;
            this.closeModal();
            this.getPnrrLineeFinanziamento();
        }, error => {
            this.toastr.error("Codice già presente");
            this.submitted = false;
        })
    }

    columns = [
        {
            name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
            , resizeable: false
        }

        , {
            name: 'codice'
            , prop: 'codice'

            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 2
            , minWidth: 70

        },
        {
            name: 'missione'
            , prop: 'missione.descrizione'
            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 3
            , minWidth: 90
        },
        {
            name: 'componente'
            , prop: 'componente.descrizione'
            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 3
            , minWidth: 90
        },
        {
            name: 'descrizione'
            , prop: 'descrizione'
            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 3
            , minWidth: 100
        }
        ,
        {
            name: 'misura PNRR'
            , prop: 'misuraPnrr'
            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 3
            , minWidth: 90
        }
        , {
            name: 'tipologia'
            , prop: 'tipologia'

            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 3
            , minWidth: 50

        }
        , {
            name: 'pnrr'
            , prop: 'pnrr'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'currencyValueTemplate'
            , flexGrow: 4
            , minWidth: 70
        }
        , {
            name: 'pnc'
            , prop: 'fc'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'currencyValueTemplate'
            , flexGrow: 3
            , minWidth: 70
        }

        , {
            name: 'modifica'
            , prop: null
            , visible: this.checkPermission('update') || this.checkPermission('update', 'PNRR - Linee di Finanziamento - Dati di Servizio')
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            //, width: 10
            , resizeable: false
            , draggable: false
            , flexGrow: 2
            , minWidth: 55
        }
        , {
            name: 'elimina'
            , prop: 'id'
            , visible: this.checkPermission('delete')
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , flexGrow: 2
            , minWidth: 45

        }
    ];

    loadData = (dataTablesParameters: any, callback: any) => {
        this.pnrrService.getAllLineaFinanziamentoDt(dataTablesParameters)
            .subscribe(resp => {
                callback(resp);
            });
    };

    isPnrrLineaFinanziamentoPrincipale = false;
    onEdit(row) {
        this.submitted = false;
        this.isEdit = true;
        //this.activeTab = '0';
        //console.log(row);
        this.modalRef = this.modalService.show(this.templateModal, this.config);
        this.addNationForm.setValue({
            id: row.id,
            descrizione: row.descrizione,
            tipologia: row.tipologia ? row.tipologia.toLowerCase() : null,
            amministrazioneTitolare: row.amministrazioneTitolare ? row.amministrazioneTitolare.id : null,
            pnrr: row.pnrr.toString(),
            fc: row.fc.toString(),
            fonte: row.fonte,
            missione: row.missione.id,
            componente: row.componente.id
            , codicePrimaParte: ''
            , codiceSecondaParte: ''
            , misuraPnrr: row.misuraPnrr
            , note: row.note
            , quotaAssegnataPnrr: row.quotaAssegnataPnrr ? row.quotaAssegnataPnrr.toString() : '0'
            , quotaAssegnataFc: row.quotaAssegnataFc ? row.quotaAssegnataFc.toString() : '0'
            , pnrrLineaFinanziamentoPrincipale: row.pnrrLineaFinanziamentoPrincipale?row.pnrrLineaFinanziamentoPrincipale.id: row.pnrrLineaFinanziamentoPrincipaleId ? row.pnrrLineaFinanziamentoPrincipaleId : null
            ,abilitaPnrr:row.abilitaPnrr?true:false
            ,abilitaFc:row.abilitaFc?true:false
            ,quotaRipartitaPnrr:row.ripartizionePnrr ? row.ripartizionePnrr.toString() : '0'
            ,quotaRipartitaFc:row.ripartizioneFc ? row.ripartizioneFc.toString() : '0'
            ,quotaRipartitaSarPnrr:row.ripartizioneSarPnrr ? row.ripartizioneSarPnrr.toString() : '0'
            ,quotaRipartitaSarFc:row.ripartizioneSarFc ? row.ripartizioneSarFc.toString() : '0'
        });
        this.formDatiServizio.setValue({
            id: row.id
            , valoreIpotesiDestinato: row.valoreIpotesiDestinato ? row.valoreIpotesiDestinato.toString() : '0'
            , valoreRipartitoRegione: row.valoreRipartitoRegione ? row.valoreRipartitoRegione.toString() : '0'
            ,decretoDiRipartoDaFare: row.decretoDiRipartoDaFare
            ,decretoDiRipartoAssegnato: row.decretoDiRipartoAssegnato
            ,bandoRegionaleDaRipartire: row.bandoRegionaleDaRipartire
            ,bandoRegionaleRipartito: row.bandoRegionaleRipartito
            ,bandoRegionaleBandito: row.bandoRegionaleBandito
            ,bandoRegionaleAssegnato: row.bandoRegionaleAssegnato
            ,bandoMinisterialeDaBandire: row.bandoMinisterialeDaBandire
            ,bandoMinisterialeBandito:row.bandoMinisterialeBandito
            ,bandoMinisterialeAssegnato: row.bandoMinisterialeAssegnato
            ,programmazione:row.programmazione?'1':'0'
            ,attuazione:row.attuazione?'1':'0'
            ,proponente:row.proponente?'1':'0'
            ,beneficiarioDiretto:row.beneficiarioDiretto?'1':'0'
            ,coProgrammazione:row.coProgrammazione?'1':'0'
            ,beneficiarioDirettoDescrizione:row.beneficiarioDirettoDescrizione
            ,coProgrammazioneDescrizione:row.coProgrammazioneDescrizione
            ,noteDatiServizio:row.noteDatiServizio
            ,impattoTerritoriale:row.impattoTerritoriale?'1':'0'
            ,attuazioneDescrizione:row.attuazioneDescrizione
        });


        this.missioneChange(null);
        this.tipologiaOnChange();
        this.addNationForm.controls.codicePrimaParte.setValue(this.getCodicePrimaParte());
        const codicePrimaParte = this.getCodicePrimaParte();
        this.addNationForm.controls.codiceSecondaParte.setValue(row.codice.replace(codicePrimaParte, ''));
        this.isPnrrLineaFinanziamentoPrincipale = row.isPnrrLineaFinanziamentoPrincipale;
        if (row.isPnrrLineaFinanziamentoPrincipale){
            this.f.pnrr.disable();
            this.f.fc.disable();
            this.abilitaPnrrChange();
            this.abilitaFcChange();
        }
        this.activeTab = this.setActiveTab();
        if (this.setDisabledFormDatiGenerali()) {
            this.addNationForm.disable();
        }

        //if (this.isViewer())this.activeTab='tabDatiServizio';
    }

    abilitaPnrrChange(){
        console.log(this.f.abilitaPnrr.value);
        const v = this.f.abilitaPnrr.value;
        if (v)this.f.pnrr.enable();
        else this.f.pnrr.disable();
    }
    abilitaFcChange(){
        console.log(this.f.abilitaFc.value);
        const v = this.f.abilitaFc.value;
        if (v)this.f.fc.enable();
        else this.f.fc.disable();
    }

    loading: boolean = false;
    onDelete(row) {
        console.log(row);
        if (!confirm(`Stai per eliminare la linea di finanziamento PNRR/PNC "${row.codice}", proseguire?`)) return;
        this.loading = true;
        this.pnrrService.deleteLineaFinanziamento(row.id).subscribe(
            result => {
                this.loading = false;
                this.refreshGrid();
                this.toastr.success('Linea di finanziamento PNRR/PNC eliminata con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile eliminare la linea di finanziamento PNRR/PNC: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                console.log('error');
            });
    }
    closeModal() {
        if (this.modalRef) this.modalRef.hide();
        this.isEdit = false;
        this.activeTab = this.setActiveTab();
        this.addNationForm.reset();
        this.formDatiServizio.reset();
        this.isPnrrLineaFinanziamentoPrincipale = false;
        this.confirmChangeTab(null, 0);
        this.missioneChange(null);
        this.tipologiaOnChange();
        this.f.pnrr.enable();
        this.f.fc.enable();
        if (!this.setDisabledFormDatiGenerali()) {
            this.addNationForm.enable();
        }
    }

    missioni = [];
    componenti = [];
    tutteComponenti = [];
    pnrrLineeFinanziamentoInvestimentiRiforme = [];

    getMissioni() {
        this.pnrrService.getAllMissione().subscribe(result => this.missioni = result);
    }
    getComponenti() {
        this.pnrrService.getAllComponente().subscribe(result => this.tutteComponenti = result);
    }

    getPnrrLineeFinanziamento() {
        this.pnrrService.getAllLineaFinanziamento().subscribe(
            result =>{
                this.pnrrLineeFinanziamentoInvestimentiRiforme =
                result.filter(x => x['tipologia'] != 'sub-investimento' && x['tipologia'] != 'sub-riforma').sort((a,b)=>a['codice']<b['codice']?-1:1);
                this.pnrrLineeFinanziamentoInvestimentiRiforme =
                    this.pnrrLineeFinanziamentoInvestimentiRiforme.map(x=>{
                        x['descrizioneEstesa'] = `${x['codice']} - ${x['missione']['descrizione']} - ${x['componente']['descrizione']} - ${x.descrizione}`;
                        return x;
                    });
            }
        );
    }

    hidepnrrLineaFinanziamentoPrincipale(){
        const value = this.f.tipologia.value;
        if (value != 'sub-riforma' && value != 'sub-investimento')return true;
        return (this.isPnrrLineaFinanziamentoPrincipale) ;
    }

    tipologiaOnChange() {
        const value = this.f.tipologia.value;
        if (value == 'sub-riforma' || value == 'sub-investimento') {
            this.addNationForm.controls['pnrrLineaFinanziamentoPrincipale'].setValidators([Validators.required]);
            this.addNationForm.controls['pnrrLineaFinanziamentoPrincipale'].updateValueAndValidity();
           // this.hidepnrrLineaFinanziamentoPrincipale = false;
            this.f.abilitaPnrr.setValue(true);
            this.f.abilitaFc.setValue(true);
        }
        else {
            this.addNationForm.controls['pnrrLineaFinanziamentoPrincipale'].clearValidators();
            this.addNationForm.controls['pnrrLineaFinanziamentoPrincipale'].updateValueAndValidity();
            //this.hidepnrrLineaFinanziamentoPrincipale = true;
        }
    }


    missioneChange($event) {
        let missione = this.addNationForm.controls.missione.value;
        this.componenti = [];
        if (missione) this.componenti = this.tutteComponenti.filter(x => x.missione.id == missione);
        this.resetCodice($event);
    }

    getCodicePrimaParte() {
        const idMissione = this.f.missione.value ? this.f.missione.value : -1;
        const idComponente = this.f.componente.value ? this.f.componente.value : -1;
        const missione = this.missioni.find(x => x.id == idMissione);
        const componente = this.componenti.find(x => x.id == idComponente);
        return `${missione ? missione.codice : ''}${componente ? componente.codice : ''}`;
    }

    resetCodice($event) {
        this.addNationForm.controls.codicePrimaParte.setValue(this.getCodicePrimaParte());
    }
    activeTab = this.setActiveTab();
    private setActiveTab(): string {
        return !this.isEdit
                    ?   'tabDatiGenerali'
                    :   this.checkPermissions(['read', 'update'], 'PNRR - Linee di Finanziamento - Dati Generali', true)
                            ? 'tabDatiGenerali'
                            : this.checkPermission('update', 'PNRR - Linee di Finanziamento - Dati di Servizio')
                                ? 'tabDatiServizio'
                                : this.checkPermissions(['read', 'update'], 'PNRR - Linee di Finanziamento - Fasi', true)
                                    ? 'tabFasi'
                                    : 'tabStorico';
    }

    @ViewChild(FasiComponent, { static: true })
    tabFasiContent: FasiComponent;

    @ViewChild("divTemplateStorico", { static: true })
    divTemplateStorico: StoricoPnrrLineaFinanziamentoComponent;

    confirmChangeTab($event, num) {
        switch (num) {
            case 0:
                this.activeTab = 'tabDatiGenerali';
                break;
            case 1:
                this.activeTab = 'tabFasi';
                if (this.tabFasiContent)
                    this.tabFasiContent.reloadData();
                break;
            case 2:
                this.activeTab = 'tabDatiServizio';
                break;
            case 3:
                this.activeTab = "tabStorico";
                if (this.divTemplateStorico) this.divTemplateStorico.reloadAllData();
            break;
        }
    }

    loadingExportExcel = false;


    esportaExcel() {
        this.loadingExportExcel = true;
        this.pnrrService.esportaPnrrLineeFinanziamentoExcel().subscribe(
            res => {
                this.loadingExportExcel = false;
                let filename = `esportazione_pnrr.xlsx`;
                FileSaver.saveAs(
                    res.body,
                    `${filename}`
                );
            }
            , error => {
                this.loadingExportExcel = false;
                this.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );
    }

    onSubmitServizio() {
        this.submitted = true;
        this.formDatiServizio.updateValueAndValidity();

        let errors = false;
        for (let c in this.formDatiServizio.controls) {
            errors = errors || this.formDatiServizio.controls[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.formDatiServizio.controls[c].errors)
            );
        }
        if (this.formDatiServizio.invalid || errors) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.formDatiServizio.controls) {
                if (this.formDatiServizio.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.formDatiServizio.controls[el] instanceof FormGroup) {
                        for (let el1 in this.formDatiServizio.get(el)["controls"]) {
                            if (this.formDatiServizio.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }

        const obj = this.formDatiServizio.value;
        obj.programmazione = obj.programmazione=='1';
        obj.attuazione = obj.attuazione=='1';
        obj.proponente = obj.proponente=='1';
        obj.beneficiarioDiretto = obj.beneficiarioDiretto=='1';
        obj.coProgrammazione = obj.coProgrammazione=='1';
        obj.impattoTerritoriale = obj.impattoTerritoriale=='1';
        this.pnrrService.saveLineaFinanziamentoExtraDati(obj).subscribe(res => {
            this.toastr.success("Linea di finanziamento PNRR/PNC salvata con successo!");
            this.refreshGrid();
            this.submitted = false;
            this.open = false;
            this.closeModal();
        }, error => {
            this.toastr.error("Codice già presente");
        })
    }

    soggettiProponenti = [];
    getSoggettiGiuridici() {
        this.soggettoGiuridicoService.getAll().subscribe(
            res => {
                let ress: any[]; ress = res; ress.map((i) => {
                    i.descrizione = `${i.denominazione} (${i.codiceIpa})`;
                    return i;
                });
                this.soggettiProponenti = ress.filter(x => x.codiceIdentificativo && (x.codiceIdentificativo.toLowerCase() == 'propon_pnrr' || x.codiceIdentificativo.toLowerCase() == 'ammtit_pnrr'));
            }
        );
    }

    esportaRR05() {
        this.loadingExportExcel = true;
        this.pnrrService.esportaPnrrLineeFinanziamentoRr05().subscribe(
            res => {
                this.loadingExportExcel = false;
                let filename = `esportazione_rr05.pdf`;
                FileSaver.saveAs(
                    res.body,
                    `${filename}`
                );
            }
            , error => {
                this.loadingExportExcel = false;
                this.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );
    }

    esportaRR05Excel(){
        this.loadingExportExcel = true;
        this.pnrrService.esportaPnrrLineeFinanziamentoRr05Excel().subscribe(
            res => {
                this.loadingExportExcel = false;
                let filename = `esportazione_rr05.xls`;
                FileSaver.saveAs(
                    res.body,
                    `${filename}`
                );
            }
            , error => {
                this.loadingExportExcel = false;
                this.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );
    }


    doEsportaRR07() {
        this.loadingExportExcel = true;
        const escludiLineeSenzaRipartizioni = this.exportForm.controls.escludiLineeSenzaRipartizioni.value;
        this.closeModalEsportazione();
        this.pnrrService.esportaPnrrLineeFinanziamentoRr07(escludiLineeSenzaRipartizioni).subscribe(
            res => {
                this.loadingExportExcel = false;
                let filename = `esportazione_rr07.pdf`;
                FileSaver.saveAs(
                    res.body,
                    `${filename}`
                );
            }
            , error => {
                this.loadingExportExcel = false;
                this.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );
    }
    exportForm:FormGroup;
    @ViewChild('modalExportDataRR07', { static: true })
    public modalExportDataRR07: TemplateRef<any>;
    modalExportDataRR07Ref: BsModalRef;
    closeModalEsportazione() {
        if(this.modalExportDataRR07Ref)this.modalExportDataRR07Ref.hide();
    }

    openModalEsportaRR07() {
        const config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xs"
        };
        this.exportForm.reset();
        this.exportForm.controls.escludiLineeSenzaRipartizioni.setValue(true);
        this.modalExportDataRR07Ref = this.modalService.show(this.modalExportDataRR07, config);
    }

    isAdmin() {
       // return true;//con la nuova gestione permessi e menu, sapendo che il gruppo pnrr deve accedere a questa sezione, non posso piu gestire isAdmin
			return this.authService.isAdmin();
    }
    /*isViewer() {
			return this.authService.isViewer();
    }*/

    /**
     * Controlla se l'utente può accedere o meno alla sezione.
     * @param section il nome della sezione
     * @param operations le operazioni da poter effettuare sulla sezione
     * @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
     * @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
     * @returns true se il controllo è andato a buon fine, false altrimenti
    */
    private enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
      return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
    }

    checkPermission(operation: string, section: string = this.section) {
        return this.enableSection(section,  [operation], false, this.isAdmin() || this.isGruppoPnrr());
      //return (!this.isAdmin() && this.enableSection(section, [operation], false, false)) || (this.isAdmin() && this.enableSection(section, [operation]));
    }

    checkPermissions(operations: string[], section: string = this.section, atLeastOne: boolean = false) {
			return this.enableSection(section, operations, atLeastOne, this.isAdmin() || this.isGruppoPnrr());
    }
    isGruppoPnrr() {
        return this.authService.isGruppoPnrr();
    }


    esportaExcelVerificaScostamenti() {
        this.loadingExportExcel = true;
        this.pnrrService.esportaPnrrLineeFinanziamentoVerificaScostamentiExcel().subscribe(
            res => {
                this.loadingExportExcel = false;
                let filename = `esportazione_pnrr_scostamenti.xlsx`;
                FileSaver.saveAs(
                    res.body,
                    `${filename}`
                );
            }
            , error => {
                this.loadingExportExcel = false;
                this.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );

    }
}
