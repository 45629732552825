<div #view class="card border-primary mb-3">
  <div class="card-header text-white bold h6 text-uppercase noHover" style="letter-spacing: 0.8px;">
    {{ menuItem.title }}
  </div>

  <div class="card-body text-0083e0">  
    <div class="card-columns {{ menuItem.subMenu.length % 3 === 0 ? '' : menuItem.subMenu.length % 4 === 0 ? 'custom' : '' }}">
      <div *ngFor="let item of menuItem.subMenu" class="card shadow-sm">
        <app-card-menu style="cursor: pointer;" [item]="item" [title]="item.title" [url]="item.link" [icon]="item.icon"></app-card-menu>
      </div>
    </div>
  </div>
</div>
