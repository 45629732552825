import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UserSection } from 'src/app/models/userSection';
import { ToastService, UserService } from 'src/app/services';

@Component({
  selector: 'app-add-permissions',
  templateUrl: './add-permissions.component.html',
  styleUrls: ['./add-permissions.component.css']
})
export class AddPermissionsComponent implements OnInit {

  @Input()
  userId: number;
  @Input()
  sections: any[];
  @Output()
  permissionSelectedEmit = new EventEmitter<boolean>();
  @Output()
  permissionEmit = new EventEmitter<UserSection>();
  @ViewChild(NgSelectComponent, { static: false })
  select: NgSelectComponent;
  sectionSelected = null;
  mapSectionsValues: Map<string, boolean> = new Map<string, boolean>();

  constructor(
    private userService: UserService,
    private toastr: ToastService
  ) { }

  ngOnInit() { }

  onChange($event: any) {
    this.sectionSelected = $event;
    this.permissionSelectedEmit.emit($event ? true : false);
  }

  enableHandler(name: string, value: any) {
    if (!value) {
      this.mapSectionsValues.delete(name);
    } else {
      this.mapSectionsValues.set(name, value.target.checked);
    }
  }

  onSubmit() {
    const us: UserSection = new UserSection();
    us.idUser = this.userId;
    us.idSection = this.sectionSelected.id;
    us.insert = this.checkMapValue('insert');
    us.update = this.checkMapValue('update');
    us.read = this.checkMapValue('read');
    us.delete = this.checkMapValue('delete');
    this.userService.saveNewUserPermission(us).subscribe(resp => {
      this.select.handleClearClick();
      this.permissionEmit.emit(resp);
    }, error => {
      this.toastr.error('Errore');
    });
  }

  checkMapValue(field: string): boolean {
    const value = this.mapSectionsValues.get(field);
    return value ? value : false;
  }
}
