<!-- Header Top -->
<nav [hidden]="
    router.url.includes('dashboard-finanziaria') ||
    router.url.includes('dashboard-fasi') ||
    router.url.includes('dashboard-stato-finanziamento') ||
    router.url.includes('dashboard-stato-intervento') ||
    router.url.includes('dashboard-interventi') ||
    router.url.includes('dashboard-pnrr') ||
    router.url.includes('dashboard-non-disegnabile') ||
    router.url.includes('dashboard-pkmg') ||
    router.url.includes('dashboard-entrata-uscita') ||
    router.url.includes('dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr') ||
    router.url.includes('dashboard-sisma')||
    router.url.includes('cruscotto-note-atti')
  " class="navbar navbar-expand-sm sticky-top preheader" style="z-index: 1022">
  <div class="container">
    <a class="text-white" title="{{ente.nome}}" href="{{ente.sitoWeb}}" target="_blank" rel=”noopener”>
      {{ente.nome}}
    </a>
    <span class="text-white">
      {{titleExtraText}}
    </span>

    <div class="float-right text-white" *ngIf="isUserlogged()">

      <a routerLink="account/me" class="text-white" title="Account">
        <span>{{ getCurrentUsername() }}</span>
      </a>
      &nbsp;|&nbsp;
      <a type="button" class="" title="Menù Utente" [popover]="popTemplate">
        <i class="fas fa-user-tie" aria-label="menù utente" ></i>
      </a>
    </div>
  </div>
</nav>
<!-- Header -->
<header [hidden]="
    router.url.includes('dashboard-finanziaria') ||
    router.url.includes('dashboard-fasi') ||
    router.url.includes('dashboard-stato-finanziamento') ||
    router.url.includes('dashboard-stato-intervento') ||
    router.url.includes('dashboard-interventi') ||
    router.url.includes('dashboard-pnrr') ||
    router.url.includes('dashboard-pkmg') ||
    router.url.includes('dashboard-non-disegnabile') ||
    router.url.includes('dashboard-entrata-uscita') ||
    router.url.includes('dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr') ||
    router.url.includes('dashboard-sisma')||
    router.url.includes('cruscotto-note-atti')
  " class="mainheader">

  <!-- Logo E Titolo -->

  <div [hidden]="isHeaderCustom()" class="container" [ngClass]="{
      fascia_titolo_login: isUserlogged(),
      fascia_titolo: !isUserlogged()
    }" >

    <div class="d-flex justify-content-between align-items-center">


      <div class=" d-flex align-items-center" [ngClass]="{
        'logo-img-login': isUserlogged(),
        'logo-img': !isUserlogged()
      }">
        <img class="" style="height: 85px; " src="{{ente.logo}}"
          alt="logo {{ente.nome}}" />

        <h4 class="m-0 text-uppercase text-white">{{ente.nome}}</h4>
      </div>

      <div class=" flex-nowrap" (click)="goToHome()" style="cursor: pointer" title="Vai alla Home Page">
        <img class=" my-2" style="height: 85px; " src="{{logo}}"
          alt="logo {{title}}" />
      </div>
    </div>
  </div>

</header>


<div *ngIf="isUserlogged()" #menuElement [class.menu_fisso]="menuFixed" class="sticky-top" style="top: 40px; z-index: 1021">
  <app-header-menu *ngIf="router.url !== '/'"></app-header-menu>
  <app-bar-menu *ngIf="router.url === '/'"></app-bar-menu>
</div>


<ng-template #popTemplate>
  <ul class="list-group list-group-flush" >
      <a role="button" (click)="signOut()" class="list-group-item list-group-item-action">
        <i class="fa fa-sign-out-alt" aria-hidden="true"></i> LOG OUT
      </a>
      <a *ngIf="isRegistered()" routerLink="account/me" class="list-group-item list-group-item-action" >
        <i class="fa fa-user-circle" aria-hidden="true" ></i> ACCOUNT
      </a>
      <a *ngIf="!isLdapUser" routerLink="changePassword" class="list-group-item list-group-item-action" >
        <i class="fa fa-key" aria-hidden="true"></i> MODIFICA PASSWORD
      </a>

  </ul>
</ng-template>
