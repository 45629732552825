import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    TemplateRef
  } from "@angular/core";
  import { FormGroup, FormBuilder, Validators } from "@angular/forms";
  import { HttpClient } from "@angular/common/http";
  import { ToastService } from 'src/app/services/toast.service';
  import { BsLocaleService } from 'ngx-bootstrap/datepicker';
  import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SoggettoGiuridico } from '../../models';
import { SoggettoGiuridicoService } from '../../services';
import {GeoService} from 'src/app/services/geo.service';
import { Province } from 'src/app/models/province';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { ColumnMode } from '@swimlane/ngx-datatable';
  @Component({
    selector: 'soggetti-giuridici-component',
    templateUrl: './soggetti-giuridici.component.html',
    styleUrls: ['./soggetti-giuridici.component.css']
  })
  export class SoggettiGiuridiciComponent
    implements OnInit {
    dtOptions: any = {};
    visible = false;
    submitted: boolean = false;
    editForm: FormGroup;
    modalRef: BsModalRef;
    open: boolean = false;
    provinces:Province[];
  provinceSelected:Province;

  @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;
    @ViewChild('templateModal', { static: true })
    public templateModal: TemplateRef<any>;

    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;


    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    columns = [
        {
            name: 'id', prop: 'id', visible: false, sortable: true
            , filtrable: true, width: 10
        }
        , {
            name: 'codice identificativo'
            , prop: 'codiceIdentificativo'
            , sortable: true
            , filtrable: true
            , flexGrow: 3
            , minWidth: 100


        }
        , {
            name: 'codice ipa'
            , prop: 'codiceIpa'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            , minWidth: 60


        }
        , {
            name: 'denominazione'
            , prop: 'denominazione'
            , sortable: true
            , filtrable: true
            //,cellClass: 'mobile-hidden desktop-hidden'
            //,headerClass: 'mobile-hidden desktop-hidden'
            , flexGrow: 7
            , minWidth: 200
        }
        , {
            name: 'provincia'
            , prop: 'siglaProvincia'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            , minWidth: 60


        }
        , {
            name: 'modifica'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60

        }
        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60
        }
    ];


    templateList = [];
    constructor(
      private http: HttpClient,
      private formBuilder: FormBuilder,
      private el: ElementRef,
      private localeService: BsLocaleService,
      private toastr: ToastService,
      private modalService: BsModalService,
      private currentService:SoggettoGiuridicoService
      ,private geoService:GeoService
    ) {
      this.localeService.use("it");
      this.dtOptions = {
        rows: []
        ,totalElements:0
        , columns: this.columns
        , columnMode: ColumnMode.flex
        , headerHeight: "50"
        , footerHeight: "50"
        , rowHeight: "auto"
        , externalSorting: true// true
        , loadingIndicator: 'loanding'
        , class: "bootstrap"
        , summaryRow: true
        , summaryPosition: "'bottom'"
        , externalPaging: true// true
        , count: 0
        , offset: 0
        , limit: 10
        , serverSide: true
        ,scrollbarH:"true"
        ,ajax:this.getTipologie
    };
    }

    ngOnInit() {
      this.getProvinces();
      this.templateList = [ { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }, { name: 'btnEditTemplate', template: this.btnEditTemplate }];
        window.scroll(0, 0);
        this.editForm = this.initializeFormGroup();
       // this.getTipologie();
    }



    getProvinces(){
        this.geoService.getAllProvinceDt(0,{draw:1,columns:[],order:[],start:0,length:2000,search:{}})
        .subscribe(resp => {
          this.provinces = resp.data;
        });
      }

    initializeFormGroup() {
      return this.formBuilder.group({
        id:[null],
        codiceIdentificativo: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
        codiceipa: ['', [Validators.required, Validators.maxLength(50)]],
        denominazione: ['', [Validators.required, Validators.maxLength(255)]],
        provincia: ['', [Validators.required]],

      });
    }

    get f() {
      return this.editForm.controls;
    }

    onSubmit() {
      this.submitted = true;
      this.editForm.updateValueAndValidity();
      let errors = false;
      for (let c in this.f) {
        errors = errors || this.f[c].errors !== null;
        console.log(
          "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
        );
      }
      if (this.editForm.invalid || errors) {
        console.log("invalid");
        let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
        for (let el in this.editForm.controls) {
          if (this.editForm.controls[el].invalid) {
            console.log(`set focus on ${el}`);
            if (this.editForm.controls[el] instanceof FormGroup) {
              for (let el1 in this.editForm.get(el)["controls"]) {
                if (this.editForm.get(el)["controls"][el1].invalid) {
                  invalidElements = this.el.nativeElement.querySelectorAll(
                    `[formcontrolname="${el1}"]`
                  );
                  break;
                }
              }
            } else {
              invalidElements = this.el.nativeElement.querySelectorAll(
                `[formcontrolname="${el}"]`
              );
            }
            break;
          }
        }
        if (invalidElements.length > 0) {
          invalidElements[0].focus();
        }

        return false;
      }

      const newSchoolYear: SoggettoGiuridico = new SoggettoGiuridico();
      newSchoolYear.denominazione = this.f.denominazione.value;
    newSchoolYear.codiceIdentificativo = this.f.codiceIdentificativo.value;
    newSchoolYear.siglaProvincia = this.provinceSelected.acronym;
    newSchoolYear.codiceIpa = this.f.codiceipa.value;
    newSchoolYear.id = this.f.id.value;

    this.currentService.save(newSchoolYear).subscribe(res => {
        this.toastr.success("Soggetto giuridico salvato con successo!");
        this.submitted = false;
        this.open = false;
        this.editForm.reset();

        this.buttonLabel="Inserisci";
        if (!newSchoolYear.id){
            //new row
            this.ngxDatatableWrapper.addRow(res);
        }
        else{
            this.modalRef.hide();
            this.ngxDatatableWrapper.updateRow(newSchoolYear);
        }

    }, error => {
        this.toastr.error("Errore di sistema");
    });
    }

    onProvincesChange($event:Province){
        this.provinceSelected = $event;
    }

    buttonLabel:string="Inserisci";
    loading:boolean=false;
    config = {
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };
    onEdit(row) {
        this.submitted = false;
        console.log(row);
        this.buttonLabel = "Aggiorna";
        this.modalRef = this.modalService.show(this.templateModal, this.config);
        let prov = this.provinces.find(x=>x.acronym == row.siglaProvincia);
        this.provinceSelected = prov;
        this.editForm.setValue({
            id: row.id,
            denominazione:row.denominazione,
            codiceIdentificativo:row.codiceIdentificativo,
            codiceipa:row.codiceIpa,
            provincia:prov?prov.id:null
        });
    }
    onDelete(row) {
        console.log(row);
        if (!confirm(`Stai per eliminare il soggetto giuridico "${row.codiceIdentificativo} - ${row.denominazione}", proseguire?`)) return;
        this.loading = true;
        this.currentService.delete(row.id).subscribe(
            result => {
                this.loading = false;
                this.ngxDatatableWrapper.deleteRow(row);
                this.toastr.success('Soggetto giuridico eliminato con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile eliminare il soggetto giuridico: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                console.log('error');
            });
    }
    closeModal(){
        this.modalRef.hide();
        this.editForm.reset();
        this.buttonLabel="Inserisci";
    }

    /*getTipologie() {
        this.currentService.getAll().subscribe(
            result => {

                this.ngxDatatableWrapper.setInitialData(result);

            }
        );

    }*/
    getTipologie = (dataTablesParameters: any, callback: any) => {
        let that = this;
        this.currentService.getAllDt(dataTablesParameters)
          .subscribe(resp => {
            callback(resp)
          });
      }
}


