import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  DashboardService,
  ProgettoService,
  SoggettoGiuridicoService,
  StorageService,
  TipoClassificazioneService,
  TipoStrumentoAttuativoService,
} from "../../services";
import * as moment from "moment";
import * as FileSaver from "file-saver";
import { ToastService } from "src/app/services/toast.service";
import { AuthService } from "src/app/services";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Progetto } from "../../models";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
@Component({
  selector: "dashboard-entrata-uscita-component",
  templateUrl: "./dashboard-entrata-uscita.component.html",
  styleUrls: ["./dashboard-entrata-uscita.component.css"],
})
export class DashboardEntrataUscitaComponent implements OnInit, OnDestroy {
  showLoading: boolean = false;
  loadingExportExcel: boolean = false;
  soggettiGiuridiciCombo: [];
  elencoTipiFinSubscription = null;
  fontiFianziamentoList: [];
  progettualitaItems: Progetto[] = [];
  myForm = null;
  classificazioniCombo: any[] = [];
  areeTematicheCombo: any[] = [];
  interventionDescription: string;

  exportForm:FormGroup;

  constructor(
    private dashboardService: DashboardService,
    private toastr: ToastService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private soggettiService: SoggettoGiuridicoService,
    private storageService: StorageService,
    private progettoService: ProgettoService,
    private classificazioniService: TipoClassificazioneService,
    private strumentiAttuativiService:TipoStrumentoAttuativoService
    ,private modalService: BsModalService
  ) {}
  ngOnDestroy(): void {
    if (this.elencoTipiFinSubscription)
      this.elencoTipiFinSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.myForm = this.initializeFormGroup();
    this.getSoggettiGiuridici();
    this.getFontiFinanziamento();
    this.getProgettualita();
    this.getClassificazioni();
    this.getAreeTematiche();
    this.getStrumentiAttuativi();

    this.exportForm = this.formBuilder.group({
        conClassificazioni: [null],
        soloFiltrate:[null]
      });
  }

  initializeFormGroup() {
    return this.formBuilder.group({
      fonteFinanziamento: [null],
      soggettoGiuridico: [null],
      progettualita: [null],
      classification: [null],
      direction: [null],
      interventi: [null],
      strumentoAttuativo:[null]
    });
  }

  setIntervention($event) {
    console.log("Intervento", $event);
    this.filterObject.intervento = $event ? $event.id : null;
  }

  getProgettualita() {
    this.progettoService.getAll().subscribe((progetti) => {
      this.progettualitaItems = progetti
        .filter((proget) => !proget.isDeleted)
        .map((prog) => {
          prog["label"] = `${prog.codice} - ${prog.descrizione}`;
          return prog;
        });
    });
  }

  setProgettualita($event) {
    this.filterObject.progettualita = $event ? $event.id : null;    
  }

  getAreeTematiche() {  
      this.storageService.elencoAreeTematiche.subscribe((x) => {
        this.areeTematicheCombo = x || [];
      });
  }

  setStrumentoAttuativo($event) {
    this.filterObject.strumentoAttuativo = $event ? $event.id : null;    
  }

  strumentiAttuativiCombo:any = [];
  getStrumentiAttuativi() {  
    this.strumentiAttuativiService.getAll().subscribe((x) => {
      this.strumentiAttuativiCombo = x || [];
    });
}

setAreaTematica($event) {
  this.filterObject.direction = $event ? $event.id : null;  
}

  filterComboInterventi(filterString, callBack) {
    let f = {
      descriptionSearchString: filterString,
    };
    this["context"].dashboardService
      .getDashboardPnrrInterventiList(f)
      .subscribe((x) => {
        x = x.map((y) => {
          y["descrizioneEstesa"] = `${y.codice} - ${y.descrizione}`;
          return y;
        });
        if (callBack) callBack(x);
      });
  }

  get f() {
    return this.myForm.controls;
  }

  completerParams = {
    labelField: "descrizioneEstesa",
    placeholder: "Cerca intervento",
    ajax: this.filterComboInterventi,
    items: [],
    context: this,
  };

  getSoggettiGiuridici() {
    this.soggettiService.getAll().subscribe((x) => {
      this.soggettiGiuridiciCombo = x;
    });
  }

  getFontiFinanziamento() {
    this.elencoTipiFinSubscription =
      this.storageService.elencoTipiFinanziamento.subscribe((x) => {
        this.fontiFianziamentoList = x || [];
      });
  }

  filterObject = {
    intervento: null,
    progettualita: null,
    direction: null,
    classification: null,
    strumentoAttuativo:null,
    opzioniClassificazioni:null,
    isSintesi:false
  };

  
  setClassificazione($event) {
    this.filterObject.classification = $event ? $event.id : null;    
  }

  

  getClassificazioni() {
    this.classificazioniService.getAll().subscribe((x) => {
      /*this.classificazioniCombo = [
        { id: -2, tipo: "Nessuna classificazione" },
        ...x,
      ];*/
      this.classificazioniCombo = x;
    });
  }

  esportaExcelEntrata() {
    const soggetto = this.myForm.controls.soggettoGiuridico.value; //è null o un numero
    let fonti = this.myForm.controls.fonteFinanziamento.value; //è un valore, una lista di valori separati da virgola, null, o stringa vuota
   
    if (fonti && fonti =='') {
      fonti = null;      
    }


    this.loadingExportExcel = true;
    this.dashboardService
      .esportaDashboardEntrata(soggetto, fonti, this.filterObject)
      .subscribe(
        (res) => {
          const filename = `esportazione_entrata_${moment(new Date()).format(
            "YYYY_MM_DDTHH_mm_ss"
          )}.xlsx`;
          FileSaver.saveAs(res.body, `${filename}`);
          this.loadingExportExcel = false;
          console.log(res);
        },
        (error) => {
          this.toastr.error(
            `Errore: ${
              error.error.message
                ? error.error.message
                : error.error.error_description
                ? error.error.error_description
                : error.error
            }`,
            null,
            {
              timeOut: 2000,
              disableTimeOut: false,
            }
          );
          this.loadingExportExcel = false;
        }
      );
    console.log(this.filterObject);
  }

  esportaExcelUscita() {
    const soggetto = this.myForm.controls.soggettoGiuridico.value; //è null o un numero
    let fonti = this.myForm.controls.fonteFinanziamento.value; //è un valore, una lista di valori separati da virgola, null, o stringa vuota
    if (fonti && fonti =='') {
        fonti = null;      
    }
    this.filterObject.opzioniClassificazioni = 0;
    if (this.exportForm.value.conClassificazioni){
        if (this.exportForm.value.soloFiltrate)
            this.filterObject.opzioniClassificazioni = 2;
        else
        this.filterObject.opzioniClassificazioni = 1;
    }
    

    ;
    this.loadingExportExcel = true;
    this.dashboardService
      .esportaDashboardUscita(soggetto, fonti, this.filterObject)
      .subscribe(
        (res) => {
          const filename = `esportazione_uscita_${moment(new Date()).format(
            "YYYY_MM_DDTHH_mm_ss"
          )}.xlsx`;
          FileSaver.saveAs(res.body, `${filename}`);
          this.loadingExportExcel = false;
        },
        (error) => {
          this.toastr.error(
            `Errore: ${
              error.error.message
                ? error.error.message
                : error.error.error_description
                ? error.error.error_description
                : error.error
            }`,
            null,
            {
              timeOut: 2000,
              disableTimeOut: false,
            }
          );
          this.loadingExportExcel = false;
        }
      );
    console.log(this.filterObject);
  }

  isCustomHeader() {
    return this.authService.getHeaderCode() != "generale";
  }
  getHeaderLabel() {
    return this.isCustomHeader()
      ? "Piano Nazionale Ripresa e Resilienza"
      : "Direzione Regionale Infrastrutture e Mobilità";
  }



  @ViewChild("modalExportData", { static: true })
  public modalExportData: TemplateRef<any>;
  modalEsportazioneRef: BsModalRef;
  closeModalEsportazione() {
    this.modalEsportazioneRef.hide();
  }

  openModalClassificazioni() {    
    const config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: "modal-xs",
    };
    this.modalEsportazioneRef = this.modalService.show(
      this.modalExportData,
      config
    );
  }
  checkExportUscita(){
    this.filterObject.isSintesi = false;
    const tipoClassificazione = this.myForm.value.classification;
    if (tipoClassificazione){
        this.openModalClassificazioni();
        return;
    }
    this.esportaExcelUscita();
  }

  checkExportSintesi(){
    this.filterObject.isSintesi = true;
    const tipoClassificazione = this.myForm.value.classification;
    if (tipoClassificazione){
        this.openModalClassificazioni();
        return;
    }
    this.esportaExcelUscita();
  }

}
