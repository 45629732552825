import { Component, OnInit } from '@angular/core';
import { TitleLogoService } from 'src/app/services/title-logo.service';

@Component({
  selector: 'app-header-dashboard',
  templateUrl: './header-dashboard.component.html',
  styleUrls: ['./header-dashboard.component.css']
})
export class HeaderDashboardComponent implements OnInit {

  title: string;

  logo: string;

  ente: any;

  constructor(private titleLogoService: TitleLogoService) { }

  ngOnInit(): void {
    this.title = this.titleLogoService.title;
    this.logo = this.titleLogoService.logo;
    this.ente = this.titleLogoService.ente;
  }

}
