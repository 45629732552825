import { Classificazione } from "./classificazione";
import { SoggettoGiuridico } from "./soggetto-giuridico";
import { AreaTematica } from "./area-tematica";
import { TemaPrioritario } from "./tema-prioritario";
import { Provvedimento } from "./provvedimento";
import { ImportoIntervento } from "./importo-intervento";

export class Intervento {
  id: number;
  codice: string;
  descrizione: string;
  annoRiferimento: number;
  codiceDirezione: string;
  isDeleted: boolean;
  noteResponsabile: string;
  cup: string;
  cig: string;
  presenteInBdu: boolean;
  classificazioni: Classificazione[];
  areeTematiche: AreaTematica[];
  soggettiGiuridici: SoggettoGiuridico[];
  temiPrioritari: TemaPrioritario[];
  provvedimenti: Provvedimento[];
  importi: ImportoIntervento[];
  isMain: boolean;
  isReadOnly: boolean;
  validazione: any;
  disegnato: boolean;
  validationStatus: string;
  drawType: string;
  finanziamenti: any;
  subInterventi: number;
  numSubInterventi:number;

  constructor() {
    this.isDeleted = false;
    //this.classificazione = new Classificazione();
    this.areeTematiche = [];
    this.classificazioni = [];
    this.soggettiGiuridici = [];
    this.temiPrioritari = [];
    this.provvedimenti = [];
    this.importi = [];
  }
}
