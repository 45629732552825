import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TipoFinanziamento, TipoProvvedimento } from '../../../models';
import { TipoProvvedimentoService } from '../../../services';

@Component({
  selector: 'app-search-provvedimento',
  templateUrl: './search-provvedimento.component.html',
  styleUrls: ['./search-provvedimento.component.css']
})
export class SearchProvvedimentoComponent implements OnInit {

  form: FormGroup;
  tipiFinanziamento: TipoFinanziamento[];
  filters: any = null;
  tipiProvvedimento: TipoProvvedimento[];
  itemsComboFilterPnrr = [{ id: 'Si', descrizione: 'Si' }, { id: 'No', descrizione: 'No' }];

  public maskCodice: any = {
    mask: 'KKKKKKKKKKKKKKKKKK €',
    lazy: false,  // make placeholder always visible
    blocks: {
      KKKKKKKKKKKKKKKKKK: {
        mask: Number,
        thousandsSeparator: '.',
        scale: 2,
        padFractionalZeros: true,
        radix: ','
      }
    }
  };

  loadComponent = false;
  disableSearch = false;

  constructor(
    private tipoProvvedimentoService: TipoProvvedimentoService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);

    this.form = this.initializeFormGroup();

    this.getTipiProvvedimento();
  }

  private initializeFormGroup() {
    return this.formBuilder.group({
      tipo: [null],
      data: [null],
      numero: [null],
      oggetto: [null],
      pnrr: [null]
    });
  }

  getTipiProvvedimento() {
      this.tipoProvvedimentoService.getAll().subscribe(
          x => this.tipiProvvedimento = x
      );
  }

  checkDisableSearch(): boolean {
    return this.disableSearch || (!this.f.tipo.value && !this.f.data.value && !this.f.numero.value && !this.f.oggetto.value && !this.f.pnrr.value);
  }

  get f() {
    return this.form.controls;
  }

  disableFilters($event: boolean) {
    $event ? this.form.disable() : this.form.enable();
    this.disableSearch = $event;
  }

  setDisableSearchButton($event: boolean) {
    this.disableSearch = $event;
  }

  onSubmit() {
    this.disableSearch = true;
    this.loadComponent = true;
    this.filters = this.form.getRawValue();
    const date = this.filters.data;
    date ? date.setHours(date.getHours() + 2) : null;
    this.filters.data = date;
  }
}
