import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AlertService } from "src/app/services/alert.service";

@Component({
  selector: "app-alert",
  templateUrl: "alert.component.html"
})
export class AlertComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  message: any;
  show: boolean = false;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.subscription = this.alertService.getMessage().subscribe(message => {
      this.message = message;
      console.log(
        "inside alertservice.message " + JSON.stringify(this.message)
      );
      this.show = this.message && this.message.text && this.message.text != "";
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
