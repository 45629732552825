import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { ImageHelper } from "src/app/helpers/image.helper";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: "root",
})
export class TipologiaInterventiService {
  public DEFAULT_TIPOLOGIA_ICON: string = "assets/img/generico.png";

  public ASSETS_ICONS = {
    road: "assets/img/road.png",
    park: "assets/img/parcheggi-default.png",
    nodes: "assets/img/nodi-default.png",
    rail: "assets/img/rail.png",
    station: "assets/img/station.png",
    metro: "assets/img/metro.png",
    metrostop: "assets/img/metrostation.png",
    port: "assets/img/porto.png",
    bike: "assets/img/bike.png",
    parkland: "assets/img/parchi-default.png",
    emobility: "assets/img/emobility-default.png",
    skilift: "assets/img/funivia.png",
    other_points: "assets/img/building.png",
    points: "assets/img/scuola_save.png",
    other_lines: "assets/img/altre-vie-comunicazione_save.png",
    hospitals: "assets/img/hospital.png",
    unmapped: "assets/img/non-disegnato.png",
    asl: "assets/img/asl.png",
    plants: "assets/img/plants.png",
    networks_satellites: "assets/img/networks_satellites.png",
    bonifiche_territorio_punti: "assets/img/bonifiche_territorio_punti.png",
    bonifiche_territorio_linee: "assets/img/bonifiche_territorio_linee.png",
    musei_cultura: "assets/img/musei_cultura.png",
    luoghi_di_culto: "assets/img/luoghi_di_culto.png",
    cimiteri: "assets/img/cimiteri.png",
    aeroporti: "assets/img/aeroporti.png",
    impianti_sportivi: "assets/img/impianti_sportivi.png",
    interventi_sul_sociale: "assets/img/interventi_sul_sociale.png",
    stazioni_autolinee: "assets/img/stazioni_autolinee.png",
    impianti_energie_rinnovabili: "assets/img/impianti_energie_rinnovabili.png",
    opere_urbanizzazione:"assets/img/opere_urbanizzazione.png"
  };

  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}tipologiaInterventi/`;
  private publicApiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob_public}tipologiaInterventi/`;
  private apiUploadFile = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.file}`;

  constructor(private http: HttpClient, private imageHelper: ImageHelper) {}

  getTipologiaInterventi() {
    return this.http.get<any[]>(`${this.publicApiUrl}`);
  }

  getAllDt(dataTablesParameters: any) {
    return this.http.post<DataTablesResponse>(
      `${this.apiUrl}allDt`,
      dataTablesParameters
    );
  }

  insert(s: any) {
    return this.http.post<any>(`${this.apiUrl}`, s);
  }

  update(s: any) {
    return this.http.put<any>(`${this.apiUrl}${s.id}`, s);
  }

  delete(id: number) {
    return this.http.delete<any>(`${this.apiUrl}${id}`);
  }

  save(s: any) {
    if (s.id) return this.update(s);
    return this.insert(s);
  }

  // uploadIcon(id:number, form: FormData) {
  //     return this.http.post<any>(`${this.apiUrl}${id}/uploadIcon`, form);
  // }

  deleteIcon(id: number) {
    return this.http.delete<any>(`${this.apiUrl}${id}/icon`);
  }

  uploadTempFile(form: FormData) {
    return this.http.post<any>(`${this.apiUploadFile}`, form);
  }

  getAssetIcon(tipologiaIntervento: any): string {
    if (tipologiaIntervento && this.ASSETS_ICONS[tipologiaIntervento.codice]) {
      return this.ASSETS_ICONS[tipologiaIntervento.codice];
    }
    if (!tipologiaIntervento.iconaFileBase64) {
      return this.DEFAULT_TIPOLOGIA_ICON;
    }
    return this.imageHelper.hrefBase64Image(
      tipologiaIntervento.iconaFileBase64
    );
  }
}
