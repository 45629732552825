<div class="container-xl">
  <div class="position-sticky sticky-top pt-4 pb-4 elemento-aggiorna shadow-custom "
  [ngClass]="{'sticky-top-custom-modal': isInModal,'sticky-top-custom':!isInModal }">
    <div class="row justify-content-center">
      <div [hidden]="readOnlyForm" class="text-center col-lg-2 col-md-2" [ngClass]="{ ' offset-lg-3': !gotoBtnOptions || !gotoBtnOptions.show }">
        <button [disabled]="loading" (click)="onSubmit()" class="btn btn-primary btn-block"
          type="button" 
        >
          Salva&nbsp;<i class="fas fa-save"></i>
          <img *ngIf="loading" class="pl-3 float-right"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
          />
        </button>
      </div>
      <div class=" text-center test2"
        [ngClass]="{ ' col-lg-4 col-md-6': !(gotoBtnOptions && gotoBtnOptions.showIcon) , 'col-lg-2' : gotoBtnOptions && gotoBtnOptions.showIcon}">
        <button (click)="gotoBackBtnClick()" *ngIf="gotoBtnOptions && gotoBtnOptions.show" [disabled]="loading"
          class="btn btn-secondary btn-block font-weight-bold"
          type="button">{{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}&nbsp;<i class="fas fa-times"
            *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i></button>
      </div>
    </div>
  </div>
  <div class="row justify-content-md-center mt-4" style="margin-bottom: 2em;">
    <div class="col-lg-12">
      <form [formGroup]="myForm" style="width:100%">
        <div class="col-lg-12 mt-3 px-4 table-responsive-lg">
          <label for="tipologie">
            Tipologia sisma
          </label>
          <div>
            <ng-select
            [ngClass]="{ 'is-invalid': submitted && f.tipologie.errors }"
            [items]="tipologieSisma"
            bindValue="id"
            bindLabel="descrizione"
            placeholder="Seleziona tipologia sisma"
            formControlName="tipologie"
            ></ng-select>
            <div *ngIf="submitted && f.tipologie.errors" class="invalid-feedback" style="display: block">
              <div *ngIf="f.tipologie.errors.required">Campo obbligatorio</div>
            </div>
          </div>
          <label for="categorie">
            Categoria sisma
          </label>
          <div>
            <ng-select
            [items]="categorieSisma"
            [ngClass]="{ 'is-invalid': submitted && f.categorie.errors }"
            bindValue="id"
            bindLabel="descrizione"
            placeholder="Seleziona categoria sisma"
            formControlName="categorie"
            ></ng-select>
            <div *ngIf="submitted && f.categorie.errors" class="invalid-feedback" style="display: block">
              <div *ngIf="f.categorie.errors.required">Campo obbligatorio</div>
            </div>
          </div>
          
          <div>
            <label for="soggettiResp" >
              Rp
            </label>
            <div>
              <ng-select
              [items]="soggettiResp"
              bindValue="id"
              bindLabel="label"
              placeholder="Seleziona rp "
              formControlName="soggettiResp"
              >
            </ng-select>
          </div>
        </div>
          <!--TODO:aggiungere RUP-->
        </div>
      </form>
    </div>
  </div>
</div>
