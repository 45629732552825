import { Component,  ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';


import { Router, ActivatedRoute } from "@angular/router";

import { UserService } from 'src/app/services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  forgotPassword!: FormGroup;

  invalidForgotPassword: boolean;

    loading: boolean = false;
    submitted: boolean = false;
    emailSent:boolean = false;
  


  constructor(
    private el: ElementRef,
    private userService: UserService,
    )
     {
    this.forgotPassword = new FormGroup({
      username: new FormControl(null, [Validators.required]),
    });

  }

  

  get f() { return this.forgotPassword.controls; }



  onSubmit(){

    this.submitted = true;

  // stop here if form is invalid
  if (this.forgotPassword.invalid) {
    for (const key of Object.keys(this.forgotPassword.controls)) {
        if (this.forgotPassword.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector(
            `[formcontrolname="${key}"]`
          );
          invalidControl.focus();
          break;
        }
      }
      return;

  }
    let credentials = this.forgotPassword.value;
    this.loading = true;
    this.invalidForgotPassword = false;
    this.emailSent = false;
    this.userService.resetPassword(credentials.username).subscribe(
        ris=>{
            if (ris){
                this.emailSent=true;
                this.loading = false;
            }
            else{
                this.loading=false;
                this.invalidForgotPassword = true;
            }
        }
    );
    
  }
}

