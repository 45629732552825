import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { ServiceType } from 'src/app/modules/inframob/models/service-type';
import { SicerImpegnoAccertamento } from 'src/app/modules/inframob/models/sicer-impegno-accertamento';
import { SicerLiquidazione } from 'src/app/modules/inframob/models/sicer-liquidazione';
import { InterventoService } from 'src/app/modules/inframob/services';

//jQuery
declare var $: any;

@Component({
  selector: 'app-dettaglio-liquidazione',
  templateUrl: './dettaglio-liquidazione.component.html',
  styleUrls: ['./dettaglio-liquidazione.component.css']
})
export class DettaglioLiquidazioneComponent implements OnInit, OnChanges {

  @Input()
  impegno: SicerImpegnoAccertamento;
  @Input()
  liquidazioni: SicerLiquidazione[];
  @Input()
  interventoId: number;
  @Input()
  type: ServiceType;

  disponibilita: number = 0;
  totImportoLiquidazioni: number = 0;
  totImportoOrdinativi: number = 0;

  constructor(
    public interventoService: InterventoService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    switch (true) {
      case changes.liquidazioni !== undefined:
        this.totImportoLiquidazioni = 0;
        this.liquidazioni?.forEach(liq => {
          this.totImportoLiquidazioni += liq.importoScadenza;

          if (!liq.ordinativi) {
            this.totImportoOrdinativi = 0;
            this.interventoService.getSicerLiquidazioniOrdinativiByPkImpaccAndNumLiq(this.impegno.pkImpacc, liq.numLiq).subscribe(res => {
              liq.ordinativi = res;
              res.forEach(ord => {
                this.totImportoOrdinativi += ord.importoScadenza;
              });
            });
          }
        });
        this.disponibilita = (this.impegno ? this.impegno.valoreAttualeImpacc : 0) - this.totImportoLiquidazioni;
        break;
    }
  }

  insertDotForThousands(baseValue: number): string {
    if (baseValue === null || baseValue === undefined) return '';

    const value = baseValue.toFixed(2).toString().replace('.', ',');
    return value
              ? value.replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
              : '';
  }

  getDataFormat(date: Date): string {
    return date ? moment(date).format('DD/MM/YYYY') : '';
  }

  getLabelFieldsByType(): string {
    return this.type === ServiceType.FINANZIAMENTO ? 'Acc.' : 'Imp.';
  }

  checkTitleButton(id: string, liquidazione: SicerLiquidazione): string {
    const isExpanded = this.checkButtonIsExpanded(id);
    return !liquidazione || !liquidazione.ordinativi || liquidazione.ordinativi.length === 0
              ? 'Non sono presenti ordinativi'
              : isExpanded
                  ? 'Collassa ordinativi'
                  : 'Espandi ordinativi';
  }

  checkButtonIsExpanded(id: string): any {
    const checkExp = $('#' + id).attr("aria-expanded");
    return checkExp ? JSON.parse(checkExp) : false;
  }

  setTargetAndControl(accertamento: SicerImpegnoAccertamento, liquidazione: SicerLiquidazione): string {
    return '#collapse' + accertamento.id + liquidazione.id + (liquidazione?.ordinativi ? liquidazione.ordinativi.length : '');
  }

  removeFocus(id: number) {
    setTimeout(() => {
      $('#' + id).blur();
    }, 0);
  }

  getRagSoc(liquidazione: SicerLiquidazione) {
    if (!liquidazione.ordinativi) return liquidazione.ragsocSoggetto;

    const soggs = [...new Set(liquidazione.ordinativi.map(ord => ord.ragsocSoggetto))];
    return soggs.length > 1 ? 'MULTI BENEFICIARIO' : liquidazione.ragsocSoggetto;
  }
}
