import { Component, OnInit, OnDestroy,Input, Output, EventEmitter } from '@angular/core';
import {
    HttpClient,
    HttpRequest,
    HttpEventType,
    HttpResponse,
  } from '@angular/common/http'
import { Subscription } from 'rxjs';
import { map } from  'rxjs/operators'



@Component({
    selector: 'uploadfile',
    templateUrl: 'upload-file.component.html',
    styleUrls: ['./upload-file.component.css']
})



export class UploadFileComponent    implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;
    currentFile:File = null;

    fileUploaded:boolean = false;
    uploadResponse = { status: '', message: '', filePath: '',progressBarType:'info',originalFileName:'' };  
    errors :string = '';        
    myconfig:{
        uploadButtonLabel:string,
        accept:string,
        maxSize:number,
        hideProgressBar:boolean,
        hideUploadButton:boolean,
        doUploadOnFileChange:boolean,
        mandatory:boolean,
        errorMessages:{
            notSupportedExtension:string,
            sizeExceeded:string
        }
        elementName:string
        ,uploadCallback:any
        ,fixedFilename:string
        ,mainObjectType:string
        ,apiUrl:string
        ,fileExists:boolean
        ,hideDownloadButton:boolean
    };
    @Input('externalConfig') externalConfig:{};
    constructor(private http: HttpClient) { }

    @Output() onAfterUpload = new EventEmitter();

    @Output() onDelete = new EventEmitter();

    @Output() onDownload = new EventEmitter();
    @Output() onFileChanged = new EventEmitter();

    ngOnInit() 
    {
        this.myconfig =
        {
            uploadButtonLabel:'Carica',
            accept:'.pdf,.p7m',
            maxSize:100,
            hideProgressBar:false,
            hideUploadButton:false,
            doUploadOnFileChange:false,
            mandatory:false,
            errorMessages:{
                notSupportedExtension:"Estensione non consentita",
                sizeExceeded:"Grandezza massima file superata"
            },
            elementName:""
            ,uploadCallback:null
            ,fixedFilename:""
            ,mainObjectType:""
            ,apiUrl : ""
            ,fileExists : false
            ,hideDownloadButton:false
        };  
        //Object.assign(this.myconfig,this.myconfig,this.externalConfig);       
        this.myconfig = {...this.myconfig,...this.externalConfig};
    }

    ngOnDestroy() {
        if (this.subscription)this.subscription.unsubscribe();
    }    

    clear(){
      this.uploadResponse =  { status: '', message: '', filePath: '',progressBarType:'info',originalFileName:'' };  
      this.errors = '';      
    }

    uploadFile() 
    {   
        this.uploadResponse = null;
        console.log('inside uploadfile, myconfig:' + JSON.stringify(this.myconfig));             
        const formData:FormData = new FormData();
        formData.append('file', this.currentFile);
        if (this.myconfig.fixedFilename !=='')formData.append('newfilename',this.myconfig.fixedFilename);
        return this.upload(formData).subscribe(            
          (res) => {
                this.uploadResponse = res;
                //console.log(JSON.stringify(this.myconfig.uploadCallback));                 
                if (this.uploadResponse.status==="done" /*&& this.myconfig.uploadCallback!=undefined*/){
                    console.log('call uploadCallback');
                    this.fileUploaded = true;
                    //let objtemp = Object.assign(this.uploadResponse,{name:this.myconfig.elementName});
                    let objtemp = {...this.uploadResponse,...{name:this.myconfig.elementName}};
                    this.onAfterUpload.emit(objtemp);
                    //this.myconfig.uploadCallback(objtemp);
                }
            },
          (err) => this.errors = err
        );  
    }    

    onFileChange(event:any) {
        console.log('into onFileChange');
        if (event.target.files.length > 0) {
            this.currentFile = event.target.files.item(0);    
            this.fileUploaded         = false;
            console.log('into onFileChange, file:' + this.currentFile.name);
            this.errors = this.CheckExtensions(this.currentFile.name)?"":this.myconfig.errorMessages.notSupportedExtension;
            if(!this.CheckMaxSize(this.currentFile))this.errors = this.myconfig.errorMessages.sizeExceeded;
            console.log('into onFileChange, this.errors:' + this.errors);            
            if (this.errors.length<=0 && this.myconfig.doUploadOnFileChange)this.uploadFile();
            this.onFileChanged.emit(event);
        }
    } 
    
    GetFileName(){
        return this.currentFile?this.currentFile.name:"";
    }
    CheckExtensions(filename:string)
    {
        console.log('into CheckExtensions, f=' + filename);
        if (this.myconfig.accept.length<=0)return true;//all accepted
        var ext = this.GetFileExtension(filename);
        console.log('into CheckExtensions, ext=' + ext);
        if (ext !==undefined){
            ext = '.' + ext;
            let exts = this.myconfig.accept.split(',');
            console.log('exts.indexOf(ext):' + exts.indexOf(ext));
            return exts.indexOf(ext)>-1;
        }
        return false;
    }

    CheckMaxSize(f:File){
        var size = f.size/1024/1024;
        return size <=this.myconfig.maxSize;
    }

    GetFileExtension(filename:string) {
        return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
    }

    Validate()
    {
        console.log("into validate of " + this.myconfig.elementName);
        if (this.errors.length>0)return false;
        if (this.myconfig.mandatory){
            if ((this.myconfig.doUploadOnFileChange && this.uploadResponse.status!=='done')
            ||
            (!this.myconfig.doUploadOnFileChange && this.GetFileName()=='')
            )
            {
              this.errors = "Il file è obbligatorio";
              return false;
            }
            
        }
        return true;
    }


    public upload(data:any) 
    {
        let uploadURL = `${this.myconfig.apiUrl}`;
        console.log(`uploadURL:${uploadURL}`)
        console.log(`into upload.service.upload() ${data}`);
        return this.http.post<any>(uploadURL, data, {
          reportProgress: true,
          observe: 'events'
        }).pipe(map((event) => {
          console.log(` event:  ${JSON.stringify(event)}`);
          switch (event.type) {
            case HttpEventType.Sent:
              console.log(` event: Sent - ${event.type}`);
              return { status: 'progress', message: 0 };
            case HttpEventType.UploadProgress:
              console.log(` event: UploadProgress - ${event.type}`);
              const progress = Math.round(100 * event.loaded / event.total);
              return { status: 'progress', message: progress };
            case HttpEventType.ResponseHeader:
              console.log(` event: ResponseHeader - ${event.type}`);
              return { status: 'progress', message: 100 ,progressBarType :'success'};
            case HttpEventType.Response:
              console.log(` event: Response - ${event.type}`);
              return event.body;
              
            case HttpEventType.DownloadProgress:
              console.log(` event: DownloadProgress - ${event.type}`);
              return { status: 'progress', message: 100,progressBarType :'success' };
            default:
              console.log(` event: default - ${event.type}`);
              return `Unhandled event: ${event.type}`;
          }
        })
        );
      } 
      
      deleteFile(){
        console.log('delete');
        this.myconfig.fileExists = false;
        this.onDelete.emit(JSON.stringify(this.currentFile));
      }
      downloadFile(){
        this.onDownload.emit(JSON.stringify(this.currentFile));
      }
}