<div class="container-fluid px-0 bg-celeste">
  <div
    class="text-center p-5 position-absolute top-50 start-50 translate-middle bg-white border border-dark rounded shadow"
    style="z-index: 999999999"
    *ngIf="showLoading"
  >
    <app-loading message="Caricamento in corso..."></app-loading>
  </div>

    <app-header-dashboard></app-header-dashboard>
    <!-- menu -->
    <div
      class="row pb-3 pt-1 bg-0083e0"
      style="z-index: 13000"
      [formGroup]="myForm"
    >
      <div class="col-xl-3 col-lg-6 col-md-6 pt-1" style="z-index: 13000">
        <label for="soggettoGiuridico">
          <h6 class="mb-0 text-white bold">Soggetto giuridico</h6>
        </label>
        <ng-select
          [items]="soggettiGiuridiciCombo"
          bindValue="id"
          bindLabel="denominazione"
          placeholder="Seleziona soggetto giuridico"
          formControlName="soggettoGiuridico"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.denominazione }}">{{ item.denominazione }}</div>
          </ng-template>
        </ng-select>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label for="">
          <h6 class="mb-0 text-white bold">Fonte finanziamento</h6>
        </label>
        <ng-select
          [items]="fontiFianziamentoList"
          bindValue="id"
          bindLabel="tipoFinanziamento"
          placeholder="Seleziona fonte finanziamento"
          formControlName="fonteFinanziamento"
          [multiple]="true"
          [selectableGroup]="true"
          [selectableGroupAsModel]="false"
          groupBy="gruppo"
        >
          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <input
              id="item-{{ index }}"
              type="checkbox"
              [checked]="item$.selected"
            />
            <span title="{{ item.tipoFinanziamento }}"
              >&nbsp;{{ item.tipoFinanziamento }}</span
            >
          </ng-template>
        </ng-select>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label>
          <h6 class="mb-0 text-white bold">Strumento attuativo</h6>
        </label>
        <ng-select
          [items]="strumentiAttuativiCombo"
          bindValue="id"
          bindLabel="descrizione"
          formControlName="strumentoAttuativo"
          placeholder="Seleziona strumento attuativo"
          (change)="setStrumentoAttuativo($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
          </ng-template>
        </ng-select>
      </div>




      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label>
          <h6 class="mb-0 text-white bold">Progettualità/Fabbisogno</h6>
        </label>
        <ng-select
          [items]="progettualitaItems"
          bindValue="id"
          bindLabel="label"
          placeholder="Seleziona progettualità/Fabbisogno"
          formControlName="progettualita"
          (change)="setProgettualita($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizione }}">{{ item.label }}</div>
          </ng-template>
        </ng-select>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label for=" interventi">
          <h6 class="mb-0 text-white bold">
            Intervento <small>(min 3 caratteri)</small>
          </h6>
        </label>

        <app-completer
          [cpOptions]="completerParams"
          (onSelectEmitter)="setIntervention($event)"
          (keyup)="setIntervention($event)"
        >
        </app-completer>
      </div>

      <!-- Filtro aree gestionali  -->
      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label for="importoFinanziamento ">
          <h6 class="mb-0 text-white bold">
            Aree gestionali direzione e/o esterne
          </h6>
        </label>
        <ng-select
          [items]="areeTematicheCombo"
          bindValue="id"
          bindLabel="descrizione"
          formControlName="direction"
          placeholder="Seleziona area gestionale direzione e/o esterna "
          (change)="setAreaTematica($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
          </ng-template>
        </ng-select>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
        <label>
          <h6 class="mb-0 text-white bold">Tipologia Classificazioni</h6>
        </label>
        <ng-select
          [items]="classificazioniCombo"
          bindValue="id"
          bindLabel="tipo"
          formControlName="classification"
          placeholder="Seleziona tipologia classificazioni"
          (change)="setClassificazione($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.tipo }}">{{ item.tipo }}</div>
          </ng-template>
        </ng-select>
      </div>

      <div
        class="col-xl-3 col-lg-4 col-md-6 col-sm-12 pt-1 align-self-end align-items-end"
      >
        <div class="d-flex">
          <div class="col-lg-6 pr-0">
            <button
              [disabled]="
                loadingExportExcel ||
                ((!myForm.controls.fonteFinanziamento?.value ||
                myForm.controls.fonteFinanziamento?.value == '')
                &&
                !filterObject.direction
                && !filterObject.strumentoAttuativo)
              "
              type="button"
              class="mt-1 btn btn-info btn-block"
              (click)="esportaExcelEntrata()"
            >
              Esporta entrata
              <img
                *ngIf="loadingExportExcel"
                class="pl-3 float-right"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              />
            </button>
          </div>
        </div>
      </div>
      <div
        class="col-xl-3 col-lg-4 col-md-6 col-sm-12 pt-1 align-self-end align-items-end"
      >
        <div class="d-flex">
          <div class="col-lg-6 pr-0">
            <button
              [disabled]="
                loadingExportExcel ||
                (
                    (!myForm.controls.fonteFinanziamento?.value || myForm.controls.fonteFinanziamento?.value == '')
                    &&
                    !myForm.controls.soggettoGiuridico?.value
                    && !filterObject.intervento
                    && !filterObject.progettualita
                    && !filterObject.classification
                    && !filterObject.direction
                    && !filterObject.strumentoAttuativo
                  )
              "
              type="button"
              class="mt-1 btn btn-info btn-block"
              (click)="esportaExcelUscita()"
            >
              Esporta uscita
              <img
                *ngIf="loadingExportExcel"
                class="pl-3 float-right"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              />
            </button>
          </div>
        </div>
      </div>
    </div>

</div>
