import { TipoClassificazione } from './tipo-classificazione';

export class Classificazione{
    id:number;
    codice:string;
    descrizione:string;
    ordine:number;
    tipoClassificazione:any;
    idTipoClassificazione:number;//for save purpose only
    //tipoClassificazione:string;//for grid purpose only

}