<ng-template #templateEditAdd>
  <div class="col-lg-12">
    <form [formGroup]="addSchoolYearForm" (ngSubmit)="onSubmit()">
      <input
        type="hidden"
        [formGroup]="addSchoolYearForm"
        formControlName="id"
      />
      <div class="row">
        <div class="form-group col-lg-6">
          <h5><label for="fase" class="mandatory">Tipologia:</label></h5>
          <input
            [formGroup]="addSchoolYearForm"
            formControlName="fase"
            maxlength="255"
            type="text"
            value=""
            class="form-control"
            placeholder="Inserisci la tipologia"
            [ngClass]="{
              'form-control': true,
              'is-invalid': submitted && f.fase.errors
            }"
          />
          <div
            *ngIf="submitted && f.fase.errors"
            class="invalid-feedback"
            style="display: block"
          >
            <div *ngIf="f.fase.errors.required">Campo obbligatorio</div>
          </div>
        </div>
        <div class="form-group col-lg-6">
          <h5><label for="titolo" class="mandatory">Titolo:</label></h5>
          <input
            [formGroup]="addSchoolYearForm"
            formControlName="titolo"
            maxlength="255"
            type="text"
            value=""
            class="form-control"
            placeholder="Inserisci il titolo"
            [ngClass]="{
              'form-control': true,
              'is-invalid': submitted && f.titolo.errors
            }"
          />
          <div
            *ngIf="submitted && f.titolo.errors"
            class="invalid-feedback"
            style="display: block"
          >
            <div *ngIf="f.titolo.errors.required">Campo obbligatorio</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-8">
          <h5>
            <label for="descrizione" class="mandatory">Descrizione:</label>
          </h5>
          <input
            [formGroup]="addSchoolYearForm"
            formControlName="descrizione"
            maxlength="255"
            type="text"
            value=""
            class="form-control"
            placeholder="Inserisci la descrizione"
            [ngClass]="{
              'form-control': true,
              'is-invalid': submitted && f.descrizione.errors
            }"
          />
          <div
            *ngIf="submitted && f.descrizione.errors"
            class="invalid-feedback"
            style="display: block"
          >
            <div *ngIf="f.descrizione.errors.required">Campo obbligatorio</div>
          </div>
        </div>
        <!--***-->
        <div class="form-group col-lg-4">
          <div class="">
            <label for="descrizione">
              <h5 class="mandatory">Entrata/Uscita/PNRR:</h5>
            </label>

            <div [ngClass]="{ 'is-invalid': submitted && f.tipologia.errors }">
              <ng-select
                [items]="tipologie"
                bindValue="value"
                bindLabel="value"
                placeholder="Seleziona tipologia "
                formControlName="tipologia"
              >
              </ng-select>
            </div>
            <div
              *ngIf="submitted && f.tipologia.errors"
              class="invalid-feedback"
              style="display: block"
            >
              <div *ngIf="f.tipologia.errors.required">Campo obbligatorio</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-12">
          <h5>
            <label for="is_data_prevista_inizio" class="">Monitoraggio:</label>
          </h5>
          <div class="row">
            <div class="col-lg-6 col-md-6 mb-3">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  [formGroup]="addSchoolYearForm"
                  formControlName="is_data_prevista_inizio"
                />
                <label class="form-check-label" for="flexSwitchCheckDefault"
                  >fase data inzio per la durata prevista</label
                >
              </div>
            </div>

            <div class="col-lg-6 col-md-6 mb-3">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  [formGroup]="addSchoolYearForm"
                  formControlName="is_data_prevista_fine"
                />
                <label class="form-check-label" for="flexSwitchCheckDefault"
                  >fase data fine per la durata prevista</label
                >
              </div>
            </div>

            <div class="col-lg-6 col-md-6 mb-3">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  [formGroup]="addSchoolYearForm"
                  formControlName="is_data_effettiva_inizio"
                />
                <label class="form-check-label" for="flexSwitchCheckDefault"
                  >fase data inzio per la durata effettiva</label
                >
              </div>
            </div>

            <div class="col-lg-6 col-md-6 mb-3">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  [formGroup]="addSchoolYearForm"
                  formControlName="is_data_effettiva_fine"
                />
                <label class="form-check-label" for="flexSwitchCheckDefault"
                  >fase data fine per la durata effettiva</label
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-4">
                <div class="">
                  <label for="statoAttuazione">
                    <h5 class="">Stato di attuazione:</h5>
                  </label>
      
                  <div [ngClass]="{ 'is-invalid': submitted && f.statoAttuazione.errors }">
                    <ng-select
                      [items]="elencoStatiAttuazione"
                      bindValue="id"
                      bindLabel="descrizione"
                      placeholder="Seleziona"
                      formControlName="statoAttuazione"
                    >
                    </ng-select>
                  </div>
                  <div
                    *ngIf="submitted && f.statoAttuazione.errors"
                    class="invalid-feedback"
                    style="display: block"
                  >
                    <div *ngIf="f.statoAttuazione.errors.required">Campo obbligatorio</div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--aree tematiche-->

      <div class="col-lg-12 mt-5">
        <hr />
      </div>

      <div class="col-lg-12 mt-4">
        <div class="row justify-content-between">
          <div class="col-lg-6 col-sm-6 col-6 mt-3">
            <h5 for="" class="mandatory">
              Aree gestionali direzione e/o esterne
            </h5>
          </div>
          <div class="col-lg-1 col-sm-2 col-3 text-center mt-3">
            <a
              href="javascript:;;"
              class="bottone-inserisci"
              (click)="openModalAT()"
              ><i class="fas fa-plus"></i
            ></a>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mt-3 table-responsive-lg">
        <table class="table table-hover table-colore">
          <thead class="">
            <th class="casella-descrizione-tx">descrizione</th>
            <th>codice ipa</th>
            <th>struttura</th>
            <th class="casella-icona-tx">elimina</th>
          </thead>
          <tbody>
            <ng-container *ngIf="areeTematiche && areeTematiche.length > 0">
              <tr *ngFor="let item of areeTematiche">
                <td>
                  {{ item.disabled ? "(disattiva) " : ""
                  }}{{ item.descrizione }}
                </td>
                <td>{{ item.codiceIpa }}</td>
                <td>{{ item.struttura }}</td>
                <td>
                  <a
                    (click)="removeAreaTematica(item)"
                    href="javascript:;;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="elimina"
                    ><i class="far fa-trash-alt casella-icona-tx"></i>
                  </a>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="!(areeTematiche && areeTematiche.length > 0)">
              <td colspan="4">Nessuna area direzione selezionata</td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="submitted && areeTematicheIsError"
          class="invalidFieldMessage"
        >
          <div
            *ngIf="areeTematicheIsError"
            class="invalid-feedback"
            style="display: block"
          >
            Selezionare almeno un'area direzione
          </div>
        </div>
      </div>

      <button
        class="btn btn-lg btn-primary btn-block"
        type="submit"
        style="margin: 15px 0"
      >
        {{ buttonLabel }}
      </button>
    </form>
  </div>
</ng-template>

<div class="container sfondo_bianco">
  <hr />
  <accordion [isAnimated]="true" [hidden]="readonly">
    <accordion-group heading="Inserisci Nuova tipologia di fase">
      <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
    </accordion-group>
  </accordion>

  <accordion [isAnimated]="true" class="mt-3" [hidden]="readonly">
    <accordion-group heading="Gestisci colori tipologia di fase">
      <div class="col-lg-12">
        <form>
          <div class="form-group row">
            <div class="col-lg-1">
              <input
                class="form-control"
                [(colorPicker)]="color"
                [style.background]="color"
                [cpPosition]="'right'"
                [cpOutputFormat]="'hex'"
                title="colore tipologie"
                [cpPresetColors]="presetColors"
                [cpColorMode]="'presets'"
                [cpPresetLabel]="'colori possibili'"
                [cpCancelButton]="true"
                [cpCancelButtonText]="'clear'"
              />
            </div>
            <div class="col-lg-1">
              <input
                class="form-control"
                [(colorPicker)]="coloreFasiGenerico"
                [style.background]="coloreFasiGenerico"
                [cpPosition]="'right'"
                [cpOutputFormat]="'hex'"
                title="colore fasi"
                [cpPresetColors]="presetColors"
                [cpColorMode]="'presets'"
                [cpPresetLabel]="'colori possibili'"
                [cpCancelButton]="true"
                [cpCancelButtonText]="'clear'"
              />
            </div>
            <div class="col-lg-3">
              <button
                class="btn btn-primary form-control"
                type="button"
                (click)="applyColorToSelected()"
              >
                Applica a selezionati
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-12 mt-3 table-responsive-lg">
        <table class="table table-hover table-colore">
          <thead>
            <tr>
              <th>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customSwitchAll"
                    (click)="selectUnSelectColors($event)"
                  />
                  <label
                    class="custom-control-label"
                    for="customSwitchAll"
                  ></label>
                </div>
              </th>
              <th scope="col">tipologia</th>
              <th scope="col" class="casella-icona-tx">
                colore&nbsp;tipologia
              </th>
              <th scope="col" class="casella-icona-tx text-center">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;colore&nbsp;fasi&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </th>
              <!--<th scope="col" class="casella-icona-tx">salva</th>-->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of tipologieUnivoche; let i = index">
              <td>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customSwitch{{ i }}"
                    (click)="selectColorItem(item)"
                  />
                  <label
                    class="custom-control-label"
                    for="customSwitch{{ i }}"
                  ></label>
                </div>
              </td>
              <td>{{ item.fase }}</td>
              <td class="color-picker-sticky py-1">
                <input
                  class="form-control"
                  [(colorPicker)]="item.colore"
                  [cpPosition]="'top'"
                  [style.background]="item.colore"
                  [cpOutputFormat]="'hex'"
                  (colorPickerChange)="onColorChange($event, item)"
                  (colorPickerCancel)="onColorChange(null, item)"
                  [cpPresetColors]="presetColors"
                  [cpColorMode]="'presets'"
                  [cpPresetLabel]="'colori possibili'"
                  [cpCancelButton]="true"
                  [cpCancelButtonText]="'clear'"
                />
              </td>
              <td class="color-picker-sticky py-1">
                <input
                  class="form-control"
                  [(colorPicker)]="item.coloreFasi"
                  [cpPosition]="'top'"
                  [style.background]="item.coloreFasi"
                  [cpOutputFormat]="'hex'"
                  (colorPickerChange)="onColorChangeFasi($event, item)"
                  [cpPresetColors]="presetColors"
                  (colorPickerCancel)="onColorChangeFasi(null, item)"
                  [cpColorMode]="'presets'"
                  [cpPresetLabel]="'colori possibili'"
                  [cpCancelButton]="true"
                  [cpCancelButtonText]="'clear'"
                />
              </td>
              <!--<td><a (click)="saveTipologiaFaseColore(item)" href="javascript:;;" data-toggle="tooltip" data-placement="top" title="salva"><i
                            class="fas fa-save "></i>
                        </a></td>-->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-12">
        <button
          [disabled]="loading"
          class="btn btn-lg btn-primary btn-block"
          type="button"
          style="margin: 15px 0"
          (click)="saveTipologiaFaseColore()"
        >
          Aggiorna
        </button>
      </div>
    </accordion-group>
  </accordion>

  <div class="mt-5">
    <ngx-datatable-wrapper
      #ngxDatatableWrapper
      [templates]="templateList"
      style="width: 100%; height: auto"
      [dtOptions]="dtOptions"
    >
      <ng-template #direzioneFieldValueTemplate let-row="row" let-value="value">
        <ul class="pl-2" *ngIf="value && value != ''">
          <li
            class="text-lowercase"
            *ngFor="let val of printDirezioneFieldValue(value)"
          >
            {{ val }}
          </li>
        </ul>
      </ng-template>

      <ng-template #btnEditTemplate let-row="row" let-value="value">
        <a
          [ngClass]="{
            disabled: loading
          }"
          class="border-0 text-center"
          (click)="onEdit(row)"
          data-toggle="tooltip"
          data-placement="top"
          title="modifica dati"
          >&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i
        ></a>
      </ng-template>
      <ng-template #btnDeleteTemplate let-row="row" let-value="value">
        <a
          [ngClass]="{
            disabled: loading
          }"
          class="border-0 text-center"
          (click)="onDelete(row)"
          data-toggle="tooltip"
          data-placement="top"
          title="elimina"
          >&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i
        ></a>
      </ng-template>
      <ng-template #spanTipologiaTemplate let-row="row" let-value="value">
        <span>{{ value ? "Entrata" : "Uscita" }}</span>
      </ng-template>
    </ngx-datatable-wrapper>
  </div>
</div>
<ng-template #templateModal>
  <div class="modal-header text-center">
    <h4 class="modal-title pull-left">Modifica tipologia</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
  </div>
</ng-template>
