import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { TipoClassificazione } from '../models';


class DataTablesResponse {
    data: TipoClassificazione[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}

@Injectable({
    providedIn: "root"
})
export class TipoClassificazioneService {
    private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}TipiClassificazione/`;
    constructor(private http: HttpClient) { }

    getAll(){
        return this.http.get<TipoClassificazione[]>(
            `${this.apiUrl}`            
        );
    }
    getAllDt(dataTablesParameters: any) {
        return this.http.post<DataTablesResponse>(
            `${this.apiUrl}alldt`,
            dataTablesParameters
        );
    }
    insert(s: TipoClassificazione) {
        return this.http.post<any>(`${this.apiUrl}`, s);
    }
    update(s: TipoClassificazione) {
        return this.http.put<any>(`${this.apiUrl}${s.id}`, s);
    }
    delete(id: number) {
        return this.http.delete<any>(`${this.apiUrl}${id}`);
    }
    save(s:TipoClassificazione){
        if (s.id)return this.update(s);
        return this.insert(s);
    }

    getClassificazioni(id:number){
        return this.http.get<any>(
            `${this.apiUrl}${id}/classificazioni`            
        );
    }

}
