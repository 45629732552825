import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";



@Injectable({
  providedIn: "root",
})
export class ValidazioneService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}Validazione/`;
  constructor(private http: HttpClient) {}

  getAllDt(dataTablesParameters: any) {
    return this.http.post<any>(
      `${this.apiUrl}alldt`,
      dataTablesParameters
    );
  }  
}
