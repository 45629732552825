<div class="container-xl">
  <div class="position-sticky sticky-top pt-4 pb-4 elemento-aggiorna shadow-custom "
  [ngClass]="{'sticky-top-custom-modal': isInModal,'sticky-top-custom':!isInModal ,'sticky-top-custom-sal': esistonoInterventi  }">
    <div class=" row  justify-content-center ">
      <div class=" text-center test2"
        [ngClass]="{ ' col-lg-4 col-md-6': !(gotoBtnOptions && gotoBtnOptions.showIcon) , 'col-lg-2' : gotoBtnOptions && gotoBtnOptions.showIcon}">
        <button (click)="gotoBackBtnClick()" *ngIf="gotoBtnOptions && gotoBtnOptions.show" [disabled]="loading"
          class="btn btn-secondary btn-block font-weight-bold"
          type="button">{{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}&nbsp;<i class="fas fa-times"
            *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i></button>
      </div>
    </div>
  </div>

  <div class="row justify-content-md-center mt-4" [hidden]="readOnlyForm || showAllData">
    <div class="col-lg-12">
      <form [formGroup]="addDocumentForm" style="width:100%">
        <div class="col-lg-12 mt-3 px-4 table-responsive-lg">
          <table class="table  table-colore">
            <thead class="">
              <th class="casella-descrizione-tx">titolo documentazione</th>
              <th class="casella-descrizione-tx">descrizione</th>
              <th>file documentazione</th>
              <th class="casella-icona-tx">carica</th>
            </thead>
            <tbody>
              <ng-container>
                <tr>
                  <td>
                    <input type="text" maxlength="255" [formGroup]="addDocumentForm" formControlName="title"
                      class="form-control" [ngClass]="{'is-invalid': submitted && f.title.errors }">
                    <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                      <div *ngIf="f.title.errors.required">Titolo &egrave; obbligatorio</div>
                    </div>
                  </td>
                  <td>
                    <input type="text" maxlength="255" [formGroup]="addDocumentForm" formControlName="description"
                      class="form-control" [ngClass]="{'is-invalid': submitted && f.description.errors }">
                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                      <div *ngIf="f.description.errors.required">Descrizione &egrave; obbligatorio</div>
                    </div>
                  </td>
                  <td>
                    <input type="file" formControlName="attach" [formGroup]="addDocumentForm"
                      (change)="fileChange($event)" class="pt-1 pointer"
                      [ngClass]="{'is-invalid': submitted && f.attach.errors }" />
                    <div *ngIf="submitted && f.attach.errors" class=" invalid-feedback" style="display: block;">
                      <div *ngIf="f.attach.errors.required">File &egrave; obbligatorio</div>
                    </div>
                  </td>
                  <td>
                    <span (click)="onSubmit()" class="pointer">
                      <i class="fas fa-upload pointer casella-icona-tx" title="carica"></i>
                    </span>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>

  <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;"
    [dtOptions]="dtOptionsNgx">
    <ng-template #btnDownloadTemplate let-row="row" let-value="value">
      <a class=" border-0 text-center" (click)="downloadDocument(row)" data-toggle="tooltip"
        data-placement="top" title="scarica">&nbsp;&nbsp;&ensp;<i class="fas fa-download pointer"></i></a>
    </ng-template>

    <ng-template #btnDeleteTemplate let-row="row" let-value="value">
      <a [hidden]="row.readonly" class=" border-0 text-center" (click)="deleteDocument(row)" data-toggle="tooltip" data-placement="top"
        title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
    </ng-template>
  </ngx-datatable-wrapper>
</div>
