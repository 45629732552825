<div id="map-container" class="position-relative">




  <div id="map" class="border ">
    <div class="rounded form-group position-absolute bottom-0 start-50 translate-middle-x m-3 p-2 mb-4 d-none d-lg-block"
    style="z-index: 2000; background: rgba(255, 255, 255, 0.4);">
    <label class="m-0 small">
      <span class="">Opacità layer</span>
      <input id="opacity-input" (input)="setOpacity($event)" type="range" min="0" max="1" step="0.01" value="1" />
      <span id="opacity-output">{{opacityOutput}}</span>
    </label>
  </div>
  <!-- filter button-->
    <button style="z-index: 999; border-top-right-radius: 0.25rem!important; border-bottom-right-radius: 0.25rem!important;"
    class="d-none d-lg-block position-absolute btn btn-light
    top-50 start-0 translate-middle-y rounded-right rounded-0 border border-left-0
    bg-celeste py-3 px-1" type="button" data-toggle="collapse" data-target=".collapseWidthExample" aria-expanded="true"
    aria-controls="collapseWidthExample" (click)="clickEvent()" title="{{status ? 'Chiudi filtri' : 'Espandi filtri'}}" [attr.aria-label]="status ? 'Chiudi filtri' : 'Espandi filtri'">
    <i class="fas  fa-2xl" [ngClass]="status ? 'fa-times ' : 'fa-angle-double-right'" aria-hidden="true"></i>
    </button>

    <button #filterCollapseBtn
    style="z-index: 999; border-bottom-right-radius: 0.25rem!important; border-bottom-left-radius: 0.25rem!important;"
    class="d-lg-none d-block position-absolute btn btn-light top-0 start-50 translate-middle-x rounded-0 bg-celeste
    px-4 py-0 mb-4 rounded-bottom border border-top-0"
    type="button" data-toggle="collapse" data-target=".collapseWidthExample" (click)="clickEvent()"
    aria-expanded="true" aria-controls="collapseWidthExample" title="{{status ? 'Chiudi filtri' : 'Espandi filtri'}}" [attr.aria-label]="status ? 'Chiudi filtri' : 'Espandi filtri'">
      <i class="fas fa-2xl" [ngClass]="status ? 'fa-times ' : 'fa-angle-double-up'" aria-hidden="true"></i>

    </button>



    <!-- popover -->
    <div
      #popup
      [hidden]="true"
      class="shadow-sm card bg-light mb-3 border-0 rounded"
      style="z-index: 140000"
    >
    <div class="d-flex py-2 px-3 align-items-center justify-content-between bg-primary text-white rounded-top">
      <h6 class="m-0 mt-1 align-self-center">{{currentMode == "comune"? 'Comune di' : "Provincia di"}} <span class="bold">{{popUpInfo.soggetto.nome}} &nbsp;</span></h6>
    <button type="button" class="close align-self-center text-white " aria-label="Close" (click)="closeOverlay()">
      <span class="align-self-center" aria-hidden="true">&times;</span>
    </button>
  </div>
      <div id="style-3" class="scrollbarcard-body overflow-auto m-1 px-2" style="max-height: 300px;">

        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item">

            <a class="nav-link active" id="pills-missioni-tab" data-toggle="pill" href="#pills-missioni" role="tab" aria-controls="pills-missioni" aria-selected="true">Missioni</a>
          </li>
          <li class="nav-item">
            <a class="nav-link " id="pills-tipolgie-tab" data-toggle="pill" href="#pills-tipologie" role="tab" aria-controls="pills-tipologie" aria-selected="false">Tipologie</a>
          </li>

        </ul>


        <div class="tab-content table-striped" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-missioni" role="tabpanel" aria-labelledby="pills-missioni-tab">
            <table class="table">
            <thead>
              <tr>
                <th scope="col" class="p-1" >Missioni</th>
                <th scope="col" class="p-1">Interventi</th>
                <th scope="col" class="p-1">Risorse</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let mission of popUpInfo.missioni">
                <th scope="row" class="p-1">{{mission.codice}}</th>
                <td class="p-1">{{mission.interventi}}</td>
                <td class="p-1">{{printValueAsCurrency(mission.risorse)}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="bg-dark text-white">
                <th scope="row" class="p-1 ">Totale</th>
                <td class="p-1">{{popUpInfo.totaliMissioni.interventi}}</td>
                <td class="p-1">{{printValueAsCurrency(popUpInfo.totaliMissioni.importi)}}</td>
              </tr>
            </tfoot>
          </table>
        </div>
          <div class="tab-pane fade" id="pills-tipologie" role="tabpanel" aria-labelledby="pills-tipologie-tab">
            <table class="table">
            <thead>
            <tr>
              <th scope="col" class="p-1" >Tipologie</th>
              <th scope="col" class="p-1">Interventi</th>
              <th scope="col" class="p-1">Quantità</th>

            </tr>
          </thead>
            <tbody>
              <tr *ngFor="let tipologie of popUpInfo.tipologie">
                <th scope="row" class="p-1">{{tipologie.descrizione}}</th>
                <td class="p-1">{{tipologie.quantita}}</td>
                <td class="p-1">{{tipologie.quantitaLoc}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="bg-dark text-white">
                <th scope="row" class="p-1">Totale</th>
                <td class="p-1">{{popUpInfo.totaliTipologie.interventi}}</td>
                <td class="p-1">{{popUpInfo.totaliTipologie.quantita}}</td>
              </tr>
            </tfoot>
          </table>
        </div>

        </div>

      </div>
    </div>



    <!-- button triple-->
    <div
      class="position-absolute start-50 translate-middle-x p-3 rounded mt-3"
      style="z-index: 1; background: rgba(255, 255, 255, 0.4)"
    >
      <div class="btn-group btn-group-toggle flex">
        <label
          class="btn btn-primary pointer btn-sm border-right border-white"
          id="label_chk_all"
          [ngClass]="{ attiva: checkedMuni }"
        >
          <input
            [checked]="checkedMuni"
            (change)="changeLayer('Comuni')"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio1"
            checked
          />Comuni
        </label>

        <label
          class="btn btn-primary pointer btn-sm border-right border-white"
          id="label_chk_p"
          [ngClass]="{ attiva: checkedProv }"
        >
          <input
            [checked]="checkedProv"
            (change)="changeLayer('Province')"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio2"
          />Province
        </label>
        <!-- <label
          class="btn btn-primary pointer border-right border-white btn-sm"
          id="label_chk_i"
          [ngClass]="{ attiva: checkedAsl }"
        >
          <input
            [checked]="checkedAsl"
            (change)="changeLayer('asl')"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio3"
          />ASL
        </label> -->
      </div>
    </div>

    <!-- legenda -->

    <div
      id="legenda"
      class="m-3 position-absolute bottom-0 end-0 p-3 rounded mb-4"
      style="z-index: 1; background: rgba(255, 255, 255, 0.4)"
    >
      <button
        class="w-100 btn btn-primary btn-sm px-4"

        type="button"
        data-toggle="collapse"
        data-target="#collapseLegend"
        aria-expanded="true"
        aria-controls="collapseLegend"
      >
        <i class="fas fa-ellipsis-v" aria-hidden="true"></i>&ensp;Legenda
      </button>
      <div class="collapse show p-0 mt-1" id="collapseLegend">
        <div
          class="card card-body border"
          style="
            background: rgba(255, 255, 255, 0.7);
            padding: 0.5rem !important;
          "
        >
          <div class="d-flex justify-content-end">
            <div class="d-flex align-items-end flex-column">
              <div class="small mr-1">
                max
                {{
                  this.colorsRange[this.colorsRange.length - 1]?.maxValue
                }}
                -&ensp;
              </div>
              <div class="mt-auto small mr-1">
                min {{ this.colorsRange[0]?.minValue }} -&ensp;
              </div>
            </div>
            <div
              class="my-2"
              style="
                width: 20px;
                height: 150px;
                background: linear-gradient(
                  0deg,
                  rgba(241, 249, 253, 1) 0%,
                  rgba(0, 39, 66, 1) 100%
                );
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
     <!--filtri attivi-->

     <div [style.opacity]="'0'"
     class="rounded form-group position-absolute top-0 end-0 m-3 p-3"
     style="z-index: 2000; background: rgba(255, 255, 255, 0.4); margin-top: 6rem!important; min-width: 241.25px; max-width: 320px;"
   >
   <button [disabled]="true"
   class="w-100 btn btn-outline-primary btn-sm mb-1 px-4"

   type="button"
   data-toggle="collapse"
   data-target="#collapseFilter"
   aria-expanded="false"
   aria-controls="collapseFilter"
 >
   <i class="fas fa-ellipsis-v" aria-hidden="true"></i>&ensp;Risultato:&nbsp;<span>{{totalNumTipologies}}</span>
 </button>
     <div class="collapse" id="collapseFilter">
      <div
          class="card card-body border"
          style="
            background: rgba(255, 255, 255, 0.4);
            padding: 0rem !important;
          "
        >
        <div class="p-2">
        <h2 class="display-5 h6">
          <span class="small">Risultato&nbsp;</span><span class="text-0083e0">{{totalNumTipologies}}</span><span class="small">&nbsp;interventi</span>
          <br/>
          <span class="small">di cui&nbsp;</span>
          <span class="text-0083e0 h6">{{this.getNonLocalizzati()}}</span>
          <span class="small">&nbsp;non localizzati</span>
          <div *ngIf="_cntLocalizzatiAltrove>0">
            <span class="h6" *ngIf="hasFilterProvince">localizzati in altra provincia:</span>
            <span class="h6" *ngIf="hasFilterMunicipality">localizzati in altro comune:</span>&nbsp;
            <span class="text-0083e0 h5">{{_cntLocalizzatiAltrove}}</span>
          </div> 
        </h2>
      </div>
          <ul class="list-group list-group-flush" >

            <li class="list-group-item small px-2 py-1 hide-custom-father" *ngFor="let item of conditions" [ngClass]="item.fieldTitle">{{item.fieldTitle}}: {{item.fieldValue}}</li>
            <li class="list-group-item small px-2 py-1 hide-custom-child">Nessun filtro attivo</li>

            <!--<li class="list-group-item small px-2 py-1 bg-002742 text-white" >Risultato:&nbsp;<span>{{totalNumTipologies}}</span></li>-->
          </ul>
        </div>
     </div>
   </div>


<!-- Risultato -->
<div class="rounded p-3 m-3  position-absolute top-0 " style="z-index: 2000; background: rgba(255, 255, 255, 0.4);">
<div class="bg-white border rounded p-2">
  <h2 class="display-5 mb-0 h6">
    <span class="small">Risultato:&nbsp;</span><span class="text-0083e0">{{totaleInterventi}}</span><span class="small">&nbsp;interventi</span>
    <br/>
    <span class="small">di cui&nbsp;</span>
    <span class="text-0083e0 h6">{{this.getNonLocalizzati()}}</span>
    <span class="small">&nbsp;non localizzati</span>
    <div *ngIf="_cntLocalizzatiAltrove>0">
        <span class="smaill" *ngIf="hasFilterProvince">localizzati in altra provincia:</span>
        <span class="small" *ngIf="hasFilterMunicipality">localizzati in altro comune:</span>
        <span class="text-0083e0 h6">{{_cntLocalizzatiAltrove}}</span>
        <ng-template #popoverNotaLocalizzatiAltrove>
            <ul>
                <li *ngFor="let v of _localizzatiAltrove">{{v}}</li>
            </ul>
          </ng-template>
        <a 
                  type="button"
                  class="help"
                  [popover]="popoverNotaLocalizzatiAltrove"
                  placement="bottom"
                  triggers="mouseenter:mouseleave"
                >
                  <i
                    class="far fa-question-circle text-0083e0 help"
                    style="font-size: 14px"
                  >
                  </i>
                </a>
      </div> 
  </h2>
</div>
</div>


    <!--seleziona mappa-->

    <div

      class="rounded form-group position-absolute top-0 end-0 m-3 p-3 d-none d-lg-block"
      style="z-index: 2000; background: rgba(255, 255, 255, 0.4);"
    >
      <div class="d-flex">
        <i class="fas fa-map my-2 mr-1 text-0083e0" style="font-size: 18px" aria-hidden="true"></i>
        <ng-select
          style="min-width: 185px"
          bindValue="code"
          bindLabel="title"
          groupBy="group"
          placeholder="Seleziona Mappa"
          selectOnTab="true"
          [clearable]="true"
          [searchable]="true"
          [items]="baseMapsLayers"
          (change)="chooseBasemap($event)"
        >
        </ng-select>
      </div>
    </div>

    <!-- export png -->
    <button
      id="export-png"
      crossorigin="anonymous"
      class="mask rounded btn btn-default position-absolute bottom-0 pointer text-primary start-0 m-3 mb-4"
      style="z-index: 50000; background: rgba(255, 255, 255, 0.4)"
      (click)="exportMap()"
    >
      <img
        src="assets/img/export.png"
        alt="pulsante esportazione"
        title="esporta png"
        class="opacity"
        style="
          width: 1.2em;
          height: 1.2em;
          min-width: 32px;
          min-height: 32px;
          padding: 0.05em 0.15em;
        "
      />
      <span class="pt-1 d-none d-sm-inline-block">Esporta PNG</span>
    </button>
    <a crossorigin="anonymous" id="image-download" #downloadLink download="mappa.png"></a>

  </div>
</div>
