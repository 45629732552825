<ng-container *ngIf="!projectId || projectId>=0">

    <div class="container p-0">
        <div class="row">


            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="">Codice Progetto{{isAdmin() || isGr34()?' / Fabbisogno':''}}: <span class="text-0083e0">{{mainIntervento?mainIntervento.codice:''}}</span></div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="">Descrizione&nbsp;Progetto{{ isAdmin() || isGr34()?' / Fabbisogno':''}}: <span class="text-0083e0">{{mainIntervento?mainIntervento.descrizione:''}}</span></div>

            </div>

            <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="interventoId">
                <div class="">Codice&nbsp;Intervento: <span class="text-0083e0">{{codiceIntervento}}</span></div>
            </div>

            <div class="col-lg-12" *ngIf="interventoId">
                <div class="">Descrizione&nbsp;Intervento: <span class="text-0083e0">{{descrizioneIntervento}}</span></div>
            </div>
        </div>

      
    </div>



   

    <div class="container p-0">
        <hr>
        <div class="row justify-content-center">



            <div class="col-lg-5 col-md-6 col-sm-12 col-12 ">


                <table>

                    <tr>
                        <td class="text-right">Valore progetto{{ isAdmin() || isGr34()?' / Fabbisogno':''}}:</td>
                        <td class="text-0083e0 text-left">{{printValueAsCurrency(valoreProgetto)}}</td>

                    </tr>
                    <tr *ngIf="isAdmin() || isGr34()">
                        <td class="text-right">Di cui finanziato:</td>
                        <td class="text-0083e0 text-left">{{printValueAsCurrency(progettoFinanziato)}}</td>

                    </tr>
                    <tr *ngIf="isAdmin() || isGr34()">
                        <td class="text-right">Finanziamento assegnabile agli interventi:</td>
                        <td class="text-0083e0 text-left">{{printValueAsCurrency(progettoFinanziato - progettoUtilizzato)}}</td>

                    </tr>
                    <tr *ngIf="isAdmin() || isGr34()">
                        <td class="text-right">Da finanziare:</td>
                        <td class="text-0083e0 text-left">{{printValueAsCurrency(progettoDaFinanziare)}}</td>

                    </tr>
                    <tr>
                        <td class="text-right">Capacità residua progetto{{ isAdmin() || isGr34()?' / fabbisogno':''}}:</td>
                        <td class="text-0083e0 text-left">{{printValueAsCurrency(disponibilitaProgetto)}}</td>

                    </tr>
                </table>

            </div>


            <div class="col-lg-4 col-md-6 col-sm-12 col-12 " *ngIf="interventoId">



                <table style="min-width: 270px;">

                    <tr>
                        <td class="text-right">Valore intervento:</td>
                        <td class="text-0083e0 text-left">{{printValueAsCurrency(valoreIntervento)}}</td>

                    </tr>
                    <tr *ngIf="isAdmin() || isGr34()">
                        <td class="text-right">Di cui finanziato:</td>
                        <td class="text-0083e0 text-left">{{printValueAsCurrency(interventoFinanziato)}}</td>

                    </tr>
                    <tr *ngIf="isAdmin() || isGr34()">
                        <td class="text-right">Da finanziare:</td>
                        <td class="text-0083e0 text-left">{{printValueAsCurrency(interventoDaFinanziare)}}</td>

                    </tr>

                </table>

            </div>



        </div>


        <hr>
    </div>





    <!--<div class="col-lg-6 my-3 text-left">
        <h6 class="colore-im  ">Valore Intervento: <span class="text-0083e0">{{printValueAsCurrency(valoreProgetto)}}</span></h6>


        <h6 class="colore-im  ">Di cui finanziato: <span class="text-0083e0">{{printValueAsCurrency(disponibilitaProgetto)}}</span></h6>

        <h6 class="colore-im  ">Da finanziare: <span class="text-0083e0">{{printValueAsCurrency(disponibilitaProgetto)}}</span></h6>

    </div>-->
</ng-container>


<!--<ng-container *ngIf="mainIntervento">
    <h6 class="colore-im col-lg-4 mb-3 mt-3 text-left">Codice Progetto: <span class="text-0083e0">{{mainIntervento.codice}}</span></h6>
    <h6 class="colore-im col-lg-8 mb-3 mt-3 ">
        <div class="row px-3">
            <div class="col-lg-4 p-0 text-align-dinamic">Descrizione&nbsp;Progetto:&nbsp;</div>
            <div class="col-lg-8 p-0 text-0083e0">{{mainIntervento.descrizione}}</div>
        </div>
    </h6>
    <div class="col-lg-6 mb-3 mt-3 text-align-dinamic">
        <h6 class="colore-im  ">Valore Progetto: <span class="text-0083e0">{{printValueAsCurrency(valoreProgetto)}}</span></h6>


        <h6 class="colore-im  ">Di cui finanziato: <span class="text-0083e0">{{printValueAsCurrency(disponibilitaProgetto)}}</span></h6>

        <h6 class="colore-im  ">Da finanziare: <span class="text-0083e0">{{printValueAsCurrency(disponibilitaProgetto)}}</span></h6>


        <h6 class="colore-im  ">Disponibilità Progetto: <span class="text-0083e0">{{printValueAsCurrency(disponibilitaProgetto)}}</span></h6>


    </div>
    <div class="col-lg-6 mb-3 mt-3 text-align-dinamic">
        <h6 class="colore-im  ">Valore Intervento: <span class="text-0083e0">{{printValueAsCurrency(valoreProgetto)}}</span></h6>


        <h6 class="colore-im  ">Di cui finanziato: <span class="text-0083e0">{{printValueAsCurrency(disponibilitaProgetto)}}</span></h6>

        <h6 class="colore-im  ">Da finanziare: <span class="text-0083e0">{{printValueAsCurrency(disponibilitaProgetto)}}</span></h6>

    </div>
</ng-container>-->