import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import {  CommonModule } from "@angular/common";
import{CompleterComponent} from './index';



@NgModule({
  declarations: [
    CompleterComponent
],
  exports: [
    CompleterComponent
]

  ,imports:[
      CommonModule,
      FormsModule,
      ReactiveFormsModule     
  ],
  providers: [    
  ],
  entryComponents: [    
  ]
})
export class CompleterModule {}