import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services';
import { TitleLogoService } from 'src/app/services/title-logo.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  title: string;

  logo: string;

  constructor(
    private authService:AuthService,
    private titleLogoService: TitleLogoService
    ) { }
    headerCode:string = '';

  ngOnInit() {
    this.headerCode = this.authService.getHeaderCode();

    this.title = this.titleLogoService.title;
    this.logo = this.titleLogoService.logo;

  }
  isCustomHeader(){
    return this.headerCode && this.headerCode !='generale';

  }


}
