import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";


@Injectable({ providedIn: "root" })
export class ProgettoDivDettaglioService {
  private subject = new Subject<any>();
  private subjectIntervento = new Subject<any>();
  private id:number;
  private interventoId:number;

  constructor() {
  }
  

  reload(){
    //console.log(`inside reload with id = ${this.id}`);
        this.subject.next(null);
        this.subject.next(this.id);
        this.subjectIntervento.next(null);
        this.subjectIntervento.next(this.interventoId);
  }

 setId(id:number){
     //console.log(`inside setId with id = ${id}`);
     this.id = id;
     this.reload();
 }
    

  getId(): Observable<any> {
    return this.subject.asObservable();
  }  

  setInterventoId(id:number){
      this.interventoId = id;
      this.reload();
  }

  getInterventoId():Observable<any>{
      return this.subjectIntervento.asObservable();
  }
  
}
