import { Progetto } from "./progetto";
import { TipoProvvedimento } from "./tipo-provvedimento";
import { ScopoProvvedimento } from "./scopo-provvedimento";
import { ProvvedimentoCapitoloBilancio } from "./provvedimento-capitolo-bilancio";
import { SoggettoGiuridico } from "./soggetto-giuridico";

export class Provvedimento {
  id: number;
  data: Date | string;
  progetto: Progetto;
  tipoProvvedimento: TipoProvvedimento;
  scopoProvvedimento: ScopoProvvedimento;
  provvedimentoCapitoliBilancio: ProvvedimentoCapitoloBilancio[];
  soggettiGiuridici: SoggettoGiuridico[];
  tipoProvvedimentoId: number; //only view
  tipoProvvedimentoDescrizione: string; //only view
  scopoProvvedimentoId: number; //only view
  scopoProvvedimentoDescrizione: string; //only view
  progettoId: number; //only view
  numero: string;
  documenti: any[];
  oggetto: string;
  descrizioneAree: any;
}
