<!--<div class="p-5 container-xl text-center" [hidden]="loadDataCounter<=0">
    <app-loading message="Caricamento in corso..."></app-loading>
</div>-->
<div class="container-xl">
    <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptions">
        <ng-template #btnEditTemplate let-row="row" let-value="value">
            <a [ngClass]="{
                'disabled': loading
                }" class=" border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top" title="{{isReadOnly(row)?'dettaglio':'modifica dati'}}">&nbsp;&nbsp;&ensp;<i class="far pointer" [ngClass]="{'fa-edit': !isReadOnly(row),'fa-eye': isReadOnly(row),'detail-icon': isReadOnly(row)}" ></i></a>
        </ng-template>
        <ng-template #btnGeoTemplate let-row="row" let-value="value">
            <a [ngClass]="{
            'disabled': true
            }" class=" border-0 text-center" data-toggle="tooltip" data-placement="top" title="{{row.nonLocalizzabile?'non localizzabile':(value)?'Localizzazione presente':'Nessuna localizzazione presente'}}">
                <i class="fas  text-center font-size-18" [ngClass]="{'text-info icon-no-map': row.nonLocalizzabile
                ,'text-success fa-map-marker-alt':value && !row.nonLocalizzabile
                ,'text-secondary fa-map-marker-alt':!value && !row.nonLocalizzabile
                ,'pointer fa-map-marker-alt': !readOnlyFormNoDetail && !row.nonLocalizzabile
                }"></i>
            </a>
        </ng-template>

        <ng-template #fieldNoteTemplate let-row="row" let-value="value">
            <i class="fas font-size-18 text-secondary" [ngClass]="{
                      ' fa-comment-slash': !value,
                      ' fa-comment text-info help px-1': value
                    }" popover="{{value}}" placement="left" triggers="mouseenter:mouseleave"></i>
        </ng-template>

        <ng-template #direzioneFieldValueTemplate let-row="row" let-value="value">
            <ul class="pl-2" *ngIf="value && value !=''">
                <li class="text-lowercase" *ngFor="let val of printDirezioneFieldValue(value)">{{val}}</li>
            </ul>
        </ng-template>
        <!--<ng-template #annualitaFieldValueTemplate let-row="row" let-value="value">
            {{calcolaValoreIntervento(row)}}
        </ng-template>-->

        <ng-template #extraFilterTemplate>
            <form class="form-inline  justify-content-center px-0 px-sm-3">
                <label class="mb-sm-2 mb-md-0">Aree gestionali direzione e/o esterne:&nbsp;</label>

                <ng-select style="letter-spacing: -0.5px; min-width: 330px;" class="" [items]="areeTematicheCombo" bindValue="id" bindLabel="descrizione" placeholder="Seleziona area gestionale direzione e/o esterna " (change)="filterAreaTematica($event)">
                    <ng-template ng-option-tmp let-item="item">
                        <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
                    </ng-template>
                </ng-select>

            </form>

        </ng-template>

        <ng-template #annualitaFieldValueTemplate let-row="row" let-value="value" let-index="index">
            <table class="w-100 table table-borderless" id="detail_importi_{{row.id}}">
                <tr class="collapse trdetail" *ngFor="let val of sortImporti(row.importi)">

                    <td class="pt-0 pb-1 pl-0 pr-1">{{val.anno}}:</td>
                    <td class="pt-0 pb-1 text-0083e0 pl-0">{{printValueAsCurrency(val.importo)}}</td>
                    <tr>
                        <td class="pt-0 pb-1 pl-0 pr-1">
                            <a class="show-detail-anchor collapse show" data-toggle="collapse" attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i class="fas fa-list-ul pointer"></i></a>
                            <!--<a class="trdetail show-detail-anchor collapse show" data-toggle="collapse" attr.data-target="#detail_importi_{{row.id}} .trdetail" data-placement="top" title="mostra dettaglio"><i class="fas fa-edit pointer"></i></a>-->
                        </td>
                        <td class="pt-0 pb-1 pl-0">{{ calcolaValoreIntervento(row)}}</td>
                    </tr>
            </table>
        </ng-template>
        <ng-template #btnCheckTemplate let-row="row" let-value="value">
            <a class=" border-0 text-center" data-toggle="tooltip" data-placement="top" title="{{printBtnValidazioneTitle(value) }}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <i class=" font-size-18  " [ngClass]="{
                    'text-danger': value=='FABBISOGNO',
                    'text-success': value=='APPROVED',
                    'fa-check-circle': value=='APPROVED',
                    'fa-ban': value=='REJECTED',
                    'text-warning': value =='WAITING_FOR_APPROVAL_FIRST_LEVEL' || value =='WAITING_FOR_APPROVAL_SECOND_LEVEL',
                    'fa-pause-circle': value =='WAITING_FOR_APPROVAL_FIRST_LEVEL' || value =='WAITING_FOR_APPROVAL_SECOND_LEVEL',
                    'fa-adjust': value=='IBRIDO',
                    'text-dark': (value=='IBRIDO' || value=='IDLE'),
                    'fa-circle': ( (value !='IBRIDO'
                    && value!='REJECTED'
                    && value!='APPROVED'
                    && value!='WAITING_FOR_APPROVAL_FIRST_LEVEL'
                    && value!='WAITING_FOR_APPROVAL_SECOND_LEVEL'
                    && value!='TERMINATO'
                    ))
                    ,'far': !value || value=='' || value=='IDLE'
                    ,'fas': value && value!='' && value!='IDLE'
                    ,'fa-check-square': value=='TERMINATO'
                    ,'text-0083e0': value=='TERMINATO'
                    }">
                </i></a>
        </ng-template>

    </ngx-datatable-wrapper>



    <div class="bold text-center mb-3">Legenda:</div>
    <div class="d-flex justify-content-around mb-5 flex-wrap">

        <div><i class="fas fa-circle font-size-18 text-danger no-hover"></i><span>&nbsp;&nbsp;fabbisogno</span></div>
        <div><i class="fas fa-adjust font-size-18 no-hover"></i><span>&nbsp;&nbsp;parzialmente finanziato</span></div>
        <div><i class="far fa-circle font-size-18"></i><span>&nbsp;&nbsp;da validare</span></div>
        <div><i class="fas fa-pause-circle font-size-18 text-warning no-hover"></i><span>&nbsp;&nbsp;in attesa di validazione</span></div>
        <div><i class="fas fa-check-circle font-size-18 text-success no-hover"></i><span>&nbsp;&nbsp;validato</span></div>
        <div><i class="fas fa-ban font-size-18 no-hover"></i><span>&nbsp;&nbsp;rifiutato</span></div>
        <div><i class="fas fa-check-square font-size-18 text-0083e0 no-hover"></i><span>&nbsp;&nbsp;completato</span></div>

    </div>

</div>

<ng-template #templateEditAdd>
    <div class="modal-header text-center bg-0083e0">
        <h4 class="modal-title pull-left text-white">Dettaglio monitoraggio</h4>
        <button type="button" class="close pull-right text-white" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body bg-trasparent">

        <monitoraggio-component [isInModal]="true" #divTemplateMonitoraggio
        [interventoId]="interventoId"
        [gotoBtnOptions]="{show:true,label: 'Chiudi',showIcon:true} "
        (gotoBtnFunction)="closeModal($event) "
        [isReadOnly]="true"
        [reloadAllDataOnSetInterventoId]="true"
        >
        </monitoraggio-component>


    </div>
</ng-template>
