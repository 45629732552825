<div class="modal-header text-center">
    <h4 class="modal-title pull-left">{{modalDefinition.pageTitle}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body  ">
    <div class="p-5 container-xl text-center" [hidden]="!loading">
        <app-loading message="Caricamento in corso..."></app-loading>
    </div>
    <div [hidden]="loading">
        <!-- <div class="row mx-0">
    <div Class="col-lg-3 col-md-6 px-0">
      <p class="bold text-left">Operazione: <span class="text-0083e0">{{getAzioneLabel()}}</span></p>
    </div>
    <div Class="col-lg-3 col-md-6 px-0">

    <p class="bold text-left">Data: <span class="text-0083e0">{{getAzioneData()}}</span></p> 
  </div>
    <div Class="col-lg-3 col-md-6 px-0">
      <p class="bold text-left">Utente: <span class="text-0083e0">{{getAzioneUtente()}}</span></p> 
    </div>
      <div Class="col-lg-3 col-md-6 px-0">
        <p class="bold text-left">Descrizione: <span class="text-0083e0">{{getAzioneDescrizione()}}</span></p>
      </div>
  </div>-->
        <div class="d-flex justify-content-between flex-wrap">
            <p class="bold text-left border rounded p-1 {{storicoBorder()}}">Operazione: <span class="{{storicoColor()}}">{{getAzioneLabel()}}</span></p>

            <p class="bold text-left p-1">Data: <span class="{{storicoColor()}}">{{getAzioneData()}}</span></p>

            <p class="bold text-left p-1">Utente: <span class="{{storicoColor()}}">{{getAzioneUtente()}}</span></p>

            <p class="bold text-left p-1">Descrizione: <span class="{{storicoColor()}}">{{getAzioneDescrizione()}}</span></p>
        </div>



        <table class="table table-bordered  table-wrapper-scroll-y scrollbar-y h-storico">
            <thead>
                <tr style="width:100%;">
                    <th scope="col" style="width:14%;" style="min-width: 164px;">Campo</th>
                    <th scope="col" style="width:43%;">Versione Precendente</th>
                    <th scope="col" style="width:43%;">Versione Attuale</th>

                </tr>
            </thead>
            <tbody>

                <ng-container *ngFor="let item of newObjectPrperties">
                    <ng-container *ngIf="isGoodProperty(item)">
                        <tr>
                            <th scope="row" class="capitalize">{{printFieldName(item) === 'disponibilità' ? printFieldName(item) + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' : printFieldName(item)}}</th>
                            <td>
                                <ng-container *ngIf="isChildrenObject(item)">
                                    <ng-container *ngIf="isChildrenComplexObject(item)">
                                        <div [innerHTML]="getComplexObjectHtml(item,oldObjectData,false)"></div>
                                    </ng-container>
                                    <ng-container *ngIf="!isChildrenComplexObject(item)">
                                        <ul class="list-group font-14">
                                            <ng-container *ngFor="let subItem of getSubItemObjects(item,oldObjectData)">
                                                <li class="list-group-item font-14">{{printSubItemValue(subItem,item)}}</li>
                                            </ng-container>
                                        </ul>
                                    </ng-container>

                                </ng-container>
                                <ng-container *ngIf="!isChildrenObject(item)">
                                    {{printOldFieldValue(item)}}
                                </ng-container>
                            </td>
                            <td class=" {{!isChildrenObject(item)?getClassName(item):''}}">
                                <ng-container *ngIf="isChildrenObject(item)">
                                    <ng-container *ngIf="isChildrenComplexObject(item)">
                                        <div [innerHTML]="getComplexObjectHtml(item,newObjectData,true)"></div>
                                    </ng-container>
                                    <ng-container *ngIf="!isChildrenComplexObject(item)">
                                        <ul class="list-group font-14">
                                            <ng-container *ngFor="let subItem of getSubItemObjectsActualVersion(item)">
                                                <li class="list-group-item font-14 {{getSubItemClass(subItem)}} ">{{printSubItemValue(subItem,item)}}</li>
                                            </ng-container>
                                        </ul>
                                    </ng-container>



                                </ng-container>
                                <ng-container *ngIf="!isChildrenObject(item)">
                                    {{ (!newObjectData || ( newObjectData[item] != null && newObjectData[item].toString()!=''))? printFieldValue(item):printOldFieldValue(item)}}
                                </ng-container>

                            </td>

                        </tr>



                    </ng-container>
                </ng-container>



                <!--<tr>
        <th scope="row">Importi</th>
        <td>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col" style="width:10%;">Anno</th>
                <th scope="col" style="width:30%;">Disponibilità finanziamento per l'anno selezionato:</th>
                <th scope="col" style="width:30%;">Valore progetto per l'anno selezionato</th>
                <th scope="col" style="width:30%;">Importo</th>

              </tr>
            </thead>
            <tbody>

              <tr>
                <td scope="row">2020</td>
                <td>Ferrovia Roma - Viterbo</td>
                <td class="">Ferrovia Roma - Viterbo</td>
                <td class="">3000 €</td>

              </tr>
            </tbody>
          </table>
        </td>
        <td>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col" style="width:10%;">Anno</th>
                <th scope="col" style="width:30%;">Disponibilità finanziamento per l'anno selezionato:</th>
                <th scope="col" style="width:30%;">Valore progetto per l'anno selezionato</th>
                <th scope="col" style="width:30%;">Importo</th>

              </tr>
            </thead>
            <tbody>

              <tr>
                <td class="text-danger text-delete" scope="row">2020</td>
                <td class="text-danger text-delete">Ferrovia Roma - Viterbo</td>
                <td class="text-danger text-delete">Ferrovia Roma - Viterbo</td>
                <td class="text-danger text-delete">3000 €</td>

              </tr>
            </tbody>
          </table>
        </td>

      </tr>-->



            </tbody>
        </table>
    </div>

</div>
<div class="" style="border-top: 1px solid #e9ecef;">
    <!--<button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>-->
    <p class="bold text-center mt-2">Legenda:</p>


    <div class="row mb-3">



        <p class="col-lg-3 text-center mb-1">campo non modificato</p>
        <p class="text-0083e0 col-lg-3 text-center mb-1">campo modificato</p>
        <p class="text-success col-lg-3 text-center mb-1">nuovo campo</p>
        <p class="text-danger text-delete col-lg-3 text-center mb-1">campo eliminato</p>



    </div>

</div>