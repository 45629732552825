import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  DashboardService,
  SoggettoResponsabileService,
  TipoClassificazioneService,
  TipologiaFaseService,
  TipologiaSoggettoResponsabileService,
  TemaPrioritarioService,
  StorageService,  
  FaseService
} from "../../services";

import {  map } from "rxjs/operators";
import { Subject } from "rxjs";
import * as FileSaver from "file-saver";
import { ToastService } from "src/app/services/toast.service";
import * as moment from "moment";


@Component({
  selector: "app-dashboard-fasi-gantt",
  templateUrl: "./dashboard-fasi-gantt.component.html",
  styleUrls: ["./dashboard-fasi-gantt.component.css"],
})
export class DashboardFasiGanttComponent implements OnInit, OnDestroy {

  noresult = false;
  myForm: FormGroup;
  exportForm:FormGroup;
  //filters
  mainTypesCombo: Array<{ k: string; v: string }> = [
    { k: "i", v: "Interventi" },
    { k: "p", v: "Progettualità/fabbisogni" },
    { k: "f", v: "Finanziamenti" },
    { k: "t", v: "Tutti" },
  ];

  flussoDiCassaItems: Array<{ k: string; v: string }> = [
    { k: "1", v: "Si" },
    { k: "0", v: "No" },
  ];

  allCompleterElementi = [];
  allCompleterFInanziamenti = [];
  allCompleterInterventi = [];

  filteredElements;

  

  completerParams = {
    labelField: "parentTitle",
    placeholder: "Cerca intervento; prog./fabbisogno; finanz.",
    ajax: this.filterComboMainObjectId,
    context:this,

    items: [],
  };

  loading = false;

  filterComboMainObjectId(filterString,callBack){
    this['context'].faseService.getAllPhases(
        {
            mainObjectType:'t' 
            ,filterAreaTematica:-1
            ,filterTipologiaFase:-1  
            ,soggettoResponsabileId:-1  
            ,tipologiaSoggettoResponsabileId:-1 
            ,mainObjectId:'' 
            ,mainObjectStringFilter:filterString       
        }

    ).subscribe(
        result=>{
            let res = [];
            if (result.finanziamenti){
                result.finanziamenti.forEach(
                    
                    finanziamento=>{
                        if(!res.find(x=>x.id == finanziamento.codice))
                            res.push({
                            id:finanziamento.codice
                            ,parentType:'FINANZIAMENTI'
                            ,parentTitle: finanziamento.codice + ' - ' + finanziamento.titolo
                            ,internalId: finanziamento.finanziamentoId
                        }
                            );
                    }
                );
            }
            if (result.progetti){
                result.progetti.forEach(
                    progetto =>{
                        if(!res.find(x=>x.id == progetto.codice)){
                            //siccome il progetto mi viene reso sempre, devo verificare se il filtro è presente nell adescrizione o nel codice, altrimenti non lo inserisco nell'array
                            if (progetto.codice.toLowerCase().includes(filterString.toLowerCase()) || progetto.titolo.toLowerCase().includes(filterString.toLowerCase()))
                                res.push({
                                    id:progetto.codice
                                    ,parentType:'PROGETTI'
                                    ,parentTitle: progetto.codice + ' - ' + progetto.titolo
                                    ,internalId: progetto.interventoId
                                }
                                    
                                    );
                        }
                        if (progetto.interventi){
                            progetto.interventi.forEach(
                                intervento=>{
                                    if(!res.find(x=>x.id == intervento.codice))
                                        res.push({
                                        id:intervento.codice
                                        ,parentType:'INTERVENTI'
                                        ,parentTitle: intervento.codice + ' - ' + intervento.titolo
                                        ,internalId: intervento.interventoId
                                    });
                                }
                            );
                        }
                    }
                );
            }
            callBack(res);
        }
    );
}

  completerParamsSoggetti = {};
  areeTematicheCombo = [];
  avanzamentoCombo: Array<{ k: string; v: string }> = [
    { k: "7", v: "Future" },
    { k: "1", v: "In scadenza" },
    { k: "2", v: "Concluse" },
    { k: "3", v: "In corso" },
    { k: "4", v: "In ritardo" },
    { k: "5", v: "Scadute" },
    { k: "6", v: "Nessuna fase" },
  ];
  classificazioniCombo = [];
  tipologieResponsabiliCombo = [];
  tipologieFasiCombo = [];
  soggettiCombo = [];
  temiPrioritariCombo = [];

  filterMain = "";
  completerId = -1;
  completerObject = null;
  filterArea = -1;
  filterStatoFasi = -1;
  filterClassificazione = -1;
  filterTipologiaResponsabile = -1;
  filterTipologiaFasi = [];
  completerSoggettiId = -1;
  filterTemiPrioritari = [];
  
  description: string = "";

  chartStatoFasi;
  chartAvanzamentoFasi;
  chartSoggettiResponasabili;
  chartTipologieFasi;
  chart6;

  classificationsCodes = [];
  classifications = [];
 
  elencoClassificazioni = [];

  isHiddenChart6: boolean = true;
  showPage = false;
  gantt;
  constructor(
    private formBuilder: FormBuilder,
    private classificazioniService: TipoClassificazioneService,
    private tipologiaResponsabileService: TipologiaSoggettoResponsabileService,
    private tipologiaFaseService: TipologiaFaseService,
    private dashboardService: DashboardService,
    private soggettiService: SoggettoResponsabileService,
    private toastr: ToastService
    ,private modalService: BsModalService,
    private temaPrioritarioService: TemaPrioritarioService,
    private storageService:StorageService,
    private faseService: FaseService    
  ) {}

  ngOnInit() {
    am4core.useTheme(am4themes_animated);
    am4core.options.autoSetClassName = true;
    this.myForm = this.initializeFormGroup();    
    this.exportForm = this.formBuilder.group({
      intervallo:[null,Validators.required],
      tipologieRaggruppate:[null],
      raggruppamentoMesi:['Mensile']
    });


    const dataDown = [
      {
        name: "Iter autorizzativo definitivo (pubblica utilità, VIA/PAUR, ecc.) Atto di approvazione a valle della C.d.S. decisoria",
        fromDate: "2018-05",
        toDate: "2018-10",
        color: am4core.color("#f00", 0.5),
        tipologia: "Progettazione"
      },
      {
        name: "Verifica, validazione ed approvazione Atto di validazione e approvazione finale",
        fromDate: "2018-10",
        toDate: "2018-11",
        color: am4core.color("#f00", 0.5),
        tipologia: "Progettazione"
      },
      {
        name: "Parere Comitato regionale per i lavori pubblici Parere Comitato regionale per i lavori pubblici ai sensi della L.R. 31 Gennaio 2002, n. 5",
        fromDate: "2018-11",
        toDate: "2018-12",
        color: am4core.color("#f00", 0.5),
        tipologia: "Progettazione"
      },

      {
        name: "Attività propedeutiche all'esecuzione Attività propedeutiche della S.A. e dell'esecutore (notifiche, polizze, cronoprogrammi, POS, ecc.) Verbale di consegna dei lavori (o delle attività)",
        fromDate: "2018-12",
        toDate: "2019-01",
        color: am4core.color("#00f", 0.5),
        tipologia: "Esecuzione (Lavori / Manutenzione Straordinaria)"
      },
      {
        name: "Esecuzione del contratto - Progettazione Saldo della Progettazione a S.A.",
        fromDate: "2018-12",
        toDate: "2019-01",
        color: am4core.color("#f0f", 0.5),
        tipologia: "Flussi cassa - Progettazione"
      },
      {
        name: "Esecuzione del contratto - Lavori Anticipazione a S.A. del 30%",
        fromDate: "2018-12",
        toDate: "2019-01",
        color: am4core.color("#ff0", 0.5),
        tipologia: "Flussi cassa - Esecuzione "
      },

    ]

    this.renderGant1(dataDown);
  }

  ngOnDestroy() {
    this.disposeCharts();
    if(this.elencoAreeSubscription)this.elencoAreeSubscription.unsubscribe();
  }

  initializeFormGroup() {
    const form = this.formBuilder.group({
      main: [null, null],
      completer: [{ value: null, disabled: false }, null],
      direction: [{ value: null, disabled: false }, null],
      statoFasi: [{ value: null, disabled: false }, null],
      classification: [{ value: null, disabled: false }, null],
      tipologiaResponsabile: [{ value: null, disabled: false }, null],
    });

    this.getAreeTematiche();
    this.getClassificazioni();
    this.getTipologieResponsabili();
    this.getTipologieFasi();
    this.getSoggettiResponsabili();
    this.getTemiPrioritari();
    return form;
  }

  get f() {
    return this.myForm.controls;
  }

  /* FILTERS AND EVENTS */

  filter(init: boolean) {
    this.getSoggettiResponsabili();
    this.doFilter(      
      init
    );
  }

  doFilter(   
    init: boolean    
  ) {
    

    this.loading = true;
    let tipologieRaggruppate = this.exportForm.controls.tipologieRaggruppate.value;    
    let mainType = "";
    let idFin = -1;
    let idInt = -1;
    let idPro = -1;
    if (this.completerObject) {
      switch (this.completerObject.parentType) {
        case "INTERVENTO":
        case "INTERVENTI":
          idInt = this.completerObject.internalId;
          break;
        case "PROGETTO":
        case "PROGETTI":
          idPro = this.completerObject.internalId;
          break;
        case "FINANZIAMENTO":
        case "FINANZIAMENTI":
          idFin = this.completerObject.internalId;
          break;
      }
    }
    if (this.filterMain) {
      switch (this.filterMain) {
        case "i":
          mainType = "INTERVENTI";          
          break;
        case "p":
          mainType = "PROGETTI";          
          break;
        case "f":
          mainType = "FINANZIAMENTI";          
          break;
      }
    }
    this.showPage = false;
    this.dashboardService
      .getDashboardFasiGanttData(
        idPro,
        idInt,
        idFin,
        this.filterArea,
        this.filterClassificazione,
        mainType,
        this.filterTipologiaResponsabile,
        this.filterTipologiaFasi,
        this.filterStatoFasi,
        this.completerSoggettiId,
        this.filterFlussoDiCassa,
        tipologieRaggruppate,
        this.filterTemiPrioritari
      )
    
      .subscribe((res) => {
        this.filteredElements = res;
        
        if(this.filteredElements && this.filteredElements.finanziamenti.length === 0 && this.filteredElements.progetti.length === 0){
          this.showPage = false;
          this.noresult = true;
        }else{
          this.noresult = false;
          this.showPage = true;
        }
        this.loading = false;
      });
    
  }

  getUniqueArrayElements(arr) {
    let u = [];
    arr.forEach((element) => {
      if (!u.find((x) => x.parentCode == element.parentCode)) u.push(element);
    });
    return u;
  }

  private elencoAreeSubscription = null;
    getAreeTematiche() {
       
        this.elencoAreeSubscription = this.storageService.elencoAreeTematiche.subscribe(
            x=>{
                console.log(x);
                this.areeTematicheCombo = x?x:[];
            }
            );
    }

  getClassificazioni() {
    this.classificazioniService.getAll().subscribe((x) => {
      console.log(x);
      this.classificazioniCombo = x;
    });
  }

  getTipologieResponsabili() {
    this.tipologiaResponsabileService.getAll().subscribe((result) => {
      this.tipologieResponsabiliCombo = result;
    });
  }

  getTipologieFasi() {
    this.tipologiaFaseService
      .getAll()
      .pipe(
        map((data) => {
          return data.map((elem) => {
            return {
              k: elem.id,
              v: `${elem.fase}-${elem.titolo}-${elem.descrizione}`,
              fase:elem.fase
            };
          });
        })
      )
      .subscribe((mappedRes) => {
        this.tipologieFasiCombo = mappedRes.filter(x=>x.fase);        
      });
  }

  getSoggettiResponsabili() {
    let that = this;
    this.soggettiService.getAll().subscribe((result) => {
      that.soggettiCombo = result;
      that.soggettiCombo.forEach((element) => {
        element["label"] = `${element.cognome} ${element.nome} - ${element.tipologia.descrizione}`;
      });
      this.completerParamsSoggetti = {
        items: this.soggettiCombo,
        labelField: "label",
        ajax: null,
        placeholder: "Cerca  ",
      };
    });
  }

  getTemiPrioritari(){
    this.temaPrioritarioService.getAll().subscribe((result) => {
      this.temiPrioritariCombo = result;
    });
  }

  areAllFiltersDisabled(){
   return this.filterMain === ""
      && this.filterArea < 0
      && this.filterStatoFasi < 0
      && this.filterClassificazione < 0
      && this.filterTipologiaResponsabile < 0
      && this.filterTipologiaFasi.length === 0
      && this.filterFlussoDiCassa < 0
      && this.completerSoggettiId < 0
      && this.completerId < 0
      && this.filterTemiPrioritari.length === 0
  }

  filterOrHide(){
    if(!this.areAllFiltersDisabled()){
      this.filter(false)
    }else{
      this.showPage = false;
    }
  }

  setMain($event) {
    this.filterMain = $event ? $event.k : "";    
  }

  setAreaTematica($event) {
    this.filterArea = $event ? $event.id : -1;
    !this.areAllFiltersDisabled(); 
  }

  setInterventionCompleter($event) {
    if ($event) {
      this.completerObject = $event;
      this.completerId = $event.internalId; 
    } else {
      this.completerId = -1;
      this.completerParams.items = [];
      this.completerObject = null;
    }
    this.description = $event && $event.parentTitle;   
  }

  onChangeInterventionCompleter($event) {
    !$event.target.value && this.setInterventionCompleter(null);
  }

  onChangeSoggettiCompleter($event) {
    !$event.target.value && this.setSoggettiCompleter(null);
  }

  setStatoFasi($event) {
    this.filterStatoFasi = $event ? $event.k : -1;    
  }

  setClassificazione($event) {
    this.filterClassificazione = $event ? $event.id : -1;
    $event ? (this.isHiddenChart6 = false) : (this.isHiddenChart6 = true);
    
  }

  setTipologiaResponsabile($event) {
    this.filterTipologiaResponsabile = $event ? $event.id : -1;    
  }

  setTipologiaFase($event) {
    this.filterTipologiaFasi = $event ? $event.map(x=>x.k) : [];    
  }

  setTemaPrioritario($event){
    this.filterTemiPrioritari = $event ? $event.map( x => x.id) : [];    
  }

  filterFlussoDiCassa = -1;
  setFlussoDiCassa($event) {
    this.filterFlussoDiCassa = $event ? $event.k : -1;   
  }

  setSoggettiCompleter($event) {
    this.completerSoggettiId = $event ? $event.id : -1;
    
  }

  /* CHARTS */
  drawCharts() {    
  }

  


  disposeCharts() {
    
  }

  exportChart(chart, title) {
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = "right";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.background = "#ff0000";
    chart.exporting.menu.defaultStyles = true;
    chart.exporting.filePrefix = title;
    chart.exporting.menu.items = [
      {
        menu: [
          { type: "jpg", label: "JPG" },
          { type: "png", label: "PNG" },
          { type: "csv", label: "CSV" },
        ],
      },
    ];
    chart.exporting.menu.items[0].icon = "assets/img/export.png";
  }

  aggiungiLogo(logoChart) {
    // Add watermark
    let watermark = new am4core.Image();
    watermark.href = "assets/img/logo_blu.svg";
    logoChart.tooltipContainer.children.push(watermark);
    watermark.align = "right";
    watermark.valign = "bottom";
    watermark.opacity = 0.3;
    watermark.marginRight = 10;
    watermark.marginBottom = 5;
    watermark.disabled = true;

    // Enable watermark on export
    logoChart.exporting.events.on("exportstarted", function (ev) {
      watermark.disabled = false;
    });

    // Disable watermark when export finishes
    logoChart.exporting.events.on("exportfinished", function (ev) {
      watermark.disabled = true;
    });

    // Add watermark to validated sprites
    logoChart.exporting.validateSprites.push(watermark);

  }


  tabellaDettaglioObj: any;
  tabellaDettaglioObjLoaded = false;
  subjectTabDettaglio = new Subject<any>();
  observable$ = this.subjectTabDettaglio.asObservable();

  getTabellaDettaglioObj(obj) {
    this.tabellaDettaglioObj = obj;
    this.subjectTabDettaglio.next(this.tabellaDettaglioObj);

    //this.tabellaDettaglioObjLoaded = true;
  }
  loadingExportExcel = false;

  doEsportaExcel() {
      //get tipologie raggruppate flag!
    let tipologieRaggruppate = this.exportForm.controls.tipologieRaggruppate.value;
    let raggruppamentoMesi = this.exportForm.controls.raggruppamentoMesi.value;
    console.log(tipologieRaggruppate);
    let mainType = "";
    let idFin = -1;
    let idInt = -1;
    let idPro = -1;
    if (this.completerObject) {
      switch (this.completerObject.parentType) {
        case "INTERVENTO":
        case "INTERVENTI":
          idInt = this.completerObject.internalId;
          break;
        case "PROGETTO":
        case "PROGETTI":
          idPro = this.completerObject.internalId;
          break;
        case "FINANZIAMENTO":
        case "FINANZIAMENTI":
          idFin = this.completerObject.internalId;
          break;
      }
    }
    if (this.filterMain) {
      switch (this.filterMain) {
        case "i":
          mainType = "INTERVENTI";
          
          break;
        case "p":
          mainType = "PROGETTI";
          
          break;
        case "f":
          mainType = "FINANZIAMENTI";
          
          break;
      }
    }
    

    this.loadingExportExcel = true;
    this.dashboardService
      .getDashboardFasiGanttExport(
        idPro,
        idInt,
        idFin,
        this.filterArea,
        this.filterClassificazione,
        mainType,
        this.filterTipologiaResponsabile,
        this.filterTipologiaFasi,
        this.filterStatoFasi,
        this.completerSoggettiId,
        this.filterFlussoDiCassa,
        tipologieRaggruppate,
        raggruppamentoMesi,
        this.filterTemiPrioritari
      )
      .subscribe(
        (res) => {
          let filename = `esportazione_gantt_fasi_${moment(new Date()).format(
            "YYYY_MM_DDTHH_mm_ss"
          )}.xls`;
          FileSaver.saveAs(res.body, `${filename}`);
          this.loadingExportExcel = false;
        },
        (error) => {
          this.toastr.error(
            `Errore: ${error.error.message
              ? error.error.message
              : error.error.error_description
                ? error.error.error_description
                : error.error
            }`,
            null,
            {
              timeOut: 2000,
              disableTimeOut: false,
            }
          );
          //console.log("error");
          this.loadingExportExcel = false;
        }
      );
  }



  renderGant1(data) {
    /// Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    this.gantt = am4core.create("chartdiv1", am4charts.XYChart);
    this.gantt.hiddenState.properties.opacity = 0; // this creates initial fade-in

    this.gantt.paddingRight = 30;
    this.gantt.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm";

    var colorSet = new am4core.ColorSet();
    colorSet.saturation = 0.4;
    var title = this.gantt.titles.create();
    //title.wrap = true;
    //title.maxWidth = 1999;
    const title1 = "P20.0013-008 Ferrovia Roma - Viterbo";
    const title2 = "Ammodernamento e potenziamento della ferrovia ex concessa Roma - Viterbo nella tratta extraurbana Riano - Morlupo da progr. km 0+000 a progr. km 5+989,31 di progetto. Opere Civili lotto 1";
    title.text = `${title1} \n ${title2} `;
    title.fontSize = 18;
    title.marginBottom = 30;
    title.align = "left";

    //sono tutte fasi
    this.gantt.data = data;


    var categoryAxis = this.gantt.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.cellStartLocation = 0.0;
    categoryAxis.renderer.cellEndLocation = 1;
    //categoryAxis.renderer.inside = true;

    
    
    
    //categoryAxis.exportable = false;
    const label = categoryAxis.renderer.labels.template;
    //label.wrap = true;
    label.truncate = true;
    label.maxWidth = 303;
    label.fontSize = 12;
    label.tooltipText = "{name}";


    var dateAxis = this.gantt.xAxes.push(new am4charts.DateAxis());
    dateAxis.dateFormatter.dateFormat = "yyyy-MM-dd";
    dateAxis.renderer.minGridDistance = 70;

    dateAxis.baseInterval = { count: 1, timeUnit: "day" };
    // dateAxis.max = new Date(2018, 0, 1, 24, 0, 0, 0).getTime();
    // dateAxis.strictMinMax = true;
    dateAxis.renderer.tooltipLocation = 0;
    dateAxis.renderer.opposite = true;
    //dateAxis.renderer.grid.template.location = 50;

    var series1 = this.gantt.series.push(new am4charts.ColumnSeries());
    series1.columns.template.tooltipText = "{name}: {openDateX} - {dateX}";
    series1.dataFields.openDateX = "fromDate";
    series1.dataFields.dateX = "toDate";
    series1.dataFields.categoryY = "name";
    series1.columns.template.propertyFields.fill = "color"; // get color from data
    series1.columns.template.propertyFields.stroke = "color";
    series1.columns.template.strokeOpacity = 1;
    series1.columns.template.column.fillOpacity = 1;
    series1.columns.template.height = am4core.percent(60);
    series1.zIndex = 10;

    series1.name = "fasi";
    this.gantt.scrollbarX = new am4core.Scrollbar();
    this.gantt.scrollbarX.exportable = false;
    this.gantt.scrollbarY = new am4core.Scrollbar();
    this.gantt.scrollbarY.exportable = false;




    const values = [];
    const series = [];

    let that = this;
    function createFill(tipologia, category, date, openDate, color, span) {
      var fillAxis = that.gantt.yAxes.push(new am4charts.CategoryAxis());
      fillAxis.dataFields.category = "name";
      fillAxis.renderer.grid.template.disabled = true;
      fillAxis.renderer.labels.template.disabled = true;
      fillAxis.renderer.inversed = true;
      fillAxis.renderer.cellStartLocation = categoryAxis.renderer.cellStartLocation;
      fillAxis.renderer.cellEndLocation = span - fillAxis.renderer.cellStartLocation;

      var fillSeries = that.gantt.series.push(new am4charts.ColumnSeries());
      fillSeries.clustered = false;
      fillSeries.yAxis = fillAxis;
      fillSeries.columns.template.height = am4core.percent(100);
      fillSeries.columns.template.tooltipText = "{name}: {openDateX} - {dateX}";
      fillSeries.dataFields.openDateX = "fromDate";
      fillSeries.dataFields.dateX = "toDate";
      fillSeries.dataFields.categoryY = "name";
      fillSeries.fill = color;
      fillSeries.stroke = color;
      fillSeries.strokeWidth = 0;
      fillSeries.zIndex = 8;
      fillSeries.data = [{
        fromDate: openDate,
        toDate: date,
        name: category
      }]
      fillSeries.name = tipologia;
      fillSeries.columns.template.height = am4core.percent(100);

      if ((tipologia === 'Progettazione' || tipologia === 'Esecuzione (Lavori / Manutenzione Straordinaria)' || tipologia === 'Flussi cassa - Esecuzione') && values.includes(tipologia)) {

        fillSeries.hiddenInLegend = true;
      }
      values.push(tipologia)

      if ((tipologia === 'Progettazione')) series.push(fillSeries)

    }
    this.aggiungiLogo(this.gantt);
    function createFillParent(tipologia, category, date, openDate, color, span) {
      var fillAxis = that.gantt.yAxes.push(new am4charts.CategoryAxis());
      fillAxis.dataFields.category = "name";
      fillAxis.renderer.grid.template.disabled = true;
      fillAxis.renderer.labels.template.disabled = true;
      fillAxis.renderer.inversed = true;
      fillAxis.renderer.cellStartLocation = categoryAxis.renderer.cellStartLocation;
      fillAxis.renderer.cellEndLocation = span - fillAxis.renderer.cellStartLocation;

      var fillSeries = that.gantt.series.push(new am4charts.ColumnSeries());
      fillSeries.clustered = false;
      fillSeries.yAxis = fillAxis;
      fillSeries.columns.template.height = am4core.percent(100);
      fillSeries.columns.template.tooltipText = "{name}: {openDateX} - {dateX}";
      fillSeries.dataFields.openDateX = "fromDate";
      fillSeries.dataFields.dateX = "toDate";
      fillSeries.dataFields.categoryY = "name";
      fillSeries.fill = color;
      fillSeries.stroke = color;
      fillSeries.strokeWidth = 0;
      fillSeries.columns.template.column.fillOpacity = 1;
      fillSeries.data = [{
        fromDate: openDate,
        toDate: date,
        name: category
      }]
      fillSeries.name = tipologia;
      fillSeries.columns.template.height = am4core.percent(100);
      fillSeries.hiddenInLegend = true;
    }

    createFillParent("P20.0013-0008", "Iter autorizzativo definitivo (pubblica utilità, VIA/PAUR, ecc.) Atto di approvazione a valle della C.d.S. decisoria", "2018-05", "2019-01", am4core.color("cyan", 0.1,), 6);
    createFill("Progettazione", "Iter autorizzativo definitivo (pubblica utilità, VIA/PAUR, ecc.) Atto di approvazione a valle della C.d.S. decisoria", "2018-05", "2018-12", am4core.color("red", 0.3), 3);
    createFill("Esecuzione (Lavori / Manutenzione Straordinaria)", "Attività propedeutiche all'esecuzione Attività propedeutiche della S.A. e dell'esecutore (notifiche, polizze, cronoprogrammi, POS, ecc.) Verbale di consegna dei lavori (o delle attività)", "2018-12", "2019-01", am4core.color("blue", 0.3), 1);
    createFill("Flussi cassa - Progettazione", "Esecuzione del contratto - Progettazione Saldo della Progettazione a S.A.", "2018-12", "2019-01 ", am4core.color("magenta", 0.3), 1);
    createFill("Flussi cassa - Esecuzione", "Esecuzione del contratto - Lavori Anticipazione a S.A. del 30%", "2018-12", "2019-01", am4core.color("orange", 0.3), 1);
    //createFill("Flussi cassa - Esecuzione","Esecuzione Esecuzione dei lavori...", "2018-05-01 ", "2018-06-01 ", am4core.color("magenta", 0.3), 2);
    this.gantt.legend = new am4charts.Legend();

    series[0].events.on("hidden", function () {
      // series[1].hide();
      // series[2].hide();
      // series[3].hide();
    });

    series[0].events.on("shown", function () {
      // series[1].show();
      // series[2].show();
      // series[3].show();
    });

     this.gantt.exporting.events.on("exportstarted", function(ev) {
      categoryAxis.renderer.labels.template.disabled = true;
      
     });

     this.gantt.exporting.events.on("exportfinished", function(ev) {
      categoryAxis.renderer.labels.template.disabled = false;
     });

    this.exportChart(this.gantt, title.text);
  }

  
    //modale export
    //dateRangePickerValue:Date[]=  [new Date(),new Date()];

    @ViewChild('modalExportData', { static: true })
    public modalExportData: TemplateRef<any>;
    modalEsportazioneRef: BsModalRef;
      esportaExcel(){
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xs"
        };        
        this.modalEsportazioneRef = this.modalService.show(this.modalExportData, config);
      }
      closeModalEsportazione(){
          this.modalEsportazioneRef.hide();
      }
}




