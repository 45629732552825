<div class="container-fluid px-0 bg-celeste" [formGroup]="myForm">
  <div
    class="text-center p-5 position-absolute top-50 start-50 translate-middle bg-white border border-dark rounded shadow"
    style="z-index: 999999999" *ngIf="showLoading>0"

  >
    <app-loading message="Caricamento in corso..."></app-loading>
  </div>
  <app-header-dashboard></app-header-dashboard>
</div>
<div class="container-fluid px-0 px-sm-3">
  <div class="row my-3" >
    <!-- left start-->
    <div class="col-xl-2 col-lg-4 width collapse collapseWidthExample" #menuFiltri>
      <h3 class="h5 border-bottom">Filtri:</h3>
      <div [formGroup]="myForm" class="mb-3">
        <!-- Filtro Provincia -->
        <div class="form-group mb-1">
          <label title="Provincia">Provincia </label>
          <ng-select
            [items]="provinciaItems"
            formControlName="province"
            bindValue="key"
            bindLabel="value"
            placeholder="Seleziona provincia"
            (change)="onChangeFilter($event, 'cod_prov', 'province', 'Provincia')"
          >
            <ng-template ng-option-tmp let-item="item">
              <div class="small" title="{{ item.value }}">{{ item.value }}</div>
            </ng-template>
          </ng-select>
        </div>
        <!-- Filtro Comune -->
        <div class="form-group mb-1">
          <label title="Comune">Comune </label>
          <ng-select
            [items]="comuneItems"
            formControlName="comuni"
            bindValue="key"
            bindLabel="value"
            placeholder="Seleziona comune"
            (change)="onChangeFilter($event, 'pro_com_t', 'comuni', 'Comune')"
          >
            <ng-template ng-option-tmp let-item="item">
              <div class="small" title="{{ item.value }}">{{ item.value }}</div>
            </ng-template>
          </ng-select>
        </div>
        <hr />
        <!-- Filtro Natura -->
        <div class="form-group mb-1">
          <label title="Natura">Natura </label>
          <ng-select
            [items]="naturaItems"
            formControlName="nature"
            bindValue="key"
            bindLabel="value"
            placeholder="Seleziona natura"
            (change)="onChangeFilter($event, 'nature', 'nature', 'Natura')"
          >
            <ng-template ng-option-tmp let-item="item">
              <div class="small" title="{{ item.value }}">{{ item.value }}</div>
            </ng-template>
          </ng-select>
        </div>
        <!-- Filtro Tipologia -->
        <div class="form-group mb-1">
          <label title="Tipologia">Tipologia </label>
          <ng-select
            [items]="tipologiaItems"
            formControlName="tipologie"
            bindValue="key"
            bindLabel="value"
            placeholder="Seleziona tipologia"
            (change)="onChangeFilter($event, 'tipologie', 'tipologie', 'Tipologia')"
          >
            <ng-template ng-option-tmp let-item="item">
              <div class="small" title="{{ item.value }}">{{ item.value }}</div>
            </ng-template>
          </ng-select>
        </div>
        <!-- Filtro Dettaglio -->
        <div class="form-group mb-1" [hidden]="true">
          <label title="Dettaglio">Dettaglio </label>
          <ng-select
            [items]="dettaglioItems"
            formControlName="dettagli"
            bindValue="key"
            bindLabel="value"
            placeholder="Seleziona dettaglio"
            (change)="onChangeFilter($event, 'dettagli', 'dettagli', 'Dettaglio')"
          >
            <ng-template ng-option-tmp let-item="item">
              <div class="small" title="{{ item.value }}">{{ item.value }}</div>
            </ng-template>
          </ng-select>
        </div>

        <hr />

        <!-- Filtro Missioni -->
        <div class="form-group mb-1">
          <label title="Missioni">Missioni </label>
          <ng-select
            [items]="finanziamentoMissionsPnrrComboItems"
            formControlName="pnrrMissione"
            bindValue="key"
            bindLabel="value"
            placeholder="Seleziona missione"
            (change)="onChangeFilter($event, 'pnrrMissione', 'pnrrMissione', 'Missione')"
          >
            <ng-template ng-option-tmp let-item="item">
              <div class="small" title="{{ item.value }}">
                {{ item.value }}
              </div>
            </ng-template>
          </ng-select>
        </div>
        <!-- Filtro Componenti -->
        <div class="form-group mb-1">
          <label title="Componenti">Componenti </label>
          <ng-select
            [items]="finanziamentoComponentsPnrrComboItems"
            formControlName="pnrrComponente"
            bindValue="key"
            bindLabel="value"
            placeholder="Seleziona componente"
            (change)="onChangeFilter($event, 'pnrrComponente', 'pnrrComponente', 'Componente')"
          >
            <ng-template ng-option-tmp let-item="item">
              <div class="small" title="{{ item.value }}">
                {{ item.value }}
              </div>
            </ng-template>
          </ng-select>
        </div>
        <!-- Filtro Linee -->
        <div class="form-group mb-1">
          <label title="Linee">Linee </label>
          <ng-select
            [items]="finanziamentoLinesPnrrComboItems"
            formControlName="pnrrLinea"
            bindValue="key"
            bindLabel="value"
            placeholder="Seleziona linea"
            (change)="onChangeFilter($event, 'pnrrLinea', 'pnrrLinea', 'Linea')"
          >
            <ng-template ng-option-tmp let-item="item">
              <div class="small" title="{{ item.value }}">
                {{ item.value }}
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <!-- card -->
      <div class="bg-white border rounded p-2 mb-3 mb-xl-0">
        <div class="d-flex justify-content-between">
        <button type="button" class="btn btn-success btn-sm w-50" (click)="onSubmit()">
          Cerca <i class="fas fa-search" aria-hidden="true"></i>
        </button>
        <button type="button" class="btn btn-outline-secondary btn-sm px-lg-2  px-xl-1" (click)="resetFilters()">
          Resetta <i class="fas fa-undo" aria-hidden="true"></i>
        </button>
      </div>

        <h2 class="display-5 mb-0 mt-2 h5">
            <ng-template #popoverNotaLocalizzazioni>
                <span>Nota Bene: Il numero totale delle progettualità comprende sia i progetti con interventi sia i progetti senza interventi sottostanti, quindi progetti che devono ancora essere sviluppati in interventi</span>
              </ng-template>
            <!--<a
                      type="button"
                      class="help"
                      [popover]="popoverNotaLocalizzazioni"
                      placement="bottom"
                      triggers="mouseenter:mouseleave"
                    >
                      <i
                        class="far fa-question-circle text-0083e0 help"
                        style="font-size: 14px"
                      >
                      </i>
                    </a>-->
          Risultato: <span class="text-0083e0">{{this.getTotal()}}</span>&nbsp;<span class="h6">interventi</span>
          <br/>
          <span class="h6">di cui</span>&nbsp;
          <span class="text-0083e0 h5">{{this.getNonLocalizzati()}}</span>&nbsp;
          <span class="h6">non localizzati</span>
          <div *ngIf="cntLocalizzatiAltrove>0">
            <span class="h6" *ngIf="(filtersObject?.provinceCode && filtersObject?.provinceCode!='') && (!filtersObject?.municipalityCode || filtersObject?.municipalityCode=='') ">localizzati in altra provincia:</span>
            <span class="h6" *ngIf="filtersObject?.municipalityCode && filtersObject?.municipalityCode!=''">localizzati in altro comune:</span>
            <span class="text-0083e0 h5">{{this.cntLocalizzatiAltrove}}</span>
            <ng-template #popoverNotaLocalizzatiAltrove>
                <ul>
                    <li *ngFor="let v of localizzatiAltrove">{{v}}</li>
                </ul>
              </ng-template>
            <a
                      type="button"
                      class="help"
                      [popover]="popoverNotaLocalizzatiAltrove"
                      placement="bottom"
                      triggers="mouseenter:mouseleave"
                    >
                      <i
                        class="far fa-question-circle text-0083e0 help"
                        style="font-size: 14px"
                      >
                      </i>
                    </a>
          </div>
        </h2>

      </div>
    </div>
    <!-- left close-->

    <!-- center start -->
    <div class="col " >
      <app-mappa-non-disegnabile
        #mapPnrr
        (changeModeEvent)="setEnabledFields($event)"
        (risultatoTotale)="setResultNumber($event)"
        (interventiNonLocalizzati)="setInterventiNonLocalizzati($event)"
        (chartsFeatures)="setChartsFeatures($event)"
        (resetFiltersOnLayerChange)="resetFilters()"
        [isMenuFiltroShown]="isMenuFiltroShown"
        [filtersSubmitted]="filteredConditions"
        [filtersActive]="filtersActive"
        [cntLocalizzatiAltrove]="cntLocalizzatiAltrove"
        [localizzatiAltrove]="localizzatiAltrove"
      ></app-mappa-non-disegnabile>
    </div>
    <!-- center close -->
    <div class="col-xl-5 col-lg-12 d-flex align-items-start flex-column" style="z-index: 2;">
      <div class="bg-white border rounded mb-xl-auto my-4 mt-xl-0 w-100">
        <app-chart-assi-impilati-orizzontalmente
          [chartData]="_pnrrChartData"
        >
        </app-chart-assi-impilati-orizzontalmente>
      </div>
      <div class="bg-white border rounded w-100">
        <app-chart-pnrr-linee
        [chartData]="_pnrrChartData"

        >
      </app-chart-pnrr-linee>
      </div>
    </div>
  </div>


  <div class="row" >

    <!-- grafico pnrr province -->
    <div class="col-12 mb-3 " id="step1" >


      <div class="tab-content bg-white border rounded" id="pills-tabContent-province">
        <!-- Switch Grafico 'Interventi - Risorse' Province -->
        <ul class="nav nav-pills m-3" id="pills-tab-province" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="pills-interventi-tab" data-toggle="pill" href="#pills-interventi" role="tab" aria-controls="pills-interventi" aria-selected="true">Interventi</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="pills-risorse-tab" data-toggle="pill" href="#pills-risorse" role="tab" aria-controls="pills-risorse" aria-selected="false">Risorse</a>
          </li>
        </ul>


        <div class="tab-pane fade show active" id="pills-interventi" role="tabpanel" aria-labelledby="pills-interventi-tab" #chartStep1>
          <app-chart-pnrr-interventi-provincie
            [filterManager]="filtersActive"
            [provinces]="provinciaFiltered"
          >
          </app-chart-pnrr-interventi-provincie>
        </div>
        <!-- Grafico 6 -->
        <div class="tab-pane fade " id="pills-risorse" role="tabpanel" aria-labelledby="pills-risorse-tab">
          <app-chart-pnrr-risorse-provincie
            [filterManager]="filtersActive"
            [provinces]="provinciaFiltered"
          >
          </app-chart-pnrr-risorse-provincie>
        </div>

      </div>



    </div>

  </div>

  <div class="row" >
<!-- grafico pnrr comuni -->
<div class="col-12 mb-3" id="step2" *ngIf="muniChartsEnabler" >


  <div class="tab-content bg-white border rounded" id="pills-tabContent-comuni">
    <!-- Switch Grafico 'Interventi' Comuni -->
    <ul class="nav nav-pills m-3" id="pills-tab-comuni" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="pills-interventi-tab" data-toggle="pill" href="#pills-interventi-comuni" role="tab" aria-controls="pills-interventi" aria-selected="true">Interventi</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="pills-risorse-tab" data-toggle="pill" href="#pills-risorse-comuni" role="tab" aria-controls="pills-risorse" aria-selected="false">Risorse</a>
      </li>
    </ul>


    <div class="tab-pane fade show active" id="pills-interventi-comuni" role="tabpanel" aria-labelledby="pills-interventi-tab">
      <app-chart-pnrr-interventi-comuni
        [filterManager]="filtersActive"
        [municipalities]="comuneFiltered"
      >
      </app-chart-pnrr-interventi-comuni>
    </div>
    <!-- Switch Grafico 'Risorse' Comuni -->
    <div class="tab-pane fade " id="pills-risorse-comuni" role="tabpanel" aria-labelledby="pills-risorse-tab">
      <app-chart-pnrr-risorse-comuni
        [filterManager]="filtersActive"
        [municipalities]="comuneFiltered"
      >
      </app-chart-pnrr-risorse-comuni>
    </div>

  </div>



</div>
</div>


<div class="row" >
  <div class="col-12 mb-3" id="step3"  >

  <div class="tab-content bg-white border rounded" id="pills-tabContent-natura">
    <!-- Switch Grafico Natura -->
    <ul class="nav nav-pills m-3" id="pills-tab-natura" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="pills-interventi-natura-tab" data-toggle="pill" href="#pills-interventi-natura" role="tab" aria-controls="pills-interventi-natura" aria-selected="true" (click)="reloadtipologieInterventi()">Interventi</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="pills-risorse-natura-tab" data-toggle="pill" href="#pills-risorse-natura" role="tab" aria-controls="pills-risorse-natura" aria-selected="false" (click)="reloadtipologieRisorse()">Risorse</a>
      </li>
    </ul>
      <!-- grafico interventi natura - tipologia-->


      <div class="tab-pane fade show active" #pillsInterventiNatura id="pills-interventi-natura" role="tabpanel" aria-labelledby="pills-interventi-natura-tab">

        <app-chart-pnrr-natura-tipologie #chartTipologieInterventi
        [filterManager]="filtersActive"
        >
        </app-chart-pnrr-natura-tipologie>

    </div>
      <!-- grafico risorse natura -tipologia-->
      <div class="tab-pane fade " #pillsRisorseNatura id="pills-risorse-natura" role="tabpanel" aria-labelledby="pills-risorse-natura-tab">

        <app-chart-pnrr-natura-importi #chartTipologieRisorse
        [filterManager]="filtersActive"
        >
        </app-chart-pnrr-natura-importi>

    </div>

  </div>
</div>
</div>
       <div class="bottom-0 position-absolute text-center start-50 translate-middle-x d-none d-lg-block">
            <button (click)="toGoNext()" class="btn btn-light fa-2xl px-4 py-0 mb-3 bg-celeste rounded-0 rounded-top border border-bottom-0"
         style="z-index:9000; border-top-right-radius: 0.25rem!important;
          border-top-left-radius: 0.25rem!important;" aria-label="Vai alla sezione successiva" title="Vai alla sezione successiva">
          <i class="fas fa-angle-double-down" aria-hidden="true"></i>
            </button>
        </div>
</div>



