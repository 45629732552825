import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { Observable } from "rxjs";

class DataTablesResponse {
  data: [];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: "root",
})
export class PnrrService {
  private apiUrlMissione = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}PnrrMissione/`;
  private apiUrlComponente = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}PnrrComponente/`;
  private apiUrlLineaFinanziamento = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}PnrrLineaFinanziamento/`;
  private publicApiUrlLineaFinanziamento = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob_public}PnrrLineaFinanziamento/`;
  private apiUrlInterventiSicerContabilita = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.apiUrlInterventiSicerContabilita}`;
  private apiPnrrTargetMilestone = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.apiPnrrTargetMilestone}`;

  constructor(private http: HttpClient) {}

  getAllInterventiSicerContabilita(filters) {
    return this.http.get<[]>(`${this.apiUrlInterventiSicerContabilita}`, {
      params: { filterObject: JSON.stringify(filters)},
    });
  }

  esportaInExcel(filters) {
    if (!filters.soggAttuatore) filters.soggAttuatore = "";
    if (!filters.pnrrMissioni) filters.pnrrMissioni = [];
    if (!filters.pnrrComponenti) filters.pnrrComponenti = [];
    if (!filters.pnrrLinee) filters.pnrrLinee = [];
    if (!filters.progetto) filters.progetto = "";
    if (!filters.intervento) filters.intervento = "";
    if (!filters.anno) filters.anno = "";

    return this.http.get(`${this.apiUrlInterventiSicerContabilita}/esporta`, {
      observe: "response",
      responseType: "blob",
      params: { filterObject: JSON.stringify(filters) },
    });
  }

  getAllPnrrTargetMilestone() {
    return this.http.get<[]>(`${this.apiPnrrTargetMilestone}`);
  }

  getAllMissioneDt(dataTablesParameters: any) {
    return this.http.post<DataTablesResponse>(
      `${this.apiUrlMissione}alldt`,
      dataTablesParameters
    );
  }
  getAllComponenteDt(dataTablesParameters: any) {
    return this.http.post<DataTablesResponse>(
      `${this.apiUrlComponente}alldt`,
      dataTablesParameters
    );
  }

  getAllLineaFinanziamentoDt(
    dataTablesParameters: any,
    onlyWithProvvedimenti = false,
    onlyChildren = false
  ) {
    return this.http.post<DataTablesResponse>(
      `${this.apiUrlLineaFinanziamento}alldt?onlyWithProvvedimenti=${onlyWithProvvedimenti}&onlyChildren=${onlyChildren}`,
      dataTablesParameters
    );
  }

  getAllMissione() {
    return this.http.get<[]>(`${this.apiUrlMissione}`);
  }

  getAllComponente() {
    return this.http.get<[]>(`${this.apiUrlComponente}`);
  }

  getAllLineaFinanziamento(): Observable<any[]> {
    return this.http.get<[]>(`${this.publicApiUrlLineaFinanziamento}`);
  }

  saveMissione(s: any) {
    if (s.id) return this.updateMissione(s);
    return this.insertMissione(s);
  }

  saveComponente(s: any) {
    if (s.id) return this.updateComponente(s);
    return this.insertComponente(s);
  }

  saveLineaFinanziamento(s: any) {
    if (s.id) return this.updateLineaFinanziamento(s);
    return this.insertLineaFinanziamento(s);
  }
  insertMissione(s: any) {
    return this.http.post<any>(`${this.apiUrlMissione}`, s);
  }
  insertComponente(s: any) {
    return this.http.post<any>(`${this.apiUrlComponente}`, s);
  }
  insertLineaFinanziamento(s: any) {
    return this.http.post<any>(`${this.apiUrlLineaFinanziamento}`, s);
  }
  updateMissione(s: any) {
    return this.http.put<any>(`${this.apiUrlMissione}${s.id}`, s);
  }

  updateComponente(s: any) {
    return this.http.put<any>(`${this.apiUrlComponente}${s.id}`, s);
  }
  updateLineaFinanziamento(s: any) {
    return this.http.put<any>(`${this.apiUrlLineaFinanziamento}${s.id}`, s);
  }
  deleteMissione(id: number) {
    return this.http.delete<any>(`${this.apiUrlMissione}${id}`);
  }

  deleteComponente(id: number) {
    return this.http.delete<any>(`${this.apiUrlComponente}${id}`);
  }

  deleteLineaFinanziamento(id: number) {
    return this.http.delete<any>(`${this.apiUrlLineaFinanziamento}${id}`);
  }

  getFasi(idLineaFin: any) {
    return this.http.get<any[]>(
      `${this.apiUrlLineaFinanziamento}${idLineaFin}/fasi`
    );
  }
  saveFase(progettoId: number, fase: any) {
    if (fase.id && fase.id > 0) return this.updateFase(progettoId, fase);
    return this.insertFase(progettoId, fase);
  }
  insertFase(progettoId: number, fase: any) {
    return this.http.post<any>(
      `${this.apiUrlLineaFinanziamento}${progettoId}/Fasi`,
      fase
    );
  }
  updateFase(progettoId: number, fase: any) {
    return this.http.put<any>(
      `${this.apiUrlLineaFinanziamento}${progettoId}/Fasi/${fase.id}`,
      fase
    );
  }
  deleteFase(progettoId: number, faseId: number) {
    return this.http.delete<boolean>(
      `${this.apiUrlLineaFinanziamento}${progettoId}/Fasi/${faseId}`
    );
  }

  saveLineaFinanziamentoExtraDati(s: any) {
    return this.http.put<any>(
      `${this.apiUrlLineaFinanziamento}${s.id}/extraDati`,
      s
    );
  }

  esportaPnrrLineeFinanziamentoExcel() {
    return this.http.get(`${this.apiUrlLineaFinanziamento}excel`, {
      observe: "response",
      responseType: "blob",
    });
  }

  esportaPnrrLineeFinanziamentoVerificaScostamentiExcel() {
    return this.http.get(
      `${this.apiUrlLineaFinanziamento}excelVerificaScostamenti`,
      {
        observe: "response",
        responseType: "blob",
      }
    );
  }

  esportaPnrrLineeFinanziamentoRr05() {
    return this.http.get(`${this.apiUrlLineaFinanziamento}exportRr05`, {
      observe: "response",
      responseType: "blob",
    });
  }

  esportaPnrrLineeFinanziamentoRr05Excel() {
    return this.http.get(`${this.apiUrlLineaFinanziamento}exportRr05Excel`, {
      observe: "response",
      responseType: "blob",
    });
  }

  esportaPnrrLineeFinanziamentoRr07(escludiLineeSenzaRipartizioni: any) {
    if (!escludiLineeSenzaRipartizioni) escludiLineeSenzaRipartizioni = false;
    return this.http.get(
      `${this.apiUrlLineaFinanziamento}exportRr07?escludiLineeSenzaRipartizioni=${escludiLineeSenzaRipartizioni}`,
      {
        observe: "response",
        responseType: "blob",
      }
    );
  }

  getStoricoDt(finId: number, dataTablesParameters: any) {
    return this.http.post<any>(
      `${this.apiUrlLineaFinanziamento}${finId}/history/alldt`,
      dataTablesParameters
    );
  }

  getDettaglioStorico(storicoId, finId) {
    return this.http.get<any>(
      `${this.apiUrlLineaFinanziamento}${finId}/history/${storicoId}`
    );
  }
}
