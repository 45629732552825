import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    TemplateRef
} from "@angular/core";
import { Subject } from "rxjs";
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AreaTematica } from '../../models';
import { AreeTematicheService } from '../../services';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Menu } from "src/app/models/menu";
import { MenuService } from "src/app/services/menu.service";


@Component({
    selector: 'aree-tematiche-component',
    templateUrl: './aree-tematiche.component.html',
    styleUrls: ['./aree-tematiche.component.css']
})
export class AreeTematicheComponent
    implements OnInit {
    dtOptions: any = {};
    visible = false;
    submitted: boolean = false;
    editForm: FormGroup;
    modalRef: BsModalRef;
    open: boolean = false;
    loading: boolean = false;

    obbiettivi = [];
    menu = []

    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };
    buttonLabel: string = "Inserisci";

    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;
    @ViewChild('templateModal', { static: true })
    public templateModal: TemplateRef<any>;

    //@ViewChild('buttonsTemplate',{static:false}) buttonsTemplate: TemplateRef<any>;
    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    @ViewChild('btnEnableTemplate', { static: true })
    public btnEnableTemplate: TemplateRef<any>;

    @ViewChild('menuFieldTemplate', { static: true })
    public menuFieldTemplate: TemplateRef<any>;
    

    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    templateList = [];

    columns = [
        {
            name: 'id'
            , prop: 'id'
            , visible: false
            , sortable: true
            , filtrable: true
            //, width: 10

        }
        , {
            name: 'descrizione'
            , prop: 'descrizione'
            , sortable: true
            , filtrable: true
            , minWidth: 200
            , cellClass: 'mobile-hidden desktop-hidden'
            , headerClass: 'mobile-hidden desktop-hidden'
            , flexGrow: 6
        }
        , {
            name: 'codice ipa'
            , prop: 'codiceIpa'
            , sortable: true
            , filtrable: true
            , minWidth: 90
            //,cellClass: 'mobile-hidden desktop-hidden'
            //,headerClass: 'mobile-hidden desktop-hidden'
            , flexGrow: 2
        }
        , {
            name: 'struttura'
            , prop: 'struttura'
            , sortable: true
            , filtrable: true
            , minWidth: 90
            , flexGrow: 3
        }
        , {
            name: 'nominativo&nbsp;'
            , prop: 'nominativo'
            , sortable: true
            , filtrable: true

            , flexGrow: 3
            , minWidth: 70

        },
        {
            name: 'menu'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'menuFieldTemplate'// 'buttonsTemplate'
            , flexGrow: 3
            , minWidth: 70
            


        }
        , {
            name: 'stato'
            , prop: 'disabled'
            , sortable: true
            , filtrable: false
            , cellTemplate: 'btnEnableTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60
            


        }
        , {
            name: 'modifica'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60
            


        }
        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60
            


        }
    ];


    constructor(
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private el: ElementRef,
        private localeService: BsLocaleService,
        private toastr: ToastService,
        private modalService: BsModalService,
        private areeTematicheService: AreeTematicheService,
        private menuService: MenuService
    ) {
        this.localeService.use("it");
        this.dtOptions = {
            rows: []
            , totalElements: 0
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: false// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: false// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: false

            , scrollbarH:"true"
        };
    }

    //ng-select menu
    menus: Menu[];

    ngOnInit() {
        window.scroll(0, 0);
        this.templateList = [
            { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }
            , { name: 'btnEditTemplate', template: this.btnEditTemplate }
            , { name: 'btnEnableTemplate', template: this.btnEnableTemplate },
            {name: 'menuFieldTemplate', template: this.menuFieldTemplate }
        ];
        this.getAll();
        this.editForm = this.initializeFormGroup();
        this.obbiettivoForm = this.createObbiettivoFormGroup();

        this.menuService.getAllMenu().subscribe((menu) => {
            this.menus = menu;
        });
    };





    initializeFormGroup() {
        return this.formBuilder.group({
            id: [null],
            descrizione: [null, [Validators.required, Validators.maxLength(255)]],
            codiceIpa: [null, [Validators.required, Validators.maxLength(50)]],
            struttura: [null, [Validators.required, Validators.maxLength(50)]],
            nominativo: [null, [Validators.required, Validators.maxLength(150)]],
            menu: [null],
            descrizioneEstesa: [null],
            areaTematicaDisabledSwitch: [null],
            gestisciPnrrSwitch:[null,[Validators.required]],
            gestisciNonPnrrSwitch:[null,[Validators.required]],
            visualizzaSoloEntitaDirezioneSwitch:[null,[Validators.required]]
        });
    }

    get f() {
        return this.editForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        this.editForm.updateValueAndValidity();
        let errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );
        }
        if (this.editForm.invalid || errors) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.editForm.controls) {
                if (this.editForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.editForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.editForm.get(el)["controls"]) {
                            if (this.editForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }

        const menuIdSelected = this.editForm.value.menu;

        const selectedMenu = menuIdSelected ? [this.menus.find(x => x.id === menuIdSelected)] : [];


    
        let obj = this.editForm.value as AreaTematica;
        obj.obbiettivi = this.obbiettivi;
        obj.disabled = this.editForm.controls.areaTematicaDisabledSwitch.value=="0";
        obj.gestisciEntitaPnrr = this.editForm.controls.gestisciPnrrSwitch.value=="1";
        obj.gestisciEntitaNonPnrr = this.editForm.controls.gestisciNonPnrrSwitch.value=="1";
        obj.visualizzaSoloEntitaDirezione = this.editForm.controls.visualizzaSoloEntitaDirezioneSwitch.value=="1";
        obj.menu = selectedMenu;


        if (!obj.gestisciEntitaPnrr && !obj.gestisciEntitaNonPnrr){
            this.toastr.error("Attenzione: selezionare almeno una tra le voci gestisci PNRR e gestisci non PNRR");
            return;
        }

        this.areeTematicheService.save(obj).subscribe(res => {
            this.toastr.success("Area direzione aggiunta con successo!");

            this.submitted = false;
            this.open = false;
            this.editForm.reset();
            this.obbiettivi = [];

            this.buttonLabel = "Inserisci";
            if (!obj.id) {
                //new row
                this.ngxDatatableWrapper.addRow(res);
            }
            else {
                this.closeModal();
                this.ngxDatatableWrapper.updateRow(obj);
            }


        }, error => {
            this.toastr.error("Errore di sistema");
        });
    }

    onEdit(row) {

        this.submitted = false;
        console.log(row);
        this.buttonLabel = "Aggiorna";
        this.modalRef = this.modalService.show(this.templateModal, this.config);

        this.editForm.setValue({
            id: row.id,
            descrizione: row.descrizione,
            codiceIpa: row.codiceIpa,
            struttura: row.struttura,
            nominativo: row.nominativo,
            menu: row.menu && row.menu.length > 0 ? row.menu[0].id : null,            
            descrizioneEstesa: row.descrizioneEstesa
            ,areaTematicaDisabledSwitch: !row.disabled ?"1":"0"
            ,gestisciPnrrSwitch:row.gestisciEntitaPnrr?"1":"0"
            ,gestisciNonPnrrSwitch:row.gestisciEntitaNonPnrr?"1":"0"
            ,visualizzaSoloEntitaDirezioneSwitch:row.visualizzaSoloEntitaDirezione?"1":"0"
        });
        this.obbiettivi = row.obbiettivi;
        
    }
    onDelete(row) {
        console.log(row);
        if (!confirm(`Stai per eliminare l'area direzione "${row.descrizione}", proseguire?`)) return;
        this.loading = true;
        this.areeTematicheService.delete(row.id).subscribe(
            result => {
                this.loading = false;
                this.ngxDatatableWrapper.deleteRow(row);
                this.toastr.success('Area direzione eliminata con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
            }, error => {
                this.loading = false;
                this.toastr.error(`Impossibile eliminare l'area direzione: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                console.log('error');
            });
    }
    closeModal() {
        this.modalRef.hide();
        this.buttonLabel = "Inserisci";
        this.editForm.reset()
    }



    getAll() {
        this.areeTematicheService.getAll().subscribe(
            result => {
                this.ngxDatatableWrapper.setInitialData(result);
                
            }
        );
    }


    //obbiettivi
    public maskYear: any = {
        mask: 'KK',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                /*thousandsSeparator: '.',*/
                scale: 0,
                padFractionalZeros: false,
                radix: ','
                , min: 1950
            }
        }
    };

    obbiettivoForm: FormGroup;
    submittedObbiettivo: boolean = false;
    modalRefObbiettivo: BsModalRef;
    minYear = 1950;
    @ViewChild('templateModalObbiettivo', { static: true })
    public templateModalObbiettivo: TemplateRef<any>;

    createObbiettivoFormGroup() {
        return this.formBuilder.group({
            id: [null],
            descrizione: [null, [Validators.required]],
            anno: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4), this.checkAnnoRiferimentoRangeValidator(this.minYear, null)]],
        });
    }


    checkAnnoRiferimentoRangeValidator(minYear: number, maxYear: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            console.log('inside checkAnnoRiferimentoRangeValidator ');
            if (!control || !control.parent) return null;
            let value = parseInt(control.value);
            if (isNaN(value) || value < minYear /*|| value > maxYear*/) return { range: true };
            return null;
        }
    }

    removeObbiettivo(item: any) {
        this.obbiettivi = this.obbiettivi.filter(x => x.anno != item.anno).sort((a, b) => a.anno - b.anno);
    }

    closeModalObbiettivo() {
        this.modalRefObbiettivo.hide();
    }
    onSubmitObbiettivo() {
        this.submittedObbiettivo = true;
        let myForm = this.obbiettivoForm;
        myForm.updateValueAndValidity();
        let errors = false;
        for (let c in myForm.controls) {
            errors = errors || myForm.controls[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(myForm.controls[c].errors)
            );
        }
        if (myForm.invalid || errors) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in myForm.controls) {
                if (myForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (myForm.controls[el] instanceof FormGroup) {
                        for (let el1 in myForm.get(el)["controls"]) {
                            if (myForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }
        let obj = myForm.value;
        if (!this.obbiettivi) this.obbiettivi = [];
        if (this.obbiettivi.find(x => x.anno == obj.anno)) {
            this.toastr.error(`Esiste già un obbiettivo per l'anno selezionato`, null, {
                timeOut: 2000, disableTimeOut: false
            });
            return;
        }
        this.obbiettivi = [...this.obbiettivi, obj].sort((a, b) => a.anno - b.anno);
        this.closeModalObbiettivo();
        this.submittedObbiettivo = false;
        this.toastr.success(`Obbiettivo aggiunto con successo`, null, {
            timeOut: 2000, disableTimeOut: false
        });
    }
    addObbiettivo() {
        this.obbiettivoForm.reset();
        this.modalRefObbiettivo = this.modalService.show(this.templateModalObbiettivo, this.config);
    }

    areaTematicaDisabledSwitch_change($event){

    }

}

