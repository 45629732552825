import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { CategoriaSisma } from "../models/categoria-sisma";

@Injectable({
  providedIn: "root"
})
export class CategoriaSismaService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}CategorieSisma/`;
  constructor(private http: HttpClient) { }

  getAllDt(dataTablesParameters: any) {
      return this.http.post<any>(
          `${this.apiUrl}alldt`,
          dataTablesParameters
      );
  }
  getAll() {
      return this.http.get<CategoriaSisma[]>(
          `${this.apiUrl}`
      );
  }

  insert(s: CategoriaSisma) {
      return this.http.post<any>(`${this.apiUrl}`, s);
  }
  update(s: CategoriaSisma) {
      return this.http.put<any>(`${this.apiUrl}${s.id}`, s);
  }
  delete(id: number) {
      return this.http.delete<any>(`${this.apiUrl}${id}`);
  }
  save(s:CategoriaSisma){
      if (!s.id)return this.insert(s);
      return this.update(s);
  }

}
