<div class="col-lg-12">
  <div class=" fixedHeaderTable col-lg-12  table-responsive table-wrapper-scroll-y scrollbar-y my-5 px-0"
    style="max-height: 600px;">
    <table class="table table-hover table-colore tableFixHead">
      <thead class="">
        <th *ngIf="showSelectColumn"  class="bg-white"  style="max-width:20px;">&nbsp;</th>
        <th class="fixedmeY bg-white" style="min-width: 290px;">{{isGr34() || isAdmin() ?'Progettualità/Fabbisogno - Intervento - Finanziamento':'Progettualità - Intervento - Finanziamento'}}
        </th>
        <!--<th>Descrizione</th>            -->
        <th class="bg-white" style="max-width:290px">Tipologia Fase</th>
        <th class="bg-white" style="max-width:290px">Stato&nbsp;Fase</th>
        <th class="bg-white">Data prevista&nbsp;inizio</th>
        <th class="bg-white" style="z-index: 90000;">Data inizio</th>
        <th class="bg-white">Scostamento (gg)
          <a type="button" class="help"
            popover="Lo scostamento viene calcolato dalla data inizio, se presente, o dal giorno corrente"
            placement="bottom" triggers="mouseenter:mouseleave">
            <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
            </i>
          </a>
        </th>
        <th class="bg-white">Data prevista&nbsp;fine</th>
        <th class="bg-white">Data fine</th>
        <th class="bg-white">
          Scostamento (gg)
          <a type="button" class="help"
            popover="Lo scostamento viene calcolato dalla data fine, se presente, o dal giorno corrente"
            placement="bottom" triggers="mouseenter:mouseleave">
            <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
            </i>
          </a>
        </th>
        <th class="bg-white" style="min-width: 290px">Soggetti Responsabili</th>
      </thead>
      <tbody>

        <!--finanziamenti-->
        <ng-container *ngIf="currentObject && currentObject.finanziamenti as finanziamenti">
          <ng-container *ngFor="let finanziamento of finanziamenti;let i=index;">
            <ng-container [ngSwitch]="finanziamento.fasi && finanziamento.fasi.length>0 && 'hasFasi'">

              <ng-container *ngSwitchCase="'hasFasi'">
                <ng-container *ngFor="let fase of finanziamento.fasi;let j=index;">
                  <tr class="background-table-green">
                    <td *ngIf="showSelectColumn" style="max-width:20px;"><input type="checkbox" (click)="addRemoveSelection(fase.id)"></td>
                    <td class="fixedme" style="max-width: 290px;">
                      <!--<a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')" class="pointer font-weight-bold text-0083e0"
                                            data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.tr_collapse_' + i" [attr.data-target]="'.tr_collapse_' + i">-->
                      <span
                        class="font-weight-bold "><i></i>&nbsp;{{j==0 ? ( 'finanziamento ' + finanziamento.codice ) :'' }}</span>
                      <!-- </a>-->
                      <div class="" style="font-size: 13px; font-weight: normal;">
                        {{j==0? finanziamento.titolo:''}} </div>
                    </td>
                    <td>
                        <span popover="{{fase.tipologiaFase.fase + ' ' + fase.tipologiaFase.titolo + ' ' + fase.tipologiaFase.descrizione}}" placement="auto" triggers="mouseenter:mouseleave">{{trimText(fase.tipologiaFase.fase + ' ' + fase.tipologiaFase.titolo + ' ' + fase.tipologiaFase.descrizione,100)}}</span>
                    </td>
                    <td class="text-center">
                      {{printStatoFase(fase)}}
                      <div class="help p-2 rounded shadow-sm justify-content-center {{getColorStatoFase(fase)}} "
                        title="gravità: {{getColorStatoFase1(fase)}}"></div>
                    </td>
                    <td>
                      {{printDate(fase.dataPrevistaInizio)}}

                    </td>
                    <td>
                      {{printDate(fase.dataInizio)}}

                    </td>
                    <td>
                      {{printScostamentoRitardo(fase)}}
                      <a type="button" class="help"
                        popover="Scostamento calcolato dal {{printValueAsDateWithCurrentDateAsDefault(fase.dataInizio)}}"
                        placement="bottom" triggers="mouseenter:mouseleave">
                        <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                        </i>
                      </a>

                    </td>
                    <td>
                      {{printDate(fase.dataPrevistaFine)}}

                    </td>
                    <td>
                      {{printDate(fase.dataFine)}}

                    </td>
                    <td>
                      {{printScostamentoScaduto(fase)}}
                      <a type="button" class="help"
                        popover="Scostamento calcolato dal {{printValueAsDateWithCurrentDateAsDefault(fase.dataFine)}}"
                        placement="bottom" triggers="mouseenter:mouseleave">
                        <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                        </i>
                      </a>
                    </td>
                    <td>
                      <ul>
                        <li *ngFor="let val of getSoggettoResponsabileText(fase)">{{val}}</li>
                      </ul>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <tr class="background-table-green">
                  <td class="fixedme" style="max-width: 290px;">
                    <!--<a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')" class="pointer font-weight-bold text-0083e0"
                                        data-toggle="collapse" role="button" aria-expanded="false" [attr.aria-controls]="'.tr_collapse_' + i" [attr.data-target]="'.tr_collapse_' + i">-->
                    <i></i>&nbsp;finanziamento {{finanziamento.codice}}
                    <!-- </a>-->
                    <div class="" style="font-size: 13px;">{{finanziamento.titolo}}</div>
                  </td>
                  <td>
                    nessuna fase presente
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td>
                    &nbsp;
                  </td>
                </tr>
              </ng-container>
            </ng-container>

          </ng-container>


        </ng-container>

        <!--progetti / interventi-->
        <ng-container *ngIf="currentObject && currentObject.progetti as progetti">
          <ng-container *ngFor="let progetto of progetti;let i=index;">


            <ng-container
              [ngSwitch]="((progetto.fasi && progetto.fasi.length>0)|| (progetto.fasiProgetto && progetto.fasiProgetto.length>0 ) ) && 'hasFasi'">

              <ng-container *ngSwitchCase="'hasFasi'">
                <ng-container *ngFor="let fase of getFasi(progetto) ;let j=index;">
                  <tr>
                    <td *ngIf="showSelectColumn" style="max-width:20px;"><input type="checkbox" (click)="addRemoveSelection(fase.id)"></td>
                    <td class="fixedme" style="max-width: 290px;">
                      <a *ngIf="j==0"
                        onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')"
                        class="pointer font-weight-bold text-0083e0" data-toggle="collapse" role="button"
                        aria-expanded="false" [attr.aria-controls]="'.tr_collapse_' + i"
                        [attr.data-target]="'.tr_collapse_' + i">
                        <i class="fas fa-arrow-circle-down">
                        </i>&nbsp;{{j==0 ? ( (isGr34() || isAdmin()?  'progetto / fabbisogno ':'progetto')  + progetto.codice ) :'' }}
                      </a>
                      <div class="" style="font-size: 13px;">{{j==0? progetto.titolo:''}}</div>
                    </td>
                    <td >                        
                      <span popover="{{fase.tipologiaFase.fase + ' ' + fase.tipologiaFase.titolo + ' ' + fase.tipologiaFase.descrizione}}" placement="auto" triggers="mouseenter:mouseleave">{{trimText(fase.tipologiaFase.fase + ' ' + fase.tipologiaFase.titolo + ' ' + fase.tipologiaFase.descrizione,100)}}</span>
                    </td>
                    <td class="text-center">
                      {{printStatoFase(fase)}}
                      <div
                        class="help text-center p-2 rounded shadow-sm justify-content-center {{getColorStatoFase(fase)}}"
                        title="gravità: {{getColorStatoFase1(fase)}}"></div>
                    </td>
                    <td>

                      {{printDate(fase.dataPrevistaInizio)}}

                    </td>
                    <td>
                      {{printDate(fase.dataInizio)}}

                    </td>
                    <td>
                      {{printScostamentoRitardo(fase)}}
                      <a type="button" class="help"
                        popover="Scostamento calcolato dal {{printValueAsDateWithCurrentDateAsDefault(fase.dataInizio)}}"
                        placement="bottom" triggers="mouseenter:mouseleave">
                        <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                        </i>
                      </a>

                    </td>
                    <td>
                      {{printDate(fase.dataPrevistaFine)}}

                    </td>
                    <td>
                      {{printDate(fase.dataFine)}}

                    </td>
                    <td>
                      {{printScostamentoScaduto(fase)}}
                      <a type="button" class="help"
                        popover="Scostamento calcolato dal {{printValueAsDateWithCurrentDateAsDefault(fase.dataFine)}}"
                        placement="bottom" triggers="mouseenter:mouseleave">
                        <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                        </i>
                      </a>
                    </td>
                    <td>
                      <ul>
                        <li *ngFor="let val of getSoggettoResponsabileText(fase)">{{val}}</li>
                      </ul>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <tr>
                 <td *ngIf="showSelectColumn" style="max-width:20px;">&nbsp;</td>
                  <td class="fixedme" style="max-width: 290px;">
                    <a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')"
                      class="pointer font-weight-bold text-0083e0" data-toggle="collapse" role="button"
                      aria-expanded="false" [attr.aria-controls]="'.tr_collapse_' + i"
                      [attr.data-target]="'.tr_collapse_' + i">
                      <i class="fas fa-arrow-circle-down"></i>&nbsp;progetto {{isGr34() || isAdmin()?'/ fabbisogno':''}} {{progetto.codice}}
                    </a>
                    <div class="" style="font-size: 13px;">{{progetto.titolo}}</div>
                  </td>
                  <td>
                    nessuna fase presente
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td>
                    &nbsp;
                  </td>
                </tr>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="progetto && progetto.interventi">
              <ng-container *ngFor="let intervento of progetto.interventi;let j = index;">

                <ng-container [ngSwitch]="intervento.fasi && intervento.fasi.length>0 && 'hasFasiIntervento'">

                  <ng-container *ngSwitchCase="'hasFasiIntervento'">
                    <ng-container *ngFor="let faseIntervento of intervento.fasi;let k=index;">
                      <tr class="background-table collapse {{'tr_collapse_' + i}}">
                        <td *ngIf="showSelectColumn" style="max-width:20px;"><input type="checkbox" (click)="addRemoveSelection(fase.id)"></td>
                        <th *ngIf="k==0" class="fixedme" style="max-width: 290px;" scope="row">

                          &nbsp;{{k==0 ? ( 'intervento ' + intervento.codice ) :'' }}

                          <div class="" style="font-size: 13px; font-weight: normal;">{{j==0? intervento.titolo:''}}
                          </div>
                        </th>
                        <td>
                          {{faseIntervento.tipologiaFase.fase + ' ' + faseIntervento.tipologiaFase.titolo + ' ' + faseIntervento.tipologiaFase.descrizione}}
                        </td>
                        <td class="text-center">
                          {{printStatoFase(faseIntervento)}}
                          <div
                            class="help p-2  rounded shadow-sm justify-content-center {{getColorStatoFase(faseIntervento)}} "
                            title="gravità: {{getColorStatoFase1(faseIntervento)}}"></div>
                        </td>
                        <td>
                          {{printDate(faseIntervento.dataPrevistaInizio)}}

                        </td>
                        <td>
                          {{printDate(faseIntervento.dataInizio)}}

                        </td>
                        <td>
                          {{printScostamentoRitardo(faseIntervento)}}
                          <a type="button" class="help"
                            popover="Scostamento calcolato dal {{printValueAsDateWithCurrentDateAsDefault(faseIntervento.dataInizio)}}"
                            placement="bottom" triggers="mouseenter:mouseleave">
                            <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                            </i>
                          </a>

                        </td>
                        <td>
                          {{printDate(faseIntervento.dataPrevistaFine)}}

                        </td>
                        <td>
                          {{printDate(faseIntervento.dataFine)}}

                        </td>
                        <td>
                          {{printScostamentoScaduto(faseIntervento)}}
                          <a type="button" class="help"
                            popover="Scostamento calcolato dal {{printValueAsDateWithCurrentDateAsDefault(faseIntervento.dataFine)}}"
                            placement="bottom" triggers="mouseenter:mouseleave">
                            <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                            </i>
                          </a>
                        </td>
                        <td>
                          <ul>
                            <li *ngFor="let val of getSoggettoResponsabileText(faseIntervento)">{{val}}</li>
                          </ul>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <tr class="background-table collapse {{'tr_collapse_' + i}}">
                      <td *ngIf="showSelectColumn" style="max-width:20px;">&nbsp;</td>
                      <th class="fixedme" style="max-width: 290px;" scope="row">
                        &nbsp;intervento {{intervento.codice}}
                        <div class="" style="font-size: 13px;">{{intervento.titolo}}</div>
                      </th>
                      <td>
                        nessuna fase presente
                      </td>
                      <td>
                        &nbsp;
                      </td>
                      <td>
                        &nbsp;
                      </td>
                      <td>
                        &nbsp;
                      </td>
                      <td>
                        &nbsp;
                      </td>
                      <td>
                        &nbsp;
                      </td>
                      <td>
                        &nbsp;
                      </td>
                      <td>
                        &nbsp;
                      </td>
                      <td>
                        &nbsp;
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>

          </ng-container>


        </ng-container>

        <!--nessun risultato-->
        <ng-container
          *ngIf="!(currentObject && ((currentObject.progetti && currentObject.progetti.length>0) || (currentObject.finanziamenti && currentObject.finanziamenti.length>0)))">
          <tr>
            <td colspan="20">Nessun progetto {{isGr34() || isAdmin()?'/ fabbisogno':''}} / finanziamento presente</td>
          </tr>
        </ng-container>
      </tbody>




    </table>

  </div>
</div>
