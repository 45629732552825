<div class="p-5 container-xl text-center" [hidden]="loadDataCounter<=0">
  <app-loading message="Caricamento in corso..."></app-loading>
</div>
<div class="container-xl" [hidden]="loadDataCounter>0">
  <div class="position-sticky sticky-top   pt-4 pb-4 elemento-aggiorna mb-5 shadow-custom"
    [ngClass]="{'sticky-top-custom-modal': currentIntervento && !currentIntervento.isMain,'sticky-top-custom':!currentIntervento ||( currentIntervento && currentIntervento.isMain) }">
    <div class=" row  justify-content-center">
      <div class="col-lg-4 col-md-6 text-center " [hidden]="readOnlyForm">
        <button [disabled]="loading" (click)="openAddModal()" class="btn btn-primary btn-block" type="button">
          Aggiungi nuova erogazione&nbsp;<i class="fas fa-plus"></i>
        </button>
      </div>
      <div class="col-lg-4 col-md-6 text-center " *ngIf="gotoBtnOptions && gotoBtnOptions.show">
        <button (click)="gotoBackBtnClick()" [disabled]="loading" class="btn btn-secondary btn-block font-weight-bold"
          type="button">
          {{gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}}&nbsp;<i class="fas fa-times"
            *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i>
        </button>
      </div>
    </div>
  </div>

  <!--***-->

  <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;"
    [dtOptions]="dtOptions">
    <ng-template #btnEditTemplate let-row="row" let-value="value">
      <a [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top"
        title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
    </ng-template>
    <ng-template #btnDetailTemplate let-row="row" let-value="value">
      <a [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top"
        title="dettaglio">&nbsp;&nbsp;&ensp;<i class="far fa-eye pointer detail-icon"></i></a>
    </ng-template>
    <ng-template #btnDeleteTemplate let-row="row" let-value="value">
      <a [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top"
        title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
    </ng-template>
    <ng-template #anticipazioneFieldTemplate let-row="row" let-value="value" ngx-datatable-cell-template>
      {{printAnticipazioneTemplate(value)}}
    </ng-template>
  </ngx-datatable-wrapper>
</div>


<ng-template #templateEditAdd>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">{{modalTitle}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
      <input type="hidden" [formGroup]="editForm" formControlName="id" />

      <div class="col-lg-12 mt-3">

        <div class="form-group row">
          <div class="col-sm-6">
            <label for="tipologiaId">
              <h5 class="mandatory">Tipologia:</h5>
            </label>
            <div [ngClass]="{ 'is-invalid': submitted && editForm.controls['tipologiaId'].errors }">
              <ng-select [formGroup]="editForm" formControlName="tipologiaId" [items]="tipologieErogazioni"
                bindValue="id" bindLabel="titolo" placeholder="Seleziona la tipologia">
              </ng-select>
            </div>
            <div *ngIf="submitted && editForm.controls['tipologiaId'].errors" class="invalidFieldMessage"
              style="display: block;">
              <div *ngIf="editForm.controls['tipologiaId'].errors" class="invalid-feedback" style="display: block;">
                Campo obbligatorio
              </div>
            </div>
          </div>
          <div class="col-sm-6" [hidden]="true">
            <!--25/01/2022: questo campo non serve piu-->
            <label for="anno">
              <h5 class="mandatory">Anno:</h5>
            </label>
            <div [ngClass]="{ 'is-invalid': submitted && editForm.controls['anno'].errors }">
              <ng-select [formGroup]="editForm" formControlName="anno" [items]="anniList" bindValue="anno"
                bindLabel="anno" placeholder="Seleziona l'anno">
              </ng-select>
            </div>
            <div *ngIf="submitted && editForm.controls['anno'].errors" class="invalidFieldMessage"
              style="display: block;">
              <div *ngIf="editForm.controls['anno'].errors" class="invalid-feedback" style="display: block;">
                Campo obbligatorio
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <label for="importoComplessivo">
              <h5 class="mandatory">Importo:</h5>
            </label>
            <input [formGroup]="editForm" formControlName="importo" maxlength="255" type="text" class="form-control"
              placeholder="Inserisci l'importo" [ngClass]="{
                                'is-invalid': submitted && editForm.controls.importo.errors
                                }" [imask]="mask" [unmask]="true" />
            <div *ngIf="submitted && editForm.controls.importo.errors" class="invalid-feedback" style="display: block;">
              <div *ngIf="editForm.controls.importo.errors.required">
                Campo obbligatorio
              </div>
            </div>
          </div>
        </div>



        <div class="form-group row">
          <div class="col-sm-6" [hidden]="true">
            <!--data prevista 25/01/2022: questo campo non serve piu-->
            <label for="dataPrevista">
              <h5 class="mandatory">Data prevista:</h5>
            </label>
            <div [ngClass]="{
                        'is-invalid': submitted && editForm.controls.dataPrevista.errors
                        }" class="col-lg-12 col-md-12 col-sm-12 col-12"
              style="display:flex; padding-right:0px;padding-left:0px">
              <input type="text" formControlName="dataPrevista" class="form-control" bsDatepicker [formGroup]="editForm"
                [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY',containerClass:'theme-dark-blue' }"
                #dataPrevista="bsDatepicker" style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
              <button class="btn btn-primary" (click)="dataPrevista.toggle()" type="button"
                style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>

            <div *ngIf="submitted && editForm.controls.dataPrevista.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="editForm.controls.dataPrevista.errors.required">
                Campo obbligatorio
              </div>

            </div>
          </div>

          <div class="col-sm-6">
            <label for="tipoProvvedimento">
              <h5 class="mandatory">Tipologia provvedimento:</h5>
            </label>
            <div [ngClass]="{ 'is-invalid': submitted && editForm.controls['tipoProvvedimento'].errors }">
              <ng-select [formGroup]="editForm" formControlName="tipoProvvedimento" [items]="tipiProvvedimento"
                bindValue="id" bindLabel="provvedimento" placeholder="Seleziona la tipologia">
              </ng-select>
            </div>
            <div *ngIf="submitted && editForm.controls['tipoProvvedimento'].errors" class="invalidFieldMessage"
              style="display: block;">
              <div *ngIf="editForm.controls['tipoProvvedimento'].errors" class="invalid-feedback"
                style="display: block;">
                Campo obbligatorio
              </div>
            </div>
          </div>


          <div class="col-sm-6">
            <label for="dataEffettiva">
              <h5 class="mandatory">Data erogazione:</h5>
            </label>
            <div [ngClass]="{
                        'is-invalid': submitted && editForm.controls.dataEffettiva.errors
                        }" class="col-lg-12 col-md-12 col-sm-12 col-12"
              style="display:flex; padding-right:0px;padding-left:0px">
              <input type="text" formControlName="dataEffettiva" class="form-control" bsDatepicker
                [formGroup]="editForm"
                [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY',containerClass:'theme-dark-blue' }"
                #dataEffettiva="bsDatepicker" style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
              <button class="btn btn-primary" (click)="dataEffettiva.toggle()" type="button"
                style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>

            <div *ngIf="submitted && editForm.controls.dataEffettiva.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="editForm.controls.dataEffettiva.errors.required">
                Campo obbligatorio
              </div>

            </div>
          </div>
        </div>


        <div class="form-group row">
          <div class="col-sm-6">
            <label for="numeroProvvedimento">
              <h5 class="mandatory">Numero provvedimento:</h5>
            </label>
            <input [formGroup]="editForm" formControlName="numeroProvvedimento" maxlength="255" type="text"
              class="form-control" placeholder="Inserisci il numero di provvedimento" [ngClass]="{
                'is-invalid': submitted && editForm.controls.numeroProvvedimento.errors
                }" />
            <div *ngIf="submitted && editForm.controls.numeroProvvedimento.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="editForm.controls.numeroProvvedimento.errors.required">
                Campo obbligatorio
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <label for="dataProvvedimento">
              <h5 class="mandatory">Data provvedimento:</h5>
            </label>
            <div [ngClass]="{
                        'is-invalid': submitted && editForm.controls.dataProvvedimento.errors
                        }" class="col-lg-12 col-md-12 col-sm-12 col-12"
              style="display:flex; padding-right:0px;padding-left:0px">
              <input type="text" formControlName="dataProvvedimento" class="form-control" bsDatepicker
                [formGroup]="editForm"
                [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY',containerClass:'theme-dark-blue' }"
                #dataProvvedimento="bsDatepicker"
                style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
              <button class="btn btn-primary" (click)="dataProvvedimento.toggle()" type="button"
                style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                <i class="fas fa-calendar-alt"></i>
              </button>
            </div>

            <div *ngIf="submitted && editForm.controls.dataProvvedimento.errors" class="invalid-feedback"
              style="display: block;">
              <div *ngIf="editForm.controls.dataProvvedimento.errors.required">
                Campo obbligatorio
              </div>

            </div>
          </div>
        </div>

      </div>

      <div class="col-lg-12 mt-5">
        <hr>
      </div>
      <!-- inizio finanziamenti-->
      <div class="col-lg-12 mt-4">
        <div class="row justify-content-between">

          <div class="col-lg-6 col-sm-6 col-6 mt-3">
            <h5 for="" class="mandatory">FINANZIAMENTI</h5>
          </div>
          <div class="col-lg-1 col-sm-2 col-3 text-center mt-3"
            [hidden]="(readOnlyForm  )">
            <a href="javascript:;;" class="bottone-inserisci" (click)="openModalFinanziamenti();"><i
                class="fas fa-plus"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-12 mt-3 table-responsive-lg">
        <table class="table table-hover table-colore" [ngClass]="{
                    'is-invalid': submitted && finanziamentoIsError
                    }">
          <thead class="">
            <th>codice</th>
            <th>titolo</th>
            <th>importo</th>
            <th class="casella-icona-tx" [hidden]="(readOnlyForm )">elimina</th>
          </thead>
          <tbody>
            <ng-container
              *ngIf="finanziamentiSelectedList && finanziamentiSelectedList.length && finanziamentiSelectedList.length>0">
              <tr *ngFor="let item of finanziamentiSelectedList">
                <td>{{item.finanziamento.codice}}</td>
                <td>{{item.finanziamento.titolo}}</td>
                <td>{{printValueAsCurrency(item.importo)}}</td>
                <td [hidden]="(readOnlyForm )"><a (click)="removeFinanziamento(item)"
                    href="javascript:;;" data-toggle="tooltip" data-placement="top" title="elimina"><i
                      class="far fa-trash-alt casella-icona-tx"></i> </a>
                </td>
              </tr>
            </ng-container>
            <tr
              *ngIf="!(finanziamentiSelectedList && finanziamentiSelectedList.length && finanziamentiSelectedList.length>0)">
              <td colspan="4">Nessun finanziamento selezionato</td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="submitted && finanziamentoIsError" class="invalidFieldMessage">
          <div *ngIf="finanziamentoIsError" class="invalid-feedback" style="display: block;">
            Selezionare almeno un finanziamento
          </div>
        </div>
      </div>



      <!--fine finanziamenti-->

      <div class="col-lg-12 mt-5">
        <hr>
      </div>

      <div class="col-lg-12 mt-4">
        <div class="row justify-content-between">

          <div class="col-lg-6 col-sm-6 col-6 mt-3">
            <h5 for="" class="mandatory">SAL/ALTRO</h5>
          </div>
          <div class="col-lg-1 col-sm-2 col-3 text-center mt-3"
            [hidden]="(readOnlyForm  || editForm.controls.id.value)">
            <a href="javascript:;;" class="bottone-inserisci" (click)="openModalSAL();"><i class="fas fa-plus"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-12 mt-3 table-responsive-lg">
        <table class="table table-hover table-colore" [ngClass]="{
                    'is-invalid': submitted && salListIsError
                    }">
          <thead class="">
            <th>categoria</th>
            <th>importo</th>
            <th>data<br />competenza SAL/ALTRO</th>
            <th>descrizione</th>
            <!--<th>somme<br />a soggetto attuatore</th>-->
            <th class="casella-icona-tx" [hidden]="(readOnlyForm || editForm.controls.id.value)">elimina</th>
          </thead>
          <tbody>
            <ng-container *ngIf="salList && salList.length && salList.length>0">
              <tr *ngFor="let item of salList">
                <td>{{item.categoria.titolo}}</td>
                <td>{{printValueAsCurrency(item.importo)}}</td>
                <td>{{printValueAsDate(item.dataLavori)}}</td>
                <td>{{item.descrizione}}</td>
                <!--<td>{{printValueAsBoolean(item.anticipazioneASoggettoAttuatore)}}</td>-->
                <td [hidden]="(readOnlyForm ||  editForm.controls.id.value)"><a (click)="removeSal(item)"
                    href="javascript:;;" data-toggle="tooltip" data-placement="top" title="elimina"><i
                      class="far fa-trash-alt casella-icona-tx"></i> </a>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="!(salList && salList.length && salList.length>0)">
              <td colspan="5">Nessun sal/altro selezionato</td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="submitted && salListIsError" class="invalidFieldMessage">
          <div *ngIf="salListIsError" class="invalid-feedback" style="display: block;">
            Selezionare almeno un sal/altro
          </div>
        </div>
      </div>
      <div class="col-lg-12 mb-3" [hidden]="readOnlyForm"><button [disabled]="loading"
          class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
          <img *ngIf="loading" class="pl-3 float-right"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          {{modalButtonLabel}}
        </button></div>
    </form>
  </div>
</ng-template>



<ng-template #templateAddFinanziamento>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">Aggiungi finanziamento</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalFinanziamenti()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editFormFinanziamento" (ngSubmit)="onSubmitFinanziamento()">
      <input type="hidden" [formGroup]="editFormFinanziamento" formControlName="id" />

      <div class="col-lg-12 mt-3">
        <div class="form-group">
          <h5><label for="finanziamentoId" class="mandatory">Finanziamento:</label></h5>
          <div [ngClass]="{ 'is-invalid': submitted && editFormFinanziamento.controls.finanziamentoId.errors }">
            <ng-select [formGroup]="editFormFinanziamento" formControlName="finanziamentoId" [items]="finanziamentiList"
              bindValue="id" bindLabel="titoloLungo" placeholder="Seleziona il finanziamento">
            </ng-select>
          </div>
          <div *ngIf="submittedFinanziamento && editFormFinanziamento.controls.finanziamentoId.errors"
            class="invalid-feedback" style="display: block;">
            <div *ngIf="editFormFinanziamento.controls.finanziamentoId.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>
        <div class="form-group">
          <h5><label for="importo" class="mandatory">Importo:</label></h5>
          <input [formGroup]="editFormFinanziamento" formControlName="importo" type="text" [showMaskTyped]="false"
            mask="separator.2" thousandSeparator="." suffix=" €" class="form-control" placeholder="Inserisci l'importo"
            [ngClass]="{
                                  'form-control': true,
                                  'is-invalid': submittedImporti && editFormFinanziamento.controls.importo.errors
                                  }" />
          <div *ngIf="submittedFinanziamento && editFormFinanziamento.controls.importo.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="editFormFinanziamento.controls.importo.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>

      </div>

      <div class="col-lg-12 mb-3"><button [disabled]="loading" class="btn btn-lg btn-primary btn-block" type="submit">
          <img *ngIf="loading" class="pl-3 float-right"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          Aggiungi
        </button></div>
    </form>
  </div>
</ng-template>
