<div class="container " [hidden]="
    hidden ||
    router.url.includes('dashboard-finanziaria') ||
    router.url.includes('dashboard-fasi') ||
    router.url.includes('dashboard-stato-finanziamento') ||
    router.url.includes('dashboard-stato-intervento') ||
    router.url.includes('dashboard-interventi') ||
    router.url.includes('dashboard-pnrr') ||
    router.url.includes('dashboard-non-disegnabile') ||
    router.url.includes('dashboard-pkmg') ||
    router.url.includes('dashboard-entrata-uscita') ||
    router.url.includes('dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr') ||
    router.url.includes('dashboard-sisma')  ||
    router.url.includes('cruscotto-note-atti') ||
    router.url.includes('forgotPassword')
  ">
  <div class="row">
    <ol class="breadcrumb" [hidden]="hidden">
      <li *ngFor="let breadcrumb of breadcrumbs$ | async; last as isLast" class="breadcrumb-item" [ngClass]="{ active: isLast }" aria-current="page">
        <a [ngClass]="{
          linkdisabilitato: !breadcrumb['clickable']
          }" *ngIf="!isLast; else lastRoute" [routerLink]="getRouterLink(breadcrumb)" routerLinkActive="active">
          {{ breadcrumb.label }}
        </a>
        <ng-template #lastRoute>{{ breadcrumb.label }}</ng-template>
      </li>
    </ol>
  </div>
</div>
