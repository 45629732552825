<div class="container-xl">



  <div class="container mb-5 px-0">


    <div id="tabs" #tabs>
      <ul class="nav nav-tabs bg-white pt-4 pb-1 mb-4">
        <li class="nav-item mt-1 px-1"  [hidden]="true">
          <a (click)="confirmChangeTab($event,0); " [ngClass]="{ 'active':activeTab==='tabProsa' } "
            class="tab-menu nav-link px-1 " href="#divTabProsa" data-toggle="tab">Protocolli Prosa</a>
        </li>
        <li class="nav-item mt-1 px-1">
          <a (click)="confirmChangeTab($event,1); " [ngClass]="{ 'active':activeTab==='tabGenerico' } "
            class="tab-menu nav-link px-1 " href="#divTabGenerico" data-toggle="tab">Caricamento da File</a>
        </li>

        <li class="nav-item mt-1 px-1" [hidden]="true">
          <a (click)="confirmChangeTab($event,2); " [ngClass]="{ 'active':activeTab==='tabSicer' } "
            class="tab-menu nav-link px-1 " href="#divTabSicer" data-toggle="tab">Sicer - Atti</a>
        </li>

        <li class=" nav-item mt-1 px-1" [hidden]="showAllData || this.classificazioneId || this.faseId || this.subInterventoId">
          <a (click)="confirmChangeTab($event,3); " [ngClass]="{ 'active':activeTab==='tabProvvedimenti' } "
            class="tab-menu nav-link px-1 " href="#divTabProvvedimenti" data-toggle="tab">Provvedimenti (non SICER)</a>
        </li>


      </ul>

      <div class="tab-content ">
        <div class="tab-pane " id="divTabProsa" [ngClass]="{ 'active':activeTab==='tabProsa' } ">
          <!--div protocolli-->
          <div class="col-lg-12 px-0">
            <div class="" [hidden]="readOnlyForm || showAllData">
              <form [formGroup]="frm_protocolli">
                <!-- *****  -->

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="inputAnno4">
                      <h5>Anno:</h5>
                    </label>
                    <input type="text" [formGroup]="frm_protocolli" formControlName="protocollo_anno"
                      class="form-control" />
                  </div>
                  <div class="form-group col-md-5">
                    <label for="inputProtocollo4">
                      <h5>N° Protocollo:</h5>
                    </label>
                    <input type="text" [formGroup]="frm_protocolli" formControlName="protocollo_numero"
                      class="form-control" />
                  </div>
                  <div class="form-group col-md-3 ">
                    <label for="buttonCerca1">
                      <h5 class="d-none d-sm-block">&nbsp;</h5>
                    </label>

                    <button
                      [disabled]="loading || !frm_protocolli.controls['protocollo_anno'] || !frm_protocolli.controls['protocollo_anno'].value || frm_protocolli.controls['protocollo_anno'].value.length!=4 || !frm_protocolli.controls['protocollo_numero'] || !frm_protocolli.controls['protocollo_numero'].value || frm_protocolli.controls['protocollo_numero'].value.length<=0 "
                      type="button" class="btn btn-info btn-block bold" (click)="acquisisciProtocolli()">
                      Cerca&nbsp;<i class="fas fa-search pointer" style="color:#fff; font-size: 18px;"></i>
                      <img *ngIf="loading" class="pl-3 float-right"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </button>


                  </div>
                </div>

              </form>


              <div class="col-lg-12 mt-3 table-responsive-lg">
                <table class="table table-hover table-colore">
                  <thead class="">
                    <th scope="col">tipologia</th>
                    <th scope="col">numero&nbsp;protocollo</th>
                    <th scope="col">data</th>
                    <th class="casella-descrizione-tx" scope="col" class="casella-descrizione-tx">oggetto</th>
                    <th class="casella-icona-tx" scope="col" class="casella-icona-tx">aggiungi</th>

                  </thead>
                  <tbody>
                    <ng-container *ngIf="protocolli && protocolli.length>0">
                      <tr *ngFor="let item of protocolli;let i = index;">
                        <td class="casella-icona-tx">{{item.tipoProtocollo}}</td>
                        <td>

                          <a class="pointer font-weight-bold text-0083e0 " title="mostra allegati"
                            data-toggle="collapse" role="button" aria-expanded="false"
                            [attr.aria-controls]="'.tr_collapse_' + i" [attr.data-target]="'.tr_collapse_' + i">
                            <i class="fas fa-list"></i>&nbsp;{{item.numeroProtocollo}}
                          </a>
                          <br />
                          <div class="ml-0" id="documenti-{{item.annoProtocollo}}-{{item.numeroProtocollo}}"
                            class="collapse {{'tr_collapse_' + i}}">

                            <p style="color: red;" *ngIf="item.messaggio && item.messaggio !=''">{{item.messaggio}}</p>
                            <ul class="pl-2" *ngIf="!(item.messaggio && item.messaggio !='')">
                              <li class="text-lowercase" *ngFor="let allegato of item.allegati">
                                <small><a href="javascript:;"
                                    (click)="downloadDocumento(allegato.codice, allegato.nome)">{{allegato.nome}}</a></small>
                              </li>
                            </ul>


                          </div>

                        </td>
                        <td><small>{{printDateValue(item.dataProtocollo)}}</small></td>
                        <td><small>{{item.oggetto}}</small></td>
                        <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;<a class="bottone-inserisci"
                            (click)="aggiungiProtocollo(item.numeroProtocollo);" href="javascript:;;"><i
                              class="fas fa-plus "></i></a>
                        </td>
                      </tr>
                    </ng-container>
                    <tr *ngIf="(!protocolli || protocolli.length <=0)">
                      <td colspan="4">Nessun protocollo trovato</td>
                    </tr>
                  </tbody>
                </table>

              </div>

            </div>

            <!--accordion-->
            <accordion [isAnimated]="true">
              <accordion-group heading="Protocolli in Entrata ({{cntProtocolliEntrata}})">
                <ngx-datatable-wrapper #ngxDatatableWrapperProtocolliEntrata [templates]="templateList"
                  style="width: 100%; height: auto;" [dtOptions]="dtOptionsProtocolliEntrata">
                </ngx-datatable-wrapper>
              </accordion-group>
            </accordion>
            <accordion [isAnimated]="true">
              <accordion-group heading="Protocolli in Uscita ({{cntProtocolliUscita}})">
                <ngx-datatable-wrapper #ngxDatatableWrapperProtocolliUscita [templates]="templateList"
                  style="width: 100%; height: auto;" [dtOptions]="dtOptionsProtocolliUscita">
                  <ng-template #btnDeleteProsaTemplate let-row="row" let-value="value">
                    <a [hidden]="readOnlyForm || showAllData || row.readonly" class=" border-0 text-center"
                      (click)="deleteProsaDocument(row)" data-toggle="tooltip" data-placement="top"
                      title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
                  </ng-template>

                  <ng-template #numeroProtocolloTemplate let-row="row">
                    <a class="pointer font-weight-bold text-0083e0" title="mostra allegati" data-toggle="collapse"
                      role="button" aria-expanded="false" [attr.aria-controls]="'.dtw_tr_collapse_' + row.id"
                      [attr.data-target]="'.dtw_tr_collapse_' + row.id">
                      <i class="fas fa-list"></i>&nbsp;{{row.numeroProtocollo}}</a>
                    <br />
                    <div id="documenti-{{row.annoProtocollo}}-{{row.numeroProtocollo}}"
                      class="collapse {{'dtw_tr_collapse_' + row.id}}">

                      <p style="color: red;" *ngIf="row.messaggio && row.messaggio !=''">{{row.messaggio}}</p>
                      <ul class="pl-2" *ngIf="!(row.messaggio && row.messaggio !='')">
                        <li class="text-lowercase" *ngFor="let allegato of row.allegati">
                          <a href="javascript:;"
                            (click)="downloadDocumento(allegato.codice, allegato.nome)">{{allegato.nome}}</a>
                        </li>
                      </ul>
                    </div>
                  </ng-template>
                  <ng-template #noteProtocolloTemplate let-row="row" let-value="value">
                    <a class=" border-0 text-center" (click)="editProsaDocument(row)" data-toggle="tooltip"
                      data-placement="top" title="aggiorna note"
                      [hidden]="readOnlyForm || showAllData || row.readonly"><i
                        class="far fa-edit pointer"></i></a><span class="help"
                      title="{{value}}">{{trimText(value,100)}}</span>
                  </ng-template>

                  <ng-template #oggettoProtocolloTemplate let-row="row" let-value="value">
                    <span class="help" title="{{value}}">{{trimText(value,100)}}</span>
                  </ng-template>
                </ngx-datatable-wrapper>
              </accordion-group>
            </accordion>
            <accordion [isAnimated]="true">
              <accordion-group heading="Protocolli Interni ({{cntProtocolliInterni}})">
                <ngx-datatable-wrapper #ngxDatatableWrapperProtocolliInterni [templates]="templateList"
                  style="width: 100%; height: auto;" [dtOptions]="dtOptionsProtocolliInterni">
                </ngx-datatable-wrapper>
              </accordion-group>
            </accordion>

          </div>
          <!--fine div protocolli-->
        </div>

        <div class="tab-pane " id="divTabProvvedimenti" [ngClass]="{ 'active':activeTab==='tabProvvedimenti' } ">
          <associa-provvedimenti-component [isInModal]="isInModal" #tabProvvedimentiContent id="templateProvvedimenti"
            [finanziamentoId]="finanziamentoId" [interventoId]="interventoId"
            [gotoBtnOptions]="{show:false,label:gotoBtnOptions?gotoBtnOptions.label:'Torna a elenco'}"
            (onChangeOk)="associaProvvedimentiChanged($event) ">
          </associa-provvedimenti-component>
        </div>

        <div class="tab-pane " id="divTabGenerico" [ngClass]="{ 'active':activeTab==='tabGenerico' } ">

          <div class="col-lg-12 px-0 mt-3">

            <div class="row justify-content-md-center" [hidden]="readOnlyForm || showAllData">
              <form [formGroup]="addDocumentForm" style="width:100%">
                <!-- *****  -->

                <div class="col-lg-12 mt-3 px-4 table-responsive-lg">
                  <table class="table  table-colore">
                    <thead class="">
                      <th class="casella-descrizione-tx">titolo documentazione</th>

                      <th>file documentazione</th>
                      <th class="casella-icona-tx">carica</th>
                    </thead>
                    <tbody>
                      <ng-container>
                        <tr>
                          <td>
                            <input type="text" maxlength="255" [formGroup]="addDocumentForm" formControlName="title"
                              class="form-control" [ngClass]="{'is-invalid': submitted && f.title.errors }">
                            <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                              <div *ngIf="f.title.errors.required">Titolo &egrave; obbligatorio</div>
                            </div>
                          </td>
                          <td>
                            <input type="file" formControlName="attach" [formGroup]="addDocumentForm"
                              (change)="fileChange($event)" class="pt-1 pointer"
                              [ngClass]="{'is-invalid': submitted && f.attach.errors }" />
                            <div *ngIf="submitted && f.attach.errors" class=" invalid-feedback" style="display: block;">
                              <div *ngIf="f.attach.errors.required">File &egrave; obbligatorio</div>
                            </div>
                          </td>
                          <td>
                            <span (click)="onSubmit()" class="pointer">
                              <i class="fas fa-upload pointer casella-icona-tx" title="carica"></i>
                            </span>
                          </td>
                        </tr>
                      </ng-container>

                    </tbody>
                  </table>

                </div>
              </form>
            </div>
            <!--<div class="">
                      <hr>
                  </div>-->
            <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;"
              [dtOptions]="dtOptionsNgx">
              <ng-template #btnDownloadTemplate let-row="row" let-value="value">
                <a class=" border-0 text-center" (click)="downloadDocument(row)" data-toggle="tooltip"
                  data-placement="top" title="scarica">&nbsp;&nbsp;&ensp;<i class="fas fa-download pointer"></i></a>
              </ng-template>

              <ng-template #btnDeleteTemplate let-row="row" let-value="value">
                <a [hidden]="row.readonly" class=" border-0 text-center" (click)="deleteDocument(row)"
                  data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i
                    class="far fa-trash-alt pointer"></i></a>
              </ng-template>
            </ngx-datatable-wrapper>
          </div>
        </div>

        <div class="tab-pane " id="divTabSicer" [ngClass]="{ 'active':activeTab==='tabSicer' } ">
          <div class="col-lg-12 px-0">
            <div class="" [hidden]="readOnlyForm || showAllData">
              <form [formGroup]="frm_sicer">
                <!-- *****  -->
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="sicer_anno">
                      <h5>Anno:</h5>
                    </label>
                    <input type="text" [formGroup]="frm_sicer" formControlName="sicer_anno" class="form-control" />
                  </div>
                  <div class="form-group col-md-5">
                    <label for="sicer_numero">
                      <h5>N° Atto:</h5>
                    </label>
                    <input type="text" [formGroup]="frm_sicer" formControlName="sicer_numero" class="form-control" />
                  </div>
                  <div class="form-group col-md-3 ">
                    <label for="buttonCerca1">
                      <h5 class="d-none d-sm-block">&nbsp;</h5>
                    </label>

                    <button
                      [disabled]="loading || !frm_sicer.controls['sicer_anno'] || !frm_sicer.controls['sicer_anno'].value || frm_sicer.controls['sicer_anno'].value.length!=4 || !frm_sicer.controls['sicer_numero'] || !frm_sicer.controls['sicer_numero'].value || frm_sicer.controls['sicer_numero'].value.length<=0 "
                      type="button" class="btn btn-info btn-block bold" (click)="acquisisciSicerAtti()">
                      Cerca&nbsp;<i class="fas fa-search pointer" style="color:#fff; font-size: 18px;"></i>
                      <img *ngIf="loading" class="pl-3 float-right"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </button>


                  </div>
                </div>


              </form>


              <div class="col-lg-12 mt-3 table-responsive-lg" [hidden]="readOnlyForm || showAllData">
                <table class="table table-hover table-colore">
                  <thead class="">
                    <th scope="col">numero&nbsp;atto</th>
                    <th scope="col">data</th>
                    <th scope="col">tipologia</th>
                    <th class="casella-descrizione-tx" scope="col" class="casella-descrizione-tx">oggetto</th>
                    <th class="casella-icona-tx" scope="col" class="casella-icona-tx">aggiungi</th>

                  </thead>
                  <tbody>
                    <ng-container *ngIf="sicerAtti && sicerAtti.length>0">
                      <tr *ngFor="let item of sicerAtti;let i = index;">
                        <td>
                          <a class="pointer font-weight-bold text-0083e0 " title="scarica allegato" href="javascript:;"
                            (click)="downloadSicer(item)">{{item.numero}}
                          </a>
                        </td>
                        <td><small>{{printDateValue(item.data)}}</small></td>
                        <td class="casella-icona-tx">{{item.tipo}}</td>
                        <td><small>{{item.oggetto}}</small></td>
                        <td>
                          &nbsp;&nbsp;&nbsp;&nbsp;<a class="bottone-inserisci" (click)="aggiungiSicerAtto(item);"
                            href="javascript:;;"><i class="fas fa-plus "></i></a>
                        </td>
                      </tr>
                    </ng-container>
                    <tr *ngIf="(!sicerAtti || sicerAtti.length <=0)">
                      <td colspan="4">Nessun atto trovato</td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>

            <accordion [isAnimated]="true">
              <accordion-group heading="SICER - ATTI ({{cntSicerAtti}})">
                <ngx-datatable-wrapper #ngxDatatableWrapperSicerAtti [templates]="templateList"
                  style="width: 100%; height: auto;" [dtOptions]="dtOptionsSicerAtti">
                  <ng-template #btnDeleteSicerAttiTemplate let-row="row" let-value="value">
                    <a [hidden]="readOnlyForm || showAllData || row.readonly" class=" border-0 text-center"
                      (click)="deleteSicerAtti(row)" data-toggle="tooltip" data-placement="top"
                      title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i></a>
                  </ng-template>
                  <ng-template #noteSicerAttiTemplate let-row="row" let-value="value">
                    <a class=" border-0 text-center" (click)="editSicerAttiDocument(row)" data-toggle="tooltip"
                      data-placement="top" title="aggiorna note"
                      [hidden]="readOnlyForm || showAllData || row.readonly"><i
                        class="far fa-edit pointer"></i></a><span class="help"
                      title="{{value}}">{{trimText(value,100)}}</span>
                  </ng-template>

                  <ng-template #numeroAttoTemplate let-row="row">
                    <a href="javascript:;" (click)="downloadSicer(row)">{{row.numero}}</a>
                  </ng-template>

                </ngx-datatable-wrapper>
              </accordion-group>
            </accordion>

          </div>
        </div>


      </div>
    </div>

  </div>

</div>
<ng-template #templateEditNoteModal>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">Modifica note</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalEditProsaNote()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editNoteProtocollo">
      <input type="hidden" [formGroup]="editNoteProtocollo" formControlName="id" />
      <div class="form-group">
        <div class="col-12">
          <h5><label for="note" class="">Note:</label></h5>
          <textarea [formGroup]="editNoteProtocollo" rows="3" formControlName="note_protocollo" maxlength="255"
            type="text" value="" class="form-controlh" placeholder="Inserisci le note" [ngClass]="{
                'form-control': true
                }"></textarea>
        </div>
      </div>


      <div class="col-12 mb-3">
        <button class="btn btn-lg btn-primary btn-block" type="button" style="margin:15px 0"
          (click)="salvaNoteProtocollo()">
          Salva
        </button></div>
    </form>
  </div>
</ng-template>

<ng-template #templateEditNoteModalSicerAtti>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">Modifica note</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalEditProsaNote()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editNoteSicer">
      <input type="hidden" [formGroup]="editNoteSicer" formControlName="id" />
      <div class="form-group">
        <div class="col-12">
          <h5><label for="note" class="">Note:</label></h5>
          <textarea [formGroup]="editNoteSicer" rows="3" formControlName="note" maxlength="255" type="text" value=""
            class="form-controlh" placeholder="Inserisci le note" [ngClass]="{
                  'form-control': true
                  }"></textarea>
        </div>
      </div>


      <div class="col-12 mb-3">
        <button class="btn btn-lg btn-primary btn-block" type="button" style="margin:15px 0" (click)="salvaNoteSicer()">
          Salva
        </button></div>
    </form>
  </div>
</ng-template>
