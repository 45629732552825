
  <h4 class="text-center">Avanzamento Target Regionale</h4>
  <ul class="nav nav-tabs bg-white pb-1" id="pills-tab-province" role="tablist">
    <li class="nav-item mt-1 px-1">
      <a
        class="tab-menu nav-link px-1  active"
        id="target-tab"
        data-toggle="pill"
        href="#target"
        role="tab"
        aria-controls="target-tab"
        aria-selected="true"
        >Target</a
      >
    </li>
    <li class="nav-item mt-1 px-1">
      <a
        class="tab-menu nav-link px-1 "
        id="milestone-tab"
        data-toggle="pill"
        href="#milestone"
        role="tab"
        aria-controls="milestone-tab"
        aria-selected="false"
       
        >Milestone</a
      >
    </li>
  </ul>
  <div class="tab-content" id="targetMilestoneTab">
    <!-- Target -->
    <div
      class="tab-pane fade show active"
      id="target"
      role="tabpanel"
      aria-labelledby="target-tab"
    >
      <app-pnrr-tab-target-milestone [tipologia]="'target'" ></app-pnrr-tab-target-milestone>
    </div>
    <!-- Milestone  -->
    <div
      class="tab-pane fade"
      id="milestone"
      role="tabpanel"
      aria-labelledby="target-tab"
    >
    <app-pnrr-tab-target-milestone [tipologia]="'milestone'"></app-pnrr-tab-target-milestone>
    </div>
  </div>

