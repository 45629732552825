<div class="container bg-white">
    <div class="col-lg-12 mt-3">
        <app-title-page></app-title-page>

        <div class="row justify-content-md-center" *ngIf="isAdmin()">
            <form [formGroup]="addDocumentForm" style="width:100%">
                <!-- *****  -->

                <div class="col-lg-12 mt-3 px-4 table-responsive-lg">
                    <table class="table  table-colore">
                        <thead class="">
                            <th class="casella-descrizione-tx">titolo documentazione</th>

                            <th>file documentazione</th>
                            <th class="casella-icona-tx">carica</th>
                        </thead>
                        <tbody>
                            <ng-container>
                                <tr>
                                    <td>
                                        <input type="text" maxlength="255" [formGroup]="addDocumentForm" formControlName="title" class="form-control" [ngClass]="{'is-invalid': submitted && f.title.errors }">
                                        <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                            <div *ngIf="f.title.errors.required">Titolo &egrave; obbligatorio</div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="file" formControlName="attach" [formGroup]="addDocumentForm" (change)="fileChange($event)" class="pt-1" [ngClass]="{'is-invalid': submitted && f.attach.errors }" />
                                        <div *ngIf="submitted && f.attach.errors" class=" invalid-feedback" style="display: block;">
                                            <div *ngIf="f.attach.errors.required">File &egrave; obbligatorio</div>
                                        </div>
                                    </td>
                                    <td>
                                        <span (click)="onSubmit()" class="pointer">
                                            <i class="fas fa-upload pointer casella-icona-tx" ></i>
                                        </span>
                                    </td>
                                </tr>
                            </ng-container>

                        </tbody>
                    </table>

                </div>


                <!--<table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">
                            TITOLO DOCUMENTAZIONE
                            <span style="color: #e02222;font-size: 12px;padding-left: 2px;"> * </span>
                        </th>
                        <th scope="col" style="width:210px">
                            FILE DOCUMENTAZIONE
                            <span style="color: #e02222;font-size: 12px;padding-left: 2px;"> * </span>
                        </th>

                        <th scope="col" style="width:80px">
                            CARICA
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input type="text" maxlength="255" [formGroup]="addDocumentForm" formControlName="title" class="form-control" [ngClass]="{'is-invalid': submitted && f.title.errors }">
                            <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                <div *ngIf="f.title.errors.required">Titolo &egrave; obbligatorio</div>
                            </div>
                        </td>
                        <td>
                            <input type="file" formControlName="attach" [formGroup]="addDocumentForm" (change)="fileChange($event)" [ngClass]="{'is-invalid': submitted && f.attach.errors }" />
                            <div *ngIf="submitted && f.attach.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.attach.errors.required">File &egrave; obbligatorio</div>
                            </div>
                        </td>
                        <td>
                            <span (click)="onSubmit()" class="pointer">
                    
                              &nbsp;&nbsp;&ensp;<i class="fas fa-upload pointer" ></i>
                  </span>
                        </td>
                    </tr>
                </tbody>
            </table>-->
            </form>
        </div>
        <div class="">
            <hr>
        </div>
        <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsNgx">
            <ng-template #btnDownloadTemplate let-row="row" let-value="value">
                <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="downloadDocument(row)" data-toggle="tooltip" data-placement="top" title="scarica">&nbsp;&nbsp;&ensp;<i class="fas fa-download pointer" ></i></a>
            </ng-template>

            <ng-template #btnDeleteTemplate let-row="row" let-value="value">
                <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="deleteDocument(row)" data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer" ></i></a>
            </ng-template>
        </ngx-datatable-wrapper>
    </div>
</div>