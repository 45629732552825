import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AuthService } from 'src/app/services';

@Component({
    selector: "progetto-div-dettaglio-component",
    templateUrl: "./progetto-div-dettaglio.component.html",
    styleUrls: ["./progetto-div-dettaglio.component.css"]
})
export class ProgettoDivDettaglioComponent implements OnInit, OnDestroy {

    projectId: number;
    interventoId:number;
    mainIntervento: any;

    @Input()
    set datiDettaglioProgetto(value) {
        if (!value) {
            this.valoreProgetto = '0';
            this.disponibilitaProgetto = '0';
            this.valoreIntervento = '0';
            this.disponibilitaIntervento = '0';
            this.progettoFinanziato = '0';
            this.progettoDaFinanziare = '0';
            this.interventoFinanziato = '0';
            this.interventoDaFinanziare = '0';
            this.descrizioneIntervento = '';
            this.codiceIntervento = '';
            this.progettoUtilizzato = '0';
            this.interventoId = null;
            this.projectId = 0;
            this.mainIntervento = null;
        }
        else {
            this.projectId = value.id;
            this.mainIntervento = value;
            this.calcolaValoreProgetto();
        }
    }

    @Input()
    set datiDettaglioIntervento(value) {
        if (!value) return;
        this.interventoId = value.interventoId;
        this.descrizioneIntervento = value.descrizione;
        this.codiceIntervento = value.codice;
        this.calcolaValoreIntervento(value);
    }

    constructor(
        private authService:AuthService
    ) { }

    ngOnInit() { }

    ngOnDestroy() { }

    printValueAsCurrency(value) {
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }

    valoreProgetto = '0';
    disponibilitaProgetto = '0';
    valoreIntervento = '0';
    disponibilitaIntervento = '0';
    progettoFinanziato = '0';
    progettoDaFinanziare = '0';
    interventoFinanziato = '0';
    interventoDaFinanziare = '0';
    descrizioneIntervento = '';
    codiceIntervento = '';
    progettoUtilizzato = '0';
    calcolaValoreProgetto() {
        if (!this.mainIntervento /*|| !this.mainIntervento['importi']*/) {
            this.valoreProgetto = '0';
            this.disponibilitaProgetto = '0';
            return;
        }
        let valore = 0;
        let disponibilita=0;
        /*this.mainIntervento['importi'].forEach(element => {
            valore += element.importo;
            disponibilita += element.disponibilita;
        });*/

        valore = this.mainIntervento.importoTotale?this.mainIntervento.importoTotale:0;
        disponibilita = this.mainIntervento.disponibilita?this.mainIntervento.disponibilita:0;

        this.valoreProgetto = valore.toString();
        this.disponibilitaProgetto = disponibilita.toString();
        let finanziato = 0;
        /*this.mainIntervento.finanziamenti.forEach(element => {
            element.annualita.forEach(annualita => {
                finanziato +=annualita.importo;
            });
        });*/
        finanziato = this.mainIntervento.finanziato?this.mainIntervento.finanziato:0;

        this.progettoFinanziato = finanziato.toString();
        this.progettoDaFinanziare = (valore - finanziato).toString();
        this.progettoUtilizzato = this.mainIntervento['importoFinanziamentiUtilizzato'].toString();
    }

    calcolaValoreIntervento(obj){
        let valoreIntervento = 0;
        this.valoreIntervento = '0';
        //console.log(`inside calcolaValoreIntervento`);
        if (!obj)return;
        valoreIntervento = obj.importoTotale?obj.importoTotale:0;
        let finanziato = obj.finanziato? obj.finanziato:0;

        /*if (!obj || !obj.importi)return;
        obj.importi.forEach(element => {
            valoreIntervento += element.importo;
        });*/
        this.valoreIntervento = valoreIntervento.toString();
        /*let finanziato = 0;
        obj.finanziamenti.forEach(element => {
            element.annualita.forEach(annualita => {
                finanziato +=annualita.importo;
            });
        });*/
        this.interventoFinanziato = finanziato.toString();
        this.interventoDaFinanziare = (valoreIntervento - finanziato).toString();
    }
    isAdmin(){
        return this.authService.isAdmin();
      }
      isGr34(){
        return this.authService.isGr34();
      }
}