import { CurrencyPipe } from '@angular/common';
import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    TemplateRef,
    Input,
    Output,
    EventEmitter,
    AfterViewInit,
    ElementRef,
    SimpleChanges
} from "@angular/core";
import { registerLocaleData } from '@angular/common';
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProgettoService, InterventoService, AreeTematicheService ,MotivazioneCancellazioneService,StorageService} from '../../services';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from '../../../../components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component'
import localeIt from '@angular/common/locales/it';
import { OrderPipe } from 'ngx-order-pipe';
import { AuthService } from 'src/app/services';
import { DashboardComponent } from "src/app/modules/web-gis/components/dashboard/dashboard.component";
registerLocaleData(localeIt, 'it');
defineLocale('it', itLocale);
import { IsDirtyHelper } from "../../../../helpers/is-dirty.helper"
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'interventi-component',
    templateUrl: './interventi.component.html',
    styleUrls: ['./interventi.component.css']
})
export class InterventiComponent implements OnInit, OnDestroy, AfterViewInit {
section:string='Interventi';
    ngOnDestroy(): void {
        this.clearSubscriptions();
        if(this.elencoAreeSubscription)this.elencoAreeSubscription.unsubscribe();
    }

    submitted: boolean = false;
    loading: boolean = false;
    loadDataCounter: number = 0;
    _interventoId: number;
    @Input()
    set interventoId(value: number) {
        this._interventoId = value;
        //if (this.readOnlyFormNoDetail)this.reloadAllData();
    }
    get interventoId() {
        return this._interventoId;
    }

    esistonoInterventi = false;

    projectId: number;

    dataProgetto: any;
    @Input()
    set dataProject(data) {
        this.projectId = data?.progettoId;
        this.dataProgetto = data;
    }
    get dataProject() {
        return this.dataProgetto;
    }

    modalSubscriptions = [];

    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnGeoTemplate', { static: true })
    public btnGeoTemplate: TemplateRef<any>;

    @ViewChild('direzioneFieldValueTemplate', { static: true })
    public direzioneFieldValueTemplate: TemplateRef<any>;

    @ViewChild('annualitaFieldValueTemplate', { static: true })
    public annualitaFieldValueTemplate: TemplateRef<any>;

    @ViewChild('btnCheckTemplate', { static: true })
    public btnCheckTemplate: TemplateRef<any>;

    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    @ViewChild('fieldNoteTemplate', { static: true })
    public fieldNoteTemplate: TemplateRef<any>;



    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    @ViewChild('extraFilterTemplate', { static: true })
    public extraFilterTemplate: TemplateRef<any>;

    @Input()
    filters: any;
    @Input()
    datiDettaglioProgetto: any;
    @Output()
    disableSearchEmitter = new EventEmitter<boolean>();

    templateList = [];

    dtOptions: any;
    dtOptionsSoggResp: any;
    rows: any[];
    columns = [
        {
            name: '', prop: 'id', sortable: true, filtrable: false, visible: false
            // 'buttonsTemplate'
        }

        , {
            name: 'codice'
            , prop: 'codice'
            , sortable: true
            , filtrable: true
            , minWidth: 85
            , flexGrow: 3
        }
        , {
            name: 'cup'
            , prop: 'cup'
            , sortable: true
            , filtrable: true
            , minWidth: 120
            , flexGrow: 4
            , visible: false

        }
        , {
            name: 'descrizione'
            , prop: 'descrizione'
            , sortable: true
            , filtrable: true
            , minWidth: 120
            , flexGrow: 5
        }
        , {
            name: 'aree gestionali'
            , prop: 'areeTematiche'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'direzioneFieldValueTemplate'
            , minWidth: 130
            , flexGrow: 4
        }
        , {
            name: 'valore'
            , prop: 'importi'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'annualitaFieldValueTemplate'
            , minWidth: 160
            , flexGrow: 4
        }
        , {
            name: 'validazione'
            , prop: 'validationStatus'
            , sortable: true
            , filtrable: true
            , cellTemplate: 'btnCheckTemplate'
            , flexGrow: 2
            , minWidth: 80
        }
        , {
            name: 'note'
            , prop: 'noteResponsabile'
            , sortable: true
            , filtrable: false
            , cellTemplate: 'fieldNoteTemplate'
            , flexGrow: 2
            , minWidth: 45
        }
        , {
            name: 'loc.'
            , prop: 'disegnato'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnGeoTemplate'// 'buttonsTemplate'
            //, width: 10
            , resizeable: false
            , draggable: false
            , flexGrow: 1
            , minWidth: 45
        }
        , {
            name: 'modifica'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , minWidth: 58
            , flexGrow: 2
        }
        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , minWidth: 58
            , flexGrow: 2
        }
    ];



    modalTitle: string = '';
    modalButtonLabel: string = '';

    modalRef: BsModalRef;

    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-xl"
    };
    ngAfterViewInit() {
        //this.ngxDatatableWrapper.dtOptions = this.ngxDatatableWrapper.dtOptions ;
        this.route.params.subscribe((params) => {
            const idIntervento = params["idIntervento"];

            idIntervento && this.onEdit({ id: idIntervento });
        });
        if (this.readOnlyFormNoDetail) this.reloadAllData();
    }

    ngOnChanges(changes: SimpleChanges) {
      switch (true) {
        case changes.filters !== undefined:
            setTimeout(() => {
                this.ngxDatatableWrapper.refresh();
            }, 0);
            break;
      }
    }

    selectedInterventoData: string;


    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;



    constructor(
        private el: ElementRef,
        private progettoService: ProgettoService
        , private toastr: ToastService
        , private modalService: BsModalService
        , private localeService: BsLocaleService
        , private interventoService: InterventoService
        , private orderPipe: OrderPipe
        , private authService: AuthService
        , private isDirtyHelper: IsDirtyHelper
        , private areeTematicheService: AreeTematicheService
        , private route: ActivatedRoute
        , private formBuilder: FormBuilder
        , private motivazioneCancellazioneService:MotivazioneCancellazioneService
        , private storageService:StorageService
    ) {
        this.deleteForm = this.initializeDeleteFormGroup();
        this.getMotivazioniCancellazione();
        this.getAreeTematiche();
        this.localeService.use("it");
        this.dtOptions = {
            rows: this.rows
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , scrollbarH: "true"
            , ajax: this.getInterventiDt
            , extraFilterTemplate: this.extraFilterTemplate
        };

    }


    ngOnInit() {
        this.templateList = [
            { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }
            , { name: 'btnEditTemplate', template: this.btnEditTemplate }
            , { name: 'direzioneFieldValueTemplate', template: this.direzioneFieldValueTemplate }
            , { name: 'annualitaFieldValueTemplate', template: this.annualitaFieldValueTemplate }
            , { name: 'btnCheckTemplate', template: this.btnCheckTemplate },
            { name: 'btnGeoTemplate', template: this.btnGeoTemplate }
            , { name: 'fieldNoteTemplate', template: this.fieldNoteTemplate }

        ];
        window.scroll(0, 0);
        this.dtOptions.extraFilterTemplate = this.extraFilterTemplate;
        // this.ngxDatatableWrapper.dtOptions = this.dtOptions;
        this.ngxDatatableWrapper ? this.ngxDatatableWrapper.dtOptions = this.dtOptions : null;
        if (this.readOnlyFormNoDetail) {
            this.columns[9].visible = false;
            this.columns[10].visible = false;
        }
    }

    @Input()
    readOnlyFormNoDetail: boolean = false;

    @Input()
    readOnlyForm: boolean = false;

    getInterventoData(id: number) {
        if (!id) return;
        this.interventoService.get(id).subscribe(
            result => {
                this.readOnlyForm = result.isReadOnly || !this.canEdit();
                if (this.readOnlyForm || this.readOnlyFormNoDetail) {
                    this.columns[10].visible = false;
                }
                if (this.readOnlyFormNoDetail) {
                    this.columns[9].visible = false;
                }
                this.reloadData();
                this.esistonoInterventi = false;
                if (result.isMain)
                    this.progettoService.getInterventiCount(result.progettoId).subscribe(
                        res => {
                            this.esistonoInterventi = res - 1 > 0;                
                        }
                    );
            }
        );
    }

    reloadAllData() {
        this.ngxDatatableWrapper.gridModel.data = [];
        this.getInterventoData(this._interventoId);
    }

    getInterventiByProjectId(id: number) {
        this.ngxDatatableWrapper.refresh();
        return;
        console.log(`into getInterventiByProjectId with id = ${id}`);
        if (!id) return;
        this.progettoService.getInterventi(id).subscribe(
            result => {
                result = result.filter(x => !x.isMain);//remove the main intervento
                this.ngxDatatableWrapper.setInitialData(result);
                //this.ngxDatatableWrapper.refresh()
                //this.ngxDatatableWrapper.dtOptions  = this.ngxDatatableWrapper.dtOptions;
                //console.log(`result= ${JSON.stringify(result)}`);
            }
        );
    }

    getInterventiDt = (dataTablesParameters: any, callback: any) => {
        if (!this.projectId && !this.filters) return;
        this.progettoService.getInterventiDt(this.projectId, dataTablesParameters, this.filters)
            .subscribe(resp => {
                callback(resp);
                this.disableSearchEmitter.emit(false);
            });
    }

    openAddModal() {
        this.selectedInterventoData = JSON.stringify({ interventoId: null, projectId: this.projectId, projectData: this.dataProgetto, dataProgetto: this.datiDettaglioProgetto, type: "pippo" });
        this.modalTitle = 'Nuovo intervento';
        this.modalRef = this.modalService.show(this.templateEditAdd, this.config);
    }

    getDatiDettaglioDalProgettoService(rowData, notOpenModal?: boolean){
        let progettoId = rowData.progettoId ? rowData.progettoId : rowData.id;
        this.progettoService.getDatiDettaglio(progettoId).subscribe(
            result =>{
                this.interventoService.getDatiDettaglio(rowData.id).subscribe(
                    resultIntervento=>{
                        resultIntervento['interventoId'] = rowData.id;
                        this.selectedInterventoData = JSON.stringify({ interventoId: rowData.id, projectId: this.projectId, cup: rowData.cup, projectData: this.dataProgetto, dataProgetto: result, dataIntervento: resultIntervento });
                        this.modalTitle = 'Modifica intervento';
                        if (!notOpenModal) this.modalRef = this.modalService.show(this.templateEditAdd, this.config);
                    }, error => {
                        this.toastr.error('Errore!');
                });
             }
        );
    } 

    onEdit(row, notOpenModal?: boolean) {
        if (this.readOnlyFormNoDetail) return;
        this.projectId = null;
        window.setTimeout(() => {
            this.projectId = row.progettoId ? row.progettoId : row.id;
        }, 10);

        if(!this.datiDettaglioProgetto)
            this.getDatiDettaglioDalProgettoService(row, notOpenModal);
        else{
            this.interventoService.getDatiDettaglio(row.id).subscribe(
                result=>{
                    result['interventoId'] = row.id;
                    this.selectedInterventoData = JSON.stringify({ interventoId: row.id, projectId: this.projectId, cup: row.cup, projectData: this.dataProgetto, dataProgetto: this.datiDettaglioProgetto, dataIntervento: result });
                    this.modalTitle = 'Modifica intervento';
                    if (!notOpenModal) this.modalRef = this.modalService.show(this.templateEditAdd, this.config);
                }, error => {
                    this.toastr.error('Errore!');
                });
        }
    }

    onDelete(row) {
        console.log(row);
        if (this.readOnlyFormNoDetail) return;
        if (row.isReadOnly) return;
        this.openConfirmDeleteModal(row);
        return;
        if (!confirm(`Stai per eliminare l'intervento "${row['cup']} - ${row.annoRiferimento} - ${row.descrizione}", proseguire?`)) return;
        this.loading = true;
        let myService = this.interventoService;
        let currentId = this.projectId;
        myService.delete(row.id).subscribe(
            result => {
                this.loading = false;
                this.ngxDatatableWrapper.deleteRow(row);
                this.toastr.success('Intervento eliminato con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                this.onInterventoDatiGeneraliSaved.emit(null);
            });
    }

    clearSubscriptions() {
        this.modalSubscriptions.forEach(x => x.unsubscribe());
        this.modalSubscriptions = [];
    }

    closeModal(data: any) {
        if (!this.isDirtyHelper.checkIsDirty()) return false;
        this.modalRef.hide();
        this.getInterventiByProjectId(this.projectId);
    }

    datiGeneraliSaved(data) {
        console.log(`inside datiGeneraliSavedInterventi with : ${JSON.stringify(data)}`);
        this.ngxDatatableWrapper.refresh();
        //let rows = this.ngxDatatableWrapper.originalRows;
        //if (rows.find(x=>x.id == data.id))this.ngxDatatableWrapper.updateRow(data);
        //else
        //{
        //    this.ngxDatatableWrapper.addRow(data);
        this.onEdit(data, true);
        //}
        this.onInterventoDatiGeneraliSaved.emit(data);
    }
    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
    gotoBackBtnClick() {
        this.gotoBtnFunction.emit(true);
    }

    @Output()
    onInterventoDatiGeneraliSaved: EventEmitter<any> = new EventEmitter();


    printDirezioneFieldValue(data) {
        let str = '';
        let arr = [];
        if (data && data != '') {
            //let obj = JSON.parse(data);
            for (let o in data) {
                let str1 = data[o]["descrizione"];
                if (data[o]["disabled"])str1 = `(disattiva) ${str1}`;
                arr.push(str1);
            }
            str = arr.join('<br />');
        }
        return arr;
    }

    printValueAsCurrency(value) {
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }

    calcolaValoreIntervento(row: any) {

        if (!row || !row['importi']) return this.printValueAsCurrency(0);
        let valore = 0;
        row['importi'].forEach(element => {
            valore += element['importo'];
        });
        return this.printValueAsCurrency(valore);
    }

    sortImporti(arr) {
        return this.orderPipe.transform(arr, 'anno');
    }

    reloadData() {
        if (this.projectId) this.getInterventiByProjectId(this.projectId);
    }

    isAdmin() {
        return this.authService.isAdmin();
    }
    isDirectionalGroup() {
        return this.authService.isDirectionalGroup();
    }
    isAreaStaff() {
        return this.authService.isAreaStaff();
    }
    isExternal() {
        return this.authService.isExternal();
    }
    isViewer() {
        return this.authService.isViewer();
    }
    isGr34(){
        return this.authService.isGr34();
    }

    printBtnValidazioneTitle(value) {
        let result = '';
        switch (value) {
            case '':
            case 'IDLE':
            case 'REJECT':
                if (this.isAdmin())
                    result = 'clicca per validare';
                else
                    result = 'clicca per richiedere la validazione';
                break;
            case 'APPROVED':
            case 'TERMINATO':
                if (this.isAdmin()) result = 'clicca per svalidare';
                break;
            case "WAITING_FOR_APPROVAL_FIRST_LEVEL":
                if (!(this.isAreaStaff() || this.isExternal())) result = 'clicca per validare';
                break;
            case "WAITING_FOR_APPROVAL_SECOND_LEVEL":
                if (this.isAdmin())
                    result = 'clicca per validare';
                break;

        }
        return result;
    }

    onRichiediValidazione(row: any) {
        if (row.isReadOnly) return;
        if (this.readOnlyFormNoDetail) return;
        let status = row.validazione ? (row.validazione.validationStatus ? row.validazione.validationStatus : 0) : 0;
        let msg = '';
        let action = false;
        //status =0 posso richiede approvazione
        switch (status.toString().toUpperCase()) {
            case '0':
            case 'IDLE':
            case 'REJECTED':
            case '3':
                if (this.isAdmin()) msg = `Stai per validare l'intervento ${row.codice} - ${row.descrizione}, proseguire?`;
                else msg = `Stai per richiedere la validazione dell'intervento ${row.codice} - ${row.descrizione}, proseguire?`;
                action = true;
                break;
            case '1':
            case 'APPROVED':
            case 'TERMINATO':
                if (this.isAdmin()) msg = `Stai per annullare la validazione dell'intervento ${row.codice} - ${row.descrizione}, proseguire?`;
                else return;//msg = `Stai per richiedere l'annullamento della validazione del finanziamento ${row.titolo}, proseguire?`;
                action = false;
                break;
            case '4':
            case 'WAITING_FOR_APPROVAL_FIRST_LEVEL':
                if (this.isAreaStaff() || this.isExternal()) return;
                msg = `Stai per validare l'intervento ${row.codice} - ${row.descrizione}, proseguire?`;
                action = true;
                break;
            case 5:
            case 'WAITING_FOR_APPROVAL_SECOND_LEVEL':
                if (!this.isAdmin()) return;
                msg = `Stai per validare l'intervento ${row.codice} - ${row.descrizione}, proseguire?`;
                action = true;
                break;
            /*case 6:
                if (!this.isAdmin())return;
                msg = `Stai per annullare la validazione del finanziamento ${row.titolo}, proseguire?`;
                action=false;
                break;*/
            default:
                return;
        }
        if (!confirm(msg)) return;
        this.interventoService.manageValidation(row.id, action).subscribe(
            result => {
                this.reloadData();
                let msg = '';
                if ((this.isExternal() || this.isAreaStaff()) && action) msg = 'Richiesta di validazione avvenuta con successo';
                if ((this.isAdmin() || this.isDirectionalGroup()) && action) msg = 'Validazione avvenuta con successo';
                if (this.isAdmin() && !action) msg = 'Validazione annullata con successo';
                this.toastr.success(msg, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                if (result.warningMessage && result.warningMessage != '') {
                    this.toastr.warning(result.warningMessage, null, { timeOut: 10000, disableTimeOut: false });
                }

            }
            , error => {
                //this.toastr.error(`${JSON.stringify(error)}`);
                this.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );
    }
    isReadOnly(info: any): boolean {
        //controllo se l'utente può modificare il progetto
        //let user = this.authService.getCurrentUser();
        return info.isReadOnly;
    }

    closeModalEmitterSubscribe = null;
    onGeolocalizza(row) {
        if (this.readOnlyFormNoDetail || row.nonLocalizzabile) return;
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xxl",
        };
        const initialState = {
            interventoId: row.id,

            isProjectMode: true,
            descrizione: row.descrizione,

        };
        this.modalRef = this.modalService.show(
            DashboardComponent,
            Object.assign({}, config, { initialState })
        );
        if (this.closeModalEmitterSubscribe) this.closeModalEmitterSubscribe.unsubscribe();
        this.closeModalEmitterSubscribe = this.modalRef.content.closeModalEmitter.subscribe(
            data => {
                this.reloadData();
            }
        );
    }
    areeTematicheCombo = [];
    private elencoAreeSubscription = null;
    getAreeTematiche() {       
        this.elencoAreeSubscription = this.storageService.elencoAreeTematiche.subscribe(
            x=>{
                console.log(x);
                this.areeTematicheCombo = x?x:[];
            }
            );
    }

    filterAreaTematica($event) {
        let descr = $event ? $event.descrizione : '';
        if (descr.startsWith('(disattiva)')){
            descr = descr.replace('(disattiva)','');
        }
        this.ngxDatatableWrapper.originalColumns[4].search = { value: descr, regex: false };
        this.ngxDatatableWrapper.dtInputParams.columns[4].search = { value: descr, regex: false };
        this.ngxDatatableWrapper.refresh();
        //this.filterArea = $event ? $event.id : -1;
        //this.filterInterventions(false);
    }

    //modale conferma cancellazione
    @ViewChild('modalConfirmDelete', { static: true })
    public modalConfirmDelete: TemplateRef<any>;

    modalRefCancellazione: BsModalRef;

    deleteRowTitle = '';
    deleteForm: FormGroup;
    deletedRow = null;

    initializeDeleteFormGroup(): FormGroup {
        let frm = this.formBuilder.group({
           motivazione: [null, [Validators.required]],
            txtMotivazioneCancellazione: [null],
        });
        return frm;
    }


    openConfirmDeleteModal(row) {
        this.submitted = false;
        this.deletedRow = row;
        this.deleteRowTitle = `"${row['cup']} - ${row.annoRiferimento} - ${row.descrizione}"`;
        this.deleteForm.reset();
        let config = {
            animated: true,
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-lg"
        };
        this.modalRefCancellazione = this.modalService.show(this.modalConfirmDelete, config);
    }

    closeConfirmDeleteModal() {
        this.modalRefCancellazione.hide();
    }

    doDelete() {
        this.submitted = true;
        this.deleteForm.updateValueAndValidity();
        for (let c in this.deleteForm.controls) {
            this.deleteForm.controls[c].updateValueAndValidity();
        }
        let errors = false;
        for (let c in this.deleteForm.controls) {
            errors = errors || this.deleteForm.controls[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.deleteForm.controls[c].errors)
            );
        }



        if (errors || this.deleteForm.invalid) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.deleteForm.controls) {
                if (this.deleteForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.deleteForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.deleteForm.get(el)["controls"]) {
                            if (this.deleteForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }
        //no errors
        let obj = this.deleteForm.value;
        if (obj.motivazione =='Altro')obj.motivazione =obj.txtMotivazioneCancellazione;
        this.closeConfirmDeleteModal();
        //return;
        //if (!confirm(`Stai per eliminare l'intervento "${row['cup']} - ${row.annoRiferimento} - ${row.descrizione}", proseguire?`)) return;
        this.loading = true;
        let myService = this.interventoService;
        let currentId = this.projectId;
        myService.delete(this.deletedRow.id,obj.motivazione).subscribe(
            result => {
                this.loading = false;
                this.ngxDatatableWrapper.deleteRow(this.deletedRow);
                this.toastr.success('Intervento eliminato con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                this.onInterventoDatiGeneraliSaved.emit(null);
            });


    }
    motivazioniCancellazione = [];
     getMotivazioniCancellazione(){
        this.motivazioneCancellazioneService.getAll().subscribe(
            result=>{
                result.push({descrizione:'Altro'});
                this.motivazioniCancellazione = result;
            }
            );
     }
     motivazioneCancellazioneSelected = null;
     setMotivazioneCancellazione($event){
         this.motivazioneCancellazioneSelected =$event? $event.descrizione:null;
         if (this.motivazioneCancellazioneSelected && this.motivazioneCancellazioneSelected=='Altro'){
            this.deleteForm.controls.txtMotivazioneCancellazione.setValidators(Validators.required);
         }
         else{
            this.deleteForm.controls.txtMotivazioneCancellazione.clearValidators();
         }
     }

     canEdit() {
        return (this.enableSection(this.section, ['update']));
    }


    /**
* Controlla se l'utente può accedere o meno alla sezione.
* @param section il nome della sezione
* @param operations le operazioni da poter effettuare sulla sezione
* @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
* @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
* @returns true se il controllo è andato a buon fine, false altrimenti
*/
    enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
        return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
    }
}
