import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MenuService } from "src/app/services/menu.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastService } from "src/app/services";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { GestioneMenu } from "src/app/models/menu";
import { NgxDatatableWrapperComponent } from "src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-gestione-menu",
  templateUrl: "./gestione-menu.component.html",
  styleUrls: ["./gestione-menu.component.css"],
})
export class GestioneMenuComponent implements OnInit {
  modalRef: BsModalRef;
  loading: boolean = false;
  open: boolean = false;
  dtOptions: any;
  config = {
    animated: true,
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-lg",
  };

  constructor(
    private menuService: MenuService,
    private toastr: ToastService,
    private modalService: BsModalService,
    private el: ElementRef
  ) {
    this.dtOptions = {
      rows: [],
      totalElements: 0,
      columns: this.columns,
      columnMode: ColumnMode.flex,
      headerHeight: "50",
      footerHeight: "50",
      rowHeight: "auto",
      externalSorting: false, // true
      loadingIndicator: "loanding",
      class: "bootstrap",
      summaryRow: true,
      summaryPosition: "'bottom'",
      externalPaging: false, // true
      count: 0,
      offset: 0,
      limit: 10,
      serverSide: false,
      scrollbarH: "true",
    };
  }

  @ViewChild("ngxDatatableWrapper")
  ngxDatatableWrapper: NgxDatatableWrapperComponent;

  @ViewChild("templateEditAdd", { static: true })
  public templateEditAdd: TemplateRef<any>;

  @ViewChild("templateModal", { static: true })
  public templateModal: TemplateRef<any>;

  @ViewChild("btnDeleteTemplate", { static: true })
  public btnDeleteTemplate: TemplateRef<any>;

  @ViewChild("btnEditTemplate", { static: true })
  public btnEditTemplate: TemplateRef<any>;

  templateList = [];

  columns = [
    {
      name: "id",
      prop: "id",
      visible: false,
      sortable: true,
      filtrable: true,
      width: 10,
    },
    {
      name: "codice",
      prop: "code",
      sortable: true,
      filtrable: true,
      flexGrow: 14,
      minWidth: 100,
    },
    {
      name: "commento",
      prop: "comment",
      sortable: true,
      filtrable: true,
      flexGrow: 14,
      minWidth: 100,
      visible: false,
    },
    {
      name: "valore",
      prop: "value",
      visible: false,
      sortable: true,
      filtrable: true,
      flexGrow: 14,
      minWidth: 100,
    },
    {
      name: "modifica",
      prop: "id",
      sortable: false,
      filtrable: false,
      cellTemplate: "btnEditTemplate", // 'buttonsTemplate'
      flexGrow: 1,
      minWidth: 60,
    },
    {
      name: "elimina",
      prop: "id",
      sortable: false,
      filtrable: false,
      cellTemplate: "btnDeleteTemplate", // 'buttonsTemplate'
      flexGrow: 1,
      minWidth: 60,
    },
  ];

  menus: GestioneMenu[];

  ngOnInit() {
    this.getAll();
    this.initializeFormGroup();
    this.templateList = [
      { name: "btnDeleteTemplate", template: this.btnDeleteTemplate },
      { name: "btnEditTemplate", template: this.btnEditTemplate },
    ];
  }

  getAll() {
    this.menuService.getAllMenu().subscribe((result) => {
      console.log(result);
      this.ngxDatatableWrapper.setInitialData(result);
    });
  }

  buttonLabel: string = "Inserisci";
  editForm: FormGroup = null;
  submitted: boolean = false;

  get f() {
    return this.editForm.controls;
  }

  initializeFormGroup() {
    this.editForm = new FormGroup({
      id: new FormControl(null),
      code: new FormControl("", [Validators.required]),
      comment: new FormControl(""),
      value: new FormControl("", [Validators.required]),
    });
  }

  onSubmit() {
    this.submitted = true;
    this.editForm.updateValueAndValidity();
    let errors = false;

    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }
    if (this.editForm.invalid || errors) {
      console.log("invalid");
      let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
      for (let el in this.editForm.controls) {
        if (this.editForm.controls[el].invalid) {
          console.log(`set focus on ${el}`);
          if (this.editForm.controls[el] instanceof FormGroup) {
            for (let el1 in this.editForm.get(el)["controls"]) {
              if (this.editForm.get(el)["controls"][el1].invalid) {
                invalidElements = this.el.nativeElement.querySelectorAll(
                  `[formcontrolname="${el1}"]`
                );
                break;
              }
            }
          } else {
            invalidElements = this.el.nativeElement.querySelectorAll(
              `[formcontrolname="${el}"]`
            );
          }
          break;
        }
      }
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }

      return false;
    }

    const obj = this.editForm.value;
    if (!obj.code)obj.code = this.editForm.getRawValue().code;

    let functionOk = (result) => {
      this.toastr.success("Menù salvato con successo!");
      this.submitted = false;
      this.open = false;
      this.editForm.reset();
      this.buttonLabel = "Inserisci";

      if (!obj.id) {
        //new row
        this.ngxDatatableWrapper.addRow(result);
      } else {
        this.modalRef.hide();
        this.ngxDatatableWrapper.updateRow(obj);
      }
    };

    let functionError = (error) => {
      if (error.error && error.error.message) {
        this.toastr.error(`Errore: ${error.error.message}`);
      } else {
        this.toastr.error("Si è verificato un errore interno");
      }
    };

    if (obj.id)
      this.menuService.updateMenu(obj).subscribe(functionOk, functionError);
    else this.menuService.insertMenu(obj).subscribe(functionOk, functionError);
  }

  onEdit(row) {
    this.submitted = false;
    console.log(row);
    this.buttonLabel = "Aggiorna";
    this.modalRef = this.modalService.show(this.templateModal, this.config);
    this.editForm.setValue({
      id: row.id,
      code: row.code,
      value: row.value,
      comment: row.comment,
    });
    if (row.code.toLowerCase() == "generale") {
      this.editForm.get("code").disable();
    } else {
      this.editForm.get("code").enable();
    }
  }

  onDelete(row) {
    console.log(row);
    if (!confirm(`Stai per eliminar il menu "${row.code}", proseguire ?`))
      return;
    this.loading = true;
    this.menuService.deleteMenu(row.id).subscribe(
      (result) => {
        this.loading = false;
        this.ngxDatatableWrapper.deleteRow(row);
        this.toastr.success("Menu eliminato con successo", null, {
          timeOut: 2000,
          disableTimeOut: false,
        });
      },
      (error) => {
        this.loading = false;
        if (error.error && error.error.message) {
          this.toastr.error(`Errore: ${error.error.message}`);
        } else {
          this.toastr.error("Si è verificato un errore interno");
        }
      }
    );
  }

  closeModal() {
    this.modalRef.hide();
    this.buttonLabel = "Inserisci";
    this.editForm.reset();
    this.editForm.get("code").enable();
  }
}
