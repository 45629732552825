import { Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { TipologiaInterventiService } from '../../services/tipologia-interventi.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NaturaTipologieService } from '../../services/natura-tipologie.service';
import { NaturaTipologia } from '../../models/natura-tipologia';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services';
import { TipologiaIntervento } from '../../models/tipologia-intervento';
import { IsDirtyHelper } from 'src/app/helpers/is-dirty.helper';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageHelper } from 'src/app/helpers/image.helper';

@Component({
  selector: 'app-gestione-tipologia-interventi',
  templateUrl: './gestione-tipologia-interventi.component.html',
  styleUrls: ['./gestione-tipologia-interventi.component.css']
})
export class GestioneTipologiaInterventiComponent implements OnInit {

  dtOptions: any = {};
  visible = false;
  disegnabile = false;

  submitted: boolean = false;
  modalRef: BsModalRef;
  open: boolean = false;

  isEditingIcona: boolean = false;

  addTipologiaInterventoForm: FormGroup;

  naturaTipologie: NaturaTipologia[];
  naturaTipologieSelected: NaturaTipologia;

  templateList = [];

  attach: any;

  accordionIsOpen: boolean = false;

  iconaFilePath: string = null;

  iconaBase64: string = null;

  iconFileExtensionIsValid: boolean = true;

  @ViewChild('templateEditAdd', { static: true })
  public templateEditAdd: TemplateRef<any>;
  @ViewChild('templateModal', { static: true })
  public templateModal: TemplateRef<any>;

  @ViewChild('btnEditTemplate', { static: true })
  public btnEditTemplate: TemplateRef<any>;

  @ViewChild('btnDeleteTemplate', { static: true })
  public btnDeleteTemplate: TemplateRef<any>;

  @ViewChild('columnDisegnabileTemplate', { static: true })
  public columnDisegnabileTemplate: TemplateRef<any>;

  @ViewChild('columnNaturaTipologiaTemplate', { static: true })
  public columnNaturaTipologiaTemplate: TemplateRef<any>;

  @ViewChild('columnIconaTemplate', { static: true })
  public columnIconaTemplate: TemplateRef<any>;

  @ViewChild('ngxDatatableWrapper')
  ngxDatatableWrapper: NgxDatatableWrapperComponent;

  @Output()
  onSubmitEmitter: EventEmitter<any> = new EventEmitter();

  columns = [
    {
      name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
    }
    , {
      name: 'codice'
      , prop: 'codice'
      , sortable: true
      , filtrable: true
      , flexGrow: 3
      , minWidth: 170
    },
    {
      name: 'descrizione'
      , prop: 'descrizione'
      , sortable: true
      , filtrable: true
      , flexGrow: 5
      , minWidth: 100
    }
    ,
    {
      name: 'natura'
      , prop: 'naturaTipologia'
      , sortable: true
      , filtrable: true
      , cellTemplate: 'columnNaturaTipologiaTemplate'// 'buttonsTemplate'
      , flexGrow: 7
      , minWidth: 100
    },
    {
      name: 'disegnabile'
      , prop: 'disegnabile'
      , sortable: true
      , filtrable: false
      , cellTemplate: 'columnDisegnabileTemplate'// 'buttonsTemplate'
      , flexGrow: 3
      , minWidth: 100
    },
    {
      name: 'icona'
      , prop: 'icona'
      , sortable: true
      , filtrable: false
      , cellTemplate: 'columnIconaTemplate'
      , flexGrow: 3
      , minWidth: 80
    },
    {
      name: 'note'
      , prop: 'note'
      , sortable: true
      , filtrable: true
      , flexGrow: 7
      , minWidth: 100
    },
    {
      name: 'modifica'
      , prop: 'id'
      , sortable: false
      , filtrable: false
      , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
      , flexGrow: 1
      , minWidth: 60

    }
    , {
      name: 'elimina'
      , prop: 'id'
      , sortable: false
      , filtrable: false
      , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
      , flexGrow: 1
      , minWidth: 60
    }
  ];

  constructor(
    private isDirtyHelper: IsDirtyHelper,
    private formBuilder: FormBuilder,
    private el: ElementRef,
    private toastr: ToastService,
    private modalService: BsModalService,
    private localeService: BsLocaleService,
    private naturaTipologieService: NaturaTipologieService,
    private tipologiaInterventiService: TipologiaInterventiService,
    private imageHelper: ImageHelper
  ) {
    this.localeService.use("it");
    this.dtOptions = {
      rows: []
      , totalElements: 0
      , columns: this.columns
      , columnMode: ColumnMode.flex
      , headerHeight: "50"
      , footerHeight: "50"
      , rowHeight: "auto"
      , externalSorting: false// true
      , loadingIndicator: 'loanding'
      , class: "bootstrap"
      , summaryRow: true
      , summaryPosition: "'bottom'"
      , externalPaging: false// true
      , count: 0
      , offset: 0
      , limit: 10
      , serverSide: false
      , scrollbarH: "true"
    };
  }

  setIsDirty(value: boolean) {
    this.isDirtyHelper.isDirty = value;
  }

  ngOnInit(): void {
    this.templateList = [{ name: 'btnDeleteTemplate', template: this.btnDeleteTemplate },
    { name: 'btnEditTemplate', template: this.btnEditTemplate },
    { name: 'columnDisegnabileTemplate', template: this.columnDisegnabileTemplate },
    { name: 'columnNaturaTipologiaTemplate', template: this.columnNaturaTipologiaTemplate },
    { name: 'columnIconaTemplate', template: this.columnIconaTemplate }

    ];
    this.getNaturaTipologie();
    window.scroll(0, 0);

    this.addTipologiaInterventoForm = this.initializeFormGroup();
    this.addTipologiaInterventoForm.valueChanges.subscribe(val =>
      this.setIsDirty(this.addTipologiaInterventoForm.dirty)
    );
    this.getTipologiaInterventi();
  }

  initializeFormGroup() {
    return this.formBuilder.group({
      id: [null],
      codice: ['', [Validators.required, Validators.maxLength(255)]],
      descrizione: ["", [Validators.required, Validators.maxLength(1048)]],
      disegnabile: [false],
      icona: ["", [Validators.pattern('.*\.png$')]],
      note: [""],
      idNaturaTipologia: [null, [Validators.required]],
    });
  }

  isFileType(filename: string, type: string) {
    const extension = filename.split('.')[1].toLowerCase();
    return type.toLowerCase() === extension.toLowerCase();
  }

  getSrcForImageRow(row: any) {
    let image = this.tipologiaInterventiService.getAssetIcon(row)
    if (!image.endsWith('.png')) {
      return this.imageHelper.sanitizeUri(image)
    }
    return image;
  }

  getSrcForImage() {
    return this.iconaBase64 ? this.imageHelper.renderSanitizedBase64Image(this.iconaBase64) : this.tipologiaInterventiService.DEFAULT_TIPOLOGIA_ICON
  }

  get f() {
    return this.addTipologiaInterventoForm.controls;
  }

  onEditingIcona() {
    this.isEditingIcona = !this.isEditingIcona;
    this.iconFileExtensionIsValid = true;
  }

  onIconReset() {

    if (!confirm(`Stai per ripristinare l'icona di default, confermi?`)) return;

    this.f.icona.setValue(null);
    this.iconaFilePath = null;
    this.isEditingIcona = false;
    this.iconaBase64 = null;
    this.attach = new File([""], "");
    this.addTipologiaInterventoForm.updateValueAndValidity();
    this.setIsDirty(true);
  }

  onIconUpload(event) {
    this.iconFileExtensionIsValid = true;

    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      if (!this.isFileType(file.name, 'png')) {
        event.target.files = null;
        event.target.value = null;
        this.iconFileExtensionIsValid = false;
        return;
      }
      this.setIsDirty(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let result = reader.result as string;
        if (result) {
          let dataUrl = result.split(",")[1]
          this.iconaBase64 = dataUrl;
        }
      }
      this.attach = file;
    }

    const formData: FormData = new FormData();
    formData.append("file", this.attach, this.attach.name);

    this.tipologiaInterventiService
      .uploadTempFile(formData)
      .subscribe((res) => {

        this.f.icona.setValue(res.originalFileName);
        this.iconaFilePath = res.filePath;
        this.isEditingIcona = false;
        this.setIsDirty(true);

      }
        , error => {
          event.target.files = null;
          event.target.value = null;
          this.toastr.error(`Errore nel caricare l'icona`, null, {
            timeOut: 4000,/*closeButton:true,*/ disableTimeOut: false
          });
          console.log('error');
        });
  }

  refreshGrid() {
    if (this.ngxDatatableWrapper) {
      this.ngxDatatableWrapper.dtOptions.columns = this.columns;
      this.ngxDatatableWrapper.dtOptions = this.ngxDatatableWrapper.dtOptions;
      this.ngxDatatableWrapper.refresh();
    }
  }

  onChangeIdNaturaTipologia($event: NaturaTipologia) {
    this.naturaTipologieSelected = $event;
    this.iconFileExtensionIsValid = true;
  }

  onRemoveNaturaTipologia($event: NaturaTipologia) {
    this.naturaTipologieSelected = null
    this.iconFileExtensionIsValid = true;
  }

  private validateForm() {
    this.addTipologiaInterventoForm.updateValueAndValidity();
    let errors = false;
    if (!this.iconFileExtensionIsValid) {
      return false;
    }
    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
    }
    if (this.addTipologiaInterventoForm.invalid || errors) {

      let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
      for (let el in this.addTipologiaInterventoForm.controls) {
        if (this.addTipologiaInterventoForm.controls[el].invalid) {

          if (this.addTipologiaInterventoForm.controls[el] instanceof FormGroup) {
            for (let el1 in this.addTipologiaInterventoForm.get(el)["controls"]) {
              if (this.addTipologiaInterventoForm.get(el)["controls"][el1].invalid) {
                invalidElements = this.el.nativeElement.querySelectorAll(
                  `[formcontrolname="${el1}"]`
                );
                break;
              }
            }
          } else {
            invalidElements = this.el.nativeElement.querySelectorAll(
              `[formcontrolname="${el}"]`
            );
          }
          break;
        }
      }
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }

      return false;
    }
    return true;
  }


  onSubmit() {
    this.submitted = true;

    if (!this.validateForm()) {
      return false;
    }

    if (!this.iconFileExtensionIsValid) {
      let ris = confirm("File icona non correttamente caricato (non verra' salvata alcuna modifica), proseguire ?");
      if (!ris) return false;
    }

    this.iconFileExtensionIsValid = true;

    const obj: TipologiaIntervento = new TipologiaIntervento();
    obj.codice = this.f.codice.value;
    obj.descrizione = this.f.descrizione.value;
    obj.icona = this.f.icona.value;
    obj.note = this.f.note.value;
    obj.idNaturaTipologia = this.naturaTipologieSelected ? this.naturaTipologieSelected.id : null;
    obj.naturaTipologia = this.naturaTipologieSelected ? this.naturaTipologie.find(x => x.id == obj.idNaturaTipologia) : null;
    obj.id = this.f.id.value;
    obj.iconaFilePath = this.iconaFilePath;
    obj.iconaFileBase64 = this.iconaBase64
    let disegnabile = this.f.disegnabile.value;

    this.tipologiaInterventiService.save(obj).subscribe(res => {
      this.setIsDirty(false);
      this.naturaTipologieSelected = null;
      this.toastr.success("Tipologia intervento salvata con successo!");
      this.submitted = false;
      this.open = false;
      this.isEditingIcona = false;
      this.iconaFilePath = null;
      this.addTipologiaInterventoForm.reset();

      this.buttonLabel = "Inserisci";
      if (!obj.id) {
        //new row
        this.ngxDatatableWrapper.addRow(res);
      }
      else {
        this.ngxDatatableWrapper.updateRow({ disegnabile: disegnabile, ...obj });
      }
      this.closeModal();
      this.accordionIsOpen = false;

    }, error => {
      this.toastr.error(`Impossibile salvare la tipologia: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`);
    });
  }



  buttonLabel: string = "Inserisci";
  loading: boolean = false;
  // classificazioneId:any=null;
  isInInsert: boolean = true;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-lg"
  };

  onEdit(row) {

    this.accordionIsOpen = false;
    this.setIsDirty(false);
    this.isInInsert = false;
    this.submitted = false;
    this.disegnabile = row.disegnabile;
    this.buttonLabel = "Aggiorna";
    this.iconaFilePath = null;
    this.isEditingIcona = false;
    this.modalRef = this.modalService.show(this.templateModal, this.config);
    this.iconaBase64 = row.iconaFileBase64;
    this.iconFileExtensionIsValid = true;
    this.addTipologiaInterventoForm.setValue({
      id: row.id,
      codice: row.codice,
      descrizione: row.descrizione,
      icona: row.icona,
      note: row.note,
      disegnabile: row.disegnabile,
      idNaturaTipologia: row.naturaTipologia.id,
    });
    this.naturaTipologieSelected = row.naturaTipologia;
  }



  onDelete(row) {

    if (!confirm(`Stai per eliminare la tipologia di intervento "${row.codice}", proseguire?`)) return;
    this.loading = true;
    this.tipologiaInterventiService.delete(row.id).subscribe(
      result => {
        this.loading = false;
        this.ngxDatatableWrapper.deleteRow(row);
        this.toastr.success('Tipologia eliminata con successo', null, {
          timeOut: 4000,/*closeButton:true,*/ disableTimeOut: false
        });
      }
      , error => {
        this.loading = false;
        this.toastr.error(`Impossibile eliminare la tipologia: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
          timeOut: 4000,/*closeButton:true,*/ disableTimeOut: false
        });

      });
  }


  closeModal() {
    if (!this.isDirtyHelper.checkIsDirty()) {
      return false;
    }
    this.setIsDirty(false);
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.addTipologiaInterventoForm.reset();
    this.buttonLabel = "Inserisci";
    this.naturaTipologieSelected = null;
    this.isInInsert = true;
    this.disegnabile = false;
    this.isEditingIcona = false;
    this.iconaFilePath = null;
    this.iconFileExtensionIsValid = true;
    this.iconaBase64 = null;
  }

  getTipologiaInterventi() {
    this.tipologiaInterventiService.getTipologiaInterventi().subscribe(
      (result) => {
        this.ngxDatatableWrapper.dtOptions = this.dtOptions;
        this.ngxDatatableWrapper.setInitialData(result);
      },
      (error) => {
        console.log(error)
        this.toastr.error(`Errore nel recuperare le tipologie di intervento`, null, {
          timeOut: 4000,/*closeButton:true,*/ disableTimeOut: false
        });
      }
    );

  }

  getNaturaTipologie() {
    this.naturaTipologieService.getNaturaTipologie().subscribe(
      (result) => {
        if (result) {
          result.sort((a, b) => a.codice < b.codice ? -1 : 1);
          this.naturaTipologie = result;
        }
      },
      (error) => {
        console.log(error)
        this.toastr.error(`Errore nel recuperare le nature di tipologie di intervento`, null, {
          timeOut: 4000,/*closeButton:true,*/ disableTimeOut: false
        });
      }
    )
  }


}
