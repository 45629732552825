import {
    Component
} from "@angular/core";

@Component({
    templateUrl: "./dashboard-pnrr-pubblica-bis.component.html",
    styleUrls: ["./dashboard-pnrr-pubblica-bis.component.css"]
})
export class DashboardPnrrPubblicaBisComponent {
    constructor() { }

}
