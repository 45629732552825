import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    ElementRef,
    TemplateRef
} from "@angular/core";
import { registerLocaleData } from '@angular/common';
import { FormGroup, FormBuilder } from "@angular/forms";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
      ClassificazioneService
    , TipoClassificazioneService, TemaPrioritarioService
    , ProgettoService, InterventoService,StorageService
} from '../../services';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { ColumnMode } from '@swimlane/ngx-datatable';
import localeIt from '@angular/common/locales/it';
import { GenericSelectionModalComponent } from '../genericSelectionModal/generic-selection-modal.component';
import { Subject } from "rxjs";
registerLocaleData(localeIt, 'it');
defineLocale('it', itLocale);
import IMask from 'imask';
import { debounceTime, map } from "rxjs/operators";

@Component({
    selector: 'gestione-massiva-classificazioni-component',
    templateUrl: './gestione-massiva-classificazioni.component.html',
    styleUrls: ['./gestione-massiva-classificazioni.component.css']
})
export class GestioneMassivaClassificazioniComponent implements OnInit, OnDestroy {

    public mask: any = {
        mask: 'Z KK',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '',
                scale: 0,
                padFractionalZeros: false,
                radix: ','
            },
            Z: {
                mask: IMask.MaskedEnum,
                enum: ['+', '-']
            }
        }
    };

    ngOnDestroy(): void {
        this.clearSubscriptions();
        if(this.elencoAreeSubscription)this.elencoAreeSubscription.unsubscribe();
    }

    submitted: boolean = false;
    loading: boolean = false;
    readOnlyForm: boolean = false;

    projectId: number;

    modalSubscriptions = [];
    //@ViewChild('buttonsTemplate',{static:false}) buttonsTemplate: TemplateRef<any>;




    modalTitle: string = '';
    modalButtonLabel: string = '';

    modalRef: BsModalRef;
    modalRefSogResp: BsModalRef;

    config = {
        animated: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg"
    };

    tipologieClassificazioni = [];
    temiPrioritariCombo = [];

    editForm: FormGroup;
    filterForm: FormGroup;

    classificazioni = [];

    templateListSogResp = [];
    allProjects = [];

    soggettoResponsabileIsError: boolean = false;

    @ViewChild('btnSelectSoggResp', { static: true })
    public btnSelectSoggResp: TemplateRef<any>;



    @ViewChild('templateEditAdd', { static: true })
    public templateEditAdd: TemplateRef<any>;

    constructor(
        private toastr: ToastService
        , private modalService: BsModalService
        , private tipologiaClassificazioneService: TipoClassificazioneService
        , private localeService: BsLocaleService
        , private formBuilder: FormBuilder
        , private el: ElementRef,
          private temaPrioritarioService: TemaPrioritarioService
        , private classificazioneService: ClassificazioneService
        , private projectService: ProgettoService
        , private interventoService:InterventoService
        , private storageService:StorageService
    ) {
        this.localeService.use("it");
        this.editForm = this.initializeFormGroup();
        this.filterForm = this.initializeFilterFormGroup();
    }

    setIsDirty(value: boolean) {
        return;
    }


    initializeFilterFormGroup(): FormGroup {
        let frm = this.formBuilder.group({
            project: [null],
            objectSearchText: [null],
            areeTematicheCombo: [null],
            tipologiaClassificazioni: [null],
            temiPrioritari: [null]
        });
        frm.controls.objectSearchText.valueChanges
            .pipe(
                map((i: any) => i),
                debounceTime(500)
            )
            .subscribe(
                data => {
                    this.setObjectSearchText(data);
                }
            );
        return frm;
    }


    initializeFormGroup(): FormGroup {
        let frm = this.formBuilder.group({

        });
        return frm;
    }




    get f() {
        return this.editForm.controls;
    }

    ngOnInit() {

        this.templateListSogResp = [{ name: 'btnSelectSoggResp', template: this.btnSelectSoggResp }];
        window.scroll(0, 0);

        this.getTipologieClassificazioni();
        this.getTemiPrioritari();
        this.getAreeTematiche();
        this.getAllProjects();
    }




    loadDataCounter: number = 0;
    reloadData() {
        this.loadDataCounter = 0;

    }



    getTemiPrioritari() {
        this.temaPrioritarioService.getAll().subscribe((temi) => {
            this.temiPrioritariCombo = temi;
            console.log("temi");
        });
    }



    getTipologieClassificazioni() {
        this.tipologiaClassificazioneService.getAll().subscribe((x) => {
            this.tipologieClassificazioni = [
                { id: -2, tipo: "Nessuna classificazione" },
                ...x,
            ];
        });
    }

    getAllProjects() {
        this.projectService.getAll().subscribe((res) => {
            this.allProjects = res;
            this.allProjects = res
                .filter((p) => !p.isDeleted)
                .map((p) => {
                    p["label"] = `${p.codice} - ${p.descrizione}`;
                    return p;
                });
        });
    }



    codesSelected = null;
    flagSoggResp = true;//true = sostituisci
    panelClass = "";


    codesSelectedChanged(codes) {
        this.codesSelected = codes;
    }

    classificazioniSelectedChanged(items){
        let classificazioniAreTheSame = true;
        if (!items || items.length<=0)return;

        if (items.length>1){
            for(let i = 0;i<items.length;i++){
                let primaFase = items[0];
                let faseCorrente = items[i];
                if (primaFase.classificazioni.length ==0 || primaFase.classificazioni.length!= faseCorrente.classificazioni.length)classificazioniAreTheSame = false;
                else{
                    primaFase.classificazioni.forEach(
                        sg=> {
                            if (!faseCorrente.classificazioni.find(x=>x.id == sg.id))classificazioniAreTheSame = false;
                        }
                    );
                }

            }
        }
        if (classificazioniAreTheSame && items[0].classificazioni.length>0){
            this.classificazioni = [];
            items[0].classificazioni.forEach(
                sg=>this.classificazioni.push(sg)
            );
        }
        else{
            this.classificazioni = [];
        }
    }

    changeFlagSoggResp() {
        this.flagSoggResp = !this.flagSoggResp;
        this.panelClass = this.flagSoggResp ? "" : "card-header-info";
    }




    onSubmit() {
        this.submitted = true;
        if ((!this.codesSelected) || this.codesSelected.length <= 0) {
            this.toastr.error(`Nessun progettualità / fabbisogno / intervento selezionato`, null, {
                timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
            });
            return;
        }

        let errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;
            /*console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );*/
        }
        if (errors || this.editForm.invalid || this.soggettoResponsabileIsError) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.editForm.controls) {
                if (this.editForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.editForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.editForm.get(el)["controls"]) {
                            if (this.editForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }
        //no errors
        //console.log(`fase.dataPrevistaInizio=${fase.dataPrevistaInizio}, moment=${moment(fase.dataPrevistaInizio).format('YYYY-MM-DD')}`);

        let myService = this.interventoService;

        if (!confirm(`Procedere all'aggiornamento massivo dei ${this.codesSelected.length} elementi selezionati?`)) return false;
        this.loading = true;
        this.submitted = false;
        myService.updateMassiveClassificazioni(this.classificazioni.map(x=>x.id), this.codesSelected,this.flagSoggResp).subscribe(
            result => {
                this.loading = false;
                this.toastr.success('Aggiornamento effettuato con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                this.editForm.reset();
                this.loadTableData();
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile salvare gli elementi:${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                console.log('error');
            }
        );

    }



    closeModal() {
        this.modalRef.hide();
    }




    printValueAsDate(value) {
        if (!value || value == '') return "";
        return moment(value).format('DD/MM/YYYY');
    }
    printSoggettiResponsabiliDescription(value: string | undefined) {
        if (!value || value == '') return '';
        return value.split('|').join('<br />');
    }

    removeClassificazione(item: any) {
        this.classificazioni = this.classificazioni.filter(x => x.id != item.id);
    }


    clearSubscriptions() {
        this.modalSubscriptions.forEach(x => x.unsubscribe());
        this.modalSubscriptions = [];
    }
    getClassificazioniDTNgx = (dataTablesParameters: any, callback: any) => {
        this.classificazioneService
            .getAllDt(dataTablesParameters)
            .subscribe((resp) => {
                callback(resp);
            });
    };


    openModalClassificazioni() {
        let modalDefinitionNgx = {
            rows: [],
            totalElements: 0,
            columns: [
                {
                    name: "id",
                    prop: "id",
                    visible: false,
                    sortable: true,
                    filtrable: true,
                    width: 10,
                    resizeable: false,
                },

                {
                    name: "codice",
                    prop: "codice",

                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 1,
                    minWidth: 60,
                },
                {
                    name: "ordine",
                    prop: "ordine",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 1,
                    minWidth: 60,
                },
                {
                    name: "tipo classificazione",
                    prop: "tipoClassificazione",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 2,
                    minWidth: 200,
                },
                {
                    name: "descrizione",
                    prop: "descrizione",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 6,
                    minWidth: 200,
                },
            ],
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            ajax: this.getClassificazioniDTNgx,
            scrollbarH: "true",
        };
        let modalDefinition = {
            pageTitle: "Seleziona le classificazioni"
        }

        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl",
        };
        const initialState = {
            dtOptionsNgx: modalDefinitionNgx,
            modalDefinition: modalDefinition
        };

        this.modalRef = this.modalService.show(
            GenericSelectionModalComponent,
            Object.assign({}, config, { initialState })
        );
        console.log("apertura modale GenericSelectionModalComponent");
        this.clearSubscriptions();
        let subscription = this.modalRef.content.onSubmitOk.subscribe((data) => {
            console.log("inside ok of classificazione");
            let existsObj = this.classificazioni.find(
                (x) => x.id == data.id
            );
            if (existsObj) return; //already added
            this.classificazioni.push(data);
            this.classificazioni = Object.assign(
                [],
                this.classificazioni
            );

            this.toastr.success("Classificazione inserita con successo", null, {
                timeOut: 2000,
                disableTimeOut: false,
            });
            this.setIsDirty(true);
        });
        this.modalSubscriptions.push(subscription);
    }



    loadTableData() {
        this.codesSelected = [];
        if (
            this.filterObject.filterAreaTematica == -1 &&
            this.filterObject.filterTipologiaClassificazioni == -1 &&
            this.filterObject.temaPrioritarioId == -1 &&
            this.filterObject.mainObjectId == -1 &&
            this.filterObject.mainObjectStringFilter == '' &&
            this.filterObject.projectId == -1
        ) {
            //svuoto i filtri!!
            this.editForm.reset();
            this.tabellaDettaglioObj = [];
            this.subjectTabDettaglio.next(this.tabellaDettaglioObj);
            return;
        }

        //chiamo il metodo
        this.projectService
            .getInterventiSimpleDataWithProjects({
                filterProjectId: this.filterObject.projectId,
                filterSimpleInterventionId: this.filterObject.mainObjectId,
                filterStatus: -1,
                filterAreeGestionaliDirezioneEsterne: this.filterObject.filterAreaTematica,
                filterTipoClassificazione: this.filterObject.filterTipologiaClassificazioni,
                filterSoggettoGiuridico: -1,
                filterAnno: 'all',
                filterProvinciaSoggettoGiuridico: 'all',
                filterTemaProprietario: this.filterObject.temaPrioritarioId,
                filterFrom: -1,
                filterTo: -1,
                descriptionSearchString: this.filterObject.mainObjectStringFilter,
                filterTipologiaIntervento: -1
            })
            .subscribe((result) => {
                let res = [];
                result
                    .filter((x) => x.isMain)
                    .forEach((progetto) => {
                        res.push(progetto);
                        progetto["interventi"] = result.filter(
                            (x) =>
                                !x.isMain /*|| x.numSubInterventi <= 0*/ &&
                                x["progettoId"] == progetto["progettoId"]
                        );
                    });
                this.tabellaDettaglioObj = {progetti:res};
                this.subjectTabDettaglio.next(this.tabellaDettaglioObj);

            });

    }


    tabellaDettaglioObj: any;
    tabellaDettaglioObjLoaded = false;
    subjectTabDettaglio = new Subject<any>();
    observable$ = this.subjectTabDettaglio.asObservable();

    /*gestione filtri*/


    filterObject = {
        filterAreaTematica: -1
        , filterTipologiaClassificazioni: -1
        , temaPrioritarioId: -1
        , projectId: -1
        , mainObjectId: -1
        , mainObjectStringFilter: ''
    };


    areeTematicheCombo: any[];
    tipologieResponsabiliCombo: any[];





    private elencoAreeSubscription = null;
    getAreeTematiche() {
       
        this.elencoAreeSubscription = this.storageService.elencoAreeTematiche.subscribe(
            x=>{
                console.log(x);
                this.areeTematicheCombo = x?x:[];
            }
            );
    }


    setAreaTematicaFilter($event) {
        this.filterObject.filterAreaTematica = $event ? $event.id : -1;
        this.loadTableData();
    }

    setTipologiaClassificazioniFilter($event) {
        this.filterObject.filterTipologiaClassificazioni = $event ? $event.id : -1;
        this.loadTableData();
    }



    setTemiPrioritario($event) {
        this.filterObject.temaPrioritarioId = $event ? $event.id : -1;
        this.loadTableData();
    }

    completerParams = {
        labelField: "parentTitle",
        placeholder: "Cerca intervento; prog./fabbisogno; finanz.",
        ajax: this.filterComboMainObjectId,
        context: this,
        items: [],
    };


    setObjectSearchText($event) {
        if ($event && $event.length >= 3) {
            this.filterObject.mainObjectStringFilter = $event;
            // this.filterByText(this.filterObject.mainObjectStringFilter, null);
        } else {
            this.filterObject.mainObjectStringFilter = '';
        }
        this.loadTableData();
    }

    setInterventionCompleter($event) {
        if ($event) {
            this.filterObject.mainObjectId = $event.id;
        } else {
            this.filterObject.mainObjectId = -1;
            this.completerParams.items = [];
        }

        this.loadTableData();
    }

    onChangeInterventionCompleter($event) {
        !$event.target.value && this.setInterventionCompleter(null);
    }


    filterComboMainObjectId(filterString, callBack) {
        this['context'].projectService
            .getInterventiSimpleDataWithProjects({
                filterProjectId: -1,
                filterSimpleInterventionId: -1,
                filterStatus: -1,
                filterAreeGestionaliDirezioneEsterne: -1,
                filterTipoClassificazione: -1,
                filterSoggettoGiuridico: -1,
                filterAnno: 'all',
                filterProvinciaSoggettoGiuridico: 'all',
                filterTemaProprietario: -1,
                filterFrom: -1,
                filterTo: -1,
                descriptionSearchString: filterString
            })
            .subscribe((result) => {
                let res = [];
                result.forEach(
                    x => {
                        if (
                            x.codice.toLowerCase().includes(filterString.toLowerCase())
                            || x.descrizione.toLowerCase().includes(filterString.toLowerCase())
                            || (x.noteResponsabile && x.noteResponsabile.toLowerCase().includes(filterString.toLowerCase()))
                        ) {
                            res.push({ id: x.id, parentTitle: x.codice + ' - ' + x.descrizione });
                        }
                    }
                );
                callBack(res);
            });
    }

    description = '';
    setProject($event) {
        this.description = $event ? $event.descrizione : "";
        this.filterObject.projectId = $event ? $event.id : -1;
        this.loadTableData();
    }

}
