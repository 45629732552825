<form [formGroup]="loginForm">

  <div class="container">
    <div class="row justify-content-center mb-5">
      <div class="col-lg-6 ">
        <h3 class="text-center font-weight-bold mb-4 mt-3">
          Modifica password
        </h3>

        <div id="data" class="col-md-12 ">
          <div class="form-group">
            <label class="font-weight-bold">
              Vecchia password: <span class="text-danger font-weight-bold">*</span>
            </label>
            <show-hide-password size="md" [btnOutline]="false">
              <input type="password" #oldPassword tabindex="1" formControlName="oldPassword" class="form-control"
                [ngClass]="{
                    'is-invalid': submitted && f.oldPassword.errors
                  }" />
            </show-hide-password>
            <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback" style="display:block">

              <div *ngIf="f.oldPassword.errors.required">
                Vecchia password &egrave; obbligatoria
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold ">
              Nuova password: <span class="text-danger font-weight-bold">*</span>
            </label>

            <show-hide-password size="md" [btnOutline]="false">
              <input type="password" tabindex="2" #newPassword formControlName="newPassword" class="form-control"
                [ngClass]="{
                  'is-invalid': submitted && f.newPassword.errors
                }" />
            </show-hide-password>

            <div class="dropdown mt-1">
              <app-popover [html]="htmlPopover" [placement]="'top'"
                [class]="'btn btn-sm btn-outline-secondary'" [htmlImage]="htmlImg"
                [text]="'Requisiti password'">
              </app-popover>
            </div>

            <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback" style="display:block">
              <div *ngIf="f.newPassword.errors.required">
                Nuova password &egrave; obbligatoria
              </div>
              <div *ngIf="f.newPassword.errors.pattern">
                La password non rispetta i requisiti
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold ">
              Conferma nuova password: <span class="text-danger font-weight-bold">*</span>
            </label>
            <show-hide-password size="md" [btnOutline]="false">
              <input type="password" tabindex="3" formControlName="confirmPassword" #confirmPassword
                class="form-control" [ngClass]="{
                  'is-invalid': submitted && f.confirmPassword.errors
                }" />
            </show-hide-password>
            <div *ngIf="submitted && (f.newPassword.errors || f.confirmPassword.errors ) " class="invalid-feedback"
              style="display:block">
              <div *ngIf="f.newPassword != f.confirmPassword">
                Riscrivere la nuova password
              </div>

            </div>
          </div>
          <button class="btn btn-primary btn-block btn-lg" value="INVIA" (click)="onSubmit()">
            INVIA
          </button>

          <p class="small">
            I campi contrassegnati da asterisco (<span class="text-danger font-weight-bold">*</span>) sono obbligatori
          </p>
        </div>
      </div>
    </div>
  </div>

</form>
