<div class="container bg-white">

    <div class="col-12 mt-3 ">
        <app-title-page></app-title-page>
        <tabset>
            <tab heading="Gestione Comuni" id="tab1">
                <app-municipality-management></app-municipality-management>
            </tab>
            <tab heading="Gestione Province / Città Metropolitane">
                <app-province-management></app-province-management>
            </tab>
            <tab heading="Gestione Regioni">
                <app-region-management></app-region-management>
            </tab>
            <tab heading="Gestione Nazioni">
                <app-nation-management></app-nation-management>
            </tab>
        </tabset>

    </div>
</div>
