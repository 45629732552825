<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
    <div class="modal-header text-center">
        <h4 class="modal-title pull-left">Modifica Dati Comune</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <div class="col-lg-12">
            <div class="row">
                <!-- **** -->
                <div class="form-group col-xl-6 col-lg-6">
                    <h5><label for="provinces" class="mandatory">Nome Comune:</label></h5>
                    <div class="">
                        <input [formGroup]="editForm" formControlName="name" type="text" value="" maxlength="255" class="form-control" placeholder="Inserisci il nome del comune" [ngClass]="{
          'form-control': true,
          'is-invalid': submitted && f.name.errors
        }" />
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.name.errors.required">
                                Nome comune &egrave; obbligatoria
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group col-xl-6 col-lg-6">
                    <h5><label for="provinces" class="mandatory">Provincia:</label></h5>
                    <div class="">
                        <ng-select [items]="provinces" bindValue="id" formControlName="province" placeholder="Seleziona" bindLabel="value" selectOnTab="true" [searchable]="true" [clearable]="false" (change)="onChangeNewProvince($event)" notFoundText="Nessun risultato trovato...">
                        </ng-select>

                        <div *ngIf="submitted && f.province.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.province.errors.required">
                                Provincia &egrave; obbligatorio
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- *** -->
                <div class="form-group col-xl-6 col-lg-6">
                    <h5><label for="istat" class="mandatory">Codice Istat:</label></h5>
                    <div class="">
                        <input [formGroup]="editForm" formControlName="istatCode" type="text" value="" maxlength="6" class="form-control" placeholder="Inserisci il codice istat del comune" [ngClass]="{
          'form-control': true,
          'is-invalid': submitted && f.istatCode.errors
        }" />
                        <div *ngIf="submitted && f.istatCode.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.istatCode.errors.required">
                                Codice Istat &egrave; obbligatorio
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group col-xl-6 col-lg-6">
                    <h5><label for="istat" class="mandatory">Codice Catastale :</label></h5>
                    <div class="">
                        <input [formGroup]="editForm" formControlName="cadastreCode" type="text" value="" maxlength="4" class="form-control" placeholder="Inserisci il codice catastale del comune" [ngClass]="{
          'form-control': true,
          'is-invalid': submitted && f.cadastreCode.errors
        }" />
                        <div *ngIf="submitted && f.cadastreCode.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.cadastreCode.errors.required">
                                Codice Catasto &egrave; obbligatorio
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- *** -->
                <div class="form-group col-xl-6 col-lg-6">
                    <h5><label class="">Data Inizio Validità:</label></h5>

                    <div class="input-group">
                        <input placeholder="Seleziona la data di inizio validità" type="text" formControlName="fromDate" class="form-control" bsDatepicker maxlength="10" [ngClass]="{
            'is-invalid': submitted && f.fromDate.errors
          }" #dp="bsDatepicker" (bsValueChange)="onChangeFromDate($event)" [bsConfig]="{
            dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true, containerClass: 'theme-dark-blue',
            isAnimated: true
          }" />
                        <button type="button" class="btn btn-primary mt-0" (click)="dp.show()" [attr.aria-expanded]="dp.isOpen">
        <i class="fas fa-calendar-alt"></i>
        </button>
                    </div>
                    <div *ngIf="submitted && f.fromDate.errors" class="invalid-feedback" style="display: block;">
                        <div *ngIf="f.fromDate.errors.required">
                            Data Inizio Validità &egrave; obbligatoria
                        </div>
                        <!-- <div *ngIf="f.fromDate.errors">
              Data Inizio Validità &egrave; obbligatoria nel formato gg/mm/aaaa
            </div> -->
                    </div>
                </div>
                <div class="form-group col-xl-6 col-lg-6">
                    <h5><label class="">Data Fine Validità:</label></h5>

                    <div class="input-group">
                        <input placeholder="Seleziona la data di fine validità" type="text" class="form-control" bsDatepicker maxlength="10" formControlName="toDate" [ngClass]="{
            'is-invalid': submitted && f.toDate.errors
          }" #dp2="bsDatepicker" (bsValueChange)="onChangeToDate($event)" [bsConfig]="{
            dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true,
            containerClass: 'theme-dark-blue',
            isAnimated: true
          }" />
                        <button type="button" class="btn btn-primary mt-0" (click)="dp2.show()" [attr.aria-expanded]="dp2.isOpen">
                      <i class="fas fa-calendar-alt"></i>
        </button>
                    </div>
                    <!-- <div *ngIf="f.toDate.errors.required">
          Data Fine Validità &egrave; obbligatoria
        </div> -->
                    <!-- <div *ngIf="f.toDate.errors">
              Data Inizio Validità &egrave; obbligatoria nel formato gg/mm/aaaa
            </div> -->
                </div>
            </div>

            <div class="text-center my-3">
                <button class="btn btn-primary col-lg-4 col-md-12 col-sm-12 col-12" type="submit" style="font-weight: bold;" [disabled]="!editForm.dirty">
      AGGIORNA COMUNE
      &nbsp;<i aria-hidden="true" class="fa fa-redo-alt"></i>
    </button>
            </div>
        </div>

    </div>
</form>