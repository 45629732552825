import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";


@Injectable({ providedIn: "root" })
export class FinanziamentoDivDettaglioService {
  private subject = new Subject<any>();
  private subjectFinanziamento = new Subject<any>();
  private id:number;
  private finanziamentoId:number;

  constructor() {
  }

  reload(){
    //console.log(`inside reload with id = ${this.id}`);
        this.subject.next(null);
        this.subject.next(this.finanziamentoId);
        //this.subjectFinanziamento.next(null);
        //this.subjectFinanziamento.next(this.finanziamentoId);
  }

 setId(id:number){
     //console.log(`inside setId with id = ${id}`);
     this.finanziamentoId = id;
     this.reload();
 }
    

  getId(): Observable<any> {
    return this.subject.asObservable();
  }  

  setFinanziamentoId(id:number){
      this.finanziamentoId = id;
      this.reload();
  }

  /*getFinanziamentoId():Observable<any>{
      return this.subjectFinanziamento.asObservable();
  }*/
}
