import { TipoStrumentoAttuativo } from './tipo-strumento-attuativo';
import { TipoFinanziamento } from './tipo-finanziamento';
import { Provvedimento } from './provvedimento';
import { Fase } from './fase';

export class FinanziamentoAnnualita{
    id:number;
    anno:number;
    importo:number;
    disponibilita:number;

}

export class Finanziamento{
    id:number;
    titolo:string;    
    tipoStrumentoAttuativo:TipoStrumentoAttuativo;
    importoComplessivo:number;
    percentualeCofinanziamento:number;
    tipoFinanziamento:TipoFinanziamento;
    disponibilitaResidua:number;
    provvedimenti:Provvedimento[];
    fasi:Fase[];    
    annualita :FinanziamentoAnnualita[];
    codice:string;
    validazione:any;
    isReadOnly:boolean;
    note:string;
    importoUtilizzato:number;
}