import { CurrencyPipe } from "@angular/common";
import {
  Component,
  OnInit,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ToastService } from "src/app/services/toast.service";
import { InterventoService, ProgettoService } from "../../services/index";
import * as moment from "moment";

@Component({
  selector: "riepilogo-intervento-component",
  templateUrl: "riepilogo-intervento.component.html",
  styleUrls: ["riepilogo-intervento.component.css"],
})
export class RiepilogoInterventoComponent implements OnInit {
  private _interventoId: number;
  loading = false;
  intervento: any;
  listaInterventi = [];

  @Input()
  set interventoId(value) {
   // console.log(`inside set interventoId= ${value}`);
    this._interventoId = value;

    if (!value) return;
    //this.reloadData();
  }
  get interventoId() {
    //console.log(`inside get interventoId= ${this._interventoId}`);
    return this._interventoId;
  }

  constructor(
    private el: ElementRef,
    private toastr: ToastService,
    private interventoService: InterventoService,
    private progettoService: ProgettoService
  ) {
   // console.log("into RiepilogoInterventoComponent.constructor");
  }

  ngOnInit() {
   // console.log("into RiepilogoInterventoComponent.ngoninit");
  }

  @Input()
  gotoBtnOptions: any;

  @Output()
  gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
  gotoBackBtnClick() {
    this.gotoBtnFunction.emit(true);
  }

  listaCapitoli = [];
  listaSalSa = [];
  listaSalNormali = [];

  getProvvedimenti() {
    if (!this._interventoId) return;
    this.listaCapitoli = [];
    let that = this;
    this.interventoService
      .getProvvedimenti(this._interventoId)
      .subscribe((x) => {
        x.sort((a, b) => {
          if (moment(a.data).toDate() < moment(b.data).toDate()) return -1;
          if (moment(a.data).toDate() > moment(b.data).toDate()) return 1;
          return 0;
        });
        //this.listaCapitoli = [];
        x.forEach((provvedimento) => {
          if (provvedimento.provvedimentoCapitoliBilancio)
            provvedimento.provvedimentoCapitoliBilancio.forEach((capitolo) => {
              let cap = {
                descrizione: capitolo.capitoloBilancioDescrizione,
                codice: capitolo.capitoloBilancioCodice,
                id: capitolo.capitoloBilancioId,
                annualita: [{ anno: capitolo.anno, importo: capitolo.importo }],
              };

              let capitoloInArray = that.listaCapitoli.find(
                (y) => y.id == cap.id
              );
              if (!capitoloInArray) {
                that.listaCapitoli.push(cap);
              } else {
                let annualitacapitolo = capitoloInArray.annualita.find(
                  (x) => x.anno == capitolo.anno
                );
                if (annualitacapitolo) {
                  annualitacapitolo.importo = capitolo.importo;
                } else {
                  capitoloInArray.annualita.push({
                    anno: capitolo.anno,
                    importo: capitolo.importo,
                  });
                }
              }
            });
        });
        // console.log(`listacapitoli=${that.listaCapitoli}`);
      });
  }

  reloadData() {
    if (!this._interventoId) return;
    this.getDatiGenerali();
    this.getProvvedimenti();
    //this.getSalByInterventoId();
  }

  getSalByInterventoId() {
    this.interventoService.getSal(this._interventoId).subscribe((result) => {
      this.listaSalNormali = result.filter(
        (x) => !x.anticipazioneASoggettoAttuatore
      );
      this.listaSalSa = result.filter((x) => x.anticipazioneASoggettoAttuatore);

      //ciclo i sal e erogazioni per prendere gli anni che non ci sono nell'intervento ed aggiungerceli  
      result.forEach(sal=>{
        if (sal && sal.dataLavori) {
            let dataLavori = moment(sal.dataLavori).toDate();
            let year = dataLavori.getFullYear();
            if (!this.intervento.importi.find(x=> x.anno == year)){
                this.intervento.importi.push({anno:year,importo:0});
            };
            if (sal.erogazioni && sal.erogazioni.length>0){
                sal.erogazioni.forEach(
                    erogazione=>{
                        let dataLavori = moment(erogazione.dataEffettiva).toDate();
                        let year = dataLavori.getFullYear();
                        if (!this.intervento.importi.find(x=> x.anno == year)){
                            this.intervento.importi.push({anno:year,importo:0});
                        };
                    }
                );
            }
        }
        
      });

    });
  }

  getDatiGenerali() {
    if (!this._interventoId) return;
    //dati generali
    this.interventoService.get(this._interventoId).subscribe((x) => {
      this.intervento = x;
      this.intervento["importi"].sort((a, b) => a.anno - b.anno);
      this.getSalByInterventoId();
    });
  }

  getImportoPerAnnoCapitolo(capitolo: any, anno: number) {
    //return 0;
    if (!this.intervento || !capitolo) return 0;
    let annualita = capitolo.annualita.find((x) => x.anno == anno);
    if (!annualita) return 0;
    return annualita.importo;
  }

  getTotaleCapitolo(capitolo: any) {
    //return 0;
    if (!this.intervento || !capitolo) return 0;
    let somma = 0;
    this.intervento.importi.forEach((annualita) => {
      let valore = capitolo.annualita.find((y) => y.anno == annualita.anno);
      somma += valore ? valore.importo : 0;
    });
    /*capitolo.annualita.forEach(element => {
            somma +=element.importo;
        });*/
    return somma;
  }

  getTotaleSommaAnnoCapitoli(anno: number) {
    if (!this.intervento || !this.listaCapitoli) return 0;
    let somma = 0;
    this.listaCapitoli.forEach((capitolo) => {
      let annualita = capitolo.annualita.find((y) => y.anno == anno);
      somma += annualita ? annualita.importo : 0;
    });
    return somma;
  }

  getTotaleSommaCapitoli() {
    if (!this.intervento || !this.listaCapitoli) return 0;
    let somma = 0;
    this.intervento.importi.forEach((annualita) => {
      somma += this.getTotaleSommaAnnoCapitoli(annualita.anno);
    });
    return somma;
  }

  getSalImportoPerAnno(sal: any, anno: number) {
    if (!sal || !sal.dataLavori) return 0;
    let dataLavori = moment(sal.dataLavori).toDate();
    let year = dataLavori.getFullYear();
    if (year != anno) return 0;
    return sal.importo;
  }

  getImportoPerAnnoErogazione(erogazione: any, anno: number) {
    if (!erogazione || !erogazione.dataEffettiva) return 0;
    let dataLavori = moment(erogazione.dataEffettiva).toDate();
    let year = dataLavori.getFullYear();
    if (year != anno) return 0;
    return erogazione.importo;
  }

  getTotaleErogatoPerAnno(listasal: any, anno: number) {
    if (!listasal) return 0;
    let somma = 0;
    listasal.forEach((element) => {
      somma += this.getErogatoPerAnnoPerSal(element, anno);
    });
    return somma;
  }

  getTotaleErogato(listasal: any) {
    if (!listasal) return 0;
    let somma = 0;
    listasal.forEach((element) => {
      somma += this.getErogatoTotalePerSal(element);
    });
    return somma;
  }

  getImportoTotaleIntervento() {
    if (!this.intervento) return 0;
    let somma = 0;
    this.intervento.importi.forEach((element) => {
      somma += element.importo;
    });
    return somma;
  }

  getErogatoTotalePerSal(sal: any) {
    if (!sal || !sal.erogazioni) return 0;
    let somma = 0;
    sal.erogazioni.forEach((element) => {
      somma += element.importo;
    });
    return somma;
  }

  getTotaleTuttiSal(listasal: any) {
    if (!listasal) return 0;
    let somma = 0;
    listasal.forEach((element) => {
      somma += element.importo;
    });
    return somma;
  }

  getTotaleTuttiSalPerAnno(listasal: any, anno: number) {
    if (!listasal) return 0;
    let somma = 0;
    listasal.forEach((sal) => {
      somma += this.getSalImportoPerAnno(sal, anno);
    });
    return somma;
  }

  getErogatoPerAnnoPerSal(sal: any, anno: number) {
    if (!sal || !sal.erogazioni) return 0;
    let somma = 0;
    sal.erogazioni.forEach((element) => {
        let imp = this.getImportoPerAnnoErogazione(element,anno);
        somma += imp;
    });
    return somma;
  }

  getImportoPerAnno(intervento: any, anno: number) {
    if (!intervento) return 0;
    if (!intervento.importi || intervento.importi.length <= 0) return 0;
    let importoAnno = 0;
    importoAnno = intervento.importi.find((x) => x.anno == anno);
    if (!importoAnno) return 0;
    return importoAnno["importo"];
  }

  getTotaleIntervento(intervento: any) {
    if (!intervento) return 0;
    let valore = 0;
    intervento.importi.forEach((element) => {
      valore += element.importo;
    });
    return valore;
  }

  printValueAsCurrency(value, withCurrency: boolean = true) {
    //return this.italianCurrencyPipe.parse(value) + " €";
    value = typeof value === "number" ? value : value.replace(",", ".").trim();
    const format = "1.2-2";
    let currency = "€";
    if (!withCurrency) currency = "";
    const currentLocale: string = "it";
    return new CurrencyPipe(currentLocale).transform(
      value,
      "EUR",
      currency,
      format,
      "it-IT"
    );
  }

  arrowUp = true;
}
