import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import {  CommonModule } from "@angular/common";
import { NgxMaskModule } from 'ngx-mask';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import{NgxDatatableWrapperComponent} from './ngx-datatable-wrapper.component';




@NgModule({
  declarations: [
    NgxDatatableWrapperComponent
],
  exports: [
      NgxDatatableWrapperComponent
]

  ,imports:[
      CommonModule,
      ModalModule.forRoot(),
      BsDatepickerModule.forRoot(),
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
      NgSelectModule,      
      NgxMaskModule.forRoot(),          
      NgxDatatableModule,    
    
  ],
  providers: [    
  ],
  entryComponents: [    
  ]
})
export class NgxDatatableWrapperModule {}