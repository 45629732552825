import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import {ItalianCurrencyPipe} from './currencyPipe';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        ItalianCurrencyPipe,        
    ],
    exports: [ItalianCurrencyPipe
    ]

    , imports: [
        CommonModule,        

    ],
    providers: [        
    ],
    entryComponents: [        
    ]
})
export class ItalianCurrencyPipeModule { }