import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { DatiSisma } from "../models/dati-sisma";

@Injectable({
  providedIn: "root",
})
export class DatiSismaService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}DatiSisma/`;
  constructor(private http: HttpClient) {}

  getAllDt(dataTablesParameters: any) {
    return this.http.post<any>(`${this.apiUrl}alldt`, dataTablesParameters);
  }
  getAll() {
    return this.http.get<DatiSisma[]>(`${this.apiUrl}`);
  }

  getDataByInterventoId(interventoId: number) {
    return this.http.get<DatiSisma[]>(`${this.apiUrl}${interventoId}`);
  }

  insert(s: DatiSisma, interventoId: number) {
    return this.http.post<any>(`${this.apiUrl}${interventoId}`, s);
  }

  update(s: DatiSisma, interventoId: number) {
    return this.http.put<any>(`${this.apiUrl}${interventoId}/${s.id}`, s);
  }

  delete(id: number) {
    return this.http.delete<any>(`${this.apiUrl}${id}`);
  }

  save(s: DatiSisma, interventoId: number) {
    if (!s.id) return this.insert(s, interventoId);
    return this.update(s, interventoId);
  }
}
