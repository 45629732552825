<div class="container">
  <div class="pb-3">
    <div class=" row ">
      <div class="titolo col-md-12 ">
        <h3 class="mt-3">PNRR</h3>
      </div>
    </div>
  </div>

  <div class="row mb-5 ">
    <tabset class="col-lg-12 tab-wrapper">
      <tab heading="Gestione Missioni" id="tab1" *ngIf="enableSection('PNRR - Missioni', ['read', 'insert', 'update', 'delete'], true)">
        <pnrr-missione-management></pnrr-missione-management>
      </tab>
      <tab heading="Gestione Componenti" id="tab2" *ngIf="enableSection('PNRR - Componenti', ['read', 'insert', 'update', 'delete'], true)">
        <pnrr-componente-management></pnrr-componente-management>
      </tab>
      <tab heading="Gestione Linee di finanziamento PNRR/PNC" id="tab3" *ngIf="enableSection('PNRR - Linee di Finanziamento', ['read', 'insert', 'update', 'delete'], true)">
        <pnrr-linea-finanziamento-management></pnrr-linea-finanziamento-management>
      </tab>
    </tabset>
  </div>
</div>
