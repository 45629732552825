<div class="container-fluid px-0 bg-celeste">
    <div class="text-center p-5 position-absolute top-50 start-50 translate-middle bg-white border border-dark rounded shadow"
    style="z-index: 999999999" [hidden]="showPage">
        <app-loading message="Caricamento in corso..."></app-loading>
    </div>

      <app-header-dashboard></app-header-dashboard>
    <!-- menu -->
    <div class="row pb-3 pt-1 bg-0083e0" style="z-index: 13000">
      <div class="col-xl-3 col-lg-12 col-md-12 pt-1" style="z-index: 13000">
        <label for="importoFinanziamento">
          <h6 class="mb-0 text-white bold">Fonte finanziamento</h6>
        </label>
        <ng-select
          [items]="fontiCombo"
          bindValue="id"
          bindLabel="tipoFinanziamento"
          placeholder="Seleziona fonte finanziamento"
          (change)="setFonte($event)"
          [multiple]="true"
        ><ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> <span title="{{ item.tipoFinanziamento }}">&nbsp;{{ item.tipoFinanziamento }}</span>
      </ng-template>
        </ng-select>
      </div>

      <!-- inizio multiselect-->
      <div class="col-xl-9 col-lg-12 col-md-12 pt-1" style="z-index: 13000">
        <label for="importoFinanziamento">
          <h6 class="mb-0 text-white bold">Titolo finanziamento</h6>
        </label>
        <ng-select
          [items]="allFinanziamenti"
          bindValue="id"
          bindLabel="titoloEsteso"
          placeholder="Seleziona finanziamenti"
          (change)="setFinanziamento($event)"
          [multiple]="true"
        ><ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> <span title="{{ item.titoloEsteso }}">&nbsp;{{ item.titoloEsteso }}</span>
      </ng-template>
        </ng-select>
      </div>
      <!-- fine multiselect-->
      <!-- <div class="col-xl-2 col-lg-4 col-md-7 pt-1">
        <label for=" importoFinanziamento ">
          <h6 class="mb-0 text-white bold">
            Titolo finanziamento <small>(min 3 caratteri)</small>
          </h6>
        </label>

        <app-completer
          [cpOptions]="completerParams"
          (onSelectEmitter)="setFinanziamento($event)"
          (keyup)="onChangeFinaziamento($event)"
        >
        </app-completer>
      </div> -->
      <!---->
      <div class="col-xl-3 col-lg-3 col-md-6 pt-1">
        <label for="">
          <h6 class="mb-0 text-white bold">Strumento attuativo</h6>
        </label>
        <ng-select
          [items]="strumentiCombo"
          bindValue="id"
          bindLabel="descrizione"
          placeholder="Seleziona strumento attuativo "
          (change)="setStrumento($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
          </ng-template>
        </ng-select>
      </div>

      <!--<div class="col-xl-2 col-lg-3 col-md-4">
        <label for="importoFinanziamento ">
          <h6 class="mb-0 text-white bold">Con disponibilità</h6>
        </label>
        <ng-select
          [items]="siNoCombo"
          bindValue="k"
          bindLabel="v"
          placeholder="Seleziona con disponibilità  "
          (change)="setDisponibilita($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.v }}">{{ item.v }}</div>
          </ng-template>
        </ng-select>
      </div>-->
      <div class="col-xl-2 col-lg-2 col-md-6 pt-1">
        <label for="importoFinanziamento ">
          <h6 class="mb-0 text-white bold">Annualità</h6>
        </label>
        <ng-select
          [items]="anniCombo"
          bindValue="value"
          bindLabel="key"
          placeholder="Seleziona annualità"
          (change)="setAnno($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.value }}">{{ item.value }}</div>
          </ng-template>
        </ng-select>
      </div>

      <div class="col-xl-4 col-lg-5 col-md-8 pt-1">
        <label for=" ">
          <h6 class="mb-0 text-white bold">
            Aree gestionali direzione e/o esterne
          </h6>
        </label>
        <ng-select
          [items]="areeTematicheCombo"
          bindValue="id"
          bindLabel="descrizione"
          placeholder="Seleziona area gestionale direzione e/o esterna "
          (change)="setAreaTematica($event)"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
          </ng-template>
        </ng-select>
      </div>

      <div class="col-xl-3 col-lg-2 col-md-4 col-sm-12 pt-1 align-self-end">
        <!--<label>
          <h6 class="mb-0 text-white bold"></h6>
        </label>-->
        <div >
            <button
            [disabled]="loadingExportExcel"
            type="button"
            class="mt-1 btn btn-info btn-block"
            (click)="esportaExcel()"
          >
            Esporta
            <img
              *ngIf="loadingExportExcel"
              class="pl-3 float-right"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
            />
          </button>
        </div>
      </div>
    </div>

    <!--grafici classificazione-->
    <div class="row">
      <!-- esportazione grafici--->

      <div class="col-lg-6 col-md-12">


        <div
          id="chart1"
          class="bg-white chart1 border shadow-sm my-3 rounded"
        ></div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div
          id="chartLast"
          class="bg-white chart2 border shadow-sm my-3 rounded"
        ></div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-lg-12 col-md-12">
        <div
          id="chart1"
          class="bg-white chart1 border shadow-sm my-3 rounded"
        ></div>
      </div>
    </div> -->
    <div class="row">

      <div class="col-lg-12 col-md-12">
        <div
          id="chart2"
          class="bg-white chart2 border shadow-sm my-3 rounded"
        ></div>
      </div>
    </div>
    <div class="row">

      <div class="col-lg-12">
        <div
          id="chart3 "
          class="bg-white chart3 border shadow-sm my-3 rounded"
        ></div>
      </div>
    </div>
    <div class="row">

      <div class="col-lg-12">
        <div
          id="chart4 "
          class="bg-white chart4 border shadow-sm my-3 rounded"
        ></div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-lg-12">
        <div
          id="chart7 "
          class="bg-white chart4 border shadow-sm my-3 rounded"
        ></div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-lg-12 my-3  text-center">
        <div class="bg-white border shadow-sm rounded">
            <button *ngIf="!tabellaDettaglioObjLoaded" (click)="tabellaDettaglioObjLoaded = true" class="btn btn-primary my-2">Mostra tabella di dettaglio</button>
          <!-- Tabella dettaglio-->
          <div class="text-left"><dashboard-stato-finanziamenti-details-table-component
            [hidden]="!tabellaDettaglioObjLoaded"
            [observable$]="observable$ | async"
          ></dashboard-stato-finanziamenti-details-table-component></div>
        </div>
      </div>
    </div>

</div>
