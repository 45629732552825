


<!-- Recupera password -->
<section class="fascia-grey-login mt-5 " >
    <div class="container ">
        <div class="row clearfix">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                <div class="account-wall">
                    <form class="form-signin border shadow p-3 mt-5 mb-5 bg-white rounded" [formGroup]="forgotPassword" (ngSubmit)="onSubmit()">
                        <h3 class="text-center login-title mb-4"><b>Recupera password</b></h3>
                        <p class="lead  text-center">
                          Per favore inserisci l'username. Riceverai una nuova password via e-mail.
                        </p>
                        <div *ngIf="invalidForgotPassword" class="alert alert-danger">
                          Username non è stato trovato nel database! Verifica di aver inserito l'username corretto.
                        </div>
                        <div *ngIf="emailSent" class="alert alert-success">
                          Ti abbiamo inviato la password al tuo indirizzo email.
                        </div>
                        <div class="form-group">
                          <label for="username" class="bold">Username</label>

                          <input type="text" formControlName="username" tabindex="1" class="form-control" placeholder="Username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                              <div *ngIf="f.username.errors.required">Username &egrave; obbligatorio</div>
                          </div>

                        </div>

                        <div style="width:100%" style="margin-top:50px">
                            <button class="btn btn-lg btn-primary btn-block" type="submit" tabindex="3" [disabled]="loading">
                              Richiedi una nuova password
                                <img *ngIf="loading" class="pl-3 float-right"
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </button>

                        </div>

                        <span class="clearfix"></span>
                    </form>
                </div>

            </div>
        </div>
    </div>
</section>

