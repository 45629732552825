export class AppStrings {
  //Alerts
  public static ALERT_WAITING_SUC =
    "Il suo account è in fase di accreditamento. Quando sarà validato ne riceverà comunicazione tramite mail.";
  public static ALERT_UPLOAD_SUC = "Caricamento completato con successo.";
  public static ALERT_UPLOAD_ERR = "";
  public static ALERT_USER_FIRST_ACCESS = "E' la prima volta che accedi al sistema, ti preghiamo di compilare i dati";

  public static ALERT_EMAIL_OR_FC_ERR =
    "Codice Fiscale o indirizzo mail già presente nella banca dati degli utenti";

  //Declarations
  public static DECLARATION_REGION = "Dichiarazione regione";
  public static DECLARATION_PROVINCE = "Dichiarazione provincia";
  public static DECLARATION_MUNICIPALITY = "Dichiarazione comune";
  public static DECLARATION_EDUCATIONAL_AREA = "Dichiarazione zona";
  public static DECLARATION_SCHOOL = "Dichiarazione istituzione";

  //Import Message
  public static CONFIRM_CHANGES_STEP2 =
    "Ci sono delle modifiche in corso non salvate nello step 2. Continuare e perdere le modifiche?";
  public static CONFIRM_CHANGES_STEP3 =
    "Ci sono delle modifiche in corso non salvate nello step 3. Continuare e perdere le modifiche?";
}
