<div class="col-12 mb-5">

    <div class="col-md-12">
        <p class="text-sm-left mt-3">
            In questa pagina è possibile <b>inserire un nuovo Comune, visualizzare la lista dei Comuni presenti e
                modificarne i
                relativi dati</b>.
        </p>
    </div>
    <hr />

    <!-- INSERISCI NUOVO COMUNE -->
    <accordion [isAnimated]="true">
        <accordion-group [isOpen]="open" (isOpenChange)="log($event)" heading="Scheda Inserimento Nuovo Comune">

            <form style="height: auto;" [formGroup]="addForm" (ngSubmit)="onSubmit()">

                <div class="form col-lg-12">

                    <!-- provincia-->
                    <div class="row">
                        <div class="form-group col-xl-6 col-lg-6">
                            <h5><label for="istat" class="mandatory">Provincia:</label></h5>

                            <div class="">
                                <ng-select [items]="provincesAdd" bindValue="id" appendTo="body"
                                    formControlName="province" placeholder="Seleziona" bindLabel="value"
                                    selectOnTab="true" [searchable]="true" [clearable]="false" [ngClass]="{
                                                        'is-invalid': submitted && f.province.errors}"
                                    notFoundText="Nessun risultato trovato..." (change)="onChangeEditProvince($event)">
                                </ng-select>
                                <div *ngIf="submitted && f.province.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.province.errors.required">
                                        Provincia &egrave; obbligatoria
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="form-group col-xl-6 col-lg-6">
                            <h5><label for="name" class="mandatory">Nome Comune:</label></h5>
                            <div class="">
                                <input [formGroup]="addForm" formControlName="name" type="text" value="" maxlength="255"
                                    placeholder="Inserisci il nome del comune" [ngClass]="{
                                                'form-control': true,
                                                'is-invalid': submitted && f.name.errors
                                                }" />
                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.name.errors.required">
                                        Nome comune &egrave; obbligatorio
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="row">
                        <div class="form-group col-xl-6 col-lg-6">
                            <h5><label for="istat" class=" mandatory">Codice Istat:</label></h5>

                            <div class="">
                                <input [formGroup]="addForm" formControlName="istatCode" type="text" value=""
                                    maxlength="6" class="form-control"
                                    placeholder="Inserisci il codice istat del comune" [ngClass]="{
                                        'form-control': true,
                                       'is-invalid': submitted && f.istatCode.errors
                                              }" />
                                <div *ngIf="submitted && f.istatCode.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.istatCode.errors.required">
                                        Codice Istat &egrave; obbligatorio
                                    </div>
                                    <div *ngIf="f.istatCode.errors.minlength">
                                        Codice Istat deve essere di 6 caratteri
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-xl-6 col-lg-6">
                            <h5><label for="istat" class="mandatory">Codice Catastale:</label></h5>

                            <div class="">
                                <input [formGroup]="addForm" formControlName="cadastreCode" type="text" value=""
                                    maxlength="4" class="form-control"
                                    placeholder="Inserisci il codice catastale del comune" [ngClass]="{
                                  'form-control': true,
                                          'is-invalid': submitted && f.cadastreCode.errors
                                           }" />
                                <div *ngIf="submitted && f.cadastreCode.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.cadastreCode.errors.required">
                                        Codice Catasto &egrave; obbligatorio
                                    </div>
                                    <div *ngIf="f.cadastreCode.errors.minlength">
                                        Codice Catasto deve essere di 4 caratteri
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--***-->
                    <div class="row">

                        <div class="form-group col-xl-6 col-lg-6">
                            <h5><label class="">Data Inizio Validità:</label></h5>
                            <div class="input-group">
                                <input placeholder="Seleziona la data di inizio validità" type="text"
                                    formControlName="fromDate" class="form-control datapi" bsDatepicker maxlength="10"
                                    [ngClass]="{
                                     'is-invalid': submitted && f.fromDate.errors
                                       }" #dp="bsDatepicker" [bsConfig]="{
                                         dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true, containerClass: 'theme-dark-blue', isAnimated: true
                                        }" />


                                <button type="button" class="btn btn-primary mt-0" (click)="dp.show()"
                                    [attr.aria-expanded]="dp.isOpen">
                                    <i class="fas fa-calendar-alt"></i>
                                </button>
                            </div>
                        </div>



                        <div class="form-group col-xl-6 col-lg-6">
                            <h5><label class="">Data Fine Validità:</label></h5>

                            <div class="input-group ">
                                <input placeholder="Seleziona la data di fine validità" type="text" class="form-control"
                                    bsDatepicker maxlength="10" formControlName="toDate" [ngClass]="{
                                 'is-invalid': submitted && f.toDate.errors
                            }" #dp2="bsDatepicker" [bsConfig]="{
                             dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true, containerClass: 'theme-dark-blue', isAnimated: true
                              }" />
                                <button type="button" class="btn btn-primary mt-0" (click)="dp2.show()"
                                    [attr.aria-expanded]="dp2.isOpen">
                                    <i class="fas fa-calendar-alt"></i>
                                </button>
                            </div>
                            <div *ngIf="submitted && f.toDate.errors" class="invalid-feedback" style="display: block;">
                                <div *ngIf="f.toDate.errors.required">
                                    Data Fine Validità &egrave; obbligatoria
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="text-center my-3">
                        <button class="btn btn-primary col-lg-4 col-md-12 col-sm-12 col-12 " type="submit">
                            INSERISCI NUOVO COMUNE&nbsp;<i aria-hidden="true" class="fa fa-plus"></i>
                        </button>
                    </div>

                </div>
            </form>
        </accordion-group>
    </accordion>

    <div class="mt-4">
        <h4 class="titoletti">Lista Comuni presenti in GLP</h4>
        <div class="form-group clearfix ">
            <label for="municipality">Provincia/Città Metropolitana:<span aria-hidden="true"
                    class="text-danger font-weight-bold">*</span>
            </label>
            <ng-select [items]="provinces" bindValue="id" placeholder="Seleziona" bindLabel="value" selectOnTab="true"
                [searchable]="true" [clearable]="false" (change)="onChangeProvince($event)"
                notFoundText="Nessun risultato trovato...">
            </ng-select>
        </div>
    </div>
    <div [hidden]="!visible">
        <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;"
            [dtOptions]="dtOptionsNgx">
            <ng-template #btnEditTemplate let-row="row" let-value="value">
                <a [ngClass]="{
            'disabled': loading
            }" class=" border-0 text-center" (click)="editHandler(row)" data-toggle="tooltip" data-placement="top"
                    title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit pointer"></i></a>
            </ng-template>
        </ngx-datatable-wrapper>
    </div>
</div>
