//import "core-js";
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit,  ElementRef, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { RoleService} from 'src/app/services/role.service'
import { Role } from 'src/app/models/role';
import {AccessLogService} from 'src/app/services/accesslog.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import * as moment from "moment";
import { Roles,RolesDescriptions } from 'src/app/helpers/constants';
defineLocale('it', itLocale);



@Component(
    {
        templateUrl: 'accesslog-list.component.html',
        styleUrls: ['accesslog-list.component.css']
    }
)
export class AccessLogListComponent implements AfterViewInit, OnDestroy, OnInit {
    private chart: am4charts.XYChart;
    registerForm: FormGroup;
    submitted = false;
    loading = false;
    showChart = false;
    dtOptions: any = {};
    maxDate = new Date();
    selectedPeriod = '';
    selectedUserType = '';
    searched = false;
    searchBtnClicked = false;
    reloadChart=false;

    userTypes: Role[] = [{key:Roles.role_admin,value:RolesDescriptions.role_admin},
        {key:Roles.role_directional_group,value:RolesDescriptions.role_directional_group},
        {key:Roles.role_area_staff,value:RolesDescriptions.role_area_staff},
        {key:Roles.role_external,value:RolesDescriptions.role_external},
        {key:Roles.role_viewer,value:RolesDescriptions.role_viewer}
    ];

    periods = [
        { label: 'Oggi', value: 'today' },
        { label: 'Settimana', value: 'week' },
        { label: 'Mese', value: 'month' },
        { label: 'Anno', value: 'year' },
        { label: 'Seleziona un periodo', value: 'period' },
    ];

    dtOptionsNgx:any;
    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;




    columns = [
        {
            name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
            , resizeable: false
        }
        ,{name:'usertype',prop:'usertype',visible:false}

        , { name: 'tipologia utente'
        , prop: 'userCodesLabels'

        , sortable: true
        , filtrable: true
        , resizeable: false
        , draggable: false
        , flexGrow: 1
            , minWidth: 60

      }
      , { name: 'utente'
        , prop: 'username'

        , sortable: true
        , filtrable: true
        , resizeable: false
        , draggable: false
        , flexGrow: 1
            , minWidth: 60

      }
      , { name: 'data login'
        , prop: 'loginDate'

        , sortable: true
        , filtrable: true
        , resizeable: false
        , draggable: false
        , cellTemplate:'dateTimeValueTemplate'
        , flexGrow: 1
            , minWidth: 60

      }
      , { name: 'data logout'
        , prop: 'logoutDate'

        , sortable: true
        , filtrable: true
        , resizeable: false
        , draggable: false
        , cellTemplate:'dateTimeValueTemplate'
        , flexGrow: 1
            , minWidth: 60

      }
      , { name: 'cognome e nome'
        , prop: 'completedName'

        , sortable: true
        , filtrable: true
        , resizeable: false
        , draggable: false
        , flexGrow: 1
            , minWidth: 60

      }

    ];


    constructor(
        private el: ElementRef,
        private localeService: BsLocaleService,
        private formBuilder: FormBuilder,
        private zone: NgZone        ,
        private roleService:RoleService,
        private accessLogService:AccessLogService
    ) {
        console.log('into AccessListComponent.constructor');
        this.localeService.use('it');
        am4core.useTheme(am4themes_animated);
        this.dtOptionsNgx = {
            rows: []
            , totalElements: 0
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            ,ajax:this.loadData
            , scrollbarH:"true"
            , sorts:[{dir:'desc',prop:'loginDate'}]
        };
    }

    get f() { return this.registerForm.controls; }


    templateList=[];
    ngOnInit() {
        this.templateList =[];
        console.log('into AccessListComponent.ngoninit');
        this.registerForm = this.formBuilder.group({
            userType: [null, [Validators.required]],
            datePeriod: [null]

        });
    }

    ngAfterViewInit(): void {
        this.zone.runOutsideAngular(() => {
            //this.loadChartData();
        });
    }


    loadChartData(dataTablesParameters) {
        if(!this.reloadChart)return;
        this.reloadChart = false;
        //var dataTablesParameters = JSON.parse('{"draw":1,"columns":[{"data":"userType","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"userCodesLabels","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"username","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"loginDate","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"logoutDate","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"ip","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"lastName","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}}],"order":[{"column":2,"dir":"asc"}],"start":0,"length":10,"search":{"value":"","regex":false}}');
        dataTablesParameters.draw = dataTablesParameters.draw +1;
        dataTablesParameters.length = -1;
        dataTablesParameters.order = [{column:3,dir:'asc'}];
        dataTablesParameters.search.value='';
        this.accessLogService.getAllDt(dataTablesParameters).subscribe(resp => {
            console.log(`response of loadChartData: ${resp.data}`);
            let ris = resp.data.reduce(
                (result, item) => (
                    {
                    ...result,
                    [moment(item.loginDate).format('YYYY-MM-DD')]: [
                        ...(result[moment(item.loginDate).format('YYYY-MM-DD')] || []),
                        item,
                    ],
                })
                , {}
            );
            console.log(`after reduce ${ris}`);
            let k = [];
            for(let key in ris){
                console.log(`key ${key}`);
                k.push({date:key, value: ris[key].length});
            }

            this.showChartData(k);
        });
    }
    showChartData(data) {
        this.showChart = true;
        let chart = am4core.create("report_timeline", am4charts.XYChart);
        chart.paddingRight = 20;

        data = data.sort((a, b)=>
            moment(a.date).diff(b.date)
          );
        // Add data
        chart.data = data;

        // Set input format for the dates
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

        // Create axes
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        dateAxis.baseInterval = {
            "timeUnit": "day",
            "count": 1
        };
        dateAxis.tooltipDateFormat = "dd/MM/yyyy";
        valueAxis.title.text = "N° Accessi";
        dateAxis.dateFormats.setKey("day", "dd/MM/yyyy");
        dateAxis.dateFormats.setKey("month", "dd/MM/yyyy");
        dateAxis.periodChangeDateFormats.setKey("month", "dd/MM/yyyy");
        dateAxis.periodChangeDateFormats.setKey("day", "dd/MM/yyyy");
        // Create series
        var series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "date";
        series.tooltipText = "{value}"
        series.strokeWidth = 2;
        series.minBulletDistance = 15;
        series.fillOpacity = 0.3;
        // Drop-shaped tooltips
        series.tooltip.background.cornerRadius = 20;
        series.tooltip.background.strokeOpacity = 0;
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.label.minWidth = 40;
        series.tooltip.label.minHeight = 40;
        series.tooltip.label.textAlign = "middle";
        series.tooltip.label.textValign = "middle";

        // Make bullets grow on hover
        var bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.strokeWidth = 2;
        bullet.circle.radius = 4;
        bullet.circle.fill = am4core.color("#fff");

        var bullethover = bullet.states.create("hover");
        bullethover.properties.scale = 1.3;

        // Make a panning cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "panXY";
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series;

        // Create vertical scrollbar and place it before the value axis
        chart.scrollbarY = new am4core.Scrollbar();
        chart.scrollbarY.parent = chart.leftAxesContainer;
        chart.scrollbarY.toBack();

        // Create a horizontal scrollbar with previe and place it underneath the date axis
        let scrollbarX = new am4charts.XYChartScrollbar();
        scrollbarX.series.push(series);
        scrollbarX.parent = chart.bottomAxesContainer;
        chart.scrollbarX = scrollbarX;
        chart.events.on("ready", function () {
            //dateAxis.zoom({ start: 0.79, end: 1 });
        });

        this.chart = chart;

    }



    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.zone.runOutsideAngular(() => {
            if (this.chart) {
                this.chart.dispose();
            }
        });
    }


    search() {
        this.searched = true;
        this.searchBtnClicked = true;
        this.reloadChart = true;
        this.ngxDatatableWrapper.refresh();
    }

    onChangeUserType(event) {
        this.selectedUserType = event.key;
    }
    onChangeDate(event) {
        this.selectedPeriod = event.value;
    }


    getDateFilter() {
        if (this.selectedPeriod != 'period')
            return (this.selectedPeriod);
        var selectedRange = this.f.datePeriod.value;
        var from = selectedRange[0];
        var to = selectedRange[1];
    }
    getDateFilterObj() {
        /*if (this.selectedPeriod != 'period')
            return ({ value: this.selectedPeriod });*/
        let selectedRange = this.f.datePeriod.value;
        if (!selectedRange) return null;
        let from = selectedRange[0];
        let to = selectedRange[1];
        return {value:JSON.stringify({  from: moment(from).format('YYYY-MM-DD')/*.toISOString()*/, to: moment(to).format('YYYY-MM-DD')/*.toISOString()*/  })};
        //return { ext: { from: moment(from).toISOString(), to: moment(to).toISOString() }, value: this.selectedPeriod };
    }



      loadData = (dataTablesParameters: any, callback: any) => {
        let that = this;
        if (this.selectedPeriod != '' || this.selectedUserType != '') {
            if (this.selectedUserType != 'ALL') dataTablesParameters.columns[1].search.value = this.selectedUserType;
            dataTablesParameters.columns[4].search = (this.getDateFilterObj());
           // dataTablesParameters.columns[3].regex = dataTablesParameters.columns[3].search;
            if(this.searchBtnClicked)this.loadChartData({...dataTablesParameters});
            this.searchBtnClicked =false;
            if (dataTablesParameters.order.length<=0)!dataTablesParameters.order.push({dir:'desc',column:4});
            this.accessLogService.getAllDt(dataTablesParameters)
          .subscribe(resp => {
            callback(
               resp
            );
          });
        }
      }



}


