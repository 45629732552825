import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    TemplateRef,
    EventEmitter,
    Output,
    Input
} from "@angular/core";
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'src/app/services/toast.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CapitoloBilancio, CapitoloBilancioAnnualita } from '../../models';
import { CapitoloBilancioService } from '../../services';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
import { ColumnMode } from '@swimlane/ngx-datatable';


@Component({
    selector: 'capitoli-bilancio-component',
    templateUrl: './capitoli-bilancio.component.html',
    styleUrls: ['./capitoli-bilancio.component.css']
})
export class CapitoliBilancioComponent
    implements OnInit {


        public maskCodice: any = {
            mask: 'KKKKKK/YYYY',
            lazy: false,  // make placeholder always visible
            placeholderChar: '#',
            blocks: {
                KKKKKK: {
                    mask: '000000',
                    scale: 0,
                    padFractionalZeros: false,
                    radix: ','
                },
                YYYY: {
                    mask: '0000',
                    /*thousandsSeparator: '.',*/
                    scale: 0,
                    padFractionalZeros: false,
                    radix: ','
                    , min: 1950
                }
            }
        };

        public mask: any = {
            mask: 'KK €',
            lazy: false,  // make placeholder always visible

            blocks: {
                KK: {
                    mask: Number,
                    thousandsSeparator: '.',
                    scale: 2,
                    padFractionalZeros: true,
                    radix: ','
                }
            }
        };
        public maskYear: any = {
            mask: 'KK',
            lazy: false,  // make placeholder always visible

            blocks: {
                KK: {
                    mask: Number,
                    /*thousandsSeparator: '.',*/
                    scale: 0,
                    padFractionalZeros: false,
                    radix: ','
                    , min: 1950
                }
            }
        };

    _fastEditObject:any;
    @Input()
    public set fastEditObject(value:any){
        this._fastEditObject = value;

    }
    public get fastEditObject():any{
        return this._fastEditObject;
    }

    @Output() onSubmitOk = new EventEmitter();
    /*@ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;*/
    dtOptions: any = {};
    //dtTrigger: Subject<any> = new Subject();
    visible = false;
    submitted: boolean = false;
    submittedAnnualita =false;
    addSchoolYearForm: FormGroup;
    modalRef: BsModalRef;
    open: boolean = false;
    loading: boolean = false;
    buttonLabel: string = "Inserisci";

    annualitaIsError: boolean = false;
    @ViewChild('formTemplate', { static: true })
    public formTemplate: TemplateRef<any>;
    @ViewChild('templateModal', { static: true })
    public templateModal: TemplateRef<any>;

    //@ViewChild('buttonsTemplate',{static:false}) buttonsTemplate: TemplateRef<any>;
    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;

    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;

    @ViewChild('dateTemplate', { static: true })
    public dateTemplate: TemplateRef<any>;
    @ViewChild('booleanTemplate', { static: true })
    public booleanTemplate: TemplateRef<any>;

    annualita = Array<CapitoloBilancioAnnualita>();

    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    templateList = [];


    columns = [
        /* {
             name: 'id', prop: 'id',visible:false, sortable: true, filtrable: true, width: 10
         }
         ,*/
        {
            name: ' codice'
            , prop: 'codice'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            , minWidth: 60
        }
        , {
            name: 'descrizione'
            , prop: 'descrizione'
            , sortable: true
            , filtrable: true
            , flexGrow: 10
            , minWidth: 200
            //,cellClass: 'mobile-hidden desktop-hidden'
            //,headerClass: 'mobile-hidden desktop-hidden'

        }
        , {
            name: 'entrata'
            , prop: 'isEntrata'
            , cellTemplate: 'booleanTemplate'
            , sortable: true
            , filtrable: true
            , flexGrow: 2
            , minWidth: 60
        }
        , {
            name: 'modifica'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60

        }
        , {
            name: 'elimina'
            , prop: 'id'
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            , flexGrow: 1
            , minWidth: 60

        }
    ];


    constructor(
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private el: ElementRef,
        private localeService: BsLocaleService,
        private toastr: ToastService,
        private modalService: BsModalService,
        private currentService: CapitoloBilancioService,
    ) {
        this.localeService.use("it");
        this.dtOptions = {
            rows: []
            , totalElements: 0
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loanding'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            , scrollbarH: "true"
            ,ajax:this.getCapitoli
        };
    }

    ngOnInit() {
        window.scroll(0, 0);
        this.templateList = [{ name: 'dateTemplate', template: this.dateTemplate }, { name: 'booleanTemplate', template: this.booleanTemplate }, { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }, { name: 'btnEditTemplate', template: this.btnEditTemplate }];
        //this.getTable();
        this.addSchoolYearForm = this.initializeFormGroup();
        this.annualitaForm = this.initializeFormGroupAnnualita();
        this.annualita = [];
        if(this.fastEditObject){
            this.fillEditForm(this._fastEditObject);
            this.buttonLabel = "Aggiorna";
            this.addSchoolYearForm.controls.codice.disable();
            this.addSchoolYearForm.controls.descrizione.disable();
            this.addSchoolYearForm.controls.isEntrata.disable();
        }
       // this.getCapitoli();
    }


    initializeFormGroup() {
        return this.formBuilder.group({
            id: [null],
            codice: [null, [Validators.required, Validators.maxLength(255)]],
            descrizione: [null, [Validators.required]]

            , isEntrata: [null, [Validators.required]]
        });
    }


    initializeFormGroupAnnualita() {
        return this.formBuilder.group({
            id: [null]
            , anno: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4), this.checkAnnoRiferimentoRangeValidator(this.minYear, 2000)]]
            , stanziamentoCompetenza: [null, [Validators.required]],
            stanziamentoCassa: [null, []],
            variazioniCompetenza: [null, []],
            variazioniCassa: [null, []]
            , impegnato: [null, []]
        });
    }

    get f() {
        return this.addSchoolYearForm.controls;
    }


    onSubmit() {
        this.submitted = true;
        this.addSchoolYearForm.updateValueAndValidity();
        let errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );
        }
        if (this.addSchoolYearForm.invalid || errors) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.addSchoolYearForm.controls) {
                if (this.addSchoolYearForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.addSchoolYearForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.addSchoolYearForm.get(el)["controls"]) {
                            if (this.addSchoolYearForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }

        const obj: CapitoloBilancio = new CapitoloBilancio();
        obj.descrizione = this.f.descrizione.value;
        obj.id = this.f.id.value;
        obj.isEntrata = this.f.isEntrata.value == "1";
        obj.codice = this.f.codice.value;
        obj.annualita = this.annualita;
        obj.annualita.forEach(x => { if (x.id <= 0) x.id = null; });
        this.loading = true;
        this.currentService.save(obj).subscribe(res => {
            if (!res) {
                this.toastr.error(`Capitolo di bilancio già presente, operazione annullata`);
                this.loading = false;
                return;
            }
            this.toastr.success("Capitolo di bilancio salvato con successo!");
            this.submitted = false;
            this.submittedAnnualita = false;
            this.open = false;
            this.loading = false;
            this.addSchoolYearForm.reset();
            this.buttonLabel = "Inserisci";
            this.currentRow = new CapitoloBilancio();
            this.annualita = [];
            if (!obj.id) {
                //new row
                this.ngxDatatableWrapper.addRow(res);
            }
            else {
                this.closeModal();
                if(this.ngxDatatableWrapper)this.ngxDatatableWrapper.updateRow(res);
            }
            this.onSubmitOk.emit(res);
        }, error => {
            this.toastr.error("Errore di sistema");
        });
    }

    closeModal() {
        if(this.modalRef)this.modalRef.hide();
        this.addSchoolYearForm.reset();
        this.annualita = [];
    }

    fillEditForm(obj){
        this.addSchoolYearForm.setValue({
            id: obj.id,
            descrizione: obj.descrizione,
            codice: obj.codice
            , isEntrata: obj.isEntrata ? '1' : '0'
        });
        this.annualita = obj.annualita;
        this.annualita.sort((a, b) => a.anno - b.anno);
        this.currentRow = obj;
    }
    onEdit(row) {
        const config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl"
        };
        this.submitted = false;
        this.submittedAnnualita = false;
        console.log(row);
        this.buttonLabel = "Aggiorna";
        this.modalRef = this.modalService.show(this.templateModal, config);
        this.fillEditForm(row);
    }



    onDelete(row) {
        console.log(row);
        if (!confirm(`Stai per eliminare il capitolo di bilancio "${row.codice}", proseguire?`)) return;
        this.loading = true;
        this.currentService.delete(row.id).subscribe(
            result => {
                this.loading = false;
                this.ngxDatatableWrapper.deleteRow(row);
                this.toastr.success('Capitolo di bilancio con successo', null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Impossibile eliminare il capitolo di bilancio: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                    timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
                });
                console.log('error');
            }
        );
    }



    printValueAsDate(value) {
        return value;
    }


    /*getCapitoli() {
        this.currentService.getAll(null).subscribe(
            result => {
                this.ngxDatatableWrapper.setInitialData(result);
            }
        );
    }*/
    getCapitoli = (dataTablesParameters: any, callback: any) => {
        let that = this;
        this.currentService.getAllDt(dataTablesParameters)
          .subscribe(resp => {
            callback(resp)
          });
      }

    currentRow: CapitoloBilancio;
    templateAnnualitaTitle: string = "Inserisci nuova annualità";
    buttonLabelAnnualita: string = "Inserisci";
    @ViewChild('templateAnnualita', { static: true })
    public templateAnnualita: TemplateRef<any>;

    annualitaForm: FormGroup;
    minYear: number = 1950;



    checkAnnoRiferimentoRangeValidator(minYear: number, maxYear: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            console.log('inside checkAnnoRiferimentoRangeValidator ');
            if (!control || !control.parent) return null;
            let value = parseInt(control.value);
            if (isNaN(value) || value < minYear/* || value > maxYear*/) return { range: true };
            return null;
        }
    }

    modalRefAnn: BsModalRef;
    tempId = 0;
    openModalAnnualita() {
        this.templateAnnualitaTitle = "Inserisci nuova annualità";
        const config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-lg"
        };
        this.submitted = false;
        this.submittedAnnualita = false;
        this.buttonLabelAnnualita = "Inserisci";
        this.annualitaForm.setValue(
            {
                id: --this.tempId
                , anno: null
                , stanziamentoCompetenza: null
                , stanziamentoCassa: null
                , variazioniCompetenza: null
                , variazioniCassa: null
                , impegnato: null
            }
        );
        this.modalRefAnn = this.modalService.show(this.templateAnnualita, config);
    }

    fa() {
        return this.annualitaForm.controls;
    }
    onSubmitAnnualita() {
        this.submittedAnnualita = true;
        this.annualitaForm.updateValueAndValidity();
        let errors = false;
        for (let c in this.annualitaForm.controls) {
            errors = errors || this.annualitaForm.controls[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.annualitaForm.controls[c].errors)
            );
        }
        if (this.annualitaForm.invalid || errors) {
            console.log("invalid");
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (let el in this.annualitaForm.controls) {
                if (this.annualitaForm.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.annualitaForm.controls[el] instanceof FormGroup) {
                        for (let el1 in this.annualitaForm.get(el)["controls"]) {
                            if (this.annualitaForm.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            return false;
        }
        let obj = this.annualitaForm.value;
        let existsAnno = this.annualita.filter(x => x.id != obj.id && x.anno == obj.anno);
        if (existsAnno.length > 0) {
            this.toastr.error(`Esiste già l'annualità per l'anno selezionato`, null, {
                timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
            });
            return false;
        }
        let currentAnn = this.annualita.filter(x => x.id != obj.id);
        currentAnn.push(obj);
        currentAnn.sort((a, b) => a.anno - b.anno);
        this.annualita = currentAnn;
        this.toastr.success('Annualità salvata con successo', null, {
            timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
        });
        this.modalRefAnn.hide();
    }

    removeAnnualita(item) {
        this.annualita = this.annualita.filter(x => x.id != item.id);
        this.toastr.success(`Annualità ${item.anno} rimossa con successo`, null, {
            timeOut: 2000,/*closeButton:true,*/ disableTimeOut: false
        });
    }
    editAnnualita(item) {
        this.templateAnnualitaTitle = "Modifica annualità";
        const config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-lg"
        };
        this.submitted = false;
        this.submittedAnnualita = false;
        this.buttonLabelAnnualita = "Aggiorna";
        this.annualitaForm.setValue({
            id: item.id
            , anno: item.anno
            , stanziamentoCompetenza: item.stanziamentoCompetenza
            , stanziamentoCassa: item.stanziamentoCassa
            , variazioniCompetenza: item.variazioniCompetenza
            , variazioniCassa: item.variazioniCassa
            , impegnato: item.impegnato
        }
        );
        this.modalRefAnn = this.modalService.show(this.templateAnnualita, config);
        return false;
    }

}
