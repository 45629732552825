import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from "src/app/services/auth.service";
import{AuthenticationIAMService} from "src/app/services/authentication-iam.service";
import { AppConfigService } from "src/app/services/app-config.service";


@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
    invalidLogin: boolean;
    loginForm: FormGroup;
    loading: boolean = false;
    submitted: boolean = false;
    newLogin = true;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private authenticationIAMService:AuthenticationIAMService
    ) { 
        this.newLogin = AppConfigService.env.onlyIamLogin;
        

    }

    get f() { return this.loginForm.controls; }
    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });


    }

    visualizzaIamLogin(){
        this.newLogin = true;
    }



    signIn() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        let credentials = this.loginForm.value;
        this.loading = true;
        this.authService.login(credentials).subscribe(result => {
            this.loading = false;
            if (result) {
                const returnUrl = this.route.snapshot.queryParamMap.get("returnUrl");
                this.router.navigate([returnUrl || ""]);
            } else this.invalidLogin = true;
        });
    }

    iamLogin(){
        return this.authenticationIAMService.login();
    }


}
