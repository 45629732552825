import {
    Component,
    OnInit,
    ViewChild,
    TemplateRef
} from "@angular/core";
import { News } from "src/app/models/news";
import { NewsService } from "src/app/services/news.service";
import { HttpClient } from "@angular/common/http";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { defineLocale } from "ngx-bootstrap/chronos";
import { itLocale } from "ngx-bootstrap/locale";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import {
    FormGroup,
    Validators,
    FormBuilder
} from "@angular/forms";
import { DatePipe } from "@angular/common";
import * as FileSaver from "file-saver";
import { ToastService } from 'src/app/services/toast.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from 'src/app/components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component';
defineLocale("it", itLocale);

//jQuery
declare var $: any;

@Component({
    templateUrl: "./news-management.component.html",
    styleUrls: ["./news-management.component.css"]
})
export class NewsManagementComponent implements OnInit {
    modalRef: BsModalRef;
    modalOpts: ModalOptions = {
        backdrop: "static",
        ignoreBackdropClick: true,
        keyboard: false
    };
    attach: File = new File([""], "");
    newsGroup: FormGroup;
    newsGroup2: FormGroup;

    submitted: boolean = false;
    modNews: News;



    columns = [
        {
            name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
            , resizeable: false
        }

        , {
            name: 'data'
            , prop: 'datePub'

            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , cellTemplate: 'dateValueTemplate'
            , flexGrow: 2
            , minWidth: 90

        }
        , {
            name: 'titolo'
            , prop: 'title'

            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , flexGrow: 10
            , minWidth: 200

        }
        , {
            name: 'pubblicato'
            , prop: 'enabled'
            , sortable: true
            , filtrable: true
            , resizeable: false
            , draggable: false
            , cellTemplate: 'btnEnableTemplate'
            , flexGrow: 2
            , minWidth: 90
        }
        , {
            name: 'modifica'
            , prop: null
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnEditTemplate'// 'buttonsTemplate'
            //, width: 10
            , resizeable: false
            , draggable: false
            , flexGrow: 1
            , minWidth: 60
        }
        , {
            name: 'elimina'
            , prop: null
            , sortable: false
            , filtrable: false
            , cellTemplate: 'btnDeleteTemplate'// 'buttonsTemplate'
            //, width: 10
            , resizeable: false
            , draggable: false
            , flexGrow: 1
            , minWidth: 60
        }
    ];
    dtOptionsNgx: any;
    @ViewChild('btnDeleteTemplate', { static: true })
    public btnDeleteTemplate: TemplateRef<any>;
    @ViewChild('btnEditTemplate', { static: true })
    public btnEditTemplate: TemplateRef<any>;
    @ViewChild('btnEnableTemplate', { static: true })
    public btnEnableTemplate: TemplateRef<any>;
    templateList = [];
    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;


    constructor(
        private http: HttpClient,
        private newsService: NewsService,
        private modalService: BsModalService,
        private formBuilder: FormBuilder,
        public datePipe: DatePipe,
        private localeService: BsLocaleService
        , private toastr: ToastService
    ) {
        this.localeService.use("it");
        this.dtOptionsNgx = {
            rows: []
            , totalElements: 0
            , columns: this.columns
            , columnMode: ColumnMode.flex
            , headerHeight: "50"
            , footerHeight: "50"
            , rowHeight: "auto"
            , externalSorting: true// true
            , loadingIndicator: 'loading'
            , class: "bootstrap"
            , summaryRow: true
            , summaryPosition: "'bottom'"
            , externalPaging: true// true
            , count: 0
            , offset: 0
            , limit: 10
            , serverSide: true
            ,ajax:this.loadData
            , scrollbarH:"true"
        };
    }

    ngOnInit() {
        this.newsGroup = this.formBuilder.group({
            title: [null, [Validators.required]],
            date: [null, [Validators.required]],
            message: [null, [Validators.required]],
            attach: [null]
        });
        this.newsGroup2 = this.formBuilder.group({
            title2: [null, [Validators.required]],
            date2: [null, [Validators.required]],
            message2: [null, [Validators.required]],
            attach2: [null]
        });
        this.templateList = [
        { name: 'btnDeleteTemplate', template: this.btnDeleteTemplate }
        , { name: 'btnEnableTemplate', template: this.btnEnableTemplate }
        , { name: 'btnEditTemplate', template: this.btnEditTemplate }];
        }




    openModal(template: TemplateRef<any>, doReset: boolean) {
        this.submitted = false;
        if (doReset) {
            this.modNews = null;
            this.newsGroup.reset();
        }
        const config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-lg"
          };

          this.modalRef = this.modalService.show(
            template,
            config
          );


        //this.modalRef = this.modalService.show(template);
        this.f.attach.patchValue("");
    }

    onSubmit() {
        this.saveNews(this.modNews);
    }

    fileChange(event) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            const file: File = fileList[0];

            this.attach = file;
        }
    }



    get f() {
        return this.newsGroup.controls;
    }

    loadData = (dataTablesParameters: any, callback: any) => {
        let that = this;
        this.newsService.getAllDt(dataTablesParameters)
            .subscribe(resp => {
                callback(resp);
            });
    };

    saveNews(n: News) {
        this.submitted = true;
        this.f.title.markAsTouched();
        this.f.date.markAsTouched();
        this.f.message.markAsTouched();
        console.log("this.Form.invalid:" + this.newsGroup.invalid);

        var errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );
        }
        console.log("errors:" + errors);
        if (this.newsGroup.invalid || errors) {
            console.log("invalid");
            return false;
        }
        const formData: FormData = new FormData();
        formData.append("attach", this.attach, this.attach.name);
        formData.append("title", this.f.title.value);
        formData.append("date", this.f.date.value);
        formData.append("message", this.f.message.value);
        //formData.append("enabled",this.modNews && this.modNews.enabled?"1":"0");
        if (n != null) {
            formData.append("id", n.id.toString());
        }

        this.newsService.saveNews(formData).subscribe(res => {
            this.modNews = null;
            this.attach = new File([""], "");
            this.f.title.patchValue(null);
            this.f.date.patchValue(null);
            this.f.message.patchValue(null);
            this.f.attach.patchValue("");
            this.modalRef.hide();
            this.refreshGrid();
            this.toastr.success(`News salvata con successo`);
        });
    }

    editHandler(news: any) {
        this.modNews = news;
        $("#fake").click();
        this.f.title.patchValue(news.title);
        const date = new Date(news.datePub);
        this.f.date.patchValue(date);
        this.f.message.patchValue(news.message);

        // if (news.filePath != null) {
        //   const filename: String[] = news.filePath.split("/");
        //   this.f.attach.patchValue(filename[filename.length - 1]);
        // }
    }

    deleteHandler(news: any) {
        const result: boolean = confirm(`Sei sicuro di voler eliminare la news selezionata?`);
        if (result) {
            this.newsService.deleteNews(news).subscribe(deleted => {
                if (deleted) {
                    //alert(`News eliminata con successo`);
                    this.toastr.success(`News eliminata con successo`);
                    this.refreshGrid();
                }
            });
        }
    }
    refreshGrid() {
        this.ngxDatatableWrapper.refresh();
    }

    enableHandler(news: any) {
        if (!confirm(`${news.enabled ? 'Annullare la pubblicazione della' : 'Pubblicare la'} news selezionata?`)) return;
        this.newsService.enableNews(news).subscribe(res => {
            let message = news.enabled ? 'Pubblicazione della news annullata con successo' : 'News pubblicata con successo';
            this.toastr.success(message);
            this.refreshGrid();
        });
    }

    deleteFile() {
        if (!confirm(`Eliminare l'allegato selezionato?`)) return;
        this.newsService.deleteAttachFromNew(this.modNews).subscribe(res => {
            this.attach = new File([""], "");
            this.modNews.filePath = null;
        });
    }

    downloadFile() {
        this.newsService.downloadAttach(this.modNews).subscribe(res => {
            let filename = this.modNews.filePath.replace(/^.*[\\\/]/, '');
            FileSaver.saveAs(res.body, `${filename}`);
        });
    }


}
