<ng-template #templateEditAdd>
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()" class="col-lg-12">
        <input type="hidden" [formGroup]="editForm" formControlName="id" />
        <div class="form-group">
            <h5><label for="descrizione" class="mandatory">Descrizione:</label></h5>
            <input [formGroup]="editForm" formControlName="descrizione" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci la descrizione" [ngClass]="{
                    'form-control': true,
                    'is-invalid': submitted && f.descrizione.errors
                    }" />
            <div *ngIf="submitted && f.descrizione.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.descrizione.errors.required">
                    Campo obbligatorio
                </div>
            </div>
        </div>
        <div class="form-group ">
            <h5><label for="" class="">Responsabile Unico del Procedimento (Dlgs. 50/2016)<br>Responsabile del Procedimento (L.241/1990)<br>Referente:</label></h5>
            <div class="custom-control custom-switch ">

                <input type="checkbox" class="custom-control-input" formControlName="addTipologia" id="addTipologia" #addTipologia  /><!--TODO deve passare una stringa-->
                <label class="custom-control-label" for="addTipologia"></label>
            </div>
        </div>
        <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
      Inserisci
    </button>
    </form>
</ng-template>
<ng-template #templateEdit>
    <form [formGroup]="editForm2" (ngSubmit)="onSubmit2()" class="col-lg-12">
        <input type="hidden"  formControlName="id" />
        <div class="form-group">
            <h5><label for="descrizione" class="mandatory">Descrizione:</label></h5>
            <input  formControlName="descrizione"  maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci la descrizione" [ngClass]="{
                    'form-control': true,
                    'is-invalid': submitted2 && f2.descrizione.errors
                    }" />
            <div *ngIf="submitted2 && f2.descrizione.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f2.descrizione.errors.required">
                    Campo obbligatorio
                </div>
            </div>
        </div>
        <div class="form-group ">
            <h5><label for="" class="">Responsabile Unico del Procedimento (Dlgs. 50/2016)<br>Responsabile del Procedimento (L.241/1990)<br>Referente:</label></h5>
            <div class="custom-control custom-switch ">

                <input type="checkbox" formControlName="editTipologia" class="custom-control-input" id="editTipologia" [checked]="editForm2.controls.sottoTipologia"  #editTipologia  /><!--TODO deve passare una stringa-->
                <label class="custom-control-label" for="editTipologia"></label>
            </div>
        </div>
        <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
      Aggiorna
    </button>
    </form>
</ng-template>

<div class="container ">
    <hr />
    <accordion [isAnimated]="true">
        <accordion-group heading="Inserisci Nuova tipologia di soggetto responsabile">
            <ng-container *ngTemplateOutlet="templateEditAdd"></ng-container>
        </accordion-group>
    </accordion>
    <div class="mt-5">

        <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptions">
            <ng-template #btnEditTemplate let-row="row" let-value="value">
                <a [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onEdit(row)" data-toggle="tooltip" data-placement="top" title="modifica dati">&nbsp;&nbsp;&ensp;<i class="far fa-edit"></i></a>
            </ng-template>
            <ng-template #btnDeleteTemplate let-row="row" let-value="value">
                <a [ngClass]="{
            'disabled': loading
            }" class="  border-0 text-center" (click)="onDelete(row)" data-toggle="tooltip" data-placement="top" title="elimina">&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt"></i></a>
            </ng-template>
            <ng-template #dateTemplate let-row="row" let-value="value">
                {{printValueAsDate(value)}}
            </ng-template>
        </ngx-datatable-wrapper>
    </div>

    <ng-template #templateModal>
        <div class="modal-header  text-center">
            <h4 class="modal-title pull-left">Modifica tipologia</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <ng-container *ngTemplateOutlet="templateEdit"></ng-container>
        </div>
    </ng-template>


</div>
