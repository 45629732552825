import { SicerImpegnoAccertamento } from "./sicer-impegno-accertamento";
import { SicerCapitoloBilancio } from "./sicer-capitolo-bilancio";

export class SicerCapitolo {
  id: number;
  codCpt: string;
  descrCpt: string;
  pkCpt: string;
  scrittureBilancio: SicerCapitoloBilancio[];
  impegniAccertamenti: SicerImpegnoAccertamento[];
}
