<div class="container-fluid px-0 bg-celeste">
  <div
    class="text-center p-5 position-absolute top-50 start-50 translate-middle bg-white border border-dark rounded shadow"
    style="z-index: 999999999" [hidden]="showPage">
    <app-loading message="Caricamento in corso..."></app-loading>
  </div>

  <app-header-dashboard></app-header-dashboard>
  <!-- menu -->
  <div class="row pb-3 pt-1 bg-0083e0" style="z-index: 9000">
    <div class="col-xl-2 col-lg-4 col-md-4 pt-1">
      <label for="importoFinanziamento ">
        <h6 class="mb-0 text-white bold">Stato Progettualità/Intervento</h6>
      </label>
      <ng-select [items]="statuses" bindValue="code" bindLabel="label"
        placeholder="Seleziona stato progettualità/Intervento " (change)="setInterventionStatus($event)"
        [multiple]="true" [selectableGroup]="true" [selectableGroupAsModel]="false" groupBy="gruppo">
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> <span
            title="{{ item.label }}">&nbsp;{{ item.label }}</span>
        </ng-template>
        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
          <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item.gruppo | uppercase}}
        </ng-template>
      </ng-select>
    </div>

    <div class="col-xl-2 col-lg-5 col-md-5 pt-1">
      <label for=" importoFinanziamento ">
        <h6 class="mb-0 text-white bold">
          Titolo intervento <small>(min 3 caratteri)</small>
        </h6>
      </label>

      <app-completer [cpOptions]="completerParams" (onSelectEmitter)="setIntervention($event)"
        (keyup)="onChangeIntervention($event)">
      </app-completer>
    </div>

    <div class="col-xl-2 col-lg-3 col-md-3 pt-1">
      <label>
        <h6 class="mb-0 text-white bold">Annualità</h6>
      </label>
      <ng-select [items]="anniCombo" bindValue="value" bindLabel="key" placeholder="Seleziona annualità"
        (change)="setAnno($event)">
        <ng-template ng-option-tmp let-item="item">
          <div title="{{ item.value }}">{{ item.value }}</div>
        </ng-template>
      </ng-select>
    </div>

    <div class="col-xl-3 col-lg-6 col-md-5 pt-1">
      <label for="importoFinanziamento ">
        <h6 class="mb-0 text-white bold">
          Aree gestionali direzione e/o esterne
        </h6>
      </label>
      <ng-select [items]="areeTematicheCombo" bindValue="id" bindLabel="descrizione"
        placeholder="Seleziona area gestionale direzione e/o esterna " (change)="setAreaTematica($event)">
        <ng-template ng-option-tmp let-item="item">
          <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
        </ng-template>
      </ng-select>
    </div>
    <div class="col-xl-2 col-lg-4 col-md-5 pt-1">
      <label for="importoFinanziamento">
        <h6 class="mb-0 text-white bold">Tipologia Classificazioni</h6>
      </label>
      <ng-select [items]="classificazioniCombo" bindValue="id" bindLabel="tipo"
        placeholder="Seleziona tipologia classificazioni" (change)="setClassificazione($event)">
        <ng-template ng-option-tmp let-item="item">
          <div title="{{ item.tipo }}">{{ item.tipo }}</div>
        </ng-template>
      </ng-select>
    </div>
    <!-- <div class="col-xl-3 col-lg-3 col-md-5 pt-1">
      <label for="importoFinanziamento">
        <h6 class="mb-0 text-white bold">Soggetti Giuridici</h6>
      </label>
      <ng-select
        bindValue="id"
        bindLabel="denominazione"
        placeholder="Seleziona soggetto giuridico"
      >
      </ng-select>
    </div> -->

    <div class="col-xl-1 col-lg-2 col-md-2 col-sm-12 pt-1">
      <label>
        <h6 class="mb-0 text-white bold"></h6>
      </label>
      <div>
        <button [disabled]="loadingExportExcel" type="button" class="mt-1 btn btn-info btn-block"
          (click)="esportaExcel()">
          Esporta
          <img *ngIf="loadingExportExcel" class="pl-3 float-right"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-5 col-md-12">
      <div id="chart1" class="bg-white chart1 border shadow-sm my-3 rounded"></div>
    </div>
    <div class="col-lg-7 col-md-12">
      <div id="chart3" class="bg-white chart1 border shadow-sm my-3 rounded"></div>
    </div>
  </div>
  <div class="row">
    <!-- <div class="col-lg-12 col-md-12">
      <div
        id="chart3"
        class="bg-white chart3 border shadow-sm my-3 rounded"
      ></div>
    </div> -->
    <div class="col-lg-12">
      <div id="chart4 " class="bg-white chart4 border shadow-sm my-3 rounded"></div>
    </div>
  </div>

  <!--grafici classificazione-->
  <div class="row" [hidden]="isHiddenChart67" style="z-index: 12000; overflow-y: visible">
    <div class="col-lg-12 col-md-12" style="z-index: 12000; overflow-y: visible">
      <div id="chart6" class="bg-white chart6 border shadow-sm my-3 rounded"
        style="z-index: 12000; overflow-y: visible"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div id="chart7" class="bg-white chart3 border shadow-sm my-3 rounded"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 my-3 text-center">
      <div class="bg-white border shadow-sm rounded">
        <button *ngIf="!tabellaDettaglioObjLoaded" (click)="tabellaDettaglioObjLoaded = true"
          class="btn btn-primary my-2">Mostra tabella di dettaglio</button>
        <div class="text-left"><dashboard-finanziaria-details-table-component [hidden]="!tabellaDettaglioObjLoaded"
            [observable$]="observable$ | async" [tabellaDettaglioObj]="tabellaDettaglioObj"
            (onExportExcelClick)="exportXlsDetailTableData($event)"></dashboard-finanziaria-details-table-component>
        </div>
      </div>
    </div>
  </div>
</div>
